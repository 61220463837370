
const digest = '336c46b2530c417ee8e0176ec27d65512f0afbabc723b9eba4c4809498d0ceb3';
const css = `._message_11prj_1 {
  align-content: center;
  display: flex;
  font-weight: 600;
}

._message_11prj_1 svg {
  margin-right: 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"message":"_message_11prj_1"};
export { css, digest };
  