
const digest = '72ed5e0f2a46b6f0da50c03d2a13f86d383d592c45e6af7ce932928585617389';
const css = `._tooltip_qai5p_1 {
  --background: var(--color-neutral-40);
  background: var(--background);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  pointer-events: none;
}

._tooltip_qai5p_1._light_qai5p_9 {
  --background: var(--color-white);
  filter: drop-shadow(0px 1px 8px rgba(54, 78, 99, 0.12));
}

._left_qai5p_14 {
  top: var(--tether-vert-center);
  left: var(--tether-left);
  transform: translateX(calc(-100% - 0.5rem)) translateY(-50%);
}

._right_qai5p_20 {
  top: var(--tether-vert-center);
  left: var(--tether-right);
  transform: translateX(8px) translateY(-50%);
}

._top_qai5p_26 {
  top: var(--tether-top);
  left: var(--tether-hor-center);
  transform: translateY(calc(-100% - 0.5rem)) translateX(-50%);
}

._bottom_qai5p_32 {
  top: var(--tether-bottom);
  left: var(--tether-hor-center);
  transform: translateX(calc(-50% + var(--offsetX))) translateY(0.5rem);
}

._arrow_qai5p_38 {
  position: absolute;
}

._bottom_qai5p_32 ._arrow_qai5p_38 {
  top: 0;
  left: 50%;
  transform: translateY(-16px) translateX(calc(-50% - var(--offsetX)));
}

._top_qai5p_26 ._arrow_qai5p_38 {
  bottom: 0;
  left: 50%;
  transform: translateY(16px) translateX(-50%) rotate(180deg);
}

._left_qai5p_14 ._arrow_qai5p_38 {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateX(1px) rotate(90deg);
}

._right_qai5p_20 ._arrow_qai5p_38 {
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(calc(-100% - 1px)) rotate(-90deg);
}

._heading_qai5p_66 {
  color: var(--color--neutral-10);
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.25rem;
  text-align: center;
}

._text_qai5p_76 {
  color: var(--color-white);
  font-size: 0.75rem;
  font-style: normal;
  line-height: 1;
}

._text_qai5p_76._light_qai5p_9 {
  color: var(--color-neutral40);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"tooltip":"_tooltip_qai5p_1","light":"_light_qai5p_9","left":"_left_qai5p_14","right":"_right_qai5p_20","top":"_top_qai5p_26","bottom":"_bottom_qai5p_32","arrow":"_arrow_qai5p_38","heading":"_heading_qai5p_66","text":"_text_qai5p_76"};
export { css, digest };
  