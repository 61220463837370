
const digest = 'd9e005803d82108c460a4d67431b56a3b42444fc4ba4f826d1b54f7aa405a340';
const css = `._column_1tvdw_1 {
  position: relative;

  background: var(--color-legacy-gray-1);
  border-right: 1px solid var(--color--neutral-20);
  min-height: 100%;
  height: 100%;
  transform: translateZ(0);
  padding-top: 0.5rem;
  transition: padding 0.1s var(--transition);
}

._column_1tvdw_1._rowColumn_1tvdw_13,
._column_1tvdw_1._selected_1tvdw_14 {
  padding-top: 0;
}

._selected_1tvdw_14 {
  background: var(--color-legacy-gray-2);
}

._addProject_1tvdw_22 {
  padding: 0.25rem 0.5rem 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"column":"_column_1tvdw_1","rowColumn":"_rowColumn_1tvdw_13","selected":"_selected_1tvdw_14","addProject":"_addProject_1tvdw_22"};
export { css, digest };
  