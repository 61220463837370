
const digest = '3578d78b5160e9dac7feabd15ca0372d48f5de844f612292ec8720a7e4578077';
const css = `._formWrapper_1kd6c_1 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: repeat(auto-fill, 1fr);
}

._nameRow_1kd6c_7 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"formWrapper":"_formWrapper_1kd6c_1","nameRow":"_nameRow_1kd6c_7"};
export { css, digest };
  