
const digest = '91ad5cb49aa9f62a701b44fe893abe02678b4b516ef70c7b6a1603e42b4850c9';
const css = `._highlighted_6cck3_1 {
  color: var(--color-neutral-40);
}

._small_6cck3_5 {
  height: 2em;
}

._medium_6cck3_9 {
  height: 3em;
}

._large_6cck3_13 {
  height: 4em;
}

._hasHorizontalBorders_6cck3_17 {
  border-top: 1px solid var(--color--neutral-20);
  border-bottom: 1px solid var(--color--neutral-20);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"highlighted":"_highlighted_6cck3_1","small":"_small_6cck3_5","medium":"_medium_6cck3_9","large":"_large_6cck3_13","hasHorizontalBorders":"_hasHorizontalBorders_6cck3_17"};
export { css, digest };
  