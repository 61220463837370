import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: Record<string, unknown>;
};



export type Activity = {
  __typename?: 'Activity';
  ajeraIdentifier?: Maybe<AjeraIdentifier>;
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AddClientAddressInput = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  parcel?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddClient */
export type AddClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddClientAddressInput>;
  department?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  invoiceSlug?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  shouldSyncToQuickbooks?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddClient. */
export type AddClientPayload = {
  __typename?: 'AddClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddConsultantCompany */
export type AddConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryContactFname: Scalars['String'];
  primaryContactLname: Scalars['String'];
  primaryContactEmail: Scalars['String'];
  primaryContactPhone?: Maybe<Scalars['String']>;
  consultantTemplateIds: Array<Scalars['String']>;
  consultingWorkId?: Maybe<Scalars['String']>;
  quickbooksVendorId?: Maybe<Scalars['String']>;
  quickbooksVendorSyncToken?: Maybe<Scalars['Int']>;
  bypassVendorExistenceCheck?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddConsultantCompany. */
export type AddConsultantCompanyPayload = {
  __typename?: 'AddConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of AddConsultantContact */
export type AddConsultantContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  fname: Scalars['String'];
  lname: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  consultantCompanyId: Scalars['String'];
  consultantTemplateId: Scalars['String'];
  consultingWorkId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddConsultantContact. */
export type AddConsultantContactPayload = {
  __typename?: 'AddConsultantContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantContact?: Maybe<ConsultantContact>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  parcel?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  /** Returns a single organization */
  organization: Organization;
  organizationSearch: Array<Organization>;
  /** Returns recent qualified organizations */
  organizations: OrganizationConnection;
  profileSearch: Array<Profile>;
  /** Returns 50 recent profiles */
  recentProfiles: Array<Profile>;
  /** Returns 50 recent admin users */
  recentUsers: Array<User>;
};


export type AdminOrganizationArgs = {
  id: Scalars['ID'];
};


export type AdminOrganizationSearchArgs = {
  filter?: Maybe<OrganizationFilter>;
};


export type AdminOrganizationsArgs = {
  isQualified?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AdminProfileSearchArgs = {
  filter?: Maybe<ProfileFilter>;
};


export type AdminRecentUsersArgs = {
  isAdmin?: Maybe<Scalars['Boolean']>;
};

export type AdminMutations = {
  __typename?: 'AdminMutations';
  cloneShowcaseTemplate?: Maybe<CloneShowcaseTemplatePayload>;
  confirmUserEmail?: Maybe<ConfirmUserEmailPayload>;
  connectSubscription?: Maybe<ConnectSubscriptionPayload>;
  createAccount?: Maybe<CreateAccountMutationPayload>;
  createEmailLinkAndSendToUser?: Maybe<CreateEmailLinkAndSendToUserPayload>;
  deleteOrganization?: Maybe<DeleteOrganizationMutationPayload>;
  enableMfa?: Maybe<EnableMfaPayload>;
  generateUserInviteLink?: Maybe<GenerateUserInviteLinkPayload>;
  resetDemo?: Maybe<ResetDemoPayload>;
  sendPreviewDigest?: Maybe<SendPreviewDigestPayload>;
  toggleProfile?: Maybe<ToggleProfileMutationPayload>;
  toggleUserSuperadminStatus?: Maybe<UpdateUserSuperadminStatusPayload>;
  updateOrganizationQualified?: Maybe<UpdateOrganizationQualifiedMutationPayload>;
  updateOrganizationStatus?: Maybe<UpdateOrganizationStatusMutationPayload>;
  updateOrganizationTrial?: Maybe<UpdateOrganizationTrialMutationPayload>;
};


export type AdminMutationsCloneShowcaseTemplateArgs = {
  input: CloneShowcaseTemplateInput;
};


export type AdminMutationsConfirmUserEmailArgs = {
  input: ConfirmUserEmailInput;
};


export type AdminMutationsConnectSubscriptionArgs = {
  input: ConnectSubscriptionInput;
};


export type AdminMutationsCreateAccountArgs = {
  input: CreateAccountMutationInput;
};


export type AdminMutationsCreateEmailLinkAndSendToUserArgs = {
  input: CreateEmailLinkAndSendToUserInput;
};


export type AdminMutationsDeleteOrganizationArgs = {
  input: DeleteOrganizationMutationInput;
};


export type AdminMutationsEnableMfaArgs = {
  input: EnableMfaInput;
};


export type AdminMutationsGenerateUserInviteLinkArgs = {
  input: GenerateUserInviteLinkInput;
};


export type AdminMutationsResetDemoArgs = {
  input: ResetDemoInput;
};


export type AdminMutationsSendPreviewDigestArgs = {
  input: SendPreviewDigestInput;
};


export type AdminMutationsToggleProfileArgs = {
  input: ToggleProfileMutationInput;
};


export type AdminMutationsToggleUserSuperadminStatusArgs = {
  input: UpdateUserSuperadminStatusInput;
};


export type AdminMutationsUpdateOrganizationQualifiedArgs = {
  input: UpdateOrganizationQualifiedMutationInput;
};


export type AdminMutationsUpdateOrganizationStatusArgs = {
  input: UpdateOrganizationStatusMutationInput;
};


export type AdminMutationsUpdateOrganizationTrialArgs = {
  input: UpdateOrganizationTrialMutationInput;
};

export type AjeraIdentifier = {
  __typename?: 'AjeraIdentifier';
  id: Scalars['ID'];
  key: Scalars['Int'];
  recordId: Scalars['Int'];
  recordType: AjeraRecord;
  typeKey?: Maybe<Scalars['Int']>;
};

/** All available Ajera record types */
export enum AjeraRecord {
  Profile = 'Profile',
  Phase = 'Phase',
  Activity = 'Activity'
}

/** Autogenerated input type of ApproveOrRejectEntryRow */
export type ApproveOrRejectEntryRowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRowId: Scalars['ID'];
  isApproved: Scalars['Boolean'];
};

/** Autogenerated return type of ApproveOrRejectEntryRow. */
export type ApproveOrRejectEntryRowPayload = {
  __typename?: 'ApproveOrRejectEntryRowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRow?: Maybe<EntryRow>;
};

/** Autogenerated input type of ArchiveProject */
export type ArchiveProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ArchiveProject. */
export type ArchiveProjectPayload = {
  __typename?: 'ArchiveProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of ArchiveRoleTemplate */
export type ArchiveRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ArchiveRoleTemplate. */
export type ArchiveRoleTemplatePayload = {
  __typename?: 'ArchiveRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplate?: Maybe<RoleTemplate>;
};

/** Autogenerated input type of AssignConsultantBillToInvoice */
export type AssignConsultantBillToInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['ID']>;
  invoiceSlug?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AssignConsultantBillToInvoice. */
export type AssignConsultantBillToInvoicePayload = {
  __typename?: 'AssignConsultantBillToInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of AssignRoleToAllPhases */
export type AssignRoleToAllPhasesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of AssignRoleToAllPhases. */
export type AssignRoleToAllPhasesPayload = {
  __typename?: 'AssignRoleToAllPhasesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

export type AssistantMessage = {
  __typename?: 'AssistantMessage';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  role: AssistantMessageRole;
};

/** All AI assistant message role type */
export enum AssistantMessageRole {
  System = 'system',
  Assistant = 'assistant',
  User = 'user',
  Tool = 'tool'
}

/** Autogenerated input type of AttachConsultantBillToInvoice */
export type AttachConsultantBillToInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of AttachConsultantBillToInvoice. */
export type AttachConsultantBillToInvoicePayload = {
  __typename?: 'AttachConsultantBillToInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

export type AvailableFilters = {
  __typename?: 'AvailableFilters';
  activities: Array<FilterOption>;
  phaseTemplates: Array<FilterOption>;
  phases: Array<FilterOption>;
  profiles: Array<FilterOption>;
  projects: Array<FilterOption>;
  roleTemplates: Array<FilterOption>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  bankName: Scalars['String'];
  billingDetails: BillingDetails;
  createdAt: Scalars['ISO8601Date'];
  id: Scalars['String'];
  last4: Scalars['String'];
  type: PaymentMethodType;
};

/** All potential Check bank account onboarding issues */
export enum BankAccountOnboarding {
  BankAccountExists = 'bank_account_exists',
  BankAccountVerified = 'bank_account_verified',
  BankAccountDebitAuthorizationSubmitted = 'bank_account_debit_authorization_submitted'
}

/** All  potential Check bank account statuses */
export enum BankAccountStatus {
  ValidationPending = 'validation_pending',
  Validated = 'validated',
  OwnershipVerified = 'ownership_verified',
  DisabledRecoverable = 'disabled_recoverable',
  DisabledIrrecoverable = 'disabled_irrecoverable'
}

/** All potential Check bank account subtype types  */
export enum BankAccountSubtype {
  Checking = 'checking',
  Savings = 'savings'
}

/** Autogenerated return type of BatchExpensesToQBOJob. */
export type BatchExpensesToQboJobPayload = {
  __typename?: 'BatchExpensesToQBOJobPayload';
  result?: Maybe<ExpensesToQbo>;
};

/** Autogenerated return type of BatchInvoicesToQBOJob. */
export type BatchInvoicesToQboJobPayload = {
  __typename?: 'BatchInvoicesToQBOJobPayload';
  result?: Maybe<InvoicesToQbo>;
};

export type BatchJobStatus = {
  __typename?: 'BatchJobStatus';
  bid: Scalars['String'];
  failInfo: Scalars['String'];
  failures: Scalars['String'];
  isComplete: Scalars['String'];
  pending: Scalars['String'];
  total: Scalars['String'];
};

/** Autogenerated return type of BatchJobStatus. */
export type BatchJobStatusPayload = {
  __typename?: 'BatchJobStatusPayload';
  status?: Maybe<BatchJobStatus>;
};

/** All available states of Billable select */
export enum Billable {
  All = 'ALL',
  Billable = 'BILLABLE',
  NonBillable = 'NON_BILLABLE'
}

export type BillingDetails = {
  __typename?: 'BillingDetails';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BulkCreateOverheadEntryGroups */
export type BulkCreateOverheadEntryGroupsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  overheadId: Scalars['ID'];
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  numberOfWeeks: Scalars['Int'];
};

/** Autogenerated return type of BulkCreateOverheadEntryGroups. */
export type BulkCreateOverheadEntryGroupsPayload = {
  __typename?: 'BulkCreateOverheadEntryGroupsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheets?: Maybe<Array<Timesheet>>;
};

/** Autogenerated input type of BulkCreateStaffingRoleAllocations */
export type BulkCreateStaffingRoleAllocationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  allocation: Scalars['Float'];
  beginDates: Array<Scalars['String']>;
  roleId: Scalars['ID'];
};

/** Autogenerated return type of BulkCreateStaffingRoleAllocations. */
export type BulkCreateStaffingRoleAllocationsPayload = {
  __typename?: 'BulkCreateStaffingRoleAllocationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

/** Autogenerated input type of BulkCreateTimeOffPolicyAssignments */
export type BulkCreateTimeOffPolicyAssignmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  assignments: Array<TimeOffPolicyAssignmentInput>;
  timeOffPolicyId: Scalars['ID'];
};

/** Autogenerated return type of BulkCreateTimeOffPolicyAssignments. */
export type BulkCreateTimeOffPolicyAssignmentsPayload = {
  __typename?: 'BulkCreateTimeOffPolicyAssignmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicy: TimeOffPolicy;
};

/** Autogenerated input type of BulkEditEntriesToTargetActivity */
export type BulkEditEntriesToTargetActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sourceEntryIds: Array<Scalars['ID']>;
  targetActivityId: Scalars['ID'];
};

/** Autogenerated return type of BulkEditEntriesToTargetActivity. */
export type BulkEditEntriesToTargetActivityPayload = {
  __typename?: 'BulkEditEntriesToTargetActivityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entries: Array<Entry>;
};

/** Autogenerated input type of BulkEditEntriesToTargetPhase */
export type BulkEditEntriesToTargetPhaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sourceEntryIds: Array<Scalars['ID']>;
  targetPhaseId: Scalars['ID'];
};

/** Autogenerated return type of BulkEditEntriesToTargetPhase. */
export type BulkEditEntriesToTargetPhasePayload = {
  __typename?: 'BulkEditEntriesToTargetPhasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entries: Array<Entry>;
};

/** Autogenerated input type of BulkEditEntriesToTargetRoleTemplate */
export type BulkEditEntriesToTargetRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sourceEntryIds: Array<Scalars['ID']>;
  targetRoleTemplateId: Scalars['ID'];
  targetRateTableId: Scalars['ID'];
};

/** Autogenerated return type of BulkEditEntriesToTargetRoleTemplate. */
export type BulkEditEntriesToTargetRoleTemplatePayload = {
  __typename?: 'BulkEditEntriesToTargetRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entries: Array<Entry>;
};

/** Autogenerated input type of BulkGenerateStaffingAllocations */
export type BulkGenerateStaffingAllocationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of BulkGenerateStaffingAllocations. */
export type BulkGenerateStaffingAllocationsPayload = {
  __typename?: 'BulkGenerateStaffingAllocationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroups: Array<EntryGroup>;
};

/** Autogenerated input type of BulkUpdateStaffingOverheadAllocations */
export type BulkUpdateStaffingOverheadAllocationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  allocation: Scalars['Float'];
  beginDates: Array<Scalars['String']>;
  overheadId: Scalars['ID'];
  profileId: Scalars['ID'];
};

/** Autogenerated return type of BulkUpdateStaffingOverheadAllocations. */
export type BulkUpdateStaffingOverheadAllocationsPayload = {
  __typename?: 'BulkUpdateStaffingOverheadAllocationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroups: Array<EntryGroup>;
};

/** All business type of the company */
export enum Business {
  /** Sole Proprietorship */
  SoleProprietorship = 'sole_proprietorship',
  /** Partnership */
  Partnership = 'partnership',
  /** C Corporation */
  CCorporation = 'c_corporation',
  /** S Corporation */
  SCorporation = 's_corporation',
  /** LLC */
  Llc = 'llc'
}

export type BusinessPerformanceMetric = {
  __typename?: 'BusinessPerformanceMetric';
  aggregate: Scalars['Float'];
  items: Array<BusinessPerformanceMetricItem>;
};

export type BusinessPerformanceMetricItem = {
  __typename?: 'BusinessPerformanceMetricItem';
  beginDate: Scalars['ISO8601Date'];
  value: Scalars['Float'];
};

/** Autogenerated input type of CancelTimeOffRequest */
export type CancelTimeOffRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CancelTimeOffRequest. */
export type CancelTimeOffRequestPayload = {
  __typename?: 'CancelTimeOffRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffRequest?: Maybe<TimeOffRequest>;
};

export type Card = {
  __typename?: 'Card';
  billingDetails: BillingDetails;
  brand: Scalars['String'];
  createdAt: Scalars['ISO8601Date'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
  type: PaymentMethodType;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Types of workers at Check */
export enum CheckEmployment {
  /** contractor */
  Contractor = 'contractor',
  /** employee */
  Employee = 'employee'
}

/** Autogenerated input type of CheckForExistingUser */
export type CheckForExistingUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CheckForExistingUser. */
export type CheckForExistingUserPayload = {
  __typename?: 'CheckForExistingUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userExists?: Maybe<Scalars['Boolean']>;
};

/** Types of paystubs at Check */
export enum CheckPaystub {
  /** Employee paystub */
  EmployeePaystub = 'employee_paystub',
  /** Contractor payment */
  ContractorPayment = 'contractor_payment'
}

export type Client = {
  __typename?: 'Client';
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  directoryName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullyQualifiedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  lname?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Project>>;
  qboCustomer?: Maybe<QboCustomer>;
  quickbooksId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  syncedToQbo?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** The connection type for Client. */
export type ClientConnection = {
  __typename?: 'ClientConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Client>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ClientEdge = {
  __typename?: 'ClientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Client>;
};

/** Filters for clients */
export type ClientFiltersInput = {
  syncedToQbo?: Maybe<Scalars['Boolean']>;
  searchString?: Maybe<Scalars['String']>;
  nonQboOnly?: Maybe<Scalars['Boolean']>;
  qboInstanceIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ClientStatus>>;
};

export type ClientList = {
  __typename?: 'ClientList';
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  directoryName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fullyQualifiedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  lname?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Project>>;
  qboCustomer?: Maybe<QboCustomer>;
  quickbooksId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  syncedToQbo?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** All available client status types */
export enum ClientStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Sort fields for clients */
export enum ClientsFieldInput {
  DirectoryName = 'DIRECTORY_NAME',
  OrganizationName = 'ORGANIZATION_NAME',
  LastName = 'LAST_NAME',
  Title = 'TITLE',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Active = 'ACTIVE',
  SortableName = 'SORTABLE_NAME'
}

export type ClientsSortInput = {
  field: ClientsFieldInput;
  direction: SortDirectionInput;
};

/** Autogenerated input type of CloneShowcaseTemplate */
export type CloneShowcaseTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

/** Autogenerated return type of CloneShowcaseTemplate. */
export type CloneShowcaseTemplatePayload = {
  __typename?: 'CloneShowcaseTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type Comment = {
  __typename?: 'Comment';
  createdAt: Scalars['ISO8601Date'];
  description: Scalars['String'];
  id: Scalars['ID'];
  profile: Profile;
  task: Array<Task>;
};

export type Company = {
  __typename?: 'Company';
  document: PayrollCompanyDocument;
  documents: PayrollCompanyDocuments;
  employee: Employee;
  employees: Array<Employee>;
  info: PayrollCompanyInfo;
  links: CompanyEmployeeLinks;
  /** Returns an org's next payday, if applicable */
  nextPayday?: Maybe<PaySchedulePayday>;
  paySchedule?: Maybe<PaySchedule>;
  /** Returns an org's Check Paydays, if applicable, with an optional start date */
  paydays: Array<PaydayInfo>;
  payrolls: Payrolls;
  /** Returns a company's payroll summmary report in csv format */
  report: Report;
  worker: Worker;
  workers: Array<Worker>;
  workplaces: Array<Workplace>;
};


export type CompanyEmployeeArgs = {
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
};


export type CompanyPaydaysArgs = {
  startDate?: Maybe<Scalars['ISO8601Date']>;
};


export type CompanyReportArgs = {
  category: ReportCategory;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
};


export type CompanyWorkerArgs = {
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
};


export type CompanyWorkersArgs = {
  status?: Maybe<ProfileStatus>;
};

export type CompanyEmployeeLinks = {
  __typename?: 'CompanyEmployeeLinks';
  onboarding: Scalars['String'];
  runPayroll: Scalars['String'];
};

export type Compensation = {
  __typename?: 'Compensation';
  effectiveOn: Scalars['ISO8601Date'];
  effectiveUntil?: Maybe<Scalars['ISO8601Date']>;
  hoursPerWeek: Scalars['Float'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
  payrollType: PayrollTypeEnum;
  rate: Scalars['Float'];
};

/** Autogenerated input type of ConfigureInvoiceReminder */
export type ConfigureInvoiceReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  sendOnDueDate?: Maybe<Scalars['Boolean']>;
  daysBeforeDueDate?: Maybe<Scalars['Int']>;
  daysAfterDueDate?: Maybe<Scalars['Int']>;
  emailAddresses?: Maybe<Array<Scalars['String']>>;
  emailAddressesCc?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ConfigureInvoiceReminder. */
export type ConfigureInvoiceReminderPayload = {
  __typename?: 'ConfigureInvoiceReminderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated input type of ConfirmUserEmail */
export type ConfirmUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ConfirmUserEmail. */
export type ConfirmUserEmailPayload = {
  __typename?: 'ConfirmUserEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ConnectSubscription */
export type ConnectSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  planType: Scalars['String'];
  planName: Scalars['String'];
  stripeId: Scalars['String'];
};

/** Autogenerated return type of ConnectSubscription. */
export type ConnectSubscriptionPayload = {
  __typename?: 'ConnectSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** All available consultant types */
export enum Consultant {
  Project = 'PROJECT',
  Phase = 'PHASE'
}

export type ConsultantBill = {
  __typename?: 'ConsultantBill';
  billAmount: Scalars['Float'];
  billNumber?: Maybe<Scalars['String']>;
  consultantCompany: ConsultantCompany;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  invoiceAttachment?: Maybe<InvoiceAttachment>;
  issueDate: Scalars['ISO8601Date'];
  notes?: Maybe<Scalars['String']>;
  paidAmount: Scalars['Float'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
  pdfLink: Scalars['String'];
  phases?: Maybe<Array<Phase>>;
  project?: Maybe<Project>;
  quickbooksBillCategoryId?: Maybe<Scalars['String']>;
  quickbooksBillId?: Maybe<Scalars['String']>;
  status: ConsultantBillStatus;
};

/** The connection type for ConsultantBill. */
export type ConsultantBillConnection = {
  __typename?: 'ConsultantBillConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConsultantBillEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ConsultantBill>>>;
  owedAmount: Scalars['Float'];
  owedCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  paidAmount: Scalars['Float'];
  paidCount: Scalars['Int'];
  totalBilled: Scalars['Float'];
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ConsultantBillEdge = {
  __typename?: 'ConsultantBillEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ConsultantBill>;
};

/** Filters for consultant bills */
export type ConsultantBillFiltersInput = {
  consultantCompanyId?: Maybe<Scalars['ID']>;
  consultantCompanyIds?: Maybe<Array<Scalars['ID']>>;
  issueDateRange?: Maybe<DateRangeInput>;
  projectId?: Maybe<Scalars['ID']>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ConsultantBillStatus>>;
  invoiceSlug?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of ConsultantBillMarkAsOpen */
export type ConsultantBillMarkAsOpenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ConsultantBillMarkAsOpen. */
export type ConsultantBillMarkAsOpenPayload = {
  __typename?: 'ConsultantBillMarkAsOpenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of ConsultantBillMarkAsPaid */
export type ConsultantBillMarkAsPaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of ConsultantBillMarkAsPaid. */
export type ConsultantBillMarkAsPaidPayload = {
  __typename?: 'ConsultantBillMarkAsPaidPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** All available ConsultantBill status types */
export enum ConsultantBillStatus {
  Open = 'OPEN',
  BilledClient = 'BILLED_CLIENT',
  ClientPaid = 'CLIENT_PAID',
  Paid = 'PAID'
}

/** Sort fields for consultant bills */
export enum ConsultantBillsFieldInput {
  BillNumber = 'BILL_NUMBER',
  Notes = 'NOTES',
  ClientInvoice = 'CLIENT_INVOICE',
  ConsultantCompany = 'CONSULTANT_COMPANY',
  Id = 'ID',
  PdfLink = 'PDF_LINK',
  BillAmount = 'BILL_AMOUNT',
  PaidAmount = 'PAID_AMOUNT',
  PaidDate = 'PAID_DATE',
  DueDate = 'DUE_DATE',
  IssueDate = 'ISSUE_DATE',
  ProjectName = 'PROJECT_NAME',
  Status = 'STATUS'
}

/** Autogenerated input type of ConsultantBills */
export type ConsultantBillsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions entries should satisfy */
  filters: ConsultantBillFiltersInput;
};

/** Autogenerated return type of ConsultantBills. */
export type ConsultantBillsPayload = {
  __typename?: 'ConsultantBillsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ConsultantBillsSortInput = {
  field: ConsultantBillsFieldInput;
  direction: SortDirectionInput;
};

export type ConsultantCompany = {
  __typename?: 'ConsultantCompany';
  active?: Maybe<Scalars['Boolean']>;
  consultantContacts?: Maybe<Array<ConsultantContact>>;
  consultantServiceDefaults?: Maybe<Array<ConsultantServiceDefault>>;
  consultantServiceNames?: Maybe<Scalars['String']>;
  consultantTemplateIds?: Maybe<Array<Scalars['String']>>;
  consultingWork?: Maybe<Array<ConsultingWork>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  primaryAddressDisplayLabel?: Maybe<Scalars['String']>;
  primaryConsultantContact?: Maybe<ConsultantContact>;
  qboVendor?: Maybe<QuickbooksVendor>;
  quickbooksVendorId?: Maybe<Scalars['String']>;
  secondaryAddress?: Maybe<Address>;
  secondaryAddressDisplayLabel?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  syncedToQbo?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Scalars['String']>;
};

export type ConsultantContact = {
  __typename?: 'ConsultantContact';
  active?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySlug?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
  consultantServiceDefaults?: Maybe<Array<ConsultantServiceDefault>>;
  consultantServiceNames?: Maybe<Scalars['String']>;
  consultantTemplateId?: Maybe<Scalars['String']>;
  consultingWorks?: Maybe<Array<ConsultingWork>>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
  lname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ConsultantServiceDefault = {
  __typename?: 'ConsultantServiceDefault';
  consultantTemplate: ConsultantTemplate;
  id: Scalars['ID'];
};

export type ConsultantTemplate = {
  __typename?: 'ConsultantTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  quickbooksItem?: Maybe<QuickbooksItem>;
};

export type ConsultantWithTemplateNames = {
  __typename?: 'ConsultantWithTemplateNames';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
};

export type ConsultingWork = {
  __typename?: 'ConsultingWork';
  consultantBills?: Maybe<Array<ConsultantBill>>;
  consultantCompany?: Maybe<ConsultantCompany>;
  consultantContact?: Maybe<ConsultantContact>;
  consultantReferenceType?: Maybe<ConsultingWorkReference>;
  id: Scalars['ID'];
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
};

/** The type of reference for a consulting work */
export enum ConsultingWorkReference {
  /** Consultant Company */
  ConsultantCompany = 'ConsultantCompany',
  /** Consultant Contact */
  ConsultantContact = 'ConsultantContact'
}

export type Contractor = {
  __typename?: 'Contractor';
  bankAccounts: Array<PayrollBankAccount>;
  document: PayrollEmployeeDocument;
  documents: PayrollEmployeeDocuments;
  info: PayrollWorkerInfo;
  links: PayrollEmployeeLinks;
};

export type ContractorPayment = {
  __typename?: 'ContractorPayment';
  amount: Scalars['String'];
  contractor: Scalars['String'];
  netPay: Scalars['String'];
  paymentMethod: Scalars['String'];
  reimbursementAmount: Scalars['String'];
};

/** Autogenerated input type of CopyAllocationsFromPreviousWeek */
export type CopyAllocationsFromPreviousWeekInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  destinationDate: Scalars['String'];
  profileIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of CopyAllocationsFromPreviousWeek. */
export type CopyAllocationsFromPreviousWeekPayload = {
  __typename?: 'CopyAllocationsFromPreviousWeekPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroups: Array<EntryGroup>;
};

/** Autogenerated input type of CopyTimeOffHolidaysYear */
export type CopyTimeOffHolidaysYearInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  sourceYear: Scalars['Int'];
  destinationYear: Scalars['Int'];
  timeOffHolidayPolicyId: Scalars['ID'];
};

/** Autogenerated return type of CopyTimeOffHolidaysYear. */
export type CopyTimeOffHolidaysYearPayload = {
  __typename?: 'CopyTimeOffHolidaysYearPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffHolidayPolicy?: Maybe<TimeOffHolidayPolicy>;
};

/** All available cost categories */
export enum CostCategory {
  ProjectCosts = 'project_costs',
  Wages = 'wages',
  LaborRelated = 'labor_related',
  OperatingCosts = 'operating_costs',
  Consultants = 'consultants',
  NonOperatingCosts = 'non_operating_costs'
}

export type CostUpdateMapping = {
  costId: Scalars['ID'];
  costCategory: CostCategory;
  projectId?: Maybe<Scalars['ID']>;
};

/** All available sorts for costs */
export enum CostsFieldInput {
  Name = 'NAME',
  Account = 'ACCOUNT',
  Vendor = 'VENDOR',
  Date = 'DATE',
  Amount = 'AMOUNT'
}

/** Filters for costs */
export type CostsFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
  costCategory?: Maybe<Array<CostCategory>>;
  qboAccountIds?: Maybe<Array<Scalars['ID']>>;
  qboVendorIds?: Maybe<Array<Scalars['ID']>>;
  qboCustomerIds?: Maybe<Array<Scalars['ID']>>;
  unassigned?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type CostsSortInput = {
  field: CostsFieldInput;
  direction: SortDirectionInput;
};

/** Autogenerated input type of CreateAccountMutation */
export type CreateAccountMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** User's e-mail address */
  email: Scalars['String'];
  /** User's first name */
  fname?: Maybe<Scalars['String']>;
  /** User's last name */
  lname?: Maybe<Scalars['String']>;
  /** The name of the new organization */
  organizationName: Scalars['String'];
  /** The organizaiton's sub-industry */
  subIndustry?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAccountMutation. */
export type CreateAccountMutationPayload = {
  __typename?: 'CreateAccountMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization: Organization;
  profile: Profile;
  user: User;
};

/** Autogenerated input type of CreateActivity */
export type CreateActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isBillable: Scalars['Boolean'];
};

/** Autogenerated return type of CreateActivity. */
export type CreateActivityPayload = {
  __typename?: 'CreateActivityPayload';
  activity?: Maybe<Activity>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateAdminTimeOffAccrual */
export type CreateAdminTimeOffAccrualInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  assignmentId: Scalars['ID'];
  hours: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAdminTimeOffAccrual. */
export type CreateAdminTimeOffAccrualPayload = {
  __typename?: 'CreateAdminTimeOffAccrualPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicyAssignment?: Maybe<TimeOffPolicyAssignment>;
};

/** Autogenerated input type of CreateAssistantMessage */
export type CreateAssistantMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  /** Skip LLM processing if dev mode is true */
  devMode?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateAssistantMessage. */
export type CreateAssistantMessagePayload = {
  __typename?: 'CreateAssistantMessagePayload';
  assistantMessage: AssistantMessage;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCategory */
export type CreateCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateCategory. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateClientAccount */
export type CreateClientAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fname: Scalars['String'];
  lname: Scalars['String'];
  paymentUuid?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateClientAccount. */
export type CreateClientAccountPayload = {
  __typename?: 'CreateClientAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Autogenerated input type of CreateClientLoginLink */
export type CreateClientLoginLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  paymentUuid?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateClientLoginLink. */
export type CreateClientLoginLinkPayload = {
  __typename?: 'CreateClientLoginLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Autogenerated input type of CreateComment */
export type CreateCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  taskId: Scalars['ID'];
  description: Scalars['String'];
};

/** Autogenerated return type of CreateComment. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
};

/** Autogenerated input type of CreateCompensation */
export type CreateCompensationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  effectiveOn: Scalars['ISO8601Date'];
  payrollType: PayrollTypeEnum;
  rate?: Maybe<Scalars['Float']>;
  hoursPerWeek?: Maybe<Scalars['Float']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of CreateCompensation. */
export type CreateCompensationPayload = {
  __typename?: 'CreateCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  compensation?: Maybe<Compensation>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateConsultantBill */
export type CreateConsultantBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  pdfLink: Scalars['String'];
  consultantCompanyId: Scalars['ID'];
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  issueDate?: Maybe<Scalars['ISO8601Date']>;
  notes?: Maybe<Scalars['String']>;
  billNumber?: Maybe<Scalars['String']>;
  billAmount: Scalars['Float'];
  projectId: Scalars['ID'];
  quickbooksBillCategoryId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateConsultantBill. */
export type CreateConsultantBillPayload = {
  __typename?: 'CreateConsultantBillPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of CreateConsultantTemplate */
export type CreateConsultantTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quickbooksItemId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateConsultantTemplate. */
export type CreateConsultantTemplatePayload = {
  __typename?: 'CreateConsultantTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantTemplate?: Maybe<ConsultantTemplate>;
};

/** Autogenerated input type of CreateEmailLinkAndSendToUser */
export type CreateEmailLinkAndSendToUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of CreateEmailLinkAndSendToUser. */
export type CreateEmailLinkAndSendToUserPayload = {
  __typename?: 'CreateEmailLinkAndSendToUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of CreateEntryGroup */
export type CreateEntryGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetId: Scalars['ID'];
  roleId?: Maybe<Scalars['ID']>;
  phaseId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateEntryGroup. */
export type CreateEntryGroupPayload = {
  __typename?: 'CreateEntryGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroup?: Maybe<EntryGroup>;
};

/** Autogenerated input type of CreateEntryGroupWithOverhead */
export type CreateEntryGroupWithOverheadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetId: Scalars['ID'];
  overheadId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateEntryGroupWithOverhead. */
export type CreateEntryGroupWithOverheadPayload = {
  __typename?: 'CreateEntryGroupWithOverheadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroup?: Maybe<EntryGroup>;
};

/** Autogenerated input type of CreateEntry */
export type CreateEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  phaseId: Scalars['ID'];
  roleId?: Maybe<Scalars['ID']>;
  entryRowId?: Maybe<Scalars['ID']>;
  activityId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateEntry. */
export type CreateEntryPayload = {
  __typename?: 'CreateEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
  entryRow?: Maybe<EntryRow>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated input type of CreateEntryRow */
export type CreateEntryRowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroupId: Scalars['ID'];
  activityId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateEntryRow. */
export type CreateEntryRowPayload = {
  __typename?: 'CreateEntryRowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRow?: Maybe<EntryRow>;
};

/** Autogenerated input type of CreateEntryWithOverhead */
export type CreateEntryWithOverheadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  overheadId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateEntryWithOverhead. */
export type CreateEntryWithOverheadPayload = {
  __typename?: 'CreateEntryWithOverheadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
  entryRow?: Maybe<EntryRow>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated input type of CreateExpenseCategory */
export type CreateExpenseCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  markupPercentage: Scalars['Float'];
  qboExpenseAccountId?: Maybe<Scalars['ID']>;
  qboItemMapping?: Maybe<Array<ExpenseCategoryQboItemMapping>>;
};

/** Autogenerated return type of CreateExpenseCategory. */
export type CreateExpenseCategoryPayload = {
  __typename?: 'CreateExpenseCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of CreateExpense */
export type CreateExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  entryRowId: Scalars['ID'];
  entryDate: Scalars['ISO8601Date'];
  expenseCategoryId: Scalars['ID'];
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  isBillable?: Maybe<Scalars['Boolean']>;
  isReimbursable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateExpense. */
export type CreateExpensePayload = {
  __typename?: 'CreateExpensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expense?: Maybe<Expense>;
};

/** Autogenerated input type of CreateExpenseRate */
export type CreateExpenseRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rate: Scalars['Float'];
  expenseCategoryId: Scalars['ID'];
};

/** Autogenerated return type of CreateExpenseRate. */
export type CreateExpenseRatePayload = {
  __typename?: 'CreateExpenseRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseRate?: Maybe<ExpenseRate>;
};

/** Autogenerated input type of CreateExpenseV2 */
export type CreateExpenseV2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  phaseId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  overheadId?: Maybe<Scalars['ID']>;
  expenseCategoryId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  isBillable?: Maybe<Scalars['Boolean']>;
  isReimbursable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateExpenseV2. */
export type CreateExpenseV2Payload = {
  __typename?: 'CreateExpenseV2Payload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expense?: Maybe<Expense>;
};

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  type: PlanableTypes;
  slug: Scalars['String'];
  templateId: Scalars['ID'];
  budget?: Maybe<Scalars['Float']>;
  markup?: Maybe<Scalars['Float']>;
  consultantCompanyId?: Maybe<Scalars['ID']>;
  consultantContactId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateMilestone */
export type CreateMilestoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  dueDate: Scalars['ISO8601Date'];
  phaseId: Scalars['ID'];
};

/** Autogenerated return type of CreateMilestone. */
export type CreateMilestonePayload = {
  __typename?: 'CreateMilestonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  milestone: Milestone;
};

/** Autogenerated input type of CreateOffCyclePayroll */
export type CreateOffCyclePayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  periodStart: Scalars['ISO8601Date'];
  periodEnd: Scalars['ISO8601Date'];
  payday: Scalars['ISO8601Date'];
  forceSupplementalWithholding: Scalars['Boolean'];
  applyBenefits: Scalars['Boolean'];
  applyPostTaxDeductions: Scalars['Boolean'];
};

/** Autogenerated return type of CreateOffCyclePayroll. */
export type CreateOffCyclePayrollPayload = {
  __typename?: 'CreateOffCyclePayrollPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payrollId: Scalars['ID'];
  runPayrollLink: Scalars['String'];
};

/** Autogenerated input type of CreateOverhead */
export type CreateOverheadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  availableHours: Scalars['Boolean'];
};

/** Autogenerated return type of CreateOverhead. */
export type CreateOverheadPayload = {
  __typename?: 'CreateOverheadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  overhead?: Maybe<Overhead>;
};

/** Autogenerated input type of CreatePaySchedule */
export type CreatePayScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payFrequency: PayFrequency;
  firstPayday: Scalars['ISO8601Date'];
  firstPayPeriodEnd: Scalars['ISO8601Date'];
};

/** Autogenerated return type of CreatePaySchedule. */
export type CreatePaySchedulePayload = {
  __typename?: 'CreatePaySchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paySchedule: PaySchedule;
};

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectConsultant?: Maybe<ProjectConsultant>;
};

/** Autogenerated input type of CreatePaymentMethodSetupIntent */
export type CreatePaymentMethodSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentMethodTypes: Array<PaymentMethodType>;
};

/** Autogenerated return type of CreatePaymentMethodSetupIntent. */
export type CreatePaymentMethodSetupIntentPayload = {
  __typename?: 'CreatePaymentMethodSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  setupIntent: StripeSetupIntent;
};

/** Autogenerated input type of CreatePayrollCompany */
export type CreatePayrollCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  legalName: Scalars['String'];
  address: PayrollAddressInput;
};

/** Autogenerated return type of CreatePayrollCompany. */
export type CreatePayrollCompanyPayload = {
  __typename?: 'CreatePayrollCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company: Company;
};

/** Autogenerated input type of CreatePayrollContractor */
export type CreatePayrollContractorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: PayrollAddressInput;
};

/** Autogenerated return type of CreatePayrollContractor. */
export type CreatePayrollContractorPayload = {
  __typename?: 'CreatePayrollContractorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contractor: Contractor;
};

/** Autogenerated input type of CreatePayrollEmployeeEarningRate */
export type CreatePayrollEmployeeEarningRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  period?: Maybe<EarningRatePeriod>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePayrollEmployeeEarningRate. */
export type CreatePayrollEmployeeEarningRatePayload = {
  __typename?: 'CreatePayrollEmployeeEarningRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worker: Worker;
};

/** Autogenerated input type of CreatePayrollEmployee */
export type CreatePayrollEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dob: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
  residence: PayrollAddressInput;
  workplaceId: Scalars['ID'];
};

/** Autogenerated return type of CreatePayrollEmployee. */
export type CreatePayrollEmployeePayload = {
  __typename?: 'CreatePayrollEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  employee: Employee;
};

/** Autogenerated input type of CreatePayroll */
export type CreatePayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePayroll. */
export type CreatePayrollPayload = {
  __typename?: 'CreatePayrollPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payrollId: Scalars['ID'];
  runPayrollLink: Scalars['String'];
};

/** Autogenerated input type of CreatePayrollWaitlister */
export type CreatePayrollWaitlisterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

/** Autogenerated return type of CreatePayrollWaitlister. */
export type CreatePayrollWaitlisterPayload = {
  __typename?: 'CreatePayrollWaitlisterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payrollWaitlister?: Maybe<PayrollWaitlister>;
};

/** Autogenerated input type of CreatePayrollWorkplace */
export type CreatePayrollWorkplaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: PayrollAddressInput;
};

/** Autogenerated return type of CreatePayrollWorkplace. */
export type CreatePayrollWorkplacePayload = {
  __typename?: 'CreatePayrollWorkplacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  workplace: Workplace;
};

/** Autogenerated input type of CreatePhaseFromTemplate */
export type CreatePhaseFromTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  phaseTemplateId: Scalars['ID'];
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['ID']>;
  projectTemplateId?: Maybe<Scalars['ID']>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
  feeType?: Maybe<PhaseFee>;
};

/** Autogenerated return type of CreatePhaseFromTemplate. */
export type CreatePhaseFromTemplatePayload = {
  __typename?: 'CreatePhaseFromTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase: Phase;
};

/** Autogenerated input type of CreatePhase */
export type CreatePhaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  feeType: PhaseFee;
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['ID']>;
  projectTemplateId?: Maybe<Scalars['ID']>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreatePhase. */
export type CreatePhasePayload = {
  __typename?: 'CreatePhasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase: Phase;
};

/** Autogenerated input type of CreatePhaseTemplate */
export type CreatePhaseTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
  qboItemIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreatePhaseTemplate. */
export type CreatePhaseTemplatePayload = {
  __typename?: 'CreatePhaseTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phaseTemplate?: Maybe<PhaseTemplate>;
};

/** Autogenerated input type of CreatePolicy */
export type CreatePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreatePolicy. */
export type CreatePolicyPayload = {
  __typename?: 'CreatePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
};

/** Autogenerated input type of CreateProfile */
export type CreateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  sendInvitation?: Maybe<Scalars['Boolean']>;
  defaultRoleTemplateId?: Maybe<Scalars['ID']>;
  defaultRateTableId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateProfile. */
export type CreateProfilePayload = {
  __typename?: 'CreateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of CreateProjectFromTemplate */
export type CreateProjectFromTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  projectTemplateId: Scalars['ID'];
  budget?: Maybe<Scalars['Float']>;
  consultantsFee?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProjectFromTemplate. */
export type CreateProjectFromTemplatePayload = {
  __typename?: 'CreateProjectFromTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of CreateProject */
export type CreateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  budget?: Scalars['Float'];
  color?: Maybe<Scalars['String']>;
  consultantsFee?: Scalars['Float'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProject. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of CreateProjectTemplate */
export type CreateProjectTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  budget?: Scalars['Float'];
  color?: Maybe<Scalars['String']>;
  consultantsFee?: Scalars['Float'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProjectTemplate. */
export type CreateProjectTemplatePayload = {
  __typename?: 'CreateProjectTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectTemplate?: Maybe<ProjectTemplate>;
};

/** Autogenerated input type of CreateRate */
export type CreateRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  effectiveOn: Scalars['ISO8601Date'];
  rateTableId: Scalars['ID'];
  amount: Scalars['Float'];
};

/** Autogenerated return type of CreateRate. */
export type CreateRatePayload = {
  __typename?: 'CreateRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rate: Rate;
};

/** Autogenerated input type of CreateRateTable */
export type CreateRateTableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  initialRateAmount: Scalars['Float'];
  roleTemplateId: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateRateTable. */
export type CreateRateTablePayload = {
  __typename?: 'CreateRateTablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rateTable: RateTable;
};

/** Autogenerated input type of CreateRole */
export type CreateRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplateId: Scalars['ID'];
  phaseId: Scalars['ID'];
  rate?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ID']>;
  percent?: Maybe<Scalars['Float']>;
  rateTableId?: Maybe<Scalars['ID']>;
  setAsDefaultRole?: Maybe<Scalars['Boolean']>;
  shiftPhaseStartDate?: Maybe<Scalars['Boolean']>;
  shiftPhaseEndDate?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateRole. */
export type CreateRolePayload = {
  __typename?: 'CreateRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase: Phase;
  role: Role;
};

/** Autogenerated input type of CreateRoleTemplateAndRate */
export type CreateRoleTemplateAndRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  templateName: Scalars['String'];
  rateName: Scalars['String'];
  rate: Scalars['Float'];
};

/** Autogenerated return type of CreateRoleTemplateAndRate. */
export type CreateRoleTemplateAndRatePayload = {
  __typename?: 'CreateRoleTemplateAndRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplate?: Maybe<RoleTemplate>;
};

/** Autogenerated input type of CreateSalesforceLead */
export type CreateSalesforceLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  leadSource?: Maybe<Scalars['String']>;
  selfServe?: Maybe<Scalars['String']>;
  icpFit?: Maybe<Scalars['String']>;
  subIndustry?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSalesforceLead. */
export type CreateSalesforceLeadPayload = {
  __typename?: 'CreateSalesforceLeadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSignatoryItemAndOnboardingLink */
export type CreateSignatoryItemAndOnboardingLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

/** Autogenerated return type of CreateSignatoryItemAndOnboardingLink. */
export type CreateSignatoryItemAndOnboardingLinkPayload = {
  __typename?: 'CreateSignatoryItemAndOnboardingLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  onboardingLink?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateStaffingRoleAllocation */
export type CreateStaffingRoleAllocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  allocation: Scalars['Float'];
  beginDate: Scalars['String'];
  roleId: Scalars['ID'];
};

/** Autogenerated return type of CreateStaffingRoleAllocation. */
export type CreateStaffingRoleAllocationPayload = {
  __typename?: 'CreateStaffingRoleAllocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['ISO8601Date'];
  milestoneId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phaseId: Scalars['ID'];
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
};

/** Autogenerated input type of CreateTaxRate */
export type CreateTaxRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  percent: Scalars['Float'];
};

/** Autogenerated return type of CreateTaxRate. */
export type CreateTaxRatePayload = {
  __typename?: 'CreateTaxRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  taxRate: TaxRate;
};

/** Autogenerated input type of CreateTemplateFromProject */
export type CreateTemplateFromProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  name: Scalars['String'];
  isBillable: Scalars['Boolean'];
  color: Scalars['String'];
  number: Scalars['String'];
  budget?: Maybe<Scalars['Float']>;
  consultantsFee?: Maybe<Scalars['Float']>;
  totalFee?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTemplateFromProject. */
export type CreateTemplateFromProjectPayload = {
  __typename?: 'CreateTemplateFromProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  template?: Maybe<ProjectTemplate>;
};

/** Autogenerated input type of CreateTerm */
export type CreateTermInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  days: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of CreateTerm. */
export type CreateTermPayload = {
  __typename?: 'CreateTermPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  term?: Maybe<Term>;
};

/** Autogenerated input type of CreateTimeOffHoliday */
export type CreateTimeOffHolidayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  timeOffHolidayPolicyId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  standardHolidayId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateTimeOffHoliday. */
export type CreateTimeOffHolidayPayload = {
  __typename?: 'CreateTimeOffHolidayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffHolidayPolicy: TimeOffHolidayPolicy;
};

/** Autogenerated input type of CreateTimeOffHolidayPolicy */
export type CreateTimeOffHolidayPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overheadId: Scalars['ID'];
  standardHolidayIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateTimeOffHolidayPolicy. */
export type CreateTimeOffHolidayPolicyPayload = {
  __typename?: 'CreateTimeOffHolidayPolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffHolidayPolicy?: Maybe<TimeOffHolidayPolicy>;
};

/** Autogenerated input type of CreateTimeOffPolicy */
export type CreateTimeOffPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  timeOffType: TimeOffType;
  overheadId: Scalars['ID'];
  accrualMethod: TimeOffAccrualMethod;
  autoApprove?: Maybe<Scalars['Boolean']>;
  earnedRate?: Maybe<TimeOffEarnedRate>;
  hoursPerYear?: Maybe<Scalars['Float']>;
  maxBalance?: Maybe<Scalars['Float']>;
  annualRolloverLimit?: Maybe<Scalars['Float']>;
  reviewRecipientIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateTimeOffPolicy. */
export type CreateTimeOffPolicyPayload = {
  __typename?: 'CreateTimeOffPolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicy?: Maybe<TimeOffPolicy>;
};

export type CreateTimeOffRequestDayInput = {
  date: Scalars['ISO8601Date'];
  hours: Scalars['Float'];
};

/** Autogenerated input type of CreateTimeOffRequest */
export type CreateTimeOffRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicyAssignmentId: Scalars['ID'];
  timeOffRequestDays: Array<CreateTimeOffRequestDayInput>;
  note?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTimeOffRequest. */
export type CreateTimeOffRequestPayload = {
  __typename?: 'CreateTimeOffRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffRequest?: Maybe<TimeOffRequest>;
};

/** Valid format types for timesheet CSVs */
export enum CsvFormat {
  /** dates_as_rows */
  DatesAsRows = 'DATES_AS_ROWS',
  /** dates_as_columns */
  DatesAsColumns = 'DATES_AS_COLUMNS'
}

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  decimalDigits?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerExternalDataProcessor = {
  __typename?: 'CustomerExternalDataProcessor';
  status: CustomerExternalDataProcessorStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** All available status types for the customer external data processor */
export enum CustomerExternalDataProcessorStatus {
  Enqueued = 'enqueued',
  Running = 'running',
  Succeeded = 'succeeded',
  Error = 'error'
}

export type CustomerImportMapping = {
  qboCustomerId: Scalars['ID'];
  monographClientId?: Maybe<Scalars['ID']>;
};

/** Available resolution strategies for importing QBO customers */
export enum CustomerImportResolution {
  /** use_qbo_records */
  UseQboRecords = 'USE_QBO_RECORDS',
  /** use_monograph_records */
  UseMonographRecords = 'USE_MONOGRAPH_RECORDS'
}

export type DashboardLink = {
  __typename?: 'DashboardLink';
  url?: Maybe<Scalars['String']>;
};

/** All available date increment types */
export enum DateIncrement {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER'
}

export type DateRange = {
  __typename?: 'DateRange';
  /** Begin date */
  beginDate: Scalars['ISO8601Date'];
  /** End date */
  endDate: Scalars['ISO8601Date'];
};

/** Date range input type */
export type DateRangeInput = {
  /** Begin date */
  beginDate: Scalars['ISO8601Date'];
  /** End date */
  endDate: Scalars['ISO8601Date'];
};

/** Autogenerated input type of DeactivateClient */
export type DeactivateClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeactivateClient. */
export type DeactivateClientPayload = {
  __typename?: 'DeactivateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeactivateConsultantCompany */
export type DeactivateConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeactivateConsultantCompany. */
export type DeactivateConsultantCompanyPayload = {
  __typename?: 'DeactivateConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of DeactivateConsultantContact */
export type DeactivateConsultantContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeactivateConsultantContact. */
export type DeactivateConsultantContactPayload = {
  __typename?: 'DeactivateConsultantContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantContact?: Maybe<ConsultantContact>;
};

/** Autogenerated input type of DeactivateRateTable */
export type DeactivateRateTableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeactivateRateTable. */
export type DeactivateRateTablePayload = {
  __typename?: 'DeactivateRateTablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rateTable: RateTable;
};

/** Autogenerated input type of DeactivateTimeOffPolicy */
export type DeactivateTimeOffPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeactivateTimeOffPolicy. */
export type DeactivateTimeOffPolicyPayload = {
  __typename?: 'DeactivateTimeOffPolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicy?: Maybe<TimeOffPolicy>;
};

/** Autogenerated input type of DeleteActivity */
export type DeleteActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteActivity. */
export type DeleteActivityPayload = {
  __typename?: 'DeleteActivityPayload';
  activity?: Maybe<Activity>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteCategory */
export type DeleteCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCategory. */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteClient */
export type DeleteClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteClient. */
export type DeleteClientPayload = {
  __typename?: 'DeleteClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteComment */
export type DeleteCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteComment. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment: Comment;
};

/** Autogenerated input type of DeleteCompensation */
export type DeleteCompensationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCompensation. */
export type DeleteCompensationPayload = {
  __typename?: 'DeleteCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  compensation?: Maybe<Compensation>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of DeleteConsultantBill */
export type DeleteConsultantBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteConsultantBill. */
export type DeleteConsultantBillPayload = {
  __typename?: 'DeleteConsultantBillPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of DeleteConsultantCompany */
export type DeleteConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteConsultantCompany. */
export type DeleteConsultantCompanyPayload = {
  __typename?: 'DeleteConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of DeleteConsultantContact */
export type DeleteConsultantContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteConsultantContact. */
export type DeleteConsultantContactPayload = {
  __typename?: 'DeleteConsultantContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantContact?: Maybe<ConsultantContact>;
};

/** Autogenerated input type of DeleteConsultantTemplate */
export type DeleteConsultantTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteConsultantTemplate. */
export type DeleteConsultantTemplatePayload = {
  __typename?: 'DeleteConsultantTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantTemplate?: Maybe<ConsultantTemplate>;
};

/** Autogenerated input type of DeleteCosts */
export type DeleteCostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  costIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of DeleteCosts. */
export type DeleteCostsPayload = {
  __typename?: 'DeleteCostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of DeleteEntryGroup */
export type DeleteEntryGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteEntryGroup. */
export type DeleteEntryGroupPayload = {
  __typename?: 'DeleteEntryGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroup?: Maybe<EntryGroup>;
};

/** Autogenerated input type of DeleteEntryRow */
export type DeleteEntryRowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteEntryRow. */
export type DeleteEntryRowPayload = {
  __typename?: 'DeleteEntryRowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRow?: Maybe<EntryRow>;
};

/** Autogenerated input type of DeleteExpenseCategory */
export type DeleteExpenseCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteExpenseCategory. */
export type DeleteExpenseCategoryPayload = {
  __typename?: 'DeleteExpenseCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of DeleteExpense */
export type DeleteExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteExpense. */
export type DeleteExpensePayload = {
  __typename?: 'DeleteExpensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expense?: Maybe<Expense>;
};

/** Autogenerated input type of Delete */
export type DeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteMilestone */
export type DeleteMilestoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteMilestone. */
export type DeleteMilestonePayload = {
  __typename?: 'DeleteMilestonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  milestone: Milestone;
};

/** Autogenerated input type of DeleteOrganizationMutation */
export type DeleteOrganizationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteOrganizationMutation. */
export type DeleteOrganizationMutationPayload = {
  __typename?: 'DeleteOrganizationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of DeleteOverhead */
export type DeleteOverheadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteOverhead. */
export type DeleteOverheadPayload = {
  __typename?: 'DeleteOverheadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  overhead?: Maybe<Overhead>;
};

/** Autogenerated return type of Delete. */
export type DeletePayload = {
  __typename?: 'DeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectConsultant?: Maybe<ProjectConsultant>;
};

/** Autogenerated input type of DeletePaymentMethod */
export type DeletePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePaymentMethod. */
export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
};

/** Autogenerated input type of DeletePhase */
export type DeletePhaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePhase. */
export type DeletePhasePayload = {
  __typename?: 'DeletePhasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
};

/** Autogenerated input type of DeletePhaseTemplate */
export type DeletePhaseTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePhaseTemplate. */
export type DeletePhaseTemplatePayload = {
  __typename?: 'DeletePhaseTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phaseTemplate?: Maybe<PhaseTemplate>;
};

/** Autogenerated input type of DeletePolicy */
export type DeletePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeletePolicy. */
export type DeletePolicyPayload = {
  __typename?: 'DeletePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
};

/** Autogenerated input type of DeleteProfile */
export type DeleteProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteProfile. */
export type DeleteProfilePayload = {
  __typename?: 'DeleteProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of DeleteProject */
export type DeleteProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteProject. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of DeleteProjectTemplate */
export type DeleteProjectTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteProjectTemplate. */
export type DeleteProjectTemplatePayload = {
  __typename?: 'DeleteProjectTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectTemplate?: Maybe<ProjectTemplate>;
};

/** Autogenerated input type of DeleteRate */
export type DeleteRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteRate. */
export type DeleteRatePayload = {
  __typename?: 'DeleteRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rate?: Maybe<Rate>;
};

/** Autogenerated input type of DeleteRole */
export type DeleteRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  deleteUninvoicedProfileProjectRoles?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteRole. */
export type DeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
};

/** Autogenerated input type of DeleteTask */
export type DeleteTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTask. */
export type DeleteTaskPayload = {
  __typename?: 'DeleteTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  task: Task;
};

/** Autogenerated input type of DeleteTaxRate */
export type DeleteTaxRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTaxRate. */
export type DeleteTaxRatePayload = {
  __typename?: 'DeleteTaxRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  taxRate?: Maybe<TaxRate>;
};

/** Autogenerated input type of DeleteTerm */
export type DeleteTermInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTerm. */
export type DeleteTermPayload = {
  __typename?: 'DeleteTermPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  term?: Maybe<Term>;
};

/** Autogenerated input type of DeleteTimeOffHoliday */
export type DeleteTimeOffHolidayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTimeOffHoliday. */
export type DeleteTimeOffHolidayPayload = {
  __typename?: 'DeleteTimeOffHolidayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffHolidayPolicy?: Maybe<TimeOffHolidayPolicy>;
};

/** Autogenerated input type of DeleteTimeOffPolicyAssignment */
export type DeleteTimeOffPolicyAssignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  profileId: Scalars['ID'];
};

/** Autogenerated return type of DeleteTimeOffPolicyAssignment. */
export type DeleteTimeOffPolicyAssignmentPayload = {
  __typename?: 'DeleteTimeOffPolicyAssignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicy?: Maybe<TimeOffPolicy>;
};

/** Autogenerated input type of DeleteTimeOffRequest */
export type DeleteTimeOffRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteTimeOffRequest. */
export type DeleteTimeOffRequestPayload = {
  __typename?: 'DeleteTimeOffRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffRequest: TimeOffRequest;
};

/** Autogenerated input type of DetachClient */
export type DetachClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DetachClient. */
export type DetachClientPayload = {
  __typename?: 'DetachClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DetachConsultantCompany */
export type DetachConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DetachConsultantCompany. */
export type DetachConsultantCompanyPayload = {
  __typename?: 'DetachConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of DisconnectOrganizationQuickbooks */
export type DisconnectOrganizationQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DisconnectOrganizationQuickbooks. */
export type DisconnectOrganizationQuickbooksPayload = {
  __typename?: 'DisconnectOrganizationQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** All potential Check document types */
export enum DocumentCategory {
  CompanyTaxDocument = 'company_tax_document',
  CompanyAuthorizationDocument = 'company_authorization_document',
  EmployeeTaxDocument = 'employee_tax_document',
  ContractorTaxDocument = 'contractor_tax_document'
}

/** Autogenerated input type of DuplicatePolicy */
export type DuplicatePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of DuplicatePolicy. */
export type DuplicatePolicyPayload = {
  __typename?: 'DuplicatePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
};

/** Autogenerated input type of DuplicateProject */
export type DuplicateProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  totalFee?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of DuplicateProject. */
export type DuplicateProjectPayload = {
  __typename?: 'DuplicateProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of DuplicateRateTable */
export type DuplicateRateTableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of DuplicateRateTable. */
export type DuplicateRateTablePayload = {
  __typename?: 'DuplicateRateTablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rateTable: RateTable;
};

export type Earning = {
  __typename?: 'Earning';
  amount: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  type: Scalars['String'];
  workplace: Scalars['String'];
};

/** All available Check earning rate periods */
export enum EarningRatePeriod {
  Annually = 'annually',
  Hourly = 'hourly',
  Piece = 'piece'
}

/** Autogenerated input type of EditComment */
export type EditCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  description: Scalars['String'];
};

/** Autogenerated return type of EditComment. */
export type EditCommentPayload = {
  __typename?: 'EditCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment: Comment;
};

/** Autogenerated input type of EditMilestone */
export type EditMilestoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  phaseId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of EditMilestone. */
export type EditMilestonePayload = {
  __typename?: 'EditMilestonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  milestone: Milestone;
};

/** Autogenerated input type of EditPaymentMethod */
export type EditPaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of EditPaymentMethod. */
export type EditPaymentMethodPayload = {
  __typename?: 'EditPaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
};

/** Autogenerated input type of EditRateTable */
export type EditRateTableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of EditRateTable. */
export type EditRateTablePayload = {
  __typename?: 'EditRateTablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rateTable: RateTable;
};

/** Autogenerated input type of EditTask */
export type EditTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  milestoneId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phaseId?: Maybe<Scalars['ID']>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of EditTask. */
export type EditTaskPayload = {
  __typename?: 'EditTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
};

/** Autogenerated input type of EditTimeOffRequest */
export type EditTimeOffRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  timeOffRequestDays?: Maybe<Array<CreateTimeOffRequestDayInput>>;
  approveOnSave?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EditTimeOffRequest. */
export type EditTimeOffRequestPayload = {
  __typename?: 'EditTimeOffRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffRequest: TimeOffRequest;
};

export type Employee = {
  __typename?: 'Employee';
  bankAccounts: Array<PayrollBankAccount>;
  document: PayrollEmployeeDocument;
  documents: PayrollEmployeeDocuments;
  earningRates: Array<PayrollEarningRate>;
  info: PayrollEmployeeInfo;
  links: PayrollEmployeeLinks;
};

/** All Check employee onboarding steps that may block or require attention */
export enum EmployeeOnboardingSteps {
  Ssn = 'ssn',
  CompanyDefinedAttributes = 'company_defined_attributes',
  Workplaces = 'workplaces',
  Withholdings = 'withholdings',
  PaymentMethod = 'payment_method'
}

/** Autogenerated input type of EnableMfa */
export type EnableMfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of EnableMfa. */
export type EnableMfaPayload = {
  __typename?: 'EnableMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  mfaQrCodeSvg: Scalars['String'];
};

/** All available sorts for entries */
export enum EntriesFieldInput {
  ProfileName = 'PROFILE_NAME',
  RoleName = 'ROLE_NAME',
  RoleRate = 'ROLE_RATE',
  Date = 'DATE',
  Notes = 'NOTES',
  Hours = 'HOURS',
  Amount = 'AMOUNT',
  ProjectName = 'PROJECT_NAME',
  ProjectNumber = 'PROJECT_NUMBER',
  ActivityName = 'ACTIVITY_NAME',
  PhaseName = 'PHASE_NAME'
}

/** Autogenerated input type of Entries */
export type EntriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Intent for timelog query: global or project. */
  intent: TimeLogIntent;
  /** List of conditions entries should satisfy */
  filters: EntryFiltersInput;
};

/** Autogenerated return type of Entries. */
export type EntriesPayload = {
  __typename?: 'EntriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type EntriesSortInput = {
  field: EntriesFieldInput;
  direction: SortDirectionInput;
};

/** Entry represents a fact that some profile tracked working hours on a specific date for the project. */
export type Entry = {
  __typename?: 'Entry';
  activity?: Maybe<Activity>;
  amount?: Maybe<Scalars['Float']>;
  date: Scalars['ISO8601Date'];
  expenses?: Maybe<Array<Expense>>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  overhead?: Maybe<Overhead>;
  phase?: Maybe<Phase>;
  profile?: Maybe<Profile>;
  project?: Maybe<Project>;
  rate: Scalars['Float'];
  role?: Maybe<Role>;
  timesheet: Timesheet;
};

/** The connection type for Entry. */
export type EntryConnection = {
  __typename?: 'EntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Entry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalAmount: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalHours: Scalars['Float'];
};

/** An edge in a connection. */
export type EntryEdge = {
  __typename?: 'EntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Entry>;
};

/** Filters for entries */
export type EntryFiltersInput = {
  dateRange: DateRangeInput;
  isOverhead?: Maybe<Scalars['Boolean']>;
  /**
   * list of project ids to filter entries if intent is global timelog,
   *                                   for project time log, pass only 1 id
   */
  projectIds?: Maybe<Array<Scalars['ID']>>;
  /** boolean to indicate wether to include entries with no activities attached */
  noActivity?: Maybe<Scalars['Boolean']>;
  /**
   * boolean to indicate wether to include entries with no phase templates attached,
   *                ussually those are custom phases
   */
  noPhaseTemplate?: Maybe<Scalars['Boolean']>;
  /** boolean to indicate wether to include entries with no role attached */
  noRole?: Maybe<Scalars['Boolean']>;
  /** ignored when user has no permission to view timesheets */
  profileIds?: Maybe<Array<Scalars['ID']>>;
  /** list of activity IDs, use nil to get entries without activity */
  activityIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** list of phase IDs, use nil to get entries without phases */
  phaseIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** list of entry IDs, use nil to get all entries */
  entryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** list of phase template IDs, use nil to get entries without phase template */
  phaseTemplateIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** list of role IDs, use nil to get entries without roles */
  roleTemplateIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** boolean to indicate wether to exclude overhead entries or not */
  excludeOverhead?: Maybe<Scalars['Boolean']>;
  /** filter by activity type: billable, non-billable or all */
  activityType?: Maybe<Billable>;
  /** list of project category IDs */
  categoryIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EntryGroup = {
  __typename?: 'EntryGroup';
  allocatedByUser: Scalars['Boolean'];
  allocation: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  entries?: Maybe<Array<Entry>>;
  entryRows?: Maybe<Array<EntryRow>>;
  id: Scalars['ID'];
  overhead?: Maybe<Overhead>;
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
  role?: Maybe<Role>;
  timesheet: Timesheet;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type EntryRow = {
  __typename?: 'EntryRow';
  activity?: Maybe<Activity>;
  createdAt: Scalars['ISO8601DateTime'];
  entries?: Maybe<Array<Entry>>;
  entryGroup: EntryGroup;
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  timesheet: Timesheet;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of Example. */
export type ExamplePayload = {
  __typename?: 'ExamplePayload';
  results: Scalars['Int'];
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Float'];
  attachment?: Maybe<Scalars['String']>;
  billableAmount: Scalars['Float'];
  category: ExpenseCategory;
  date?: Maybe<Scalars['ISO8601Date']>;
  expenseRate?: Maybe<ExpenseRate>;
  id: Scalars['ID'];
  isBillable?: Maybe<Scalars['Boolean']>;
  isReimbursable: Scalars['Boolean'];
  markup: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  onOpenInvoice: Scalars['Boolean'];
  overhead?: Maybe<Overhead>;
  phase?: Maybe<Phase>;
  profile: Profile;
  project?: Maybe<Project>;
  qboBillId?: Maybe<Scalars['String']>;
  qboPurchaseId?: Maybe<Scalars['String']>;
  reimbursePaidDate?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated input type of ExpenseBatchSendToQuickbooks */
export type ExpenseBatchSendToQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseMappings: Array<ExpenseQboAccountMapping>;
};

/** Autogenerated return type of ExpenseBatchSendToQuickbooks. */
export type ExpenseBatchSendToQuickbooksPayload = {
  __typename?: 'ExpenseBatchSendToQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enqueued: Scalars['Boolean'];
};

export type ExpenseCategory = {
  __typename?: 'ExpenseCategory';
  /** Returns dependency counts for a given expense category */
  dependencyCounts: ExpenseCategoryDependencyCounts;
  expenseCategoryQboItems: Array<ExpenseCategoryQboItem>;
  expenseRates?: Maybe<Array<ExpenseRate>>;
  id: Scalars['ID'];
  mapToPhaseQbItem: Scalars['Boolean'];
  markupPercentage: Scalars['Float'];
  name: Scalars['String'];
  qboExpenseAccount?: Maybe<QboAccount>;
};

/** An object containing the total number of invoices and expenses that will be affected by removing an expense category. */
export type ExpenseCategoryDependencyCounts = {
  __typename?: 'ExpenseCategoryDependencyCounts';
  expenses: Scalars['Int'];
  invoices: Scalars['Int'];
};

export type ExpenseCategoryQboItem = {
  __typename?: 'ExpenseCategoryQBOItem';
  id: Scalars['ID'];
  mapToPhaseQbItem: Scalars['Boolean'];
  qboInstance: QboInstance;
  qboItem?: Maybe<QboItem>;
};

export type ExpenseCategoryQboItemMapping = {
  qboInstanceId: Scalars['ID'];
  qboItemId?: Maybe<Scalars['ID']>;
  mapToPhaseQbItem?: Maybe<Scalars['Boolean']>;
};

/** All available states of Expense type select */
export enum ExpenseCharge {
  Billable = 'billable',
  NonBillable = 'non_billable',
  Reimbursable = 'reimbursable',
  NonReimbursable = 'non_reimbursable'
}

export type ExpenseChargeType = {
  __typename?: 'ExpenseChargeType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Expense. */
export type ExpenseConnection = {
  __typename?: 'ExpenseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExpenseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Expense>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalAmount: Scalars['Float'];
  totalBillableAmount: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalMarkup: Scalars['Float'];
};

/** An edge in a connection. */
export type ExpenseEdge = {
  __typename?: 'ExpenseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Expense>;
};

/** Filters for expenses */
export type ExpenseFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
  /** list of project ids to filter expenses */
  projectIds?: Maybe<Array<Scalars['ID']>>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
  expenseCategoryIds?: Maybe<Array<Scalars['ID']>>;
  expenseChargeTypes?: Maybe<Array<ExpenseCharge>>;
  qboStatus?: Maybe<ExpenseQboStatus>;
  phaseIds?: Maybe<Array<Scalars['ID']>>;
  overheadIds?: Maybe<Array<Scalars['ID']>>;
  projectSlug?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ExpenseMarkReimburseAsPaid */
export type ExpenseMarkReimburseAsPaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of ExpenseMarkReimburseAsPaid. */
export type ExpenseMarkReimburseAsPaidPayload = {
  __typename?: 'ExpenseMarkReimburseAsPaidPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expense: Expense;
};

export type ExpenseQboAccountMapping = {
  expenseId: Scalars['ID'];
  qboExpenseAccountId: Scalars['ID'];
};

/** All available status types for expenses being sent to qbo */
export enum ExpenseQboStatus {
  Sent = 'sent',
  NotSent = 'not_sent'
}

export type ExpenseRate = {
  __typename?: 'ExpenseRate';
  expenseCategory: ExpenseCategory;
  id: Scalars['ID'];
  name: Scalars['String'];
  rate: Scalars['Float'];
};

/** All batch job status types */
export enum ExpenseToQboBatchJobStatus {
  Succeeded = 'succeeded',
  PartiallySucceeded = 'partially_succeeded',
  Failed = 'failed'
}

/** All available sorts for expenses */
export enum ExpensesFieldInput {
  Id = 'ID',
  ProfileName = 'PROFILE_NAME',
  Date = 'DATE',
  ProjectName = 'PROJECT_NAME',
  CategoryName = 'CATEGORY_NAME',
  Note = 'NOTE',
  IsBillable = 'IS_BILLABLE',
  IsReimbursable = 'IS_REIMBURSABLE',
  Attachment = 'ATTACHMENT',
  Logged = 'LOGGED',
  Markup = 'MARKUP',
  BillableAmount = 'BILLABLE_AMOUNT'
}

/** Autogenerated input type of Expenses */
export type ExpensesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions expenses should satisfy */
  filters: ExpenseFiltersInput;
};

/** Autogenerated return type of Expenses. */
export type ExpensesPayload = {
  __typename?: 'ExpensesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ExpensesSortInput = {
  field: ExpensesFieldInput;
  direction: SortDirectionInput;
};

export type ExpensesToQbo = {
  __typename?: 'ExpensesToQBO';
  itemFailures?: Maybe<Array<ExpensesToQboFailures>>;
  status?: Maybe<ExpenseToQboBatchJobStatus>;
};

export type ExpensesToQboFailures = {
  __typename?: 'ExpensesToQBOFailures';
  expense?: Maybe<Expense>;
  message: Scalars['String'];
  profile?: Maybe<Profile>;
  qboEntity: QboExpenseBatchErrorEntity;
};

export type ExportStatus = {
  __typename?: 'ExportStatus';
  downloadUrl?: Maybe<Scalars['String']>;
  kind: ExportStatusKindType;
  status: ExportStatusStatusType;
};

/** All ExportStatus kinds */
export enum ExportStatusKindType {
  ConsultantBills = 'CONSULTANT_BILLS',
  Entries = 'ENTRIES',
  Expenses = 'EXPENSES',
  ProfitDrivers = 'PROFIT_DRIVERS',
  ProfitForecast = 'PROFIT_FORECAST',
  ProfitQboVersion = 'PROFIT_QBO_VERSION',
  ProfitNonQboVersion = 'PROFIT_NON_QBO_VERSION',
  GroupedEntries = 'GROUPED_ENTRIES',
  Timesheet = 'TIMESHEET',
  ProfileTimesheets = 'PROFILE_TIMESHEETS',
  OrganizationTimesheets = 'ORGANIZATION_TIMESHEETS',
  OrganizationTimesheetsAjera = 'ORGANIZATION_TIMESHEETS_AJERA',
  Payments = 'PAYMENTS',
  PaymentsInvoices = 'PAYMENTS_INVOICES',
  PaymentsReceivables = 'PAYMENTS_RECEIVABLES',
  Payouts = 'PAYOUTS',
  ProjectsTimings = 'PROJECTS_TIMINGS',
  ProjectsFinancials = 'PROJECTS_FINANCIALS',
  ProjectsProfit = 'PROJECTS_PROFIT',
  Invoices = 'INVOICES',
  InvoiceQbo = 'INVOICE_QBO',
  InvoiceXero = 'INVOICE_XERO',
  Realization = 'REALIZATION',
  ReportTeamForecastV2 = 'REPORT_TEAM_FORECAST_V2',
  Transactions = 'TRANSACTIONS',
  TaxesCollected = 'TAXES_COLLECTED',
  UnbilledProjects = 'UNBILLED_PROJECTS',
  Utilization = 'UTILIZATION'
}

/** All ExportStatus statuses */
export enum ExportStatusStatusType {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

/** Autogenerated input type of ExportUnbilledProjectsReport */
export type ExportUnbilledProjectsReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for unbilled projects report */
  filters?: Maybe<ProjectsUnbilledFiltersInput>;
};

/** Autogenerated return type of ExportUnbilledProjectsReport. */
export type ExportUnbilledProjectsReportPayload = {
  __typename?: 'ExportUnbilledProjectsReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization: Organization;
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  aiAssistant: Scalars['Boolean'];
  ajeraRework: Scalars['Boolean'];
  bqeExport: Scalars['Boolean'];
  bulkEditTimesheets: Scalars['Boolean'];
  consultantsRework: Scalars['Boolean'];
  expenseRates: Scalars['Boolean'];
  expensesV2: Scalars['Boolean'];
  exportMigrationData: Scalars['Boolean'];
  hideEntryGroupsFromCompletedProj: Scalars['Boolean'];
  highValueInsights: Scalars['Boolean'];
  insightsDashboardV1: Scalars['Boolean'];
  invoicesV2: Scalars['Boolean'];
  killInvoicesV1: Scalars['Boolean'];
  modifyClient: Scalars['Boolean'];
  passConvenienceFeesV2: Scalars['Boolean'];
  payrollEnabled: Scalars['Boolean'];
  qboExpenses: Scalars['Boolean'];
  renewalPriceTesting: Scalars['Boolean'];
  roleRateSuperadmin: Scalars['Boolean'];
  staffingProjectView: Scalars['Boolean'];
  staffingToggleUnallocatedPhases: Scalars['Boolean'];
  staffingVisibility: Scalars['Boolean'];
  stripeSetupReminders: Scalars['Boolean'];
  teamForecastNextJs: Scalars['Boolean'];
  timeOffHolidays: Scalars['Boolean'];
  usesAccounting: Scalars['Boolean'];
  weeklyStaffingNextjs: Scalars['Boolean'];
};

export type FilterOption = {
  __typename?: 'FilterOption';
  label?: Maybe<Scalars['String']>;
  value: Scalars['ID'];
};

export type FilteredTotals = {
  __typename?: 'FilteredTotals';
  invoiced: TotalWithCountType;
  open: TotalWithCountType;
  paid: TotalWithCountType;
  pastDue: TotalWithCountType;
};

/** All available gantt chart assignment options */
export enum GanttAssigmentOption {
  AssignedToMe = 'assignedToMe',
  NotAssignedToMe = 'notAssignedToMe'
}

/** Filters for Gantt Chart */
export type GanttChartFiltersInput = {
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  projectStatuses?: Maybe<Array<ProjectStatus>>;
  assignmentOption?: Maybe<GanttAssigmentOption>;
  activePhaseTemplateIds?: Maybe<Array<Scalars['ID']>>;
  projectSlug?: Maybe<Scalars['String']>;
  /** Sort order for gantt chart */
  sortBy?: Maybe<GanttSortOption>;
};

export type GanttChartMilestone = {
  __typename?: 'GanttChartMilestone';
  completed: Scalars['Boolean'];
  date: Scalars['String'];
  name: Scalars['String'];
};

export type GanttChartPhase = {
  __typename?: 'GanttChartPhase';
  endDate: Scalars['String'];
  hoursConsumed: Scalars['Float'];
  hoursPlanned: Scalars['Float'];
  invoiced: Scalars['Float'];
  logged: Scalars['Float'];
  milestones: Array<GanttChartMilestone>;
  paid: Scalars['Float'];
  planned: Scalars['Float'];
  startDate: Scalars['String'];
  type: GanttChartPhaseDetail;
};

export type GanttChartPhaseDetail = {
  __typename?: 'GanttChartPhaseDetail';
  abbr?: Maybe<Scalars['String']>;
  feeType: PhaseFee;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: PhaseStatus;
};

export type GanttChartResponse = {
  __typename?: 'GanttChartResponse';
  rows: Array<GanttChartRow>;
  totalProjectsCount: Scalars['Int'];
};

export type GanttChartRow = {
  __typename?: 'GanttChartRow';
  assigned: Scalars['Int'];
  color: Scalars['String'];
  endDate: Scalars['String'];
  hoursConsumed: Scalars['Float'];
  hoursPlanned: Scalars['Float'];
  href: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  phases: Array<GanttChartPhase>;
  showInvoicedData: Scalars['Boolean'];
  showMoneyGantt: Scalars['Boolean'];
  showMoneyGanttHoverCard: Scalars['Boolean'];
  startDate: Scalars['String'];
  status: ProjectStatus;
};

/** All available gantt chart sort options */
export enum GanttSortOption {
  Alphabetical = 'alphabetical',
  ReverseAlphabetical = 'reverseAlphabetical',
  Number = 'number',
  ReverseNumber = 'reverseNumber',
  BeginDate = 'beginDate',
  ReverseBeginDate = 'reverseBeginDate'
}

/** Autogenerated input type of GenerateCompanyInsuranceLink */
export type GenerateCompanyInsuranceLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateCompanyInsuranceLink. */
export type GenerateCompanyInsuranceLinkPayload = {
  __typename?: 'GenerateCompanyInsuranceLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailRequired?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GenerateCompanyOnboardingLink */
export type GenerateCompanyOnboardingLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateCompanyOnboardingLink. */
export type GenerateCompanyOnboardingLinkPayload = {
  __typename?: 'GenerateCompanyOnboardingLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  onboardingLink?: Maybe<Scalars['String']>;
  signatoryRequired?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of GenerateCompanyRetirementLink */
export type GenerateCompanyRetirementLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateCompanyRetirementLink. */
export type GenerateCompanyRetirementLinkPayload = {
  __typename?: 'GenerateCompanyRetirementLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailRequired?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GenerateEmployeeOnboardingLink */
export type GenerateEmployeeOnboardingLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of GenerateEmployeeOnboardingLink. */
export type GenerateEmployeeOnboardingLinkPayload = {
  __typename?: 'GenerateEmployeeOnboardingLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  onboardingLink?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GenerateRunPayrollLink */
export type GenerateRunPayrollLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payrollId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of GenerateRunPayrollLink. */
export type GenerateRunPayrollLinkPayload = {
  __typename?: 'GenerateRunPayrollLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  runPayrollLink?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of GenerateStaffingAllocations */
export type GenerateStaffingAllocationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleId: Scalars['ID'];
  overwriteUserAllocations?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of GenerateStaffingAllocations. */
export type GenerateStaffingAllocationsPayload = {
  __typename?: 'GenerateStaffingAllocationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroups: Array<EntryGroup>;
};

/** Autogenerated input type of GenerateUserInviteLink */
export type GenerateUserInviteLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of GenerateUserInviteLink. */
export type GenerateUserInviteLinkPayload = {
  __typename?: 'GenerateUserInviteLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  inviteLink: Scalars['String'];
};

/** Autogenerated input type of GlobalRebalanceAllocations */
export type GlobalRebalanceAllocationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
  phaseIds?: Maybe<Array<Scalars['ID']>>;
  overwriteUserAllocations?: Maybe<Scalars['Boolean']>;
  dateRange: DateRangeInput;
};

/** Autogenerated return type of GlobalRebalanceAllocations. */
export type GlobalRebalanceAllocationsPayload = {
  __typename?: 'GlobalRebalanceAllocationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jobBatchBid: Scalars['String'];
};

export type GlobalRebalanceAllocationsPreview = {
  __typename?: 'GlobalRebalanceAllocationsPreview';
  /** The ID of the existing batch job that is currently running, if any */
  existingJobBatchBid?: Maybe<Scalars['String']>;
  /** The number of phases that will be affected by the operation */
  phasesCount: Scalars['Int'];
  /** The number of roles that will be affected by the operation */
  rolesCount: Scalars['Int'];
};

/** All available groupings for entries */
export enum GroupByInput {
  Activity = 'ACTIVITY',
  Phase = 'PHASE',
  Project = 'PROJECT',
  Profile = 'PROFILE'
}

export type GroupedEntriesGroup = {
  __typename?: 'GroupedEntriesGroup';
  grouping?: Maybe<GroupedEntriesGrouping>;
  totalAmount: Scalars['Float'];
  totalEntries: Scalars['Int'];
  totalHours: Scalars['Float'];
};

/** Groupings for Entries */
export type GroupedEntriesGrouping = Activity | Phase | Profile | Project;

/** Autogenerated input type of GroupedEntries */
export type GroupedEntriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Intent for timelog query: global or project. */
  intent: TimeLogIntent;
  /** An entity to aggregate entries for */
  groupBy: GroupByInput;
  /** List of conditions entries should satisfy */
  filters: EntryFiltersInput;
};

/** Autogenerated return type of GroupedEntries. */
export type GroupedEntriesPayload = {
  __typename?: 'GroupedEntriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Highcharts = {
  __typename?: 'Highcharts';
  /** Returns a hours and dollars spent per project for current organization */
  hoursAndDollarsSpentPerProject: HoursAndDollarsSpentPerProject;
};


export type HighchartsHoursAndDollarsSpentPerProjectArgs = {
  dateRange?: Maybe<DateRangeInput>;
};

export type HourlyValidationTimesheet = {
  __typename?: 'HourlyValidationTimesheet';
  beginDate: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  totalHours: Scalars['Float'];
};

export type HourlyValidationWorker = {
  __typename?: 'HourlyValidationWorker';
  hourlyRate: Scalars['Float'];
  profile: Profile;
  timesheets: Array<HourlyValidationTimesheet>;
  type: CheckEmployment;
};

export type HoursAndDollarsSpentPerProject = {
  __typename?: 'HoursAndDollarsSpentPerProject';
  projects: Array<HoursAndDollarsSpentPerProjectProjectListEntry>;
  roles: Array<HoursAndDollarsSpentPerProjectRoleListEntry>;
};

export type HoursAndDollarsSpentPerProjectProjectListEntry = {
  __typename?: 'HoursAndDollarsSpentPerProjectProjectListEntry';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type HoursAndDollarsSpentPerProjectRoleListEntry = {
  __typename?: 'HoursAndDollarsSpentPerProjectRoleListEntry';
  budgetConsumed: Array<Scalars['Float']>;
  color: Scalars['String'];
  hoursConsumed: Array<Scalars['Float']>;
  name: Scalars['String'];
};

export type HoursAndFee = {
  __typename?: 'HoursAndFee';
  fee: Scalars['Int'];
  hours: Scalars['Int'];
};



/** Autogenerated input type of ImportAllVendorsFromQuickbooks */
export type ImportAllVendorsFromQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ImportAllVendorsFromQuickbooks. */
export type ImportAllVendorsFromQuickbooksPayload = {
  __typename?: 'ImportAllVendorsFromQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompanies?: Maybe<Array<ConsultantCompany>>;
};

/** Autogenerated input type of ImportCustomersFromQBO */
export type ImportCustomersFromQboInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  importMap: Array<CustomerImportMapping>;
  resolutionStrategy?: Maybe<CustomerImportResolution>;
};

/** Autogenerated return type of ImportCustomersFromQBO. */
export type ImportCustomersFromQboPayload = {
  __typename?: 'ImportCustomersFromQBOPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enqueued: Scalars['Boolean'];
};

/** Autogenerated input type of ImportQBOCosts */
export type ImportQboCostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  costsImportStartDate: Scalars['ISO8601Date'];
  qboAccountsCostCategories: Array<QboAccountCostCategoryMapping>;
};

/** Autogenerated return type of ImportQBOCosts. */
export type ImportQboCostsPayload = {
  __typename?: 'ImportQBOCostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of ImportSpecificVendorsFromQuickbooks */
export type ImportSpecificVendorsFromQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  vendorsIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of ImportSpecificVendorsFromQuickbooks. */
export type ImportSpecificVendorsFromQuickbooksPayload = {
  __typename?: 'ImportSpecificVendorsFromQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompanies?: Maybe<Array<ConsultantCompany>>;
};

/** All the possible industries for a company */
export enum Industry {
  /** Arts or Entertainment or Recreation */
  ArtsOrEntertainmentOrRecreation = 'arts_or_entertainment_or_recreation',
  /** Auto or Machine Repair */
  AutoOrMachineRepair = 'auto_or_machine_repair',
  /** Auto or Machine Sales */
  AutoOrMachineSales = 'auto_or_machine_sales',
  /** Cleaning Services */
  CleaningServices = 'cleaning_services',
  /** Consulting Services */
  ConsultingServices = 'consulting_services',
  /** Educational Services */
  EducationalServices = 'educational_services',
  /** Family Care Services */
  FamilyCareServices = 'family_care_services',
  /** Financial Services */
  FinancialServices = 'financial_services',
  /** Food and Beverage Retail or Wholesale */
  FoodAndBeverageRetailOrWholesale = 'food_and_beverage_retail_or_wholesale',
  /** General Construction or General Contracting */
  GeneralConstructionOrGeneralContracting = 'general_construction_or_general_contracting',
  /** Health Care */
  HealthCare = 'health_care',
  /** Hospitality or Accommodation */
  HospitalityOrAccommodation = 'hospitality_or_accommodation',
  /** HVAC or Plumbing or Electrical Contracting */
  HvacOrPlumbingOrElectricalContracting = 'hvac_or_plumbing_or_electrical_contracting',
  /** Legal Services */
  LegalServices = 'legal_services',
  /** Non-Food Retail or Wholesale */
  NonFoodRetailOrWholesale = 'non_food_retail_or_wholesale',
  /** Other */
  Other = 'other',
  /** Personal Care Services */
  PersonalCareServices = 'personal_care_services',
  /** Real Estate */
  RealEstate = 'real_estate',
  /** Restaurant */
  Restaurant = 'restaurant',
  /** Scientific or Technical Services */
  ScientificOrTechnicalServices = 'scientific_or_technical_services',
  /** Security Services */
  SecurityServices = 'security_services',
  /** Staffing */
  Staffing = 'staffing',
  /** Tobacco or Alcohol Sales */
  TobaccoOrAlcoholSales = 'tobacco_or_alcohol_sales',
  /** Transportation */
  Transportation = 'transportation'
}

export type InsightsDashboardBusinessPerformance = {
  __typename?: 'InsightsDashboardBusinessPerformance';
  estimatedOperatingProfit?: Maybe<BusinessPerformanceMetric>;
  id: Scalars['ID'];
  projectedFirmCapacity?: Maybe<BusinessPerformanceMetric>;
  projectedOperatingProfit?: Maybe<BusinessPerformanceMetric>;
  utilization?: Maybe<BusinessPerformanceMetric>;
};


export type InsightsDashboardBusinessPerformanceEstimatedOperatingProfitArgs = {
  useCache?: Maybe<Scalars['Boolean']>;
};


export type InsightsDashboardBusinessPerformanceProjectedFirmCapacityArgs = {
  useCache?: Maybe<Scalars['Boolean']>;
};


export type InsightsDashboardBusinessPerformanceProjectedOperatingProfitArgs = {
  useCache?: Maybe<Scalars['Boolean']>;
};


export type InsightsDashboardBusinessPerformanceUtilizationArgs = {
  useCache?: Maybe<Scalars['Boolean']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  allowBankTransfers: Scalars['Boolean'];
  allowCardPayments: Scalars['Boolean'];
  attachedExpenses?: Maybe<Array<Expense>>;
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  billingDestination?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  currentActivity?: Maybe<InvoiceActivity>;
  deliverTo?: Maybe<Array<Scalars['String']>>;
  deliverToCc?: Maybe<Array<Scalars['String']>>;
  displayOptions: InvoiceDisplayOptions;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  footer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalNote?: Maybe<Scalars['String']>;
  invoiceActivities?: Maybe<Array<InvoiceActivity>>;
  invoiceAttachments?: Maybe<Array<InvoiceAttachment>>;
  invoicePayment?: Maybe<InvoicePayment>;
  invoicePayments?: Maybe<Array<InvoicePayment>>;
  invoiceQboDetails?: Maybe<InvoiceQboDetails>;
  invoiceVersion: Scalars['Int'];
  issueDate?: Maybe<Scalars['ISO8601Date']>;
  lastLineItemUpdate?: Maybe<Scalars['ISO8601DateTime']>;
  lastSyncedToQuickbooksAt?: Maybe<Scalars['ISO8601DateTime']>;
  lineItemsHaveQboMappings: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organization: Organization;
  paidDate?: Maybe<Scalars['ISO8601Date']>;
  passConvenienceFeeAch: Scalars['Boolean'];
  passConvenienceFeeCard: Scalars['Boolean'];
  paymentIntentStatus?: Maybe<PaymentIntentStatus>;
  paymentUrl?: Maybe<Scalars['String']>;
  project: Project;
  qboInstance?: Maybe<QboInstance>;
  referenceNumber?: Maybe<Scalars['String']>;
  remainingBalance: Scalars['Float'];
  sentToQuickbooks?: Maybe<Scalars['ISO8601Date']>;
  slug: Scalars['String'];
  status: InvoiceStatus;
  suggestVersionSwitch: Scalars['Boolean'];
  term?: Maybe<Term>;
  total?: Maybe<Scalars['Float']>;
  totalPaid: Scalars['Float'];
  unassignedLineItemsCount: Scalars['Int'];
};

export type InvoiceActivity = {
  __typename?: 'InvoiceActivity';
  activityType: InvoiceActivityValues;
  amount?: Maybe<Scalars['Float']>;
  billingDestination?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  previousAmount?: Maybe<Scalars['Float']>;
  previousRelevantDate?: Maybe<Scalars['ISO8601Date']>;
  profile?: Maybe<Profile>;
  relevantDate?: Maybe<Scalars['ISO8601Date']>;
  sentToQuickbooks?: Maybe<Scalars['ISO8601Date']>;
  thirdPartySource?: Maybe<InvoiceActivityThirdPartySource>;
};

/** All available third party sources for invoice activities */
export enum InvoiceActivityThirdPartySource {
  Qbo = 'qbo'
}

/** All available invoice activity types */
export enum InvoiceActivityValues {
  Sent = 'sent',
  Viewed = 'viewed',
  PaymentProcessing = 'payment_processing',
  PaymentPending = 'payment_pending',
  PaymentAwaitingAction = 'payment_awaiting_action',
  PaymentFailed = 'payment_failed',
  PaymentSucceeded = 'payment_succeeded',
  PaymentDeposited = 'payment_deposited',
  MarkedAsOpen = 'marked_as_open',
  MarkedAsPaid = 'marked_as_paid',
  MarkedAsUnpaid = 'marked_as_unpaid',
  Created = 'created',
  SentReminder = 'sent_reminder',
  SentToQbo = 'sent_to_qbo',
  EditedPayment = 'edited_payment',
  PartiallyPaid = 'partially_paid',
  DeletedPayment = 'deleted_payment'
}

/** Autogenerated input type of InvoiceAddAttachment */
export type InvoiceAddAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
  files: Array<InvoiceAttachmentFile>;
};

/** Autogenerated return type of InvoiceAddAttachment. */
export type InvoiceAddAttachmentPayload = {
  __typename?: 'InvoiceAddAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceAddPayment */
export type InvoiceAddPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  paidDate: Scalars['ISO8601Date'];
  amount?: Maybe<Scalars['Float']>;
  emailAddresses: Array<Scalars['String']>;
  emailAddressesCc?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of InvoiceAddPayment. */
export type InvoiceAddPaymentPayload = {
  __typename?: 'InvoiceAddPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated input type of InvoiceAttachExpense */
export type InvoiceAttachExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceAttachExpense. */
export type InvoiceAttachExpensePayload = {
  __typename?: 'InvoiceAttachExpensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

export type InvoiceAttachment = {
  __typename?: 'InvoiceAttachment';
  filename: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type InvoiceAttachmentFile = {
  filename: Scalars['String'];
  url: Scalars['String'];
};

/** Autogenerated input type of InvoiceBatchSendToQuickbooks */
export type InvoiceBatchSendToQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoices: Array<Scalars['String']>;
};

/** Autogenerated return type of InvoiceBatchSendToQuickbooks. */
export type InvoiceBatchSendToQuickbooksPayload = {
  __typename?: 'InvoiceBatchSendToQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enqueued: Scalars['Boolean'];
};

export type InvoiceBuilderClientInput = {
  __typename?: 'InvoiceBuilderClientInput';
  value: Scalars['Float'];
  touched: Scalars['Boolean'];
};

export type InvoiceBuilderConsultantTotals = {
  __typename?: 'InvoiceBuilderConsultantTotals';
  consultantFee: Scalars['Float'];
  currentCompletePercent: Scalars['Float'];
  currentDue: Scalars['Float'];
  markup: Scalars['Float'];
  planned: Scalars['Float'];
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
};

/** Autogenerated input type of InvoiceBuilderExpensesAddAll */
export type InvoiceBuilderExpensesAddAllInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
};

/** Autogenerated return type of InvoiceBuilderExpensesAddAll. */
export type InvoiceBuilderExpensesAddAllPayload = {
  __typename?: 'InvoiceBuilderExpensesAddAllPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderExpensesAdd */
export type InvoiceBuilderExpensesAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceBuilderExpensesAdd. */
export type InvoiceBuilderExpensesAddPayload = {
  __typename?: 'InvoiceBuilderExpensesAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderExpensesDelete */
export type InvoiceBuilderExpensesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceBuilderExpensesDelete. */
export type InvoiceBuilderExpensesDeletePayload = {
  __typename?: 'InvoiceBuilderExpensesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

export type InvoiceBuilderExpensesType = {
  __typename?: 'InvoiceBuilderExpensesType';
  included: Array<Expense>;
  reported: Array<Expense>;
  totals: InvoiceBuilderServiceTotals;
};

export type InvoiceBuilderFixedFeeGroupedEntryType = {
  __typename?: 'InvoiceBuilderFixedFeeGroupedEntryType';
  consultantEntries: Array<InvoiceBuilderProjectConsultantType>;
  fixedFeeServiceEntry: InvoiceBuilderFixedFeeService;
  hideFixedFeeServiceEntry: Scalars['Boolean'];
  phase?: Maybe<Phase>;
  totals: InvoiceBuilderPhaseTotals;
};

export type InvoiceBuilderFixedFeeService = InvoiceBuilderServiceEntry & {
  __typename?: 'InvoiceBuilderFixedFeeService';
  /** the percent of phase budget that is already billed */
  currentCompletePercent?: Maybe<Scalars['Float']>;
  currentCompletePercentInput: InvoiceBuilderClientInput;
  currentDue?: Maybe<Scalars['Float']>;
  currentDueInput: InvoiceBuilderClientInput;
  id: Scalars['ID'];
  milestones: Array<InvoiceBuilderMilestone>;
  phase: Phase;
  planned: Scalars['Float'];
  /** amounts and percent that were billed in previous invoices */
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
  unbilled: InvoiceBuilderTooltip;
};

export type InvoiceBuilderFixedFeeServices = {
  __typename?: 'InvoiceBuilderFixedFeeServices';
  entries: Array<InvoiceBuilderFixedFeeService>;
  groupedPhaseEntries: Array<InvoiceBuilderFixedFeeGroupedEntryType>;
  phaseTotals: InvoiceBuilderPhaseTotals;
  totals: InvoiceBuilderServiceTotals;
};

export type InvoiceBuilderHourlyPhaseTotals = {
  __typename?: 'InvoiceBuilderHourlyPhaseTotals';
  currentDue: Scalars['Float'];
  currentHours: Scalars['Float'];
  previouslyBilledAmount?: Maybe<Scalars['Float']>;
  previouslyBilledHours: Scalars['Float'];
  totalDue?: Maybe<Scalars['Float']>;
};

export type InvoiceBuilderHourlyService = InvoiceBuilderServiceEntry & {
  __typename?: 'InvoiceBuilderHourlyService';
  consultantEntries: Array<InvoiceBuilderProjectConsultantType>;
  /** the percent of phase budget that is already billed */
  currentCompletePercent?: Maybe<Scalars['Float']>;
  currentDue?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  maxCap: Scalars['Float'];
  milestones: Array<InvoiceBuilderMilestone>;
  phase: Phase;
  phaseTotals: InvoiceBuilderHourlyPhaseTotals;
  /** amounts and percent that were billed in previous invoices */
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
  roleEntries: Array<InvoiceBuilderRoleEntry>;
  roleEntriesDue?: Maybe<Scalars['Float']>;
  unbilled: InvoiceBuilderTooltip;
};

export type InvoiceBuilderHourlyServices = {
  __typename?: 'InvoiceBuilderHourlyServices';
  entries: Array<InvoiceBuilderHourlyService>;
  totals: InvoiceBuilderServiceTotals;
};

export type InvoiceBuilderMilestone = {
  __typename?: 'InvoiceBuilderMilestone';
  completedTasksCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
  tasksCount: Scalars['Int'];
};

export type InvoiceBuilderMutations = {
  __typename?: 'InvoiceBuilderMutations';
  addAllExpenses?: Maybe<InvoiceBuilderExpensesAddAllPayload>;
  addExpense?: Maybe<InvoiceBuilderExpensesAddPayload>;
  applyRetainer?: Maybe<InvoiceBuilderRetainersApplyPayload>;
  createOtherItem?: Maybe<InvoiceBuilderOtherItemsCreatePayload>;
  createRetainer?: Maybe<InvoiceBuilderRetainersCreatePayload>;
  createTaxItem?: Maybe<InvoiceBuilderTaxItemsCreatePayload>;
  deleteExpense?: Maybe<InvoiceBuilderExpensesDeletePayload>;
  deleteOtherItem?: Maybe<InvoiceBuilderOtherItemsDeletePayload>;
  deleteRetainer?: Maybe<InvoiceBuilderRetainersDeletePayload>;
  deleteTaxItem?: Maybe<InvoiceBuilderTaxItemsDeletePayload>;
  updateFixedFeeService?: Maybe<InvoiceBuilderUpdateFixedFeeServicePayload>;
  updateHourlyService?: Maybe<InvoiceBuilderUpdateHourlyServicePayload>;
  updateOtherItem?: Maybe<InvoiceBuilderOtherItemsUpdatePayload>;
  updateProjectConsultant?: Maybe<InvoiceBuilderUpdateProjectConsultantPayload>;
  updateRetainer?: Maybe<InvoiceBuilderRetainersUpdatePayload>;
  updateTaxItem?: Maybe<InvoiceBuilderTaxItemsUpdatePayload>;
};


export type InvoiceBuilderMutationsAddAllExpensesArgs = {
  input: InvoiceBuilderExpensesAddAllInput;
};


export type InvoiceBuilderMutationsAddExpenseArgs = {
  input: InvoiceBuilderExpensesAddInput;
};


export type InvoiceBuilderMutationsApplyRetainerArgs = {
  input: InvoiceBuilderRetainersApplyInput;
};


export type InvoiceBuilderMutationsCreateOtherItemArgs = {
  input: InvoiceBuilderOtherItemsCreateInput;
};


export type InvoiceBuilderMutationsCreateRetainerArgs = {
  input: InvoiceBuilderRetainersCreateInput;
};


export type InvoiceBuilderMutationsCreateTaxItemArgs = {
  input: InvoiceBuilderTaxItemsCreateInput;
};


export type InvoiceBuilderMutationsDeleteExpenseArgs = {
  input: InvoiceBuilderExpensesDeleteInput;
};


export type InvoiceBuilderMutationsDeleteOtherItemArgs = {
  input: InvoiceBuilderOtherItemsDeleteInput;
};


export type InvoiceBuilderMutationsDeleteRetainerArgs = {
  input: InvoiceBuilderRetainersDeleteInput;
};


export type InvoiceBuilderMutationsDeleteTaxItemArgs = {
  input: InvoiceBuilderTaxItemsDeleteInput;
};


export type InvoiceBuilderMutationsUpdateFixedFeeServiceArgs = {
  input: InvoiceBuilderUpdateFixedFeeServiceInput;
};


export type InvoiceBuilderMutationsUpdateHourlyServiceArgs = {
  input: InvoiceBuilderUpdateHourlyServiceInput;
};


export type InvoiceBuilderMutationsUpdateOtherItemArgs = {
  input: InvoiceBuilderOtherItemsUpdateInput;
};


export type InvoiceBuilderMutationsUpdateProjectConsultantArgs = {
  input: InvoiceBuilderUpdateProjectConsultantInput;
};


export type InvoiceBuilderMutationsUpdateRetainerArgs = {
  input: InvoiceBuilderRetainersUpdateInput;
};


export type InvoiceBuilderMutationsUpdateTaxItemArgs = {
  input: InvoiceBuilderTaxItemsUpdateInput;
};

/** Autogenerated input type of InvoiceBuilderOtherItemsCreate */
export type InvoiceBuilderOtherItemsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderOtherItemsCreate. */
export type InvoiceBuilderOtherItemsCreatePayload = {
  __typename?: 'InvoiceBuilderOtherItemsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderOtherItemsDelete */
export type InvoiceBuilderOtherItemsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of InvoiceBuilderOtherItemsDelete. */
export type InvoiceBuilderOtherItemsDeletePayload = {
  __typename?: 'InvoiceBuilderOtherItemsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

export type InvoiceBuilderOtherItemsType = {
  __typename?: 'InvoiceBuilderOtherItemsType';
  currentDue: Scalars['Float'];
  entries: Array<LineItem>;
};

/** Autogenerated input type of InvoiceBuilderOtherItemsUpdate */
export type InvoiceBuilderOtherItemsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderOtherItemsUpdate. */
export type InvoiceBuilderOtherItemsUpdatePayload = {
  __typename?: 'InvoiceBuilderOtherItemsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

export type InvoiceBuilderOustandingInvoices = {
  __typename?: 'InvoiceBuilderOustandingInvoices';
  entries: Array<InvoiceBuilderOutstandingInvoiceType>;
  total?: Maybe<Scalars['Float']>;
};

export type InvoiceBuilderOutstandingInvoiceType = {
  __typename?: 'InvoiceBuilderOutstandingInvoiceType';
  dueDate: Scalars['ISO8601Date'];
  number: Scalars['String'];
  paymentUrl?: Maybe<Scalars['String']>;
  remainingBalance: Scalars['Float'];
};

export type InvoiceBuilderPayload = {
  __typename?: 'InvoiceBuilderPayload';
  appliedRetainer?: Maybe<LineItem>;
  convenienceFee?: Maybe<Scalars['Float']>;
  expenses: InvoiceBuilderExpensesType;
  fixedFeeServices: InvoiceBuilderFixedFeeServices;
  hourlyServices: InvoiceBuilderHourlyServices;
  invoice: Invoice;
  loadingTotals: Scalars['Boolean'];
  otherItems: InvoiceBuilderOtherItemsType;
  outstandingInvoices: InvoiceBuilderOustandingInvoices;
  phaseConsultants: InvoiceBuilderPhaseConsultants;
  projectConsultants: InvoiceBuilderProjectConsultants;
  remainingBalance?: Maybe<Scalars['Float']>;
  retainerItems: InvoiceBuilderRetainerItemsType;
  retainerSummary?: Maybe<RetainerSummary>;
  subtotal: Scalars['Float'];
  taxes: InvoiceBuilderTaxes;
  timelogDetails: InvoiceBuilderTimelogDetails;
  totalDue: Scalars['Float'];
  totalDueWithConvenienceFee: Scalars['Float'];
  totalDueWithoutAppliedRetainer: Scalars['Float'];
  totalPaid?: Maybe<Scalars['Float']>;
};

export type InvoiceBuilderPhaseConsultantTemplateTotals = {
  __typename?: 'InvoiceBuilderPhaseConsultantTemplateTotals';
  consultantFee: Scalars['Float'];
  currentCompletePercent: Scalars['Float'];
  currentDue: Scalars['Float'];
  markup: Scalars['Float'];
  markupPercentage: Scalars['Float'];
  planned: Scalars['Float'];
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
};

export type InvoiceBuilderPhaseConsultantType = {
  __typename?: 'InvoiceBuilderPhaseConsultantType';
  consultantCompany?: Maybe<ConsultantCompany>;
  consultantTemplate: ConsultantTemplate;
  consultantTemplateTotals: InvoiceBuilderPhaseConsultantTemplateTotals;
  entries: Array<InvoiceBuilderProjectConsultantType>;
};

export type InvoiceBuilderPhaseConsultants = {
  __typename?: 'InvoiceBuilderPhaseConsultants';
  currentDue: Scalars['Float'];
  entries: Array<InvoiceBuilderPhaseConsultantType>;
  totals: InvoiceBuilderConsultantTotals;
};

export type InvoiceBuilderPhaseTotals = {
  __typename?: 'InvoiceBuilderPhaseTotals';
  currentCompletePercent: Scalars['Float'];
  currentDue: Scalars['Float'];
  planned: Scalars['Float'];
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
  unbilled: Scalars['Float'];
};

export type InvoiceBuilderPreviouslyBilled = {
  __typename?: 'InvoiceBuilderPreviouslyBilled';
  amount: Scalars['Float'];
  percent: Scalars['Float'];
};

export type InvoiceBuilderProjectConsultantType = InvoiceBuilderServiceEntry & {
  __typename?: 'InvoiceBuilderProjectConsultantType';
  consultant: ProjectConsultant;
  consultantFee: Scalars['Float'];
  /** the percent of phase budget that is already billed */
  currentCompletePercent?: Maybe<Scalars['Float']>;
  currentCompletePercentInput: InvoiceBuilderClientInput;
  currentDue?: Maybe<Scalars['Float']>;
  currentDueInput: InvoiceBuilderClientInput;
  id: Scalars['ID'];
  markup: Scalars['Float'];
  markupPercentage: Scalars['Float'];
  planned: Scalars['Float'];
  /** amounts and percent that were billed in previous invoices */
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
};

export type InvoiceBuilderProjectConsultants = {
  __typename?: 'InvoiceBuilderProjectConsultants';
  consultantTotals: InvoiceBuilderConsultantTotals;
  currentDue: Scalars['Float'];
  entries: Array<InvoiceBuilderProjectConsultantType>;
};

export type InvoiceBuilderRetainerItemsType = {
  __typename?: 'InvoiceBuilderRetainerItemsType';
  currentDue: Scalars['Float'];
  entries: Array<LineItem>;
};

/** Autogenerated input type of InvoiceBuilderRetainersApply */
export type InvoiceBuilderRetainersApplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  title: Scalars['String'];
  amount: Scalars['Float'];
};

/** Autogenerated return type of InvoiceBuilderRetainersApply. */
export type InvoiceBuilderRetainersApplyPayload = {
  __typename?: 'InvoiceBuilderRetainersApplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderRetainersCreate */
export type InvoiceBuilderRetainersCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderRetainersCreate. */
export type InvoiceBuilderRetainersCreatePayload = {
  __typename?: 'InvoiceBuilderRetainersCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderRetainersDelete */
export type InvoiceBuilderRetainersDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of InvoiceBuilderRetainersDelete. */
export type InvoiceBuilderRetainersDeletePayload = {
  __typename?: 'InvoiceBuilderRetainersDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderRetainersUpdate */
export type InvoiceBuilderRetainersUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderRetainersUpdate. */
export type InvoiceBuilderRetainersUpdatePayload = {
  __typename?: 'InvoiceBuilderRetainersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

export type InvoiceBuilderRoleEntry = {
  __typename?: 'InvoiceBuilderRoleEntry';
  /** amount to bill for this role */
  amount?: Maybe<Scalars['Float']>;
  /** hours to bill for this role */
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** hours logged for this role */
  logged: InvoiceBuilderTooltip;
  /** amount that was billed in previous invoices */
  previouslyBilledAmount?: Maybe<Scalars['Float']>;
  /** hours that were billed in previous invoices */
  previouslyBilledHours: Scalars['Float'];
  role: Role;
  /** rate of the role in the given service period */
  roleRate: Scalars['Float'];
};

export type InvoiceBuilderServiceEntry = {
  /** the percent of phase budget that is already billed */
  currentCompletePercent?: Maybe<Scalars['Float']>;
  currentDue?: Maybe<Scalars['Float']>;
  /** amounts and percent that were billed in previous invoices */
  previouslyBilled: InvoiceBuilderPreviouslyBilled;
};

export type InvoiceBuilderServiceTotals = {
  __typename?: 'InvoiceBuilderServiceTotals';
  currentDue: Scalars['Float'];
  previouslyBilledAmount?: Maybe<Scalars['Float']>;
  unbilled: Scalars['Float'];
};

export type InvoiceBuilderTaxItem = {
  __typename?: 'InvoiceBuilderTaxItem';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  taxConfiguration?: Maybe<LineItemTaxConfiguration>;
  taxRate?: Maybe<TaxRate>;
};

/** Autogenerated input type of InvoiceBuilderTaxItemsCreate */
export type InvoiceBuilderTaxItemsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  taxRateId?: Maybe<Scalars['ID']>;
  fixedFeeServices: Scalars['Boolean'];
  hourlyServices: Scalars['Boolean'];
  consultingServices: Scalars['Boolean'];
  expenses: Scalars['Boolean'];
  otherItems: Scalars['Boolean'];
  retainerCollection: Scalars['Boolean'];
};

/** Autogenerated return type of InvoiceBuilderTaxItemsCreate. */
export type InvoiceBuilderTaxItemsCreatePayload = {
  __typename?: 'InvoiceBuilderTaxItemsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderTaxItemsDelete */
export type InvoiceBuilderTaxItemsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of InvoiceBuilderTaxItemsDelete. */
export type InvoiceBuilderTaxItemsDeletePayload = {
  __typename?: 'InvoiceBuilderTaxItemsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderTaxItemsUpdate */
export type InvoiceBuilderTaxItemsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  id: Scalars['ID'];
  taxRateId?: Maybe<Scalars['ID']>;
  fixedFeeServices: Scalars['Boolean'];
  hourlyServices: Scalars['Boolean'];
  consultingServices: Scalars['Boolean'];
  expenses: Scalars['Boolean'];
  otherItems: Scalars['Boolean'];
  retainerCollection: Scalars['Boolean'];
};

/** Autogenerated return type of InvoiceBuilderTaxItemsUpdate. */
export type InvoiceBuilderTaxItemsUpdatePayload = {
  __typename?: 'InvoiceBuilderTaxItemsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

export type InvoiceBuilderTaxes = {
  __typename?: 'InvoiceBuilderTaxes';
  entries: Array<InvoiceBuilderTaxItem>;
  totals: InvoiceBuilderTaxesTotals;
};

export type InvoiceBuilderTaxesTotals = {
  __typename?: 'InvoiceBuilderTaxesTotals';
  currentDue: Scalars['Float'];
};

export type InvoiceBuilderTimelogDetailActivityEntryType = {
  __typename?: 'InvoiceBuilderTimelogDetailActivityEntryType';
  activity?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  hours: Scalars['Float'];
  rate: Scalars['Float'];
};

export type InvoiceBuilderTimelogDetailEntryType = {
  __typename?: 'InvoiceBuilderTimelogDetailEntryType';
  activity?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  date: Scalars['ISO8601Date'];
  employee: Scalars['String'];
  hours: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
  role: Scalars['String'];
};

export type InvoiceBuilderTimelogDetailGroupedEntryType = {
  __typename?: 'InvoiceBuilderTimelogDetailGroupedEntryType';
  activityEntries: Array<InvoiceBuilderTimelogDetailActivityEntryType>;
  role: Scalars['String'];
  total: Scalars['Float'];
};

export type InvoiceBuilderTimelogDetails = {
  __typename?: 'InvoiceBuilderTimelogDetails';
  entries: Array<InvoiceBuilderTimelogDetailEntryType>;
  groupedEntries: Array<InvoiceBuilderTimelogDetailGroupedEntryType>;
};

export type InvoiceBuilderTooltip = {
  __typename?: 'InvoiceBuilderTooltip';
  beforeInvoiceBeginDate: Scalars['Float'];
  duringInvoiceRange: Scalars['Float'];
  total: Scalars['Float'];
};

/** Autogenerated input type of InvoiceBuilderUpdateFixedFeeService */
export type InvoiceBuilderUpdateFixedFeeServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  phaseId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderUpdateFixedFeeService. */
export type InvoiceBuilderUpdateFixedFeeServicePayload = {
  __typename?: 'InvoiceBuilderUpdateFixedFeeServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderUpdateHourlyService */
export type InvoiceBuilderUpdateHourlyServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  roleId: Scalars['ID'];
  hours?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderUpdateHourlyService. */
export type InvoiceBuilderUpdateHourlyServicePayload = {
  __typename?: 'InvoiceBuilderUpdateHourlyServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceBuilderUpdateProjectConsultant */
export type InvoiceBuilderUpdateProjectConsultantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceSlug: Scalars['String'];
  consultantId: Scalars['ID'];
  type: Consultant;
  amount?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of InvoiceBuilderUpdateProjectConsultant. */
export type InvoiceBuilderUpdateProjectConsultantPayload = {
  __typename?: 'InvoiceBuilderUpdateProjectConsultantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceChangeVersion */
export type InvoiceChangeVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  invoiceVersion: Scalars['Int'];
};

/** Autogenerated return type of InvoiceChangeVersion. */
export type InvoiceChangeVersionPayload = {
  __typename?: 'InvoiceChangeVersionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalBilled: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalRemaining: Scalars['Float'];
};

/** Autogenerated input type of InvoiceCreate */
export type InvoiceCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  number: Scalars['String'];
  referenceNumber?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeInput>;
};

/** Autogenerated return type of InvoiceCreate. */
export type InvoiceCreatePayload = {
  __typename?: 'InvoiceCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceCreatePaymentIntent */
export type InvoiceCreatePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentUuid: Scalars['String'];
};

/** Autogenerated return type of InvoiceCreatePaymentIntent. */
export type InvoiceCreatePaymentIntentPayload = {
  __typename?: 'InvoiceCreatePaymentIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentIntent: StripePaymentIntent;
};

/** Autogenerated input type of InvoiceDelete */
export type InvoiceDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceDelete. */
export type InvoiceDeletePayload = {
  __typename?: 'InvoiceDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

export type InvoiceDisplayOptions = {
  __typename?: 'InvoiceDisplayOptions';
  groupTimelogByRoleAndActivity: Scalars['Boolean'];
  hidePreviouslyBilled: Scalars['Boolean'];
  hideServicesThrough: Scalars['Boolean'];
  includeReceiptImages: Scalars['Boolean'];
  includeTimelog: Scalars['Boolean'];
  phaseConsultantsDisplay: InvoicePhaseConsultantsDisplay;
  showAllFixedFeeLineItems: Scalars['Boolean'];
  showOutstandingInvoices: Scalars['Boolean'];
  showRetainerSummary: Scalars['Boolean'];
  useCompactLayout: Scalars['Boolean'];
};

/** Autogenerated input type of InvoiceDuplicate */
export type InvoiceDuplicateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceDuplicate. */
export type InvoiceDuplicatePayload = {
  __typename?: 'InvoiceDuplicatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

/** Autogenerated input type of InvoiceEditPayment */
export type InvoiceEditPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
  paymentId: Scalars['ID'];
  paidDate: Scalars['ISO8601Date'];
  amount: Scalars['Float'];
};

/** Autogenerated return type of InvoiceEditPayment. */
export type InvoiceEditPaymentPayload = {
  __typename?: 'InvoiceEditPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Filters for invoices */
export type InvoiceFiltersInput = {
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  consultantCompanyId?: Maybe<Scalars['ID']>;
  consultantCompanyIds?: Maybe<Array<Scalars['ID']>>;
  clientId?: Maybe<Scalars['ID']>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  excludeArchivedProjects?: Maybe<Scalars['Boolean']>;
  paidDateRange?: Maybe<DateRangeInput>;
  searchString?: Maybe<Scalars['String']>;
  issueDateRange?: Maybe<DateRangeInput>;
  partiallyPaid?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<InvoiceStatus>>;
};

/** Autogenerated input type of InvoiceMarkAsOpen */
export type InvoiceMarkAsOpenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceMarkAsOpen. */
export type InvoiceMarkAsOpenPayload = {
  __typename?: 'InvoiceMarkAsOpenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  amount: Scalars['Float'];
  applicationFee: Scalars['Float'];
  applyConvenienceFee: Scalars['Boolean'];
  id: Scalars['ID'];
  invoice: Invoice;
  isQboPayment: Scalars['Boolean'];
  net: Scalars['Float'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
  paymentCurrency: Scalars['String'];
  paymentType: InvoicePaymentType;
};

/** The connection type for InvoicePayment. */
export type InvoicePaymentConnection = {
  __typename?: 'InvoicePaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoicePaymentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InvoicePayment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  totalPaid: Scalars['Float'];
};

/** Autogenerated input type of InvoicePaymentDelete */
export type InvoicePaymentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of InvoicePaymentDelete. */
export type InvoicePaymentDeletePayload = {
  __typename?: 'InvoicePaymentDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** An edge in a connection. */
export type InvoicePaymentEdge = {
  __typename?: 'InvoicePaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InvoicePayment>;
};

/** All available invoice payment types */
export enum InvoicePaymentType {
  UsBankAccount = 'us_bank_account',
  Card = 'card',
  AcssDebit = 'acss_debit',
  Manual = 'manual'
}

/** All available sorts for invoice payments */
export enum InvoicePaymentsFieldInput {
  Amount = 'AMOUNT',
  Client = 'CLIENT',
  InvoiceNumber = 'INVOICE_NUMBER',
  PaidDate = 'PAID_DATE',
  PaymentType = 'PAYMENT_TYPE',
  ProjectName = 'PROJECT_NAME',
  ProjectNumber = 'PROJECT_NUMBER',
  ServiceFee = 'SERVICE_FEE'
}

export type InvoicePaymentsSortInput = {
  field: InvoicePaymentsFieldInput;
  direction: SortDirectionInput;
};

/** All options to show phase consultants in an invoice */
export enum InvoicePhaseConsultantsDisplay {
  Grouped = 'grouped',
  Itemized = 'itemized',
  Hidden = 'hidden'
}

export type InvoiceQboDetails = {
  __typename?: 'InvoiceQBODetails';
  accountingDestinationCustomer?: Maybe<QboCustomer>;
  id: Scalars['ID'];
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceReminderDelete */
export type InvoiceReminderDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceReminderDelete. */
export type InvoiceReminderDeletePayload = {
  __typename?: 'InvoiceReminderDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

export type InvoiceRemindersScheduler = {
  __typename?: 'InvoiceRemindersScheduler';
  daysAfterDueDate?: Maybe<Scalars['Int']>;
  daysBeforeDueDate?: Maybe<Scalars['Int']>;
  deliverCc?: Maybe<Array<Scalars['String']>>;
  deliverTo?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  sendOnDueDate?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of InvoiceRemoveAttachedExpense */
export type InvoiceRemoveAttachedExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceRemoveAttachedExpense. */
export type InvoiceRemoveAttachedExpensePayload = {
  __typename?: 'InvoiceRemoveAttachedExpensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceRemoveAttachment */
export type InvoiceRemoveAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
  attachmentId: Scalars['ID'];
};

/** Autogenerated return type of InvoiceRemoveAttachment. */
export type InvoiceRemoveAttachmentPayload = {
  __typename?: 'InvoiceRemoveAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceSendPaymentLink */
export type InvoiceSendPaymentLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  emailAddresses: Array<Scalars['String']>;
  emailAddressesCc?: Maybe<Array<Scalars['String']>>;
  emailBody: Scalars['String'];
};

/** Autogenerated return type of InvoiceSendPaymentLink. */
export type InvoiceSendPaymentLinkPayload = {
  __typename?: 'InvoiceSendPaymentLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceSendReminderEmail */
export type InvoiceSendReminderEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailAddresses: Array<Scalars['String']>;
  emailAddressesCc?: Maybe<Array<Scalars['String']>>;
  emailBody: Scalars['String'];
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceSendReminderEmail. */
export type InvoiceSendReminderEmailPayload = {
  __typename?: 'InvoiceSendReminderEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated input type of InvoiceSendToQuickbooks */
export type InvoiceSendToQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of InvoiceSendToQuickbooks. */
export type InvoiceSendToQuickbooksPayload = {
  __typename?: 'InvoiceSendToQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** All available invoice status types */
export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  PastDue = 'PAST_DUE'
}

/** All batch job status types */
export enum InvoiceToQboBatchJobStatus {
  InRetry = 'in_retry',
  Succeeded = 'succeeded',
  PartiallySucceeded = 'partially_succeeded',
  Failed = 'failed'
}

/** Autogenerated input type of InvoiceUpdateBillingDestination */
export type InvoiceUpdateBillingDestinationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  qboCustomerId: Scalars['String'];
};

/** Autogenerated return type of InvoiceUpdateBillingDestination. */
export type InvoiceUpdateBillingDestinationPayload = {
  __typename?: 'InvoiceUpdateBillingDestinationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceUpdateDisplayOptions */
export type InvoiceUpdateDisplayOptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  showAllFixedFeeLineItems: Scalars['Boolean'];
  hidePreviouslyBilled: Scalars['Boolean'];
  includeReceiptImages: Scalars['Boolean'];
  useCompactLayout: Scalars['Boolean'];
  includeTimelog: Scalars['Boolean'];
  groupTimelogByRoleAndActivity: Scalars['Boolean'];
  phaseConsultantsDisplay: InvoicePhaseConsultantsDisplay;
  hideServicesThrough: Scalars['Boolean'];
  showRetainerSummary: Scalars['Boolean'];
  showOutstandingInvoices: Scalars['Boolean'];
};

/** Autogenerated return type of InvoiceUpdateDisplayOptions. */
export type InvoiceUpdateDisplayOptionsPayload = {
  __typename?: 'InvoiceUpdateDisplayOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payload?: Maybe<InvoiceBuilderPayload>;
};

/** Autogenerated input type of InvoiceUpdate */
export type InvoiceUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  issueDate?: Maybe<Scalars['ISO8601Date']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  termId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  number?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  allowCardPayments?: Maybe<Scalars['Boolean']>;
  allowBankTransfers?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of InvoiceUpdateInternalNote */
export type InvoiceUpdateInternalNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  internalNote?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InvoiceUpdateInternalNote. */
export type InvoiceUpdateInternalNotePayload = {
  __typename?: 'InvoiceUpdateInternalNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated return type of InvoiceUpdate. */
export type InvoiceUpdatePayload = {
  __typename?: 'InvoiceUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of InvoiceUpdatePaymentIntent */
export type InvoiceUpdatePaymentIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentUuid: Scalars['String'];
  paymentIntentId: Scalars['String'];
  paymentMethodType: PaymentMethodType;
  setupFutureUsage: Scalars['Boolean'];
};

/** Autogenerated return type of InvoiceUpdatePaymentIntent. */
export type InvoiceUpdatePaymentIntentPayload = {
  __typename?: 'InvoiceUpdatePaymentIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  convenienceFee: Scalars['Float'];
  invoice: Invoice;
  paymentIntent: StripePaymentIntent;
};

/** Autogenerated input type of InvoiceView */
export type InvoiceViewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentUuid: Scalars['String'];
  emailToken?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InvoiceView. */
export type InvoiceViewPayload = {
  __typename?: 'InvoiceViewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** All available sorts for invoices */
export enum InvoicesFieldInput {
  Amount = 'AMOUNT',
  ClientAppDisplayName = 'CLIENT_APP_DISPLAY_NAME',
  ClientDisplayName = 'CLIENT_DISPLAY_NAME',
  Client = 'CLIENT',
  CurrentActivity = 'CURRENT_ACTIVITY',
  DueDate = 'DUE_DATE',
  Id = 'ID',
  InvoiceNumber = 'INVOICE_NUMBER',
  IssueDate = 'ISSUE_DATE',
  PaidDate = 'PAID_DATE',
  PaymentType = 'PAYMENT_TYPE',
  ProjectName = 'PROJECT_NAME',
  ProjectNumber = 'PROJECT_NUMBER',
  SentToQuickbooks = 'SENT_TO_QUICKBOOKS',
  ServiceFee = 'SERVICE_FEE',
  Status = 'STATUS'
}

/** Autogenerated input type of Invoices */
export type InvoicesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions entries should satisfy */
  filters: InvoiceFiltersInput;
};

/** Autogenerated return type of Invoices. */
export type InvoicesPayload = {
  __typename?: 'InvoicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type InvoicesSortInput = {
  field: InvoicesFieldInput;
  direction: SortDirectionInput;
};

export type InvoicesToQbo = {
  __typename?: 'InvoicesToQBO';
  error?: Maybe<Scalars['String']>;
  itemFailures?: Maybe<Array<InvoicesToQboFailures>>;
  status?: Maybe<InvoiceToQboBatchJobStatus>;
};

export type InvoicesToQboFailures = {
  __typename?: 'InvoicesToQBOFailures';
  invoice: Invoice;
  message: Scalars['String'];
};

export type InvoicesTotals = {
  __typename?: 'InvoicesTotals';
  chart?: Maybe<Array<InvoicesTotalsChartEntry>>;
  dateIncrement: DateIncrement;
  filteredTotals: FilteredTotals;
  yearlyTotals?: Maybe<YearlyTotals>;
};

export type InvoicesTotalsChartEntry = {
  __typename?: 'InvoicesTotalsChartEntry';
  data: Array<InvoicesTotalsChartEntryData>;
  total: Scalars['Float'];
  xAxis: Scalars['ISO8601Date'];
};

export type InvoicesTotalsChartEntryData = {
  __typename?: 'InvoicesTotalsChartEntryData';
  amount: Scalars['Float'];
  status: InvoiceStatus;
};

export type Item = {
  __typename?: 'Item';
  earnings: Array<Earning>;
  employee: Scalars['String'];
  id: Scalars['String'];
  netPay: Scalars['String'];
  paymentMethod: Scalars['String'];
  payroll: Scalars['String'];
  status: Scalars['String'];
  taxes: Array<Tax>;
};


export type LineItem = {
  __typename?: 'LineItem';
  amount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type LineItemTaxConfiguration = {
  __typename?: 'LineItemTaxConfiguration';
  consultingServices: Scalars['Boolean'];
  expenses: Scalars['Boolean'];
  fixedFeeServices: Scalars['Boolean'];
  hourlyServices: Scalars['Boolean'];
  otherItems: Scalars['Boolean'];
  retainerCollection: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of LogOnboardingEvent */
export type LogOnboardingEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  event: OnboardingEvent;
};

/** Autogenerated return type of LogOnboardingEvent. */
export type LogOnboardingEventPayload = {
  __typename?: 'LogOnboardingEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of LogSignUpInformation */
export type LogSignUpInformationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationLocation?: Maybe<Scalars['String']>;
  organizationSize?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  howDidYouHearAboutUs?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LogSignUpInformation. */
export type LogSignUpInformationPayload = {
  __typename?: 'LogSignUpInformationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  icp?: Maybe<Scalars['Boolean']>;
};

export type Milestone = {
  __typename?: 'Milestone';
  completedTasksCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
  tasksCount: Scalars['Int'];
};

/** The connection type for Milestone. */
export type MilestoneConnection = {
  __typename?: 'MilestoneConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Milestone>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MilestoneEdge = {
  __typename?: 'MilestoneEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Milestone>;
};

/** Filters for milestones */
export type MilestoneFiltersInput = {
  projectId?: Maybe<Scalars['ID']>;
  phaseTemplateId?: Maybe<Scalars['ID']>;
  dueDates?: Maybe<Array<TaskDueDate>>;
  excludeInactiveProjects?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addClient?: Maybe<AddClientPayload>;
  addConsultantCompany?: Maybe<AddConsultantCompanyPayload>;
  addConsultantContact?: Maybe<AddConsultantContactPayload>;
  addInvoiceAttachments?: Maybe<InvoiceAddAttachmentPayload>;
  addInvoicePayment?: Maybe<InvoiceAddPaymentPayload>;
  admin: AdminMutations;
  approveOrRejectEntryRow?: Maybe<ApproveOrRejectEntryRowPayload>;
  archiveProject?: Maybe<ArchiveProjectPayload>;
  archiveRoleTemplate?: Maybe<ArchiveRoleTemplatePayload>;
  /** This adds a consultant bill to an invoice such that it's visible internally, i.e., from the POV of the org */
  assignConsultantBillToInvoice?: Maybe<AssignConsultantBillToInvoicePayload>;
  assignRoleToAllPhases?: Maybe<AssignRoleToAllPhasesPayload>;
  assistantMessage?: Maybe<CreateAssistantMessagePayload>;
  /** This adds a previously-attached consultant bill to its invoice such that it's visible externally, i.e., from the POV of the invoice viewer */
  attachConsultantBillToInvoice?: Maybe<AttachConsultantBillToInvoicePayload>;
  attachInvoiceExpense?: Maybe<InvoiceAttachExpensePayload>;
  batchSendExpenseToQuickbooks?: Maybe<ExpenseBatchSendToQuickbooksPayload>;
  batchSendInvoiceToQuickbooks?: Maybe<InvoiceBatchSendToQuickbooksPayload>;
  bulkCreateEntryGroupsWithOverhead?: Maybe<BulkCreateOverheadEntryGroupsPayload>;
  bulkCreateStaffingRoleAllocations?: Maybe<BulkCreateStaffingRoleAllocationsPayload>;
  bulkCreateTimeOffPolicyAssignments?: Maybe<BulkCreateTimeOffPolicyAssignmentsPayload>;
  bulkEditEntriesToTargetActivity?: Maybe<BulkEditEntriesToTargetActivityPayload>;
  bulkEditEntriesToTargetPhase?: Maybe<BulkEditEntriesToTargetPhasePayload>;
  bulkEditEntriesToTargetRoleTemplate?: Maybe<BulkEditEntriesToTargetRoleTemplatePayload>;
  bulkGenerateStaffingAllocations?: Maybe<BulkGenerateStaffingAllocationsPayload>;
  bulkUpdateStaffingOverheadAllocations?: Maybe<BulkUpdateStaffingOverheadAllocationsPayload>;
  cancelTimeOffRequest?: Maybe<CancelTimeOffRequestPayload>;
  changeInvoiceVersion?: Maybe<InvoiceChangeVersionPayload>;
  checkForExistingUser?: Maybe<CheckForExistingUserPayload>;
  configureInvoiceReminder?: Maybe<ConfigureInvoiceReminderPayload>;
  copyAllocationsFromPreviousWeek?: Maybe<CopyAllocationsFromPreviousWeekPayload>;
  copyTimeOffHolidaysYear?: Maybe<CopyTimeOffHolidaysYearPayload>;
  createActivity?: Maybe<CreateActivityPayload>;
  createAdminTimeOffAccrual?: Maybe<CreateAdminTimeOffAccrualPayload>;
  createCategory?: Maybe<CreateCategoryPayload>;
  createClientAccount?: Maybe<CreateClientAccountPayload>;
  createClientLoginLink?: Maybe<CreateClientLoginLinkPayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createCompensation?: Maybe<CreateCompensationPayload>;
  createConsultantBill?: Maybe<CreateConsultantBillPayload>;
  createConsultantTemplate?: Maybe<CreateConsultantTemplatePayload>;
  createEntry?: Maybe<CreateEntryPayload>;
  createEntryGroupWithOverhead?: Maybe<CreateEntryGroupWithOverheadPayload>;
  createEntryGroupWithProject?: Maybe<CreateEntryGroupPayload>;
  createEntryRow?: Maybe<CreateEntryRowPayload>;
  createEntryWithOverhead?: Maybe<CreateEntryWithOverheadPayload>;
  createExpense?: Maybe<CreateExpensePayload>;
  createExpenseCategory?: Maybe<CreateExpenseCategoryPayload>;
  createExpenseRate?: Maybe<CreateExpenseRatePayload>;
  createExpenseV2?: Maybe<CreateExpenseV2Payload>;
  createInvoice?: Maybe<InvoiceCreatePayload>;
  createInvoicePaymentIntent?: Maybe<InvoiceCreatePaymentIntentPayload>;
  createMilestone?: Maybe<CreateMilestonePayload>;
  createOffCyclePayroll?: Maybe<CreateOffCyclePayrollPayload>;
  createOverhead?: Maybe<CreateOverheadPayload>;
  createPaySchedule?: Maybe<CreatePaySchedulePayload>;
  createPaymentMethodSetupIntent?: Maybe<CreatePaymentMethodSetupIntentPayload>;
  createPayroll?: Maybe<CreatePayrollPayload>;
  createPayrollCompany?: Maybe<CreatePayrollCompanyPayload>;
  createPayrollContractor?: Maybe<CreatePayrollContractorPayload>;
  createPayrollEmployee?: Maybe<CreatePayrollEmployeePayload>;
  createPayrollEmployeeEarningRate?: Maybe<CreatePayrollEmployeeEarningRatePayload>;
  createPayrollWaitlister?: Maybe<CreatePayrollWaitlisterPayload>;
  createPayrollWorkplace?: Maybe<CreatePayrollWorkplacePayload>;
  createPhase?: Maybe<CreatePhasePayload>;
  createPhaseFromTemplate?: Maybe<CreatePhaseFromTemplatePayload>;
  createPhaseTemplate?: Maybe<CreatePhaseTemplatePayload>;
  createPolicy?: Maybe<CreatePolicyPayload>;
  createProfile?: Maybe<CreateProfilePayload>;
  createProject?: Maybe<CreateProjectPayload>;
  createProjectConsultant?: Maybe<CreatePayload>;
  createProjectFromTemplate?: Maybe<CreateProjectFromTemplatePayload>;
  createProjectTemplate?: Maybe<CreateProjectTemplatePayload>;
  createRate?: Maybe<CreateRatePayload>;
  createRateTable?: Maybe<CreateRateTablePayload>;
  createRole?: Maybe<CreateRolePayload>;
  createRoleTemplateAndRate?: Maybe<CreateRoleTemplateAndRatePayload>;
  createSalesforceLead?: Maybe<CreateSalesforceLeadPayload>;
  createSignatoryItemAndOnboardingLink?: Maybe<CreateSignatoryItemAndOnboardingLinkPayload>;
  createStaffingRoleAllocation?: Maybe<CreateStaffingRoleAllocationPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createTaxRate?: Maybe<CreateTaxRatePayload>;
  createTemplateFromProject?: Maybe<CreateTemplateFromProjectPayload>;
  createTerm?: Maybe<CreateTermPayload>;
  createTimeOffHoliday?: Maybe<CreateTimeOffHolidayPayload>;
  createTimeOffHolidayPolicy?: Maybe<CreateTimeOffHolidayPolicyPayload>;
  createTimeOffPolicy?: Maybe<CreateTimeOffPolicyPayload>;
  createTimeOffRequest?: Maybe<CreateTimeOffRequestPayload>;
  deactivateClient?: Maybe<DeactivateClientPayload>;
  deactivateConsultantCompany?: Maybe<DeactivateConsultantCompanyPayload>;
  deactivateConsultantContact?: Maybe<DeactivateConsultantContactPayload>;
  deactivateProfile?: Maybe<ProfileDeactivatePayload>;
  deactivateRateTable?: Maybe<DeactivateRateTablePayload>;
  deactivateTimeOffPolicy?: Maybe<DeactivateTimeOffPolicyPayload>;
  deleteActivity?: Maybe<DeleteActivityPayload>;
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  deleteClient?: Maybe<DeleteClientPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteCompensation?: Maybe<DeleteCompensationPayload>;
  deleteConsultantBill?: Maybe<DeleteConsultantBillPayload>;
  deleteConsultantCompany?: Maybe<DeleteConsultantCompanyPayload>;
  deleteConsultantContact?: Maybe<DeleteConsultantContactPayload>;
  deleteConsultantTemplate?: Maybe<DeleteConsultantTemplatePayload>;
  deleteCosts?: Maybe<DeleteCostsPayload>;
  deleteEntryGroup?: Maybe<DeleteEntryGroupPayload>;
  deleteEntryRow?: Maybe<DeleteEntryRowPayload>;
  deleteExpense?: Maybe<DeleteExpensePayload>;
  deleteExpenseCategory?: Maybe<DeleteExpenseCategoryPayload>;
  deleteInvoice?: Maybe<InvoiceDeletePayload>;
  deleteInvoicePayments?: Maybe<InvoicePaymentDeletePayload>;
  deleteInvoiceReminder?: Maybe<InvoiceReminderDeletePayload>;
  deleteMilestone?: Maybe<DeleteMilestonePayload>;
  deleteOverhead?: Maybe<DeleteOverheadPayload>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  deletePhase?: Maybe<DeletePhasePayload>;
  deletePhaseTemplate?: Maybe<DeletePhaseTemplatePayload>;
  deletePolicy?: Maybe<DeletePolicyPayload>;
  deleteProfile?: Maybe<DeleteProfilePayload>;
  deleteProject?: Maybe<DeleteProjectPayload>;
  deleteProjectConsultant?: Maybe<DeletePayload>;
  deleteProjectTemplate?: Maybe<DeleteProjectTemplatePayload>;
  deleteRate?: Maybe<DeleteRatePayload>;
  deleteRole?: Maybe<DeleteRolePayload>;
  deleteTask?: Maybe<DeleteTaskPayload>;
  deleteTaxRate?: Maybe<DeleteTaxRatePayload>;
  deleteTerm?: Maybe<DeleteTermPayload>;
  deleteTimeOffHoliday?: Maybe<DeleteTimeOffHolidayPayload>;
  deleteTimeOffPolicyAssignment?: Maybe<DeleteTimeOffPolicyAssignmentPayload>;
  deleteTimeOffRequest?: Maybe<DeleteTimeOffRequestPayload>;
  /** Detaches a Monograph Client from a QuickBooks Customer. */
  detachClient?: Maybe<DetachClientPayload>;
  /** Detaches a Monograph Consultant Company from a QuickBooks Vendor. */
  detachConsultantCompany?: Maybe<DetachConsultantCompanyPayload>;
  disconnectOrganizationQuickbooks?: Maybe<DisconnectOrganizationQuickbooksPayload>;
  duplicateInvoice?: Maybe<InvoiceDuplicatePayload>;
  duplicatePolicy?: Maybe<DuplicatePolicyPayload>;
  duplicateProject?: Maybe<DuplicateProjectPayload>;
  duplicateRateTable?: Maybe<DuplicateRateTablePayload>;
  editComment?: Maybe<EditCommentPayload>;
  editInvoicePayment?: Maybe<InvoiceEditPaymentPayload>;
  editMilestone?: Maybe<EditMilestonePayload>;
  editPaymentMethod?: Maybe<EditPaymentMethodPayload>;
  editRateTable?: Maybe<EditRateTablePayload>;
  editTask?: Maybe<EditTaskPayload>;
  editTimeOffRequest?: Maybe<EditTimeOffRequestPayload>;
  exportUnbilledProjectsReport?: Maybe<ExportUnbilledProjectsReportPayload>;
  generateCompanyPayrollInsuranceLink?: Maybe<GenerateCompanyInsuranceLinkPayload>;
  generateCompanyPayrollOnboardingLink?: Maybe<GenerateCompanyOnboardingLinkPayload>;
  generateCompanyPayrollRetirementLink?: Maybe<GenerateCompanyRetirementLinkPayload>;
  generateEmployeePayrollOnboardingLink?: Maybe<GenerateEmployeeOnboardingLinkPayload>;
  generatePayrollRunPayrollLink?: Maybe<GenerateRunPayrollLinkPayload>;
  generateStaffingAllocations?: Maybe<GenerateStaffingAllocationsPayload>;
  globalRebalanceAllocations?: Maybe<GlobalRebalanceAllocationsPayload>;
  importAllVendorsFromQuickbooks?: Maybe<ImportAllVendorsFromQuickbooksPayload>;
  importCustomersFromQbo?: Maybe<ImportCustomersFromQboPayload>;
  importQboCosts?: Maybe<ImportQboCostsPayload>;
  importSpecificVendorsFromQuickbooks?: Maybe<ImportSpecificVendorsFromQuickbooksPayload>;
  initiateStripeAccountOnboarding?: Maybe<StripeAccountInitiateOnboardingPayload>;
  invoiceBuilder: InvoiceBuilderMutations;
  logOnboardingEvent?: Maybe<LogOnboardingEventPayload>;
  logSignUpInformation?: Maybe<LogSignUpInformationPayload>;
  markConsultantBillAsOpen?: Maybe<ConsultantBillMarkAsOpenPayload>;
  markConsultantBillAsPaid?: Maybe<ConsultantBillMarkAsPaidPayload>;
  markInvoiceAsOpen?: Maybe<InvoiceMarkAsOpenPayload>;
  markReimburseAsPaid?: Maybe<ExpenseMarkReimburseAsPaidPayload>;
  markTaskComplete?: Maybe<TaskMarkCompletePayload>;
  processQboCustomers?: Maybe<ProcessQboCustomersPayload>;
  reactivateClient?: Maybe<ReactivateClientPayload>;
  reactivateConsultantCompany?: Maybe<ReactivateConsultantCompanyPayload>;
  reactivateConsultantContact?: Maybe<ReactivateConsultantContactPayload>;
  reactivateProfile?: Maybe<ProfileReactivatePayload>;
  reactivateRateTable?: Maybe<ReactivateRateTablePayload>;
  removeAttachedInvoiceExpense?: Maybe<InvoiceRemoveAttachedExpensePayload>;
  /** This removes a consultant bill's internally-visible invoice (and its externally-visible invoice, if applicable) */
  removeConsultantBillInvoiceAssignment?: Maybe<RemoveConsultantBillInvoiceAssignmentPayload>;
  /** This removes a consultant bill's externally-visible invoice. */
  removeConsultantBillInvoiceAttachment?: Maybe<RemoveConsultantBillInvoiceAttachmentPayload>;
  removeInvoiceAttachment?: Maybe<InvoiceRemoveAttachmentPayload>;
  removeLinkedPhases?: Maybe<RemoveLinkedPhasesPayload>;
  /** Create or find a user by their email and send an email link thatcompletes the process of attaching the payment method used to the user for future use */
  requestSavePaymentDetails?: Maybe<RequestSavePaymentDetailsPayload>;
  resyncStripeAccount?: Maybe<StripeAccountResyncPayload>;
  sendInvoicePaymentLink?: Maybe<InvoiceSendPaymentLinkPayload>;
  sendInvoiceReminderEmail?: Maybe<InvoiceSendReminderEmailPayload>;
  sendInvoiceToQuickbooks?: Maybe<InvoiceSendToQuickbooksPayload>;
  sendProfileInvitation?: Maybe<SendProfileInvitationPayload>;
  setPrimaryCompanyContact?: Maybe<SetPrimaryCompanyContactPayload>;
  startExport: StartExportMutations;
  stopCompensation?: Maybe<StopCompensationPayload>;
  syncClientToQbo?: Maybe<SyncClientToQboPayload>;
  syncConsultantBillWithQuickbooks?: Maybe<SyncConsultantBillWithQuickbooksPayload>;
  syncConsultantCompanyWithQuickbooks?: Maybe<SyncConsultantCompanyWithQuickbooksPayload>;
  syncImportCustomersFromQbo?: Maybe<SyncImportCustomersFromQboPayload>;
  syncQboTaxRates?: Maybe<TriggerSyncTaxRatesPayload>;
  syncStripeAttributes?: Maybe<SyncStripeAttributesPayload>;
  syncTermsWithQuickbooks?: Maybe<SyncTermsWithQuickbooksPayload>;
  toggleOrganization?: Maybe<ToggleOrganizationMutationPayload>;
  toggleTaskComplete?: Maybe<TaskToggleCompletePayload>;
  unarchiveProject?: Maybe<UnarchiveProjectPayload>;
  unarchiveRoleTemplate?: Maybe<UnarchiveRoleTemplatePayload>;
  updateActivity?: Maybe<UpdateActivityPayload>;
  updateAjeraIdentifier?: Maybe<UpdateAjeraIdentifierPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateClient?: Maybe<UpdateClientPayload>;
  updateCompensation?: Maybe<UpdateCompensationPayload>;
  updateConsultantBill?: Maybe<UpdateConsultantBillPayload>;
  updateConsultantCompany?: Maybe<UpdateConsultantCompanyPayload>;
  updateConsultantContact?: Maybe<UpdateConsultantContactPayload>;
  updateConsultantTemplate?: Maybe<UpdateConsultantTemplatePayload>;
  updateConsultantTemplateQuickbooksMapping?: Maybe<UpdateConsultantTemplateQuickbooksMappingPayload>;
  updateCosts?: Maybe<UpdateCostsPayload>;
  updateEntry?: Maybe<UpdateEntryPayload>;
  updateEntryGroupAllocation?: Maybe<UpdateEntryGroupAllocationPayload>;
  updateEntryGroupPhase?: Maybe<UpdateEntryGroupPhasePayload>;
  updateEntryRowActivity?: Maybe<UpdateEntryRowActivityPayload>;
  updateExpense?: Maybe<UpdateExpensePayload>;
  updateExpenseCategory?: Maybe<UpdateExpenseCategoryPayload>;
  updateExpenseCategoryForProject?: Maybe<UpdateExpenseCategoryForProjectPayload>;
  updateExpenseCategoryQuickbooksAccount?: Maybe<UpdateExpenseCategoryQuickbooksAccountPayload>;
  updateExpenseCategoryQuickbooksService?: Maybe<UpdateExpenseCategoryQuickbooksServicePayload>;
  updateExpenseRate?: Maybe<UpdateExpenseRatePayload>;
  updateInsightsEmailPreference?: Maybe<UpdateInsightsEmailPreferencePayload>;
  updateInvoice?: Maybe<InvoiceUpdatePayload>;
  /** Mutation to update billing destination in Quickbooks to send invoice. */
  updateInvoiceBillingDestination?: Maybe<InvoiceUpdateBillingDestinationPayload>;
  /** Update display options for invoice and its project */
  updateInvoiceDisplayOptions?: Maybe<InvoiceUpdateDisplayOptionsPayload>;
  updateInvoiceFooter?: Maybe<UpdateInvoiceFooterPayload>;
  updateInvoiceInternalNote?: Maybe<InvoiceUpdateInternalNotePayload>;
  updateInvoicePaymentIntent?: Maybe<InvoiceUpdatePaymentIntentPayload>;
  updateInvoicePaymentOptions?: Maybe<UpdateInvoicePaymentOptionsPayload>;
  updateOrganizationActivityRequired?: Maybe<UpdateActivityRequiredPayload>;
  updateOrganizationAddress?: Maybe<UpdateOrganizationAddressPayload>;
  updateOrganizationConsultantMarkup?: Maybe<UpdateOrganizationConsultantMarkupPayload>;
  updateOrganizationConsultantMarkupQuickbooksMapping?: Maybe<UpdateConsultantMarkupQuickbooksMappingPayload>;
  updateOrganizationCurrency?: Maybe<UpdateOrganizationCurrencyPayload>;
  updateOrganizationDefaultUtilizationInfo?: Maybe<UpdateDefaultUtilizationInfoPayload>;
  updateOrganizationEmailReplyToAddress?: Maybe<UpdateOrganizationEmailReplyToAddressPayload>;
  updateOrganizationEmailSenderName?: Maybe<UpdateOrganizationEmailSenderNamePayload>;
  updateOrganizationLocale?: Maybe<UpdateOrganizationLocalePayload>;
  updateOrganizationLogo?: Maybe<UpdateOrganizationLogoPayload>;
  updateOrganizationName?: Maybe<UpdateOrganizationNamePayload>;
  updateOrganizationNonReimbursableExpensesAccount?: Maybe<UpdateNonReimbursableExpensesAccountPayload>;
  updateOrganizationOnlinePaymentFeeAccount?: Maybe<UpdateOnlinePaymentFeeAccountPayload>;
  updateOrganizationPaymentDepositAccount?: Maybe<UpdatePaymentDepositAccountPayload>;
  updateOrganizationRealization?: Maybe<UpdateOrganizationRealizationPayload>;
  updateOrganizationReimbursableExpensesAccount?: Maybe<UpdateReimbursableExpensesAccountPayload>;
  updateOrganizationRetainerQuickbooksMapping?: Maybe<UpdateRetainersQuickbooksMappingPayload>;
  updateOrganizationStripePayoutAccount?: Maybe<UpdateStripePayoutAccountPayload>;
  updateOrganizationTelephone?: Maybe<UpdateOrganizationTelephonePayload>;
  updateOrganizationTimeZone?: Maybe<UpdateOrganizationTimeZonePayload>;
  updateOrganizationUnitOfMeasurement?: Maybe<UpdateOrganizationUnitOfMeasurementPayload>;
  updateOrganizationWebsite?: Maybe<UpdateOrganizationWebsitePayload>;
  updateOverhead?: Maybe<UpdateOverheadPayload>;
  updateOverheadMultiplier?: Maybe<UpdateOverheadMultiplierPayload>;
  updatePayrollCompany?: Maybe<UpdatePayrollCompanyPayload>;
  updatePayrollContractor?: Maybe<UpdatePayrollContractorPayload>;
  updatePayrollEmployee?: Maybe<UpdatePayrollEmployeePayload>;
  updatePayrollEmploymentType?: Maybe<UpdatePayrollEmploymentTypePayload>;
  updatePayrollWorkplace?: Maybe<UpdatePayrollWorkplacePayload>;
  updatePermission?: Maybe<UpdatePermissionPayload>;
  updatePhase?: Maybe<UpdatePhasePayload>;
  updatePhaseConsultants?: Maybe<UpdatePhaseConsultantsPayload>;
  updatePhasePosition?: Maybe<UpdatePhasePositionPayload>;
  updatePhaseStatus?: Maybe<UpdatePhaseStatusPayload>;
  updatePhaseTemplate?: Maybe<UpdatePhaseTemplatePayload>;
  updatePhaseTemplateQuickbooksMapping?: Maybe<UpdatePhaseTemplateQuickbooksMappingPayload>;
  updatePlanableBudget?: Maybe<PlanableBudgetUpdatePayload>;
  updatePolicy?: Maybe<UpdatePolicyPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProfileDefaultRoleTemplate?: Maybe<UpdateProfileDefaultRoleTemplatePayload>;
  updateProfileRoleTemplate?: Maybe<UpdateProfileRoleTemplatePayload>;
  updateProfileSettings?: Maybe<UpdateProfileSettingsPayload>;
  updateProfileUtilizationRate?: Maybe<UpdateUtilizationRatePayload>;
  updateProjectAccounting?: Maybe<ProjectAccountingUpdatePayload>;
  updateProjectBasicInfo?: Maybe<ProjectBasicInfoUpdatePayload>;
  updateProjectConsultant?: Maybe<UpdateProjectConsultantPayload>;
  updateProjectConsultantsFee?: Maybe<ProjectConsultantsFeeUpdatePayload>;
  updateProjectCost?: Maybe<ProjectCostUpdatePayload>;
  updateProjectShareable?: Maybe<ProjectShareableUpdatePayload>;
  updateProjectStatus?: Maybe<UpdateProjectStatusPayload>;
  updateProjectTemplateBasicInfo?: Maybe<ProjectTemplateBasicInfoUpdatePayload>;
  updateProjectTemplateConsultantsFee?: Maybe<ProjectTemplateConsultantsFeeUpdatePayload>;
  updateRate?: Maybe<UpdateRatePayload>;
  updateRoleHours?: Maybe<UpdateRoleHoursPayload>;
  updateRolePosition?: Maybe<UpdateRolePositionPayload>;
  updateRoleProfile?: Maybe<UpdateRoleProfilePayload>;
  /** Updates both a role and a role template. Used in ART environment */
  updateRoleRoleTemplateAndRate?: Maybe<UpdateRoleRoleTemplateAndRatePayload>;
  updateRoleTemplate?: Maybe<UpdateRoleTemplatePayload>;
  updateTaxRate?: Maybe<UpdateTaxRatePayload>;
  updateTerm?: Maybe<UpdateTermPayload>;
  updateTimeOff?: Maybe<UpdateTimeOffPayload>;
  updateTimeOffHoliday?: Maybe<UpdateTimeOffHolidayPayload>;
  updateTimeOffPolicy?: Maybe<UpdateTimeOffPolicyPayload>;
  updateTimeOffRequest?: Maybe<UpdateTimeOffRequestPayload>;
  updateTimer?: Maybe<UpdateTimerPayload>;
  updateTimesheet?: Maybe<UpdateTimesheetPayload>;
  updateTimesheetLock?: Maybe<UpdateTimesheetLockPayload>;
  updateTimesheetLockDays?: Maybe<UpdateTimesheetLockDaysPayload>;
  updateTimesheetLockTime?: Maybe<UpdateTimesheetLockTimePayload>;
  updateTimesheetNotesExample?: Maybe<UpdateTimesheetNotesExamplePayload>;
  updateTimesheetNotesReminder?: Maybe<UpdateTimesheetNotesReminderPayload>;
  updateTimesheetReminder?: Maybe<UpdateTimesheetReminderPayload>;
  updateTimesheetReminderDay?: Maybe<UpdateTimesheetReminderDayPayload>;
  updateTimesheetReminderNote?: Maybe<UpdateTimesheetReminderNotePayload>;
  updateTimesheetReminderTime?: Maybe<UpdateTimesheetReminderTimePayload>;
  updateYearlyWorkingHours?: Maybe<UpdateYearlyWorkingHoursPayload>;
  upsertEntry?: Maybe<UpsertEntryPayload>;
  upsertEntryWithNotes?: Maybe<UpsertEntryWithNotesPayload>;
  viewInvoice?: Maybe<InvoiceViewPayload>;
};


export type MutationAddClientArgs = {
  input: AddClientInput;
};


export type MutationAddConsultantCompanyArgs = {
  input: AddConsultantCompanyInput;
};


export type MutationAddConsultantContactArgs = {
  input: AddConsultantContactInput;
};


export type MutationAddInvoiceAttachmentsArgs = {
  input: InvoiceAddAttachmentInput;
};


export type MutationAddInvoicePaymentArgs = {
  input: InvoiceAddPaymentInput;
};


export type MutationApproveOrRejectEntryRowArgs = {
  input: ApproveOrRejectEntryRowInput;
};


export type MutationArchiveProjectArgs = {
  input: ArchiveProjectInput;
};


export type MutationArchiveRoleTemplateArgs = {
  input: ArchiveRoleTemplateInput;
};


export type MutationAssignConsultantBillToInvoiceArgs = {
  input: AssignConsultantBillToInvoiceInput;
};


export type MutationAssignRoleToAllPhasesArgs = {
  input: AssignRoleToAllPhasesInput;
};


export type MutationAssistantMessageArgs = {
  input: CreateAssistantMessageInput;
};


export type MutationAttachConsultantBillToInvoiceArgs = {
  input: AttachConsultantBillToInvoiceInput;
};


export type MutationAttachInvoiceExpenseArgs = {
  input: InvoiceAttachExpenseInput;
};


export type MutationBatchSendExpenseToQuickbooksArgs = {
  input: ExpenseBatchSendToQuickbooksInput;
};


export type MutationBatchSendInvoiceToQuickbooksArgs = {
  input: InvoiceBatchSendToQuickbooksInput;
};


export type MutationBulkCreateEntryGroupsWithOverheadArgs = {
  input: BulkCreateOverheadEntryGroupsInput;
};


export type MutationBulkCreateStaffingRoleAllocationsArgs = {
  input: BulkCreateStaffingRoleAllocationsInput;
};


export type MutationBulkCreateTimeOffPolicyAssignmentsArgs = {
  input: BulkCreateTimeOffPolicyAssignmentsInput;
};


export type MutationBulkEditEntriesToTargetActivityArgs = {
  input: BulkEditEntriesToTargetActivityInput;
};


export type MutationBulkEditEntriesToTargetPhaseArgs = {
  input: BulkEditEntriesToTargetPhaseInput;
};


export type MutationBulkEditEntriesToTargetRoleTemplateArgs = {
  input: BulkEditEntriesToTargetRoleTemplateInput;
};


export type MutationBulkGenerateStaffingAllocationsArgs = {
  input: BulkGenerateStaffingAllocationsInput;
};


export type MutationBulkUpdateStaffingOverheadAllocationsArgs = {
  input: BulkUpdateStaffingOverheadAllocationsInput;
};


export type MutationCancelTimeOffRequestArgs = {
  input: CancelTimeOffRequestInput;
};


export type MutationChangeInvoiceVersionArgs = {
  input: InvoiceChangeVersionInput;
};


export type MutationCheckForExistingUserArgs = {
  input: CheckForExistingUserInput;
};


export type MutationConfigureInvoiceReminderArgs = {
  input: ConfigureInvoiceReminderInput;
};


export type MutationCopyAllocationsFromPreviousWeekArgs = {
  input: CopyAllocationsFromPreviousWeekInput;
};


export type MutationCopyTimeOffHolidaysYearArgs = {
  input: CopyTimeOffHolidaysYearInput;
};


export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


export type MutationCreateAdminTimeOffAccrualArgs = {
  input: CreateAdminTimeOffAccrualInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateClientAccountArgs = {
  input: CreateClientAccountInput;
};


export type MutationCreateClientLoginLinkArgs = {
  input: CreateClientLoginLinkInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCompensationArgs = {
  input: CreateCompensationInput;
};


export type MutationCreateConsultantBillArgs = {
  input: CreateConsultantBillInput;
};


export type MutationCreateConsultantTemplateArgs = {
  input: CreateConsultantTemplateInput;
};


export type MutationCreateEntryArgs = {
  input: CreateEntryInput;
};


export type MutationCreateEntryGroupWithOverheadArgs = {
  input: CreateEntryGroupWithOverheadInput;
};


export type MutationCreateEntryGroupWithProjectArgs = {
  input: CreateEntryGroupInput;
};


export type MutationCreateEntryRowArgs = {
  input: CreateEntryRowInput;
};


export type MutationCreateEntryWithOverheadArgs = {
  input: CreateEntryWithOverheadInput;
};


export type MutationCreateExpenseArgs = {
  input: CreateExpenseInput;
};


export type MutationCreateExpenseCategoryArgs = {
  input: CreateExpenseCategoryInput;
};


export type MutationCreateExpenseRateArgs = {
  input: CreateExpenseRateInput;
};


export type MutationCreateExpenseV2Args = {
  input: CreateExpenseV2Input;
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceCreateInput;
};


export type MutationCreateInvoicePaymentIntentArgs = {
  input: InvoiceCreatePaymentIntentInput;
};


export type MutationCreateMilestoneArgs = {
  input: CreateMilestoneInput;
};


export type MutationCreateOffCyclePayrollArgs = {
  input: CreateOffCyclePayrollInput;
};


export type MutationCreateOverheadArgs = {
  input: CreateOverheadInput;
};


export type MutationCreatePayScheduleArgs = {
  input: CreatePayScheduleInput;
};


export type MutationCreatePaymentMethodSetupIntentArgs = {
  input: CreatePaymentMethodSetupIntentInput;
};


export type MutationCreatePayrollArgs = {
  input: CreatePayrollInput;
};


export type MutationCreatePayrollCompanyArgs = {
  input: CreatePayrollCompanyInput;
};


export type MutationCreatePayrollContractorArgs = {
  input: CreatePayrollContractorInput;
};


export type MutationCreatePayrollEmployeeArgs = {
  input: CreatePayrollEmployeeInput;
};


export type MutationCreatePayrollEmployeeEarningRateArgs = {
  input: CreatePayrollEmployeeEarningRateInput;
};


export type MutationCreatePayrollWaitlisterArgs = {
  input: CreatePayrollWaitlisterInput;
};


export type MutationCreatePayrollWorkplaceArgs = {
  input: CreatePayrollWorkplaceInput;
};


export type MutationCreatePhaseArgs = {
  input: CreatePhaseInput;
};


export type MutationCreatePhaseFromTemplateArgs = {
  input: CreatePhaseFromTemplateInput;
};


export type MutationCreatePhaseTemplateArgs = {
  input: CreatePhaseTemplateInput;
};


export type MutationCreatePolicyArgs = {
  input: CreatePolicyInput;
};


export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateProjectConsultantArgs = {
  input: CreateInput;
};


export type MutationCreateProjectFromTemplateArgs = {
  input: CreateProjectFromTemplateInput;
};


export type MutationCreateProjectTemplateArgs = {
  input: CreateProjectTemplateInput;
};


export type MutationCreateRateArgs = {
  input: CreateRateInput;
};


export type MutationCreateRateTableArgs = {
  input: CreateRateTableInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateRoleTemplateAndRateArgs = {
  input: CreateRoleTemplateAndRateInput;
};


export type MutationCreateSalesforceLeadArgs = {
  input: CreateSalesforceLeadInput;
};


export type MutationCreateSignatoryItemAndOnboardingLinkArgs = {
  input: CreateSignatoryItemAndOnboardingLinkInput;
};


export type MutationCreateStaffingRoleAllocationArgs = {
  input: CreateStaffingRoleAllocationInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput;
};


export type MutationCreateTemplateFromProjectArgs = {
  input: CreateTemplateFromProjectInput;
};


export type MutationCreateTermArgs = {
  input: CreateTermInput;
};


export type MutationCreateTimeOffHolidayArgs = {
  input: CreateTimeOffHolidayInput;
};


export type MutationCreateTimeOffHolidayPolicyArgs = {
  input: CreateTimeOffHolidayPolicyInput;
};


export type MutationCreateTimeOffPolicyArgs = {
  input: CreateTimeOffPolicyInput;
};


export type MutationCreateTimeOffRequestArgs = {
  input: CreateTimeOffRequestInput;
};


export type MutationDeactivateClientArgs = {
  input: DeactivateClientInput;
};


export type MutationDeactivateConsultantCompanyArgs = {
  input: DeactivateConsultantCompanyInput;
};


export type MutationDeactivateConsultantContactArgs = {
  input: DeactivateConsultantContactInput;
};


export type MutationDeactivateProfileArgs = {
  input: ProfileDeactivateInput;
};


export type MutationDeactivateRateTableArgs = {
  input: DeactivateRateTableInput;
};


export type MutationDeactivateTimeOffPolicyArgs = {
  input: DeactivateTimeOffPolicyInput;
};


export type MutationDeleteActivityArgs = {
  input: DeleteActivityInput;
};


export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationDeleteCompensationArgs = {
  input: DeleteCompensationInput;
};


export type MutationDeleteConsultantBillArgs = {
  input: DeleteConsultantBillInput;
};


export type MutationDeleteConsultantCompanyArgs = {
  input: DeleteConsultantCompanyInput;
};


export type MutationDeleteConsultantContactArgs = {
  input: DeleteConsultantContactInput;
};


export type MutationDeleteConsultantTemplateArgs = {
  input: DeleteConsultantTemplateInput;
};


export type MutationDeleteCostsArgs = {
  input: DeleteCostsInput;
};


export type MutationDeleteEntryGroupArgs = {
  input: DeleteEntryGroupInput;
};


export type MutationDeleteEntryRowArgs = {
  input: DeleteEntryRowInput;
};


export type MutationDeleteExpenseArgs = {
  input: DeleteExpenseInput;
};


export type MutationDeleteExpenseCategoryArgs = {
  input: DeleteExpenseCategoryInput;
};


export type MutationDeleteInvoiceArgs = {
  input: InvoiceDeleteInput;
};


export type MutationDeleteInvoicePaymentsArgs = {
  input: InvoicePaymentDeleteInput;
};


export type MutationDeleteInvoiceReminderArgs = {
  input: InvoiceReminderDeleteInput;
};


export type MutationDeleteMilestoneArgs = {
  input: DeleteMilestoneInput;
};


export type MutationDeleteOverheadArgs = {
  input: DeleteOverheadInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePhaseArgs = {
  input: DeletePhaseInput;
};


export type MutationDeletePhaseTemplateArgs = {
  input: DeletePhaseTemplateInput;
};


export type MutationDeletePolicyArgs = {
  input: DeletePolicyInput;
};


export type MutationDeleteProfileArgs = {
  input: DeleteProfileInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationDeleteProjectConsultantArgs = {
  input: DeleteInput;
};


export type MutationDeleteProjectTemplateArgs = {
  input: DeleteProjectTemplateInput;
};


export type MutationDeleteRateArgs = {
  input: DeleteRateInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};


export type MutationDeleteTaxRateArgs = {
  input: DeleteTaxRateInput;
};


export type MutationDeleteTermArgs = {
  input: DeleteTermInput;
};


export type MutationDeleteTimeOffHolidayArgs = {
  input: DeleteTimeOffHolidayInput;
};


export type MutationDeleteTimeOffPolicyAssignmentArgs = {
  input: DeleteTimeOffPolicyAssignmentInput;
};


export type MutationDeleteTimeOffRequestArgs = {
  input: DeleteTimeOffRequestInput;
};


export type MutationDetachClientArgs = {
  input: DetachClientInput;
};


export type MutationDetachConsultantCompanyArgs = {
  input: DetachConsultantCompanyInput;
};


export type MutationDisconnectOrganizationQuickbooksArgs = {
  input: DisconnectOrganizationQuickbooksInput;
};


export type MutationDuplicateInvoiceArgs = {
  input: InvoiceDuplicateInput;
};


export type MutationDuplicatePolicyArgs = {
  input: DuplicatePolicyInput;
};


export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput;
};


export type MutationDuplicateRateTableArgs = {
  input: DuplicateRateTableInput;
};


export type MutationEditCommentArgs = {
  input: EditCommentInput;
};


export type MutationEditInvoicePaymentArgs = {
  input: InvoiceEditPaymentInput;
};


export type MutationEditMilestoneArgs = {
  input: EditMilestoneInput;
};


export type MutationEditPaymentMethodArgs = {
  input: EditPaymentMethodInput;
};


export type MutationEditRateTableArgs = {
  input: EditRateTableInput;
};


export type MutationEditTaskArgs = {
  input: EditTaskInput;
};


export type MutationEditTimeOffRequestArgs = {
  input: EditTimeOffRequestInput;
};


export type MutationExportUnbilledProjectsReportArgs = {
  input: ExportUnbilledProjectsReportInput;
};


export type MutationGenerateCompanyPayrollInsuranceLinkArgs = {
  input: GenerateCompanyInsuranceLinkInput;
};


export type MutationGenerateCompanyPayrollOnboardingLinkArgs = {
  input: GenerateCompanyOnboardingLinkInput;
};


export type MutationGenerateCompanyPayrollRetirementLinkArgs = {
  input: GenerateCompanyRetirementLinkInput;
};


export type MutationGenerateEmployeePayrollOnboardingLinkArgs = {
  input: GenerateEmployeeOnboardingLinkInput;
};


export type MutationGeneratePayrollRunPayrollLinkArgs = {
  input: GenerateRunPayrollLinkInput;
};


export type MutationGenerateStaffingAllocationsArgs = {
  input: GenerateStaffingAllocationsInput;
};


export type MutationGlobalRebalanceAllocationsArgs = {
  input: GlobalRebalanceAllocationsInput;
};


export type MutationImportAllVendorsFromQuickbooksArgs = {
  input: ImportAllVendorsFromQuickbooksInput;
};


export type MutationImportCustomersFromQboArgs = {
  input: ImportCustomersFromQboInput;
};


export type MutationImportQboCostsArgs = {
  input: ImportQboCostsInput;
};


export type MutationImportSpecificVendorsFromQuickbooksArgs = {
  input: ImportSpecificVendorsFromQuickbooksInput;
};


export type MutationInitiateStripeAccountOnboardingArgs = {
  input: StripeAccountInitiateOnboardingInput;
};


export type MutationLogOnboardingEventArgs = {
  input: LogOnboardingEventInput;
};


export type MutationLogSignUpInformationArgs = {
  input: LogSignUpInformationInput;
};


export type MutationMarkConsultantBillAsOpenArgs = {
  input: ConsultantBillMarkAsOpenInput;
};


export type MutationMarkConsultantBillAsPaidArgs = {
  input: ConsultantBillMarkAsPaidInput;
};


export type MutationMarkInvoiceAsOpenArgs = {
  input: InvoiceMarkAsOpenInput;
};


export type MutationMarkReimburseAsPaidArgs = {
  input: ExpenseMarkReimburseAsPaidInput;
};


export type MutationMarkTaskCompleteArgs = {
  input: TaskMarkCompleteInput;
};


export type MutationProcessQboCustomersArgs = {
  input: ProcessQboCustomersInput;
};


export type MutationReactivateClientArgs = {
  input: ReactivateClientInput;
};


export type MutationReactivateConsultantCompanyArgs = {
  input: ReactivateConsultantCompanyInput;
};


export type MutationReactivateConsultantContactArgs = {
  input: ReactivateConsultantContactInput;
};


export type MutationReactivateProfileArgs = {
  input: ProfileReactivateInput;
};


export type MutationReactivateRateTableArgs = {
  input: ReactivateRateTableInput;
};


export type MutationRemoveAttachedInvoiceExpenseArgs = {
  input: InvoiceRemoveAttachedExpenseInput;
};


export type MutationRemoveConsultantBillInvoiceAssignmentArgs = {
  input: RemoveConsultantBillInvoiceAssignmentInput;
};


export type MutationRemoveConsultantBillInvoiceAttachmentArgs = {
  input: RemoveConsultantBillInvoiceAttachmentInput;
};


export type MutationRemoveInvoiceAttachmentArgs = {
  input: InvoiceRemoveAttachmentInput;
};


export type MutationRemoveLinkedPhasesArgs = {
  input: RemoveLinkedPhasesInput;
};


export type MutationRequestSavePaymentDetailsArgs = {
  input: RequestSavePaymentDetailsInput;
};


export type MutationResyncStripeAccountArgs = {
  input: StripeAccountResyncInput;
};


export type MutationSendInvoicePaymentLinkArgs = {
  input: InvoiceSendPaymentLinkInput;
};


export type MutationSendInvoiceReminderEmailArgs = {
  input: InvoiceSendReminderEmailInput;
};


export type MutationSendInvoiceToQuickbooksArgs = {
  input: InvoiceSendToQuickbooksInput;
};


export type MutationSendProfileInvitationArgs = {
  input: SendProfileInvitationInput;
};


export type MutationSetPrimaryCompanyContactArgs = {
  input: SetPrimaryCompanyContactInput;
};


export type MutationStopCompensationArgs = {
  input: StopCompensationInput;
};


export type MutationSyncClientToQboArgs = {
  input: SyncClientToQboInput;
};


export type MutationSyncConsultantBillWithQuickbooksArgs = {
  input: SyncConsultantBillWithQuickbooksInput;
};


export type MutationSyncConsultantCompanyWithQuickbooksArgs = {
  input: SyncConsultantCompanyWithQuickbooksInput;
};


export type MutationSyncImportCustomersFromQboArgs = {
  input: SyncImportCustomersFromQboInput;
};


export type MutationSyncQboTaxRatesArgs = {
  input: TriggerSyncTaxRatesInput;
};


export type MutationSyncStripeAttributesArgs = {
  input: SyncStripeAttributesInput;
};


export type MutationSyncTermsWithQuickbooksArgs = {
  input: SyncTermsWithQuickbooksInput;
};


export type MutationToggleOrganizationArgs = {
  input: ToggleOrganizationMutationInput;
};


export type MutationToggleTaskCompleteArgs = {
  input: TaskToggleCompleteInput;
};


export type MutationUnarchiveProjectArgs = {
  input: UnarchiveProjectInput;
};


export type MutationUnarchiveRoleTemplateArgs = {
  input: UnarchiveRoleTemplateInput;
};


export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};


export type MutationUpdateAjeraIdentifierArgs = {
  input: UpdateAjeraIdentifierInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateCompensationArgs = {
  input: UpdateCompensationInput;
};


export type MutationUpdateConsultantBillArgs = {
  input: UpdateConsultantBillInput;
};


export type MutationUpdateConsultantCompanyArgs = {
  input: UpdateConsultantCompanyInput;
};


export type MutationUpdateConsultantContactArgs = {
  input: UpdateConsultantContactInput;
};


export type MutationUpdateConsultantTemplateArgs = {
  input: UpdateConsultantTemplateInput;
};


export type MutationUpdateConsultantTemplateQuickbooksMappingArgs = {
  input: UpdateConsultantTemplateQuickbooksMappingInput;
};


export type MutationUpdateCostsArgs = {
  input: UpdateCostsInput;
};


export type MutationUpdateEntryArgs = {
  input: UpdateEntryInput;
};


export type MutationUpdateEntryGroupAllocationArgs = {
  input: UpdateEntryGroupAllocationInput;
};


export type MutationUpdateEntryGroupPhaseArgs = {
  input: UpdateEntryGroupPhaseInput;
};


export type MutationUpdateEntryRowActivityArgs = {
  input: UpdateEntryRowActivityInput;
};


export type MutationUpdateExpenseArgs = {
  input: UpdateExpenseInput;
};


export type MutationUpdateExpenseCategoryArgs = {
  input: UpdateExpenseCategoryInput;
};


export type MutationUpdateExpenseCategoryForProjectArgs = {
  input: UpdateExpenseCategoryForProjectInput;
};


export type MutationUpdateExpenseCategoryQuickbooksAccountArgs = {
  input: UpdateExpenseCategoryQuickbooksAccountInput;
};


export type MutationUpdateExpenseCategoryQuickbooksServiceArgs = {
  input: UpdateExpenseCategoryQuickbooksServiceInput;
};


export type MutationUpdateExpenseRateArgs = {
  input: UpdateExpenseRateInput;
};


export type MutationUpdateInsightsEmailPreferenceArgs = {
  input: UpdateInsightsEmailPreferenceInput;
};


export type MutationUpdateInvoiceArgs = {
  input: InvoiceUpdateInput;
};


export type MutationUpdateInvoiceBillingDestinationArgs = {
  input: InvoiceUpdateBillingDestinationInput;
};


export type MutationUpdateInvoiceDisplayOptionsArgs = {
  input: InvoiceUpdateDisplayOptionsInput;
};


export type MutationUpdateInvoiceFooterArgs = {
  input: UpdateInvoiceFooterInput;
};


export type MutationUpdateInvoiceInternalNoteArgs = {
  input: InvoiceUpdateInternalNoteInput;
};


export type MutationUpdateInvoicePaymentIntentArgs = {
  input: InvoiceUpdatePaymentIntentInput;
};


export type MutationUpdateInvoicePaymentOptionsArgs = {
  input: UpdateInvoicePaymentOptionsInput;
};


export type MutationUpdateOrganizationActivityRequiredArgs = {
  input: UpdateActivityRequiredInput;
};


export type MutationUpdateOrganizationAddressArgs = {
  input: UpdateOrganizationAddressInput;
};


export type MutationUpdateOrganizationConsultantMarkupArgs = {
  input: UpdateOrganizationConsultantMarkupInput;
};


export type MutationUpdateOrganizationConsultantMarkupQuickbooksMappingArgs = {
  input: UpdateConsultantMarkupQuickbooksMappingInput;
};


export type MutationUpdateOrganizationCurrencyArgs = {
  input: UpdateOrganizationCurrencyInput;
};


export type MutationUpdateOrganizationDefaultUtilizationInfoArgs = {
  input: UpdateDefaultUtilizationInfoInput;
};


export type MutationUpdateOrganizationEmailReplyToAddressArgs = {
  input: UpdateOrganizationEmailReplyToAddressInput;
};


export type MutationUpdateOrganizationEmailSenderNameArgs = {
  input: UpdateOrganizationEmailSenderNameInput;
};


export type MutationUpdateOrganizationLocaleArgs = {
  input: UpdateOrganizationLocaleInput;
};


export type MutationUpdateOrganizationLogoArgs = {
  input: UpdateOrganizationLogoInput;
};


export type MutationUpdateOrganizationNameArgs = {
  input: UpdateOrganizationNameInput;
};


export type MutationUpdateOrganizationNonReimbursableExpensesAccountArgs = {
  input: UpdateNonReimbursableExpensesAccountInput;
};


export type MutationUpdateOrganizationOnlinePaymentFeeAccountArgs = {
  input: UpdateOnlinePaymentFeeAccountInput;
};


export type MutationUpdateOrganizationPaymentDepositAccountArgs = {
  input: UpdatePaymentDepositAccountInput;
};


export type MutationUpdateOrganizationRealizationArgs = {
  input: UpdateOrganizationRealizationInput;
};


export type MutationUpdateOrganizationReimbursableExpensesAccountArgs = {
  input: UpdateReimbursableExpensesAccountInput;
};


export type MutationUpdateOrganizationRetainerQuickbooksMappingArgs = {
  input: UpdateRetainersQuickbooksMappingInput;
};


export type MutationUpdateOrganizationStripePayoutAccountArgs = {
  input: UpdateStripePayoutAccountInput;
};


export type MutationUpdateOrganizationTelephoneArgs = {
  input: UpdateOrganizationTelephoneInput;
};


export type MutationUpdateOrganizationTimeZoneArgs = {
  input: UpdateOrganizationTimeZoneInput;
};


export type MutationUpdateOrganizationUnitOfMeasurementArgs = {
  input: UpdateOrganizationUnitOfMeasurementInput;
};


export type MutationUpdateOrganizationWebsiteArgs = {
  input: UpdateOrganizationWebsiteInput;
};


export type MutationUpdateOverheadArgs = {
  input: UpdateOverheadInput;
};


export type MutationUpdateOverheadMultiplierArgs = {
  input: UpdateOverheadMultiplierInput;
};


export type MutationUpdatePayrollCompanyArgs = {
  input: UpdatePayrollCompanyInput;
};


export type MutationUpdatePayrollContractorArgs = {
  input: UpdatePayrollContractorInput;
};


export type MutationUpdatePayrollEmployeeArgs = {
  input: UpdatePayrollEmployeeInput;
};


export type MutationUpdatePayrollEmploymentTypeArgs = {
  input: UpdatePayrollEmploymentTypeInput;
};


export type MutationUpdatePayrollWorkplaceArgs = {
  input: UpdatePayrollWorkplaceInput;
};


export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};


export type MutationUpdatePhaseArgs = {
  input: UpdatePhaseInput;
};


export type MutationUpdatePhaseConsultantsArgs = {
  input: UpdatePhaseConsultantsInput;
};


export type MutationUpdatePhasePositionArgs = {
  input: UpdatePhasePositionInput;
};


export type MutationUpdatePhaseStatusArgs = {
  input: UpdatePhaseStatusInput;
};


export type MutationUpdatePhaseTemplateArgs = {
  input: UpdatePhaseTemplateInput;
};


export type MutationUpdatePhaseTemplateQuickbooksMappingArgs = {
  input: UpdatePhaseTemplateQuickbooksMappingInput;
};


export type MutationUpdatePlanableBudgetArgs = {
  input: PlanableBudgetUpdateInput;
};


export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProfileDefaultRoleTemplateArgs = {
  input: UpdateProfileDefaultRoleTemplateInput;
};


export type MutationUpdateProfileRoleTemplateArgs = {
  input: UpdateProfileRoleTemplateInput;
};


export type MutationUpdateProfileSettingsArgs = {
  input: UpdateProfileSettingsInput;
};


export type MutationUpdateProfileUtilizationRateArgs = {
  input: UpdateUtilizationRateInput;
};


export type MutationUpdateProjectAccountingArgs = {
  input: ProjectAccountingUpdateInput;
};


export type MutationUpdateProjectBasicInfoArgs = {
  input: ProjectBasicInfoUpdateInput;
};


export type MutationUpdateProjectConsultantArgs = {
  input: UpdateProjectConsultantInput;
};


export type MutationUpdateProjectConsultantsFeeArgs = {
  input: ProjectConsultantsFeeUpdateInput;
};


export type MutationUpdateProjectCostArgs = {
  input: ProjectCostUpdateInput;
};


export type MutationUpdateProjectShareableArgs = {
  input: ProjectShareableUpdateInput;
};


export type MutationUpdateProjectStatusArgs = {
  input: UpdateProjectStatusInput;
};


export type MutationUpdateProjectTemplateBasicInfoArgs = {
  input: ProjectTemplateBasicInfoUpdateInput;
};


export type MutationUpdateProjectTemplateConsultantsFeeArgs = {
  input: ProjectTemplateConsultantsFeeUpdateInput;
};


export type MutationUpdateRateArgs = {
  input: UpdateRateInput;
};


export type MutationUpdateRoleHoursArgs = {
  input: UpdateRoleHoursInput;
};


export type MutationUpdateRolePositionArgs = {
  input: UpdateRolePositionInput;
};


export type MutationUpdateRoleProfileArgs = {
  input: UpdateRoleProfileInput;
};


export type MutationUpdateRoleRoleTemplateAndRateArgs = {
  input: UpdateRoleRoleTemplateAndRateInput;
};


export type MutationUpdateRoleTemplateArgs = {
  input: UpdateRoleTemplateInput;
};


export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


export type MutationUpdateTermArgs = {
  input: UpdateTermInput;
};


export type MutationUpdateTimeOffArgs = {
  input: UpdateTimeOffInput;
};


export type MutationUpdateTimeOffHolidayArgs = {
  input: UpdateTimeOffHolidayInput;
};


export type MutationUpdateTimeOffPolicyArgs = {
  input: UpdateTimeOffPolicyInput;
};


export type MutationUpdateTimeOffRequestArgs = {
  input: UpdateTimeOffRequestInput;
};


export type MutationUpdateTimerArgs = {
  input: UpdateTimerInput;
};


export type MutationUpdateTimesheetArgs = {
  input: UpdateTimesheetInput;
};


export type MutationUpdateTimesheetLockArgs = {
  input: UpdateTimesheetLockInput;
};


export type MutationUpdateTimesheetLockDaysArgs = {
  input: UpdateTimesheetLockDaysInput;
};


export type MutationUpdateTimesheetLockTimeArgs = {
  input: UpdateTimesheetLockTimeInput;
};


export type MutationUpdateTimesheetNotesExampleArgs = {
  input: UpdateTimesheetNotesExampleInput;
};


export type MutationUpdateTimesheetNotesReminderArgs = {
  input: UpdateTimesheetNotesReminderInput;
};


export type MutationUpdateTimesheetReminderArgs = {
  input: UpdateTimesheetReminderInput;
};


export type MutationUpdateTimesheetReminderDayArgs = {
  input: UpdateTimesheetReminderDayInput;
};


export type MutationUpdateTimesheetReminderNoteArgs = {
  input: UpdateTimesheetReminderNoteInput;
};


export type MutationUpdateTimesheetReminderTimeArgs = {
  input: UpdateTimesheetReminderTimeInput;
};


export type MutationUpdateYearlyWorkingHoursArgs = {
  input: UpdateYearlyWorkingHoursInput;
};


export type MutationUpsertEntryArgs = {
  input: UpsertEntryInput;
};


export type MutationUpsertEntryWithNotesArgs = {
  input: UpsertEntryWithNotesInput;
};


export type MutationViewInvoiceArgs = {
  input: InvoiceViewInput;
};

export type OnboardStats = {
  __typename?: 'OnboardStats';
  organization?: Maybe<Steps>;
  worker?: Maybe<Steps>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  editedDefaults?: Maybe<Scalars['Boolean']>;
  editedTimesheets?: Maybe<Scalars['Boolean']>;
  guideWatched?: Maybe<Scalars['Boolean']>;
  hasMembers?: Maybe<Scalars['Boolean']>;
  hasProjects?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  onboardingComplete?: Maybe<Scalars['Boolean']>;
  reportsVisited?: Maybe<Scalars['Boolean']>;
  timesheetSubmit?: Maybe<Scalars['Boolean']>;
  timesheetTour?: Maybe<Scalars['Boolean']>;
  usedTimer?: Maybe<Scalars['Boolean']>;
};

/** All acceptable onboarding events */
export enum OnboardingEvent {
  ReportsVisited = 'REPORTS_VISITED',
  UsedTimer = 'USED_TIMER',
  TimesheetTour = 'TIMESHEET_TOUR',
  TimesheetSubmit = 'TIMESHEET_SUBMIT',
  GuideWatched = 'GUIDE_WATCHED',
  OnboardingCompleted = 'ONBOARDING_COMPLETED'
}

/** All available sign up industry options */
export enum OnboardingIndustry {
  /** Architecture */
  Architecture = 'ARCHITECTURE',
  /** Design Build */
  DesignBuild = 'DESIGN_BUILD',
  /** Interior Architecture */
  InteriorArchitecture = 'INTERIOR_ARCHITECTURE',
  /** Landscape Architecture */
  LandscapeArchitecture = 'LANDSCAPE_ARCHITECTURE',
  /** Lighting Design */
  LightingDesign = 'LIGHTING_DESIGN',
  /** MEP Engineer */
  MepEngineer = 'MEP_ENGINEER',
  /** Structural Engineer */
  StructuralEngineer = 'STRUCTURAL_ENGINEER',
  /** Not Listed */
  NotListed = 'NOT_LISTED'
}

/** All available sign up location options */
export enum OnboardingLocation {
  /** United States */
  UnitedStates = 'UNITED_STATES',
  /** Canada */
  Canada = 'CANADA',
  /** Australia */
  Australia = 'AUSTRALIA',
  /** New Zealand */
  NewZealand = 'NEW_ZEALAND',
  /** Other */
  Other = 'OTHER'
}

/** All available Check onboarding status types */
export enum OnboardingStatus {
  Completed = 'completed',
  NeedsAttention = 'needs_attention',
  Blocking = 'blocking'
}

/** All available Check org implentation statuses */
export enum OrgImplementationStatus {
  NeedsAttention = 'needs_attention',
  InReview = 'in_review',
  Completed = 'completed'
}

/** All Check org-onboarding steps that may block or require attentiom */
export enum OrgOnboardingSteps {
  BankAccount = 'bank_account',
  SetupParameters = 'setup_parameters',
  FilingAuthorization = 'filing_authorization'
}

export type Organization = {
  __typename?: 'Organization';
  activities: Array<Activity>;
  activityRequired: Scalars['Boolean'];
  address?: Maybe<Address>;
  applyAchOffer: Scalars['Boolean'];
  bqeDataProcessor?: Maybe<CustomerExternalDataProcessor>;
  businessPerformance: InsightsDashboardBusinessPerformance;
  categories?: Maybe<Array<Category>>;
  clients?: Maybe<Array<Client>>;
  connectedQboAccountsCount: Scalars['Int'];
  connectedToQbo: Scalars['Boolean'];
  consultantBills: Array<ConsultantBill>;
  consultantTemplates: Array<ConsultantTemplate>;
  createdAt: Scalars['ISO8601DateTime'];
  currency: Currency;
  declaredReferrer?: Maybe<Scalars['String']>;
  declaredSize?: Maybe<Scalars['Int']>;
  demo?: Maybe<Organization>;
  demoParent?: Maybe<Organization>;
  emailReplyToAddress?: Maybe<Scalars['String']>;
  emailSenderName?: Maybe<Scalars['String']>;
  expenseCategories?: Maybe<Array<ExpenseCategory>>;
  hasTimer: Scalars['Boolean'];
  hasUnmatchedQboCustomers?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invoiceFooter?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isDemo: Scalars['Boolean'];
  isQualified?: Maybe<Scalars['Boolean']>;
  isSubscribed: Scalars['Boolean'];
  locale: Locale;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overCapacityProfiles: Array<Profile>;
  overheadMultiplier?: Maybe<Scalars['Float']>;
  overheads: Array<Overhead>;
  paySchedule?: Maybe<PaySchedule>;
  payroll?: Maybe<Company>;
  phaseTemplates: Array<PhaseTemplate>;
  policies?: Maybe<Array<Policy>>;
  profileCount: Scalars['Int'];
  profiles: Array<Profile>;
  profilesWithIncompleteOnboardingCount: Scalars['Int'];
  profilesWithoutCompensation: Array<Profile>;
  profilesWithoutCompensationsCount: Scalars['Int'];
  projectLimit?: Maybe<Scalars['Int']>;
  projectTemplates: Array<ProjectTemplate>;
  projectsCount?: Maybe<Scalars['Int']>;
  projectsWithOverBudgetPhases: Array<Project>;
  projectsWithOverStaffedPhases: Array<Project>;
  qboInstances: Array<QboInstance>;
  /** @deprecated Use qboTaxMethod from QBOInstanceType instead */
  qboTaxMethod?: Maybe<QboTaxMethod>;
  quickbooksItems: Array<QuickbooksItem>;
  retainers?: Maybe<Array<Retainer>>;
  roleAudit?: Maybe<Scalars['Boolean']>;
  roleTemplates: Array<RoleTemplate>;
  settings: OrganizationSetting;
  size?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  staffOrg: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
  subscription?: Maybe<OrganizationSubscription>;
  subscriptionForOrgOrDemoParent?: Maybe<OrganizationSubscription>;
  telephone?: Maybe<Scalars['String']>;
  terms?: Maybe<Array<Term>>;
  timeOffHolidayPolicies: Array<TimeOffHolidayPolicy>;
  timeOffPolicies: Array<TimeOffPolicy>;
  timeZone: Scalars['String'];
  timer?: Maybe<Scalars['Boolean']>;
  timesheetLock: TimesheetLock;
  timesheetNotesExample?: Maybe<Scalars['String']>;
  timesheetNotesReminder: Scalars['Boolean'];
  timesheetReminder: TimesheetReminder;
  trialEndDate?: Maybe<Scalars['ISO8601Date']>;
  /** @deprecated Use field from QBOInstanceType instead */
  unassignedProjectCostsCount: Scalars['Int'];
  unprocessedQboCustomers: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  website?: Maybe<Scalars['String']>;
};


export type OrganizationOverheadsArgs = {
  excludeTimeOffOverheads?: Maybe<Scalars['Boolean']>;
};

/** The connection type for Organization. */
export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Organization>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Organization>;
};

export type OrganizationExport = {
  __typename?: 'OrganizationExport';
  projectPhaseJson?: Maybe<Scalars['String']>;
};

export type OrganizationFilter = {
  query?: Maybe<Scalars['String']>;
  isQualified?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSetting = {
  __typename?: 'OrganizationSetting';
  activityRequired: Scalars['Boolean'];
  ajeraEnabled: Scalars['Boolean'];
  applyConsultantMappingForMarkup: Scalars['Boolean'];
  applyUtilizationRateToExistingProfiles?: Maybe<Scalars['Boolean']>;
  checkId?: Maybe<Scalars['String']>;
  consultantMarkupQuickbooksServiceItem?: Maybe<QuickbooksItem>;
  costsImportStartDate?: Maybe<Scalars['ISO8601Date']>;
  defaultConsultantMarkup: Scalars['Float'];
  defaultHoursPerWeek?: Maybe<Scalars['Float']>;
  defaultRealizationRate?: Maybe<Scalars['Float']>;
  defaultUtilizationRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  multiQboInstanceEnabled: Scalars['Boolean'];
  nonReimbursableExpensesAccount?: Maybe<QboAccount>;
  onlinePaymentFeeAccount?: Maybe<QboAccount>;
  paymentDepositAccount?: Maybe<QboAccount>;
  ptoEnabled?: Maybe<Scalars['Boolean']>;
  reimbursableExpensesAccount?: Maybe<QboAccount>;
  stripePayoutAccount?: Maybe<QboAccount>;
  timeOffToggledAt?: Maybe<Scalars['String']>;
  unitOfMeasurement: UnitOfMeasurement;
  yearlyWorkingHours: Scalars['Float'];
};

export enum OrganizationStatusEnum {
  Booked = 'BOOKED',
  NotBooked = 'NOT_BOOKED',
  Completed = 'COMPLETED'
}

export type OrganizationSubscription = {
  __typename?: 'OrganizationSubscription';
  billingEmail?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  cancelDate?: Maybe<Scalars['ISO8601Date']>;
  coupon?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  hasFailedPayment?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isAnnual?: Maybe<Scalars['Boolean']>;
  lastFour?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  planName?: Maybe<Scalars['String']>;
  prepaidSeatCount?: Maybe<Scalars['Int']>;
  prepaidSeatsFilled?: Maybe<Scalars['Boolean']>;
  previousInvoiceAmount?: Maybe<Scalars['Float']>;
  previousInvoiceDate?: Maybe<Scalars['ISO8601Date']>;
  stripeId?: Maybe<Scalars['String']>;
  upcomingInvoiceAmount?: Maybe<Scalars['Float']>;
  upcomingInvoiceDate?: Maybe<Scalars['ISO8601Date']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of OrganizationTimesheetsExportAjera */
export type OrganizationTimesheetsExportAjeraInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
};

/** Autogenerated return type of OrganizationTimesheetsExportAjera. */
export type OrganizationTimesheetsExportAjeraPayload = {
  __typename?: 'OrganizationTimesheetsExportAjeraPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of OrganizationTimesheetsExport */
export type OrganizationTimesheetsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  includeNotes: Scalars['Boolean'];
  csvFormat: CsvFormat;
};

/** Autogenerated return type of OrganizationTimesheetsExport. */
export type OrganizationTimesheetsExportPayload = {
  __typename?: 'OrganizationTimesheetsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Overhead = {
  __typename?: 'Overhead';
  availableHours: Scalars['Boolean'];
  entryCount: Scalars['Int'];
  entryGroups: Array<EntryGroup>;
  id: Scalars['ID'];
  name: Scalars['String'];
  staffingEntryGroups: Array<EntryGroup>;
  timeOffPolicies?: Maybe<Array<TimeOffPolicy>>;
};


export type OverheadStaffingEntryGroupsArgs = {
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** All available Check pay frequency types */
export enum PayFrequency {
  /** Weekly */
  Weekly = 'weekly',
  /** Biweekly */
  Biweekly = 'biweekly',
  /** Semimonthly */
  Semimonthly = 'semimonthly',
  /** Monthly */
  Monthly = 'monthly',
  /** Quarterly */
  Quarterly = 'quarterly',
  /** Annually */
  Annually = 'annually'
}

export type PaySchedule = {
  __typename?: 'PaySchedule';
  firstPayPeriodEnd: Scalars['ISO8601Date'];
  firstPayday: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  payFrequency: PayFrequency;
};

export type PaySchedulePayday = {
  __typename?: 'PaySchedulePayday';
  approvalDeadline: Scalars['ISO8601Date'];
  impactedByWeekendOrHoliday: Scalars['Boolean'];
  payday: Scalars['ISO8601Date'];
  periodEnd: Scalars['ISO8601Date'];
  periodStart: Scalars['ISO8601Date'];
};

export type PaydayInfo = {
  __typename?: 'PaydayInfo';
  approvalDeadline: Scalars['ISO8601Date'];
  impactedByHoliday: Scalars['Boolean'];
  payday: Scalars['ISO8601Date'];
};

export type PaymentEmailSettings = {
  __typename?: 'PaymentEmailSettings';
  customBody?: Maybe<Scalars['String']>;
  defaultBodyWithPayment: Scalars['String'];
  defaultBodyWithoutPayment: Scalars['String'];
};

/** Filters for invoice payments */
export type PaymentFiltersInput = {
  paidDateRange?: Maybe<DateRangeInput>;
};

export type PaymentIntentSecret = {
  __typename?: 'PaymentIntentSecret';
  clientSecret?: Maybe<Scalars['String']>;
};

/** All available payment intent status types */
export enum PaymentIntentStatus {
  Processing = 'processing',
  RequiresAction = 'requires_action',
  Failed = 'failed',
  Succeeded = 'succeeded',
  Pending = 'pending'
}

export type PaymentMethod = BankAccount | Card;

/** All potential Check payment method preference types */
export enum PaymentMethodPreference {
  Manual = 'manual',
  DirectDeposit = 'direct_deposit'
}

/** All available payment method types */
export enum PaymentMethodType {
  UsBankAccount = 'us_bank_account',
  Card = 'card',
  AcssDebit = 'acss_debit'
}

/** Autogenerated input type of PaymentsInvoices */
export type PaymentsInvoicesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issueDateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsInvoices. */
export type PaymentsInvoicesPayload = {
  __typename?: 'PaymentsInvoicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of PaymentsPayments */
export type PaymentsPaymentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paidDateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsPayments. */
export type PaymentsPaymentsPayload = {
  __typename?: 'PaymentsPaymentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of PaymentsPayouts */
export type PaymentsPayoutsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payoutDateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsPayouts. */
export type PaymentsPayoutsPayload = {
  __typename?: 'PaymentsPayoutsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of PaymentsReceivables */
export type PaymentsReceivablesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  issueDateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsReceivables. */
export type PaymentsReceivablesPayload = {
  __typename?: 'PaymentsReceivablesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of PaymentsTaxesCollected */
export type PaymentsTaxesCollectedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paidDateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsTaxesCollected. */
export type PaymentsTaxesCollectedPayload = {
  __typename?: 'PaymentsTaxesCollectedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of PaymentsTransactions */
export type PaymentsTransactionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dateRange: DateRangeInput;
};

/** Autogenerated return type of PaymentsTransactions. */
export type PaymentsTransactionsPayload = {
  __typename?: 'PaymentsTransactionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Payout = {
  __typename?: 'Payout';
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  stripeCreationDate?: Maybe<Scalars['ISO8601Date']>;
};

/** The connection type for Payout. */
export type PayoutConnection = {
  __typename?: 'PayoutConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayoutEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Payout>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  totalPayoutAmount: Scalars['Float'];
};

/** An edge in a connection. */
export type PayoutEdge = {
  __typename?: 'PayoutEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Payout>;
};

/** Filters for payouts */
export type PayoutFiltersInput = {
  stripeCreationDate?: Maybe<DateRangeInput>;
};

/** All available sorts for payouts */
export enum PayoutsFieldInput {
  Amount = 'AMOUNT',
  PayoutDate = 'PAYOUT_DATE'
}

export type PayoutsSortInput = {
  field: PayoutsFieldInput;
  direction: SortDirectionInput;
};

export type Payroll = {
  __typename?: 'Payroll';
  approvalDeadline?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['String']>;
  company: Scalars['String'];
  contractorPayments: Array<ContractorPayment>;
  fundingPaymentMethod: Scalars['String'];
  id: Scalars['ID'];
  isVoid: Scalars['Boolean'];
  items: Array<Item>;
  managed: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  offCycleOptions?: Maybe<Scalars['String']>;
  payFrequency: Scalars['String'];
  payday: Scalars['String'];
  periodEnd: Scalars['String'];
  periodStart: Scalars['String'];
  processingPeriod: Scalars['String'];
  status: Scalars['String'];
  totals?: Maybe<Totals>;
  type: Scalars['String'];
};

export type PayrollAddress = {
  __typename?: 'PayrollAddress';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

/** An address for the Check API */
export type PayrollAddressInput = {
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};

/** A version of PayrollAddressInput with all properties optional. */
export type PayrollAddressInputPartial = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type PayrollBankAccount = {
  __typename?: 'PayrollBankAccount';
  id: Scalars['ID'];
  info: PayrollBankAccountInfo;
  status: BankAccountStatus;
  workerId: Scalars['ID'];
};

export type PayrollBankAccountInfo = {
  __typename?: 'PayrollBankAccountInfo';
  accountNumberLastFour: Scalars['String'];
  institutionName?: Maybe<Scalars['String']>;
  subtype: BankAccountSubtype;
  type: Scalars['String'];
};

export type PayrollCompanyDocument = {
  __typename?: 'PayrollCompanyDocument';
  /** Returns an organization's Check API Authorization Document (with PDF), if applicable */
  authorization: PayrollCompanyPdfDocument;
  /** Returns an organization's Check API Tax Document (with PDF), if applicable */
  tax: PayrollCompanyPdfDocument;
};


export type PayrollCompanyDocumentAuthorizationArgs = {
  documentId: Scalars['ID'];
};


export type PayrollCompanyDocumentTaxArgs = {
  documentId: Scalars['ID'];
};

export type PayrollCompanyDocuments = {
  __typename?: 'PayrollCompanyDocuments';
  authorization: Array<PayrollDocument>;
  tax: Array<PayrollDocument>;
};

export type PayrollCompanyInfo = {
  __typename?: 'PayrollCompanyInfo';
  active: Scalars['Boolean'];
  address: PayrollAddress;
  businessType?: Maybe<Business>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  implementationStatus: OrgImplementationStatus;
  industryType?: Maybe<Industry>;
  legalName: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  onboarding: PayrollCompanyOnboarding;
  otherBusinessName?: Maybe<Scalars['String']>;
  payFrequency?: Maybe<PayFrequency>;
  phone?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  tradeName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type PayrollCompanyOnboarding = {
  __typename?: 'PayrollCompanyOnboarding';
  bankAccount: Array<BankAccountOnboarding>;
  blockingSteps: Array<OrgOnboardingSteps>;
  remainingForms: Array<Scalars['String']>;
  remainingSteps: Array<OrgOnboardingSteps>;
  remainingTaxJurisdictions: Array<Scalars['String']>;
  status: OnboardingStatus;
};

export type PayrollCompanyPdfDocument = {
  __typename?: 'PayrollCompanyPdfDocument';
  category: DocumentCategory;
  description?: Maybe<Scalars['String']>;
  filedOn: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  jurisdiction?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  pdf: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollDocument = {
  __typename?: 'PayrollDocument';
  category: DocumentCategory;
  description?: Maybe<Scalars['String']>;
  filedOn: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  jurisdiction?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  year: Scalars['Int'];
};

export type PayrollEarningRate = {
  __typename?: 'PayrollEarningRate';
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  period: EarningRatePeriod;
};

export type PayrollEmployeeDocument = {
  __typename?: 'PayrollEmployeeDocument';
  /** Returns Base64'd PDF of a profile's given Check API Paystub, if applicable */
  paystub: PaystubPdf;
  /** Returns an employee's Check API Tax Document (with PDF), if applicable */
  tax: PayrollCompanyPdfDocument;
};


export type PayrollEmployeeDocumentPaystubArgs = {
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  payrollId: Scalars['ID'];
};


export type PayrollEmployeeDocumentTaxArgs = {
  profileId: Scalars['ID'];
  documentId: Scalars['ID'];
};

export type PayrollEmployeeDocuments = {
  __typename?: 'PayrollEmployeeDocuments';
  tax: Array<PayrollDocument>;
};

export type PayrollEmployeeInfo = {
  __typename?: 'PayrollEmployeeInfo';
  active: Scalars['Boolean'];
  checkOnboardingStatus?: Maybe<OnboardingStatus>;
  dob: Scalars['ISO8601Date'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  onboarding: PayrollEmployeeOnboarding;
  paymentMethodPreference?: Maybe<PaymentMethodPreference>;
  residence: PayrollAddress;
  ssnLastFour?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  workplace: Workplace;
};

export type PayrollEmployeeLinks = {
  __typename?: 'PayrollEmployeeLinks';
  onboarding: Scalars['String'];
};

export type PayrollEmployeeOnboarding = {
  __typename?: 'PayrollEmployeeOnboarding';
  bankAccount?: Maybe<Array<BankAccountOnboarding>>;
  blockingSteps: Array<EmployeeOnboardingSteps>;
  filingAuthorization?: Maybe<Array<PayrollEmployeeOnboardingFillingAuthorization>>;
  remainingSteps: Array<EmployeeOnboardingSteps>;
  setupParameters?: Maybe<Array<PayrollEmployeeOnboardingSetupParams>>;
  status: OnboardingStatus;
};

export type PayrollEmployeeOnboardingFillingAuthorization = {
  __typename?: 'PayrollEmployeeOnboardingFillingAuthorization';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: OnboardingStatus;
};

export type PayrollEmployeeOnboardingSetupParams = {
  __typename?: 'PayrollEmployeeOnboardingSetupParams';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: OnboardingStatus;
};

/** Autogenerated return type of PayrollHourlyWorker. */
export type PayrollHourlyWorkerPayload = {
  __typename?: 'PayrollHourlyWorkerPayload';
  periodEnd: Scalars['ISO8601DateTime'];
  periodStart: Scalars['ISO8601DateTime'];
  workers: Array<HourlyValidationWorker>;
};

/** Autogenerated return type of PayrollReimbursableExpenses. */
export type PayrollReimbursableExpensesPayload = {
  __typename?: 'PayrollReimbursableExpensesPayload';
  workers: Array<ReimbursableExpensesValidationWorker>;
};

/** All available compensation payroll types */
export enum PayrollTypeEnum {
  Salary = 'SALARY',
  Hourly = 'HOURLY',
  None = 'NONE'
}

export type PayrollWaitlister = {
  __typename?: 'PayrollWaitlister';
  id: Scalars['ID'];
  organization: Organization;
  profile: Profile;
};

export type PayrollWorkerDocuments = {
  __typename?: 'PayrollWorkerDocuments';
  paystubs: Array<Paystub>;
  tax: Array<PayrollDocument>;
};

export type PayrollWorkerInfo = {
  __typename?: 'PayrollWorkerInfo';
  active?: Maybe<Scalars['Boolean']>;
  checkOnboardingStatus?: Maybe<OnboardingStatus>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  ein?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  onboarding: PayrollEmployeeOnboarding;
  paymentMethodPreference?: Maybe<PaymentMethodPreference>;
  residence: PayrollAddress;
  ssnLastFour?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  workplace?: Maybe<Workplace>;
};

export type Payrolls = {
  __typename?: 'Payrolls';
  /** List of payrolls */
  list: Array<Payroll>;
  /** List of unprocessed (draft/pending status) payrolls */
  listUnprocessed: Array<Payroll>;
};


export type PayrollsListArgs = {
  startDate?: Maybe<Scalars['ISO8601Date']>;
};


export type PayrollsListUnprocessedArgs = {
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

export type Paystub = {
  __typename?: 'Paystub';
  amount: Scalars['Int'];
  date: Scalars['ISO8601Date'];
  payrollId: Scalars['ID'];
  totalHours?: Maybe<Scalars['Int']>;
  type: CheckPaystub;
};

export type PaystubPdf = {
  __typename?: 'PaystubPdf';
  amount: Scalars['Int'];
  date: Scalars['ISO8601Date'];
  payrollId: Scalars['ID'];
  pdf: Scalars['String'];
  totalHours: Scalars['Int'];
};

export type Permissions = {
  __typename?: 'Permissions';
  canCreateAllProjects: Scalars['Boolean'];
  canCreateInvoices: Scalars['Boolean'];
  canDeleteAllProjects: Scalars['Boolean'];
  canDeleteAssignedProjects: Scalars['Boolean'];
  canDeleteInvoices: Scalars['Boolean'];
  canEditAllProjects: Scalars['Boolean'];
  canEditAllTimesheets: Scalars['Boolean'];
  canEditAssignedProjects: Scalars['Boolean'];
  canEditInvoices: Scalars['Boolean'];
  canEditSettings: Scalars['Boolean'];
  canManageAllClients: Scalars['Boolean'];
  canManageAllConsultants: Scalars['Boolean'];
  canManageAllExpenses: Scalars['Boolean'];
  canManageCompensations: Scalars['Boolean'];
  canManageProfiles: Scalars['Boolean'];
  canManagePto: Scalars['Boolean'];
  canManageStaffing: Scalars['Boolean'];
  canSendToQuickbooks: Scalars['Boolean'];
  canViewAllClients: Scalars['Boolean'];
  canViewAllExpenses: Scalars['Boolean'];
  canViewAllProjects: Scalars['Boolean'];
  canViewAllTimesheets: Scalars['Boolean'];
  canViewFinancialInformation: Scalars['Boolean'];
  canViewFinancialReports: Scalars['Boolean'];
  canViewGanttInvoicedPaidAllProjects: Scalars['Boolean'];
  canViewGanttInvoicedPaidAssignedProjects: Scalars['Boolean'];
  canViewGanttLoggedTimeAllProjects: Scalars['Boolean'];
  canViewGanttLoggedTimeAssignedProjects: Scalars['Boolean'];
  canViewInvoices: Scalars['Boolean'];
  canViewTeamReports: Scalars['Boolean'];
  moneyGanttAllProjects: Scalars['Boolean'];
  moneyGanttAssignedProjects: Scalars['Boolean'];
};

export enum PermissionsKey {
  CanManagePto = 'canManagePto',
  CanManageCompensations = 'canManageCompensations',
  CanManageProfiles = 'canManageProfiles',
  CanManageStaffing = 'canManageStaffing',
  CanViewFinancialInformation = 'canViewFinancialInformation',
  CanViewFinancialReports = 'canViewFinancialReports',
  CanViewTeamReports = 'canViewTeamReports',
  CanEditAssignedProjects = 'canEditAssignedProjects',
  CanDeleteAssignedProjects = 'canDeleteAssignedProjects',
  MoneyGanttAssignedProjects = 'moneyGanttAssignedProjects',
  CanViewGanttLoggedTimeAssignedProjects = 'canViewGanttLoggedTimeAssignedProjects',
  CanViewGanttInvoicedPaidAssignedProjects = 'canViewGanttInvoicedPaidAssignedProjects',
  CanViewAllProjects = 'canViewAllProjects',
  CanEditAllProjects = 'canEditAllProjects',
  CanCreateAllProjects = 'canCreateAllProjects',
  CanDeleteAllProjects = 'canDeleteAllProjects',
  MoneyGanttAllProjects = 'moneyGanttAllProjects',
  CanViewGanttLoggedTimeAllProjects = 'canViewGanttLoggedTimeAllProjects',
  CanViewGanttInvoicedPaidAllProjects = 'canViewGanttInvoicedPaidAllProjects',
  CanViewAllTimesheets = 'canViewAllTimesheets',
  CanEditAllTimesheets = 'canEditAllTimesheets',
  CanViewAllExpenses = 'canViewAllExpenses',
  CanManageAllExpenses = 'canManageAllExpenses',
  CanEditSettings = 'canEditSettings',
  CanViewInvoices = 'canViewInvoices',
  CanEditInvoices = 'canEditInvoices',
  CanCreateInvoices = 'canCreateInvoices',
  CanDeleteInvoices = 'canDeleteInvoices',
  CanViewAllClients = 'canViewAllClients',
  CanManageAllClients = 'canManageAllClients',
  CanManageAllConsultants = 'canManageAllConsultants',
  CanSendToQuickbooks = 'canSendToQuickbooks'
}

/**
 * Phase is a separate part of project. It's limited by begin and end date and has assigned budget.
 *
 * Phase can have attached roles. Role is the fact that some profile was assigned to work on some project phase. Role has name, houly rate (for billing) and planned budget.
 *
 *
 * There are two types of phases: fixed fee phase means that we have a maximum budget we can spend, while hourly phase is not limited and we bill for each hour based on role rate.
 */
export type Phase = {
  __typename?: 'Phase';
  abbreviation?: Maybe<Scalars['String']>;
  ajeraIdentifier?: Maybe<AjeraIdentifier>;
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  budget: Scalars['Float'];
  budgetAllocated: Scalars['Float'];
  budgetConsumed: Scalars['Float'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  entryHoursCount: Scalars['Float'];
  feeType: PhaseFee;
  hasNonDraftLineItem: Scalars['Boolean'];
  hours: Scalars['Float'];
  hoursAllocated: Scalars['Float'];
  id: Scalars['ID'];
  isOverstaffed: Scalars['Boolean'];
  linkedPhase?: Maybe<Phase>;
  linkedPhases: Array<Phase>;
  milestones?: Maybe<Array<Milestone>>;
  name: Scalars['String'];
  percent?: Maybe<Scalars['Float']>;
  phaseTemplate?: Maybe<PhaseTemplate>;
  position: Scalars['Int'];
  project?: Maybe<Project>;
  roleCount: Scalars['Int'];
  roles: Array<Role>;
  staffingEntryGroups: Array<EntryGroup>;
  staffingTimesheets: Array<Timesheet>;
  status?: Maybe<PhaseStatus>;
  teamList?: Maybe<Array<TeamList>>;
  weeklyBudgetAllocated: Array<StaffingCell>;
};

export type PhaseConsultant = {
  __typename?: 'PhaseConsultant';
  consultantTemplate: ConsultantTemplate;
  consultingWork?: Maybe<ConsultingWork>;
  entries?: Maybe<Array<ProjectConsultant>>;
  markupPercentage: Scalars['Float'];
  markupTotal: Scalars['Float'];
  total: Scalars['Float'];
};

/** All available phase fee types */
export enum PhaseFee {
  Fixed = 'FIXED',
  Hourly = 'HOURLY'
}

/** All available phase statuses */
export enum PhaseStatus {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED'
}

export type PhaseTemplate = {
  __typename?: 'PhaseTemplate';
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
  id: Scalars['ID'];
  name: Scalars['String'];
  qboItems: Array<QboItem>;
};

export type Planable = {
  budget: Scalars['Float'];
  categories?: Maybe<Array<Category>>;
  color: Scalars['String'];
  consultantsAllocatedPercent?: Maybe<Scalars['Float']>;
  consultantsFee?: Maybe<Scalars['Float']>;
  consultantsUnallocated?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderedPhases: Array<Phase>;
  orderedProjectConsultantIds?: Maybe<Scalars['String']>;
  phaseConsultants?: Maybe<Array<PhaseConsultant>>;
  phases: Array<Phase>;
  projectConsultants?: Maybe<Array<ProjectConsultant>>;
  slug: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  totalConsultantsMarkup: Scalars['Float'];
  totalFee?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of PlanableBudgetUpdate */
export type PlanableBudgetUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  budget: Scalars['Float'];
  isProjectTemplate?: Maybe<Scalars['Boolean']>;
  updatePhaseBudgets?: Maybe<Scalars['Boolean']>;
  updateRoleHours?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PlanableBudgetUpdate. */
export type PlanableBudgetUpdatePayload = {
  __typename?: 'PlanableBudgetUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  planable: Planable;
};

/** All available planable types */
export enum PlanableTypes {
  Project = 'PROJECT',
  ProjectTemplate = 'PROJECT_TEMPLATE'
}

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  permissions: Permissions;
  profiles: Array<Profile>;
  slug?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ProcessQBOCustomers */
export type ProcessQboCustomersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboCustomerQuickbooksIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of ProcessQBOCustomers. */
export type ProcessQboCustomersPayload = {
  __typename?: 'ProcessQBOCustomersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboCustomers?: Maybe<Array<QboCustomer>>;
};

export type Profile = {
  __typename?: 'Profile';
  ajeraIdentifier?: Maybe<AjeraIdentifier>;
  canViewPayrollMenu: Scalars['Boolean'];
  checkId?: Maybe<Scalars['String']>;
  compensations?: Maybe<Array<Compensation>>;
  completedOnboarding?: Maybe<Scalars['Boolean']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentTimesheet?: Maybe<Timesheet>;
  /** used for logging time if no rate is already assigned */
  defaultRateTable?: Maybe<RateTable>;
  /** used for logging time if no role is already assigned */
  defaultRoleTemplate?: Maybe<RoleTemplate>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstNameLastInitial: Scalars['String'];
  fname?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  initials: Scalars['String'];
  intercomUserHash: Scalars['String'];
  invitationSent: Scalars['Boolean'];
  isSignedUpForRetainers: Scalars['Boolean'];
  lname?: Maybe<Scalars['String']>;
  mfaEnabled: Scalars['Boolean'];
  /** Returns a paginated and sorted list of a profile's milestones. */
  milestones: MilestoneConnection;
  name?: Maybe<Scalars['String']>;
  onboardingComplete: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  payrollType: ProfilePayrollType;
  permissionsEdited?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<Policy>;
  policyId?: Maybe<Scalars['Int']>;
  /** Returns a paginated and sorted list of a profile's projects in the current organization.  */
  projects: ProjectConnection;
  pronouns?: Maybe<Scalars['String']>;
  publicCompensations?: Maybe<Array<PublicCompensation>>;
  roleTemplate?: Maybe<RoleTemplate>;
  /** Returns a set of roles assigned to the current profile, with optional phase or project params. */
  roles: Array<Role>;
  sendDailyEmail?: Maybe<Scalars['Boolean']>;
  sendTimesheetsSummaryEmail?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<ProfileSetting>;
  slug: Scalars['String'];
  staffingRoles: Array<Role>;
  status: ProfileStatus;
  /** Returns a paginated and sorted list of a profile's viewable tasks. */
  tasks: TaskConnection;
  telephone?: Maybe<Scalars['String']>;
  timeOffPolicyAssignments: Array<TimeOffPolicyAssignment>;
  timeOffRequests: Array<TimeOffRequest>;
  timesheet: Timesheet;
  timesheets: Array<Timesheet>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
  utilizationRate?: Maybe<Scalars['Float']>;
  weeklyHoursAllocated: Array<StaffingCell>;
};


export type ProfileCompensationsArgs = {
  isCurrent?: Maybe<Scalars['Boolean']>;
};


export type ProfileMilestonesArgs = {
  sortBy?: Maybe<ProfileMilestonesSortInput>;
  filters?: Maybe<MilestoneFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProfileProjectsArgs = {
  sortBy?: Maybe<ProfileProjectsSortInput>;
  filters?: Maybe<ProfileProjectsFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProfileRolesArgs = {
  projectId?: Maybe<Scalars['ID']>;
  phaseId?: Maybe<Scalars['ID']>;
};


export type ProfileStaffingRolesArgs = {
  beginDate: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
};


export type ProfileTasksArgs = {
  sortBy?: Maybe<ProfileTasksSortInput>;
  filters?: Maybe<TaskFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProfileTimesheetArgs = {
  beginDate: Scalars['ISO8601Date'];
};


export type ProfileTimesheetsArgs = {
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
};


export type ProfileUtilizationRateArgs = {
  withOrganizationDefault?: Maybe<Scalars['Boolean']>;
};


export type ProfileWeeklyHoursAllocatedArgs = {
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
};

/** The connection type for Profile. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Profile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of ProfileDeactivate */
export type ProfileDeactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProfileDeactivate. */
export type ProfileDeactivatePayload = {
  __typename?: 'ProfileDeactivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** An edge in a connection. */
export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Profile>;
};

export type ProfileFilter = {
  query?: Maybe<Scalars['String']>;
};

/** Filters for profiles */
export type ProfileFiltersInput = {
  ids?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ProfileStatus>>;
  titles?: Maybe<Array<Scalars['String']>>;
  policyIds?: Maybe<Array<Scalars['ID']>>;
  searchString?: Maybe<Scalars['String']>;
};

export type ProfileGroup = {
  __typename?: 'ProfileGroup';
  eligible: Array<Profile>;
  ineligible: Array<Profile>;
  name: Scalars['String'];
};

/** All available sorts for profile milestones */
export enum ProfileMilestonesFieldInput {
  Name = 'NAME',
  DueDate = 'DUE_DATE',
  ProjectNumber = 'PROJECT_NUMBER',
  ProjectName = 'PROJECT_NAME',
  PhaseAbbreviation = 'PHASE_ABBREVIATION'
}

export type ProfileMilestonesSortInput = {
  field: ProfileMilestonesFieldInput;
  direction: SortDirectionInput;
};

/** All profile payroll types */
export enum ProfilePayrollType {
  Hourly = 'hourly',
  Salaried = 'salaried',
  None = 'none'
}

/** All available sorts for profile projects */
export enum ProfileProjectsFieldInput {
  Name = 'NAME',
  Number = 'NUMBER',
  UpdatedAt = 'UPDATED_AT'
}

/** Filters for a profile's projects */
export type ProfileProjectsFiltersInput = {
  statuses?: Maybe<Array<ProjectStatus>>;
};

export type ProfileProjectsSortInput = {
  field: ProfileProjectsFieldInput;
  direction: SortDirectionInput;
};

/** Autogenerated input type of ProfileReactivate */
export type ProfileReactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ProfileReactivate. */
export type ProfileReactivatePayload = {
  __typename?: 'ProfileReactivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type ProfileSetting = {
  __typename?: 'ProfileSetting';
  checkContractorId?: Maybe<Scalars['String']>;
  checkEmployeeId?: Maybe<Scalars['String']>;
  checkEmploymentType: CheckEmployment;
  id: Scalars['ID'];
  phaseRetroInsightsDigest: Scalars['Boolean'];
  weeklyInsightsDigest: Scalars['Boolean'];
};

/** All available profile status types */
export enum ProfileStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
  NotInvited = 'NOT_INVITED'
}

/** All available sorts for profile tasks */
export enum ProfileTasksFieldInput {
  Name = 'NAME',
  DueDate = 'DUE_DATE',
  Description = 'DESCRIPTION',
  ProjectNumber = 'PROJECT_NUMBER',
  PhaseAbbreviation = 'PHASE_ABBREVIATION',
  ProjectName = 'PROJECT_NAME',
  MilestoneName = 'MILESTONE_NAME',
  Status = 'STATUS'
}

export type ProfileTasksSortInput = {
  field: ProfileTasksFieldInput;
  direction: SortDirectionInput;
};

/** Autogenerated input type of ProfileTimesheetsExport */
export type ProfileTimesheetsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  includeNotes: Scalars['Boolean'];
  csvFormat: CsvFormat;
};

/** Autogenerated return type of ProfileTimesheetsExport. */
export type ProfileTimesheetsExportPayload = {
  __typename?: 'ProfileTimesheetsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** All available sorts for profiles */
export enum ProfilesFieldInput {
  Id = 'ID',
  PolicyName = 'POLICY_NAME',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  Title = 'TITLE',
  Status = 'STATUS',
  Onboarding = 'ONBOARDING'
}

export type ProfilesSortInput = {
  field: ProfilesFieldInput;
  direction: SortDirectionInput;
};

/** Autogenerated input type of ProfitDriversExport */
export type ProfitDriversExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for profit drivers */
  filters: ProfitDriversV2FiltersInput;
};

/** Autogenerated return type of ProfitDriversExport. */
export type ProfitDriversExportPayload = {
  __typename?: 'ProfitDriversExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** All available profit drivers groupings */
export enum ProfitDriversGroupings {
  Phase = 'phase',
  Category = 'category',
  Client = 'client'
}

export type ProfitDriversV2 = {
  __typename?: 'ProfitDriversV2';
  costs: Scalars['Float'];
  identifier?: Maybe<Scalars['String']>;
  margin: Scalars['Float'];
  name: Scalars['String'];
  profit: Scalars['Float'];
  revenue: Scalars['Float'];
};

/** Filters for profit drivers report */
export type ProfitDriversV2FiltersInput = {
  groupBy: ProfitDriversGroupings;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ProjectStatus>>;
  groupingId?: Maybe<Scalars['ID']>;
  qbo?: Maybe<Scalars['Boolean']>;
};

/** Filters for a profit report for an organization */
export type ProfitFiltersInput = {
  dateRange: DateRangeInput;
};

export type ProfitForecast = {
  __typename?: 'ProfitForecast';
  entries: Array<ProfitForecastEntryType>;
  totals: ProfitForecastTotalsType;
};

export type ProfitForecastEntryType = {
  __typename?: 'ProfitForecastEntryType';
  consultantCost?: Maybe<Scalars['Float']>;
  consultantRevenue?: Maybe<Scalars['Float']>;
  fixedRevenue?: Maybe<Scalars['Float']>;
  hourlyRevenue?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  month: Scalars['ISO8601Date'];
  overheadCost?: Maybe<Scalars['Float']>;
  projectedCost?: Maybe<Scalars['Float']>;
  projectedProfit?: Maybe<Scalars['Float']>;
  projectedRevenue?: Maybe<Scalars['Float']>;
  wageCost?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of ProfitForecastExport */
export type ProfitForecastExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for profit forecast */
  filters: ProfitForecastFiltersInput;
};

/** Autogenerated return type of ProfitForecastExport. */
export type ProfitForecastExportPayload = {
  __typename?: 'ProfitForecastExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Filters for a profit forecast for an organization */
export type ProfitForecastFiltersInput = {
  statuses?: Maybe<Array<ProjectStatus>>;
  dateRange: DateRangeInput;
};

export type ProfitForecastTotalsType = {
  __typename?: 'ProfitForecastTotalsType';
  cost?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
};

export type ProfitNonQboVersion = {
  __typename?: 'ProfitNonQBOVersion';
  entries: Array<ProfitProfitNonQboVersionEntryType>;
  minimumReportDate?: Maybe<Scalars['ISO8601Date']>;
  showConsultantWarning: Scalars['Boolean'];
  totals: ProfitNonQboVersionTotalsType;
};

/** Autogenerated input type of ProfitNonQBOVersionExport */
export type ProfitNonQboVersionExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for profit report */
  filters: ProfitFiltersInput;
};

/** Autogenerated return type of ProfitNonQBOVersionExport. */
export type ProfitNonQboVersionExportPayload = {
  __typename?: 'ProfitNonQBOVersionExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ProfitNonQboVersionTotalsType = {
  __typename?: 'ProfitNonQBOVersionTotalsType';
  cost?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
};

export type ProfitProfitNonQboVersionEntryType = {
  __typename?: 'ProfitProfitNonQBOVersionEntryType';
  consultantCosts?: Maybe<Scalars['Float']>;
  consultantRevenue?: Maybe<Scalars['Float']>;
  fixedRevenue?: Maybe<Scalars['Float']>;
  hourlyRevenue?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  month: Scalars['ISO8601Date'];
  otherCosts?: Maybe<Scalars['Float']>;
  otherRevenue?: Maybe<Scalars['Float']>;
  overheadCosts?: Maybe<Scalars['Float']>;
  totalCosts?: Maybe<Scalars['Float']>;
  totalProfit?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  wageCosts?: Maybe<Scalars['Float']>;
};

export type ProfitProfitQboVersionEntryType = {
  __typename?: 'ProfitProfitQBOVersionEntryType';
  consultantRevenue?: Maybe<Scalars['Float']>;
  directCosts?: Maybe<Scalars['Float']>;
  fixedRevenue?: Maybe<Scalars['Float']>;
  hourlyRevenue?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  month: Scalars['ISO8601Date'];
  nonOperatingCosts?: Maybe<Scalars['Float']>;
  operatingCosts?: Maybe<Scalars['Float']>;
  otherRevenue?: Maybe<Scalars['Float']>;
  totalCosts?: Maybe<Scalars['Float']>;
  totalProfit?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
};

export type ProfitQboVersion = {
  __typename?: 'ProfitQBOVersion';
  entries: Array<ProfitProfitQboVersionEntryType>;
  minimumReportDate?: Maybe<Scalars['ISO8601Date']>;
  showConsultantWarning: Scalars['Boolean'];
  totals: ProfitQboVersionTotalsType;
};

/** Autogenerated input type of ProfitQBOVersionExport */
export type ProfitQboVersionExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for profit report */
  filters: ProfitFiltersInput;
};

/** Autogenerated return type of ProfitQBOVersionExport. */
export type ProfitQboVersionExportPayload = {
  __typename?: 'ProfitQBOVersionExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ProfitQboVersionTotalsType = {
  __typename?: 'ProfitQBOVersionTotalsType';
  cost?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
};

export type ProfitReport = {
  __typename?: 'ProfitReport';
  chart: Array<ProfitReportChartEntry>;
  dateIncrement: DateIncrement;
  futureRevenueSource: ProfitRevenueSource;
  partialEnd: Scalars['Boolean'];
  partialStart: Scalars['Boolean'];
  pastRevenueSource: ProfitRevenueSource;
  totals: ProfitReportTotals;
};

export type ProfitReportChartEntry = {
  __typename?: 'ProfitReportChartEntry';
  data: Array<ProfitReportChartEntryData>;
  xAxis: Scalars['ISO8601Date'];
};

export type ProfitReportChartEntryData = {
  __typename?: 'ProfitReportChartEntryData';
  costs: Scalars['Float'];
  margin: Scalars['Float'];
  profit: Scalars['Float'];
  revenue: Scalars['Float'];
  revenueSource: ProfitRevenueSource;
};

export type ProfitReportTotals = {
  __typename?: 'ProfitReportTotals';
  costs: Scalars['Float'];
  profit: Scalars['Float'];
  revenue: Scalars['Float'];
};

/** All revenue source types for profit report */
export enum ProfitRevenueSource {
  InvoiceIssued = 'invoice_issued',
  InvoicePaid = 'invoice_paid',
  PlannerData = 'planner_data'
}

export type Project = Planable & {
  __typename?: 'Project';
  accountingDestinationCustomer?: Maybe<QboCustomer>;
  /** Lists active phases within date range */
  activePhasesInRange?: Maybe<Array<Phase>>;
  address?: Maybe<Address>;
  allowBankTransfers: Scalars['Boolean'];
  allowCardPayments: Scalars['Boolean'];
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  budget: Scalars['Float'];
  budgetAllocated: Scalars['Float'];
  budgetConsumed: Scalars['Float'];
  categories?: Maybe<Array<Category>>;
  client?: Maybe<Client>;
  color: Scalars['String'];
  consultantCompaniesWithTemplateNames?: Maybe<Array<ConsultantWithTemplateNames>>;
  consultantsAllocatedPercent?: Maybe<Scalars['Float']>;
  consultantsFee?: Maybe<Scalars['Float']>;
  consultantsUnallocated?: Maybe<Scalars['Float']>;
  currentPhases?: Maybe<Array<Phase>>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  externalAccountingId?: Maybe<Scalars['ID']>;
  externalAccountingRoleId?: Maybe<Scalars['ID']>;
  hours: Scalars['Float'];
  hoursAllocated: Scalars['Float'];
  hoursLogged: Scalars['Float'];
  id: Scalars['ID'];
  invoices?: Maybe<Array<Invoice>>;
  invoicesCount: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isBillable: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  link: Scalars['String'];
  milestones?: Maybe<Array<InvoiceBuilderMilestone>>;
  name?: Maybe<Scalars['String']>;
  nonDraftInvoicesCount: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderedPhases: Array<Phase>;
  orderedProjectConsultantIds?: Maybe<Scalars['String']>;
  overBudgetPhases?: Maybe<Array<Phase>>;
  paymentEmailSettings: PaymentEmailSettings;
  phaseConsultants?: Maybe<Array<PhaseConsultant>>;
  phases: Array<Phase>;
  profiles?: Maybe<Array<Profile>>;
  projectConsultants?: Maybe<Array<ProjectConsultant>>;
  projectCost?: Maybe<ProjectCost>;
  retainerSummary?: Maybe<RetainerSummary>;
  shareableHash: Scalars['String'];
  slug: Scalars['String'];
  status?: Maybe<ProjectStatus>;
  tasks?: Maybe<Array<Task>>;
  teamList?: Maybe<Array<TeamList>>;
  totalConsultantsMarkup: Scalars['Float'];
  totalFee?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
  v1InvoicesWithUnassignedLineItemsCount: Scalars['Int'];
};


export type ProjectActivePhasesInRangeArgs = {
  dateRange: DateRangeInput;
};

/** Autogenerated input type of ProjectAccountingUpdate */
export type ProjectAccountingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  externalAccountingId?: Maybe<Scalars['ID']>;
  externalAccountingRoleId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ProjectAccountingUpdate. */
export type ProjectAccountingUpdatePayload = {
  __typename?: 'ProjectAccountingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
};

export type ProjectBasicInfoAddressInput = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  parcel?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ProjectBasicInfoUpdate */
export type ProjectBasicInfoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  color: Scalars['String'];
  isBillable: Scalars['Boolean'];
  categoryIds: Array<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
  address: ProjectBasicInfoAddressInput;
  notes?: Maybe<Scalars['String']>;
  qboAccountingDestinationCustomerId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ProjectBasicInfoUpdate. */
export type ProjectBasicInfoUpdatePayload = {
  __typename?: 'ProjectBasicInfoUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type ProjectColor = {
  __typename?: 'ProjectColor';
  hex: Scalars['String'];
  number: Scalars['Int'];
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Project>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProjectConsultant = {
  __typename?: 'ProjectConsultant';
  budget: Scalars['Float'];
  budgetIsFrozen: Scalars['Boolean'];
  consultantTemplate: ConsultantTemplate;
  consultantTemplateId: Scalars['ID'];
  consultingWork?: Maybe<ConsultingWork>;
  id: Scalars['ID'];
  markup: Scalars['Float'];
  markupPercentage: Scalars['Float'];
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
  projectTemplate?: Maybe<ProjectTemplate>;
};

/** Autogenerated input type of ProjectConsultantsFeeUpdate */
export type ProjectConsultantsFeeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  consultantsFee: Scalars['Float'];
};

/** Autogenerated return type of ProjectConsultantsFeeUpdate. */
export type ProjectConsultantsFeeUpdatePayload = {
  __typename?: 'ProjectConsultantsFeeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
};

export type ProjectCost = {
  __typename?: 'ProjectCost';
  constructionCost?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  sqUnitCostType: SqUnitCost;
  sqUnits?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of ProjectCostUpdate */
export type ProjectCostUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  constructionCost?: Maybe<Scalars['Float']>;
  sqUnits?: Maybe<Scalars['Float']>;
  sqUnitCostType?: Maybe<SqUnitCost>;
};

/** Autogenerated return type of ProjectCostUpdate. */
export type ProjectCostUpdatePayload = {
  __typename?: 'ProjectCostUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Project>;
};

export type ProjectFinancials = {
  __typename?: 'ProjectFinancials';
  consultants: Array<ProjectFinancialsEntryType>;
  consultantsTotals: ProjectFinancialsEntryType;
  expenses: Array<ProjectFinancialsEntryType>;
  expensesTotals: ProjectFinancialsEntryType;
  hasPhaseConsultants: Scalars['Boolean'];
  others: Array<ProjectFinancialsOtherEntryType>;
  othersTotals: ProjectFinancialsOtherEntryType;
  phases: Array<ProjectFinancialsEntryType>;
  phasesTotals: ProjectFinancialsEntryType;
  retainers: Array<ProjectFinancialsRetainerEntryType>;
  retainersTotals: ProjectFinancialsRetainerEntryType;
};

export type ProjectFinancialsEntryType = {
  __typename?: 'ProjectFinancialsEntryType';
  billed?: Maybe<Scalars['Float']>;
  billedPercent?: Maybe<Scalars['Float']>;
  children?: Maybe<Array<ProjectFinancialsEntryType>>;
  consultantFee?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  firmServicesAmount?: Maybe<Scalars['Float']>;
  logged?: Maybe<Scalars['Float']>;
  loggedBillableBudgetConsumed?: Maybe<Scalars['Float']>;
  loggedBillableHours?: Maybe<Scalars['Float']>;
  loggedNonBillableBudgetConsumed?: Maybe<Scalars['Float']>;
  loggedNonBillableHours?: Maybe<Scalars['Float']>;
  loggedPercent?: Maybe<Scalars['Float']>;
  margin?: Maybe<Scalars['Float']>;
  markup?: Maybe<Scalars['Float']>;
  markupPercent?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Float']>;
  otherServicesAmount?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  pastDue?: Maybe<Scalars['Float']>;
  planned?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  remaining?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  unbilled?: Maybe<Scalars['Float']>;
  warning?: Maybe<Scalars['Boolean']>;
};

/** Filters for project financials report */
export type ProjectFinancialsFiltersInput = {
  phaseIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of ProjectFinancials */
export type ProjectFinancialsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of filters for the project financials report */
  filters?: Maybe<ProjectFinancialsFiltersInput>;
  slug: Scalars['String'];
};

export type ProjectFinancialsOtherEntryType = {
  __typename?: 'ProjectFinancialsOtherEntryType';
  billed: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceSlug?: Maybe<Scalars['String']>;
  invoiceVersion?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
  open: Scalars['Float'];
  paid: Scalars['Float'];
  pastDue: Scalars['Float'];
  quantity: Scalars['Float'];
  rate: Scalars['Float'];
};

/** Autogenerated return type of ProjectFinancials. */
export type ProjectFinancialsPayload = {
  __typename?: 'ProjectFinancialsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ProjectFinancialsRetainerEntryType = {
  __typename?: 'ProjectFinancialsRetainerEntryType';
  billed: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  invoiceIssueDate?: Maybe<Scalars['ISO8601Date']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  invoicePaidDate?: Maybe<Scalars['ISO8601Date']>;
  invoiceSlug?: Maybe<Scalars['String']>;
  invoiceVersion?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
};

export type ProjectOption = {
  __typename?: 'ProjectOption';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ProjectPerformance = {
  __typename?: 'ProjectPerformance';
  dateIncrement: DateIncrement;
  /** Ranging from the lowest to the highest recorded data for planned, logged, invoiced, or allocated */
  dateRange: DateRange;
  entries: Array<ProjectPerformanceChartEntryData>;
  filters: ProjectPerformanceFilters;
  phases: Array<ProjectPerformancePhases>;
  projectBudget: ProjectPerformanceProjectBudget;
  projectEndDate?: Maybe<Scalars['ISO8601Date']>;
};


export type ProjectPerformancePhasesArgs = {
  endDate: Scalars['ISO8601Date'];
};

export type ProjectPerformanceChartEntryData = {
  __typename?: 'ProjectPerformanceChartEntryData';
  allocated: ProjectPerformanceHoursAndFee;
  consumed: ProjectPerformanceHoursAndFee;
  endDate: Scalars['ISO8601Date'];
  invoicedFee: Scalars['Float'];
  planned: ProjectPerformanceHoursAndFee;
  startDate: Scalars['ISO8601Date'];
};

export type ProjectPerformanceFilters = {
  __typename?: 'ProjectPerformanceFilters';
  feeType?: Maybe<PhaseFee>;
  phaseId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
};

/** Filters for project perfomance (burn up) report */
export type ProjectPerformanceFiltersInput = {
  /** Project ID */
  projectId?: Maybe<Scalars['ID']>;
  feeType?: Maybe<PhaseFee>;
  excludeNonBillable?: Maybe<Scalars['Boolean']>;
  /** Phase ID */
  phaseId?: Maybe<Scalars['ID']>;
  /** Profile ID */
  profileId?: Maybe<Scalars['ID']>;
};

export type ProjectPerformanceHoursAndFee = {
  __typename?: 'ProjectPerformanceHoursAndFee';
  fee: Scalars['Float'];
  hours: Scalars['Float'];
};

export type ProjectPerformancePhases = {
  __typename?: 'ProjectPerformancePhases';
  allocated: ProjectPerformanceHoursAndFee;
  consumed: ProjectPerformanceHoursAndFee;
  invoicedFee: Scalars['Float'];
  phase: Phase;
  planned: ProjectPerformanceHoursAndFee;
};

export type ProjectPerformanceProjectBudget = {
  __typename?: 'ProjectPerformanceProjectBudget';
  fixedFee?: Maybe<ProjectPerformanceHoursAndFee>;
  hourlyFee?: Maybe<ProjectPerformanceHoursAndFee>;
  total?: Maybe<ProjectPerformanceHoursAndFee>;
};

export type ProjectProfit = {
  __typename?: 'ProjectProfit';
  costsTotal: Scalars['Float'];
  entries: Array<ProjectProfitEntryType>;
  missingCostsImport: Scalars['Boolean'];
  profilesMissingCompensation: Array<Profile>;
  profitTotal: Scalars['Float'];
  revenueTotal: Scalars['Float'];
};

export type ProjectProfitEntryType = {
  __typename?: 'ProjectProfitEntryType';
  costs?: Maybe<Scalars['Float']>;
  grossProfit?: Maybe<Scalars['Float']>;
  missingLaborRelatedCosts?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  revenue?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of ProjectProfitExport */
export type ProjectProfitExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** Autogenerated return type of ProjectProfitExport. */
export type ProjectProfitExportPayload = {
  __typename?: 'ProjectProfitExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of ProjectShareableUpdate */
export type ProjectShareableUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isShareable: Scalars['Boolean'];
};

/** Autogenerated return type of ProjectShareableUpdate. */
export type ProjectShareableUpdatePayload = {
  __typename?: 'ProjectShareableUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
};

/** All available project statuses */
export enum ProjectStatus {
  /** Proposed */
  Proposed = 'PROPOSED',
  /** Active */
  Active = 'ACTIVE',
  /** Paused */
  Paused = 'PAUSED',
  /** Completed */
  Completed = 'COMPLETED',
  /** Canceled */
  Canceled = 'CANCELED'
}

export type ProjectTemplate = Planable & {
  __typename?: 'ProjectTemplate';
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  budget: Scalars['Float'];
  categories?: Maybe<Array<Category>>;
  color: Scalars['String'];
  consultantsAllocatedPercent?: Maybe<Scalars['Float']>;
  consultantsFee?: Maybe<Scalars['Float']>;
  consultantsUnallocated?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  isBillable: Scalars['Boolean'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  orderedPhases: Array<Phase>;
  orderedProjectConsultantIds?: Maybe<Scalars['String']>;
  phaseConsultants?: Maybe<Array<PhaseConsultant>>;
  phases: Array<Phase>;
  projectConsultants?: Maybe<Array<ProjectConsultant>>;
  slug: Scalars['String'];
  tasks?: Maybe<Array<Task>>;
  teamList?: Maybe<Array<TeamList>>;
  totalConsultantsMarkup: Scalars['Float'];
  totalFee?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of ProjectTemplateBasicInfoUpdate */
export type ProjectTemplateBasicInfoUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  number: Scalars['String'];
  color: Scalars['String'];
  isBillable: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of ProjectTemplateBasicInfoUpdate. */
export type ProjectTemplateBasicInfoUpdatePayload = {
  __typename?: 'ProjectTemplateBasicInfoUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectTemplate?: Maybe<ProjectTemplate>;
};

/** The connection type for ProjectTemplate. */
export type ProjectTemplateConnection = {
  __typename?: 'ProjectTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProjectTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of ProjectTemplateConsultantsFeeUpdate */
export type ProjectTemplateConsultantsFeeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  consultantsFee: Scalars['Float'];
};

/** Autogenerated return type of ProjectTemplateConsultantsFeeUpdate. */
export type ProjectTemplateConsultantsFeeUpdatePayload = {
  __typename?: 'ProjectTemplateConsultantsFeeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectTemplate: ProjectTemplate;
};

/** An edge in a connection. */
export type ProjectTemplateEdge = {
  __typename?: 'ProjectTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProjectTemplate>;
};

/** All available sorts for project templates */
export enum ProjectTemplatesFieldInput {
  Budget = 'BUDGET',
  ConsultantsFee = 'CONSULTANTS_FEE',
  Number = 'NUMBER',
  TemplateName = 'TEMPLATE_NAME',
  TotalFee = 'TOTAL_FEE'
}

export type ProjectTemplatesSortInput = {
  field: ProjectTemplatesFieldInput;
  direction: SortDirectionInput;
};

/** Filters for project timings report */
export type ProjectTimingsFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ProjectStatus>>;
  billableOption?: Maybe<Billable>;
  minSqUnit?: Maybe<Scalars['Float']>;
  maxSqUnit?: Maybe<Scalars['Float']>;
  datePreset?: Maybe<Scalars['String']>;
};

export type ProjectUnbilledTotals = {
  __typename?: 'ProjectUnbilledTotals';
  currentUnbilled: Scalars['Float'];
  invoicedServices: Scalars['Float'];
  plannedServices: Scalars['Float'];
  totalUnbilled: Scalars['Float'];
};


export type ProjectUnbilledTotalsCurrentUnbilledArgs = {
  useCache?: Maybe<Scalars['Boolean']>;
};

/** All available sorts for searchable projects */
export enum ProjectsSortableFieldsInput {
  Id = 'ID',
  Name = 'NAME',
  Number = 'NUMBER',
  UpdatedAt = 'UPDATED_AT',
  BestMatch = 'BEST_MATCH'
}

export type ProjectsTimings = {
  __typename?: 'ProjectsTimings';
  chart: Array<ProjectsTimingsChartEntry>;
  dateIncrement: DateIncrement;
  filters: ProjectsTimingsFilters;
  partialEnd: Scalars['Boolean'];
  partialStart: Scalars['Boolean'];
  projects: Array<ProjectsTimingsProjectListEntry>;
  totals: ProjectsTimingsTotals;
};

export type ProjectsTimingsChartEntry = {
  __typename?: 'ProjectsTimingsChartEntry';
  data: Array<ProjectsTimingsChartEntryData>;
  interval: DateRange;
  totals: ProjectsTimingsChartEntryTotals;
};

export type ProjectsTimingsChartEntryData = {
  __typename?: 'ProjectsTimingsChartEntryData';
  consumed: HoursAndFee;
  issuedFee?: Maybe<Scalars['Float']>;
  planned: HoursAndFee;
  projectId: Scalars['ID'];
};

export type ProjectsTimingsChartEntryTotals = {
  __typename?: 'ProjectsTimingsChartEntryTotals';
  consumed: HoursAndFee;
  issuedFee: Scalars['Float'];
  planned: HoursAndFee;
};

export type ProjectsTimingsConstructionCost = {
  __typename?: 'ProjectsTimingsConstructionCost';
  consumedFeeBySqUnit: Scalars['Float'];
  costBySqUnit: Scalars['Float'];
  plannedFeeBySqUnit: Scalars['Float'];
  revenueBySqUnit: Scalars['Float'];
};

export type ProjectsTimingsFilters = {
  __typename?: 'ProjectsTimingsFilters';
  billableOption: Billable;
  categoryIds: Array<Scalars['ID']>;
  clientIds: Array<Scalars['ID']>;
  datePreset?: Maybe<Scalars['String']>;
  dateRange: DateRange;
  maxSqUnit?: Maybe<Scalars['Float']>;
  minSqUnit?: Maybe<Scalars['Float']>;
  projectIds: Array<Scalars['ID']>;
  statuses: Array<ProjectStatus>;
};

/** Filters for project timings report */
export type ProjectsTimingsInput = {
  dateRange?: Maybe<DateRangeInput>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ProjectStatus>>;
  billableOption?: Maybe<Billable>;
  minSqUnit?: Maybe<Scalars['Float']>;
  maxSqUnit?: Maybe<Scalars['Float']>;
  datePreset?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ProjectsTimings. */
export type ProjectsTimingsPayload = {
  __typename?: 'ProjectsTimingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type ProjectsTimingsProjectListEntry = {
  __typename?: 'ProjectsTimingsProjectListEntry';
  consumed: HoursAndFee;
  costs: ProjectsTimingsConstructionCost;
  issuedFee: Scalars['Int'];
  planned: HoursAndFee;
  project: Project;
  projectCost?: Maybe<ProjectCost>;
};

export type ProjectsTimingsTotals = {
  __typename?: 'ProjectsTimingsTotals';
  consumed: ProjectsTimingsTotalsGroup;
  filteredProjectCount: Scalars['Int'];
  invoicesCount: Scalars['Int'];
  issuedFee: Scalars['Int'];
  planned: ProjectsTimingsTotalsGroup;
  projectPercentage: Scalars['Int'];
  projectTotal: Scalars['Int'];
};

export type ProjectsTimingsTotalsGroup = {
  __typename?: 'ProjectsTimingsTotalsGroup';
  billableFeePercent: Scalars['Float'];
  fee: Scalars['Int'];
  hours: Scalars['Int'];
};

export type ProjectsUnbilled = {
  __typename?: 'ProjectsUnbilled';
  clientName?: Maybe<Scalars['String']>;
  currentUnbilled: Scalars['Float'];
  firstPhaseBeginDate?: Maybe<Scalars['ISO8601Date']>;
  firstTimelogEntryDate?: Maybe<Scalars['ISO8601Date']>;
  lastInvoiceEndDate?: Maybe<Scalars['ISO8601Date']>;
  lastInvoiceTotal: Scalars['Float'];
  minUnbilledEntryDate?: Maybe<Scalars['ISO8601Date']>;
  percentComplete: Scalars['Float'];
  projectColor: Scalars['String'];
  projectId: Scalars['String'];
  projectLink: Scalars['String'];
  projectName: Scalars['String'];
  projectNumber?: Maybe<Scalars['String']>;
  totalInvoiced: Scalars['Float'];
  totalPlannedServices: Scalars['Float'];
  totalUnbilled: Scalars['Float'];
};

/** The connection type for ProjectsUnbilled. */
export type ProjectsUnbilledConnection = {
  __typename?: 'ProjectsUnbilledConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProjectsUnbilledEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProjectsUnbilled>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  totals: ProjectUnbilledTotals;
};

/** An edge in a connection. */
export type ProjectsUnbilledEdge = {
  __typename?: 'ProjectsUnbilledEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProjectsUnbilled>;
};

/** All available sorts for unbilled projects */
export enum ProjectsUnbilledFieldInput {
  ProjectNumber = 'PROJECT_NUMBER',
  ProjectName = 'PROJECT_NAME',
  Client = 'CLIENT',
  TotalPlannedServices = 'TOTAL_PLANNED_SERVICES',
  PercentComplete = 'PERCENT_COMPLETE',
  TotalInvoiced = 'TOTAL_INVOICED',
  LastInvoiced = 'LAST_INVOICED',
  TotalUnbilled = 'TOTAL_UNBILLED',
  CurrentUnbilled = 'CURRENT_UNBILLED'
}

/** Filters for unbilled project report */
export type ProjectsUnbilledFiltersInput = {
  projectId?: Maybe<Scalars['ID']>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  clientId?: Maybe<Scalars['ID']>;
  clientIds?: Maybe<Array<Scalars['ID']>>;
  projectType?: Maybe<UnbilledProject>;
  categoryId?: Maybe<Scalars['ID']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
};

export type ProjectsUnbilledSortInput = {
  field: ProjectsUnbilledFieldInput;
  direction: SortDirectionInput;
};

export type PublicCompensation = {
  __typename?: 'PublicCompensation';
  effectiveOn: Scalars['ISO8601Date'];
  effectiveUntil?: Maybe<Scalars['ISO8601Date']>;
  hoursPerWeek: Scalars['Float'];
  id: Scalars['ID'];
  isCurrent: Scalars['Boolean'];
};

export type QboAccount = {
  __typename?: 'QBOAccount';
  accountSubType?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  costCategory?: Maybe<CostCategory>;
  description?: Maybe<Scalars['String']>;
  fullyQualifiedName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  parentQuickbooksId?: Maybe<Scalars['ID']>;
  quickbooksId: Scalars['ID'];
};

export type QboAccountCostCategoryMapping = {
  qboAccountId: Scalars['ID'];
  costCategory: CostCategory;
};

/** QBO account types available for fetching */
export enum QboAccountType {
  Bank = 'BANK',
  Expense = 'EXPENSE',
  OtherExpense = 'OTHER_EXPENSE',
  Cogs = 'COGS',
  OtherCurrentAsset = 'OTHER_CURRENT_ASSET'
}

export type QboAddress = {
  __typename?: 'QBOAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type QboCustomer = {
  __typename?: 'QBOCustomer';
  active: Scalars['Boolean'];
  billingAddressCity?: Maybe<Scalars['String']>;
  billingAddressCountry?: Maybe<Scalars['String']>;
  billingAddressLine1?: Maybe<Scalars['String']>;
  billingAddressLine2?: Maybe<Scalars['String']>;
  billingAddressState?: Maybe<Scalars['String']>;
  billingAddressZipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  descendants?: Maybe<Array<QboCustomer>>;
  displayName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullyQualifiedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  parentQuickbooksId?: Maybe<Scalars['ID']>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  processed: Scalars['Boolean'];
  project: Scalars['Boolean'];
  qboCreatedAt: Scalars['ISO8601DateTime'];
  qboInstance: QboInstance;
  qboInstanceId: Scalars['ID'];
  qboUpdatedAt: Scalars['ISO8601DateTime'];
  quickbooksId: Scalars['ID'];
  quickbooksSyncToken?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  taxable: Scalars['Boolean'];
  zipcode?: Maybe<Scalars['String']>;
};

export type QboCustomerClientMatch = {
  __typename?: 'QBOCustomerClientMatch';
  clientId?: Maybe<Scalars['ID']>;
  qboCustomerId: Scalars['ID'];
};

export type QboCustomerFilters = {
  noMatchingMgClient?: Maybe<Scalars['Boolean']>;
  l0Only?: Maybe<Scalars['Boolean']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

/** Entities that may have raised error when sending batch expenses to QBO */
export enum QboExpenseBatchErrorEntity {
  Purchase = 'purchase',
  Bill = 'bill',
  Attachment = 'attachment',
  Vendor = 'vendor'
}

/** Autogenerated input type of QBO */
export type QboInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
};

export type QboInstance = {
  __typename?: 'QBOInstance';
  companyName?: Maybe<Scalars['String']>;
  connected: Scalars['Boolean'];
  id: Scalars['ID'];
  lastConnectedAt: Scalars['ISO8601DateTime'];
  matchedQboCustomersCount: Scalars['Int'];
  matchedQboVendorsCount: Scalars['Int'];
  qboAccountPayableAccounts: Array<QboAccount>;
  qboExpenseAccounts: Array<QboAccount>;
  qboItems: Array<QboItem>;
  qboPaymentAccounts: Array<QboAccount>;
  qboTaxMethod?: Maybe<QboTaxMethod>;
  qboTerms: Array<QboTerm>;
  /** Count of unassigned project costs imported in the last 30 days */
  unassignedProjectCostsCount: Scalars['Int'];
};

export type QboItem = {
  __typename?: 'QBOItem';
  active: Scalars['Boolean'];
  fullyQualifiedName: Scalars['String'];
  id: Scalars['ID'];
  itemType: QboItemItem;
  name: Scalars['String'];
  parentQuickbooksId?: Maybe<Scalars['String']>;
  qboInstance: QboInstance;
  quickbooksId: Scalars['String'];
};

export enum QboItemItem {
  Service = 'Service',
  Category = 'Category',
  Inventory = 'Inventory',
  NonInventory = 'NonInventory',
  Group = 'Group'
}

export type QboJournalEntryCost = {
  __typename?: 'QBOJournalEntryCost';
  amount: Scalars['Float'];
  costCategory?: Maybe<CostCategory>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  project?: Maybe<Project>;
  qboAccount: QboAccount;
  qboCustomer?: Maybe<QboCustomer>;
  qboVendor?: Maybe<QuickbooksVendor>;
  transactionDate?: Maybe<Scalars['ISO8601Date']>;
};

/** The connection type for QBOJournalEntryCost. */
export type QboJournalEntryCostConnection = {
  __typename?: 'QBOJournalEntryCostConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QboJournalEntryCostEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<QboJournalEntryCost>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type QboJournalEntryCostEdge = {
  __typename?: 'QBOJournalEntryCostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<QboJournalEntryCost>;
};

/** Autogenerated return type of QBO. */
export type QboPayload = {
  __typename?: 'QBOPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** All available qbo tax methods */
export enum QboTaxMethod {
  Can = 'can',
  Ast = 'ast',
  NonAst = 'non_ast'
}

export type QboTerm = {
  __typename?: 'QBOTerm';
  active: Scalars['Boolean'];
  dueDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  qboInstance: QboInstance;
  quickbooksId: Scalars['ID'];
};

export type QboVendorConsultantMatch = {
  __typename?: 'QBOVendorConsultantMatch';
  consultantCompanyId?: Maybe<Scalars['ID']>;
  qboVendorId: Scalars['ID'];
};

export type QboVendorFilters = {
  noMatchingMgConsultant?: Maybe<Scalars['Boolean']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  /** Returns all activities for current organization */
  activities?: Maybe<Array<Activity>>;
  admin: Admin;
  /** Returns active Clients for current organization without permission checks */
  allClients?: Maybe<Array<ClientList>>;
  /** Returns filtered expenses without pagination for current organization */
  allExpenses?: Maybe<Array<Expense>>;
  /** Returns AI assistant messages */
  assistantMessage: Array<AssistantMessage>;
  /** Returns all categories for current organization */
  categories?: Maybe<Array<Category>>;
  /** Returns export by ID */
  checkExport?: Maybe<ExportStatus>;
  /** Returns a single client */
  client: Client;
  /** Returns all clients for current organization */
  clients: ClientConnection;
  /** Returns a single consultant bill by id */
  consultantBill: ConsultantBill;
  /** Returns an array of consultant bills for the current organization */
  consultantBills: ConsultantBillConnection;
  /** Returns an array of consultant companies for the current organization */
  consultantCompanies: Array<ConsultantCompany>;
  /** Returns a single consultant company by slug */
  consultantCompany: ConsultantCompany;
  /** Returns a single consultant contact by slug */
  consultantContact: ConsultantContact;
  /** Returns an array of consultant contacts */
  consultantContacts: Array<ConsultantContact>;
  /** Returns a list of consultant templates for an organization */
  consultantTemplates: Array<ConsultantTemplate>;
  /** Returns all currencies */
  currencies: Array<Currency>;
  currentProfile?: Maybe<Profile>;
  /** Returns a paginated and sorted list of filtered entries. Entry represents a fact that some profile tracked working hours on a specific date for the project. */
  entries: EntryConnection;
  /** Returns a single entry group */
  entryGroup?: Maybe<EntryGroup>;
  /** Returns a single entry row */
  entryRow?: Maybe<EntryRow>;
  /** Returns all expense categories for current organization */
  expenseCategories: Array<ExpenseCategory>;
  /** Returns all available states of Expense type select */
  expenseChargeTypes: Array<ExpenseChargeType>;
  /** Returns filtered and sorted expenses for current organization */
  expenses: ExpenseConnection;
  /** List feature flags enabled for current user and organization */
  featureFlags: FeatureFlags;
  /** Potentially returns a matching QuickBooks customer */
  findMatchingCustomer?: Maybe<QboCustomer>;
  /** Returns data for a Gantt chart */
  ganttChart: GanttChartResponse;
  /** Returns the preview of resources to be affected by a rebalance operation */
  globalRebalanceAllocationsPreview: GlobalRebalanceAllocationsPreview;
  /** Filters entries, groups them and calculates totals. Entry represents a fact that some profile tracked working hours on a specific date for the project. */
  groupedEntries: Array<GroupedEntriesGroup>;
  highcharts: Highcharts;
  /** Returns a single invoice */
  invoice: Invoice;
  /** Returns data for invoice builder by invoice slug */
  invoiceBuilder?: Maybe<InvoiceBuilderPayload>;
  /** Returns filtered and sorted invoice payments for current organization */
  invoicePayments: InvoicePaymentConnection;
  /** Returns an invoice reminders scheduler */
  invoiceRemindersScheduler?: Maybe<InvoiceRemindersScheduler>;
  /** Returns filtered and sorted invoices for current organization */
  invoices: InvoiceConnection;
  /** Returns all locales */
  locales: Array<Locale>;
  me?: Maybe<User>;
  /** Returns a single milestone */
  milestone: Milestone;
  /** Returns a profiles onboarding progress data */
  onboarding: Onboarding;
  /** Returns a list of industry options for onboarding */
  onboardingIndustry: OnboardingIndustry;
  /** Returns a list of location options for onboarding */
  onboardingLocation: OnboardingLocation;
  /** Returns organization export data in json format, for easy conversion to csv */
  organizationExports: OrganizationExport;
  /** Returns the client secret of an invoice's payment intent */
  paymentIntentSecret: PaymentIntentSecret;
  /** List of payment methods for the current user */
  paymentMethods: Array<PaymentMethod>;
  /** Returns filtered and sorted payouts for current organization */
  payouts: PayoutConnection;
  /** Gets payroll info for current org, if it exists */
  payroll: Company;
  /** Returns the remaining steps on the onboarding for an org and worker, if applicable */
  payrollOnboardStats?: Maybe<OnboardStats>;
  /** Returns a payroll waitlister if it was created by a someone at the organization */
  payrollWaitlister?: Maybe<PayrollWaitlister>;
  /** Returns a single phase */
  phase: Phase;
  /** Returns a list of phase templates for the current organization */
  phaseTemplates: Array<PhaseTemplate>;
  /** Returns a single planable (with permission checks) */
  planable?: Maybe<Planable>;
  /** Returns a single profile */
  profile: Profile;
  /** Returns a paginated list of filtered and sorted profiles for the current organization */
  profiles: ProfileConnection;
  /** Returns a single project (with permission checks) */
  project?: Maybe<Project>;
  /** Returns a list of project colors */
  projectColors: Array<ProjectColor>;
  /** Returns all expense categories for project */
  projectExpenseCategories: Array<ExpenseCategory>;
  /** Returns project profit data (not to confuse with profit drivers) */
  projectProfit?: Maybe<ProjectProfit>;
  /** Returns a single project template */
  projectTemplate?: Maybe<ProjectTemplate>;
  /** Returns project templates for the current organization */
  projectTemplates: ProjectTemplateConnection;
  /** Returns non-archived projects for current organization (with permission checks) */
  projects?: Maybe<Array<Project>>;
  /** Returns non-archived projects for current organization without permission checks */
  projectsOptions?: Maybe<Array<ProjectOption>>;
  /** Returns invoice builder preview data by invoice payment uuid */
  publicInvoice?: Maybe<InvoiceBuilderPayload>;
  /** Returns a list of Quickbooks Online instances */
  qboInstances: Array<QboInstance>;
  /** Returns a list of all account payable accounts from Quickbooks */
  quickbooksAccountPayableAccounts?: Maybe<Array<QboAccount>>;
  /** Returns a list of imported QBO costs filtered and sorted for current organization */
  quickbooksCosts: QboJournalEntryCostConnection;
  /** Returns a QBO customer */
  quickbooksCustomer: QboCustomer;
  /** Returns list of QBO L0 clients for current organization */
  quickbooksCustomers: Array<QboCustomer>;
  /** Lists QuickBooks customer IDs alongside their potential match with a Monograph client */
  quickbooksCustomersPotentialMatchingClients: Array<QboCustomerClientMatch>;
  /** Returns a list of expense accounts from Quickbooks */
  quickbooksExpenseAccounts?: Maybe<Array<QboAccount>>;
  /** Returns a list of all accounts from Quickbooks of type bank or card */
  quickbooksPaymentAccounts?: Maybe<Array<QboAccount>>;
  /** Returns list of QBO vendors for the current organization */
  quickbooksVendors: Array<QuickbooksVendor>;
  /** Lists QuickBooks vendor IDs alongside their potential match with a Monograph consultant */
  quickbooksVendorsPotentialMatchingConsultants: Array<QboVendorConsultantMatch>;
  /** Returns a list of vendors to import from Quickbooks */
  quickbooksVendorsToImport: Array<QuickbooksVendor>;
  /** Returns a single rate */
  rate: Rate;
  /** Returns a single rate table */
  rateTable: RateTable;
  /** Returns filtered and sorted received invoices, as well as possible filters for current user. */
  receivedInvoices: ReceivedInvoices;
  reports: Reports;
  /** Returns a single role */
  role: Role;
  /** Given an ID, returns a role template for the current org */
  roleTemplate?: Maybe<RoleTemplate>;
  /** Returns all role templates for current organization */
  roleTemplates?: Maybe<Array<RoleTemplate>>;
  /** Returns filtered projects for current organization (with permission checks) */
  searchableProjects?: Maybe<Array<Project>>;
  /** Returns filtered timesheets for current organization (with permission checks) */
  searchableTimesheets?: Maybe<Array<Timesheet>>;
  staffing: Staffing;
  /** Returns all standard holidays */
  standardHolidays: Array<StandardHoliday>;
  /** Returns the stripe account owned by the organization */
  stripeAccount?: Maybe<StripeAccount>;
  /** Returns a stripe dashboard login link for an organization */
  stripeAccountDashboardLink: DashboardLink;
  /** Returns a single task */
  task: Task;
  /** Returns all tax rates for current organization */
  taxRates?: Maybe<Array<TaxRate>>;
  /** Returns a single time off holiday by id */
  timeOffHoliday: TimeOffHoliday;
  /** Returns a single time off holiday policy by id or slug */
  timeOffHolidayPolicy: TimeOffHolidayPolicy;
  /** Returns a single time off policy by id or slug */
  timeOffPolicy: TimeOffPolicy;
  timeOffPolicyAssignment: TimeOffPolicyAssignment;
  /** Returns a single time off request by id */
  timeOffRequest: TimeOffRequest;
  /** Returns an array of time off requests for the current organization */
  timeOffRequests: TimeOffRequestConnection;
  /** Returns all time zones */
  timeZones: Array<TimeZone>;
  timer: Timer;
  /** Returns a single timesheet */
  timesheets?: Maybe<Timesheet>;
};


export type QueryAllClientsArgs = {
  filters?: Maybe<ClientFiltersInput>;
};


export type QueryAllExpensesArgs = {
  filters?: Maybe<ExpenseFiltersInput>;
};


export type QueryCheckExportArgs = {
  id: Scalars['ID'];
};


export type QueryClientArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryClientsArgs = {
  sortBy?: Maybe<ClientsSortInput>;
  filters?: Maybe<ClientFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryConsultantBillArgs = {
  id: Scalars['ID'];
};


export type QueryConsultantBillsArgs = {
  sortBy?: Maybe<ConsultantBillsSortInput>;
  filters?: Maybe<ConsultantBillFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryConsultantCompaniesArgs = {
  qboSyncedOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryConsultantCompanyArgs = {
  slug: Scalars['String'];
};


export type QueryConsultantContactArgs = {
  slug: Scalars['String'];
};


export type QueryConsultantContactsArgs = {
  companySlug?: Maybe<Scalars['String']>;
  onlyActiveCompanies?: Maybe<Scalars['Boolean']>;
};


export type QueryConsultantTemplatesArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


export type QueryEntriesArgs = {
  sortBy?: Maybe<EntriesSortInput>;
  filters: EntryFiltersInput;
  intent: TimeLogIntent;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEntryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryEntryRowArgs = {
  id: Scalars['ID'];
};


export type QueryExpensesArgs = {
  sortBy?: Maybe<ExpensesSortInput>;
  filters?: Maybe<ExpenseFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryFindMatchingCustomerArgs = {
  clientName: Scalars['String'];
  qboInstanceId?: Maybe<Scalars['ID']>;
};


export type QueryGanttChartArgs = {
  isTemplate?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<GanttChartFiltersInput>;
};


export type QueryGlobalRebalanceAllocationsPreviewArgs = {
  profileIds?: Maybe<Array<Scalars['ID']>>;
  phaseIds?: Maybe<Array<Scalars['ID']>>;
  dateRange: DateRangeInput;
};


export type QueryGroupedEntriesArgs = {
  intent: TimeLogIntent;
  groupBy: GroupByInput;
  filters: EntryFiltersInput;
};


export type QueryInvoiceArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryInvoiceBuilderArgs = {
  slug: Scalars['String'];
  previewMode?: Maybe<Scalars['Boolean']>;
};


export type QueryInvoicePaymentsArgs = {
  sortBy?: Maybe<InvoicePaymentsSortInput>;
  filters?: Maybe<PaymentFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceRemindersSchedulerArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryInvoicesArgs = {
  sortBy?: Maybe<InvoicesSortInput>;
  filters?: Maybe<InvoiceFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMilestoneArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationExportsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryPaymentIntentSecretArgs = {
  paymentUuid: Scalars['String'];
};


export type QueryPayoutsArgs = {
  sortBy?: Maybe<PayoutsSortInput>;
  filters?: Maybe<PayoutFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPhaseArgs = {
  id: Scalars['ID'];
};


export type QueryPlanableArgs = {
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
};


export type QueryProfileArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryProfilesArgs = {
  filters?: Maybe<ProfileFiltersInput>;
  sortBy?: Maybe<ProfilesSortInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProjectArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryProjectExpenseCategoriesArgs = {
  projectId?: Maybe<Scalars['ID']>;
  projectSlug?: Maybe<Scalars['String']>;
};


export type QueryProjectProfitArgs = {
  slug: Scalars['String'];
};


export type QueryProjectTemplateArgs = {
  slug: Scalars['String'];
};


export type QueryProjectTemplatesArgs = {
  sortBy?: Maybe<ProjectTemplatesSortInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProjectsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<ProjectStatus>>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
};


export type QueryPublicInvoiceArgs = {
  paymentUuid: Scalars['String'];
};


export type QueryQuickbooksCostsArgs = {
  sortBy?: Maybe<CostsSortInput>;
  filters?: Maybe<CostsFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryQuickbooksCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryQuickbooksCustomersArgs = {
  filters?: Maybe<QboCustomerFilters>;
};


export type QueryQuickbooksCustomersPotentialMatchingClientsArgs = {
  qboInstanceId?: Maybe<Scalars['ID']>;
};


export type QueryQuickbooksExpenseAccountsArgs = {
  accountType?: Maybe<Array<QboAccountType>>;
};


export type QueryQuickbooksVendorsArgs = {
  filters?: Maybe<QboVendorFilters>;
};


export type QueryQuickbooksVendorsPotentialMatchingConsultantsArgs = {
  qboInstanceId?: Maybe<Scalars['ID']>;
};


export type QueryRateArgs = {
  id: Scalars['ID'];
};


export type QueryRateTableArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryReceivedInvoicesArgs = {
  filters?: Maybe<ReceivedInvoicesFiltersInput>;
  sortBy?: Maybe<ReceivedInvoicesSortInput>;
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRoleTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryRoleTemplatesArgs = {
  includeDeactivated?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchableProjectsArgs = {
  parameters: SearchableProjectsParametersInput;
};


export type QuerySearchableTimesheetsArgs = {
  parameters: SearchableTimesheetsParametersInput;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaxRatesArgs = {
  qboInstanceId?: Maybe<Scalars['ID']>;
};


export type QueryTimeOffHolidayArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTimeOffHolidayPolicyArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryTimeOffPolicyArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryTimeOffPolicyAssignmentArgs = {
  timeOffPolicySlug?: Maybe<Scalars['String']>;
  timeOffPolicyId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
};


export type QueryTimeOffRequestArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTimeOffRequestsArgs = {
  sortBy?: Maybe<TimeOffRequestsSortInput>;
  filters?: Maybe<TimeOffRequestsFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTimesheetsArgs = {
  id: Scalars['ID'];
};

export type QuickbooksItem = {
  __typename?: 'QuickbooksItem';
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemType: QuickbooksItemItem;
  name: Scalars['String'];
  quickbooksId: Scalars['String'];
  quickbooksParentRef?: Maybe<Scalars['String']>;
};

/** All available Quickbooks Item types */
export enum QuickbooksItemItem {
  Service = 'SERVICE',
  Category = 'CATEGORY'
}

export type QuickbooksVendor = {
  __typename?: 'QuickbooksVendor';
  active: Scalars['Boolean'];
  address?: Maybe<QboAddress>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lname?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  quickbooksVendorId: Scalars['ID'];
  quickbooksVendorSyncToken: Scalars['ID'];
  website?: Maybe<Scalars['String']>;
};

/** A rate is a billing amount for a Role Template for a given period of time (starting with its effective_on and, if applicable, ending with the role template's next rate.effective_on) */
export type Rate = {
  __typename?: 'Rate';
  amount: Scalars['Float'];
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  /** Returns dependency counts for a given rate */
  dependencyCounts?: Maybe<RateDependencyCounts>;
  displayDate: Scalars['String'];
  effectiveOn: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  isCurrentRate: Scalars['Boolean'];
  isInitialRate: Scalars['Boolean'];
  rateTable?: Maybe<RateTable>;
  /** @deprecated No longer directly belongs to a role template. Use rate_table.role_template instead. */
  roleTemplate?: Maybe<RoleTemplate>;
};

/** An object containing the total number of projects, roles, and profiles that will be affected by changing/removing a rate. */
export type RateDependencyCounts = {
  __typename?: 'RateDependencyCounts';
  profiles: Array<Profile>;
  projects: Scalars['Int'];
  roles: Scalars['Int'];
};

/**
 * A rate table is a named, date-delimited collection of rates on a role template. It is assigned to a role
 * to automatically update that role's billing rate as time goes by.
 */
export type RateTable = {
  __typename?: 'RateTable';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  currentRate: Rate;
  /** Returns dependency counts for a given rate table */
  dependencyCounts?: Maybe<RateTableDependencyCounts>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderedRates: Array<Rate>;
  profiles?: Maybe<Array<Profile>>;
  rates: Array<Rate>;
  roleTemplate: RoleTemplate;
  roleTemplateId: Scalars['ID'];
  roles?: Maybe<Array<Role>>;
  slug: Scalars['String'];
};

/** An object containing the total number of projects and profiles that will be affected by changing/removing a rate table. */
export type RateTableDependencyCounts = {
  __typename?: 'RateTableDependencyCounts';
  profiles: Array<Profile>;
  projects: Scalars['Int'];
};

/** Autogenerated input type of ReactivateClient */
export type ReactivateClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactivateClient. */
export type ReactivateClientPayload = {
  __typename?: 'ReactivateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReactivateConsultantCompany */
export type ReactivateConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactivateConsultantCompany. */
export type ReactivateConsultantCompanyPayload = {
  __typename?: 'ReactivateConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of ReactivateConsultantContact */
export type ReactivateConsultantContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactivateConsultantContact. */
export type ReactivateConsultantContactPayload = {
  __typename?: 'ReactivateConsultantContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantContact?: Maybe<ConsultantContact>;
};

/** Autogenerated input type of ReactivateRateTable */
export type ReactivateRateTableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ReactivateRateTable. */
export type ReactivateRateTablePayload = {
  __typename?: 'ReactivateRateTablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rateTable: RateTable;
};

export type RealizationChartEntry = {
  __typename?: 'RealizationChartEntry';
  data: RealizationChartEntryData;
  xAxis: Scalars['ISO8601Date'];
};

export type RealizationChartEntryData = {
  __typename?: 'RealizationChartEntryData';
  billableFees: Scalars['Float'];
  invoicedFees: Scalars['Float'];
  realizationRate: Scalars['Float'];
};

/** Filters for realization report */
export type RealizationFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of Realization */
export type RealizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions entries should satisfy */
  filters?: Maybe<RealizationFiltersInput>;
};

/** Autogenerated return type of Realization. */
export type RealizationPayload = {
  __typename?: 'RealizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type RealizationProjectEntry = {
  __typename?: 'RealizationProjectEntry';
  billableFees: Scalars['Float'];
  invoicedFees: Scalars['Float'];
  projectName: Scalars['String'];
  realizationRate: Scalars['Float'];
  unbilledWork: Scalars['Float'];
};

export type RealizationReport = {
  __typename?: 'RealizationReport';
  chart: Array<RealizationChartEntry>;
  dateIncrement: DateIncrement;
  defaultRealizationRate?: Maybe<Scalars['Float']>;
  earliestEntryDate?: Maybe<Scalars['ISO8601DateTime']>;
  table: Array<RealizationTableEntryData>;
  totals: RealizationTotals;
};

export type RealizationTableEntryData = {
  __typename?: 'RealizationTableEntryData';
  billableFees: Scalars['Float'];
  invoicedFees: Scalars['Float'];
  rangeEnd: Scalars['ISO8601Date'];
  rangeStart: Scalars['ISO8601Date'];
  realizationRate: Scalars['Float'];
};

export type RealizationTotals = {
  __typename?: 'RealizationTotals';
  billableFees: Scalars['Float'];
  invoicedFees: Scalars['Float'];
  realizationRate: Scalars['Float'];
};

export type ReceivedInvoice = {
  __typename?: 'ReceivedInvoice';
  emailAddress: Scalars['String'];
  id: Scalars['ID'];
  invoice: Invoice;
};

/** The connection type for ReceivedInvoice. */
export type ReceivedInvoiceConnection = {
  __typename?: 'ReceivedInvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReceivedInvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReceivedInvoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReceivedInvoiceEdge = {
  __typename?: 'ReceivedInvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ReceivedInvoice>;
};

export type ReceivedInvoices = {
  __typename?: 'ReceivedInvoices';
  filters: ReceivedInvoicesFiltersType;
  invoices: ReceivedInvoiceConnection;
  totals: ReceivedInvoicesTotals;
};


export type ReceivedInvoicesInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** All available sorts for received invoices */
export enum ReceivedInvoicesFieldInput {
  InvoiceNumber = 'INVOICE_NUMBER',
  OrganizationName = 'ORGANIZATION_NAME',
  ProjectName = 'PROJECT_NAME',
  IssueDate = 'ISSUE_DATE',
  Amount = 'AMOUNT',
  Status = 'STATUS',
  DueDate = 'DUE_DATE',
  PaymentType = 'PAYMENT_TYPE',
  PaidDate = 'PAID_DATE'
}

/** Filters for received invoices */
export type ReceivedInvoicesFiltersInput = {
  organizationIds?: Maybe<Array<Scalars['ID']>>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<InvoiceStatus>>;
};

export type ReceivedInvoicesFiltersType = {
  __typename?: 'ReceivedInvoicesFiltersType';
  organizations: Array<Organization>;
  projects: Array<Project>;
};

export type ReceivedInvoicesSortInput = {
  field: ReceivedInvoicesFieldInput;
  direction: SortDirectionInput;
};

export type ReceivedInvoicesTotalWithCountType = {
  __typename?: 'ReceivedInvoicesTotalWithCountType';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type ReceivedInvoicesTotals = {
  __typename?: 'ReceivedInvoicesTotals';
  open: ReceivedInvoicesTotalWithCountType;
  paid: ReceivedInvoicesTotalWithCountType;
  pastDue: ReceivedInvoicesTotalWithCountType;
};

export type ReimbursableExpensesValidationWorker = {
  __typename?: 'ReimbursableExpensesValidationWorker';
  expenses: Array<Expense>;
  profile: Profile;
};

/** Autogenerated input type of RemoveConsultantBillInvoiceAssignment */
export type RemoveConsultantBillInvoiceAssignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveConsultantBillInvoiceAssignment. */
export type RemoveConsultantBillInvoiceAssignmentPayload = {
  __typename?: 'RemoveConsultantBillInvoiceAssignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of RemoveConsultantBillInvoiceAttachment */
export type RemoveConsultantBillInvoiceAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveConsultantBillInvoiceAttachment. */
export type RemoveConsultantBillInvoiceAttachmentPayload = {
  __typename?: 'RemoveConsultantBillInvoiceAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of RemoveLinkedPhases */
export type RemoveLinkedPhasesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RemoveLinkedPhases. */
export type RemoveLinkedPhasesPayload = {
  __typename?: 'RemoveLinkedPhasesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
};

export type Report = {
  __typename?: 'Report';
  csv: Scalars['String'];
};

/** All Check Report types */
export enum ReportCategory {
  Summary = 'summary',
  Journal = 'journal'
}

export type Reports = {
  __typename?: 'Reports';
  /**
   * Returns a timelog data for the specified project or for the current organization.
   *
   * The report is based on the tracked time. Each row represents the time interval and contains aggregated hours and fee (billable and not billable).
   */
  bulkEdit: TimeLog;
  /** Returns financials data for the each phase of the specified project */
  financials: ProjectFinancials;
  /** Returns totals for invoices during a given time period and with filters applied */
  invoiceTotals: InvoicesTotals;
  /** Returns qbo profit data for an organization */
  nonQboProfit?: Maybe<ProfitNonQboVersion>;
  /**
   * Returns profitability data for the organization.
   *
   * The report contains data based on both planned and actual data.
   * If "revenue_source" argument is provided an invoice date type,
   * historical entries will be calculated using revenue from invoices.
   * Each row represents the time interval and contains aggregated data.
   */
  profit: ProfitReport;
  /** This report helps to understand where organization earns and loses money. It groups projects by the specified entry and calculates aggregates for each group. */
  profitDriversV2: Array<ProfitDriversV2>;
  /** Returns projected profit data for an organization */
  profitForecast?: Maybe<ProfitForecast>;
  /**
   * Returns project performance data for the specified project.
   *
   * The report is based on the planned and consumed time.
   */
  projectPerformance: ProjectPerformance;
  /** Returns project profit data (not to confuse with profit drivers) */
  projectProfit?: Maybe<ProjectProfit>;
  /**
   * Returns a consumed and planned time and fee for all (maybe filtered) projects in current organization.
   *
   * Consumed time is calculated based on entries. Entry represents a fact that some profile tracked working hours on a specific date for the project.
   *
   * Planned time is calculated based on phase dates and planned hours. Hours are calculcated based on roles attached to this phase.
   */
  projectsTimings: ProjectsTimings;
  /**
   * Returns a list of projects along with their total invoiced dollar amount and unbilled amount.
   *
   * The Unbilled report allows customers to easily see what projects they should consider invoicing for based on unbilled work.
   */
  projectsUnbilled: ProjectsUnbilledConnection;
  /** Returns qbo profit data for an organization */
  qboProfit?: Maybe<ProfitQboVersion>;
  realization: RealizationReport;
  realizationProjects: Array<RealizationProjectEntry>;
  /** Returns filtered and sorted taxes collected for current organization */
  taxesCollected: Array<TaxRateTotals>;
  teamActivity: TeamActivityReport;
  teamForecast: TeamForecastReport;
  /**
   * Returns a timelog data for the specified project or for the current organization.
   *
   * The report is based on the tracked time. Each row represents the time interval and contains aggregated hours and fee (billable and not billable).
   */
  timeLog: TimeLog;
  utilization: UtilizationReport;
  utilizationPeople: Array<UtilizationProfileEntry>;
};


export type ReportsBulkEditArgs = {
  filters?: Maybe<EntryFiltersInput>;
};


export type ReportsFinancialsArgs = {
  slug: Scalars['String'];
  includeConsultantsInPhases: Scalars['Boolean'];
  filters?: Maybe<ProjectFinancialsFiltersInput>;
};


export type ReportsInvoiceTotalsArgs = {
  filters?: Maybe<InvoiceFiltersInput>;
};


export type ReportsNonQboProfitArgs = {
  filters: ProfitFiltersInput;
};


export type ReportsProfitArgs = {
  dateRange: DateRangeInput;
  statuses?: Maybe<Array<ProjectStatus>>;
  phaseFeeType?: Maybe<PhaseFee>;
  revenueSource: ProfitRevenueSource;
  includeConsultants?: Maybe<Scalars['Boolean']>;
};


export type ReportsProfitDriversV2Args = {
  filters: ProfitDriversV2FiltersInput;
};


export type ReportsProfitForecastArgs = {
  filters: ProfitForecastFiltersInput;
};


export type ReportsProjectPerformanceArgs = {
  filters?: Maybe<ProjectPerformanceFiltersInput>;
};


export type ReportsProjectProfitArgs = {
  slug: Scalars['String'];
};


export type ReportsProjectsTimingsArgs = {
  filters?: Maybe<ProjectTimingsFiltersInput>;
};


export type ReportsProjectsUnbilledArgs = {
  sortBy?: Maybe<ProjectsUnbilledSortInput>;
  filters?: Maybe<ProjectsUnbilledFiltersInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ReportsQboProfitArgs = {
  filters: ProfitFiltersInput;
};


export type ReportsRealizationArgs = {
  filters?: Maybe<RealizationFiltersInput>;
};


export type ReportsRealizationProjectsArgs = {
  filters?: Maybe<RealizationFiltersInput>;
};


export type ReportsTaxesCollectedArgs = {
  sortBy?: Maybe<TaxesSortInput>;
  filters?: Maybe<TaxRatesFiltersInput>;
};


export type ReportsTeamActivityArgs = {
  filters?: Maybe<TeamActivityFiltersInput>;
};


export type ReportsTeamForecastArgs = {
  filters?: Maybe<TeamForecastFiltersInput>;
};


export type ReportsTimeLogArgs = {
  intent: TimeLogIntent;
  filters?: Maybe<TimeLogFiltersInput>;
};


export type ReportsUtilizationArgs = {
  filters?: Maybe<UtilizationFiltersInput>;
};


export type ReportsUtilizationPeopleArgs = {
  filters?: Maybe<UtilizationFiltersInput>;
};

/** Autogenerated input type of RequestSavePaymentDetails */
export type RequestSavePaymentDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentUuid: Scalars['String'];
  paymentIntentId: Scalars['String'];
  fname: Scalars['String'];
  lname: Scalars['String'];
  email: Scalars['String'];
};

/** Autogenerated return type of RequestSavePaymentDetails. */
export type RequestSavePaymentDetailsPayload = {
  __typename?: 'RequestSavePaymentDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of ResetDemo */
export type ResetDemoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

/** Autogenerated return type of ResetDemo. */
export type ResetDemoPayload = {
  __typename?: 'ResetDemoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type Retainer = {
  __typename?: 'Retainer';
  id: Scalars['ID'];
  qboItem?: Maybe<QboItem>;
};

export type RetainerSummary = {
  __typename?: 'RetainerSummary';
  applied: Scalars['Float'];
  openApplied: Scalars['Float'];
  openReceived: Scalars['Float'];
  received: Scalars['Float'];
  remaining: Scalars['Float'];
  title: Scalars['String'];
};

/** Role is the fact that some profile was assigned to work on some project phase. Role has name, houly rate (for billing) and planned budget. */
export type Role = {
  __typename?: 'Role';
  allRolesEntryHoursCount?: Maybe<Scalars['Float']>;
  /** planned budget for this phase */
  budget: Scalars['Float'];
  /** budget allocated between now and the end of the phase */
  budgetAllocated: Scalars['Float'];
  /** budget logged in the phase thus far */
  budgetConsumed: Scalars['Float'];
  entryHoursCount: Scalars['Float'];
  exclusivelyHasUserAllocations: Scalars['Boolean'];
  hasLoggedTime: Scalars['Boolean'];
  hasNonDraftLineItem: Scalars['Boolean'];
  /** planned amount of hours in the phase */
  hours: Scalars['Float'];
  /** hours allocated between now and the end of the phase */
  hoursAllocated: Scalars['Float'];
  /** hours logged in the phase thus far */
  hoursLogged: Scalars['Float'];
  id: Scalars['ID'];
  isOverstaffed: Scalars['Boolean'];
  name: Scalars['String'];
  /** percentage of the phase's overall budget */
  percent: Scalars['Float'];
  phase: Phase;
  phaseRate?: Maybe<Scalars['Float']>;
  phaseRates: Array<Rate>;
  position: Scalars['Int'];
  profile?: Maybe<Profile>;
  project?: Maybe<Project>;
  /** cost of one hour */
  rate: Scalars['Float'];
  rateTable: RateTable;
  ratesDuring: Array<Rate>;
  roleTemplate: RoleTemplate;
  staffingEntryGroups: Array<EntryGroup>;
};


/** Role is the fact that some profile was assigned to work on some project phase. Role has name, houly rate (for billing) and planned budget. */
export type RoleRatesDuringArgs = {
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
};

export type RoleTemplate = {
  __typename?: 'RoleTemplate';
  activeRateTables: Array<RateTable>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  assignedProfiles?: Maybe<Array<Profile>>;
  id: Scalars['ID'];
  isAssigned?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  profiles?: Maybe<Array<Profile>>;
  rateTables: Array<RateTable>;
};

/**
 * Filters to search for projects. We can expect this to expand over time if
 * search becomes more of a first-class citizen.
 */
export type SearchableProjectsParametersInput = {
  searchString?: Maybe<Scalars['String']>;
  minProfilesCount?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<ProjectStatus>>;
  sortBy?: Maybe<SearchableProjectsSortInput>;
  archived?: Maybe<Scalars['Boolean']>;
  activeDateRange?: Maybe<DateRangeInput>;
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type SearchableProjectsSortInput = {
  field: ProjectsSortableFieldsInput;
  direction: SortDirectionInput;
};

export type SearchableTimesheetsParametersInput = {
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  isLate?: Maybe<Scalars['Boolean']>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
  projectIds?: Maybe<Array<Scalars['ID']>>;
  limit?: Maybe<Scalars['Int']>;
  profileStatuses?: Maybe<Array<ProfileStatus>>;
};

/** Autogenerated input type of SendPreviewDigest */
export type SendPreviewDigestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  previewProfileId: Scalars['ID'];
};

/** Autogenerated return type of SendPreviewDigest. */
export type SendPreviewDigestPayload = {
  __typename?: 'SendPreviewDigestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of SendProfileInvitation */
export type SendProfileInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendProfileInvitation. */
export type SendProfileInvitationPayload = {
  __typename?: 'SendProfileInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of SetPrimaryCompanyContact */
export type SetPrimaryCompanyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SetPrimaryCompanyContact. */
export type SetPrimaryCompanyContactPayload = {
  __typename?: 'SetPrimaryCompanyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** All available sort directions */
export enum SortDirectionInput {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Available options for sq unit cost type */
export enum SqUnitCost {
  Gross = 'gross',
  Net = 'net'
}

export type Staffing = {
  __typename?: 'Staffing';
  id: Scalars['ID'];
  /** Returns staffing roles, overheads, and PTO for a profile */
  profile: StaffingProfile;
  /** Given dates, returns relevant top-level staffing profile information for the org */
  profiles: Array<Profile>;
  /** Given dates, returns relevant top-level staffing profile information for the org */
  totals: Array<StaffingTotals>;
};


export type StaffingProfileArgs = {
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
};


export type StaffingProfilesArgs = {
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  profileIds?: Maybe<Array<Scalars['ID']>>;
};


export type StaffingTotalsArgs = {
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

export type StaffingCell = {
  __typename?: 'StaffingCell';
  timesheetBeginDate: Scalars['ISO8601Date'];
  value: Scalars['Float'];
};

export type StaffingProfile = {
  __typename?: 'StaffingProfile';
  overheads: Array<Overhead>;
  roles: Array<Role>;
  timeOff: Array<StaffingCell>;
};

export type StaffingTotals = {
  __typename?: 'StaffingTotals';
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  totalAssignedHours: Scalars['Float'];
  totalAvailableHours: Scalars['Float'];
};

export type StandardHoliday = {
  __typename?: 'StandardHoliday';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  lookupName: Scalars['String'];
};

export type StartExportMutations = {
  __typename?: 'StartExportMutations';
  /** Initiates export of consultant bills. Returns an ID of export to be passed to the `checkExport` field */
  consultantBills?: Maybe<ConsultantBillsPayload>;
  /**
   * Initiates export of entries. Returns an ID of export to be passed to the `checkExport` field.
   * Note that if the current_user has access, they will see _all_ entries for all people on the
   * project. Requires edit_all permissions or edit_assigned permissions for the project. For global TimeLog,
   * requires only edit_all permission.
   */
  entries?: Maybe<EntriesPayload>;
  /** Initiates export of invoices. Returns an ID of export to be passed to the `checkExport` field */
  expenses?: Maybe<ExpensesPayload>;
  /**
   * Initiates export of grouped entries. Returns an ID of export to be passed to the `checkExport` field.
   *   Note that if the current_user has access, they will see _all_ entries for all people on the
   *   project. Requires edit_all permissions or edit_assigned permissions for the project.
   */
  groupedEntries?: Maybe<GroupedEntriesPayload>;
  /** Initiates invoice export for QBO. Returns an ID of export to be passed to the `checkExport` field */
  invoiceQbo?: Maybe<QboPayload>;
  /** Initiates invoice export for Xero. Returns an ID of export to be passed to the `checkExport` field */
  invoiceXero?: Maybe<XeroPayload>;
  /** Initiates export of invoices. Returns an ID of export to be passed to the `checkExport` field */
  invoices?: Maybe<InvoicesPayload>;
  /** Initiates export of organization timesheets. Sends an email with the report. */
  organizationTimesheets?: Maybe<OrganizationTimesheetsExportPayload>;
  /** Initiates export of organization timesheets into an Ajera-friendly CSV. */
  organizationTimesheetsAjera?: Maybe<OrganizationTimesheetsExportAjeraPayload>;
  /** Initiates export of payments -> payments. Sends an email with the report. */
  payments?: Maybe<PaymentsPaymentsPayload>;
  /** Initiates export of payments -> invoices. Sends an email with the report. */
  paymentsInvoices?: Maybe<PaymentsInvoicesPayload>;
  /** Initiates export of payments -> receivables. Sends an email with the report. */
  paymentsReceivables?: Maybe<PaymentsReceivablesPayload>;
  /** Initiates export of payments -> payouts. Sends an email with the report. */
  payouts?: Maybe<PaymentsPayoutsPayload>;
  /** Initiates export of profile timesheets. */
  profileTimesheets?: Maybe<ProfileTimesheetsExportPayload>;
  /** Initiates export of profit drivers report. */
  profitDrivers?: Maybe<ProfitDriversExportPayload>;
  /** Initiates export of profit forecast report. */
  profitForecast?: Maybe<ProfitForecastExportPayload>;
  /** Initiates export of profit report. */
  profitNonQboVersion?: Maybe<ProfitNonQboVersionExportPayload>;
  /** Initiates export of profit report. */
  profitQboVersion?: Maybe<ProfitQboVersionExportPayload>;
  /** Initiates export of project financials. Returns an ID of export to be passed to the `checkExport` field */
  projectFinancials?: Maybe<ProjectFinancialsPayload>;
  /** Initiates export of project profit report. */
  projectProfit?: Maybe<ProjectProfitExportPayload>;
  /** Initiates export of projects timings. Returns an ID of export to be passed to the `checkExport` field */
  projectsTimings?: Maybe<ProjectsTimingsPayload>;
  /** Initiates export of realization report. Returns an ID of export to be passed to the `checkExport` field */
  realization?: Maybe<RealizationPayload>;
  /** Initiates export of taxes collected. Sends an email with the report. */
  taxesCollected?: Maybe<PaymentsTaxesCollectedPayload>;
  /** Initiates export of team_forecast report. Returns an ID of export to be passed to the `checkExport` field */
  teamForecast?: Maybe<TeamForecastPayload>;
  /** Initiates export of a timesheet. */
  timesheet?: Maybe<TimesheetExportPayload>;
  /** Initiates export of all payment transactions. Sends the report as an email. */
  transactions?: Maybe<PaymentsTransactionsPayload>;
  /** Initiates export of utilization report. Returns an ID of export to be passed to the `checkExport` field */
  utilization?: Maybe<UtilizationPayload>;
};


export type StartExportMutationsConsultantBillsArgs = {
  input: ConsultantBillsInput;
};


export type StartExportMutationsEntriesArgs = {
  input: EntriesInput;
};


export type StartExportMutationsExpensesArgs = {
  input: ExpensesInput;
};


export type StartExportMutationsGroupedEntriesArgs = {
  input: GroupedEntriesInput;
};


export type StartExportMutationsInvoiceQboArgs = {
  input: QboInput;
};


export type StartExportMutationsInvoiceXeroArgs = {
  input: XeroInput;
};


export type StartExportMutationsInvoicesArgs = {
  input: InvoicesInput;
};


export type StartExportMutationsOrganizationTimesheetsArgs = {
  input: OrganizationTimesheetsExportInput;
};


export type StartExportMutationsOrganizationTimesheetsAjeraArgs = {
  input: OrganizationTimesheetsExportAjeraInput;
};


export type StartExportMutationsPaymentsArgs = {
  input: PaymentsPaymentsInput;
};


export type StartExportMutationsPaymentsInvoicesArgs = {
  input: PaymentsInvoicesInput;
};


export type StartExportMutationsPaymentsReceivablesArgs = {
  input: PaymentsReceivablesInput;
};


export type StartExportMutationsPayoutsArgs = {
  input: PaymentsPayoutsInput;
};


export type StartExportMutationsProfileTimesheetsArgs = {
  input: ProfileTimesheetsExportInput;
};


export type StartExportMutationsProfitDriversArgs = {
  input: ProfitDriversExportInput;
};


export type StartExportMutationsProfitForecastArgs = {
  input: ProfitForecastExportInput;
};


export type StartExportMutationsProfitNonQboVersionArgs = {
  input: ProfitNonQboVersionExportInput;
};


export type StartExportMutationsProfitQboVersionArgs = {
  input: ProfitQboVersionExportInput;
};


export type StartExportMutationsProjectFinancialsArgs = {
  input: ProjectFinancialsInput;
};


export type StartExportMutationsProjectProfitArgs = {
  input: ProjectProfitExportInput;
};


export type StartExportMutationsProjectsTimingsArgs = {
  input: ProjectsTimingsInput;
};


export type StartExportMutationsRealizationArgs = {
  input: RealizationInput;
};


export type StartExportMutationsTaxesCollectedArgs = {
  input: PaymentsTaxesCollectedInput;
};


export type StartExportMutationsTeamForecastArgs = {
  input: TeamForecastInput;
};


export type StartExportMutationsTimesheetArgs = {
  input: TimesheetExportInput;
};


export type StartExportMutationsTransactionsArgs = {
  input: PaymentsTransactionsInput;
};


export type StartExportMutationsUtilizationArgs = {
  input: UtilizationInput;
};

export type Steps = {
  __typename?: 'Steps';
  blockingSteps?: Maybe<Scalars['Int']>;
  remainingSteps?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of StopCompensation */
export type StopCompensationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  effectiveOn: Scalars['ISO8601Date'];
  profileId: Scalars['ID'];
};

/** Autogenerated return type of StopCompensation. */
export type StopCompensationPayload = {
  __typename?: 'StopCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  compensation?: Maybe<Compensation>;
  errors: Array<Scalars['String']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  active: Scalars['Boolean'];
  chargesEnabled: Scalars['Boolean'];
  currencyCode: StripeCurrencyCode;
  detailsSubmitted: Scalars['Boolean'];
  id: Scalars['ID'];
  organization: Organization;
  payoutsEnabled: Scalars['Boolean'];
  setupIncomplete: Scalars['Boolean'];
  status?: Maybe<StripeAccountStatus>;
  stripeId: Scalars['String'];
};

/** Autogenerated input type of StripeAccountInitiateOnboarding */
export type StripeAccountInitiateOnboardingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Url to send the user back to after stripe connect is done */
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StripeAccountInitiateOnboarding. */
export type StripeAccountInitiateOnboardingPayload = {
  __typename?: 'StripeAccountInitiateOnboardingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};

/** Autogenerated return type of StripeAccountOnboardingUrl. */
export type StripeAccountOnboardingUrlPayload = {
  __typename?: 'StripeAccountOnboardingUrlPayload';
  onboardingUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StripeAccount. */
export type StripeAccountPayload = {
  __typename?: 'StripeAccountPayload';
  stripeAccount?: Maybe<StripeAccount>;
};

/** Autogenerated input type of StripeAccountResync */
export type StripeAccountResyncInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StripeAccountResync. */
export type StripeAccountResyncPayload = {
  __typename?: 'StripeAccountResyncPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};

/** Valid statuses for stripe account */
export enum StripeAccountStatus {
  Active = 'active',
  Restricted = 'restricted',
  RestrictedSoon = 'restricted_soon'
}

/** All available currency codes for a Stripe Account */
export enum StripeCurrencyCode {
  Usd = 'usd',
  Cad = 'cad'
}

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Float'];
  clientSecret: Scalars['String'];
  currency: Scalars['String'];
  hasCustomer: Scalars['Boolean'];
  id: Scalars['String'];
  setupFutureUsage: Scalars['Boolean'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  batchExpensesToQboJob: BatchExpensesToQboJobPayload;
  batchInvoicesToQboJob: BatchInvoicesToQboJobPayload;
  batchJobStatus: BatchJobStatusPayload;
  example: ExamplePayload;
  payrollHourlyWorker: PayrollHourlyWorkerPayload;
  payrollReimbursableExpenses: PayrollReimbursableExpensesPayload;
  stripeAccount: StripeAccountPayload;
  stripeAccountOnboardingUrl: StripeAccountOnboardingUrlPayload;
};


export type SubscriptionBatchJobStatusArgs = {
  jobBatchBid: Scalars['String'];
};


export type SubscriptionPayrollHourlyWorkerArgs = {
  periodStart?: Maybe<Scalars['ISO8601Date']>;
  periodEnd?: Maybe<Scalars['ISO8601Date']>;
};


export type SubscriptionPayrollReimbursableExpensesArgs = {
  periodStart?: Maybe<Scalars['ISO8601Date']>;
  periodEnd?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated input type of SyncClientToQbo */
export type SyncClientToQboInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  qboInstanceId: Scalars['ID'];
};

/** Autogenerated return type of SyncClientToQbo. */
export type SyncClientToQboPayload = {
  __typename?: 'SyncClientToQboPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SyncConsultantBillWithQuickbooks */
export type SyncConsultantBillWithQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SyncConsultantBillWithQuickbooks. */
export type SyncConsultantBillWithQuickbooksPayload = {
  __typename?: 'SyncConsultantBillWithQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill?: Maybe<ConsultantBill>;
};

/** Autogenerated input type of SyncConsultantCompanyWithQuickbooks */
export type SyncConsultantCompanyWithQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SyncConsultantCompanyWithQuickbooks. */
export type SyncConsultantCompanyWithQuickbooksPayload = {
  __typename?: 'SyncConsultantCompanyWithQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of SyncImportCustomersFromQBO */
export type SyncImportCustomersFromQboInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  importMap: Array<CustomerImportMapping>;
  resolutionStrategy?: Maybe<CustomerImportResolution>;
};

/** Autogenerated return type of SyncImportCustomersFromQBO. */
export type SyncImportCustomersFromQboPayload = {
  __typename?: 'SyncImportCustomersFromQBOPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
};

/** Autogenerated input type of SyncStripeAttributes */
export type SyncStripeAttributesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated return type of SyncStripeAttributes. */
export type SyncStripeAttributesPayload = {
  __typename?: 'SyncStripeAttributesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of SyncTermsWithQuickbooks */
export type SyncTermsWithQuickbooksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of SyncTermsWithQuickbooks. */
export type SyncTermsWithQuickbooksPayload = {
  __typename?: 'SyncTermsWithQuickbooksPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type Task = {
  __typename?: 'Task';
  assignees?: Maybe<Array<Profile>>;
  comments?: Maybe<Array<Comment>>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  milestone?: Maybe<InvoiceBuilderMilestone>;
  name?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
  project?: Maybe<Project>;
  regularMilestone?: Maybe<Milestone>;
  status?: Maybe<Scalars['String']>;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaskEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Task>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** All due date groups available as filters for tasks (and milestones) */
export enum TaskDueDate {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  ThisWeek = 'THIS_WEEK',
  NextWeek = 'NEXT_WEEK',
  Later = 'LATER',
  Overdue = 'OVERDUE'
}

/** An edge in a connection. */
export type TaskEdge = {
  __typename?: 'TaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Task>;
};

/** Filters for tasks */
export type TaskFiltersInput = {
  projectId?: Maybe<Scalars['ID']>;
  projectSlug?: Maybe<Scalars['String']>;
  phaseTemplateId?: Maybe<Scalars['ID']>;
  profileId?: Maybe<Scalars['ID']>;
  dueDates?: Maybe<Array<TaskDueDate>>;
  excludeCompletedTasks?: Maybe<Scalars['Boolean']>;
  excludeInactiveProjects?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TaskStatus>;
};

/** Autogenerated input type of TaskMarkComplete */
export type TaskMarkCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of TaskMarkComplete. */
export type TaskMarkCompletePayload = {
  __typename?: 'TaskMarkCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
};

/** All status types */
export enum TaskStatus {
  Active = 'active',
  Completed = 'completed'
}

/** Autogenerated input type of TaskToggleComplete */
export type TaskToggleCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of TaskToggleComplete. */
export type TaskToggleCompletePayload = {
  __typename?: 'TaskToggleCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
};

export type Tax = {
  __typename?: 'Tax';
  amount: Scalars['String'];
  description: Scalars['String'];
  payer: Scalars['String'];
  tax: Scalars['String'];
};

export type TaxRate = {
  __typename?: 'TaxRate';
  draftInvoices?: Maybe<Array<Invoice>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Float'];
};

export type TaxRateTotals = {
  __typename?: 'TaxRateTotals';
  name: Scalars['String'];
  percent: Scalars['Float'];
  total: Scalars['Float'];
};

/** Filters for tax rates in invoice and payments */
export type TaxRatesFiltersInput = {
  paidDateRange?: Maybe<DateRangeInput>;
};

/** All available sorts for taxes collected */
export enum TaxesFieldInput {
  TaxTotal = 'TAX_TOTAL',
  TaxRate = 'TAX_RATE'
}

export type TaxesSortInput = {
  field: TaxesFieldInput;
  direction: SortDirectionInput;
};

/** Filters for team activity report */
export type TeamActivityFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
};

export type TeamActivityProfileTotals = {
  __typename?: 'TeamActivityProfileTotals';
  billableHours: Scalars['Float'];
  nonBillableHours: Scalars['Float'];
  profile: Profile;
};

export type TeamActivityReport = {
  __typename?: 'TeamActivityReport';
  totals: Array<TeamActivityProfileTotals>;
};

/** Allocation for a profile in a particular week. Only one of role_id, overhead_id, time_off_request_id, or time_off_holiday_id will be present. */
export type TeamForecastAllocationGroup = {
  __typename?: 'TeamForecastAllocationGroup';
  /** Overhead ID, if the allocation is for an overhead */
  overheadId?: Maybe<Scalars['ID']>;
  profileId: Scalars['ID'];
  /** Role ID, if the allocation is for a role */
  roleId?: Maybe<Scalars['ID']>;
  /** Time off holiday ID, if the allocation is for a time off holiday */
  timeOffHolidayId?: Maybe<Scalars['ID']>;
  /** Time off request ID, if the allocation is for a time off request */
  timeOffRequestId?: Maybe<Scalars['ID']>;
  timesheetBeginDate: Scalars['ISO8601Date'];
  totalAllocation: Scalars['Float'];
};

/** Filters for team forecast report */
export type TeamForecastFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
};

/** Autogenerated input type of TeamForecast */
export type TeamForecastInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions entries should satisfy */
  filters?: TeamForecastFiltersInput;
};

/** Autogenerated return type of TeamForecast. */
export type TeamForecastPayload = {
  __typename?: 'TeamForecastPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type TeamForecastReport = {
  __typename?: 'TeamForecastReport';
  allocationGroups: Array<TeamForecastAllocationGroup>;
  overheads: Array<Overhead>;
  profiles: Array<Profile>;
  roles: Array<Role>;
  timeOffHolidays: Array<TimeOffHoliday>;
  timeOffRequests: Array<TimeOffRequest>;
};

export type TeamList = {
  __typename?: 'TeamList';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rolesSentence?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Term = {
  __typename?: 'Term';
  active: Scalars['Boolean'];
  days: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  qboTerm?: Maybe<QboTerm>;
  syncedToQbo?: Maybe<Scalars['Boolean']>;
};

export type TimeLog = {
  __typename?: 'TimeLog';
  availableFilters: AvailableFilters;
  chart: Array<TimeLogChartEntry>;
  dateIncrement: DateIncrement;
  filters: TimeLogFilters;
  partialEnd: Scalars['Boolean'];
  partialStart: Scalars['Boolean'];
  project?: Maybe<Project>;
  totals: TimeLogTotals;
};

export type TimeLogChartEntry = {
  __typename?: 'TimeLogChartEntry';
  data: Array<TimeLogChartEntryData>;
  xAxis: Scalars['ISO8601Date'];
};

export type TimeLogChartEntryData = {
  __typename?: 'TimeLogChartEntryData';
  billable: Scalars['Boolean'];
  fee: Scalars['Float'];
  hours: Scalars['Float'];
};

export type TimeLogFilters = {
  __typename?: 'TimeLogFilters';
  activityIds: Array<Scalars['ID']>;
  dateRange: DateRange;
  noActivity?: Maybe<Scalars['Boolean']>;
  noPhaseTemplate?: Maybe<Scalars['Boolean']>;
  phaseIds: Array<Scalars['ID']>;
  phaseTemplateIds: Array<Scalars['ID']>;
  profileIds: Array<Scalars['ID']>;
  projectIds: Array<Scalars['ID']>;
  roleTemplateIds: Array<Scalars['ID']>;
};

/** Filters for project time log report */
export type TimeLogFiltersInput = {
  dateRange: DateRangeInput;
  profileIds?: Maybe<Array<Scalars['ID']>>;
  /**
   * list of project ids to filter entries if intent is global timelog,
   *   for project time log, pass only 1 id
   */
  projectIds?: Maybe<Array<Scalars['ID']>>;
  /** list of activity IDs, use nil to get entries without activity */
  activityIds?: Maybe<Array<Scalars['ID']>>;
  /** list of phase IDs, use nil to get entries without phases */
  phaseIds?: Maybe<Array<Scalars['ID']>>;
  /** list of phase template IDs, use nil to get entries without phase template */
  phaseTemplateIds?: Maybe<Array<Scalars['ID']>>;
  /** list of role IDs, use nil to get entries without roles */
  roleTemplateIds?: Maybe<Array<Scalars['ID']>>;
  /** boolean to indicate wether to include entries with no activities attached */
  noActivity?: Maybe<Scalars['Boolean']>;
  /** boolean to indicate wether to include entries with no role attached */
  noRole?: Maybe<Scalars['Boolean']>;
  /**
   * boolean to indicate wether to include entries with no phase templates attached,
   *            ussually those are custom phases
   */
  noPhaseTemplate?: Maybe<Scalars['Boolean']>;
  /** boolean to indicate wether to exclude overhead entries or not */
  excludeOverhead?: Maybe<Scalars['Boolean']>;
  /** filter by activity type: billable, non-billable or all */
  activityType?: Maybe<Billable>;
  /** list of project category IDs */
  categoryIds?: Maybe<Array<Scalars['ID']>>;
};

/** All available time log intent types */
export enum TimeLogIntent {
  Global = 'GLOBAL',
  Project = 'PROJECT'
}

export type TimeLogTotals = {
  __typename?: 'TimeLogTotals';
  billableFee: Scalars['Float'];
  billableHours: Scalars['Float'];
  billablePercent: Scalars['Float'];
  nonBillableHours: Scalars['Float'];
  totalHours: Scalars['Float'];
};

export type TimeOffAccrual = {
  __typename?: 'TimeOffAccrual';
  accrualType: TimeOffAccrualType;
  adminProfile?: Maybe<Profile>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  timeOffPolicyAssignment: TimeOffPolicyAssignment;
};

/** Valid accrual methods for time off policies */
export enum TimeOffAccrualMethod {
  /** Fixed */
  Fixed = 'fixed',
  /** Unlimited */
  Unlimited = 'unlimited'
}

/** Valid accrual types for time off accruals */
export enum TimeOffAccrualType {
  /** Interval */
  Interval = 'interval',
  /** Admin */
  Admin = 'admin',
  /** Rollover */
  Rollover = 'rollover'
}

/** Valid earned rates for time off policies */
export enum TimeOffEarnedRate {
  /** Annual */
  Annual = 'annual',
  /** Pay Schedule */
  PaySchedule = 'pay_schedule'
}

export type TimeOffHoliday = {
  __typename?: 'TimeOffHoliday';
  appliesToNextPayday: Scalars['Boolean'];
  canBeDeleted: Scalars['Boolean'];
  date: Scalars['ISO8601Date'];
  hours: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  standardHoliday?: Maybe<StandardHoliday>;
  timeOffHolidayPolicy: TimeOffHolidayPolicy;
};

export type TimeOffHolidayPolicy = {
  __typename?: 'TimeOffHolidayPolicy';
  accrualMethod: TimeOffAccrualMethod;
  annualRolloverLimit?: Maybe<Scalars['Float']>;
  autoApprove: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  earnedRate?: Maybe<TimeOffEarnedRate>;
  eligibleProfiles: Array<Profile>;
  hoursPerYear?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  ineligibleProfiles: Array<Profile>;
  lastAccruedAt?: Maybe<Scalars['ISO8601DateTime']>;
  maxBalance?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  overhead?: Maybe<Overhead>;
  profileOptions: Array<ProfileGroup>;
  reviewRecipients: Array<Profile>;
  slug: Scalars['String'];
  timeOffHolidays?: Maybe<Array<TimeOffHoliday>>;
  timeOffHolidaysByYear?: Maybe<Array<TimeOffHolidaysByYear>>;
  timeOffPolicyAssignments: Array<TimeOffPolicyAssignment>;
  timeOffType: TimeOffType;
};

export type TimeOffHolidaysByYear = {
  __typename?: 'TimeOffHolidaysByYear';
  holidays: Array<TimeOffHoliday>;
  year: Scalars['Int'];
};

export type TimeOffPolicy = {
  __typename?: 'TimeOffPolicy';
  accrualMethod: TimeOffAccrualMethod;
  annualRolloverLimit?: Maybe<Scalars['Float']>;
  autoApprove: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  earnedRate?: Maybe<TimeOffEarnedRate>;
  eligibleProfiles: Array<Profile>;
  hoursPerYear?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  ineligibleProfiles: Array<Profile>;
  lastAccruedAt?: Maybe<Scalars['ISO8601DateTime']>;
  maxBalance?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  overhead?: Maybe<Overhead>;
  profileOptions: Array<ProfileGroup>;
  reviewRecipients: Array<Profile>;
  slug: Scalars['String'];
  timeOffPolicyAssignments: Array<TimeOffPolicyAssignment>;
  timeOffType: TimeOffType;
};

export type TimeOffPolicyAssignment = {
  __typename?: 'TimeOffPolicyAssignment';
  balance: Scalars['Float'];
  events?: Maybe<TimeOffPolicyAssignmentEventConnection>;
  id: Scalars['ID'];
  profile: Profile;
  timeOffAccruals: Array<TimeOffAccrual>;
  timeOffPolicy: TimeOffPolicy;
  timeOffRequests: Array<TimeOffRequest>;
  totalHoursAccrued: Scalars['Float'];
  totals: TimeOffPolicyAssignmentTotals;
};


export type TimeOffPolicyAssignmentEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirectionInput>;
};

export type TimeOffPolicyAssignmentEvent = {
  __typename?: 'TimeOffPolicyAssignmentEvent';
  activity: Scalars['String'];
  balance: Scalars['Float'];
  beginning: Scalars['ISO8601Date'];
  createdAt: Scalars['ISO8601DateTime'];
  displayHours: Scalars['Float'];
  ending: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

/** The connection type for TimeOffPolicyAssignmentEvent. */
export type TimeOffPolicyAssignmentEventConnection = {
  __typename?: 'TimeOffPolicyAssignmentEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TimeOffPolicyAssignmentEventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TimeOffPolicyAssignmentEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TimeOffPolicyAssignmentEventEdge = {
  __typename?: 'TimeOffPolicyAssignmentEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TimeOffPolicyAssignmentEvent>;
};

export type TimeOffPolicyAssignmentInput = {
  profileId: Scalars['ID'];
  startingBalance: Scalars['Float'];
  accrualStartDate: Scalars['ISO8601Date'];
};

export type TimeOffPolicyAssignmentTotals = {
  __typename?: 'TimeOffPolicyAssignmentTotals';
  hoursAccruedYtd: Scalars['Float'];
  hoursAvailable: Scalars['Float'];
  hoursPlanned: Scalars['Float'];
  hoursTaken: Scalars['Float'];
};

export type TimeOffRequest = {
  __typename?: 'TimeOffRequest';
  adminProfile?: Maybe<Profile>;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']>;
  deniedAt?: Maybe<Scalars['ISO8601DateTime']>;
  hours: Scalars['Float'];
  id: Scalars['ID'];
  needsDeletionWarning: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  openedAt: Scalars['ISO8601DateTime'];
  processedDate?: Maybe<Scalars['ISO8601DateTime']>;
  requestsBeginning?: Maybe<Scalars['String']>;
  requestsEnd?: Maybe<Scalars['String']>;
  reviewerNote?: Maybe<Scalars['String']>;
  status: TimeOffRequestStatus;
  timeOffAccruals?: Maybe<Array<TimeOffAccrual>>;
  timeOffPolicy: TimeOffPolicy;
  timeOffPolicyAssignment: TimeOffPolicyAssignment;
  timeOffRequestDays: Array<TimeOffRequestDay>;
};

/** The connection type for TimeOffRequest. */
export type TimeOffRequestConnection = {
  __typename?: 'TimeOffRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TimeOffRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TimeOffRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TimeOffRequestDay = {
  __typename?: 'TimeOffRequestDay';
  date: Scalars['ISO8601Date'];
  hours: Scalars['Float'];
  id: Scalars['ID'];
  timeOffRequest: TimeOffRequest;
};

/** An edge in a connection. */
export type TimeOffRequestEdge = {
  __typename?: 'TimeOffRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TimeOffRequest>;
};

/** Valid status types for time off requests */
export enum TimeOffRequestStatus {
  /** Approved */
  Approved = 'approved',
  /** Denied */
  Denied = 'denied',
  /** Canceled */
  Canceled = 'canceled',
  /** Pending */
  Pending = 'pending',
  /** Taken */
  Taken = 'taken'
}

/** Sort fields for time off requests */
export enum TimeOffRequestsFieldInput {
  Status = 'STATUS'
}

/** Filters for time off requests */
export type TimeOffRequestsFiltersInput = {
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  dateRange?: Maybe<DateRangeInput>;
  timeOffPolicyId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<TimeOffRequestStatus>>;
};

export type TimeOffRequestsSortInput = {
  field: TimeOffRequestsFieldInput;
  direction: SortDirectionInput;
};

/** Valid time off types for time off policies */
export enum TimeOffType {
  /** Bereavement */
  Bereavement = 'bereavement',
  /** Floating Holiday */
  FloatingHoliday = 'floating_holiday',
  /** Holiday */
  Holiday = 'holiday',
  /** Jury Duty */
  JuryDuty = 'jury_duty',
  /** Learning & Development */
  LearningAndDevelopment = 'learning_and_development',
  /** Paid Time Off */
  PaidTimeOff = 'paid_time_off',
  /** Parental Leave */
  ParentalLeave = 'parental_leave',
  /** Sick Leave */
  SickLeave = 'sick_leave',
  /** Unpaid */
  Unpaid = 'unpaid',
  /** Volunteer */
  Volunteer = 'volunteer',
  /** Other */
  Other = 'other'
}

export type TimeZone = {
  __typename?: 'TimeZone';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Timer = {
  __typename?: 'Timer';
  /** Returns a set of entries relevant to the current timer. */
  entries?: Maybe<Array<Entry>>;
  /** Returns a set of projects relevant to a given timer project. */
  projectPhases: Array<Phase>;
  /** Returns a set of projects relevant to the current timer. */
  projects: Array<Project>;
};


export type TimerProjectPhasesArgs = {
  projectId?: Maybe<Scalars['ID']>;
};

export type Timesheet = {
  __typename?: 'Timesheet';
  beginDate: Scalars['ISO8601Date'];
  createdAt: Scalars['ISO8601DateTime'];
  dates: Array<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  entries?: Maybe<Array<Entry>>;
  entryGroups?: Maybe<Array<EntryGroup>>;
  entryRows?: Maybe<Array<EntryRow>>;
  expenses?: Maybe<Array<Expense>>;
  hoursAllocated: Scalars['Float'];
  hoursAllotted: Scalars['Float'];
  id: Scalars['ID'];
  isLate: Scalars['Boolean'];
  isLockable: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  isSubmitted: Scalars['Boolean'];
  isUnlocked: Scalars['Boolean'];
  organization?: Maybe<Organization>;
  overheadHours: Scalars['Float'];
  overheadHoursAllocated: Scalars['Float'];
  overheads?: Maybe<Array<Overhead>>;
  populated: Scalars['Boolean'];
  profile?: Maybe<Profile>;
  projects?: Maybe<Array<Project>>;
  slug?: Maybe<Scalars['String']>;
  timeOffHolidayDays?: Maybe<Array<TimeOffHoliday>>;
  timeOffHolidayHours: Scalars['Float'];
  timeOffRequestHours: Scalars['Float'];
  totalHours: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};


export type TimesheetEntriesArgs = {
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
};


export type TimesheetEntryGroupsArgs = {
  visible?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of TimesheetExport */
export type TimesheetExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of TimesheetExport. */
export type TimesheetExportPayload = {
  __typename?: 'TimesheetExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type TimesheetLock = {
  __typename?: 'TimesheetLock';
  days?: Maybe<Scalars['Int']>;
  isLocked: Scalars['Boolean'];
  time?: Maybe<Scalars['Float']>;
};

export type TimesheetReminder = {
  __typename?: 'TimesheetReminder';
  day?: Maybe<Scalars['Int']>;
  isOn: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of ToggleOrganizationMutation */
export type ToggleOrganizationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

/** Autogenerated return type of ToggleOrganizationMutation. */
export type ToggleOrganizationMutationPayload = {
  __typename?: 'ToggleOrganizationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Profile>;
};

/** Autogenerated input type of ToggleProfileMutation */
export type ToggleProfileMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of ToggleProfileMutation. */
export type ToggleProfileMutationPayload = {
  __typename?: 'ToggleProfileMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type TotalWithCountType = {
  __typename?: 'TotalWithCountType';
  amount: Scalars['Float'];
  consultantServicesAmount: Scalars['Float'];
  count: Scalars['Float'];
  expensesAmount: Scalars['Float'];
  firmServicesAmount: Scalars['Float'];
  otherAmount: Scalars['Float'];
};

export type Totals = {
  __typename?: 'Totals';
  cashRequirement: Scalars['String'];
  companyBenefits: Scalars['String'];
  companyTaxes: Scalars['String'];
  contractorGross: Scalars['String'];
  contractorNet: Scalars['String'];
  contractorReimbursements: Scalars['String'];
  employeeBenefits: Scalars['String'];
  employeeGross: Scalars['String'];
  employeeNet: Scalars['String'];
  employeeReimbursements: Scalars['String'];
  employeeTaxes: Scalars['String'];
  liability: Scalars['String'];
  postTaxDeductions: Scalars['String'];
  reimbursables?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of TriggerSyncTaxRates */
export type TriggerSyncTaxRatesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TriggerSyncTaxRates. */
export type TriggerSyncTaxRatesPayload = {
  __typename?: 'TriggerSyncTaxRatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  taxRate?: Maybe<TaxRate>;
};

/** Autogenerated input type of UnarchiveProject */
export type UnarchiveProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveProject. */
export type UnarchiveProjectPayload = {
  __typename?: 'UnarchiveProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of UnarchiveRoleTemplate */
export type UnarchiveRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveRoleTemplate. */
export type UnarchiveRoleTemplatePayload = {
  __typename?: 'UnarchiveRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplate?: Maybe<RoleTemplate>;
};

/** All available project types for the unbilled reports filter */
export enum UnbilledProject {
  Active = 'ACTIVE',
  All = 'ALL',
  WithTotalUnbilled = 'WITH_TOTAL_UNBILLED',
  WithCurrentUnbilled = 'WITH_CURRENT_UNBILLED'
}

/** Available options for unit of measurement type */
export enum UnitOfMeasurement {
  Feet = 'feet',
  Meters = 'meters'
}

/** Autogenerated input type of UpdateActivity */
export type UpdateActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  isBillable: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateActivity. */
export type UpdateActivityPayload = {
  __typename?: 'UpdateActivityPayload';
  activity?: Maybe<Activity>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateActivityRequired */
export type UpdateActivityRequiredInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  isActivityRequired: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateActivityRequired. */
export type UpdateActivityRequiredPayload = {
  __typename?: 'UpdateActivityRequiredPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateAjeraIdentifier */
export type UpdateAjeraIdentifierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  recordId: Scalars['Int'];
  recordType: AjeraRecord;
  key: Scalars['Int'];
  typeKey?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateAjeraIdentifier. */
export type UpdateAjeraIdentifierPayload = {
  __typename?: 'UpdateAjeraIdentifierPayload';
  ajeraIdentifier: AjeraIdentifier;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCategory */
export type UpdateCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateCategory. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateClientAddressInput = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  parcel?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<UpdateClientAddressInput>;
  department?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  qboCustomerId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateClient. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateCompensation */
export type UpdateCompensationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  effectiveOn?: Maybe<Scalars['ISO8601Date']>;
  payrollType?: Maybe<PayrollTypeEnum>;
  rate?: Maybe<Scalars['Float']>;
  hoursPerWeek?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateCompensation. */
export type UpdateCompensationPayload = {
  __typename?: 'UpdateCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  compensation?: Maybe<Compensation>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of UpdateConsultantBill */
export type UpdateConsultantBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBillId: Scalars['ID'];
  pdfLink?: Maybe<Scalars['String']>;
  consultantCompanyId?: Maybe<Scalars['ID']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  issueDate?: Maybe<Scalars['ISO8601Date']>;
  notes?: Maybe<Scalars['String']>;
  billNumber?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  billAmount?: Maybe<Scalars['Float']>;
  quickbooksBillCategoryId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateConsultantBill. */
export type UpdateConsultantBillPayload = {
  __typename?: 'UpdateConsultantBillPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantBill: ConsultantBill;
};

export type UpdateConsultantCompanyAddressInput = {
  line1: Scalars['String'];
  line2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipcode: Scalars['String'];
  country: Scalars['String'];
};

/** Autogenerated input type of UpdateConsultantCompany */
export type UpdateConsultantCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  consultantTemplateIds: Array<Scalars['String']>;
  primaryAddress?: Maybe<UpdateConsultantCompanyAddressInput>;
  secondaryAddress?: Maybe<UpdateConsultantCompanyAddressInput>;
};

/** Autogenerated return type of UpdateConsultantCompany. */
export type UpdateConsultantCompanyPayload = {
  __typename?: 'UpdateConsultantCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantCompany?: Maybe<ConsultantCompany>;
};

/** Autogenerated input type of UpdateConsultantContact */
export type UpdateConsultantContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  fname: Scalars['String'];
  lname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  consultantCompanyId: Scalars['String'];
  consultantTemplateId: Scalars['String'];
};

/** Autogenerated return type of UpdateConsultantContact. */
export type UpdateConsultantContactPayload = {
  __typename?: 'UpdateConsultantContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantContact?: Maybe<ConsultantContact>;
};

/** Autogenerated input type of UpdateConsultantMarkupQuickbooksMapping */
export type UpdateConsultantMarkupQuickbooksMappingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** If true, maps the consultants quickbooks_item to the markup line item sent to QBO */
  applyConsultantMapping?: Maybe<Scalars['Boolean']>;
  quickbooksItemId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateConsultantMarkupQuickbooksMapping. */
export type UpdateConsultantMarkupQuickbooksMappingPayload = {
  __typename?: 'UpdateConsultantMarkupQuickbooksMappingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateConsultantTemplate */
export type UpdateConsultantTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateConsultantTemplate. */
export type UpdateConsultantTemplatePayload = {
  __typename?: 'UpdateConsultantTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantTemplate?: Maybe<ConsultantTemplate>;
};

/** Autogenerated input type of UpdateConsultantTemplateQuickbooksMapping */
export type UpdateConsultantTemplateQuickbooksMappingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  quickbooksItemId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateConsultantTemplateQuickbooksMapping. */
export type UpdateConsultantTemplateQuickbooksMappingPayload = {
  __typename?: 'UpdateConsultantTemplateQuickbooksMappingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consultantTemplate?: Maybe<ConsultantTemplate>;
};

/** Autogenerated input type of UpdateCosts */
export type UpdateCostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  costs: Array<CostUpdateMapping>;
};

/** Autogenerated return type of UpdateCosts. */
export type UpdateCostsPayload = {
  __typename?: 'UpdateCostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboJournalEntryCosts?: Maybe<Array<QboJournalEntryCost>>;
};

/** Autogenerated input type of UpdateDefaultUtilizationInfo */
export type UpdateDefaultUtilizationInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  utilizationRate: Scalars['Float'];
  defaultHoursPerWeek: Scalars['Float'];
  applyUtilizationRateToExistingProfiles: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateDefaultUtilizationInfo. */
export type UpdateDefaultUtilizationInfoPayload = {
  __typename?: 'UpdateDefaultUtilizationInfoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateEntryGroupAllocation */
export type UpdateEntryGroupAllocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  allocation: Scalars['Float'];
};

/** Autogenerated return type of UpdateEntryGroupAllocation. */
export type UpdateEntryGroupAllocationPayload = {
  __typename?: 'UpdateEntryGroupAllocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroup?: Maybe<EntryGroup>;
};

/** Autogenerated input type of UpdateEntryGroupPhase */
export type UpdateEntryGroupPhaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phaseId: Scalars['ID'];
  profileId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateEntryGroupPhase. */
export type UpdateEntryGroupPhasePayload = {
  __typename?: 'UpdateEntryGroupPhasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryGroup?: Maybe<EntryGroup>;
};

/** Autogenerated input type of UpdateEntry */
export type UpdateEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryId: Scalars['ID'];
  date?: Maybe<Scalars['ISO8601Date']>;
  phaseId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  activityId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateEntry. */
export type UpdateEntryPayload = {
  __typename?: 'UpdateEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
  entryRow?: Maybe<EntryRow>;
};

/** Autogenerated input type of UpdateEntryRowActivity */
export type UpdateEntryRowActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRowId: Scalars['ID'];
  activityId: Scalars['ID'];
};

/** Autogenerated return type of UpdateEntryRowActivity. */
export type UpdateEntryRowActivityPayload = {
  __typename?: 'UpdateEntryRowActivityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryRow?: Maybe<EntryRow>;
};

/** Autogenerated input type of UpdateExpenseCategoryForProject */
export type UpdateExpenseCategoryForProjectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  markupPercentage: Scalars['Float'];
  applyToAllForProject: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateExpenseCategoryForProject. */
export type UpdateExpenseCategoryForProjectPayload = {
  __typename?: 'UpdateExpenseCategoryForProjectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of UpdateExpenseCategory */
export type UpdateExpenseCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  markupPercentage: Scalars['Float'];
};

/** Autogenerated return type of UpdateExpenseCategory. */
export type UpdateExpenseCategoryPayload = {
  __typename?: 'UpdateExpenseCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of UpdateExpenseCategoryQuickbooksAccount */
export type UpdateExpenseCategoryQuickbooksAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  qboExpenseAccountId?: Maybe<Scalars['ID']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateExpenseCategoryQuickbooksAccount. */
export type UpdateExpenseCategoryQuickbooksAccountPayload = {
  __typename?: 'UpdateExpenseCategoryQuickbooksAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of UpdateExpenseCategoryQuickbooksService */
export type UpdateExpenseCategoryQuickbooksServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboItemId?: Maybe<Scalars['ID']>;
  mapToPhaseQbItem?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateExpenseCategoryQuickbooksService. */
export type UpdateExpenseCategoryQuickbooksServicePayload = {
  __typename?: 'UpdateExpenseCategoryQuickbooksServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseCategory?: Maybe<ExpenseCategory>;
};

/** Autogenerated input type of UpdateExpense */
export type UpdateExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  expenseCategoryId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  phaseId?: Maybe<Scalars['ID']>;
  overheadId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['ISO8601Date']>;
  amount?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  isBillable?: Maybe<Scalars['Boolean']>;
  isReimbursable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateExpense. */
export type UpdateExpensePayload = {
  __typename?: 'UpdateExpensePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expense?: Maybe<Expense>;
};

/** Autogenerated input type of UpdateExpenseRate */
export type UpdateExpenseRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rate: Scalars['Float'];
  expenseCategoryId: Scalars['ID'];
};

/** Autogenerated return type of UpdateExpenseRate. */
export type UpdateExpenseRatePayload = {
  __typename?: 'UpdateExpenseRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  expenseRate?: Maybe<ExpenseRate>;
};

/** Autogenerated input type of UpdateInsightsEmailPreference */
export type UpdateInsightsEmailPreferenceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  weeklyInsightsDigest?: Maybe<Scalars['Boolean']>;
  phaseRetroInsightsDigest?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateInsightsEmailPreference. */
export type UpdateInsightsEmailPreferencePayload = {
  __typename?: 'UpdateInsightsEmailPreferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateInvoiceFooter */
export type UpdateInvoiceFooterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceFooter: Scalars['String'];
};

/** Autogenerated return type of UpdateInvoiceFooter. */
export type UpdateInvoiceFooterPayload = {
  __typename?: 'UpdateInvoiceFooterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization: Organization;
};

/** Autogenerated input type of UpdateInvoicePaymentOptions */
export type UpdateInvoicePaymentOptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  allowCardPayments?: Maybe<Scalars['Boolean']>;
  allowBankTransfers?: Maybe<Scalars['Boolean']>;
  passConvenienceFeeCard?: Maybe<Scalars['Boolean']>;
  passConvenienceFeeAch?: Maybe<Scalars['Boolean']>;
  persistPaymentOptions?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateInvoicePaymentOptions. */
export type UpdateInvoicePaymentOptionsPayload = {
  __typename?: 'UpdateInvoicePaymentOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice: Invoice;
};

/** Autogenerated input type of UpdateNonReimbursableExpensesAccount */
export type UpdateNonReimbursableExpensesAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboAccountId: Scalars['ID'];
};

/** Autogenerated return type of UpdateNonReimbursableExpensesAccount. */
export type UpdateNonReimbursableExpensesAccountPayload = {
  __typename?: 'UpdateNonReimbursableExpensesAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOnlinePaymentFeeAccount */
export type UpdateOnlinePaymentFeeAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboAccountId: Scalars['ID'];
};

/** Autogenerated return type of UpdateOnlinePaymentFeeAccount. */
export type UpdateOnlinePaymentFeeAccountPayload = {
  __typename?: 'UpdateOnlinePaymentFeeAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationAddress */
export type UpdateOrganizationAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOrganizationAddress. */
export type UpdateOrganizationAddressPayload = {
  __typename?: 'UpdateOrganizationAddressPayload';
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateOrganizationConsultantMarkup */
export type UpdateOrganizationConsultantMarkupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  defaultConsultantMarkup: Scalars['Float'];
};

/** Autogenerated return type of UpdateOrganizationConsultantMarkup. */
export type UpdateOrganizationConsultantMarkupPayload = {
  __typename?: 'UpdateOrganizationConsultantMarkupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationCurrency */
export type UpdateOrganizationCurrencyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currencyId: Scalars['ID'];
};

/** Autogenerated return type of UpdateOrganizationCurrency. */
export type UpdateOrganizationCurrencyPayload = {
  __typename?: 'UpdateOrganizationCurrencyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationEmailReplyToAddress */
export type UpdateOrganizationEmailReplyToAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailReplyToAddress?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOrganizationEmailReplyToAddress. */
export type UpdateOrganizationEmailReplyToAddressPayload = {
  __typename?: 'UpdateOrganizationEmailReplyToAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationEmailSenderName */
export type UpdateOrganizationEmailSenderNameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailSenderName?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOrganizationEmailSenderName. */
export type UpdateOrganizationEmailSenderNamePayload = {
  __typename?: 'UpdateOrganizationEmailSenderNamePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationLocale */
export type UpdateOrganizationLocaleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  localeId: Scalars['ID'];
};

/** Autogenerated return type of UpdateOrganizationLocale. */
export type UpdateOrganizationLocalePayload = {
  __typename?: 'UpdateOrganizationLocalePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationLogo */
export type UpdateOrganizationLogoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateOrganizationLogo. */
export type UpdateOrganizationLogoPayload = {
  __typename?: 'UpdateOrganizationLogoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationName */
export type UpdateOrganizationNameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateOrganizationName. */
export type UpdateOrganizationNamePayload = {
  __typename?: 'UpdateOrganizationNamePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationQualifiedMutation */
export type UpdateOrganizationQualifiedMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Is qualified true or false */
  isQualified: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateOrganizationQualifiedMutation. */
export type UpdateOrganizationQualifiedMutationPayload = {
  __typename?: 'UpdateOrganizationQualifiedMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationRealization */
export type UpdateOrganizationRealizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  defaultRealizationRate?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateOrganizationRealization. */
export type UpdateOrganizationRealizationPayload = {
  __typename?: 'UpdateOrganizationRealizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationStatusMutation */
export type UpdateOrganizationStatusMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: OrganizationStatusEnum;
};

/** Autogenerated return type of UpdateOrganizationStatusMutation. */
export type UpdateOrganizationStatusMutationPayload = {
  __typename?: 'UpdateOrganizationStatusMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationTelephone */
export type UpdateOrganizationTelephoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
};

/** Autogenerated return type of UpdateOrganizationTelephone. */
export type UpdateOrganizationTelephonePayload = {
  __typename?: 'UpdateOrganizationTelephonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationTimeZone */
export type UpdateOrganizationTimeZoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
};

/** Autogenerated return type of UpdateOrganizationTimeZone. */
export type UpdateOrganizationTimeZonePayload = {
  __typename?: 'UpdateOrganizationTimeZonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationTrialMutation */
export type UpdateOrganizationTrialMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Date in format of YYYY-MM-DD */
  trialEndDate: Scalars['String'];
};

/** Autogenerated return type of UpdateOrganizationTrialMutation. */
export type UpdateOrganizationTrialMutationPayload = {
  __typename?: 'UpdateOrganizationTrialMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationUnitOfMeasurement */
export type UpdateOrganizationUnitOfMeasurementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  unitOfMeasurement: UnitOfMeasurement;
};

/** Autogenerated return type of UpdateOrganizationUnitOfMeasurement. */
export type UpdateOrganizationUnitOfMeasurementPayload = {
  __typename?: 'UpdateOrganizationUnitOfMeasurementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOrganizationWebsite */
export type UpdateOrganizationWebsiteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  website: Scalars['String'];
};

/** Autogenerated return type of UpdateOrganizationWebsite. */
export type UpdateOrganizationWebsitePayload = {
  __typename?: 'UpdateOrganizationWebsitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateOverhead */
export type UpdateOverheadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  availableHours: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateOverheadMultiplier */
export type UpdateOverheadMultiplierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  overheadMultiplier?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateOverheadMultiplier. */
export type UpdateOverheadMultiplierPayload = {
  __typename?: 'UpdateOverheadMultiplierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated return type of UpdateOverhead. */
export type UpdateOverheadPayload = {
  __typename?: 'UpdateOverheadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  overhead?: Maybe<Overhead>;
};

/** Autogenerated input type of UpdatePaymentDepositAccount */
export type UpdatePaymentDepositAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboAccountId: Scalars['ID'];
};

/** Autogenerated return type of UpdatePaymentDepositAccount. */
export type UpdatePaymentDepositAccountPayload = {
  __typename?: 'UpdatePaymentDepositAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdatePayrollCompany */
export type UpdatePayrollCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payFrequency?: Maybe<PayFrequency>;
  email?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  address?: Maybe<PayrollAddressInput>;
};

/** Autogenerated return type of UpdatePayrollCompany. */
export type UpdatePayrollCompanyPayload = {
  __typename?: 'UpdatePayrollCompanyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyInfo: PayrollCompanyInfo;
};

/** Autogenerated input type of UpdatePayrollContractor */
export type UpdatePayrollContractorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address: PayrollAddressInput;
};

/** Autogenerated return type of UpdatePayrollContractor. */
export type UpdatePayrollContractorPayload = {
  __typename?: 'UpdatePayrollContractorPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contractorInfo: PayrollWorkerInfo;
};

/** Autogenerated input type of UpdatePayrollEmployee */
export type UpdatePayrollEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  residence?: Maybe<PayrollAddressInputPartial>;
};

/** Autogenerated return type of UpdatePayrollEmployee. */
export type UpdatePayrollEmployeePayload = {
  __typename?: 'UpdatePayrollEmployeePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  employeeInfo: PayrollEmployeeInfo;
};

/** Autogenerated input type of UpdatePayrollEmploymentType */
export type UpdatePayrollEmploymentTypeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  employmentType: CheckEmployment;
};

/** Autogenerated return type of UpdatePayrollEmploymentType. */
export type UpdatePayrollEmploymentTypePayload = {
  __typename?: 'UpdatePayrollEmploymentTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile: Profile;
};

/** Autogenerated input type of UpdatePayrollWorkplace */
export type UpdatePayrollWorkplaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  workplaceId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<PayrollAddressInputPartial>;
};

/** Autogenerated return type of UpdatePayrollWorkplace. */
export type UpdatePayrollWorkplacePayload = {
  __typename?: 'UpdatePayrollWorkplacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  workplace: Workplace;
};

/** Autogenerated input type of UpdatePermission */
export type UpdatePermissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  key: PermissionsKey;
  value: Scalars['Boolean'];
  /** Policy ID */
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdatePermission. */
export type UpdatePermissionPayload = {
  __typename?: 'UpdatePermissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
};

export type UpdatePhaseConsultantEntryInput = {
  phaseId: Scalars['ID'];
  budget: Scalars['Float'];
};

/** Autogenerated input type of UpdatePhaseConsultants */
export type UpdatePhaseConsultantsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  type: PlanableTypes;
  slug: Scalars['String'];
  initialTemplateId: Scalars['ID'];
  newTemplateId: Scalars['ID'];
  phaseConsultants: Array<UpdatePhaseConsultantEntryInput>;
  markup: Scalars['Float'];
  consultantCompanyId?: Maybe<Scalars['ID']>;
  consultantContactId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdatePhaseConsultants. */
export type UpdatePhaseConsultantsPayload = {
  __typename?: 'UpdatePhaseConsultantsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phaseConsultants: Array<PhaseConsultant>;
};

/** Autogenerated input type of UpdatePhase */
export type UpdatePhaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  phaseTemplateId?: Maybe<Scalars['ID']>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
  feeType?: Maybe<PhaseFee>;
  updateRoleHours?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdatePhase. */
export type UpdatePhasePayload = {
  __typename?: 'UpdatePhasePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
};

/** Autogenerated input type of UpdatePhasePosition */
export type UpdatePhasePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of UpdatePhasePosition. */
export type UpdatePhasePositionPayload = {
  __typename?: 'UpdatePhasePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
};

/** Autogenerated input type of UpdatePhaseStatus */
export type UpdatePhaseStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: PhaseStatus;
};

/** Autogenerated return type of UpdatePhaseStatus. */
export type UpdatePhaseStatusPayload = {
  __typename?: 'UpdatePhaseStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
};

/** Autogenerated input type of UpdatePhaseTemplate */
export type UpdatePhaseTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
};

/** Autogenerated return type of UpdatePhaseTemplate. */
export type UpdatePhaseTemplatePayload = {
  __typename?: 'UpdatePhaseTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phaseTemplate?: Maybe<PhaseTemplate>;
};

/** Autogenerated input type of UpdatePhaseTemplateQuickbooksMapping */
export type UpdatePhaseTemplateQuickbooksMappingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboItemId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdatePhaseTemplateQuickbooksMapping. */
export type UpdatePhaseTemplateQuickbooksMappingPayload = {
  __typename?: 'UpdatePhaseTemplateQuickbooksMappingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  phaseTemplate?: Maybe<PhaseTemplate>;
};

/** Autogenerated input type of UpdatePolicy */
export type UpdatePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdatePolicy. */
export type UpdatePolicyPayload = {
  __typename?: 'UpdatePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policy?: Maybe<Policy>;
};

/** Autogenerated input type of UpdateProfileDefaultRoleTemplate */
export type UpdateProfileDefaultRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplateId?: Maybe<Scalars['ID']>;
  rateTableId?: Maybe<Scalars['ID']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of UpdateProfileDefaultRoleTemplate. */
export type UpdateProfileDefaultRoleTemplatePayload = {
  __typename?: 'UpdateProfileDefaultRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  pronouns?: Maybe<Scalars['String']>;
  isSignedUpForRetainers?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateProfileRoleTemplate */
export type UpdateProfileRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplateId?: Maybe<Scalars['ID']>;
  profileId: Scalars['ID'];
};

/** Autogenerated return type of UpdateProfileRoleTemplate. */
export type UpdateProfileRoleTemplatePayload = {
  __typename?: 'UpdateProfileRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateProfileSettings */
export type UpdateProfileSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  sendDailyEmail?: Maybe<Scalars['Boolean']>;
  sendTimesheetsSummaryEmail?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateProfileSettings. */
export type UpdateProfileSettingsPayload = {
  __typename?: 'UpdateProfileSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateProjectConsultant */
export type UpdateProjectConsultantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  templateId?: Maybe<Scalars['ID']>;
  budget: Scalars['Float'];
  markup?: Maybe<Scalars['Float']>;
  consultantCompanyId?: Maybe<Scalars['ID']>;
  consultantContactId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateProjectConsultant. */
export type UpdateProjectConsultantPayload = {
  __typename?: 'UpdateProjectConsultantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  projectConsultant: ProjectConsultant;
};

/** Autogenerated input type of UpdateProjectStatus */
export type UpdateProjectStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status: ProjectStatus;
};

/** Autogenerated return type of UpdateProjectStatus. */
export type UpdateProjectStatusPayload = {
  __typename?: 'UpdateProjectStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

/** Autogenerated input type of UpdateRate */
export type UpdateRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  effectiveOn?: Maybe<Scalars['ISO8601Date']>;
  amount: Scalars['Float'];
};

/** Autogenerated return type of UpdateRate. */
export type UpdateRatePayload = {
  __typename?: 'UpdateRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rate?: Maybe<Rate>;
};

/** Autogenerated input type of UpdateReimbursableExpensesAccount */
export type UpdateReimbursableExpensesAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboAccountId: Scalars['ID'];
};

/** Autogenerated return type of UpdateReimbursableExpensesAccount. */
export type UpdateReimbursableExpensesAccountPayload = {
  __typename?: 'UpdateReimbursableExpensesAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateRetainersQuickbooksMapping */
export type UpdateRetainersQuickbooksMappingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboItemId?: Maybe<Scalars['ID']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UpdateRetainersQuickbooksMapping. */
export type UpdateRetainersQuickbooksMappingPayload = {
  __typename?: 'UpdateRetainersQuickbooksMappingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  retainer?: Maybe<Retainer>;
};

/** Autogenerated input type of UpdateRoleHours */
export type UpdateRoleHoursInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  hours: Scalars['Float'];
};

/** Autogenerated return type of UpdateRoleHours. */
export type UpdateRoleHoursPayload = {
  __typename?: 'UpdateRoleHoursPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

/** Autogenerated input type of UpdateRolePosition */
export type UpdateRolePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of UpdateRolePosition. */
export type UpdateRolePositionPayload = {
  __typename?: 'UpdateRolePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

/** Autogenerated input type of UpdateRoleProfile */
export type UpdateRoleProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  profileId: Scalars['ID'];
};

/** Autogenerated return type of UpdateRoleProfile. */
export type UpdateRoleProfilePayload = {
  __typename?: 'UpdateRoleProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

/** Autogenerated input type of UpdateRoleRoleTemplateAndRate */
export type UpdateRoleRoleTemplateAndRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roleTemplateId: Scalars['ID'];
  rateTableId: Scalars['ID'];
  setAsDefaultRole?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateRoleRoleTemplateAndRate. */
export type UpdateRoleRoleTemplateAndRatePayload = {
  __typename?: 'UpdateRoleRoleTemplateAndRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  role: Role;
};

/** Autogenerated input type of UpdateRoleTemplate */
export type UpdateRoleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateRoleTemplate. */
export type UpdateRoleTemplatePayload = {
  __typename?: 'UpdateRoleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  roleTemplate?: Maybe<RoleTemplate>;
};

/** Autogenerated input type of UpdateStripePayoutAccount */
export type UpdateStripePayoutAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboAccountId: Scalars['ID'];
};

/** Autogenerated return type of UpdateStripePayoutAccount. */
export type UpdateStripePayoutAccountPayload = {
  __typename?: 'UpdateStripePayoutAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateTaxRate */
export type UpdateTaxRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Float'];
};

/** Autogenerated return type of UpdateTaxRate. */
export type UpdateTaxRatePayload = {
  __typename?: 'UpdateTaxRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  taxRate: TaxRate;
};

/** Autogenerated input type of UpdateTerm */
export type UpdateTermInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  days: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateTerm. */
export type UpdateTermPayload = {
  __typename?: 'UpdateTermPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  term?: Maybe<Term>;
};

/** Autogenerated input type of UpdateTimeOffHoliday */
export type UpdateTimeOffHolidayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of UpdateTimeOffHoliday. */
export type UpdateTimeOffHolidayPayload = {
  __typename?: 'UpdateTimeOffHolidayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffHolidayPolicy: TimeOffHolidayPolicy;
};

/** Autogenerated input type of UpdateTimeOff */
export type UpdateTimeOffInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOff: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateTimeOff. */
export type UpdateTimeOffPayload = {
  __typename?: 'UpdateTimeOffPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization: Organization;
};

/** Autogenerated input type of UpdateTimeOffPolicy */
export type UpdateTimeOffPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewRecipientIds?: Maybe<Array<Scalars['ID']>>;
  autoApprove?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateTimeOffPolicy. */
export type UpdateTimeOffPolicyPayload = {
  __typename?: 'UpdateTimeOffPolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffPolicy?: Maybe<TimeOffPolicy>;
};

/** Autogenerated input type of UpdateTimeOffRequest */
export type UpdateTimeOffRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTimeOffRequest. */
export type UpdateTimeOffRequestPayload = {
  __typename?: 'UpdateTimeOffRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timeOffRequest?: Maybe<TimeOffRequest>;
};

/** Autogenerated input type of UpdateTimer */
export type UpdateTimerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  hasTimer: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateTimer. */
export type UpdateTimerPayload = {
  __typename?: 'UpdateTimerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization: Organization;
};

/** Autogenerated input type of UpdateTimesheet */
export type UpdateTimesheetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUnlocked?: Maybe<Scalars['Boolean']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of UpdateTimesheetLockDays */
export type UpdateTimesheetLockDaysInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLockDays: Scalars['Int'];
};

/** Autogenerated return type of UpdateTimesheetLockDays. */
export type UpdateTimesheetLockDaysPayload = {
  __typename?: 'UpdateTimesheetLockDaysPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLock?: Maybe<TimesheetLock>;
};

/** Autogenerated input type of UpdateTimesheetLock */
export type UpdateTimesheetLockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLock: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateTimesheetLock. */
export type UpdateTimesheetLockPayload = {
  __typename?: 'UpdateTimesheetLockPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLock?: Maybe<TimesheetLock>;
};

/** Autogenerated input type of UpdateTimesheetLockTime */
export type UpdateTimesheetLockTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLockTime: Scalars['Float'];
};

/** Autogenerated return type of UpdateTimesheetLockTime. */
export type UpdateTimesheetLockTimePayload = {
  __typename?: 'UpdateTimesheetLockTimePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetLock?: Maybe<TimesheetLock>;
};

/** Autogenerated input type of UpdateTimesheetNotesExample */
export type UpdateTimesheetNotesExampleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetNotesExample: Scalars['String'];
};

/** Autogenerated return type of UpdateTimesheetNotesExample. */
export type UpdateTimesheetNotesExamplePayload = {
  __typename?: 'UpdateTimesheetNotesExamplePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpdateTimesheetNotesReminder */
export type UpdateTimesheetNotesReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetNotesReminder: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateTimesheetNotesReminder. */
export type UpdateTimesheetNotesReminderPayload = {
  __typename?: 'UpdateTimesheetNotesReminderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated return type of UpdateTimesheet. */
export type UpdateTimesheetPayload = {
  __typename?: 'UpdateTimesheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated input type of UpdateTimesheetReminderDay */
export type UpdateTimesheetReminderDayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminderDay: Scalars['Int'];
};

/** Autogenerated return type of UpdateTimesheetReminderDay. */
export type UpdateTimesheetReminderDayPayload = {
  __typename?: 'UpdateTimesheetReminderDayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminder?: Maybe<TimesheetReminder>;
};

/** Autogenerated input type of UpdateTimesheetReminder */
export type UpdateTimesheetReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminder: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateTimesheetReminderNote */
export type UpdateTimesheetReminderNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminderNote: Scalars['String'];
};

/** Autogenerated return type of UpdateTimesheetReminderNote. */
export type UpdateTimesheetReminderNotePayload = {
  __typename?: 'UpdateTimesheetReminderNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminder?: Maybe<TimesheetReminder>;
};

/** Autogenerated return type of UpdateTimesheetReminder. */
export type UpdateTimesheetReminderPayload = {
  __typename?: 'UpdateTimesheetReminderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminder?: Maybe<TimesheetReminder>;
};

/** Autogenerated input type of UpdateTimesheetReminderTime */
export type UpdateTimesheetReminderTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminderTime: Scalars['Float'];
};

/** Autogenerated return type of UpdateTimesheetReminderTime. */
export type UpdateTimesheetReminderTimePayload = {
  __typename?: 'UpdateTimesheetReminderTimePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  timesheetReminder?: Maybe<TimesheetReminder>;
};

/** Autogenerated input type of UpdateUserSuperadminStatus */
export type UpdateUserSuperadminStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of UpdateUserSuperadminStatus. */
export type UpdateUserSuperadminStatusPayload = {
  __typename?: 'UpdateUserSuperadminStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateUtilizationRate */
export type UpdateUtilizationRateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
  utilizationRate: Scalars['Float'];
};

/** Autogenerated return type of UpdateUtilizationRate. */
export type UpdateUtilizationRatePayload = {
  __typename?: 'UpdateUtilizationRatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

/** Autogenerated input type of UpdateYearlyWorkingHours */
export type UpdateYearlyWorkingHoursInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  yearlyWorkingHours?: Scalars['Float'];
};

/** Autogenerated return type of UpdateYearlyWorkingHours. */
export type UpdateYearlyWorkingHoursPayload = {
  __typename?: 'UpdateYearlyWorkingHoursPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

/** Autogenerated input type of UpsertEntry */
export type UpsertEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['ID']>;
  entryRowId?: Maybe<Scalars['ID']>;
  overheadId?: Maybe<Scalars['ID']>;
  phaseId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  activityId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpsertEntry. */
export type UpsertEntryPayload = {
  __typename?: 'UpsertEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<Entry>;
  entryRow?: Maybe<EntryRow>;
  timesheet?: Maybe<Timesheet>;
};

/** Autogenerated input type of UpsertEntryWithNotes */
export type UpsertEntryWithNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entryId?: Maybe<Scalars['ID']>;
  entryRowId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
  notes: Scalars['String'];
};

/** Autogenerated return type of UpsertEntryWithNotes. */
export type UpsertEntryWithNotesPayload = {
  __typename?: 'UpsertEntryWithNotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry: Entry;
  entryRow: EntryRow;
};

export type User = {
  __typename?: 'User';
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  fname?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isClient?: Maybe<Scalars['Boolean']>;
  isTeamMember?: Maybe<Scalars['Boolean']>;
  lname?: Maybe<Scalars['String']>;
  loggedInAsSomeoneElse: Scalars['Boolean'];
  organizations: Array<Organization>;
  payrollAdmin: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
};

export type UtilizationChartEntry = {
  __typename?: 'UtilizationChartEntry';
  data: UtilizationChartEntryData;
  totalHours?: Maybe<Scalars['Float']>;
  xAxis: Scalars['ISO8601Date'];
};

export type UtilizationChartEntryData = {
  __typename?: 'UtilizationChartEntryData';
  billableHours?: Maybe<Scalars['Float']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
  utilizationRate?: Maybe<Scalars['Float']>;
};

/** Filters for utilization report */
export type UtilizationFiltersInput = {
  dateRange?: Maybe<DateRangeInput>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of Utilization */
export type UtilizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of conditions entries should satisfy */
  filters?: Maybe<UtilizationFiltersInput>;
};

/** Autogenerated return type of Utilization. */
export type UtilizationPayload = {
  __typename?: 'UtilizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type UtilizationProfileEntry = {
  __typename?: 'UtilizationProfileEntry';
  billableHours: Scalars['Float'];
  fname: Scalars['String'];
  id: Scalars['ID'];
  lname: Scalars['String'];
  nonBillableHours: Scalars['Float'];
  slug: Scalars['String'];
  targetRate?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  utilizationRate: Scalars['Float'];
};

export type UtilizationReport = {
  __typename?: 'UtilizationReport';
  chart: Array<UtilizationChartEntry>;
  dateIncrement: DateIncrement;
  defaultUtilizationRate?: Maybe<Scalars['Float']>;
  earliestEntryDate?: Maybe<Scalars['ISO8601DateTime']>;
  table: Array<UtilizationTableEntryData>;
  totals: UtilizationTotals;
};

export type UtilizationTableEntryData = {
  __typename?: 'UtilizationTableEntryData';
  billableHours: Scalars['Float'];
  nonBillableHours: Scalars['Float'];
  rangeEnd: Scalars['ISO8601Date'];
  rangeStart: Scalars['ISO8601Date'];
  totalHours: Scalars['Float'];
  utilizationRate: Scalars['Float'];
};

export type UtilizationTotals = {
  __typename?: 'UtilizationTotals';
  billableHours?: Maybe<Scalars['Float']>;
  nonBillableHours?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
  utilizationRate?: Maybe<Scalars['Float']>;
};

export type Worker = {
  __typename?: 'Worker';
  bankAccounts: Array<PayrollBankAccount>;
  compensations: Array<Compensation>;
  document: PayrollEmployeeDocument;
  documents: PayrollWorkerDocuments;
  earningRates: Array<PayrollEarningRate>;
  id: Scalars['ID'];
  info: PayrollWorkerInfo;
  links: PayrollEmployeeLinks;
  profile: Profile;
  type: CheckEmployment;
};


export type WorkerCompensationsArgs = {
  beginDate?: Maybe<Scalars['ISO8601DateTime']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Workplace = {
  __typename?: 'Workplace';
  active: Scalars['Boolean'];
  address: PayrollAddress;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of Xero */
export type XeroInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  slug: Scalars['ID'];
};

/** Autogenerated return type of Xero. */
export type XeroPayload = {
  __typename?: 'XeroPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type YearlyTotals = {
  __typename?: 'YearlyTotals';
  averageDaysToPayment?: Maybe<Scalars['Float']>;
  invoiced: Scalars['Float'];
  paymentsReceived: Scalars['Float'];
  totalCountYtdInvoices: Scalars['Int'];
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByUrl?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

export type EnableMfaMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type EnableMfaMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { enableMfa?: Maybe<(
      { __typename?: 'EnableMfaPayload' }
      & Pick<EnableMfaPayload, 'mfaQrCodeSvg'>
    )> }
  ) }
);

export type ClientInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientInfoQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isClient' | 'fname' | 'lname' | 'fullName' | 'email' | 'isTeamMember'>
  )> }
);

export type CreateClientLoginLinkMutationVariables = Exact<{
  input: CreateClientLoginLinkInput;
}>;


export type CreateClientLoginLinkMutation = (
  { __typename?: 'Mutation' }
  & { createClientLoginLink?: Maybe<(
    { __typename?: 'CreateClientLoginLinkPayload' }
    & Pick<CreateClientLoginLinkPayload, 'status'>
  )> }
);

export type CreateClientAccountMutationVariables = Exact<{
  input: CreateClientAccountInput;
}>;


export type CreateClientAccountMutation = (
  { __typename?: 'Mutation' }
  & { createClientAccount?: Maybe<(
    { __typename?: 'CreateClientAccountPayload' }
    & Pick<CreateClientAccountPayload, 'status'>
  )> }
);

export type DetachConsultantCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetachConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { detachConsultantCompany?: Maybe<(
    { __typename?: 'DetachConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id'>
    )> }
  )> }
);

export type VendorResultFragment = (
  { __typename?: 'QuickbooksVendor' }
  & Pick<QuickbooksVendor, 'displayName' | 'fname' | 'lname' | 'email' | 'phone' | 'website' | 'quickbooksVendorId' | 'quickbooksVendorSyncToken'>
  & { address?: Maybe<(
    { __typename?: 'QBOAddress' }
    & Pick<QboAddress, 'city'>
  )> }
);

export type QuickbooksVendorsToImportQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickbooksVendorsToImportQuery = (
  { __typename?: 'Query' }
  & { quickbooksVendorsToImport: Array<(
    { __typename?: 'QuickbooksVendor' }
    & VendorResultFragment
  )> }
);

export type QuickbooksVendorsPotentialMatchingConsultantQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickbooksVendorsPotentialMatchingConsultantQuery = (
  { __typename?: 'Query' }
  & { quickbooksVendorsPotentialMatchingConsultants: Array<(
    { __typename?: 'QBOVendorConsultantMatch' }
    & Pick<QboVendorConsultantMatch, 'consultantCompanyId' | 'qboVendorId'>
  )> }
);

export type AssignedWorkPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name'>
);

export type AssignedWorkProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'number' | 'color' | 'slug'>
);

export type AssignedWorkOverheadFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name'>
);

export type AssignedWorkEntryGroupFragment = (
  { __typename?: 'EntryGroup' }
  & Pick<EntryGroup, 'allocation'>
  & { phase?: Maybe<(
    { __typename?: 'Phase' }
    & AssignedWorkPhaseFragment
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & AssignedWorkProjectFragment
  )>, overhead?: Maybe<(
    { __typename?: 'Overhead' }
    & AssignedWorkOverheadFragment
  )> }
);

export type ProfileAssignedTasksOrWorkQueryVariables = Exact<{
  overdueTasksFilters?: Maybe<TaskFiltersInput>;
  thisWeekTasksFilters?: Maybe<TaskFiltersInput>;
  nextWeekTasksFilters?: Maybe<TaskFiltersInput>;
}>;


export type ProfileAssignedTasksOrWorkQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
    & { overdueTasks: (
      { __typename?: 'TaskConnection' }
      & Pick<TaskConnection, 'totalCount'>
    ), thisWeekTasks: (
      { __typename?: 'TaskConnection' }
      & Pick<TaskConnection, 'totalCount'>
    ), nextWeekTasks: (
      { __typename?: 'TaskConnection' }
      & Pick<TaskConnection, 'totalCount'>
    ), allTasks: (
      { __typename?: 'TaskConnection' }
      & Pick<TaskConnection, 'totalCount'>
    ), currentTimesheet?: Maybe<(
      { __typename?: 'Timesheet' }
      & Pick<Timesheet, 'id' | 'beginDate'>
      & { entryGroups?: Maybe<Array<(
        { __typename?: 'EntryGroup' }
        & AssignedWorkEntryGroupFragment
      )>> }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { subscriptionForOrgOrDemoParent?: Maybe<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'planName'>
      )> }
    )> }
  )> }
);

export type OrganizationBusinessPerformanceMetricFragment = (
  { __typename?: 'BusinessPerformanceMetric' }
  & Pick<BusinessPerformanceMetric, 'aggregate'>
  & { items: Array<(
    { __typename?: 'BusinessPerformanceMetricItem' }
    & Pick<BusinessPerformanceMetricItem, 'beginDate' | 'value'>
  )> }
);

export type BusinessPerformanceUtilizationRateQueryVariables = Exact<{
  useCache?: Maybe<Scalars['Boolean']>;
}>;


export type BusinessPerformanceUtilizationRateQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { businessPerformance: (
        { __typename?: 'InsightsDashboardBusinessPerformance' }
        & Pick<InsightsDashboardBusinessPerformance, 'id'>
        & { utilization?: Maybe<(
          { __typename?: 'BusinessPerformanceMetric' }
          & OrganizationBusinessPerformanceMetricFragment
        )> }
      ) }
    )> }
  )> }
);

export type BusinessPerformanceProjectedFirmCapacityQueryVariables = Exact<{
  useCache?: Maybe<Scalars['Boolean']>;
}>;


export type BusinessPerformanceProjectedFirmCapacityQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { businessPerformance: (
        { __typename?: 'InsightsDashboardBusinessPerformance' }
        & Pick<InsightsDashboardBusinessPerformance, 'id'>
        & { projectedFirmCapacity?: Maybe<(
          { __typename?: 'BusinessPerformanceMetric' }
          & OrganizationBusinessPerformanceMetricFragment
        )> }
      ) }
    )> }
  )> }
);

export type BusinessPerformanceProjectedOperatingProfitQueryVariables = Exact<{
  useCache?: Maybe<Scalars['Boolean']>;
}>;


export type BusinessPerformanceProjectedOperatingProfitQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { businessPerformance: (
        { __typename?: 'InsightsDashboardBusinessPerformance' }
        & Pick<InsightsDashboardBusinessPerformance, 'id'>
        & { projectedOperatingProfit?: Maybe<(
          { __typename?: 'BusinessPerformanceMetric' }
          & OrganizationBusinessPerformanceMetricFragment
        )> }
      ) }
    )> }
  )> }
);

export type BusinessPerformanceEstimatedOperatingProfitQueryVariables = Exact<{
  useCache?: Maybe<Scalars['Boolean']>;
}>;


export type BusinessPerformanceEstimatedOperatingProfitQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo'>
      & { businessPerformance: (
        { __typename?: 'InsightsDashboardBusinessPerformance' }
        & Pick<InsightsDashboardBusinessPerformance, 'id'>
        & { estimatedOperatingProfit?: Maybe<(
          { __typename?: 'BusinessPerformanceMetric' }
          & OrganizationBusinessPerformanceMetricFragment
        )> }
      ) }
    )> }
  )> }
);

export type ProjectsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsCountQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'projectsCount'>
    )> }
  )> }
);

export type OverBudgetPhasesQueryVariables = Exact<{ [key: string]: never; }>;


export type OverBudgetPhasesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { projectsWithOverBudgetPhases: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      )> }
    )> }
  )> }
);

export type OverStaffedPhasesQueryVariables = Exact<{ [key: string]: never; }>;


export type OverStaffedPhasesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { projectsWithOverStaffedPhases: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      )> }
    )> }
  )> }
);

export type OverCapacityProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type OverCapacityProfilesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { overCapacityProfiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )> }
    )> }
  )> }
);

export type OrganizationInvoicesQueryVariables = Exact<{
  filters?: Maybe<InvoiceFiltersInput>;
}>;


export type OrganizationInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalBilled' | 'totalCount'>
  ) }
);

export type OrganizationUnpaidConsultantBillsQueryVariables = Exact<{
  filters?: Maybe<ConsultantBillFiltersInput>;
}>;


export type OrganizationUnpaidConsultantBillsQuery = (
  { __typename?: 'Query' }
  & { consultantBills: (
    { __typename?: 'ConsultantBillConnection' }
    & Pick<ConsultantBillConnection, 'totalBilled' | 'totalCount'>
  ) }
);

export type OrganizationUnbilledAmountQueryVariables = Exact<{
  filters: ProjectsUnbilledFiltersInput;
  useCache?: Maybe<Scalars['Boolean']>;
  timesheetParams: SearchableTimesheetsParametersInput;
}>;


export type OrganizationUnbilledAmountQuery = (
  { __typename?: 'Query' }
  & { searchableTimesheets?: Maybe<Array<(
    { __typename?: 'Timesheet' }
    & Pick<Timesheet, 'id'>
  )>>, reports: (
    { __typename?: 'Reports' }
    & { projectsUnbilled: (
      { __typename?: 'ProjectsUnbilledConnection' }
      & { totals: (
        { __typename?: 'ProjectUnbilledTotals' }
        & Pick<ProjectUnbilledTotals, 'currentUnbilled'>
      ) }
    ) }
  ) }
);

export type RecentProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'budget' | 'budgetConsumed' | 'hours' | 'hoursLogged' | 'color' | 'number' | 'slug'>
);

export type ProjectsByIdQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ProjectsByIdQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & RecentProjectFragment
  )>> }
);

export type RecentProjectsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SearchableProjectsSortInput>;
}>;


export type RecentProjectsQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & RecentProjectFragment
  )>> }
);

export type TimesheetTotalsFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'beginDate' | 'totalHours' | 'hoursAllotted'>
);

export type ProfileTimesheetsQueryVariables = Exact<{
  thisWeekBeginDate: Scalars['ISO8601Date'];
  lastWeekBeginDate: Scalars['ISO8601Date'];
}>;


export type ProfileTimesheetsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'slug'>
    & { thisWeekTimesheet: (
      { __typename?: 'Timesheet' }
      & TimesheetTotalsFragment
    ), lastWeekTimesheet: (
      { __typename?: 'Timesheet' }
      & TimesheetTotalsFragment
    ) }
  )> }
);

export type PaymentMethodsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type PaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'total'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { stripeAccount?: Maybe<(
        { __typename?: 'StripeAccount' }
        & OrganizationStripeAccountFragmentFragment
      )> }
    ) }
    & InvoicePaymentMethodsFragmentFragment
  ) }
);

export type UpdateInvoicePaymentOptionsMutationVariables = Exact<{
  data: UpdateInvoicePaymentOptionsInput;
}>;


export type UpdateInvoicePaymentOptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoicePaymentOptions?: Maybe<(
    { __typename?: 'UpdateInvoicePaymentOptionsPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & InvoicePaymentMethodsFragmentFragment
    ) }
  )> }
);

export type InvoiceCountQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceCountQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount'>
  ) }
);

export type ReceivedInvoicesQueryVariables = Exact<{
  filters?: Maybe<ReceivedInvoicesFiltersInput>;
  sortBy?: Maybe<ReceivedInvoicesSortInput>;
}>;


export type ReceivedInvoicesQuery = (
  { __typename?: 'Query' }
  & { receivedInvoices: (
    { __typename?: 'ReceivedInvoices' }
    & { invoices: (
      { __typename?: 'ReceivedInvoiceConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'ReceivedInvoice' }
        & Pick<ReceivedInvoice, 'emailAddress' | 'id'>
        & { invoice: (
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'number' | 'issueDate' | 'total' | 'status' | 'dueDate' | 'paidDate' | 'remainingBalance' | 'paymentUrl' | 'totalPaid' | 'allowBankTransfers' | 'allowCardPayments' | 'paymentIntentStatus'>
          & { organization: (
            { __typename?: 'Organization' }
            & Pick<Organization, 'name'>
          ), project: (
            { __typename?: 'Project' }
            & Pick<Project, 'name'>
          ), invoicePayment?: Maybe<(
            { __typename?: 'InvoicePayment' }
            & Pick<InvoicePayment, 'amount' | 'net' | 'applicationFee' | 'paymentType' | 'paymentCurrency'>
          )>, invoicePayments?: Maybe<Array<(
            { __typename?: 'InvoicePayment' }
            & Pick<InvoicePayment, 'id' | 'amount' | 'paidDate'>
          )>> }
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    ), totals: (
      { __typename?: 'ReceivedInvoicesTotals' }
      & { pastDue: (
        { __typename?: 'ReceivedInvoicesTotalWithCountType' }
        & Pick<ReceivedInvoicesTotalWithCountType, 'amount' | 'count'>
      ), open: (
        { __typename?: 'ReceivedInvoicesTotalWithCountType' }
        & Pick<ReceivedInvoicesTotalWithCountType, 'amount' | 'count'>
      ), paid: (
        { __typename?: 'ReceivedInvoicesTotalWithCountType' }
        & Pick<ReceivedInvoicesTotalWithCountType, 'amount' | 'count'>
      ) }
    ) }
  ) }
);

export type ReceivedInvoicesFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReceivedInvoicesFiltersQuery = (
  { __typename?: 'Query' }
  & { receivedInvoices: (
    { __typename?: 'ReceivedInvoices' }
    & { filters: (
      { __typename?: 'ReceivedInvoicesFiltersType' }
      & { organizations: Array<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      )>, projects: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type ReceivedInvoicesCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ReceivedInvoicesCountQuery = (
  { __typename?: 'Query' }
  & { receivedInvoices: (
    { __typename?: 'ReceivedInvoices' }
    & { invoices: (
      { __typename?: 'ReceivedInvoiceConnection' }
      & Pick<ReceivedInvoiceConnection, 'totalCount'>
    ) }
  ) }
);

export type FilteredTotalsFragment = (
  { __typename?: 'FilteredTotals' }
  & { invoiced: (
    { __typename?: 'TotalWithCountType' }
    & Pick<TotalWithCountType, 'count' | 'amount' | 'otherAmount' | 'firmServicesAmount' | 'consultantServicesAmount' | 'expensesAmount'>
  ), paid: (
    { __typename?: 'TotalWithCountType' }
    & Pick<TotalWithCountType, 'count' | 'amount' | 'otherAmount' | 'firmServicesAmount' | 'consultantServicesAmount' | 'expensesAmount'>
  ), open: (
    { __typename?: 'TotalWithCountType' }
    & Pick<TotalWithCountType, 'count' | 'amount' | 'otherAmount' | 'firmServicesAmount' | 'consultantServicesAmount' | 'expensesAmount'>
  ), pastDue: (
    { __typename?: 'TotalWithCountType' }
    & Pick<TotalWithCountType, 'count' | 'amount' | 'otherAmount' | 'firmServicesAmount' | 'consultantServicesAmount' | 'expensesAmount'>
  ) }
);

export type YearlyTotalsFragment = (
  { __typename?: 'YearlyTotals' }
  & Pick<YearlyTotals, 'averageDaysToPayment' | 'invoiced' | 'paymentsReceived' | 'totalCountYtdInvoices'>
);

export type InvoiceTotalsChartFragment = (
  { __typename?: 'InvoicesTotalsChartEntry' }
  & Pick<InvoicesTotalsChartEntry, 'xAxis' | 'total'>
  & { data: Array<(
    { __typename?: 'InvoicesTotalsChartEntryData' }
    & Pick<InvoicesTotalsChartEntryData, 'status' | 'amount'>
  )> }
);

export type InvoiceTotalsFragment = (
  { __typename?: 'Reports' }
  & { invoiceTotals: (
    { __typename?: 'InvoicesTotals' }
    & Pick<InvoicesTotals, 'dateIncrement'>
    & { filteredTotals: (
      { __typename?: 'FilteredTotals' }
      & FilteredTotalsFragment
    ), yearlyTotals?: Maybe<(
      { __typename?: 'YearlyTotals' }
      & YearlyTotalsFragment
    )>, chart?: Maybe<Array<(
      { __typename?: 'InvoicesTotalsChartEntry' }
      & InvoiceTotalsChartFragment
    )>> }
  ) }
);

export type InvoiceTotalsOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceTotalsOrganizationQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'country'>
      )>, stripeAccount?: Maybe<(
        { __typename?: 'StripeAccount' }
        & Pick<StripeAccount, 'id'>
      )> }
    )> }
  )> }
);

export type InvoiceTotalsQueryVariables = Exact<{
  filters: InvoiceFiltersInput;
}>;


export type InvoiceTotalsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & InvoiceTotalsFragment
  ) }
);

export type AddPaymentOrganizationDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type AddPaymentOrganizationDetailsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'country'>
      )>, stripeAccount?: Maybe<(
        { __typename?: 'StripeAccount' }
        & Pick<StripeAccount, 'id'>
      )> }
    )> }
  )> }
);

export type PaymentAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentAccountsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'bankName' | 'last4' | 'createdAt'>
    & { billingDetails: (
      { __typename?: 'BillingDetails' }
      & Pick<BillingDetails, 'name' | 'email'>
    ) }
  ) | (
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'brand' | 'expMonth' | 'expYear' | 'last4' | 'createdAt'>
    & { billingDetails: (
      { __typename?: 'BillingDetails' }
      & Pick<BillingDetails, 'name' | 'email'>
    ) }
  )> }
);

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { deletePaymentMethod?: Maybe<(
    { __typename?: 'DeletePaymentMethodPayload' }
    & { paymentMethod: (
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id' | 'bankName' | 'last4' | 'createdAt'>
    ) | (
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'brand' | 'expMonth' | 'expYear' | 'last4' | 'createdAt'>
    ) }
  )> }
);

export type CreateSetupIntentMutationVariables = Exact<{
  paymentMethodTypes: Array<PaymentMethodType> | PaymentMethodType;
}>;


export type CreateSetupIntentMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMethodSetupIntent?: Maybe<(
    { __typename?: 'CreatePaymentMethodSetupIntentPayload' }
    & { setupIntent: (
      { __typename?: 'StripeSetupIntent' }
      & Pick<StripeSetupIntent, 'clientSecret'>
    ) }
  )> }
);

export type EditPaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}>;


export type EditPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { editPaymentMethod?: Maybe<(
    { __typename?: 'EditPaymentMethodPayload' }
    & { paymentMethod: (
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'id'>
      & { billingDetails: (
        { __typename?: 'BillingDetails' }
        & Pick<BillingDetails, 'name' | 'email'>
      ) }
    ) | (
      { __typename?: 'Card' }
      & Pick<Card, 'id' | 'expMonth' | 'expYear'>
    ) }
  )> }
);

export type CheckoutPublicInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'status' | 'paymentIntentStatus' | 'allowBankTransfers' | 'allowCardPayments'>
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'logo'>
  ) }
);

export type CheckoutPaymentIntentFragment = (
  { __typename?: 'StripePaymentIntent' }
  & Pick<StripePaymentIntent, 'id' | 'clientSecret' | 'amount' | 'currency' | 'hasCustomer' | 'setupFutureUsage'>
);

export type CheckoutPaymentMethodTypeBankAccountFragment = (
  { __typename?: 'BankAccount' }
  & Pick<BankAccount, 'id' | 'type' | 'bankName' | 'last4' | 'createdAt'>
);

export type CheckoutPaymentMethodTypeCardFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'type' | 'brand' | 'expMonth' | 'expYear' | 'last4' | 'createdAt'>
);

type CheckoutPaymentMethod_BankAccount_Fragment = (
  { __typename?: 'BankAccount' }
  & CheckoutPaymentMethodTypeBankAccountFragment
);

type CheckoutPaymentMethod_Card_Fragment = (
  { __typename?: 'Card' }
  & CheckoutPaymentMethodTypeCardFragment
);

export type CheckoutPaymentMethodFragment = CheckoutPaymentMethod_BankAccount_Fragment | CheckoutPaymentMethod_Card_Fragment;

export type CheckoutInvoiceQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type CheckoutInvoiceQuery = (
  { __typename?: 'Query' }
  & { publicInvoice?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & CheckoutPublicInvoiceFragment
    ) }
  )> }
);

export type CheckoutPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<(
    { __typename?: 'BankAccount' }
    & CheckoutPaymentMethodTypeBankAccountFragment
  ) | (
    { __typename?: 'Card' }
    & CheckoutPaymentMethodTypeCardFragment
  )> }
);

export type CreateInvoicePaymentIntentMutationVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type CreateInvoicePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createInvoicePaymentIntent?: Maybe<(
    { __typename?: 'InvoiceCreatePaymentIntentPayload' }
    & { paymentIntent: (
      { __typename?: 'StripePaymentIntent' }
      & CheckoutPaymentIntentFragment
    ) }
  )> }
);

export type UpdateInvoicePaymentIntentMutationVariables = Exact<{
  paymentUuid: Scalars['String'];
  paymentIntentId: Scalars['String'];
  paymentMethodType: PaymentMethodType;
  setupFutureUsage: Scalars['Boolean'];
}>;


export type UpdateInvoicePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoicePaymentIntent?: Maybe<(
    { __typename?: 'InvoiceUpdatePaymentIntentPayload' }
    & Pick<InvoiceUpdatePaymentIntentPayload, 'convenienceFee'>
    & { paymentIntent: (
      { __typename?: 'StripePaymentIntent' }
      & CheckoutPaymentIntentFragment
    ) }
  )> }
);

export type RequestSavePaymentDetailsMutationVariables = Exact<{
  paymentUuid: Scalars['String'];
  paymentIntentId: Scalars['String'];
  fname: Scalars['String'];
  lname: Scalars['String'];
  email: Scalars['String'];
}>;


export type RequestSavePaymentDetailsMutation = (
  { __typename?: 'Mutation' }
  & { requestSavePaymentDetails?: Maybe<(
    { __typename?: 'RequestSavePaymentDetailsPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
    ) }
  )> }
);

export type CheckoutSsrInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'status' | 'paymentIntentStatus' | 'allowBankTransfers' | 'allowCardPayments'>
);

export type CheckoutSsrInvoiceQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type CheckoutSsrInvoiceQuery = (
  { __typename?: 'Query' }
  & { publicInvoice?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & CheckoutSsrInvoiceFragment
    ) }
  )> }
);

export type PaymentsInvoiceQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type PaymentsInvoiceQuery = (
  { __typename?: 'Query' }
  & { publicInvoice?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'status' | 'number'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'slug' | 'name'>
      ) }
    ) }
  )> }
);

export type ArchivedProjectsQueryVariables = Exact<{
  parameters: SearchableProjectsParametersInput;
}>;


export type ArchivedProjectsQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ArchivedProjectDataFragment
  )>> }
);

export type ArchivedProjectDataFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'number' | 'color' | 'name' | 'beginDate' | 'endDate' | 'status' | 'totalFee' | 'link'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'name'>
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'name'>
  )>> }
);

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProject?: Maybe<(
    { __typename?: 'DeleteProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  )> }
);

export type UnarchiveProjectMutationVariables = Exact<{
  input: UnarchiveProjectInput;
}>;


export type UnarchiveProjectMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveProject?: Maybe<(
    { __typename?: 'UnarchiveProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  )> }
);

export type ProjectOverviewConsultantsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectOverviewConsultantsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { consultantCompaniesWithTemplateNames?: Maybe<Array<(
      { __typename?: 'ConsultantWithTemplateNames' }
      & Pick<ConsultantWithTemplateNames, 'name' | 'slug' | 'templateName'>
    )>> }
  )> }
);

export type PhaseDetailsQueryVariables = Exact<{
  phaseId: Scalars['ID'];
}>;


export type PhaseDetailsQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'beginDate' | 'endDate' | 'feeType' | 'budget' | 'percent'>
    & { linkedPhase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'notes' | 'slug'>
    )> }
  ) }
);

export type ProjectDetailsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'budget' | 'budgetConsumed' | 'beginDate' | 'endDate' | 'id' | 'totalFee' | 'consultantsFee' | 'totalConsultantsMarkup' | 'notes'>
    & { categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>>, projectCost?: Maybe<(
      { __typename?: 'ProjectCost' }
      & Pick<ProjectCost, 'sqUnits' | 'constructionCost' | 'id' | 'sqUnitCostType'>
    )>, client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'fullName' | 'displayName' | 'phone' | 'email'>
    )> }
  )> }
);

export type PhaseOverviewQueryVariables = Exact<{
  phaseId: Scalars['ID'];
}>;


export type PhaseOverviewQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canEditAllProjects' | 'canEditAssignedProjects'>
      ) }
    )> }
  )>, phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'budgetConsumed' | 'budget' | 'beginDate' | 'endDate' | 'status'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & PhaseProgressRolesFragment
    )>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'name' | 'color' | 'status'>
    )>, teamList?: Maybe<Array<(
      { __typename?: 'TeamList' }
      & TeamListFragment
    )>>, phaseTemplate?: Maybe<(
      { __typename?: 'PhaseTemplate' }
      & Pick<PhaseTemplate, 'id'>
    )> }
  ) }
);

export type UpdatePhaseStatusMutationVariables = Exact<{
  input: UpdatePhaseStatusInput;
}>;


export type UpdatePhaseStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePhaseStatus?: Maybe<(
    { __typename?: 'UpdatePhaseStatusPayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'status'>
    )> }
  )> }
);

export type PhaseProgressRolesFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'budget' | 'budgetConsumed' | 'hours' | 'hoursLogged' | 'name'>
  & { roleTemplate: (
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id'>
  ) }
);

export type PhaseProgressDataFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'budgetConsumed' | 'budget' | 'beginDate' | 'endDate'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & PhaseProgressRolesFragment
  )> }
);

export type ProjectProgressDataFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'budget' | 'budgetConsumed' | 'beginDate' | 'endDate' | 'color'>
  & { orderedPhases: Array<(
    { __typename?: 'Phase' }
    & PhaseProgressDataFragment
  )> }
);

export type ProjectProgressQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectProgressQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectProgressDataFragment
  )> }
);

export type ProjectPermissionQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectPermissionQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canEditAllProjects' | 'canEditAssignedProjects'>
      ) }
    )> }
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'isShareable' | 'shareableHash' | 'status' | 'isArchived' | 'name' | 'invoicesCount' | 'beginDate' | 'totalFee' | 'isBillable' | 'color'>
    & { profiles?: Maybe<Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )>> }
  )> }
);

export type ProjectTemplateDataFragment = (
  { __typename?: 'ProjectTemplate' }
  & Pick<ProjectTemplate, 'beginDate' | 'endDate' | 'budget' | 'consultantsFee' | 'id' | 'name' | 'notes' | 'number' | 'slug' | 'totalFee' | 'totalConsultantsMarkup'>
  & { teamList?: Maybe<Array<(
    { __typename?: 'TeamList' }
    & TeamListFragment
  )>> }
);

export type ProjectTemplateQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectTemplateQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canEditAllProjects' | 'canEditAssignedProjects'>
      ) }
    )> }
  )>, projectTemplate?: Maybe<(
    { __typename?: 'ProjectTemplate' }
    & ProjectTemplateDataFragment
  )> }
);

export type UpdateProjectStatusMutationVariables = Exact<{
  input: UpdateProjectStatusInput;
}>;


export type UpdateProjectStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectStatus?: Maybe<(
    { __typename?: 'UpdateProjectStatusPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'status'>
    )> }
  )> }
);

export type ArchiveProjectMutationVariables = Exact<{
  input: ArchiveProjectInput;
}>;


export type ArchiveProjectMutation = (
  { __typename?: 'Mutation' }
  & { archiveProject?: Maybe<(
    { __typename?: 'ArchiveProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'isArchived'>
    )> }
  )> }
);

export type DuplicateProjectMutationVariables = Exact<{
  input: DuplicateProjectInput;
}>;


export type DuplicateProjectMutation = (
  { __typename?: 'Mutation' }
  & { duplicateProject?: Maybe<(
    { __typename?: 'DuplicateProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'slug'>
    )> }
  )> }
);

export type CreateTemplateFromProjectMutationVariables = Exact<{
  input: CreateTemplateFromProjectInput;
}>;


export type CreateTemplateFromProjectMutation = (
  { __typename?: 'Mutation' }
  & { createTemplateFromProject?: Maybe<(
    { __typename?: 'CreateTemplateFromProjectPayload' }
    & { template?: Maybe<(
      { __typename?: 'ProjectTemplate' }
      & Pick<ProjectTemplate, 'id' | 'slug'>
    )> }
  )> }
);

export type ProjectRetainerSummaryQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectRetainerSummaryQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'invoicesCount'>
    & { retainerSummary?: Maybe<(
      { __typename?: 'RetainerSummary' }
      & Pick<RetainerSummary, 'applied' | 'received' | 'remaining' | 'openReceived' | 'title' | 'openApplied'>
    )> }
  )> }
);

export type UserTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'dueDate' | 'id' | 'name'>
  & { assignees?: Maybe<Array<(
    { __typename?: 'Profile' }
    & PlanableAssigneeFragment
  )>> }
);

export type UserTasksQueryVariables = Exact<{
  filters?: Maybe<TaskFiltersInput>;
}>;


export type UserTasksQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { tasks: (
      { __typename?: 'TaskConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & UserTaskFragment
      )>>> }
    ) }
  )> }
);

export type ProjectTeamQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ProjectTeamQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { teamList?: Maybe<Array<(
      { __typename?: 'TeamList' }
      & TeamListFragment
    )>> }
  )> }
);

export type TeamListFragment = (
  { __typename?: 'TeamList' }
  & Pick<TeamList, 'rolesSentence' | 'name' | 'initials' | 'image' | 'slug' | 'id'>
);

export type ProjectTaskPhasePlanableMilestoneFragment = (
  { __typename?: 'Milestone' }
  & Pick<Milestone, 'id' | 'name'>
);

export type PlanablePhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'beginDate' | 'endDate' | 'abbreviation'>
  & { milestones?: Maybe<Array<(
    { __typename?: 'Milestone' }
    & ProjectTaskPhasePlanableMilestoneFragment
  )>> }
);

export type PlanableMilestoneFragment = (
  { __typename?: 'Milestone' }
  & Pick<Milestone, 'id' | 'name' | 'dueDate' | 'tasksCount' | 'description'>
  & { phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )> }
);

export type PlanableAssigneeFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'image' | 'initials' | 'firstNameLastInitial' | 'fullName'>
);

export type PlanableTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'dueDate' | 'name' | 'status'>
  & { assignees?: Maybe<Array<(
    { __typename?: 'Profile' }
    & PlanableAssigneeFragment
  )>>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
  )>, regularMilestone?: Maybe<(
    { __typename?: 'Milestone' }
    & PlanableMilestoneFragment
  )> }
);

export type ProjectMilestonesQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type ProjectMilestonesQuery = (
  { __typename?: 'Query' }
  & { planable?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'slug' | 'number' | 'color'>
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & PlanablePhaseFragment
    )> }
  ) | (
    { __typename?: 'ProjectTemplate' }
    & Pick<ProjectTemplate, 'id' | 'name' | 'slug' | 'number' | 'color'>
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & PlanablePhaseFragment
    )> }
  )> }
);

export type ProjectTasksQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type ProjectTasksQuery = (
  { __typename?: 'Query' }
  & { planable?: Maybe<(
    { __typename?: 'Project' }
    & { tasks?: Maybe<Array<(
      { __typename?: 'Task' }
      & PlanableTaskFragment
    )>> }
  ) | (
    { __typename?: 'ProjectTemplate' }
    & { tasks?: Maybe<Array<(
      { __typename?: 'Task' }
      & PlanableTaskFragment
    )>> }
  )> }
);

export type ProjectProfilesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectProfilesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { profiles?: Maybe<Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fullName'>
    )>> }
  )> }
);

export type CreateTaskMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  dueDate: Scalars['ISO8601Date'];
  milestoneId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phaseId: Scalars['ID'];
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask?: Maybe<(
    { __typename?: 'CreateTaskPayload' }
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'description' | 'dueDate'>
      & { assignees?: Maybe<Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>>, milestone?: Maybe<(
        { __typename?: 'InvoiceBuilderMilestone' }
        & Pick<InvoiceBuilderMilestone, 'id'>
      )>, phase?: Maybe<(
        { __typename?: 'Phase' }
        & Pick<Phase, 'id'>
      )> }
    )> }
  )> }
);

export type TaskShowCommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'description' | 'createdAt'>
  & { profile: (
    { __typename?: 'Profile' }
    & PlanableAssigneeFragment
  ) }
);

export type TaskShowFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'completedAt' | 'dueDate' | 'name' | 'status' | 'description'>
  & { assignees?: Maybe<Array<(
    { __typename?: 'Profile' }
    & PlanableAssigneeFragment
  )>>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'abbreviation' | 'name'>
  )>, milestone?: Maybe<(
    { __typename?: 'InvoiceBuilderMilestone' }
    & Pick<InvoiceBuilderMilestone, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'color'>
    & { profiles?: Maybe<Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )>> }
  )>, comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & TaskShowCommentFragment
  )>>, regularMilestone?: Maybe<(
    { __typename?: 'Milestone' }
    & PlanableMilestoneFragment
  )> }
);

export type TaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskQuery = (
  { __typename?: 'Query' }
  & { task: (
    { __typename?: 'Task' }
    & TaskShowFragment
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  description: Scalars['String'];
  taskId: Scalars['ID'];
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'CreateCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & TaskShowCommentFragment
    )> }
  )> }
);

export type PlanablePhaseOptionsQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type PlanablePhaseOptionsQuery = (
  { __typename?: 'Query' }
  & { planable?: Maybe<(
    { __typename?: 'Project' }
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
      & { milestones?: Maybe<Array<(
        { __typename?: 'Milestone' }
        & Pick<Milestone, 'id' | 'name'>
      )>> }
    )> }
  ) | (
    { __typename?: 'ProjectTemplate' }
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
      & { milestones?: Maybe<Array<(
        { __typename?: 'Milestone' }
        & Pick<Milestone, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type EditTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  milestoneId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phaseId?: Maybe<Scalars['ID']>;
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type EditTaskMutation = (
  { __typename?: 'Mutation' }
  & { editTask?: Maybe<(
    { __typename?: 'EditTaskPayload' }
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'dueDate' | 'name' | 'description'>
      & { assignees?: Maybe<Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>> }
    )> }
  )> }
);

export type EditCommentMutationVariables = Exact<{
  description: Scalars['String'];
  id: Scalars['ID'];
}>;


export type EditCommentMutation = (
  { __typename?: 'Mutation' }
  & { editComment?: Maybe<(
    { __typename?: 'EditCommentPayload' }
    & { comment: (
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'createdAt' | 'description'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      ) }
    ) }
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'DeleteCommentPayload' }
    & { comment: (
      { __typename?: 'Comment' }
      & Pick<Comment, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      ) }
    ) }
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask?: Maybe<(
    { __typename?: 'DeleteTaskPayload' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type DeleteMilestoneMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { deleteMilestone?: Maybe<(
    { __typename?: 'DeleteMilestonePayload' }
    & { milestone: (
      { __typename?: 'Milestone' }
      & Pick<Milestone, 'id'>
    ) }
  )> }
);

export type EditMilestoneMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['ISO8601Date']>;
  phaseId?: Maybe<Scalars['ID']>;
}>;


export type EditMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { editMilestone?: Maybe<(
    { __typename?: 'EditMilestonePayload' }
    & { milestone: (
      { __typename?: 'Milestone' }
      & Pick<Milestone, 'id' | 'name' | 'description' | 'dueDate'>
      & { phase?: Maybe<(
        { __typename?: 'Phase' }
        & Pick<Phase, 'id'>
      )> }
    ) }
  )> }
);

export type MilestoneShowFragment = (
  { __typename?: 'Milestone' }
  & Pick<Milestone, 'id' | 'name' | 'description' | 'dueDate' | 'tasksCount'>
  & { phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )> }
);

export type MilestoneQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MilestoneQuery = (
  { __typename?: 'Query' }
  & { milestone: (
    { __typename?: 'Milestone' }
    & MilestoneShowFragment
  ) }
);

export type ProfilesOptionsQueryVariables = Exact<{
  sortBy: ProfilesSortInput;
}>;


export type ProfilesOptionsQuery = (
  { __typename?: 'Query' }
  & { profiles: (
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fullName'>
    )>>> }
  ) }
);

export type ProjectTimesheetEntryGroupFragment = (
  { __typename?: 'EntryGroup' }
  & Pick<EntryGroup, 'id'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'id'>
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )>, role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>, entryRows?: Maybe<Array<(
    { __typename?: 'EntryRow' }
    & Pick<EntryRow, 'id'>
    & { activity?: Maybe<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id' | 'name'>
    )>, entries?: Maybe<Array<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'date' | 'hours' | 'id'>
    )>> }
  )>> }
);

export type ProjectTimesheetResultFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'name' | 'id' | 'slug'>
  )>, entryGroups?: Maybe<Array<(
    { __typename?: 'EntryGroup' }
    & ProjectTimesheetEntryGroupFragment
  )>> }
);

export type ProjectTimesheetsQueryVariables = Exact<{
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  isLate?: Maybe<Scalars['Boolean']>;
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  projectIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ProjectTimesheetsQuery = (
  { __typename?: 'Query' }
  & { searchableTimesheets?: Maybe<Array<(
    { __typename?: 'Timesheet' }
    & ProjectTimesheetResultFragment
  )>> }
);

export type ProjectTimesheetsProjectQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectTimesheetsProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type ExpensesExportClientsListQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpensesExportClientsListQuery = (
  { __typename?: 'Query' }
  & { allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'id' | 'displayName' | 'syncedToQbo'>
    & { qboCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
    )> }
  )>> }
);

export type TeamActivityReportQueryVariables = Exact<{
  filters: TeamActivityFiltersInput;
}>;


export type TeamActivityReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { teamActivity: (
      { __typename?: 'TeamActivityReport' }
      & { totals: Array<(
        { __typename?: 'TeamActivityProfileTotals' }
        & Pick<TeamActivityProfileTotals, 'billableHours' | 'nonBillableHours'>
        & { profile: (
          { __typename?: 'Profile' }
          & Pick<Profile, 'id' | 'slug' | 'firstNameLastInitial'>
        ) }
      )> }
    ) }
  ) }
);

export type TeamForecastProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'slug' | 'firstNameLastInitial' | 'image' | 'initials' | 'utilizationRate'>
);

export type TeamForecastRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  ), project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'color'>
  )> }
);

export type TeamForecastOverheadFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name' | 'availableHours'>
);

export type TeamForecastTimeOffRequestFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'hours'>
  & { timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name'>
  ) }
);

export type TeamForecastTimeOffHolidayFragment = (
  { __typename?: 'TimeOffHoliday' }
  & Pick<TimeOffHoliday, 'id' | 'name'>
);

export type TeamForecastReportQueryVariables = Exact<{
  filters: TeamForecastFiltersInput;
}>;


export type TeamForecastReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { teamForecast: (
      { __typename?: 'TeamForecastReport' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & TeamForecastProfileFragment
      )>, roles: Array<(
        { __typename?: 'Role' }
        & TeamForecastRoleFragment
      )>, overheads: Array<(
        { __typename?: 'Overhead' }
        & TeamForecastOverheadFragment
      )>, timeOffRequests: Array<(
        { __typename?: 'TimeOffRequest' }
        & TeamForecastTimeOffRequestFragment
      )>, timeOffHolidays: Array<(
        { __typename?: 'TimeOffHoliday' }
        & TeamForecastTimeOffHolidayFragment
      )>, allocationGroups: Array<(
        { __typename?: 'TeamForecastAllocationGroup' }
        & Pick<TeamForecastAllocationGroup, 'timesheetBeginDate' | 'profileId' | 'roleId' | 'overheadId' | 'timeOffRequestId' | 'timeOffHolidayId' | 'totalAllocation'>
      )> }
    ) }
  ), currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'defaultHoursPerWeek'>
      ) }
    )> }
  )> }
);

export type TeamForecastExportMutationVariables = Exact<{
  input: TeamForecastInput;
}>;


export type TeamForecastExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { teamForecast?: Maybe<(
      { __typename?: 'TeamForecastPayload' }
      & Pick<TeamForecastPayload, 'id'>
    )> }
  ) }
);

export type UpdateOrgTelephoneMutationVariables = Exact<{
  telephone: Scalars['String'];
}>;


export type UpdateOrgTelephoneMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationTelephone?: Maybe<(
    { __typename?: 'UpdateOrganizationTelephonePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'telephone'>
    )> }
  )> }
);

export type UpdateOrgWebsiteMutationVariables = Exact<{
  website: Scalars['String'];
}>;


export type UpdateOrgWebsiteMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationWebsite?: Maybe<(
    { __typename?: 'UpdateOrganizationWebsitePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'website'>
    )> }
  )> }
);

export type CostsReviewProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'isArchived' | 'name' | 'color' | 'number'>
);

export type CostsReviewProjectsQueryVariables = Exact<{
  includeArchived?: Maybe<Scalars['Boolean']>;
}>;


export type CostsReviewProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & CostsReviewProjectFragment
  )>> }
);

export type CategoriesFilterCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type CategoriesFilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & CategoriesFilterCategoryFragment
      )>> }
    )> }
  )> }
);

export type ProfilesFilterProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'initials' | 'name' | 'fullName' | 'slug'>
);

export type ProfilesFilterProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'slug' | 'status'>
  & { profiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & ProfilesFilterProfileFragment
  )>> }
);

export type ProfilesFilterOptionsQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  limitProfiles?: Maybe<Scalars['Int']>;
  selectedProfileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  loadSelectedProfiles: Scalars['Boolean'];
  projectsLimit?: Maybe<Scalars['Int']>;
  projectsMinProfilesCount?: Maybe<Scalars['Int']>;
  projectsSortBy?: Maybe<SearchableProjectsSortInput>;
}>;


export type ProfilesFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { suggestedProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProfilesFilterProjectFragment
  )>>, suggestedProfiles: (
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & ProfilesFilterProfileFragment
    )>>> }
  ), selectedProfiles: Maybe<(
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & ProfilesFilterProfileFragment
    )>>> }
  )> }
);

export type ProjectsFilterProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'slug' | 'status'>
  & { profiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
  )>> }
);

export type ProjectsFilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProjectsFilterProjectFragment
  )>> }
);

export type RebalanceModalRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'exclusivelyHasUserAllocations'>
  & { staffingEntryGroups: Array<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id'>
  )>, phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'endDate' | 'beginDate'>
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  )> }
);

export type RebalanceModalRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RebalanceModalRoleQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & RebalanceModalRoleFragment
  ) }
);

export type AllocationUpdaterEntryGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllocationUpdaterEntryGroupQuery = (
  { __typename?: 'Query' }
  & { entryGroup?: Maybe<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id' | 'allocation' | 'allocatedByUser'>
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    )>, phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
    )> }
  )> }
);

export type UpdateAllocationMutationVariables = Exact<{
  id: Scalars['ID'];
  allocation: Scalars['Float'];
}>;


export type UpdateAllocationMutation = (
  { __typename?: 'Mutation' }
  & { updateEntryGroupAllocation?: Maybe<(
    { __typename?: 'UpdateEntryGroupAllocationPayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id' | 'allocation' | 'allocatedByUser'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'hoursAllocated'>
      )>, timesheet: (
        { __typename?: 'Timesheet' }
        & Pick<Timesheet, 'id' | 'beginDate' | 'hoursAllocated' | 'overheadHoursAllocated'>
      ) }
    )> }
  )> }
);

export type CreateRoleAllocationMutationVariables = Exact<{
  input: CreateStaffingRoleAllocationInput;
}>;


export type CreateRoleAllocationMutation = (
  { __typename?: 'Mutation' }
  & { createStaffingRoleAllocation?: Maybe<(
    { __typename?: 'CreateStaffingRoleAllocationPayload' }
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'hoursAllocated'>
      & { profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
        & { timesheets: Array<(
          { __typename?: 'Timesheet' }
          & Pick<Timesheet, 'id' | 'beginDate' | 'hoursAllocated'>
          & { entryGroups?: Maybe<Array<(
            { __typename?: 'EntryGroup' }
            & Pick<EntryGroup, 'id' | 'allocatedByUser' | 'allocation'>
            & { role?: Maybe<(
              { __typename?: 'Role' }
              & Pick<Role, 'id'>
            )> }
          )>> }
        )> }
      )> }
    ) }
  )> }
);

export type TimeOffCardQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffCardQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ptoEnabled'>
      ) }
    )> }
  )> }
);

export type StaffingCurrentWeekTimesheetFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'beginDate' | 'timeOffHolidayHours' | 'timeOffRequestHours' | 'hoursAllocated' | 'hoursAllotted' | 'overheadHoursAllocated'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
  )>, entryGroups?: Maybe<Array<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id' | 'allocatedByUser' | 'allocation'>
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & Pick<Overhead, 'id' | 'name'>
    )>, role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    )>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'status'>
    )> }
  )>> }
);

export type StaffingCurrentWeekRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name' | 'hoursLogged' | 'hoursAllocated' | 'hours' | 'isOverstaffed' | 'exclusivelyHasUserAllocations'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  )>, phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'abbreviation' | 'beginDate' | 'endDate' | 'budget'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'number' | 'slug' | 'color'>
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )>> }
    )> }
  ) }
);

export type StaffingCurrentWeekFullProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id'>
  & { timesheets: Array<(
    { __typename?: 'Timesheet' }
    & StaffingCurrentWeekTimesheetFragment
  )>, staffingRoles: Array<(
    { __typename?: 'Role' }
    & StaffingCurrentWeekRoleFragment
  )> }
);

export type StaffingCurrentWeekProfileColumnQueryVariables = Exact<{
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type StaffingCurrentWeekProfileColumnQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & StaffingCurrentWeekFullProfileFragment
  ) }
);

export type StaffingCurrentWeekProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'initials' | 'fname' | 'lname' | 'name' | 'image'>
);

export type StaffingCurrentWeekPageQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffingCurrentWeekPageQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & StaffingCurrentWeekProfileFragment
      )> }
    )> }
  )> }
);

export type StaffingProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'color' | 'number' | 'status' | 'slug' | 'beginDate' | 'endDate'>
  & { overBudgetPhases?: Maybe<Array<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'endDate' | 'beginDate'>
  )>>, currentPhases?: Maybe<Array<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>> }
);

export type StaffingProjectsQueryVariables = Exact<{
  projectIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type StaffingProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & StaffingProjectFragment
  )>> }
);

export type StaffingEditPhaseDrawerProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'number' | 'color'>
);

export type StaffingEditPhaseDrawerRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name' | 'hours' | 'hoursLogged' | 'budget' | 'position'>
  & { roleTemplate: (
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id'>
  ), rateTable: (
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id'>
  ), profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'fname' | 'lname' | 'status'>
  )>, phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
  ) }
);

export type StaffingEditPhaseDrawerPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'beginDate' | 'endDate' | 'feeType' | 'abbreviation' | 'budget' | 'roleCount'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & StaffingEditPhaseDrawerProjectFragment
  )>, roles: Array<(
    { __typename?: 'Role' }
    & StaffingEditPhaseDrawerRoleFragment
  )> }
);

export type StaffingEditPhaseDrawerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StaffingEditPhaseDrawerQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & StaffingEditPhaseDrawerPhaseFragment
  ) }
);

export type StaffingUpdateRoleHoursMutationVariables = Exact<{
  input: UpdateRoleHoursInput;
}>;


export type StaffingUpdateRoleHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateRoleHours?: Maybe<(
    { __typename?: 'UpdateRoleHoursPayload' }
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'hours' | 'budget'>
    ) }
  )> }
);

export type ProjectStaffingProjectBudgetFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'budget' | 'budgetConsumed' | 'hoursAllocated' | 'budgetAllocated'>
);

export type ProjectStaffingProjectBudgetQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectStaffingProjectBudgetQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectStaffingProjectBudgetFragment
  )> }
);

export type ProjectStaffingPhaseRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name' | 'hours'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fname' | 'lname' | 'initials' | 'image' | 'name' | 'status'>
  )>, roleTemplate: (
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id'>
  ) }
);

export type ProjectStaffingPhaseRolesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectStaffingPhaseRolesQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & ProjectStaffingPhaseRoleFragment
    )> }
  ) }
);

export type UpdatePhaseDatesMutationVariables = Exact<{
  id: Scalars['ID'];
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
}>;


export type UpdatePhaseDatesMutation = (
  { __typename?: 'Mutation' }
  & { updatePhase?: Maybe<(
    { __typename?: 'UpdatePhasePayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'beginDate' | 'endDate'>
    )> }
  )> }
);

export type RemoveLinkedPhasesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveLinkedPhasesMutation = (
  { __typename?: 'Mutation' }
  & { removeLinkedPhases?: Maybe<(
    { __typename?: 'RemoveLinkedPhasesPayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
      & { linkedPhases: Array<(
        { __typename?: 'Phase' }
        & Pick<Phase, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ProjectStaffingPhaseRoleRowFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'status'>
    & { publicCompensations?: Maybe<Array<(
      { __typename?: 'PublicCompensation' }
      & Pick<PublicCompensation, 'id' | 'hoursPerWeek' | 'effectiveOn' | 'effectiveUntil'>
    )>>, weeklyHoursAllocated: Array<(
      { __typename?: 'StaffingCell' }
      & Pick<StaffingCell, 'timesheetBeginDate' | 'value'>
    )> }
  )>, staffingEntryGroups: Array<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id' | 'allocation' | 'allocatedByUser'>
    & { timesheet: (
      { __typename?: 'Timesheet' }
      & Pick<Timesheet, 'id' | 'beginDate'>
    ) }
  )> }
);

export type ProjectStaffingPhaseRoleRowsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectStaffingPhaseRoleRowsQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & ProjectStaffingPhaseRoleRowFragment
    )> }
  ) }
);

export type ProjectStaffingRoleCardRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id'>
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'budget' | 'feeType'>
  ) }
);

export type ProjectStaffingRoleCardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectStaffingRoleCardQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & RebalanceModalRoleFragment
  ) }
);

export type StaffingProjectPagePhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'beginDate' | 'abbreviation' | 'isOverstaffed' | 'status' | 'endDate' | 'position' | 'roleCount' | 'feeType' | 'budget'>
  & { linkedPhases: Array<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )>, weeklyBudgetAllocated: Array<(
    { __typename?: 'StaffingCell' }
    & Pick<StaffingCell, 'timesheetBeginDate' | 'value'>
  )>, phaseTemplate?: Maybe<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id'>
  )> }
);

export type StaffingProjectPageProjectQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type StaffingProjectPageProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'beginDate' | 'endDate' | 'hoursAllocated' | 'budgetAllocated'>
    & { phases: Array<(
      { __typename?: 'Phase' }
      & StaffingProjectPagePhaseFragment
    )> }
  )> }
);

export type DetailsHoverCardRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'hours' | 'hoursLogged' | 'hoursAllocated' | 'budget' | 'budgetAllocated' | 'budgetConsumed'>
);

export type DetailsHoverCardProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'hours' | 'hoursLogged' | 'hoursAllocated' | 'budget' | 'budgetAllocated' | 'budgetConsumed'>
);

export type DetailsHoverCardPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'hours' | 'hoursAllocated' | 'entryHoursCount' | 'budget' | 'budgetAllocated' | 'budgetConsumed'>
);

export type DetailsHoverCardProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailsHoverCardProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & DetailsHoverCardProjectFragment
  )> }
);

export type DetailsHoverCardPhaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailsHoverCardPhaseQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & DetailsHoverCardPhaseFragment
  ) }
);

export type DetailsHoverCardRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailsHoverCardRoleQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & DetailsHoverCardRoleFragment
  ) }
);

export type EmptyHoverCardRoleQueryVariables = Exact<{
  roleId: Scalars['ID'];
}>;


export type EmptyHoverCardRoleQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'slug'>
    )>, phase: (
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
    ) }
  ) }
);

export type DetailsHovercardRebalanceModalPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'endDate' | 'beginDate'>
);

export type DetailsHovercardRebalanceModalPhaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailsHovercardRebalanceModalPhaseQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & DetailsHovercardRebalanceModalPhaseFragment
  ) }
);

export type GlobalRebalanceAllocationsPreviewQueryVariables = Exact<{
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  dateRange: DateRangeInput;
}>;


export type GlobalRebalanceAllocationsPreviewQuery = (
  { __typename?: 'Query' }
  & { globalRebalanceAllocationsPreview: (
    { __typename?: 'GlobalRebalanceAllocationsPreview' }
    & Pick<GlobalRebalanceAllocationsPreview, 'phasesCount' | 'rolesCount' | 'existingJobBatchBid'>
  ) }
);

export type GlobalRebalancePhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'budget' | 'budgetAllocated' | 'budgetConsumed'>
);

export type GlobalRebalancePhaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GlobalRebalancePhaseQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & GlobalRebalancePhaseFragment
  ) }
);

export type AddTeamMemberProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type AddTeamMemberProfilesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName'>
      )> }
    )> }
  )> }
);

export type AddTeamMemberRolesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AddTeamMemberRolesQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
      & { profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>, roleTemplate: (
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id'>
      ), rateTable: (
        { __typename?: 'RateTable' }
        & Pick<RateTable, 'id'>
      ) }
    )> }
  ) }
);

export type AssignOverheadModalOverheadFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name'>
  & { timeOffPolicies?: Maybe<Array<(
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id'>
  )>> }
);

export type AssignOverheadModalOverheadsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssignOverheadModalOverheadsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { overheads: Array<(
        { __typename?: 'Overhead' }
        & AssignOverheadModalOverheadFragment
      )> }
    )> }
  ) }
);

export type BulkCreateOverheadEntryGroupsMutationVariables = Exact<{
  input: BulkCreateOverheadEntryGroupsInput;
}>;


export type BulkCreateOverheadEntryGroupsMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateEntryGroupsWithOverhead?: Maybe<(
    { __typename?: 'BulkCreateOverheadEntryGroupsPayload' }
    & { timesheets?: Maybe<Array<(
      { __typename?: 'Timesheet' }
      & Pick<Timesheet, 'id'>
    )>> }
  )> }
);

export type UpdateAssignedPhaseMutationVariables = Exact<{
  input: UpdatePhaseInput;
}>;


export type UpdateAssignedPhaseMutation = (
  { __typename?: 'Mutation' }
  & { updatePhase?: Maybe<(
    { __typename?: 'UpdatePhasePayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
    )> }
  )> }
);

export type AssignPhaseProjectSearchPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'status' | 'abbreviation' | 'name' | 'beginDate' | 'endDate'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type AssignPhaseProjectSearchProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'number' | 'color'>
  & { phases: Array<(
    { __typename?: 'Phase' }
    & AssignPhaseProjectSearchPhaseFragment
  )> }
);

export type AssignPhaseProjectSearchQueryVariables = Exact<{
  parameters: SearchableProjectsParametersInput;
}>;


export type AssignPhaseProjectSearchQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & AssignPhaseProjectSearchProjectFragment
  )>> }
);

export type RoleAndRateFieldsProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fname'>
  & { defaultRoleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id'>
  )>, defaultRateTable?: Maybe<(
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id'>
  )> }
);

export type RoleAndRateFieldsProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleAndRateFieldsProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & RoleAndRateFieldsProfileFragment
  ) }
);

export type EditTimeOffRequestMutationVariables = Exact<{
  input: EditTimeOffRequestInput;
}>;


export type EditTimeOffRequestMutation = (
  { __typename?: 'Mutation' }
  & { editTimeOffRequest?: Maybe<(
    { __typename?: 'EditTimeOffRequestPayload' }
    & { timeOffRequest: (
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    ) }
  )> }
);

export type MyTimeOffPolicyAssignmentFragment = (
  { __typename?: 'TimeOffPolicyAssignment' }
  & Pick<TimeOffPolicyAssignment, 'id' | 'balance'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
  ), timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name' | 'slug' | 'timeOffType'>
  ) }
);

export type MyTimeOffPolicyAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTimeOffPolicyAssignmentsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'slug'>
    & { timeOffPolicyAssignments: Array<(
      { __typename?: 'TimeOffPolicyAssignment' }
      & MyTimeOffPolicyAssignmentFragment
    )> }
  )> }
);

export type UpcomingRequestsTableRequestFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'status' | 'requestsBeginning' | 'requestsEnd' | 'hours' | 'note'>
  & { timeOffRequestDays: Array<(
    { __typename?: 'TimeOffRequestDay' }
    & Pick<TimeOffRequestDay, 'id' | 'date' | 'hours'>
  )>, adminProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )>, timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name' | 'timeOffType'>
  ), timeOffPolicyAssignment: (
    { __typename?: 'TimeOffPolicyAssignment' }
    & MyTimeOffPolicyAssignmentFragment
  ) }
);

export type UpcomingRequestsTableQueryVariables = Exact<{ [key: string]: never; }>;


export type UpcomingRequestsTableQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { timeOffRequests: Array<(
      { __typename?: 'TimeOffRequest' }
      & UpcomingRequestsTableRequestFragment
    )> }
  )> }
);

export type SpecificApproversQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecificApproversQuery = (
  { __typename?: 'Query' }
  & { profiles: (
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fullName'>
      & { policy?: Maybe<(
        { __typename?: 'Policy' }
        & { permissions: (
          { __typename?: 'Permissions' }
          & Pick<Permissions, 'canManagePto'>
        ) }
      )> }
    )>>> }
  ) }
);

export type BulkEditEntryProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'color' | 'number'>
);

export type BulkEditEntryPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'abbreviation' | 'name'>
);

export type BulkEditEntryRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  )> }
);

export type BulkEditEntryActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'name'>
);

export type BulkEditTimesheetEntryFragment = (
  { __typename?: 'Entry' }
  & Pick<Entry, 'id' | 'hours' | 'date' | 'rate'>
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & BulkEditEntryRoleFragment
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & BulkEditEntryProjectFragment
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & BulkEditEntryPhaseFragment
  )>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  )>, activity?: Maybe<(
    { __typename?: 'Activity' }
    & BulkEditEntryActivityFragment
  )>, timesheet: (
    { __typename?: 'Timesheet' }
    & Pick<Timesheet, 'id' | 'isLocked'>
  ) }
);

export type BulkEditTimesheetsQueryVariables = Exact<{
  filters: EntryFiltersInput;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  sortBy?: Maybe<EntriesSortInput>;
}>;


export type BulkEditTimesheetsQuery = (
  { __typename?: 'Query' }
  & { entries: (
    { __typename?: 'EntryConnection' }
    & Pick<EntryConnection, 'totalCount'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Entry' }
      & BulkEditTimesheetEntryFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  ) }
);

export type BulkEditFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type BulkEditFiltersQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )>>, projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & BulkEditEntryProjectFragment
  )>>, phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id' | 'name'>
  )>, roleTemplates?: Maybe<Array<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>> }
);

export type BulkEditAvailableFiltersQueryVariables = Exact<{
  filters: EntryFiltersInput;
}>;


export type BulkEditAvailableFiltersQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { bulkEdit: (
      { __typename?: 'TimeLog' }
      & { availableFilters: (
        { __typename?: 'AvailableFilters' }
        & { phases: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, profiles: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )> }
      ) }
    ) }
  ) }
);

export type BulkEditAvailablePhasesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type BulkEditAvailablePhasesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { phases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name' | 'abbreviation'>
    )> }
  )> }
);

export type BulkEditEntryInformationQueryVariables = Exact<{
  filters: EntryFiltersInput;
}>;


export type BulkEditEntryInformationQuery = (
  { __typename?: 'Query' }
  & { entries: (
    { __typename?: 'EntryConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id'>
      & { profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>, timesheet: (
        { __typename?: 'Timesheet' }
        & Pick<Timesheet, 'id' | 'isLocked'>
      ) }
    )>>> }
  ) }
);

export type BulkEditPhaseInformationQueryVariables = Exact<{
  phaseId: Scalars['ID'];
}>;


export type BulkEditPhaseInformationQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
      & { profiles?: Maybe<Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>> }
    )> }
  ) }
);

export type BulkEditEntryRoleTemplateFragment = (
  { __typename?: 'RoleTemplate' }
  & Pick<RoleTemplate, 'id' | 'name'>
  & { rateTables: Array<(
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id' | 'name'>
    & { currentRate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'id' | 'amount'>
    ) }
  )> }
);

export type BulkEditProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BulkEditProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & BulkEditEntryProjectFragment
  )> }
);

export type BulkEditPhaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BulkEditPhaseQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & BulkEditEntryPhaseFragment
  ) }
);

export type BulkEditRoleTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BulkEditRoleTemplateQuery = (
  { __typename?: 'Query' }
  & { roleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & BulkEditEntryRoleTemplateFragment
  )> }
);

export type BulkEditActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type BulkEditActivitiesQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & BulkEditEntryActivityFragment
  )>> }
);

export type BulkEditEntriesToTargetPhaseMutationVariables = Exact<{
  sourceEntryIds: Array<Scalars['ID']> | Scalars['ID'];
  targetPhaseId: Scalars['ID'];
}>;


export type BulkEditEntriesToTargetPhaseMutation = (
  { __typename?: 'Mutation' }
  & { bulkEditEntriesToTargetPhase?: Maybe<(
    { __typename?: 'BulkEditEntriesToTargetPhasePayload' }
    & { entries: Array<(
      { __typename?: 'Entry' }
      & BulkEditTimesheetEntryFragment
    )> }
  )> }
);

export type BulkEditEntriesToTargetActivityMutationVariables = Exact<{
  sourceEntryIds: Array<Scalars['ID']> | Scalars['ID'];
  targetActivityId: Scalars['ID'];
}>;


export type BulkEditEntriesToTargetActivityMutation = (
  { __typename?: 'Mutation' }
  & { bulkEditEntriesToTargetActivity?: Maybe<(
    { __typename?: 'BulkEditEntriesToTargetActivityPayload' }
    & { entries: Array<(
      { __typename?: 'Entry' }
      & BulkEditTimesheetEntryFragment
    )> }
  )> }
);

export type BulkEditEntriesToTargetRoleTemplateMutationVariables = Exact<{
  sourceEntryIds: Array<Scalars['ID']> | Scalars['ID'];
  targetRoleTemplateId: Scalars['ID'];
  targetRateTableId: Scalars['ID'];
}>;


export type BulkEditEntriesToTargetRoleTemplateMutation = (
  { __typename?: 'Mutation' }
  & { bulkEditEntriesToTargetRoleTemplate?: Maybe<(
    { __typename?: 'BulkEditEntriesToTargetRoleTemplatePayload' }
    & { entries: Array<(
      { __typename?: 'Entry' }
      & BulkEditTimesheetEntryFragment
    )> }
  )> }
);

export type CreateExpenseV2MutationVariables = Exact<{
  input: CreateExpenseV2Input;
}>;


export type CreateExpenseV2Mutation = (
  { __typename?: 'Mutation' }
  & { createExpenseV2?: Maybe<(
    { __typename?: 'CreateExpenseV2Payload' }
    & { expense?: Maybe<(
      { __typename?: 'Expense' }
      & Pick<Expense, 'id'>
    )> }
  )> }
);

export type ExpensesProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'color' | 'number'>
  & { phases: Array<(
    { __typename?: 'Phase' }
    & ExpensePhaseInfoFragment
  )> }
);

export type ExpensePhaseInfoFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'name' | 'id' | 'abbreviation'>
);

export type ExpensesProjectPhasesSearchQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
}>;


export type ExpensesProjectPhasesSearchQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ExpensesProjectFragment
  )>> }
);

export type ExpensesFormOverheadsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpensesFormOverheadsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { overheads: Array<(
        { __typename?: 'Overhead' }
        & Pick<Overhead, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ExpensesProjectPhasesQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type ExpensesProjectPhasesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { phases: Array<(
      { __typename?: 'Phase' }
      & ExpensePhaseInfoFragment
    )> }
  )> }
);

export type ExpensePhaseInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExpensePhaseInfoQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
    & ExpensePhaseInfoFragment
  ) }
);

export type ExpensesRecentProjectsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<SearchableProjectsSortInput>;
}>;


export type ExpensesRecentProjectsQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ExpensesProjectFragment
  )>> }
);

export type OrganizationTimesheetFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'updatedAt' | 'beginDate' | 'isSubmitted' | 'totalHours' | 'isLocked' | 'isLockable'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & OrganizationTimesheetProfileFragment
  )> }
);

export type OrganizationTimesheetProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'slug' | 'image' | 'initials' | 'fname' | 'lname'>
);

export type OrganizationTimesheetsQueryVariables = Exact<{
  beginDate: Scalars['ISO8601Date'];
}>;


export type OrganizationTimesheetsQuery = (
  { __typename?: 'Query' }
  & { searchableTimesheets?: Maybe<Array<(
    { __typename?: 'Timesheet' }
    & OrganizationTimesheetFragment
  )>> }
);

export type ProfilePastTimesheetFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'updatedAt' | 'beginDate' | 'isSubmitted' | 'totalHours' | 'isLocked' | 'isLockable'>
);

export type PastTimesheetProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'slug' | 'image' | 'fullName' | 'initials'>
);

export type ProfilePastTimesheetsQueryVariables = Exact<{
  slug: Scalars['String'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type ProfilePastTimesheetsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { timesheets: Array<(
      { __typename?: 'Timesheet' }
      & ProfilePastTimesheetFragment
    )> }
    & PastTimesheetProfileFragment
  ) }
);

export type ExportTimesheetMutationVariables = Exact<{
  input: TimesheetExportInput;
}>;


export type ExportTimesheetMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { timesheet?: Maybe<(
      { __typename?: 'TimesheetExportPayload' }
      & Pick<TimesheetExportPayload, 'id'>
    )> }
  ) }
);

export type DeletionModalEntryGroupQueryVariables = Exact<{
  entryGroupId: Scalars['ID'];
}>;


export type DeletionModalEntryGroupQuery = (
  { __typename?: 'Query' }
  & { entryGroup?: Maybe<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id' | 'allocation'>
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & Pick<Overhead, 'id' | 'name'>
    )>, project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )>, entries?: Maybe<Array<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'hours' | 'notes'>
      & { expenses?: Maybe<Array<(
        { __typename?: 'Expense' }
        & Pick<Expense, 'id'>
      )>> }
    )>> }
  )> }
);

export type DeleteEntryGroupMutationVariables = Exact<{
  entryGroupId: Scalars['ID'];
}>;


export type DeleteEntryGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteEntryGroup?: Maybe<(
    { __typename?: 'DeleteEntryGroupPayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id'>
    )> }
  )> }
);

export type UpdateEntryMutationVariables = Exact<{
  input: UpdateEntryInput;
}>;


export type UpdateEntryMutation = (
  { __typename?: 'Mutation' }
  & { updateEntry?: Maybe<(
    { __typename?: 'UpdateEntryPayload' }
    & { entry?: Maybe<(
      { __typename?: 'Entry' }
      & TimesheetEntryFragment
    )>, entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & Pick<EntryRow, 'id'>
      & { entries?: Maybe<Array<(
        { __typename?: 'Entry' }
        & TimesheetEntryFragment
      )>> }
    )> }
  )> }
);

export type CreateEntryWithOverheadMutationVariables = Exact<{
  input: CreateEntryWithOverheadInput;
}>;


export type CreateEntryWithOverheadMutation = (
  { __typename?: 'Mutation' }
  & { createEntryWithOverhead?: Maybe<(
    { __typename?: 'CreateEntryWithOverheadPayload' }
    & { entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & Pick<EntryRow, 'id'>
      & { entries?: Maybe<Array<(
        { __typename?: 'Entry' }
        & TimesheetEntryFragment
      )>> }
    )> }
  )> }
);

export type UpsertEntryMutationVariables = Exact<{
  input: UpsertEntryInput;
}>;


export type UpsertEntryMutation = (
  { __typename?: 'Mutation' }
  & { upsertEntry?: Maybe<(
    { __typename?: 'UpsertEntryPayload' }
    & { entry?: Maybe<(
      { __typename?: 'Entry' }
      & TimesheetEntryFragment
    )>, entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & Pick<EntryRow, 'id'>
      & { entries?: Maybe<Array<(
        { __typename?: 'Entry' }
        & TimesheetEntryFragment
      )>> }
    )> }
  )> }
);

export type OrganizationTimesheetNotesRequiredQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationTimesheetNotesRequiredQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'timesheetNotesReminder'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol'>
      ) }
    )> }
  )> }
);

export type UpsertEntryWithNotesMutationVariables = Exact<{
  entryId?: Maybe<Scalars['ID']>;
  entryRowId: Scalars['ID'];
  notes: Scalars['String'];
  date: Scalars['ISO8601Date'];
}>;


export type UpsertEntryWithNotesMutation = (
  { __typename?: 'Mutation' }
  & { upsertEntryWithNotes?: Maybe<(
    { __typename?: 'UpsertEntryWithNotesPayload' }
    & { entry: (
      { __typename?: 'Entry' }
      & TimesheetEntryFragment
    ) }
  )> }
);

export type CreateEntryRowMutationVariables = Exact<{
  input: CreateEntryRowInput;
}>;


export type CreateEntryRowMutation = (
  { __typename?: 'Mutation' }
  & { createEntryRow?: Maybe<(
    { __typename?: 'CreateEntryRowPayload' }
    & { entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & TimesheetEntryRowFragment
    )> }
  )> }
);

export type UpdateEntryRowActivityMutationVariables = Exact<{
  input: UpdateEntryRowActivityInput;
}>;


export type UpdateEntryRowActivityMutation = (
  { __typename?: 'Mutation' }
  & { updateEntryRowActivity?: Maybe<(
    { __typename?: 'UpdateEntryRowActivityPayload' }
    & { entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & TimesheetEntryRowFragment
    )> }
  )> }
);

export type DeletionModalEntryRowQueryVariables = Exact<{
  entryRowId: Scalars['ID'];
}>;


export type DeletionModalEntryRowQuery = (
  { __typename?: 'Query' }
  & { entryRow?: Maybe<(
    { __typename?: 'EntryRow' }
    & Pick<EntryRow, 'id'>
    & { entries?: Maybe<Array<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'id' | 'hours' | 'notes'>
      & { expenses?: Maybe<Array<(
        { __typename?: 'Expense' }
        & Pick<Expense, 'id'>
      )>> }
    )>>, entryGroup: (
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id' | 'allocation'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )>, entryRows?: Maybe<Array<(
        { __typename?: 'EntryRow' }
        & Pick<EntryRow, 'id'>
      )>> }
    ) }
  )> }
);

export type DeleteEntryRowMutationVariables = Exact<{
  entryRowId: Scalars['ID'];
}>;


export type DeleteEntryRowMutation = (
  { __typename?: 'Mutation' }
  & { deleteEntryRow?: Maybe<(
    { __typename?: 'DeleteEntryRowPayload' }
    & { entryRow?: Maybe<(
      { __typename?: 'EntryRow' }
      & Pick<EntryRow, 'id'>
    )> }
  )> }
);

export type TimesheetEntryGroupPhasesQueryVariables = Exact<{
  phaseId: Scalars['ID'];
  activeDateRange: DateRangeInput;
}>;


export type TimesheetEntryGroupPhasesQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & { phases?: Maybe<Array<(
        { __typename?: 'Phase' }
        & TimesheetsSearchablePhaseFragment
      )>> }
      & TimesheetsSearchableProjectFragment
    )> }
  ) }
);

export type UpdateEntryGroupPhaseMutationVariables = Exact<{
  input: UpdateEntryGroupPhaseInput;
}>;


export type UpdateEntryGroupPhaseMutation = (
  { __typename?: 'Mutation' }
  & { updateEntryGroupPhase?: Maybe<(
    { __typename?: 'UpdateEntryGroupPhasePayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & TimesheetEntryGroupFragment
    )> }
  )> }
);

export type UpdateExpenseMutationVariables = Exact<{
  input: UpdateExpenseInput;
}>;


export type UpdateExpenseMutation = (
  { __typename?: 'Mutation' }
  & { updateExpense?: Maybe<(
    { __typename?: 'UpdateExpensePayload' }
    & { expense?: Maybe<(
      { __typename?: 'Expense' }
      & TimesheetExpenseFragment
    )> }
  )> }
);

export type DeleteExpenseMutationVariables = Exact<{
  input: DeleteExpenseInput;
}>;


export type DeleteExpenseMutation = (
  { __typename?: 'Mutation' }
  & { deleteExpense?: Maybe<(
    { __typename?: 'DeleteExpensePayload' }
    & { expense?: Maybe<(
      { __typename?: 'Expense' }
      & TimesheetExpenseFragment
    )> }
  )> }
);

export type ExpenseCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpenseCategoriesQuery = (
  { __typename?: 'Query' }
  & { expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
  )> }
);

export type TimesheetsSearchableProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'number' | 'status' | 'color'>
);

export type TimesheetsSearchablePhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'status' | 'abbreviation'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'phaseRate'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'slug'>
    )> }
  )> }
);

export type TimesheetTimeOffEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type TimesheetTimeOffEnabledQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ptoEnabled'>
      ) }
    )> }
  )> }
);

export type TimesheetSelectableOverheadsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type TimesheetSelectableOverheadsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { overheads: Array<(
        { __typename?: 'Overhead' }
        & Pick<Overhead, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type TimesheetSelectableProjectPhasesQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  activeDateRange: DateRangeInput;
}>;


export type TimesheetSelectableProjectPhasesQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & { phases?: Maybe<Array<(
      { __typename?: 'Phase' }
      & TimesheetsSearchablePhaseFragment
    )>> }
    & TimesheetsSearchableProjectFragment
  )>> }
);

export type CreateEntryGroupWithOverheadMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  overheadId: Scalars['ID'];
}>;


export type CreateEntryGroupWithOverheadMutation = (
  { __typename?: 'Mutation' }
  & { createEntryGroupWithOverhead?: Maybe<(
    { __typename?: 'CreateEntryGroupWithOverheadPayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & TimesheetEntryGroupFragment
    )> }
  )> }
);

export type CreateEntryGroupWithPhaseMutationVariables = Exact<{
  timesheetId: Scalars['ID'];
  phaseId: Scalars['ID'];
  roleId?: Maybe<Scalars['ID']>;
}>;


export type CreateEntryGroupWithPhaseMutation = (
  { __typename?: 'Mutation' }
  & { createEntryGroupWithProject?: Maybe<(
    { __typename?: 'CreateEntryGroupPayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & TimesheetEntryGroupFragment
    )> }
  )> }
);

export type TimesheetExpenseFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id' | 'amount' | 'attachment' | 'date' | 'isBillable' | 'isReimbursable' | 'note' | 'onOpenInvoice'>
  & { category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
  ), project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type TimesheetEntryFragment = (
  { __typename?: 'Entry' }
  & Pick<Entry, 'id' | 'date' | 'hours' | 'notes'>
  & { expenses?: Maybe<Array<(
    { __typename?: 'Expense' }
    & TimesheetExpenseFragment
  )>> }
);

export type TimesheetProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'status' | 'color' | 'isArchived' | 'number' | 'name' | 'slug'>
);

export type TimesheetOverheadFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name'>
);

export type TimesheetEntryRowFragment = (
  { __typename?: 'EntryRow' }
  & Pick<EntryRow, 'id'>
  & { activity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )>, entries?: Maybe<Array<(
    { __typename?: 'Entry' }
    & TimesheetEntryFragment
  )>> }
);

export type TimesheetEntryGroupFragment = (
  { __typename?: 'EntryGroup' }
  & Pick<EntryGroup, 'id' | 'allocation' | 'allocatedByUser'>
  & { entryRows?: Maybe<Array<(
    { __typename?: 'EntryRow' }
    & TimesheetEntryRowFragment
  )>>, project?: Maybe<(
    { __typename?: 'Project' }
    & TimesheetProjectFragment
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'status' | 'abbreviation' | 'name'>
  )>, role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'phaseRate'>
  )>, overhead?: Maybe<(
    { __typename?: 'Overhead' }
    & TimesheetOverheadFragment
  )> }
);

export type TimesheetTimeOffHolidayFragment = (
  { __typename?: 'TimeOffHoliday' }
  & Pick<TimeOffHoliday, 'id' | 'name' | 'hours' | 'date'>
  & { timeOffHolidayPolicy: (
    { __typename?: 'TimeOffHolidayPolicy' }
    & Pick<TimeOffHolidayPolicy, 'id' | 'name'>
  ) }
);

export type TimesheetDetailFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'beginDate' | 'isLockable' | 'isLocked' | 'isUnlocked' | 'isSubmitted'>
  & { timeOffHolidayDays?: Maybe<Array<(
    { __typename?: 'TimeOffHoliday' }
    & TimesheetTimeOffHolidayFragment
  )>>, entryGroups?: Maybe<Array<(
    { __typename?: 'EntryGroup' }
    & TimesheetEntryGroupFragment
  )>>, backendFilteredEntryGroups?: Maybe<Array<(
    { __typename?: 'EntryGroup' }
    & Pick<EntryGroup, 'id'>
  )>> }
);

export type TimesheetDetailQueryVariables = Exact<{
  profileSlug: Scalars['String'];
  beginDate: Scalars['ISO8601Date'];
}>;


export type TimesheetDetailQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { timesheet: (
      { __typename?: 'Timesheet' }
      & TimesheetDetailFragment
    ) }
  ) }
);

export type OrganizationActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationActivitiesQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )>> }
);

export type UpdateTimesheetMutationVariables = Exact<{
  input: UpdateTimesheetInput;
}>;


export type UpdateTimesheetMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheet?: Maybe<(
    { __typename?: 'UpdateTimesheetPayload' }
    & { timesheet?: Maybe<(
      { __typename?: 'Timesheet' }
      & TimesheetDetailFragment
    )> }
  )> }
);

export type TimesheetEntryRowOrgSettingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TimesheetEntryRowOrgSettingQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'activityRequired'>
      ) }
    )> }
  )>, entryRow?: Maybe<(
    { __typename?: 'EntryRow' }
    & Pick<EntryRow, 'id'>
    & { activity?: Maybe<(
      { __typename?: 'Activity' }
      & Pick<Activity, 'id'>
    )>, entryGroup: (
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id'>
      & { overhead?: Maybe<(
        { __typename?: 'Overhead' }
        & Pick<Overhead, 'id'>
      )> }
    ) }
  )> }
);

export type TimesheetTimeOffRequestFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'status'>
  & { timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name'>
  ), timeOffRequestDays: Array<(
    { __typename?: 'TimeOffRequestDay' }
    & Pick<TimeOffRequestDay, 'id' | 'date' | 'hours'>
  )> }
);

export type TimesheetTimeOffQueryVariables = Exact<{
  profileSlug: Scalars['String'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type TimesheetTimeOffQuery = (
  { __typename?: 'Query' }
  & { timeOffRequests: (
    { __typename?: 'TimeOffRequestConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TimeOffRequest' }
      & TimesheetTimeOffRequestFragment
    )>>> }
  ) }
);

export type ProfileTimesheetsCsvMutationVariables = Exact<{
  profileSlug?: Maybe<Scalars['String']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  includeNotes: Scalars['Boolean'];
  csvFormat: CsvFormat;
}>;


export type ProfileTimesheetsCsvMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { profileTimesheets?: Maybe<(
      { __typename?: 'ProfileTimesheetsExportPayload' }
      & Pick<ProfileTimesheetsExportPayload, 'id'>
    )> }
  ) }
);

export type OrganizationTimesheetsCsvMutationVariables = Exact<{
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  includeNotes: Scalars['Boolean'];
  csvFormat: CsvFormat;
}>;


export type OrganizationTimesheetsCsvMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { organizationTimesheets?: Maybe<(
      { __typename?: 'OrganizationTimesheetsExportPayload' }
      & Pick<OrganizationTimesheetsExportPayload, 'id'>
    )> }
  ) }
);

export type OrganizationTimesheetsAjeraCsvMutationVariables = Exact<{
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type OrganizationTimesheetsAjeraCsvMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { organizationTimesheetsAjera?: Maybe<(
      { __typename?: 'OrganizationTimesheetsExportAjeraPayload' }
      & Pick<OrganizationTimesheetsExportAjeraPayload, 'id'>
    )> }
  ) }
);

export type LockTimesheetMutationVariables = Exact<{
  id: Scalars['ID'];
  isUnlocked: Scalars['Boolean'];
}>;


export type LockTimesheetMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheet?: Maybe<(
    { __typename?: 'UpdateTimesheetPayload' }
    & { timesheet?: Maybe<(
      { __typename?: 'Timesheet' }
      & Pick<Timesheet, 'id' | 'isLocked' | 'isLockable'>
    )> }
  )> }
);

export type UpdateOrganizationTrialMutationVariables = Exact<{
  id: Scalars['ID'];
  trialEndDate: Scalars['String'];
}>;


export type UpdateOrganizationTrialMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { updateOrganizationTrial?: Maybe<(
      { __typename?: 'UpdateOrganizationTrialMutationPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'trialEndDate'>
      )> }
    )> }
  ) }
);

export type UpdateOrganizationStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: OrganizationStatusEnum;
}>;


export type UpdateOrganizationStatusMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { updateOrganizationStatus?: Maybe<(
      { __typename?: 'UpdateOrganizationStatusMutationPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'status'>
      )> }
    )> }
  ) }
);

export type UpdateOrganizationQualifiedMutationVariables = Exact<{
  id: Scalars['ID'];
  isQualified: Scalars['Boolean'];
}>;


export type UpdateOrganizationQualifiedMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { updateOrganizationQualified?: Maybe<(
      { __typename?: 'UpdateOrganizationQualifiedMutationPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'isQualified'>
      )> }
    )> }
  ) }
);

export type ResetDemoMutationVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type ResetDemoMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { resetDemo?: Maybe<(
      { __typename?: 'ResetDemoPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id'>
      )> }
    )> }
  ) }
);

export type CloneShowcaseTemplateMutationVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type CloneShowcaseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { cloneShowcaseTemplate?: Maybe<(
      { __typename?: 'CloneShowcaseTemplatePayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id'>
      )> }
    )> }
  ) }
);

export type ConfirmUserEmailMutationVariables = Exact<{
  profileId?: Maybe<Scalars['ID']>;
}>;


export type ConfirmUserEmailMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { confirmUserEmail?: Maybe<(
      { __typename?: 'ConfirmUserEmailPayload' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  ) }
);

export type GenerateUserInviteLinkMutationVariables = Exact<{
  profileId?: Maybe<Scalars['ID']>;
}>;


export type GenerateUserInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { generateUserInviteLink?: Maybe<(
      { __typename?: 'GenerateUserInviteLinkPayload' }
      & Pick<GenerateUserInviteLinkPayload, 'inviteLink'>
    )> }
  ) }
);

export type ToggleUserSuperadminStatusMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ToggleUserSuperadminStatusMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { toggleUserSuperadminStatus?: Maybe<(
      { __typename?: 'UpdateUserSuperadminStatusPayload' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  ) }
);

export type CreateAccountMutationVariables = Exact<{
  email: Scalars['String'];
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  subIndustry?: Maybe<Scalars['String']>;
}>;


export type CreateAccountMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { createAccount?: Maybe<(
      { __typename?: 'CreateAccountMutationPayload' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ), organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id'>
      ), profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      ) }
    )> }
  ) }
);

export type AddOrganizationToPayrollMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  legalName: Scalars['String'];
  address: PayrollAddressInput;
}>;


export type AddOrganizationToPayrollMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollCompany?: Maybe<(
    { __typename?: 'CreatePayrollCompanyPayload' }
    & { company: (
      { __typename?: 'Company' }
      & { info: (
        { __typename?: 'PayrollCompanyInfo' }
        & Pick<PayrollCompanyInfo, 'id'>
      ) }
    ) }
  )> }
);

export type SuperAdminUsersQueryVariables = Exact<{
  isAdmin?: Maybe<Scalars['Boolean']>;
}>;


export type SuperAdminUsersQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'Admin' }
    & { recentUsers: Array<(
      { __typename?: 'User' }
      & SuperAdminFieldsFragment
    )> }
  ) }
);

export type SuperAdminFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'fullName' | 'slug' | 'email' | 'isAdmin' | 'createdAt'>
);

export type ConnectSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  planType: Scalars['String'];
  planName: Scalars['String'];
  stripeId: Scalars['String'];
}>;


export type ConnectSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { connectSubscription?: Maybe<(
      { __typename?: 'ConnectSubscriptionPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id'>
      )> }
    )> }
  ) }
);

export type CreateEmailLinkAndSendToUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type CreateEmailLinkAndSendToUserMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { createEmailLinkAndSendToUser?: Maybe<(
      { __typename?: 'CreateEmailLinkAndSendToUserPayload' }
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )> }
  ) }
);

export type AjeraSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type AjeraSettingsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ajeraEnabled'>
      ) }
    )> }
  )> }
);

export type ProjectPhasesForAjeraQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectPhasesForAjeraQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
      & { ajeraIdentifier?: Maybe<(
        { __typename?: 'AjeraIdentifier' }
        & AjeraFieldsFragment
      )> }
    )> }
  )> }
);

export type ClientAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
);

export type ClientProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'slug' | 'number' | 'name' | 'status'>
);

export type ClientInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'slug' | 'total' | 'issueDate' | 'dueDate' | 'status' | 'invoiceVersion'>
);

export type ClientResultFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'active' | 'syncedToQbo' | 'department' | 'displayName' | 'email' | 'fname' | 'fullyQualifiedName' | 'id' | 'lname' | 'name' | 'organizationName' | 'phone' | 'quickbooksId' | 'slug' | 'title'>
  & { qboCustomer?: Maybe<(
    { __typename?: 'QBOCustomer' }
    & Pick<QboCustomer, 'id' | 'quickbooksId'>
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & ClientAddressFragment
  )>, projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ClientProjectFragment
  )>>, invoices?: Maybe<Array<(
    { __typename?: 'Invoice' }
    & ClientInvoiceFragment
  )>> }
);

export type DirectoryClientFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'active' | 'syncedToQbo' | 'displayName' | 'email' | 'fname' | 'fullName' | 'id' | 'lname' | 'organizationName' | 'phone' | 'slug' | 'title'>
);

export type ClientQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type ClientQuery = (
  { __typename?: 'Query' }
  & { client: (
    { __typename?: 'Client' }
    & ClientResultFragment
  ) }
);

export type UpdateClientMutationVariables = Exact<{
  data: UpdateClientInput;
}>;


export type UpdateClientMutation = (
  { __typename?: 'Mutation' }
  & { updateClient?: Maybe<(
    { __typename?: 'UpdateClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type AddClientMutationVariables = Exact<{
  data: AddClientInput;
}>;


export type AddClientMutation = (
  { __typename?: 'Mutation' }
  & { addClient?: Maybe<(
    { __typename?: 'AddClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type DeactivateClientMutationVariables = Exact<{
  data: DeactivateClientInput;
}>;


export type DeactivateClientMutation = (
  { __typename?: 'Mutation' }
  & { deactivateClient?: Maybe<(
    { __typename?: 'DeactivateClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type ReactivateClientMutationVariables = Exact<{
  data: ReactivateClientInput;
}>;


export type ReactivateClientMutation = (
  { __typename?: 'Mutation' }
  & { reactivateClient?: Maybe<(
    { __typename?: 'ReactivateClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type DeleteClientMutationVariables = Exact<{
  data: DeleteClientInput;
}>;


export type DeleteClientMutation = (
  { __typename?: 'Mutation' }
  & { deleteClient?: Maybe<(
    { __typename?: 'DeleteClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type ProcessQboCustomersMutationVariables = Exact<{
  input: ProcessQboCustomersInput;
}>;


export type ProcessQboCustomersMutation = (
  { __typename?: 'Mutation' }
  & { processQboCustomers?: Maybe<(
    { __typename?: 'ProcessQBOCustomersPayload' }
    & { qboCustomers?: Maybe<Array<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
    )>> }
  )> }
);

export type DetachClientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetachClientMutation = (
  { __typename?: 'Mutation' }
  & { detachClient?: Maybe<(
    { __typename?: 'DetachClientPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id'>
      & { qboCustomer?: Maybe<(
        { __typename?: 'QBOCustomer' }
        & Pick<QboCustomer, 'id'>
      )> }
    )> }
  )> }
);

export type FlagsFragment = (
  { __typename?: 'FeatureFlags' }
  & Pick<FeatureFlags, 'ajeraRework' | 'exportMigrationData' | 'invoicesV2' | 'payrollEnabled' | 'usesAccounting' | 'bqeExport' | 'timeOffHolidays' | 'qboExpenses' | 'highValueInsights' | 'killInvoicesV1' | 'weeklyStaffingNextjs' | 'staffingProjectView' | 'insightsDashboardV1' | 'renewalPriceTesting' | 'passConvenienceFeesV2' | 'roleRateSuperadmin' | 'stripeSetupReminders' | 'hideEntryGroupsFromCompletedProj' | 'bulkEditTimesheets' | 'modifyClient' | 'expensesV2' | 'consultantsRework' | 'expenseRates'>
);

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagsQuery = (
  { __typename?: 'Query' }
  & { featureFlags: (
    { __typename?: 'FeatureFlags' }
    & FlagsFragment
  ) }
);

export type GlobalOrganizationSettingsFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'timeZone' | 'overheadMultiplier' | 'emailSenderName' | 'emailReplyToAddress' | 'connectedToQbo' | 'isSubscribed' | 'invoiceFooter'>
  & { currency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'symbol' | 'code' | 'decimalDigits'>
  ), address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
  )>, locale: (
    { __typename?: 'Locale' }
    & Pick<Locale, 'id' | 'name' | 'tag'>
  ), settings: (
    { __typename?: 'OrganizationSetting' }
    & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled' | 'defaultRealizationRate' | 'defaultUtilizationRate' | 'defaultConsultantMarkup' | 'unitOfMeasurement' | 'ptoEnabled' | 'timeOffToggledAt' | 'applyUtilizationRateToExistingProfiles' | 'defaultHoursPerWeek' | 'yearlyWorkingHours' | 'costsImportStartDate'>
  ) }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & GlobalOrganizationSettingsFragment
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'logo'>
    )> }
    & CurrentUserFragment
  )>, currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fname' | 'lname' | 'name' | 'initials' | 'slug' | 'email' | 'title' | 'intercomUserHash' | 'image' | 'confirmedAt'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'isAdmin' | 'name'>
      & { permissions: (
        { __typename?: 'Permissions' }
        & CurrentProfilePermissionsFragment
      ) }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & { demo?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'size'>
      )>, demoParent?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'size' | 'logo'>
      )> }
      & CurrentOrganizationFragment
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & CurrentUserFragment
    )> }
  )> }
);

export type UserNameQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNameQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'fullName' | 'fname' | 'lname'>
  )> }
);

export type CompensationFragment = (
  { __typename?: 'Compensation' }
  & Pick<Compensation, 'id' | 'isCurrent' | 'effectiveOn' | 'hoursPerWeek' | 'payrollType' | 'rate'>
);

export type ProfileCompensationsQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type ProfileCompensationsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { compensations?: Maybe<Array<(
      { __typename?: 'Compensation' }
      & CompensationFragment
    )>> }
  ) }
);

export type CreateCompensationMutationVariables = Exact<{
  data: CreateCompensationInput;
}>;


export type CreateCompensationMutation = (
  { __typename?: 'Mutation' }
  & { createCompensation?: Maybe<(
    { __typename?: 'CreateCompensationPayload' }
    & { compensation?: Maybe<(
      { __typename?: 'Compensation' }
      & CompensationFragment
    )> }
  )> }
);

export type EditCompensationMutationVariables = Exact<{
  data: UpdateCompensationInput;
}>;


export type EditCompensationMutation = (
  { __typename?: 'Mutation' }
  & { updateCompensation?: Maybe<(
    { __typename?: 'UpdateCompensationPayload' }
    & { compensation?: Maybe<(
      { __typename?: 'Compensation' }
      & CompensationFragment
    )> }
  )> }
);

export type StopCompensationMutationVariables = Exact<{
  data: StopCompensationInput;
}>;


export type StopCompensationMutation = (
  { __typename?: 'Mutation' }
  & { stopCompensation?: Maybe<(
    { __typename?: 'StopCompensationPayload' }
    & { compensation?: Maybe<(
      { __typename?: 'Compensation' }
      & CompensationFragment
    )> }
  )> }
);

export type DeleteCompensationMutationVariables = Exact<{
  data: DeleteCompensationInput;
}>;


export type DeleteCompensationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompensation?: Maybe<(
    { __typename?: 'DeleteCompensationPayload' }
    & { compensation?: Maybe<(
      { __typename?: 'Compensation' }
      & CompensationFragment
    )> }
  )> }
);

export type ConsultantBillsQueryVariables = Exact<{
  sortBy?: Maybe<ConsultantBillsSortInput>;
  filters: ConsultantBillFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ConsultantBillsQuery = (
  { __typename?: 'Query' }
  & { consultantBills: (
    { __typename?: 'ConsultantBillConnection' }
    & Pick<ConsultantBillConnection, 'totalCount' | 'totalBilled' | 'owedCount' | 'owedAmount' | 'paidCount' | 'paidAmount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ConsultantBill' }
      & ConsultantBillFragment
    )>>> }
  ) }
);

export type ConsultantBillInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'beginDate' | 'endDate' | 'id' | 'number' | 'slug' | 'status'>
);

export type ConsultantBillCompanyFragment = (
  { __typename?: 'ConsultantCompany' }
  & Pick<ConsultantCompany, 'id' | 'name' | 'quickbooksVendorId'>
);

export type ConsultantBillProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'color' | 'id' | 'link' | 'name' | 'number' | 'slug'>
  & { invoices?: Maybe<Array<(
    { __typename?: 'Invoice' }
    & ConsultantBillInvoiceFragment
  )>> }
);

export type ConsultantBillFragment = (
  { __typename?: 'ConsultantBill' }
  & Pick<ConsultantBill, 'id' | 'notes' | 'pdfLink' | 'billNumber' | 'billAmount' | 'paidAmount' | 'dueDate' | 'issueDate' | 'paidDate' | 'status' | 'quickbooksBillId' | 'quickbooksBillCategoryId'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ConsultantBillProjectFragment
  )>, invoice?: Maybe<(
    { __typename?: 'Invoice' }
    & ConsultantBillInvoiceFragment
  )>, invoiceAttachment?: Maybe<(
    { __typename?: 'InvoiceAttachment' }
    & Pick<InvoiceAttachment, 'id'>
  )>, consultantCompany: (
    { __typename?: 'ConsultantCompany' }
    & ConsultantBillCompanyFragment
  ) }
);

export type ConsultantBillProjectConsultantFragment = (
  { __typename?: 'ProjectConsultant' }
  & Pick<ProjectConsultant, 'id'>
  & { consultantTemplate: (
    { __typename?: 'ConsultantTemplate' }
    & Pick<ConsultantTemplate, 'id' | 'name'>
  ), consultingWork?: Maybe<(
    { __typename?: 'ConsultingWork' }
    & Pick<ConsultingWork, 'id'>
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
    )>, consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id' | 'name' | 'quickbooksVendorId'>
    )>, consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & Pick<ConsultantContact, 'companyId' | 'companyName'>
      & { consultantCompany?: Maybe<(
        { __typename?: 'ConsultantCompany' }
        & Pick<ConsultantCompany, 'id' | 'name' | 'quickbooksVendorId'>
      )> }
    )> }
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type AllProjectConsultantsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllProjectConsultantsQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { phaseConsultants?: Maybe<Array<(
      { __typename?: 'PhaseConsultant' }
      & { entries?: Maybe<Array<(
        { __typename?: 'ProjectConsultant' }
        & ConsultantBillProjectConsultantFragment
      )>> }
    )>>, projectConsultants?: Maybe<Array<(
      { __typename?: 'ProjectConsultant' }
      & ConsultantBillProjectConsultantFragment
    )>> }
  )> }
);

export type QuickbooksExpenseAccountsQueryVariables = Exact<{
  accountType?: Maybe<Array<QboAccountType> | QboAccountType>;
}>;


export type QuickbooksExpenseAccountsQuery = (
  { __typename?: 'Query' }
  & { quickbooksExpenseAccounts?: Maybe<Array<(
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'id' | 'name' | 'active' | 'fullyQualifiedName' | 'parentQuickbooksId' | 'quickbooksId' | 'accountType' | 'accountSubType' | 'costCategory' | 'description'>
  )>> }
);

export type ConsultantBillMarkAsPaidMutationVariables = Exact<{
  id: Scalars['ID'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
}>;


export type ConsultantBillMarkAsPaidMutation = (
  { __typename?: 'Mutation' }
  & { markConsultantBillAsPaid?: Maybe<(
    { __typename?: 'ConsultantBillMarkAsPaidPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id' | 'paidDate' | 'status'>
    )> }
  )> }
);

export type CreateConsultantBillMutationVariables = Exact<{
  input: CreateConsultantBillInput;
}>;


export type CreateConsultantBillMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantBill?: Maybe<(
    { __typename?: 'CreateConsultantBillPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & ConsultantBillFragment
    )> }
  )> }
);

export type UpdateConsultantBillMutationVariables = Exact<{
  input: UpdateConsultantBillInput;
}>;


export type UpdateConsultantBillMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantBill?: Maybe<(
    { __typename?: 'UpdateConsultantBillPayload' }
    & { consultantBill: (
      { __typename?: 'ConsultantBill' }
      & ConsultantBillFragment
    ) }
  )> }
);

export type ConsultantBillMarkAsOpenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConsultantBillMarkAsOpenMutation = (
  { __typename?: 'Mutation' }
  & { markConsultantBillAsOpen?: Maybe<(
    { __typename?: 'ConsultantBillMarkAsOpenPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id' | 'paidDate' | 'status'>
    )> }
  )> }
);

export type AssignConsultantBillToInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['ID']>;
  invoiceSlug?: Maybe<Scalars['String']>;
}>;


export type AssignConsultantBillToInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { assignConsultantBillToInvoice?: Maybe<(
    { __typename?: 'AssignConsultantBillToInvoicePayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id'>
      & { invoice?: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )>, invoiceAttachment?: Maybe<(
        { __typename?: 'InvoiceAttachment' }
        & InvoiceAttachmentFragment
      )> }
    )> }
  )> }
);

export type AttachConsultantBillToInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AttachConsultantBillToInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { attachConsultantBillToInvoice?: Maybe<(
    { __typename?: 'AttachConsultantBillToInvoicePayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id'>
      & { invoice?: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )>, invoiceAttachment?: Maybe<(
        { __typename?: 'InvoiceAttachment' }
        & InvoiceAttachmentFragment
      )> }
    )> }
  )> }
);

export type RemoveConsultantBillInvoiceAssignmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveConsultantBillInvoiceAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { removeConsultantBillInvoiceAssignment?: Maybe<(
    { __typename?: 'RemoveConsultantBillInvoiceAssignmentPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id'>
      & { invoice?: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )> }
    )> }
  )> }
);

export type RemoveConsultantBillInvoiceAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveConsultantBillInvoiceAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeConsultantBillInvoiceAttachment?: Maybe<(
    { __typename?: 'RemoveConsultantBillInvoiceAttachmentPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id'>
      & { invoiceAttachment?: Maybe<(
        { __typename?: 'InvoiceAttachment' }
        & Pick<InvoiceAttachment, 'id'>
      )>, invoice?: Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )> }
    )> }
  )> }
);

export type DeleteConsultantBillMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteConsultantBillMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantBill?: Maybe<(
    { __typename?: 'DeleteConsultantBillPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'id'>
    )> }
  )> }
);

export type SyncConsultantBillWithQuickbooksMutationVariables = Exact<{
  input: SyncConsultantBillWithQuickbooksInput;
}>;


export type SyncConsultantBillWithQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { syncConsultantBillWithQuickbooks?: Maybe<(
    { __typename?: 'SyncConsultantBillWithQuickbooksPayload' }
    & { consultantBill?: Maybe<(
      { __typename?: 'ConsultantBill' }
      & Pick<ConsultantBill, 'quickbooksBillId'>
    )> }
  )> }
);

export type ProjectConsultantTotalsFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'budget' | 'totalFee'>
);

export type ProjectTemplateConsultantTotalsFragment = (
  { __typename?: 'ProjectTemplate' }
  & Pick<ProjectTemplate, 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'budget' | 'totalFee'>
);

export type ConsultingWorkDetailsFragment = (
  { __typename?: 'ConsultingWork' }
  & Pick<ConsultingWork, 'consultantReferenceType' | 'id'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
    )> }
  )>, consultantContact?: Maybe<(
    { __typename?: 'ConsultantContact' }
    & Pick<ConsultantContact, 'id' | 'fullName' | 'slug'>
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id' | 'name' | 'slug'>
    )> }
  )>, consultantCompany?: Maybe<(
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'id' | 'name' | 'slug'>
  )>, consultantBills?: Maybe<Array<(
    { __typename?: 'ConsultantBill' }
    & Pick<ConsultantBill, 'id'>
  )>> }
);

export type ProjectConsultantDetailsFragment = (
  { __typename?: 'ProjectConsultant' }
  & Pick<ProjectConsultant, 'budget' | 'budgetIsFrozen' | 'consultantTemplateId' | 'id' | 'markup' | 'markupPercentage'>
  & { consultingWork?: Maybe<(
    { __typename?: 'ConsultingWork' }
    & ConsultingWorkDetailsFragment
  )>, consultantTemplate: (
    { __typename?: 'ConsultantTemplate' }
    & Pick<ConsultantTemplate, 'id' | 'name'>
  ) }
);

export type PhaseConsultantDetailsFragment = (
  { __typename?: 'PhaseConsultant' }
  & Pick<PhaseConsultant, 'markupPercentage' | 'markupTotal' | 'total'>
  & { consultingWork?: Maybe<(
    { __typename?: 'ConsultingWork' }
    & ConsultingWorkDetailsFragment
  )>, consultantTemplate: (
    { __typename?: 'ConsultantTemplate' }
    & Pick<ConsultantTemplate, 'id' | 'name'>
  ), entries?: Maybe<Array<(
    { __typename?: 'ProjectConsultant' }
    & Pick<ProjectConsultant, 'budget' | 'budgetIsFrozen' | 'consultantTemplateId' | 'id' | 'markup' | 'markupPercentage'>
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )> }
  )>> }
);

export type PlanableConsultantTotalsQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type PlanableConsultantTotalsQuery = (
  { __typename?: 'Query' }
  & { planable?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'budget' | 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'totalFee'>
  ) | (
    { __typename?: 'ProjectTemplate' }
    & Pick<ProjectTemplate, 'id' | 'budget' | 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'totalFee'>
  )> }
);

export type ConsultantTemplatesQueryVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
}>;


export type ConsultantTemplatesQuery = (
  { __typename?: 'Query' }
  & { consultantTemplates: Array<(
    { __typename?: 'ConsultantTemplate' }
    & Pick<ConsultantTemplate, 'id' | 'name'>
  )> }
);

export type PlanableConsultantsQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type PlanableConsultantsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'defaultConsultantMarkup'>
      ) }
    )> }
  )>, planable?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'budget' | 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'id' | 'totalConsultantsMarkup' | 'totalFee'>
    & { projectConsultants?: Maybe<Array<(
      { __typename?: 'ProjectConsultant' }
      & ProjectConsultantDetailsFragment
    )>>, phaseConsultants?: Maybe<Array<(
      { __typename?: 'PhaseConsultant' }
      & PhaseConsultantDetailsFragment
    )>> }
  ) | (
    { __typename?: 'ProjectTemplate' }
    & Pick<ProjectTemplate, 'budget' | 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'id' | 'totalConsultantsMarkup' | 'totalFee'>
    & { projectConsultants?: Maybe<Array<(
      { __typename?: 'ProjectConsultant' }
      & ProjectConsultantDetailsFragment
    )>>, phaseConsultants?: Maybe<Array<(
      { __typename?: 'PhaseConsultant' }
      & PhaseConsultantDetailsFragment
    )>> }
  )> }
);

export type ProjectTemplatePhasesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectTemplatePhasesQuery = (
  { __typename?: 'Query' }
  & { projectTemplate?: Maybe<(
    { __typename?: 'ProjectTemplate' }
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )> }
  )> }
);

export type CreateProjectConsultantMutationVariables = Exact<{
  input: CreateInput;
}>;


export type CreateProjectConsultantMutation = (
  { __typename?: 'Mutation' }
  & { createProjectConsultant?: Maybe<(
    { __typename?: 'CreatePayload' }
    & { projectConsultant?: Maybe<(
      { __typename?: 'ProjectConsultant' }
      & Pick<ProjectConsultant, 'budget' | 'id' | 'consultantTemplateId'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
        & ProjectConsultantTotalsFragment
      )>, projectTemplate?: Maybe<(
        { __typename?: 'ProjectTemplate' }
        & Pick<ProjectTemplate, 'id'>
        & ProjectTemplateConsultantTotalsFragment
      )> }
    )> }
  )> }
);

export type UpdateProjectConsultantPlannerMutationVariables = Exact<{
  input: UpdateProjectConsultantInput;
}>;


export type UpdateProjectConsultantPlannerMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectConsultant?: Maybe<(
    { __typename?: 'UpdateProjectConsultantPayload' }
    & { projectConsultant: (
      { __typename?: 'ProjectConsultant' }
      & Pick<ProjectConsultant, 'id' | 'budget' | 'consultantTemplateId'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
        & ProjectConsultantTotalsFragment
      )>, projectTemplate?: Maybe<(
        { __typename?: 'ProjectTemplate' }
        & Pick<ProjectTemplate, 'id'>
        & ProjectTemplateConsultantTotalsFragment
      )> }
    ) }
  )> }
);

export type DeleteProjectConsultantMutationVariables = Exact<{
  input: DeleteInput;
}>;


export type DeleteProjectConsultantMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectConsultant?: Maybe<(
    { __typename?: 'DeletePayload' }
    & { projectConsultant?: Maybe<(
      { __typename?: 'ProjectConsultant' }
      & Pick<ProjectConsultant, 'id'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
        & ProjectConsultantTotalsFragment
      )>, projectTemplate?: Maybe<(
        { __typename?: 'ProjectTemplate' }
        & Pick<ProjectTemplate, 'id'>
        & ProjectTemplateConsultantTotalsFragment
      )> }
    )> }
  )> }
);

export type CreateOrUpdatePhaseConsultantMutationVariables = Exact<{
  input: UpdatePhaseConsultantsInput;
}>;


export type CreateOrUpdatePhaseConsultantMutation = (
  { __typename?: 'Mutation' }
  & { updatePhaseConsultants?: Maybe<(
    { __typename?: 'UpdatePhaseConsultantsPayload' }
    & { phaseConsultants: Array<(
      { __typename?: 'PhaseConsultant' }
      & { consultantTemplate: (
        { __typename?: 'ConsultantTemplate' }
        & Pick<ConsultantTemplate, 'id' | 'name'>
      ), entries?: Maybe<Array<(
        { __typename?: 'ProjectConsultant' }
        & Pick<ProjectConsultant, 'budget' | 'budgetIsFrozen' | 'markup' | 'markupPercentage' | 'id'>
        & { consultantTemplate: (
          { __typename?: 'ConsultantTemplate' }
          & Pick<ConsultantTemplate, 'id'>
        ), phase?: Maybe<(
          { __typename?: 'Phase' }
          & Pick<Phase, 'id'>
        )>, project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'id'>
          & ProjectConsultantTotalsFragment
        )>, projectTemplate?: Maybe<(
          { __typename?: 'ProjectTemplate' }
          & Pick<ProjectTemplate, 'id'>
          & ProjectTemplateConsultantTotalsFragment
        )> }
      )>> }
    )> }
  )> }
);

export type UpdateProjectConsultantsFeeMutationVariables = Exact<{
  input: ProjectConsultantsFeeUpdateInput;
}>;


export type UpdateProjectConsultantsFeeMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectConsultantsFee?: Maybe<(
    { __typename?: 'ProjectConsultantsFeeUpdatePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
      & ProjectConsultantTotalsFragment
    ) }
  )> }
);

export type UpdateProjectTemplateConsultantsFeeMutationVariables = Exact<{
  input: ProjectTemplateConsultantsFeeUpdateInput;
}>;


export type UpdateProjectTemplateConsultantsFeeMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectTemplateConsultantsFee?: Maybe<(
    { __typename?: 'ProjectTemplateConsultantsFeeUpdatePayload' }
    & { projectTemplate: (
      { __typename?: 'ProjectTemplate' }
      & Pick<ProjectTemplate, 'id'>
      & ProjectTemplateConsultantTotalsFragment
    ) }
  )> }
);

export type ConsultantCompanyQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ConsultantCompanyQuery = (
  { __typename?: 'Query' }
  & { consultantCompany: (
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'secondaryAddressDisplayLabel'>
    & { secondaryAddress?: Maybe<(
      { __typename?: 'Address' }
      & ConsultantCompanyAddressFragment
    )> }
    & ConsultantCompanyResultFragment
  ) }
);

export type ConsultantContactQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ConsultantContactQuery = (
  { __typename?: 'Query' }
  & { consultantContact: (
    { __typename?: 'ConsultantContact' }
    & ConsultantContactResultFragment
  ) }
);

export type ConsultantCompaniesQueryVariables = Exact<{
  qboSyncedOnly?: Maybe<Scalars['Boolean']>;
}>;


export type ConsultantCompaniesQuery = (
  { __typename?: 'Query' }
  & { consultantCompanies: Array<(
    { __typename?: 'ConsultantCompany' }
    & ConsultantCompanyResultFragment
  )> }
);

export type ConsultantContactsQueryVariables = Exact<{
  onlyActiveCompanies?: Maybe<Scalars['Boolean']>;
}>;


export type ConsultantContactsQuery = (
  { __typename?: 'Query' }
  & { consultantContacts: Array<(
    { __typename?: 'ConsultantContact' }
    & ConsultantContactResultFragment
  )> }
);

export type ConsultantCompanyContactsQueryVariables = Exact<{
  companySlug?: Maybe<Scalars['String']>;
}>;


export type ConsultantCompanyContactsQuery = (
  { __typename?: 'Query' }
  & { consultantContacts: Array<(
    { __typename?: 'ConsultantContact' }
    & ConsultantContactResultFragment
  )> }
);

export type ConsultantContactResultFragment = (
  { __typename?: 'ConsultantContact' }
  & Pick<ConsultantContact, 'id' | 'email' | 'title' | 'fullName' | 'fname' | 'lname' | 'phone' | 'consultantTemplateId' | 'consultantServiceNames' | 'isPrimary' | 'active' | 'slug'>
  & { consultantServiceDefaults?: Maybe<Array<(
    { __typename?: 'ConsultantServiceDefault' }
    & Pick<ConsultantServiceDefault, 'id'>
    & { consultantTemplate: (
      { __typename?: 'ConsultantTemplate' }
      & Pick<ConsultantTemplate, 'id' | 'name'>
    ) }
  )>>, consultantCompany?: Maybe<(
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'id' | 'name' | 'slug' | 'active'>
  )> }
);

export type ConsultantCompanyResultFragment = (
  { __typename?: 'ConsultantCompany' }
  & Pick<ConsultantCompany, 'active' | 'consultantServiceNames' | 'consultantTemplateIds' | 'id' | 'name' | 'notes' | 'primaryAddressDisplayLabel' | 'secondaryAddressDisplayLabel' | 'slug' | 'syncedToQbo' | 'website' | 'quickbooksVendorId'>
  & { consultantServiceDefaults?: Maybe<Array<(
    { __typename?: 'ConsultantServiceDefault' }
    & Pick<ConsultantServiceDefault, 'id'>
    & { consultantTemplate: (
      { __typename?: 'ConsultantTemplate' }
      & Pick<ConsultantTemplate, 'name'>
    ) }
  )>>, consultantContacts?: Maybe<Array<(
    { __typename?: 'ConsultantContact' }
    & ConsultantContactResultFragment
  )>>, primaryAddress?: Maybe<(
    { __typename?: 'Address' }
    & ConsultantCompanyAddressFragment
  )>, secondaryAddress?: Maybe<(
    { __typename?: 'Address' }
    & ConsultantCompanyAddressFragment
  )>, qboVendor?: Maybe<(
    { __typename?: 'QuickbooksVendor' }
    & Pick<QuickbooksVendor, 'id'>
  )> }
);

export type ConsultantCompanyAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
);

export type AddConsultantCompanyMutationVariables = Exact<{
  input: AddConsultantCompanyInput;
}>;


export type AddConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantCompany?: Maybe<(
    { __typename?: 'AddConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type AddConsultantContactMutationVariables = Exact<{
  input: AddConsultantContactInput;
}>;


export type AddConsultantContactMutation = (
  { __typename?: 'Mutation' }
  & { addConsultantContact?: Maybe<(
    { __typename?: 'AddConsultantContactPayload' }
    & { consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & Pick<ConsultantContact, 'id' | 'email' | 'slug' | 'fullName'>
    )> }
  )> }
);

export type UpdateConsultantCompanyMutationVariables = Exact<{
  input: UpdateConsultantCompanyInput;
}>;


export type UpdateConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantCompany?: Maybe<(
    { __typename?: 'UpdateConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type UpdateConsultantContactMutationVariables = Exact<{
  input: UpdateConsultantContactInput;
}>;


export type UpdateConsultantContactMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantContact?: Maybe<(
    { __typename?: 'UpdateConsultantContactPayload' }
    & { consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & ConsultantContactResultFragment
    )> }
  )> }
);

export type DeactivateConsultantContactMutationVariables = Exact<{
  data: DeactivateConsultantContactInput;
}>;


export type DeactivateConsultantContactMutation = (
  { __typename?: 'Mutation' }
  & { deactivateConsultantContact?: Maybe<(
    { __typename?: 'DeactivateConsultantContactPayload' }
    & { consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & ConsultantContactResultFragment
    )> }
  )> }
);

export type DeactivateConsultantCompanyMutationVariables = Exact<{
  data: DeactivateConsultantCompanyInput;
}>;


export type DeactivateConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deactivateConsultantCompany?: Maybe<(
    { __typename?: 'DeactivateConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type ReactivateConsultantContactMutationVariables = Exact<{
  data: ReactivateConsultantContactInput;
}>;


export type ReactivateConsultantContactMutation = (
  { __typename?: 'Mutation' }
  & { reactivateConsultantContact?: Maybe<(
    { __typename?: 'ReactivateConsultantContactPayload' }
    & { consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & ConsultantContactResultFragment
    )> }
  )> }
);

export type ReactivateConsultantCompanyMutationVariables = Exact<{
  data: ReactivateConsultantCompanyInput;
}>;


export type ReactivateConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { reactivateConsultantCompany?: Maybe<(
    { __typename?: 'ReactivateConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type DeleteConsultantContactMutationVariables = Exact<{
  data: DeleteConsultantContactInput;
}>;


export type DeleteConsultantContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantContact?: Maybe<(
    { __typename?: 'DeleteConsultantContactPayload' }
    & { consultantContact?: Maybe<(
      { __typename?: 'ConsultantContact' }
      & ConsultantContactResultFragment
    )> }
  )> }
);

export type DeleteConsultantCompanyMutationVariables = Exact<{
  data: DeleteConsultantCompanyInput;
}>;


export type DeleteConsultantCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantCompany?: Maybe<(
    { __typename?: 'DeleteConsultantCompanyPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type SetPrimaryCompanyContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetPrimaryCompanyContactMutation = (
  { __typename?: 'Mutation' }
  & { setPrimaryCompanyContact?: Maybe<(
    { __typename?: 'SetPrimaryCompanyContactPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type SyncConsultantCompanyWithQuickbooksMutationVariables = Exact<{
  input: SyncConsultantCompanyWithQuickbooksInput;
}>;


export type SyncConsultantCompanyWithQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { syncConsultantCompanyWithQuickbooks?: Maybe<(
    { __typename?: 'SyncConsultantCompanyWithQuickbooksPayload' }
    & { consultantCompany?: Maybe<(
      { __typename?: 'ConsultantCompany' }
      & ConsultantCompanyResultFragment
    )> }
  )> }
);

export type ClientDirectoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientDirectoryQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'unprocessedQboCustomers'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )>, qboInstances: Array<(
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id' | 'companyName'>
  )> }
);

export type ClientsOverviewQueryVariables = Exact<{
  sortBy?: Maybe<ClientsSortInput>;
  filters?: Maybe<ClientFiltersInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ClientsOverviewQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'ClientConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Client' }
      & DirectoryClientFragment
    )>>> }
  ) }
);

export type ClientProfileQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'companyName' | 'connected' | 'id'>
);

export type ClientProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientProfileQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo' | 'unprocessedQboCustomers'>
      & { qboInstances: Array<(
        { __typename?: 'QBOInstance' }
        & ClientProfileQboInstanceFragment
      )>, settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type FindMatchingCustomerQueryVariables = Exact<{
  clientName: Scalars['String'];
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type FindMatchingCustomerQuery = (
  { __typename?: 'Query' }
  & { findMatchingCustomer?: Maybe<(
    { __typename?: 'QBOCustomer' }
    & Pick<QboCustomer, 'id' | 'displayName'>
  )> }
);

export type QboModalQboCustomersOptionsQueryVariables = Exact<{
  filters?: Maybe<QboCustomerFilters>;
}>;


export type QboModalQboCustomersOptionsQuery = (
  { __typename?: 'Query' }
  & { quickbooksCustomers: Array<(
    { __typename?: 'QBOCustomer' }
    & { qboInstance: (
      { __typename?: 'QBOInstance' }
      & Pick<QboInstance, 'id'>
    ) }
    & QboCustomerDetailsFragment
  )> }
);

export type SyncClientToQboMutationVariables = Exact<{
  id: Scalars['ID'];
  qboInstanceId: Scalars['ID'];
}>;


export type SyncClientToQboMutation = (
  { __typename?: 'Mutation' }
  & { syncClientToQbo?: Maybe<(
    { __typename?: 'SyncClientToQboPayload' }
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )> }
  )> }
);

export type ConsultantDirectoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsultantDirectoryQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type QboCustomerDetailsForImportFragment = (
  { __typename?: 'QBOCustomer' }
  & Pick<QboCustomer, 'processed'>
  & { qboInstance: (
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id' | 'companyName'>
  ) }
  & QboCustomerDetailsFragment
);

export type QuickbooksCustomersForImportQueryVariables = Exact<{
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type QuickbooksCustomersForImportQuery = (
  { __typename?: 'Query' }
  & { quickbooksCustomers: Array<(
    { __typename?: 'QBOCustomer' }
    & QboCustomerDetailsForImportFragment
  )> }
);

export type QuickbooksCustomersPotentialMatchingClientsQueryVariables = Exact<{
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type QuickbooksCustomersPotentialMatchingClientsQuery = (
  { __typename?: 'Query' }
  & { customersMatchingClients: Array<(
    { __typename?: 'QBOCustomerClientMatch' }
    & Pick<QboCustomerClientMatch, 'qboCustomerId' | 'clientId'>
  )> }
);

export type ImporterOrgQboSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ImporterOrgQboSettingsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type PhaseInfoFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'abbreviation'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'phaseRate'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type ExpenseInfoFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id' | 'date' | 'note' | 'isBillable' | 'isReimbursable' | 'reimbursePaidDate' | 'amount' | 'markup' | 'billableAmount' | 'attachment' | 'onOpenInvoice' | 'qboPurchaseId' | 'qboBillId'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectGeneralInfoFragment
  )>, profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'slug'>
  ), category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
    & { qboExpenseAccount?: Maybe<(
      { __typename?: 'QBOAccount' }
      & Pick<QboAccount, 'id' | 'name'>
    )> }
  ), overhead?: Maybe<(
    { __typename?: 'Overhead' }
    & Pick<Overhead, 'name' | 'id'>
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & PhaseInfoFragment
  )> }
);

export type AllExpensesQueryVariables = Exact<{
  filters: ExpenseFiltersInput;
}>;


export type AllExpensesQuery = (
  { __typename?: 'Query' }
  & { allExpenses?: Maybe<Array<(
    { __typename?: 'Expense' }
    & ExpenseInfoFragment
  )>> }
);

export type ExpensesCountQueryVariables = Exact<{
  sortBy?: Maybe<ExpensesSortInput>;
  filters: ExpenseFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ExpensesCountQuery = (
  { __typename?: 'Query' }
  & { expenses: (
    { __typename?: 'ExpenseConnection' }
    & Pick<ExpenseConnection, 'totalCount'>
  ) }
);

export type ExpensesQueryVariables = Exact<{
  sortBy?: Maybe<ExpensesSortInput>;
  filters: ExpenseFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ExpensesQuery = (
  { __typename?: 'Query' }
  & { expenses: (
    { __typename?: 'ExpenseConnection' }
    & Pick<ExpenseConnection, 'totalCount' | 'totalAmount' | 'totalMarkup' | 'totalBillableAmount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Expense' }
      & ExpenseInfoFragment
    )>>> }
  ) }
);

export type ExpensesFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpensesFiltersQuery = (
  { __typename?: 'Query' }
  & { projectsOptions?: Maybe<Array<(
    { __typename?: 'ProjectOption' }
    & Pick<ProjectOption, 'id' | 'name'>
  )>>, currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name'>
      )>, overheads: Array<(
        { __typename?: 'Overhead' }
        & Pick<Overhead, 'id' | 'name'>
      )> }
    )> }
  )>, expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
  )>, expenseChargeTypes: Array<(
    { __typename?: 'ExpenseChargeType' }
    & Pick<ExpenseChargeType, 'id' | 'name'>
  )> }
);

export type ProjectExpensesFiltersQueryVariables = Exact<{
  projectSlug: Scalars['String'];
}>;


export type ProjectExpensesFiltersQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name'>
      )> }
    )> }
  )>, projectExpenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
  )>, expenseChargeTypes: Array<(
    { __typename?: 'ExpenseChargeType' }
    & Pick<ExpenseChargeType, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectPhasesFragment
  )> }
);

export type ExpenseMarkReimburseAsPaidMutationVariables = Exact<{
  id: Scalars['ID'];
  paidDate?: Maybe<Scalars['ISO8601Date']>;
}>;


export type ExpenseMarkReimburseAsPaidMutation = (
  { __typename?: 'Mutation' }
  & { markReimburseAsPaid?: Maybe<(
    { __typename?: 'ExpenseMarkReimburseAsPaidPayload' }
    & { expense: (
      { __typename?: 'Expense' }
      & Pick<Expense, 'id' | 'reimbursePaidDate'>
    ) }
  )> }
);

export type BatchSendExpenseToQuickbooksMutationVariables = Exact<{
  input: ExpenseBatchSendToQuickbooksInput;
}>;


export type BatchSendExpenseToQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { batchSendExpenseToQuickbooks?: Maybe<(
    { __typename?: 'ExpenseBatchSendToQuickbooksPayload' }
    & Pick<ExpenseBatchSendToQuickbooksPayload, 'enqueued'>
  )> }
);

export type BatchExpensesToQboJobSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BatchExpensesToQboJobSubscription = (
  { __typename?: 'Subscription' }
  & { batchExpensesToQboJob: (
    { __typename?: 'BatchExpensesToQBOJobPayload' }
    & { result?: Maybe<(
      { __typename?: 'ExpensesToQBO' }
      & Pick<ExpensesToQbo, 'status'>
      & { itemFailures?: Maybe<Array<(
        { __typename?: 'ExpensesToQBOFailures' }
        & Pick<ExpensesToQboFailures, 'message' | 'qboEntity'>
        & { expense?: Maybe<(
          { __typename?: 'Expense' }
          & Pick<Expense, 'id' | 'date' | 'amount'>
          & { project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<Project, 'name'>
          )> }
        )>, profile?: Maybe<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'name'>
        )> }
      )>> }
    )> }
  ) }
);

export type ConsultantBillsExportStartMutationVariables = Exact<{
  filters: ConsultantBillsInput;
}>;


export type ConsultantBillsExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { consultantBills?: Maybe<(
      { __typename?: 'ConsultantBillsPayload' }
      & Pick<ConsultantBillsPayload, 'id'>
    )> }
  ) }
);

export type ProjectTimingsExportStartMutationVariables = Exact<{
  filters: ProjectsTimingsInput;
}>;


export type ProjectTimingsExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { projectsTimings?: Maybe<(
      { __typename?: 'ProjectsTimingsPayload' }
      & Pick<ProjectsTimingsPayload, 'id'>
    )> }
  ) }
);

export type InvoicesExportStartMutationVariables = Exact<{
  filters: InvoicesInput;
}>;


export type InvoicesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { invoices?: Maybe<(
      { __typename?: 'InvoicesPayload' }
      & Pick<InvoicesPayload, 'id'>
    )> }
  ) }
);

export type PaymentsInvoicesExportStartMutationVariables = Exact<{
  issueDateRange: DateRangeInput;
}>;


export type PaymentsInvoicesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { paymentsInvoices?: Maybe<(
      { __typename?: 'PaymentsInvoicesPayload' }
      & Pick<PaymentsInvoicesPayload, 'id'>
    )> }
  ) }
);

export type PaymentsReceivablesExportStartMutationVariables = Exact<{
  issueDateRange: DateRangeInput;
}>;


export type PaymentsReceivablesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { paymentsReceivables?: Maybe<(
      { __typename?: 'PaymentsReceivablesPayload' }
      & Pick<PaymentsReceivablesPayload, 'id'>
    )> }
  ) }
);

export type PaymentsExportStartMutationVariables = Exact<{
  paidDateRange: DateRangeInput;
}>;


export type PaymentsExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { payments?: Maybe<(
      { __typename?: 'PaymentsPaymentsPayload' }
      & Pick<PaymentsPaymentsPayload, 'id'>
    )> }
  ) }
);

export type PayoutsExportStartMutationVariables = Exact<{
  payoutDateRange: DateRangeInput;
}>;


export type PayoutsExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { payouts?: Maybe<(
      { __typename?: 'PaymentsPayoutsPayload' }
      & Pick<PaymentsPayoutsPayload, 'id'>
    )> }
  ) }
);

export type TaxesCollectedExportStartMutationVariables = Exact<{
  paidDateRange: DateRangeInput;
}>;


export type TaxesCollectedExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { taxesCollected?: Maybe<(
      { __typename?: 'PaymentsTaxesCollectedPayload' }
      & Pick<PaymentsTaxesCollectedPayload, 'id'>
    )> }
  ) }
);

export type TransactionsExportStartMutationVariables = Exact<{
  dateRange: DateRangeInput;
}>;


export type TransactionsExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { transactions?: Maybe<(
      { __typename?: 'PaymentsTransactionsPayload' }
      & Pick<PaymentsTransactionsPayload, 'id'>
    )> }
  ) }
);

export type ExpensesExportStartMutationVariables = Exact<{
  filters: ExpensesInput;
}>;


export type ExpensesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { expenses?: Maybe<(
      { __typename?: 'ExpensesPayload' }
      & Pick<ExpensesPayload, 'id'>
    )> }
  ) }
);

export type EntriesExportStartMutationVariables = Exact<{
  intent: TimeLogIntent;
  filters: EntryFiltersInput;
}>;


export type EntriesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { entries?: Maybe<(
      { __typename?: 'EntriesPayload' }
      & Pick<EntriesPayload, 'id'>
    )> }
  ) }
);

export type GroupedEntriesExportStartMutationVariables = Exact<{
  groupBy: GroupByInput;
  intent: TimeLogIntent;
  filters: EntryFiltersInput;
}>;


export type GroupedEntriesExportStartMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { groupedEntries?: Maybe<(
      { __typename?: 'GroupedEntriesPayload' }
      & Pick<GroupedEntriesPayload, 'id'>
    )> }
  ) }
);

export type CheckExportStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckExportStatusQuery = (
  { __typename?: 'Query' }
  & { checkExport?: Maybe<(
    { __typename?: 'ExportStatus' }
    & Pick<ExportStatus, 'status' | 'downloadUrl'>
  )> }
);

export type OrganizationExportsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationExportsQuery = (
  { __typename?: 'Query' }
  & { organizationExports: (
    { __typename?: 'OrganizationExport' }
    & Pick<OrganizationExport, 'projectPhaseJson'>
  ) }
);

export type ProjectProfitExportMutationVariables = Exact<{
  input: ProjectProfitExportInput;
}>;


export type ProjectProfitExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { projectProfit?: Maybe<(
      { __typename?: 'ProjectProfitExportPayload' }
      & Pick<ProjectProfitExportPayload, 'id'>
    )> }
  ) }
);

export type CommonAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'city' | 'country' | 'line1' | 'line2' | 'state' | 'zipcode'>
);

export type AjeraFieldsFragment = (
  { __typename?: 'AjeraIdentifier' }
  & Pick<AjeraIdentifier, 'id' | 'key' | 'typeKey' | 'recordId' | 'recordType'>
);

export type SelectableProfileGroupFragment = (
  { __typename?: 'ProfileGroup' }
  & Pick<ProfileGroup, 'name'>
  & { eligible: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )>, ineligible: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )> }
);

export type CurrentOrganizationCurrencyFragment = (
  { __typename?: 'Currency' }
  & Pick<Currency, 'id' | 'name' | 'symbol' | 'code' | 'decimalDigits'>
);

export type ExpenseFragmentFragment = (
  { __typename?: 'Expense' }
  & Pick<Expense, 'id' | 'attachment' | 'note' | 'date' | 'amount' | 'markup' | 'billableAmount'>
  & { category: (
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'name'>
  ), phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'name' | 'id'>
  )>, profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  ) }
);

export type InvoiceTotalFragmentFragment = (
  { __typename?: 'InvoiceBuilderServiceTotals' }
  & Pick<InvoiceBuilderServiceTotals, 'currentDue' | 'unbilled' | 'previouslyBilledAmount'>
);

export type ProjectConsultantsTotalFragmentFragment = (
  { __typename?: 'InvoiceBuilderProjectConsultants' }
  & Pick<InvoiceBuilderProjectConsultants, 'currentDue'>
  & { consultantTotals: (
    { __typename?: 'InvoiceBuilderConsultantTotals' }
    & Pick<InvoiceBuilderConsultantTotals, 'currentDue' | 'currentCompletePercent' | 'planned'>
    & { previouslyBilled: (
      { __typename?: 'InvoiceBuilderPreviouslyBilled' }
      & Pick<InvoiceBuilderPreviouslyBilled, 'percent' | 'amount'>
    ) }
  ) }
);

export type PhaseConsultantsTotalFragmentFragment = (
  { __typename?: 'InvoiceBuilderPhaseConsultants' }
  & Pick<InvoiceBuilderPhaseConsultants, 'currentDue'>
  & { totals: (
    { __typename?: 'InvoiceBuilderConsultantTotals' }
    & Pick<InvoiceBuilderConsultantTotals, 'currentCompletePercent' | 'currentDue' | 'planned'>
    & { previouslyBilled: (
      { __typename?: 'InvoiceBuilderPreviouslyBilled' }
      & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
    ) }
  ) }
);

export type TabsTotalsFragmentFragment = (
  { __typename?: 'InvoiceBuilderPayload' }
  & { fixedFeeServices: (
    { __typename?: 'InvoiceBuilderFixedFeeServices' }
    & { totals: (
      { __typename?: 'InvoiceBuilderServiceTotals' }
      & InvoiceTotalFragmentFragment
    ) }
  ), hourlyServices: (
    { __typename?: 'InvoiceBuilderHourlyServices' }
    & { totals: (
      { __typename?: 'InvoiceBuilderServiceTotals' }
      & InvoiceTotalFragmentFragment
    ) }
  ), expenses: (
    { __typename?: 'InvoiceBuilderExpensesType' }
    & { totals: (
      { __typename?: 'InvoiceBuilderServiceTotals' }
      & InvoiceTotalFragmentFragment
    ) }
  ), projectConsultants: (
    { __typename?: 'InvoiceBuilderProjectConsultants' }
    & ProjectConsultantsTotalFragmentFragment
  ), phaseConsultants: (
    { __typename?: 'InvoiceBuilderPhaseConsultants' }
    & PhaseConsultantsTotalFragmentFragment
  ), otherItems: (
    { __typename?: 'InvoiceBuilderOtherItemsType' }
    & Pick<InvoiceBuilderOtherItemsType, 'currentDue'>
  ), retainerItems: (
    { __typename?: 'InvoiceBuilderRetainerItemsType' }
    & Pick<InvoiceBuilderRetainerItemsType, 'currentDue'>
  ), appliedRetainer?: Maybe<(
    { __typename?: 'LineItem' }
    & AppliedRetainerItemFragmentFragment
  )>, taxes: (
    { __typename?: 'InvoiceBuilderTaxes' }
    & TaxEntryPayloadFragment
  ), retainerSummary?: Maybe<(
    { __typename?: 'RetainerSummary' }
    & RetainerSummaryFragmentFragment
  )> }
);

export type RetainerSummaryFragmentFragment = (
  { __typename?: 'RetainerSummary' }
  & Pick<RetainerSummary, 'title' | 'received' | 'applied' | 'remaining' | 'openReceived' | 'openApplied'>
);

export type MilestoneFragmentFragment = (
  { __typename?: 'InvoiceBuilderMilestone' }
  & Pick<InvoiceBuilderMilestone, 'id' | 'completedTasksCount' | 'dueDate' | 'name' | 'tasksCount'>
);

export type FixedFeeEntryFragmentFragment = (
  { __typename?: 'InvoiceBuilderFixedFeeService' }
  & Pick<InvoiceBuilderFixedFeeService, 'id' | 'planned' | 'currentCompletePercent' | 'currentDue'>
  & { milestones: Array<(
    { __typename?: 'InvoiceBuilderMilestone' }
    & MilestoneFragmentFragment
  )>, previouslyBilled: (
    { __typename?: 'InvoiceBuilderPreviouslyBilled' }
    & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
  ), currentDueInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), currentCompletePercentInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'status' | 'feeType' | 'endDate'>
  ), unbilled: (
    { __typename?: 'InvoiceBuilderTooltip' }
    & Pick<InvoiceBuilderTooltip, 'total' | 'beforeInvoiceBeginDate' | 'duringInvoiceRange'>
  ) }
);

export type PublicHourlyServicesEntryFragmentFragment = (
  { __typename?: 'InvoiceBuilderHourlyService' }
  & Pick<InvoiceBuilderHourlyService, 'id' | 'currentCompletePercent' | 'currentDue' | 'roleEntriesDue' | 'maxCap'>
  & { milestones: Array<(
    { __typename?: 'InvoiceBuilderMilestone' }
    & MilestoneFragmentFragment
  )>, previouslyBilled: (
    { __typename?: 'InvoiceBuilderPreviouslyBilled' }
    & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
  ), phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'status' | 'feeType'>
  ), phaseTotals: (
    { __typename?: 'InvoiceBuilderHourlyPhaseTotals' }
    & Pick<InvoiceBuilderHourlyPhaseTotals, 'currentDue' | 'totalDue' | 'currentHours' | 'previouslyBilledHours' | 'previouslyBilledAmount'>
  ) }
);

export type HourlyServicesEntryFragmentFragment = (
  { __typename?: 'InvoiceBuilderHourlyService' }
  & { unbilled: (
    { __typename?: 'InvoiceBuilderTooltip' }
    & Pick<InvoiceBuilderTooltip, 'total' | 'beforeInvoiceBeginDate' | 'duringInvoiceRange'>
  ) }
  & PublicHourlyServicesEntryFragmentFragment
);

export type HourlyServicesRoleFragmentFragment = (
  { __typename?: 'InvoiceBuilderRoleEntry' }
  & Pick<InvoiceBuilderRoleEntry, 'id' | 'hours' | 'previouslyBilledHours' | 'previouslyBilledAmount' | 'roleRate' | 'amount'>
  & { logged: (
    { __typename?: 'InvoiceBuilderTooltip' }
    & Pick<InvoiceBuilderTooltip, 'total' | 'duringInvoiceRange' | 'beforeInvoiceBeginDate'>
  ), role: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'hours' | 'name'>
    & { rateTable: (
      { __typename?: 'RateTable' }
      & Pick<RateTable, 'id' | 'slug' | 'name'>
    ) }
  ) }
);

export type OtherItemFragmentFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'amount' | 'quantity' | 'title' | 'description' | 'rate'>
);

export type RetainerItemFragmentFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'amount' | 'title' | 'description'>
);

export type AppliedRetainerItemFragmentFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'amount' | 'title'>
);

export type InvoiceBuilderProjectInvoiceFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'slug' | 'v1InvoicesWithUnassignedLineItemsCount'>
  & { retainerSummary?: Maybe<(
    { __typename?: 'RetainerSummary' }
    & Pick<RetainerSummary, 'remaining' | 'received' | 'applied'>
  )> }
);

export type InvoiceBuilderInvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'status' | 'beginDate' | 'endDate' | 'slug' | 'totalPaid' | 'total' | 'remainingBalance' | 'billingDestination' | 'lastLineItemUpdate'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'syncedToQbo'>
    & { qboCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
      & { qboInstance: (
        { __typename?: 'QBOInstance' }
        & Pick<QboInstance, 'id'>
      ) }
    )> }
  )>, qboInstance?: Maybe<(
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id' | 'qboTaxMethod'>
  )>, organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'connectedToQbo'>
    & { clients?: Maybe<Array<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'syncedToQbo' | 'displayName' | 'organizationName'>
      & { qboCustomer?: Maybe<(
        { __typename?: 'QBOCustomer' }
        & Pick<QboCustomer, 'id'>
      )> }
    )>> }
  ), invoicePayments?: Maybe<Array<(
    { __typename?: 'InvoicePayment' }
    & Pick<InvoicePayment, 'amount' | 'id' | 'paidDate'>
  )>>, project: (
    { __typename?: 'Project' }
    & InvoiceBuilderProjectInvoiceFragmentFragment
  ) }
);

export type HourlyServicesEntryWithRolesFragmentFragment = (
  { __typename?: 'InvoiceBuilderHourlyService' }
  & { roleEntries: Array<(
    { __typename?: 'InvoiceBuilderRoleEntry' }
    & HourlyServicesRoleFragmentFragment
  )> }
  & HourlyServicesEntryFragmentFragment
);

export type HourlyServicesConsultantEntriesFragmentFragment = (
  { __typename?: 'InvoiceBuilderProjectConsultantType' }
  & Pick<InvoiceBuilderProjectConsultantType, 'currentCompletePercent' | 'currentDue' | 'id' | 'planned'>
  & { consultant: (
    { __typename?: 'ProjectConsultant' }
    & Pick<ProjectConsultant, 'id'>
    & { consultantTemplate: (
      { __typename?: 'ConsultantTemplate' }
      & Pick<ConsultantTemplate, 'name' | 'id'>
    ) }
  ), previouslyBilled: (
    { __typename?: 'InvoiceBuilderPreviouslyBilled' }
    & Pick<InvoiceBuilderPreviouslyBilled, 'amount'>
  ) }
);

export type HourlyServicesEntriesFragmentFragment = (
  { __typename?: 'InvoiceBuilderHourlyServices' }
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderHourlyService' }
    & { consultantEntries: Array<(
      { __typename?: 'InvoiceBuilderProjectConsultantType' }
      & HourlyServicesConsultantEntriesFragmentFragment
    )> }
    & HourlyServicesEntryWithRolesFragmentFragment
  )>, totals: (
    { __typename?: 'InvoiceBuilderServiceTotals' }
    & InvoiceTotalFragmentFragment
  ) }
);

export type InvoiceBuilderPhaseTotalsFragmentFragment = (
  { __typename?: 'InvoiceBuilderFixedFeeServices' }
  & { phaseTotals: (
    { __typename?: 'InvoiceBuilderPhaseTotals' }
    & Pick<InvoiceBuilderPhaseTotals, 'planned' | 'unbilled' | 'currentDue' | 'currentCompletePercent'>
    & { previouslyBilled: (
      { __typename?: 'InvoiceBuilderPreviouslyBilled' }
      & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
    ) }
  ) }
);

export type FixedFeeServicesEntriesFragmentFragment = (
  { __typename?: 'InvoiceBuilderFixedFeeServices' }
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderFixedFeeService' }
    & FixedFeeEntryFragmentFragment
  )>, groupedPhaseEntries: Array<(
    { __typename?: 'InvoiceBuilderFixedFeeGroupedEntryType' }
    & Pick<InvoiceBuilderFixedFeeGroupedEntryType, 'hideFixedFeeServiceEntry'>
    & { fixedFeeServiceEntry: (
      { __typename?: 'InvoiceBuilderFixedFeeService' }
      & FixedFeeEntryFragmentFragment
    ), consultantEntries: Array<(
      { __typename?: 'InvoiceBuilderProjectConsultantType' }
      & Pick<InvoiceBuilderProjectConsultantType, 'currentDue' | 'currentCompletePercent' | 'planned' | 'id'>
      & { consultant: (
        { __typename?: 'ProjectConsultant' }
        & Pick<ProjectConsultant, 'id'>
        & { consultantTemplate: (
          { __typename?: 'ConsultantTemplate' }
          & Pick<ConsultantTemplate, 'id' | 'name'>
        ) }
      ), previouslyBilled: (
        { __typename?: 'InvoiceBuilderPreviouslyBilled' }
        & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
      ) }
    )>, phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )>, totals: (
      { __typename?: 'InvoiceBuilderPhaseTotals' }
      & Pick<InvoiceBuilderPhaseTotals, 'currentCompletePercent' | 'planned' | 'currentDue'>
      & { previouslyBilled: (
        { __typename?: 'InvoiceBuilderPreviouslyBilled' }
        & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
      ) }
    ) }
  )>, totals: (
    { __typename?: 'InvoiceBuilderServiceTotals' }
    & InvoiceTotalFragmentFragment
  ) }
  & InvoiceBuilderPhaseTotalsFragmentFragment
);

export type OtherItemEntriesFragmentFragment = (
  { __typename?: 'InvoiceBuilderOtherItemsType' }
  & Pick<InvoiceBuilderOtherItemsType, 'currentDue'>
  & { entries: Array<(
    { __typename?: 'LineItem' }
    & OtherItemFragmentFragment
  )> }
);

export type RetainerItemEntriesFragmentFragment = (
  { __typename?: 'InvoiceBuilderRetainerItemsType' }
  & Pick<InvoiceBuilderRetainerItemsType, 'currentDue'>
  & { entries: Array<(
    { __typename?: 'LineItem' }
    & RetainerItemFragmentFragment
  )> }
);

export type PhaseConsultantsFragmentFragment = (
  { __typename?: 'InvoiceBuilderPhaseConsultantType' }
  & { consultantCompany?: Maybe<(
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'name'>
  )>, consultantTemplate: (
    { __typename?: 'ConsultantTemplate' }
    & Pick<ConsultantTemplate, 'id' | 'name'>
  ), consultantTemplateTotals: (
    { __typename?: 'InvoiceBuilderPhaseConsultantTemplateTotals' }
    & Pick<InvoiceBuilderPhaseConsultantTemplateTotals, 'consultantFee' | 'currentDue' | 'markup' | 'markupPercentage' | 'planned' | 'currentCompletePercent'>
    & { previouslyBilled: (
      { __typename?: 'InvoiceBuilderPreviouslyBilled' }
      & Pick<InvoiceBuilderPreviouslyBilled, 'percent' | 'amount'>
    ) }
  ), entries: Array<(
    { __typename?: 'InvoiceBuilderProjectConsultantType' }
    & ProjectConsultantFragmentFragment
  )> }
);

export type ProjectConsultantFragmentFragment = (
  { __typename?: 'InvoiceBuilderProjectConsultantType' }
  & Pick<InvoiceBuilderProjectConsultantType, 'id' | 'planned' | 'currentDue' | 'currentCompletePercent' | 'markup' | 'markupPercentage' | 'consultantFee'>
  & { consultant: (
    { __typename?: 'ProjectConsultant' }
    & Pick<ProjectConsultant, 'id'>
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'name' | 'id'>
    )>, consultantTemplate: (
      { __typename?: 'ConsultantTemplate' }
      & Pick<ConsultantTemplate, 'name'>
    ) }
  ), currentDueInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), currentCompletePercentInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), previouslyBilled: (
    { __typename?: 'InvoiceBuilderPreviouslyBilled' }
    & Pick<InvoiceBuilderPreviouslyBilled, 'percent' | 'amount'>
  ) }
);

export type ProjectConsultantsFragmentFragment = (
  { __typename?: 'InvoiceBuilderProjectConsultants' }
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderProjectConsultantType' }
    & ProjectConsultantFragmentFragment
  )> }
  & ProjectConsultantsTotalFragmentFragment
);

export type CurrentActivityFragmentFragment = (
  { __typename?: 'Invoice' }
  & { currentActivity?: Maybe<(
    { __typename?: 'InvoiceActivity' }
    & Pick<InvoiceActivity, 'activityType' | 'createdAt' | 'email' | 'id' | 'amount' | 'previousAmount' | 'relevantDate' | 'previousRelevantDate'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'email'>
    )> }
  )> }
);

export type InvoiceActivitiesFragmentFragment = (
  { __typename?: 'Invoice' }
  & { invoiceActivities?: Maybe<Array<(
    { __typename?: 'InvoiceActivity' }
    & Pick<InvoiceActivity, 'activityType' | 'createdAt' | 'email' | 'id' | 'amount' | 'previousAmount' | 'relevantDate' | 'previousRelevantDate' | 'billingDestination' | 'thirdPartySource'>
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'email'>
    )> }
  )>> }
);

export type InvoiceTimelogDetailsFragment = (
  { __typename?: 'InvoiceBuilderTimelogDetails' }
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderTimelogDetailEntryType' }
    & InvoiceTimelogDetailsEntryFragment
  )>, groupedEntries: Array<(
    { __typename?: 'InvoiceBuilderTimelogDetailGroupedEntryType' }
    & InvoiceTimelogDetailsGroupedEntryFragment
  )> }
);

export type OutstandingInvoicesFragmentFragment = (
  { __typename?: 'InvoiceBuilderOustandingInvoices' }
  & Pick<InvoiceBuilderOustandingInvoices, 'total'>
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderOutstandingInvoiceType' }
    & Pick<InvoiceBuilderOutstandingInvoiceType, 'dueDate' | 'number' | 'paymentUrl' | 'remainingBalance'>
  )> }
);

export type InvoiceTimelogDetailsEntryFragment = (
  { __typename?: 'InvoiceBuilderTimelogDetailEntryType' }
  & Pick<InvoiceBuilderTimelogDetailEntryType, 'employee' | 'date' | 'role' | 'activity' | 'notes' | 'rate' | 'hours' | 'amount' | 'phase'>
);

export type InvoiceTimelogDetailsGroupedEntryFragment = (
  { __typename?: 'InvoiceBuilderTimelogDetailGroupedEntryType' }
  & Pick<InvoiceBuilderTimelogDetailGroupedEntryType, 'role' | 'total'>
  & { activityEntries: Array<(
    { __typename?: 'InvoiceBuilderTimelogDetailActivityEntryType' }
    & Pick<InvoiceBuilderTimelogDetailActivityEntryType, 'activity' | 'rate' | 'hours' | 'amount'>
  )> }
);

export type TaxRateFragment = (
  { __typename?: 'TaxRate' }
  & Pick<TaxRate, 'id' | 'name' | 'percent'>
);

export type TaxConfigurationFragment = (
  { __typename?: 'LineItemTaxConfiguration' }
  & Pick<LineItemTaxConfiguration, 'updatedAt' | 'fixedFeeServices' | 'hourlyServices' | 'consultingServices' | 'expenses' | 'otherItems' | 'retainerCollection'>
);

export type TaxItemFragment = (
  { __typename?: 'InvoiceBuilderTaxItem' }
  & Pick<InvoiceBuilderTaxItem, 'id' | 'amount'>
  & { taxRate?: Maybe<(
    { __typename?: 'TaxRate' }
    & TaxRateFragment
  )>, taxConfiguration?: Maybe<(
    { __typename?: 'LineItemTaxConfiguration' }
    & TaxConfigurationFragment
  )> }
);

export type TaxEntryPayloadFragment = (
  { __typename?: 'InvoiceBuilderTaxes' }
  & { entries: Array<(
    { __typename?: 'InvoiceBuilderTaxItem' }
    & TaxItemFragment
  )>, totals: (
    { __typename?: 'InvoiceBuilderTaxesTotals' }
    & Pick<InvoiceBuilderTaxesTotals, 'currentDue'>
  ) }
);

export type CurrentOrganizationLocaleFragment = (
  { __typename?: 'Locale' }
  & Pick<Locale, 'id' | 'name' | 'tag'>
);

export type QuickbooksItemFragment = (
  { __typename?: 'QuickbooksItem' }
  & Pick<QuickbooksItem, 'description' | 'id' | 'itemType' | 'name' | 'quickbooksId' | 'quickbooksParentRef'>
);

export type OrganizationSettingsFragment = (
  { __typename?: 'OrganizationSetting' }
  & Pick<OrganizationSetting, 'id' | 'activityRequired' | 'ajeraEnabled' | 'applyConsultantMappingForMarkup' | 'applyUtilizationRateToExistingProfiles' | 'defaultConsultantMarkup' | 'defaultHoursPerWeek' | 'defaultRealizationRate' | 'defaultUtilizationRate' | 'multiQboInstanceEnabled' | 'ptoEnabled' | 'timeOffToggledAt' | 'unitOfMeasurement' | 'yearlyWorkingHours' | 'checkId' | 'costsImportStartDate'>
  & { consultantMarkupQuickbooksServiceItem?: Maybe<(
    { __typename?: 'QuickbooksItem' }
    & QuickbooksItemFragment
  )>, reimbursableExpensesAccount?: Maybe<(
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'id' | 'name'>
  )>, nonReimbursableExpensesAccount?: Maybe<(
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'id' | 'name'>
  )> }
);

export type CurrentUserOrganizationsFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'logo'>
);

export type CurrentOrganizationFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'logo' | 'name' | 'size' | 'isDemo' | 'staffOrg' | 'connectedToQbo' | 'unassignedProjectCostsCount' | 'isSubscribed' | 'isQualified' | 'trialEndDate' | 'timer' | 'roleAudit' | 'profileCount' | 'profilesWithoutCompensationsCount' | 'profilesWithIncompleteOnboardingCount' | 'projectLimit' | 'projectsCount' | 'qboTaxMethod'>
  & { bqeDataProcessor?: Maybe<(
    { __typename?: 'CustomerExternalDataProcessor' }
    & Pick<CustomerExternalDataProcessor, 'updatedAt' | 'status'>
  )>, consultantBills: Array<(
    { __typename?: 'ConsultantBill' }
    & Pick<ConsultantBill, 'id'>
  )>, profiles: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'slug' | 'fullName' | 'id'>
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
  )>, demo?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'size'>
  )>, demoParent?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'size' | 'logo'>
  )>, subscription?: Maybe<(
    { __typename?: 'OrganizationSubscription' }
    & Pick<OrganizationSubscription, 'createdAt' | 'isActive' | 'hasFailedPayment' | 'prepaidSeatCount' | 'prepaidSeatsFilled' | 'planName'>
  )>, subscriptionForOrgOrDemoParent?: Maybe<(
    { __typename?: 'OrganizationSubscription' }
    & Pick<OrganizationSubscription, 'id' | 'createdAt' | 'isActive' | 'hasFailedPayment' | 'prepaidSeatCount' | 'prepaidSeatsFilled' | 'planName'>
  )>, currency: (
    { __typename?: 'Currency' }
    & CurrentOrganizationCurrencyFragment
  ), locale: (
    { __typename?: 'Locale' }
    & CurrentOrganizationLocaleFragment
  ), qboInstances: Array<(
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id'>
  )>, settings: (
    { __typename?: 'OrganizationSetting' }
    & Pick<OrganizationSetting, 'id' | 'checkId' | 'multiQboInstanceEnabled' | 'unitOfMeasurement' | 'costsImportStartDate'>
  ) }
);

export type PayrollPaystubFragment = (
  { __typename?: 'Paystub' }
  & Pick<Paystub, 'type' | 'amount' | 'date' | 'payrollId' | 'totalHours'>
);

export type PayrollAddressFragment = (
  { __typename?: 'PayrollAddress' }
  & Pick<PayrollAddress, 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
);

export type PayrollEmployeeFragment = (
  { __typename?: 'PayrollEmployeeInfo' }
  & Pick<PayrollEmployeeInfo, 'id' | 'active' | 'dob' | 'startDate' | 'firstName' | 'lastName' | 'checkOnboardingStatus' | 'ssnLastFour' | 'paymentMethodPreference'>
  & { residence: (
    { __typename?: 'PayrollAddress' }
    & PayrollAddressFragment
  ) }
);

export type PayrollContractorFragment = (
  { __typename?: 'PayrollWorkerInfo' }
  & Pick<PayrollWorkerInfo, 'id' | 'firstName' | 'lastName' | 'checkOnboardingStatus' | 'ssnLastFour' | 'ein' | 'paymentMethodPreference'>
  & { residence: (
    { __typename?: 'PayrollAddress' }
    & PayrollAddressFragment
  ) }
);

export type PayrollWorkerFragment = (
  { __typename?: 'PayrollWorkerInfo' }
  & Pick<PayrollWorkerInfo, 'id' | 'active' | 'dob' | 'startDate' | 'ein' | 'firstName' | 'lastName' | 'checkOnboardingStatus' | 'ssnLastFour' | 'paymentMethodPreference'>
  & { residence: (
    { __typename?: 'PayrollAddress' }
    & PayrollAddressFragment
  ), workplace?: Maybe<(
    { __typename?: 'Workplace' }
    & PayrollWorkplaceFragment
  )> }
);

export type PayrollProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fname' | 'lname' | 'title' | 'fullName' | 'checkId' | 'slug' | 'status'>
  & { settings?: Maybe<(
    { __typename?: 'ProfileSetting' }
    & Pick<ProfileSetting, 'checkEmploymentType' | 'checkContractorId' | 'checkEmployeeId'>
  )> }
);

export type PayrollInfoFragment = (
  { __typename?: 'PayrollCompanyInfo' }
  & Pick<PayrollCompanyInfo, 'id' | 'legalName' | 'email' | 'payFrequency'>
  & { onboarding: (
    { __typename?: 'PayrollCompanyOnboarding' }
    & Pick<PayrollCompanyOnboarding, 'status' | 'blockingSteps' | 'remainingForms' | 'remainingSteps' | 'remainingTaxJurisdictions' | 'bankAccount'>
  ), address: (
    { __typename?: 'PayrollAddress' }
    & PayrollAddressFragment
  ) }
);

export type PayrollEarningRateFragment = (
  { __typename?: 'PayrollEarningRate' }
  & Pick<PayrollEarningRate, 'amount' | 'period'>
);

export type PayrollWorkplaceFragment = (
  { __typename?: 'Workplace' }
  & Pick<Workplace, 'id' | 'name'>
  & { address: (
    { __typename?: 'PayrollAddress' }
    & PayrollAddressFragment
  ) }
);

export type PayrollPaydayFragment = (
  { __typename?: 'PaydayInfo' }
  & Pick<PaydayInfo, 'payday' | 'approvalDeadline' | 'impactedByHoliday'>
);

export type PayrollPaySchedulePaydayFragment = (
  { __typename?: 'PaySchedulePayday' }
  & Pick<PaySchedulePayday, 'payday' | 'periodStart' | 'periodEnd' | 'approvalDeadline' | 'impactedByWeekendOrHoliday'>
);

export type CompanyPayrollDocumentFragment = (
  { __typename?: 'PayrollDocument' }
  & Pick<PayrollDocument, 'id' | 'label' | 'year' | 'jurisdiction' | 'filedOn'>
);

export type PayrollBankAccountFragment = (
  { __typename?: 'PayrollBankAccount' }
  & Pick<PayrollBankAccount, 'id' | 'workerId' | 'status'>
  & { info: (
    { __typename?: 'PayrollBankAccountInfo' }
    & Pick<PayrollBankAccountInfo, 'accountNumberLastFour' | 'institutionName' | 'type' | 'subtype'>
  ) }
);

export type PayrollPayScheduleFragment = (
  { __typename?: 'PaySchedule' }
  & Pick<PaySchedule, 'id' | 'firstPayday' | 'firstPayPeriodEnd' | 'payFrequency'>
);

export type CurrentProfilePermissionsFragment = (
  { __typename?: 'Permissions' }
  & Pick<Permissions, 'canEditAssignedProjects' | 'canDeleteAssignedProjects' | 'moneyGanttAssignedProjects' | 'canViewAllProjects' | 'canEditAllProjects' | 'canCreateAllProjects' | 'canDeleteAllProjects' | 'moneyGanttAllProjects' | 'canViewAllTimesheets' | 'canEditAllTimesheets' | 'canViewAllExpenses' | 'canManageAllExpenses' | 'canEditSettings' | 'canViewInvoices' | 'canEditInvoices' | 'canDeleteInvoices' | 'canCreateInvoices' | 'canManageProfiles' | 'canManageCompensations' | 'canViewFinancialReports' | 'canViewTeamReports' | 'canViewAllClients' | 'canManageAllClients' | 'canSendToQuickbooks' | 'canManageAllConsultants' | 'canManageStaffing' | 'canViewFinancialInformation' | 'canViewGanttLoggedTimeAssignedProjects' | 'canViewGanttLoggedTimeAllProjects' | 'canViewGanttInvoicedPaidAssignedProjects' | 'canViewGanttInvoicedPaidAllProjects' | 'canManagePto'>
);

export type CurrentProfilePolicyFragment = (
  { __typename?: 'Policy' }
  & Pick<Policy, 'id' | 'isAdmin' | 'name'>
  & { permissions: (
    { __typename?: 'Permissions' }
    & CurrentProfilePermissionsFragment
  ) }
);

export type CurrentProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fname' | 'lname' | 'name' | 'initials' | 'slug' | 'email' | 'title' | 'intercomUserHash' | 'image' | 'confirmedAt'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & CurrentOrganizationFragment
  )>, policy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'isAdmin'>
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & CurrentUserFragment
  )> }
);

export type ProfileRateFragment = (
  { __typename?: 'Profile' }
  & { defaultRateTable?: Maybe<(
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id' | 'name'>
    & { currentRate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'id' | 'amount'>
    ) }
  )> }
);

export type CurrentUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'isAdmin' | 'isClient' | 'isTeamMember' | 'payrollAdmin' | 'fullName' | 'fname' | 'lname' | 'email' | 'loggedInAsSomeoneElse'>
);

export type GanttProjectRowFragment = (
  { __typename?: 'GanttChartRow' }
  & Pick<GanttChartRow, 'assigned' | 'hoursConsumed' | 'hoursPlanned' | 'href' | 'id' | 'number' | 'status' | 'color' | 'name' | 'startDate' | 'endDate' | 'showMoneyGantt' | 'showMoneyGanttHoverCard' | 'showInvoicedData'>
  & { phases: Array<(
    { __typename?: 'GanttChartPhase' }
    & Pick<GanttChartPhase, 'startDate' | 'endDate' | 'hoursPlanned' | 'hoursConsumed' | 'planned' | 'logged' | 'invoiced' | 'paid'>
    & { type: (
      { __typename?: 'GanttChartPhaseDetail' }
      & Pick<GanttChartPhaseDetail, 'id' | 'name' | 'abbr' | 'feeType' | 'status'>
    ), milestones: Array<(
      { __typename?: 'GanttChartMilestone' }
      & Pick<GanttChartMilestone, 'name' | 'date' | 'completed'>
    )> }
  )> }
);

export type GanttChartQueryVariables = Exact<{
  isTemplate?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<GanttChartFiltersInput>;
}>;


export type GanttChartQuery = (
  { __typename?: 'Query' }
  & { ganttChart: (
    { __typename?: 'GanttChartResponse' }
    & Pick<GanttChartResponse, 'totalProjectsCount'>
    & { rows: Array<(
      { __typename?: 'GanttChartRow' }
      & GanttProjectRowFragment
    )> }
  ) }
);

export type ProjectStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectStatusesQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )> }
);

export type CategoryOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryOptionsQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type ClientOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientOptionsQuery = (
  { __typename?: 'Query' }
  & { allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'syncedToQbo' | 'displayName' | 'id'>
  )>> }
);

export type PhaseTemplateOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PhaseTemplateOptionsQuery = (
  { __typename?: 'Query' }
  & { phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id' | 'name'>
  )> }
);

export type InvoiceBuilderQueryVariables = Exact<{
  slug: Scalars['String'];
  previewMode?: Maybe<Scalars['Boolean']>;
}>;


export type InvoiceBuilderQuery = (
  { __typename?: 'Query' }
  & { invoiceBuilder?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & Pick<InvoiceBuilderPayload, 'loadingTotals' | 'convenienceFee' | 'totalDue' | 'totalDueWithoutAppliedRetainer' | 'totalDueWithConvenienceFee' | 'subtotal'>
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'suggestVersionSwitch'>
      & InvoiceBuilderInvoiceFragmentFragment
    ), fixedFeeServices: (
      { __typename?: 'InvoiceBuilderFixedFeeServices' }
      & FixedFeeServicesEntriesFragmentFragment
    ), hourlyServices: (
      { __typename?: 'InvoiceBuilderHourlyServices' }
      & HourlyServicesEntriesFragmentFragment
    ), expenses: (
      { __typename?: 'InvoiceBuilderExpensesType' }
      & { included: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>, reported: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )> }
    ), phaseConsultants: (
      { __typename?: 'InvoiceBuilderPhaseConsultants' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderPhaseConsultantType' }
        & PhaseConsultantsFragmentFragment
      )> }
      & PhaseConsultantsTotalFragmentFragment
    ), projectConsultants: (
      { __typename?: 'InvoiceBuilderProjectConsultants' }
      & ProjectConsultantsFragmentFragment
    ), otherItems: (
      { __typename?: 'InvoiceBuilderOtherItemsType' }
      & OtherItemEntriesFragmentFragment
    ), retainerItems: (
      { __typename?: 'InvoiceBuilderRetainerItemsType' }
      & RetainerItemEntriesFragmentFragment
    ), taxes: (
      { __typename?: 'InvoiceBuilderTaxes' }
      & TaxEntryPayloadFragment
    ), retainerSummary?: Maybe<(
      { __typename?: 'RetainerSummary' }
      & RetainerSummaryFragmentFragment
    )> }
    & TabsTotalsFragmentFragment
  )> }
);

export type PreviousInvoiceDetailsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'beginDate' | 'endDate' | 'id' | 'number' | 'slug'>
);

export type PreviousInvoicesForProjectQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
}>;


export type PreviousInvoicesForProjectQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & PreviousInvoiceDetailsFragment
    )>>> }
  ) }
);

export type ArtHoursServicesRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'hours' | 'name'>
  & { rateTable: (
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id' | 'name' | 'slug'>
  ), ratesDuring: Array<(
    { __typename?: 'Rate' }
    & Pick<Rate, 'amount' | 'effectiveOn' | 'displayDate'>
  )> }
);

export type ArtInvoiceBuilderQueryVariables = Exact<{
  slug: Scalars['String'];
  previewMode?: Maybe<Scalars['Boolean']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type ArtInvoiceBuilderQuery = (
  { __typename?: 'Query' }
  & { invoiceBuilder?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & Pick<InvoiceBuilderPayload, 'loadingTotals' | 'totalDue'>
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'suggestVersionSwitch'>
      & InvoiceBuilderInvoiceFragmentFragment
    ), fixedFeeServices: (
      { __typename?: 'InvoiceBuilderFixedFeeServices' }
      & FixedFeeServicesEntriesFragmentFragment
    ), hourlyServices: (
      { __typename?: 'InvoiceBuilderHourlyServices' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderHourlyService' }
        & { roleEntries: Array<(
          { __typename?: 'InvoiceBuilderRoleEntry' }
          & Pick<InvoiceBuilderRoleEntry, 'id' | 'hours' | 'previouslyBilledHours' | 'previouslyBilledAmount' | 'roleRate' | 'amount'>
          & { logged: (
            { __typename?: 'InvoiceBuilderTooltip' }
            & Pick<InvoiceBuilderTooltip, 'total' | 'duringInvoiceRange' | 'beforeInvoiceBeginDate'>
          ), role: (
            { __typename?: 'Role' }
            & ArtHoursServicesRoleFragment
          ) }
        )>, consultantEntries: Array<(
          { __typename?: 'InvoiceBuilderProjectConsultantType' }
          & HourlyServicesConsultantEntriesFragmentFragment
        )> }
        & HourlyServicesEntryFragmentFragment
      )>, totals: (
        { __typename?: 'InvoiceBuilderServiceTotals' }
        & InvoiceTotalFragmentFragment
      ) }
    ), expenses: (
      { __typename?: 'InvoiceBuilderExpensesType' }
      & { included: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>, reported: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )> }
    ), phaseConsultants: (
      { __typename?: 'InvoiceBuilderPhaseConsultants' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderPhaseConsultantType' }
        & PhaseConsultantsFragmentFragment
      )> }
      & PhaseConsultantsTotalFragmentFragment
    ), projectConsultants: (
      { __typename?: 'InvoiceBuilderProjectConsultants' }
      & ProjectConsultantsFragmentFragment
    ), otherItems: (
      { __typename?: 'InvoiceBuilderOtherItemsType' }
      & OtherItemEntriesFragmentFragment
    ) }
    & TabsTotalsFragmentFragment
  )> }
);

export type UpdateFixedFeeServiceMutationVariables = Exact<{
  input: InvoiceBuilderUpdateFixedFeeServiceInput;
}>;


export type UpdateFixedFeeServiceMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateFixedFeeService?: Maybe<(
      { __typename?: 'InvoiceBuilderUpdateFixedFeeServicePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { invoice: (
          { __typename?: 'Invoice' }
          & InvoiceBuilderInvoiceFragmentFragment
        ), fixedFeeServices: (
          { __typename?: 'InvoiceBuilderFixedFeeServices' }
          & FixedFeeServicesEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type UpdateProjectConsultantMutationVariables = Exact<{
  input: InvoiceBuilderUpdateProjectConsultantInput;
}>;


export type UpdateProjectConsultantMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateProjectConsultant?: Maybe<(
      { __typename?: 'InvoiceBuilderUpdateProjectConsultantPayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { projectConsultants: (
          { __typename?: 'InvoiceBuilderProjectConsultants' }
          & ProjectConsultantsFragmentFragment
        ), phaseConsultants: (
          { __typename?: 'InvoiceBuilderPhaseConsultants' }
          & { entries: Array<(
            { __typename?: 'InvoiceBuilderPhaseConsultantType' }
            & PhaseConsultantsFragmentFragment
          )> }
          & PhaseConsultantsTotalFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type UpdateInvoiceDateRangeMutationVariables = Exact<{
  input: InvoiceUpdateInput;
}>;


export type UpdateInvoiceDateRangeMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'InvoiceUpdatePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & InvoiceBuilderInvoiceFragmentFragment
    ) }
  )> }
);

export type AddOtherItemMutationVariables = Exact<{
  input: InvoiceBuilderOtherItemsCreateInput;
}>;


export type AddOtherItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { createOtherItem?: Maybe<(
      { __typename?: 'InvoiceBuilderOtherItemsCreatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue'>
        & { otherItems: (
          { __typename?: 'InvoiceBuilderOtherItemsType' }
          & OtherItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type UpdateOtherItemMutationVariables = Exact<{
  input: InvoiceBuilderOtherItemsUpdateInput;
}>;


export type UpdateOtherItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateOtherItem?: Maybe<(
      { __typename?: 'InvoiceBuilderOtherItemsUpdatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { otherItems: (
          { __typename?: 'InvoiceBuilderOtherItemsType' }
          & OtherItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type DeleteOtherItemMutationVariables = Exact<{
  input: InvoiceBuilderOtherItemsDeleteInput;
}>;


export type DeleteOtherItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { deleteOtherItem?: Maybe<(
      { __typename?: 'InvoiceBuilderOtherItemsDeletePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { otherItems: (
          { __typename?: 'InvoiceBuilderOtherItemsType' }
          & OtherItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type AddRetainerItemMutationVariables = Exact<{
  input: InvoiceBuilderRetainersCreateInput;
}>;


export type AddRetainerItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { createRetainer?: Maybe<(
      { __typename?: 'InvoiceBuilderRetainersCreatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { retainerItems: (
          { __typename?: 'InvoiceBuilderRetainerItemsType' }
          & RetainerItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type UpdateRetainerItemMutationVariables = Exact<{
  input: InvoiceBuilderRetainersUpdateInput;
}>;


export type UpdateRetainerItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateRetainer?: Maybe<(
      { __typename?: 'InvoiceBuilderRetainersUpdatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { retainerItems: (
          { __typename?: 'InvoiceBuilderRetainerItemsType' }
          & RetainerItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type DeleteRetainerItemMutationVariables = Exact<{
  input: InvoiceBuilderRetainersDeleteInput;
}>;


export type DeleteRetainerItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { deleteRetainer?: Maybe<(
      { __typename?: 'InvoiceBuilderRetainersDeletePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { retainerItems: (
          { __typename?: 'InvoiceBuilderRetainerItemsType' }
          & RetainerItemEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type ApplyRetainerMutationVariables = Exact<{
  input: InvoiceBuilderRetainersApplyInput;
}>;


export type ApplyRetainerMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { applyRetainer?: Maybe<(
      { __typename?: 'InvoiceBuilderRetainersApplyPayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { appliedRetainer?: Maybe<(
          { __typename?: 'LineItem' }
          & AppliedRetainerItemFragmentFragment
        )> }
      )> }
    )> }
  ) }
);

export type ExpensesMutationPayloadFragment = (
  { __typename?: 'InvoiceBuilderPayload' }
  & { expenses: (
    { __typename?: 'InvoiceBuilderExpensesType' }
    & { included: Array<(
      { __typename?: 'Expense' }
      & ExpenseFragmentFragment
    )>, reported: Array<(
      { __typename?: 'Expense' }
      & ExpenseFragmentFragment
    )> }
  ) }
);

export type InvoiceAddExpenseMutationVariables = Exact<{
  input: InvoiceBuilderExpensesAddInput;
}>;


export type InvoiceAddExpenseMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { addExpense?: Maybe<(
      { __typename?: 'InvoiceBuilderExpensesAddPayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & ExpensesMutationPayloadFragment
      )> }
    )> }
  ) }
);

export type InvoiceRemoveExpenseMutationVariables = Exact<{
  input: InvoiceBuilderExpensesDeleteInput;
}>;


export type InvoiceRemoveExpenseMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { deleteExpense?: Maybe<(
      { __typename?: 'InvoiceBuilderExpensesDeletePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & ExpensesMutationPayloadFragment
      )> }
    )> }
  ) }
);

export type InvoiceAddAllExpensesMutationVariables = Exact<{
  input: InvoiceBuilderExpensesAddAllInput;
}>;


export type InvoiceAddAllExpensesMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { addAllExpenses?: Maybe<(
      { __typename?: 'InvoiceBuilderExpensesAddAllPayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & ExpensesMutationPayloadFragment
      )> }
    )> }
  ) }
);

export type UpdateHoursForRoleEntryMutationVariables = Exact<{
  input: InvoiceBuilderUpdateHourlyServiceInput;
}>;


export type UpdateHoursForRoleEntryMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateHourlyService?: Maybe<(
      { __typename?: 'InvoiceBuilderUpdateHourlyServicePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer'>
        & { hourlyServices: (
          { __typename?: 'InvoiceBuilderHourlyServices' }
          & HourlyServicesEntriesFragmentFragment
        ) }
      )> }
    )> }
  ) }
);

export type InvoiceBuilderChangeInvoiceVersionMutationVariables = Exact<{
  input: InvoiceChangeVersionInput;
}>;


export type InvoiceBuilderChangeInvoiceVersionMutation = (
  { __typename?: 'Mutation' }
  & { changeInvoiceVersion?: Maybe<(
    { __typename?: 'InvoiceChangeVersionPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'invoiceVersion'>
    ) }
  )> }
);

export type AddTaxItemMutationVariables = Exact<{
  input: InvoiceBuilderTaxItemsCreateInput;
}>;


export type AddTaxItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { createTaxItem?: Maybe<(
      { __typename?: 'InvoiceBuilderTaxItemsCreatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & { taxes: (
          { __typename?: 'InvoiceBuilderTaxes' }
          & TaxEntryPayloadFragment
        ) }
      )> }
    )> }
  ) }
);

export type DeleteTaxItemMutationVariables = Exact<{
  input: InvoiceBuilderTaxItemsDeleteInput;
}>;


export type DeleteTaxItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { deleteTaxItem?: Maybe<(
      { __typename?: 'InvoiceBuilderTaxItemsDeletePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & { taxes: (
          { __typename?: 'InvoiceBuilderTaxes' }
          & TaxEntryPayloadFragment
        ) }
      )> }
    )> }
  ) }
);

export type UpdateTaxItemMutationVariables = Exact<{
  input: InvoiceBuilderTaxItemsUpdateInput;
}>;


export type UpdateTaxItemMutation = (
  { __typename?: 'Mutation' }
  & { invoiceBuilder: (
    { __typename?: 'InvoiceBuilderMutations' }
    & { updateTaxItem?: Maybe<(
      { __typename?: 'InvoiceBuilderTaxItemsUpdatePayload' }
      & { payload?: Maybe<(
        { __typename?: 'InvoiceBuilderPayload' }
        & { taxes: (
          { __typename?: 'InvoiceBuilderTaxes' }
          & TaxEntryPayloadFragment
        ) }
      )> }
    )> }
  ) }
);

export type InvoiceDesignerQueryVariables = Exact<{
  slug: Scalars['String'];
  previewMode?: Maybe<Scalars['Boolean']>;
}>;


export type InvoiceDesignerQuery = (
  { __typename?: 'Query' }
  & { invoiceBuilder?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & Pick<InvoiceBuilderPayload, 'loadingTotals' | 'totalDue' | 'totalDueWithoutAppliedRetainer' | 'convenienceFee' | 'totalDueWithConvenienceFee' | 'subtotal'>
    & { invoice: (
      { __typename?: 'Invoice' }
      & InvoiceFragmentFragment
    ), fixedFeeServices: (
      { __typename?: 'InvoiceBuilderFixedFeeServices' }
      & FixedFeeServicesEntriesFragmentFragment
    ), hourlyServices: (
      { __typename?: 'InvoiceBuilderHourlyServices' }
      & HourlyServicesEntriesFragmentFragment
    ), expenses: (
      { __typename?: 'InvoiceBuilderExpensesType' }
      & { included: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>, reported: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )> }
    ), phaseConsultants: (
      { __typename?: 'InvoiceBuilderPhaseConsultants' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderPhaseConsultantType' }
        & PhaseConsultantsFragmentFragment
      )> }
      & PhaseConsultantsTotalFragmentFragment
    ), projectConsultants: (
      { __typename?: 'InvoiceBuilderProjectConsultants' }
      & ProjectConsultantsFragmentFragment
    ), otherItems: (
      { __typename?: 'InvoiceBuilderOtherItemsType' }
      & OtherItemEntriesFragmentFragment
    ), retainerItems: (
      { __typename?: 'InvoiceBuilderRetainerItemsType' }
      & RetainerItemEntriesFragmentFragment
    ), appliedRetainer?: Maybe<(
      { __typename?: 'LineItem' }
      & AppliedRetainerItemFragmentFragment
    )>, retainerSummary?: Maybe<(
      { __typename?: 'RetainerSummary' }
      & RetainerSummaryFragmentFragment
    )>, taxes: (
      { __typename?: 'InvoiceBuilderTaxes' }
      & TaxEntryPayloadFragment
    ), timelogDetails: (
      { __typename?: 'InvoiceBuilderTimelogDetails' }
      & InvoiceTimelogDetailsFragment
    ), outstandingInvoices: (
      { __typename?: 'InvoiceBuilderOustandingInvoices' }
      & OutstandingInvoicesFragmentFragment
    ) }
    & TabsTotalsFragmentFragment
  )> }
);

export type BillingDestinationCustomerFragment = (
  { __typename?: 'QBOCustomer' }
  & Pick<QboCustomer, 'displayName' | 'id' | 'level' | 'parentQuickbooksId' | 'qboInstanceId' | 'quickbooksId'>
);

export type BillingDestinationCustomerListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BillingDestinationCustomerListQuery = (
  { __typename?: 'Query' }
  & { quickbooksCustomer: (
    { __typename?: 'QBOCustomer' }
    & { descendants?: Maybe<Array<(
      { __typename?: 'QBOCustomer' }
      & BillingDestinationCustomerFragment
    )>> }
    & BillingDestinationCustomerFragment
  ) }
);

export type OrganizationStripeAccountFragmentFragment = (
  { __typename?: 'StripeAccount' }
  & Pick<StripeAccount, 'id' | 'detailsSubmitted' | 'chargesEnabled' | 'payoutsEnabled' | 'currencyCode'>
);

export type InvoiceOrganizationFragmentFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'id' | 'name' | 'logo' | 'connectedToQbo' | 'invoiceFooter' | 'isSubscribed' | 'applyAchOffer'>
  & { stripeAccount?: Maybe<(
    { __typename?: 'StripeAccount' }
    & OrganizationStripeAccountFragmentFragment
  )>, settings: (
    { __typename?: 'OrganizationSetting' }
    & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
  ), terms?: Maybe<Array<(
    { __typename?: 'Term' }
    & Pick<Term, 'id' | 'name' | 'days' | 'active'>
    & { qboTerm?: Maybe<(
      { __typename?: 'QBOTerm' }
      & { qboInstance: (
        { __typename?: 'QBOInstance' }
        & Pick<QboInstance, 'id'>
      ) }
    )> }
  )>>, address?: Maybe<(
    { __typename?: 'Address' }
    & InvoiceAddressFragmentFragment
  )>, clients?: Maybe<Array<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'syncedToQbo' | 'displayName' | 'organizationName'>
  )>> }
);

export type InvoiceAddressFragmentFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
);

export type InvoiceClientFragmentFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'displayName' | 'id' | 'syncedToQbo' | 'fname' | 'fullyQualifiedName' | 'fullName' | 'lname' | 'slug' | 'organizationName' | 'department' | 'email'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & InvoiceAddressFragmentFragment
  )>, qboCustomer?: Maybe<(
    { __typename?: 'QBOCustomer' }
    & Pick<QboCustomer, 'id'>
    & { qboInstance: (
      { __typename?: 'QBOInstance' }
      & Pick<QboInstance, 'id'>
    ) }
  )> }
);

export type InvoicePaymentMethodsFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'allowCardPayments' | 'allowBankTransfers' | 'passConvenienceFeeAch' | 'passConvenienceFeeCard'>
);

export type InvoiceBasicInfoFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'message' | 'referenceNumber' | 'issueDate' | 'dueDate' | 'beginDate' | 'endDate'>
  & { term?: Maybe<(
    { __typename?: 'Term' }
    & Pick<Term, 'id'>
  )> }
);

export type InvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'deliverTo' | 'deliverToCc' | 'id' | 'internalNote' | 'invoiceVersion' | 'lastSyncedToQuickbooksAt' | 'lastLineItemUpdate' | 'slug' | 'total' | 'totalPaid' | 'remainingBalance' | 'status' | 'paidDate' | 'paymentUrl' | 'paymentIntentStatus' | 'billingDestination' | 'footer'>
  & { invoicePayments?: Maybe<Array<(
    { __typename?: 'InvoicePayment' }
    & Pick<InvoicePayment, 'amount' | 'id' | 'paidDate' | 'isQboPayment'>
  )>>, client?: Maybe<(
    { __typename?: 'Client' }
    & InvoiceClientFragmentFragment
  )>, project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'number' | 'color' | 'v1InvoicesWithUnassignedLineItemsCount' | 'slug'>
    & { paymentEmailSettings: (
      { __typename?: 'PaymentEmailSettings' }
      & Pick<PaymentEmailSettings, 'customBody' | 'defaultBodyWithPayment' | 'defaultBodyWithoutPayment'>
    ), retainerSummary?: Maybe<(
      { __typename?: 'RetainerSummary' }
      & Pick<RetainerSummary, 'remaining' | 'received' | 'applied'>
    )> }
  ), organization: (
    { __typename?: 'Organization' }
    & InvoiceOrganizationFragmentFragment
  ), term?: Maybe<(
    { __typename?: 'Term' }
    & Pick<Term, 'id' | 'days' | 'name'>
  )>, attachedExpenses?: Maybe<Array<(
    { __typename?: 'Expense' }
    & ExpenseFragmentFragment
  )>>, invoiceAttachments?: Maybe<Array<(
    { __typename?: 'InvoiceAttachment' }
    & Pick<InvoiceAttachment, 'id' | 'url' | 'filename'>
  )>>, qboInstance?: Maybe<(
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id' | 'qboTaxMethod'>
  )>, invoiceQboDetails?: Maybe<(
    { __typename?: 'InvoiceQBODetails' }
    & Pick<InvoiceQboDetails, 'id'>
    & { accountingDestinationCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & BillingDestinationCustomerFragment
    )> }
  )> }
  & InvoicePaymentMethodsFragmentFragment
  & InvoiceActivitiesFragmentFragment
  & InvoiceBasicInfoFragmentFragment
  & InvoiceWithDisplayOptionsFragmentFragment
);

export type InvoiceQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  ) }
);

export type InvoicePaymentMethodsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;


export type InvoicePaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
    & InvoicePaymentMethodsFragmentFragment
  ) }
);

export type UpdateInvoiceMutationVariables = Exact<{
  data: InvoiceUpdateInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'InvoiceUpdatePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & { client?: Maybe<(
        { __typename?: 'Client' }
        & InvoiceClientFragmentFragment
      )> }
      & InvoiceBasicInfoFragmentFragment
    ) }
  )> }
);

export type UpdateInvoicePaymentMethodsMutationVariables = Exact<{
  data: InvoiceUpdateInput;
}>;


export type UpdateInvoicePaymentMethodsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'InvoiceUpdatePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & InvoicePaymentMethodsFragmentFragment
    ) }
  )> }
);

export type SendToQboMutationVariables = Exact<{
  data: InvoiceSendToQuickbooksInput;
}>;


export type SendToQboMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoiceToQuickbooks?: Maybe<(
    { __typename?: 'InvoiceSendToQuickbooksPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'lastSyncedToQuickbooksAt'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'connectedToQbo'>
      ) }
    )> }
  )> }
);

export type BatchSendToQboMutationVariables = Exact<{
  data: InvoiceBatchSendToQuickbooksInput;
}>;


export type BatchSendToQboMutation = (
  { __typename?: 'Mutation' }
  & { batchSendInvoiceToQuickbooks?: Maybe<(
    { __typename?: 'InvoiceBatchSendToQuickbooksPayload' }
    & Pick<InvoiceBatchSendToQuickbooksPayload, 'enqueued'>
  )> }
);

export type XeroExportMutationVariables = Exact<{
  input: XeroInput;
}>;


export type XeroExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { invoiceXero?: Maybe<(
      { __typename?: 'XeroPayload' }
      & Pick<XeroPayload, 'id'>
    )> }
  ) }
);

export type QboExportMutationVariables = Exact<{
  input: QboInput;
}>;


export type QboExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { invoiceQbo?: Maybe<(
      { __typename?: 'QBOPayload' }
      & Pick<QboPayload, 'id'>
    )> }
  ) }
);

export type InvoiceWithDisplayOptionsFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id'>
  & { displayOptions: (
    { __typename?: 'InvoiceDisplayOptions' }
    & Pick<InvoiceDisplayOptions, 'showAllFixedFeeLineItems' | 'hidePreviouslyBilled' | 'groupTimelogByRoleAndActivity' | 'includeReceiptImages' | 'phaseConsultantsDisplay' | 'useCompactLayout' | 'includeTimelog' | 'hideServicesThrough' | 'showRetainerSummary' | 'showOutstandingInvoices'>
  ) }
);

export type UpdateDisplayOptionsMutationVariables = Exact<{
  input: InvoiceUpdateDisplayOptionsInput;
}>;


export type UpdateDisplayOptionsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceDisplayOptions?: Maybe<(
    { __typename?: 'InvoiceUpdateDisplayOptionsPayload' }
    & { payload?: Maybe<(
      { __typename?: 'InvoiceBuilderPayload' }
      & { invoice: (
        { __typename?: 'Invoice' }
        & InvoiceWithDisplayOptionsFragmentFragment
      ) }
    )> }
  )> }
);

export type UpdateInvoiceInternalNoteMutationVariables = Exact<{
  input: InvoiceUpdateInternalNoteInput;
}>;


export type UpdateInvoiceInternalNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceInternalNote?: Maybe<(
    { __typename?: 'InvoiceUpdateInternalNotePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'internalNote'>
    ) }
  )> }
);

export type UpdateInvoiceBillingDestinationMutationVariables = Exact<{
  input: InvoiceUpdateBillingDestinationInput;
}>;


export type UpdateInvoiceBillingDestinationMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceBillingDestination?: Maybe<(
    { __typename?: 'InvoiceUpdateBillingDestinationPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'billingDestination'>
      & { invoiceQboDetails?: Maybe<(
        { __typename?: 'InvoiceQBODetails' }
        & Pick<InvoiceQboDetails, 'id'>
        & { accountingDestinationCustomer?: Maybe<(
          { __typename?: 'QBOCustomer' }
          & BillingDestinationCustomerFragment
        )> }
      )> }
    ) }
  )> }
);

export type ItemFailuresFragment = (
  { __typename?: 'InvoicesToQBOFailures' }
  & Pick<InvoicesToQboFailures, 'message'>
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'slug' | 'number'>
  ) }
);

export type BatchInvoicesToQboJobSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type BatchInvoicesToQboJobSubscription = (
  { __typename?: 'Subscription' }
  & { batchInvoicesToQboJob: (
    { __typename?: 'BatchInvoicesToQBOJobPayload' }
    & { result?: Maybe<(
      { __typename?: 'InvoicesToQBO' }
      & Pick<InvoicesToQbo, 'status' | 'error'>
      & { itemFailures?: Maybe<Array<(
        { __typename?: 'InvoicesToQBOFailures' }
        & ItemFailuresFragment
      )>> }
    )> }
  ) }
);

export type PubicProjectInvoiceFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'name' | 'number' | 'color'>
);

export type PublicInvoiceOrganizationDetailsFragment = (
  { __typename?: 'Invoice' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'logo' | 'invoiceFooter'>
    & { currency: (
      { __typename?: 'Currency' }
      & CurrentOrganizationCurrencyFragment
    ), locale: (
      { __typename?: 'Locale' }
      & CurrentOrganizationLocaleFragment
    ) }
  ) }
);

export type PublicInvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'invoiceVersion' | 'number' | 'referenceNumber' | 'allowCardPayments' | 'allowBankTransfers' | 'slug' | 'total' | 'remainingBalance' | 'totalPaid' | 'status' | 'issueDate' | 'dueDate' | 'paidDate' | 'beginDate' | 'endDate' | 'message' | 'footer' | 'paymentIntentStatus' | 'passConvenienceFeeCard' | 'passConvenienceFeeAch'>
  & { invoicePayments?: Maybe<Array<(
    { __typename?: 'InvoicePayment' }
    & Pick<InvoicePayment, 'amount' | 'id' | 'paidDate'>
  )>>, displayOptions: (
    { __typename?: 'InvoiceDisplayOptions' }
    & Pick<InvoiceDisplayOptions, 'showAllFixedFeeLineItems' | 'hidePreviouslyBilled' | 'includeReceiptImages' | 'groupTimelogByRoleAndActivity' | 'phaseConsultantsDisplay' | 'useCompactLayout' | 'includeTimelog' | 'hideServicesThrough' | 'showRetainerSummary' | 'showOutstandingInvoices'>
  ), client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'displayName' | 'fullyQualifiedName' | 'id' | 'name' | 'fname' | 'lname' | 'fullName' | 'organizationName' | 'email'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
    )> }
  )>, project: (
    { __typename?: 'Project' }
    & PubicProjectInvoiceFragmentFragment
  ), organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'name' | 'logo' | 'invoiceFooter'>
    & { currency: (
      { __typename?: 'Currency' }
      & CurrentOrganizationCurrencyFragment
    ), locale: (
      { __typename?: 'Locale' }
      & CurrentOrganizationLocaleFragment
    ), terms?: Maybe<Array<(
      { __typename?: 'Term' }
      & Pick<Term, 'id' | 'name' | 'days' | 'active'>
    )>>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
    )> }
  ), term?: Maybe<(
    { __typename?: 'Term' }
    & Pick<Term, 'id' | 'days' | 'name'>
  )>, attachedExpenses?: Maybe<Array<(
    { __typename?: 'Expense' }
    & ExpenseFragmentFragment
  )>>, invoiceAttachments?: Maybe<Array<(
    { __typename?: 'InvoiceAttachment' }
    & Pick<InvoiceAttachment, 'id' | 'url' | 'filename'>
  )>> }
);

export type PublicFixedFeeEntryFragmentFragment = (
  { __typename?: 'InvoiceBuilderFixedFeeService' }
  & Pick<InvoiceBuilderFixedFeeService, 'planned' | 'currentCompletePercent' | 'currentDue'>
  & { previouslyBilled: (
    { __typename?: 'InvoiceBuilderPreviouslyBilled' }
    & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
  ), currentDueInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), currentCompletePercentInput: (
    { __typename?: 'InvoiceBuilderClientInput' }
    & Pick<InvoiceBuilderClientInput, 'value' | 'touched'>
  ), phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'name'>
  ) }
);

export type PublicInvoicePaymentDetailsQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type PublicInvoicePaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { publicInvoice?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & PublicInvoiceOrganizationDetailsFragment
    ) }
  )> }
);

export type PublicInvoiceQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;


export type PublicInvoiceQuery = (
  { __typename?: 'Query' }
  & { publicInvoice?: Maybe<(
    { __typename?: 'InvoiceBuilderPayload' }
    & Pick<InvoiceBuilderPayload, 'totalDue' | 'totalDueWithoutAppliedRetainer' | 'convenienceFee' | 'totalDueWithConvenienceFee' | 'subtotal'>
    & { invoice: (
      { __typename?: 'Invoice' }
      & PublicInvoiceFragmentFragment
    ), fixedFeeServices: (
      { __typename?: 'InvoiceBuilderFixedFeeServices' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderFixedFeeService' }
        & PublicFixedFeeEntryFragmentFragment
      )>, groupedPhaseEntries: Array<(
        { __typename?: 'InvoiceBuilderFixedFeeGroupedEntryType' }
        & { fixedFeeServiceEntry: (
          { __typename?: 'InvoiceBuilderFixedFeeService' }
          & FixedFeeEntryFragmentFragment
        ), consultantEntries: Array<(
          { __typename?: 'InvoiceBuilderProjectConsultantType' }
          & Pick<InvoiceBuilderProjectConsultantType, 'currentDue' | 'currentCompletePercent' | 'planned' | 'id'>
          & { consultant: (
            { __typename?: 'ProjectConsultant' }
            & { consultantTemplate: (
              { __typename?: 'ConsultantTemplate' }
              & Pick<ConsultantTemplate, 'id' | 'name'>
            ) }
          ), previouslyBilled: (
            { __typename?: 'InvoiceBuilderPreviouslyBilled' }
            & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
          ) }
        )>, phase?: Maybe<(
          { __typename?: 'Phase' }
          & Pick<Phase, 'id' | 'name'>
        )>, totals: (
          { __typename?: 'InvoiceBuilderPhaseTotals' }
          & Pick<InvoiceBuilderPhaseTotals, 'currentCompletePercent' | 'planned' | 'currentDue'>
          & { previouslyBilled: (
            { __typename?: 'InvoiceBuilderPreviouslyBilled' }
            & Pick<InvoiceBuilderPreviouslyBilled, 'amount' | 'percent'>
          ) }
        ) }
      )>, totals: (
        { __typename?: 'InvoiceBuilderServiceTotals' }
        & Pick<InvoiceBuilderServiceTotals, 'currentDue' | 'unbilled'>
      ) }
      & InvoiceBuilderPhaseTotalsFragmentFragment
    ), hourlyServices: (
      { __typename?: 'InvoiceBuilderHourlyServices' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderHourlyService' }
        & { roleEntries: Array<(
          { __typename?: 'InvoiceBuilderRoleEntry' }
          & Pick<InvoiceBuilderRoleEntry, 'id' | 'hours' | 'previouslyBilledHours' | 'previouslyBilledAmount' | 'roleRate' | 'amount'>
          & { logged: (
            { __typename?: 'InvoiceBuilderTooltip' }
            & Pick<InvoiceBuilderTooltip, 'total' | 'duringInvoiceRange' | 'beforeInvoiceBeginDate'>
          ), role: (
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'hours' | 'name'>
          ) }
        )>, consultantEntries: Array<(
          { __typename?: 'InvoiceBuilderProjectConsultantType' }
          & HourlyServicesConsultantEntriesFragmentFragment
        )> }
        & HourlyServicesEntryFragmentFragment
      )>, totals: (
        { __typename?: 'InvoiceBuilderServiceTotals' }
        & InvoiceTotalFragmentFragment
      ) }
    ), expenses: (
      { __typename?: 'InvoiceBuilderExpensesType' }
      & { included: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>, reported: Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )> }
    ), projectConsultants: (
      { __typename?: 'InvoiceBuilderProjectConsultants' }
      & ProjectConsultantsFragmentFragment
    ), phaseConsultants: (
      { __typename?: 'InvoiceBuilderPhaseConsultants' }
      & { entries: Array<(
        { __typename?: 'InvoiceBuilderPhaseConsultantType' }
        & PhaseConsultantsFragmentFragment
      )> }
      & PhaseConsultantsTotalFragmentFragment
    ), otherItems: (
      { __typename?: 'InvoiceBuilderOtherItemsType' }
      & OtherItemEntriesFragmentFragment
    ), retainerItems: (
      { __typename?: 'InvoiceBuilderRetainerItemsType' }
      & RetainerItemEntriesFragmentFragment
    ), appliedRetainer?: Maybe<(
      { __typename?: 'LineItem' }
      & AppliedRetainerItemFragmentFragment
    )>, retainerSummary?: Maybe<(
      { __typename?: 'RetainerSummary' }
      & RetainerSummaryFragmentFragment
    )>, taxes: (
      { __typename?: 'InvoiceBuilderTaxes' }
      & TaxEntryPayloadFragment
    ), timelogDetails: (
      { __typename?: 'InvoiceBuilderTimelogDetails' }
      & InvoiceTimelogDetailsFragment
    ), outstandingInvoices: (
      { __typename?: 'InvoiceBuilderOustandingInvoices' }
      & OutstandingInvoicesFragmentFragment
    ) }
    & TabsTotalsFragmentFragment
  )> }
);

export type SendInvoicePaymentLinkMutationVariables = Exact<{
  input: InvoiceSendPaymentLinkInput;
}>;


export type SendInvoicePaymentLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoicePaymentLink?: Maybe<(
    { __typename?: 'InvoiceSendPaymentLinkPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & { project: (
        { __typename?: 'Project' }
        & { paymentEmailSettings: (
          { __typename?: 'PaymentEmailSettings' }
          & Pick<PaymentEmailSettings, 'customBody' | 'defaultBodyWithPayment' | 'defaultBodyWithoutPayment'>
        ) }
      ) }
      & InvoiceActivitiesFragmentFragment
    ) }
  )> }
);

export type SendInvoiceReminderEmailMutationVariables = Exact<{
  input: InvoiceSendReminderEmailInput;
}>;


export type SendInvoiceReminderEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendInvoiceReminderEmail?: Maybe<(
    { __typename?: 'InvoiceSendReminderEmailPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & InvoiceActivitiesFragmentFragment
    )> }
  )> }
);

export type ViewInvoiceMutationVariables = Exact<{
  input: InvoiceViewInput;
}>;


export type ViewInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { viewInvoice?: Maybe<(
    { __typename?: 'InvoiceViewPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
    )> }
  )> }
);

export type InvoiceRemindersSchedulerFragment = (
  { __typename?: 'InvoiceRemindersScheduler' }
  & Pick<InvoiceRemindersScheduler, 'daysAfterDueDate' | 'daysBeforeDueDate' | 'deliverCc' | 'deliverTo' | 'sendOnDueDate'>
);

export type ConfigureInvoiceReminderMutationVariables = Exact<{
  slug: Scalars['String'];
  sendOnDueDate?: Maybe<Scalars['Boolean']>;
  daysBeforeDueDate?: Maybe<Scalars['Int']>;
  daysAfterDueDate?: Maybe<Scalars['Int']>;
  emailAddresses?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  emailAddressesCc?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ConfigureInvoiceReminderMutation = (
  { __typename?: 'Mutation' }
  & { configureInvoiceReminder?: Maybe<(
    { __typename?: 'ConfigureInvoiceReminderPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
    )> }
  )> }
);

export type DeleteInvoiceReminderMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DeleteInvoiceReminderMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceReminder?: Maybe<(
    { __typename?: 'InvoiceReminderDeletePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
    ) }
  )> }
);

export type InvoiceRemindersSchedulerQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type InvoiceRemindersSchedulerQuery = (
  { __typename?: 'Query' }
  & { invoiceRemindersScheduler?: Maybe<(
    { __typename?: 'InvoiceRemindersScheduler' }
    & InvoiceRemindersSchedulerFragment
  )> }
);

export type InvoicesQueryVariables = Exact<{
  sortBy?: Maybe<InvoicesSortInput>;
  filters: InvoiceFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount' | 'totalBilled' | 'totalRemaining'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'total' | 'status' | 'issueDate' | 'dueDate' | 'slug' | 'number' | 'totalPaid' | 'internalNote' | 'remainingBalance' | 'paidDate' | 'passConvenienceFeeCard' | 'passConvenienceFeeAch' | 'sentToQuickbooks' | 'paymentIntentStatus' | 'deliverTo' | 'deliverToCc' | 'invoiceVersion' | 'lastSyncedToQuickbooksAt' | 'unassignedLineItemsCount'>
      & { invoicePayments?: Maybe<Array<(
        { __typename?: 'InvoicePayment' }
        & Pick<InvoicePayment, 'amount' | 'id' | 'paidDate' | 'isQboPayment' | 'applyConvenienceFee'>
      )>>, client?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'displayName' | 'email' | 'name'>
      )>, organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'connectedToQbo' | 'isSubscribed'>
      ), project: (
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'link' | 'v1InvoicesWithUnassignedLineItemsCount'>
        & { paymentEmailSettings: (
          { __typename?: 'PaymentEmailSettings' }
          & Pick<PaymentEmailSettings, 'customBody' | 'defaultBodyWithPayment' | 'defaultBodyWithoutPayment'>
        ) }
      ), invoicePayment?: Maybe<(
        { __typename?: 'InvoicePayment' }
        & Pick<InvoicePayment, 'amount' | 'net' | 'applicationFee' | 'paymentType' | 'paymentCurrency' | 'applyConvenienceFee'>
      )> }
      & CurrentActivityFragmentFragment
      & InvoiceActivitiesFragmentFragment
    )>>> }
  ) }
);

export type QuickbooksBatchSendPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickbooksBatchSendPermissionsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
    )>, policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canSendToQuickbooks'>
      ) }
    )> }
  )> }
);

export type InvoiceMarkAsOpenMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type InvoiceMarkAsOpenMutation = (
  { __typename?: 'Mutation' }
  & { markInvoiceAsOpen?: Maybe<(
    { __typename?: 'InvoiceMarkAsOpenPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'paidDate' | 'status' | 'paymentUrl'>
    )> }
  )> }
);

export type InvoicesFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesFiltersQuery = (
  { __typename?: 'Query' }
  & { projectsOptions?: Maybe<Array<(
    { __typename?: 'ProjectOption' }
    & Pick<ProjectOption, 'id' | 'name'>
  )>>, allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'displayName' | 'id'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, consultantCompanies: Array<(
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'id' | 'name'>
  )> }
);

export type ConsultantBillsFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsultantBillsFiltersQuery = (
  { __typename?: 'Query' }
  & { projectsOptions?: Maybe<Array<(
    { __typename?: 'ProjectOption' }
    & Pick<ProjectOption, 'id' | 'name'>
  )>>, consultantCompanies: Array<(
    { __typename?: 'ConsultantCompany' }
    & Pick<ConsultantCompany, 'id' | 'name'>
  )> }
);

export type LastInvoiceAutocompleteDataByProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type LastInvoiceAutocompleteDataByProjectQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'number' | 'beginDate' | 'endDate'>
    )>>> }
  ) }
);

export type InvoiceDuplicateMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type InvoiceDuplicateMutation = (
  { __typename?: 'Mutation' }
  & { duplicateInvoice?: Maybe<(
    { __typename?: 'InvoiceDuplicatePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'slug'>
    ) }
  )> }
);

export type InvoiceCreateMutationVariables = Exact<{
  invoice: InvoiceCreateInput;
}>;


export type InvoiceCreateMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice?: Maybe<(
    { __typename?: 'InvoiceCreatePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'slug' | 'invoiceVersion'>
    ) }
  )> }
);

export type InvoiceDeleteMutationVariables = Exact<{
  slug: Scalars['String'];
}>;


export type InvoiceDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoice?: Maybe<(
    { __typename?: 'InvoiceDeletePayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
    )> }
  )> }
);

export type AddInvoiceExpenseAttachmentMutationVariables = Exact<{
  slug: Scalars['ID'];
  expenseId: Scalars['ID'];
}>;


export type AddInvoiceExpenseAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { attachInvoiceExpense?: Maybe<(
    { __typename?: 'InvoiceAttachExpensePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & { attachedExpenses?: Maybe<Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>> }
    ) }
  )> }
);

export type RemoveInvoiceExpenseAttachmentMutationVariables = Exact<{
  slug: Scalars['ID'];
  expenseId: Scalars['ID'];
}>;


export type RemoveInvoiceExpenseAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeAttachedInvoiceExpense?: Maybe<(
    { __typename?: 'InvoiceRemoveAttachedExpensePayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & { attachedExpenses?: Maybe<Array<(
        { __typename?: 'Expense' }
        & ExpenseFragmentFragment
      )>> }
    ) }
  )> }
);

export type InvoiceAttachmentFragment = (
  { __typename?: 'InvoiceAttachment' }
  & Pick<InvoiceAttachment, 'id' | 'url' | 'filename'>
);

export type AddInvoiceAttachmentsMutationVariables = Exact<{
  slug: Scalars['ID'];
  files: Array<InvoiceAttachmentFile> | InvoiceAttachmentFile;
}>;


export type AddInvoiceAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { addInvoiceAttachments?: Maybe<(
    { __typename?: 'InvoiceAddAttachmentPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & { invoiceAttachments?: Maybe<Array<(
        { __typename?: 'InvoiceAttachment' }
        & InvoiceAttachmentFragment
      )>> }
    ) }
  )> }
);

export type RemoveInvoiceAttachmentMutationVariables = Exact<{
  slug: Scalars['ID'];
  attachmentId: Scalars['ID'];
}>;


export type RemoveInvoiceAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeInvoiceAttachment?: Maybe<(
    { __typename?: 'InvoiceRemoveAttachmentPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & { invoiceAttachments?: Maybe<Array<(
        { __typename?: 'InvoiceAttachment' }
        & InvoiceAttachmentFragment
      )>> }
    ) }
  )> }
);

export type AddInvoicePaymentMutationVariables = Exact<{
  input: InvoiceAddPaymentInput;
}>;


export type AddInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { addInvoicePayment?: Maybe<(
    { __typename?: 'InvoiceAddPaymentPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'paidDate' | 'invoiceVersion' | 'total' | 'remainingBalance' | 'status' | 'totalPaid'>
      & { invoicePayments?: Maybe<Array<(
        { __typename?: 'InvoicePayment' }
        & Pick<InvoicePayment, 'id' | 'amount' | 'paidDate'>
      )>> }
      & CurrentActivityFragmentFragment
      & InvoiceActivitiesFragmentFragment
    )> }
  )> }
);

export type EditInvoicePaymentMutationVariables = Exact<{
  input: InvoiceEditPaymentInput;
}>;


export type EditInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { editInvoicePayment?: Maybe<(
    { __typename?: 'InvoiceEditPaymentPayload' }
    & { invoice: (
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'paidDate' | 'status' | 'totalPaid' | 'remainingBalance' | 'id'>
      & { invoicePayments?: Maybe<Array<(
        { __typename?: 'InvoicePayment' }
        & Pick<InvoicePayment, 'amount' | 'paidDate' | 'id'>
      )>> }
      & CurrentActivityFragmentFragment
      & InvoiceActivitiesFragmentFragment
    ) }
  )> }
);

export type DeleteInvoicePaymentsMutationVariables = Exact<{
  input: InvoicePaymentDeleteInput;
}>;


export type DeleteInvoicePaymentsMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoicePayments?: Maybe<(
    { __typename?: 'InvoicePaymentDeletePayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'paidDate' | 'status' | 'totalPaid' | 'remainingBalance' | 'id'>
      & { invoicePayments?: Maybe<Array<(
        { __typename?: 'InvoicePayment' }
        & Pick<InvoicePayment, 'amount' | 'paidDate' | 'id'>
      )>> }
      & CurrentActivityFragmentFragment
      & InvoiceActivitiesFragmentFragment
    )> }
  )> }
);

export type InvoiceClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoiceClientsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'multiQboInstanceEnabled'>
      ) }
    )> }
  )>, allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'id' | 'syncedToQbo' | 'displayName'>
    & { qboCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
      & { qboInstance: (
        { __typename?: 'QBOInstance' }
        & Pick<QboInstance, 'id'>
      ) }
    )> }
  )>> }
);

export type ConsultantBillModalDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsultantBillModalDetailsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type ReviewInvoiceForQboQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ReviewInvoiceForQboQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'status' | 'slug' | 'lineItemsHaveQboMappings'>
  ) }
);

export type ProfileMilestonesQueryVariables = Exact<{
  sortBy?: Maybe<ProfileMilestonesSortInput>;
  filters?: Maybe<MilestoneFiltersInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ProfileMilestonesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { milestones: (
      { __typename?: 'MilestoneConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Milestone' }
        & ListedMilestoneFragment
      )>>> }
    ) }
  )> }
);

export type ListedMilestoneFragment = (
  { __typename?: 'Milestone' }
  & Pick<Milestone, 'completedTasksCount' | 'dueDate' | 'id' | 'name' | 'tasksCount'>
  & { phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'abbreviation'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'slug'>
  )> }
);

export type CreateMilestoneMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  dueDate: Scalars['ISO8601Date'];
  phaseId: Scalars['ID'];
}>;


export type CreateMilestoneMutation = (
  { __typename?: 'Mutation' }
  & { createMilestone?: Maybe<(
    { __typename?: 'CreateMilestonePayload' }
    & { milestone: (
      { __typename?: 'Milestone' }
      & Pick<Milestone, 'id' | 'name' | 'description' | 'dueDate'>
      & { phase?: Maybe<(
        { __typename?: 'Phase' }
        & Pick<Phase, 'id'>
      )> }
    ) }
  )> }
);

export type NavigationQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ptoEnabled' | 'checkId'>
      ) }
    )> }
  )> }
);

export type OnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingQuery = (
  { __typename?: 'Query' }
  & { onboarding: (
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'hasProjects' | 'hasMembers' | 'hasSubscription' | 'reportsVisited' | 'editedTimesheets' | 'editedDefaults' | 'usedTimer' | 'timesheetTour' | 'timesheetSubmit' | 'guideWatched'>
  ) }
);

export type CompletedOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedOnboardingQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'completedOnboarding'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'isAdmin'>
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canManageStaffing'>
      ) }
    )> }
  )> }
);

export type LogOnboardingEventMutationVariables = Exact<{
  event: OnboardingEvent;
}>;


export type LogOnboardingEventMutation = (
  { __typename?: 'Mutation' }
  & { logOnboardingEvent?: Maybe<(
    { __typename?: 'LogOnboardingEventPayload' }
    & Pick<LogOnboardingEventPayload, 'id'>
  )> }
);

export type AdminOrganizationSubscriptionFieldsFragment = (
  { __typename?: 'OrganizationSubscription' }
  & Pick<OrganizationSubscription, 'id' | 'isActive' | 'isAnnual' | 'createdAt' | 'planName'>
);

export type AdminOrganizationAddressFieldsFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'city' | 'line1' | 'state' | 'zipcode'>
);

export type AdminOrganizationFieldsFragment = (
  { __typename?: 'Organization' }
  & Pick<Organization, 'name' | 'id' | 'slug' | 'declaredSize' | 'profileCount' | 'declaredReferrer' | 'status' | 'trialEndDate' | 'isQualified' | 'createdAt' | 'updatedAt'>
  & { demo?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  )>, settings: (
    { __typename?: 'OrganizationSetting' }
    & Pick<OrganizationSetting, 'id' | 'checkId'>
  ), subscription?: Maybe<(
    { __typename?: 'OrganizationSubscription' }
    & AdminOrganizationSubscriptionFieldsFragment
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & AdminOrganizationAddressFieldsFragment
  )> }
);

export type OrganizationsQueryVariables = Exact<{
  isQualified?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type OrganizationsQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'Admin' }
    & { organizations: (
      { __typename?: 'OrganizationConnection' }
      & Pick<OrganizationConnection, 'totalCount'>
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Organization' }
        & AdminOrganizationFieldsFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'hasPreviousPage'>
      ) }
    ) }
  ) }
);

export type OrganizationSearchQueryVariables = Exact<{
  query: Scalars['String'];
  isQualified?: Maybe<Scalars['Boolean']>;
}>;


export type OrganizationSearchQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'Admin' }
    & { organizationSearch: Array<(
      { __typename?: 'Organization' }
      & AdminOrganizationFieldsFragment
    )> }
  ) }
);

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { deleteOrganization?: Maybe<(
      { __typename?: 'DeleteOrganizationMutationPayload' }
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id'>
      )> }
    )> }
  ) }
);

export type AccountManagerQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountManagerQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isAdmin'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'size' | 'logo' | 'isDemo'>
    )> }
  )>, currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'isDemo' | 'size' | 'name' | 'logo'>
      & { demo?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'size'>
      )>, demoParent?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'size' | 'logo'>
      )> }
    )> }
  )> }
);

export type OrganizationAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
);

export type UpdateOrganizationAddressMutationVariables = Exact<{
  data: UpdateOrganizationAddressInput;
}>;


export type UpdateOrganizationAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationAddress?: Maybe<(
    { __typename?: 'UpdateOrganizationAddressPayload' }
    & { address: (
      { __typename?: 'Address' }
      & OrganizationAddressFragment
    ) }
  )> }
);

export type ToggleOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { toggleOrganization?: Maybe<(
    { __typename?: 'ToggleOrganizationMutationPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type OrganizationPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationPoliciesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { policies?: Maybe<Array<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'name' | 'id'>
      )>> }
    )> }
  )> }
);

export type SyncStripeAttributesMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SyncStripeAttributesMutation = (
  { __typename?: 'Mutation' }
  & { syncStripeAttributes?: Maybe<(
    { __typename?: 'SyncStripeAttributesPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type InvoicePaymentsQueryVariables = Exact<{
  sortBy?: Maybe<InvoicePaymentsSortInput>;
  filters: PaymentFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type InvoicePaymentsQuery = (
  { __typename?: 'Query' }
  & { invoicePayments: (
    { __typename?: 'InvoicePaymentConnection' }
    & Pick<InvoicePaymentConnection, 'totalCount' | 'totalPaid'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'InvoicePayment' }
      & Pick<InvoicePayment, 'id' | 'paidDate' | 'amount' | 'net' | 'applicationFee' | 'paymentType' | 'paymentCurrency' | 'applyConvenienceFee'>
      & { invoice: (
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status' | 'issueDate' | 'dueDate' | 'slug' | 'number' | 'total' | 'paidDate' | 'invoiceVersion'>
        & { client?: Maybe<(
          { __typename?: 'Client' }
          & Pick<Client, 'name' | 'displayName'>
        )>, project: (
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'link' | 'v1InvoicesWithUnassignedLineItemsCount'>
        ) }
      ) }
    )>>> }
  ) }
);

export type PayoutsQueryVariables = Exact<{
  sortBy?: Maybe<PayoutsSortInput>;
  filters: PayoutFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type PayoutsQuery = (
  { __typename?: 'Query' }
  & { payouts: (
    { __typename?: 'PayoutConnection' }
    & Pick<PayoutConnection, 'totalCount' | 'totalPayoutAmount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Payout' }
      & Pick<Payout, 'id' | 'stripeCreationDate' | 'amount'>
      & { invoices?: Maybe<Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'status' | 'issueDate' | 'dueDate' | 'slug' | 'number' | 'total' | 'paidDate' | 'invoiceVersion'>
        & { client?: Maybe<(
          { __typename?: 'Client' }
          & Pick<Client, 'name' | 'displayName'>
        )>, project: (
          { __typename?: 'Project' }
          & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'link' | 'v1InvoicesWithUnassignedLineItemsCount'>
        ), invoicePayment?: Maybe<(
          { __typename?: 'InvoicePayment' }
          & Pick<InvoicePayment, 'amount' | 'net' | 'applicationFee' | 'paymentType' | 'paymentCurrency'>
        )> }
      )>> }
    )>>> }
  ) }
);

export type PayrollQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { paySchedule?: Maybe<(
      { __typename?: 'PaySchedule' }
      & PayrollPayScheduleFragment
    )>, info: (
      { __typename?: 'PayrollCompanyInfo' }
      & PayrollInfoFragment
    ), workplaces: Array<(
      { __typename?: 'Workplace' }
      & PayrollWorkplaceFragment
    )>, nextPayday?: Maybe<(
      { __typename?: 'PaySchedulePayday' }
      & PayrollPaySchedulePaydayFragment
    )>, documents: (
      { __typename?: 'PayrollCompanyDocuments' }
      & { authorization: Array<(
        { __typename?: 'PayrollDocument' }
        & CompanyPayrollDocumentFragment
      )>, tax: Array<(
        { __typename?: 'PayrollDocument' }
        & CompanyPayrollDocumentFragment
      )> }
    ), payrolls: (
      { __typename?: 'Payrolls' }
      & { list: Array<(
        { __typename?: 'Payroll' }
        & Pick<Payroll, 'id' | 'periodStart' | 'periodEnd' | 'payday' | 'status' | 'type'>
        & { totals?: Maybe<(
          { __typename?: 'Totals' }
          & Pick<Totals, 'reimbursables' | 'cashRequirement'>
        )> }
      )> }
    ) }
  ) }
);

export type PayrollCompanyAuthorizationDocumentQueryVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type PayrollCompanyAuthorizationDocumentQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { document: (
      { __typename?: 'PayrollCompanyDocument' }
      & { authorization: (
        { __typename?: 'PayrollCompanyPdfDocument' }
        & Pick<PayrollCompanyPdfDocument, 'pdf'>
      ) }
    ) }
  ) }
);

export type PayrollCompanyTaxDocumentQueryVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type PayrollCompanyTaxDocumentQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { document: (
      { __typename?: 'PayrollCompanyDocument' }
      & { tax: (
        { __typename?: 'PayrollCompanyPdfDocument' }
        & Pick<PayrollCompanyPdfDocument, 'pdf'>
      ) }
    ) }
  ) }
);

export type PayrollWorkerTaxDocumentQueryVariables = Exact<{
  profileId: Scalars['ID'];
  documentId: Scalars['ID'];
}>;


export type PayrollWorkerTaxDocumentQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { worker: (
      { __typename?: 'Worker' }
      & { document: (
        { __typename?: 'PayrollEmployeeDocument' }
        & { tax: (
          { __typename?: 'PayrollCompanyPdfDocument' }
          & Pick<PayrollCompanyPdfDocument, 'pdf'>
        ) }
      ) }
    ) }
  ) }
);

export type PayrollEmployeePaystubQueryVariables = Exact<{
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
  payrollId: Scalars['ID'];
}>;


export type PayrollEmployeePaystubQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { employee: (
      { __typename?: 'Employee' }
      & { document: (
        { __typename?: 'PayrollEmployeeDocument' }
        & { paystub: (
          { __typename?: 'PaystubPdf' }
          & Pick<PaystubPdf, 'pdf'>
        ) }
      ) }
    ) }
  ) }
);

export type PayrollWaitlisterQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollWaitlisterQuery = (
  { __typename?: 'Query' }
  & { payrollWaitlister?: Maybe<(
    { __typename?: 'PayrollWaitlister' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    ), profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'name'>
    ) }
  )> }
);

export type PayrollOrgStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollOrgStatusQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { workers: Array<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id'>
      & { info: (
        { __typename?: 'PayrollWorkerInfo' }
        & Pick<PayrollWorkerInfo, 'id'>
      ) }
    )>, info: (
      { __typename?: 'PayrollCompanyInfo' }
      & Pick<PayrollCompanyInfo, 'id' | 'payFrequency'>
      & { onboarding: (
        { __typename?: 'PayrollCompanyOnboarding' }
        & Pick<PayrollCompanyOnboarding, 'status' | 'blockingSteps' | 'remainingForms' | 'remainingSteps' | 'remainingTaxJurisdictions' | 'bankAccount'>
      ) }
    ), nextPayday?: Maybe<(
      { __typename?: 'PaySchedulePayday' }
      & Pick<PaySchedulePayday, 'approvalDeadline' | 'payday'>
    )>, payrolls: (
      { __typename?: 'Payrolls' }
      & { listUnprocessed: Array<(
        { __typename?: 'Payroll' }
        & Pick<Payroll, 'id'>
      )> }
    ) }
  ) }
);

export type PayrollReportQueryVariables = Exact<{
  category: ReportCategory;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
}>;


export type PayrollReportQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'csv'>
    ) }
  ) }
);

export type PayrollOrgWorkplacesQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollOrgWorkplacesQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { workplaces: Array<(
      { __typename?: 'Workplace' }
      & Pick<Workplace, 'active' | 'id' | 'name'>
      & { address: (
        { __typename?: 'PayrollAddress' }
        & PayrollAddressFragment
      ) }
    )> }
  ) }
);

export type GetPayrollEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayrollEmployeesQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { employees: Array<(
      { __typename?: 'Employee' }
      & { info: (
        { __typename?: 'PayrollEmployeeInfo' }
        & PayrollEmployeeFragment
      ), bankAccounts: Array<(
        { __typename?: 'PayrollBankAccount' }
        & PayrollBankAccountFragment
      )> }
    )> }
  ) }
);

export type GetPayrollWorkersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayrollWorkersQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { workers: Array<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'id' | 'type'>
      & { info: (
        { __typename?: 'PayrollWorkerInfo' }
        & PayrollWorkerFragment
      ), bankAccounts: Array<(
        { __typename?: 'PayrollBankAccount' }
        & PayrollBankAccountFragment
      )> }
    )> }
  ) }
);

export type UnsafeProfilesForPayrollQueryVariables = Exact<{ [key: string]: never; }>;


export type UnsafeProfilesForPayrollQuery = (
  { __typename?: 'Query' }
  & { profiles: (
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & PayrollProfileFragment
    )>>> }
  ) }
);

export type ViewPayrollNavItemQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewPayrollNavItemQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'checkId'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'checkId'>
      ) }
    )>, policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canManageCompensations'>
      ) }
    )> }
  )> }
);

export type GetPayrollEmployeeQueryVariables = Exact<{
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
}>;


export type GetPayrollEmployeeQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & PayrollProfileFragment
  ), payroll: (
    { __typename?: 'Company' }
    & { employee: (
      { __typename?: 'Employee' }
      & { earningRates: Array<(
        { __typename?: 'PayrollEarningRate' }
        & PayrollEarningRateFragment
      )>, documents: (
        { __typename?: 'PayrollEmployeeDocuments' }
        & { tax: Array<(
          { __typename?: 'PayrollDocument' }
          & CompanyPayrollDocumentFragment
        )> }
      ), info: (
        { __typename?: 'PayrollEmployeeInfo' }
        & PayrollEmployeeFragment
      ), bankAccounts: Array<(
        { __typename?: 'PayrollBankAccount' }
        & PayrollBankAccountFragment
      )> }
    ) }
  ) }
);

export type GetPayrollWorkerQueryVariables = Exact<{
  profileId?: Maybe<Scalars['ID']>;
  profileSlug?: Maybe<Scalars['String']>;
}>;


export type GetPayrollWorkerQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & PayrollProfileFragment
  ), payroll: (
    { __typename?: 'Company' }
    & { worker: (
      { __typename?: 'Worker' }
      & Pick<Worker, 'type' | 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      ), earningRates: Array<(
        { __typename?: 'PayrollEarningRate' }
        & PayrollEarningRateFragment
      )>, documents: (
        { __typename?: 'PayrollWorkerDocuments' }
        & { paystubs: Array<(
          { __typename?: 'Paystub' }
          & PayrollPaystubFragment
        )>, tax: Array<(
          { __typename?: 'PayrollDocument' }
          & CompanyPayrollDocumentFragment
        )> }
      ), info: (
        { __typename?: 'PayrollWorkerInfo' }
        & PayrollWorkerFragment
      ), bankAccounts: Array<(
        { __typename?: 'PayrollBankAccount' }
        & PayrollBankAccountFragment
      )> }
    ) }
  ) }
);

export type GetPayrollNextPaydayPeriodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayrollNextPaydayPeriodQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { nextPayday?: Maybe<(
      { __typename?: 'PaySchedulePayday' }
      & Pick<PaySchedulePayday, 'periodStart' | 'periodEnd'>
    )> }
  ) }
);

export type GetPayrollHourlyReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPayrollHourlyReportQuery = (
  { __typename?: 'Query' }
  & { payroll: (
    { __typename?: 'Company' }
    & { workers: Array<(
      { __typename?: 'Worker' }
      & Pick<Worker, 'type'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name' | 'image' | 'slug'>
      ), compensations: Array<(
        { __typename?: 'Compensation' }
        & Pick<Compensation, 'effectiveOn' | 'hoursPerWeek' | 'id' | 'isCurrent' | 'payrollType' | 'rate'>
      )>, earningRates: Array<(
        { __typename?: 'PayrollEarningRate' }
        & Pick<PayrollEarningRate, 'active' | 'amount' | 'employeeId' | 'id' | 'period'>
      )> }
    )> }
  ) }
);

export type GetHourlyReportHoursQueryVariables = Exact<{
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type GetHourlyReportHoursQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { timesheets: Array<(
      { __typename?: 'Timesheet' }
      & { entries?: Maybe<Array<(
        { __typename?: 'Entry' }
        & Pick<Entry, 'date' | 'hours'>
      )>> }
    )> }
  ) }
);

export type CreatePayrollEmployeeMutationVariables = Exact<{
  input: CreatePayrollEmployeeInput;
}>;


export type CreatePayrollEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollEmployee?: Maybe<(
    { __typename?: 'CreatePayrollEmployeePayload' }
    & { employee: (
      { __typename?: 'Employee' }
      & { info: (
        { __typename?: 'PayrollEmployeeInfo' }
        & PayrollEmployeeFragment
      ) }
    ) }
  )> }
);

export type CreatePayrollContractorMutationVariables = Exact<{
  input: CreatePayrollContractorInput;
}>;


export type CreatePayrollContractorMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollContractor?: Maybe<(
    { __typename?: 'CreatePayrollContractorPayload' }
    & { contractor: (
      { __typename?: 'Contractor' }
      & { info: (
        { __typename?: 'PayrollWorkerInfo' }
        & PayrollContractorFragment
      ) }
    ) }
  )> }
);

export type UpdatePayrollEmployeeMutationVariables = Exact<{
  input: UpdatePayrollEmployeeInput;
}>;


export type UpdatePayrollEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollEmployee?: Maybe<(
    { __typename?: 'UpdatePayrollEmployeePayload' }
    & { employeeInfo: (
      { __typename?: 'PayrollEmployeeInfo' }
      & PayrollEmployeeFragment
    ) }
  )> }
);

export type UpdatePayrollContractorMutationVariables = Exact<{
  input: UpdatePayrollContractorInput;
}>;


export type UpdatePayrollContractorMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollContractor?: Maybe<(
    { __typename?: 'UpdatePayrollContractorPayload' }
    & { contractorInfo: (
      { __typename?: 'PayrollWorkerInfo' }
      & PayrollWorkerFragment
    ) }
  )> }
);

export type UpdatePayrollEmploymentTypeMutationVariables = Exact<{
  input: UpdatePayrollEmploymentTypeInput;
}>;


export type UpdatePayrollEmploymentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollEmploymentType?: Maybe<(
    { __typename?: 'UpdatePayrollEmploymentTypePayload' }
    & { profile: (
      { __typename?: 'Profile' }
      & PayrollProfileFragment
    ) }
  )> }
);

export type CreatePayrollEmployeeEarningRateMutationVariables = Exact<{
  input: CreatePayrollEmployeeEarningRateInput;
}>;


export type CreatePayrollEmployeeEarningRateMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollEmployeeEarningRate?: Maybe<(
    { __typename?: 'CreatePayrollEmployeeEarningRatePayload' }
    & { worker: (
      { __typename?: 'Worker' }
      & Pick<Worker, 'id'>
      & { earningRates: Array<(
        { __typename?: 'PayrollEarningRate' }
        & PayrollEarningRateFragment
      )> }
    ) }
  )> }
);

export type GenerateCompanyOnboardingLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateCompanyOnboardingLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateCompanyPayrollOnboardingLink?: Maybe<(
    { __typename?: 'GenerateCompanyOnboardingLinkPayload' }
    & Pick<GenerateCompanyOnboardingLinkPayload, 'onboardingLink' | 'signatoryRequired'>
  )> }
);

export type GenerateEmployeeOnboardingLinkMutationVariables = Exact<{
  input: GenerateEmployeeOnboardingLinkInput;
}>;


export type GenerateEmployeeOnboardingLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateEmployeePayrollOnboardingLink?: Maybe<(
    { __typename?: 'GenerateEmployeeOnboardingLinkPayload' }
    & Pick<GenerateEmployeeOnboardingLinkPayload, 'onboardingLink'>
  )> }
);

export type GenerateRunPayrollLinkMutationVariables = Exact<{
  input: GenerateRunPayrollLinkInput;
}>;


export type GenerateRunPayrollLinkMutation = (
  { __typename?: 'Mutation' }
  & { generatePayrollRunPayrollLink?: Maybe<(
    { __typename?: 'GenerateRunPayrollLinkPayload' }
    & Pick<GenerateRunPayrollLinkPayload, 'runPayrollLink'>
  )> }
);

export type GenerateCompanyInsuranceLinkMutationVariables = Exact<{
  input: GenerateCompanyInsuranceLinkInput;
}>;


export type GenerateCompanyInsuranceLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateCompanyPayrollInsuranceLink?: Maybe<(
    { __typename?: 'GenerateCompanyInsuranceLinkPayload' }
    & Pick<GenerateCompanyInsuranceLinkPayload, 'link' | 'emailRequired'>
  )> }
);

export type GenerateCompanyRetirementLinkMutationVariables = Exact<{
  input: GenerateCompanyRetirementLinkInput;
}>;


export type GenerateCompanyRetirementLinkMutation = (
  { __typename?: 'Mutation' }
  & { generateCompanyPayrollRetirementLink?: Maybe<(
    { __typename?: 'GenerateCompanyRetirementLinkPayload' }
    & Pick<GenerateCompanyRetirementLinkPayload, 'link' | 'emailRequired'>
  )> }
);

export type CreateSignatoryItemAndOnboardingLinkMutationVariables = Exact<{
  input: CreateSignatoryItemAndOnboardingLinkInput;
}>;


export type CreateSignatoryItemAndOnboardingLinkMutation = (
  { __typename?: 'Mutation' }
  & { createSignatoryItemAndOnboardingLink?: Maybe<(
    { __typename?: 'CreateSignatoryItemAndOnboardingLinkPayload' }
    & Pick<CreateSignatoryItemAndOnboardingLinkPayload, 'onboardingLink'>
  )> }
);

export type CreatePayrollWaitlisterMutationVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type CreatePayrollWaitlisterMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollWaitlister?: Maybe<(
    { __typename?: 'CreatePayrollWaitlisterPayload' }
    & { payrollWaitlister?: Maybe<(
      { __typename?: 'PayrollWaitlister' }
      & Pick<PayrollWaitlister, 'id'>
    )> }
  )> }
);

export type CreatePayrollWorkplaceMutationVariables = Exact<{
  name: Scalars['String'];
  address: PayrollAddressInput;
}>;


export type CreatePayrollWorkplaceMutation = (
  { __typename?: 'Mutation' }
  & { createPayrollWorkplace?: Maybe<(
    { __typename?: 'CreatePayrollWorkplacePayload' }
    & { workplace: (
      { __typename?: 'Workplace' }
      & PayrollWorkplaceFragment
    ) }
  )> }
);

export type UpdatePayrollWorkplaceMutationVariables = Exact<{
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<PayrollAddressInputPartial>;
}>;


export type UpdatePayrollWorkplaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollWorkplace?: Maybe<(
    { __typename?: 'UpdatePayrollWorkplacePayload' }
    & { workplace: (
      { __typename?: 'Workplace' }
      & PayrollWorkplaceFragment
    ) }
  )> }
);

export type UpdatePayrollPayFrequencyMutationVariables = Exact<{
  payFrequency: PayFrequency;
}>;


export type UpdatePayrollPayFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollCompany?: Maybe<(
    { __typename?: 'UpdatePayrollCompanyPayload' }
    & { companyInfo: (
      { __typename?: 'PayrollCompanyInfo' }
      & PayrollInfoFragment
    ) }
  )> }
);

export type UpdatePayrollBasicInfoMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  address?: Maybe<PayrollAddressInput>;
}>;


export type UpdatePayrollBasicInfoMutation = (
  { __typename?: 'Mutation' }
  & { updatePayrollCompany?: Maybe<(
    { __typename?: 'UpdatePayrollCompanyPayload' }
    & { companyInfo: (
      { __typename?: 'PayrollCompanyInfo' }
      & PayrollInfoFragment
    ) }
  )> }
);

export type CreatePayrollMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePayrollMutation = (
  { __typename?: 'Mutation' }
  & { createPayroll?: Maybe<(
    { __typename?: 'CreatePayrollPayload' }
    & Pick<CreatePayrollPayload, 'runPayrollLink' | 'payrollId'>
  )> }
);

export type CreateOffCyclePayrollMutationVariables = Exact<{
  periodStart: Scalars['ISO8601Date'];
  periodEnd: Scalars['ISO8601Date'];
  payday: Scalars['ISO8601Date'];
  forceSupplementalWithholding: Scalars['Boolean'];
  applyBenefits: Scalars['Boolean'];
  applyPostTaxDeductions: Scalars['Boolean'];
}>;


export type CreateOffCyclePayrollMutation = (
  { __typename?: 'Mutation' }
  & { createOffCyclePayroll?: Maybe<(
    { __typename?: 'CreateOffCyclePayrollPayload' }
    & Pick<CreateOffCyclePayrollPayload, 'runPayrollLink' | 'payrollId'>
  )> }
);

export type PayrollHourlyWorkerSubscriptionVariables = Exact<{
  periodStart?: Maybe<Scalars['ISO8601Date']>;
  periodEnd?: Maybe<Scalars['ISO8601Date']>;
}>;


export type PayrollHourlyWorkerSubscription = (
  { __typename?: 'Subscription' }
  & { payrollHourlyWorker: (
    { __typename?: 'PayrollHourlyWorkerPayload' }
    & Pick<PayrollHourlyWorkerPayload, 'periodStart' | 'periodEnd'>
    & { workers: Array<(
      { __typename?: 'HourlyValidationWorker' }
      & Pick<HourlyValidationWorker, 'type' | 'hourlyRate'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'slug' | 'fullName'>
      ), timesheets: Array<(
        { __typename?: 'HourlyValidationTimesheet' }
        & Pick<HourlyValidationTimesheet, 'totalHours' | 'beginDate' | 'endDate'>
      )> }
    )> }
  ) }
);

export type PayrollReimbursableExpensesSubscriptionVariables = Exact<{
  periodStart?: Maybe<Scalars['ISO8601Date']>;
  periodEnd?: Maybe<Scalars['ISO8601Date']>;
}>;


export type PayrollReimbursableExpensesSubscription = (
  { __typename?: 'Subscription' }
  & { payrollReimbursableExpenses: (
    { __typename?: 'PayrollReimbursableExpensesPayload' }
    & { workers: Array<(
      { __typename?: 'ReimbursableExpensesValidationWorker' }
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'slug' | 'fullName'>
      ), expenses: Array<(
        { __typename?: 'Expense' }
        & Pick<Expense, 'note' | 'attachment' | 'date' | 'amount' | 'isReimbursable'>
        & { category: (
          { __typename?: 'ExpenseCategory' }
          & Pick<ExpenseCategory, 'name'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<Project, 'name'>
        )>, phase?: Maybe<(
          { __typename?: 'Phase' }
          & Pick<Phase, 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type PayrollOnboardStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollOnboardStatsQuery = (
  { __typename?: 'Query' }
  & { payrollOnboardStats?: Maybe<(
    { __typename?: 'OnboardStats' }
    & { organization?: Maybe<(
      { __typename?: 'Steps' }
      & Pick<Steps, 'remainingSteps'>
    )>, worker?: Maybe<(
      { __typename?: 'Steps' }
      & Pick<Steps, 'remainingSteps'>
    )> }
  )> }
);

export type ActivePayScheduleQueryVariables = Exact<{ [key: string]: never; }>;


export type ActivePayScheduleQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { paySchedule?: Maybe<(
        { __typename?: 'PaySchedule' }
        & PayrollPayScheduleFragment
      )> }
    )> }
  )> }
);

export type AdminProfileFieldsFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'name' | 'email' | 'title' | 'mfaEnabled'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type ProfileRoleTemplatesFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id'>
  & { defaultRoleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol'>
    ), roleTemplates: Array<(
      { __typename?: 'RoleTemplate' }
      & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
    )> }
  )> }
);

export type ProfileSettingsFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'sendDailyEmail' | 'sendTimesheetsSummaryEmail'>
  & { settings?: Maybe<(
    { __typename?: 'ProfileSetting' }
    & Pick<ProfileSetting, 'id' | 'weeklyInsightsDigest' | 'phaseRetroInsightsDigest'>
  )> }
);

export type ProfileBasicInfoFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fname' | 'lname' | 'fullName' | 'initials' | 'title' | 'pronouns' | 'status' | 'email' | 'image' | 'telephone' | 'slug' | 'utilizationRate'>
  & { policy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'id' | 'isAdmin'>
  )> }
);

export type ProfilePayrollInfoFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'checkId'>
);

export type ProfileProjectsFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'number' | 'name' | 'status' | 'color'>
);

export type TeamRoleColumnFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol'>
    ), roleTemplates: Array<(
      { __typename?: 'RoleTemplate' }
      & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
    )> }
  )>, defaultRoleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'name'>
  )> }
  & ProfileRateFragment
);

export type TeamOverviewFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fname' | 'lname' | 'fullName' | 'initials' | 'image' | 'title' | 'email' | 'telephone' | 'slug' | 'confirmedAt' | 'status' | 'onboardingComplete'>
  & { policy?: Maybe<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'id' | 'name'>
  )> }
  & TeamRoleColumnFragment
);

export type RecentProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type RecentProfilesQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'Admin' }
    & { recentProfiles: Array<(
      { __typename?: 'Profile' }
      & AdminProfileFieldsFragment
    )> }
  ) }
);

export type ProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
}>;


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { ajeraIdentifier?: Maybe<(
      { __typename?: 'AjeraIdentifier' }
      & AjeraFieldsFragment
    )> }
    & ProfileRoleTemplatesFragment
    & ProfileRateFragment
    & ProfileBasicInfoFragment
    & ProfileSettingsFragment
    & ProfilePayrollInfoFragment
  ) }
);

export type ProfileProjectsQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  projectSortBy?: Maybe<ProfileProjectsSortInput>;
  projectFilters?: Maybe<ProfileProjectsFiltersInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ProfileProjectsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { projects: (
      { __typename?: 'ProjectConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & ProjectFragmentFragment
      )>>> }
    ) }
    & ProfileBasicInfoFragment
  ) }
);

export type ProfileSearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type ProfileSearchQuery = (
  { __typename?: 'Query' }
  & { admin: (
    { __typename?: 'Admin' }
    & { profileSearch: Array<(
      { __typename?: 'Profile' }
      & AdminProfileFieldsFragment
    )> }
  ) }
);

export type TeamOverviewQueryVariables = Exact<{
  filters?: Maybe<ProfileFiltersInput>;
  sortBy?: Maybe<ProfilesSortInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TeamOverviewQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'isAdmin'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'profilesWithIncompleteOnboardingCount'>
      & { policies?: Maybe<Array<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'id' | 'name'>
      )>> }
    )> }
  )>, profiles: (
    { __typename?: 'ProfileConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fname' | 'lname' | 'fullName' | 'initials' | 'image' | 'title' | 'email' | 'telephone' | 'slug' | 'confirmedAt' | 'status' | 'onboardingComplete'>
      & { policy?: Maybe<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'id' | 'name'>
      )> }
      & TeamRoleColumnFragment
    )>>> }
  ) }
);

export type TeamCompensationsQueryVariables = Exact<{
  filters?: Maybe<ProfileFiltersInput>;
  sortBy?: Maybe<ProfilesSortInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TeamCompensationsQuery = (
  { __typename?: 'Query' }
  & { profiles: (
    { __typename?: 'ProfileConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
      & { compensations?: Maybe<Array<(
        { __typename?: 'Compensation' }
        & Pick<Compensation, 'id'>
      )>> }
    )>>> }
  ) }
);

export type PrepaidSeatsQueryVariables = Exact<{ [key: string]: never; }>;


export type PrepaidSeatsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'size'>
      & { subscription?: Maybe<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'prepaidSeatCount' | 'prepaidSeatsFilled'>
      )> }
    )> }
  )> }
);

export type ProfileAlertCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileAlertCountQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'profilesWithoutCompensationsCount' | 'profilesWithIncompleteOnboardingCount' | 'unprocessedQboCustomers'>
    )> }
  )> }
);

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'isAdmin'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { roleTemplates: Array<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
      )>, profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'image' | 'fname' | 'lname' | 'fullName' | 'initials' | 'title' | 'email' | 'telephone' | 'slug' | 'confirmedAt' | 'status'>
        & { policy?: Maybe<(
          { __typename?: 'Policy' }
          & Pick<Policy, 'id' | 'name'>
        )>, compensations?: Maybe<Array<(
          { __typename?: 'Compensation' }
          & Pick<Compensation, 'id'>
        )>>, defaultRoleTemplate?: Maybe<(
          { __typename?: 'RoleTemplate' }
          & Pick<RoleTemplate, 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type AdminProfileToggleQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminProfileToggleQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'image' | 'fname' | 'lname' | 'initials' | 'title' | 'slug'>
        & { policy?: Maybe<(
          { __typename?: 'Policy' }
          & Pick<Policy, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateAdminProfileToggleMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type UpdateAdminProfileToggleMutation = (
  { __typename?: 'Mutation' }
  & { admin: (
    { __typename?: 'AdminMutations' }
    & { toggleProfile?: Maybe<(
      { __typename?: 'ToggleProfileMutationPayload' }
      & { profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )> }
    )> }
  ) }
);

export type ProfileRoleTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileRoleTemplatesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName'>
        & { roleTemplate?: Maybe<(
          { __typename?: 'RoleTemplate' }
          & Pick<RoleTemplate, 'id' | 'name'>
        )> }
      )>, roleTemplates: Array<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
      )> }
    )> }
  )> }
);

export type UpdateProfileRoleTemplateMutationVariables = Exact<{
  profileId: Scalars['ID'];
  roleTemplateId: Scalars['ID'];
}>;


export type UpdateProfileRoleTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileRoleTemplate?: Maybe<(
    { __typename?: 'UpdateProfileRoleTemplatePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
      & { roleTemplate?: Maybe<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateProfileDefaultRoleTemplateMutationVariables = Exact<{
  profileId: Scalars['ID'];
  roleTemplateId?: Maybe<Scalars['ID']>;
  rateTableId?: Maybe<Scalars['ID']>;
}>;


export type UpdateProfileDefaultRoleTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileDefaultRoleTemplate?: Maybe<(
    { __typename?: 'UpdateProfileDefaultRoleTemplatePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
      & { defaultRoleTemplate?: Maybe<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id'>
      )>, defaultRateTable?: Maybe<(
        { __typename?: 'RateTable' }
        & Pick<RateTable, 'id'>
      )> }
    )> }
  )> }
);

export type DeactivateProfileMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type DeactivateProfileMutation = (
  { __typename?: 'Mutation' }
  & { deactivateProfile?: Maybe<(
    { __typename?: 'ProfileDeactivatePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type ReactivateProfileMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type ReactivateProfileMutation = (
  { __typename?: 'Mutation' }
  & { reactivateProfile?: Maybe<(
    { __typename?: 'ProfileReactivatePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type DeleteProfileMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type DeleteProfileMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfile?: Maybe<(
    { __typename?: 'DeleteProfilePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type CreateProfileMutationVariables = Exact<{
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  defaultRoleTemplateId?: Maybe<Scalars['ID']>;
  defaultRateTableId?: Maybe<Scalars['ID']>;
  sendInvitation?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
}>;


export type CreateProfileMutation = (
  { __typename?: 'Mutation' }
  & { createProfile?: Maybe<(
    { __typename?: 'CreateProfilePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'email'>
    )> }
  )> }
);

export type SendProfileInvitationMutationVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type SendProfileInvitationMutation = (
  { __typename?: 'Mutation' }
  & { sendProfileInvitation?: Maybe<(
    { __typename?: 'SendProfileInvitationPayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id'>
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  policyId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdateProfilePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileBasicInfoFragment
    )> }
  )> }
);

export type UpdateProfileSettingsMutationVariables = Exact<{
  profileId: Scalars['ID'];
  sendDailyEmail?: Maybe<Scalars['Boolean']>;
  sendTimesheetsSummaryEmail?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateProfileSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileSettings?: Maybe<(
    { __typename?: 'UpdateProfileSettingsPayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileBasicInfoFragment
      & ProfileSettingsFragment
    )> }
  )> }
);

export type UpdateProfileUtilizationRateMutationVariables = Exact<{
  input: UpdateUtilizationRateInput;
}>;


export type UpdateProfileUtilizationRateMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileUtilizationRate?: Maybe<(
    { __typename?: 'UpdateUtilizationRatePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'utilizationRate'>
    )> }
  )> }
);

export type UpdateInsightsEmailPreferenceMutationVariables = Exact<{
  input: UpdateInsightsEmailPreferenceInput;
}>;


export type UpdateInsightsEmailPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { updateInsightsEmailPreference?: Maybe<(
    { __typename?: 'UpdateInsightsEmailPreferencePayload' }
    & { profile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileBasicInfoFragment
      & ProfileSettingsFragment
    )> }
  )> }
);

type PlannerPlanable_Project_Fragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'color' | 'slug' | 'budget' | 'isBillable' | 'name' | 'consultantsFee' | 'number' | 'totalFee'>
  & { phases: Array<(
    { __typename?: 'Phase' }
    & PlannerPhaseFragment
  )> }
);

type PlannerPlanable_ProjectTemplate_Fragment = (
  { __typename?: 'ProjectTemplate' }
  & Pick<ProjectTemplate, 'id' | 'color' | 'slug' | 'budget' | 'isBillable' | 'name' | 'consultantsFee' | 'number' | 'totalFee'>
  & { phases: Array<(
    { __typename?: 'Phase' }
    & PlannerPhaseFragment
  )> }
);

export type PlannerPlanableFragment = PlannerPlanable_Project_Fragment | PlannerPlanable_ProjectTemplate_Fragment;

export type PlannerPhaseProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'fname' | 'fullName' | 'id' | 'image' | 'lname' | 'initials'>
  & { roleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>, defaultRoleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>, defaultRateTable?: Maybe<(
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id'>
  )> }
);

export type PlannerProjectTemplateFragment = (
  { __typename?: 'ProjectTemplate' }
  & Pick<ProjectTemplate, 'id' | 'color' | 'slug' | 'consultantsFee' | 'budget' | 'isBillable' | 'name' | 'number' | 'totalFee'>
);

export type PhaseRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'budget' | 'hours' | 'name' | 'percent' | 'position' | 'entryHoursCount' | 'hasNonDraftLineItem' | 'hasLoggedTime' | 'phaseRate'>
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & PlannerPhaseProfileFragment
  )>, phaseRates: Array<(
    { __typename?: 'Rate' }
    & Pick<Rate, 'amount' | 'effectiveOn' | 'displayDate'>
  )>, rateTable: (
    { __typename?: 'RateTable' }
    & Pick<RateTable, 'id' | 'name' | 'slug'>
  ), roleTemplate: (
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id'>
  ) }
);

export type PlannerPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'id' | 'name' | 'abbreviation' | 'feeType' | 'budget' | 'status' | 'beginDate' | 'endDate' | 'percent' | 'position' | 'entryHoursCount' | 'hasNonDraftLineItem'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & PhaseRoleFragment
  )>, phaseTemplate?: Maybe<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id'>
  )>, linkedPhase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )>, linkedPhases: Array<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )> }
);

export type PlannerPhaseTemplateFragment = (
  { __typename?: 'PhaseTemplate' }
  & Pick<PhaseTemplate, 'id' | 'name' | 'abbreviation' | 'feeType'>
);

export type ProjectPlannerCreatePhaseTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
}>;


export type ProjectPlannerCreatePhaseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createPhaseTemplate?: Maybe<(
    { __typename?: 'CreatePhaseTemplatePayload' }
    & { phaseTemplate?: Maybe<(
      { __typename?: 'PhaseTemplate' }
      & PlannerPhaseTemplateFragment
    )> }
  )> }
);

export type ProjectPlannerPhaseTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectPlannerPhaseTemplatesQuery = (
  { __typename?: 'Query' }
  & { phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & PlannerPhaseTemplateFragment
  )> }
);

export type ProjectPlannerProjectTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectPlannerProjectTemplatesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { projectTemplates: Array<(
        { __typename?: 'ProjectTemplate' }
        & Pick<ProjectTemplate, 'budget' | 'color' | 'consultantsFee' | 'id' | 'isBillable' | 'name' | 'number' | 'slug' | 'totalFee'>
      )> }
    )> }
  )> }
);

export type ProjectPlannerPlanableQueryVariables = Exact<{
  slug: Scalars['String'];
  isProjectTemplate: Scalars['Boolean'];
}>;


export type ProjectPlannerPlanableQuery = (
  { __typename?: 'Query' }
  & { planable?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'color' | 'slug' | 'budget' | 'isBillable' | 'name' | 'consultantsFee' | 'notes' | 'number' | 'totalFee'>
    & { phases: Array<(
      { __typename?: 'Phase' }
      & PlannerPhaseFragment
    )>, categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>> }
  ) | (
    { __typename?: 'ProjectTemplate' }
    & Pick<ProjectTemplate, 'id' | 'color' | 'slug' | 'budget' | 'isBillable' | 'name' | 'consultantsFee' | 'notes' | 'number' | 'totalFee'>
    & { phases: Array<(
      { __typename?: 'Phase' }
      & PlannerPhaseFragment
    )>, categories?: Maybe<Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )>> }
  )> }
);

export type PlannerProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type PlannerProfilesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { roleTemplates: Array<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id' | 'name'>
      )>, profiles: Array<(
        { __typename?: 'Profile' }
        & PlannerPhaseProfileFragment
      )> }
    )> }
  )> }
);

export type ProjectPlannerPhaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectPlannerPhaseQuery = (
  { __typename?: 'Query' }
  & { phase: (
    { __typename?: 'Phase' }
    & PlannerPhaseFragment
  ) }
);

export type ProjectPlannerProfilesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectPlannerProfilesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & PlannerPhaseProfileFragment
      )> }
    )> }
  )> }
);

export type ProjectPlannerExpenseCategoryFragment = (
  { __typename?: 'ExpenseCategory' }
  & Pick<ExpenseCategory, 'id' | 'name' | 'markupPercentage'>
);

export type ProjectExpenseCategoriesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectExpenseCategoriesQuery = (
  { __typename?: 'Query' }
  & { projectExpenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & ProjectPlannerExpenseCategoryFragment
  )> }
);

export type UpdateExpenseCategoryForProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  markupPercentage: Scalars['Float'];
  applyToAllForProject: Scalars['Boolean'];
}>;


export type UpdateExpenseCategoryForProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseCategoryForProject?: Maybe<(
    { __typename?: 'UpdateExpenseCategoryForProjectPayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & Pick<ExpenseCategory, 'id' | 'name' | 'markupPercentage'>
    )> }
  )> }
);

export type CreateCustomPhaseMutationVariables = Exact<{
  name: Scalars['String'];
  feeType: PhaseFee;
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  abbreviation: Scalars['String'];
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  projectId?: Maybe<Scalars['ID']>;
  projectTemplateId?: Maybe<Scalars['ID']>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
}>;


export type CreateCustomPhaseMutation = (
  { __typename?: 'Mutation' }
  & { createPhase?: Maybe<(
    { __typename?: 'CreatePhasePayload' }
    & { phase: (
      { __typename?: 'Phase' }
      & PlannerPhaseFragment
    ) }
  )> }
);

export type CreatePhaseFromTemplateMutationVariables = Exact<{
  phaseTemplateId: Scalars['ID'];
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  projectId?: Maybe<Scalars['ID']>;
  projectTemplateId?: Maybe<Scalars['ID']>;
  feeType?: Maybe<PhaseFee>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
}>;


export type CreatePhaseFromTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createPhaseFromTemplate?: Maybe<(
    { __typename?: 'CreatePhaseFromTemplatePayload' }
    & { phase: (
      { __typename?: 'Phase' }
      & PlannerPhaseFragment
    ) }
  )> }
);

export type DeletePhaseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePhaseMutation = (
  { __typename?: 'Mutation' }
  & { deletePhase?: Maybe<(
    { __typename?: 'DeletePhasePayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )> }
  )> }
);

export type UpdatePhaseMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Float']>;
  budget?: Maybe<Scalars['Float']>;
  linkedPhaseId?: Maybe<Scalars['ID']>;
  feeType?: Maybe<PhaseFee>;
  phaseTemplateId?: Maybe<Scalars['ID']>;
  beginDate?: Maybe<Scalars['ISO8601Date']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  updateRoleHours?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePhaseMutation = (
  { __typename?: 'Mutation' }
  & { updatePhase?: Maybe<(
    { __typename?: 'UpdatePhasePayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & PlannerPhaseFragment
    )> }
  )> }
);

export type UpdatePhasePositionMutationVariables = Exact<{
  id: Scalars['ID'];
  position: Scalars['Int'];
}>;


export type UpdatePhasePositionMutation = (
  { __typename?: 'Mutation' }
  & { updatePhasePosition?: Maybe<(
    { __typename?: 'UpdatePhasePositionPayload' }
    & { phase?: Maybe<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'position'>
    )> }
  )> }
);

export type UpdateRoleProfileMutationVariables = Exact<{
  input: UpdateRoleProfileInput;
}>;


export type UpdateRoleProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateRoleProfile?: Maybe<(
    { __typename?: 'UpdateRoleProfilePayload' }
    & { role: (
      { __typename?: 'Role' }
      & PhaseRoleFragment
    ) }
  )> }
);

export type UpdateRoleHoursMutationVariables = Exact<{
  input: UpdateRoleHoursInput;
}>;


export type UpdateRoleHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateRoleHours?: Maybe<(
    { __typename?: 'UpdateRoleHoursPayload' }
    & { role: (
      { __typename?: 'Role' }
      & PhaseRoleFragment
    ) }
  )> }
);

export type UpdateRoleRoleTemplateAndRateMutationVariables = Exact<{
  input: UpdateRoleRoleTemplateAndRateInput;
}>;


export type UpdateRoleRoleTemplateAndRateMutation = (
  { __typename?: 'Mutation' }
  & { updateRoleRoleTemplateAndRate?: Maybe<(
    { __typename?: 'UpdateRoleRoleTemplateAndRatePayload' }
    & { role: (
      { __typename?: 'Role' }
      & PhaseRoleFragment
    ) }
  )> }
);

export type UpdateRolePositionMutationVariables = Exact<{
  id: Scalars['ID'];
  position: Scalars['Int'];
}>;


export type UpdateRolePositionMutation = (
  { __typename?: 'Mutation' }
  & { updateRolePosition?: Maybe<(
    { __typename?: 'UpdateRolePositionPayload' }
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'position'>
    )> }
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'CreateRolePayload' }
    & { role: (
      { __typename?: 'Role' }
      & PhaseRoleFragment
    ), phase: (
      { __typename?: 'Phase' }
      & Pick<Phase, 'id'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
        & { profile?: Maybe<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'id'>
          & { timesheets: Array<(
            { __typename?: 'Timesheet' }
            & StaffingTimesheetFragment
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename?: 'DeleteRolePayload' }
    & { roles?: Maybe<Array<(
      { __typename?: 'Role' }
      & PhaseRoleFragment
    )>> }
  )> }
);

export type AssignRoleToAllPhasesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AssignRoleToAllPhasesMutation = (
  { __typename?: 'Mutation' }
  & { assignRoleToAllPhases?: Maybe<(
    { __typename?: 'AssignRoleToAllPhasesPayload' }
    & { role: (
      { __typename?: 'Role' }
      & PhaseRoleFragment
    ) }
  )> }
);

export type UpdatePlanableBudgetMutationVariables = Exact<{
  input: PlanableBudgetUpdateInput;
}>;


export type UpdatePlanableBudgetMutation = (
  { __typename?: 'Mutation' }
  & { updatePlanableBudget?: Maybe<(
    { __typename?: 'PlanableBudgetUpdatePayload' }
    & { planable: (
      { __typename?: 'Project' }
      & PlannerPlanable_Project_Fragment
    ) | (
      { __typename?: 'ProjectTemplate' }
      & PlannerPlanable_ProjectTemplate_Fragment
    ) }
  )> }
);

export type BulkGenerateRoleAllocationsMutationVariables = Exact<{
  roleIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type BulkGenerateRoleAllocationsMutation = (
  { __typename?: 'Mutation' }
  & { bulkGenerateStaffingAllocations?: Maybe<(
    { __typename?: 'BulkGenerateStaffingAllocationsPayload' }
    & { entryGroups: Array<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id'>
    )> }
  )> }
);

export type DeleteRoleInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoleInfoQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & Pick<Role, 'allRolesEntryHoursCount'>
  ) }
);

export type ProjectTemplateGeneralInfoFragment = (
  { __typename?: 'ProjectTemplate' }
  & Pick<ProjectTemplate, 'budget' | 'color' | 'consultantsAllocatedPercent' | 'consultantsFee' | 'consultantsUnallocated' | 'id' | 'isBillable' | 'name' | 'notes' | 'number' | 'slug' | 'totalFee'>
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type ProjectTemplatesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ProjectTemplatesSortInput>;
}>;


export type ProjectTemplatesQuery = (
  { __typename?: 'Query' }
  & { projectTemplates: (
    { __typename?: 'ProjectTemplateConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectTemplate' }
      & ProjectTemplateGeneralInfoFragment
    )>>> }
  ) }
);

export type CreateProjectTemplateMutationVariables = Exact<{
  input: CreateProjectTemplateInput;
}>;


export type CreateProjectTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createProjectTemplate?: Maybe<(
    { __typename?: 'CreateProjectTemplatePayload' }
    & { projectTemplate?: Maybe<(
      { __typename?: 'ProjectTemplate' }
      & ProjectTemplateGeneralInfoFragment
    )> }
  )> }
);

export type DeleteProjectTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProjectTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectTemplate?: Maybe<(
    { __typename?: 'DeleteProjectTemplatePayload' }
    & { projectTemplate?: Maybe<(
      { __typename?: 'ProjectTemplate' }
      & Pick<ProjectTemplate, 'id'>
    )> }
  )> }
);

export type UpdateProjectTemplateBasicInfoMutationVariables = Exact<{
  input: ProjectTemplateBasicInfoUpdateInput;
}>;


export type UpdateProjectTemplateBasicInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectTemplateBasicInfo?: Maybe<(
    { __typename?: 'ProjectTemplateBasicInfoUpdatePayload' }
    & { projectTemplate?: Maybe<(
      { __typename?: 'ProjectTemplate' }
      & ProjectTemplateGeneralInfoFragment
    )> }
  )> }
);

export type ProjectCommonDisplayDataFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'color' | 'id' | 'name' | 'number' | 'slug' | 'v1InvoicesWithUnassignedLineItemsCount'>
  & { profiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
  )>>, projectConsultants?: Maybe<Array<(
    { __typename?: 'ProjectConsultant' }
    & Pick<ProjectConsultant, 'id'>
    & { consultingWork?: Maybe<(
      { __typename?: 'ConsultingWork' }
      & { consultantCompany?: Maybe<(
        { __typename?: 'ConsultantCompany' }
        & Pick<ConsultantCompany, 'id'>
      )> }
    )> }
  )>>, phaseConsultants?: Maybe<Array<(
    { __typename?: 'PhaseConsultant' }
    & { entries?: Maybe<Array<(
      { __typename?: 'ProjectConsultant' }
      & Pick<ProjectConsultant, 'id'>
    )>> }
  )>> }
);

export type ProjectCostDetailsFragment = (
  { __typename?: 'ProjectCost' }
  & Pick<ProjectCost, 'constructionCost' | 'id' | 'sqUnitCostType' | 'sqUnits'>
);

export type ProjectGeneralInfoFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'slug' | 'color' | 'id' | 'budget' | 'consultantsFee' | 'totalFee' | 'consultantsUnallocated' | 'consultantsAllocatedPercent' | 'isBillable' | 'isShareable' | 'name' | 'notes' | 'number' | 'shareableHash'>
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & CommonAddressFragment
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, client?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'displayName' | 'active' | 'syncedToQbo'>
    & { qboCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
    )> }
  )>, projectCost?: Maybe<(
    { __typename?: 'ProjectCost' }
    & ProjectCostDetailsFragment
  )>, accountingDestinationCustomer?: Maybe<(
    { __typename?: 'QBOCustomer' }
    & Pick<QboCustomer, 'id' | 'displayName'>
  )> }
);

export type ProjectConsultantsFragment = (
  { __typename?: 'Project' }
  & { projectConsultants?: Maybe<Array<(
    { __typename?: 'ProjectConsultant' }
    & Pick<ProjectConsultant, 'budget' | 'budgetIsFrozen' | 'markup' | 'markupPercentage' | 'id'>
  )>> }
);

export type ProjectPhasesFragment = (
  { __typename?: 'Project' }
  & { orderedPhases: Array<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )> }
);

export type ProjectColorsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectColorsQuery = (
  { __typename?: 'Query' }
  & { projectColors: Array<(
    { __typename?: 'ProjectColor' }
    & Pick<ProjectColor, 'number' | 'hex'>
  )> }
);

export type ProjectGeneralInfoSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectGeneralInfoSettingsQuery = (
  { __typename?: 'Query' }
  & { allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'id' | 'displayName' | 'syncedToQbo'>
    & { qboCustomer?: Maybe<(
      { __typename?: 'QBOCustomer' }
      & Pick<QboCustomer, 'id'>
    )> }
  )>>, currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { categories?: Maybe<Array<(
        { __typename?: 'Category' }
        & Pick<Category, 'id' | 'name'>
      )>>, expenseCategories?: Maybe<Array<(
        { __typename?: 'ExpenseCategory' }
        & Pick<ExpenseCategory, 'id' | 'name' | 'markupPercentage'>
      )>> }
    )> }
  )> }
);

export type ProjectGeneralInfoQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectGeneralInfoQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectGeneralInfoFragment
  )> }
);

export type ProjectPageCommonDataQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectPageCommonDataQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectCommonDisplayDataFragment
  )> }
);

export type ProjectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectOptionsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & ProjectCommonDisplayDataFragment
  )>> }
);

export type ProjectsQueryVariables = Exact<{
  includeArchived?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>> }
);

export type ProjectPhasesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectPhasesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectPhasesFragment
  )> }
);

export type UpdateProjectShareableMutationVariables = Exact<{
  id: Scalars['ID'];
  isShareable: Scalars['Boolean'];
}>;


export type UpdateProjectShareableMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectShareable?: Maybe<(
    { __typename?: 'ProjectShareableUpdatePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'isShareable'>
    ) }
  )> }
);

export type UpdateProjectCostMutationVariables = Exact<{
  id: Scalars['ID'];
  constructionCost?: Maybe<Scalars['Float']>;
  sqUnits?: Maybe<Scalars['Float']>;
  sqUnitCostType?: Maybe<SqUnitCost>;
}>;


export type UpdateProjectCostMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectCost?: Maybe<(
    { __typename?: 'ProjectCostUpdatePayload' }
    & { project: (
      { __typename?: 'Project' }
      & { projectCost?: Maybe<(
        { __typename?: 'ProjectCost' }
        & ProjectCostDetailsFragment
      )> }
    ) }
  )> }
);

export type UpdateProjectBasicInfoMutationVariables = Exact<{
  input: ProjectBasicInfoUpdateInput;
}>;


export type UpdateProjectBasicInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectBasicInfo?: Maybe<(
    { __typename?: 'ProjectBasicInfoUpdatePayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & ProjectGeneralInfoFragment
    )> }
  )> }
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject?: Maybe<(
    { __typename?: 'CreateProjectPayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & ProjectGeneralInfoFragment
    )> }
  )> }
);

export type CreateProjectFromTemplateMutationVariables = Exact<{
  input: CreateProjectFromTemplateInput;
}>;


export type CreateProjectFromTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createProjectFromTemplate?: Maybe<(
    { __typename?: 'CreateProjectFromTemplatePayload' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & ProjectGeneralInfoFragment
    )> }
  )> }
);

export type ProjectAccountingInfoQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectAccountingInfoQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'externalAccountingId' | 'externalAccountingRoleId'>
  )> }
);

export type UpdateProjectAccountingMutationVariables = Exact<{
  id: Scalars['ID'];
  externalAccountingId?: Maybe<Scalars['ID']>;
  externalAccountingRoleId?: Maybe<Scalars['ID']>;
}>;


export type UpdateProjectAccountingMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectAccounting?: Maybe<(
    { __typename?: 'ProjectAccountingUpdatePayload' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'externalAccountingId' | 'externalAccountingRoleId'>
    ) }
  )> }
);

export type ProjectProfitQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectProfitQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo'>
    )> }
  )>, projectProfit?: Maybe<(
    { __typename?: 'ProjectProfit' }
    & Pick<ProjectProfit, 'costsTotal' | 'profitTotal' | 'revenueTotal' | 'missingCostsImport'>
    & { entries: Array<(
      { __typename?: 'ProjectProfitEntryType' }
      & Pick<ProjectProfitEntryType, 'costs' | 'grossProfit' | 'missingLaborRelatedCosts' | 'name' | 'revenue'>
    )>, profilesMissingCompensation: Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'fullName' | 'id' | 'slug'>
    )> }
  )> }
);

export type ClientListDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientListDetailsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type ProjectTotalsFragmentFragment = (
  { __typename?: 'ProjectsTimingsTotals' }
  & Pick<ProjectsTimingsTotals, 'filteredProjectCount' | 'projectTotal' | 'projectPercentage' | 'issuedFee' | 'invoicesCount'>
  & { consumed: (
    { __typename?: 'ProjectsTimingsTotalsGroup' }
    & Pick<ProjectsTimingsTotalsGroup, 'fee' | 'hours' | 'billableFeePercent'>
  ), planned: (
    { __typename?: 'ProjectsTimingsTotalsGroup' }
    & Pick<ProjectsTimingsTotalsGroup, 'fee' | 'hours'>
  ) }
);

export type ProjectFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'color' | 'status' | 'slug' | 'isBillable' | 'number' | 'link'>
  & { projectCost?: Maybe<(
    { __typename?: 'ProjectCost' }
    & Pick<ProjectCost, 'sqUnitCostType'>
  )> }
);

export type ProjectTotalsPerDateFragmentFragment = (
  { __typename?: 'ProjectsTimingsChartEntryTotals' }
  & Pick<ProjectsTimingsChartEntryTotals, 'issuedFee'>
  & { consumed: (
    { __typename?: 'HoursAndFee' }
    & Pick<HoursAndFee, 'fee' | 'hours'>
  ), planned: (
    { __typename?: 'HoursAndFee' }
    & Pick<HoursAndFee, 'fee' | 'hours'>
  ) }
);

export type ProjectDataFragmentFragment = (
  { __typename?: 'ProjectsTimingsProjectListEntry' }
  & Pick<ProjectsTimingsProjectListEntry, 'issuedFee'>
  & { planned: (
    { __typename?: 'HoursAndFee' }
    & Pick<HoursAndFee, 'fee' | 'hours'>
  ), consumed: (
    { __typename?: 'HoursAndFee' }
    & Pick<HoursAndFee, 'fee' | 'hours'>
  ), projectCost?: Maybe<(
    { __typename?: 'ProjectCost' }
    & Pick<ProjectCost, 'sqUnits'>
  )>, costs: (
    { __typename?: 'ProjectsTimingsConstructionCost' }
    & Pick<ProjectsTimingsConstructionCost, 'costBySqUnit' | 'plannedFeeBySqUnit' | 'consumedFeeBySqUnit' | 'revenueBySqUnit'>
  ), project: (
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  ) }
);

export type ProjectChartFragmentFragment = (
  { __typename?: 'ProjectsTimingsChartEntry' }
  & { interval: (
    { __typename?: 'DateRange' }
    & Pick<DateRange, 'beginDate' | 'endDate'>
  ), totals: (
    { __typename?: 'ProjectsTimingsChartEntryTotals' }
    & ProjectTotalsPerDateFragmentFragment
  ), data: Array<(
    { __typename?: 'ProjectsTimingsChartEntryData' }
    & Pick<ProjectsTimingsChartEntryData, 'issuedFee' | 'projectId'>
    & { consumed: (
      { __typename?: 'HoursAndFee' }
      & Pick<HoursAndFee, 'fee' | 'hours'>
    ), planned: (
      { __typename?: 'HoursAndFee' }
      & Pick<HoursAndFee, 'fee' | 'hours'>
    ) }
  )> }
);

export type CategoriesAndProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesAndProjectsQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>> }
);

export type ProjectFiltersFragmentFragment = (
  { __typename?: 'ProjectsTimingsFilters' }
  & Pick<ProjectsTimingsFilters, 'billableOption' | 'categoryIds' | 'clientIds' | 'datePreset' | 'projectIds' | 'statuses' | 'minSqUnit' | 'maxSqUnit'>
  & { dateRange: (
    { __typename?: 'DateRange' }
    & Pick<DateRange, 'beginDate' | 'endDate'>
  ) }
);

export type ProjectsReportQueryVariables = Exact<{
  filters?: Maybe<ProjectTimingsFiltersInput>;
}>;


export type ProjectsReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { projectsTimings: (
      { __typename?: 'ProjectsTimings' }
      & Pick<ProjectsTimings, 'dateIncrement' | 'partialStart' | 'partialEnd'>
      & { chart: Array<(
        { __typename?: 'ProjectsTimingsChartEntry' }
        & ProjectChartFragmentFragment
      )>, filters: (
        { __typename?: 'ProjectsTimingsFilters' }
        & ProjectFiltersFragmentFragment
      ), projects: Array<(
        { __typename?: 'ProjectsTimingsProjectListEntry' }
        & ProjectDataFragmentFragment
      )>, totals: (
        { __typename?: 'ProjectsTimingsTotals' }
        & ProjectTotalsFragmentFragment
      ) }
    ) }
  ) }
);

export type ProjectsReportDefaultsQueryVariables = Exact<{
  filters?: Maybe<ProjectTimingsFiltersInput>;
}>;


export type ProjectsReportDefaultsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { projectsTimings: (
      { __typename?: 'ProjectsTimings' }
      & { filters: (
        { __typename?: 'ProjectsTimingsFilters' }
        & ProjectFiltersFragmentFragment
      ) }
    ) }
  ) }
);

export type ImportAllVendorsFromQuickbooksMutationVariables = Exact<{ [key: string]: never; }>;


export type ImportAllVendorsFromQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { importAllVendorsFromQuickbooks?: Maybe<(
    { __typename?: 'ImportAllVendorsFromQuickbooksPayload' }
    & { consultantCompanies?: Maybe<Array<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id' | 'name' | 'quickbooksVendorId' | 'active'>
      & { primaryConsultantContact?: Maybe<(
        { __typename?: 'ConsultantContact' }
        & Pick<ConsultantContact, 'fname' | 'lname' | 'email'>
      )> }
    )>> }
  )> }
);

export type ImportSpecificVendorsFromQuickbooksMutationVariables = Exact<{
  vendorsIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ImportSpecificVendorsFromQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { importSpecificVendorsFromQuickbooks?: Maybe<(
    { __typename?: 'ImportSpecificVendorsFromQuickbooksPayload' }
    & { consultantCompanies?: Maybe<Array<(
      { __typename?: 'ConsultantCompany' }
      & Pick<ConsultantCompany, 'id' | 'name' | 'quickbooksVendorId' | 'active'>
      & { primaryConsultantContact?: Maybe<(
        { __typename?: 'ConsultantContact' }
        & Pick<ConsultantContact, 'fname' | 'lname' | 'email'>
      )> }
    )>> }
  )> }
);

export type QboCustomerDetailsFragment = (
  { __typename?: 'QBOCustomer' }
  & Pick<QboCustomer, 'billingAddressLine1' | 'billingAddressLine2' | 'billingAddressCity' | 'billingAddressState' | 'billingAddressZipCode' | 'billingAddressCountry' | 'companyName' | 'displayName' | 'emailAddress' | 'firstName' | 'id' | 'lastName' | 'primaryPhoneNumber' | 'quickbooksId' | 'level' | 'parentQuickbooksId'>
  & { qboInstance: (
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id'>
  ) }
);

export type QuickbooksCustomersQueryVariables = Exact<{
  filters?: Maybe<QboCustomerFilters>;
}>;


export type QuickbooksCustomersQuery = (
  { __typename?: 'Query' }
  & { quickbooksCustomers: Array<(
    { __typename?: 'QBOCustomer' }
    & QboCustomerDetailsFragment
  )> }
);

export type AllNonQboClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNonQboClientsQuery = (
  { __typename?: 'Query' }
  & { allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'id' | 'displayName' | 'email'>
  )>> }
);

export type HasUnImportedQboClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasUnImportedQboClientsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo' | 'hasUnmatchedQboCustomers' | 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
      ) }
    )> }
  )> }
);

export type ImportCustomersFromQboMutationVariables = Exact<{
  input: ImportCustomersFromQboInput;
}>;


export type ImportCustomersFromQboMutation = (
  { __typename?: 'Mutation' }
  & { importCustomersFromQbo?: Maybe<(
    { __typename?: 'ImportCustomersFromQBOPayload' }
    & Pick<ImportCustomersFromQboPayload, 'enqueued'>
  )> }
);

export type SyncImportCustomersFromQboMutationVariables = Exact<{
  input: SyncImportCustomersFromQboInput;
}>;


export type SyncImportCustomersFromQboMutation = (
  { __typename?: 'Mutation' }
  & { syncImportCustomersFromQbo?: Maybe<(
    { __typename?: 'SyncImportCustomersFromQBOPayload' }
    & { clients?: Maybe<Array<(
      { __typename?: 'Client' }
      & ClientResultFragment
    )>> }
  )> }
);

export type QuickbooksAccountPayableAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickbooksAccountPayableAccountsQuery = (
  { __typename?: 'Query' }
  & { quickbooksAccountPayableAccounts?: Maybe<Array<(
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'id' | 'name' | 'active' | 'fullyQualifiedName' | 'quickbooksId' | 'parentQuickbooksId'>
  )>>, quickbooksPaymentAccounts?: Maybe<Array<(
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'id' | 'name' | 'active' | 'fullyQualifiedName' | 'quickbooksId' | 'parentQuickbooksId'>
  )>> }
);

export type QuickSearchProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'color' | 'id' | 'name' | 'number' | 'slug'>
);

export type QuickSearchClientFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'syncedToQbo' | 'id' | 'name' | 'displayName' | 'slug'>
);

export type QuickSearchProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'image' | 'initials' | 'name' | 'slug'>
);

export type QuickSearchQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type QuickSearchQuery = (
  { __typename?: 'Query' }
  & { searchableProjects?: Maybe<Array<(
    { __typename?: 'Project' }
    & QuickSearchProjectFragment
  )>>, profiles: (
    { __typename?: 'ProfileConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Profile' }
      & QuickSearchProfileFragment
    )>>> }
  ), clients: (
    { __typename?: 'ClientConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Client' }
      & QuickSearchClientFragment
    )>>> }
  ) }
);

export type RoleTemplatesWithRateTablesQueryVariables = Exact<{
  includeDeactivated?: Maybe<Scalars['Boolean']>;
}>;


export type RoleTemplatesWithRateTablesQuery = (
  { __typename?: 'Query' }
  & { roleTemplates?: Maybe<Array<(
    { __typename?: 'RoleTemplate' }
    & RoleTemplateWithRatesFragment
  )>> }
);

export type RateTableQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type RateTableQuery = (
  { __typename?: 'Query' }
  & { rateTable: (
    { __typename?: 'RateTable' }
    & RateTableWithRatesFragment
    & RateTableDependenciesFragment
  ) }
);

export type RateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RateQuery = (
  { __typename?: 'Query' }
  & { rate: (
    { __typename?: 'Rate' }
    & { dependencyCounts?: Maybe<(
      { __typename?: 'RateDependencyCounts' }
      & Pick<RateDependencyCounts, 'projects' | 'roles'>
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName'>
      )> }
    )> }
    & RateDetailFragment
  ) }
);

export type RoleTemplateActiveRateTablesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleTemplateActiveRateTablesQuery = (
  { __typename?: 'Query' }
  & { roleTemplate?: Maybe<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
    & { activeRateTables: Array<(
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    )> }
  )> }
);

export type RateDetailFragment = (
  { __typename?: 'Rate' }
  & Pick<Rate, 'id' | 'effectiveOn' | 'amount' | 'displayDate' | 'isInitialRate' | 'isCurrentRate'>
);

export type RoleTemplateWithRatesFragment = (
  { __typename?: 'RoleTemplate' }
  & Pick<RoleTemplate, 'id' | 'archivedAt' | 'name' | 'isAssigned'>
  & { assignedProfiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'fullName'>
  )>>, profiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'fullName'>
  )>>, rateTables: Array<(
    { __typename?: 'RateTable' }
    & RateTableWithRatesFragment
  )> }
);

export type RateTableDetailFragment = (
  { __typename?: 'RateTable' }
  & Pick<RateTable, 'id' | 'name' | 'archivedAt' | 'slug'>
  & { roleTemplate: (
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'archivedAt' | 'name'>
    & { profiles?: Maybe<Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'name' | 'fullName'>
    )>> }
  ), profiles?: Maybe<Array<(
    { __typename?: 'Profile' }
    & RateTableProfileFragment
  )>> }
);

export type RateTableProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'initials' | 'slug' | 'fullName' | 'fname' | 'lname' | 'title' | 'image'>
);

export type RateTableDependenciesFragment = (
  { __typename?: 'RateTable' }
  & { dependencyCounts?: Maybe<(
    { __typename?: 'RateTableDependencyCounts' }
    & Pick<RateTableDependencyCounts, 'projects'>
    & { profiles: Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fullName'>
    )> }
  )> }
);

export type RateTableWithRatesFragment = (
  { __typename?: 'RateTable' }
  & { orderedRates: Array<(
    { __typename?: 'Rate' }
    & RateDetailFragment
  )>, currentRate: (
    { __typename?: 'Rate' }
    & RateDetailFragment
  ) }
  & RateTableDetailFragment
);

export type ArchiveRoleTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveRoleTemplateMutation = (
  { __typename?: 'Mutation' }
  & { archiveRoleTemplate?: Maybe<(
    { __typename?: 'ArchiveRoleTemplatePayload' }
    & { roleTemplate?: Maybe<(
      { __typename?: 'RoleTemplate' }
      & RoleTemplateWithRatesFragment
    )> }
  )> }
);

export type UnArchiveRoleTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnArchiveRoleTemplateMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveRoleTemplate?: Maybe<(
    { __typename?: 'UnarchiveRoleTemplatePayload' }
    & { roleTemplate?: Maybe<(
      { __typename?: 'RoleTemplate' }
      & RoleTemplateWithRatesFragment
    )> }
  )> }
);

export type UpdateRoleTemplateNameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateRoleTemplateNameMutation = (
  { __typename?: 'Mutation' }
  & { updateRoleTemplate?: Maybe<(
    { __typename?: 'UpdateRoleTemplatePayload' }
    & { roleTemplate?: Maybe<(
      { __typename?: 'RoleTemplate' }
      & RoleTemplateWithRatesFragment
    )> }
  )> }
);

export type CreateRoleTemplateAndRateMutationVariables = Exact<{
  templateName: Scalars['String'];
  rateName: Scalars['String'];
  rate: Scalars['Float'];
}>;


export type CreateRoleTemplateAndRateMutation = (
  { __typename?: 'Mutation' }
  & { createRoleTemplateAndRate?: Maybe<(
    { __typename?: 'CreateRoleTemplateAndRatePayload' }
    & { roleTemplate?: Maybe<(
      { __typename?: 'RoleTemplate' }
      & RoleTemplateWithRatesFragment
    )> }
  )> }
);

export type DeleteRateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteRate?: Maybe<(
    { __typename?: 'DeleteRatePayload' }
    & { rate?: Maybe<(
      { __typename?: 'Rate' }
      & Pick<Rate, 'id'>
    )> }
  )> }
);

export type UpdateRateMutationVariables = Exact<{
  id: Scalars['ID'];
  effectiveOn?: Maybe<Scalars['ISO8601Date']>;
  amount: Scalars['Float'];
}>;


export type UpdateRateMutation = (
  { __typename?: 'Mutation' }
  & { updateRate?: Maybe<(
    { __typename?: 'UpdateRatePayload' }
    & { rate?: Maybe<(
      { __typename?: 'Rate' }
      & RateDetailFragment
    )> }
  )> }
);

export type CreateRateMutationVariables = Exact<{
  rateTableId: Scalars['ID'];
  effectiveOn: Scalars['ISO8601Date'];
  amount: Scalars['Float'];
}>;


export type CreateRateMutation = (
  { __typename?: 'Mutation' }
  & { createRate?: Maybe<(
    { __typename?: 'CreateRatePayload' }
    & { rate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'id'>
    ) }
  )> }
);

export type CreateRateTableMutationVariables = Exact<{
  initialRateAmount: Scalars['Float'];
  roleTemplateId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateRateTableMutation = (
  { __typename?: 'Mutation' }
  & { createRateTable?: Maybe<(
    { __typename?: 'CreateRateTablePayload' }
    & { rateTable: (
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    ) }
  )> }
);

export type UpdateRateTableMutationVariables = Exact<{
  input: EditRateTableInput;
}>;


export type UpdateRateTableMutation = (
  { __typename?: 'Mutation' }
  & { editRateTable?: Maybe<(
    { __typename?: 'EditRateTablePayload' }
    & { rateTable: (
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    ) }
  )> }
);

export type DuplicateRateTableMutationVariables = Exact<{
  input: DuplicateRateTableInput;
}>;


export type DuplicateRateTableMutation = (
  { __typename?: 'Mutation' }
  & { duplicateRateTable?: Maybe<(
    { __typename?: 'DuplicateRateTablePayload' }
    & { rateTable: (
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    ) }
  )> }
);

export type DeactivateRateTableMutationVariables = Exact<{
  input: DeactivateRateTableInput;
}>;


export type DeactivateRateTableMutation = (
  { __typename?: 'Mutation' }
  & { deactivateRateTable?: Maybe<(
    { __typename?: 'DeactivateRateTablePayload' }
    & { rateTable: (
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    ) }
  )> }
);

export type ReactivateRateTableMutationVariables = Exact<{
  input: ReactivateRateTableInput;
}>;


export type ReactivateRateTableMutation = (
  { __typename?: 'Mutation' }
  & { reactivateRateTable?: Maybe<(
    { __typename?: 'ReactivateRateTablePayload' }
    & { rateTable: (
      { __typename?: 'RateTable' }
      & RateTableWithRatesFragment
    ) }
  )> }
);

export type ProfitReportQueryVariables = Exact<{
  dateRange: DateRangeInput;
  statuses?: Maybe<Array<ProjectStatus> | ProjectStatus>;
  phaseFeeType?: Maybe<PhaseFee>;
  revenueSource: ProfitRevenueSource;
  includeConsultants?: Maybe<Scalars['Boolean']>;
}>;


export type ProfitReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { profit: (
      { __typename?: 'ProfitReport' }
      & Pick<ProfitReport, 'pastRevenueSource' | 'futureRevenueSource' | 'dateIncrement' | 'partialEnd' | 'partialStart'>
      & { chart: Array<(
        { __typename?: 'ProfitReportChartEntry' }
        & Pick<ProfitReportChartEntry, 'xAxis'>
        & { data: Array<(
          { __typename?: 'ProfitReportChartEntryData' }
          & Pick<ProfitReportChartEntryData, 'costs' | 'revenue' | 'revenueSource' | 'profit' | 'margin'>
        )> }
      )>, totals: (
        { __typename?: 'ProfitReportTotals' }
        & Pick<ProfitReportTotals, 'costs' | 'revenue' | 'profit'>
      ) }
    ) }
  ) }
);

export type ProfitDriverFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfitDriverFiltersQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>>, phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id' | 'name'>
  )>, allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'displayName' | 'id'>
  )>> }
);

export type ProfitDriversV2QueryVariables = Exact<{
  filters: ProfitDriversV2FiltersInput;
}>;


export type ProfitDriversV2Query = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { profitDriversV2: Array<(
      { __typename?: 'ProfitDriversV2' }
      & Pick<ProfitDriversV2, 'identifier' | 'name' | 'costs' | 'revenue' | 'profit' | 'margin'>
    )> }
  ) }
);

export type TimeLogFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeLogFiltersQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )>>, projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>>, phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id' | 'name'>
  )>, roleTemplates?: Maybe<Array<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type TimelogChartAvailableFiltersQueryVariables = Exact<{
  intent: TimeLogIntent;
  filters: TimeLogFiltersInput;
}>;


export type TimelogChartAvailableFiltersQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { timeLog: (
      { __typename?: 'TimeLog' }
      & { availableFilters: (
        { __typename?: 'AvailableFilters' }
        & { phases: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, profiles: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )> }
      ) }
    ) }
  ) }
);

export type ProjectIdQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectIdQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  )> }
);

export type ProjectPerformanceProjectQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectPerformanceProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { orderedPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & { profile?: Maybe<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'id' | 'fullName'>
        )> }
      )> }
    )> }
  )> }
);

export type ProjectTimelogChartQueryVariables = Exact<{
  intent: TimeLogIntent;
  filters: TimeLogFiltersInput;
}>;


export type ProjectTimelogChartQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { timeLog: (
      { __typename?: 'TimeLog' }
      & Pick<TimeLog, 'dateIncrement' | 'partialStart' | 'partialEnd'>
      & { filters: (
        { __typename?: 'TimeLogFilters' }
        & Pick<TimeLogFilters, 'activityIds' | 'profileIds' | 'phaseTemplateIds'>
        & { dateRange: (
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'beginDate' | 'endDate'>
        ) }
      ), chart: Array<(
        { __typename?: 'TimeLogChartEntry' }
        & Pick<TimeLogChartEntry, 'xAxis'>
        & { data: Array<(
          { __typename?: 'TimeLogChartEntryData' }
          & Pick<TimeLogChartEntryData, 'billable' | 'fee' | 'hours'>
        )> }
      )>, totals: (
        { __typename?: 'TimeLogTotals' }
        & Pick<TimeLogTotals, 'billableFee' | 'billableHours' | 'billablePercent' | 'nonBillableHours' | 'totalHours'>
      ), project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'color'>
      )>, availableFilters: (
        { __typename?: 'AvailableFilters' }
        & { activities: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, projects: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, phases: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, phaseTemplates: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, profiles: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )>, roleTemplates: Array<(
          { __typename?: 'FilterOption' }
          & Pick<FilterOption, 'label' | 'value'>
        )> }
      ) }
    ) }
  ) }
);

export type TimelogChartQueryVariables = Exact<{
  intent: TimeLogIntent;
  filters: TimeLogFiltersInput;
}>;


export type TimelogChartQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { timeLog: (
      { __typename?: 'TimeLog' }
      & Pick<TimeLog, 'dateIncrement' | 'partialStart' | 'partialEnd'>
      & { filters: (
        { __typename?: 'TimeLogFilters' }
        & Pick<TimeLogFilters, 'activityIds' | 'profileIds' | 'phaseTemplateIds'>
        & { dateRange: (
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'beginDate' | 'endDate'>
        ) }
      ), chart: Array<(
        { __typename?: 'TimeLogChartEntry' }
        & Pick<TimeLogChartEntry, 'xAxis'>
        & { data: Array<(
          { __typename?: 'TimeLogChartEntryData' }
          & Pick<TimeLogChartEntryData, 'billable' | 'fee' | 'hours'>
        )> }
      )>, totals: (
        { __typename?: 'TimeLogTotals' }
        & Pick<TimeLogTotals, 'billableFee' | 'billableHours' | 'billablePercent' | 'nonBillableHours' | 'totalHours'>
      ), project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'color'>
      )> }
    ) }
  ) }
);

export type TimelogGoupedByActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'isBillable' | 'name' | 'id'>
);

export type TimelogGroupedByPhaseFragment = (
  { __typename?: 'Phase' }
  & Pick<Phase, 'name' | 'id'>
);

export type TimelogGroupedByProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fullName'>
);

export type TimelogGroupedByProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'number' | 'color'>
  & { projectName: Project['name'] }
);

export type TimelogGroupedTableQueryVariables = Exact<{
  intent: TimeLogIntent;
  groupBy: GroupByInput;
  filters: EntryFiltersInput;
}>;


export type TimelogGroupedTableQuery = (
  { __typename?: 'Query' }
  & { groupedEntries: Array<(
    { __typename?: 'GroupedEntriesGroup' }
    & Pick<GroupedEntriesGroup, 'totalAmount' | 'totalEntries' | 'totalHours'>
    & { grouping?: Maybe<(
      { __typename?: 'Activity' }
      & TimelogGoupedByActivityFragment
    ) | (
      { __typename?: 'Phase' }
      & TimelogGroupedByPhaseFragment
    ) | (
      { __typename?: 'Profile' }
      & TimelogGroupedByProfileFragment
    ) | (
      { __typename?: 'Project' }
      & TimelogGroupedByProjectFragment
    )> }
  )> }
);

export type TimelogTableQueryVariables = Exact<{
  sortBy?: Maybe<EntriesSortInput>;
  intent: TimeLogIntent;
  filters: EntryFiltersInput;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type TimelogTableQuery = (
  { __typename?: 'Query' }
  & { entries: (
    { __typename?: 'EntryConnection' }
    & Pick<EntryConnection, 'totalHours' | 'totalAmount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Entry' }
      & Pick<Entry, 'date' | 'notes' | 'rate' | 'hours' | 'amount'>
      & { activity?: Maybe<(
        { __typename?: 'Activity' }
        & Pick<Activity, 'id' | 'name'>
      )>, profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'name' | 'slug'>
      )>, project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'color' | 'number' | 'name' | 'slug' | 'id'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name'>
      )>, phase?: Maybe<(
        { __typename?: 'Phase' }
        & Pick<Phase, 'id' | 'abbreviation'>
      )> }
    )>>> }
  ) }
);

export type ProjectPerformanceChartQueryVariables = Exact<{
  filters: ProjectPerformanceFiltersInput;
}>;


export type ProjectPerformanceChartQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { projectPerformance: (
      { __typename?: 'ProjectPerformance' }
      & Pick<ProjectPerformance, 'projectEndDate' | 'dateIncrement'>
      & { dateRange: (
        { __typename?: 'DateRange' }
        & Pick<DateRange, 'beginDate' | 'endDate'>
      ), projectBudget: (
        { __typename?: 'ProjectPerformanceProjectBudget' }
        & { total?: Maybe<(
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        )>, fixedFee?: Maybe<(
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        )>, hourlyFee?: Maybe<(
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        )> }
      ), entries: Array<(
        { __typename?: 'ProjectPerformanceChartEntryData' }
        & Pick<ProjectPerformanceChartEntryData, 'startDate' | 'endDate' | 'invoicedFee'>
        & { planned: (
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        ), consumed: (
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        ), allocated: (
          { __typename?: 'ProjectPerformanceHoursAndFee' }
          & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
        ) }
      )> }
    ) }
  ) }
);

export type ProjectPerformancePhaseFragment = (
  { __typename?: 'ProjectPerformancePhases' }
  & Pick<ProjectPerformancePhases, 'invoicedFee'>
  & { phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'feeType' | 'status'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'slug'>
    )> }
  ), planned: (
    { __typename?: 'ProjectPerformanceHoursAndFee' }
    & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
  ), consumed: (
    { __typename?: 'ProjectPerformanceHoursAndFee' }
    & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
  ), allocated: (
    { __typename?: 'ProjectPerformanceHoursAndFee' }
    & Pick<ProjectPerformanceHoursAndFee, 'hours' | 'fee'>
  ) }
);

export type ProjectPerformancePhasesQueryVariables = Exact<{
  filters?: Maybe<ProjectPerformanceFiltersInput>;
  endDate: Scalars['ISO8601Date'];
}>;


export type ProjectPerformancePhasesQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { projectPerformance: (
      { __typename?: 'ProjectPerformance' }
      & { phases: Array<(
        { __typename?: 'ProjectPerformancePhases' }
        & ProjectPerformancePhaseFragment
      )> }
    ) }
  ) }
);

export type TaxesCollectedQueryVariables = Exact<{
  filters: TaxRatesFiltersInput;
  sortBy?: Maybe<TaxesSortInput>;
}>;


export type TaxesCollectedQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { taxesCollected: Array<(
      { __typename?: 'TaxRateTotals' }
      & Pick<TaxRateTotals, 'name' | 'percent' | 'total'>
    )> }
  ) }
);

export type ExpenseReportQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpenseReportQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canSendToQuickbooks'>
      ) }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
        & { reimbursableExpensesAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & Pick<QboAccount, 'id' | 'name'>
        )>, nonReimbursableExpensesAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & Pick<QboAccount, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type RealizationReportFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type RealizationReportFiltersQuery = (
  { __typename?: 'Query' }
  & { projectsOptions?: Maybe<Array<(
    { __typename?: 'ProjectOption' }
    & Pick<ProjectOption, 'id' | 'name'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type RealizationReportQueryVariables = Exact<{
  filters?: Maybe<RealizationFiltersInput>;
}>;


export type RealizationReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { realization: (
      { __typename?: 'RealizationReport' }
      & Pick<RealizationReport, 'dateIncrement' | 'defaultRealizationRate' | 'earliestEntryDate'>
      & { chart: Array<(
        { __typename?: 'RealizationChartEntry' }
        & Pick<RealizationChartEntry, 'xAxis'>
        & { data: (
          { __typename?: 'RealizationChartEntryData' }
          & Pick<RealizationChartEntryData, 'billableFees' | 'invoicedFees' | 'realizationRate'>
        ) }
      )> }
    ) }
  ) }
);

export type RealizationTotalFieldsFragment = (
  { __typename?: 'RealizationTotals' }
  & Pick<RealizationTotals, 'billableFees' | 'invoicedFees' | 'realizationRate'>
);

export type RealizationTableDataFragment = (
  { __typename?: 'RealizationTableEntryData' }
  & Pick<RealizationTableEntryData, 'billableFees' | 'invoicedFees' | 'rangeEnd' | 'rangeStart' | 'realizationRate'>
);

export type DetailedRealizationReportQueryVariables = Exact<{
  filters?: Maybe<RealizationFiltersInput>;
}>;


export type DetailedRealizationReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { realization: (
      { __typename?: 'RealizationReport' }
      & Pick<RealizationReport, 'dateIncrement' | 'defaultRealizationRate' | 'earliestEntryDate'>
      & { chart: Array<(
        { __typename?: 'RealizationChartEntry' }
        & Pick<RealizationChartEntry, 'xAxis'>
        & { data: (
          { __typename?: 'RealizationChartEntryData' }
          & Pick<RealizationChartEntryData, 'billableFees' | 'invoicedFees' | 'realizationRate'>
        ) }
      )>, totals: (
        { __typename?: 'RealizationTotals' }
        & RealizationTotalFieldsFragment
      ), table: Array<(
        { __typename?: 'RealizationTableEntryData' }
        & RealizationTableDataFragment
      )> }
    ) }
  ) }
);

export type RealizationProjectTableDataFragment = (
  { __typename?: 'RealizationProjectEntry' }
  & Pick<RealizationProjectEntry, 'billableFees' | 'invoicedFees' | 'projectName' | 'realizationRate'>
);

export type RealizationProjectsQueryVariables = Exact<{
  filters: RealizationFiltersInput;
}>;


export type RealizationProjectsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { realizationProjects: Array<(
      { __typename?: 'RealizationProjectEntry' }
      & RealizationProjectTableDataFragment
    )> }
  ) }
);

export type ExportRealizationMutationVariables = Exact<{
  filters: RealizationFiltersInput;
}>;


export type ExportRealizationMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { realization?: Maybe<(
      { __typename?: 'RealizationPayload' }
      & Pick<RealizationPayload, 'id'>
    )> }
  ) }
);

export type UtilizationReportFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type UtilizationReportFiltersQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UtilizationReportQueryVariables = Exact<{
  filters?: Maybe<UtilizationFiltersInput>;
}>;


export type UtilizationReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { utilization: (
      { __typename?: 'UtilizationReport' }
      & Pick<UtilizationReport, 'dateIncrement' | 'defaultUtilizationRate' | 'earliestEntryDate'>
      & { chart: Array<(
        { __typename?: 'UtilizationChartEntry' }
        & Pick<UtilizationChartEntry, 'totalHours' | 'xAxis'>
        & { data: (
          { __typename?: 'UtilizationChartEntryData' }
          & Pick<UtilizationChartEntryData, 'billableHours' | 'nonBillableHours' | 'totalHours' | 'utilizationRate'>
        ) }
      )> }
    ) }
  ) }
);

export type UtilizationTotalsFragment = (
  { __typename?: 'UtilizationTotals' }
  & Pick<UtilizationTotals, 'billableHours' | 'nonBillableHours' | 'totalHours' | 'utilizationRate'>
);

export type UtilizationTableDataFragment = (
  { __typename?: 'UtilizationTableEntryData' }
  & Pick<UtilizationTableEntryData, 'billableHours' | 'nonBillableHours' | 'rangeEnd' | 'rangeStart' | 'totalHours' | 'utilizationRate'>
);

export type DetailedUtilizationReportQueryVariables = Exact<{
  filters?: Maybe<UtilizationFiltersInput>;
}>;


export type DetailedUtilizationReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { utilization: (
      { __typename?: 'UtilizationReport' }
      & Pick<UtilizationReport, 'dateIncrement' | 'defaultUtilizationRate' | 'earliestEntryDate'>
      & { chart: Array<(
        { __typename?: 'UtilizationChartEntry' }
        & Pick<UtilizationChartEntry, 'totalHours' | 'xAxis'>
        & { data: (
          { __typename?: 'UtilizationChartEntryData' }
          & Pick<UtilizationChartEntryData, 'billableHours' | 'nonBillableHours' | 'totalHours' | 'utilizationRate'>
        ) }
      )>, totals: (
        { __typename?: 'UtilizationTotals' }
        & UtilizationTotalsFragment
      ), table: Array<(
        { __typename?: 'UtilizationTableEntryData' }
        & UtilizationTableDataFragment
      )> }
    ) }
  ) }
);

export type UtilizationPeopleFragment = (
  { __typename?: 'UtilizationProfileEntry' }
  & Pick<UtilizationProfileEntry, 'billableHours' | 'fname' | 'id' | 'lname' | 'nonBillableHours' | 'slug' | 'targetRate' | 'totalHours' | 'utilizationRate'>
);

export type UtilizationProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type UtilizationProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name' | 'utilizationRate'>
  ) }
);

export type UtilizationPeopleQueryVariables = Exact<{
  filters: UtilizationFiltersInput;
}>;


export type UtilizationPeopleQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { utilizationPeople: Array<(
      { __typename?: 'UtilizationProfileEntry' }
      & UtilizationPeopleFragment
    )> }
  ) }
);

export type ExportUtilizationMutationVariables = Exact<{
  filters: UtilizationFiltersInput;
}>;


export type ExportUtilizationMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { utilization?: Maybe<(
      { __typename?: 'UtilizationPayload' }
      & Pick<UtilizationPayload, 'id'>
    )> }
  ) }
);

export type ProfitDriversExportMutationVariables = Exact<{
  input: ProfitDriversExportInput;
}>;


export type ProfitDriversExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { profitDrivers?: Maybe<(
      { __typename?: 'ProfitDriversExportPayload' }
      & Pick<ProfitDriversExportPayload, 'id'>
    )> }
  ) }
);

export type ProfitForecastEmptyStateQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfitForecastEmptyStateQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'projectsCount' | 'overheadMultiplier'>
      & { profilesWithoutCompensation: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName' | 'slug'>
      )> }
    )> }
  )> }
);

export type ProfitForecastEntriesFragment = (
  { __typename?: 'ProfitForecastEntryType' }
  & Pick<ProfitForecastEntryType, 'consultantCost' | 'consultantRevenue' | 'fixedRevenue' | 'hourlyRevenue' | 'margin' | 'month' | 'overheadCost' | 'projectedCost' | 'projectedProfit' | 'projectedRevenue' | 'wageCost'>
);

export type ProfitForecastQueryVariables = Exact<{
  filters: ProfitForecastFiltersInput;
}>;


export type ProfitForecastQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'projectsCount' | 'overheadMultiplier'>
      & { profilesWithoutCompensation: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName' | 'slug'>
      )> }
    )> }
  )>, reports: (
    { __typename?: 'Reports' }
    & { profitForecast?: Maybe<(
      { __typename?: 'ProfitForecast' }
      & { totals: (
        { __typename?: 'ProfitForecastTotalsType' }
        & Pick<ProfitForecastTotalsType, 'cost' | 'profit' | 'revenue'>
      ), entries: Array<(
        { __typename?: 'ProfitForecastEntryType' }
        & ProfitForecastEntriesFragment
      )> }
    )> }
  ) }
);

export type ProfitForecastExportMutationVariables = Exact<{
  input: ProfitForecastExportInput;
}>;


export type ProfitForecastExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { profitForecast?: Maybe<(
      { __typename?: 'ProfitForecastExportPayload' }
      & Pick<ProfitForecastExportPayload, 'id'>
    )> }
  ) }
);

export type ProfitReportEmptyStateQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfitReportEmptyStateQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'connectedToQbo' | 'overheadMultiplier'>
      & { profilesWithoutCompensation: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName' | 'slug'>
      )>, settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'costsImportStartDate'>
      ) }
    )> }
  )>, invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount'>
  ) }
);

export type QboProfitReportEntryFragment = (
  { __typename?: 'ProfitProfitQBOVersionEntryType' }
  & Pick<ProfitProfitQboVersionEntryType, 'consultantRevenue' | 'directCosts' | 'fixedRevenue' | 'hourlyRevenue' | 'margin' | 'month' | 'nonOperatingCosts' | 'operatingCosts' | 'otherRevenue' | 'totalCosts' | 'totalProfit' | 'totalRevenue'>
);

export type QboProfitReportQueryVariables = Exact<{
  filters: ProfitFiltersInput;
}>;


export type QboProfitReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { qboProfit?: Maybe<(
      { __typename?: 'ProfitQBOVersion' }
      & Pick<ProfitQboVersion, 'minimumReportDate' | 'showConsultantWarning'>
      & { entries: Array<(
        { __typename?: 'ProfitProfitQBOVersionEntryType' }
        & QboProfitReportEntryFragment
      )>, totals: (
        { __typename?: 'ProfitQBOVersionTotalsType' }
        & Pick<ProfitQboVersionTotalsType, 'revenue' | 'profit' | 'cost'>
      ) }
    )> }
  ) }
);

export type NonQboProfitReportEntryFragment = (
  { __typename?: 'ProfitProfitNonQBOVersionEntryType' }
  & Pick<ProfitProfitNonQboVersionEntryType, 'consultantCosts' | 'consultantRevenue' | 'fixedRevenue' | 'hourlyRevenue' | 'margin' | 'month' | 'otherCosts' | 'otherRevenue' | 'overheadCosts' | 'totalCosts' | 'totalProfit' | 'totalRevenue' | 'wageCosts'>
);

export type NonQboProfitReportQueryVariables = Exact<{
  filters: ProfitFiltersInput;
}>;


export type NonQboProfitReportQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { nonQboProfit?: Maybe<(
      { __typename?: 'ProfitNonQBOVersion' }
      & Pick<ProfitNonQboVersion, 'minimumReportDate' | 'showConsultantWarning'>
      & { entries: Array<(
        { __typename?: 'ProfitProfitNonQBOVersionEntryType' }
        & NonQboProfitReportEntryFragment
      )>, totals: (
        { __typename?: 'ProfitNonQBOVersionTotalsType' }
        & Pick<ProfitNonQboVersionTotalsType, 'revenue' | 'profit' | 'cost'>
      ) }
    )> }
  ) }
);

export type ProfitQboExportMutationVariables = Exact<{
  input: ProfitQboVersionExportInput;
}>;


export type ProfitQboExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { profitQboVersion?: Maybe<(
      { __typename?: 'ProfitQBOVersionExportPayload' }
      & Pick<ProfitQboVersionExportPayload, 'id'>
    )> }
  ) }
);

export type ProfitNonQboExportMutationVariables = Exact<{
  input: ProfitNonQboVersionExportInput;
}>;


export type ProfitNonQboExportMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { profitNonQboVersion?: Maybe<(
      { __typename?: 'ProfitNonQBOVersionExportPayload' }
      & Pick<ProfitNonQboVersionExportPayload, 'id'>
    )> }
  ) }
);

export type ProjectFinancialsEntryItemFragment = (
  { __typename?: 'ProjectFinancialsEntryType' }
  & Pick<ProjectFinancialsEntryType, 'billed' | 'billedPercent' | 'consultantFee' | 'cost' | 'logged' | 'loggedBillableBudgetConsumed' | 'loggedBillableHours' | 'loggedNonBillableBudgetConsumed' | 'loggedNonBillableHours' | 'loggedPercent' | 'margin' | 'markup' | 'name' | 'open' | 'paid' | 'pastDue' | 'planned' | 'profit' | 'remaining' | 'revenue' | 'status' | 'unbilled' | 'warning'>
  & { children?: Maybe<Array<(
    { __typename?: 'ProjectFinancialsEntryType' }
    & Pick<ProjectFinancialsEntryType, 'billed' | 'billedPercent' | 'consultantFee' | 'cost' | 'logged' | 'loggedPercent' | 'margin' | 'markup' | 'markupPercent' | 'name' | 'open' | 'paid' | 'pastDue' | 'planned' | 'profit' | 'remaining' | 'revenue' | 'unbilled'>
  )>> }
);

export type ProjectFinancialsOtherEntryItemFragment = (
  { __typename?: 'ProjectFinancialsOtherEntryType' }
  & Pick<ProjectFinancialsOtherEntryType, 'billed' | 'description' | 'invoiceNumber' | 'invoiceSlug' | 'invoiceVersion' | 'item' | 'open' | 'paid' | 'pastDue' | 'quantity' | 'rate'>
);

export type ProjectFinancialsRetainerEntryItemFragment = (
  { __typename?: 'ProjectFinancialsRetainerEntryType' }
  & Pick<ProjectFinancialsRetainerEntryType, 'billed' | 'description' | 'invoiceIssueDate' | 'invoiceNumber' | 'invoicePaidDate' | 'invoiceSlug' | 'invoiceVersion' | 'item'>
);

export type ProjectFinancialsProjectPhasesQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProjectFinancialsProjectPhasesQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & { phases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )> }
  )> }
);

export type ProjectFinancialsQueryVariables = Exact<{
  slug: Scalars['String'];
  includeConsultantsInPhases: Scalars['Boolean'];
  filters: ProjectFinancialsFiltersInput;
}>;


export type ProjectFinancialsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { financials: (
      { __typename?: 'ProjectFinancials' }
      & Pick<ProjectFinancials, 'hasPhaseConsultants'>
      & { phases: Array<(
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      )>, phasesTotals: (
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      ), consultants: Array<(
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      )>, consultantsTotals: (
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      ), expenses: Array<(
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      )>, expensesTotals: (
        { __typename?: 'ProjectFinancialsEntryType' }
        & ProjectFinancialsEntryItemFragment
      ), others: Array<(
        { __typename?: 'ProjectFinancialsOtherEntryType' }
        & ProjectFinancialsOtherEntryItemFragment
      )>, othersTotals: (
        { __typename?: 'ProjectFinancialsOtherEntryType' }
        & ProjectFinancialsOtherEntryItemFragment
      ), retainers: Array<(
        { __typename?: 'ProjectFinancialsRetainerEntryType' }
        & ProjectFinancialsRetainerEntryItemFragment
      )>, retainersTotals: (
        { __typename?: 'ProjectFinancialsRetainerEntryType' }
        & ProjectFinancialsRetainerEntryItemFragment
      ) }
    ) }
  ) }
);

export type ExportProjectFinancialsMutationVariables = Exact<{
  slug: Scalars['String'];
  filters?: Maybe<ProjectFinancialsFiltersInput>;
}>;


export type ExportProjectFinancialsMutation = (
  { __typename?: 'Mutation' }
  & { startExport: (
    { __typename?: 'StartExportMutations' }
    & { projectFinancials?: Maybe<(
      { __typename?: 'ProjectFinancialsPayload' }
      & Pick<ProjectFinancialsPayload, 'id'>
    )> }
  ) }
);

export type RoleTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleTemplatesQuery = (
  { __typename?: 'Query' }
  & { roleTemplates?: Maybe<Array<(
    { __typename?: 'RoleTemplate' }
    & Pick<RoleTemplate, 'id' | 'name'>
  )>> }
);

export type CreateSalesforceLeadMutationVariables = Exact<{
  input: CreateSalesforceLeadInput;
}>;


export type CreateSalesforceLeadMutation = (
  { __typename?: 'Mutation' }
  & { createSalesforceLead?: Maybe<(
    { __typename?: 'CreateSalesforceLeadPayload' }
    & Pick<CreateSalesforceLeadPayload, 'email'>
  )> }
);

export type AppDefaultsQueryVariables = Exact<{ [key: string]: never; }>;


export type AppDefaultsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo' | 'timer' | 'timesheetNotesReminder' | 'timesheetNotesExample' | 'invoiceFooter'>
      & { overheads: Array<(
        { __typename?: 'Overhead' }
        & AppDefaultsOverheadItemFragment
      )>, settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ptoEnabled' | 'ajeraEnabled' | 'activityRequired' | 'defaultConsultantMarkup' | 'multiQboInstanceEnabled'>
      ), timesheetLock: (
        { __typename?: 'TimesheetLock' }
        & AppDefaultsTimesheetLockingFragment
      ), timesheetReminder: (
        { __typename?: 'TimesheetReminder' }
        & AppDefaultsTimesheetReminderFragment
      ), activities: Array<(
        { __typename?: 'Activity' }
        & AppDefaultsActivityItemFragment
      )>, terms?: Maybe<Array<(
        { __typename?: 'Term' }
        & AppDefaultsInvoiceTermFragment
      )>>, consultantTemplates: Array<(
        { __typename?: 'ConsultantTemplate' }
        & AppDefaultsConsultantTemplateFragment
      )>, expenseCategories?: Maybe<Array<(
        { __typename?: 'ExpenseCategory' }
        & AppDefaultsExpenseCategoryFragment
      )>>, qboInstances: Array<(
        { __typename?: 'QBOInstance' }
        & AppDefaultsQboInstanceFragment
      )>, phaseTemplates: Array<(
        { __typename?: 'PhaseTemplate' }
        & AppDefaultsPhaseTemplateFragment
      )>, quickbooksItems: Array<(
        { __typename?: 'QuickbooksItem' }
        & AppDefaultsQuickbooksItemFragment
      )> }
    )> }
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & AppDefaultsProjectCategoryFragment
  )>>, taxRates?: Maybe<Array<(
    { __typename?: 'TaxRate' }
    & AppDefaultsTaxRateFragment
  )>> }
);

export type AppDefaultsQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'id' | 'companyName' | 'connected'>
  & { qboExpenseAccounts: Array<(
    { __typename?: 'QBOAccount' }
    & AppDefaultsQboAccountFragment
  )>, qboItems: Array<(
    { __typename?: 'QBOItem' }
    & AppDefaultsQboItemFragment
  )> }
);

export type AppDefaultsQboAccountFragment = (
  { __typename?: 'QBOAccount' }
  & Pick<QboAccount, 'id' | 'name' | 'quickbooksId' | 'parentQuickbooksId'>
);

export type AppDefaultsQboItemFragment = (
  { __typename?: 'QBOItem' }
  & Pick<QboItem, 'id' | 'name' | 'active' | 'itemType' | 'quickbooksId' | 'parentQuickbooksId'>
  & { qboInstance: (
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id'>
  ) }
);

export type AppDefaultsQuickbooksItemFragment = (
  { __typename?: 'QuickbooksItem' }
  & Pick<QuickbooksItem, 'description' | 'id' | 'active' | 'itemType' | 'name' | 'quickbooksId' | 'quickbooksParentRef'>
);

export type AppDefaultsTimesheetLockingFragment = (
  { __typename?: 'TimesheetLock' }
  & Pick<TimesheetLock, 'days' | 'isLocked' | 'time'>
);

export type UpdateTimesheetLockMutationVariables = Exact<{
  timesheetLock: Scalars['Boolean'];
}>;


export type UpdateTimesheetLockMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetLock?: Maybe<(
    { __typename?: 'UpdateTimesheetLockPayload' }
    & { timesheetLock?: Maybe<(
      { __typename?: 'TimesheetLock' }
      & AppDefaultsTimesheetLockingFragment
    )> }
  )> }
);

export type UpdateTimesheetLockDaysTimeMutationVariables = Exact<{
  timesheetLockDays: Scalars['Int'];
  timesheetLockTime: Scalars['Float'];
}>;


export type UpdateTimesheetLockDaysTimeMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetLockTime?: Maybe<(
    { __typename?: 'UpdateTimesheetLockTimePayload' }
    & { timesheetLock?: Maybe<(
      { __typename?: 'TimesheetLock' }
      & AppDefaultsTimesheetLockingFragment
    )> }
  )>, updateTimesheetLockDays?: Maybe<(
    { __typename?: 'UpdateTimesheetLockDaysPayload' }
    & { timesheetLock?: Maybe<(
      { __typename?: 'TimesheetLock' }
      & AppDefaultsTimesheetLockingFragment
    )> }
  )> }
);

export type AppDefaultsTimesheetReminderFragment = (
  { __typename?: 'TimesheetReminder' }
  & Pick<TimesheetReminder, 'isOn' | 'day' | 'time' | 'note'>
);

export type UpdateTimesheetReminderMutationVariables = Exact<{
  timesheetReminder: Scalars['Boolean'];
}>;


export type UpdateTimesheetReminderMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetReminder?: Maybe<(
    { __typename?: 'UpdateTimesheetReminderPayload' }
    & { timesheetReminder?: Maybe<(
      { __typename?: 'TimesheetReminder' }
      & AppDefaultsTimesheetReminderFragment
    )> }
  )> }
);

export type UpdateTimesheetReminderDayTimeNoteMutationVariables = Exact<{
  timesheetReminderDay: Scalars['Int'];
  timesheetReminderTime: Scalars['Float'];
  timesheetReminderNote: Scalars['String'];
}>;


export type UpdateTimesheetReminderDayTimeNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetReminderDay?: Maybe<(
    { __typename?: 'UpdateTimesheetReminderDayPayload' }
    & { timesheetReminder?: Maybe<(
      { __typename?: 'TimesheetReminder' }
      & AppDefaultsTimesheetReminderFragment
    )> }
  )>, updateTimesheetReminderTime?: Maybe<(
    { __typename?: 'UpdateTimesheetReminderTimePayload' }
    & { timesheetReminder?: Maybe<(
      { __typename?: 'TimesheetReminder' }
      & AppDefaultsTimesheetReminderFragment
    )> }
  )>, updateTimesheetReminderNote?: Maybe<(
    { __typename?: 'UpdateTimesheetReminderNotePayload' }
    & { timesheetReminder?: Maybe<(
      { __typename?: 'TimesheetReminder' }
      & AppDefaultsTimesheetReminderFragment
    )> }
  )> }
);

export type UpdateTimerPreferenceMutationVariables = Exact<{
  hasTimer: Scalars['Boolean'];
}>;


export type UpdateTimerPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { updateTimer?: Maybe<(
    { __typename?: 'UpdateTimerPayload' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'timer'>
    ) }
  )> }
);

export type AppDefaultsOverheadItemFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name' | 'availableHours'>
  & { timeOffPolicies?: Maybe<Array<(
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name' | 'slug'>
  )>> }
);

export type CreateOverheadItemMutationVariables = Exact<{
  name: Scalars['String'];
  availableHours: Scalars['Boolean'];
}>;


export type CreateOverheadItemMutation = (
  { __typename?: 'Mutation' }
  & { createOverhead?: Maybe<(
    { __typename?: 'CreateOverheadPayload' }
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & AppDefaultsOverheadItemFragment
    )> }
  )> }
);

export type DeleteOverheadItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOverheadItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteOverhead?: Maybe<(
    { __typename?: 'DeleteOverheadPayload' }
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & AppDefaultsOverheadItemFragment
    )> }
  )> }
);

export type UpdateOverheadItemMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  availableHours: Scalars['Boolean'];
}>;


export type UpdateOverheadItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOverhead?: Maybe<(
    { __typename?: 'UpdateOverheadPayload' }
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & AppDefaultsOverheadItemFragment
    )> }
  )> }
);

export type AppDefaultsActivityItemFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'name' | 'isBillable'>
  & { ajeraIdentifier?: Maybe<(
    { __typename?: 'AjeraIdentifier' }
    & Pick<AjeraIdentifier, 'id' | 'key' | 'typeKey' | 'recordId' | 'recordType'>
  )> }
);

export type CreateActivityItemMutationVariables = Exact<{
  name: Scalars['String'];
  isBillable: Scalars['Boolean'];
}>;


export type CreateActivityItemMutation = (
  { __typename?: 'Mutation' }
  & { createActivity?: Maybe<(
    { __typename?: 'CreateActivityPayload' }
    & { activity?: Maybe<(
      { __typename?: 'Activity' }
      & AppDefaultsActivityItemFragment
    )> }
  )> }
);

export type DeleteActivityItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteActivityItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteActivity?: Maybe<(
    { __typename?: 'DeleteActivityPayload' }
    & { activity?: Maybe<(
      { __typename?: 'Activity' }
      & AppDefaultsActivityItemFragment
    )> }
  )> }
);

export type UpdateActivityItemMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  isBillable: Scalars['Boolean'];
}>;


export type UpdateActivityItemMutation = (
  { __typename?: 'Mutation' }
  & { updateActivity?: Maybe<(
    { __typename?: 'UpdateActivityPayload' }
    & { activity?: Maybe<(
      { __typename?: 'Activity' }
      & AppDefaultsActivityItemFragment
    )> }
  )> }
);

export type UpdateOrganizationSettingActivityRequiredMutationVariables = Exact<{
  isActivityRequired: Scalars['Boolean'];
}>;


export type UpdateOrganizationSettingActivityRequiredMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationActivityRequired?: Maybe<(
    { __typename?: 'UpdateActivityRequiredPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'activityRequired'>
      ) }
    )> }
  )> }
);

export type UpdateAjeraIdentifierMutationVariables = Exact<{
  input: UpdateAjeraIdentifierInput;
}>;


export type UpdateAjeraIdentifierMutation = (
  { __typename?: 'Mutation' }
  & { updateAjeraIdentifier?: Maybe<(
    { __typename?: 'UpdateAjeraIdentifierPayload' }
    & { ajeraIdentifier: (
      { __typename?: 'AjeraIdentifier' }
      & Pick<AjeraIdentifier, 'id' | 'key' | 'typeKey' | 'recordId' | 'recordType'>
    ) }
  )> }
);

export type UpdateTimesheetNotesReminderMutationVariables = Exact<{
  timesheetNotesReminder: Scalars['Boolean'];
}>;


export type UpdateTimesheetNotesReminderMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetNotesReminder?: Maybe<(
    { __typename?: 'UpdateTimesheetNotesReminderPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'timesheetNotesReminder'>
    )> }
  )> }
);

export type UpdateTimesheetNotesExampleMutationVariables = Exact<{
  timesheetNotesExample: Scalars['String'];
}>;


export type UpdateTimesheetNotesExampleMutation = (
  { __typename?: 'Mutation' }
  & { updateTimesheetNotesExample?: Maybe<(
    { __typename?: 'UpdateTimesheetNotesExamplePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'timesheetNotesExample'>
    )> }
  )> }
);

export type AppDefaultsProjectCategoryFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type CreateProjectCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateProjectCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory?: Maybe<(
    { __typename?: 'CreateCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & AppDefaultsProjectCategoryFragment
    )> }
  )> }
);

export type UpdateProjectCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateProjectCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory?: Maybe<(
    { __typename?: 'UpdateCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & AppDefaultsProjectCategoryFragment
    )> }
  )> }
);

export type DeleteProjectCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProjectCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory?: Maybe<(
    { __typename?: 'DeleteCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & AppDefaultsProjectCategoryFragment
    )> }
  )> }
);

export type UpdateInvoiceFooterMutationVariables = Exact<{
  invoiceFooter: Scalars['String'];
}>;


export type UpdateInvoiceFooterMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceFooter?: Maybe<(
    { __typename?: 'UpdateInvoiceFooterPayload' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'invoiceFooter'>
    ) }
  )> }
);

export type UpdateOrganizationConsultantMarkupMutationVariables = Exact<{
  defaultConsultantMarkup: Scalars['Float'];
}>;


export type UpdateOrganizationConsultantMarkupMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationConsultantMarkup?: Maybe<(
    { __typename?: 'UpdateOrganizationConsultantMarkupPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'defaultConsultantMarkup'>
      ) }
    )> }
  )> }
);

export type AppDefaultsDraftInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'slug'>
);

export type AppDefaultsTaxRateFragment = (
  { __typename?: 'TaxRate' }
  & Pick<TaxRate, 'id' | 'name' | 'percent'>
  & { draftInvoices?: Maybe<Array<(
    { __typename?: 'Invoice' }
    & AppDefaultsDraftInvoiceFragment
  )>> }
);

export type CreateTaxRateMutationVariables = Exact<{
  name: Scalars['String'];
  percent: Scalars['Float'];
}>;


export type CreateTaxRateMutation = (
  { __typename?: 'Mutation' }
  & { createTaxRate?: Maybe<(
    { __typename?: 'CreateTaxRatePayload' }
    & { taxRate: (
      { __typename?: 'TaxRate' }
      & AppDefaultsTaxRateFragment
    ) }
  )> }
);

export type UpdateTaxRateMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Float'];
}>;


export type UpdateTaxRateMutation = (
  { __typename?: 'Mutation' }
  & { updateTaxRate?: Maybe<(
    { __typename?: 'UpdateTaxRatePayload' }
    & { taxRate: (
      { __typename?: 'TaxRate' }
      & AppDefaultsTaxRateFragment
    ) }
  )> }
);

export type DeleteTaxRateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaxRateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaxRate?: Maybe<(
    { __typename?: 'DeleteTaxRatePayload' }
    & { taxRate?: Maybe<(
      { __typename?: 'TaxRate' }
      & Pick<TaxRate, 'id'>
    )> }
  )> }
);

export type AppDefaultsInvoiceTermFragment = (
  { __typename?: 'Term' }
  & Pick<Term, 'id' | 'name' | 'days' | 'active'>
);

export type CreateInvoiceTermMutationVariables = Exact<{
  name: Scalars['String'];
  days: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type CreateInvoiceTermMutation = (
  { __typename?: 'Mutation' }
  & { createTerm?: Maybe<(
    { __typename?: 'CreateTermPayload' }
    & { term?: Maybe<(
      { __typename?: 'Term' }
      & AppDefaultsInvoiceTermFragment
    )> }
  )> }
);

export type UpdateInvoiceTermMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  days: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateInvoiceTermMutation = (
  { __typename?: 'Mutation' }
  & { updateTerm?: Maybe<(
    { __typename?: 'UpdateTermPayload' }
    & { term?: Maybe<(
      { __typename?: 'Term' }
      & AppDefaultsInvoiceTermFragment
    )> }
  )> }
);

export type AppDefaultsConsultantTemplateFragment = (
  { __typename?: 'ConsultantTemplate' }
  & Pick<ConsultantTemplate, 'id' | 'name'>
  & { quickbooksItem?: Maybe<(
    { __typename?: 'QuickbooksItem' }
    & AppDefaultsQuickbooksItemFragment
  )> }
);

export type CreateConsultantTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  quickbooksItemId?: Maybe<Scalars['ID']>;
}>;


export type CreateConsultantTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createConsultantTemplate?: Maybe<(
    { __typename?: 'CreateConsultantTemplatePayload' }
    & { consultantTemplate?: Maybe<(
      { __typename?: 'ConsultantTemplate' }
      & AppDefaultsConsultantTemplateFragment
    )> }
  )> }
);

export type DeleteConsultantTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteConsultantTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteConsultantTemplate?: Maybe<(
    { __typename?: 'DeleteConsultantTemplatePayload' }
    & { consultantTemplate?: Maybe<(
      { __typename?: 'ConsultantTemplate' }
      & AppDefaultsConsultantTemplateFragment
    )> }
  )> }
);

export type UpdateConsultantTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateConsultantTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantTemplate?: Maybe<(
    { __typename?: 'UpdateConsultantTemplatePayload' }
    & { consultantTemplate?: Maybe<(
      { __typename?: 'ConsultantTemplate' }
      & AppDefaultsConsultantTemplateFragment
    )> }
  )> }
);

export type AppDefaultsExpenseCategoryFragment = (
  { __typename?: 'ExpenseCategory' }
  & Pick<ExpenseCategory, 'id' | 'name' | 'markupPercentage'>
  & { dependencyCounts: (
    { __typename?: 'ExpenseCategoryDependencyCounts' }
    & Pick<ExpenseCategoryDependencyCounts, 'invoices' | 'expenses'>
  ), expenseCategoryQboItems: Array<(
    { __typename?: 'ExpenseCategoryQBOItem' }
    & Pick<ExpenseCategoryQboItem, 'id'>
  )> }
);

export type CreateExpenseCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  markupPercentage: Scalars['Float'];
  qboExpenseAccountId?: Maybe<Scalars['ID']>;
  qboItemMapping?: Maybe<Array<ExpenseCategoryQboItemMapping> | ExpenseCategoryQboItemMapping>;
}>;


export type CreateExpenseCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createExpenseCategory?: Maybe<(
    { __typename?: 'CreateExpenseCategoryPayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & AppDefaultsExpenseCategoryFragment
    )> }
  )> }
);

export type UpdateExpenseCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  markupPercentage: Scalars['Float'];
}>;


export type UpdateExpenseCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseCategory?: Maybe<(
    { __typename?: 'UpdateExpenseCategoryPayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & AppDefaultsExpenseCategoryFragment
    )> }
  )> }
);

export type DeleteExpenseCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteExpenseCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteExpenseCategory?: Maybe<(
    { __typename?: 'DeleteExpenseCategoryPayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & AppDefaultsExpenseCategoryFragment
    )> }
  )> }
);

export type AppDefaultsPhaseTemplateFragment = (
  { __typename?: 'PhaseTemplate' }
  & Pick<PhaseTemplate, 'id' | 'name' | 'abbreviation' | 'feeType'>
);

export type CreatePhaseTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
  qboItemIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreatePhaseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createPhaseTemplate?: Maybe<(
    { __typename?: 'CreatePhaseTemplatePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { phaseTemplates: Array<(
        { __typename?: 'PhaseTemplate' }
        & AppDefaultsPhaseTemplateFragment
      )> }
    )> }
  )> }
);

export type DeletePhaseTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePhaseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deletePhaseTemplate?: Maybe<(
    { __typename?: 'DeletePhaseTemplatePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { phaseTemplates: Array<(
        { __typename?: 'PhaseTemplate' }
        & AppDefaultsPhaseTemplateFragment
      )> }
    )> }
  )> }
);

export type UpdatePhaseTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  feeType: PhaseFee;
}>;


export type UpdatePhaseTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updatePhaseTemplate?: Maybe<(
    { __typename?: 'UpdatePhaseTemplatePayload' }
    & { phaseTemplate?: Maybe<(
      { __typename?: 'PhaseTemplate' }
      & AppDefaultsPhaseTemplateFragment
    )> }
  )> }
);

export type OrganizationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationSettingsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'logo' | 'timeZone' | 'overheadMultiplier' | 'emailSenderName' | 'emailReplyToAddress' | 'isSubscribed' | 'telephone' | 'website'>
      & { paySchedule?: Maybe<(
        { __typename?: 'PaySchedule' }
        & Pick<PaySchedule, 'id'>
      )>, settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'ajeraEnabled' | 'defaultRealizationRate' | 'defaultUtilizationRate' | 'unitOfMeasurement' | 'ptoEnabled' | 'timeOffToggledAt' | 'applyUtilizationRateToExistingProfiles' | 'defaultHoursPerWeek' | 'yearlyWorkingHours'>
      ), address?: Maybe<(
        { __typename?: 'Address' }
        & OrganizationSettingsAddressFragment
      )>, currency: (
        { __typename?: 'Currency' }
        & OrganizationSettingsCurrencyFragment
      ), locale: (
        { __typename?: 'Locale' }
        & OrganizationSettingsLocaleFragment
      ), stripeAccount?: Maybe<(
        { __typename?: 'StripeAccount' }
        & Pick<StripeAccount, 'active'>
      )> }
    )> }
  )>, timeZones: Array<(
    { __typename?: 'TimeZone' }
    & Pick<TimeZone, 'value' | 'name'>
  )>, locales: Array<(
    { __typename?: 'Locale' }
    & Pick<Locale, 'id' | 'name' | 'tag'>
  )>, currencies: Array<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'code' | 'decimalDigits' | 'id' | 'name' | 'symbol'>
  )> }
);

export type OrganizationSettingsAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'country'>
);

export type OrganizationSettingsCurrencyFragment = (
  { __typename?: 'Currency' }
  & Pick<Currency, 'id' | 'name' | 'symbol' | 'code' | 'decimalDigits'>
);

export type OrganizationSettingsLocaleFragment = (
  { __typename?: 'Locale' }
  & Pick<Locale, 'id' | 'name' | 'tag'>
);

export type OrgUpdateCompanyLogoMutationVariables = Exact<{
  logo?: Maybe<Scalars['String']>;
}>;


export type OrgUpdateCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationLogo?: Maybe<(
    { __typename?: 'UpdateOrganizationLogoPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'logo'>
    )> }
  )> }
);

export type OrgUpdateOrgNameMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrgUpdateOrgNameMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationName?: Maybe<(
    { __typename?: 'UpdateOrganizationNamePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )> }
);

export type OrgUpdateCompanyAddressMutationVariables = Exact<{
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
}>;


export type OrgUpdateCompanyAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationAddress?: Maybe<(
    { __typename?: 'UpdateOrganizationAddressPayload' }
    & { address: (
      { __typename?: 'Address' }
      & OrganizationSettingsAddressFragment
    ) }
  )> }
);

export type OrgUpdateClientEmailSettingsMutationVariables = Exact<{
  emailSenderName?: Maybe<Scalars['String']>;
  emailReplyToAddress?: Maybe<Scalars['String']>;
}>;


export type OrgUpdateClientEmailSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationEmailSenderName?: Maybe<(
    { __typename?: 'UpdateOrganizationEmailSenderNamePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'emailSenderName'>
    )> }
  )>, updateOrganizationEmailReplyToAddress?: Maybe<(
    { __typename?: 'UpdateOrganizationEmailReplyToAddressPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'emailReplyToAddress'>
    )> }
  )> }
);

export type OrgUpdateRegionalPreferencesMutationVariables = Exact<{
  timeZone: Scalars['String'];
  localeId: Scalars['ID'];
  currencyId: Scalars['ID'];
  unitOfMeasurement: UnitOfMeasurement;
}>;


export type OrgUpdateRegionalPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationTimeZone?: Maybe<(
    { __typename?: 'UpdateOrganizationTimeZonePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'timeZone'>
    )> }
  )>, updateOrganizationLocale?: Maybe<(
    { __typename?: 'UpdateOrganizationLocalePayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { locale: (
        { __typename?: 'Locale' }
        & Pick<Locale, 'name' | 'tag'>
      ) }
    )> }
  )>, updateOrganizationCurrency?: Maybe<(
    { __typename?: 'UpdateOrganizationCurrencyPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'code' | 'decimalDigits' | 'id' | 'name' | 'symbol'>
      ) }
    )> }
  )>, updateOrganizationUnitOfMeasurement?: Maybe<(
    { __typename?: 'UpdateOrganizationUnitOfMeasurementPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type OrgUpdateOverheadMutiplierMutationVariables = Exact<{
  overheadMultiplier?: Maybe<Scalars['Float']>;
}>;


export type OrgUpdateOverheadMutiplierMutation = (
  { __typename?: 'Mutation' }
  & { updateOverheadMultiplier?: Maybe<(
    { __typename?: 'UpdateOverheadMultiplierPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'overheadMultiplier'>
    )> }
  )> }
);

export type UpdateOrganizationDefaultUtilizationInfoMutationVariables = Exact<{
  input: UpdateDefaultUtilizationInfoInput;
}>;


export type UpdateOrganizationDefaultUtilizationInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationDefaultUtilizationInfo?: Maybe<(
    { __typename?: 'UpdateDefaultUtilizationInfoPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'defaultUtilizationRate' | 'defaultHoursPerWeek' | 'applyUtilizationRateToExistingProfiles'>
      ) }
    )> }
  )> }
);

export type UpdateYearlyWorkingHoursMutationVariables = Exact<{
  yearlyWorkingHours: Scalars['Float'];
}>;


export type UpdateYearlyWorkingHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateYearlyWorkingHours?: Maybe<(
    { __typename?: 'UpdateYearlyWorkingHoursPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'yearlyWorkingHours'>
      ) }
    )> }
  )> }
);

export type UpdateOrganizationRealizationTargetMutationVariables = Exact<{
  input: UpdateOrganizationRealizationInput;
}>;


export type UpdateOrganizationRealizationTargetMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationRealization?: Maybe<(
    { __typename?: 'UpdateOrganizationRealizationPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'defaultRealizationRate'>
      ) }
    )> }
  )> }
);

export type UpdateTimeOffMutationVariables = Exact<{
  timeOff: Scalars['Boolean'];
}>;


export type UpdateTimeOffMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeOff?: Maybe<(
    { __typename?: 'UpdateTimeOffPayload' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'timeOffToggledAt' | 'ptoEnabled'>
      ) }
    ) }
  )> }
);

export type QuickbooksSettingsQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'id' | 'companyName' | 'connected' | 'lastConnectedAt'>
  & { qboItems: Array<(
    { __typename?: 'QBOItem' }
    & QuickbooksSettingsQboItemFragment
  )> }
);

export type QuickbooksSettingsQboItemFragment = (
  { __typename?: 'QBOItem' }
  & Pick<QboItem, 'id' | 'name' | 'active' | 'itemType' | 'quickbooksId' | 'parentQuickbooksId'>
  & { qboInstance: (
    { __typename?: 'QBOInstance' }
    & Pick<QboInstance, 'id'>
  ) }
);

export type QuickbooksSettingsQboAccountFragment = (
  { __typename?: 'QBOAccount' }
  & Pick<QboAccount, 'id' | 'name' | 'active' | 'quickbooksId' | 'parentQuickbooksId'>
);

export type QuickbooksSettingsQuickbooksItemFragment = (
  { __typename?: 'QuickbooksItem' }
  & Pick<QuickbooksItem, 'id' | 'name' | 'itemType' | 'active' | 'quickbooksId' | 'quickbooksParentRef'>
);

export type QuickbooksSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuickbooksSettingsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo' | 'connectedQboAccountsCount'>
      & { qboInstances: Array<(
        { __typename?: 'QBOInstance' }
        & { qboTerms: Array<(
          { __typename?: 'QBOTerm' }
          & QuickbooksSettingsQboTermFragment
        )> }
        & QuickbooksSettingsQboInstanceFragment
        & QuickbooksSettingsClientsQboInstanceFragment
        & QuickbooksSettingsConsultantContactsQboInstanceFragment
        & QuickbooksSettingsTaxesQboInstanceFragment
        & QuickbooksSettingsExpenseTypesQboInstanceFragment
        & QuickbooksSettingsExpenseCategoriesAccountsQboInstanceFragment
      )>, expenseCategories?: Maybe<Array<(
        { __typename?: 'ExpenseCategory' }
        & QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragment
        & QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragment
      )>>, phaseTemplates: Array<(
        { __typename?: 'PhaseTemplate' }
        & QuickbooksSettingsPhaseTypesPhaseTemplateFragment
      )>, consultantTemplates: Array<(
        { __typename?: 'ConsultantTemplate' }
        & QuickbooksSettingsConsultantTypesConsultantTemplateFragment
      )>, settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'multiQboInstanceEnabled'>
        & { stripePayoutAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )>, onlinePaymentFeeAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )>, paymentDepositAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
        & QuickbooksSettingsOrganizationConsultantMarkupSettingFragment
        & QuickbooksSettingsExpenseTypesSettingFragment
        & QuickbooksSettingsOrganizationCostsSettingFragment
      ), retainers?: Maybe<Array<(
        { __typename?: 'Retainer' }
        & QuickbooksSettingsRetainerFragment
      )>>, quickbooksItems: Array<(
        { __typename?: 'QuickbooksItem' }
        & QuickbooksSettingsQuickbooksItemFragment
      )> }
    )> }
  )> }
);

export type QuickbooksSettingsConsultantContactsQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'id' | 'matchedQboVendorsCount'>
);

export type QuickbooksSettingsDisconnectQboInstanceMutationVariables = Exact<{
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type QuickbooksSettingsDisconnectQboInstanceMutation = (
  { __typename?: 'Mutation' }
  & { disconnectOrganizationQuickbooks?: Maybe<(
    { __typename?: 'DisconnectOrganizationQuickbooksPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'connectedToQbo'>
      & { qboInstances: Array<(
        { __typename?: 'QBOInstance' }
        & Pick<QboInstance, 'id' | 'connected'>
      )> }
    )> }
  )> }
);

export type QuickbooksSettingsClientsQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'id' | 'matchedQboCustomersCount'>
);

export type QuickbooksSettingsOrganizationConsultantMarkupSettingFragment = (
  { __typename?: 'OrganizationSetting' }
  & Pick<OrganizationSetting, 'id' | 'applyConsultantMappingForMarkup'>
  & { consultantMarkupQuickbooksServiceItem?: Maybe<(
    { __typename?: 'QuickbooksItem' }
    & QuickbooksSettingsQuickbooksItemFragment
  )> }
);

export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationVariables = Exact<{
  applyConsultantMapping: Scalars['Boolean'];
  quickbooksItemId?: Maybe<Scalars['ID']>;
}>;


export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationConsultantMarkupQuickbooksMapping?: Maybe<(
    { __typename?: 'UpdateConsultantMarkupQuickbooksMappingPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & QuickbooksSettingsOrganizationConsultantMarkupSettingFragment
      ) }
    )> }
  )> }
);

export type QuickbooksSettingsConsultantTypesConsultantTemplateFragment = (
  { __typename?: 'ConsultantTemplate' }
  & Pick<ConsultantTemplate, 'id' | 'name'>
  & { quickbooksItem?: Maybe<(
    { __typename?: 'QuickbooksItem' }
    & QuickbooksSettingsQuickbooksItemFragment
  )> }
);

export type UpdateConsultantTemplateQuickbooksMappingMutationVariables = Exact<{
  id: Scalars['ID'];
  quickbooksItemId?: Maybe<Scalars['ID']>;
}>;


export type UpdateConsultantTemplateQuickbooksMappingMutation = (
  { __typename?: 'Mutation' }
  & { updateConsultantTemplateQuickbooksMapping?: Maybe<(
    { __typename?: 'UpdateConsultantTemplateQuickbooksMappingPayload' }
    & { consultantTemplate?: Maybe<(
      { __typename?: 'ConsultantTemplate' }
      & QuickbooksSettingsConsultantTypesConsultantTemplateFragment
    )> }
  )> }
);

export type QuickbooksSettingsOrganizationCostsSettingFragment = (
  { __typename?: 'OrganizationSetting' }
  & Pick<OrganizationSetting, 'id' | 'costsImportStartDate'>
);

export type ImportQboCostsMutationVariables = Exact<{
  input: ImportQboCostsInput;
}>;


export type ImportQboCostsMutation = (
  { __typename?: 'Mutation' }
  & { importQboCosts?: Maybe<(
    { __typename?: 'ImportQBOCostsPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { expenseCategories?: Maybe<Array<(
        { __typename?: 'ExpenseCategory' }
        & Pick<ExpenseCategory, 'id'>
        & { qboExpenseAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & Pick<QboAccount, 'id'>
        )> }
      )>> }
    )> }
  )> }
);

export type QboCostFragment = (
  { __typename?: 'QBOJournalEntryCost' }
  & Pick<QboJournalEntryCost, 'id' | 'description' | 'transactionDate' | 'amount' | 'costCategory'>
  & { qboVendor?: Maybe<(
    { __typename?: 'QuickbooksVendor' }
    & Pick<QuickbooksVendor, 'displayName' | 'quickbooksVendorId'>
  )>, qboCustomer?: Maybe<(
    { __typename?: 'QBOCustomer' }
    & Pick<QboCustomer, 'displayName'>
  )>, qboAccount: (
    { __typename?: 'QBOAccount' }
    & Pick<QboAccount, 'name' | 'id'>
  ), project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )> }
);

export type QuickbooksCostsQueryVariables = Exact<{
  filters?: Maybe<CostsFiltersInput>;
  sortBy?: Maybe<CostsSortInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type QuickbooksCostsQuery = (
  { __typename?: 'Query' }
  & { quickbooksCosts: (
    { __typename?: 'QBOJournalEntryCostConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'QBOJournalEntryCost' }
      & QboCostFragment
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type UpdateCostsMutationVariables = Exact<{
  input: UpdateCostsInput;
}>;


export type UpdateCostsMutation = (
  { __typename?: 'Mutation' }
  & { updateCosts?: Maybe<(
    { __typename?: 'UpdateCostsPayload' }
    & { qboJournalEntryCosts?: Maybe<Array<(
      { __typename?: 'QBOJournalEntryCost' }
      & Pick<QboJournalEntryCost, 'id' | 'costCategory'>
      & { project?: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id'>
      )> }
    )>> }
  )> }
);

export type DeleteCostsMutationVariables = Exact<{
  input: DeleteCostsInput;
}>;


export type DeleteCostsMutation = (
  { __typename?: 'Mutation' }
  & { deleteCosts?: Maybe<(
    { __typename?: 'DeleteCostsPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragment = (
  { __typename?: 'ExpenseCategory' }
  & Pick<ExpenseCategory, 'id' | 'name'>
  & { qboExpenseAccount?: Maybe<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )> }
);

export type QuickbooksSettingsExpenseCategoriesAccountsQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & { qboExpenseAccounts: Array<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )> }
);

export type UpdateExpenseCategoryQuickbooksAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  qboInstanceId?: Maybe<Scalars['ID']>;
  qboExpenseAccountId?: Maybe<Scalars['ID']>;
}>;


export type UpdateExpenseCategoryQuickbooksAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseCategoryQuickbooksAccount?: Maybe<(
    { __typename?: 'UpdateExpenseCategoryQuickbooksAccountPayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragment
    )> }
  )> }
);

export type QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragment = (
  { __typename?: 'ExpenseCategory' }
  & Pick<ExpenseCategory, 'id' | 'name'>
  & { expenseCategoryQboItems: Array<(
    { __typename?: 'ExpenseCategoryQBOItem' }
    & Pick<ExpenseCategoryQboItem, 'id' | 'mapToPhaseQbItem'>
    & { qboInstance: (
      { __typename?: 'QBOInstance' }
      & Pick<QboInstance, 'id'>
    ), qboItem?: Maybe<(
      { __typename?: 'QBOItem' }
      & QuickbooksSettingsQboItemFragment
    )> }
  )> }
);

export type UpdateExpenseCategoryQuickbooksServiceMutationVariables = Exact<{
  id: Scalars['ID'];
  mapToPhaseQbItem: Scalars['Boolean'];
  qboInstanceId: Scalars['ID'];
  qboItemId?: Maybe<Scalars['ID']>;
}>;


export type UpdateExpenseCategoryQuickbooksServiceMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseCategoryQuickbooksService?: Maybe<(
    { __typename?: 'UpdateExpenseCategoryQuickbooksServicePayload' }
    & { expenseCategory?: Maybe<(
      { __typename?: 'ExpenseCategory' }
      & QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragment
    )> }
  )> }
);

export type QuickbooksSettingsExpenseTypesQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & { qboAccountPayableAccounts: Array<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )>, qboPaymentAccounts: Array<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )> }
);

export type QuickbooksSettingsExpenseTypesSettingFragment = (
  { __typename?: 'OrganizationSetting' }
  & { reimbursableExpensesAccount?: Maybe<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )>, nonReimbursableExpensesAccount?: Maybe<(
    { __typename?: 'QBOAccount' }
    & QuickbooksSettingsQboAccountFragment
  )> }
);

export type UpdateReimbursableExpensesAccountMutationVariables = Exact<{
  input: UpdateReimbursableExpensesAccountInput;
}>;


export type UpdateReimbursableExpensesAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationReimbursableExpensesAccount?: Maybe<(
    { __typename?: 'UpdateReimbursableExpensesAccountPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id'>
        & { reimbursableExpensesAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type UpdateNonReimbursableExpensesAccountMutationVariables = Exact<{
  input: UpdateNonReimbursableExpensesAccountInput;
}>;


export type UpdateNonReimbursableExpensesAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationNonReimbursableExpensesAccount?: Maybe<(
    { __typename?: 'UpdateNonReimbursableExpensesAccountPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id'>
        & { nonReimbursableExpensesAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type QuickbooksSettingsQboTermFragment = (
  { __typename?: 'QBOTerm' }
  & Pick<QboTerm, 'id' | 'name' | 'active' | 'dueDays'>
);

export type SyncInvoiceTermsWithQuickbooksMutationVariables = Exact<{
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type SyncInvoiceTermsWithQuickbooksMutation = (
  { __typename?: 'Mutation' }
  & { syncTermsWithQuickbooks?: Maybe<(
    { __typename?: 'SyncTermsWithQuickbooksPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )> }
  )> }
);

export type MonographPaymentAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type MonographPaymentAccountsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & { stripePayoutAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )>, onlinePaymentFeeAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )>, paymentDepositAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type UpdateStripePayoutAccountMutationVariables = Exact<{
  input: UpdateStripePayoutAccountInput;
}>;


export type UpdateStripePayoutAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationStripePayoutAccount?: Maybe<(
    { __typename?: 'UpdateStripePayoutAccountPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id'>
        & { stripePayoutAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type UpdateOnlinePaymentFeeAccountMutationVariables = Exact<{
  input: UpdateOnlinePaymentFeeAccountInput;
}>;


export type UpdateOnlinePaymentFeeAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationOnlinePaymentFeeAccount?: Maybe<(
    { __typename?: 'UpdateOnlinePaymentFeeAccountPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id'>
        & { onlinePaymentFeeAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type UpdatePaymentDepositAccountMutationVariables = Exact<{
  input: UpdatePaymentDepositAccountInput;
}>;


export type UpdatePaymentDepositAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationPaymentDepositAccount?: Maybe<(
    { __typename?: 'UpdatePaymentDepositAccountPayload' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id'>
        & { paymentDepositAccount?: Maybe<(
          { __typename?: 'QBOAccount' }
          & QuickbooksSettingsQboAccountFragment
        )> }
      ) }
    )> }
  )> }
);

export type QuickbooksSettingsPhaseTypesPhaseTemplateFragment = (
  { __typename?: 'PhaseTemplate' }
  & Pick<PhaseTemplate, 'id' | 'name' | 'feeType'>
  & { qboItems: Array<(
    { __typename?: 'QBOItem' }
    & QuickbooksSettingsQboItemFragment
  )> }
);

export type UpdatePhaseTemplateQuickbooksMappingMutationVariables = Exact<{
  id: Scalars['ID'];
  qboItemId?: Maybe<Scalars['ID']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type UpdatePhaseTemplateQuickbooksMappingMutation = (
  { __typename?: 'Mutation' }
  & { updatePhaseTemplateQuickbooksMapping?: Maybe<(
    { __typename?: 'UpdatePhaseTemplateQuickbooksMappingPayload' }
    & { phaseTemplate?: Maybe<(
      { __typename?: 'PhaseTemplate' }
      & Pick<PhaseTemplate, 'id'>
      & { qboItems: Array<(
        { __typename?: 'QBOItem' }
        & QuickbooksSettingsQboItemFragment
      )> }
    )> }
  )> }
);

export type QuickbooksSettingsRetainerFragment = (
  { __typename?: 'Retainer' }
  & Pick<Retainer, 'id'>
  & { qboItem?: Maybe<(
    { __typename?: 'QBOItem' }
    & QuickbooksSettingsQboItemFragment
  )> }
);

export type UpdateRetainerQuickbooksMappingMutationVariables = Exact<{
  qboItemId?: Maybe<Scalars['ID']>;
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type UpdateRetainerQuickbooksMappingMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationRetainerQuickbooksMapping?: Maybe<(
    { __typename?: 'UpdateRetainersQuickbooksMappingPayload' }
    & { retainer?: Maybe<(
      { __typename?: 'Retainer' }
      & QuickbooksSettingsRetainerFragment
    )> }
  )> }
);

export type UnassignedProjectCostsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UnassignedProjectCostsCountQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { qboInstances: Array<(
        { __typename?: 'QBOInstance' }
        & Pick<QboInstance, 'id' | 'connected' | 'unassignedProjectCostsCount'>
      )> }
    )> }
  )> }
);

export type QuickbooksSettingsTaxesQboInstanceFragment = (
  { __typename?: 'QBOInstance' }
  & Pick<QboInstance, 'qboTaxMethod'>
);

export type SyncQboTaxRatesMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncQboTaxRatesMutation = (
  { __typename?: 'Mutation' }
  & { syncQboTaxRates?: Maybe<(
    { __typename?: 'TriggerSyncTaxRatesPayload' }
    & { taxRate?: Maybe<(
      { __typename?: 'TaxRate' }
      & Pick<TaxRate, 'id'>
    )> }
  )> }
);

export type RolesAndRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesAndRatesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { roleTemplates: Array<(
        { __typename?: 'RoleTemplate' }
        & Pick<RoleTemplate, 'id' | 'name' | 'archivedAt'>
        & { profiles?: Maybe<Array<(
          { __typename?: 'Profile' }
          & Pick<Profile, 'name'>
        )>> }
      )> }
    )> }
  )> }
);

export type SettingsPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsPoliciesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { policies?: Maybe<Array<(
        { __typename?: 'Policy' }
        & Pick<Policy, 'id' | 'name'>
      )>> }
    )> }
  )> }
);

export type SettingsPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsPermissionsQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'isDemo' | 'connectedToQbo'>
      & { policies?: Maybe<Array<(
        { __typename?: 'Policy' }
        & SettingsPolicyFragment
      )>> }
    )> }
  )> }
);

export type SettingsPolicyFragment = (
  { __typename?: 'Policy' }
  & Pick<Policy, 'id' | 'isAdmin' | 'name' | 'slug'>
  & { permissions: (
    { __typename?: 'Permissions' }
    & CurrentProfilePermissionsFragment
  ), profiles: Array<(
    { __typename?: 'Profile' }
    & SettingsPolicyProfileFragment
  )> }
);

export type SettingsPolicyProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'initials' | 'fname' | 'lname' | 'image' | 'slug' | 'title'>
);

export type OrganizationUpdatePolicyMutationVariables = Exact<{
  policyId: Scalars['ID'];
  value: Scalars['Boolean'];
  permissionsKey: PermissionsKey;
}>;


export type OrganizationUpdatePolicyMutation = (
  { __typename?: 'Mutation' }
  & { updatePermission?: Maybe<(
    { __typename?: 'UpdatePermissionPayload' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & SettingsPolicyFragment
    )> }
  )> }
);

export type OrganizationDuplicatePolicyMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type OrganizationDuplicatePolicyMutation = (
  { __typename?: 'Mutation' }
  & { duplicatePolicy?: Maybe<(
    { __typename?: 'DuplicatePolicyPayload' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & SettingsPolicyFragment
    )> }
  )> }
);

export type OrganizationCreatePolicyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrganizationCreatePolicyMutation = (
  { __typename?: 'Mutation' }
  & { createPolicy?: Maybe<(
    { __typename?: 'CreatePolicyPayload' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & SettingsPolicyFragment
    )> }
  )> }
);

export type OrganizationDeletePolicyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationDeletePolicyMutation = (
  { __typename?: 'Mutation' }
  & { deletePolicy?: Maybe<(
    { __typename?: 'DeletePolicyPayload' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & SettingsPolicyFragment
    )> }
  )> }
);

export type UpdatePolicyNameMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdatePolicyNameMutation = (
  { __typename?: 'Mutation' }
  & { updatePolicy?: Maybe<(
    { __typename?: 'UpdatePolicyPayload' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'isAdmin' | 'name' | 'slug'>
    )> }
  )> }
);

export type LogSignUpInformationMutationVariables = Exact<{
  email: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  organizationSize?: Maybe<Scalars['String']>;
  organizationLocation?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  howDidYouHearAboutUs?: Maybe<Scalars['String']>;
}>;


export type LogSignUpInformationMutation = (
  { __typename?: 'Mutation' }
  & { logSignUpInformation?: Maybe<(
    { __typename?: 'LogSignUpInformationPayload' }
    & Pick<LogSignUpInformationPayload, 'icp'>
  )> }
);

export type CheckForExistingUserMutationVariables = Exact<{
  input: CheckForExistingUserInput;
}>;


export type CheckForExistingUserMutation = (
  { __typename?: 'Mutation' }
  & { checkForExistingUser?: Maybe<(
    { __typename?: 'CheckForExistingUserPayload' }
    & Pick<CheckForExistingUserPayload, 'userExists'>
  )> }
);

export type OnboardingIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingIndustriesQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )> }
);

export type OnboardingLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OnboardingLocationsQuery = (
  { __typename?: 'Query' }
  & { __type?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )> }
);

export type StaffingTimesheetFragment = (
  { __typename?: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'beginDate' | 'hoursAllocated' | 'hoursAllotted' | 'overheadHours' | 'overheadHoursAllocated' | 'timeOffRequestHours' | 'timeOffHolidayHours'>
);

export type StaffingProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'name' | 'image' | 'initials' | 'title'>
  & { timesheets: Array<(
    { __typename?: 'Timesheet' }
    & StaffingTimesheetFragment
  )> }
);

export type StaffingEntryGroupFragment = (
  { __typename?: 'EntryGroup' }
  & Pick<EntryGroup, 'id' | 'allocatedByUser' | 'allocation'>
  & { timesheet: (
    { __typename?: 'Timesheet' }
    & Pick<Timesheet, 'id' | 'beginDate' | 'hoursAllocated'>
  ), entries?: Maybe<Array<(
    { __typename?: 'Entry' }
    & Pick<Entry, 'id' | 'hours'>
  )>> }
);

export type StaffingOverheadFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name'>
  & { staffingEntryGroups: Array<(
    { __typename?: 'EntryGroup' }
    & StaffingEntryGroupFragment
  )> }
);

export type StaffingRoleFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name' | 'isOverstaffed' | 'hours' | 'hoursAllocated' | 'hoursLogged' | 'exclusivelyHasUserAllocations'>
  & { rateTable: (
    { __typename?: 'RateTable' }
    & { currentRate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'amount'>
    ) }
  ), staffingEntryGroups: Array<(
    { __typename?: 'EntryGroup' }
    & StaffingEntryGroupFragment
  )>, profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'name'>
  )>, phase: (
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'beginDate' | 'endDate' | 'name' | 'abbreviation' | 'budget'>
  ), project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'number' | 'color' | 'slug' | 'status'>
  )> }
);

export type StaffingProfilesQueryVariables = Exact<{
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type StaffingProfilesQuery = (
  { __typename?: 'Query' }
  & { staffing: (
    { __typename?: 'Staffing' }
    & Pick<Staffing, 'id'>
    & { profiles: Array<(
      { __typename?: 'Profile' }
      & StaffingProfileFragment
    )> }
  ) }
);

export type StaffingProfileQueryVariables = Exact<{
  profileId: Scalars['ID'];
  beginDate: Scalars['ISO8601Date'];
  endDate?: Maybe<Scalars['ISO8601Date']>;
}>;


export type StaffingProfileQuery = (
  { __typename?: 'Query' }
  & { staffing: (
    { __typename?: 'Staffing' }
    & Pick<Staffing, 'id'>
    & { profile: (
      { __typename?: 'StaffingProfile' }
      & { roles: Array<(
        { __typename?: 'Role' }
        & StaffingRoleFragment
      )>, overheads: Array<(
        { __typename?: 'Overhead' }
        & StaffingOverheadFragment
      )>, timeOff: Array<(
        { __typename?: 'StaffingCell' }
        & Pick<StaffingCell, 'timesheetBeginDate' | 'value'>
      )> }
    ) }
  ), profile: (
    { __typename?: 'Profile' }
    & { timesheets: Array<(
      { __typename?: 'Timesheet' }
      & StaffingTimesheetFragment
    )> }
  ) }
);

export type StaffingTotalsQueryVariables = Exact<{
  beginDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type StaffingTotalsQuery = (
  { __typename?: 'Query' }
  & { staffing: (
    { __typename?: 'Staffing' }
    & Pick<Staffing, 'id'>
    & { totals: Array<(
      { __typename?: 'StaffingTotals' }
      & Pick<StaffingTotals, 'beginDate' | 'endDate' | 'totalAssignedHours' | 'totalAvailableHours'>
    )> }
  ) }
);

export type CreateStaffingRoleAllocationMutationVariables = Exact<{
  input: CreateStaffingRoleAllocationInput;
}>;


export type CreateStaffingRoleAllocationMutation = (
  { __typename?: 'Mutation' }
  & { createStaffingRoleAllocation?: Maybe<(
    { __typename?: 'CreateStaffingRoleAllocationPayload' }
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'hoursAllocated'>
      & { staffingEntryGroups: Array<(
        { __typename?: 'EntryGroup' }
        & StaffingEntryGroupFragment
      )>, profile?: Maybe<(
        { __typename?: 'Profile' }
        & { timesheets: Array<(
          { __typename?: 'Timesheet' }
          & { entryGroups?: Maybe<Array<(
            { __typename?: 'EntryGroup' }
            & Pick<EntryGroup, 'id' | 'allocatedByUser' | 'allocation'>
          )>> }
          & StaffingTimesheetFragment
        )> }
      )> }
    ) }
  )> }
);

export type UpdateOverheadAllocationMutationVariables = Exact<{
  input: UpdateEntryGroupAllocationInput;
}>;


export type UpdateOverheadAllocationMutation = (
  { __typename?: 'Mutation' }
  & { updateEntryGroupAllocation?: Maybe<(
    { __typename?: 'UpdateEntryGroupAllocationPayload' }
    & { entryGroup?: Maybe<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id' | 'allocation'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
        & { staffingEntryGroups: Array<(
          { __typename?: 'EntryGroup' }
          & StaffingEntryGroupFragment
        )>, profile?: Maybe<(
          { __typename?: 'Profile' }
          & { timesheets: Array<(
            { __typename?: 'Timesheet' }
            & StaffingTimesheetFragment
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type BulkCreateStaffingRoleAllocationsMutationVariables = Exact<{
  input: BulkCreateStaffingRoleAllocationsInput;
}>;


export type BulkCreateStaffingRoleAllocationsMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateStaffingRoleAllocations?: Maybe<(
    { __typename?: 'BulkCreateStaffingRoleAllocationsPayload' }
    & { role: (
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
      & { staffingEntryGroups: Array<(
        { __typename?: 'EntryGroup' }
        & StaffingEntryGroupFragment
      )> }
    ) }
  )> }
);

export type BulkUpdateStaffingOverheadAllocationsMutationVariables = Exact<{
  input: BulkUpdateStaffingOverheadAllocationsInput;
}>;


export type BulkUpdateStaffingOverheadAllocationsMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateStaffingOverheadAllocations?: Maybe<(
    { __typename?: 'BulkUpdateStaffingOverheadAllocationsPayload' }
    & { entryGroups: Array<(
      { __typename?: 'EntryGroup' }
      & StaffingEntryGroupFragment
    )> }
  )> }
);

export type GenerateStaffingAllocationsMutationVariables = Exact<{
  input: GenerateStaffingAllocationsInput;
}>;


export type GenerateStaffingAllocationsMutation = (
  { __typename?: 'Mutation' }
  & { generateStaffingAllocations?: Maybe<(
    { __typename?: 'GenerateStaffingAllocationsPayload' }
    & { entryGroups: Array<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id' | 'allocation'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
      )> }
    )> }
  )> }
);

export type GlobalRebalanceAllocationsMutationVariables = Exact<{
  input: GlobalRebalanceAllocationsInput;
}>;


export type GlobalRebalanceAllocationsMutation = (
  { __typename?: 'Mutation' }
  & { globalRebalanceAllocations?: Maybe<(
    { __typename?: 'GlobalRebalanceAllocationsPayload' }
    & Pick<GlobalRebalanceAllocationsPayload, 'jobBatchBid'>
  )> }
);

export type CopyAllocationsFromPreviousWeekMutationVariables = Exact<{
  input: CopyAllocationsFromPreviousWeekInput;
}>;


export type CopyAllocationsFromPreviousWeekMutation = (
  { __typename?: 'Mutation' }
  & { copyAllocationsFromPreviousWeek?: Maybe<(
    { __typename?: 'CopyAllocationsFromPreviousWeekPayload' }
    & { entryGroups: Array<(
      { __typename?: 'EntryGroup' }
      & Pick<EntryGroup, 'id' | 'allocation'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id'>
      )> }
    )> }
  )> }
);

export type BatchJobStatusSubscriptionVariables = Exact<{
  input: Scalars['String'];
}>;


export type BatchJobStatusSubscription = (
  { __typename?: 'Subscription' }
  & { batchJobStatus: (
    { __typename?: 'BatchJobStatusPayload' }
    & { status?: Maybe<(
      { __typename?: 'BatchJobStatus' }
      & Pick<BatchJobStatus, 'bid' | 'failInfo' | 'failures' | 'isComplete' | 'pending' | 'total'>
    )> }
  ) }
);

export type StripeAccountResolverQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeAccountResolverQuery = (
  { __typename?: 'Query' }
  & { stripeAccount?: Maybe<(
    { __typename?: 'StripeAccount' }
    & Pick<StripeAccount, 'id' | 'chargesEnabled' | 'detailsSubmitted' | 'payoutsEnabled' | 'stripeId' | 'setupIncomplete' | 'status'>
  )> }
);

export type InitiateStripeAccountOnboardingMutationVariables = Exact<{
  url?: Maybe<Scalars['String']>;
}>;


export type InitiateStripeAccountOnboardingMutation = (
  { __typename?: 'Mutation' }
  & { initiateStripeAccountOnboarding?: Maybe<(
    { __typename?: 'StripeAccountInitiateOnboardingPayload' }
    & Pick<StripeAccountInitiateOnboardingPayload, 'clientMutationId'>
    & { stripeAccount?: Maybe<(
      { __typename?: 'StripeAccount' }
      & Pick<StripeAccount, 'id'>
    )> }
  )> }
);

export type ResyncStripeAccountMutationVariables = Exact<{
  input: StripeAccountResyncInput;
}>;


export type ResyncStripeAccountMutation = (
  { __typename?: 'Mutation' }
  & { resyncStripeAccount?: Maybe<(
    { __typename?: 'StripeAccountResyncPayload' }
    & Pick<StripeAccountResyncPayload, 'clientMutationId'>
    & { stripeAccount?: Maybe<(
      { __typename?: 'StripeAccount' }
      & Pick<StripeAccount, 'id'>
    )> }
  )> }
);

export type StripeAccountSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type StripeAccountSubscription = (
  { __typename?: 'Subscription' }
  & { stripeAccount: (
    { __typename?: 'StripeAccountPayload' }
    & { stripeAccount?: Maybe<(
      { __typename?: 'StripeAccount' }
      & Pick<StripeAccount, 'id' | 'detailsSubmitted' | 'chargesEnabled' | 'payoutsEnabled'>
    )> }
  ) }
);

export type StripeAccountOnboardingUrlSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type StripeAccountOnboardingUrlSubscription = (
  { __typename?: 'Subscription' }
  & { stripeAccountOnboardingUrl: (
    { __typename?: 'StripeAccountOnboardingUrlPayload' }
    & Pick<StripeAccountOnboardingUrlPayload, 'onboardingUrl'>
  ) }
);

export type StripeAccountDashboardLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeAccountDashboardLinkQuery = (
  { __typename?: 'Query' }
  & { stripeAccountDashboardLink: (
    { __typename?: 'DashboardLink' }
    & Pick<DashboardLink, 'url'>
  ) }
);

export type SubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { subscription?: Maybe<(
        { __typename?: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'isAnnual' | 'isActive' | 'cancelDate' | 'updatedAt' | 'stripeId' | 'upcomingInvoiceDate' | 'upcomingInvoiceAmount' | 'previousInvoiceDate' | 'previousInvoiceAmount' | 'hasFailedPayment' | 'prepaidSeatCount' | 'prepaidSeatsFilled' | 'planName' | 'billingEmail'>
      )> }
    )> }
  )> }
);

export type TasksQueryVariables = Exact<{
  sortBy?: Maybe<ProfileTasksSortInput>;
  filters?: Maybe<TaskFiltersInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TasksQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { tasks: (
      { __typename?: 'TaskConnection' }
      & Pick<TaskConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Task' }
        & ListedTaskFragment
      )>>> }
    ) }
  )> }
);

export type AssigneeProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'name' | 'id' | 'initials' | 'slug' | 'image'>
);

export type ListedTaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'completedAt' | 'description' | 'dueDate' | 'name' | 'id' | 'status'>
  & { phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name' | 'abbreviation'>
  )>, milestone?: Maybe<(
    { __typename?: 'InvoiceBuilderMilestone' }
    & Pick<InvoiceBuilderMilestone, 'id' | 'name'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'color' | 'name' | 'number' | 'slug'>
  )>, assignees?: Maybe<Array<(
    { __typename?: 'Profile' }
    & AssigneeProfileFragment
  )>> }
);

export type TasksFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksFiltersQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & TaskFilterProjectFragment
  )>>, phaseTemplates: Array<(
    { __typename?: 'PhaseTemplate' }
    & Pick<PhaseTemplate, 'id' | 'name'>
  )>, currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'canViewAllProjects'>
      ) }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & { profiles: Array<(
        { __typename?: 'Profile' }
        & AssigneeProfileFragment
      )> }
    )> }
    & AssigneeProfileFragment
  )> }
);

export type TaskFilterProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'color' | 'id' | 'name' | 'number' | 'status'>
);

export type TaskMarkCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskMarkCompleteMutation = (
  { __typename?: 'Mutation' }
  & { markTaskComplete?: Maybe<(
    { __typename?: 'TaskMarkCompletePayload' }
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & ListedTaskFragment
    )> }
  )> }
);

export type TaskToggleCompleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskToggleCompleteMutation = (
  { __typename?: 'Mutation' }
  & { toggleTaskComplete?: Maybe<(
    { __typename?: 'TaskToggleCompletePayload' }
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & ListedTaskFragment
    )> }
  )> }
);

export type DraftInvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'number' | 'slug'>
);

export type TaxRatesQueryVariables = Exact<{
  qboInstanceId?: Maybe<Scalars['ID']>;
}>;


export type TaxRatesQuery = (
  { __typename?: 'Query' }
  & { taxRates?: Maybe<Array<(
    { __typename?: 'TaxRate' }
    & Pick<TaxRate, 'id' | 'name' | 'percent'>
    & { draftInvoices?: Maybe<Array<(
      { __typename?: 'Invoice' }
      & DraftInvoiceFragment
    )>> }
  )>> }
);

export type TimeOffRequestsQueryVariables = Exact<{
  sortBy?: Maybe<TimeOffRequestsSortInput>;
  filters: TimeOffRequestsFiltersInput;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type TimeOffRequestsQuery = (
  { __typename?: 'Query' }
  & { timeOffRequests: (
    { __typename?: 'TimeOffRequestConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    )>>> }
  ) }
);

export type StandardHolidaysQueryVariables = Exact<{ [key: string]: never; }>;


export type StandardHolidaysQuery = (
  { __typename?: 'Query' }
  & { standardHolidays: Array<(
    { __typename?: 'StandardHoliday' }
    & Pick<StandardHoliday, 'id' | 'displayName' | 'lookupName'>
  )> }
);

export type TimeOffPolicyQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type TimeOffPolicyQuery = (
  { __typename?: 'Query' }
  & { timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & TimeOffPolicyResultFragment
  ) }
);

export type TimeOffHolidayPolicyQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type TimeOffHolidayPolicyQuery = (
  { __typename?: 'Query' }
  & { timeOffHolidayPolicy: (
    { __typename?: 'TimeOffHolidayPolicy' }
    & TimeOffHolidayPolicyResultFragment
  ) }
);

export type TimeOffHolidayDeletionInfoQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type TimeOffHolidayDeletionInfoQuery = (
  { __typename?: 'Query' }
  & { timeOffHoliday: (
    { __typename?: 'TimeOffHoliday' }
    & Pick<TimeOffHoliday, 'id' | 'canBeDeleted' | 'appliesToNextPayday'>
  ) }
);

export type TimeOffRequestDeletionInfoQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type TimeOffRequestDeletionInfoQuery = (
  { __typename?: 'Query' }
  & { timeOffRequest: (
    { __typename?: 'TimeOffRequest' }
    & Pick<TimeOffRequest, 'id' | 'needsDeletionWarning'>
  ) }
);

export type TimeOffPolicyProfileGroupsQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
}>;


export type TimeOffPolicyProfileGroupsQuery = (
  { __typename?: 'Query' }
  & { timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id'>
    & { profileOptions: Array<(
      { __typename?: 'ProfileGroup' }
      & SelectableProfileGroupFragment
    )> }
  ) }
);

export type ProfilePayrollTypeQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ProfilePayrollTypeQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'payrollType'>
  ) }
);

export type TimeOffPolicyAssignmentTotalsQueryVariables = Exact<{
  profileSlug: Scalars['String'];
  timeOffPolicySlug: Scalars['String'];
}>;


export type TimeOffPolicyAssignmentTotalsQuery = (
  { __typename?: 'Query' }
  & { timeOffPolicyAssignment: (
    { __typename?: 'TimeOffPolicyAssignment' }
    & Pick<TimeOffPolicyAssignment, 'id'>
    & { timeOffPolicy: (
      { __typename?: 'TimeOffPolicy' }
      & TimeOffPolicyResultFragment
    ), totals: (
      { __typename?: 'TimeOffPolicyAssignmentTotals' }
      & Pick<TimeOffPolicyAssignmentTotals, 'hoursAccruedYtd' | 'hoursTaken' | 'hoursPlanned' | 'hoursAvailable'>
    ) }
  ) }
);

export type TimeOffPolicyAssignmentEventsQueryVariables = Exact<{
  profileSlug: Scalars['String'];
  timeOffPolicySlug: Scalars['String'];
  sortDirection: SortDirectionInput;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type TimeOffPolicyAssignmentEventsQuery = (
  { __typename?: 'Query' }
  & { timeOffPolicyAssignment: (
    { __typename?: 'TimeOffPolicyAssignment' }
    & { events?: Maybe<(
      { __typename?: 'TimeOffPolicyAssignmentEventConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'TimeOffPolicyAssignmentEvent' }
        & Pick<TimeOffPolicyAssignmentEvent, 'id' | 'activity' | 'displayHours' | 'balance' | 'beginning' | 'ending' | 'note' | 'createdAt'>
      )>>> }
    )> }
  ) }
);

export type TimeOffPolicyAssignmentsQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type TimeOffPolicyAssignmentsQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { timeOffPolicyAssignments: Array<(
      { __typename?: 'TimeOffPolicyAssignment' }
      & TimeOffPolicyAssignmentFragment
    )> }
  ) }
);

export type TimeOffHolidayPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffHolidayPoliciesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { timeOffHolidayPolicies: Array<(
        { __typename?: 'TimeOffHolidayPolicy' }
        & TimeOffHolidayPolicyResultFragment
      )> }
    )> }
  )> }
);

export type TimeOffPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffPoliciesQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { timeOffPolicies: Array<(
        { __typename?: 'TimeOffPolicy' }
        & TimeOffPolicyResultFragment
      )> }
    )> }
  )> }
);

export type TimeOffRequestsFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffRequestsFiltersQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { timeOffPolicies: Array<(
        { __typename?: 'TimeOffPolicy' }
        & Pick<TimeOffPolicy, 'id' | 'name'>
      )>, profiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName'>
      )> }
    )> }
  )> }
);

export type UpdateTimeOffPolicyMutationVariables = Exact<{
  input: UpdateTimeOffPolicyInput;
}>;


export type UpdateTimeOffPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeOffPolicy?: Maybe<(
    { __typename?: 'UpdateTimeOffPolicyPayload' }
    & { timeOffPolicy?: Maybe<(
      { __typename?: 'TimeOffPolicy' }
      & Pick<TimeOffPolicy, 'id' | 'name' | 'description' | 'slug' | 'autoApprove'>
      & { reviewRecipients: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'fullName'>
      )> }
    )> }
  )> }
);

export type CreateAdminTimeOffAccrualMutationVariables = Exact<{
  input: CreateAdminTimeOffAccrualInput;
}>;


export type CreateAdminTimeOffAccrualMutation = (
  { __typename?: 'Mutation' }
  & { createAdminTimeOffAccrual?: Maybe<(
    { __typename?: 'CreateAdminTimeOffAccrualPayload' }
    & { timeOffPolicyAssignment?: Maybe<(
      { __typename?: 'TimeOffPolicyAssignment' }
      & TimeOffPolicyAssignmentFragment
    )> }
  )> }
);

export type TimeOffHolidayResultFragment = (
  { __typename?: 'TimeOffHoliday' }
  & Pick<TimeOffHoliday, 'id' | 'name' | 'date' | 'hours'>
  & { standardHoliday?: Maybe<(
    { __typename?: 'StandardHoliday' }
    & Pick<StandardHoliday, 'id' | 'displayName'>
  )> }
);

export type TimeOffAssignableProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, 'id' | 'fullName' | 'image' | 'slug' | 'initials'>
);

export type TimeOffHolidayPolicyResultFragment = (
  { __typename?: 'TimeOffHolidayPolicy' }
  & Pick<TimeOffHolidayPolicy, 'id' | 'name' | 'description' | 'slug' | 'accrualMethod' | 'annualRolloverLimit' | 'hoursPerYear' | 'earnedRate' | 'maxBalance' | 'timeOffType' | 'deletedAt'>
  & { overhead?: Maybe<(
    { __typename?: 'Overhead' }
    & Pick<Overhead, 'id' | 'name'>
  )>, timeOffPolicyAssignments: Array<(
    { __typename?: 'TimeOffPolicyAssignment' }
    & TimeOffPolicyAssignmentFragment
  )>, eligibleProfiles: Array<(
    { __typename?: 'Profile' }
    & TimeOffAssignableProfileFragment
  )>, ineligibleProfiles: Array<(
    { __typename?: 'Profile' }
    & TimeOffAssignableProfileFragment
  )>, timeOffHolidaysByYear?: Maybe<Array<(
    { __typename?: 'TimeOffHolidaysByYear' }
    & Pick<TimeOffHolidaysByYear, 'year'>
    & { holidays: Array<(
      { __typename?: 'TimeOffHoliday' }
      & TimeOffHolidayResultFragment
    )> }
  )>> }
);

export type TimeOffPolicyResultFragment = (
  { __typename?: 'TimeOffPolicy' }
  & Pick<TimeOffPolicy, 'id' | 'name' | 'description' | 'slug' | 'accrualMethod' | 'annualRolloverLimit' | 'hoursPerYear' | 'earnedRate' | 'maxBalance' | 'autoApprove' | 'timeOffType' | 'deletedAt'>
  & { reviewRecipients: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )>, overhead?: Maybe<(
    { __typename?: 'Overhead' }
    & Pick<Overhead, 'id' | 'name'>
  )>, timeOffPolicyAssignments: Array<(
    { __typename?: 'TimeOffPolicyAssignment' }
    & TimeOffPolicyAssignmentFragment
  )>, eligibleProfiles: Array<(
    { __typename?: 'Profile' }
    & TimeOffAssignableProfileFragment
  )>, ineligibleProfiles: Array<(
    { __typename?: 'Profile' }
    & TimeOffAssignableProfileFragment
  )> }
);

export type TimeOffPolicyAssignmentFragment = (
  { __typename?: 'TimeOffPolicyAssignment' }
  & Pick<TimeOffPolicyAssignment, 'id' | 'balance' | 'totalHoursAccrued'>
  & { profile: (
    { __typename?: 'Profile' }
    & TimeOffAssignableProfileFragment
  ), timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name' | 'timeOffType' | 'slug' | 'deletedAt' | 'maxBalance'>
  ), timeOffRequests: Array<(
    { __typename?: 'TimeOffRequest' }
    & TimeOffRequestFragment
  )>, timeOffAccruals: Array<(
    { __typename?: 'TimeOffAccrual' }
    & TimeOffAccrualFragment
  )> }
);

export type TimeOffAccrualFragment = (
  { __typename?: 'TimeOffAccrual' }
  & Pick<TimeOffAccrual, 'id' | 'hours' | 'note' | 'accrualType'>
  & { adminProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )> }
);

export type TimeOffRequestFragment = (
  { __typename?: 'TimeOffRequest' }
  & Pick<TimeOffRequest, 'id' | 'openedAt' | 'deniedAt' | 'approvedAt' | 'cancelledAt' | 'requestsBeginning' | 'requestsEnd' | 'hours' | 'note' | 'status'>
  & { timeOffPolicyAssignment: (
    { __typename?: 'TimeOffPolicyAssignment' }
    & Pick<TimeOffPolicyAssignment, 'id' | 'totalHoursAccrued' | 'balance'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<Profile, 'id' | 'fullName' | 'slug'>
    ), timeOffPolicy: (
      { __typename?: 'TimeOffPolicy' }
      & Pick<TimeOffPolicy, 'id' | 'name' | 'slug' | 'timeOffType'>
    ) }
  ), timeOffPolicy: (
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'timeOffType' | 'slug' | 'name'>
  ), timeOffRequestDays: Array<(
    { __typename?: 'TimeOffRequestDay' }
    & TimeOffRequestDayFragment
  )>, adminProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'fullName'>
  )> }
);

export type TimeOffRequestDayFragment = (
  { __typename?: 'TimeOffRequestDay' }
  & Pick<TimeOffRequestDay, 'id' | 'date' | 'hours'>
);

export type TimeOffEnumsQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffEnumsQuery = (
  { __typename?: 'Query' }
  & { timeOffTypes?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )>, timeOffAccrualMethodTypes?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )>, timeOffEarnedRateTypes?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )>, payFrequencyTypes?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )>, timeOffRequestStatusTypes?: Maybe<(
    { __typename?: '__Type' }
    & { enumValues?: Maybe<Array<(
      { __typename?: '__EnumValue' }
      & Pick<__EnumValue, 'name' | 'description'>
    )>> }
  )> }
);

export type TimeOffOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffOrganizationQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { paySchedule?: Maybe<(
        { __typename?: 'PaySchedule' }
        & Pick<PaySchedule, 'id'>
      )>, overheads: Array<(
        { __typename?: 'Overhead' }
        & Pick<Overhead, 'id' | 'name' | 'entryCount'>
      )> }
    )> }
  )> }
);

export type DeleteTimeOffPolicyAssignmentMutationVariables = Exact<{
  id: Scalars['ID'];
  profileId: Scalars['ID'];
}>;


export type DeleteTimeOffPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeOffPolicyAssignment?: Maybe<(
    { __typename?: 'DeleteTimeOffPolicyAssignmentPayload' }
    & { timeOffPolicy?: Maybe<(
      { __typename?: 'TimeOffPolicy' }
      & Pick<TimeOffPolicy, 'id'>
      & { timeOffPolicyAssignments: Array<(
        { __typename?: 'TimeOffPolicyAssignment' }
        & Pick<TimeOffPolicyAssignment, 'id'>
      )>, eligibleProfiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )>, ineligibleProfiles: Array<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id'>
      )> }
    )> }
  )> }
);

export type CreateTimeOffPolicyMutationVariables = Exact<{
  input: CreateTimeOffPolicyInput;
}>;


export type CreateTimeOffPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createTimeOffPolicy?: Maybe<(
    { __typename?: 'CreateTimeOffPolicyPayload' }
    & { timeOffPolicy?: Maybe<(
      { __typename?: 'TimeOffPolicy' }
      & TimeOffPolicyResultFragment
    )> }
  )> }
);

export type DeactivateTimeOffPolicyMutationVariables = Exact<{
  input: DeactivateTimeOffPolicyInput;
}>;


export type DeactivateTimeOffPolicyMutation = (
  { __typename?: 'Mutation' }
  & { deactivateTimeOffPolicy?: Maybe<(
    { __typename?: 'DeactivateTimeOffPolicyPayload' }
    & { timeOffPolicy?: Maybe<(
      { __typename?: 'TimeOffPolicy' }
      & Pick<TimeOffPolicy, 'id' | 'deletedAt'>
    )> }
  )> }
);

export type CreatePayScheduleMutationVariables = Exact<{
  input: CreatePayScheduleInput;
}>;


export type CreatePayScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createPaySchedule?: Maybe<(
    { __typename?: 'CreatePaySchedulePayload' }
    & { paySchedule: (
      { __typename?: 'PaySchedule' }
      & PayrollPayScheduleFragment
    ) }
  )> }
);

export type CancelTimeOffRequestMutationVariables = Exact<{
  input: CancelTimeOffRequestInput;
}>;


export type CancelTimeOffRequestMutation = (
  { __typename?: 'Mutation' }
  & { cancelTimeOffRequest?: Maybe<(
    { __typename?: 'CancelTimeOffRequestPayload' }
    & { timeOffRequest?: Maybe<(
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    )> }
  )> }
);

export type CreateTimeOffRequestMutationVariables = Exact<{
  input: CreateTimeOffRequestInput;
}>;


export type CreateTimeOffRequestMutation = (
  { __typename?: 'Mutation' }
  & { createTimeOffRequest?: Maybe<(
    { __typename?: 'CreateTimeOffRequestPayload' }
    & { timeOffRequest?: Maybe<(
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    )> }
  )> }
);

export type DeleteTimeOffRequestMutationVariables = Exact<{
  input: DeleteTimeOffRequestInput;
}>;


export type DeleteTimeOffRequestMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeOffRequest?: Maybe<(
    { __typename?: 'DeleteTimeOffRequestPayload' }
    & { timeOffRequest: (
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    ) }
  )> }
);

export type UpdateTimeOffRequestMutationVariables = Exact<{
  input: UpdateTimeOffRequestInput;
}>;


export type UpdateTimeOffRequestMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeOffRequest?: Maybe<(
    { __typename?: 'UpdateTimeOffRequestPayload' }
    & { timeOffRequest?: Maybe<(
      { __typename?: 'TimeOffRequest' }
      & TimeOffRequestFragment
    )> }
  )> }
);

export type BulkCreateTimeOffPolicyAssignmentsMutationVariables = Exact<{
  input: BulkCreateTimeOffPolicyAssignmentsInput;
}>;


export type BulkCreateTimeOffPolicyAssignmentsMutation = (
  { __typename?: 'Mutation' }
  & { bulkCreateTimeOffPolicyAssignments?: Maybe<(
    { __typename?: 'BulkCreateTimeOffPolicyAssignmentsPayload' }
    & { timeOffPolicy: (
      { __typename?: 'TimeOffPolicy' }
      & TimeOffPolicyResultFragment
    ) }
  )> }
);

export type CreateTimeOffHolidayPolicyMutationVariables = Exact<{
  input: CreateTimeOffHolidayPolicyInput;
}>;


export type CreateTimeOffHolidayPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createTimeOffHolidayPolicy?: Maybe<(
    { __typename?: 'CreateTimeOffHolidayPolicyPayload' }
    & { timeOffHolidayPolicy?: Maybe<(
      { __typename?: 'TimeOffHolidayPolicy' }
      & TimeOffHolidayPolicyResultFragment
    )> }
  )> }
);

export type DeleteTimeOffHolidayMutationVariables = Exact<{
  input: DeleteTimeOffHolidayInput;
}>;


export type DeleteTimeOffHolidayMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeOffHoliday?: Maybe<(
    { __typename?: 'DeleteTimeOffHolidayPayload' }
    & { timeOffHolidayPolicy?: Maybe<(
      { __typename?: 'TimeOffHolidayPolicy' }
      & Pick<TimeOffHolidayPolicy, 'id'>
      & { timeOffHolidaysByYear?: Maybe<Array<(
        { __typename?: 'TimeOffHolidaysByYear' }
        & Pick<TimeOffHolidaysByYear, 'year'>
        & { holidays: Array<(
          { __typename?: 'TimeOffHoliday' }
          & TimeOffHolidayResultFragment
        )> }
      )>> }
    )> }
  )> }
);

export type UpdateTimeOffHolidayMutationVariables = Exact<{
  input: UpdateTimeOffHolidayInput;
}>;


export type UpdateTimeOffHolidayMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeOffHoliday?: Maybe<(
    { __typename?: 'UpdateTimeOffHolidayPayload' }
    & { timeOffHolidayPolicy: (
      { __typename?: 'TimeOffHolidayPolicy' }
      & Pick<TimeOffHolidayPolicy, 'id'>
      & { timeOffHolidaysByYear?: Maybe<Array<(
        { __typename?: 'TimeOffHolidaysByYear' }
        & Pick<TimeOffHolidaysByYear, 'year'>
        & { holidays: Array<(
          { __typename?: 'TimeOffHoliday' }
          & TimeOffHolidayResultFragment
        )> }
      )>> }
    ) }
  )> }
);

export type CreateTimeOffHolidayMutationVariables = Exact<{
  input: CreateTimeOffHolidayInput;
}>;


export type CreateTimeOffHolidayMutation = (
  { __typename?: 'Mutation' }
  & { createTimeOffHoliday?: Maybe<(
    { __typename?: 'CreateTimeOffHolidayPayload' }
    & { timeOffHolidayPolicy: (
      { __typename?: 'TimeOffHolidayPolicy' }
      & Pick<TimeOffHolidayPolicy, 'id'>
      & { timeOffHolidaysByYear?: Maybe<Array<(
        { __typename?: 'TimeOffHolidaysByYear' }
        & Pick<TimeOffHolidaysByYear, 'year'>
        & { holidays: Array<(
          { __typename?: 'TimeOffHoliday' }
          & TimeOffHolidayResultFragment
        )> }
      )>> }
    ) }
  )> }
);

export type CopyTimeOffHolidaysYearMutationVariables = Exact<{
  input: CopyTimeOffHolidaysYearInput;
}>;


export type CopyTimeOffHolidaysYearMutation = (
  { __typename?: 'Mutation' }
  & { copyTimeOffHolidaysYear?: Maybe<(
    { __typename?: 'CopyTimeOffHolidaysYearPayload' }
    & { timeOffHolidayPolicy?: Maybe<(
      { __typename?: 'TimeOffHolidayPolicy' }
      & TimeOffHolidayPolicyResultFragment
    )> }
  )> }
);

export type TimeOffOverheadItemFragment = (
  { __typename?: 'Overhead' }
  & Pick<Overhead, 'id' | 'name' | 'availableHours'>
  & { timeOffPolicies?: Maybe<Array<(
    { __typename?: 'TimeOffPolicy' }
    & Pick<TimeOffPolicy, 'id' | 'name' | 'slug'>
  )>> }
);

export type TimeOffCreateOverheadItemMutationVariables = Exact<{
  name: Scalars['String'];
  availableHours: Scalars['Boolean'];
}>;


export type TimeOffCreateOverheadItemMutation = (
  { __typename?: 'Mutation' }
  & { createOverhead?: Maybe<(
    { __typename?: 'CreateOverheadPayload' }
    & { overhead?: Maybe<(
      { __typename?: 'Overhead' }
      & TimeOffOverheadItemFragment
    )> }
  )> }
);

export type TimerEntryFragment = (
  { __typename?: 'Entry' }
  & Pick<Entry, 'id' | 'notes' | 'hours' | 'date'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>, phase?: Maybe<(
    { __typename?: 'Phase' }
    & Pick<Phase, 'id' | 'name'>
  )>, role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>, activity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )> }
);

export type CreateTimerEntryMutationVariables = Exact<{
  input: CreateEntryInput;
}>;


export type CreateTimerEntryMutation = (
  { __typename?: 'Mutation' }
  & { createEntry?: Maybe<(
    { __typename?: 'CreateEntryPayload' }
    & { entry?: Maybe<(
      { __typename?: 'Entry' }
      & TimerEntryFragment
    )> }
  )> }
);

export type UpdateTimerEntryMutationVariables = Exact<{
  input: UpdateEntryInput;
}>;


export type UpdateTimerEntryMutation = (
  { __typename?: 'Mutation' }
  & { updateEntry?: Maybe<(
    { __typename?: 'UpdateEntryPayload' }
    & { entry?: Maybe<(
      { __typename?: 'Entry' }
      & TimerEntryFragment
    )> }
  )> }
);

export type TimerEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type TimerEntriesQuery = (
  { __typename?: 'Query' }
  & { timer: (
    { __typename?: 'Timer' }
    & { entries?: Maybe<Array<(
      { __typename?: 'Entry' }
      & TimerEntryFragment
    )>> }
  ) }
);

export type TimerOrgDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TimerOrgDataQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'name'>
  )>>, timer: (
    { __typename?: 'Timer' }
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  ) }
);

export type TimerProjectDataQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type TimerProjectDataQuery = (
  { __typename?: 'Query' }
  & { timer: (
    { __typename?: 'Timer' }
    & { projectPhases: Array<(
      { __typename?: 'Phase' }
      & Pick<Phase, 'id' | 'name'>
    )> }
  ) }
);

export type TimerPhaseDataQueryVariables = Exact<{
  phaseId: Scalars['ID'];
}>;


export type TimerPhaseDataQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )> }
  )> }
);

export type ProjectsUnbilledQueryVariables = Exact<{
  filters: ProjectsUnbilledFiltersInput;
  sortBy?: Maybe<ProjectsUnbilledSortInput>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ProjectsUnbilledQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'Reports' }
    & { projectsUnbilled: (
      { __typename?: 'ProjectsUnbilledConnection' }
      & Pick<ProjectsUnbilledConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'ProjectsUnbilled' }
        & Pick<ProjectsUnbilled, 'projectColor' | 'projectId' | 'projectName' | 'projectNumber' | 'clientName' | 'totalInvoiced' | 'lastInvoiceTotal' | 'totalPlannedServices' | 'percentComplete' | 'currentUnbilled' | 'totalUnbilled' | 'projectLink' | 'lastInvoiceEndDate' | 'firstPhaseBeginDate' | 'minUnbilledEntryDate' | 'firstTimelogEntryDate'>
      )>>>, totals: (
        { __typename?: 'ProjectUnbilledTotals' }
        & Pick<ProjectUnbilledTotals, 'currentUnbilled' | 'invoicedServices' | 'plannedServices' | 'totalUnbilled'>
      ) }
    ) }
  ) }
);

export type ProjectsUnbilledFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsUnbilledFiltersQuery = (
  { __typename?: 'Query' }
  & { projectsOptions?: Maybe<Array<(
    { __typename?: 'ProjectOption' }
    & Pick<ProjectOption, 'id' | 'name'>
  )>>, allClients?: Maybe<Array<(
    { __typename?: 'ClientList' }
    & Pick<ClientList, 'displayName' | 'id'>
  )>>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>> }
);

export type ExportUnbilledReportMutationVariables = Exact<{
  input: ExportUnbilledProjectsReportInput;
}>;


export type ExportUnbilledReportMutation = (
  { __typename?: 'Mutation' }
  & { exportUnbilledProjectsReport?: Maybe<(
    { __typename?: 'ExportUnbilledProjectsReportPayload' }
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    ) }
  )> }
);

export type CurrentAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentAccountQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & CurrentUserOrganizationsFragment
    )> }
    & CurrentUserFragment
  )> }
);

export type CurrentLocaleQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentLocaleQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & { currency: (
        { __typename?: 'Currency' }
        & CurrentOrganizationCurrencyFragment
      ), locale: (
        { __typename?: 'Locale' }
        & CurrentOrganizationLocaleFragment
      ) }
    )> }
  )> }
);

export type CurrentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentProfileQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & CurrentProfileFragment
  )> }
);

export type CurrentProfilePolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentProfilePolicyQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { policy?: Maybe<(
      { __typename?: 'Policy' }
      & CurrentProfilePolicyFragment
    )> }
  )> }
);

export type TimeOffCurrentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffCurrentProfileQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id'>
    & { timeOffPolicyAssignments: Array<(
      { __typename?: 'TimeOffPolicyAssignment' }
      & Pick<TimeOffPolicyAssignment, 'id'>
      & { timeOffPolicy: (
        { __typename?: 'TimeOffPolicy' }
        & Pick<TimeOffPolicy, 'id' | 'deletedAt'>
      ) }
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { settings: (
        { __typename?: 'OrganizationSetting' }
        & Pick<OrganizationSetting, 'id' | 'ptoEnabled'>
      ) }
    )> }
  )> }
);

export const VendorResultFragmentDoc = gql`
    fragment VendorResult on QuickbooksVendor {
  displayName
  fname
  lname
  email
  phone
  website
  address {
    city
  }
  quickbooksVendorId
  quickbooksVendorSyncToken
}
    `;
export const AssignedWorkPhaseFragmentDoc = gql`
    fragment AssignedWorkPhase on Phase {
  id
  name
}
    `;
export const AssignedWorkProjectFragmentDoc = gql`
    fragment AssignedWorkProject on Project {
  id
  name
  number
  color
  slug
}
    `;
export const AssignedWorkOverheadFragmentDoc = gql`
    fragment AssignedWorkOverhead on Overhead {
  id
  name
}
    `;
export const AssignedWorkEntryGroupFragmentDoc = gql`
    fragment AssignedWorkEntryGroup on EntryGroup {
  allocation
  phase {
    ...AssignedWorkPhase
  }
  project {
    ...AssignedWorkProject
  }
  overhead {
    ...AssignedWorkOverhead
  }
}
    ${AssignedWorkPhaseFragmentDoc}
${AssignedWorkProjectFragmentDoc}
${AssignedWorkOverheadFragmentDoc}`;
export const OrganizationBusinessPerformanceMetricFragmentDoc = gql`
    fragment OrganizationBusinessPerformanceMetric on BusinessPerformanceMetric {
  aggregate
  items {
    beginDate
    value
  }
}
    `;
export const RecentProjectFragmentDoc = gql`
    fragment RecentProject on Project {
  id
  name
  budget
  budgetConsumed
  hours
  hoursLogged
  color
  number
  slug
}
    `;
export const TimesheetTotalsFragmentDoc = gql`
    fragment TimesheetTotals on Timesheet {
  id
  beginDate
  totalHours
  hoursAllotted
}
    `;
export const FilteredTotalsFragmentDoc = gql`
    fragment FilteredTotals on FilteredTotals {
  invoiced {
    count
    amount
    otherAmount
    firmServicesAmount
    consultantServicesAmount
    expensesAmount
  }
  paid {
    count
    amount
    otherAmount
    firmServicesAmount
    consultantServicesAmount
    expensesAmount
  }
  open {
    count
    amount
    otherAmount
    firmServicesAmount
    consultantServicesAmount
    expensesAmount
  }
  pastDue {
    count
    amount
    otherAmount
    firmServicesAmount
    consultantServicesAmount
    expensesAmount
  }
}
    `;
export const YearlyTotalsFragmentDoc = gql`
    fragment YearlyTotals on YearlyTotals {
  averageDaysToPayment
  invoiced
  paymentsReceived
  totalCountYtdInvoices
}
    `;
export const InvoiceTotalsChartFragmentDoc = gql`
    fragment InvoiceTotalsChart on InvoicesTotalsChartEntry {
  xAxis
  total
  data {
    status
    amount
  }
}
    `;
export const InvoiceTotalsFragmentDoc = gql`
    fragment InvoiceTotals on Reports {
  invoiceTotals(filters: $filters) {
    filteredTotals {
      ...FilteredTotals
    }
    yearlyTotals {
      ...YearlyTotals
    }
    dateIncrement
    chart {
      ...InvoiceTotalsChart
    }
  }
}
    ${FilteredTotalsFragmentDoc}
${YearlyTotalsFragmentDoc}
${InvoiceTotalsChartFragmentDoc}`;
export const CheckoutPublicInvoiceFragmentDoc = gql`
    fragment CheckoutPublicInvoice on Invoice {
  id
  number
  status
  paymentIntentStatus
  allowBankTransfers
  allowCardPayments
  organization {
    name
    logo
  }
}
    `;
export const CheckoutPaymentIntentFragmentDoc = gql`
    fragment CheckoutPaymentIntent on StripePaymentIntent {
  id
  clientSecret
  amount
  currency
  hasCustomer
  setupFutureUsage
}
    `;
export const CheckoutPaymentMethodTypeBankAccountFragmentDoc = gql`
    fragment CheckoutPaymentMethodTypeBankAccount on BankAccount {
  id
  type
  bankName
  last4
  createdAt
}
    `;
export const CheckoutPaymentMethodTypeCardFragmentDoc = gql`
    fragment CheckoutPaymentMethodTypeCard on Card {
  id
  type
  brand
  expMonth
  expYear
  last4
  createdAt
}
    `;
export const CheckoutPaymentMethodFragmentDoc = gql`
    fragment CheckoutPaymentMethod on PaymentMethod {
  ... on BankAccount {
    ...CheckoutPaymentMethodTypeBankAccount
  }
  ... on Card {
    ...CheckoutPaymentMethodTypeCard
  }
}
    ${CheckoutPaymentMethodTypeBankAccountFragmentDoc}
${CheckoutPaymentMethodTypeCardFragmentDoc}`;
export const CheckoutSsrInvoiceFragmentDoc = gql`
    fragment CheckoutSsrInvoice on Invoice {
  status
  paymentIntentStatus
  allowBankTransfers
  allowCardPayments
}
    `;
export const ArchivedProjectDataFragmentDoc = gql`
    fragment ArchivedProjectData on Project {
  id
  number
  color
  name
  beginDate
  endDate
  status
  totalFee
  link
  client {
    name
  }
  categories {
    name
  }
}
    `;
export const PhaseProgressRolesFragmentDoc = gql`
    fragment PhaseProgressRoles on Role {
  id
  budget
  budgetConsumed
  hours
  hoursLogged
  name
  roleTemplate {
    id
  }
}
    `;
export const PhaseProgressDataFragmentDoc = gql`
    fragment PhaseProgressData on Phase {
  id
  name
  budgetConsumed
  budget
  beginDate
  endDate
  roles {
    ...PhaseProgressRoles
  }
}
    ${PhaseProgressRolesFragmentDoc}`;
export const ProjectProgressDataFragmentDoc = gql`
    fragment ProjectProgressData on Project {
  id
  orderedPhases {
    ...PhaseProgressData
  }
  budget
  budgetConsumed
  beginDate
  endDate
  color
}
    ${PhaseProgressDataFragmentDoc}`;
export const TeamListFragmentDoc = gql`
    fragment TeamList on TeamList {
  rolesSentence
  name
  initials
  image
  slug
  id
}
    `;
export const ProjectTemplateDataFragmentDoc = gql`
    fragment ProjectTemplateData on ProjectTemplate {
  beginDate
  endDate
  budget
  consultantsFee
  id
  name
  notes
  number
  slug
  totalFee
  totalConsultantsMarkup
  teamList {
    ...TeamList
  }
}
    ${TeamListFragmentDoc}`;
export const PlanableAssigneeFragmentDoc = gql`
    fragment PlanableAssignee on Profile {
  id
  image
  initials
  firstNameLastInitial
  fullName
}
    `;
export const UserTaskFragmentDoc = gql`
    fragment UserTask on Task {
  assignees {
    ...PlanableAssignee
  }
  dueDate
  id
  name
}
    ${PlanableAssigneeFragmentDoc}`;
export const ProjectTaskPhasePlanableMilestoneFragmentDoc = gql`
    fragment ProjectTaskPhasePlanableMilestone on Milestone {
  id
  name
}
    `;
export const PlanablePhaseFragmentDoc = gql`
    fragment PlanablePhase on Phase {
  id
  name
  beginDate
  endDate
  abbreviation
  milestones {
    ...ProjectTaskPhasePlanableMilestone
  }
}
    ${ProjectTaskPhasePlanableMilestoneFragmentDoc}`;
export const PlanableMilestoneFragmentDoc = gql`
    fragment PlanableMilestone on Milestone {
  id
  name
  dueDate
  tasksCount
  description
  phase {
    id
    name
  }
}
    `;
export const PlanableTaskFragmentDoc = gql`
    fragment PlanableTask on Task {
  id
  dueDate
  name
  status
  assignees {
    ...PlanableAssignee
  }
  phase {
    id
  }
  regularMilestone {
    ...PlanableMilestone
  }
}
    ${PlanableAssigneeFragmentDoc}
${PlanableMilestoneFragmentDoc}`;
export const TaskShowCommentFragmentDoc = gql`
    fragment TaskShowComment on Comment {
  id
  description
  createdAt
  profile {
    ...PlanableAssignee
  }
}
    ${PlanableAssigneeFragmentDoc}`;
export const TaskShowFragmentDoc = gql`
    fragment TaskShow on Task {
  id
  completedAt
  dueDate
  name
  status
  description
  assignees {
    ...PlanableAssignee
  }
  phase {
    id
    abbreviation
    name
  }
  milestone {
    id
    name
  }
  project {
    id
    color
    profiles {
      id
    }
  }
  comments {
    ...TaskShowComment
  }
  regularMilestone {
    ...PlanableMilestone
  }
}
    ${PlanableAssigneeFragmentDoc}
${TaskShowCommentFragmentDoc}
${PlanableMilestoneFragmentDoc}`;
export const MilestoneShowFragmentDoc = gql`
    fragment MilestoneShow on Milestone {
  id
  name
  description
  dueDate
  tasksCount
  phase {
    id
    name
  }
}
    `;
export const ProjectTimesheetEntryGroupFragmentDoc = gql`
    fragment ProjectTimesheetEntryGroup on EntryGroup {
  id
  project {
    name
    id
  }
  phase {
    id
    name
  }
  role {
    id
    name
  }
  entryRows {
    id
    activity {
      id
      name
    }
    entries {
      date
      hours
      id
    }
  }
}
    `;
export const ProjectTimesheetResultFragmentDoc = gql`
    fragment ProjectTimesheetResult on Timesheet {
  id
  profile {
    name
    id
    slug
  }
  entryGroups {
    ...ProjectTimesheetEntryGroup
  }
}
    ${ProjectTimesheetEntryGroupFragmentDoc}`;
export const TeamForecastProfileFragmentDoc = gql`
    fragment TeamForecastProfile on Profile {
  id
  slug
  firstNameLastInitial
  image
  initials
  utilizationRate(withOrganizationDefault: true)
}
    `;
export const TeamForecastRoleFragmentDoc = gql`
    fragment TeamForecastRole on Role {
  id
  name
  phase {
    id
    name
  }
  project {
    id
    name
    color
  }
}
    `;
export const TeamForecastOverheadFragmentDoc = gql`
    fragment TeamForecastOverhead on Overhead {
  id
  name
  availableHours
}
    `;
export const TeamForecastTimeOffRequestFragmentDoc = gql`
    fragment TeamForecastTimeOffRequest on TimeOffRequest {
  id
  hours
  timeOffPolicy {
    id
    name
  }
}
    `;
export const TeamForecastTimeOffHolidayFragmentDoc = gql`
    fragment TeamForecastTimeOffHoliday on TimeOffHoliday {
  id
  name
}
    `;
export const CostsReviewProjectFragmentDoc = gql`
    fragment CostsReviewProject on Project {
  id
  isArchived
  name
  color
  number
}
    `;
export const CategoriesFilterCategoryFragmentDoc = gql`
    fragment CategoriesFilterCategory on Category {
  id
  name
}
    `;
export const ProfilesFilterProfileFragmentDoc = gql`
    fragment ProfilesFilterProfile on Profile {
  id
  initials
  name
  fullName
  slug
}
    `;
export const ProfilesFilterProjectFragmentDoc = gql`
    fragment ProfilesFilterProject on Project {
  id
  color
  name
  number
  slug
  status
  profiles {
    ...ProfilesFilterProfile
  }
}
    ${ProfilesFilterProfileFragmentDoc}`;
export const ProjectsFilterProjectFragmentDoc = gql`
    fragment ProjectsFilterProject on Project {
  id
  color
  name
  number
  slug
  status
  profiles {
    id
  }
}
    `;
export const RebalanceModalRoleFragmentDoc = gql`
    fragment RebalanceModalRole on Role {
  id
  exclusivelyHasUserAllocations
  staffingEntryGroups {
    id
  }
  phase {
    id
    endDate
    beginDate
  }
  profile {
    id
    name
  }
}
    `;
export const StaffingCurrentWeekTimesheetFragmentDoc = gql`
    fragment StaffingCurrentWeekTimesheet on Timesheet {
  id
  beginDate
  timeOffHolidayHours
  timeOffRequestHours
  hoursAllocated
  hoursAllotted
  overheadHoursAllocated
  profile {
    id
  }
  entryGroups {
    id
    allocatedByUser
    allocation
    overhead {
      id
      name
    }
    role {
      id
    }
    project {
      id
      status
    }
  }
}
    `;
export const StaffingCurrentWeekRoleFragmentDoc = gql`
    fragment StaffingCurrentWeekRole on Role {
  id
  name
  hoursLogged
  hoursAllocated
  hours
  isOverstaffed
  exclusivelyHasUserAllocations
  profile {
    id
    name
  }
  phase {
    id
    name
    abbreviation
    beginDate
    endDate
    budget
    project {
      id
      name
      number
      slug
      color
      categories {
        id
      }
    }
  }
}
    `;
export const StaffingCurrentWeekFullProfileFragmentDoc = gql`
    fragment StaffingCurrentWeekFullProfile on Profile {
  id
  timesheets(beginDate: $beginDate, endDate: $endDate) {
    ...StaffingCurrentWeekTimesheet
  }
  staffingRoles(beginDate: $beginDate, endDate: $endDate) {
    ...StaffingCurrentWeekRole
  }
}
    ${StaffingCurrentWeekTimesheetFragmentDoc}
${StaffingCurrentWeekRoleFragmentDoc}`;
export const StaffingCurrentWeekProfileFragmentDoc = gql`
    fragment StaffingCurrentWeekProfile on Profile {
  id
  initials
  fname
  lname
  name
  image
}
    `;
export const StaffingProjectFragmentDoc = gql`
    fragment StaffingProject on Project {
  id
  name
  color
  number
  status
  slug
  beginDate
  endDate
  overBudgetPhases {
    id
    endDate
    beginDate
  }
  currentPhases {
    id
  }
  categories {
    id
  }
}
    `;
export const StaffingEditPhaseDrawerProjectFragmentDoc = gql`
    fragment StaffingEditPhaseDrawerProject on Project {
  id
  name
  number
  color
}
    `;
export const StaffingEditPhaseDrawerRoleFragmentDoc = gql`
    fragment StaffingEditPhaseDrawerRole on Role {
  id
  name
  hours
  hoursLogged
  budget
  position
  roleTemplate {
    id
  }
  rateTable {
    id
  }
  profile {
    id
    name
    fname
    lname
    status
  }
  phase {
    id
  }
}
    `;
export const StaffingEditPhaseDrawerPhaseFragmentDoc = gql`
    fragment StaffingEditPhaseDrawerPhase on Phase {
  id
  name
  beginDate
  endDate
  feeType
  abbreviation
  budget
  project {
    ...StaffingEditPhaseDrawerProject
  }
  roleCount
  roles {
    ...StaffingEditPhaseDrawerRole
  }
}
    ${StaffingEditPhaseDrawerProjectFragmentDoc}
${StaffingEditPhaseDrawerRoleFragmentDoc}`;
export const ProjectStaffingProjectBudgetFragmentDoc = gql`
    fragment ProjectStaffingProjectBudget on Project {
  id
  budget
  budgetConsumed
  hoursAllocated
  budgetAllocated
}
    `;
export const ProjectStaffingPhaseRoleFragmentDoc = gql`
    fragment ProjectStaffingPhaseRole on Role {
  id
  name
  hours
  profile {
    id
    fname
    lname
    initials
    image
    name
    status
  }
  roleTemplate {
    id
  }
}
    `;
export const ProjectStaffingPhaseRoleRowFragmentDoc = gql`
    fragment ProjectStaffingPhaseRoleRow on Role {
  id
  profile {
    id
    publicCompensations {
      id
      hoursPerWeek
      effectiveOn
      effectiveUntil
    }
    weeklyHoursAllocated {
      timesheetBeginDate
      value
    }
    status
  }
  staffingEntryGroups {
    id
    allocation
    allocatedByUser
    timesheet {
      id
      beginDate
    }
  }
}
    `;
export const ProjectStaffingRoleCardRoleFragmentDoc = gql`
    fragment ProjectStaffingRoleCardRole on Role {
  id
  phase {
    id
    budget
    feeType
  }
}
    `;
export const StaffingProjectPagePhaseFragmentDoc = gql`
    fragment StaffingProjectPagePhase on Phase {
  id
  name
  beginDate
  abbreviation
  isOverstaffed
  status
  endDate
  position
  roleCount
  feeType
  budget
  linkedPhases {
    id
    name
  }
  weeklyBudgetAllocated {
    timesheetBeginDate
    value
  }
  phaseTemplate {
    id
  }
}
    `;
export const DetailsHoverCardRoleFragmentDoc = gql`
    fragment DetailsHoverCardRole on Role {
  id
  hours
  hoursLogged
  hoursAllocated
  budget
  budgetAllocated
  budgetConsumed
}
    `;
export const DetailsHoverCardProjectFragmentDoc = gql`
    fragment DetailsHoverCardProject on Project {
  id
  hours
  hoursLogged
  hoursAllocated
  budget
  budgetAllocated
  budgetConsumed
}
    `;
export const DetailsHoverCardPhaseFragmentDoc = gql`
    fragment DetailsHoverCardPhase on Phase {
  id
  hours
  hoursAllocated
  entryHoursCount
  budget
  budgetAllocated
  budgetConsumed
}
    `;
export const DetailsHovercardRebalanceModalPhaseFragmentDoc = gql`
    fragment DetailsHovercardRebalanceModalPhase on Phase {
  id
  endDate
  beginDate
}
    `;
export const GlobalRebalancePhaseFragmentDoc = gql`
    fragment GlobalRebalancePhase on Phase {
  id
  name
  budget
  budgetAllocated
  budgetConsumed
}
    `;
export const AssignOverheadModalOverheadFragmentDoc = gql`
    fragment AssignOverheadModalOverhead on Overhead {
  id
  name
  timeOffPolicies {
    id
  }
}
    `;
export const AssignPhaseProjectSearchPhaseFragmentDoc = gql`
    fragment AssignPhaseProjectSearchPhase on Phase {
  id
  status
  abbreviation
  name
  beginDate
  endDate
  roles {
    profile {
      id
    }
  }
}
    `;
export const AssignPhaseProjectSearchProjectFragmentDoc = gql`
    fragment AssignPhaseProjectSearchProject on Project {
  id
  name
  number
  color
  phases {
    ...AssignPhaseProjectSearchPhase
  }
}
    ${AssignPhaseProjectSearchPhaseFragmentDoc}`;
export const RoleAndRateFieldsProfileFragmentDoc = gql`
    fragment RoleAndRateFieldsProfile on Profile {
  id
  fname
  defaultRoleTemplate {
    id
  }
  defaultRateTable {
    id
  }
}
    `;
export const MyTimeOffPolicyAssignmentFragmentDoc = gql`
    fragment MyTimeOffPolicyAssignment on TimeOffPolicyAssignment {
  id
  balance
  profile {
    id
  }
  timeOffPolicy {
    id
    name
    slug
    timeOffType
  }
}
    `;
export const UpcomingRequestsTableRequestFragmentDoc = gql`
    fragment UpcomingRequestsTableRequest on TimeOffRequest {
  id
  status
  requestsBeginning
  requestsEnd
  hours
  note
  timeOffRequestDays {
    id
    date
    hours
  }
  adminProfile {
    id
    fullName
  }
  timeOffPolicy {
    id
    name
    timeOffType
  }
  timeOffPolicyAssignment {
    ...MyTimeOffPolicyAssignment
  }
}
    ${MyTimeOffPolicyAssignmentFragmentDoc}`;
export const BulkEditEntryRoleFragmentDoc = gql`
    fragment BulkEditEntryRole on Role {
  id
  name
  profile {
    id
    name
  }
}
    `;
export const BulkEditEntryProjectFragmentDoc = gql`
    fragment BulkEditEntryProject on Project {
  id
  name
  color
  number
}
    `;
export const BulkEditEntryPhaseFragmentDoc = gql`
    fragment BulkEditEntryPhase on Phase {
  id
  abbreviation
  name
}
    `;
export const BulkEditEntryActivityFragmentDoc = gql`
    fragment BulkEditEntryActivity on Activity {
  id
  name
}
    `;
export const BulkEditTimesheetEntryFragmentDoc = gql`
    fragment BulkEditTimesheetEntry on Entry {
  id
  hours
  date
  rate
  role {
    ...BulkEditEntryRole
  }
  project {
    ...BulkEditEntryProject
  }
  phase {
    ...BulkEditEntryPhase
  }
  profile {
    id
    name
  }
  activity {
    ...BulkEditEntryActivity
  }
  profile {
    id
    name
  }
  timesheet {
    id
    isLocked
  }
}
    ${BulkEditEntryRoleFragmentDoc}
${BulkEditEntryProjectFragmentDoc}
${BulkEditEntryPhaseFragmentDoc}
${BulkEditEntryActivityFragmentDoc}`;
export const BulkEditEntryRoleTemplateFragmentDoc = gql`
    fragment BulkEditEntryRoleTemplate on RoleTemplate {
  id
  name
  rateTables {
    id
    name
    currentRate {
      id
      amount
    }
  }
}
    `;
export const ExpensePhaseInfoFragmentDoc = gql`
    fragment ExpensePhaseInfo on Phase {
  name
  id
  abbreviation
}
    `;
export const ExpensesProjectFragmentDoc = gql`
    fragment ExpensesProject on Project {
  id
  name
  color
  number
  phases {
    ...ExpensePhaseInfo
  }
}
    ${ExpensePhaseInfoFragmentDoc}`;
export const OrganizationTimesheetProfileFragmentDoc = gql`
    fragment OrganizationTimesheetProfile on Profile {
  id
  slug
  image
  initials
  fname
  lname
}
    `;
export const OrganizationTimesheetFragmentDoc = gql`
    fragment OrganizationTimesheet on Timesheet {
  id
  updatedAt
  beginDate
  isSubmitted
  totalHours
  isLocked
  isLockable
  profile {
    ...OrganizationTimesheetProfile
  }
}
    ${OrganizationTimesheetProfileFragmentDoc}`;
export const ProfilePastTimesheetFragmentDoc = gql`
    fragment ProfilePastTimesheet on Timesheet {
  id
  updatedAt
  beginDate
  isSubmitted
  totalHours
  isLocked
  isLockable
}
    `;
export const PastTimesheetProfileFragmentDoc = gql`
    fragment PastTimesheetProfile on Profile {
  id
  slug
  image
  fullName
  initials
}
    `;
export const TimesheetsSearchableProjectFragmentDoc = gql`
    fragment TimesheetsSearchableProject on Project {
  id
  name
  number
  status
  color
}
    `;
export const TimesheetsSearchablePhaseFragmentDoc = gql`
    fragment TimesheetsSearchablePhase on Phase {
  id
  name
  status
  abbreviation
  roles {
    id
    name
    phaseRate
    profile {
      id
      slug
    }
  }
}
    `;
export const TimesheetTimeOffHolidayFragmentDoc = gql`
    fragment TimesheetTimeOffHoliday on TimeOffHoliday {
  id
  name
  hours
  date
  timeOffHolidayPolicy {
    id
    name
  }
}
    `;
export const TimesheetExpenseFragmentDoc = gql`
    fragment TimesheetExpense on Expense {
  id
  amount
  attachment
  category {
    id
    name
  }
  date
  isBillable
  isReimbursable
  note
  onOpenInvoice
  project {
    id
  }
}
    `;
export const TimesheetEntryFragmentDoc = gql`
    fragment TimesheetEntry on Entry {
  id
  date
  hours
  notes
  expenses {
    ...TimesheetExpense
  }
}
    ${TimesheetExpenseFragmentDoc}`;
export const TimesheetEntryRowFragmentDoc = gql`
    fragment TimesheetEntryRow on EntryRow {
  id
  activity {
    id
    name
  }
  entries {
    ...TimesheetEntry
  }
}
    ${TimesheetEntryFragmentDoc}`;
export const TimesheetProjectFragmentDoc = gql`
    fragment TimesheetProject on Project {
  id
  status
  color
  isArchived
  number
  name
  slug
}
    `;
export const TimesheetOverheadFragmentDoc = gql`
    fragment TimesheetOverhead on Overhead {
  id
  name
}
    `;
export const TimesheetEntryGroupFragmentDoc = gql`
    fragment TimesheetEntryGroup on EntryGroup {
  id
  allocation
  allocatedByUser
  entryRows {
    ...TimesheetEntryRow
  }
  project {
    ...TimesheetProject
  }
  phase {
    id
    status
    abbreviation
    name
  }
  role {
    id
    name
    phaseRate
  }
  overhead {
    ...TimesheetOverhead
  }
  allocation
}
    ${TimesheetEntryRowFragmentDoc}
${TimesheetProjectFragmentDoc}
${TimesheetOverheadFragmentDoc}`;
export const TimesheetDetailFragmentDoc = gql`
    fragment TimesheetDetail on Timesheet {
  id
  beginDate
  timeOffHolidayDays {
    ...TimesheetTimeOffHoliday
  }
  isLockable
  isLocked
  isUnlocked
  isSubmitted
  entryGroups {
    ...TimesheetEntryGroup
  }
  backendFilteredEntryGroups: entryGroups(visible: true) {
    id
  }
}
    ${TimesheetTimeOffHolidayFragmentDoc}
${TimesheetEntryGroupFragmentDoc}`;
export const TimesheetTimeOffRequestFragmentDoc = gql`
    fragment TimesheetTimeOffRequest on TimeOffRequest {
  id
  status
  timeOffPolicy {
    id
    name
  }
  timeOffRequestDays {
    id
    date
    hours
  }
}
    `;
export const SuperAdminFieldsFragmentDoc = gql`
    fragment SuperAdminFields on User {
  id
  fullName
  slug
  email
  isAdmin
  createdAt
}
    `;
export const ClientAddressFragmentDoc = gql`
    fragment ClientAddress on Address {
  id
  line1
  line2
  city
  state
  zipcode
  country
}
    `;
export const ClientProjectFragmentDoc = gql`
    fragment ClientProject on Project {
  id
  slug
  number
  name
  status
}
    `;
export const ClientInvoiceFragmentDoc = gql`
    fragment ClientInvoice on Invoice {
  id
  number
  slug
  total
  issueDate
  dueDate
  status
  invoiceVersion
}
    `;
export const ClientResultFragmentDoc = gql`
    fragment ClientResult on Client {
  active
  syncedToQbo
  department
  displayName
  email
  fname
  fullyQualifiedName
  id
  lname
  name
  organizationName
  phone
  qboCustomer {
    id
    quickbooksId
  }
  quickbooksId
  slug
  title
  address {
    ...ClientAddress
  }
  projects {
    ...ClientProject
  }
  invoices {
    ...ClientInvoice
  }
  qboCustomer {
    id
    quickbooksId
  }
}
    ${ClientAddressFragmentDoc}
${ClientProjectFragmentDoc}
${ClientInvoiceFragmentDoc}`;
export const DirectoryClientFragmentDoc = gql`
    fragment DirectoryClient on Client {
  active
  syncedToQbo
  displayName
  email
  fname
  fullName
  id
  lname
  organizationName
  phone
  slug
  title
}
    `;
export const FlagsFragmentDoc = gql`
    fragment Flags on FeatureFlags {
  ajeraRework
  exportMigrationData
  invoicesV2
  payrollEnabled
  usesAccounting
  bqeExport
  timeOffHolidays
  qboExpenses
  highValueInsights
  killInvoicesV1
  weeklyStaffingNextjs
  staffingProjectView
  insightsDashboardV1
  renewalPriceTesting
  passConvenienceFeesV2
  roleRateSuperadmin
  stripeSetupReminders
  hideEntryGroupsFromCompletedProj
  bulkEditTimesheets
  modifyClient
  expensesV2
  consultantsRework
  expenseRates
}
    `;
export const GlobalOrganizationSettingsFragmentDoc = gql`
    fragment GlobalOrganizationSettings on Organization {
  id
  timeZone
  overheadMultiplier
  emailSenderName
  emailReplyToAddress
  connectedToQbo
  isSubscribed
  invoiceFooter
  currency {
    id
    name
    symbol
    code
    decimalDigits
  }
  address {
    line1
    line2
    city
    state
    zipcode
    country
  }
  locale {
    id
    name
    tag
  }
  settings {
    id
    multiQboInstanceEnabled
    defaultRealizationRate
    defaultUtilizationRate
    defaultConsultantMarkup
    unitOfMeasurement
    ptoEnabled
    timeOffToggledAt
    applyUtilizationRateToExistingProfiles
    defaultHoursPerWeek
    yearlyWorkingHours
    costsImportStartDate
  }
}
    `;
export const CompensationFragmentDoc = gql`
    fragment Compensation on Compensation {
  id
  isCurrent
  effectiveOn
  hoursPerWeek
  payrollType
  rate
}
    `;
export const ConsultantBillInvoiceFragmentDoc = gql`
    fragment ConsultantBillInvoice on Invoice {
  beginDate
  endDate
  id
  number
  slug
  status
}
    `;
export const ConsultantBillProjectFragmentDoc = gql`
    fragment ConsultantBillProject on Project {
  color
  id
  link
  name
  number
  slug
  invoices {
    ...ConsultantBillInvoice
  }
}
    ${ConsultantBillInvoiceFragmentDoc}`;
export const ConsultantBillCompanyFragmentDoc = gql`
    fragment ConsultantBillCompany on ConsultantCompany {
  id
  name
  quickbooksVendorId
}
    `;
export const ConsultantBillFragmentDoc = gql`
    fragment ConsultantBill on ConsultantBill {
  id
  project {
    ...ConsultantBillProject
  }
  invoice {
    ...ConsultantBillInvoice
  }
  invoiceAttachment {
    id
  }
  consultantCompany {
    ...ConsultantBillCompany
  }
  notes
  pdfLink
  billNumber
  billAmount
  paidAmount
  dueDate
  issueDate
  paidDate
  status
  quickbooksBillId
  quickbooksBillCategoryId
}
    ${ConsultantBillProjectFragmentDoc}
${ConsultantBillInvoiceFragmentDoc}
${ConsultantBillCompanyFragmentDoc}`;
export const ConsultantBillProjectConsultantFragmentDoc = gql`
    fragment ConsultantBillProjectConsultant on ProjectConsultant {
  id
  consultantTemplate {
    id
    name
  }
  consultingWork {
    id
    phase {
      id
    }
    consultantCompany {
      id
      name
      quickbooksVendorId
    }
    consultantContact {
      companyId
      companyName
      consultantCompany {
        id
        name
        quickbooksVendorId
      }
    }
  }
  phase {
    id
    name
  }
  project {
    id
  }
}
    `;
export const ProjectConsultantTotalsFragmentDoc = gql`
    fragment ProjectConsultantTotals on Project {
  consultantsAllocatedPercent
  consultantsFee
  consultantsUnallocated
  budget
  totalFee
}
    `;
export const ProjectTemplateConsultantTotalsFragmentDoc = gql`
    fragment ProjectTemplateConsultantTotals on ProjectTemplate {
  consultantsAllocatedPercent
  consultantsFee
  consultantsUnallocated
  budget
  totalFee
}
    `;
export const ConsultingWorkDetailsFragmentDoc = gql`
    fragment ConsultingWorkDetails on ConsultingWork {
  consultantReferenceType
  project {
    id
  }
  phase {
    project {
      id
    }
  }
  consultantContact {
    id
    fullName
    slug
    consultantCompany {
      id
      name
      slug
    }
  }
  consultantCompany {
    id
    name
    slug
  }
  consultantBills {
    id
  }
  id
}
    `;
export const ProjectConsultantDetailsFragmentDoc = gql`
    fragment ProjectConsultantDetails on ProjectConsultant {
  consultingWork {
    ...ConsultingWorkDetails
  }
  budget
  budgetIsFrozen
  consultantTemplateId
  consultantTemplate {
    id
    name
  }
  id
  markup
  markupPercentage
}
    ${ConsultingWorkDetailsFragmentDoc}`;
export const PhaseConsultantDetailsFragmentDoc = gql`
    fragment PhaseConsultantDetails on PhaseConsultant {
  consultingWork {
    ...ConsultingWorkDetails
  }
  consultantTemplate {
    id
    name
  }
  entries {
    budget
    budgetIsFrozen
    consultantTemplateId
    id
    markup
    markupPercentage
    phase {
      id
      name
    }
  }
  markupPercentage
  markupTotal
  total
}
    ${ConsultingWorkDetailsFragmentDoc}`;
export const ConsultantContactResultFragmentDoc = gql`
    fragment ConsultantContactResult on ConsultantContact {
  id
  email
  title
  fullName
  fname
  lname
  phone
  consultantTemplateId
  consultantServiceNames
  isPrimary
  consultantServiceDefaults {
    id
    consultantTemplate {
      id
      name
    }
  }
  active
  slug
  consultantCompany {
    id
    name
    slug
    active
  }
}
    `;
export const ConsultantCompanyAddressFragmentDoc = gql`
    fragment ConsultantCompanyAddress on Address {
  id
  line1
  line2
  city
  state
  zipcode
  country
}
    `;
export const ConsultantCompanyResultFragmentDoc = gql`
    fragment ConsultantCompanyResult on ConsultantCompany {
  active
  consultantServiceDefaults {
    id
    consultantTemplate {
      name
    }
  }
  consultantContacts {
    ...ConsultantContactResult
  }
  consultantServiceNames
  consultantTemplateIds
  id
  name
  notes
  primaryAddress {
    ...ConsultantCompanyAddress
  }
  primaryAddressDisplayLabel
  secondaryAddress {
    ...ConsultantCompanyAddress
  }
  secondaryAddressDisplayLabel
  slug
  syncedToQbo
  website
  quickbooksVendorId
  qboVendor {
    id
  }
}
    ${ConsultantContactResultFragmentDoc}
${ConsultantCompanyAddressFragmentDoc}`;
export const ClientProfileQboInstanceFragmentDoc = gql`
    fragment ClientProfileQboInstance on QBOInstance {
  companyName
  connected
  id
}
    `;
export const QboCustomerDetailsFragmentDoc = gql`
    fragment QboCustomerDetails on QBOCustomer {
  billingAddressLine1
  billingAddressLine2
  billingAddressCity
  billingAddressState
  billingAddressZipCode
  billingAddressCountry
  companyName
  displayName
  emailAddress
  firstName
  id
  lastName
  primaryPhoneNumber
  quickbooksId
  level
  quickbooksId
  parentQuickbooksId
  qboInstance {
    id
  }
}
    `;
export const QboCustomerDetailsForImportFragmentDoc = gql`
    fragment QboCustomerDetailsForImport on QBOCustomer {
  ...QboCustomerDetails
  processed
  qboInstance {
    id
    companyName
  }
}
    ${QboCustomerDetailsFragmentDoc}`;
export const CommonAddressFragmentDoc = gql`
    fragment CommonAddress on Address {
  id
  city
  country
  line1
  line2
  state
  zipcode
}
    `;
export const ProjectCostDetailsFragmentDoc = gql`
    fragment ProjectCostDetails on ProjectCost {
  constructionCost
  id
  sqUnitCostType
  sqUnits
}
    `;
export const ProjectGeneralInfoFragmentDoc = gql`
    fragment ProjectGeneralInfo on Project {
  address {
    ...CommonAddress
  }
  categories {
    id
    name
  }
  client {
    id
    displayName
    active
    syncedToQbo
    qboCustomer {
      id
    }
  }
  slug
  color
  id
  budget
  consultantsFee
  totalFee
  consultantsUnallocated
  consultantsAllocatedPercent
  isBillable
  isShareable
  name
  notes
  number
  shareableHash
  projectCost {
    ...ProjectCostDetails
  }
  accountingDestinationCustomer {
    id
    displayName
  }
}
    ${CommonAddressFragmentDoc}
${ProjectCostDetailsFragmentDoc}`;
export const PhaseInfoFragmentDoc = gql`
    fragment PhaseInfo on Phase {
  id
  name
  abbreviation
  roles {
    id
    name
    phaseRate
    profile {
      id
      name
      slug
    }
  }
}
    `;
export const ExpenseInfoFragmentDoc = gql`
    fragment ExpenseInfo on Expense {
  id
  date
  note
  isBillable
  isReimbursable
  reimbursePaidDate
  amount
  markup
  billableAmount
  attachment
  onOpenInvoice
  project {
    ...ProjectGeneralInfo
  }
  profile {
    id
    name
    slug
  }
  category {
    id
    name
    qboExpenseAccount {
      id
      name
    }
  }
  qboPurchaseId
  qboBillId
  overhead {
    name
    id
  }
  phase {
    ...PhaseInfo
  }
}
    ${ProjectGeneralInfoFragmentDoc}
${PhaseInfoFragmentDoc}`;
export const AjeraFieldsFragmentDoc = gql`
    fragment AjeraFields on AjeraIdentifier {
  id
  key
  typeKey
  recordId
  recordType
}
    `;
export const SelectableProfileGroupFragmentDoc = gql`
    fragment SelectableProfileGroup on ProfileGroup {
  name
  eligible {
    id
    fullName
  }
  ineligible {
    id
    fullName
  }
}
    `;
export const InvoiceTotalFragmentFragmentDoc = gql`
    fragment InvoiceTotalFragment on InvoiceBuilderServiceTotals {
  currentDue
  unbilled
  previouslyBilledAmount
}
    `;
export const ProjectConsultantsTotalFragmentFragmentDoc = gql`
    fragment ProjectConsultantsTotalFragment on InvoiceBuilderProjectConsultants {
  currentDue
  consultantTotals {
    currentDue
    currentCompletePercent
    previouslyBilled {
      percent
      amount
    }
    planned
  }
}
    `;
export const PhaseConsultantsTotalFragmentFragmentDoc = gql`
    fragment PhaseConsultantsTotalFragment on InvoiceBuilderPhaseConsultants {
  currentDue
  totals {
    currentCompletePercent
    currentDue
    planned
    previouslyBilled {
      amount
      percent
    }
  }
}
    `;
export const AppliedRetainerItemFragmentFragmentDoc = gql`
    fragment AppliedRetainerItemFragment on LineItem {
  id
  amount
  title
}
    `;
export const TaxRateFragmentDoc = gql`
    fragment TaxRate on TaxRate {
  id
  name
  percent
}
    `;
export const TaxConfigurationFragmentDoc = gql`
    fragment TaxConfiguration on LineItemTaxConfiguration {
  updatedAt
  fixedFeeServices
  hourlyServices
  consultingServices
  expenses
  otherItems
  retainerCollection
}
    `;
export const TaxItemFragmentDoc = gql`
    fragment TaxItem on InvoiceBuilderTaxItem {
  id
  amount
  taxRate {
    ...TaxRate
  }
  taxConfiguration {
    ...TaxConfiguration
  }
}
    ${TaxRateFragmentDoc}
${TaxConfigurationFragmentDoc}`;
export const TaxEntryPayloadFragmentDoc = gql`
    fragment TaxEntryPayload on InvoiceBuilderTaxes {
  entries {
    ...TaxItem
  }
  totals {
    currentDue
  }
}
    ${TaxItemFragmentDoc}`;
export const RetainerSummaryFragmentFragmentDoc = gql`
    fragment RetainerSummaryFragment on RetainerSummary {
  title
  received
  applied
  remaining
  openReceived
  openApplied
}
    `;
export const TabsTotalsFragmentFragmentDoc = gql`
    fragment TabsTotalsFragment on InvoiceBuilderPayload {
  fixedFeeServices {
    totals {
      ...InvoiceTotalFragment
    }
  }
  hourlyServices {
    totals {
      ...InvoiceTotalFragment
    }
  }
  expenses {
    totals {
      ...InvoiceTotalFragment
    }
  }
  projectConsultants {
    ...ProjectConsultantsTotalFragment
  }
  phaseConsultants {
    ...PhaseConsultantsTotalFragment
  }
  otherItems {
    currentDue
  }
  retainerItems {
    currentDue
  }
  appliedRetainer {
    ...AppliedRetainerItemFragment
  }
  taxes {
    ...TaxEntryPayload
  }
  retainerSummary {
    ...RetainerSummaryFragment
  }
}
    ${InvoiceTotalFragmentFragmentDoc}
${ProjectConsultantsTotalFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}
${AppliedRetainerItemFragmentFragmentDoc}
${TaxEntryPayloadFragmentDoc}
${RetainerSummaryFragmentFragmentDoc}`;
export const InvoiceBuilderProjectInvoiceFragmentFragmentDoc = gql`
    fragment InvoiceBuilderProjectInvoiceFragment on Project {
  id
  slug
  v1InvoicesWithUnassignedLineItemsCount
  retainerSummary {
    remaining
    received
    applied
  }
}
    `;
export const InvoiceBuilderInvoiceFragmentFragmentDoc = gql`
    fragment InvoiceBuilderInvoiceFragment on Invoice {
  id
  status
  beginDate
  endDate
  slug
  totalPaid
  total
  remainingBalance
  billingDestination
  lastLineItemUpdate
  client {
    id
    syncedToQbo
    qboCustomer {
      id
      qboInstance {
        id
      }
    }
  }
  qboInstance {
    id
    qboTaxMethod
  }
  organization {
    connectedToQbo
    clients {
      id
      syncedToQbo
      displayName
      organizationName
      qboCustomer {
        id
      }
    }
  }
  invoicePayments {
    amount
    id
    paidDate
  }
  project {
    ...InvoiceBuilderProjectInvoiceFragment
  }
}
    ${InvoiceBuilderProjectInvoiceFragmentFragmentDoc}`;
export const MilestoneFragmentFragmentDoc = gql`
    fragment MilestoneFragment on InvoiceBuilderMilestone {
  id
  completedTasksCount
  dueDate
  name
  tasksCount
}
    `;
export const PublicHourlyServicesEntryFragmentFragmentDoc = gql`
    fragment PublicHourlyServicesEntryFragment on InvoiceBuilderHourlyService {
  id
  milestones {
    ...MilestoneFragment
  }
  previouslyBilled {
    amount
    percent
  }
  currentCompletePercent
  currentDue
  roleEntriesDue
  phase {
    id
    name
    status
    feeType
  }
  maxCap
  phaseTotals {
    currentDue
    totalDue
    currentHours
    previouslyBilledHours
    previouslyBilledAmount
  }
}
    ${MilestoneFragmentFragmentDoc}`;
export const HourlyServicesEntryFragmentFragmentDoc = gql`
    fragment HourlyServicesEntryFragment on InvoiceBuilderHourlyService {
  ...PublicHourlyServicesEntryFragment
  unbilled {
    total
    beforeInvoiceBeginDate
    duringInvoiceRange
  }
}
    ${PublicHourlyServicesEntryFragmentFragmentDoc}`;
export const HourlyServicesRoleFragmentFragmentDoc = gql`
    fragment HourlyServicesRoleFragment on InvoiceBuilderRoleEntry {
  id
  hours
  previouslyBilledHours
  previouslyBilledAmount
  roleRate
  amount
  logged {
    total
    duringInvoiceRange
    beforeInvoiceBeginDate
  }
  role {
    id
    hours
    name
    rateTable {
      id
      slug
      name
    }
  }
}
    `;
export const HourlyServicesEntryWithRolesFragmentFragmentDoc = gql`
    fragment HourlyServicesEntryWithRolesFragment on InvoiceBuilderHourlyService {
  ...HourlyServicesEntryFragment
  roleEntries {
    ...HourlyServicesRoleFragment
  }
}
    ${HourlyServicesEntryFragmentFragmentDoc}
${HourlyServicesRoleFragmentFragmentDoc}`;
export const HourlyServicesConsultantEntriesFragmentFragmentDoc = gql`
    fragment HourlyServicesConsultantEntriesFragment on InvoiceBuilderProjectConsultantType {
  consultant {
    id
    consultantTemplate {
      name
      id
    }
  }
  currentCompletePercent
  currentDue
  id
  planned
  previouslyBilled {
    amount
  }
}
    `;
export const HourlyServicesEntriesFragmentFragmentDoc = gql`
    fragment HourlyServicesEntriesFragment on InvoiceBuilderHourlyServices {
  entries {
    ...HourlyServicesEntryWithRolesFragment
    consultantEntries {
      ...HourlyServicesConsultantEntriesFragment
    }
  }
  totals {
    ...InvoiceTotalFragment
  }
}
    ${HourlyServicesEntryWithRolesFragmentFragmentDoc}
${HourlyServicesConsultantEntriesFragmentFragmentDoc}
${InvoiceTotalFragmentFragmentDoc}`;
export const FixedFeeEntryFragmentFragmentDoc = gql`
    fragment FixedFeeEntryFragment on InvoiceBuilderFixedFeeService {
  id
  milestones {
    ...MilestoneFragment
  }
  planned
  previouslyBilled {
    amount
    percent
  }
  currentCompletePercent
  currentDue
  currentDueInput @client {
    value
    touched
  }
  currentCompletePercentInput @client {
    value
    touched
  }
  phase {
    id
    name
    status
    feeType
    endDate
  }
  unbilled {
    total
    beforeInvoiceBeginDate
    duringInvoiceRange
  }
  planned
}
    ${MilestoneFragmentFragmentDoc}`;
export const InvoiceBuilderPhaseTotalsFragmentFragmentDoc = gql`
    fragment InvoiceBuilderPhaseTotalsFragment on InvoiceBuilderFixedFeeServices {
  phaseTotals {
    planned
    unbilled
    previouslyBilled {
      amount
      percent
    }
    currentDue
    currentCompletePercent
  }
}
    `;
export const FixedFeeServicesEntriesFragmentFragmentDoc = gql`
    fragment FixedFeeServicesEntriesFragment on InvoiceBuilderFixedFeeServices {
  entries {
    ...FixedFeeEntryFragment
  }
  groupedPhaseEntries {
    fixedFeeServiceEntry {
      ...FixedFeeEntryFragment
    }
    consultantEntries {
      consultant {
        id
        consultantTemplate {
          id
          name
        }
      }
      currentDue
      currentCompletePercent
      planned
      id
      previouslyBilled {
        amount
        percent
      }
    }
    phase {
      id
      name
    }
    totals {
      currentCompletePercent
      planned
      previouslyBilled {
        amount
        percent
      }
      currentDue
    }
    hideFixedFeeServiceEntry
  }
  ...InvoiceBuilderPhaseTotalsFragment
  totals {
    ...InvoiceTotalFragment
  }
}
    ${FixedFeeEntryFragmentFragmentDoc}
${InvoiceBuilderPhaseTotalsFragmentFragmentDoc}
${InvoiceTotalFragmentFragmentDoc}`;
export const OtherItemFragmentFragmentDoc = gql`
    fragment OtherItemFragment on LineItem {
  id
  amount
  quantity
  title
  description
  rate
}
    `;
export const OtherItemEntriesFragmentFragmentDoc = gql`
    fragment OtherItemEntriesFragment on InvoiceBuilderOtherItemsType {
  currentDue
  entries {
    ...OtherItemFragment
  }
}
    ${OtherItemFragmentFragmentDoc}`;
export const RetainerItemFragmentFragmentDoc = gql`
    fragment RetainerItemFragment on LineItem {
  id
  amount
  title
  description
}
    `;
export const RetainerItemEntriesFragmentFragmentDoc = gql`
    fragment RetainerItemEntriesFragment on InvoiceBuilderRetainerItemsType {
  currentDue
  entries {
    ...RetainerItemFragment
  }
}
    ${RetainerItemFragmentFragmentDoc}`;
export const ProjectConsultantFragmentFragmentDoc = gql`
    fragment ProjectConsultantFragment on InvoiceBuilderProjectConsultantType {
  id
  consultant {
    id
    phase {
      name
      id
    }
    consultantTemplate {
      name
    }
  }
  planned
  currentDue
  currentCompletePercent
  markup
  markupPercentage
  consultantFee
  currentDueInput @client {
    value
    touched
  }
  currentCompletePercentInput @client {
    value
    touched
  }
  previouslyBilled {
    percent
    amount
  }
}
    `;
export const PhaseConsultantsFragmentFragmentDoc = gql`
    fragment PhaseConsultantsFragment on InvoiceBuilderPhaseConsultantType {
  consultantCompany {
    name
  }
  consultantTemplate {
    id
    name
  }
  consultantTemplateTotals {
    consultantFee
    currentDue
    markup
    markupPercentage
    planned
    previouslyBilled {
      percent
      amount
    }
    currentCompletePercent
  }
  entries {
    ...ProjectConsultantFragment
  }
}
    ${ProjectConsultantFragmentFragmentDoc}`;
export const ProjectConsultantsFragmentFragmentDoc = gql`
    fragment ProjectConsultantsFragment on InvoiceBuilderProjectConsultants {
  entries {
    ...ProjectConsultantFragment
  }
  ...ProjectConsultantsTotalFragment
}
    ${ProjectConsultantFragmentFragmentDoc}
${ProjectConsultantsTotalFragmentFragmentDoc}`;
export const CurrentActivityFragmentFragmentDoc = gql`
    fragment CurrentActivityFragment on Invoice {
  currentActivity {
    activityType
    createdAt
    email
    id
    amount
    previousAmount
    relevantDate
    previousRelevantDate
    profile {
      email
    }
  }
}
    `;
export const InvoiceTimelogDetailsEntryFragmentDoc = gql`
    fragment InvoiceTimelogDetailsEntry on InvoiceBuilderTimelogDetailEntryType {
  employee
  date
  role
  activity
  notes
  rate
  hours
  amount
  phase
}
    `;
export const InvoiceTimelogDetailsGroupedEntryFragmentDoc = gql`
    fragment InvoiceTimelogDetailsGroupedEntry on InvoiceBuilderTimelogDetailGroupedEntryType {
  role
  activityEntries {
    activity
    rate
    hours
    amount
  }
  total
}
    `;
export const InvoiceTimelogDetailsFragmentDoc = gql`
    fragment InvoiceTimelogDetails on InvoiceBuilderTimelogDetails {
  entries {
    ...InvoiceTimelogDetailsEntry
  }
  groupedEntries {
    ...InvoiceTimelogDetailsGroupedEntry
  }
}
    ${InvoiceTimelogDetailsEntryFragmentDoc}
${InvoiceTimelogDetailsGroupedEntryFragmentDoc}`;
export const OutstandingInvoicesFragmentFragmentDoc = gql`
    fragment OutstandingInvoicesFragment on InvoiceBuilderOustandingInvoices {
  total
  entries {
    dueDate
    number
    paymentUrl
    remainingBalance
  }
}
    `;
export const QuickbooksItemFragmentDoc = gql`
    fragment QuickbooksItem on QuickbooksItem {
  description
  id
  itemType
  name
  quickbooksId
  quickbooksParentRef
}
    `;
export const OrganizationSettingsFragmentDoc = gql`
    fragment OrganizationSettings on OrganizationSetting {
  id
  activityRequired
  ajeraEnabled
  applyConsultantMappingForMarkup
  applyUtilizationRateToExistingProfiles
  consultantMarkupQuickbooksServiceItem {
    ...QuickbooksItem
  }
  defaultConsultantMarkup
  defaultHoursPerWeek
  defaultRealizationRate
  defaultUtilizationRate
  multiQboInstanceEnabled
  ptoEnabled
  timeOffToggledAt
  unitOfMeasurement
  yearlyWorkingHours
  checkId
  costsImportStartDate
  reimbursableExpensesAccount {
    id
    name
  }
  nonReimbursableExpensesAccount {
    id
    name
  }
  costsImportStartDate
}
    ${QuickbooksItemFragmentDoc}`;
export const CurrentUserOrganizationsFragmentDoc = gql`
    fragment CurrentUserOrganizations on Organization {
  id
  name
  logo
}
    `;
export const PayrollPaystubFragmentDoc = gql`
    fragment PayrollPaystub on Paystub {
  type
  amount
  date
  payrollId
  totalHours
}
    `;
export const PayrollAddressFragmentDoc = gql`
    fragment PayrollAddress on PayrollAddress {
  line1
  line2
  city
  state
  postalCode
  country
}
    `;
export const PayrollEmployeeFragmentDoc = gql`
    fragment PayrollEmployee on PayrollEmployeeInfo {
  id
  active
  dob
  startDate
  firstName
  lastName
  checkOnboardingStatus
  ssnLastFour
  paymentMethodPreference
  residence {
    ...PayrollAddress
  }
}
    ${PayrollAddressFragmentDoc}`;
export const PayrollContractorFragmentDoc = gql`
    fragment PayrollContractor on PayrollWorkerInfo {
  id
  firstName
  lastName
  checkOnboardingStatus
  ssnLastFour
  ein
  paymentMethodPreference
  residence {
    ...PayrollAddress
  }
}
    ${PayrollAddressFragmentDoc}`;
export const PayrollWorkplaceFragmentDoc = gql`
    fragment PayrollWorkplace on Workplace {
  id
  name
  address {
    ...PayrollAddress
  }
}
    ${PayrollAddressFragmentDoc}`;
export const PayrollWorkerFragmentDoc = gql`
    fragment PayrollWorker on PayrollWorkerInfo {
  id
  active
  dob
  startDate
  ein
  firstName
  lastName
  checkOnboardingStatus
  ssnLastFour
  paymentMethodPreference
  residence {
    ...PayrollAddress
  }
  workplace {
    ...PayrollWorkplace
  }
}
    ${PayrollAddressFragmentDoc}
${PayrollWorkplaceFragmentDoc}`;
export const PayrollProfileFragmentDoc = gql`
    fragment PayrollProfile on Profile {
  id
  fname
  lname
  title
  fullName
  checkId
  slug
  status
  settings {
    checkEmploymentType
    checkContractorId
    checkEmployeeId
  }
}
    `;
export const PayrollInfoFragmentDoc = gql`
    fragment PayrollInfo on PayrollCompanyInfo {
  id
  legalName
  email
  payFrequency
  onboarding {
    status
    blockingSteps
    remainingForms
    remainingSteps
    remainingTaxJurisdictions
    bankAccount
  }
  address {
    ...PayrollAddress
  }
}
    ${PayrollAddressFragmentDoc}`;
export const PayrollEarningRateFragmentDoc = gql`
    fragment PayrollEarningRate on PayrollEarningRate {
  amount
  period
}
    `;
export const PayrollPaydayFragmentDoc = gql`
    fragment PayrollPayday on PaydayInfo {
  payday
  approvalDeadline
  impactedByHoliday
}
    `;
export const PayrollPaySchedulePaydayFragmentDoc = gql`
    fragment PayrollPaySchedulePayday on PaySchedulePayday {
  payday
  periodStart
  periodEnd
  approvalDeadline
  impactedByWeekendOrHoliday
}
    `;
export const CompanyPayrollDocumentFragmentDoc = gql`
    fragment CompanyPayrollDocument on PayrollDocument {
  id
  label
  year
  jurisdiction
  filedOn
}
    `;
export const PayrollBankAccountFragmentDoc = gql`
    fragment PayrollBankAccount on PayrollBankAccount {
  id
  workerId
  status
  info {
    accountNumberLastFour
    institutionName
    type
    subtype
  }
}
    `;
export const PayrollPayScheduleFragmentDoc = gql`
    fragment PayrollPaySchedule on PaySchedule {
  id
  firstPayday
  firstPayPeriodEnd
  payFrequency
}
    `;
export const CurrentProfilePermissionsFragmentDoc = gql`
    fragment CurrentProfilePermissions on Permissions {
  canEditAssignedProjects
  canDeleteAssignedProjects
  moneyGanttAssignedProjects
  canViewAllProjects
  canEditAllProjects
  canCreateAllProjects
  canDeleteAllProjects
  moneyGanttAllProjects
  canViewAllTimesheets
  canEditAllTimesheets
  canViewAllExpenses
  canManageAllExpenses
  canEditSettings
  canViewInvoices
  canEditInvoices
  canDeleteInvoices
  canCreateInvoices
  canManageProfiles
  canManageCompensations
  canViewFinancialReports
  canViewTeamReports
  canViewAllClients
  canManageAllClients
  canSendToQuickbooks
  canManageAllConsultants
  canManageStaffing
  canViewFinancialInformation
  canViewGanttLoggedTimeAssignedProjects
  canViewGanttLoggedTimeAllProjects
  canViewGanttInvoicedPaidAssignedProjects
  canViewGanttInvoicedPaidAllProjects
  canManagePto
}
    `;
export const CurrentProfilePolicyFragmentDoc = gql`
    fragment CurrentProfilePolicy on Policy {
  id
  isAdmin
  name
  permissions {
    ...CurrentProfilePermissions
  }
}
    ${CurrentProfilePermissionsFragmentDoc}`;
export const CurrentOrganizationCurrencyFragmentDoc = gql`
    fragment CurrentOrganizationCurrency on Currency {
  id
  name
  symbol
  code
  decimalDigits
}
    `;
export const CurrentOrganizationLocaleFragmentDoc = gql`
    fragment CurrentOrganizationLocale on Locale {
  id
  name
  tag
}
    `;
export const CurrentOrganizationFragmentDoc = gql`
    fragment CurrentOrganization on Organization {
  id
  bqeDataProcessor {
    updatedAt
    status
  }
  logo
  name
  size
  isDemo
  staffOrg
  consultantBills {
    id
  }
  connectedToQbo
  unassignedProjectCostsCount
  connectedToQbo
  isSubscribed
  isQualified
  trialEndDate
  timer
  roleAudit
  profiles {
    slug
    fullName
    id
  }
  profileCount
  profilesWithoutCompensationsCount
  profilesWithIncompleteOnboardingCount
  projectLimit
  projectsCount
  address {
    line1
    line2
    city
    state
    zipcode
    country
  }
  demo {
    id
    name
    size
  }
  demoParent {
    id
    name
    size
    logo
  }
  subscription {
    createdAt
    isActive
    hasFailedPayment
    prepaidSeatCount
    prepaidSeatsFilled
    planName
  }
  subscriptionForOrgOrDemoParent {
    id
    createdAt
    isActive
    hasFailedPayment
    prepaidSeatCount
    prepaidSeatsFilled
    planName
  }
  currency {
    ...CurrentOrganizationCurrency
  }
  locale {
    ...CurrentOrganizationLocale
  }
  qboInstances {
    id
  }
  settings {
    id
    checkId
    multiQboInstanceEnabled
    unitOfMeasurement
    costsImportStartDate
  }
  qboTaxMethod
}
    ${CurrentOrganizationCurrencyFragmentDoc}
${CurrentOrganizationLocaleFragmentDoc}`;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  isAdmin
  isClient
  isTeamMember
  payrollAdmin
  fullName
  fname
  lname
  email
  loggedInAsSomeoneElse
}
    `;
export const CurrentProfileFragmentDoc = gql`
    fragment CurrentProfile on Profile {
  id
  fname
  lname
  name
  initials
  slug
  email
  title
  intercomUserHash
  image
  confirmedAt
  organization {
    ...CurrentOrganization
  }
  policy {
    isAdmin
  }
  user {
    ...CurrentUser
  }
}
    ${CurrentOrganizationFragmentDoc}
${CurrentUserFragmentDoc}`;
export const GanttProjectRowFragmentDoc = gql`
    fragment GanttProjectRow on GanttChartRow {
  assigned
  hoursConsumed
  hoursPlanned
  href
  id
  number
  status
  color
  name
  startDate
  endDate
  showMoneyGantt
  showMoneyGanttHoverCard
  showInvoicedData
  phases {
    type {
      id
      name
      abbr
      feeType
      status
    }
    startDate
    endDate
    hoursPlanned
    hoursConsumed
    planned
    logged
    invoiced
    paid
    milestones {
      name
      date
      completed
    }
  }
}
    `;
export const PreviousInvoiceDetailsFragmentDoc = gql`
    fragment PreviousInvoiceDetails on Invoice {
  beginDate
  endDate
  id
  number
  slug
}
    `;
export const ArtHoursServicesRoleFragmentDoc = gql`
    fragment ArtHoursServicesRole on Role {
  id
  hours
  name
  rateTable {
    id
    name
    slug
  }
  ratesDuring(beginDate: $beginDate, endDate: $endDate) {
    amount
    effectiveOn
    displayDate
  }
}
    `;
export const ExpenseFragmentFragmentDoc = gql`
    fragment ExpenseFragment on Expense {
  id
  category {
    id
    name
  }
  attachment
  note
  date
  phase {
    name
    id
  }
  profile {
    id
    name
  }
  amount
  markup
  billableAmount
}
    `;
export const ExpensesMutationPayloadFragmentDoc = gql`
    fragment ExpensesMutationPayload on InvoiceBuilderPayload {
  expenses {
    included {
      ...ExpenseFragment
    }
    reported {
      ...ExpenseFragment
    }
  }
}
    ${ExpenseFragmentFragmentDoc}`;
export const InvoicePaymentMethodsFragmentFragmentDoc = gql`
    fragment InvoicePaymentMethodsFragment on Invoice {
  allowCardPayments
  allowBankTransfers
  passConvenienceFeeAch
  passConvenienceFeeCard
}
    `;
export const InvoiceActivitiesFragmentFragmentDoc = gql`
    fragment InvoiceActivitiesFragment on Invoice {
  invoiceActivities {
    activityType
    createdAt
    email
    id
    amount
    previousAmount
    relevantDate
    previousRelevantDate
    profile {
      email
    }
    billingDestination
    thirdPartySource
  }
}
    `;
export const InvoiceBasicInfoFragmentFragmentDoc = gql`
    fragment InvoiceBasicInfoFragment on Invoice {
  id
  number
  message
  referenceNumber
  issueDate
  dueDate
  beginDate
  endDate
  term {
    id
  }
}
    `;
export const InvoiceWithDisplayOptionsFragmentFragmentDoc = gql`
    fragment InvoiceWithDisplayOptionsFragment on Invoice {
  id
  displayOptions {
    showAllFixedFeeLineItems
    hidePreviouslyBilled
    groupTimelogByRoleAndActivity
    includeReceiptImages
    phaseConsultantsDisplay
    useCompactLayout
    includeTimelog
    hideServicesThrough
    showRetainerSummary
    showOutstandingInvoices
  }
}
    `;
export const InvoiceAddressFragmentFragmentDoc = gql`
    fragment InvoiceAddressFragment on Address {
  id
  line1
  line2
  city
  state
  zipcode
  country
}
    `;
export const InvoiceClientFragmentFragmentDoc = gql`
    fragment InvoiceClientFragment on Client {
  displayName
  id
  syncedToQbo
  fname
  fullyQualifiedName
  fullName
  lname
  slug
  organizationName
  department
  email
  address {
    ...InvoiceAddressFragment
  }
  qboCustomer {
    id
    qboInstance {
      id
    }
  }
}
    ${InvoiceAddressFragmentFragmentDoc}`;
export const OrganizationStripeAccountFragmentFragmentDoc = gql`
    fragment OrganizationStripeAccountFragment on StripeAccount {
  id
  detailsSubmitted
  chargesEnabled
  payoutsEnabled
  currencyCode
}
    `;
export const InvoiceOrganizationFragmentFragmentDoc = gql`
    fragment InvoiceOrganizationFragment on Organization {
  id
  name
  logo
  connectedToQbo
  stripeAccount {
    ...OrganizationStripeAccountFragment
  }
  invoiceFooter
  isSubscribed
  settings {
    id
    multiQboInstanceEnabled
  }
  terms {
    id
    name
    days
    active
    qboTerm {
      qboInstance {
        id
      }
    }
  }
  address {
    ...InvoiceAddressFragment
  }
  clients {
    id
    syncedToQbo
    displayName
    organizationName
  }
  applyAchOffer
}
    ${OrganizationStripeAccountFragmentFragmentDoc}
${InvoiceAddressFragmentFragmentDoc}`;
export const BillingDestinationCustomerFragmentDoc = gql`
    fragment BillingDestinationCustomer on QBOCustomer {
  displayName
  id
  level
  parentQuickbooksId
  qboInstanceId
  quickbooksId
}
    `;
export const InvoiceFragmentFragmentDoc = gql`
    fragment InvoiceFragment on Invoice {
  deliverTo
  deliverToCc
  id
  internalNote
  invoiceVersion
  lastSyncedToQuickbooksAt
  lastLineItemUpdate
  slug
  total
  totalPaid
  remainingBalance
  status
  paidDate
  paymentUrl
  paymentIntentStatus
  billingDestination
  ...InvoicePaymentMethodsFragment
  invoicePayments {
    amount
    id
    paidDate
    isQboPayment
  }
  footer
  ...InvoiceActivitiesFragment
  ...InvoiceBasicInfoFragment
  ...InvoiceWithDisplayOptionsFragment
  client {
    ...InvoiceClientFragment
  }
  project {
    id
    name
    number
    color
    v1InvoicesWithUnassignedLineItemsCount
    slug
    paymentEmailSettings {
      customBody
      defaultBodyWithPayment
      defaultBodyWithoutPayment
    }
    retainerSummary {
      remaining
      received
      applied
    }
  }
  organization {
    ...InvoiceOrganizationFragment
  }
  term {
    id
    days
    name
  }
  attachedExpenses {
    ...ExpenseFragment
  }
  invoiceAttachments {
    id
    url
    filename
  }
  qboInstance {
    id
    qboTaxMethod
  }
  invoiceQboDetails {
    id
    accountingDestinationCustomer {
      ...BillingDestinationCustomer
    }
  }
}
    ${InvoicePaymentMethodsFragmentFragmentDoc}
${InvoiceActivitiesFragmentFragmentDoc}
${InvoiceBasicInfoFragmentFragmentDoc}
${InvoiceWithDisplayOptionsFragmentFragmentDoc}
${InvoiceClientFragmentFragmentDoc}
${InvoiceOrganizationFragmentFragmentDoc}
${ExpenseFragmentFragmentDoc}
${BillingDestinationCustomerFragmentDoc}`;
export const ItemFailuresFragmentDoc = gql`
    fragment ItemFailures on InvoicesToQBOFailures {
  invoice {
    id
    slug
    number
  }
  message
}
    `;
export const PublicInvoiceOrganizationDetailsFragmentDoc = gql`
    fragment PublicInvoiceOrganizationDetails on Invoice {
  organization {
    name
    logo
    invoiceFooter
    currency {
      ...CurrentOrganizationCurrency
    }
    locale {
      ...CurrentOrganizationLocale
    }
  }
}
    ${CurrentOrganizationCurrencyFragmentDoc}
${CurrentOrganizationLocaleFragmentDoc}`;
export const PubicProjectInvoiceFragmentFragmentDoc = gql`
    fragment PubicProjectInvoiceFragment on Project {
  name
  number
  color
}
    `;
export const PublicInvoiceFragmentFragmentDoc = gql`
    fragment PublicInvoiceFragment on Invoice {
  id
  invoiceVersion
  number
  referenceNumber
  allowCardPayments
  allowBankTransfers
  slug
  total
  remainingBalance
  totalPaid
  status
  issueDate
  dueDate
  paidDate
  beginDate
  endDate
  message
  footer
  invoicePayments {
    amount
    id
    paidDate
  }
  paymentIntentStatus
  passConvenienceFeeCard
  passConvenienceFeeAch
  displayOptions {
    showAllFixedFeeLineItems
    hidePreviouslyBilled
    includeReceiptImages
    groupTimelogByRoleAndActivity
    phaseConsultantsDisplay
    useCompactLayout
    includeTimelog
    hideServicesThrough
    showRetainerSummary
    showOutstandingInvoices
  }
  client {
    displayName
    fullyQualifiedName
    id
    name
    fname
    lname
    fullName
    organizationName
    email
    address {
      line1
      line2
      city
      state
      zipcode
      country
    }
  }
  project {
    ...PubicProjectInvoiceFragment
  }
  organization {
    name
    logo
    invoiceFooter
    currency {
      ...CurrentOrganizationCurrency
    }
    locale {
      ...CurrentOrganizationLocale
    }
    terms {
      id
      name
      days
      active
    }
    address {
      line1
      line2
      city
      state
      zipcode
      country
    }
  }
  term {
    id
    days
    name
  }
  attachedExpenses {
    ...ExpenseFragment
  }
  invoiceAttachments {
    id
    url
    filename
  }
}
    ${PubicProjectInvoiceFragmentFragmentDoc}
${CurrentOrganizationCurrencyFragmentDoc}
${CurrentOrganizationLocaleFragmentDoc}
${ExpenseFragmentFragmentDoc}`;
export const PublicFixedFeeEntryFragmentFragmentDoc = gql`
    fragment PublicFixedFeeEntryFragment on InvoiceBuilderFixedFeeService {
  planned
  previouslyBilled {
    amount
    percent
  }
  currentCompletePercent
  currentDue
  currentDueInput @client {
    value
    touched
  }
  currentCompletePercentInput @client {
    value
    touched
  }
  phase {
    name
  }
}
    `;
export const InvoiceRemindersSchedulerFragmentDoc = gql`
    fragment InvoiceRemindersScheduler on InvoiceRemindersScheduler {
  daysAfterDueDate
  daysBeforeDueDate
  deliverCc
  deliverTo
  sendOnDueDate
}
    `;
export const InvoiceAttachmentFragmentDoc = gql`
    fragment InvoiceAttachment on InvoiceAttachment {
  id
  url
  filename
}
    `;
export const ListedMilestoneFragmentDoc = gql`
    fragment ListedMilestone on Milestone {
  completedTasksCount
  dueDate
  id
  name
  tasksCount
  phase {
    id
    name
    abbreviation
  }
  project {
    id
    color
    name
    number
    slug
  }
}
    `;
export const AdminOrganizationSubscriptionFieldsFragmentDoc = gql`
    fragment AdminOrganizationSubscriptionFields on OrganizationSubscription {
  id
  isActive
  isAnnual
  createdAt
  planName
}
    `;
export const AdminOrganizationAddressFieldsFragmentDoc = gql`
    fragment AdminOrganizationAddressFields on Address {
  city
  line1
  state
  zipcode
}
    `;
export const AdminOrganizationFieldsFragmentDoc = gql`
    fragment AdminOrganizationFields on Organization {
  name
  id
  slug
  demo {
    id
  }
  declaredSize
  profileCount
  declaredReferrer
  status
  trialEndDate
  isQualified
  createdAt
  updatedAt
  demo {
    id
  }
  settings {
    id
    checkId
  }
  subscription {
    ...AdminOrganizationSubscriptionFields
  }
  address {
    ...AdminOrganizationAddressFields
  }
}
    ${AdminOrganizationSubscriptionFieldsFragmentDoc}
${AdminOrganizationAddressFieldsFragmentDoc}`;
export const OrganizationAddressFragmentDoc = gql`
    fragment OrganizationAddress on Address {
  id
  line1
  line2
  city
  state
  zipcode
  country
}
    `;
export const AdminProfileFieldsFragmentDoc = gql`
    fragment AdminProfileFields on Profile {
  id
  name
  email
  title
  mfaEnabled
  user {
    id
  }
  organization {
    id
    name
  }
}
    `;
export const ProfileRoleTemplatesFragmentDoc = gql`
    fragment ProfileRoleTemplates on Profile {
  id
  defaultRoleTemplate {
    id
    name
  }
  organization {
    currency {
      symbol
    }
    roleTemplates {
      id
      name
      archivedAt
    }
  }
}
    `;
export const ProfileSettingsFragmentDoc = gql`
    fragment ProfileSettings on Profile {
  sendDailyEmail
  sendTimesheetsSummaryEmail
  settings {
    id
    weeklyInsightsDigest
    phaseRetroInsightsDigest
  }
}
    `;
export const ProfileBasicInfoFragmentDoc = gql`
    fragment ProfileBasicInfo on Profile {
  id
  fname
  lname
  fullName
  initials
  title
  pronouns
  status
  email
  image
  telephone
  slug
  policy {
    id
    isAdmin
  }
  utilizationRate
}
    `;
export const ProfilePayrollInfoFragmentDoc = gql`
    fragment ProfilePayrollInfo on Profile {
  checkId
}
    `;
export const ProfileProjectsFragmentDoc = gql`
    fragment ProfileProjects on Project {
  id
  number
  name
  status
  color
}
    `;
export const ProfileRateFragmentDoc = gql`
    fragment ProfileRate on Profile {
  defaultRateTable {
    id
    name
    currentRate {
      id
      amount
    }
  }
}
    `;
export const TeamRoleColumnFragmentDoc = gql`
    fragment TeamRoleColumn on Profile {
  id
  organization {
    currency {
      symbol
    }
    roleTemplates {
      id
      name
      archivedAt
    }
  }
  ...ProfileRate
  defaultRoleTemplate {
    name
  }
}
    ${ProfileRateFragmentDoc}`;
export const TeamOverviewFragmentDoc = gql`
    fragment TeamOverview on Profile {
  id
  fname
  lname
  fullName
  initials
  image
  title
  email
  telephone
  slug
  confirmedAt
  status
  onboardingComplete
  policy {
    id
    name
  }
  ...TeamRoleColumn
}
    ${TeamRoleColumnFragmentDoc}`;
export const PlannerPhaseProfileFragmentDoc = gql`
    fragment PlannerPhaseProfile on Profile {
  fname
  fullName
  id
  image
  lname
  initials
  roleTemplate {
    id
    name
  }
  defaultRoleTemplate {
    id
    name
  }
  defaultRateTable {
    id
  }
}
    `;
export const PhaseRoleFragmentDoc = gql`
    fragment PhaseRole on Role {
  id
  budget
  hours
  name
  percent
  position
  entryHoursCount
  hasNonDraftLineItem
  hasLoggedTime
  profile {
    ...PlannerPhaseProfile
  }
  phaseRate
  phaseRates {
    amount
    effectiveOn
    displayDate
  }
  rateTable {
    id
    name
    slug
  }
  roleTemplate {
    id
  }
}
    ${PlannerPhaseProfileFragmentDoc}`;
export const PlannerPhaseFragmentDoc = gql`
    fragment PlannerPhase on Phase {
  id
  name
  abbreviation
  feeType
  budget
  status
  beginDate
  endDate
  percent
  position
  entryHoursCount
  hasNonDraftLineItem
  roles {
    ...PhaseRole
  }
  phaseTemplate {
    id
  }
  linkedPhase {
    id
    name
  }
  linkedPhases {
    id
    name
  }
}
    ${PhaseRoleFragmentDoc}`;
export const PlannerPlanableFragmentDoc = gql`
    fragment PlannerPlanable on Planable {
  id
  color
  slug
  budget
  isBillable
  name
  consultantsFee
  number
  totalFee
  phases {
    ...PlannerPhase
  }
}
    ${PlannerPhaseFragmentDoc}`;
export const PlannerProjectTemplateFragmentDoc = gql`
    fragment PlannerProjectTemplate on ProjectTemplate {
  id
  color
  slug
  consultantsFee
  budget
  isBillable
  name
  number
  totalFee
}
    `;
export const PlannerPhaseTemplateFragmentDoc = gql`
    fragment PlannerPhaseTemplate on PhaseTemplate {
  id
  name
  abbreviation
  feeType
}
    `;
export const ProjectPlannerExpenseCategoryFragmentDoc = gql`
    fragment ProjectPlannerExpenseCategory on ExpenseCategory {
  id
  name
  markupPercentage
}
    `;
export const ProjectTemplateGeneralInfoFragmentDoc = gql`
    fragment ProjectTemplateGeneralInfo on ProjectTemplate {
  budget
  color
  consultantsAllocatedPercent
  consultantsFee
  consultantsUnallocated
  id
  isBillable
  name
  notes
  number
  slug
  totalFee
  categories {
    id
    name
  }
}
    `;
export const ProjectCommonDisplayDataFragmentDoc = gql`
    fragment ProjectCommonDisplayData on Project {
  color
  id
  name
  number
  slug
  profiles {
    id
  }
  v1InvoicesWithUnassignedLineItemsCount
  projectConsultants {
    id
    consultingWork {
      consultantCompany {
        id
      }
    }
  }
  phaseConsultants {
    entries {
      id
    }
  }
}
    `;
export const ProjectConsultantsFragmentDoc = gql`
    fragment ProjectConsultants on Project {
  projectConsultants {
    budget
    budgetIsFrozen
    markup
    markupPercentage
    id
  }
}
    `;
export const ProjectPhasesFragmentDoc = gql`
    fragment ProjectPhases on Project {
  orderedPhases {
    id
    name
  }
}
    `;
export const ProjectTotalsFragmentFragmentDoc = gql`
    fragment ProjectTotalsFragment on ProjectsTimingsTotals {
  filteredProjectCount
  projectTotal
  projectPercentage
  issuedFee
  invoicesCount
  consumed {
    fee
    hours
    billableFeePercent
  }
  planned {
    fee
    hours
  }
  issuedFee
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  color
  status
  slug
  isBillable
  number
  link
  projectCost {
    sqUnitCostType
  }
}
    `;
export const ProjectDataFragmentFragmentDoc = gql`
    fragment ProjectDataFragment on ProjectsTimingsProjectListEntry {
  issuedFee
  planned {
    fee
    hours
  }
  consumed {
    fee
    hours
  }
  projectCost {
    sqUnits
  }
  costs {
    costBySqUnit
    plannedFeeBySqUnit
    consumedFeeBySqUnit
    revenueBySqUnit
  }
  project {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export const ProjectTotalsPerDateFragmentFragmentDoc = gql`
    fragment ProjectTotalsPerDateFragment on ProjectsTimingsChartEntryTotals {
  issuedFee
  consumed {
    fee
    hours
  }
  planned {
    fee
    hours
  }
}
    `;
export const ProjectChartFragmentFragmentDoc = gql`
    fragment ProjectChartFragment on ProjectsTimingsChartEntry {
  interval {
    beginDate
    endDate
  }
  totals {
    ...ProjectTotalsPerDateFragment
  }
  data {
    issuedFee
    consumed {
      fee
      hours
    }
    planned {
      fee
      hours
    }
    projectId
  }
}
    ${ProjectTotalsPerDateFragmentFragmentDoc}`;
export const ProjectFiltersFragmentFragmentDoc = gql`
    fragment ProjectFiltersFragment on ProjectsTimingsFilters {
  billableOption
  categoryIds
  clientIds
  dateRange {
    beginDate
    endDate
  }
  datePreset
  projectIds
  statuses
  minSqUnit
  maxSqUnit
}
    `;
export const QuickSearchProjectFragmentDoc = gql`
    fragment QuickSearchProject on Project {
  color
  id
  name
  number
  slug
}
    `;
export const QuickSearchClientFragmentDoc = gql`
    fragment QuickSearchClient on Client {
  syncedToQbo
  id
  name
  displayName
  slug
}
    `;
export const QuickSearchProfileFragmentDoc = gql`
    fragment QuickSearchProfile on Profile {
  id
  image
  initials
  name
  slug
}
    `;
export const RateTableProfileFragmentDoc = gql`
    fragment RateTableProfile on Profile {
  id
  initials
  slug
  fullName
  fname
  lname
  title
  image
}
    `;
export const RateTableDetailFragmentDoc = gql`
    fragment RateTableDetail on RateTable {
  id
  name
  archivedAt
  slug
  roleTemplate {
    id
    archivedAt
    name
    profiles {
      id
      name
      fullName
    }
  }
  profiles {
    ...RateTableProfile
  }
}
    ${RateTableProfileFragmentDoc}`;
export const RateDetailFragmentDoc = gql`
    fragment RateDetail on Rate {
  id
  effectiveOn
  amount
  displayDate
  isInitialRate
  isCurrentRate
}
    `;
export const RateTableWithRatesFragmentDoc = gql`
    fragment RateTableWithRates on RateTable {
  ...RateTableDetail
  orderedRates {
    ...RateDetail
  }
  currentRate {
    ...RateDetail
  }
}
    ${RateTableDetailFragmentDoc}
${RateDetailFragmentDoc}`;
export const RoleTemplateWithRatesFragmentDoc = gql`
    fragment RoleTemplateWithRates on RoleTemplate {
  id
  archivedAt
  name
  isAssigned
  assignedProfiles {
    id
    name
    fullName
  }
  profiles {
    id
    name
    fullName
  }
  rateTables {
    ...RateTableWithRates
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export const RateTableDependenciesFragmentDoc = gql`
    fragment RateTableDependencies on RateTable {
  dependencyCounts {
    projects
    profiles {
      id
      fullName
    }
  }
}
    `;
export const TimelogGoupedByActivityFragmentDoc = gql`
    fragment TimelogGoupedByActivity on Activity {
  isBillable
  name
  id
}
    `;
export const TimelogGroupedByPhaseFragmentDoc = gql`
    fragment TimelogGroupedByPhase on Phase {
  name
  id
}
    `;
export const TimelogGroupedByProfileFragmentDoc = gql`
    fragment TimelogGroupedByProfile on Profile {
  id
  fullName
}
    `;
export const TimelogGroupedByProjectFragmentDoc = gql`
    fragment TimelogGroupedByProject on Project {
  id
  number
  projectName: name
  color
}
    `;
export const ProjectPerformancePhaseFragmentDoc = gql`
    fragment ProjectPerformancePhase on ProjectPerformancePhases {
  phase {
    id
    name
    feeType
    status
    project {
      slug
    }
  }
  planned {
    hours
    fee
  }
  consumed {
    hours
    fee
  }
  allocated {
    hours
    fee
  }
  invoicedFee
}
    `;
export const RealizationTotalFieldsFragmentDoc = gql`
    fragment RealizationTotalFields on RealizationTotals {
  billableFees
  invoicedFees
  realizationRate
}
    `;
export const RealizationTableDataFragmentDoc = gql`
    fragment RealizationTableData on RealizationTableEntryData {
  billableFees
  invoicedFees
  rangeEnd
  rangeStart
  realizationRate
}
    `;
export const RealizationProjectTableDataFragmentDoc = gql`
    fragment RealizationProjectTableData on RealizationProjectEntry {
  billableFees
  invoicedFees
  projectName
  realizationRate
}
    `;
export const UtilizationTotalsFragmentDoc = gql`
    fragment UtilizationTotals on UtilizationTotals {
  billableHours
  nonBillableHours
  totalHours
  utilizationRate
}
    `;
export const UtilizationTableDataFragmentDoc = gql`
    fragment UtilizationTableData on UtilizationTableEntryData {
  billableHours
  nonBillableHours
  rangeEnd
  rangeStart
  totalHours
  utilizationRate
}
    `;
export const UtilizationPeopleFragmentDoc = gql`
    fragment UtilizationPeople on UtilizationProfileEntry {
  billableHours
  fname
  id
  lname
  nonBillableHours
  slug
  targetRate
  totalHours
  utilizationRate
}
    `;
export const ProfitForecastEntriesFragmentDoc = gql`
    fragment ProfitForecastEntries on ProfitForecastEntryType {
  consultantCost
  consultantRevenue
  fixedRevenue
  hourlyRevenue
  margin
  month
  overheadCost
  projectedCost
  projectedProfit
  projectedRevenue
  wageCost
}
    `;
export const QboProfitReportEntryFragmentDoc = gql`
    fragment QboProfitReportEntry on ProfitProfitQBOVersionEntryType {
  consultantRevenue
  directCosts
  fixedRevenue
  hourlyRevenue
  margin
  month
  nonOperatingCosts
  operatingCosts
  otherRevenue
  totalCosts
  totalProfit
  totalRevenue
}
    `;
export const NonQboProfitReportEntryFragmentDoc = gql`
    fragment nonQboProfitReportEntry on ProfitProfitNonQBOVersionEntryType {
  consultantCosts
  consultantRevenue
  fixedRevenue
  hourlyRevenue
  margin
  month
  otherCosts
  otherRevenue
  overheadCosts
  totalCosts
  totalProfit
  totalRevenue
  wageCosts
}
    `;
export const ProjectFinancialsEntryItemFragmentDoc = gql`
    fragment ProjectFinancialsEntryItem on ProjectFinancialsEntryType {
  billed
  billedPercent
  consultantFee
  cost
  logged
  loggedBillableBudgetConsumed
  loggedBillableHours
  loggedNonBillableBudgetConsumed
  loggedNonBillableHours
  loggedPercent
  margin
  markup
  name
  open
  paid
  pastDue
  planned
  profit
  remaining
  revenue
  status
  unbilled
  warning
  children {
    billed
    billedPercent
    consultantFee
    cost
    logged
    loggedPercent
    margin
    markup
    markupPercent
    name
    open
    paid
    pastDue
    planned
    profit
    remaining
    revenue
    unbilled
  }
}
    `;
export const ProjectFinancialsOtherEntryItemFragmentDoc = gql`
    fragment ProjectFinancialsOtherEntryItem on ProjectFinancialsOtherEntryType {
  billed
  description
  invoiceNumber
  invoiceSlug
  invoiceVersion
  item
  open
  paid
  pastDue
  quantity
  rate
}
    `;
export const ProjectFinancialsRetainerEntryItemFragmentDoc = gql`
    fragment ProjectFinancialsRetainerEntryItem on ProjectFinancialsRetainerEntryType {
  billed
  description
  invoiceIssueDate
  invoiceNumber
  invoicePaidDate
  invoiceSlug
  invoiceVersion
  item
}
    `;
export const AppDefaultsQboAccountFragmentDoc = gql`
    fragment AppDefaultsQboAccount on QBOAccount {
  id
  name
  quickbooksId
  parentQuickbooksId
}
    `;
export const AppDefaultsQboItemFragmentDoc = gql`
    fragment AppDefaultsQboItem on QBOItem {
  id
  name
  active
  itemType
  quickbooksId
  parentQuickbooksId
  qboInstance {
    id
  }
}
    `;
export const AppDefaultsQboInstanceFragmentDoc = gql`
    fragment AppDefaultsQboInstance on QBOInstance {
  id
  companyName
  connected
  qboExpenseAccounts {
    ...AppDefaultsQboAccount
  }
  qboItems {
    ...AppDefaultsQboItem
  }
}
    ${AppDefaultsQboAccountFragmentDoc}
${AppDefaultsQboItemFragmentDoc}`;
export const AppDefaultsTimesheetLockingFragmentDoc = gql`
    fragment AppDefaultsTimesheetLocking on TimesheetLock {
  days
  isLocked
  time
}
    `;
export const AppDefaultsTimesheetReminderFragmentDoc = gql`
    fragment AppDefaultsTimesheetReminder on TimesheetReminder {
  isOn
  day
  time
  note
}
    `;
export const AppDefaultsOverheadItemFragmentDoc = gql`
    fragment AppDefaultsOverheadItem on Overhead {
  id
  name
  availableHours
  timeOffPolicies {
    id
    name
    slug
  }
}
    `;
export const AppDefaultsActivityItemFragmentDoc = gql`
    fragment AppDefaultsActivityItem on Activity {
  id
  name
  isBillable
  ajeraIdentifier {
    id
    key
    typeKey
    recordId
    recordType
  }
}
    `;
export const AppDefaultsProjectCategoryFragmentDoc = gql`
    fragment AppDefaultsProjectCategory on Category {
  id
  name
}
    `;
export const AppDefaultsDraftInvoiceFragmentDoc = gql`
    fragment AppDefaultsDraftInvoice on Invoice {
  id
  number
  slug
}
    `;
export const AppDefaultsTaxRateFragmentDoc = gql`
    fragment AppDefaultsTaxRate on TaxRate {
  id
  name
  percent
  draftInvoices {
    ...AppDefaultsDraftInvoice
  }
}
    ${AppDefaultsDraftInvoiceFragmentDoc}`;
export const AppDefaultsInvoiceTermFragmentDoc = gql`
    fragment AppDefaultsInvoiceTerm on Term {
  id
  name
  days
  active
}
    `;
export const AppDefaultsQuickbooksItemFragmentDoc = gql`
    fragment AppDefaultsQuickbooksItem on QuickbooksItem {
  description
  id
  active
  itemType
  name
  quickbooksId
  quickbooksParentRef
}
    `;
export const AppDefaultsConsultantTemplateFragmentDoc = gql`
    fragment AppDefaultsConsultantTemplate on ConsultantTemplate {
  id
  name
  quickbooksItem {
    ...AppDefaultsQuickbooksItem
  }
}
    ${AppDefaultsQuickbooksItemFragmentDoc}`;
export const AppDefaultsExpenseCategoryFragmentDoc = gql`
    fragment AppDefaultsExpenseCategory on ExpenseCategory {
  id
  name
  markupPercentage
  dependencyCounts {
    invoices
    expenses
  }
  expenseCategoryQboItems {
    id
  }
}
    `;
export const AppDefaultsPhaseTemplateFragmentDoc = gql`
    fragment AppDefaultsPhaseTemplate on PhaseTemplate {
  id
  name
  abbreviation
  feeType
}
    `;
export const OrganizationSettingsAddressFragmentDoc = gql`
    fragment OrganizationSettingsAddress on Address {
  line1
  line2
  city
  state
  zipcode
  country
}
    `;
export const OrganizationSettingsCurrencyFragmentDoc = gql`
    fragment OrganizationSettingsCurrency on Currency {
  id
  name
  symbol
  code
  decimalDigits
}
    `;
export const OrganizationSettingsLocaleFragmentDoc = gql`
    fragment OrganizationSettingsLocale on Locale {
  id
  name
  tag
}
    `;
export const QuickbooksSettingsQboItemFragmentDoc = gql`
    fragment QuickbooksSettingsQboItem on QBOItem {
  id
  name
  active
  itemType
  quickbooksId
  parentQuickbooksId
  qboInstance {
    id
  }
}
    `;
export const QuickbooksSettingsQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsQboInstance on QBOInstance {
  id
  companyName
  connected
  lastConnectedAt
  qboItems {
    ...QuickbooksSettingsQboItem
  }
}
    ${QuickbooksSettingsQboItemFragmentDoc}`;
export const QuickbooksSettingsConsultantContactsQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsConsultantContactsQboInstance on QBOInstance {
  id
  matchedQboVendorsCount
}
    `;
export const QuickbooksSettingsClientsQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsClientsQboInstance on QBOInstance {
  id
  matchedQboCustomersCount
}
    `;
export const QuickbooksSettingsQuickbooksItemFragmentDoc = gql`
    fragment QuickbooksSettingsQuickbooksItem on QuickbooksItem {
  id
  name
  itemType
  active
  quickbooksId
  quickbooksParentRef
}
    `;
export const QuickbooksSettingsOrganizationConsultantMarkupSettingFragmentDoc = gql`
    fragment QuickbooksSettingsOrganizationConsultantMarkupSetting on OrganizationSetting {
  id
  applyConsultantMappingForMarkup
  consultantMarkupQuickbooksServiceItem {
    ...QuickbooksSettingsQuickbooksItem
  }
}
    ${QuickbooksSettingsQuickbooksItemFragmentDoc}`;
export const QuickbooksSettingsConsultantTypesConsultantTemplateFragmentDoc = gql`
    fragment QuickbooksSettingsConsultantTypesConsultantTemplate on ConsultantTemplate {
  id
  name
  quickbooksItem {
    ...QuickbooksSettingsQuickbooksItem
  }
}
    ${QuickbooksSettingsQuickbooksItemFragmentDoc}`;
export const QuickbooksSettingsOrganizationCostsSettingFragmentDoc = gql`
    fragment QuickbooksSettingsOrganizationCostsSetting on OrganizationSetting {
  id
  costsImportStartDate
}
    `;
export const QboCostFragmentDoc = gql`
    fragment QBOCost on QBOJournalEntryCost {
  id
  qboVendor {
    displayName
    quickbooksVendorId
  }
  qboCustomer {
    displayName
  }
  description
  qboAccount {
    name
    id
  }
  transactionDate
  amount
  costCategory
  project {
    id
    name
  }
}
    `;
export const QuickbooksSettingsQboAccountFragmentDoc = gql`
    fragment QuickbooksSettingsQboAccount on QBOAccount {
  id
  name
  active
  quickbooksId
  parentQuickbooksId
}
    `;
export const QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragmentDoc = gql`
    fragment QuickbooksSettingsExpenseCategoriesAccountsExpenseCategory on ExpenseCategory {
  id
  name
  qboExpenseAccount {
    ...QuickbooksSettingsQboAccount
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export const QuickbooksSettingsExpenseCategoriesAccountsQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsExpenseCategoriesAccountsQboInstance on QBOInstance {
  qboExpenseAccounts {
    ...QuickbooksSettingsQboAccount
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export const QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragmentDoc = gql`
    fragment QuickbooksSettingsExpenseCategoriesServicesExpenseCategory on ExpenseCategory {
  id
  name
  expenseCategoryQboItems {
    id
    mapToPhaseQbItem
    qboInstance {
      id
    }
    qboItem {
      ...QuickbooksSettingsQboItem
    }
  }
}
    ${QuickbooksSettingsQboItemFragmentDoc}`;
export const QuickbooksSettingsExpenseTypesQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsExpenseTypesQboInstance on QBOInstance {
  qboAccountPayableAccounts {
    ...QuickbooksSettingsQboAccount
  }
  qboPaymentAccounts {
    ...QuickbooksSettingsQboAccount
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export const QuickbooksSettingsExpenseTypesSettingFragmentDoc = gql`
    fragment QuickbooksSettingsExpenseTypesSetting on OrganizationSetting {
  reimbursableExpensesAccount {
    ...QuickbooksSettingsQboAccount
  }
  nonReimbursableExpensesAccount {
    ...QuickbooksSettingsQboAccount
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export const QuickbooksSettingsQboTermFragmentDoc = gql`
    fragment QuickbooksSettingsQBOTerm on QBOTerm {
  id
  name
  active
  dueDays
}
    `;
export const QuickbooksSettingsPhaseTypesPhaseTemplateFragmentDoc = gql`
    fragment QuickbooksSettingsPhaseTypesPhaseTemplate on PhaseTemplate {
  id
  name
  feeType
  qboItems {
    ...QuickbooksSettingsQboItem
  }
}
    ${QuickbooksSettingsQboItemFragmentDoc}`;
export const QuickbooksSettingsRetainerFragmentDoc = gql`
    fragment QuickbooksSettingsRetainer on Retainer {
  id
  qboItem {
    ...QuickbooksSettingsQboItem
  }
}
    ${QuickbooksSettingsQboItemFragmentDoc}`;
export const QuickbooksSettingsTaxesQboInstanceFragmentDoc = gql`
    fragment QuickbooksSettingsTaxesQboInstance on QBOInstance {
  qboTaxMethod
}
    `;
export const SettingsPolicyProfileFragmentDoc = gql`
    fragment SettingsPolicyProfile on Profile {
  id
  initials
  fname
  lname
  image
  slug
  title
}
    `;
export const SettingsPolicyFragmentDoc = gql`
    fragment SettingsPolicy on Policy {
  id
  isAdmin
  name
  slug
  permissions {
    ...CurrentProfilePermissions
  }
  profiles {
    ...SettingsPolicyProfile
  }
}
    ${CurrentProfilePermissionsFragmentDoc}
${SettingsPolicyProfileFragmentDoc}`;
export const StaffingTimesheetFragmentDoc = gql`
    fragment StaffingTimesheet on Timesheet {
  id
  beginDate
  hoursAllocated
  hoursAllotted
  overheadHours
  overheadHoursAllocated
  timeOffRequestHours
  timeOffHolidayHours
}
    `;
export const StaffingProfileFragmentDoc = gql`
    fragment StaffingProfile on Profile {
  id
  name
  image
  initials
  title
  timesheets(beginDate: $beginDate, endDate: $endDate) {
    ...StaffingTimesheet
  }
}
    ${StaffingTimesheetFragmentDoc}`;
export const StaffingEntryGroupFragmentDoc = gql`
    fragment StaffingEntryGroup on EntryGroup {
  id
  allocatedByUser
  allocation
  timesheet {
    id
    beginDate
    hoursAllocated
  }
  entries {
    id
    hours
  }
}
    `;
export const StaffingOverheadFragmentDoc = gql`
    fragment StaffingOverhead on Overhead {
  id
  name
  staffingEntryGroups(
    profileId: $profileId
    beginDate: $beginDate
    endDate: $endDate
  ) {
    ...StaffingEntryGroup
  }
}
    ${StaffingEntryGroupFragmentDoc}`;
export const StaffingRoleFragmentDoc = gql`
    fragment StaffingRole on Role {
  id
  name
  isOverstaffed
  hours
  hoursAllocated
  hoursLogged
  exclusivelyHasUserAllocations
  rateTable {
    currentRate {
      amount
    }
  }
  staffingEntryGroups {
    ...StaffingEntryGroup
  }
  profile {
    id
    name
  }
  phase {
    id
    beginDate
    endDate
    name
    abbreviation
    budget
  }
  project {
    id
    name
    number
    color
    slug
    status
  }
}
    ${StaffingEntryGroupFragmentDoc}`;
export const AssigneeProfileFragmentDoc = gql`
    fragment AssigneeProfile on Profile {
  name
  id
  initials
  slug
  image
}
    `;
export const ListedTaskFragmentDoc = gql`
    fragment ListedTask on Task {
  completedAt
  description
  dueDate
  name
  id
  status
  phase {
    id
    name
    abbreviation
  }
  milestone {
    id
    name
  }
  project {
    id
    color
    name
    number
    slug
  }
  assignees {
    ...AssigneeProfile
  }
}
    ${AssigneeProfileFragmentDoc}`;
export const TaskFilterProjectFragmentDoc = gql`
    fragment TaskFilterProject on Project {
  color
  id
  name
  number
  status
}
    `;
export const DraftInvoiceFragmentDoc = gql`
    fragment DraftInvoice on Invoice {
  id
  number
  slug
}
    `;
export const TimeOffAssignableProfileFragmentDoc = gql`
    fragment TimeOffAssignableProfile on Profile {
  id
  fullName
  image
  slug
  initials
  slug
}
    `;
export const TimeOffRequestDayFragmentDoc = gql`
    fragment TimeOffRequestDay on TimeOffRequestDay {
  id
  date
  hours
}
    `;
export const TimeOffRequestFragmentDoc = gql`
    fragment TimeOffRequest on TimeOffRequest {
  id
  openedAt
  deniedAt
  approvedAt
  cancelledAt
  requestsBeginning
  requestsEnd
  hours
  note
  status
  timeOffPolicyAssignment {
    id
    profile {
      id
      fullName
      slug
    }
    totalHoursAccrued
    balance
    timeOffPolicy {
      id
      name
      slug
      timeOffType
    }
  }
  timeOffPolicy {
    id
    timeOffType
    slug
    name
  }
  status
  timeOffRequestDays {
    ...TimeOffRequestDay
  }
  adminProfile {
    id
    fullName
  }
}
    ${TimeOffRequestDayFragmentDoc}`;
export const TimeOffAccrualFragmentDoc = gql`
    fragment TimeOffAccrual on TimeOffAccrual {
  id
  hours
  note
  accrualType
  adminProfile {
    id
    fullName
  }
}
    `;
export const TimeOffPolicyAssignmentFragmentDoc = gql`
    fragment TimeOffPolicyAssignment on TimeOffPolicyAssignment {
  id
  profile {
    ...TimeOffAssignableProfile
  }
  balance
  totalHoursAccrued
  timeOffPolicy {
    id
    name
    timeOffType
    slug
    deletedAt
    maxBalance
  }
  timeOffRequests {
    ...TimeOffRequest
  }
  timeOffAccruals {
    ...TimeOffAccrual
  }
}
    ${TimeOffAssignableProfileFragmentDoc}
${TimeOffRequestFragmentDoc}
${TimeOffAccrualFragmentDoc}`;
export const TimeOffHolidayResultFragmentDoc = gql`
    fragment TimeOffHolidayResult on TimeOffHoliday {
  id
  name
  date
  hours
  standardHoliday {
    id
    displayName
  }
}
    `;
export const TimeOffHolidayPolicyResultFragmentDoc = gql`
    fragment TimeOffHolidayPolicyResult on TimeOffHolidayPolicy {
  id
  name
  description
  slug
  accrualMethod
  annualRolloverLimit
  hoursPerYear
  earnedRate
  maxBalance
  overhead {
    id
    name
  }
  timeOffType
  slug
  deletedAt
  timeOffPolicyAssignments {
    ...TimeOffPolicyAssignment
  }
  eligibleProfiles {
    ...TimeOffAssignableProfile
  }
  ineligibleProfiles {
    ...TimeOffAssignableProfile
  }
  timeOffHolidaysByYear {
    year
    holidays {
      ...TimeOffHolidayResult
    }
  }
}
    ${TimeOffPolicyAssignmentFragmentDoc}
${TimeOffAssignableProfileFragmentDoc}
${TimeOffHolidayResultFragmentDoc}`;
export const TimeOffPolicyResultFragmentDoc = gql`
    fragment TimeOffPolicyResult on TimeOffPolicy {
  id
  name
  description
  slug
  accrualMethod
  annualRolloverLimit
  hoursPerYear
  earnedRate
  maxBalance
  autoApprove
  reviewRecipients {
    id
    fullName
  }
  overhead {
    id
    name
  }
  timeOffType
  slug
  deletedAt
  timeOffPolicyAssignments {
    ...TimeOffPolicyAssignment
  }
  eligibleProfiles {
    ...TimeOffAssignableProfile
  }
  ineligibleProfiles {
    ...TimeOffAssignableProfile
  }
}
    ${TimeOffPolicyAssignmentFragmentDoc}
${TimeOffAssignableProfileFragmentDoc}`;
export const TimeOffOverheadItemFragmentDoc = gql`
    fragment TimeOffOverheadItem on Overhead {
  id
  name
  availableHours
  timeOffPolicies {
    id
    name
    slug
  }
}
    `;
export const TimerEntryFragmentDoc = gql`
    fragment TimerEntry on Entry {
  id
  notes
  hours
  date
  project {
    id
    name
  }
  phase {
    id
    name
  }
  role {
    id
    name
  }
  activity {
    id
    name
  }
}
    `;
export const EnableMfaDocument = gql`
    mutation EnableMfa($profileId: ID!) {
  admin {
    enableMfa(input: {profileId: $profileId}) {
      mfaQrCodeSvg
    }
  }
}
    `;
export type EnableMfaMutationFn = Apollo.MutationFunction<EnableMfaMutation, EnableMfaMutationVariables>;

/**
 * __useEnableMfaMutation__
 *
 * To run a mutation, you first call `useEnableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMfaMutation, { data, loading, error }] = useEnableMfaMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useEnableMfaMutation(baseOptions?: Apollo.MutationHookOptions<EnableMfaMutation, EnableMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableMfaMutation, EnableMfaMutationVariables>(EnableMfaDocument, options);
      }
export type EnableMfaMutationHookResult = ReturnType<typeof useEnableMfaMutation>;
export type EnableMfaMutationResult = Apollo.MutationResult<EnableMfaMutation>;
export type EnableMfaMutationOptions = Apollo.BaseMutationOptions<EnableMfaMutation, EnableMfaMutationVariables>;
export const ClientInfoDocument = gql`
    query clientInfo {
  me {
    isClient
    fname
    lname
    fullName
    email
    isTeamMember
  }
}
    `;

/**
 * __useClientInfoQuery__
 *
 * To run a query within a React component, call `useClientInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientInfoQuery(baseOptions?: Apollo.QueryHookOptions<ClientInfoQuery, ClientInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientInfoQuery, ClientInfoQueryVariables>(ClientInfoDocument, options);
      }
export function useClientInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInfoQuery, ClientInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientInfoQuery, ClientInfoQueryVariables>(ClientInfoDocument, options);
        }
export type ClientInfoQueryHookResult = ReturnType<typeof useClientInfoQuery>;
export type ClientInfoLazyQueryHookResult = ReturnType<typeof useClientInfoLazyQuery>;
export type ClientInfoQueryResult = Apollo.QueryResult<ClientInfoQuery, ClientInfoQueryVariables>;
export const CreateClientLoginLinkDocument = gql`
    mutation CreateClientLoginLink($input: CreateClientLoginLinkInput!) {
  createClientLoginLink(input: $input) {
    status
  }
}
    `;
export type CreateClientLoginLinkMutationFn = Apollo.MutationFunction<CreateClientLoginLinkMutation, CreateClientLoginLinkMutationVariables>;

/**
 * __useCreateClientLoginLinkMutation__
 *
 * To run a mutation, you first call `useCreateClientLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientLoginLinkMutation, { data, loading, error }] = useCreateClientLoginLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientLoginLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientLoginLinkMutation, CreateClientLoginLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientLoginLinkMutation, CreateClientLoginLinkMutationVariables>(CreateClientLoginLinkDocument, options);
      }
export type CreateClientLoginLinkMutationHookResult = ReturnType<typeof useCreateClientLoginLinkMutation>;
export type CreateClientLoginLinkMutationResult = Apollo.MutationResult<CreateClientLoginLinkMutation>;
export type CreateClientLoginLinkMutationOptions = Apollo.BaseMutationOptions<CreateClientLoginLinkMutation, CreateClientLoginLinkMutationVariables>;
export const CreateClientAccountDocument = gql`
    mutation CreateClientAccount($input: CreateClientAccountInput!) {
  createClientAccount(input: $input) {
    status
  }
}
    `;
export type CreateClientAccountMutationFn = Apollo.MutationFunction<CreateClientAccountMutation, CreateClientAccountMutationVariables>;

/**
 * __useCreateClientAccountMutation__
 *
 * To run a mutation, you first call `useCreateClientAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientAccountMutation, { data, loading, error }] = useCreateClientAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientAccountMutation, CreateClientAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientAccountMutation, CreateClientAccountMutationVariables>(CreateClientAccountDocument, options);
      }
export type CreateClientAccountMutationHookResult = ReturnType<typeof useCreateClientAccountMutation>;
export type CreateClientAccountMutationResult = Apollo.MutationResult<CreateClientAccountMutation>;
export type CreateClientAccountMutationOptions = Apollo.BaseMutationOptions<CreateClientAccountMutation, CreateClientAccountMutationVariables>;
export const DetachConsultantCompanyDocument = gql`
    mutation DetachConsultantCompany($id: ID!) {
  detachConsultantCompany(input: {id: $id}) {
    consultantCompany {
      id
    }
  }
}
    `;
export type DetachConsultantCompanyMutationFn = Apollo.MutationFunction<DetachConsultantCompanyMutation, DetachConsultantCompanyMutationVariables>;

/**
 * __useDetachConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useDetachConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachConsultantCompanyMutation, { data, loading, error }] = useDetachConsultantCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetachConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DetachConsultantCompanyMutation, DetachConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachConsultantCompanyMutation, DetachConsultantCompanyMutationVariables>(DetachConsultantCompanyDocument, options);
      }
export type DetachConsultantCompanyMutationHookResult = ReturnType<typeof useDetachConsultantCompanyMutation>;
export type DetachConsultantCompanyMutationResult = Apollo.MutationResult<DetachConsultantCompanyMutation>;
export type DetachConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<DetachConsultantCompanyMutation, DetachConsultantCompanyMutationVariables>;
export const QuickbooksVendorsToImportDocument = gql`
    query QuickbooksVendorsToImport {
  quickbooksVendorsToImport {
    ...VendorResult
  }
}
    ${VendorResultFragmentDoc}`;

/**
 * __useQuickbooksVendorsToImportQuery__
 *
 * To run a query within a React component, call `useQuickbooksVendorsToImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksVendorsToImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksVendorsToImportQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksVendorsToImportQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksVendorsToImportQuery, QuickbooksVendorsToImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksVendorsToImportQuery, QuickbooksVendorsToImportQueryVariables>(QuickbooksVendorsToImportDocument, options);
      }
export function useQuickbooksVendorsToImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksVendorsToImportQuery, QuickbooksVendorsToImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksVendorsToImportQuery, QuickbooksVendorsToImportQueryVariables>(QuickbooksVendorsToImportDocument, options);
        }
export type QuickbooksVendorsToImportQueryHookResult = ReturnType<typeof useQuickbooksVendorsToImportQuery>;
export type QuickbooksVendorsToImportLazyQueryHookResult = ReturnType<typeof useQuickbooksVendorsToImportLazyQuery>;
export type QuickbooksVendorsToImportQueryResult = Apollo.QueryResult<QuickbooksVendorsToImportQuery, QuickbooksVendorsToImportQueryVariables>;
export const QuickbooksVendorsPotentialMatchingConsultantDocument = gql`
    query QuickbooksVendorsPotentialMatchingConsultant {
  quickbooksVendorsPotentialMatchingConsultants {
    consultantCompanyId
    qboVendorId
  }
}
    `;

/**
 * __useQuickbooksVendorsPotentialMatchingConsultantQuery__
 *
 * To run a query within a React component, call `useQuickbooksVendorsPotentialMatchingConsultantQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksVendorsPotentialMatchingConsultantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksVendorsPotentialMatchingConsultantQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksVendorsPotentialMatchingConsultantQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksVendorsPotentialMatchingConsultantQuery, QuickbooksVendorsPotentialMatchingConsultantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksVendorsPotentialMatchingConsultantQuery, QuickbooksVendorsPotentialMatchingConsultantQueryVariables>(QuickbooksVendorsPotentialMatchingConsultantDocument, options);
      }
export function useQuickbooksVendorsPotentialMatchingConsultantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksVendorsPotentialMatchingConsultantQuery, QuickbooksVendorsPotentialMatchingConsultantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksVendorsPotentialMatchingConsultantQuery, QuickbooksVendorsPotentialMatchingConsultantQueryVariables>(QuickbooksVendorsPotentialMatchingConsultantDocument, options);
        }
export type QuickbooksVendorsPotentialMatchingConsultantQueryHookResult = ReturnType<typeof useQuickbooksVendorsPotentialMatchingConsultantQuery>;
export type QuickbooksVendorsPotentialMatchingConsultantLazyQueryHookResult = ReturnType<typeof useQuickbooksVendorsPotentialMatchingConsultantLazyQuery>;
export type QuickbooksVendorsPotentialMatchingConsultantQueryResult = Apollo.QueryResult<QuickbooksVendorsPotentialMatchingConsultantQuery, QuickbooksVendorsPotentialMatchingConsultantQueryVariables>;
export const ProfileAssignedTasksOrWorkDocument = gql`
    query ProfileAssignedTasksOrWork($overdueTasksFilters: TaskFiltersInput, $thisWeekTasksFilters: TaskFiltersInput, $nextWeekTasksFilters: TaskFiltersInput) {
  currentProfile {
    id
    name
    overdueTasks: tasks(filters: $overdueTasksFilters) {
      totalCount
    }
    thisWeekTasks: tasks(filters: $thisWeekTasksFilters) {
      totalCount
    }
    nextWeekTasks: tasks(filters: $nextWeekTasksFilters) {
      totalCount
    }
    allTasks: tasks {
      totalCount
    }
    currentTimesheet {
      id
      beginDate
      entryGroups {
        ...AssignedWorkEntryGroup
      }
    }
    organization {
      id
      subscriptionForOrgOrDemoParent {
        id
        planName
      }
    }
  }
}
    ${AssignedWorkEntryGroupFragmentDoc}`;

/**
 * __useProfileAssignedTasksOrWorkQuery__
 *
 * To run a query within a React component, call `useProfileAssignedTasksOrWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileAssignedTasksOrWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileAssignedTasksOrWorkQuery({
 *   variables: {
 *      overdueTasksFilters: // value for 'overdueTasksFilters'
 *      thisWeekTasksFilters: // value for 'thisWeekTasksFilters'
 *      nextWeekTasksFilters: // value for 'nextWeekTasksFilters'
 *   },
 * });
 */
export function useProfileAssignedTasksOrWorkQuery(baseOptions?: Apollo.QueryHookOptions<ProfileAssignedTasksOrWorkQuery, ProfileAssignedTasksOrWorkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileAssignedTasksOrWorkQuery, ProfileAssignedTasksOrWorkQueryVariables>(ProfileAssignedTasksOrWorkDocument, options);
      }
export function useProfileAssignedTasksOrWorkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileAssignedTasksOrWorkQuery, ProfileAssignedTasksOrWorkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileAssignedTasksOrWorkQuery, ProfileAssignedTasksOrWorkQueryVariables>(ProfileAssignedTasksOrWorkDocument, options);
        }
export type ProfileAssignedTasksOrWorkQueryHookResult = ReturnType<typeof useProfileAssignedTasksOrWorkQuery>;
export type ProfileAssignedTasksOrWorkLazyQueryHookResult = ReturnType<typeof useProfileAssignedTasksOrWorkLazyQuery>;
export type ProfileAssignedTasksOrWorkQueryResult = Apollo.QueryResult<ProfileAssignedTasksOrWorkQuery, ProfileAssignedTasksOrWorkQueryVariables>;
export const BusinessPerformanceUtilizationRateDocument = gql`
    query BusinessPerformanceUtilizationRate($useCache: Boolean) {
  currentProfile {
    id
    organization {
      id
      businessPerformance {
        id
        utilization(useCache: $useCache) {
          ...OrganizationBusinessPerformanceMetric
        }
      }
    }
  }
}
    ${OrganizationBusinessPerformanceMetricFragmentDoc}`;

/**
 * __useBusinessPerformanceUtilizationRateQuery__
 *
 * To run a query within a React component, call `useBusinessPerformanceUtilizationRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPerformanceUtilizationRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPerformanceUtilizationRateQuery({
 *   variables: {
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useBusinessPerformanceUtilizationRateQuery(baseOptions?: Apollo.QueryHookOptions<BusinessPerformanceUtilizationRateQuery, BusinessPerformanceUtilizationRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessPerformanceUtilizationRateQuery, BusinessPerformanceUtilizationRateQueryVariables>(BusinessPerformanceUtilizationRateDocument, options);
      }
export function useBusinessPerformanceUtilizationRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessPerformanceUtilizationRateQuery, BusinessPerformanceUtilizationRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessPerformanceUtilizationRateQuery, BusinessPerformanceUtilizationRateQueryVariables>(BusinessPerformanceUtilizationRateDocument, options);
        }
export type BusinessPerformanceUtilizationRateQueryHookResult = ReturnType<typeof useBusinessPerformanceUtilizationRateQuery>;
export type BusinessPerformanceUtilizationRateLazyQueryHookResult = ReturnType<typeof useBusinessPerformanceUtilizationRateLazyQuery>;
export type BusinessPerformanceUtilizationRateQueryResult = Apollo.QueryResult<BusinessPerformanceUtilizationRateQuery, BusinessPerformanceUtilizationRateQueryVariables>;
export const BusinessPerformanceProjectedFirmCapacityDocument = gql`
    query BusinessPerformanceProjectedFirmCapacity($useCache: Boolean) {
  currentProfile {
    id
    organization {
      id
      businessPerformance {
        id
        projectedFirmCapacity(useCache: $useCache) {
          ...OrganizationBusinessPerformanceMetric
        }
      }
    }
  }
}
    ${OrganizationBusinessPerformanceMetricFragmentDoc}`;

/**
 * __useBusinessPerformanceProjectedFirmCapacityQuery__
 *
 * To run a query within a React component, call `useBusinessPerformanceProjectedFirmCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPerformanceProjectedFirmCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPerformanceProjectedFirmCapacityQuery({
 *   variables: {
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useBusinessPerformanceProjectedFirmCapacityQuery(baseOptions?: Apollo.QueryHookOptions<BusinessPerformanceProjectedFirmCapacityQuery, BusinessPerformanceProjectedFirmCapacityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessPerformanceProjectedFirmCapacityQuery, BusinessPerformanceProjectedFirmCapacityQueryVariables>(BusinessPerformanceProjectedFirmCapacityDocument, options);
      }
export function useBusinessPerformanceProjectedFirmCapacityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessPerformanceProjectedFirmCapacityQuery, BusinessPerformanceProjectedFirmCapacityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessPerformanceProjectedFirmCapacityQuery, BusinessPerformanceProjectedFirmCapacityQueryVariables>(BusinessPerformanceProjectedFirmCapacityDocument, options);
        }
export type BusinessPerformanceProjectedFirmCapacityQueryHookResult = ReturnType<typeof useBusinessPerformanceProjectedFirmCapacityQuery>;
export type BusinessPerformanceProjectedFirmCapacityLazyQueryHookResult = ReturnType<typeof useBusinessPerformanceProjectedFirmCapacityLazyQuery>;
export type BusinessPerformanceProjectedFirmCapacityQueryResult = Apollo.QueryResult<BusinessPerformanceProjectedFirmCapacityQuery, BusinessPerformanceProjectedFirmCapacityQueryVariables>;
export const BusinessPerformanceProjectedOperatingProfitDocument = gql`
    query BusinessPerformanceProjectedOperatingProfit($useCache: Boolean) {
  currentProfile {
    id
    organization {
      id
      businessPerformance {
        id
        projectedOperatingProfit(useCache: $useCache) {
          ...OrganizationBusinessPerformanceMetric
        }
      }
    }
  }
}
    ${OrganizationBusinessPerformanceMetricFragmentDoc}`;

/**
 * __useBusinessPerformanceProjectedOperatingProfitQuery__
 *
 * To run a query within a React component, call `useBusinessPerformanceProjectedOperatingProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPerformanceProjectedOperatingProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPerformanceProjectedOperatingProfitQuery({
 *   variables: {
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useBusinessPerformanceProjectedOperatingProfitQuery(baseOptions?: Apollo.QueryHookOptions<BusinessPerformanceProjectedOperatingProfitQuery, BusinessPerformanceProjectedOperatingProfitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessPerformanceProjectedOperatingProfitQuery, BusinessPerformanceProjectedOperatingProfitQueryVariables>(BusinessPerformanceProjectedOperatingProfitDocument, options);
      }
export function useBusinessPerformanceProjectedOperatingProfitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessPerformanceProjectedOperatingProfitQuery, BusinessPerformanceProjectedOperatingProfitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessPerformanceProjectedOperatingProfitQuery, BusinessPerformanceProjectedOperatingProfitQueryVariables>(BusinessPerformanceProjectedOperatingProfitDocument, options);
        }
export type BusinessPerformanceProjectedOperatingProfitQueryHookResult = ReturnType<typeof useBusinessPerformanceProjectedOperatingProfitQuery>;
export type BusinessPerformanceProjectedOperatingProfitLazyQueryHookResult = ReturnType<typeof useBusinessPerformanceProjectedOperatingProfitLazyQuery>;
export type BusinessPerformanceProjectedOperatingProfitQueryResult = Apollo.QueryResult<BusinessPerformanceProjectedOperatingProfitQuery, BusinessPerformanceProjectedOperatingProfitQueryVariables>;
export const BusinessPerformanceEstimatedOperatingProfitDocument = gql`
    query BusinessPerformanceEstimatedOperatingProfit($useCache: Boolean) {
  currentProfile {
    id
    organization {
      id
      connectedToQbo
      businessPerformance {
        id
        estimatedOperatingProfit(useCache: $useCache) {
          ...OrganizationBusinessPerformanceMetric
        }
      }
    }
  }
}
    ${OrganizationBusinessPerformanceMetricFragmentDoc}`;

/**
 * __useBusinessPerformanceEstimatedOperatingProfitQuery__
 *
 * To run a query within a React component, call `useBusinessPerformanceEstimatedOperatingProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPerformanceEstimatedOperatingProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPerformanceEstimatedOperatingProfitQuery({
 *   variables: {
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useBusinessPerformanceEstimatedOperatingProfitQuery(baseOptions?: Apollo.QueryHookOptions<BusinessPerformanceEstimatedOperatingProfitQuery, BusinessPerformanceEstimatedOperatingProfitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessPerformanceEstimatedOperatingProfitQuery, BusinessPerformanceEstimatedOperatingProfitQueryVariables>(BusinessPerformanceEstimatedOperatingProfitDocument, options);
      }
export function useBusinessPerformanceEstimatedOperatingProfitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessPerformanceEstimatedOperatingProfitQuery, BusinessPerformanceEstimatedOperatingProfitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessPerformanceEstimatedOperatingProfitQuery, BusinessPerformanceEstimatedOperatingProfitQueryVariables>(BusinessPerformanceEstimatedOperatingProfitDocument, options);
        }
export type BusinessPerformanceEstimatedOperatingProfitQueryHookResult = ReturnType<typeof useBusinessPerformanceEstimatedOperatingProfitQuery>;
export type BusinessPerformanceEstimatedOperatingProfitLazyQueryHookResult = ReturnType<typeof useBusinessPerformanceEstimatedOperatingProfitLazyQuery>;
export type BusinessPerformanceEstimatedOperatingProfitQueryResult = Apollo.QueryResult<BusinessPerformanceEstimatedOperatingProfitQuery, BusinessPerformanceEstimatedOperatingProfitQueryVariables>;
export const ProjectsCountDocument = gql`
    query ProjectsCount {
  currentProfile {
    id
    organization {
      id
      projectsCount
    }
  }
}
    `;

/**
 * __useProjectsCountQuery__
 *
 * To run a query within a React component, call `useProjectsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsCountQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsCountQuery, ProjectsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsCountQuery, ProjectsCountQueryVariables>(ProjectsCountDocument, options);
      }
export function useProjectsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsCountQuery, ProjectsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsCountQuery, ProjectsCountQueryVariables>(ProjectsCountDocument, options);
        }
export type ProjectsCountQueryHookResult = ReturnType<typeof useProjectsCountQuery>;
export type ProjectsCountLazyQueryHookResult = ReturnType<typeof useProjectsCountLazyQuery>;
export type ProjectsCountQueryResult = Apollo.QueryResult<ProjectsCountQuery, ProjectsCountQueryVariables>;
export const OverBudgetPhasesDocument = gql`
    query OverBudgetPhases {
  currentProfile {
    id
    organization {
      id
      projectsWithOverBudgetPhases {
        id
      }
    }
  }
}
    `;

/**
 * __useOverBudgetPhasesQuery__
 *
 * To run a query within a React component, call `useOverBudgetPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverBudgetPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverBudgetPhasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverBudgetPhasesQuery(baseOptions?: Apollo.QueryHookOptions<OverBudgetPhasesQuery, OverBudgetPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverBudgetPhasesQuery, OverBudgetPhasesQueryVariables>(OverBudgetPhasesDocument, options);
      }
export function useOverBudgetPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverBudgetPhasesQuery, OverBudgetPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverBudgetPhasesQuery, OverBudgetPhasesQueryVariables>(OverBudgetPhasesDocument, options);
        }
export type OverBudgetPhasesQueryHookResult = ReturnType<typeof useOverBudgetPhasesQuery>;
export type OverBudgetPhasesLazyQueryHookResult = ReturnType<typeof useOverBudgetPhasesLazyQuery>;
export type OverBudgetPhasesQueryResult = Apollo.QueryResult<OverBudgetPhasesQuery, OverBudgetPhasesQueryVariables>;
export const OverStaffedPhasesDocument = gql`
    query OverStaffedPhases {
  currentProfile {
    id
    organization {
      id
      projectsWithOverStaffedPhases {
        id
      }
    }
  }
}
    `;

/**
 * __useOverStaffedPhasesQuery__
 *
 * To run a query within a React component, call `useOverStaffedPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverStaffedPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverStaffedPhasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverStaffedPhasesQuery(baseOptions?: Apollo.QueryHookOptions<OverStaffedPhasesQuery, OverStaffedPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverStaffedPhasesQuery, OverStaffedPhasesQueryVariables>(OverStaffedPhasesDocument, options);
      }
export function useOverStaffedPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverStaffedPhasesQuery, OverStaffedPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverStaffedPhasesQuery, OverStaffedPhasesQueryVariables>(OverStaffedPhasesDocument, options);
        }
export type OverStaffedPhasesQueryHookResult = ReturnType<typeof useOverStaffedPhasesQuery>;
export type OverStaffedPhasesLazyQueryHookResult = ReturnType<typeof useOverStaffedPhasesLazyQuery>;
export type OverStaffedPhasesQueryResult = Apollo.QueryResult<OverStaffedPhasesQuery, OverStaffedPhasesQueryVariables>;
export const OverCapacityProfilesDocument = gql`
    query OverCapacityProfiles {
  currentProfile {
    id
    organization {
      id
      overCapacityProfiles {
        id
      }
    }
  }
}
    `;

/**
 * __useOverCapacityProfilesQuery__
 *
 * To run a query within a React component, call `useOverCapacityProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverCapacityProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverCapacityProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOverCapacityProfilesQuery(baseOptions?: Apollo.QueryHookOptions<OverCapacityProfilesQuery, OverCapacityProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverCapacityProfilesQuery, OverCapacityProfilesQueryVariables>(OverCapacityProfilesDocument, options);
      }
export function useOverCapacityProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverCapacityProfilesQuery, OverCapacityProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverCapacityProfilesQuery, OverCapacityProfilesQueryVariables>(OverCapacityProfilesDocument, options);
        }
export type OverCapacityProfilesQueryHookResult = ReturnType<typeof useOverCapacityProfilesQuery>;
export type OverCapacityProfilesLazyQueryHookResult = ReturnType<typeof useOverCapacityProfilesLazyQuery>;
export type OverCapacityProfilesQueryResult = Apollo.QueryResult<OverCapacityProfilesQuery, OverCapacityProfilesQueryVariables>;
export const OrganizationInvoicesDocument = gql`
    query OrganizationInvoices($filters: InvoiceFiltersInput) {
  invoices(filters: $filters) {
    totalBilled
    totalCount
  }
}
    `;

/**
 * __useOrganizationInvoicesQuery__
 *
 * To run a query within a React component, call `useOrganizationInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganizationInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>(OrganizationInvoicesDocument, options);
      }
export function useOrganizationInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>(OrganizationInvoicesDocument, options);
        }
export type OrganizationInvoicesQueryHookResult = ReturnType<typeof useOrganizationInvoicesQuery>;
export type OrganizationInvoicesLazyQueryHookResult = ReturnType<typeof useOrganizationInvoicesLazyQuery>;
export type OrganizationInvoicesQueryResult = Apollo.QueryResult<OrganizationInvoicesQuery, OrganizationInvoicesQueryVariables>;
export const OrganizationUnpaidConsultantBillsDocument = gql`
    query OrganizationUnpaidConsultantBills($filters: ConsultantBillFiltersInput) {
  consultantBills(filters: $filters) {
    totalBilled
    totalCount
  }
}
    `;

/**
 * __useOrganizationUnpaidConsultantBillsQuery__
 *
 * To run a query within a React component, call `useOrganizationUnpaidConsultantBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUnpaidConsultantBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUnpaidConsultantBillsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganizationUnpaidConsultantBillsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationUnpaidConsultantBillsQuery, OrganizationUnpaidConsultantBillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationUnpaidConsultantBillsQuery, OrganizationUnpaidConsultantBillsQueryVariables>(OrganizationUnpaidConsultantBillsDocument, options);
      }
export function useOrganizationUnpaidConsultantBillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUnpaidConsultantBillsQuery, OrganizationUnpaidConsultantBillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationUnpaidConsultantBillsQuery, OrganizationUnpaidConsultantBillsQueryVariables>(OrganizationUnpaidConsultantBillsDocument, options);
        }
export type OrganizationUnpaidConsultantBillsQueryHookResult = ReturnType<typeof useOrganizationUnpaidConsultantBillsQuery>;
export type OrganizationUnpaidConsultantBillsLazyQueryHookResult = ReturnType<typeof useOrganizationUnpaidConsultantBillsLazyQuery>;
export type OrganizationUnpaidConsultantBillsQueryResult = Apollo.QueryResult<OrganizationUnpaidConsultantBillsQuery, OrganizationUnpaidConsultantBillsQueryVariables>;
export const OrganizationUnbilledAmountDocument = gql`
    query OrganizationUnbilledAmount($filters: ProjectsUnbilledFiltersInput!, $useCache: Boolean, $timesheetParams: SearchableTimesheetsParametersInput!) {
  searchableTimesheets(parameters: $timesheetParams) {
    id
  }
  reports {
    projectsUnbilled(filters: $filters) {
      totals {
        currentUnbilled(useCache: $useCache)
      }
    }
  }
}
    `;

/**
 * __useOrganizationUnbilledAmountQuery__
 *
 * To run a query within a React component, call `useOrganizationUnbilledAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUnbilledAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUnbilledAmountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      useCache: // value for 'useCache'
 *      timesheetParams: // value for 'timesheetParams'
 *   },
 * });
 */
export function useOrganizationUnbilledAmountQuery(baseOptions: Apollo.QueryHookOptions<OrganizationUnbilledAmountQuery, OrganizationUnbilledAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationUnbilledAmountQuery, OrganizationUnbilledAmountQueryVariables>(OrganizationUnbilledAmountDocument, options);
      }
export function useOrganizationUnbilledAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUnbilledAmountQuery, OrganizationUnbilledAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationUnbilledAmountQuery, OrganizationUnbilledAmountQueryVariables>(OrganizationUnbilledAmountDocument, options);
        }
export type OrganizationUnbilledAmountQueryHookResult = ReturnType<typeof useOrganizationUnbilledAmountQuery>;
export type OrganizationUnbilledAmountLazyQueryHookResult = ReturnType<typeof useOrganizationUnbilledAmountLazyQuery>;
export type OrganizationUnbilledAmountQueryResult = Apollo.QueryResult<OrganizationUnbilledAmountQuery, OrganizationUnbilledAmountQueryVariables>;
export const ProjectsByIdDocument = gql`
    query ProjectsById($ids: [ID!]) {
  searchableProjects(parameters: {ids: $ids}) {
    ...RecentProject
  }
}
    ${RecentProjectFragmentDoc}`;

/**
 * __useProjectsByIdQuery__
 *
 * To run a query within a React component, call `useProjectsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProjectsByIdQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsByIdQuery, ProjectsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsByIdQuery, ProjectsByIdQueryVariables>(ProjectsByIdDocument, options);
      }
export function useProjectsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsByIdQuery, ProjectsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsByIdQuery, ProjectsByIdQueryVariables>(ProjectsByIdDocument, options);
        }
export type ProjectsByIdQueryHookResult = ReturnType<typeof useProjectsByIdQuery>;
export type ProjectsByIdLazyQueryHookResult = ReturnType<typeof useProjectsByIdLazyQuery>;
export type ProjectsByIdQueryResult = Apollo.QueryResult<ProjectsByIdQuery, ProjectsByIdQueryVariables>;
export const RecentProjectsDocument = gql`
    query RecentProjects($first: Int, $sortBy: SearchableProjectsSortInput) {
  searchableProjects(parameters: {limit: $first, sortBy: $sortBy}) {
    ...RecentProject
  }
}
    ${RecentProjectFragmentDoc}`;

/**
 * __useRecentProjectsQuery__
 *
 * To run a query within a React component, call `useRecentProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useRecentProjectsQuery(baseOptions?: Apollo.QueryHookOptions<RecentProjectsQuery, RecentProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentProjectsQuery, RecentProjectsQueryVariables>(RecentProjectsDocument, options);
      }
export function useRecentProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentProjectsQuery, RecentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentProjectsQuery, RecentProjectsQueryVariables>(RecentProjectsDocument, options);
        }
export type RecentProjectsQueryHookResult = ReturnType<typeof useRecentProjectsQuery>;
export type RecentProjectsLazyQueryHookResult = ReturnType<typeof useRecentProjectsLazyQuery>;
export type RecentProjectsQueryResult = Apollo.QueryResult<RecentProjectsQuery, RecentProjectsQueryVariables>;
export const ProfileTimesheetsDocument = gql`
    query ProfileTimesheets($thisWeekBeginDate: ISO8601Date!, $lastWeekBeginDate: ISO8601Date!) {
  currentProfile {
    id
    name
    slug
    thisWeekTimesheet: timesheet(beginDate: $thisWeekBeginDate) {
      ...TimesheetTotals
    }
    lastWeekTimesheet: timesheet(beginDate: $lastWeekBeginDate) {
      ...TimesheetTotals
    }
  }
}
    ${TimesheetTotalsFragmentDoc}`;

/**
 * __useProfileTimesheetsQuery__
 *
 * To run a query within a React component, call `useProfileTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTimesheetsQuery({
 *   variables: {
 *      thisWeekBeginDate: // value for 'thisWeekBeginDate'
 *      lastWeekBeginDate: // value for 'lastWeekBeginDate'
 *   },
 * });
 */
export function useProfileTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<ProfileTimesheetsQuery, ProfileTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileTimesheetsQuery, ProfileTimesheetsQueryVariables>(ProfileTimesheetsDocument, options);
      }
export function useProfileTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileTimesheetsQuery, ProfileTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileTimesheetsQuery, ProfileTimesheetsQueryVariables>(ProfileTimesheetsDocument, options);
        }
export type ProfileTimesheetsQueryHookResult = ReturnType<typeof useProfileTimesheetsQuery>;
export type ProfileTimesheetsLazyQueryHookResult = ReturnType<typeof useProfileTimesheetsLazyQuery>;
export type ProfileTimesheetsQueryResult = Apollo.QueryResult<ProfileTimesheetsQuery, ProfileTimesheetsQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods($slug: String) {
  invoice(slug: $slug) {
    id
    total
    ...InvoicePaymentMethodsFragment
    organization {
      id
      stripeAccount {
        ...OrganizationStripeAccountFragment
      }
    }
  }
}
    ${InvoicePaymentMethodsFragmentFragmentDoc}
${OrganizationStripeAccountFragmentFragmentDoc}`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodsQuery, PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<PaymentMethodsQuery, PaymentMethodsQueryVariables>;
export const UpdateInvoicePaymentOptionsDocument = gql`
    mutation UpdateInvoicePaymentOptions($data: UpdateInvoicePaymentOptionsInput!) {
  updateInvoicePaymentOptions(input: $data) {
    invoice {
      id
      ...InvoicePaymentMethodsFragment
    }
  }
}
    ${InvoicePaymentMethodsFragmentFragmentDoc}`;
export type UpdateInvoicePaymentOptionsMutationFn = Apollo.MutationFunction<UpdateInvoicePaymentOptionsMutation, UpdateInvoicePaymentOptionsMutationVariables>;

/**
 * __useUpdateInvoicePaymentOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicePaymentOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicePaymentOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicePaymentOptionsMutation, { data, loading, error }] = useUpdateInvoicePaymentOptionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoicePaymentOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicePaymentOptionsMutation, UpdateInvoicePaymentOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicePaymentOptionsMutation, UpdateInvoicePaymentOptionsMutationVariables>(UpdateInvoicePaymentOptionsDocument, options);
      }
export type UpdateInvoicePaymentOptionsMutationHookResult = ReturnType<typeof useUpdateInvoicePaymentOptionsMutation>;
export type UpdateInvoicePaymentOptionsMutationResult = Apollo.MutationResult<UpdateInvoicePaymentOptionsMutation>;
export type UpdateInvoicePaymentOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicePaymentOptionsMutation, UpdateInvoicePaymentOptionsMutationVariables>;
export const InvoiceCountDocument = gql`
    query InvoiceCount {
  invoices {
    totalCount
  }
}
    `;

/**
 * __useInvoiceCountQuery__
 *
 * To run a query within a React component, call `useInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceCountQuery(baseOptions?: Apollo.QueryHookOptions<InvoiceCountQuery, InvoiceCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceCountQuery, InvoiceCountQueryVariables>(InvoiceCountDocument, options);
      }
export function useInvoiceCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceCountQuery, InvoiceCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceCountQuery, InvoiceCountQueryVariables>(InvoiceCountDocument, options);
        }
export type InvoiceCountQueryHookResult = ReturnType<typeof useInvoiceCountQuery>;
export type InvoiceCountLazyQueryHookResult = ReturnType<typeof useInvoiceCountLazyQuery>;
export type InvoiceCountQueryResult = Apollo.QueryResult<InvoiceCountQuery, InvoiceCountQueryVariables>;
export const ReceivedInvoicesDocument = gql`
    query ReceivedInvoices($filters: ReceivedInvoicesFiltersInput, $sortBy: ReceivedInvoicesSortInput) {
  receivedInvoices(filters: $filters, sortBy: $sortBy) {
    invoices {
      nodes {
        emailAddress
        id
        invoice {
          id
          number
          organization {
            name
          }
          project {
            name
          }
          issueDate
          total
          status
          dueDate
          paidDate
          remainingBalance
          paymentUrl
          totalPaid
          invoicePayment {
            amount
            net
            applicationFee
            paymentType
            paymentCurrency
          }
          invoicePayments {
            id
            amount
            paidDate
          }
          allowBankTransfers
          allowCardPayments
          paymentIntentStatus
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    totals {
      pastDue {
        amount
        count
      }
      open {
        amount
        count
      }
      paid {
        amount
        count
      }
    }
  }
}
    `;

/**
 * __useReceivedInvoicesQuery__
 *
 * To run a query within a React component, call `useReceivedInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useReceivedInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedInvoicesQuery, ReceivedInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedInvoicesQuery, ReceivedInvoicesQueryVariables>(ReceivedInvoicesDocument, options);
      }
export function useReceivedInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedInvoicesQuery, ReceivedInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedInvoicesQuery, ReceivedInvoicesQueryVariables>(ReceivedInvoicesDocument, options);
        }
export type ReceivedInvoicesQueryHookResult = ReturnType<typeof useReceivedInvoicesQuery>;
export type ReceivedInvoicesLazyQueryHookResult = ReturnType<typeof useReceivedInvoicesLazyQuery>;
export type ReceivedInvoicesQueryResult = Apollo.QueryResult<ReceivedInvoicesQuery, ReceivedInvoicesQueryVariables>;
export const ReceivedInvoicesFiltersDocument = gql`
    query ReceivedInvoicesFilters {
  receivedInvoices {
    filters {
      organizations {
        id
        name
      }
      projects {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useReceivedInvoicesFiltersQuery__
 *
 * To run a query within a React component, call `useReceivedInvoicesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedInvoicesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedInvoicesFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReceivedInvoicesFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedInvoicesFiltersQuery, ReceivedInvoicesFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedInvoicesFiltersQuery, ReceivedInvoicesFiltersQueryVariables>(ReceivedInvoicesFiltersDocument, options);
      }
export function useReceivedInvoicesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedInvoicesFiltersQuery, ReceivedInvoicesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedInvoicesFiltersQuery, ReceivedInvoicesFiltersQueryVariables>(ReceivedInvoicesFiltersDocument, options);
        }
export type ReceivedInvoicesFiltersQueryHookResult = ReturnType<typeof useReceivedInvoicesFiltersQuery>;
export type ReceivedInvoicesFiltersLazyQueryHookResult = ReturnType<typeof useReceivedInvoicesFiltersLazyQuery>;
export type ReceivedInvoicesFiltersQueryResult = Apollo.QueryResult<ReceivedInvoicesFiltersQuery, ReceivedInvoicesFiltersQueryVariables>;
export const ReceivedInvoicesCountDocument = gql`
    query ReceivedInvoicesCount {
  receivedInvoices {
    invoices {
      totalCount
    }
  }
}
    `;

/**
 * __useReceivedInvoicesCountQuery__
 *
 * To run a query within a React component, call `useReceivedInvoicesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedInvoicesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedInvoicesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useReceivedInvoicesCountQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedInvoicesCountQuery, ReceivedInvoicesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReceivedInvoicesCountQuery, ReceivedInvoicesCountQueryVariables>(ReceivedInvoicesCountDocument, options);
      }
export function useReceivedInvoicesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedInvoicesCountQuery, ReceivedInvoicesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReceivedInvoicesCountQuery, ReceivedInvoicesCountQueryVariables>(ReceivedInvoicesCountDocument, options);
        }
export type ReceivedInvoicesCountQueryHookResult = ReturnType<typeof useReceivedInvoicesCountQuery>;
export type ReceivedInvoicesCountLazyQueryHookResult = ReturnType<typeof useReceivedInvoicesCountLazyQuery>;
export type ReceivedInvoicesCountQueryResult = Apollo.QueryResult<ReceivedInvoicesCountQuery, ReceivedInvoicesCountQueryVariables>;
export const InvoiceTotalsOrganizationDocument = gql`
    query InvoiceTotalsOrganization {
  currentProfile {
    organization {
      address {
        country
      }
      stripeAccount {
        id
      }
    }
  }
}
    `;

/**
 * __useInvoiceTotalsOrganizationQuery__
 *
 * To run a query within a React component, call `useInvoiceTotalsOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTotalsOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTotalsOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceTotalsOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<InvoiceTotalsOrganizationQuery, InvoiceTotalsOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceTotalsOrganizationQuery, InvoiceTotalsOrganizationQueryVariables>(InvoiceTotalsOrganizationDocument, options);
      }
export function useInvoiceTotalsOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceTotalsOrganizationQuery, InvoiceTotalsOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceTotalsOrganizationQuery, InvoiceTotalsOrganizationQueryVariables>(InvoiceTotalsOrganizationDocument, options);
        }
export type InvoiceTotalsOrganizationQueryHookResult = ReturnType<typeof useInvoiceTotalsOrganizationQuery>;
export type InvoiceTotalsOrganizationLazyQueryHookResult = ReturnType<typeof useInvoiceTotalsOrganizationLazyQuery>;
export type InvoiceTotalsOrganizationQueryResult = Apollo.QueryResult<InvoiceTotalsOrganizationQuery, InvoiceTotalsOrganizationQueryVariables>;
export const InvoiceTotalsDocument = gql`
    query InvoiceTotals($filters: InvoiceFiltersInput!) {
  reports {
    ...InvoiceTotals
  }
}
    ${InvoiceTotalsFragmentDoc}`;

/**
 * __useInvoiceTotalsQuery__
 *
 * To run a query within a React component, call `useInvoiceTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTotalsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInvoiceTotalsQuery(baseOptions: Apollo.QueryHookOptions<InvoiceTotalsQuery, InvoiceTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceTotalsQuery, InvoiceTotalsQueryVariables>(InvoiceTotalsDocument, options);
      }
export function useInvoiceTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceTotalsQuery, InvoiceTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceTotalsQuery, InvoiceTotalsQueryVariables>(InvoiceTotalsDocument, options);
        }
export type InvoiceTotalsQueryHookResult = ReturnType<typeof useInvoiceTotalsQuery>;
export type InvoiceTotalsLazyQueryHookResult = ReturnType<typeof useInvoiceTotalsLazyQuery>;
export type InvoiceTotalsQueryResult = Apollo.QueryResult<InvoiceTotalsQuery, InvoiceTotalsQueryVariables>;
export const AddPaymentOrganizationDetailsDocument = gql`
    query AddPaymentOrganizationDetails {
  currentProfile {
    organization {
      address {
        country
      }
      stripeAccount {
        id
      }
    }
  }
}
    `;

/**
 * __useAddPaymentOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useAddPaymentOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPaymentOrganizationDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddPaymentOrganizationDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AddPaymentOrganizationDetailsQuery, AddPaymentOrganizationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPaymentOrganizationDetailsQuery, AddPaymentOrganizationDetailsQueryVariables>(AddPaymentOrganizationDetailsDocument, options);
      }
export function useAddPaymentOrganizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPaymentOrganizationDetailsQuery, AddPaymentOrganizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPaymentOrganizationDetailsQuery, AddPaymentOrganizationDetailsQueryVariables>(AddPaymentOrganizationDetailsDocument, options);
        }
export type AddPaymentOrganizationDetailsQueryHookResult = ReturnType<typeof useAddPaymentOrganizationDetailsQuery>;
export type AddPaymentOrganizationDetailsLazyQueryHookResult = ReturnType<typeof useAddPaymentOrganizationDetailsLazyQuery>;
export type AddPaymentOrganizationDetailsQueryResult = Apollo.QueryResult<AddPaymentOrganizationDetailsQuery, AddPaymentOrganizationDetailsQueryVariables>;
export const PaymentAccountsDocument = gql`
    query PaymentAccounts {
  paymentMethods {
    ... on BankAccount {
      id
      bankName
      last4
      createdAt
      billingDetails {
        name
        email
      }
    }
    ... on Card {
      id
      brand
      expMonth
      expYear
      last4
      createdAt
      billingDetails {
        name
        email
      }
    }
  }
}
    `;

/**
 * __usePaymentAccountsQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentAccountsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentAccountsQuery, PaymentAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentAccountsQuery, PaymentAccountsQueryVariables>(PaymentAccountsDocument, options);
      }
export function usePaymentAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentAccountsQuery, PaymentAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentAccountsQuery, PaymentAccountsQueryVariables>(PaymentAccountsDocument, options);
        }
export type PaymentAccountsQueryHookResult = ReturnType<typeof usePaymentAccountsQuery>;
export type PaymentAccountsLazyQueryHookResult = ReturnType<typeof usePaymentAccountsLazyQuery>;
export type PaymentAccountsQueryResult = Apollo.QueryResult<PaymentAccountsQuery, PaymentAccountsQueryVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation DeletePaymentMethod($id: ID!) {
  deletePaymentMethod(input: {id: $id}) {
    paymentMethod {
      ... on BankAccount {
        id
        bankName
        last4
        createdAt
      }
      ... on Card {
        id
        brand
        expMonth
        expYear
        last4
        createdAt
      }
    }
  }
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const CreateSetupIntentDocument = gql`
    mutation CreateSetupIntent($paymentMethodTypes: [PaymentMethodType!]!) {
  createPaymentMethodSetupIntent(input: {paymentMethodTypes: $paymentMethodTypes}) {
    setupIntent {
      clientSecret
    }
  }
}
    `;
export type CreateSetupIntentMutationFn = Apollo.MutationFunction<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;

/**
 * __useCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupIntentMutation, { data, loading, error }] = useCreateSetupIntentMutation({
 *   variables: {
 *      paymentMethodTypes: // value for 'paymentMethodTypes'
 *   },
 * });
 */
export function useCreateSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(CreateSetupIntentDocument, options);
      }
export type CreateSetupIntentMutationHookResult = ReturnType<typeof useCreateSetupIntentMutation>;
export type CreateSetupIntentMutationResult = Apollo.MutationResult<CreateSetupIntentMutation>;
export type CreateSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;
export const EditPaymentMethodDocument = gql`
    mutation EditPaymentMethod($id: ID!, $type: String!, $name: String, $email: String, $month: Int, $year: Int) {
  editPaymentMethod(
    input: {id: $id, type: $type, name: $name, email: $email, month: $month, year: $year}
  ) {
    paymentMethod {
      ... on BankAccount {
        id
        billingDetails {
          name
          email
        }
      }
      ... on Card {
        id
        expMonth
        expYear
      }
    }
  }
}
    `;
export type EditPaymentMethodMutationFn = Apollo.MutationFunction<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>;

/**
 * __useEditPaymentMethodMutation__
 *
 * To run a mutation, you first call `useEditPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPaymentMethodMutation, { data, loading, error }] = useEditPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useEditPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>(EditPaymentMethodDocument, options);
      }
export type EditPaymentMethodMutationHookResult = ReturnType<typeof useEditPaymentMethodMutation>;
export type EditPaymentMethodMutationResult = Apollo.MutationResult<EditPaymentMethodMutation>;
export type EditPaymentMethodMutationOptions = Apollo.BaseMutationOptions<EditPaymentMethodMutation, EditPaymentMethodMutationVariables>;
export const CheckoutInvoiceDocument = gql`
    query CheckoutInvoice($paymentUuid: String!) {
  publicInvoice(paymentUuid: $paymentUuid) {
    invoice {
      ...CheckoutPublicInvoice
    }
  }
}
    ${CheckoutPublicInvoiceFragmentDoc}`;

/**
 * __useCheckoutInvoiceQuery__
 *
 * To run a query within a React component, call `useCheckoutInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutInvoiceQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function useCheckoutInvoiceQuery(baseOptions: Apollo.QueryHookOptions<CheckoutInvoiceQuery, CheckoutInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutInvoiceQuery, CheckoutInvoiceQueryVariables>(CheckoutInvoiceDocument, options);
      }
export function useCheckoutInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutInvoiceQuery, CheckoutInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutInvoiceQuery, CheckoutInvoiceQueryVariables>(CheckoutInvoiceDocument, options);
        }
export type CheckoutInvoiceQueryHookResult = ReturnType<typeof useCheckoutInvoiceQuery>;
export type CheckoutInvoiceLazyQueryHookResult = ReturnType<typeof useCheckoutInvoiceLazyQuery>;
export type CheckoutInvoiceQueryResult = Apollo.QueryResult<CheckoutInvoiceQuery, CheckoutInvoiceQueryVariables>;
export const CheckoutPaymentMethodsDocument = gql`
    query CheckoutPaymentMethods {
  paymentMethods {
    ... on BankAccount {
      ...CheckoutPaymentMethodTypeBankAccount
    }
    ... on Card {
      ...CheckoutPaymentMethodTypeCard
    }
  }
}
    ${CheckoutPaymentMethodTypeBankAccountFragmentDoc}
${CheckoutPaymentMethodTypeCardFragmentDoc}`;

/**
 * __useCheckoutPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useCheckoutPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>(CheckoutPaymentMethodsDocument, options);
      }
export function useCheckoutPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>(CheckoutPaymentMethodsDocument, options);
        }
export type CheckoutPaymentMethodsQueryHookResult = ReturnType<typeof useCheckoutPaymentMethodsQuery>;
export type CheckoutPaymentMethodsLazyQueryHookResult = ReturnType<typeof useCheckoutPaymentMethodsLazyQuery>;
export type CheckoutPaymentMethodsQueryResult = Apollo.QueryResult<CheckoutPaymentMethodsQuery, CheckoutPaymentMethodsQueryVariables>;
export const CreateInvoicePaymentIntentDocument = gql`
    mutation CreateInvoicePaymentIntent($paymentUuid: String!) {
  createInvoicePaymentIntent(input: {paymentUuid: $paymentUuid}) {
    paymentIntent {
      ...CheckoutPaymentIntent
    }
  }
}
    ${CheckoutPaymentIntentFragmentDoc}`;
export type CreateInvoicePaymentIntentMutationFn = Apollo.MutationFunction<CreateInvoicePaymentIntentMutation, CreateInvoicePaymentIntentMutationVariables>;

/**
 * __useCreateInvoicePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreateInvoicePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoicePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoicePaymentIntentMutation, { data, loading, error }] = useCreateInvoicePaymentIntentMutation({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function useCreateInvoicePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoicePaymentIntentMutation, CreateInvoicePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoicePaymentIntentMutation, CreateInvoicePaymentIntentMutationVariables>(CreateInvoicePaymentIntentDocument, options);
      }
export type CreateInvoicePaymentIntentMutationHookResult = ReturnType<typeof useCreateInvoicePaymentIntentMutation>;
export type CreateInvoicePaymentIntentMutationResult = Apollo.MutationResult<CreateInvoicePaymentIntentMutation>;
export type CreateInvoicePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreateInvoicePaymentIntentMutation, CreateInvoicePaymentIntentMutationVariables>;
export const UpdateInvoicePaymentIntentDocument = gql`
    mutation UpdateInvoicePaymentIntent($paymentUuid: String!, $paymentIntentId: String!, $paymentMethodType: PaymentMethodType!, $setupFutureUsage: Boolean!) {
  updateInvoicePaymentIntent(
    input: {paymentUuid: $paymentUuid, paymentIntentId: $paymentIntentId, paymentMethodType: $paymentMethodType, setupFutureUsage: $setupFutureUsage}
  ) {
    paymentIntent {
      ...CheckoutPaymentIntent
    }
    convenienceFee
  }
}
    ${CheckoutPaymentIntentFragmentDoc}`;
export type UpdateInvoicePaymentIntentMutationFn = Apollo.MutationFunction<UpdateInvoicePaymentIntentMutation, UpdateInvoicePaymentIntentMutationVariables>;

/**
 * __useUpdateInvoicePaymentIntentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicePaymentIntentMutation, { data, loading, error }] = useUpdateInvoicePaymentIntentMutation({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *      paymentIntentId: // value for 'paymentIntentId'
 *      paymentMethodType: // value for 'paymentMethodType'
 *      setupFutureUsage: // value for 'setupFutureUsage'
 *   },
 * });
 */
export function useUpdateInvoicePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicePaymentIntentMutation, UpdateInvoicePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicePaymentIntentMutation, UpdateInvoicePaymentIntentMutationVariables>(UpdateInvoicePaymentIntentDocument, options);
      }
export type UpdateInvoicePaymentIntentMutationHookResult = ReturnType<typeof useUpdateInvoicePaymentIntentMutation>;
export type UpdateInvoicePaymentIntentMutationResult = Apollo.MutationResult<UpdateInvoicePaymentIntentMutation>;
export type UpdateInvoicePaymentIntentMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicePaymentIntentMutation, UpdateInvoicePaymentIntentMutationVariables>;
export const RequestSavePaymentDetailsDocument = gql`
    mutation RequestSavePaymentDetails($paymentUuid: String!, $paymentIntentId: String!, $fname: String!, $lname: String!, $email: String!) {
  requestSavePaymentDetails(
    input: {paymentUuid: $paymentUuid, paymentIntentId: $paymentIntentId, fname: $fname, lname: $lname, email: $email}
  ) {
    invoice {
      id
    }
  }
}
    `;
export type RequestSavePaymentDetailsMutationFn = Apollo.MutationFunction<RequestSavePaymentDetailsMutation, RequestSavePaymentDetailsMutationVariables>;

/**
 * __useRequestSavePaymentDetailsMutation__
 *
 * To run a mutation, you first call `useRequestSavePaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSavePaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSavePaymentDetailsMutation, { data, loading, error }] = useRequestSavePaymentDetailsMutation({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *      paymentIntentId: // value for 'paymentIntentId'
 *      fname: // value for 'fname'
 *      lname: // value for 'lname'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestSavePaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<RequestSavePaymentDetailsMutation, RequestSavePaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestSavePaymentDetailsMutation, RequestSavePaymentDetailsMutationVariables>(RequestSavePaymentDetailsDocument, options);
      }
export type RequestSavePaymentDetailsMutationHookResult = ReturnType<typeof useRequestSavePaymentDetailsMutation>;
export type RequestSavePaymentDetailsMutationResult = Apollo.MutationResult<RequestSavePaymentDetailsMutation>;
export type RequestSavePaymentDetailsMutationOptions = Apollo.BaseMutationOptions<RequestSavePaymentDetailsMutation, RequestSavePaymentDetailsMutationVariables>;
export const CheckoutSsrInvoiceDocument = gql`
    query CheckoutSsrInvoice($paymentUuid: String!) {
  publicInvoice(paymentUuid: $paymentUuid) {
    invoice {
      ...CheckoutSsrInvoice
    }
  }
}
    ${CheckoutSsrInvoiceFragmentDoc}`;

/**
 * __useCheckoutSsrInvoiceQuery__
 *
 * To run a query within a React component, call `useCheckoutSsrInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSsrInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutSsrInvoiceQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function useCheckoutSsrInvoiceQuery(baseOptions: Apollo.QueryHookOptions<CheckoutSsrInvoiceQuery, CheckoutSsrInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutSsrInvoiceQuery, CheckoutSsrInvoiceQueryVariables>(CheckoutSsrInvoiceDocument, options);
      }
export function useCheckoutSsrInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutSsrInvoiceQuery, CheckoutSsrInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutSsrInvoiceQuery, CheckoutSsrInvoiceQueryVariables>(CheckoutSsrInvoiceDocument, options);
        }
export type CheckoutSsrInvoiceQueryHookResult = ReturnType<typeof useCheckoutSsrInvoiceQuery>;
export type CheckoutSsrInvoiceLazyQueryHookResult = ReturnType<typeof useCheckoutSsrInvoiceLazyQuery>;
export type CheckoutSsrInvoiceQueryResult = Apollo.QueryResult<CheckoutSsrInvoiceQuery, CheckoutSsrInvoiceQueryVariables>;
export const PaymentsInvoiceDocument = gql`
    query PaymentsInvoice($paymentUuid: String!) {
  publicInvoice(paymentUuid: $paymentUuid) {
    invoice {
      id
      status
      number
      project {
        id
        slug
        name
      }
    }
  }
}
    `;

/**
 * __usePaymentsInvoiceQuery__
 *
 * To run a query within a React component, call `usePaymentsInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsInvoiceQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function usePaymentsInvoiceQuery(baseOptions: Apollo.QueryHookOptions<PaymentsInvoiceQuery, PaymentsInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsInvoiceQuery, PaymentsInvoiceQueryVariables>(PaymentsInvoiceDocument, options);
      }
export function usePaymentsInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsInvoiceQuery, PaymentsInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsInvoiceQuery, PaymentsInvoiceQueryVariables>(PaymentsInvoiceDocument, options);
        }
export type PaymentsInvoiceQueryHookResult = ReturnType<typeof usePaymentsInvoiceQuery>;
export type PaymentsInvoiceLazyQueryHookResult = ReturnType<typeof usePaymentsInvoiceLazyQuery>;
export type PaymentsInvoiceQueryResult = Apollo.QueryResult<PaymentsInvoiceQuery, PaymentsInvoiceQueryVariables>;
export const ArchivedProjectsDocument = gql`
    query ArchivedProjects($parameters: SearchableProjectsParametersInput!) {
  searchableProjects(parameters: $parameters) {
    ...ArchivedProjectData
  }
}
    ${ArchivedProjectDataFragmentDoc}`;

/**
 * __useArchivedProjectsQuery__
 *
 * To run a query within a React component, call `useArchivedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedProjectsQuery({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useArchivedProjectsQuery(baseOptions: Apollo.QueryHookOptions<ArchivedProjectsQuery, ArchivedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivedProjectsQuery, ArchivedProjectsQueryVariables>(ArchivedProjectsDocument, options);
      }
export function useArchivedProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivedProjectsQuery, ArchivedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivedProjectsQuery, ArchivedProjectsQueryVariables>(ArchivedProjectsDocument, options);
        }
export type ArchivedProjectsQueryHookResult = ReturnType<typeof useArchivedProjectsQuery>;
export type ArchivedProjectsLazyQueryHookResult = ReturnType<typeof useArchivedProjectsLazyQuery>;
export type ArchivedProjectsQueryResult = Apollo.QueryResult<ArchivedProjectsQuery, ArchivedProjectsQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input) {
    project {
      id
    }
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const UnarchiveProjectDocument = gql`
    mutation UnarchiveProject($input: UnarchiveProjectInput!) {
  unarchiveProject(input: $input) {
    project {
      id
    }
  }
}
    `;
export type UnarchiveProjectMutationFn = Apollo.MutationFunction<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;

/**
 * __useUnarchiveProjectMutation__
 *
 * To run a mutation, you first call `useUnarchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProjectMutation, { data, loading, error }] = useUnarchiveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>(UnarchiveProjectDocument, options);
      }
export type UnarchiveProjectMutationHookResult = ReturnType<typeof useUnarchiveProjectMutation>;
export type UnarchiveProjectMutationResult = Apollo.MutationResult<UnarchiveProjectMutation>;
export type UnarchiveProjectMutationOptions = Apollo.BaseMutationOptions<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>;
export const ProjectOverviewConsultantsDocument = gql`
    query ProjectOverviewConsultants($slug: String) {
  project(slug: $slug) {
    consultantCompaniesWithTemplateNames {
      name
      slug
      templateName
    }
  }
}
    `;

/**
 * __useProjectOverviewConsultantsQuery__
 *
 * To run a query within a React component, call `useProjectOverviewConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOverviewConsultantsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectOverviewConsultantsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectOverviewConsultantsQuery, ProjectOverviewConsultantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOverviewConsultantsQuery, ProjectOverviewConsultantsQueryVariables>(ProjectOverviewConsultantsDocument, options);
      }
export function useProjectOverviewConsultantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOverviewConsultantsQuery, ProjectOverviewConsultantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOverviewConsultantsQuery, ProjectOverviewConsultantsQueryVariables>(ProjectOverviewConsultantsDocument, options);
        }
export type ProjectOverviewConsultantsQueryHookResult = ReturnType<typeof useProjectOverviewConsultantsQuery>;
export type ProjectOverviewConsultantsLazyQueryHookResult = ReturnType<typeof useProjectOverviewConsultantsLazyQuery>;
export type ProjectOverviewConsultantsQueryResult = Apollo.QueryResult<ProjectOverviewConsultantsQuery, ProjectOverviewConsultantsQueryVariables>;
export const PhaseDetailsDocument = gql`
    query PhaseDetails($phaseId: ID!) {
  phase(id: $phaseId) {
    beginDate
    endDate
    feeType
    budget
    linkedPhase {
      id
      name
    }
    percent
    project {
      notes
      slug
    }
  }
}
    `;

/**
 * __usePhaseDetailsQuery__
 *
 * To run a query within a React component, call `usePhaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhaseDetailsQuery({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function usePhaseDetailsQuery(baseOptions: Apollo.QueryHookOptions<PhaseDetailsQuery, PhaseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhaseDetailsQuery, PhaseDetailsQueryVariables>(PhaseDetailsDocument, options);
      }
export function usePhaseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhaseDetailsQuery, PhaseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhaseDetailsQuery, PhaseDetailsQueryVariables>(PhaseDetailsDocument, options);
        }
export type PhaseDetailsQueryHookResult = ReturnType<typeof usePhaseDetailsQuery>;
export type PhaseDetailsLazyQueryHookResult = ReturnType<typeof usePhaseDetailsLazyQuery>;
export type PhaseDetailsQueryResult = Apollo.QueryResult<PhaseDetailsQuery, PhaseDetailsQueryVariables>;
export const ProjectDetailsDocument = gql`
    query ProjectDetails($slug: String) {
  project(slug: $slug) {
    budget
    budgetConsumed
    beginDate
    endDate
    categories {
      id
      name
    }
    id
    totalFee
    consultantsFee
    totalConsultantsMarkup
    projectCost {
      sqUnits
      constructionCost
      id
      sqUnitCostType
    }
    client {
      fullName
      displayName
      phone
      email
    }
    notes
  }
}
    `;

/**
 * __useProjectDetailsQuery__
 *
 * To run a query within a React component, call `useProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, options);
      }
export function useProjectDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, options);
        }
export type ProjectDetailsQueryHookResult = ReturnType<typeof useProjectDetailsQuery>;
export type ProjectDetailsLazyQueryHookResult = ReturnType<typeof useProjectDetailsLazyQuery>;
export type ProjectDetailsQueryResult = Apollo.QueryResult<ProjectDetailsQuery, ProjectDetailsQueryVariables>;
export const PhaseOverviewDocument = gql`
    query PhaseOverview($phaseId: ID!) {
  currentProfile {
    id
    policy {
      permissions {
        canEditAllProjects
        canEditAssignedProjects
      }
    }
  }
  phase(id: $phaseId) {
    id
    name
    budgetConsumed
    budget
    beginDate
    endDate
    status
    roles {
      ...PhaseProgressRoles
    }
    project {
      name
      color
      status
    }
    teamList {
      ...TeamList
    }
    phaseTemplate {
      id
    }
  }
}
    ${PhaseProgressRolesFragmentDoc}
${TeamListFragmentDoc}`;

/**
 * __usePhaseOverviewQuery__
 *
 * To run a query within a React component, call `usePhaseOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhaseOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhaseOverviewQuery({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function usePhaseOverviewQuery(baseOptions: Apollo.QueryHookOptions<PhaseOverviewQuery, PhaseOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhaseOverviewQuery, PhaseOverviewQueryVariables>(PhaseOverviewDocument, options);
      }
export function usePhaseOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhaseOverviewQuery, PhaseOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhaseOverviewQuery, PhaseOverviewQueryVariables>(PhaseOverviewDocument, options);
        }
export type PhaseOverviewQueryHookResult = ReturnType<typeof usePhaseOverviewQuery>;
export type PhaseOverviewLazyQueryHookResult = ReturnType<typeof usePhaseOverviewLazyQuery>;
export type PhaseOverviewQueryResult = Apollo.QueryResult<PhaseOverviewQuery, PhaseOverviewQueryVariables>;
export const UpdatePhaseStatusDocument = gql`
    mutation UpdatePhaseStatus($input: UpdatePhaseStatusInput!) {
  updatePhaseStatus(input: $input) {
    phase {
      id
      status
    }
  }
}
    `;
export type UpdatePhaseStatusMutationFn = Apollo.MutationFunction<UpdatePhaseStatusMutation, UpdatePhaseStatusMutationVariables>;

/**
 * __useUpdatePhaseStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseStatusMutation, { data, loading, error }] = useUpdatePhaseStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhaseStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhaseStatusMutation, UpdatePhaseStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhaseStatusMutation, UpdatePhaseStatusMutationVariables>(UpdatePhaseStatusDocument, options);
      }
export type UpdatePhaseStatusMutationHookResult = ReturnType<typeof useUpdatePhaseStatusMutation>;
export type UpdatePhaseStatusMutationResult = Apollo.MutationResult<UpdatePhaseStatusMutation>;
export type UpdatePhaseStatusMutationOptions = Apollo.BaseMutationOptions<UpdatePhaseStatusMutation, UpdatePhaseStatusMutationVariables>;
export const ProjectProgressDocument = gql`
    query ProjectProgress($slug: String) {
  project(slug: $slug) {
    ...ProjectProgressData
  }
}
    ${ProjectProgressDataFragmentDoc}`;

/**
 * __useProjectProgressQuery__
 *
 * To run a query within a React component, call `useProjectProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectProgressQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectProgressQuery(baseOptions?: Apollo.QueryHookOptions<ProjectProgressQuery, ProjectProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectProgressQuery, ProjectProgressQueryVariables>(ProjectProgressDocument, options);
      }
export function useProjectProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectProgressQuery, ProjectProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectProgressQuery, ProjectProgressQueryVariables>(ProjectProgressDocument, options);
        }
export type ProjectProgressQueryHookResult = ReturnType<typeof useProjectProgressQuery>;
export type ProjectProgressLazyQueryHookResult = ReturnType<typeof useProjectProgressLazyQuery>;
export type ProjectProgressQueryResult = Apollo.QueryResult<ProjectProgressQuery, ProjectProgressQueryVariables>;
export const ProjectPermissionDocument = gql`
    query ProjectPermission($slug: String) {
  currentProfile {
    id
    policy {
      permissions {
        canEditAllProjects
        canEditAssignedProjects
      }
    }
  }
  project(slug: $slug) {
    profiles {
      id
    }
    id
    isShareable
    shareableHash
    status
    isArchived
    name
    invoicesCount
    beginDate
    totalFee
    isBillable
    color
  }
}
    `;

/**
 * __useProjectPermissionQuery__
 *
 * To run a query within a React component, call `useProjectPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPermissionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectPermissionQuery(baseOptions?: Apollo.QueryHookOptions<ProjectPermissionQuery, ProjectPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPermissionQuery, ProjectPermissionQueryVariables>(ProjectPermissionDocument, options);
      }
export function useProjectPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPermissionQuery, ProjectPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPermissionQuery, ProjectPermissionQueryVariables>(ProjectPermissionDocument, options);
        }
export type ProjectPermissionQueryHookResult = ReturnType<typeof useProjectPermissionQuery>;
export type ProjectPermissionLazyQueryHookResult = ReturnType<typeof useProjectPermissionLazyQuery>;
export type ProjectPermissionQueryResult = Apollo.QueryResult<ProjectPermissionQuery, ProjectPermissionQueryVariables>;
export const ProjectTemplateDocument = gql`
    query ProjectTemplate($slug: String!) {
  currentProfile {
    id
    policy {
      permissions {
        canEditAllProjects
        canEditAssignedProjects
      }
    }
  }
  projectTemplate(slug: $slug) {
    ...ProjectTemplateData
  }
}
    ${ProjectTemplateDataFragmentDoc}`;

/**
 * __useProjectTemplateQuery__
 *
 * To run a query within a React component, call `useProjectTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplateQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectTemplateQuery(baseOptions: Apollo.QueryHookOptions<ProjectTemplateQuery, ProjectTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTemplateQuery, ProjectTemplateQueryVariables>(ProjectTemplateDocument, options);
      }
export function useProjectTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplateQuery, ProjectTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTemplateQuery, ProjectTemplateQueryVariables>(ProjectTemplateDocument, options);
        }
export type ProjectTemplateQueryHookResult = ReturnType<typeof useProjectTemplateQuery>;
export type ProjectTemplateLazyQueryHookResult = ReturnType<typeof useProjectTemplateLazyQuery>;
export type ProjectTemplateQueryResult = Apollo.QueryResult<ProjectTemplateQuery, ProjectTemplateQueryVariables>;
export const UpdateProjectStatusDocument = gql`
    mutation UpdateProjectStatus($input: UpdateProjectStatusInput!) {
  updateProjectStatus(input: $input) {
    project {
      id
      status
    }
  }
}
    `;
export type UpdateProjectStatusMutationFn = Apollo.MutationFunction<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>;

/**
 * __useUpdateProjectStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStatusMutation, { data, loading, error }] = useUpdateProjectStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>(UpdateProjectStatusDocument, options);
      }
export type UpdateProjectStatusMutationHookResult = ReturnType<typeof useUpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationResult = Apollo.MutationResult<UpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationOptions = Apollo.BaseMutationOptions<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>;
export const ArchiveProjectDocument = gql`
    mutation ArchiveProject($input: ArchiveProjectInput!) {
  archiveProject(input: $input) {
    project {
      id
      isArchived
    }
  }
}
    `;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<ArchiveProjectMutation, ArchiveProjectMutationVariables>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(ArchiveProjectDocument, options);
      }
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>;
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($input: DuplicateProjectInput!) {
  duplicateProject(input: $input) {
    project {
      id
      slug
    }
  }
}
    `;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<DuplicateProjectMutation, DuplicateProjectMutationVariables>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument, options);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export const CreateTemplateFromProjectDocument = gql`
    mutation CreateTemplateFromProject($input: CreateTemplateFromProjectInput!) {
  createTemplateFromProject(input: $input) {
    template {
      id
      slug
    }
  }
}
    `;
export type CreateTemplateFromProjectMutationFn = Apollo.MutationFunction<CreateTemplateFromProjectMutation, CreateTemplateFromProjectMutationVariables>;

/**
 * __useCreateTemplateFromProjectMutation__
 *
 * To run a mutation, you first call `useCreateTemplateFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateFromProjectMutation, { data, loading, error }] = useCreateTemplateFromProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateFromProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateFromProjectMutation, CreateTemplateFromProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateFromProjectMutation, CreateTemplateFromProjectMutationVariables>(CreateTemplateFromProjectDocument, options);
      }
export type CreateTemplateFromProjectMutationHookResult = ReturnType<typeof useCreateTemplateFromProjectMutation>;
export type CreateTemplateFromProjectMutationResult = Apollo.MutationResult<CreateTemplateFromProjectMutation>;
export type CreateTemplateFromProjectMutationOptions = Apollo.BaseMutationOptions<CreateTemplateFromProjectMutation, CreateTemplateFromProjectMutationVariables>;
export const ProjectRetainerSummaryDocument = gql`
    query ProjectRetainerSummary($slug: String) {
  project(slug: $slug) {
    id
    retainerSummary {
      applied
      received
      remaining
      openReceived
      title
      openApplied
    }
    invoicesCount
  }
}
    `;

/**
 * __useProjectRetainerSummaryQuery__
 *
 * To run a query within a React component, call `useProjectRetainerSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRetainerSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRetainerSummaryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectRetainerSummaryQuery(baseOptions?: Apollo.QueryHookOptions<ProjectRetainerSummaryQuery, ProjectRetainerSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRetainerSummaryQuery, ProjectRetainerSummaryQueryVariables>(ProjectRetainerSummaryDocument, options);
      }
export function useProjectRetainerSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRetainerSummaryQuery, ProjectRetainerSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRetainerSummaryQuery, ProjectRetainerSummaryQueryVariables>(ProjectRetainerSummaryDocument, options);
        }
export type ProjectRetainerSummaryQueryHookResult = ReturnType<typeof useProjectRetainerSummaryQuery>;
export type ProjectRetainerSummaryLazyQueryHookResult = ReturnType<typeof useProjectRetainerSummaryLazyQuery>;
export type ProjectRetainerSummaryQueryResult = Apollo.QueryResult<ProjectRetainerSummaryQuery, ProjectRetainerSummaryQueryVariables>;
export const UserTasksDocument = gql`
    query UserTasks($filters: TaskFiltersInput) {
  currentProfile {
    tasks(filters: $filters) {
      nodes {
        ...UserTask
      }
    }
  }
}
    ${UserTaskFragmentDoc}`;

/**
 * __useUserTasksQuery__
 *
 * To run a query within a React component, call `useUserTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTasksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUserTasksQuery(baseOptions?: Apollo.QueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
      }
export function useUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTasksQuery, UserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTasksQuery, UserTasksQueryVariables>(UserTasksDocument, options);
        }
export type UserTasksQueryHookResult = ReturnType<typeof useUserTasksQuery>;
export type UserTasksLazyQueryHookResult = ReturnType<typeof useUserTasksLazyQuery>;
export type UserTasksQueryResult = Apollo.QueryResult<UserTasksQuery, UserTasksQueryVariables>;
export const ProjectTeamDocument = gql`
    query ProjectTeam($slug: String) {
  project(slug: $slug) {
    teamList {
      ...TeamList
    }
    id
  }
}
    ${TeamListFragmentDoc}`;

/**
 * __useProjectTeamQuery__
 *
 * To run a query within a React component, call `useProjectTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTeamQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectTeamQuery(baseOptions?: Apollo.QueryHookOptions<ProjectTeamQuery, ProjectTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTeamQuery, ProjectTeamQueryVariables>(ProjectTeamDocument, options);
      }
export function useProjectTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTeamQuery, ProjectTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTeamQuery, ProjectTeamQueryVariables>(ProjectTeamDocument, options);
        }
export type ProjectTeamQueryHookResult = ReturnType<typeof useProjectTeamQuery>;
export type ProjectTeamLazyQueryHookResult = ReturnType<typeof useProjectTeamLazyQuery>;
export type ProjectTeamQueryResult = Apollo.QueryResult<ProjectTeamQuery, ProjectTeamQueryVariables>;
export const ProjectMilestonesDocument = gql`
    query ProjectMilestones($slug: String!, $isProjectTemplate: Boolean!) {
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    id
    name
    slug
    number
    color
    orderedPhases {
      ...PlanablePhase
    }
  }
}
    ${PlanablePhaseFragmentDoc}`;

/**
 * __useProjectMilestonesQuery__
 *
 * To run a query within a React component, call `useProjectMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMilestonesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function useProjectMilestonesQuery(baseOptions: Apollo.QueryHookOptions<ProjectMilestonesQuery, ProjectMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectMilestonesQuery, ProjectMilestonesQueryVariables>(ProjectMilestonesDocument, options);
      }
export function useProjectMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectMilestonesQuery, ProjectMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectMilestonesQuery, ProjectMilestonesQueryVariables>(ProjectMilestonesDocument, options);
        }
export type ProjectMilestonesQueryHookResult = ReturnType<typeof useProjectMilestonesQuery>;
export type ProjectMilestonesLazyQueryHookResult = ReturnType<typeof useProjectMilestonesLazyQuery>;
export type ProjectMilestonesQueryResult = Apollo.QueryResult<ProjectMilestonesQuery, ProjectMilestonesQueryVariables>;
export const ProjectTasksDocument = gql`
    query ProjectTasks($slug: String!, $isProjectTemplate: Boolean!) {
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    tasks {
      ...PlanableTask
    }
  }
}
    ${PlanableTaskFragmentDoc}`;

/**
 * __useProjectTasksQuery__
 *
 * To run a query within a React component, call `useProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function useProjectTasksQuery(baseOptions: Apollo.QueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(ProjectTasksDocument, options);
      }
export function useProjectTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(ProjectTasksDocument, options);
        }
export type ProjectTasksQueryHookResult = ReturnType<typeof useProjectTasksQuery>;
export type ProjectTasksLazyQueryHookResult = ReturnType<typeof useProjectTasksLazyQuery>;
export type ProjectTasksQueryResult = Apollo.QueryResult<ProjectTasksQuery, ProjectTasksQueryVariables>;
export const ProjectProfilesDocument = gql`
    query ProjectProfiles($slug: String!) {
  project(slug: $slug) {
    id
    profiles {
      id
      fullName
    }
  }
}
    `;

/**
 * __useProjectProfilesQuery__
 *
 * To run a query within a React component, call `useProjectProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectProfilesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectProfilesQuery(baseOptions: Apollo.QueryHookOptions<ProjectProfilesQuery, ProjectProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectProfilesQuery, ProjectProfilesQueryVariables>(ProjectProfilesDocument, options);
      }
export function useProjectProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectProfilesQuery, ProjectProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectProfilesQuery, ProjectProfilesQueryVariables>(ProjectProfilesDocument, options);
        }
export type ProjectProfilesQueryHookResult = ReturnType<typeof useProjectProfilesQuery>;
export type ProjectProfilesLazyQueryHookResult = ReturnType<typeof useProjectProfilesLazyQuery>;
export type ProjectProfilesQueryResult = Apollo.QueryResult<ProjectProfilesQuery, ProjectProfilesQueryVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($description: String, $dueDate: ISO8601Date!, $milestoneId: ID, $name: String!, $phaseId: ID!, $profileIds: [ID!]) {
  createTask(
    input: {description: $description, dueDate: $dueDate, milestoneId: $milestoneId, name: $name, phaseId: $phaseId, profileIds: $profileIds}
  ) {
    task {
      id
      name
      description
      dueDate
      assignees {
        id
      }
      milestone {
        id
      }
      phase {
        id
      }
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      milestoneId: // value for 'milestoneId'
 *      name: // value for 'name'
 *      phaseId: // value for 'phaseId'
 *      profileIds: // value for 'profileIds'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const TaskDocument = gql`
    query Task($id: ID!) {
  task(id: $id) {
    ...TaskShow
  }
}
    ${TaskShowFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($description: String!, $taskId: ID!) {
  createComment(input: {description: $description, taskId: $taskId}) {
    comment {
      ...TaskShowComment
    }
  }
}
    ${TaskShowCommentFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      description: // value for 'description'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const PlanablePhaseOptionsDocument = gql`
    query PlanablePhaseOptions($slug: String!, $isProjectTemplate: Boolean!) {
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    orderedPhases {
      id
      name
      milestones {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePlanablePhaseOptionsQuery__
 *
 * To run a query within a React component, call `usePlanablePhaseOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanablePhaseOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanablePhaseOptionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function usePlanablePhaseOptionsQuery(baseOptions: Apollo.QueryHookOptions<PlanablePhaseOptionsQuery, PlanablePhaseOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanablePhaseOptionsQuery, PlanablePhaseOptionsQueryVariables>(PlanablePhaseOptionsDocument, options);
      }
export function usePlanablePhaseOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanablePhaseOptionsQuery, PlanablePhaseOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanablePhaseOptionsQuery, PlanablePhaseOptionsQueryVariables>(PlanablePhaseOptionsDocument, options);
        }
export type PlanablePhaseOptionsQueryHookResult = ReturnType<typeof usePlanablePhaseOptionsQuery>;
export type PlanablePhaseOptionsLazyQueryHookResult = ReturnType<typeof usePlanablePhaseOptionsLazyQuery>;
export type PlanablePhaseOptionsQueryResult = Apollo.QueryResult<PlanablePhaseOptionsQuery, PlanablePhaseOptionsQueryVariables>;
export const EditTaskDocument = gql`
    mutation EditTask($id: ID!, $description: String, $dueDate: ISO8601Date, $milestoneId: ID, $name: String, $phaseId: ID, $profileIds: [ID!]) {
  editTask(
    input: {id: $id, description: $description, dueDate: $dueDate, milestoneId: $milestoneId, name: $name, phaseId: $phaseId, profileIds: $profileIds}
  ) {
    task {
      id
      dueDate
      name
      description
      assignees {
        id
      }
    }
  }
}
    `;
export type EditTaskMutationFn = Apollo.MutationFunction<EditTaskMutation, EditTaskMutationVariables>;

/**
 * __useEditTaskMutation__
 *
 * To run a mutation, you first call `useEditTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskMutation, { data, loading, error }] = useEditTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      milestoneId: // value for 'milestoneId'
 *      name: // value for 'name'
 *      phaseId: // value for 'phaseId'
 *      profileIds: // value for 'profileIds'
 *   },
 * });
 */
export function useEditTaskMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskMutation, EditTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaskMutation, EditTaskMutationVariables>(EditTaskDocument, options);
      }
export type EditTaskMutationHookResult = ReturnType<typeof useEditTaskMutation>;
export type EditTaskMutationResult = Apollo.MutationResult<EditTaskMutation>;
export type EditTaskMutationOptions = Apollo.BaseMutationOptions<EditTaskMutation, EditTaskMutationVariables>;
export const EditCommentDocument = gql`
    mutation EditComment($description: String!, $id: ID!) {
  editComment(input: {description: $description, id: $id}) {
    comment {
      id
      createdAt
      description
      profile {
        id
      }
    }
  }
}
    `;
export type EditCommentMutationFn = Apollo.MutationFunction<EditCommentMutation, EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      description: // value for 'description'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<EditCommentMutation, EditCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID!) {
  deleteComment(input: {id: $id}) {
    comment {
      id
      profile {
        id
      }
    }
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($id: ID!) {
  deleteTask(input: {id: $id}) {
    task {
      id
    }
  }
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DeleteMilestoneDocument = gql`
    mutation DeleteMilestone($id: ID!) {
  deleteMilestone(input: {id: $id}) {
    milestone {
      id
    }
  }
}
    `;
export type DeleteMilestoneMutationFn = Apollo.MutationFunction<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>;

/**
 * __useDeleteMilestoneMutation__
 *
 * To run a mutation, you first call `useDeleteMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMilestoneMutation, { data, loading, error }] = useDeleteMilestoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>(DeleteMilestoneDocument, options);
      }
export type DeleteMilestoneMutationHookResult = ReturnType<typeof useDeleteMilestoneMutation>;
export type DeleteMilestoneMutationResult = Apollo.MutationResult<DeleteMilestoneMutation>;
export type DeleteMilestoneMutationOptions = Apollo.BaseMutationOptions<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>;
export const EditMilestoneDocument = gql`
    mutation EditMilestone($id: ID!, $name: String, $description: String, $dueDate: ISO8601Date, $phaseId: ID) {
  editMilestone(
    input: {id: $id, name: $name, description: $description, dueDate: $dueDate, phaseId: $phaseId}
  ) {
    milestone {
      id
      name
      description
      dueDate
      phase {
        id
      }
    }
  }
}
    `;
export type EditMilestoneMutationFn = Apollo.MutationFunction<EditMilestoneMutation, EditMilestoneMutationVariables>;

/**
 * __useEditMilestoneMutation__
 *
 * To run a mutation, you first call `useEditMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMilestoneMutation, { data, loading, error }] = useEditMilestoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function useEditMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<EditMilestoneMutation, EditMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMilestoneMutation, EditMilestoneMutationVariables>(EditMilestoneDocument, options);
      }
export type EditMilestoneMutationHookResult = ReturnType<typeof useEditMilestoneMutation>;
export type EditMilestoneMutationResult = Apollo.MutationResult<EditMilestoneMutation>;
export type EditMilestoneMutationOptions = Apollo.BaseMutationOptions<EditMilestoneMutation, EditMilestoneMutationVariables>;
export const MilestoneDocument = gql`
    query Milestone($id: ID!) {
  milestone(id: $id) {
    ...MilestoneShow
  }
}
    ${MilestoneShowFragmentDoc}`;

/**
 * __useMilestoneQuery__
 *
 * To run a query within a React component, call `useMilestoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilestoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilestoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMilestoneQuery(baseOptions: Apollo.QueryHookOptions<MilestoneQuery, MilestoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MilestoneQuery, MilestoneQueryVariables>(MilestoneDocument, options);
      }
export function useMilestoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MilestoneQuery, MilestoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MilestoneQuery, MilestoneQueryVariables>(MilestoneDocument, options);
        }
export type MilestoneQueryHookResult = ReturnType<typeof useMilestoneQuery>;
export type MilestoneLazyQueryHookResult = ReturnType<typeof useMilestoneLazyQuery>;
export type MilestoneQueryResult = Apollo.QueryResult<MilestoneQuery, MilestoneQueryVariables>;
export const ProfilesOptionsDocument = gql`
    query ProfilesOptions($sortBy: ProfilesSortInput!) {
  profiles(sortBy: $sortBy) {
    nodes {
      id
      fullName
    }
  }
}
    `;

/**
 * __useProfilesOptionsQuery__
 *
 * To run a query within a React component, call `useProfilesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesOptionsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProfilesOptionsQuery(baseOptions: Apollo.QueryHookOptions<ProfilesOptionsQuery, ProfilesOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesOptionsQuery, ProfilesOptionsQueryVariables>(ProfilesOptionsDocument, options);
      }
export function useProfilesOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesOptionsQuery, ProfilesOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesOptionsQuery, ProfilesOptionsQueryVariables>(ProfilesOptionsDocument, options);
        }
export type ProfilesOptionsQueryHookResult = ReturnType<typeof useProfilesOptionsQuery>;
export type ProfilesOptionsLazyQueryHookResult = ReturnType<typeof useProfilesOptionsLazyQuery>;
export type ProfilesOptionsQueryResult = Apollo.QueryResult<ProfilesOptionsQuery, ProfilesOptionsQueryVariables>;
export const ProjectTimesheetsDocument = gql`
    query ProjectTimesheets($beginDate: ISO8601Date, $ids: [ID!], $isLate: Boolean, $profileIds: [ID!], $projectIds: [ID!]) {
  searchableTimesheets(
    parameters: {beginDate: $beginDate, ids: $ids, isLate: $isLate, profileIds: $profileIds, projectIds: $projectIds}
  ) {
    ...ProjectTimesheetResult
  }
}
    ${ProjectTimesheetResultFragmentDoc}`;

/**
 * __useProjectTimesheetsQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetsQuery({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *      ids: // value for 'ids'
 *      isLate: // value for 'isLate'
 *      profileIds: // value for 'profileIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useProjectTimesheetsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables>(ProjectTimesheetsDocument, options);
      }
export function useProjectTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables>(ProjectTimesheetsDocument, options);
        }
export type ProjectTimesheetsQueryHookResult = ReturnType<typeof useProjectTimesheetsQuery>;
export type ProjectTimesheetsLazyQueryHookResult = ReturnType<typeof useProjectTimesheetsLazyQuery>;
export type ProjectTimesheetsQueryResult = Apollo.QueryResult<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables>;
export const ProjectTimesheetsProjectDocument = gql`
    query ProjectTimesheetsProject($slug: String!) {
  project(slug: $slug) {
    id
  }
}
    `;

/**
 * __useProjectTimesheetsProjectQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetsProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetsProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetsProjectQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectTimesheetsProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectTimesheetsProjectQuery, ProjectTimesheetsProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTimesheetsProjectQuery, ProjectTimesheetsProjectQueryVariables>(ProjectTimesheetsProjectDocument, options);
      }
export function useProjectTimesheetsProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimesheetsProjectQuery, ProjectTimesheetsProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTimesheetsProjectQuery, ProjectTimesheetsProjectQueryVariables>(ProjectTimesheetsProjectDocument, options);
        }
export type ProjectTimesheetsProjectQueryHookResult = ReturnType<typeof useProjectTimesheetsProjectQuery>;
export type ProjectTimesheetsProjectLazyQueryHookResult = ReturnType<typeof useProjectTimesheetsProjectLazyQuery>;
export type ProjectTimesheetsProjectQueryResult = Apollo.QueryResult<ProjectTimesheetsProjectQuery, ProjectTimesheetsProjectQueryVariables>;
export const ExpensesExportClientsListDocument = gql`
    query expensesExportClientsList {
  allClients(filters: {syncedToQbo: true}) {
    id
    displayName
    syncedToQbo
    qboCustomer {
      id
    }
  }
}
    `;

/**
 * __useExpensesExportClientsListQuery__
 *
 * To run a query within a React component, call `useExpensesExportClientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesExportClientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesExportClientsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpensesExportClientsListQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesExportClientsListQuery, ExpensesExportClientsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesExportClientsListQuery, ExpensesExportClientsListQueryVariables>(ExpensesExportClientsListDocument, options);
      }
export function useExpensesExportClientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesExportClientsListQuery, ExpensesExportClientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesExportClientsListQuery, ExpensesExportClientsListQueryVariables>(ExpensesExportClientsListDocument, options);
        }
export type ExpensesExportClientsListQueryHookResult = ReturnType<typeof useExpensesExportClientsListQuery>;
export type ExpensesExportClientsListLazyQueryHookResult = ReturnType<typeof useExpensesExportClientsListLazyQuery>;
export type ExpensesExportClientsListQueryResult = Apollo.QueryResult<ExpensesExportClientsListQuery, ExpensesExportClientsListQueryVariables>;
export const TeamActivityReportDocument = gql`
    query TeamActivityReport($filters: TeamActivityFiltersInput!) {
  reports {
    teamActivity(filters: $filters) {
      totals {
        profile {
          id
          slug
          firstNameLastInitial
        }
        billableHours
        nonBillableHours
      }
    }
  }
}
    `;

/**
 * __useTeamActivityReportQuery__
 *
 * To run a query within a React component, call `useTeamActivityReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamActivityReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamActivityReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTeamActivityReportQuery(baseOptions: Apollo.QueryHookOptions<TeamActivityReportQuery, TeamActivityReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamActivityReportQuery, TeamActivityReportQueryVariables>(TeamActivityReportDocument, options);
      }
export function useTeamActivityReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamActivityReportQuery, TeamActivityReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamActivityReportQuery, TeamActivityReportQueryVariables>(TeamActivityReportDocument, options);
        }
export type TeamActivityReportQueryHookResult = ReturnType<typeof useTeamActivityReportQuery>;
export type TeamActivityReportLazyQueryHookResult = ReturnType<typeof useTeamActivityReportLazyQuery>;
export type TeamActivityReportQueryResult = Apollo.QueryResult<TeamActivityReportQuery, TeamActivityReportQueryVariables>;
export const TeamForecastReportDocument = gql`
    query TeamForecastReport($filters: TeamForecastFiltersInput!) {
  reports {
    teamForecast(filters: $filters) {
      profiles {
        ...TeamForecastProfile
      }
      roles {
        ...TeamForecastRole
      }
      overheads {
        ...TeamForecastOverhead
      }
      timeOffRequests {
        ...TeamForecastTimeOffRequest
      }
      timeOffHolidays {
        ...TeamForecastTimeOffHoliday
      }
      allocationGroups {
        timesheetBeginDate
        profileId
        roleId
        overheadId
        timeOffRequestId
        timeOffHolidayId
        totalAllocation
      }
    }
  }
  currentProfile {
    organization {
      id
      settings {
        defaultHoursPerWeek
      }
    }
  }
}
    ${TeamForecastProfileFragmentDoc}
${TeamForecastRoleFragmentDoc}
${TeamForecastOverheadFragmentDoc}
${TeamForecastTimeOffRequestFragmentDoc}
${TeamForecastTimeOffHolidayFragmentDoc}`;

/**
 * __useTeamForecastReportQuery__
 *
 * To run a query within a React component, call `useTeamForecastReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamForecastReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamForecastReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTeamForecastReportQuery(baseOptions: Apollo.QueryHookOptions<TeamForecastReportQuery, TeamForecastReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamForecastReportQuery, TeamForecastReportQueryVariables>(TeamForecastReportDocument, options);
      }
export function useTeamForecastReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamForecastReportQuery, TeamForecastReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamForecastReportQuery, TeamForecastReportQueryVariables>(TeamForecastReportDocument, options);
        }
export type TeamForecastReportQueryHookResult = ReturnType<typeof useTeamForecastReportQuery>;
export type TeamForecastReportLazyQueryHookResult = ReturnType<typeof useTeamForecastReportLazyQuery>;
export type TeamForecastReportQueryResult = Apollo.QueryResult<TeamForecastReportQuery, TeamForecastReportQueryVariables>;
export const TeamForecastExportDocument = gql`
    mutation TeamForecastExport($input: TeamForecastInput!) {
  startExport {
    teamForecast(input: $input) {
      id
    }
  }
}
    `;
export type TeamForecastExportMutationFn = Apollo.MutationFunction<TeamForecastExportMutation, TeamForecastExportMutationVariables>;

/**
 * __useTeamForecastExportMutation__
 *
 * To run a mutation, you first call `useTeamForecastExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamForecastExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamForecastExportMutation, { data, loading, error }] = useTeamForecastExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamForecastExportMutation(baseOptions?: Apollo.MutationHookOptions<TeamForecastExportMutation, TeamForecastExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamForecastExportMutation, TeamForecastExportMutationVariables>(TeamForecastExportDocument, options);
      }
export type TeamForecastExportMutationHookResult = ReturnType<typeof useTeamForecastExportMutation>;
export type TeamForecastExportMutationResult = Apollo.MutationResult<TeamForecastExportMutation>;
export type TeamForecastExportMutationOptions = Apollo.BaseMutationOptions<TeamForecastExportMutation, TeamForecastExportMutationVariables>;
export const UpdateOrgTelephoneDocument = gql`
    mutation UpdateOrgTelephone($telephone: String!) {
  updateOrganizationTelephone(input: {telephone: $telephone}) {
    organization {
      id
      telephone
    }
  }
}
    `;
export type UpdateOrgTelephoneMutationFn = Apollo.MutationFunction<UpdateOrgTelephoneMutation, UpdateOrgTelephoneMutationVariables>;

/**
 * __useUpdateOrgTelephoneMutation__
 *
 * To run a mutation, you first call `useUpdateOrgTelephoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgTelephoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgTelephoneMutation, { data, loading, error }] = useUpdateOrgTelephoneMutation({
 *   variables: {
 *      telephone: // value for 'telephone'
 *   },
 * });
 */
export function useUpdateOrgTelephoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgTelephoneMutation, UpdateOrgTelephoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgTelephoneMutation, UpdateOrgTelephoneMutationVariables>(UpdateOrgTelephoneDocument, options);
      }
export type UpdateOrgTelephoneMutationHookResult = ReturnType<typeof useUpdateOrgTelephoneMutation>;
export type UpdateOrgTelephoneMutationResult = Apollo.MutationResult<UpdateOrgTelephoneMutation>;
export type UpdateOrgTelephoneMutationOptions = Apollo.BaseMutationOptions<UpdateOrgTelephoneMutation, UpdateOrgTelephoneMutationVariables>;
export const UpdateOrgWebsiteDocument = gql`
    mutation UpdateOrgWebsite($website: String!) {
  updateOrganizationWebsite(input: {website: $website}) {
    organization {
      id
      website
    }
  }
}
    `;
export type UpdateOrgWebsiteMutationFn = Apollo.MutationFunction<UpdateOrgWebsiteMutation, UpdateOrgWebsiteMutationVariables>;

/**
 * __useUpdateOrgWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateOrgWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgWebsiteMutation, { data, loading, error }] = useUpdateOrgWebsiteMutation({
 *   variables: {
 *      website: // value for 'website'
 *   },
 * });
 */
export function useUpdateOrgWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgWebsiteMutation, UpdateOrgWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgWebsiteMutation, UpdateOrgWebsiteMutationVariables>(UpdateOrgWebsiteDocument, options);
      }
export type UpdateOrgWebsiteMutationHookResult = ReturnType<typeof useUpdateOrgWebsiteMutation>;
export type UpdateOrgWebsiteMutationResult = Apollo.MutationResult<UpdateOrgWebsiteMutation>;
export type UpdateOrgWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateOrgWebsiteMutation, UpdateOrgWebsiteMutationVariables>;
export const CostsReviewProjectsDocument = gql`
    query CostsReviewProjects($includeArchived: Boolean) {
  projects(includeArchived: $includeArchived) {
    ...CostsReviewProject
  }
}
    ${CostsReviewProjectFragmentDoc}`;

/**
 * __useCostsReviewProjectsQuery__
 *
 * To run a query within a React component, call `useCostsReviewProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostsReviewProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostsReviewProjectsQuery({
 *   variables: {
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useCostsReviewProjectsQuery(baseOptions?: Apollo.QueryHookOptions<CostsReviewProjectsQuery, CostsReviewProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CostsReviewProjectsQuery, CostsReviewProjectsQueryVariables>(CostsReviewProjectsDocument, options);
      }
export function useCostsReviewProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostsReviewProjectsQuery, CostsReviewProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CostsReviewProjectsQuery, CostsReviewProjectsQueryVariables>(CostsReviewProjectsDocument, options);
        }
export type CostsReviewProjectsQueryHookResult = ReturnType<typeof useCostsReviewProjectsQuery>;
export type CostsReviewProjectsLazyQueryHookResult = ReturnType<typeof useCostsReviewProjectsLazyQuery>;
export type CostsReviewProjectsQueryResult = Apollo.QueryResult<CostsReviewProjectsQuery, CostsReviewProjectsQueryVariables>;
export const CategoriesFilterOptionsDocument = gql`
    query CategoriesFilterOptions {
  currentProfile {
    organization {
      id
      categories {
        ...CategoriesFilterCategory
      }
    }
  }
}
    ${CategoriesFilterCategoryFragmentDoc}`;

/**
 * __useCategoriesFilterOptionsQuery__
 *
 * To run a query within a React component, call `useCategoriesFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesFilterOptionsQuery, CategoriesFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesFilterOptionsQuery, CategoriesFilterOptionsQueryVariables>(CategoriesFilterOptionsDocument, options);
      }
export function useCategoriesFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesFilterOptionsQuery, CategoriesFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesFilterOptionsQuery, CategoriesFilterOptionsQueryVariables>(CategoriesFilterOptionsDocument, options);
        }
export type CategoriesFilterOptionsQueryHookResult = ReturnType<typeof useCategoriesFilterOptionsQuery>;
export type CategoriesFilterOptionsLazyQueryHookResult = ReturnType<typeof useCategoriesFilterOptionsLazyQuery>;
export type CategoriesFilterOptionsQueryResult = Apollo.QueryResult<CategoriesFilterOptionsQuery, CategoriesFilterOptionsQueryVariables>;
export const ProfilesFilterOptionsDocument = gql`
    query ProfilesFilterOptions($searchString: String, $limitProfiles: Int, $selectedProfileIds: [ID!], $loadSelectedProfiles: Boolean!, $projectsLimit: Int, $projectsMinProfilesCount: Int, $projectsSortBy: SearchableProjectsSortInput) {
  suggestedProjects: searchableProjects(
    parameters: {searchString: $searchString, minProfilesCount: $projectsMinProfilesCount, limit: $projectsLimit, sortBy: $projectsSortBy}
  ) {
    ...ProfilesFilterProject
  }
  suggestedProfiles: profiles(
    filters: {searchString: $searchString}
    first: $limitProfiles
  ) {
    nodes {
      ...ProfilesFilterProfile
    }
  }
  selectedProfiles: profiles(filters: {ids: $selectedProfileIds}) @include(if: $loadSelectedProfiles) {
    nodes {
      ...ProfilesFilterProfile
    }
  }
}
    ${ProfilesFilterProjectFragmentDoc}
${ProfilesFilterProfileFragmentDoc}`;

/**
 * __useProfilesFilterOptionsQuery__
 *
 * To run a query within a React component, call `useProfilesFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesFilterOptionsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limitProfiles: // value for 'limitProfiles'
 *      selectedProfileIds: // value for 'selectedProfileIds'
 *      loadSelectedProfiles: // value for 'loadSelectedProfiles'
 *      projectsLimit: // value for 'projectsLimit'
 *      projectsMinProfilesCount: // value for 'projectsMinProfilesCount'
 *      projectsSortBy: // value for 'projectsSortBy'
 *   },
 * });
 */
export function useProfilesFilterOptionsQuery(baseOptions: Apollo.QueryHookOptions<ProfilesFilterOptionsQuery, ProfilesFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesFilterOptionsQuery, ProfilesFilterOptionsQueryVariables>(ProfilesFilterOptionsDocument, options);
      }
export function useProfilesFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesFilterOptionsQuery, ProfilesFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesFilterOptionsQuery, ProfilesFilterOptionsQueryVariables>(ProfilesFilterOptionsDocument, options);
        }
export type ProfilesFilterOptionsQueryHookResult = ReturnType<typeof useProfilesFilterOptionsQuery>;
export type ProfilesFilterOptionsLazyQueryHookResult = ReturnType<typeof useProfilesFilterOptionsLazyQuery>;
export type ProfilesFilterOptionsQueryResult = Apollo.QueryResult<ProfilesFilterOptionsQuery, ProfilesFilterOptionsQueryVariables>;
export const ProjectsFilterOptionsDocument = gql`
    query ProjectsFilterOptions {
  projects {
    ...ProjectsFilterProject
  }
}
    ${ProjectsFilterProjectFragmentDoc}`;

/**
 * __useProjectsFilterOptionsQuery__
 *
 * To run a query within a React component, call `useProjectsFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>(ProjectsFilterOptionsDocument, options);
      }
export function useProjectsFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>(ProjectsFilterOptionsDocument, options);
        }
export type ProjectsFilterOptionsQueryHookResult = ReturnType<typeof useProjectsFilterOptionsQuery>;
export type ProjectsFilterOptionsLazyQueryHookResult = ReturnType<typeof useProjectsFilterOptionsLazyQuery>;
export type ProjectsFilterOptionsQueryResult = Apollo.QueryResult<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>;
export const RebalanceModalRoleDocument = gql`
    query RebalanceModalRole($id: ID!) {
  role(id: $id) {
    ...RebalanceModalRole
  }
}
    ${RebalanceModalRoleFragmentDoc}`;

/**
 * __useRebalanceModalRoleQuery__
 *
 * To run a query within a React component, call `useRebalanceModalRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRebalanceModalRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRebalanceModalRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRebalanceModalRoleQuery(baseOptions: Apollo.QueryHookOptions<RebalanceModalRoleQuery, RebalanceModalRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RebalanceModalRoleQuery, RebalanceModalRoleQueryVariables>(RebalanceModalRoleDocument, options);
      }
export function useRebalanceModalRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RebalanceModalRoleQuery, RebalanceModalRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RebalanceModalRoleQuery, RebalanceModalRoleQueryVariables>(RebalanceModalRoleDocument, options);
        }
export type RebalanceModalRoleQueryHookResult = ReturnType<typeof useRebalanceModalRoleQuery>;
export type RebalanceModalRoleLazyQueryHookResult = ReturnType<typeof useRebalanceModalRoleLazyQuery>;
export type RebalanceModalRoleQueryResult = Apollo.QueryResult<RebalanceModalRoleQuery, RebalanceModalRoleQueryVariables>;
export const AllocationUpdaterEntryGroupDocument = gql`
    query AllocationUpdaterEntryGroup($id: ID!) {
  entryGroup(id: $id) {
    id
    allocation
    allocatedByUser
    role {
      id
    }
    phase {
      id
    }
  }
}
    `;

/**
 * __useAllocationUpdaterEntryGroupQuery__
 *
 * To run a query within a React component, call `useAllocationUpdaterEntryGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocationUpdaterEntryGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocationUpdaterEntryGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllocationUpdaterEntryGroupQuery(baseOptions: Apollo.QueryHookOptions<AllocationUpdaterEntryGroupQuery, AllocationUpdaterEntryGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllocationUpdaterEntryGroupQuery, AllocationUpdaterEntryGroupQueryVariables>(AllocationUpdaterEntryGroupDocument, options);
      }
export function useAllocationUpdaterEntryGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllocationUpdaterEntryGroupQuery, AllocationUpdaterEntryGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllocationUpdaterEntryGroupQuery, AllocationUpdaterEntryGroupQueryVariables>(AllocationUpdaterEntryGroupDocument, options);
        }
export type AllocationUpdaterEntryGroupQueryHookResult = ReturnType<typeof useAllocationUpdaterEntryGroupQuery>;
export type AllocationUpdaterEntryGroupLazyQueryHookResult = ReturnType<typeof useAllocationUpdaterEntryGroupLazyQuery>;
export type AllocationUpdaterEntryGroupQueryResult = Apollo.QueryResult<AllocationUpdaterEntryGroupQuery, AllocationUpdaterEntryGroupQueryVariables>;
export const UpdateAllocationDocument = gql`
    mutation updateAllocation($id: ID!, $allocation: Float!) {
  updateEntryGroupAllocation(input: {id: $id, allocation: $allocation}) {
    entryGroup {
      id
      allocation
      allocatedByUser
      role {
        id
        hoursAllocated
      }
      timesheet {
        id
        beginDate
        hoursAllocated
        overheadHoursAllocated
      }
    }
  }
}
    `;
export type UpdateAllocationMutationFn = Apollo.MutationFunction<UpdateAllocationMutation, UpdateAllocationMutationVariables>;

/**
 * __useUpdateAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllocationMutation, { data, loading, error }] = useUpdateAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      allocation: // value for 'allocation'
 *   },
 * });
 */
export function useUpdateAllocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllocationMutation, UpdateAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllocationMutation, UpdateAllocationMutationVariables>(UpdateAllocationDocument, options);
      }
export type UpdateAllocationMutationHookResult = ReturnType<typeof useUpdateAllocationMutation>;
export type UpdateAllocationMutationResult = Apollo.MutationResult<UpdateAllocationMutation>;
export type UpdateAllocationMutationOptions = Apollo.BaseMutationOptions<UpdateAllocationMutation, UpdateAllocationMutationVariables>;
export const CreateRoleAllocationDocument = gql`
    mutation createRoleAllocation($input: CreateStaffingRoleAllocationInput!) {
  createStaffingRoleAllocation(input: $input) {
    role {
      id
      hoursAllocated
      profile {
        id
        timesheets {
          id
          beginDate
          hoursAllocated
          entryGroups {
            id
            allocatedByUser
            allocation
            role {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateRoleAllocationMutationFn = Apollo.MutationFunction<CreateRoleAllocationMutation, CreateRoleAllocationMutationVariables>;

/**
 * __useCreateRoleAllocationMutation__
 *
 * To run a mutation, you first call `useCreateRoleAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleAllocationMutation, { data, loading, error }] = useCreateRoleAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleAllocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleAllocationMutation, CreateRoleAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleAllocationMutation, CreateRoleAllocationMutationVariables>(CreateRoleAllocationDocument, options);
      }
export type CreateRoleAllocationMutationHookResult = ReturnType<typeof useCreateRoleAllocationMutation>;
export type CreateRoleAllocationMutationResult = Apollo.MutationResult<CreateRoleAllocationMutation>;
export type CreateRoleAllocationMutationOptions = Apollo.BaseMutationOptions<CreateRoleAllocationMutation, CreateRoleAllocationMutationVariables>;
export const TimeOffCardDocument = gql`
    query TimeOffCard {
  currentProfile {
    id
    organization {
      id
      settings {
        id
        ptoEnabled
      }
    }
  }
}
    `;

/**
 * __useTimeOffCardQuery__
 *
 * To run a query within a React component, call `useTimeOffCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffCardQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffCardQuery, TimeOffCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffCardQuery, TimeOffCardQueryVariables>(TimeOffCardDocument, options);
      }
export function useTimeOffCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffCardQuery, TimeOffCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffCardQuery, TimeOffCardQueryVariables>(TimeOffCardDocument, options);
        }
export type TimeOffCardQueryHookResult = ReturnType<typeof useTimeOffCardQuery>;
export type TimeOffCardLazyQueryHookResult = ReturnType<typeof useTimeOffCardLazyQuery>;
export type TimeOffCardQueryResult = Apollo.QueryResult<TimeOffCardQuery, TimeOffCardQueryVariables>;
export const StaffingCurrentWeekProfileColumnDocument = gql`
    query StaffingCurrentWeekProfileColumn($profileId: ID!, $beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  profile(id: $profileId) {
    ...StaffingCurrentWeekFullProfile
  }
}
    ${StaffingCurrentWeekFullProfileFragmentDoc}`;

/**
 * __useStaffingCurrentWeekProfileColumnQuery__
 *
 * To run a query within a React component, call `useStaffingCurrentWeekProfileColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingCurrentWeekProfileColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingCurrentWeekProfileColumnQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStaffingCurrentWeekProfileColumnQuery(baseOptions: Apollo.QueryHookOptions<StaffingCurrentWeekProfileColumnQuery, StaffingCurrentWeekProfileColumnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingCurrentWeekProfileColumnQuery, StaffingCurrentWeekProfileColumnQueryVariables>(StaffingCurrentWeekProfileColumnDocument, options);
      }
export function useStaffingCurrentWeekProfileColumnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingCurrentWeekProfileColumnQuery, StaffingCurrentWeekProfileColumnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingCurrentWeekProfileColumnQuery, StaffingCurrentWeekProfileColumnQueryVariables>(StaffingCurrentWeekProfileColumnDocument, options);
        }
export type StaffingCurrentWeekProfileColumnQueryHookResult = ReturnType<typeof useStaffingCurrentWeekProfileColumnQuery>;
export type StaffingCurrentWeekProfileColumnLazyQueryHookResult = ReturnType<typeof useStaffingCurrentWeekProfileColumnLazyQuery>;
export type StaffingCurrentWeekProfileColumnQueryResult = Apollo.QueryResult<StaffingCurrentWeekProfileColumnQuery, StaffingCurrentWeekProfileColumnQueryVariables>;
export const StaffingCurrentWeekPageDocument = gql`
    query StaffingCurrentWeekPage {
  currentProfile {
    organization {
      id
      profiles {
        ...StaffingCurrentWeekProfile
      }
    }
  }
}
    ${StaffingCurrentWeekProfileFragmentDoc}`;

/**
 * __useStaffingCurrentWeekPageQuery__
 *
 * To run a query within a React component, call `useStaffingCurrentWeekPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingCurrentWeekPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingCurrentWeekPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffingCurrentWeekPageQuery(baseOptions?: Apollo.QueryHookOptions<StaffingCurrentWeekPageQuery, StaffingCurrentWeekPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingCurrentWeekPageQuery, StaffingCurrentWeekPageQueryVariables>(StaffingCurrentWeekPageDocument, options);
      }
export function useStaffingCurrentWeekPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingCurrentWeekPageQuery, StaffingCurrentWeekPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingCurrentWeekPageQuery, StaffingCurrentWeekPageQueryVariables>(StaffingCurrentWeekPageDocument, options);
        }
export type StaffingCurrentWeekPageQueryHookResult = ReturnType<typeof useStaffingCurrentWeekPageQuery>;
export type StaffingCurrentWeekPageLazyQueryHookResult = ReturnType<typeof useStaffingCurrentWeekPageLazyQuery>;
export type StaffingCurrentWeekPageQueryResult = Apollo.QueryResult<StaffingCurrentWeekPageQuery, StaffingCurrentWeekPageQueryVariables>;
export const StaffingProjectsDocument = gql`
    query StaffingProjects($projectIds: [ID!]) {
  projects(
    includeArchived: false
    statuses: [ACTIVE, PROPOSED]
    projectIds: $projectIds
  ) {
    ...StaffingProject
  }
}
    ${StaffingProjectFragmentDoc}`;

/**
 * __useStaffingProjectsQuery__
 *
 * To run a query within a React component, call `useStaffingProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingProjectsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useStaffingProjectsQuery(baseOptions?: Apollo.QueryHookOptions<StaffingProjectsQuery, StaffingProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingProjectsQuery, StaffingProjectsQueryVariables>(StaffingProjectsDocument, options);
      }
export function useStaffingProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingProjectsQuery, StaffingProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingProjectsQuery, StaffingProjectsQueryVariables>(StaffingProjectsDocument, options);
        }
export type StaffingProjectsQueryHookResult = ReturnType<typeof useStaffingProjectsQuery>;
export type StaffingProjectsLazyQueryHookResult = ReturnType<typeof useStaffingProjectsLazyQuery>;
export type StaffingProjectsQueryResult = Apollo.QueryResult<StaffingProjectsQuery, StaffingProjectsQueryVariables>;
export const StaffingEditPhaseDrawerDocument = gql`
    query StaffingEditPhaseDrawer($id: ID!) {
  phase(id: $id) {
    ...StaffingEditPhaseDrawerPhase
  }
}
    ${StaffingEditPhaseDrawerPhaseFragmentDoc}`;

/**
 * __useStaffingEditPhaseDrawerQuery__
 *
 * To run a query within a React component, call `useStaffingEditPhaseDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingEditPhaseDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingEditPhaseDrawerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffingEditPhaseDrawerQuery(baseOptions: Apollo.QueryHookOptions<StaffingEditPhaseDrawerQuery, StaffingEditPhaseDrawerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingEditPhaseDrawerQuery, StaffingEditPhaseDrawerQueryVariables>(StaffingEditPhaseDrawerDocument, options);
      }
export function useStaffingEditPhaseDrawerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingEditPhaseDrawerQuery, StaffingEditPhaseDrawerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingEditPhaseDrawerQuery, StaffingEditPhaseDrawerQueryVariables>(StaffingEditPhaseDrawerDocument, options);
        }
export type StaffingEditPhaseDrawerQueryHookResult = ReturnType<typeof useStaffingEditPhaseDrawerQuery>;
export type StaffingEditPhaseDrawerLazyQueryHookResult = ReturnType<typeof useStaffingEditPhaseDrawerLazyQuery>;
export type StaffingEditPhaseDrawerQueryResult = Apollo.QueryResult<StaffingEditPhaseDrawerQuery, StaffingEditPhaseDrawerQueryVariables>;
export const StaffingUpdateRoleHoursDocument = gql`
    mutation StaffingUpdateRoleHours($input: UpdateRoleHoursInput!) {
  updateRoleHours(input: $input) {
    role {
      id
      name
      hours
      budget
    }
  }
}
    `;
export type StaffingUpdateRoleHoursMutationFn = Apollo.MutationFunction<StaffingUpdateRoleHoursMutation, StaffingUpdateRoleHoursMutationVariables>;

/**
 * __useStaffingUpdateRoleHoursMutation__
 *
 * To run a mutation, you first call `useStaffingUpdateRoleHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffingUpdateRoleHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffingUpdateRoleHoursMutation, { data, loading, error }] = useStaffingUpdateRoleHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffingUpdateRoleHoursMutation(baseOptions?: Apollo.MutationHookOptions<StaffingUpdateRoleHoursMutation, StaffingUpdateRoleHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffingUpdateRoleHoursMutation, StaffingUpdateRoleHoursMutationVariables>(StaffingUpdateRoleHoursDocument, options);
      }
export type StaffingUpdateRoleHoursMutationHookResult = ReturnType<typeof useStaffingUpdateRoleHoursMutation>;
export type StaffingUpdateRoleHoursMutationResult = Apollo.MutationResult<StaffingUpdateRoleHoursMutation>;
export type StaffingUpdateRoleHoursMutationOptions = Apollo.BaseMutationOptions<StaffingUpdateRoleHoursMutation, StaffingUpdateRoleHoursMutationVariables>;
export const ProjectStaffingProjectBudgetDocument = gql`
    query ProjectStaffingProjectBudget($slug: String!) {
  project(slug: $slug) {
    ...ProjectStaffingProjectBudget
  }
}
    ${ProjectStaffingProjectBudgetFragmentDoc}`;

/**
 * __useProjectStaffingProjectBudgetQuery__
 *
 * To run a query within a React component, call `useProjectStaffingProjectBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStaffingProjectBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStaffingProjectBudgetQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectStaffingProjectBudgetQuery(baseOptions: Apollo.QueryHookOptions<ProjectStaffingProjectBudgetQuery, ProjectStaffingProjectBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStaffingProjectBudgetQuery, ProjectStaffingProjectBudgetQueryVariables>(ProjectStaffingProjectBudgetDocument, options);
      }
export function useProjectStaffingProjectBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStaffingProjectBudgetQuery, ProjectStaffingProjectBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStaffingProjectBudgetQuery, ProjectStaffingProjectBudgetQueryVariables>(ProjectStaffingProjectBudgetDocument, options);
        }
export type ProjectStaffingProjectBudgetQueryHookResult = ReturnType<typeof useProjectStaffingProjectBudgetQuery>;
export type ProjectStaffingProjectBudgetLazyQueryHookResult = ReturnType<typeof useProjectStaffingProjectBudgetLazyQuery>;
export type ProjectStaffingProjectBudgetQueryResult = Apollo.QueryResult<ProjectStaffingProjectBudgetQuery, ProjectStaffingProjectBudgetQueryVariables>;
export const ProjectStaffingPhaseRolesDocument = gql`
    query ProjectStaffingPhaseRoles($id: ID!) {
  phase(id: $id) {
    id
    roles {
      ...ProjectStaffingPhaseRole
    }
  }
}
    ${ProjectStaffingPhaseRoleFragmentDoc}`;

/**
 * __useProjectStaffingPhaseRolesQuery__
 *
 * To run a query within a React component, call `useProjectStaffingPhaseRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStaffingPhaseRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStaffingPhaseRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStaffingPhaseRolesQuery(baseOptions: Apollo.QueryHookOptions<ProjectStaffingPhaseRolesQuery, ProjectStaffingPhaseRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStaffingPhaseRolesQuery, ProjectStaffingPhaseRolesQueryVariables>(ProjectStaffingPhaseRolesDocument, options);
      }
export function useProjectStaffingPhaseRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStaffingPhaseRolesQuery, ProjectStaffingPhaseRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStaffingPhaseRolesQuery, ProjectStaffingPhaseRolesQueryVariables>(ProjectStaffingPhaseRolesDocument, options);
        }
export type ProjectStaffingPhaseRolesQueryHookResult = ReturnType<typeof useProjectStaffingPhaseRolesQuery>;
export type ProjectStaffingPhaseRolesLazyQueryHookResult = ReturnType<typeof useProjectStaffingPhaseRolesLazyQuery>;
export type ProjectStaffingPhaseRolesQueryResult = Apollo.QueryResult<ProjectStaffingPhaseRolesQuery, ProjectStaffingPhaseRolesQueryVariables>;
export const UpdatePhaseDatesDocument = gql`
    mutation UpdatePhaseDates($id: ID!, $beginDate: ISO8601Date, $endDate: ISO8601Date) {
  updatePhase(input: {id: $id, beginDate: $beginDate, endDate: $endDate}) {
    phase {
      id
      beginDate
      endDate
    }
  }
}
    `;
export type UpdatePhaseDatesMutationFn = Apollo.MutationFunction<UpdatePhaseDatesMutation, UpdatePhaseDatesMutationVariables>;

/**
 * __useUpdatePhaseDatesMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseDatesMutation, { data, loading, error }] = useUpdatePhaseDatesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdatePhaseDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhaseDatesMutation, UpdatePhaseDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhaseDatesMutation, UpdatePhaseDatesMutationVariables>(UpdatePhaseDatesDocument, options);
      }
export type UpdatePhaseDatesMutationHookResult = ReturnType<typeof useUpdatePhaseDatesMutation>;
export type UpdatePhaseDatesMutationResult = Apollo.MutationResult<UpdatePhaseDatesMutation>;
export type UpdatePhaseDatesMutationOptions = Apollo.BaseMutationOptions<UpdatePhaseDatesMutation, UpdatePhaseDatesMutationVariables>;
export const RemoveLinkedPhasesDocument = gql`
    mutation RemoveLinkedPhases($id: ID!) {
  removeLinkedPhases(input: {id: $id}) {
    phase {
      id
      linkedPhases {
        id
        name
      }
    }
  }
}
    `;
export type RemoveLinkedPhasesMutationFn = Apollo.MutationFunction<RemoveLinkedPhasesMutation, RemoveLinkedPhasesMutationVariables>;

/**
 * __useRemoveLinkedPhasesMutation__
 *
 * To run a mutation, you first call `useRemoveLinkedPhasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLinkedPhasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLinkedPhasesMutation, { data, loading, error }] = useRemoveLinkedPhasesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLinkedPhasesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLinkedPhasesMutation, RemoveLinkedPhasesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLinkedPhasesMutation, RemoveLinkedPhasesMutationVariables>(RemoveLinkedPhasesDocument, options);
      }
export type RemoveLinkedPhasesMutationHookResult = ReturnType<typeof useRemoveLinkedPhasesMutation>;
export type RemoveLinkedPhasesMutationResult = Apollo.MutationResult<RemoveLinkedPhasesMutation>;
export type RemoveLinkedPhasesMutationOptions = Apollo.BaseMutationOptions<RemoveLinkedPhasesMutation, RemoveLinkedPhasesMutationVariables>;
export const ProjectStaffingPhaseRoleRowsDocument = gql`
    query ProjectStaffingPhaseRoleRows($id: ID!) {
  phase(id: $id) {
    id
    roles {
      ...ProjectStaffingPhaseRoleRow
    }
  }
}
    ${ProjectStaffingPhaseRoleRowFragmentDoc}`;

/**
 * __useProjectStaffingPhaseRoleRowsQuery__
 *
 * To run a query within a React component, call `useProjectStaffingPhaseRoleRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStaffingPhaseRoleRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStaffingPhaseRoleRowsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStaffingPhaseRoleRowsQuery(baseOptions: Apollo.QueryHookOptions<ProjectStaffingPhaseRoleRowsQuery, ProjectStaffingPhaseRoleRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStaffingPhaseRoleRowsQuery, ProjectStaffingPhaseRoleRowsQueryVariables>(ProjectStaffingPhaseRoleRowsDocument, options);
      }
export function useProjectStaffingPhaseRoleRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStaffingPhaseRoleRowsQuery, ProjectStaffingPhaseRoleRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStaffingPhaseRoleRowsQuery, ProjectStaffingPhaseRoleRowsQueryVariables>(ProjectStaffingPhaseRoleRowsDocument, options);
        }
export type ProjectStaffingPhaseRoleRowsQueryHookResult = ReturnType<typeof useProjectStaffingPhaseRoleRowsQuery>;
export type ProjectStaffingPhaseRoleRowsLazyQueryHookResult = ReturnType<typeof useProjectStaffingPhaseRoleRowsLazyQuery>;
export type ProjectStaffingPhaseRoleRowsQueryResult = Apollo.QueryResult<ProjectStaffingPhaseRoleRowsQuery, ProjectStaffingPhaseRoleRowsQueryVariables>;
export const ProjectStaffingRoleCardDocument = gql`
    query ProjectStaffingRoleCard($id: ID!) {
  role(id: $id) {
    ...RebalanceModalRole
  }
}
    ${RebalanceModalRoleFragmentDoc}`;

/**
 * __useProjectStaffingRoleCardQuery__
 *
 * To run a query within a React component, call `useProjectStaffingRoleCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStaffingRoleCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStaffingRoleCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectStaffingRoleCardQuery(baseOptions: Apollo.QueryHookOptions<ProjectStaffingRoleCardQuery, ProjectStaffingRoleCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStaffingRoleCardQuery, ProjectStaffingRoleCardQueryVariables>(ProjectStaffingRoleCardDocument, options);
      }
export function useProjectStaffingRoleCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStaffingRoleCardQuery, ProjectStaffingRoleCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStaffingRoleCardQuery, ProjectStaffingRoleCardQueryVariables>(ProjectStaffingRoleCardDocument, options);
        }
export type ProjectStaffingRoleCardQueryHookResult = ReturnType<typeof useProjectStaffingRoleCardQuery>;
export type ProjectStaffingRoleCardLazyQueryHookResult = ReturnType<typeof useProjectStaffingRoleCardLazyQuery>;
export type ProjectStaffingRoleCardQueryResult = Apollo.QueryResult<ProjectStaffingRoleCardQuery, ProjectStaffingRoleCardQueryVariables>;
export const StaffingProjectPageProjectDocument = gql`
    query StaffingProjectPageProject($slug: String!) {
  project(slug: $slug) {
    id
    name
    beginDate
    endDate
    hoursAllocated
    budgetAllocated
    phases {
      ...StaffingProjectPagePhase
    }
  }
}
    ${StaffingProjectPagePhaseFragmentDoc}`;

/**
 * __useStaffingProjectPageProjectQuery__
 *
 * To run a query within a React component, call `useStaffingProjectPageProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingProjectPageProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingProjectPageProjectQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStaffingProjectPageProjectQuery(baseOptions: Apollo.QueryHookOptions<StaffingProjectPageProjectQuery, StaffingProjectPageProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingProjectPageProjectQuery, StaffingProjectPageProjectQueryVariables>(StaffingProjectPageProjectDocument, options);
      }
export function useStaffingProjectPageProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingProjectPageProjectQuery, StaffingProjectPageProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingProjectPageProjectQuery, StaffingProjectPageProjectQueryVariables>(StaffingProjectPageProjectDocument, options);
        }
export type StaffingProjectPageProjectQueryHookResult = ReturnType<typeof useStaffingProjectPageProjectQuery>;
export type StaffingProjectPageProjectLazyQueryHookResult = ReturnType<typeof useStaffingProjectPageProjectLazyQuery>;
export type StaffingProjectPageProjectQueryResult = Apollo.QueryResult<StaffingProjectPageProjectQuery, StaffingProjectPageProjectQueryVariables>;
export const DetailsHoverCardProjectDocument = gql`
    query DetailsHoverCardProject($id: ID!) {
  project(id: $id) {
    ...DetailsHoverCardProject
  }
}
    ${DetailsHoverCardProjectFragmentDoc}`;

/**
 * __useDetailsHoverCardProjectQuery__
 *
 * To run a query within a React component, call `useDetailsHoverCardProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailsHoverCardProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailsHoverCardProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailsHoverCardProjectQuery(baseOptions: Apollo.QueryHookOptions<DetailsHoverCardProjectQuery, DetailsHoverCardProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailsHoverCardProjectQuery, DetailsHoverCardProjectQueryVariables>(DetailsHoverCardProjectDocument, options);
      }
export function useDetailsHoverCardProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailsHoverCardProjectQuery, DetailsHoverCardProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailsHoverCardProjectQuery, DetailsHoverCardProjectQueryVariables>(DetailsHoverCardProjectDocument, options);
        }
export type DetailsHoverCardProjectQueryHookResult = ReturnType<typeof useDetailsHoverCardProjectQuery>;
export type DetailsHoverCardProjectLazyQueryHookResult = ReturnType<typeof useDetailsHoverCardProjectLazyQuery>;
export type DetailsHoverCardProjectQueryResult = Apollo.QueryResult<DetailsHoverCardProjectQuery, DetailsHoverCardProjectQueryVariables>;
export const DetailsHoverCardPhaseDocument = gql`
    query DetailsHoverCardPhase($id: ID!) {
  phase(id: $id) {
    ...DetailsHoverCardPhase
  }
}
    ${DetailsHoverCardPhaseFragmentDoc}`;

/**
 * __useDetailsHoverCardPhaseQuery__
 *
 * To run a query within a React component, call `useDetailsHoverCardPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailsHoverCardPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailsHoverCardPhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailsHoverCardPhaseQuery(baseOptions: Apollo.QueryHookOptions<DetailsHoverCardPhaseQuery, DetailsHoverCardPhaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailsHoverCardPhaseQuery, DetailsHoverCardPhaseQueryVariables>(DetailsHoverCardPhaseDocument, options);
      }
export function useDetailsHoverCardPhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailsHoverCardPhaseQuery, DetailsHoverCardPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailsHoverCardPhaseQuery, DetailsHoverCardPhaseQueryVariables>(DetailsHoverCardPhaseDocument, options);
        }
export type DetailsHoverCardPhaseQueryHookResult = ReturnType<typeof useDetailsHoverCardPhaseQuery>;
export type DetailsHoverCardPhaseLazyQueryHookResult = ReturnType<typeof useDetailsHoverCardPhaseLazyQuery>;
export type DetailsHoverCardPhaseQueryResult = Apollo.QueryResult<DetailsHoverCardPhaseQuery, DetailsHoverCardPhaseQueryVariables>;
export const DetailsHoverCardRoleDocument = gql`
    query DetailsHoverCardRole($id: ID!) {
  role(id: $id) {
    ...DetailsHoverCardRole
  }
}
    ${DetailsHoverCardRoleFragmentDoc}`;

/**
 * __useDetailsHoverCardRoleQuery__
 *
 * To run a query within a React component, call `useDetailsHoverCardRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailsHoverCardRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailsHoverCardRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailsHoverCardRoleQuery(baseOptions: Apollo.QueryHookOptions<DetailsHoverCardRoleQuery, DetailsHoverCardRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailsHoverCardRoleQuery, DetailsHoverCardRoleQueryVariables>(DetailsHoverCardRoleDocument, options);
      }
export function useDetailsHoverCardRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailsHoverCardRoleQuery, DetailsHoverCardRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailsHoverCardRoleQuery, DetailsHoverCardRoleQueryVariables>(DetailsHoverCardRoleDocument, options);
        }
export type DetailsHoverCardRoleQueryHookResult = ReturnType<typeof useDetailsHoverCardRoleQuery>;
export type DetailsHoverCardRoleLazyQueryHookResult = ReturnType<typeof useDetailsHoverCardRoleLazyQuery>;
export type DetailsHoverCardRoleQueryResult = Apollo.QueryResult<DetailsHoverCardRoleQuery, DetailsHoverCardRoleQueryVariables>;
export const EmptyHoverCardRoleDocument = gql`
    query EmptyHoverCardRole($roleId: ID!) {
  role(id: $roleId) {
    id
    project {
      id
      slug
    }
    phase {
      id
    }
  }
}
    `;

/**
 * __useEmptyHoverCardRoleQuery__
 *
 * To run a query within a React component, call `useEmptyHoverCardRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmptyHoverCardRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmptyHoverCardRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEmptyHoverCardRoleQuery(baseOptions: Apollo.QueryHookOptions<EmptyHoverCardRoleQuery, EmptyHoverCardRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmptyHoverCardRoleQuery, EmptyHoverCardRoleQueryVariables>(EmptyHoverCardRoleDocument, options);
      }
export function useEmptyHoverCardRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmptyHoverCardRoleQuery, EmptyHoverCardRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmptyHoverCardRoleQuery, EmptyHoverCardRoleQueryVariables>(EmptyHoverCardRoleDocument, options);
        }
export type EmptyHoverCardRoleQueryHookResult = ReturnType<typeof useEmptyHoverCardRoleQuery>;
export type EmptyHoverCardRoleLazyQueryHookResult = ReturnType<typeof useEmptyHoverCardRoleLazyQuery>;
export type EmptyHoverCardRoleQueryResult = Apollo.QueryResult<EmptyHoverCardRoleQuery, EmptyHoverCardRoleQueryVariables>;
export const DetailsHovercardRebalanceModalPhaseDocument = gql`
    query DetailsHovercardRebalanceModalPhase($id: ID!) {
  phase(id: $id) {
    ...DetailsHovercardRebalanceModalPhase
  }
}
    ${DetailsHovercardRebalanceModalPhaseFragmentDoc}`;

/**
 * __useDetailsHovercardRebalanceModalPhaseQuery__
 *
 * To run a query within a React component, call `useDetailsHovercardRebalanceModalPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailsHovercardRebalanceModalPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailsHovercardRebalanceModalPhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetailsHovercardRebalanceModalPhaseQuery(baseOptions: Apollo.QueryHookOptions<DetailsHovercardRebalanceModalPhaseQuery, DetailsHovercardRebalanceModalPhaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailsHovercardRebalanceModalPhaseQuery, DetailsHovercardRebalanceModalPhaseQueryVariables>(DetailsHovercardRebalanceModalPhaseDocument, options);
      }
export function useDetailsHovercardRebalanceModalPhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailsHovercardRebalanceModalPhaseQuery, DetailsHovercardRebalanceModalPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailsHovercardRebalanceModalPhaseQuery, DetailsHovercardRebalanceModalPhaseQueryVariables>(DetailsHovercardRebalanceModalPhaseDocument, options);
        }
export type DetailsHovercardRebalanceModalPhaseQueryHookResult = ReturnType<typeof useDetailsHovercardRebalanceModalPhaseQuery>;
export type DetailsHovercardRebalanceModalPhaseLazyQueryHookResult = ReturnType<typeof useDetailsHovercardRebalanceModalPhaseLazyQuery>;
export type DetailsHovercardRebalanceModalPhaseQueryResult = Apollo.QueryResult<DetailsHovercardRebalanceModalPhaseQuery, DetailsHovercardRebalanceModalPhaseQueryVariables>;
export const GlobalRebalanceAllocationsPreviewDocument = gql`
    query GlobalRebalanceAllocationsPreview($profileIds: [ID!], $dateRange: DateRangeInput!) {
  globalRebalanceAllocationsPreview(
    profileIds: $profileIds
    dateRange: $dateRange
  ) {
    phasesCount
    rolesCount
    existingJobBatchBid
  }
}
    `;

/**
 * __useGlobalRebalanceAllocationsPreviewQuery__
 *
 * To run a query within a React component, call `useGlobalRebalanceAllocationsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalRebalanceAllocationsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalRebalanceAllocationsPreviewQuery({
 *   variables: {
 *      profileIds: // value for 'profileIds'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGlobalRebalanceAllocationsPreviewQuery(baseOptions: Apollo.QueryHookOptions<GlobalRebalanceAllocationsPreviewQuery, GlobalRebalanceAllocationsPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalRebalanceAllocationsPreviewQuery, GlobalRebalanceAllocationsPreviewQueryVariables>(GlobalRebalanceAllocationsPreviewDocument, options);
      }
export function useGlobalRebalanceAllocationsPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalRebalanceAllocationsPreviewQuery, GlobalRebalanceAllocationsPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalRebalanceAllocationsPreviewQuery, GlobalRebalanceAllocationsPreviewQueryVariables>(GlobalRebalanceAllocationsPreviewDocument, options);
        }
export type GlobalRebalanceAllocationsPreviewQueryHookResult = ReturnType<typeof useGlobalRebalanceAllocationsPreviewQuery>;
export type GlobalRebalanceAllocationsPreviewLazyQueryHookResult = ReturnType<typeof useGlobalRebalanceAllocationsPreviewLazyQuery>;
export type GlobalRebalanceAllocationsPreviewQueryResult = Apollo.QueryResult<GlobalRebalanceAllocationsPreviewQuery, GlobalRebalanceAllocationsPreviewQueryVariables>;
export const GlobalRebalancePhaseDocument = gql`
    query GlobalRebalancePhase($id: ID!) {
  phase(id: $id) {
    ...GlobalRebalancePhase
  }
}
    ${GlobalRebalancePhaseFragmentDoc}`;

/**
 * __useGlobalRebalancePhaseQuery__
 *
 * To run a query within a React component, call `useGlobalRebalancePhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalRebalancePhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalRebalancePhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGlobalRebalancePhaseQuery(baseOptions: Apollo.QueryHookOptions<GlobalRebalancePhaseQuery, GlobalRebalancePhaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalRebalancePhaseQuery, GlobalRebalancePhaseQueryVariables>(GlobalRebalancePhaseDocument, options);
      }
export function useGlobalRebalancePhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalRebalancePhaseQuery, GlobalRebalancePhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalRebalancePhaseQuery, GlobalRebalancePhaseQueryVariables>(GlobalRebalancePhaseDocument, options);
        }
export type GlobalRebalancePhaseQueryHookResult = ReturnType<typeof useGlobalRebalancePhaseQuery>;
export type GlobalRebalancePhaseLazyQueryHookResult = ReturnType<typeof useGlobalRebalancePhaseLazyQuery>;
export type GlobalRebalancePhaseQueryResult = Apollo.QueryResult<GlobalRebalancePhaseQuery, GlobalRebalancePhaseQueryVariables>;
export const AddTeamMemberProfilesDocument = gql`
    query AddTeamMemberProfiles {
  currentProfile {
    organization {
      profiles {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useAddTeamMemberProfilesQuery__
 *
 * To run a query within a React component, call `useAddTeamMemberProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTeamMemberProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddTeamMemberProfilesQuery(baseOptions?: Apollo.QueryHookOptions<AddTeamMemberProfilesQuery, AddTeamMemberProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTeamMemberProfilesQuery, AddTeamMemberProfilesQueryVariables>(AddTeamMemberProfilesDocument, options);
      }
export function useAddTeamMemberProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTeamMemberProfilesQuery, AddTeamMemberProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTeamMemberProfilesQuery, AddTeamMemberProfilesQueryVariables>(AddTeamMemberProfilesDocument, options);
        }
export type AddTeamMemberProfilesQueryHookResult = ReturnType<typeof useAddTeamMemberProfilesQuery>;
export type AddTeamMemberProfilesLazyQueryHookResult = ReturnType<typeof useAddTeamMemberProfilesLazyQuery>;
export type AddTeamMemberProfilesQueryResult = Apollo.QueryResult<AddTeamMemberProfilesQuery, AddTeamMemberProfilesQueryVariables>;
export const AddTeamMemberRolesDocument = gql`
    query AddTeamMemberRoles($id: ID!) {
  phase(id: $id) {
    id
    name
    roles {
      id
      profile {
        id
      }
      roleTemplate {
        id
      }
      rateTable {
        id
      }
    }
  }
}
    `;

/**
 * __useAddTeamMemberRolesQuery__
 *
 * To run a query within a React component, call `useAddTeamMemberRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTeamMemberRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddTeamMemberRolesQuery(baseOptions: Apollo.QueryHookOptions<AddTeamMemberRolesQuery, AddTeamMemberRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTeamMemberRolesQuery, AddTeamMemberRolesQueryVariables>(AddTeamMemberRolesDocument, options);
      }
export function useAddTeamMemberRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTeamMemberRolesQuery, AddTeamMemberRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTeamMemberRolesQuery, AddTeamMemberRolesQueryVariables>(AddTeamMemberRolesDocument, options);
        }
export type AddTeamMemberRolesQueryHookResult = ReturnType<typeof useAddTeamMemberRolesQuery>;
export type AddTeamMemberRolesLazyQueryHookResult = ReturnType<typeof useAddTeamMemberRolesLazyQuery>;
export type AddTeamMemberRolesQueryResult = Apollo.QueryResult<AddTeamMemberRolesQuery, AddTeamMemberRolesQueryVariables>;
export const AssignOverheadModalOverheadsDocument = gql`
    query assignOverheadModalOverheads($id: ID!) {
  profile(id: $id) {
    organization {
      overheads(excludeTimeOffOverheads: true) {
        ...AssignOverheadModalOverhead
      }
    }
  }
}
    ${AssignOverheadModalOverheadFragmentDoc}`;

/**
 * __useAssignOverheadModalOverheadsQuery__
 *
 * To run a query within a React component, call `useAssignOverheadModalOverheadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignOverheadModalOverheadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignOverheadModalOverheadsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignOverheadModalOverheadsQuery(baseOptions: Apollo.QueryHookOptions<AssignOverheadModalOverheadsQuery, AssignOverheadModalOverheadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignOverheadModalOverheadsQuery, AssignOverheadModalOverheadsQueryVariables>(AssignOverheadModalOverheadsDocument, options);
      }
export function useAssignOverheadModalOverheadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignOverheadModalOverheadsQuery, AssignOverheadModalOverheadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignOverheadModalOverheadsQuery, AssignOverheadModalOverheadsQueryVariables>(AssignOverheadModalOverheadsDocument, options);
        }
export type AssignOverheadModalOverheadsQueryHookResult = ReturnType<typeof useAssignOverheadModalOverheadsQuery>;
export type AssignOverheadModalOverheadsLazyQueryHookResult = ReturnType<typeof useAssignOverheadModalOverheadsLazyQuery>;
export type AssignOverheadModalOverheadsQueryResult = Apollo.QueryResult<AssignOverheadModalOverheadsQuery, AssignOverheadModalOverheadsQueryVariables>;
export const BulkCreateOverheadEntryGroupsDocument = gql`
    mutation bulkCreateOverheadEntryGroups($input: BulkCreateOverheadEntryGroupsInput!) {
  bulkCreateEntryGroupsWithOverhead(input: $input) {
    timesheets {
      id
    }
  }
}
    `;
export type BulkCreateOverheadEntryGroupsMutationFn = Apollo.MutationFunction<BulkCreateOverheadEntryGroupsMutation, BulkCreateOverheadEntryGroupsMutationVariables>;

/**
 * __useBulkCreateOverheadEntryGroupsMutation__
 *
 * To run a mutation, you first call `useBulkCreateOverheadEntryGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateOverheadEntryGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateOverheadEntryGroupsMutation, { data, loading, error }] = useBulkCreateOverheadEntryGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateOverheadEntryGroupsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateOverheadEntryGroupsMutation, BulkCreateOverheadEntryGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateOverheadEntryGroupsMutation, BulkCreateOverheadEntryGroupsMutationVariables>(BulkCreateOverheadEntryGroupsDocument, options);
      }
export type BulkCreateOverheadEntryGroupsMutationHookResult = ReturnType<typeof useBulkCreateOverheadEntryGroupsMutation>;
export type BulkCreateOverheadEntryGroupsMutationResult = Apollo.MutationResult<BulkCreateOverheadEntryGroupsMutation>;
export type BulkCreateOverheadEntryGroupsMutationOptions = Apollo.BaseMutationOptions<BulkCreateOverheadEntryGroupsMutation, BulkCreateOverheadEntryGroupsMutationVariables>;
export const UpdateAssignedPhaseDocument = gql`
    mutation updateAssignedPhase($input: UpdatePhaseInput!) {
  updatePhase(input: $input) {
    phase {
      id
    }
  }
}
    `;
export type UpdateAssignedPhaseMutationFn = Apollo.MutationFunction<UpdateAssignedPhaseMutation, UpdateAssignedPhaseMutationVariables>;

/**
 * __useUpdateAssignedPhaseMutation__
 *
 * To run a mutation, you first call `useUpdateAssignedPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignedPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignedPhaseMutation, { data, loading, error }] = useUpdateAssignedPhaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignedPhaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignedPhaseMutation, UpdateAssignedPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignedPhaseMutation, UpdateAssignedPhaseMutationVariables>(UpdateAssignedPhaseDocument, options);
      }
export type UpdateAssignedPhaseMutationHookResult = ReturnType<typeof useUpdateAssignedPhaseMutation>;
export type UpdateAssignedPhaseMutationResult = Apollo.MutationResult<UpdateAssignedPhaseMutation>;
export type UpdateAssignedPhaseMutationOptions = Apollo.BaseMutationOptions<UpdateAssignedPhaseMutation, UpdateAssignedPhaseMutationVariables>;
export const AssignPhaseProjectSearchDocument = gql`
    query AssignPhaseProjectSearch($parameters: SearchableProjectsParametersInput!) {
  searchableProjects(parameters: $parameters) {
    ...AssignPhaseProjectSearchProject
  }
}
    ${AssignPhaseProjectSearchProjectFragmentDoc}`;

/**
 * __useAssignPhaseProjectSearchQuery__
 *
 * To run a query within a React component, call `useAssignPhaseProjectSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignPhaseProjectSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignPhaseProjectSearchQuery({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useAssignPhaseProjectSearchQuery(baseOptions: Apollo.QueryHookOptions<AssignPhaseProjectSearchQuery, AssignPhaseProjectSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignPhaseProjectSearchQuery, AssignPhaseProjectSearchQueryVariables>(AssignPhaseProjectSearchDocument, options);
      }
export function useAssignPhaseProjectSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignPhaseProjectSearchQuery, AssignPhaseProjectSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignPhaseProjectSearchQuery, AssignPhaseProjectSearchQueryVariables>(AssignPhaseProjectSearchDocument, options);
        }
export type AssignPhaseProjectSearchQueryHookResult = ReturnType<typeof useAssignPhaseProjectSearchQuery>;
export type AssignPhaseProjectSearchLazyQueryHookResult = ReturnType<typeof useAssignPhaseProjectSearchLazyQuery>;
export type AssignPhaseProjectSearchQueryResult = Apollo.QueryResult<AssignPhaseProjectSearchQuery, AssignPhaseProjectSearchQueryVariables>;
export const RoleAndRateFieldsProfileDocument = gql`
    query RoleAndRateFieldsProfile($id: ID!) {
  profile(id: $id) {
    ...RoleAndRateFieldsProfile
  }
}
    ${RoleAndRateFieldsProfileFragmentDoc}`;

/**
 * __useRoleAndRateFieldsProfileQuery__
 *
 * To run a query within a React component, call `useRoleAndRateFieldsProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleAndRateFieldsProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleAndRateFieldsProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleAndRateFieldsProfileQuery(baseOptions: Apollo.QueryHookOptions<RoleAndRateFieldsProfileQuery, RoleAndRateFieldsProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleAndRateFieldsProfileQuery, RoleAndRateFieldsProfileQueryVariables>(RoleAndRateFieldsProfileDocument, options);
      }
export function useRoleAndRateFieldsProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleAndRateFieldsProfileQuery, RoleAndRateFieldsProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleAndRateFieldsProfileQuery, RoleAndRateFieldsProfileQueryVariables>(RoleAndRateFieldsProfileDocument, options);
        }
export type RoleAndRateFieldsProfileQueryHookResult = ReturnType<typeof useRoleAndRateFieldsProfileQuery>;
export type RoleAndRateFieldsProfileLazyQueryHookResult = ReturnType<typeof useRoleAndRateFieldsProfileLazyQuery>;
export type RoleAndRateFieldsProfileQueryResult = Apollo.QueryResult<RoleAndRateFieldsProfileQuery, RoleAndRateFieldsProfileQueryVariables>;
export const EditTimeOffRequestDocument = gql`
    mutation EditTimeOffRequest($input: EditTimeOffRequestInput!) {
  editTimeOffRequest(input: $input) {
    timeOffRequest {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;
export type EditTimeOffRequestMutationFn = Apollo.MutationFunction<EditTimeOffRequestMutation, EditTimeOffRequestMutationVariables>;

/**
 * __useEditTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useEditTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeOffRequestMutation, { data, loading, error }] = useEditTimeOffRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTimeOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<EditTimeOffRequestMutation, EditTimeOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTimeOffRequestMutation, EditTimeOffRequestMutationVariables>(EditTimeOffRequestDocument, options);
      }
export type EditTimeOffRequestMutationHookResult = ReturnType<typeof useEditTimeOffRequestMutation>;
export type EditTimeOffRequestMutationResult = Apollo.MutationResult<EditTimeOffRequestMutation>;
export type EditTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<EditTimeOffRequestMutation, EditTimeOffRequestMutationVariables>;
export const MyTimeOffPolicyAssignmentsDocument = gql`
    query MyTimeOffPolicyAssignments {
  currentProfile {
    id
    slug
    timeOffPolicyAssignments {
      ...MyTimeOffPolicyAssignment
    }
  }
}
    ${MyTimeOffPolicyAssignmentFragmentDoc}`;

/**
 * __useMyTimeOffPolicyAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyTimeOffPolicyAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTimeOffPolicyAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTimeOffPolicyAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTimeOffPolicyAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<MyTimeOffPolicyAssignmentsQuery, MyTimeOffPolicyAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTimeOffPolicyAssignmentsQuery, MyTimeOffPolicyAssignmentsQueryVariables>(MyTimeOffPolicyAssignmentsDocument, options);
      }
export function useMyTimeOffPolicyAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTimeOffPolicyAssignmentsQuery, MyTimeOffPolicyAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTimeOffPolicyAssignmentsQuery, MyTimeOffPolicyAssignmentsQueryVariables>(MyTimeOffPolicyAssignmentsDocument, options);
        }
export type MyTimeOffPolicyAssignmentsQueryHookResult = ReturnType<typeof useMyTimeOffPolicyAssignmentsQuery>;
export type MyTimeOffPolicyAssignmentsLazyQueryHookResult = ReturnType<typeof useMyTimeOffPolicyAssignmentsLazyQuery>;
export type MyTimeOffPolicyAssignmentsQueryResult = Apollo.QueryResult<MyTimeOffPolicyAssignmentsQuery, MyTimeOffPolicyAssignmentsQueryVariables>;
export const UpcomingRequestsTableDocument = gql`
    query UpcomingRequestsTable {
  currentProfile {
    id
    timeOffRequests {
      ...UpcomingRequestsTableRequest
    }
  }
}
    ${UpcomingRequestsTableRequestFragmentDoc}`;

/**
 * __useUpcomingRequestsTableQuery__
 *
 * To run a query within a React component, call `useUpcomingRequestsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingRequestsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingRequestsTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingRequestsTableQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingRequestsTableQuery, UpcomingRequestsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingRequestsTableQuery, UpcomingRequestsTableQueryVariables>(UpcomingRequestsTableDocument, options);
      }
export function useUpcomingRequestsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingRequestsTableQuery, UpcomingRequestsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingRequestsTableQuery, UpcomingRequestsTableQueryVariables>(UpcomingRequestsTableDocument, options);
        }
export type UpcomingRequestsTableQueryHookResult = ReturnType<typeof useUpcomingRequestsTableQuery>;
export type UpcomingRequestsTableLazyQueryHookResult = ReturnType<typeof useUpcomingRequestsTableLazyQuery>;
export type UpcomingRequestsTableQueryResult = Apollo.QueryResult<UpcomingRequestsTableQuery, UpcomingRequestsTableQueryVariables>;
export const SpecificApproversDocument = gql`
    query SpecificApprovers {
  profiles(filters: {statuses: [ACTIVE]}) {
    nodes {
      id
      fullName
      policy {
        permissions {
          canManagePto
        }
      }
    }
  }
}
    `;

/**
 * __useSpecificApproversQuery__
 *
 * To run a query within a React component, call `useSpecificApproversQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificApproversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificApproversQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecificApproversQuery(baseOptions?: Apollo.QueryHookOptions<SpecificApproversQuery, SpecificApproversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificApproversQuery, SpecificApproversQueryVariables>(SpecificApproversDocument, options);
      }
export function useSpecificApproversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificApproversQuery, SpecificApproversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificApproversQuery, SpecificApproversQueryVariables>(SpecificApproversDocument, options);
        }
export type SpecificApproversQueryHookResult = ReturnType<typeof useSpecificApproversQuery>;
export type SpecificApproversLazyQueryHookResult = ReturnType<typeof useSpecificApproversLazyQuery>;
export type SpecificApproversQueryResult = Apollo.QueryResult<SpecificApproversQuery, SpecificApproversQueryVariables>;
export const BulkEditTimesheetsDocument = gql`
    query BulkEditTimesheets($filters: EntryFiltersInput!, $first: Int, $after: String, $before: String, $sortBy: EntriesSortInput) {
  entries(
    filters: $filters
    intent: GLOBAL
    first: $first
    after: $after
    before: $before
    sortBy: $sortBy
  ) {
    totalCount
    nodes {
      ...BulkEditTimesheetEntry
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${BulkEditTimesheetEntryFragmentDoc}`;

/**
 * __useBulkEditTimesheetsQuery__
 *
 * To run a query within a React component, call `useBulkEditTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditTimesheetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBulkEditTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<BulkEditTimesheetsQuery, BulkEditTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditTimesheetsQuery, BulkEditTimesheetsQueryVariables>(BulkEditTimesheetsDocument, options);
      }
export function useBulkEditTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditTimesheetsQuery, BulkEditTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditTimesheetsQuery, BulkEditTimesheetsQueryVariables>(BulkEditTimesheetsDocument, options);
        }
export type BulkEditTimesheetsQueryHookResult = ReturnType<typeof useBulkEditTimesheetsQuery>;
export type BulkEditTimesheetsLazyQueryHookResult = ReturnType<typeof useBulkEditTimesheetsLazyQuery>;
export type BulkEditTimesheetsQueryResult = Apollo.QueryResult<BulkEditTimesheetsQuery, BulkEditTimesheetsQueryVariables>;
export const BulkEditFiltersDocument = gql`
    query BulkEditFilters {
  activities {
    id
    name
  }
  projects {
    ...BulkEditEntryProject
  }
  phaseTemplates {
    id
    name
  }
  roleTemplates {
    id
    name
  }
}
    ${BulkEditEntryProjectFragmentDoc}`;

/**
 * __useBulkEditFiltersQuery__
 *
 * To run a query within a React component, call `useBulkEditFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBulkEditFiltersQuery(baseOptions?: Apollo.QueryHookOptions<BulkEditFiltersQuery, BulkEditFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditFiltersQuery, BulkEditFiltersQueryVariables>(BulkEditFiltersDocument, options);
      }
export function useBulkEditFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditFiltersQuery, BulkEditFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditFiltersQuery, BulkEditFiltersQueryVariables>(BulkEditFiltersDocument, options);
        }
export type BulkEditFiltersQueryHookResult = ReturnType<typeof useBulkEditFiltersQuery>;
export type BulkEditFiltersLazyQueryHookResult = ReturnType<typeof useBulkEditFiltersLazyQuery>;
export type BulkEditFiltersQueryResult = Apollo.QueryResult<BulkEditFiltersQuery, BulkEditFiltersQueryVariables>;
export const BulkEditAvailableFiltersDocument = gql`
    query BulkEditAvailableFilters($filters: EntryFiltersInput!) {
  reports {
    bulkEdit(filters: $filters) {
      availableFilters {
        phases {
          label
          value
        }
        profiles {
          label
          value
        }
      }
    }
  }
}
    `;

/**
 * __useBulkEditAvailableFiltersQuery__
 *
 * To run a query within a React component, call `useBulkEditAvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditAvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditAvailableFiltersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBulkEditAvailableFiltersQuery(baseOptions: Apollo.QueryHookOptions<BulkEditAvailableFiltersQuery, BulkEditAvailableFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditAvailableFiltersQuery, BulkEditAvailableFiltersQueryVariables>(BulkEditAvailableFiltersDocument, options);
      }
export function useBulkEditAvailableFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditAvailableFiltersQuery, BulkEditAvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditAvailableFiltersQuery, BulkEditAvailableFiltersQueryVariables>(BulkEditAvailableFiltersDocument, options);
        }
export type BulkEditAvailableFiltersQueryHookResult = ReturnType<typeof useBulkEditAvailableFiltersQuery>;
export type BulkEditAvailableFiltersLazyQueryHookResult = ReturnType<typeof useBulkEditAvailableFiltersLazyQuery>;
export type BulkEditAvailableFiltersQueryResult = Apollo.QueryResult<BulkEditAvailableFiltersQuery, BulkEditAvailableFiltersQueryVariables>;
export const BulkEditAvailablePhasesDocument = gql`
    query BulkEditAvailablePhases($projectId: ID!) {
  project(id: $projectId) {
    id
    phases {
      id
      name
      abbreviation
    }
  }
}
    `;

/**
 * __useBulkEditAvailablePhasesQuery__
 *
 * To run a query within a React component, call `useBulkEditAvailablePhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditAvailablePhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditAvailablePhasesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBulkEditAvailablePhasesQuery(baseOptions: Apollo.QueryHookOptions<BulkEditAvailablePhasesQuery, BulkEditAvailablePhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditAvailablePhasesQuery, BulkEditAvailablePhasesQueryVariables>(BulkEditAvailablePhasesDocument, options);
      }
export function useBulkEditAvailablePhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditAvailablePhasesQuery, BulkEditAvailablePhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditAvailablePhasesQuery, BulkEditAvailablePhasesQueryVariables>(BulkEditAvailablePhasesDocument, options);
        }
export type BulkEditAvailablePhasesQueryHookResult = ReturnType<typeof useBulkEditAvailablePhasesQuery>;
export type BulkEditAvailablePhasesLazyQueryHookResult = ReturnType<typeof useBulkEditAvailablePhasesLazyQuery>;
export type BulkEditAvailablePhasesQueryResult = Apollo.QueryResult<BulkEditAvailablePhasesQuery, BulkEditAvailablePhasesQueryVariables>;
export const BulkEditEntryInformationDocument = gql`
    query BulkEditEntryInformation($filters: EntryFiltersInput!) {
  entries(filters: $filters, intent: GLOBAL) {
    nodes {
      id
      profile {
        id
      }
      timesheet {
        id
        isLocked
      }
    }
  }
}
    `;

/**
 * __useBulkEditEntryInformationQuery__
 *
 * To run a query within a React component, call `useBulkEditEntryInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditEntryInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditEntryInformationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBulkEditEntryInformationQuery(baseOptions: Apollo.QueryHookOptions<BulkEditEntryInformationQuery, BulkEditEntryInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditEntryInformationQuery, BulkEditEntryInformationQueryVariables>(BulkEditEntryInformationDocument, options);
      }
export function useBulkEditEntryInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditEntryInformationQuery, BulkEditEntryInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditEntryInformationQuery, BulkEditEntryInformationQueryVariables>(BulkEditEntryInformationDocument, options);
        }
export type BulkEditEntryInformationQueryHookResult = ReturnType<typeof useBulkEditEntryInformationQuery>;
export type BulkEditEntryInformationLazyQueryHookResult = ReturnType<typeof useBulkEditEntryInformationLazyQuery>;
export type BulkEditEntryInformationQueryResult = Apollo.QueryResult<BulkEditEntryInformationQuery, BulkEditEntryInformationQueryVariables>;
export const BulkEditPhaseInformationDocument = gql`
    query BulkEditPhaseInformation($phaseId: ID!) {
  phase(id: $phaseId) {
    id
    project {
      id
      profiles {
        id
      }
    }
  }
}
    `;

/**
 * __useBulkEditPhaseInformationQuery__
 *
 * To run a query within a React component, call `useBulkEditPhaseInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditPhaseInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditPhaseInformationQuery({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function useBulkEditPhaseInformationQuery(baseOptions: Apollo.QueryHookOptions<BulkEditPhaseInformationQuery, BulkEditPhaseInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditPhaseInformationQuery, BulkEditPhaseInformationQueryVariables>(BulkEditPhaseInformationDocument, options);
      }
export function useBulkEditPhaseInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditPhaseInformationQuery, BulkEditPhaseInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditPhaseInformationQuery, BulkEditPhaseInformationQueryVariables>(BulkEditPhaseInformationDocument, options);
        }
export type BulkEditPhaseInformationQueryHookResult = ReturnType<typeof useBulkEditPhaseInformationQuery>;
export type BulkEditPhaseInformationLazyQueryHookResult = ReturnType<typeof useBulkEditPhaseInformationLazyQuery>;
export type BulkEditPhaseInformationQueryResult = Apollo.QueryResult<BulkEditPhaseInformationQuery, BulkEditPhaseInformationQueryVariables>;
export const BulkEditProjectDocument = gql`
    query BulkEditProject($id: ID!) {
  project(id: $id) {
    ...BulkEditEntryProject
  }
}
    ${BulkEditEntryProjectFragmentDoc}`;

/**
 * __useBulkEditProjectQuery__
 *
 * To run a query within a React component, call `useBulkEditProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulkEditProjectQuery(baseOptions: Apollo.QueryHookOptions<BulkEditProjectQuery, BulkEditProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditProjectQuery, BulkEditProjectQueryVariables>(BulkEditProjectDocument, options);
      }
export function useBulkEditProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditProjectQuery, BulkEditProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditProjectQuery, BulkEditProjectQueryVariables>(BulkEditProjectDocument, options);
        }
export type BulkEditProjectQueryHookResult = ReturnType<typeof useBulkEditProjectQuery>;
export type BulkEditProjectLazyQueryHookResult = ReturnType<typeof useBulkEditProjectLazyQuery>;
export type BulkEditProjectQueryResult = Apollo.QueryResult<BulkEditProjectQuery, BulkEditProjectQueryVariables>;
export const BulkEditPhaseDocument = gql`
    query BulkEditPhase($id: ID!) {
  phase(id: $id) {
    ...BulkEditEntryPhase
  }
}
    ${BulkEditEntryPhaseFragmentDoc}`;

/**
 * __useBulkEditPhaseQuery__
 *
 * To run a query within a React component, call `useBulkEditPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditPhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulkEditPhaseQuery(baseOptions: Apollo.QueryHookOptions<BulkEditPhaseQuery, BulkEditPhaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditPhaseQuery, BulkEditPhaseQueryVariables>(BulkEditPhaseDocument, options);
      }
export function useBulkEditPhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditPhaseQuery, BulkEditPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditPhaseQuery, BulkEditPhaseQueryVariables>(BulkEditPhaseDocument, options);
        }
export type BulkEditPhaseQueryHookResult = ReturnType<typeof useBulkEditPhaseQuery>;
export type BulkEditPhaseLazyQueryHookResult = ReturnType<typeof useBulkEditPhaseLazyQuery>;
export type BulkEditPhaseQueryResult = Apollo.QueryResult<BulkEditPhaseQuery, BulkEditPhaseQueryVariables>;
export const BulkEditRoleTemplateDocument = gql`
    query BulkEditRoleTemplate($id: ID!) {
  roleTemplate(id: $id) {
    ...BulkEditEntryRoleTemplate
  }
}
    ${BulkEditEntryRoleTemplateFragmentDoc}`;

/**
 * __useBulkEditRoleTemplateQuery__
 *
 * To run a query within a React component, call `useBulkEditRoleTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditRoleTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditRoleTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBulkEditRoleTemplateQuery(baseOptions: Apollo.QueryHookOptions<BulkEditRoleTemplateQuery, BulkEditRoleTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditRoleTemplateQuery, BulkEditRoleTemplateQueryVariables>(BulkEditRoleTemplateDocument, options);
      }
export function useBulkEditRoleTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditRoleTemplateQuery, BulkEditRoleTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditRoleTemplateQuery, BulkEditRoleTemplateQueryVariables>(BulkEditRoleTemplateDocument, options);
        }
export type BulkEditRoleTemplateQueryHookResult = ReturnType<typeof useBulkEditRoleTemplateQuery>;
export type BulkEditRoleTemplateLazyQueryHookResult = ReturnType<typeof useBulkEditRoleTemplateLazyQuery>;
export type BulkEditRoleTemplateQueryResult = Apollo.QueryResult<BulkEditRoleTemplateQuery, BulkEditRoleTemplateQueryVariables>;
export const BulkEditActivitiesDocument = gql`
    query BulkEditActivities {
  activities {
    ...BulkEditEntryActivity
  }
}
    ${BulkEditEntryActivityFragmentDoc}`;

/**
 * __useBulkEditActivitiesQuery__
 *
 * To run a query within a React component, call `useBulkEditActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBulkEditActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<BulkEditActivitiesQuery, BulkEditActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkEditActivitiesQuery, BulkEditActivitiesQueryVariables>(BulkEditActivitiesDocument, options);
      }
export function useBulkEditActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkEditActivitiesQuery, BulkEditActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkEditActivitiesQuery, BulkEditActivitiesQueryVariables>(BulkEditActivitiesDocument, options);
        }
export type BulkEditActivitiesQueryHookResult = ReturnType<typeof useBulkEditActivitiesQuery>;
export type BulkEditActivitiesLazyQueryHookResult = ReturnType<typeof useBulkEditActivitiesLazyQuery>;
export type BulkEditActivitiesQueryResult = Apollo.QueryResult<BulkEditActivitiesQuery, BulkEditActivitiesQueryVariables>;
export const BulkEditEntriesToTargetPhaseDocument = gql`
    mutation BulkEditEntriesToTargetPhase($sourceEntryIds: [ID!]!, $targetPhaseId: ID!) {
  bulkEditEntriesToTargetPhase(
    input: {sourceEntryIds: $sourceEntryIds, targetPhaseId: $targetPhaseId}
  ) {
    entries {
      ...BulkEditTimesheetEntry
    }
  }
}
    ${BulkEditTimesheetEntryFragmentDoc}`;
export type BulkEditEntriesToTargetPhaseMutationFn = Apollo.MutationFunction<BulkEditEntriesToTargetPhaseMutation, BulkEditEntriesToTargetPhaseMutationVariables>;

/**
 * __useBulkEditEntriesToTargetPhaseMutation__
 *
 * To run a mutation, you first call `useBulkEditEntriesToTargetPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditEntriesToTargetPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditEntriesToTargetPhaseMutation, { data, loading, error }] = useBulkEditEntriesToTargetPhaseMutation({
 *   variables: {
 *      sourceEntryIds: // value for 'sourceEntryIds'
 *      targetPhaseId: // value for 'targetPhaseId'
 *   },
 * });
 */
export function useBulkEditEntriesToTargetPhaseMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditEntriesToTargetPhaseMutation, BulkEditEntriesToTargetPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditEntriesToTargetPhaseMutation, BulkEditEntriesToTargetPhaseMutationVariables>(BulkEditEntriesToTargetPhaseDocument, options);
      }
export type BulkEditEntriesToTargetPhaseMutationHookResult = ReturnType<typeof useBulkEditEntriesToTargetPhaseMutation>;
export type BulkEditEntriesToTargetPhaseMutationResult = Apollo.MutationResult<BulkEditEntriesToTargetPhaseMutation>;
export type BulkEditEntriesToTargetPhaseMutationOptions = Apollo.BaseMutationOptions<BulkEditEntriesToTargetPhaseMutation, BulkEditEntriesToTargetPhaseMutationVariables>;
export const BulkEditEntriesToTargetActivityDocument = gql`
    mutation BulkEditEntriesToTargetActivity($sourceEntryIds: [ID!]!, $targetActivityId: ID!) {
  bulkEditEntriesToTargetActivity(
    input: {sourceEntryIds: $sourceEntryIds, targetActivityId: $targetActivityId}
  ) {
    entries {
      ...BulkEditTimesheetEntry
    }
  }
}
    ${BulkEditTimesheetEntryFragmentDoc}`;
export type BulkEditEntriesToTargetActivityMutationFn = Apollo.MutationFunction<BulkEditEntriesToTargetActivityMutation, BulkEditEntriesToTargetActivityMutationVariables>;

/**
 * __useBulkEditEntriesToTargetActivityMutation__
 *
 * To run a mutation, you first call `useBulkEditEntriesToTargetActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditEntriesToTargetActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditEntriesToTargetActivityMutation, { data, loading, error }] = useBulkEditEntriesToTargetActivityMutation({
 *   variables: {
 *      sourceEntryIds: // value for 'sourceEntryIds'
 *      targetActivityId: // value for 'targetActivityId'
 *   },
 * });
 */
export function useBulkEditEntriesToTargetActivityMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditEntriesToTargetActivityMutation, BulkEditEntriesToTargetActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditEntriesToTargetActivityMutation, BulkEditEntriesToTargetActivityMutationVariables>(BulkEditEntriesToTargetActivityDocument, options);
      }
export type BulkEditEntriesToTargetActivityMutationHookResult = ReturnType<typeof useBulkEditEntriesToTargetActivityMutation>;
export type BulkEditEntriesToTargetActivityMutationResult = Apollo.MutationResult<BulkEditEntriesToTargetActivityMutation>;
export type BulkEditEntriesToTargetActivityMutationOptions = Apollo.BaseMutationOptions<BulkEditEntriesToTargetActivityMutation, BulkEditEntriesToTargetActivityMutationVariables>;
export const BulkEditEntriesToTargetRoleTemplateDocument = gql`
    mutation BulkEditEntriesToTargetRoleTemplate($sourceEntryIds: [ID!]!, $targetRoleTemplateId: ID!, $targetRateTableId: ID!) {
  bulkEditEntriesToTargetRoleTemplate(
    input: {sourceEntryIds: $sourceEntryIds, targetRoleTemplateId: $targetRoleTemplateId, targetRateTableId: $targetRateTableId}
  ) {
    entries {
      ...BulkEditTimesheetEntry
    }
  }
}
    ${BulkEditTimesheetEntryFragmentDoc}`;
export type BulkEditEntriesToTargetRoleTemplateMutationFn = Apollo.MutationFunction<BulkEditEntriesToTargetRoleTemplateMutation, BulkEditEntriesToTargetRoleTemplateMutationVariables>;

/**
 * __useBulkEditEntriesToTargetRoleTemplateMutation__
 *
 * To run a mutation, you first call `useBulkEditEntriesToTargetRoleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditEntriesToTargetRoleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditEntriesToTargetRoleTemplateMutation, { data, loading, error }] = useBulkEditEntriesToTargetRoleTemplateMutation({
 *   variables: {
 *      sourceEntryIds: // value for 'sourceEntryIds'
 *      targetRoleTemplateId: // value for 'targetRoleTemplateId'
 *      targetRateTableId: // value for 'targetRateTableId'
 *   },
 * });
 */
export function useBulkEditEntriesToTargetRoleTemplateMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditEntriesToTargetRoleTemplateMutation, BulkEditEntriesToTargetRoleTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditEntriesToTargetRoleTemplateMutation, BulkEditEntriesToTargetRoleTemplateMutationVariables>(BulkEditEntriesToTargetRoleTemplateDocument, options);
      }
export type BulkEditEntriesToTargetRoleTemplateMutationHookResult = ReturnType<typeof useBulkEditEntriesToTargetRoleTemplateMutation>;
export type BulkEditEntriesToTargetRoleTemplateMutationResult = Apollo.MutationResult<BulkEditEntriesToTargetRoleTemplateMutation>;
export type BulkEditEntriesToTargetRoleTemplateMutationOptions = Apollo.BaseMutationOptions<BulkEditEntriesToTargetRoleTemplateMutation, BulkEditEntriesToTargetRoleTemplateMutationVariables>;
export const CreateExpenseV2Document = gql`
    mutation CreateExpenseV2($input: CreateExpenseV2Input!) {
  createExpenseV2(input: $input) {
    expense {
      id
    }
  }
}
    `;
export type CreateExpenseV2MutationFn = Apollo.MutationFunction<CreateExpenseV2Mutation, CreateExpenseV2MutationVariables>;

/**
 * __useCreateExpenseV2Mutation__
 *
 * To run a mutation, you first call `useCreateExpenseV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseV2Mutation, { data, loading, error }] = useCreateExpenseV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpenseV2Mutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseV2Mutation, CreateExpenseV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseV2Mutation, CreateExpenseV2MutationVariables>(CreateExpenseV2Document, options);
      }
export type CreateExpenseV2MutationHookResult = ReturnType<typeof useCreateExpenseV2Mutation>;
export type CreateExpenseV2MutationResult = Apollo.MutationResult<CreateExpenseV2Mutation>;
export type CreateExpenseV2MutationOptions = Apollo.BaseMutationOptions<CreateExpenseV2Mutation, CreateExpenseV2MutationVariables>;
export const ExpensesProjectPhasesSearchDocument = gql`
    query ExpensesProjectPhasesSearch($searchString: String) {
  searchableProjects(parameters: {searchString: $searchString, archived: false}) {
    ...ExpensesProject
  }
}
    ${ExpensesProjectFragmentDoc}`;

/**
 * __useExpensesProjectPhasesSearchQuery__
 *
 * To run a query within a React component, call `useExpensesProjectPhasesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesProjectPhasesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesProjectPhasesSearchQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useExpensesProjectPhasesSearchQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesProjectPhasesSearchQuery, ExpensesProjectPhasesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesProjectPhasesSearchQuery, ExpensesProjectPhasesSearchQueryVariables>(ExpensesProjectPhasesSearchDocument, options);
      }
export function useExpensesProjectPhasesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesProjectPhasesSearchQuery, ExpensesProjectPhasesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesProjectPhasesSearchQuery, ExpensesProjectPhasesSearchQueryVariables>(ExpensesProjectPhasesSearchDocument, options);
        }
export type ExpensesProjectPhasesSearchQueryHookResult = ReturnType<typeof useExpensesProjectPhasesSearchQuery>;
export type ExpensesProjectPhasesSearchLazyQueryHookResult = ReturnType<typeof useExpensesProjectPhasesSearchLazyQuery>;
export type ExpensesProjectPhasesSearchQueryResult = Apollo.QueryResult<ExpensesProjectPhasesSearchQuery, ExpensesProjectPhasesSearchQueryVariables>;
export const ExpensesFormOverheadsDocument = gql`
    query ExpensesFormOverheads {
  currentProfile {
    organization {
      overheads(excludeTimeOffOverheads: true) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useExpensesFormOverheadsQuery__
 *
 * To run a query within a React component, call `useExpensesFormOverheadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesFormOverheadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesFormOverheadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpensesFormOverheadsQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesFormOverheadsQuery, ExpensesFormOverheadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesFormOverheadsQuery, ExpensesFormOverheadsQueryVariables>(ExpensesFormOverheadsDocument, options);
      }
export function useExpensesFormOverheadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesFormOverheadsQuery, ExpensesFormOverheadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesFormOverheadsQuery, ExpensesFormOverheadsQueryVariables>(ExpensesFormOverheadsDocument, options);
        }
export type ExpensesFormOverheadsQueryHookResult = ReturnType<typeof useExpensesFormOverheadsQuery>;
export type ExpensesFormOverheadsLazyQueryHookResult = ReturnType<typeof useExpensesFormOverheadsLazyQuery>;
export type ExpensesFormOverheadsQueryResult = Apollo.QueryResult<ExpensesFormOverheadsQuery, ExpensesFormOverheadsQueryVariables>;
export const ExpensesProjectPhasesDocument = gql`
    query ExpensesProjectPhases($slug: String, $id: ID) {
  project(slug: $slug, id: $id) {
    id
    name
    phases {
      ...ExpensePhaseInfo
    }
  }
}
    ${ExpensePhaseInfoFragmentDoc}`;

/**
 * __useExpensesProjectPhasesQuery__
 *
 * To run a query within a React component, call `useExpensesProjectPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesProjectPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesProjectPhasesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpensesProjectPhasesQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesProjectPhasesQuery, ExpensesProjectPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesProjectPhasesQuery, ExpensesProjectPhasesQueryVariables>(ExpensesProjectPhasesDocument, options);
      }
export function useExpensesProjectPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesProjectPhasesQuery, ExpensesProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesProjectPhasesQuery, ExpensesProjectPhasesQueryVariables>(ExpensesProjectPhasesDocument, options);
        }
export type ExpensesProjectPhasesQueryHookResult = ReturnType<typeof useExpensesProjectPhasesQuery>;
export type ExpensesProjectPhasesLazyQueryHookResult = ReturnType<typeof useExpensesProjectPhasesLazyQuery>;
export type ExpensesProjectPhasesQueryResult = Apollo.QueryResult<ExpensesProjectPhasesQuery, ExpensesProjectPhasesQueryVariables>;
export const ExpensePhaseInfoDocument = gql`
    query ExpensePhaseInfo($id: ID!) {
  phase(id: $id) {
    ...ExpensePhaseInfo
    project {
      id
      name
    }
  }
}
    ${ExpensePhaseInfoFragmentDoc}`;

/**
 * __useExpensePhaseInfoQuery__
 *
 * To run a query within a React component, call `useExpensePhaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensePhaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensePhaseInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpensePhaseInfoQuery(baseOptions: Apollo.QueryHookOptions<ExpensePhaseInfoQuery, ExpensePhaseInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensePhaseInfoQuery, ExpensePhaseInfoQueryVariables>(ExpensePhaseInfoDocument, options);
      }
export function useExpensePhaseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensePhaseInfoQuery, ExpensePhaseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensePhaseInfoQuery, ExpensePhaseInfoQueryVariables>(ExpensePhaseInfoDocument, options);
        }
export type ExpensePhaseInfoQueryHookResult = ReturnType<typeof useExpensePhaseInfoQuery>;
export type ExpensePhaseInfoLazyQueryHookResult = ReturnType<typeof useExpensePhaseInfoLazyQuery>;
export type ExpensePhaseInfoQueryResult = Apollo.QueryResult<ExpensePhaseInfoQuery, ExpensePhaseInfoQueryVariables>;
export const ExpensesRecentProjectsDocument = gql`
    query ExpensesRecentProjects($first: Int, $sortBy: SearchableProjectsSortInput) {
  searchableProjects(parameters: {limit: $first, sortBy: $sortBy}) {
    ...ExpensesProject
  }
}
    ${ExpensesProjectFragmentDoc}`;

/**
 * __useExpensesRecentProjectsQuery__
 *
 * To run a query within a React component, call `useExpensesRecentProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesRecentProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesRecentProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useExpensesRecentProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesRecentProjectsQuery, ExpensesRecentProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesRecentProjectsQuery, ExpensesRecentProjectsQueryVariables>(ExpensesRecentProjectsDocument, options);
      }
export function useExpensesRecentProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesRecentProjectsQuery, ExpensesRecentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesRecentProjectsQuery, ExpensesRecentProjectsQueryVariables>(ExpensesRecentProjectsDocument, options);
        }
export type ExpensesRecentProjectsQueryHookResult = ReturnType<typeof useExpensesRecentProjectsQuery>;
export type ExpensesRecentProjectsLazyQueryHookResult = ReturnType<typeof useExpensesRecentProjectsLazyQuery>;
export type ExpensesRecentProjectsQueryResult = Apollo.QueryResult<ExpensesRecentProjectsQuery, ExpensesRecentProjectsQueryVariables>;
export const OrganizationTimesheetsDocument = gql`
    query OrganizationTimesheets($beginDate: ISO8601Date!) {
  searchableTimesheets(
    parameters: {beginDate: $beginDate, profileStatuses: [ACTIVE, INVITED, NOT_INVITED]}
  ) {
    ...OrganizationTimesheet
  }
}
    ${OrganizationTimesheetFragmentDoc}`;

/**
 * __useOrganizationTimesheetsQuery__
 *
 * To run a query within a React component, call `useOrganizationTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTimesheetsQuery({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *   },
 * });
 */
export function useOrganizationTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationTimesheetsQuery, OrganizationTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTimesheetsQuery, OrganizationTimesheetsQueryVariables>(OrganizationTimesheetsDocument, options);
      }
export function useOrganizationTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTimesheetsQuery, OrganizationTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTimesheetsQuery, OrganizationTimesheetsQueryVariables>(OrganizationTimesheetsDocument, options);
        }
export type OrganizationTimesheetsQueryHookResult = ReturnType<typeof useOrganizationTimesheetsQuery>;
export type OrganizationTimesheetsLazyQueryHookResult = ReturnType<typeof useOrganizationTimesheetsLazyQuery>;
export type OrganizationTimesheetsQueryResult = Apollo.QueryResult<OrganizationTimesheetsQuery, OrganizationTimesheetsQueryVariables>;
export const ProfilePastTimesheetsDocument = gql`
    query ProfilePastTimesheets($slug: String!, $beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  profile(slug: $slug) {
    ...PastTimesheetProfile
    timesheets(beginDate: $beginDate, endDate: $endDate) {
      ...ProfilePastTimesheet
    }
  }
}
    ${PastTimesheetProfileFragmentDoc}
${ProfilePastTimesheetFragmentDoc}`;

/**
 * __useProfilePastTimesheetsQuery__
 *
 * To run a query within a React component, call `useProfilePastTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePastTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePastTimesheetsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProfilePastTimesheetsQuery(baseOptions: Apollo.QueryHookOptions<ProfilePastTimesheetsQuery, ProfilePastTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePastTimesheetsQuery, ProfilePastTimesheetsQueryVariables>(ProfilePastTimesheetsDocument, options);
      }
export function useProfilePastTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePastTimesheetsQuery, ProfilePastTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePastTimesheetsQuery, ProfilePastTimesheetsQueryVariables>(ProfilePastTimesheetsDocument, options);
        }
export type ProfilePastTimesheetsQueryHookResult = ReturnType<typeof useProfilePastTimesheetsQuery>;
export type ProfilePastTimesheetsLazyQueryHookResult = ReturnType<typeof useProfilePastTimesheetsLazyQuery>;
export type ProfilePastTimesheetsQueryResult = Apollo.QueryResult<ProfilePastTimesheetsQuery, ProfilePastTimesheetsQueryVariables>;
export const ExportTimesheetDocument = gql`
    mutation ExportTimesheet($input: TimesheetExportInput!) {
  startExport {
    timesheet(input: $input) {
      id
    }
  }
}
    `;
export type ExportTimesheetMutationFn = Apollo.MutationFunction<ExportTimesheetMutation, ExportTimesheetMutationVariables>;

/**
 * __useExportTimesheetMutation__
 *
 * To run a mutation, you first call `useExportTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTimesheetMutation, { data, loading, error }] = useExportTimesheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<ExportTimesheetMutation, ExportTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportTimesheetMutation, ExportTimesheetMutationVariables>(ExportTimesheetDocument, options);
      }
export type ExportTimesheetMutationHookResult = ReturnType<typeof useExportTimesheetMutation>;
export type ExportTimesheetMutationResult = Apollo.MutationResult<ExportTimesheetMutation>;
export type ExportTimesheetMutationOptions = Apollo.BaseMutationOptions<ExportTimesheetMutation, ExportTimesheetMutationVariables>;
export const DeletionModalEntryGroupDocument = gql`
    query DeletionModalEntryGroup($entryGroupId: ID!) {
  entryGroup(id: $entryGroupId) {
    id
    allocation
    overhead {
      id
      name
    }
    project {
      id
      name
    }
    entries {
      id
      hours
      notes
      expenses {
        id
      }
    }
  }
}
    `;

/**
 * __useDeletionModalEntryGroupQuery__
 *
 * To run a query within a React component, call `useDeletionModalEntryGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletionModalEntryGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletionModalEntryGroupQuery({
 *   variables: {
 *      entryGroupId: // value for 'entryGroupId'
 *   },
 * });
 */
export function useDeletionModalEntryGroupQuery(baseOptions: Apollo.QueryHookOptions<DeletionModalEntryGroupQuery, DeletionModalEntryGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletionModalEntryGroupQuery, DeletionModalEntryGroupQueryVariables>(DeletionModalEntryGroupDocument, options);
      }
export function useDeletionModalEntryGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletionModalEntryGroupQuery, DeletionModalEntryGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletionModalEntryGroupQuery, DeletionModalEntryGroupQueryVariables>(DeletionModalEntryGroupDocument, options);
        }
export type DeletionModalEntryGroupQueryHookResult = ReturnType<typeof useDeletionModalEntryGroupQuery>;
export type DeletionModalEntryGroupLazyQueryHookResult = ReturnType<typeof useDeletionModalEntryGroupLazyQuery>;
export type DeletionModalEntryGroupQueryResult = Apollo.QueryResult<DeletionModalEntryGroupQuery, DeletionModalEntryGroupQueryVariables>;
export const DeleteEntryGroupDocument = gql`
    mutation DeleteEntryGroup($entryGroupId: ID!) {
  deleteEntryGroup(input: {id: $entryGroupId}) {
    entryGroup {
      id
    }
  }
}
    `;
export type DeleteEntryGroupMutationFn = Apollo.MutationFunction<DeleteEntryGroupMutation, DeleteEntryGroupMutationVariables>;

/**
 * __useDeleteEntryGroupMutation__
 *
 * To run a mutation, you first call `useDeleteEntryGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntryGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntryGroupMutation, { data, loading, error }] = useDeleteEntryGroupMutation({
 *   variables: {
 *      entryGroupId: // value for 'entryGroupId'
 *   },
 * });
 */
export function useDeleteEntryGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEntryGroupMutation, DeleteEntryGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEntryGroupMutation, DeleteEntryGroupMutationVariables>(DeleteEntryGroupDocument, options);
      }
export type DeleteEntryGroupMutationHookResult = ReturnType<typeof useDeleteEntryGroupMutation>;
export type DeleteEntryGroupMutationResult = Apollo.MutationResult<DeleteEntryGroupMutation>;
export type DeleteEntryGroupMutationOptions = Apollo.BaseMutationOptions<DeleteEntryGroupMutation, DeleteEntryGroupMutationVariables>;
export const UpdateEntryDocument = gql`
    mutation UpdateEntry($input: UpdateEntryInput!) {
  updateEntry(input: $input) {
    entry {
      ...TimesheetEntry
    }
    entryRow {
      id
      entries {
        ...TimesheetEntry
      }
    }
  }
}
    ${TimesheetEntryFragmentDoc}`;
export type UpdateEntryMutationFn = Apollo.MutationFunction<UpdateEntryMutation, UpdateEntryMutationVariables>;

/**
 * __useUpdateEntryMutation__
 *
 * To run a mutation, you first call `useUpdateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryMutation, { data, loading, error }] = useUpdateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryMutation, UpdateEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryMutation, UpdateEntryMutationVariables>(UpdateEntryDocument, options);
      }
export type UpdateEntryMutationHookResult = ReturnType<typeof useUpdateEntryMutation>;
export type UpdateEntryMutationResult = Apollo.MutationResult<UpdateEntryMutation>;
export type UpdateEntryMutationOptions = Apollo.BaseMutationOptions<UpdateEntryMutation, UpdateEntryMutationVariables>;
export const CreateEntryWithOverheadDocument = gql`
    mutation CreateEntryWithOverhead($input: CreateEntryWithOverheadInput!) {
  createEntryWithOverhead(input: $input) {
    entryRow {
      id
      entries {
        ...TimesheetEntry
      }
    }
  }
}
    ${TimesheetEntryFragmentDoc}`;
export type CreateEntryWithOverheadMutationFn = Apollo.MutationFunction<CreateEntryWithOverheadMutation, CreateEntryWithOverheadMutationVariables>;

/**
 * __useCreateEntryWithOverheadMutation__
 *
 * To run a mutation, you first call `useCreateEntryWithOverheadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryWithOverheadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryWithOverheadMutation, { data, loading, error }] = useCreateEntryWithOverheadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntryWithOverheadMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryWithOverheadMutation, CreateEntryWithOverheadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryWithOverheadMutation, CreateEntryWithOverheadMutationVariables>(CreateEntryWithOverheadDocument, options);
      }
export type CreateEntryWithOverheadMutationHookResult = ReturnType<typeof useCreateEntryWithOverheadMutation>;
export type CreateEntryWithOverheadMutationResult = Apollo.MutationResult<CreateEntryWithOverheadMutation>;
export type CreateEntryWithOverheadMutationOptions = Apollo.BaseMutationOptions<CreateEntryWithOverheadMutation, CreateEntryWithOverheadMutationVariables>;
export const UpsertEntryDocument = gql`
    mutation UpsertEntry($input: UpsertEntryInput!) {
  upsertEntry(input: $input) {
    entry {
      ...TimesheetEntry
    }
    entryRow {
      id
      entries {
        ...TimesheetEntry
      }
    }
  }
}
    ${TimesheetEntryFragmentDoc}`;
export type UpsertEntryMutationFn = Apollo.MutationFunction<UpsertEntryMutation, UpsertEntryMutationVariables>;

/**
 * __useUpsertEntryMutation__
 *
 * To run a mutation, you first call `useUpsertEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEntryMutation, { data, loading, error }] = useUpsertEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertEntryMutation, UpsertEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertEntryMutation, UpsertEntryMutationVariables>(UpsertEntryDocument, options);
      }
export type UpsertEntryMutationHookResult = ReturnType<typeof useUpsertEntryMutation>;
export type UpsertEntryMutationResult = Apollo.MutationResult<UpsertEntryMutation>;
export type UpsertEntryMutationOptions = Apollo.BaseMutationOptions<UpsertEntryMutation, UpsertEntryMutationVariables>;
export const OrganizationTimesheetNotesRequiredDocument = gql`
    query OrganizationTimesheetNotesRequired {
  currentProfile {
    id
    organization {
      id
      timesheetNotesReminder
      currency {
        id
        symbol
      }
    }
  }
}
    `;

/**
 * __useOrganizationTimesheetNotesRequiredQuery__
 *
 * To run a query within a React component, call `useOrganizationTimesheetNotesRequiredQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTimesheetNotesRequiredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTimesheetNotesRequiredQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationTimesheetNotesRequiredQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationTimesheetNotesRequiredQuery, OrganizationTimesheetNotesRequiredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTimesheetNotesRequiredQuery, OrganizationTimesheetNotesRequiredQueryVariables>(OrganizationTimesheetNotesRequiredDocument, options);
      }
export function useOrganizationTimesheetNotesRequiredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTimesheetNotesRequiredQuery, OrganizationTimesheetNotesRequiredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTimesheetNotesRequiredQuery, OrganizationTimesheetNotesRequiredQueryVariables>(OrganizationTimesheetNotesRequiredDocument, options);
        }
export type OrganizationTimesheetNotesRequiredQueryHookResult = ReturnType<typeof useOrganizationTimesheetNotesRequiredQuery>;
export type OrganizationTimesheetNotesRequiredLazyQueryHookResult = ReturnType<typeof useOrganizationTimesheetNotesRequiredLazyQuery>;
export type OrganizationTimesheetNotesRequiredQueryResult = Apollo.QueryResult<OrganizationTimesheetNotesRequiredQuery, OrganizationTimesheetNotesRequiredQueryVariables>;
export const UpsertEntryWithNotesDocument = gql`
    mutation UpsertEntryWithNotes($entryId: ID, $entryRowId: ID!, $notes: String!, $date: ISO8601Date!) {
  upsertEntryWithNotes(
    input: {date: $date, entryId: $entryId, entryRowId: $entryRowId, notes: $notes}
  ) {
    entry {
      ...TimesheetEntry
    }
  }
}
    ${TimesheetEntryFragmentDoc}`;
export type UpsertEntryWithNotesMutationFn = Apollo.MutationFunction<UpsertEntryWithNotesMutation, UpsertEntryWithNotesMutationVariables>;

/**
 * __useUpsertEntryWithNotesMutation__
 *
 * To run a mutation, you first call `useUpsertEntryWithNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEntryWithNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEntryWithNotesMutation, { data, loading, error }] = useUpsertEntryWithNotesMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      entryRowId: // value for 'entryRowId'
 *      notes: // value for 'notes'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpsertEntryWithNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertEntryWithNotesMutation, UpsertEntryWithNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertEntryWithNotesMutation, UpsertEntryWithNotesMutationVariables>(UpsertEntryWithNotesDocument, options);
      }
export type UpsertEntryWithNotesMutationHookResult = ReturnType<typeof useUpsertEntryWithNotesMutation>;
export type UpsertEntryWithNotesMutationResult = Apollo.MutationResult<UpsertEntryWithNotesMutation>;
export type UpsertEntryWithNotesMutationOptions = Apollo.BaseMutationOptions<UpsertEntryWithNotesMutation, UpsertEntryWithNotesMutationVariables>;
export const CreateEntryRowDocument = gql`
    mutation CreateEntryRow($input: CreateEntryRowInput!) {
  createEntryRow(input: $input) {
    entryRow {
      ...TimesheetEntryRow
    }
  }
}
    ${TimesheetEntryRowFragmentDoc}`;
export type CreateEntryRowMutationFn = Apollo.MutationFunction<CreateEntryRowMutation, CreateEntryRowMutationVariables>;

/**
 * __useCreateEntryRowMutation__
 *
 * To run a mutation, you first call `useCreateEntryRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryRowMutation, { data, loading, error }] = useCreateEntryRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntryRowMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryRowMutation, CreateEntryRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryRowMutation, CreateEntryRowMutationVariables>(CreateEntryRowDocument, options);
      }
export type CreateEntryRowMutationHookResult = ReturnType<typeof useCreateEntryRowMutation>;
export type CreateEntryRowMutationResult = Apollo.MutationResult<CreateEntryRowMutation>;
export type CreateEntryRowMutationOptions = Apollo.BaseMutationOptions<CreateEntryRowMutation, CreateEntryRowMutationVariables>;
export const UpdateEntryRowActivityDocument = gql`
    mutation UpdateEntryRowActivity($input: UpdateEntryRowActivityInput!) {
  updateEntryRowActivity(input: $input) {
    entryRow {
      ...TimesheetEntryRow
    }
  }
}
    ${TimesheetEntryRowFragmentDoc}`;
export type UpdateEntryRowActivityMutationFn = Apollo.MutationFunction<UpdateEntryRowActivityMutation, UpdateEntryRowActivityMutationVariables>;

/**
 * __useUpdateEntryRowActivityMutation__
 *
 * To run a mutation, you first call `useUpdateEntryRowActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryRowActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryRowActivityMutation, { data, loading, error }] = useUpdateEntryRowActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryRowActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryRowActivityMutation, UpdateEntryRowActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryRowActivityMutation, UpdateEntryRowActivityMutationVariables>(UpdateEntryRowActivityDocument, options);
      }
export type UpdateEntryRowActivityMutationHookResult = ReturnType<typeof useUpdateEntryRowActivityMutation>;
export type UpdateEntryRowActivityMutationResult = Apollo.MutationResult<UpdateEntryRowActivityMutation>;
export type UpdateEntryRowActivityMutationOptions = Apollo.BaseMutationOptions<UpdateEntryRowActivityMutation, UpdateEntryRowActivityMutationVariables>;
export const DeletionModalEntryRowDocument = gql`
    query DeletionModalEntryRow($entryRowId: ID!) {
  entryRow(id: $entryRowId) {
    id
    entries {
      id
      hours
      notes
      expenses {
        id
      }
    }
    entryGroup {
      project {
        id
        name
      }
      id
      allocation
      entryRows {
        id
      }
    }
  }
}
    `;

/**
 * __useDeletionModalEntryRowQuery__
 *
 * To run a query within a React component, call `useDeletionModalEntryRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletionModalEntryRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletionModalEntryRowQuery({
 *   variables: {
 *      entryRowId: // value for 'entryRowId'
 *   },
 * });
 */
export function useDeletionModalEntryRowQuery(baseOptions: Apollo.QueryHookOptions<DeletionModalEntryRowQuery, DeletionModalEntryRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeletionModalEntryRowQuery, DeletionModalEntryRowQueryVariables>(DeletionModalEntryRowDocument, options);
      }
export function useDeletionModalEntryRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeletionModalEntryRowQuery, DeletionModalEntryRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeletionModalEntryRowQuery, DeletionModalEntryRowQueryVariables>(DeletionModalEntryRowDocument, options);
        }
export type DeletionModalEntryRowQueryHookResult = ReturnType<typeof useDeletionModalEntryRowQuery>;
export type DeletionModalEntryRowLazyQueryHookResult = ReturnType<typeof useDeletionModalEntryRowLazyQuery>;
export type DeletionModalEntryRowQueryResult = Apollo.QueryResult<DeletionModalEntryRowQuery, DeletionModalEntryRowQueryVariables>;
export const DeleteEntryRowDocument = gql`
    mutation DeleteEntryRow($entryRowId: ID!) {
  deleteEntryRow(input: {id: $entryRowId}) {
    entryRow {
      id
    }
  }
}
    `;
export type DeleteEntryRowMutationFn = Apollo.MutationFunction<DeleteEntryRowMutation, DeleteEntryRowMutationVariables>;

/**
 * __useDeleteEntryRowMutation__
 *
 * To run a mutation, you first call `useDeleteEntryRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntryRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntryRowMutation, { data, loading, error }] = useDeleteEntryRowMutation({
 *   variables: {
 *      entryRowId: // value for 'entryRowId'
 *   },
 * });
 */
export function useDeleteEntryRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEntryRowMutation, DeleteEntryRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEntryRowMutation, DeleteEntryRowMutationVariables>(DeleteEntryRowDocument, options);
      }
export type DeleteEntryRowMutationHookResult = ReturnType<typeof useDeleteEntryRowMutation>;
export type DeleteEntryRowMutationResult = Apollo.MutationResult<DeleteEntryRowMutation>;
export type DeleteEntryRowMutationOptions = Apollo.BaseMutationOptions<DeleteEntryRowMutation, DeleteEntryRowMutationVariables>;
export const TimesheetEntryGroupPhasesDocument = gql`
    query TimesheetEntryGroupPhases($phaseId: ID!, $activeDateRange: DateRangeInput!) {
  phase(id: $phaseId) {
    id
    project {
      ...TimesheetsSearchableProject
      phases: activePhasesInRange(dateRange: $activeDateRange) {
        ...TimesheetsSearchablePhase
      }
    }
  }
}
    ${TimesheetsSearchableProjectFragmentDoc}
${TimesheetsSearchablePhaseFragmentDoc}`;

/**
 * __useTimesheetEntryGroupPhasesQuery__
 *
 * To run a query within a React component, call `useTimesheetEntryGroupPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntryGroupPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetEntryGroupPhasesQuery({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *      activeDateRange: // value for 'activeDateRange'
 *   },
 * });
 */
export function useTimesheetEntryGroupPhasesQuery(baseOptions: Apollo.QueryHookOptions<TimesheetEntryGroupPhasesQuery, TimesheetEntryGroupPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetEntryGroupPhasesQuery, TimesheetEntryGroupPhasesQueryVariables>(TimesheetEntryGroupPhasesDocument, options);
      }
export function useTimesheetEntryGroupPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetEntryGroupPhasesQuery, TimesheetEntryGroupPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetEntryGroupPhasesQuery, TimesheetEntryGroupPhasesQueryVariables>(TimesheetEntryGroupPhasesDocument, options);
        }
export type TimesheetEntryGroupPhasesQueryHookResult = ReturnType<typeof useTimesheetEntryGroupPhasesQuery>;
export type TimesheetEntryGroupPhasesLazyQueryHookResult = ReturnType<typeof useTimesheetEntryGroupPhasesLazyQuery>;
export type TimesheetEntryGroupPhasesQueryResult = Apollo.QueryResult<TimesheetEntryGroupPhasesQuery, TimesheetEntryGroupPhasesQueryVariables>;
export const UpdateEntryGroupPhaseDocument = gql`
    mutation UpdateEntryGroupPhase($input: UpdateEntryGroupPhaseInput!) {
  updateEntryGroupPhase(input: $input) {
    entryGroup {
      ...TimesheetEntryGroup
    }
  }
}
    ${TimesheetEntryGroupFragmentDoc}`;
export type UpdateEntryGroupPhaseMutationFn = Apollo.MutationFunction<UpdateEntryGroupPhaseMutation, UpdateEntryGroupPhaseMutationVariables>;

/**
 * __useUpdateEntryGroupPhaseMutation__
 *
 * To run a mutation, you first call `useUpdateEntryGroupPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryGroupPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryGroupPhaseMutation, { data, loading, error }] = useUpdateEntryGroupPhaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntryGroupPhaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryGroupPhaseMutation, UpdateEntryGroupPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryGroupPhaseMutation, UpdateEntryGroupPhaseMutationVariables>(UpdateEntryGroupPhaseDocument, options);
      }
export type UpdateEntryGroupPhaseMutationHookResult = ReturnType<typeof useUpdateEntryGroupPhaseMutation>;
export type UpdateEntryGroupPhaseMutationResult = Apollo.MutationResult<UpdateEntryGroupPhaseMutation>;
export type UpdateEntryGroupPhaseMutationOptions = Apollo.BaseMutationOptions<UpdateEntryGroupPhaseMutation, UpdateEntryGroupPhaseMutationVariables>;
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($input: UpdateExpenseInput!) {
  updateExpense(input: $input) {
    expense {
      ...TimesheetExpense
    }
  }
}
    ${TimesheetExpenseFragmentDoc}`;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export const DeleteExpenseDocument = gql`
    mutation DeleteExpense($input: DeleteExpenseInput!) {
  deleteExpense(input: $input) {
    expense {
      ...TimesheetExpense
    }
  }
}
    ${TimesheetExpenseFragmentDoc}`;
export type DeleteExpenseMutationFn = Apollo.MutationFunction<DeleteExpenseMutation, DeleteExpenseMutationVariables>;

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExpenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseMutation, DeleteExpenseMutationVariables>(DeleteExpenseDocument, options);
      }
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = Apollo.MutationResult<DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>;
export const ExpenseCategoriesDocument = gql`
    query ExpenseCategories {
  expenseCategories {
    id
    name
  }
}
    `;

/**
 * __useExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpenseCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, options);
      }
export function useExpenseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, options);
        }
export type ExpenseCategoriesQueryHookResult = ReturnType<typeof useExpenseCategoriesQuery>;
export type ExpenseCategoriesLazyQueryHookResult = ReturnType<typeof useExpenseCategoriesLazyQuery>;
export type ExpenseCategoriesQueryResult = Apollo.QueryResult<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>;
export const TimesheetTimeOffEnabledDocument = gql`
    query TimesheetTimeOffEnabled {
  currentProfile {
    id
    organization {
      id
      settings {
        id
        ptoEnabled
      }
    }
  }
}
    `;

/**
 * __useTimesheetTimeOffEnabledQuery__
 *
 * To run a query within a React component, call `useTimesheetTimeOffEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetTimeOffEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetTimeOffEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimesheetTimeOffEnabledQuery(baseOptions?: Apollo.QueryHookOptions<TimesheetTimeOffEnabledQuery, TimesheetTimeOffEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetTimeOffEnabledQuery, TimesheetTimeOffEnabledQueryVariables>(TimesheetTimeOffEnabledDocument, options);
      }
export function useTimesheetTimeOffEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetTimeOffEnabledQuery, TimesheetTimeOffEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetTimeOffEnabledQuery, TimesheetTimeOffEnabledQueryVariables>(TimesheetTimeOffEnabledDocument, options);
        }
export type TimesheetTimeOffEnabledQueryHookResult = ReturnType<typeof useTimesheetTimeOffEnabledQuery>;
export type TimesheetTimeOffEnabledLazyQueryHookResult = ReturnType<typeof useTimesheetTimeOffEnabledLazyQuery>;
export type TimesheetTimeOffEnabledQueryResult = Apollo.QueryResult<TimesheetTimeOffEnabledQuery, TimesheetTimeOffEnabledQueryVariables>;
export const TimesheetSelectableOverheadsDocument = gql`
    query TimesheetSelectableOverheads($slug: String!) {
  profile(slug: $slug) {
    id
    organization {
      id
      overheads(excludeTimeOffOverheads: true) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTimesheetSelectableOverheadsQuery__
 *
 * To run a query within a React component, call `useTimesheetSelectableOverheadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetSelectableOverheadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetSelectableOverheadsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTimesheetSelectableOverheadsQuery(baseOptions: Apollo.QueryHookOptions<TimesheetSelectableOverheadsQuery, TimesheetSelectableOverheadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetSelectableOverheadsQuery, TimesheetSelectableOverheadsQueryVariables>(TimesheetSelectableOverheadsDocument, options);
      }
export function useTimesheetSelectableOverheadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetSelectableOverheadsQuery, TimesheetSelectableOverheadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetSelectableOverheadsQuery, TimesheetSelectableOverheadsQueryVariables>(TimesheetSelectableOverheadsDocument, options);
        }
export type TimesheetSelectableOverheadsQueryHookResult = ReturnType<typeof useTimesheetSelectableOverheadsQuery>;
export type TimesheetSelectableOverheadsLazyQueryHookResult = ReturnType<typeof useTimesheetSelectableOverheadsLazyQuery>;
export type TimesheetSelectableOverheadsQueryResult = Apollo.QueryResult<TimesheetSelectableOverheadsQuery, TimesheetSelectableOverheadsQueryVariables>;
export const TimesheetSelectableProjectPhasesDocument = gql`
    query TimesheetSelectableProjectPhases($searchString: String, $activeDateRange: DateRangeInput!) {
  searchableProjects(
    parameters: {archived: false, searchString: $searchString, activeDateRange: $activeDateRange}
  ) {
    ...TimesheetsSearchableProject
    phases: activePhasesInRange(dateRange: $activeDateRange) {
      ...TimesheetsSearchablePhase
    }
  }
}
    ${TimesheetsSearchableProjectFragmentDoc}
${TimesheetsSearchablePhaseFragmentDoc}`;

/**
 * __useTimesheetSelectableProjectPhasesQuery__
 *
 * To run a query within a React component, call `useTimesheetSelectableProjectPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetSelectableProjectPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetSelectableProjectPhasesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      activeDateRange: // value for 'activeDateRange'
 *   },
 * });
 */
export function useTimesheetSelectableProjectPhasesQuery(baseOptions: Apollo.QueryHookOptions<TimesheetSelectableProjectPhasesQuery, TimesheetSelectableProjectPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetSelectableProjectPhasesQuery, TimesheetSelectableProjectPhasesQueryVariables>(TimesheetSelectableProjectPhasesDocument, options);
      }
export function useTimesheetSelectableProjectPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetSelectableProjectPhasesQuery, TimesheetSelectableProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetSelectableProjectPhasesQuery, TimesheetSelectableProjectPhasesQueryVariables>(TimesheetSelectableProjectPhasesDocument, options);
        }
export type TimesheetSelectableProjectPhasesQueryHookResult = ReturnType<typeof useTimesheetSelectableProjectPhasesQuery>;
export type TimesheetSelectableProjectPhasesLazyQueryHookResult = ReturnType<typeof useTimesheetSelectableProjectPhasesLazyQuery>;
export type TimesheetSelectableProjectPhasesQueryResult = Apollo.QueryResult<TimesheetSelectableProjectPhasesQuery, TimesheetSelectableProjectPhasesQueryVariables>;
export const CreateEntryGroupWithOverheadDocument = gql`
    mutation CreateEntryGroupWithOverhead($timesheetId: ID!, $overheadId: ID!) {
  createEntryGroupWithOverhead(
    input: {overheadId: $overheadId, timesheetId: $timesheetId}
  ) {
    entryGroup {
      ...TimesheetEntryGroup
    }
  }
}
    ${TimesheetEntryGroupFragmentDoc}`;
export type CreateEntryGroupWithOverheadMutationFn = Apollo.MutationFunction<CreateEntryGroupWithOverheadMutation, CreateEntryGroupWithOverheadMutationVariables>;

/**
 * __useCreateEntryGroupWithOverheadMutation__
 *
 * To run a mutation, you first call `useCreateEntryGroupWithOverheadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryGroupWithOverheadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryGroupWithOverheadMutation, { data, loading, error }] = useCreateEntryGroupWithOverheadMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      overheadId: // value for 'overheadId'
 *   },
 * });
 */
export function useCreateEntryGroupWithOverheadMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryGroupWithOverheadMutation, CreateEntryGroupWithOverheadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryGroupWithOverheadMutation, CreateEntryGroupWithOverheadMutationVariables>(CreateEntryGroupWithOverheadDocument, options);
      }
export type CreateEntryGroupWithOverheadMutationHookResult = ReturnType<typeof useCreateEntryGroupWithOverheadMutation>;
export type CreateEntryGroupWithOverheadMutationResult = Apollo.MutationResult<CreateEntryGroupWithOverheadMutation>;
export type CreateEntryGroupWithOverheadMutationOptions = Apollo.BaseMutationOptions<CreateEntryGroupWithOverheadMutation, CreateEntryGroupWithOverheadMutationVariables>;
export const CreateEntryGroupWithPhaseDocument = gql`
    mutation CreateEntryGroupWithPhase($timesheetId: ID!, $phaseId: ID!, $roleId: ID) {
  createEntryGroupWithProject(
    input: {timesheetId: $timesheetId, phaseId: $phaseId, roleId: $roleId}
  ) {
    entryGroup {
      ...TimesheetEntryGroup
    }
  }
}
    ${TimesheetEntryGroupFragmentDoc}`;
export type CreateEntryGroupWithPhaseMutationFn = Apollo.MutationFunction<CreateEntryGroupWithPhaseMutation, CreateEntryGroupWithPhaseMutationVariables>;

/**
 * __useCreateEntryGroupWithPhaseMutation__
 *
 * To run a mutation, you first call `useCreateEntryGroupWithPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryGroupWithPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryGroupWithPhaseMutation, { data, loading, error }] = useCreateEntryGroupWithPhaseMutation({
 *   variables: {
 *      timesheetId: // value for 'timesheetId'
 *      phaseId: // value for 'phaseId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useCreateEntryGroupWithPhaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryGroupWithPhaseMutation, CreateEntryGroupWithPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryGroupWithPhaseMutation, CreateEntryGroupWithPhaseMutationVariables>(CreateEntryGroupWithPhaseDocument, options);
      }
export type CreateEntryGroupWithPhaseMutationHookResult = ReturnType<typeof useCreateEntryGroupWithPhaseMutation>;
export type CreateEntryGroupWithPhaseMutationResult = Apollo.MutationResult<CreateEntryGroupWithPhaseMutation>;
export type CreateEntryGroupWithPhaseMutationOptions = Apollo.BaseMutationOptions<CreateEntryGroupWithPhaseMutation, CreateEntryGroupWithPhaseMutationVariables>;
export const TimesheetDetailDocument = gql`
    query TimesheetDetail($profileSlug: String!, $beginDate: ISO8601Date!) {
  profile(slug: $profileSlug) {
    id
    timesheet(beginDate: $beginDate) {
      ...TimesheetDetail
    }
  }
}
    ${TimesheetDetailFragmentDoc}`;

/**
 * __useTimesheetDetailQuery__
 *
 * To run a query within a React component, call `useTimesheetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetDetailQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *      beginDate: // value for 'beginDate'
 *   },
 * });
 */
export function useTimesheetDetailQuery(baseOptions: Apollo.QueryHookOptions<TimesheetDetailQuery, TimesheetDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetDetailQuery, TimesheetDetailQueryVariables>(TimesheetDetailDocument, options);
      }
export function useTimesheetDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetDetailQuery, TimesheetDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetDetailQuery, TimesheetDetailQueryVariables>(TimesheetDetailDocument, options);
        }
export type TimesheetDetailQueryHookResult = ReturnType<typeof useTimesheetDetailQuery>;
export type TimesheetDetailLazyQueryHookResult = ReturnType<typeof useTimesheetDetailLazyQuery>;
export type TimesheetDetailQueryResult = Apollo.QueryResult<TimesheetDetailQuery, TimesheetDetailQueryVariables>;
export const OrganizationActivitiesDocument = gql`
    query OrganizationActivities {
  activities {
    id
    name
  }
}
    `;

/**
 * __useOrganizationActivitiesQuery__
 *
 * To run a query within a React component, call `useOrganizationActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>(OrganizationActivitiesDocument, options);
      }
export function useOrganizationActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>(OrganizationActivitiesDocument, options);
        }
export type OrganizationActivitiesQueryHookResult = ReturnType<typeof useOrganizationActivitiesQuery>;
export type OrganizationActivitiesLazyQueryHookResult = ReturnType<typeof useOrganizationActivitiesLazyQuery>;
export type OrganizationActivitiesQueryResult = Apollo.QueryResult<OrganizationActivitiesQuery, OrganizationActivitiesQueryVariables>;
export const UpdateTimesheetDocument = gql`
    mutation UpdateTimesheet($input: UpdateTimesheetInput!) {
  updateTimesheet(input: $input) {
    timesheet {
      ...TimesheetDetail
    }
  }
}
    ${TimesheetDetailFragmentDoc}`;
export type UpdateTimesheetMutationFn = Apollo.MutationFunction<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>;

/**
 * __useUpdateTimesheetMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetMutation, { data, loading, error }] = useUpdateTimesheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>(UpdateTimesheetDocument, options);
      }
export type UpdateTimesheetMutationHookResult = ReturnType<typeof useUpdateTimesheetMutation>;
export type UpdateTimesheetMutationResult = Apollo.MutationResult<UpdateTimesheetMutation>;
export type UpdateTimesheetMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>;
export const TimesheetEntryRowOrgSettingDocument = gql`
    query TimesheetEntryRowOrgSetting($id: ID!) {
  currentProfile {
    id
    organization {
      id
      settings {
        id
        activityRequired
      }
    }
  }
  entryRow(id: $id) {
    id
    activity {
      id
    }
    entryGroup {
      id
      overhead {
        id
      }
    }
  }
}
    `;

/**
 * __useTimesheetEntryRowOrgSettingQuery__
 *
 * To run a query within a React component, call `useTimesheetEntryRowOrgSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetEntryRowOrgSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetEntryRowOrgSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimesheetEntryRowOrgSettingQuery(baseOptions: Apollo.QueryHookOptions<TimesheetEntryRowOrgSettingQuery, TimesheetEntryRowOrgSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetEntryRowOrgSettingQuery, TimesheetEntryRowOrgSettingQueryVariables>(TimesheetEntryRowOrgSettingDocument, options);
      }
export function useTimesheetEntryRowOrgSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetEntryRowOrgSettingQuery, TimesheetEntryRowOrgSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetEntryRowOrgSettingQuery, TimesheetEntryRowOrgSettingQueryVariables>(TimesheetEntryRowOrgSettingDocument, options);
        }
export type TimesheetEntryRowOrgSettingQueryHookResult = ReturnType<typeof useTimesheetEntryRowOrgSettingQuery>;
export type TimesheetEntryRowOrgSettingLazyQueryHookResult = ReturnType<typeof useTimesheetEntryRowOrgSettingLazyQuery>;
export type TimesheetEntryRowOrgSettingQueryResult = Apollo.QueryResult<TimesheetEntryRowOrgSettingQuery, TimesheetEntryRowOrgSettingQueryVariables>;
export const TimesheetTimeOffDocument = gql`
    query TimesheetTimeOff($profileSlug: String!, $beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  timeOffRequests(
    filters: {profileSlug: $profileSlug, statuses: [approved, pending, taken], dateRange: {beginDate: $beginDate, endDate: $endDate}}
    first: 100
  ) {
    nodes {
      ...TimesheetTimeOffRequest
    }
  }
}
    ${TimesheetTimeOffRequestFragmentDoc}`;

/**
 * __useTimesheetTimeOffQuery__
 *
 * To run a query within a React component, call `useTimesheetTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetTimeOffQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTimesheetTimeOffQuery(baseOptions: Apollo.QueryHookOptions<TimesheetTimeOffQuery, TimesheetTimeOffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimesheetTimeOffQuery, TimesheetTimeOffQueryVariables>(TimesheetTimeOffDocument, options);
      }
export function useTimesheetTimeOffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimesheetTimeOffQuery, TimesheetTimeOffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimesheetTimeOffQuery, TimesheetTimeOffQueryVariables>(TimesheetTimeOffDocument, options);
        }
export type TimesheetTimeOffQueryHookResult = ReturnType<typeof useTimesheetTimeOffQuery>;
export type TimesheetTimeOffLazyQueryHookResult = ReturnType<typeof useTimesheetTimeOffLazyQuery>;
export type TimesheetTimeOffQueryResult = Apollo.QueryResult<TimesheetTimeOffQuery, TimesheetTimeOffQueryVariables>;
export const ProfileTimesheetsCsvDocument = gql`
    mutation ProfileTimesheetsCsv($profileSlug: String, $beginDate: ISO8601Date!, $endDate: ISO8601Date!, $includeNotes: Boolean!, $csvFormat: CsvFormat!) {
  startExport {
    profileTimesheets(
      input: {profileSlug: $profileSlug, beginDate: $beginDate, endDate: $endDate, includeNotes: $includeNotes, csvFormat: $csvFormat}
    ) {
      id
    }
  }
}
    `;
export type ProfileTimesheetsCsvMutationFn = Apollo.MutationFunction<ProfileTimesheetsCsvMutation, ProfileTimesheetsCsvMutationVariables>;

/**
 * __useProfileTimesheetsCsvMutation__
 *
 * To run a mutation, you first call `useProfileTimesheetsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileTimesheetsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileTimesheetsCsvMutation, { data, loading, error }] = useProfileTimesheetsCsvMutation({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      includeNotes: // value for 'includeNotes'
 *      csvFormat: // value for 'csvFormat'
 *   },
 * });
 */
export function useProfileTimesheetsCsvMutation(baseOptions?: Apollo.MutationHookOptions<ProfileTimesheetsCsvMutation, ProfileTimesheetsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileTimesheetsCsvMutation, ProfileTimesheetsCsvMutationVariables>(ProfileTimesheetsCsvDocument, options);
      }
export type ProfileTimesheetsCsvMutationHookResult = ReturnType<typeof useProfileTimesheetsCsvMutation>;
export type ProfileTimesheetsCsvMutationResult = Apollo.MutationResult<ProfileTimesheetsCsvMutation>;
export type ProfileTimesheetsCsvMutationOptions = Apollo.BaseMutationOptions<ProfileTimesheetsCsvMutation, ProfileTimesheetsCsvMutationVariables>;
export const OrganizationTimesheetsCsvDocument = gql`
    mutation OrganizationTimesheetsCsv($beginDate: ISO8601Date!, $endDate: ISO8601Date!, $includeNotes: Boolean!, $csvFormat: CsvFormat!) {
  startExport {
    organizationTimesheets(
      input: {beginDate: $beginDate, endDate: $endDate, includeNotes: $includeNotes, csvFormat: $csvFormat}
    ) {
      id
    }
  }
}
    `;
export type OrganizationTimesheetsCsvMutationFn = Apollo.MutationFunction<OrganizationTimesheetsCsvMutation, OrganizationTimesheetsCsvMutationVariables>;

/**
 * __useOrganizationTimesheetsCsvMutation__
 *
 * To run a mutation, you first call `useOrganizationTimesheetsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTimesheetsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTimesheetsCsvMutation, { data, loading, error }] = useOrganizationTimesheetsCsvMutation({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      includeNotes: // value for 'includeNotes'
 *      csvFormat: // value for 'csvFormat'
 *   },
 * });
 */
export function useOrganizationTimesheetsCsvMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationTimesheetsCsvMutation, OrganizationTimesheetsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationTimesheetsCsvMutation, OrganizationTimesheetsCsvMutationVariables>(OrganizationTimesheetsCsvDocument, options);
      }
export type OrganizationTimesheetsCsvMutationHookResult = ReturnType<typeof useOrganizationTimesheetsCsvMutation>;
export type OrganizationTimesheetsCsvMutationResult = Apollo.MutationResult<OrganizationTimesheetsCsvMutation>;
export type OrganizationTimesheetsCsvMutationOptions = Apollo.BaseMutationOptions<OrganizationTimesheetsCsvMutation, OrganizationTimesheetsCsvMutationVariables>;
export const OrganizationTimesheetsAjeraCsvDocument = gql`
    mutation OrganizationTimesheetsAjeraCsv($beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  startExport {
    organizationTimesheetsAjera(input: {beginDate: $beginDate, endDate: $endDate}) {
      id
    }
  }
}
    `;
export type OrganizationTimesheetsAjeraCsvMutationFn = Apollo.MutationFunction<OrganizationTimesheetsAjeraCsvMutation, OrganizationTimesheetsAjeraCsvMutationVariables>;

/**
 * __useOrganizationTimesheetsAjeraCsvMutation__
 *
 * To run a mutation, you first call `useOrganizationTimesheetsAjeraCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTimesheetsAjeraCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTimesheetsAjeraCsvMutation, { data, loading, error }] = useOrganizationTimesheetsAjeraCsvMutation({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOrganizationTimesheetsAjeraCsvMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationTimesheetsAjeraCsvMutation, OrganizationTimesheetsAjeraCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationTimesheetsAjeraCsvMutation, OrganizationTimesheetsAjeraCsvMutationVariables>(OrganizationTimesheetsAjeraCsvDocument, options);
      }
export type OrganizationTimesheetsAjeraCsvMutationHookResult = ReturnType<typeof useOrganizationTimesheetsAjeraCsvMutation>;
export type OrganizationTimesheetsAjeraCsvMutationResult = Apollo.MutationResult<OrganizationTimesheetsAjeraCsvMutation>;
export type OrganizationTimesheetsAjeraCsvMutationOptions = Apollo.BaseMutationOptions<OrganizationTimesheetsAjeraCsvMutation, OrganizationTimesheetsAjeraCsvMutationVariables>;
export const LockTimesheetDocument = gql`
    mutation LockTimesheet($id: ID!, $isUnlocked: Boolean!) {
  updateTimesheet(input: {id: $id, isUnlocked: $isUnlocked}) {
    timesheet {
      id
      isLocked
      isLockable
    }
  }
}
    `;
export type LockTimesheetMutationFn = Apollo.MutationFunction<LockTimesheetMutation, LockTimesheetMutationVariables>;

/**
 * __useLockTimesheetMutation__
 *
 * To run a mutation, you first call `useLockTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockTimesheetMutation, { data, loading, error }] = useLockTimesheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isUnlocked: // value for 'isUnlocked'
 *   },
 * });
 */
export function useLockTimesheetMutation(baseOptions?: Apollo.MutationHookOptions<LockTimesheetMutation, LockTimesheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LockTimesheetMutation, LockTimesheetMutationVariables>(LockTimesheetDocument, options);
      }
export type LockTimesheetMutationHookResult = ReturnType<typeof useLockTimesheetMutation>;
export type LockTimesheetMutationResult = Apollo.MutationResult<LockTimesheetMutation>;
export type LockTimesheetMutationOptions = Apollo.BaseMutationOptions<LockTimesheetMutation, LockTimesheetMutationVariables>;
export const UpdateOrganizationTrialDocument = gql`
    mutation UpdateOrganizationTrial($id: ID!, $trialEndDate: String!) {
  admin {
    updateOrganizationTrial(input: {id: $id, trialEndDate: $trialEndDate}) {
      organization {
        id
        trialEndDate
      }
    }
  }
}
    `;
export type UpdateOrganizationTrialMutationFn = Apollo.MutationFunction<UpdateOrganizationTrialMutation, UpdateOrganizationTrialMutationVariables>;

/**
 * __useUpdateOrganizationTrialMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationTrialMutation, { data, loading, error }] = useUpdateOrganizationTrialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trialEndDate: // value for 'trialEndDate'
 *   },
 * });
 */
export function useUpdateOrganizationTrialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationTrialMutation, UpdateOrganizationTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationTrialMutation, UpdateOrganizationTrialMutationVariables>(UpdateOrganizationTrialDocument, options);
      }
export type UpdateOrganizationTrialMutationHookResult = ReturnType<typeof useUpdateOrganizationTrialMutation>;
export type UpdateOrganizationTrialMutationResult = Apollo.MutationResult<UpdateOrganizationTrialMutation>;
export type UpdateOrganizationTrialMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationTrialMutation, UpdateOrganizationTrialMutationVariables>;
export const UpdateOrganizationStatusDocument = gql`
    mutation UpdateOrganizationStatus($id: ID!, $status: OrganizationStatusEnum!) {
  admin {
    updateOrganizationStatus(input: {id: $id, status: $status}) {
      organization {
        id
        status
      }
    }
  }
}
    `;
export type UpdateOrganizationStatusMutationFn = Apollo.MutationFunction<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;

/**
 * __useUpdateOrganizationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationStatusMutation, { data, loading, error }] = useUpdateOrganizationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOrganizationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>(UpdateOrganizationStatusDocument, options);
      }
export type UpdateOrganizationStatusMutationHookResult = ReturnType<typeof useUpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationResult = Apollo.MutationResult<UpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>;
export const UpdateOrganizationQualifiedDocument = gql`
    mutation UpdateOrganizationQualified($id: ID!, $isQualified: Boolean!) {
  admin {
    updateOrganizationQualified(input: {id: $id, isQualified: $isQualified}) {
      organization {
        id
        isQualified
      }
    }
  }
}
    `;
export type UpdateOrganizationQualifiedMutationFn = Apollo.MutationFunction<UpdateOrganizationQualifiedMutation, UpdateOrganizationQualifiedMutationVariables>;

/**
 * __useUpdateOrganizationQualifiedMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationQualifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationQualifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationQualifiedMutation, { data, loading, error }] = useUpdateOrganizationQualifiedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isQualified: // value for 'isQualified'
 *   },
 * });
 */
export function useUpdateOrganizationQualifiedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationQualifiedMutation, UpdateOrganizationQualifiedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationQualifiedMutation, UpdateOrganizationQualifiedMutationVariables>(UpdateOrganizationQualifiedDocument, options);
      }
export type UpdateOrganizationQualifiedMutationHookResult = ReturnType<typeof useUpdateOrganizationQualifiedMutation>;
export type UpdateOrganizationQualifiedMutationResult = Apollo.MutationResult<UpdateOrganizationQualifiedMutation>;
export type UpdateOrganizationQualifiedMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationQualifiedMutation, UpdateOrganizationQualifiedMutationVariables>;
export const ResetDemoDocument = gql`
    mutation ResetDemo($organizationId: ID!) {
  admin {
    resetDemo(input: {organizationId: $organizationId}) {
      organization {
        id
      }
    }
  }
}
    `;
export type ResetDemoMutationFn = Apollo.MutationFunction<ResetDemoMutation, ResetDemoMutationVariables>;

/**
 * __useResetDemoMutation__
 *
 * To run a mutation, you first call `useResetDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDemoMutation, { data, loading, error }] = useResetDemoMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useResetDemoMutation(baseOptions?: Apollo.MutationHookOptions<ResetDemoMutation, ResetDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetDemoMutation, ResetDemoMutationVariables>(ResetDemoDocument, options);
      }
export type ResetDemoMutationHookResult = ReturnType<typeof useResetDemoMutation>;
export type ResetDemoMutationResult = Apollo.MutationResult<ResetDemoMutation>;
export type ResetDemoMutationOptions = Apollo.BaseMutationOptions<ResetDemoMutation, ResetDemoMutationVariables>;
export const CloneShowcaseTemplateDocument = gql`
    mutation CloneShowcaseTemplate($organizationId: ID!) {
  admin {
    cloneShowcaseTemplate(input: {organizationId: $organizationId}) {
      organization {
        id
      }
    }
  }
}
    `;
export type CloneShowcaseTemplateMutationFn = Apollo.MutationFunction<CloneShowcaseTemplateMutation, CloneShowcaseTemplateMutationVariables>;

/**
 * __useCloneShowcaseTemplateMutation__
 *
 * To run a mutation, you first call `useCloneShowcaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneShowcaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneShowcaseTemplateMutation, { data, loading, error }] = useCloneShowcaseTemplateMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCloneShowcaseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CloneShowcaseTemplateMutation, CloneShowcaseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneShowcaseTemplateMutation, CloneShowcaseTemplateMutationVariables>(CloneShowcaseTemplateDocument, options);
      }
export type CloneShowcaseTemplateMutationHookResult = ReturnType<typeof useCloneShowcaseTemplateMutation>;
export type CloneShowcaseTemplateMutationResult = Apollo.MutationResult<CloneShowcaseTemplateMutation>;
export type CloneShowcaseTemplateMutationOptions = Apollo.BaseMutationOptions<CloneShowcaseTemplateMutation, CloneShowcaseTemplateMutationVariables>;
export const ConfirmUserEmailDocument = gql`
    mutation ConfirmUserEmail($profileId: ID) {
  admin {
    confirmUserEmail(input: {profileId: $profileId}) {
      user {
        id
      }
    }
  }
}
    `;
export type ConfirmUserEmailMutationFn = Apollo.MutationFunction<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;

/**
 * __useConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailMutation, { data, loading, error }] = useConfirmUserEmailMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useConfirmUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>(ConfirmUserEmailDocument, options);
      }
export type ConfirmUserEmailMutationHookResult = ReturnType<typeof useConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationResult = Apollo.MutationResult<ConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;
export const GenerateUserInviteLinkDocument = gql`
    mutation GenerateUserInviteLink($profileId: ID) {
  admin {
    generateUserInviteLink(input: {profileId: $profileId}) {
      inviteLink
    }
  }
}
    `;
export type GenerateUserInviteLinkMutationFn = Apollo.MutationFunction<GenerateUserInviteLinkMutation, GenerateUserInviteLinkMutationVariables>;

/**
 * __useGenerateUserInviteLinkMutation__
 *
 * To run a mutation, you first call `useGenerateUserInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserInviteLinkMutation, { data, loading, error }] = useGenerateUserInviteLinkMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGenerateUserInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUserInviteLinkMutation, GenerateUserInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUserInviteLinkMutation, GenerateUserInviteLinkMutationVariables>(GenerateUserInviteLinkDocument, options);
      }
export type GenerateUserInviteLinkMutationHookResult = ReturnType<typeof useGenerateUserInviteLinkMutation>;
export type GenerateUserInviteLinkMutationResult = Apollo.MutationResult<GenerateUserInviteLinkMutation>;
export type GenerateUserInviteLinkMutationOptions = Apollo.BaseMutationOptions<GenerateUserInviteLinkMutation, GenerateUserInviteLinkMutationVariables>;
export const ToggleUserSuperadminStatusDocument = gql`
    mutation ToggleUserSuperadminStatus($email: String!) {
  admin {
    toggleUserSuperadminStatus(input: {email: $email}) {
      user {
        id
      }
    }
  }
}
    `;
export type ToggleUserSuperadminStatusMutationFn = Apollo.MutationFunction<ToggleUserSuperadminStatusMutation, ToggleUserSuperadminStatusMutationVariables>;

/**
 * __useToggleUserSuperadminStatusMutation__
 *
 * To run a mutation, you first call `useToggleUserSuperadminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserSuperadminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserSuperadminStatusMutation, { data, loading, error }] = useToggleUserSuperadminStatusMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useToggleUserSuperadminStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUserSuperadminStatusMutation, ToggleUserSuperadminStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUserSuperadminStatusMutation, ToggleUserSuperadminStatusMutationVariables>(ToggleUserSuperadminStatusDocument, options);
      }
export type ToggleUserSuperadminStatusMutationHookResult = ReturnType<typeof useToggleUserSuperadminStatusMutation>;
export type ToggleUserSuperadminStatusMutationResult = Apollo.MutationResult<ToggleUserSuperadminStatusMutation>;
export type ToggleUserSuperadminStatusMutationOptions = Apollo.BaseMutationOptions<ToggleUserSuperadminStatusMutation, ToggleUserSuperadminStatusMutationVariables>;
export const CreateAccountDocument = gql`
    mutation CreateAccount($email: String!, $fname: String, $lname: String, $organizationName: String!, $subIndustry: String) {
  admin {
    createAccount(
      input: {email: $email, fname: $fname, lname: $lname, organizationName: $organizationName, subIndustry: $subIndustry}
    ) {
      user {
        id
      }
      organization {
        id
      }
      profile {
        id
      }
    }
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fname: // value for 'fname'
 *      lname: // value for 'lname'
 *      organizationName: // value for 'organizationName'
 *      subIndustry: // value for 'subIndustry'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const AddOrganizationToPayrollDocument = gql`
    mutation AddOrganizationToPayroll($organizationId: ID!, $legalName: String!, $address: PayrollAddressInput!) {
  createPayrollCompany(
    input: {organizationId: $organizationId, legalName: $legalName, address: $address}
  ) {
    company {
      info {
        id
      }
    }
  }
}
    `;
export type AddOrganizationToPayrollMutationFn = Apollo.MutationFunction<AddOrganizationToPayrollMutation, AddOrganizationToPayrollMutationVariables>;

/**
 * __useAddOrganizationToPayrollMutation__
 *
 * To run a mutation, you first call `useAddOrganizationToPayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationToPayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationToPayrollMutation, { data, loading, error }] = useAddOrganizationToPayrollMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      legalName: // value for 'legalName'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddOrganizationToPayrollMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationToPayrollMutation, AddOrganizationToPayrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationToPayrollMutation, AddOrganizationToPayrollMutationVariables>(AddOrganizationToPayrollDocument, options);
      }
export type AddOrganizationToPayrollMutationHookResult = ReturnType<typeof useAddOrganizationToPayrollMutation>;
export type AddOrganizationToPayrollMutationResult = Apollo.MutationResult<AddOrganizationToPayrollMutation>;
export type AddOrganizationToPayrollMutationOptions = Apollo.BaseMutationOptions<AddOrganizationToPayrollMutation, AddOrganizationToPayrollMutationVariables>;
export const SuperAdminUsersDocument = gql`
    query superAdminUsers($isAdmin: Boolean) {
  admin {
    recentUsers(isAdmin: $isAdmin) {
      ...SuperAdminFields
    }
  }
}
    ${SuperAdminFieldsFragmentDoc}`;

/**
 * __useSuperAdminUsersQuery__
 *
 * To run a query within a React component, call `useSuperAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminUsersQuery({
 *   variables: {
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useSuperAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<SuperAdminUsersQuery, SuperAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperAdminUsersQuery, SuperAdminUsersQueryVariables>(SuperAdminUsersDocument, options);
      }
export function useSuperAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperAdminUsersQuery, SuperAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperAdminUsersQuery, SuperAdminUsersQueryVariables>(SuperAdminUsersDocument, options);
        }
export type SuperAdminUsersQueryHookResult = ReturnType<typeof useSuperAdminUsersQuery>;
export type SuperAdminUsersLazyQueryHookResult = ReturnType<typeof useSuperAdminUsersLazyQuery>;
export type SuperAdminUsersQueryResult = Apollo.QueryResult<SuperAdminUsersQuery, SuperAdminUsersQueryVariables>;
export const ConnectSubscriptionDocument = gql`
    mutation ConnectSubscription($id: String!, $planType: String!, $planName: String!, $stripeId: String!) {
  admin {
    connectSubscription(
      input: {id: $id, planType: $planType, planName: $planName, stripeId: $stripeId}
    ) {
      organization {
        id
      }
    }
  }
}
    `;
export type ConnectSubscriptionMutationFn = Apollo.MutationFunction<ConnectSubscriptionMutation, ConnectSubscriptionMutationVariables>;

/**
 * __useConnectSubscriptionMutation__
 *
 * To run a mutation, you first call `useConnectSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectSubscriptionMutation, { data, loading, error }] = useConnectSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      planType: // value for 'planType'
 *      planName: // value for 'planName'
 *      stripeId: // value for 'stripeId'
 *   },
 * });
 */
export function useConnectSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ConnectSubscriptionMutation, ConnectSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectSubscriptionMutation, ConnectSubscriptionMutationVariables>(ConnectSubscriptionDocument, options);
      }
export type ConnectSubscriptionMutationHookResult = ReturnType<typeof useConnectSubscriptionMutation>;
export type ConnectSubscriptionMutationResult = Apollo.MutationResult<ConnectSubscriptionMutation>;
export type ConnectSubscriptionMutationOptions = Apollo.BaseMutationOptions<ConnectSubscriptionMutation, ConnectSubscriptionMutationVariables>;
export const CreateEmailLinkAndSendToUserDocument = gql`
    mutation createEmailLinkAndSendToUser($userId: ID!) {
  admin {
    createEmailLinkAndSendToUser(input: {userId: $userId}) {
      user {
        id
      }
    }
  }
}
    `;
export type CreateEmailLinkAndSendToUserMutationFn = Apollo.MutationFunction<CreateEmailLinkAndSendToUserMutation, CreateEmailLinkAndSendToUserMutationVariables>;

/**
 * __useCreateEmailLinkAndSendToUserMutation__
 *
 * To run a mutation, you first call `useCreateEmailLinkAndSendToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailLinkAndSendToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailLinkAndSendToUserMutation, { data, loading, error }] = useCreateEmailLinkAndSendToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateEmailLinkAndSendToUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailLinkAndSendToUserMutation, CreateEmailLinkAndSendToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailLinkAndSendToUserMutation, CreateEmailLinkAndSendToUserMutationVariables>(CreateEmailLinkAndSendToUserDocument, options);
      }
export type CreateEmailLinkAndSendToUserMutationHookResult = ReturnType<typeof useCreateEmailLinkAndSendToUserMutation>;
export type CreateEmailLinkAndSendToUserMutationResult = Apollo.MutationResult<CreateEmailLinkAndSendToUserMutation>;
export type CreateEmailLinkAndSendToUserMutationOptions = Apollo.BaseMutationOptions<CreateEmailLinkAndSendToUserMutation, CreateEmailLinkAndSendToUserMutationVariables>;
export const AjeraSettingsDocument = gql`
    query AjeraSettings {
  currentProfile {
    organization {
      settings {
        id
        ajeraEnabled
      }
    }
  }
}
    `;

/**
 * __useAjeraSettingsQuery__
 *
 * To run a query within a React component, call `useAjeraSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAjeraSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAjeraSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAjeraSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AjeraSettingsQuery, AjeraSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AjeraSettingsQuery, AjeraSettingsQueryVariables>(AjeraSettingsDocument, options);
      }
export function useAjeraSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AjeraSettingsQuery, AjeraSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AjeraSettingsQuery, AjeraSettingsQueryVariables>(AjeraSettingsDocument, options);
        }
export type AjeraSettingsQueryHookResult = ReturnType<typeof useAjeraSettingsQuery>;
export type AjeraSettingsLazyQueryHookResult = ReturnType<typeof useAjeraSettingsLazyQuery>;
export type AjeraSettingsQueryResult = Apollo.QueryResult<AjeraSettingsQuery, AjeraSettingsQueryVariables>;
export const ProjectPhasesForAjeraDocument = gql`
    query ProjectPhasesForAjera($slug: String!) {
  project(slug: $slug) {
    orderedPhases {
      id
      name
      ajeraIdentifier {
        ...AjeraFields
      }
    }
  }
}
    ${AjeraFieldsFragmentDoc}`;

/**
 * __useProjectPhasesForAjeraQuery__
 *
 * To run a query within a React component, call `useProjectPhasesForAjeraQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPhasesForAjeraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPhasesForAjeraQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectPhasesForAjeraQuery(baseOptions: Apollo.QueryHookOptions<ProjectPhasesForAjeraQuery, ProjectPhasesForAjeraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPhasesForAjeraQuery, ProjectPhasesForAjeraQueryVariables>(ProjectPhasesForAjeraDocument, options);
      }
export function useProjectPhasesForAjeraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPhasesForAjeraQuery, ProjectPhasesForAjeraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPhasesForAjeraQuery, ProjectPhasesForAjeraQueryVariables>(ProjectPhasesForAjeraDocument, options);
        }
export type ProjectPhasesForAjeraQueryHookResult = ReturnType<typeof useProjectPhasesForAjeraQuery>;
export type ProjectPhasesForAjeraLazyQueryHookResult = ReturnType<typeof useProjectPhasesForAjeraLazyQuery>;
export type ProjectPhasesForAjeraQueryResult = Apollo.QueryResult<ProjectPhasesForAjeraQuery, ProjectPhasesForAjeraQueryVariables>;
export const ClientDocument = gql`
    query client($slug: String) {
  client(slug: $slug) {
    ...ClientResult
  }
}
    ${ClientResultFragmentDoc}`;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useClientQuery(baseOptions?: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($data: UpdateClientInput!) {
  updateClient(input: $data) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const AddClientDocument = gql`
    mutation AddClient($data: AddClientInput!) {
  addClient(input: $data) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type AddClientMutationFn = Apollo.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;
export const DeactivateClientDocument = gql`
    mutation DeactivateClient($data: DeactivateClientInput!) {
  deactivateClient(input: $data) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type DeactivateClientMutationFn = Apollo.MutationFunction<DeactivateClientMutation, DeactivateClientMutationVariables>;

/**
 * __useDeactivateClientMutation__
 *
 * To run a mutation, you first call `useDeactivateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateClientMutation, { data, loading, error }] = useDeactivateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeactivateClientMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateClientMutation, DeactivateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateClientMutation, DeactivateClientMutationVariables>(DeactivateClientDocument, options);
      }
export type DeactivateClientMutationHookResult = ReturnType<typeof useDeactivateClientMutation>;
export type DeactivateClientMutationResult = Apollo.MutationResult<DeactivateClientMutation>;
export type DeactivateClientMutationOptions = Apollo.BaseMutationOptions<DeactivateClientMutation, DeactivateClientMutationVariables>;
export const ReactivateClientDocument = gql`
    mutation ReactivateClient($data: ReactivateClientInput!) {
  reactivateClient(input: $data) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type ReactivateClientMutationFn = Apollo.MutationFunction<ReactivateClientMutation, ReactivateClientMutationVariables>;

/**
 * __useReactivateClientMutation__
 *
 * To run a mutation, you first call `useReactivateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateClientMutation, { data, loading, error }] = useReactivateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateClientMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateClientMutation, ReactivateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateClientMutation, ReactivateClientMutationVariables>(ReactivateClientDocument, options);
      }
export type ReactivateClientMutationHookResult = ReturnType<typeof useReactivateClientMutation>;
export type ReactivateClientMutationResult = Apollo.MutationResult<ReactivateClientMutation>;
export type ReactivateClientMutationOptions = Apollo.BaseMutationOptions<ReactivateClientMutation, ReactivateClientMutationVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($data: DeleteClientInput!) {
  deleteClient(input: $data) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const ProcessQboCustomersDocument = gql`
    mutation ProcessQboCustomers($input: ProcessQBOCustomersInput!) {
  processQboCustomers(input: $input) {
    qboCustomers {
      id
    }
  }
}
    `;
export type ProcessQboCustomersMutationFn = Apollo.MutationFunction<ProcessQboCustomersMutation, ProcessQboCustomersMutationVariables>;

/**
 * __useProcessQboCustomersMutation__
 *
 * To run a mutation, you first call `useProcessQboCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessQboCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processQboCustomersMutation, { data, loading, error }] = useProcessQboCustomersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessQboCustomersMutation(baseOptions?: Apollo.MutationHookOptions<ProcessQboCustomersMutation, ProcessQboCustomersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessQboCustomersMutation, ProcessQboCustomersMutationVariables>(ProcessQboCustomersDocument, options);
      }
export type ProcessQboCustomersMutationHookResult = ReturnType<typeof useProcessQboCustomersMutation>;
export type ProcessQboCustomersMutationResult = Apollo.MutationResult<ProcessQboCustomersMutation>;
export type ProcessQboCustomersMutationOptions = Apollo.BaseMutationOptions<ProcessQboCustomersMutation, ProcessQboCustomersMutationVariables>;
export const DetachClientDocument = gql`
    mutation DetachClient($id: ID!) {
  detachClient(input: {id: $id}) {
    client {
      id
      qboCustomer {
        id
      }
    }
  }
}
    `;
export type DetachClientMutationFn = Apollo.MutationFunction<DetachClientMutation, DetachClientMutationVariables>;

/**
 * __useDetachClientMutation__
 *
 * To run a mutation, you first call `useDetachClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachClientMutation, { data, loading, error }] = useDetachClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDetachClientMutation(baseOptions?: Apollo.MutationHookOptions<DetachClientMutation, DetachClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DetachClientMutation, DetachClientMutationVariables>(DetachClientDocument, options);
      }
export type DetachClientMutationHookResult = ReturnType<typeof useDetachClientMutation>;
export type DetachClientMutationResult = Apollo.MutationResult<DetachClientMutation>;
export type DetachClientMutationOptions = Apollo.BaseMutationOptions<DetachClientMutation, DetachClientMutationVariables>;
export const FeatureFlagsDocument = gql`
    query featureFlags {
  featureFlags {
    ...Flags
  }
}
    ${FlagsFragmentDoc}`;

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
      }
export function useFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsQuery, FeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument, options);
        }
export type FeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsQuery>;
export type FeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsLazyQuery>;
export type FeatureFlagsQueryResult = Apollo.QueryResult<FeatureFlagsQuery, FeatureFlagsQueryVariables>;
export const SettingsDocument = gql`
    query Settings {
  currentProfile {
    id
    organization {
      ...GlobalOrganizationSettings
    }
  }
}
    ${GlobalOrganizationSettingsFragmentDoc}`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...CurrentUser
    organizations {
      id
      name
      logo
    }
  }
  currentProfile {
    id
    fname
    lname
    name
    initials
    slug
    email
    title
    intercomUserHash
    image
    confirmedAt
    policy {
      id
      isAdmin
      name
      permissions {
        ...CurrentProfilePermissions
      }
    }
    organization {
      ...CurrentOrganization
      demo {
        id
        name
        size
      }
      demoParent {
        id
        name
        size
        logo
      }
    }
    user {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}
${CurrentProfilePermissionsFragmentDoc}
${CurrentOrganizationFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserNameDocument = gql`
    query userName {
  me {
    fullName
    fname
    lname
  }
}
    `;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(baseOptions?: Apollo.QueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
      }
export function useUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
        }
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<typeof useUserNameLazyQuery>;
export type UserNameQueryResult = Apollo.QueryResult<UserNameQuery, UserNameQueryVariables>;
export const ProfileCompensationsDocument = gql`
    query profileCompensations($profileId: ID!) {
  profile(id: $profileId) {
    id
    compensations {
      ...Compensation
    }
  }
}
    ${CompensationFragmentDoc}`;

/**
 * __useProfileCompensationsQuery__
 *
 * To run a query within a React component, call `useProfileCompensationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileCompensationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileCompensationsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useProfileCompensationsQuery(baseOptions: Apollo.QueryHookOptions<ProfileCompensationsQuery, ProfileCompensationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileCompensationsQuery, ProfileCompensationsQueryVariables>(ProfileCompensationsDocument, options);
      }
export function useProfileCompensationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileCompensationsQuery, ProfileCompensationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileCompensationsQuery, ProfileCompensationsQueryVariables>(ProfileCompensationsDocument, options);
        }
export type ProfileCompensationsQueryHookResult = ReturnType<typeof useProfileCompensationsQuery>;
export type ProfileCompensationsLazyQueryHookResult = ReturnType<typeof useProfileCompensationsLazyQuery>;
export type ProfileCompensationsQueryResult = Apollo.QueryResult<ProfileCompensationsQuery, ProfileCompensationsQueryVariables>;
export const CreateCompensationDocument = gql`
    mutation CreateCompensation($data: CreateCompensationInput!) {
  createCompensation(input: $data) {
    compensation {
      ...Compensation
    }
  }
}
    ${CompensationFragmentDoc}`;
export type CreateCompensationMutationFn = Apollo.MutationFunction<CreateCompensationMutation, CreateCompensationMutationVariables>;

/**
 * __useCreateCompensationMutation__
 *
 * To run a mutation, you first call `useCreateCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompensationMutation, { data, loading, error }] = useCreateCompensationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompensationMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompensationMutation, CreateCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompensationMutation, CreateCompensationMutationVariables>(CreateCompensationDocument, options);
      }
export type CreateCompensationMutationHookResult = ReturnType<typeof useCreateCompensationMutation>;
export type CreateCompensationMutationResult = Apollo.MutationResult<CreateCompensationMutation>;
export type CreateCompensationMutationOptions = Apollo.BaseMutationOptions<CreateCompensationMutation, CreateCompensationMutationVariables>;
export const EditCompensationDocument = gql`
    mutation EditCompensation($data: UpdateCompensationInput!) {
  updateCompensation(input: $data) {
    compensation {
      ...Compensation
    }
  }
}
    ${CompensationFragmentDoc}`;
export type EditCompensationMutationFn = Apollo.MutationFunction<EditCompensationMutation, EditCompensationMutationVariables>;

/**
 * __useEditCompensationMutation__
 *
 * To run a mutation, you first call `useEditCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompensationMutation, { data, loading, error }] = useEditCompensationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditCompensationMutation(baseOptions?: Apollo.MutationHookOptions<EditCompensationMutation, EditCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompensationMutation, EditCompensationMutationVariables>(EditCompensationDocument, options);
      }
export type EditCompensationMutationHookResult = ReturnType<typeof useEditCompensationMutation>;
export type EditCompensationMutationResult = Apollo.MutationResult<EditCompensationMutation>;
export type EditCompensationMutationOptions = Apollo.BaseMutationOptions<EditCompensationMutation, EditCompensationMutationVariables>;
export const StopCompensationDocument = gql`
    mutation StopCompensation($data: StopCompensationInput!) {
  stopCompensation(input: $data) {
    compensation {
      ...Compensation
    }
  }
}
    ${CompensationFragmentDoc}`;
export type StopCompensationMutationFn = Apollo.MutationFunction<StopCompensationMutation, StopCompensationMutationVariables>;

/**
 * __useStopCompensationMutation__
 *
 * To run a mutation, you first call `useStopCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopCompensationMutation, { data, loading, error }] = useStopCompensationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStopCompensationMutation(baseOptions?: Apollo.MutationHookOptions<StopCompensationMutation, StopCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopCompensationMutation, StopCompensationMutationVariables>(StopCompensationDocument, options);
      }
export type StopCompensationMutationHookResult = ReturnType<typeof useStopCompensationMutation>;
export type StopCompensationMutationResult = Apollo.MutationResult<StopCompensationMutation>;
export type StopCompensationMutationOptions = Apollo.BaseMutationOptions<StopCompensationMutation, StopCompensationMutationVariables>;
export const DeleteCompensationDocument = gql`
    mutation DeleteCompensation($data: DeleteCompensationInput!) {
  deleteCompensation(input: $data) {
    compensation {
      ...Compensation
    }
  }
}
    ${CompensationFragmentDoc}`;
export type DeleteCompensationMutationFn = Apollo.MutationFunction<DeleteCompensationMutation, DeleteCompensationMutationVariables>;

/**
 * __useDeleteCompensationMutation__
 *
 * To run a mutation, you first call `useDeleteCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompensationMutation, { data, loading, error }] = useDeleteCompensationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteCompensationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompensationMutation, DeleteCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompensationMutation, DeleteCompensationMutationVariables>(DeleteCompensationDocument, options);
      }
export type DeleteCompensationMutationHookResult = ReturnType<typeof useDeleteCompensationMutation>;
export type DeleteCompensationMutationResult = Apollo.MutationResult<DeleteCompensationMutation>;
export type DeleteCompensationMutationOptions = Apollo.BaseMutationOptions<DeleteCompensationMutation, DeleteCompensationMutationVariables>;
export const ConsultantBillsDocument = gql`
    query ConsultantBills($sortBy: ConsultantBillsSortInput, $filters: ConsultantBillFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  consultantBills(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    totalBilled
    owedCount
    owedAmount
    paidCount
    paidAmount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...ConsultantBill
    }
  }
}
    ${ConsultantBillFragmentDoc}`;

/**
 * __useConsultantBillsQuery__
 *
 * To run a query within a React component, call `useConsultantBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantBillsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useConsultantBillsQuery(baseOptions: Apollo.QueryHookOptions<ConsultantBillsQuery, ConsultantBillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantBillsQuery, ConsultantBillsQueryVariables>(ConsultantBillsDocument, options);
      }
export function useConsultantBillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantBillsQuery, ConsultantBillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantBillsQuery, ConsultantBillsQueryVariables>(ConsultantBillsDocument, options);
        }
export type ConsultantBillsQueryHookResult = ReturnType<typeof useConsultantBillsQuery>;
export type ConsultantBillsLazyQueryHookResult = ReturnType<typeof useConsultantBillsLazyQuery>;
export type ConsultantBillsQueryResult = Apollo.QueryResult<ConsultantBillsQuery, ConsultantBillsQueryVariables>;
export const AllProjectConsultantsDocument = gql`
    query AllProjectConsultants($id: ID!) {
  project(id: $id) {
    phaseConsultants {
      entries {
        ...ConsultantBillProjectConsultant
      }
    }
    projectConsultants {
      ...ConsultantBillProjectConsultant
    }
  }
}
    ${ConsultantBillProjectConsultantFragmentDoc}`;

/**
 * __useAllProjectConsultantsQuery__
 *
 * To run a query within a React component, call `useAllProjectConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectConsultantsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllProjectConsultantsQuery(baseOptions: Apollo.QueryHookOptions<AllProjectConsultantsQuery, AllProjectConsultantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProjectConsultantsQuery, AllProjectConsultantsQueryVariables>(AllProjectConsultantsDocument, options);
      }
export function useAllProjectConsultantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProjectConsultantsQuery, AllProjectConsultantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProjectConsultantsQuery, AllProjectConsultantsQueryVariables>(AllProjectConsultantsDocument, options);
        }
export type AllProjectConsultantsQueryHookResult = ReturnType<typeof useAllProjectConsultantsQuery>;
export type AllProjectConsultantsLazyQueryHookResult = ReturnType<typeof useAllProjectConsultantsLazyQuery>;
export type AllProjectConsultantsQueryResult = Apollo.QueryResult<AllProjectConsultantsQuery, AllProjectConsultantsQueryVariables>;
export const QuickbooksExpenseAccountsDocument = gql`
    query QuickbooksExpenseAccounts($accountType: [QBOAccountType!]) {
  quickbooksExpenseAccounts(accountType: $accountType) {
    id
    name
    active
    fullyQualifiedName
    parentQuickbooksId
    quickbooksId
    accountType
    accountSubType
    costCategory
    description
  }
}
    `;

/**
 * __useQuickbooksExpenseAccountsQuery__
 *
 * To run a query within a React component, call `useQuickbooksExpenseAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksExpenseAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksExpenseAccountsQuery({
 *   variables: {
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useQuickbooksExpenseAccountsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksExpenseAccountsQuery, QuickbooksExpenseAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksExpenseAccountsQuery, QuickbooksExpenseAccountsQueryVariables>(QuickbooksExpenseAccountsDocument, options);
      }
export function useQuickbooksExpenseAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksExpenseAccountsQuery, QuickbooksExpenseAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksExpenseAccountsQuery, QuickbooksExpenseAccountsQueryVariables>(QuickbooksExpenseAccountsDocument, options);
        }
export type QuickbooksExpenseAccountsQueryHookResult = ReturnType<typeof useQuickbooksExpenseAccountsQuery>;
export type QuickbooksExpenseAccountsLazyQueryHookResult = ReturnType<typeof useQuickbooksExpenseAccountsLazyQuery>;
export type QuickbooksExpenseAccountsQueryResult = Apollo.QueryResult<QuickbooksExpenseAccountsQuery, QuickbooksExpenseAccountsQueryVariables>;
export const ConsultantBillMarkAsPaidDocument = gql`
    mutation ConsultantBillMarkAsPaid($id: ID!, $paidDate: ISO8601Date) {
  markConsultantBillAsPaid(input: {id: $id, paidDate: $paidDate}) {
    consultantBill {
      id
      paidDate
      status
    }
  }
}
    `;
export type ConsultantBillMarkAsPaidMutationFn = Apollo.MutationFunction<ConsultantBillMarkAsPaidMutation, ConsultantBillMarkAsPaidMutationVariables>;

/**
 * __useConsultantBillMarkAsPaidMutation__
 *
 * To run a mutation, you first call `useConsultantBillMarkAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillMarkAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consultantBillMarkAsPaidMutation, { data, loading, error }] = useConsultantBillMarkAsPaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paidDate: // value for 'paidDate'
 *   },
 * });
 */
export function useConsultantBillMarkAsPaidMutation(baseOptions?: Apollo.MutationHookOptions<ConsultantBillMarkAsPaidMutation, ConsultantBillMarkAsPaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsultantBillMarkAsPaidMutation, ConsultantBillMarkAsPaidMutationVariables>(ConsultantBillMarkAsPaidDocument, options);
      }
export type ConsultantBillMarkAsPaidMutationHookResult = ReturnType<typeof useConsultantBillMarkAsPaidMutation>;
export type ConsultantBillMarkAsPaidMutationResult = Apollo.MutationResult<ConsultantBillMarkAsPaidMutation>;
export type ConsultantBillMarkAsPaidMutationOptions = Apollo.BaseMutationOptions<ConsultantBillMarkAsPaidMutation, ConsultantBillMarkAsPaidMutationVariables>;
export const CreateConsultantBillDocument = gql`
    mutation CreateConsultantBill($input: CreateConsultantBillInput!) {
  createConsultantBill(input: $input) {
    consultantBill {
      ...ConsultantBill
    }
  }
}
    ${ConsultantBillFragmentDoc}`;
export type CreateConsultantBillMutationFn = Apollo.MutationFunction<CreateConsultantBillMutation, CreateConsultantBillMutationVariables>;

/**
 * __useCreateConsultantBillMutation__
 *
 * To run a mutation, you first call `useCreateConsultantBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultantBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultantBillMutation, { data, loading, error }] = useCreateConsultantBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsultantBillMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsultantBillMutation, CreateConsultantBillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsultantBillMutation, CreateConsultantBillMutationVariables>(CreateConsultantBillDocument, options);
      }
export type CreateConsultantBillMutationHookResult = ReturnType<typeof useCreateConsultantBillMutation>;
export type CreateConsultantBillMutationResult = Apollo.MutationResult<CreateConsultantBillMutation>;
export type CreateConsultantBillMutationOptions = Apollo.BaseMutationOptions<CreateConsultantBillMutation, CreateConsultantBillMutationVariables>;
export const UpdateConsultantBillDocument = gql`
    mutation UpdateConsultantBill($input: UpdateConsultantBillInput!) {
  updateConsultantBill(input: $input) {
    consultantBill {
      ...ConsultantBill
    }
  }
}
    ${ConsultantBillFragmentDoc}`;
export type UpdateConsultantBillMutationFn = Apollo.MutationFunction<UpdateConsultantBillMutation, UpdateConsultantBillMutationVariables>;

/**
 * __useUpdateConsultantBillMutation__
 *
 * To run a mutation, you first call `useUpdateConsultantBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultantBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultantBillMutation, { data, loading, error }] = useUpdateConsultantBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsultantBillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultantBillMutation, UpdateConsultantBillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultantBillMutation, UpdateConsultantBillMutationVariables>(UpdateConsultantBillDocument, options);
      }
export type UpdateConsultantBillMutationHookResult = ReturnType<typeof useUpdateConsultantBillMutation>;
export type UpdateConsultantBillMutationResult = Apollo.MutationResult<UpdateConsultantBillMutation>;
export type UpdateConsultantBillMutationOptions = Apollo.BaseMutationOptions<UpdateConsultantBillMutation, UpdateConsultantBillMutationVariables>;
export const ConsultantBillMarkAsOpenDocument = gql`
    mutation ConsultantBillMarkAsOpen($id: ID!) {
  markConsultantBillAsOpen(input: {id: $id}) {
    consultantBill {
      id
      paidDate
      status
    }
  }
}
    `;
export type ConsultantBillMarkAsOpenMutationFn = Apollo.MutationFunction<ConsultantBillMarkAsOpenMutation, ConsultantBillMarkAsOpenMutationVariables>;

/**
 * __useConsultantBillMarkAsOpenMutation__
 *
 * To run a mutation, you first call `useConsultantBillMarkAsOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillMarkAsOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consultantBillMarkAsOpenMutation, { data, loading, error }] = useConsultantBillMarkAsOpenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultantBillMarkAsOpenMutation(baseOptions?: Apollo.MutationHookOptions<ConsultantBillMarkAsOpenMutation, ConsultantBillMarkAsOpenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsultantBillMarkAsOpenMutation, ConsultantBillMarkAsOpenMutationVariables>(ConsultantBillMarkAsOpenDocument, options);
      }
export type ConsultantBillMarkAsOpenMutationHookResult = ReturnType<typeof useConsultantBillMarkAsOpenMutation>;
export type ConsultantBillMarkAsOpenMutationResult = Apollo.MutationResult<ConsultantBillMarkAsOpenMutation>;
export type ConsultantBillMarkAsOpenMutationOptions = Apollo.BaseMutationOptions<ConsultantBillMarkAsOpenMutation, ConsultantBillMarkAsOpenMutationVariables>;
export const AssignConsultantBillToInvoiceDocument = gql`
    mutation AssignConsultantBillToInvoice($id: ID!, $invoiceId: ID, $invoiceSlug: String) {
  assignConsultantBillToInvoice(
    input: {id: $id, invoiceId: $invoiceId, invoiceSlug: $invoiceSlug}
  ) {
    consultantBill {
      id
      invoice {
        id
      }
      invoiceAttachment {
        ...InvoiceAttachment
      }
    }
  }
}
    ${InvoiceAttachmentFragmentDoc}`;
export type AssignConsultantBillToInvoiceMutationFn = Apollo.MutationFunction<AssignConsultantBillToInvoiceMutation, AssignConsultantBillToInvoiceMutationVariables>;

/**
 * __useAssignConsultantBillToInvoiceMutation__
 *
 * To run a mutation, you first call `useAssignConsultantBillToInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignConsultantBillToInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignConsultantBillToInvoiceMutation, { data, loading, error }] = useAssignConsultantBillToInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      invoiceId: // value for 'invoiceId'
 *      invoiceSlug: // value for 'invoiceSlug'
 *   },
 * });
 */
export function useAssignConsultantBillToInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AssignConsultantBillToInvoiceMutation, AssignConsultantBillToInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignConsultantBillToInvoiceMutation, AssignConsultantBillToInvoiceMutationVariables>(AssignConsultantBillToInvoiceDocument, options);
      }
export type AssignConsultantBillToInvoiceMutationHookResult = ReturnType<typeof useAssignConsultantBillToInvoiceMutation>;
export type AssignConsultantBillToInvoiceMutationResult = Apollo.MutationResult<AssignConsultantBillToInvoiceMutation>;
export type AssignConsultantBillToInvoiceMutationOptions = Apollo.BaseMutationOptions<AssignConsultantBillToInvoiceMutation, AssignConsultantBillToInvoiceMutationVariables>;
export const AttachConsultantBillToInvoiceDocument = gql`
    mutation AttachConsultantBillToInvoice($id: ID!) {
  attachConsultantBillToInvoice(input: {id: $id}) {
    consultantBill {
      id
      invoice {
        id
      }
      invoiceAttachment {
        ...InvoiceAttachment
      }
    }
  }
}
    ${InvoiceAttachmentFragmentDoc}`;
export type AttachConsultantBillToInvoiceMutationFn = Apollo.MutationFunction<AttachConsultantBillToInvoiceMutation, AttachConsultantBillToInvoiceMutationVariables>;

/**
 * __useAttachConsultantBillToInvoiceMutation__
 *
 * To run a mutation, you first call `useAttachConsultantBillToInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachConsultantBillToInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachConsultantBillToInvoiceMutation, { data, loading, error }] = useAttachConsultantBillToInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachConsultantBillToInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AttachConsultantBillToInvoiceMutation, AttachConsultantBillToInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachConsultantBillToInvoiceMutation, AttachConsultantBillToInvoiceMutationVariables>(AttachConsultantBillToInvoiceDocument, options);
      }
export type AttachConsultantBillToInvoiceMutationHookResult = ReturnType<typeof useAttachConsultantBillToInvoiceMutation>;
export type AttachConsultantBillToInvoiceMutationResult = Apollo.MutationResult<AttachConsultantBillToInvoiceMutation>;
export type AttachConsultantBillToInvoiceMutationOptions = Apollo.BaseMutationOptions<AttachConsultantBillToInvoiceMutation, AttachConsultantBillToInvoiceMutationVariables>;
export const RemoveConsultantBillInvoiceAssignmentDocument = gql`
    mutation RemoveConsultantBillInvoiceAssignment($id: ID!) {
  removeConsultantBillInvoiceAssignment(input: {id: $id}) {
    consultantBill {
      id
      invoice {
        id
      }
    }
  }
}
    `;
export type RemoveConsultantBillInvoiceAssignmentMutationFn = Apollo.MutationFunction<RemoveConsultantBillInvoiceAssignmentMutation, RemoveConsultantBillInvoiceAssignmentMutationVariables>;

/**
 * __useRemoveConsultantBillInvoiceAssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveConsultantBillInvoiceAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConsultantBillInvoiceAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConsultantBillInvoiceAssignmentMutation, { data, loading, error }] = useRemoveConsultantBillInvoiceAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveConsultantBillInvoiceAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConsultantBillInvoiceAssignmentMutation, RemoveConsultantBillInvoiceAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConsultantBillInvoiceAssignmentMutation, RemoveConsultantBillInvoiceAssignmentMutationVariables>(RemoveConsultantBillInvoiceAssignmentDocument, options);
      }
export type RemoveConsultantBillInvoiceAssignmentMutationHookResult = ReturnType<typeof useRemoveConsultantBillInvoiceAssignmentMutation>;
export type RemoveConsultantBillInvoiceAssignmentMutationResult = Apollo.MutationResult<RemoveConsultantBillInvoiceAssignmentMutation>;
export type RemoveConsultantBillInvoiceAssignmentMutationOptions = Apollo.BaseMutationOptions<RemoveConsultantBillInvoiceAssignmentMutation, RemoveConsultantBillInvoiceAssignmentMutationVariables>;
export const RemoveConsultantBillInvoiceAttachmentDocument = gql`
    mutation RemoveConsultantBillInvoiceAttachment($id: ID!) {
  removeConsultantBillInvoiceAttachment(input: {id: $id}) {
    consultantBill {
      id
      invoiceAttachment {
        id
      }
      invoice {
        id
      }
    }
  }
}
    `;
export type RemoveConsultantBillInvoiceAttachmentMutationFn = Apollo.MutationFunction<RemoveConsultantBillInvoiceAttachmentMutation, RemoveConsultantBillInvoiceAttachmentMutationVariables>;

/**
 * __useRemoveConsultantBillInvoiceAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveConsultantBillInvoiceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConsultantBillInvoiceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConsultantBillInvoiceAttachmentMutation, { data, loading, error }] = useRemoveConsultantBillInvoiceAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveConsultantBillInvoiceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConsultantBillInvoiceAttachmentMutation, RemoveConsultantBillInvoiceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConsultantBillInvoiceAttachmentMutation, RemoveConsultantBillInvoiceAttachmentMutationVariables>(RemoveConsultantBillInvoiceAttachmentDocument, options);
      }
export type RemoveConsultantBillInvoiceAttachmentMutationHookResult = ReturnType<typeof useRemoveConsultantBillInvoiceAttachmentMutation>;
export type RemoveConsultantBillInvoiceAttachmentMutationResult = Apollo.MutationResult<RemoveConsultantBillInvoiceAttachmentMutation>;
export type RemoveConsultantBillInvoiceAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveConsultantBillInvoiceAttachmentMutation, RemoveConsultantBillInvoiceAttachmentMutationVariables>;
export const DeleteConsultantBillDocument = gql`
    mutation DeleteConsultantBill($id: ID!) {
  deleteConsultantBill(input: {id: $id}) {
    consultantBill {
      id
    }
  }
}
    `;
export type DeleteConsultantBillMutationFn = Apollo.MutationFunction<DeleteConsultantBillMutation, DeleteConsultantBillMutationVariables>;

/**
 * __useDeleteConsultantBillMutation__
 *
 * To run a mutation, you first call `useDeleteConsultantBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultantBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultantBillMutation, { data, loading, error }] = useDeleteConsultantBillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsultantBillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultantBillMutation, DeleteConsultantBillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultantBillMutation, DeleteConsultantBillMutationVariables>(DeleteConsultantBillDocument, options);
      }
export type DeleteConsultantBillMutationHookResult = ReturnType<typeof useDeleteConsultantBillMutation>;
export type DeleteConsultantBillMutationResult = Apollo.MutationResult<DeleteConsultantBillMutation>;
export type DeleteConsultantBillMutationOptions = Apollo.BaseMutationOptions<DeleteConsultantBillMutation, DeleteConsultantBillMutationVariables>;
export const SyncConsultantBillWithQuickbooksDocument = gql`
    mutation SyncConsultantBillWithQuickbooks($input: SyncConsultantBillWithQuickbooksInput!) {
  syncConsultantBillWithQuickbooks(input: $input) {
    consultantBill {
      quickbooksBillId
    }
  }
}
    `;
export type SyncConsultantBillWithQuickbooksMutationFn = Apollo.MutationFunction<SyncConsultantBillWithQuickbooksMutation, SyncConsultantBillWithQuickbooksMutationVariables>;

/**
 * __useSyncConsultantBillWithQuickbooksMutation__
 *
 * To run a mutation, you first call `useSyncConsultantBillWithQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConsultantBillWithQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConsultantBillWithQuickbooksMutation, { data, loading, error }] = useSyncConsultantBillWithQuickbooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncConsultantBillWithQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<SyncConsultantBillWithQuickbooksMutation, SyncConsultantBillWithQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncConsultantBillWithQuickbooksMutation, SyncConsultantBillWithQuickbooksMutationVariables>(SyncConsultantBillWithQuickbooksDocument, options);
      }
export type SyncConsultantBillWithQuickbooksMutationHookResult = ReturnType<typeof useSyncConsultantBillWithQuickbooksMutation>;
export type SyncConsultantBillWithQuickbooksMutationResult = Apollo.MutationResult<SyncConsultantBillWithQuickbooksMutation>;
export type SyncConsultantBillWithQuickbooksMutationOptions = Apollo.BaseMutationOptions<SyncConsultantBillWithQuickbooksMutation, SyncConsultantBillWithQuickbooksMutationVariables>;
export const PlanableConsultantTotalsDocument = gql`
    query PlanableConsultantTotals($slug: String!, $isProjectTemplate: Boolean!) {
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    id
    budget
    consultantsAllocatedPercent
    consultantsFee
    consultantsUnallocated
    totalFee
  }
}
    `;

/**
 * __usePlanableConsultantTotalsQuery__
 *
 * To run a query within a React component, call `usePlanableConsultantTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanableConsultantTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanableConsultantTotalsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function usePlanableConsultantTotalsQuery(baseOptions: Apollo.QueryHookOptions<PlanableConsultantTotalsQuery, PlanableConsultantTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanableConsultantTotalsQuery, PlanableConsultantTotalsQueryVariables>(PlanableConsultantTotalsDocument, options);
      }
export function usePlanableConsultantTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanableConsultantTotalsQuery, PlanableConsultantTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanableConsultantTotalsQuery, PlanableConsultantTotalsQueryVariables>(PlanableConsultantTotalsDocument, options);
        }
export type PlanableConsultantTotalsQueryHookResult = ReturnType<typeof usePlanableConsultantTotalsQuery>;
export type PlanableConsultantTotalsLazyQueryHookResult = ReturnType<typeof usePlanableConsultantTotalsLazyQuery>;
export type PlanableConsultantTotalsQueryResult = Apollo.QueryResult<PlanableConsultantTotalsQuery, PlanableConsultantTotalsQueryVariables>;
export const ConsultantTemplatesDocument = gql`
    query ConsultantTemplates($organizationId: ID) {
  consultantTemplates(organizationId: $organizationId) {
    id
    name
  }
}
    `;

/**
 * __useConsultantTemplatesQuery__
 *
 * To run a query within a React component, call `useConsultantTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantTemplatesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useConsultantTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantTemplatesQuery, ConsultantTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantTemplatesQuery, ConsultantTemplatesQueryVariables>(ConsultantTemplatesDocument, options);
      }
export function useConsultantTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantTemplatesQuery, ConsultantTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantTemplatesQuery, ConsultantTemplatesQueryVariables>(ConsultantTemplatesDocument, options);
        }
export type ConsultantTemplatesQueryHookResult = ReturnType<typeof useConsultantTemplatesQuery>;
export type ConsultantTemplatesLazyQueryHookResult = ReturnType<typeof useConsultantTemplatesLazyQuery>;
export type ConsultantTemplatesQueryResult = Apollo.QueryResult<ConsultantTemplatesQuery, ConsultantTemplatesQueryVariables>;
export const PlanableConsultantsDocument = gql`
    query PlanableConsultants($slug: String!, $isProjectTemplate: Boolean!) {
  currentProfile {
    id
    organization {
      id
      settings {
        id
        defaultConsultantMarkup
      }
    }
  }
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    budget
    consultantsAllocatedPercent
    consultantsFee
    consultantsUnallocated
    id
    totalConsultantsMarkup
    totalFee
    projectConsultants {
      ...ProjectConsultantDetails
    }
    phaseConsultants {
      ...PhaseConsultantDetails
    }
  }
}
    ${ProjectConsultantDetailsFragmentDoc}
${PhaseConsultantDetailsFragmentDoc}`;

/**
 * __usePlanableConsultantsQuery__
 *
 * To run a query within a React component, call `usePlanableConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanableConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanableConsultantsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function usePlanableConsultantsQuery(baseOptions: Apollo.QueryHookOptions<PlanableConsultantsQuery, PlanableConsultantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanableConsultantsQuery, PlanableConsultantsQueryVariables>(PlanableConsultantsDocument, options);
      }
export function usePlanableConsultantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanableConsultantsQuery, PlanableConsultantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanableConsultantsQuery, PlanableConsultantsQueryVariables>(PlanableConsultantsDocument, options);
        }
export type PlanableConsultantsQueryHookResult = ReturnType<typeof usePlanableConsultantsQuery>;
export type PlanableConsultantsLazyQueryHookResult = ReturnType<typeof usePlanableConsultantsLazyQuery>;
export type PlanableConsultantsQueryResult = Apollo.QueryResult<PlanableConsultantsQuery, PlanableConsultantsQueryVariables>;
export const ProjectTemplatePhasesDocument = gql`
    query ProjectTemplatePhases($slug: String!) {
  projectTemplate(slug: $slug) {
    orderedPhases {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectTemplatePhasesQuery__
 *
 * To run a query within a React component, call `useProjectTemplatePhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplatePhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplatePhasesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectTemplatePhasesQuery(baseOptions: Apollo.QueryHookOptions<ProjectTemplatePhasesQuery, ProjectTemplatePhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTemplatePhasesQuery, ProjectTemplatePhasesQueryVariables>(ProjectTemplatePhasesDocument, options);
      }
export function useProjectTemplatePhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplatePhasesQuery, ProjectTemplatePhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTemplatePhasesQuery, ProjectTemplatePhasesQueryVariables>(ProjectTemplatePhasesDocument, options);
        }
export type ProjectTemplatePhasesQueryHookResult = ReturnType<typeof useProjectTemplatePhasesQuery>;
export type ProjectTemplatePhasesLazyQueryHookResult = ReturnType<typeof useProjectTemplatePhasesLazyQuery>;
export type ProjectTemplatePhasesQueryResult = Apollo.QueryResult<ProjectTemplatePhasesQuery, ProjectTemplatePhasesQueryVariables>;
export const CreateProjectConsultantDocument = gql`
    mutation CreateProjectConsultant($input: CreateInput!) {
  createProjectConsultant(input: $input) {
    projectConsultant {
      budget
      id
      consultantTemplateId
      project {
        id
        ...ProjectConsultantTotals
      }
      projectTemplate {
        id
        ...ProjectTemplateConsultantTotals
      }
    }
  }
}
    ${ProjectConsultantTotalsFragmentDoc}
${ProjectTemplateConsultantTotalsFragmentDoc}`;
export type CreateProjectConsultantMutationFn = Apollo.MutationFunction<CreateProjectConsultantMutation, CreateProjectConsultantMutationVariables>;

/**
 * __useCreateProjectConsultantMutation__
 *
 * To run a mutation, you first call `useCreateProjectConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectConsultantMutation, { data, loading, error }] = useCreateProjectConsultantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectConsultantMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectConsultantMutation, CreateProjectConsultantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectConsultantMutation, CreateProjectConsultantMutationVariables>(CreateProjectConsultantDocument, options);
      }
export type CreateProjectConsultantMutationHookResult = ReturnType<typeof useCreateProjectConsultantMutation>;
export type CreateProjectConsultantMutationResult = Apollo.MutationResult<CreateProjectConsultantMutation>;
export type CreateProjectConsultantMutationOptions = Apollo.BaseMutationOptions<CreateProjectConsultantMutation, CreateProjectConsultantMutationVariables>;
export const UpdateProjectConsultantPlannerDocument = gql`
    mutation UpdateProjectConsultantPlanner($input: UpdateProjectConsultantInput!) {
  updateProjectConsultant(input: $input) {
    projectConsultant {
      id
      budget
      consultantTemplateId
      project {
        id
        ...ProjectConsultantTotals
      }
      projectTemplate {
        id
        ...ProjectTemplateConsultantTotals
      }
    }
  }
}
    ${ProjectConsultantTotalsFragmentDoc}
${ProjectTemplateConsultantTotalsFragmentDoc}`;
export type UpdateProjectConsultantPlannerMutationFn = Apollo.MutationFunction<UpdateProjectConsultantPlannerMutation, UpdateProjectConsultantPlannerMutationVariables>;

/**
 * __useUpdateProjectConsultantPlannerMutation__
 *
 * To run a mutation, you first call `useUpdateProjectConsultantPlannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectConsultantPlannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectConsultantPlannerMutation, { data, loading, error }] = useUpdateProjectConsultantPlannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectConsultantPlannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectConsultantPlannerMutation, UpdateProjectConsultantPlannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectConsultantPlannerMutation, UpdateProjectConsultantPlannerMutationVariables>(UpdateProjectConsultantPlannerDocument, options);
      }
export type UpdateProjectConsultantPlannerMutationHookResult = ReturnType<typeof useUpdateProjectConsultantPlannerMutation>;
export type UpdateProjectConsultantPlannerMutationResult = Apollo.MutationResult<UpdateProjectConsultantPlannerMutation>;
export type UpdateProjectConsultantPlannerMutationOptions = Apollo.BaseMutationOptions<UpdateProjectConsultantPlannerMutation, UpdateProjectConsultantPlannerMutationVariables>;
export const DeleteProjectConsultantDocument = gql`
    mutation DeleteProjectConsultant($input: DeleteInput!) {
  deleteProjectConsultant(input: $input) {
    projectConsultant {
      id
      project {
        id
        ...ProjectConsultantTotals
      }
      projectTemplate {
        id
        ...ProjectTemplateConsultantTotals
      }
    }
  }
}
    ${ProjectConsultantTotalsFragmentDoc}
${ProjectTemplateConsultantTotalsFragmentDoc}`;
export type DeleteProjectConsultantMutationFn = Apollo.MutationFunction<DeleteProjectConsultantMutation, DeleteProjectConsultantMutationVariables>;

/**
 * __useDeleteProjectConsultantMutation__
 *
 * To run a mutation, you first call `useDeleteProjectConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectConsultantMutation, { data, loading, error }] = useDeleteProjectConsultantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectConsultantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectConsultantMutation, DeleteProjectConsultantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectConsultantMutation, DeleteProjectConsultantMutationVariables>(DeleteProjectConsultantDocument, options);
      }
export type DeleteProjectConsultantMutationHookResult = ReturnType<typeof useDeleteProjectConsultantMutation>;
export type DeleteProjectConsultantMutationResult = Apollo.MutationResult<DeleteProjectConsultantMutation>;
export type DeleteProjectConsultantMutationOptions = Apollo.BaseMutationOptions<DeleteProjectConsultantMutation, DeleteProjectConsultantMutationVariables>;
export const CreateOrUpdatePhaseConsultantDocument = gql`
    mutation CreateOrUpdatePhaseConsultant($input: UpdatePhaseConsultantsInput!) {
  updatePhaseConsultants(input: $input) {
    phaseConsultants {
      consultantTemplate {
        id
        name
      }
      entries {
        budget
        budgetIsFrozen
        markup
        markupPercentage
        consultantTemplate {
          id
        }
        id
        phase {
          id
        }
        project {
          id
          ...ProjectConsultantTotals
        }
        projectTemplate {
          id
          ...ProjectTemplateConsultantTotals
        }
      }
    }
  }
}
    ${ProjectConsultantTotalsFragmentDoc}
${ProjectTemplateConsultantTotalsFragmentDoc}`;
export type CreateOrUpdatePhaseConsultantMutationFn = Apollo.MutationFunction<CreateOrUpdatePhaseConsultantMutation, CreateOrUpdatePhaseConsultantMutationVariables>;

/**
 * __useCreateOrUpdatePhaseConsultantMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdatePhaseConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdatePhaseConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdatePhaseConsultantMutation, { data, loading, error }] = useCreateOrUpdatePhaseConsultantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdatePhaseConsultantMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdatePhaseConsultantMutation, CreateOrUpdatePhaseConsultantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdatePhaseConsultantMutation, CreateOrUpdatePhaseConsultantMutationVariables>(CreateOrUpdatePhaseConsultantDocument, options);
      }
export type CreateOrUpdatePhaseConsultantMutationHookResult = ReturnType<typeof useCreateOrUpdatePhaseConsultantMutation>;
export type CreateOrUpdatePhaseConsultantMutationResult = Apollo.MutationResult<CreateOrUpdatePhaseConsultantMutation>;
export type CreateOrUpdatePhaseConsultantMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdatePhaseConsultantMutation, CreateOrUpdatePhaseConsultantMutationVariables>;
export const UpdateProjectConsultantsFeeDocument = gql`
    mutation UpdateProjectConsultantsFee($input: ProjectConsultantsFeeUpdateInput!) {
  updateProjectConsultantsFee(input: $input) {
    project {
      id
      ...ProjectConsultantTotals
    }
  }
}
    ${ProjectConsultantTotalsFragmentDoc}`;
export type UpdateProjectConsultantsFeeMutationFn = Apollo.MutationFunction<UpdateProjectConsultantsFeeMutation, UpdateProjectConsultantsFeeMutationVariables>;

/**
 * __useUpdateProjectConsultantsFeeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectConsultantsFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectConsultantsFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectConsultantsFeeMutation, { data, loading, error }] = useUpdateProjectConsultantsFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectConsultantsFeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectConsultantsFeeMutation, UpdateProjectConsultantsFeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectConsultantsFeeMutation, UpdateProjectConsultantsFeeMutationVariables>(UpdateProjectConsultantsFeeDocument, options);
      }
export type UpdateProjectConsultantsFeeMutationHookResult = ReturnType<typeof useUpdateProjectConsultantsFeeMutation>;
export type UpdateProjectConsultantsFeeMutationResult = Apollo.MutationResult<UpdateProjectConsultantsFeeMutation>;
export type UpdateProjectConsultantsFeeMutationOptions = Apollo.BaseMutationOptions<UpdateProjectConsultantsFeeMutation, UpdateProjectConsultantsFeeMutationVariables>;
export const UpdateProjectTemplateConsultantsFeeDocument = gql`
    mutation UpdateProjectTemplateConsultantsFee($input: ProjectTemplateConsultantsFeeUpdateInput!) {
  updateProjectTemplateConsultantsFee(input: $input) {
    projectTemplate {
      id
      ...ProjectTemplateConsultantTotals
    }
  }
}
    ${ProjectTemplateConsultantTotalsFragmentDoc}`;
export type UpdateProjectTemplateConsultantsFeeMutationFn = Apollo.MutationFunction<UpdateProjectTemplateConsultantsFeeMutation, UpdateProjectTemplateConsultantsFeeMutationVariables>;

/**
 * __useUpdateProjectTemplateConsultantsFeeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateConsultantsFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateConsultantsFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateConsultantsFeeMutation, { data, loading, error }] = useUpdateProjectTemplateConsultantsFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateConsultantsFeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTemplateConsultantsFeeMutation, UpdateProjectTemplateConsultantsFeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTemplateConsultantsFeeMutation, UpdateProjectTemplateConsultantsFeeMutationVariables>(UpdateProjectTemplateConsultantsFeeDocument, options);
      }
export type UpdateProjectTemplateConsultantsFeeMutationHookResult = ReturnType<typeof useUpdateProjectTemplateConsultantsFeeMutation>;
export type UpdateProjectTemplateConsultantsFeeMutationResult = Apollo.MutationResult<UpdateProjectTemplateConsultantsFeeMutation>;
export type UpdateProjectTemplateConsultantsFeeMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTemplateConsultantsFeeMutation, UpdateProjectTemplateConsultantsFeeMutationVariables>;
export const ConsultantCompanyDocument = gql`
    query consultantCompany($slug: String!) {
  consultantCompany(slug: $slug) {
    secondaryAddress {
      ...ConsultantCompanyAddress
    }
    secondaryAddressDisplayLabel
    ...ConsultantCompanyResult
  }
}
    ${ConsultantCompanyAddressFragmentDoc}
${ConsultantCompanyResultFragmentDoc}`;

/**
 * __useConsultantCompanyQuery__
 *
 * To run a query within a React component, call `useConsultantCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantCompanyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useConsultantCompanyQuery(baseOptions: Apollo.QueryHookOptions<ConsultantCompanyQuery, ConsultantCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantCompanyQuery, ConsultantCompanyQueryVariables>(ConsultantCompanyDocument, options);
      }
export function useConsultantCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantCompanyQuery, ConsultantCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantCompanyQuery, ConsultantCompanyQueryVariables>(ConsultantCompanyDocument, options);
        }
export type ConsultantCompanyQueryHookResult = ReturnType<typeof useConsultantCompanyQuery>;
export type ConsultantCompanyLazyQueryHookResult = ReturnType<typeof useConsultantCompanyLazyQuery>;
export type ConsultantCompanyQueryResult = Apollo.QueryResult<ConsultantCompanyQuery, ConsultantCompanyQueryVariables>;
export const ConsultantContactDocument = gql`
    query consultantContact($slug: String!) {
  consultantContact(slug: $slug) {
    ...ConsultantContactResult
  }
}
    ${ConsultantContactResultFragmentDoc}`;

/**
 * __useConsultantContactQuery__
 *
 * To run a query within a React component, call `useConsultantContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantContactQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useConsultantContactQuery(baseOptions: Apollo.QueryHookOptions<ConsultantContactQuery, ConsultantContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantContactQuery, ConsultantContactQueryVariables>(ConsultantContactDocument, options);
      }
export function useConsultantContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantContactQuery, ConsultantContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantContactQuery, ConsultantContactQueryVariables>(ConsultantContactDocument, options);
        }
export type ConsultantContactQueryHookResult = ReturnType<typeof useConsultantContactQuery>;
export type ConsultantContactLazyQueryHookResult = ReturnType<typeof useConsultantContactLazyQuery>;
export type ConsultantContactQueryResult = Apollo.QueryResult<ConsultantContactQuery, ConsultantContactQueryVariables>;
export const ConsultantCompaniesDocument = gql`
    query consultantCompanies($qboSyncedOnly: Boolean) {
  consultantCompanies(qboSyncedOnly: $qboSyncedOnly) {
    ...ConsultantCompanyResult
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;

/**
 * __useConsultantCompaniesQuery__
 *
 * To run a query within a React component, call `useConsultantCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantCompaniesQuery({
 *   variables: {
 *      qboSyncedOnly: // value for 'qboSyncedOnly'
 *   },
 * });
 */
export function useConsultantCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantCompaniesQuery, ConsultantCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantCompaniesQuery, ConsultantCompaniesQueryVariables>(ConsultantCompaniesDocument, options);
      }
export function useConsultantCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantCompaniesQuery, ConsultantCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantCompaniesQuery, ConsultantCompaniesQueryVariables>(ConsultantCompaniesDocument, options);
        }
export type ConsultantCompaniesQueryHookResult = ReturnType<typeof useConsultantCompaniesQuery>;
export type ConsultantCompaniesLazyQueryHookResult = ReturnType<typeof useConsultantCompaniesLazyQuery>;
export type ConsultantCompaniesQueryResult = Apollo.QueryResult<ConsultantCompaniesQuery, ConsultantCompaniesQueryVariables>;
export const ConsultantContactsDocument = gql`
    query consultantContacts($onlyActiveCompanies: Boolean) {
  consultantContacts(onlyActiveCompanies: $onlyActiveCompanies) {
    ...ConsultantContactResult
  }
}
    ${ConsultantContactResultFragmentDoc}`;

/**
 * __useConsultantContactsQuery__
 *
 * To run a query within a React component, call `useConsultantContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantContactsQuery({
 *   variables: {
 *      onlyActiveCompanies: // value for 'onlyActiveCompanies'
 *   },
 * });
 */
export function useConsultantContactsQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantContactsQuery, ConsultantContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantContactsQuery, ConsultantContactsQueryVariables>(ConsultantContactsDocument, options);
      }
export function useConsultantContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantContactsQuery, ConsultantContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantContactsQuery, ConsultantContactsQueryVariables>(ConsultantContactsDocument, options);
        }
export type ConsultantContactsQueryHookResult = ReturnType<typeof useConsultantContactsQuery>;
export type ConsultantContactsLazyQueryHookResult = ReturnType<typeof useConsultantContactsLazyQuery>;
export type ConsultantContactsQueryResult = Apollo.QueryResult<ConsultantContactsQuery, ConsultantContactsQueryVariables>;
export const ConsultantCompanyContactsDocument = gql`
    query consultantCompanyContacts($companySlug: String) {
  consultantContacts(companySlug: $companySlug) {
    ...ConsultantContactResult
  }
}
    ${ConsultantContactResultFragmentDoc}`;

/**
 * __useConsultantCompanyContactsQuery__
 *
 * To run a query within a React component, call `useConsultantCompanyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantCompanyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantCompanyContactsQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useConsultantCompanyContactsQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantCompanyContactsQuery, ConsultantCompanyContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantCompanyContactsQuery, ConsultantCompanyContactsQueryVariables>(ConsultantCompanyContactsDocument, options);
      }
export function useConsultantCompanyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantCompanyContactsQuery, ConsultantCompanyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantCompanyContactsQuery, ConsultantCompanyContactsQueryVariables>(ConsultantCompanyContactsDocument, options);
        }
export type ConsultantCompanyContactsQueryHookResult = ReturnType<typeof useConsultantCompanyContactsQuery>;
export type ConsultantCompanyContactsLazyQueryHookResult = ReturnType<typeof useConsultantCompanyContactsLazyQuery>;
export type ConsultantCompanyContactsQueryResult = Apollo.QueryResult<ConsultantCompanyContactsQuery, ConsultantCompanyContactsQueryVariables>;
export const AddConsultantCompanyDocument = gql`
    mutation AddConsultantCompany($input: AddConsultantCompanyInput!) {
  addConsultantCompany(input: $input) {
    consultantCompany {
      id
      name
      slug
    }
  }
}
    `;
export type AddConsultantCompanyMutationFn = Apollo.MutationFunction<AddConsultantCompanyMutation, AddConsultantCompanyMutationVariables>;

/**
 * __useAddConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useAddConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConsultantCompanyMutation, { data, loading, error }] = useAddConsultantCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddConsultantCompanyMutation, AddConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConsultantCompanyMutation, AddConsultantCompanyMutationVariables>(AddConsultantCompanyDocument, options);
      }
export type AddConsultantCompanyMutationHookResult = ReturnType<typeof useAddConsultantCompanyMutation>;
export type AddConsultantCompanyMutationResult = Apollo.MutationResult<AddConsultantCompanyMutation>;
export type AddConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<AddConsultantCompanyMutation, AddConsultantCompanyMutationVariables>;
export const AddConsultantContactDocument = gql`
    mutation AddConsultantContact($input: AddConsultantContactInput!) {
  addConsultantContact(input: $input) {
    consultantContact {
      id
      email
      slug
      fullName
    }
  }
}
    `;
export type AddConsultantContactMutationFn = Apollo.MutationFunction<AddConsultantContactMutation, AddConsultantContactMutationVariables>;

/**
 * __useAddConsultantContactMutation__
 *
 * To run a mutation, you first call `useAddConsultantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConsultantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConsultantContactMutation, { data, loading, error }] = useAddConsultantContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConsultantContactMutation(baseOptions?: Apollo.MutationHookOptions<AddConsultantContactMutation, AddConsultantContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConsultantContactMutation, AddConsultantContactMutationVariables>(AddConsultantContactDocument, options);
      }
export type AddConsultantContactMutationHookResult = ReturnType<typeof useAddConsultantContactMutation>;
export type AddConsultantContactMutationResult = Apollo.MutationResult<AddConsultantContactMutation>;
export type AddConsultantContactMutationOptions = Apollo.BaseMutationOptions<AddConsultantContactMutation, AddConsultantContactMutationVariables>;
export const UpdateConsultantCompanyDocument = gql`
    mutation UpdateConsultantCompany($input: UpdateConsultantCompanyInput!) {
  updateConsultantCompany(input: $input) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type UpdateConsultantCompanyMutationFn = Apollo.MutationFunction<UpdateConsultantCompanyMutation, UpdateConsultantCompanyMutationVariables>;

/**
 * __useUpdateConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultantCompanyMutation, { data, loading, error }] = useUpdateConsultantCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultantCompanyMutation, UpdateConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultantCompanyMutation, UpdateConsultantCompanyMutationVariables>(UpdateConsultantCompanyDocument, options);
      }
export type UpdateConsultantCompanyMutationHookResult = ReturnType<typeof useUpdateConsultantCompanyMutation>;
export type UpdateConsultantCompanyMutationResult = Apollo.MutationResult<UpdateConsultantCompanyMutation>;
export type UpdateConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateConsultantCompanyMutation, UpdateConsultantCompanyMutationVariables>;
export const UpdateConsultantContactDocument = gql`
    mutation UpdateConsultantContact($input: UpdateConsultantContactInput!) {
  updateConsultantContact(input: $input) {
    consultantContact {
      ...ConsultantContactResult
    }
  }
}
    ${ConsultantContactResultFragmentDoc}`;
export type UpdateConsultantContactMutationFn = Apollo.MutationFunction<UpdateConsultantContactMutation, UpdateConsultantContactMutationVariables>;

/**
 * __useUpdateConsultantContactMutation__
 *
 * To run a mutation, you first call `useUpdateConsultantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultantContactMutation, { data, loading, error }] = useUpdateConsultantContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsultantContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultantContactMutation, UpdateConsultantContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultantContactMutation, UpdateConsultantContactMutationVariables>(UpdateConsultantContactDocument, options);
      }
export type UpdateConsultantContactMutationHookResult = ReturnType<typeof useUpdateConsultantContactMutation>;
export type UpdateConsultantContactMutationResult = Apollo.MutationResult<UpdateConsultantContactMutation>;
export type UpdateConsultantContactMutationOptions = Apollo.BaseMutationOptions<UpdateConsultantContactMutation, UpdateConsultantContactMutationVariables>;
export const DeactivateConsultantContactDocument = gql`
    mutation DeactivateConsultantContact($data: DeactivateConsultantContactInput!) {
  deactivateConsultantContact(input: $data) {
    consultantContact {
      ...ConsultantContactResult
    }
  }
}
    ${ConsultantContactResultFragmentDoc}`;
export type DeactivateConsultantContactMutationFn = Apollo.MutationFunction<DeactivateConsultantContactMutation, DeactivateConsultantContactMutationVariables>;

/**
 * __useDeactivateConsultantContactMutation__
 *
 * To run a mutation, you first call `useDeactivateConsultantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateConsultantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateConsultantContactMutation, { data, loading, error }] = useDeactivateConsultantContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeactivateConsultantContactMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateConsultantContactMutation, DeactivateConsultantContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateConsultantContactMutation, DeactivateConsultantContactMutationVariables>(DeactivateConsultantContactDocument, options);
      }
export type DeactivateConsultantContactMutationHookResult = ReturnType<typeof useDeactivateConsultantContactMutation>;
export type DeactivateConsultantContactMutationResult = Apollo.MutationResult<DeactivateConsultantContactMutation>;
export type DeactivateConsultantContactMutationOptions = Apollo.BaseMutationOptions<DeactivateConsultantContactMutation, DeactivateConsultantContactMutationVariables>;
export const DeactivateConsultantCompanyDocument = gql`
    mutation DeactivateConsultantCompany($data: DeactivateConsultantCompanyInput!) {
  deactivateConsultantCompany(input: $data) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type DeactivateConsultantCompanyMutationFn = Apollo.MutationFunction<DeactivateConsultantCompanyMutation, DeactivateConsultantCompanyMutationVariables>;

/**
 * __useDeactivateConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useDeactivateConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateConsultantCompanyMutation, { data, loading, error }] = useDeactivateConsultantCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeactivateConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateConsultantCompanyMutation, DeactivateConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateConsultantCompanyMutation, DeactivateConsultantCompanyMutationVariables>(DeactivateConsultantCompanyDocument, options);
      }
export type DeactivateConsultantCompanyMutationHookResult = ReturnType<typeof useDeactivateConsultantCompanyMutation>;
export type DeactivateConsultantCompanyMutationResult = Apollo.MutationResult<DeactivateConsultantCompanyMutation>;
export type DeactivateConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<DeactivateConsultantCompanyMutation, DeactivateConsultantCompanyMutationVariables>;
export const ReactivateConsultantContactDocument = gql`
    mutation ReactivateConsultantContact($data: ReactivateConsultantContactInput!) {
  reactivateConsultantContact(input: $data) {
    consultantContact {
      ...ConsultantContactResult
    }
  }
}
    ${ConsultantContactResultFragmentDoc}`;
export type ReactivateConsultantContactMutationFn = Apollo.MutationFunction<ReactivateConsultantContactMutation, ReactivateConsultantContactMutationVariables>;

/**
 * __useReactivateConsultantContactMutation__
 *
 * To run a mutation, you first call `useReactivateConsultantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateConsultantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateConsultantContactMutation, { data, loading, error }] = useReactivateConsultantContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateConsultantContactMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateConsultantContactMutation, ReactivateConsultantContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateConsultantContactMutation, ReactivateConsultantContactMutationVariables>(ReactivateConsultantContactDocument, options);
      }
export type ReactivateConsultantContactMutationHookResult = ReturnType<typeof useReactivateConsultantContactMutation>;
export type ReactivateConsultantContactMutationResult = Apollo.MutationResult<ReactivateConsultantContactMutation>;
export type ReactivateConsultantContactMutationOptions = Apollo.BaseMutationOptions<ReactivateConsultantContactMutation, ReactivateConsultantContactMutationVariables>;
export const ReactivateConsultantCompanyDocument = gql`
    mutation ReactivateConsultantCompany($data: ReactivateConsultantCompanyInput!) {
  reactivateConsultantCompany(input: $data) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type ReactivateConsultantCompanyMutationFn = Apollo.MutationFunction<ReactivateConsultantCompanyMutation, ReactivateConsultantCompanyMutationVariables>;

/**
 * __useReactivateConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useReactivateConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateConsultantCompanyMutation, { data, loading, error }] = useReactivateConsultantCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReactivateConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateConsultantCompanyMutation, ReactivateConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateConsultantCompanyMutation, ReactivateConsultantCompanyMutationVariables>(ReactivateConsultantCompanyDocument, options);
      }
export type ReactivateConsultantCompanyMutationHookResult = ReturnType<typeof useReactivateConsultantCompanyMutation>;
export type ReactivateConsultantCompanyMutationResult = Apollo.MutationResult<ReactivateConsultantCompanyMutation>;
export type ReactivateConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<ReactivateConsultantCompanyMutation, ReactivateConsultantCompanyMutationVariables>;
export const DeleteConsultantContactDocument = gql`
    mutation DeleteConsultantContact($data: DeleteConsultantContactInput!) {
  deleteConsultantContact(input: $data) {
    consultantContact {
      ...ConsultantContactResult
    }
  }
}
    ${ConsultantContactResultFragmentDoc}`;
export type DeleteConsultantContactMutationFn = Apollo.MutationFunction<DeleteConsultantContactMutation, DeleteConsultantContactMutationVariables>;

/**
 * __useDeleteConsultantContactMutation__
 *
 * To run a mutation, you first call `useDeleteConsultantContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultantContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultantContactMutation, { data, loading, error }] = useDeleteConsultantContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteConsultantContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultantContactMutation, DeleteConsultantContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultantContactMutation, DeleteConsultantContactMutationVariables>(DeleteConsultantContactDocument, options);
      }
export type DeleteConsultantContactMutationHookResult = ReturnType<typeof useDeleteConsultantContactMutation>;
export type DeleteConsultantContactMutationResult = Apollo.MutationResult<DeleteConsultantContactMutation>;
export type DeleteConsultantContactMutationOptions = Apollo.BaseMutationOptions<DeleteConsultantContactMutation, DeleteConsultantContactMutationVariables>;
export const DeleteConsultantCompanyDocument = gql`
    mutation DeleteConsultantCompany($data: DeleteConsultantCompanyInput!) {
  deleteConsultantCompany(input: $data) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type DeleteConsultantCompanyMutationFn = Apollo.MutationFunction<DeleteConsultantCompanyMutation, DeleteConsultantCompanyMutationVariables>;

/**
 * __useDeleteConsultantCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteConsultantCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultantCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultantCompanyMutation, { data, loading, error }] = useDeleteConsultantCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteConsultantCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultantCompanyMutation, DeleteConsultantCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultantCompanyMutation, DeleteConsultantCompanyMutationVariables>(DeleteConsultantCompanyDocument, options);
      }
export type DeleteConsultantCompanyMutationHookResult = ReturnType<typeof useDeleteConsultantCompanyMutation>;
export type DeleteConsultantCompanyMutationResult = Apollo.MutationResult<DeleteConsultantCompanyMutation>;
export type DeleteConsultantCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteConsultantCompanyMutation, DeleteConsultantCompanyMutationVariables>;
export const SetPrimaryCompanyContactDocument = gql`
    mutation SetPrimaryCompanyContact($id: ID!) {
  setPrimaryCompanyContact(input: {id: $id}) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type SetPrimaryCompanyContactMutationFn = Apollo.MutationFunction<SetPrimaryCompanyContactMutation, SetPrimaryCompanyContactMutationVariables>;

/**
 * __useSetPrimaryCompanyContactMutation__
 *
 * To run a mutation, you first call `useSetPrimaryCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryCompanyContactMutation, { data, loading, error }] = useSetPrimaryCompanyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetPrimaryCompanyContactMutation(baseOptions?: Apollo.MutationHookOptions<SetPrimaryCompanyContactMutation, SetPrimaryCompanyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPrimaryCompanyContactMutation, SetPrimaryCompanyContactMutationVariables>(SetPrimaryCompanyContactDocument, options);
      }
export type SetPrimaryCompanyContactMutationHookResult = ReturnType<typeof useSetPrimaryCompanyContactMutation>;
export type SetPrimaryCompanyContactMutationResult = Apollo.MutationResult<SetPrimaryCompanyContactMutation>;
export type SetPrimaryCompanyContactMutationOptions = Apollo.BaseMutationOptions<SetPrimaryCompanyContactMutation, SetPrimaryCompanyContactMutationVariables>;
export const SyncConsultantCompanyWithQuickbooksDocument = gql`
    mutation SyncConsultantCompanyWithQuickbooks($input: SyncConsultantCompanyWithQuickbooksInput!) {
  syncConsultantCompanyWithQuickbooks(input: $input) {
    consultantCompany {
      ...ConsultantCompanyResult
    }
  }
}
    ${ConsultantCompanyResultFragmentDoc}`;
export type SyncConsultantCompanyWithQuickbooksMutationFn = Apollo.MutationFunction<SyncConsultantCompanyWithQuickbooksMutation, SyncConsultantCompanyWithQuickbooksMutationVariables>;

/**
 * __useSyncConsultantCompanyWithQuickbooksMutation__
 *
 * To run a mutation, you first call `useSyncConsultantCompanyWithQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConsultantCompanyWithQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConsultantCompanyWithQuickbooksMutation, { data, loading, error }] = useSyncConsultantCompanyWithQuickbooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncConsultantCompanyWithQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<SyncConsultantCompanyWithQuickbooksMutation, SyncConsultantCompanyWithQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncConsultantCompanyWithQuickbooksMutation, SyncConsultantCompanyWithQuickbooksMutationVariables>(SyncConsultantCompanyWithQuickbooksDocument, options);
      }
export type SyncConsultantCompanyWithQuickbooksMutationHookResult = ReturnType<typeof useSyncConsultantCompanyWithQuickbooksMutation>;
export type SyncConsultantCompanyWithQuickbooksMutationResult = Apollo.MutationResult<SyncConsultantCompanyWithQuickbooksMutation>;
export type SyncConsultantCompanyWithQuickbooksMutationOptions = Apollo.BaseMutationOptions<SyncConsultantCompanyWithQuickbooksMutation, SyncConsultantCompanyWithQuickbooksMutationVariables>;
export const ClientDirectoryDocument = gql`
    query ClientDirectory {
  currentProfile {
    id
    organization {
      id
      unprocessedQboCustomers
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
  qboInstances {
    id
    companyName
  }
}
    `;

/**
 * __useClientDirectoryQuery__
 *
 * To run a query within a React component, call `useClientDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDirectoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientDirectoryQuery(baseOptions?: Apollo.QueryHookOptions<ClientDirectoryQuery, ClientDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDirectoryQuery, ClientDirectoryQueryVariables>(ClientDirectoryDocument, options);
      }
export function useClientDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDirectoryQuery, ClientDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDirectoryQuery, ClientDirectoryQueryVariables>(ClientDirectoryDocument, options);
        }
export type ClientDirectoryQueryHookResult = ReturnType<typeof useClientDirectoryQuery>;
export type ClientDirectoryLazyQueryHookResult = ReturnType<typeof useClientDirectoryLazyQuery>;
export type ClientDirectoryQueryResult = Apollo.QueryResult<ClientDirectoryQuery, ClientDirectoryQueryVariables>;
export const ClientsOverviewDocument = gql`
    query clientsOverview($sortBy: ClientsSortInput, $filters: ClientFiltersInput, $last: Int, $first: Int, $before: String, $after: String) {
  clients(
    sortBy: $sortBy
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
    nodes {
      ...DirectoryClient
    }
  }
}
    ${DirectoryClientFragmentDoc}`;

/**
 * __useClientsOverviewQuery__
 *
 * To run a query within a React component, call `useClientsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsOverviewQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClientsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<ClientsOverviewQuery, ClientsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsOverviewQuery, ClientsOverviewQueryVariables>(ClientsOverviewDocument, options);
      }
export function useClientsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsOverviewQuery, ClientsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsOverviewQuery, ClientsOverviewQueryVariables>(ClientsOverviewDocument, options);
        }
export type ClientsOverviewQueryHookResult = ReturnType<typeof useClientsOverviewQuery>;
export type ClientsOverviewLazyQueryHookResult = ReturnType<typeof useClientsOverviewLazyQuery>;
export type ClientsOverviewQueryResult = Apollo.QueryResult<ClientsOverviewQuery, ClientsOverviewQueryVariables>;
export const ClientProfileDocument = gql`
    query ClientProfile {
  currentProfile {
    id
    organization {
      id
      connectedToQbo
      unprocessedQboCustomers
      qboInstances {
        ...ClientProfileQboInstance
      }
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
}
    ${ClientProfileQboInstanceFragmentDoc}`;

/**
 * __useClientProfileQuery__
 *
 * To run a query within a React component, call `useClientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileQuery, ClientProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileQuery, ClientProfileQueryVariables>(ClientProfileDocument, options);
      }
export function useClientProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileQuery, ClientProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileQuery, ClientProfileQueryVariables>(ClientProfileDocument, options);
        }
export type ClientProfileQueryHookResult = ReturnType<typeof useClientProfileQuery>;
export type ClientProfileLazyQueryHookResult = ReturnType<typeof useClientProfileLazyQuery>;
export type ClientProfileQueryResult = Apollo.QueryResult<ClientProfileQuery, ClientProfileQueryVariables>;
export const FindMatchingCustomerDocument = gql`
    query FindMatchingCustomer($clientName: String!, $qboInstanceId: ID) {
  findMatchingCustomer(clientName: $clientName, qboInstanceId: $qboInstanceId) {
    id
    displayName
  }
}
    `;

/**
 * __useFindMatchingCustomerQuery__
 *
 * To run a query within a React component, call `useFindMatchingCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMatchingCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMatchingCustomerQuery({
 *   variables: {
 *      clientName: // value for 'clientName'
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useFindMatchingCustomerQuery(baseOptions: Apollo.QueryHookOptions<FindMatchingCustomerQuery, FindMatchingCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMatchingCustomerQuery, FindMatchingCustomerQueryVariables>(FindMatchingCustomerDocument, options);
      }
export function useFindMatchingCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMatchingCustomerQuery, FindMatchingCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMatchingCustomerQuery, FindMatchingCustomerQueryVariables>(FindMatchingCustomerDocument, options);
        }
export type FindMatchingCustomerQueryHookResult = ReturnType<typeof useFindMatchingCustomerQuery>;
export type FindMatchingCustomerLazyQueryHookResult = ReturnType<typeof useFindMatchingCustomerLazyQuery>;
export type FindMatchingCustomerQueryResult = Apollo.QueryResult<FindMatchingCustomerQuery, FindMatchingCustomerQueryVariables>;
export const QboModalQboCustomersOptionsDocument = gql`
    query QboModalQboCustomersOptions($filters: QBOCustomerFilters) {
  quickbooksCustomers(filters: $filters) {
    ...QboCustomerDetails
    qboInstance {
      id
    }
  }
}
    ${QboCustomerDetailsFragmentDoc}`;

/**
 * __useQboModalQboCustomersOptionsQuery__
 *
 * To run a query within a React component, call `useQboModalQboCustomersOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQboModalQboCustomersOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQboModalQboCustomersOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQboModalQboCustomersOptionsQuery(baseOptions?: Apollo.QueryHookOptions<QboModalQboCustomersOptionsQuery, QboModalQboCustomersOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QboModalQboCustomersOptionsQuery, QboModalQboCustomersOptionsQueryVariables>(QboModalQboCustomersOptionsDocument, options);
      }
export function useQboModalQboCustomersOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QboModalQboCustomersOptionsQuery, QboModalQboCustomersOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QboModalQboCustomersOptionsQuery, QboModalQboCustomersOptionsQueryVariables>(QboModalQboCustomersOptionsDocument, options);
        }
export type QboModalQboCustomersOptionsQueryHookResult = ReturnType<typeof useQboModalQboCustomersOptionsQuery>;
export type QboModalQboCustomersOptionsLazyQueryHookResult = ReturnType<typeof useQboModalQboCustomersOptionsLazyQuery>;
export type QboModalQboCustomersOptionsQueryResult = Apollo.QueryResult<QboModalQboCustomersOptionsQuery, QboModalQboCustomersOptionsQueryVariables>;
export const SyncClientToQboDocument = gql`
    mutation SyncClientToQbo($id: ID!, $qboInstanceId: ID!) {
  syncClientToQbo(input: {id: $id, qboInstanceId: $qboInstanceId}) {
    client {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type SyncClientToQboMutationFn = Apollo.MutationFunction<SyncClientToQboMutation, SyncClientToQboMutationVariables>;

/**
 * __useSyncClientToQboMutation__
 *
 * To run a mutation, you first call `useSyncClientToQboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncClientToQboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncClientToQboMutation, { data, loading, error }] = useSyncClientToQboMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useSyncClientToQboMutation(baseOptions?: Apollo.MutationHookOptions<SyncClientToQboMutation, SyncClientToQboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncClientToQboMutation, SyncClientToQboMutationVariables>(SyncClientToQboDocument, options);
      }
export type SyncClientToQboMutationHookResult = ReturnType<typeof useSyncClientToQboMutation>;
export type SyncClientToQboMutationResult = Apollo.MutationResult<SyncClientToQboMutation>;
export type SyncClientToQboMutationOptions = Apollo.BaseMutationOptions<SyncClientToQboMutation, SyncClientToQboMutationVariables>;
export const ConsultantDirectoryDocument = gql`
    query ConsultantDirectory {
  currentProfile {
    id
    organization {
      connectedToQbo
      settings {
        multiQboInstanceEnabled
      }
    }
  }
}
    `;

/**
 * __useConsultantDirectoryQuery__
 *
 * To run a query within a React component, call `useConsultantDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantDirectoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsultantDirectoryQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantDirectoryQuery, ConsultantDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantDirectoryQuery, ConsultantDirectoryQueryVariables>(ConsultantDirectoryDocument, options);
      }
export function useConsultantDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantDirectoryQuery, ConsultantDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantDirectoryQuery, ConsultantDirectoryQueryVariables>(ConsultantDirectoryDocument, options);
        }
export type ConsultantDirectoryQueryHookResult = ReturnType<typeof useConsultantDirectoryQuery>;
export type ConsultantDirectoryLazyQueryHookResult = ReturnType<typeof useConsultantDirectoryLazyQuery>;
export type ConsultantDirectoryQueryResult = Apollo.QueryResult<ConsultantDirectoryQuery, ConsultantDirectoryQueryVariables>;
export const QuickbooksCustomersForImportDocument = gql`
    query QuickbooksCustomersForImport($qboInstanceId: ID) {
  quickbooksCustomers(
    filters: {noMatchingMgClient: true, qboInstanceId: $qboInstanceId}
  ) {
    ...QboCustomerDetailsForImport
  }
}
    ${QboCustomerDetailsForImportFragmentDoc}`;

/**
 * __useQuickbooksCustomersForImportQuery__
 *
 * To run a query within a React component, call `useQuickbooksCustomersForImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksCustomersForImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksCustomersForImportQuery({
 *   variables: {
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useQuickbooksCustomersForImportQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksCustomersForImportQuery, QuickbooksCustomersForImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksCustomersForImportQuery, QuickbooksCustomersForImportQueryVariables>(QuickbooksCustomersForImportDocument, options);
      }
export function useQuickbooksCustomersForImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksCustomersForImportQuery, QuickbooksCustomersForImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksCustomersForImportQuery, QuickbooksCustomersForImportQueryVariables>(QuickbooksCustomersForImportDocument, options);
        }
export type QuickbooksCustomersForImportQueryHookResult = ReturnType<typeof useQuickbooksCustomersForImportQuery>;
export type QuickbooksCustomersForImportLazyQueryHookResult = ReturnType<typeof useQuickbooksCustomersForImportLazyQuery>;
export type QuickbooksCustomersForImportQueryResult = Apollo.QueryResult<QuickbooksCustomersForImportQuery, QuickbooksCustomersForImportQueryVariables>;
export const QuickbooksCustomersPotentialMatchingClientsDocument = gql`
    query QuickbooksCustomersPotentialMatchingClients($qboInstanceId: ID) {
  customersMatchingClients: quickbooksCustomersPotentialMatchingClients(
    qboInstanceId: $qboInstanceId
  ) {
    qboCustomerId
    clientId
  }
}
    `;

/**
 * __useQuickbooksCustomersPotentialMatchingClientsQuery__
 *
 * To run a query within a React component, call `useQuickbooksCustomersPotentialMatchingClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksCustomersPotentialMatchingClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksCustomersPotentialMatchingClientsQuery({
 *   variables: {
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useQuickbooksCustomersPotentialMatchingClientsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksCustomersPotentialMatchingClientsQuery, QuickbooksCustomersPotentialMatchingClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksCustomersPotentialMatchingClientsQuery, QuickbooksCustomersPotentialMatchingClientsQueryVariables>(QuickbooksCustomersPotentialMatchingClientsDocument, options);
      }
export function useQuickbooksCustomersPotentialMatchingClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksCustomersPotentialMatchingClientsQuery, QuickbooksCustomersPotentialMatchingClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksCustomersPotentialMatchingClientsQuery, QuickbooksCustomersPotentialMatchingClientsQueryVariables>(QuickbooksCustomersPotentialMatchingClientsDocument, options);
        }
export type QuickbooksCustomersPotentialMatchingClientsQueryHookResult = ReturnType<typeof useQuickbooksCustomersPotentialMatchingClientsQuery>;
export type QuickbooksCustomersPotentialMatchingClientsLazyQueryHookResult = ReturnType<typeof useQuickbooksCustomersPotentialMatchingClientsLazyQuery>;
export type QuickbooksCustomersPotentialMatchingClientsQueryResult = Apollo.QueryResult<QuickbooksCustomersPotentialMatchingClientsQuery, QuickbooksCustomersPotentialMatchingClientsQueryVariables>;
export const ImporterOrgQboSettingsDocument = gql`
    query ImporterOrgQboSettings {
  currentProfile {
    organization {
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
}
    `;

/**
 * __useImporterOrgQboSettingsQuery__
 *
 * To run a query within a React component, call `useImporterOrgQboSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImporterOrgQboSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImporterOrgQboSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImporterOrgQboSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ImporterOrgQboSettingsQuery, ImporterOrgQboSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImporterOrgQboSettingsQuery, ImporterOrgQboSettingsQueryVariables>(ImporterOrgQboSettingsDocument, options);
      }
export function useImporterOrgQboSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImporterOrgQboSettingsQuery, ImporterOrgQboSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImporterOrgQboSettingsQuery, ImporterOrgQboSettingsQueryVariables>(ImporterOrgQboSettingsDocument, options);
        }
export type ImporterOrgQboSettingsQueryHookResult = ReturnType<typeof useImporterOrgQboSettingsQuery>;
export type ImporterOrgQboSettingsLazyQueryHookResult = ReturnType<typeof useImporterOrgQboSettingsLazyQuery>;
export type ImporterOrgQboSettingsQueryResult = Apollo.QueryResult<ImporterOrgQboSettingsQuery, ImporterOrgQboSettingsQueryVariables>;
export const AllExpensesDocument = gql`
    query AllExpenses($filters: ExpenseFiltersInput!) {
  allExpenses(filters: $filters) {
    ...ExpenseInfo
  }
}
    ${ExpenseInfoFragmentDoc}`;

/**
 * __useAllExpensesQuery__
 *
 * To run a query within a React component, call `useAllExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExpensesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllExpensesQuery(baseOptions: Apollo.QueryHookOptions<AllExpensesQuery, AllExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllExpensesQuery, AllExpensesQueryVariables>(AllExpensesDocument, options);
      }
export function useAllExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllExpensesQuery, AllExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllExpensesQuery, AllExpensesQueryVariables>(AllExpensesDocument, options);
        }
export type AllExpensesQueryHookResult = ReturnType<typeof useAllExpensesQuery>;
export type AllExpensesLazyQueryHookResult = ReturnType<typeof useAllExpensesLazyQuery>;
export type AllExpensesQueryResult = Apollo.QueryResult<AllExpensesQuery, AllExpensesQueryVariables>;
export const ExpensesCountDocument = gql`
    query ExpensesCount($sortBy: ExpensesSortInput, $filters: ExpenseFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  expenses(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
  }
}
    `;

/**
 * __useExpensesCountQuery__
 *
 * To run a query within a React component, call `useExpensesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesCountQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExpensesCountQuery(baseOptions: Apollo.QueryHookOptions<ExpensesCountQuery, ExpensesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesCountQuery, ExpensesCountQueryVariables>(ExpensesCountDocument, options);
      }
export function useExpensesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesCountQuery, ExpensesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesCountQuery, ExpensesCountQueryVariables>(ExpensesCountDocument, options);
        }
export type ExpensesCountQueryHookResult = ReturnType<typeof useExpensesCountQuery>;
export type ExpensesCountLazyQueryHookResult = ReturnType<typeof useExpensesCountLazyQuery>;
export type ExpensesCountQueryResult = Apollo.QueryResult<ExpensesCountQuery, ExpensesCountQueryVariables>;
export const ExpensesDocument = gql`
    query Expenses($sortBy: ExpensesSortInput, $filters: ExpenseFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  expenses(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    totalAmount
    totalMarkup
    totalBillableAmount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...ExpenseInfo
    }
  }
}
    ${ExpenseInfoFragmentDoc}`;

/**
 * __useExpensesQuery__
 *
 * To run a query within a React component, call `useExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useExpensesQuery(baseOptions: Apollo.QueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<ExpensesQuery, ExpensesQueryVariables>;
export const ExpensesFiltersDocument = gql`
    query ExpensesFilters {
  projectsOptions {
    id
    name
  }
  currentProfile {
    organization {
      connectedToQbo
      profiles {
        id
        name
      }
      overheads(excludeTimeOffOverheads: true) {
        id
        name
      }
    }
  }
  expenseCategories {
    id
    name
  }
  expenseChargeTypes {
    id
    name
  }
}
    `;

/**
 * __useExpensesFiltersQuery__
 *
 * To run a query within a React component, call `useExpensesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpensesFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesFiltersQuery, ExpensesFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesFiltersQuery, ExpensesFiltersQueryVariables>(ExpensesFiltersDocument, options);
      }
export function useExpensesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesFiltersQuery, ExpensesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesFiltersQuery, ExpensesFiltersQueryVariables>(ExpensesFiltersDocument, options);
        }
export type ExpensesFiltersQueryHookResult = ReturnType<typeof useExpensesFiltersQuery>;
export type ExpensesFiltersLazyQueryHookResult = ReturnType<typeof useExpensesFiltersLazyQuery>;
export type ExpensesFiltersQueryResult = Apollo.QueryResult<ExpensesFiltersQuery, ExpensesFiltersQueryVariables>;
export const ProjectExpensesFiltersDocument = gql`
    query ProjectExpensesFilters($projectSlug: String!) {
  currentProfile {
    organization {
      profiles {
        id
        name
      }
    }
  }
  projectExpenseCategories(projectSlug: $projectSlug) {
    id
    name
  }
  expenseChargeTypes {
    id
    name
  }
  project(slug: $projectSlug) {
    ...ProjectPhases
  }
}
    ${ProjectPhasesFragmentDoc}`;

/**
 * __useProjectExpensesFiltersQuery__
 *
 * To run a query within a React component, call `useProjectExpensesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectExpensesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectExpensesFiltersQuery({
 *   variables: {
 *      projectSlug: // value for 'projectSlug'
 *   },
 * });
 */
export function useProjectExpensesFiltersQuery(baseOptions: Apollo.QueryHookOptions<ProjectExpensesFiltersQuery, ProjectExpensesFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectExpensesFiltersQuery, ProjectExpensesFiltersQueryVariables>(ProjectExpensesFiltersDocument, options);
      }
export function useProjectExpensesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectExpensesFiltersQuery, ProjectExpensesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectExpensesFiltersQuery, ProjectExpensesFiltersQueryVariables>(ProjectExpensesFiltersDocument, options);
        }
export type ProjectExpensesFiltersQueryHookResult = ReturnType<typeof useProjectExpensesFiltersQuery>;
export type ProjectExpensesFiltersLazyQueryHookResult = ReturnType<typeof useProjectExpensesFiltersLazyQuery>;
export type ProjectExpensesFiltersQueryResult = Apollo.QueryResult<ProjectExpensesFiltersQuery, ProjectExpensesFiltersQueryVariables>;
export const ExpenseMarkReimburseAsPaidDocument = gql`
    mutation ExpenseMarkReimburseAsPaid($id: ID!, $paidDate: ISO8601Date) {
  markReimburseAsPaid(input: {id: $id, paidDate: $paidDate}) {
    expense {
      id
      reimbursePaidDate
    }
  }
}
    `;
export type ExpenseMarkReimburseAsPaidMutationFn = Apollo.MutationFunction<ExpenseMarkReimburseAsPaidMutation, ExpenseMarkReimburseAsPaidMutationVariables>;

/**
 * __useExpenseMarkReimburseAsPaidMutation__
 *
 * To run a mutation, you first call `useExpenseMarkReimburseAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseMarkReimburseAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseMarkReimburseAsPaidMutation, { data, loading, error }] = useExpenseMarkReimburseAsPaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      paidDate: // value for 'paidDate'
 *   },
 * });
 */
export function useExpenseMarkReimburseAsPaidMutation(baseOptions?: Apollo.MutationHookOptions<ExpenseMarkReimburseAsPaidMutation, ExpenseMarkReimburseAsPaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpenseMarkReimburseAsPaidMutation, ExpenseMarkReimburseAsPaidMutationVariables>(ExpenseMarkReimburseAsPaidDocument, options);
      }
export type ExpenseMarkReimburseAsPaidMutationHookResult = ReturnType<typeof useExpenseMarkReimburseAsPaidMutation>;
export type ExpenseMarkReimburseAsPaidMutationResult = Apollo.MutationResult<ExpenseMarkReimburseAsPaidMutation>;
export type ExpenseMarkReimburseAsPaidMutationOptions = Apollo.BaseMutationOptions<ExpenseMarkReimburseAsPaidMutation, ExpenseMarkReimburseAsPaidMutationVariables>;
export const BatchSendExpenseToQuickbooksDocument = gql`
    mutation BatchSendExpenseToQuickbooks($input: ExpenseBatchSendToQuickbooksInput!) {
  batchSendExpenseToQuickbooks(input: $input) {
    enqueued
  }
}
    `;
export type BatchSendExpenseToQuickbooksMutationFn = Apollo.MutationFunction<BatchSendExpenseToQuickbooksMutation, BatchSendExpenseToQuickbooksMutationVariables>;

/**
 * __useBatchSendExpenseToQuickbooksMutation__
 *
 * To run a mutation, you first call `useBatchSendExpenseToQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSendExpenseToQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSendExpenseToQuickbooksMutation, { data, loading, error }] = useBatchSendExpenseToQuickbooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSendExpenseToQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<BatchSendExpenseToQuickbooksMutation, BatchSendExpenseToQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchSendExpenseToQuickbooksMutation, BatchSendExpenseToQuickbooksMutationVariables>(BatchSendExpenseToQuickbooksDocument, options);
      }
export type BatchSendExpenseToQuickbooksMutationHookResult = ReturnType<typeof useBatchSendExpenseToQuickbooksMutation>;
export type BatchSendExpenseToQuickbooksMutationResult = Apollo.MutationResult<BatchSendExpenseToQuickbooksMutation>;
export type BatchSendExpenseToQuickbooksMutationOptions = Apollo.BaseMutationOptions<BatchSendExpenseToQuickbooksMutation, BatchSendExpenseToQuickbooksMutationVariables>;
export const BatchExpensesToQboJobDocument = gql`
    subscription BatchExpensesToQboJob {
  batchExpensesToQboJob {
    result {
      status
      itemFailures {
        expense {
          id
          date
          project {
            name
          }
          amount
        }
        message
        profile {
          name
        }
        qboEntity
      }
    }
  }
}
    `;

/**
 * __useBatchExpensesToQboJobSubscription__
 *
 * To run a query within a React component, call `useBatchExpensesToQboJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBatchExpensesToQboJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchExpensesToQboJobSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBatchExpensesToQboJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BatchExpensesToQboJobSubscription, BatchExpensesToQboJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BatchExpensesToQboJobSubscription, BatchExpensesToQboJobSubscriptionVariables>(BatchExpensesToQboJobDocument, options);
      }
export type BatchExpensesToQboJobSubscriptionHookResult = ReturnType<typeof useBatchExpensesToQboJobSubscription>;
export type BatchExpensesToQboJobSubscriptionResult = Apollo.SubscriptionResult<BatchExpensesToQboJobSubscription>;
export const ConsultantBillsExportStartDocument = gql`
    mutation ConsultantBillsExportStart($filters: ConsultantBillsInput!) {
  startExport {
    consultantBills(input: $filters) {
      id
    }
  }
}
    `;
export type ConsultantBillsExportStartMutationFn = Apollo.MutationFunction<ConsultantBillsExportStartMutation, ConsultantBillsExportStartMutationVariables>;

/**
 * __useConsultantBillsExportStartMutation__
 *
 * To run a mutation, you first call `useConsultantBillsExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillsExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consultantBillsExportStartMutation, { data, loading, error }] = useConsultantBillsExportStartMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useConsultantBillsExportStartMutation(baseOptions?: Apollo.MutationHookOptions<ConsultantBillsExportStartMutation, ConsultantBillsExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsultantBillsExportStartMutation, ConsultantBillsExportStartMutationVariables>(ConsultantBillsExportStartDocument, options);
      }
export type ConsultantBillsExportStartMutationHookResult = ReturnType<typeof useConsultantBillsExportStartMutation>;
export type ConsultantBillsExportStartMutationResult = Apollo.MutationResult<ConsultantBillsExportStartMutation>;
export type ConsultantBillsExportStartMutationOptions = Apollo.BaseMutationOptions<ConsultantBillsExportStartMutation, ConsultantBillsExportStartMutationVariables>;
export const ProjectTimingsExportStartDocument = gql`
    mutation ProjectTimingsExportStart($filters: ProjectsTimingsInput!) {
  startExport {
    projectsTimings(input: $filters) {
      id
    }
  }
}
    `;
export type ProjectTimingsExportStartMutationFn = Apollo.MutationFunction<ProjectTimingsExportStartMutation, ProjectTimingsExportStartMutationVariables>;

/**
 * __useProjectTimingsExportStartMutation__
 *
 * To run a mutation, you first call `useProjectTimingsExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTimingsExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTimingsExportStartMutation, { data, loading, error }] = useProjectTimingsExportStartMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectTimingsExportStartMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTimingsExportStartMutation, ProjectTimingsExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTimingsExportStartMutation, ProjectTimingsExportStartMutationVariables>(ProjectTimingsExportStartDocument, options);
      }
export type ProjectTimingsExportStartMutationHookResult = ReturnType<typeof useProjectTimingsExportStartMutation>;
export type ProjectTimingsExportStartMutationResult = Apollo.MutationResult<ProjectTimingsExportStartMutation>;
export type ProjectTimingsExportStartMutationOptions = Apollo.BaseMutationOptions<ProjectTimingsExportStartMutation, ProjectTimingsExportStartMutationVariables>;
export const InvoicesExportStartDocument = gql`
    mutation InvoicesExportStart($filters: InvoicesInput!) {
  startExport {
    invoices(input: $filters) {
      id
    }
  }
}
    `;
export type InvoicesExportStartMutationFn = Apollo.MutationFunction<InvoicesExportStartMutation, InvoicesExportStartMutationVariables>;

/**
 * __useInvoicesExportStartMutation__
 *
 * To run a mutation, you first call `useInvoicesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoicesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoicesExportStartMutation, { data, loading, error }] = useInvoicesExportStartMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInvoicesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<InvoicesExportStartMutation, InvoicesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoicesExportStartMutation, InvoicesExportStartMutationVariables>(InvoicesExportStartDocument, options);
      }
export type InvoicesExportStartMutationHookResult = ReturnType<typeof useInvoicesExportStartMutation>;
export type InvoicesExportStartMutationResult = Apollo.MutationResult<InvoicesExportStartMutation>;
export type InvoicesExportStartMutationOptions = Apollo.BaseMutationOptions<InvoicesExportStartMutation, InvoicesExportStartMutationVariables>;
export const PaymentsInvoicesExportStartDocument = gql`
    mutation PaymentsInvoicesExportStart($issueDateRange: DateRangeInput!) {
  startExport {
    paymentsInvoices(input: {issueDateRange: $issueDateRange}) {
      id
    }
  }
}
    `;
export type PaymentsInvoicesExportStartMutationFn = Apollo.MutationFunction<PaymentsInvoicesExportStartMutation, PaymentsInvoicesExportStartMutationVariables>;

/**
 * __usePaymentsInvoicesExportStartMutation__
 *
 * To run a mutation, you first call `usePaymentsInvoicesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsInvoicesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsInvoicesExportStartMutation, { data, loading, error }] = usePaymentsInvoicesExportStartMutation({
 *   variables: {
 *      issueDateRange: // value for 'issueDateRange'
 *   },
 * });
 */
export function usePaymentsInvoicesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<PaymentsInvoicesExportStartMutation, PaymentsInvoicesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentsInvoicesExportStartMutation, PaymentsInvoicesExportStartMutationVariables>(PaymentsInvoicesExportStartDocument, options);
      }
export type PaymentsInvoicesExportStartMutationHookResult = ReturnType<typeof usePaymentsInvoicesExportStartMutation>;
export type PaymentsInvoicesExportStartMutationResult = Apollo.MutationResult<PaymentsInvoicesExportStartMutation>;
export type PaymentsInvoicesExportStartMutationOptions = Apollo.BaseMutationOptions<PaymentsInvoicesExportStartMutation, PaymentsInvoicesExportStartMutationVariables>;
export const PaymentsReceivablesExportStartDocument = gql`
    mutation PaymentsReceivablesExportStart($issueDateRange: DateRangeInput!) {
  startExport {
    paymentsReceivables(input: {issueDateRange: $issueDateRange}) {
      id
    }
  }
}
    `;
export type PaymentsReceivablesExportStartMutationFn = Apollo.MutationFunction<PaymentsReceivablesExportStartMutation, PaymentsReceivablesExportStartMutationVariables>;

/**
 * __usePaymentsReceivablesExportStartMutation__
 *
 * To run a mutation, you first call `usePaymentsReceivablesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsReceivablesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsReceivablesExportStartMutation, { data, loading, error }] = usePaymentsReceivablesExportStartMutation({
 *   variables: {
 *      issueDateRange: // value for 'issueDateRange'
 *   },
 * });
 */
export function usePaymentsReceivablesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<PaymentsReceivablesExportStartMutation, PaymentsReceivablesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentsReceivablesExportStartMutation, PaymentsReceivablesExportStartMutationVariables>(PaymentsReceivablesExportStartDocument, options);
      }
export type PaymentsReceivablesExportStartMutationHookResult = ReturnType<typeof usePaymentsReceivablesExportStartMutation>;
export type PaymentsReceivablesExportStartMutationResult = Apollo.MutationResult<PaymentsReceivablesExportStartMutation>;
export type PaymentsReceivablesExportStartMutationOptions = Apollo.BaseMutationOptions<PaymentsReceivablesExportStartMutation, PaymentsReceivablesExportStartMutationVariables>;
export const PaymentsExportStartDocument = gql`
    mutation PaymentsExportStart($paidDateRange: DateRangeInput!) {
  startExport {
    payments(input: {paidDateRange: $paidDateRange}) {
      id
    }
  }
}
    `;
export type PaymentsExportStartMutationFn = Apollo.MutationFunction<PaymentsExportStartMutation, PaymentsExportStartMutationVariables>;

/**
 * __usePaymentsExportStartMutation__
 *
 * To run a mutation, you first call `usePaymentsExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsExportStartMutation, { data, loading, error }] = usePaymentsExportStartMutation({
 *   variables: {
 *      paidDateRange: // value for 'paidDateRange'
 *   },
 * });
 */
export function usePaymentsExportStartMutation(baseOptions?: Apollo.MutationHookOptions<PaymentsExportStartMutation, PaymentsExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentsExportStartMutation, PaymentsExportStartMutationVariables>(PaymentsExportStartDocument, options);
      }
export type PaymentsExportStartMutationHookResult = ReturnType<typeof usePaymentsExportStartMutation>;
export type PaymentsExportStartMutationResult = Apollo.MutationResult<PaymentsExportStartMutation>;
export type PaymentsExportStartMutationOptions = Apollo.BaseMutationOptions<PaymentsExportStartMutation, PaymentsExportStartMutationVariables>;
export const PayoutsExportStartDocument = gql`
    mutation PayoutsExportStart($payoutDateRange: DateRangeInput!) {
  startExport {
    payouts(input: {payoutDateRange: $payoutDateRange}) {
      id
    }
  }
}
    `;
export type PayoutsExportStartMutationFn = Apollo.MutationFunction<PayoutsExportStartMutation, PayoutsExportStartMutationVariables>;

/**
 * __usePayoutsExportStartMutation__
 *
 * To run a mutation, you first call `usePayoutsExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoutsExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoutsExportStartMutation, { data, loading, error }] = usePayoutsExportStartMutation({
 *   variables: {
 *      payoutDateRange: // value for 'payoutDateRange'
 *   },
 * });
 */
export function usePayoutsExportStartMutation(baseOptions?: Apollo.MutationHookOptions<PayoutsExportStartMutation, PayoutsExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayoutsExportStartMutation, PayoutsExportStartMutationVariables>(PayoutsExportStartDocument, options);
      }
export type PayoutsExportStartMutationHookResult = ReturnType<typeof usePayoutsExportStartMutation>;
export type PayoutsExportStartMutationResult = Apollo.MutationResult<PayoutsExportStartMutation>;
export type PayoutsExportStartMutationOptions = Apollo.BaseMutationOptions<PayoutsExportStartMutation, PayoutsExportStartMutationVariables>;
export const TaxesCollectedExportStartDocument = gql`
    mutation TaxesCollectedExportStart($paidDateRange: DateRangeInput!) {
  startExport {
    taxesCollected(input: {paidDateRange: $paidDateRange}) {
      id
    }
  }
}
    `;
export type TaxesCollectedExportStartMutationFn = Apollo.MutationFunction<TaxesCollectedExportStartMutation, TaxesCollectedExportStartMutationVariables>;

/**
 * __useTaxesCollectedExportStartMutation__
 *
 * To run a mutation, you first call `useTaxesCollectedExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxesCollectedExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxesCollectedExportStartMutation, { data, loading, error }] = useTaxesCollectedExportStartMutation({
 *   variables: {
 *      paidDateRange: // value for 'paidDateRange'
 *   },
 * });
 */
export function useTaxesCollectedExportStartMutation(baseOptions?: Apollo.MutationHookOptions<TaxesCollectedExportStartMutation, TaxesCollectedExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaxesCollectedExportStartMutation, TaxesCollectedExportStartMutationVariables>(TaxesCollectedExportStartDocument, options);
      }
export type TaxesCollectedExportStartMutationHookResult = ReturnType<typeof useTaxesCollectedExportStartMutation>;
export type TaxesCollectedExportStartMutationResult = Apollo.MutationResult<TaxesCollectedExportStartMutation>;
export type TaxesCollectedExportStartMutationOptions = Apollo.BaseMutationOptions<TaxesCollectedExportStartMutation, TaxesCollectedExportStartMutationVariables>;
export const TransactionsExportStartDocument = gql`
    mutation TransactionsExportStart($dateRange: DateRangeInput!) {
  startExport {
    transactions(input: {dateRange: $dateRange}) {
      id
    }
  }
}
    `;
export type TransactionsExportStartMutationFn = Apollo.MutationFunction<TransactionsExportStartMutation, TransactionsExportStartMutationVariables>;

/**
 * __useTransactionsExportStartMutation__
 *
 * To run a mutation, you first call `useTransactionsExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionsExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionsExportStartMutation, { data, loading, error }] = useTransactionsExportStartMutation({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useTransactionsExportStartMutation(baseOptions?: Apollo.MutationHookOptions<TransactionsExportStartMutation, TransactionsExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransactionsExportStartMutation, TransactionsExportStartMutationVariables>(TransactionsExportStartDocument, options);
      }
export type TransactionsExportStartMutationHookResult = ReturnType<typeof useTransactionsExportStartMutation>;
export type TransactionsExportStartMutationResult = Apollo.MutationResult<TransactionsExportStartMutation>;
export type TransactionsExportStartMutationOptions = Apollo.BaseMutationOptions<TransactionsExportStartMutation, TransactionsExportStartMutationVariables>;
export const ExpensesExportStartDocument = gql`
    mutation ExpensesExportStart($filters: ExpensesInput!) {
  startExport {
    expenses(input: $filters) {
      id
    }
  }
}
    `;
export type ExpensesExportStartMutationFn = Apollo.MutationFunction<ExpensesExportStartMutation, ExpensesExportStartMutationVariables>;

/**
 * __useExpensesExportStartMutation__
 *
 * To run a mutation, you first call `useExpensesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpensesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expensesExportStartMutation, { data, loading, error }] = useExpensesExportStartMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExpensesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<ExpensesExportStartMutation, ExpensesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpensesExportStartMutation, ExpensesExportStartMutationVariables>(ExpensesExportStartDocument, options);
      }
export type ExpensesExportStartMutationHookResult = ReturnType<typeof useExpensesExportStartMutation>;
export type ExpensesExportStartMutationResult = Apollo.MutationResult<ExpensesExportStartMutation>;
export type ExpensesExportStartMutationOptions = Apollo.BaseMutationOptions<ExpensesExportStartMutation, ExpensesExportStartMutationVariables>;
export const EntriesExportStartDocument = gql`
    mutation EntriesExportStart($intent: TimeLogIntent!, $filters: EntryFiltersInput!) {
  startExport {
    entries(input: {filters: $filters, intent: $intent}) {
      id
    }
  }
}
    `;
export type EntriesExportStartMutationFn = Apollo.MutationFunction<EntriesExportStartMutation, EntriesExportStartMutationVariables>;

/**
 * __useEntriesExportStartMutation__
 *
 * To run a mutation, you first call `useEntriesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntriesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entriesExportStartMutation, { data, loading, error }] = useEntriesExportStartMutation({
 *   variables: {
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEntriesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<EntriesExportStartMutation, EntriesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EntriesExportStartMutation, EntriesExportStartMutationVariables>(EntriesExportStartDocument, options);
      }
export type EntriesExportStartMutationHookResult = ReturnType<typeof useEntriesExportStartMutation>;
export type EntriesExportStartMutationResult = Apollo.MutationResult<EntriesExportStartMutation>;
export type EntriesExportStartMutationOptions = Apollo.BaseMutationOptions<EntriesExportStartMutation, EntriesExportStartMutationVariables>;
export const GroupedEntriesExportStartDocument = gql`
    mutation GroupedEntriesExportStart($groupBy: GroupByInput!, $intent: TimeLogIntent!, $filters: EntryFiltersInput!) {
  startExport {
    groupedEntries(input: {groupBy: $groupBy, intent: $intent, filters: $filters}) {
      id
    }
  }
}
    `;
export type GroupedEntriesExportStartMutationFn = Apollo.MutationFunction<GroupedEntriesExportStartMutation, GroupedEntriesExportStartMutationVariables>;

/**
 * __useGroupedEntriesExportStartMutation__
 *
 * To run a mutation, you first call `useGroupedEntriesExportStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupedEntriesExportStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupedEntriesExportStartMutation, { data, loading, error }] = useGroupedEntriesExportStartMutation({
 *   variables: {
 *      groupBy: // value for 'groupBy'
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGroupedEntriesExportStartMutation(baseOptions?: Apollo.MutationHookOptions<GroupedEntriesExportStartMutation, GroupedEntriesExportStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupedEntriesExportStartMutation, GroupedEntriesExportStartMutationVariables>(GroupedEntriesExportStartDocument, options);
      }
export type GroupedEntriesExportStartMutationHookResult = ReturnType<typeof useGroupedEntriesExportStartMutation>;
export type GroupedEntriesExportStartMutationResult = Apollo.MutationResult<GroupedEntriesExportStartMutation>;
export type GroupedEntriesExportStartMutationOptions = Apollo.BaseMutationOptions<GroupedEntriesExportStartMutation, GroupedEntriesExportStartMutationVariables>;
export const CheckExportStatusDocument = gql`
    query CheckExportStatus($id: ID!) {
  checkExport(id: $id) {
    status
    downloadUrl
  }
}
    `;

/**
 * __useCheckExportStatusQuery__
 *
 * To run a query within a React component, call `useCheckExportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExportStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckExportStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckExportStatusQuery, CheckExportStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckExportStatusQuery, CheckExportStatusQueryVariables>(CheckExportStatusDocument, options);
      }
export function useCheckExportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckExportStatusQuery, CheckExportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckExportStatusQuery, CheckExportStatusQueryVariables>(CheckExportStatusDocument, options);
        }
export type CheckExportStatusQueryHookResult = ReturnType<typeof useCheckExportStatusQuery>;
export type CheckExportStatusLazyQueryHookResult = ReturnType<typeof useCheckExportStatusLazyQuery>;
export type CheckExportStatusQueryResult = Apollo.QueryResult<CheckExportStatusQuery, CheckExportStatusQueryVariables>;
export const OrganizationExportsDocument = gql`
    query OrganizationExports($organizationId: ID!) {
  organizationExports(organizationId: $organizationId) {
    projectPhaseJson
  }
}
    `;

/**
 * __useOrganizationExportsQuery__
 *
 * To run a query within a React component, call `useOrganizationExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationExportsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationExportsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationExportsQuery, OrganizationExportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationExportsQuery, OrganizationExportsQueryVariables>(OrganizationExportsDocument, options);
      }
export function useOrganizationExportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationExportsQuery, OrganizationExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationExportsQuery, OrganizationExportsQueryVariables>(OrganizationExportsDocument, options);
        }
export type OrganizationExportsQueryHookResult = ReturnType<typeof useOrganizationExportsQuery>;
export type OrganizationExportsLazyQueryHookResult = ReturnType<typeof useOrganizationExportsLazyQuery>;
export type OrganizationExportsQueryResult = Apollo.QueryResult<OrganizationExportsQuery, OrganizationExportsQueryVariables>;
export const ProjectProfitExportDocument = gql`
    mutation ProjectProfitExport($input: ProjectProfitExportInput!) {
  startExport {
    projectProfit(input: $input) {
      id
    }
  }
}
    `;
export type ProjectProfitExportMutationFn = Apollo.MutationFunction<ProjectProfitExportMutation, ProjectProfitExportMutationVariables>;

/**
 * __useProjectProfitExportMutation__
 *
 * To run a mutation, you first call `useProjectProfitExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectProfitExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectProfitExportMutation, { data, loading, error }] = useProjectProfitExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectProfitExportMutation(baseOptions?: Apollo.MutationHookOptions<ProjectProfitExportMutation, ProjectProfitExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectProfitExportMutation, ProjectProfitExportMutationVariables>(ProjectProfitExportDocument, options);
      }
export type ProjectProfitExportMutationHookResult = ReturnType<typeof useProjectProfitExportMutation>;
export type ProjectProfitExportMutationResult = Apollo.MutationResult<ProjectProfitExportMutation>;
export type ProjectProfitExportMutationOptions = Apollo.BaseMutationOptions<ProjectProfitExportMutation, ProjectProfitExportMutationVariables>;
export const GanttChartDocument = gql`
    query ganttChart($isTemplate: Boolean, $filters: GanttChartFiltersInput) {
  ganttChart(filters: $filters, isTemplate: $isTemplate) {
    totalProjectsCount
    rows {
      ...GanttProjectRow
    }
  }
}
    ${GanttProjectRowFragmentDoc}`;

/**
 * __useGanttChartQuery__
 *
 * To run a query within a React component, call `useGanttChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGanttChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGanttChartQuery({
 *   variables: {
 *      isTemplate: // value for 'isTemplate'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGanttChartQuery(baseOptions?: Apollo.QueryHookOptions<GanttChartQuery, GanttChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GanttChartQuery, GanttChartQueryVariables>(GanttChartDocument, options);
      }
export function useGanttChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GanttChartQuery, GanttChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GanttChartQuery, GanttChartQueryVariables>(GanttChartDocument, options);
        }
export type GanttChartQueryHookResult = ReturnType<typeof useGanttChartQuery>;
export type GanttChartLazyQueryHookResult = ReturnType<typeof useGanttChartLazyQuery>;
export type GanttChartQueryResult = Apollo.QueryResult<GanttChartQuery, GanttChartQueryVariables>;
export const ProjectStatusesDocument = gql`
    query ProjectStatuses {
  __type(name: "ProjectStatus") {
    enumValues {
      name
      description
    }
  }
}
    `;

/**
 * __useProjectStatusesQuery__
 *
 * To run a query within a React component, call `useProjectStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectStatusesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectStatusesQuery, ProjectStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStatusesQuery, ProjectStatusesQueryVariables>(ProjectStatusesDocument, options);
      }
export function useProjectStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStatusesQuery, ProjectStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStatusesQuery, ProjectStatusesQueryVariables>(ProjectStatusesDocument, options);
        }
export type ProjectStatusesQueryHookResult = ReturnType<typeof useProjectStatusesQuery>;
export type ProjectStatusesLazyQueryHookResult = ReturnType<typeof useProjectStatusesLazyQuery>;
export type ProjectStatusesQueryResult = Apollo.QueryResult<ProjectStatusesQuery, ProjectStatusesQueryVariables>;
export const CategoryOptionsDocument = gql`
    query CategoryOptions {
  categories {
    id
    name
  }
}
    `;

/**
 * __useCategoryOptionsQuery__
 *
 * To run a query within a React component, call `useCategoryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<CategoryOptionsQuery, CategoryOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryOptionsQuery, CategoryOptionsQueryVariables>(CategoryOptionsDocument, options);
      }
export function useCategoryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryOptionsQuery, CategoryOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryOptionsQuery, CategoryOptionsQueryVariables>(CategoryOptionsDocument, options);
        }
export type CategoryOptionsQueryHookResult = ReturnType<typeof useCategoryOptionsQuery>;
export type CategoryOptionsLazyQueryHookResult = ReturnType<typeof useCategoryOptionsLazyQuery>;
export type CategoryOptionsQueryResult = Apollo.QueryResult<CategoryOptionsQuery, CategoryOptionsQueryVariables>;
export const ClientOptionsDocument = gql`
    query ClientOptions {
  allClients {
    syncedToQbo
    displayName
    id
  }
}
    `;

/**
 * __useClientOptionsQuery__
 *
 * To run a query within a React component, call `useClientOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ClientOptionsQuery, ClientOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientOptionsQuery, ClientOptionsQueryVariables>(ClientOptionsDocument, options);
      }
export function useClientOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientOptionsQuery, ClientOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientOptionsQuery, ClientOptionsQueryVariables>(ClientOptionsDocument, options);
        }
export type ClientOptionsQueryHookResult = ReturnType<typeof useClientOptionsQuery>;
export type ClientOptionsLazyQueryHookResult = ReturnType<typeof useClientOptionsLazyQuery>;
export type ClientOptionsQueryResult = Apollo.QueryResult<ClientOptionsQuery, ClientOptionsQueryVariables>;
export const PhaseTemplateOptionsDocument = gql`
    query PhaseTemplateOptions {
  phaseTemplates {
    id
    name
  }
}
    `;

/**
 * __usePhaseTemplateOptionsQuery__
 *
 * To run a query within a React component, call `usePhaseTemplateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhaseTemplateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhaseTemplateOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhaseTemplateOptionsQuery(baseOptions?: Apollo.QueryHookOptions<PhaseTemplateOptionsQuery, PhaseTemplateOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhaseTemplateOptionsQuery, PhaseTemplateOptionsQueryVariables>(PhaseTemplateOptionsDocument, options);
      }
export function usePhaseTemplateOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhaseTemplateOptionsQuery, PhaseTemplateOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhaseTemplateOptionsQuery, PhaseTemplateOptionsQueryVariables>(PhaseTemplateOptionsDocument, options);
        }
export type PhaseTemplateOptionsQueryHookResult = ReturnType<typeof usePhaseTemplateOptionsQuery>;
export type PhaseTemplateOptionsLazyQueryHookResult = ReturnType<typeof usePhaseTemplateOptionsLazyQuery>;
export type PhaseTemplateOptionsQueryResult = Apollo.QueryResult<PhaseTemplateOptionsQuery, PhaseTemplateOptionsQueryVariables>;
export const InvoiceBuilderDocument = gql`
    query InvoiceBuilder($slug: String!, $previewMode: Boolean) {
  invoiceBuilder(slug: $slug, previewMode: $previewMode) {
    loadingTotals @client
    invoice {
      ...InvoiceBuilderInvoiceFragment
      suggestVersionSwitch
    }
    fixedFeeServices {
      ...FixedFeeServicesEntriesFragment
    }
    hourlyServices {
      ...HourlyServicesEntriesFragment
    }
    expenses {
      included {
        ...ExpenseFragment
      }
      reported {
        ...ExpenseFragment
      }
    }
    phaseConsultants {
      entries {
        ...PhaseConsultantsFragment
      }
      ...PhaseConsultantsTotalFragment
    }
    projectConsultants {
      ...ProjectConsultantsFragment
    }
    otherItems {
      ...OtherItemEntriesFragment
    }
    retainerItems {
      ...RetainerItemEntriesFragment
    }
    taxes {
      ...TaxEntryPayload
    }
    retainerSummary {
      ...RetainerSummaryFragment
    }
    ...TabsTotalsFragment
    convenienceFee
    totalDue
    totalDueWithoutAppliedRetainer
    totalDueWithConvenienceFee
    subtotal
  }
}
    ${InvoiceBuilderInvoiceFragmentFragmentDoc}
${FixedFeeServicesEntriesFragmentFragmentDoc}
${HourlyServicesEntriesFragmentFragmentDoc}
${ExpenseFragmentFragmentDoc}
${PhaseConsultantsFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}
${ProjectConsultantsFragmentFragmentDoc}
${OtherItemEntriesFragmentFragmentDoc}
${RetainerItemEntriesFragmentFragmentDoc}
${TaxEntryPayloadFragmentDoc}
${RetainerSummaryFragmentFragmentDoc}
${TabsTotalsFragmentFragmentDoc}`;

/**
 * __useInvoiceBuilderQuery__
 *
 * To run a query within a React component, call `useInvoiceBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceBuilderQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      previewMode: // value for 'previewMode'
 *   },
 * });
 */
export function useInvoiceBuilderQuery(baseOptions: Apollo.QueryHookOptions<InvoiceBuilderQuery, InvoiceBuilderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceBuilderQuery, InvoiceBuilderQueryVariables>(InvoiceBuilderDocument, options);
      }
export function useInvoiceBuilderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceBuilderQuery, InvoiceBuilderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceBuilderQuery, InvoiceBuilderQueryVariables>(InvoiceBuilderDocument, options);
        }
export type InvoiceBuilderQueryHookResult = ReturnType<typeof useInvoiceBuilderQuery>;
export type InvoiceBuilderLazyQueryHookResult = ReturnType<typeof useInvoiceBuilderLazyQuery>;
export type InvoiceBuilderQueryResult = Apollo.QueryResult<InvoiceBuilderQuery, InvoiceBuilderQueryVariables>;
export const PreviousInvoicesForProjectDocument = gql`
    query PreviousInvoicesForProject($projectId: ID) {
  invoices(
    sortBy: {field: INVOICE_NUMBER, direction: DESC}
    filters: {projectId: $projectId}
  ) {
    nodes {
      ...PreviousInvoiceDetails
    }
  }
}
    ${PreviousInvoiceDetailsFragmentDoc}`;

/**
 * __usePreviousInvoicesForProjectQuery__
 *
 * To run a query within a React component, call `usePreviousInvoicesForProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviousInvoicesForProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviousInvoicesForProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePreviousInvoicesForProjectQuery(baseOptions?: Apollo.QueryHookOptions<PreviousInvoicesForProjectQuery, PreviousInvoicesForProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviousInvoicesForProjectQuery, PreviousInvoicesForProjectQueryVariables>(PreviousInvoicesForProjectDocument, options);
      }
export function usePreviousInvoicesForProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviousInvoicesForProjectQuery, PreviousInvoicesForProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviousInvoicesForProjectQuery, PreviousInvoicesForProjectQueryVariables>(PreviousInvoicesForProjectDocument, options);
        }
export type PreviousInvoicesForProjectQueryHookResult = ReturnType<typeof usePreviousInvoicesForProjectQuery>;
export type PreviousInvoicesForProjectLazyQueryHookResult = ReturnType<typeof usePreviousInvoicesForProjectLazyQuery>;
export type PreviousInvoicesForProjectQueryResult = Apollo.QueryResult<PreviousInvoicesForProjectQuery, PreviousInvoicesForProjectQueryVariables>;
export const ArtInvoiceBuilderDocument = gql`
    query ArtInvoiceBuilder($slug: String!, $previewMode: Boolean, $beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  invoiceBuilder(slug: $slug, previewMode: $previewMode) {
    loadingTotals @client
    invoice {
      ...InvoiceBuilderInvoiceFragment
      suggestVersionSwitch
    }
    fixedFeeServices {
      ...FixedFeeServicesEntriesFragment
    }
    hourlyServices {
      entries {
        ...HourlyServicesEntryFragment
        roleEntries {
          id
          hours
          previouslyBilledHours
          previouslyBilledAmount
          roleRate
          amount
          logged {
            total
            duringInvoiceRange
            beforeInvoiceBeginDate
          }
          role {
            ...ArtHoursServicesRole
          }
        }
        consultantEntries {
          ...HourlyServicesConsultantEntriesFragment
        }
      }
      totals {
        ...InvoiceTotalFragment
      }
    }
    expenses {
      included {
        ...ExpenseFragment
      }
      reported {
        ...ExpenseFragment
      }
    }
    phaseConsultants {
      entries {
        ...PhaseConsultantsFragment
      }
      ...PhaseConsultantsTotalFragment
    }
    projectConsultants {
      ...ProjectConsultantsFragment
    }
    otherItems {
      ...OtherItemEntriesFragment
    }
    ...TabsTotalsFragment
    totalDue
  }
}
    ${InvoiceBuilderInvoiceFragmentFragmentDoc}
${FixedFeeServicesEntriesFragmentFragmentDoc}
${HourlyServicesEntryFragmentFragmentDoc}
${ArtHoursServicesRoleFragmentDoc}
${HourlyServicesConsultantEntriesFragmentFragmentDoc}
${InvoiceTotalFragmentFragmentDoc}
${ExpenseFragmentFragmentDoc}
${PhaseConsultantsFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}
${ProjectConsultantsFragmentFragmentDoc}
${OtherItemEntriesFragmentFragmentDoc}
${TabsTotalsFragmentFragmentDoc}`;

/**
 * __useArtInvoiceBuilderQuery__
 *
 * To run a query within a React component, call `useArtInvoiceBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtInvoiceBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtInvoiceBuilderQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      previewMode: // value for 'previewMode'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useArtInvoiceBuilderQuery(baseOptions: Apollo.QueryHookOptions<ArtInvoiceBuilderQuery, ArtInvoiceBuilderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArtInvoiceBuilderQuery, ArtInvoiceBuilderQueryVariables>(ArtInvoiceBuilderDocument, options);
      }
export function useArtInvoiceBuilderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtInvoiceBuilderQuery, ArtInvoiceBuilderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArtInvoiceBuilderQuery, ArtInvoiceBuilderQueryVariables>(ArtInvoiceBuilderDocument, options);
        }
export type ArtInvoiceBuilderQueryHookResult = ReturnType<typeof useArtInvoiceBuilderQuery>;
export type ArtInvoiceBuilderLazyQueryHookResult = ReturnType<typeof useArtInvoiceBuilderLazyQuery>;
export type ArtInvoiceBuilderQueryResult = Apollo.QueryResult<ArtInvoiceBuilderQuery, ArtInvoiceBuilderQueryVariables>;
export const UpdateFixedFeeServiceDocument = gql`
    mutation UpdateFixedFeeService($input: InvoiceBuilderUpdateFixedFeeServiceInput!) {
  invoiceBuilder {
    updateFixedFeeService(input: $input) {
      payload {
        invoice {
          ...InvoiceBuilderInvoiceFragment
        }
        fixedFeeServices {
          ...FixedFeeServicesEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${InvoiceBuilderInvoiceFragmentFragmentDoc}
${FixedFeeServicesEntriesFragmentFragmentDoc}`;
export type UpdateFixedFeeServiceMutationFn = Apollo.MutationFunction<UpdateFixedFeeServiceMutation, UpdateFixedFeeServiceMutationVariables>;

/**
 * __useUpdateFixedFeeServiceMutation__
 *
 * To run a mutation, you first call `useUpdateFixedFeeServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFixedFeeServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFixedFeeServiceMutation, { data, loading, error }] = useUpdateFixedFeeServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFixedFeeServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFixedFeeServiceMutation, UpdateFixedFeeServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFixedFeeServiceMutation, UpdateFixedFeeServiceMutationVariables>(UpdateFixedFeeServiceDocument, options);
      }
export type UpdateFixedFeeServiceMutationHookResult = ReturnType<typeof useUpdateFixedFeeServiceMutation>;
export type UpdateFixedFeeServiceMutationResult = Apollo.MutationResult<UpdateFixedFeeServiceMutation>;
export type UpdateFixedFeeServiceMutationOptions = Apollo.BaseMutationOptions<UpdateFixedFeeServiceMutation, UpdateFixedFeeServiceMutationVariables>;
export const UpdateProjectConsultantDocument = gql`
    mutation UpdateProjectConsultant($input: InvoiceBuilderUpdateProjectConsultantInput!) {
  invoiceBuilder {
    updateProjectConsultant(input: $input) {
      payload {
        projectConsultants {
          ...ProjectConsultantsFragment
        }
        phaseConsultants {
          entries {
            ...PhaseConsultantsFragment
          }
          ...PhaseConsultantsTotalFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${ProjectConsultantsFragmentFragmentDoc}
${PhaseConsultantsFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}`;
export type UpdateProjectConsultantMutationFn = Apollo.MutationFunction<UpdateProjectConsultantMutation, UpdateProjectConsultantMutationVariables>;

/**
 * __useUpdateProjectConsultantMutation__
 *
 * To run a mutation, you first call `useUpdateProjectConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectConsultantMutation, { data, loading, error }] = useUpdateProjectConsultantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectConsultantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectConsultantMutation, UpdateProjectConsultantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectConsultantMutation, UpdateProjectConsultantMutationVariables>(UpdateProjectConsultantDocument, options);
      }
export type UpdateProjectConsultantMutationHookResult = ReturnType<typeof useUpdateProjectConsultantMutation>;
export type UpdateProjectConsultantMutationResult = Apollo.MutationResult<UpdateProjectConsultantMutation>;
export type UpdateProjectConsultantMutationOptions = Apollo.BaseMutationOptions<UpdateProjectConsultantMutation, UpdateProjectConsultantMutationVariables>;
export const UpdateInvoiceDateRangeDocument = gql`
    mutation UpdateInvoiceDateRange($input: InvoiceUpdateInput!) {
  updateInvoice(input: $input) {
    invoice {
      ...InvoiceBuilderInvoiceFragment
    }
  }
}
    ${InvoiceBuilderInvoiceFragmentFragmentDoc}`;
export type UpdateInvoiceDateRangeMutationFn = Apollo.MutationFunction<UpdateInvoiceDateRangeMutation, UpdateInvoiceDateRangeMutationVariables>;

/**
 * __useUpdateInvoiceDateRangeMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceDateRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceDateRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceDateRangeMutation, { data, loading, error }] = useUpdateInvoiceDateRangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceDateRangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceDateRangeMutation, UpdateInvoiceDateRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceDateRangeMutation, UpdateInvoiceDateRangeMutationVariables>(UpdateInvoiceDateRangeDocument, options);
      }
export type UpdateInvoiceDateRangeMutationHookResult = ReturnType<typeof useUpdateInvoiceDateRangeMutation>;
export type UpdateInvoiceDateRangeMutationResult = Apollo.MutationResult<UpdateInvoiceDateRangeMutation>;
export type UpdateInvoiceDateRangeMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceDateRangeMutation, UpdateInvoiceDateRangeMutationVariables>;
export const AddOtherItemDocument = gql`
    mutation AddOtherItem($input: InvoiceBuilderOtherItemsCreateInput!) {
  invoiceBuilder {
    createOtherItem(input: $input) {
      payload {
        otherItems {
          ...OtherItemEntriesFragment
        }
        totalDue
      }
    }
  }
}
    ${OtherItemEntriesFragmentFragmentDoc}`;
export type AddOtherItemMutationFn = Apollo.MutationFunction<AddOtherItemMutation, AddOtherItemMutationVariables>;

/**
 * __useAddOtherItemMutation__
 *
 * To run a mutation, you first call `useAddOtherItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOtherItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOtherItemMutation, { data, loading, error }] = useAddOtherItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOtherItemMutation(baseOptions?: Apollo.MutationHookOptions<AddOtherItemMutation, AddOtherItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOtherItemMutation, AddOtherItemMutationVariables>(AddOtherItemDocument, options);
      }
export type AddOtherItemMutationHookResult = ReturnType<typeof useAddOtherItemMutation>;
export type AddOtherItemMutationResult = Apollo.MutationResult<AddOtherItemMutation>;
export type AddOtherItemMutationOptions = Apollo.BaseMutationOptions<AddOtherItemMutation, AddOtherItemMutationVariables>;
export const UpdateOtherItemDocument = gql`
    mutation UpdateOtherItem($input: InvoiceBuilderOtherItemsUpdateInput!) {
  invoiceBuilder {
    updateOtherItem(input: $input) {
      payload {
        otherItems {
          ...OtherItemEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${OtherItemEntriesFragmentFragmentDoc}`;
export type UpdateOtherItemMutationFn = Apollo.MutationFunction<UpdateOtherItemMutation, UpdateOtherItemMutationVariables>;

/**
 * __useUpdateOtherItemMutation__
 *
 * To run a mutation, you first call `useUpdateOtherItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtherItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtherItemMutation, { data, loading, error }] = useUpdateOtherItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOtherItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOtherItemMutation, UpdateOtherItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOtherItemMutation, UpdateOtherItemMutationVariables>(UpdateOtherItemDocument, options);
      }
export type UpdateOtherItemMutationHookResult = ReturnType<typeof useUpdateOtherItemMutation>;
export type UpdateOtherItemMutationResult = Apollo.MutationResult<UpdateOtherItemMutation>;
export type UpdateOtherItemMutationOptions = Apollo.BaseMutationOptions<UpdateOtherItemMutation, UpdateOtherItemMutationVariables>;
export const DeleteOtherItemDocument = gql`
    mutation DeleteOtherItem($input: InvoiceBuilderOtherItemsDeleteInput!) {
  invoiceBuilder {
    deleteOtherItem(input: $input) {
      payload {
        otherItems {
          ...OtherItemEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${OtherItemEntriesFragmentFragmentDoc}`;
export type DeleteOtherItemMutationFn = Apollo.MutationFunction<DeleteOtherItemMutation, DeleteOtherItemMutationVariables>;

/**
 * __useDeleteOtherItemMutation__
 *
 * To run a mutation, you first call `useDeleteOtherItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOtherItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOtherItemMutation, { data, loading, error }] = useDeleteOtherItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOtherItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOtherItemMutation, DeleteOtherItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOtherItemMutation, DeleteOtherItemMutationVariables>(DeleteOtherItemDocument, options);
      }
export type DeleteOtherItemMutationHookResult = ReturnType<typeof useDeleteOtherItemMutation>;
export type DeleteOtherItemMutationResult = Apollo.MutationResult<DeleteOtherItemMutation>;
export type DeleteOtherItemMutationOptions = Apollo.BaseMutationOptions<DeleteOtherItemMutation, DeleteOtherItemMutationVariables>;
export const AddRetainerItemDocument = gql`
    mutation AddRetainerItem($input: InvoiceBuilderRetainersCreateInput!) {
  invoiceBuilder {
    createRetainer(input: $input) {
      payload {
        retainerItems {
          ...RetainerItemEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${RetainerItemEntriesFragmentFragmentDoc}`;
export type AddRetainerItemMutationFn = Apollo.MutationFunction<AddRetainerItemMutation, AddRetainerItemMutationVariables>;

/**
 * __useAddRetainerItemMutation__
 *
 * To run a mutation, you first call `useAddRetainerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRetainerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRetainerItemMutation, { data, loading, error }] = useAddRetainerItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRetainerItemMutation(baseOptions?: Apollo.MutationHookOptions<AddRetainerItemMutation, AddRetainerItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRetainerItemMutation, AddRetainerItemMutationVariables>(AddRetainerItemDocument, options);
      }
export type AddRetainerItemMutationHookResult = ReturnType<typeof useAddRetainerItemMutation>;
export type AddRetainerItemMutationResult = Apollo.MutationResult<AddRetainerItemMutation>;
export type AddRetainerItemMutationOptions = Apollo.BaseMutationOptions<AddRetainerItemMutation, AddRetainerItemMutationVariables>;
export const UpdateRetainerItemDocument = gql`
    mutation UpdateRetainerItem($input: InvoiceBuilderRetainersUpdateInput!) {
  invoiceBuilder {
    updateRetainer(input: $input) {
      payload {
        retainerItems {
          ...RetainerItemEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${RetainerItemEntriesFragmentFragmentDoc}`;
export type UpdateRetainerItemMutationFn = Apollo.MutationFunction<UpdateRetainerItemMutation, UpdateRetainerItemMutationVariables>;

/**
 * __useUpdateRetainerItemMutation__
 *
 * To run a mutation, you first call `useUpdateRetainerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetainerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetainerItemMutation, { data, loading, error }] = useUpdateRetainerItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRetainerItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRetainerItemMutation, UpdateRetainerItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRetainerItemMutation, UpdateRetainerItemMutationVariables>(UpdateRetainerItemDocument, options);
      }
export type UpdateRetainerItemMutationHookResult = ReturnType<typeof useUpdateRetainerItemMutation>;
export type UpdateRetainerItemMutationResult = Apollo.MutationResult<UpdateRetainerItemMutation>;
export type UpdateRetainerItemMutationOptions = Apollo.BaseMutationOptions<UpdateRetainerItemMutation, UpdateRetainerItemMutationVariables>;
export const DeleteRetainerItemDocument = gql`
    mutation DeleteRetainerItem($input: InvoiceBuilderRetainersDeleteInput!) {
  invoiceBuilder {
    deleteRetainer(input: $input) {
      payload {
        retainerItems {
          ...RetainerItemEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${RetainerItemEntriesFragmentFragmentDoc}`;
export type DeleteRetainerItemMutationFn = Apollo.MutationFunction<DeleteRetainerItemMutation, DeleteRetainerItemMutationVariables>;

/**
 * __useDeleteRetainerItemMutation__
 *
 * To run a mutation, you first call `useDeleteRetainerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRetainerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRetainerItemMutation, { data, loading, error }] = useDeleteRetainerItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRetainerItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRetainerItemMutation, DeleteRetainerItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRetainerItemMutation, DeleteRetainerItemMutationVariables>(DeleteRetainerItemDocument, options);
      }
export type DeleteRetainerItemMutationHookResult = ReturnType<typeof useDeleteRetainerItemMutation>;
export type DeleteRetainerItemMutationResult = Apollo.MutationResult<DeleteRetainerItemMutation>;
export type DeleteRetainerItemMutationOptions = Apollo.BaseMutationOptions<DeleteRetainerItemMutation, DeleteRetainerItemMutationVariables>;
export const ApplyRetainerDocument = gql`
    mutation ApplyRetainer($input: InvoiceBuilderRetainersApplyInput!) {
  invoiceBuilder {
    applyRetainer(input: $input) {
      payload {
        appliedRetainer {
          ...AppliedRetainerItemFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${AppliedRetainerItemFragmentFragmentDoc}`;
export type ApplyRetainerMutationFn = Apollo.MutationFunction<ApplyRetainerMutation, ApplyRetainerMutationVariables>;

/**
 * __useApplyRetainerMutation__
 *
 * To run a mutation, you first call `useApplyRetainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyRetainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyRetainerMutation, { data, loading, error }] = useApplyRetainerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyRetainerMutation(baseOptions?: Apollo.MutationHookOptions<ApplyRetainerMutation, ApplyRetainerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyRetainerMutation, ApplyRetainerMutationVariables>(ApplyRetainerDocument, options);
      }
export type ApplyRetainerMutationHookResult = ReturnType<typeof useApplyRetainerMutation>;
export type ApplyRetainerMutationResult = Apollo.MutationResult<ApplyRetainerMutation>;
export type ApplyRetainerMutationOptions = Apollo.BaseMutationOptions<ApplyRetainerMutation, ApplyRetainerMutationVariables>;
export const InvoiceAddExpenseDocument = gql`
    mutation InvoiceAddExpense($input: InvoiceBuilderExpensesAddInput!) {
  invoiceBuilder {
    addExpense(input: $input) {
      payload {
        ...ExpensesMutationPayload
      }
    }
  }
}
    ${ExpensesMutationPayloadFragmentDoc}`;
export type InvoiceAddExpenseMutationFn = Apollo.MutationFunction<InvoiceAddExpenseMutation, InvoiceAddExpenseMutationVariables>;

/**
 * __useInvoiceAddExpenseMutation__
 *
 * To run a mutation, you first call `useInvoiceAddExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceAddExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceAddExpenseMutation, { data, loading, error }] = useInvoiceAddExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceAddExpenseMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceAddExpenseMutation, InvoiceAddExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceAddExpenseMutation, InvoiceAddExpenseMutationVariables>(InvoiceAddExpenseDocument, options);
      }
export type InvoiceAddExpenseMutationHookResult = ReturnType<typeof useInvoiceAddExpenseMutation>;
export type InvoiceAddExpenseMutationResult = Apollo.MutationResult<InvoiceAddExpenseMutation>;
export type InvoiceAddExpenseMutationOptions = Apollo.BaseMutationOptions<InvoiceAddExpenseMutation, InvoiceAddExpenseMutationVariables>;
export const InvoiceRemoveExpenseDocument = gql`
    mutation InvoiceRemoveExpense($input: InvoiceBuilderExpensesDeleteInput!) {
  invoiceBuilder {
    deleteExpense(input: $input) {
      payload {
        ...ExpensesMutationPayload
      }
    }
  }
}
    ${ExpensesMutationPayloadFragmentDoc}`;
export type InvoiceRemoveExpenseMutationFn = Apollo.MutationFunction<InvoiceRemoveExpenseMutation, InvoiceRemoveExpenseMutationVariables>;

/**
 * __useInvoiceRemoveExpenseMutation__
 *
 * To run a mutation, you first call `useInvoiceRemoveExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRemoveExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceRemoveExpenseMutation, { data, loading, error }] = useInvoiceRemoveExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceRemoveExpenseMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceRemoveExpenseMutation, InvoiceRemoveExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceRemoveExpenseMutation, InvoiceRemoveExpenseMutationVariables>(InvoiceRemoveExpenseDocument, options);
      }
export type InvoiceRemoveExpenseMutationHookResult = ReturnType<typeof useInvoiceRemoveExpenseMutation>;
export type InvoiceRemoveExpenseMutationResult = Apollo.MutationResult<InvoiceRemoveExpenseMutation>;
export type InvoiceRemoveExpenseMutationOptions = Apollo.BaseMutationOptions<InvoiceRemoveExpenseMutation, InvoiceRemoveExpenseMutationVariables>;
export const InvoiceAddAllExpensesDocument = gql`
    mutation InvoiceAddAllExpenses($input: InvoiceBuilderExpensesAddAllInput!) {
  invoiceBuilder {
    addAllExpenses(input: $input) {
      payload {
        ...ExpensesMutationPayload
      }
    }
  }
}
    ${ExpensesMutationPayloadFragmentDoc}`;
export type InvoiceAddAllExpensesMutationFn = Apollo.MutationFunction<InvoiceAddAllExpensesMutation, InvoiceAddAllExpensesMutationVariables>;

/**
 * __useInvoiceAddAllExpensesMutation__
 *
 * To run a mutation, you first call `useInvoiceAddAllExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceAddAllExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceAddAllExpensesMutation, { data, loading, error }] = useInvoiceAddAllExpensesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceAddAllExpensesMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceAddAllExpensesMutation, InvoiceAddAllExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceAddAllExpensesMutation, InvoiceAddAllExpensesMutationVariables>(InvoiceAddAllExpensesDocument, options);
      }
export type InvoiceAddAllExpensesMutationHookResult = ReturnType<typeof useInvoiceAddAllExpensesMutation>;
export type InvoiceAddAllExpensesMutationResult = Apollo.MutationResult<InvoiceAddAllExpensesMutation>;
export type InvoiceAddAllExpensesMutationOptions = Apollo.BaseMutationOptions<InvoiceAddAllExpensesMutation, InvoiceAddAllExpensesMutationVariables>;
export const UpdateHoursForRoleEntryDocument = gql`
    mutation UpdateHoursForRoleEntry($input: InvoiceBuilderUpdateHourlyServiceInput!) {
  invoiceBuilder {
    updateHourlyService(input: $input) {
      payload {
        hourlyServices {
          ...HourlyServicesEntriesFragment
        }
        totalDue
        totalDueWithoutAppliedRetainer
      }
    }
  }
}
    ${HourlyServicesEntriesFragmentFragmentDoc}`;
export type UpdateHoursForRoleEntryMutationFn = Apollo.MutationFunction<UpdateHoursForRoleEntryMutation, UpdateHoursForRoleEntryMutationVariables>;

/**
 * __useUpdateHoursForRoleEntryMutation__
 *
 * To run a mutation, you first call `useUpdateHoursForRoleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHoursForRoleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHoursForRoleEntryMutation, { data, loading, error }] = useUpdateHoursForRoleEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHoursForRoleEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHoursForRoleEntryMutation, UpdateHoursForRoleEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHoursForRoleEntryMutation, UpdateHoursForRoleEntryMutationVariables>(UpdateHoursForRoleEntryDocument, options);
      }
export type UpdateHoursForRoleEntryMutationHookResult = ReturnType<typeof useUpdateHoursForRoleEntryMutation>;
export type UpdateHoursForRoleEntryMutationResult = Apollo.MutationResult<UpdateHoursForRoleEntryMutation>;
export type UpdateHoursForRoleEntryMutationOptions = Apollo.BaseMutationOptions<UpdateHoursForRoleEntryMutation, UpdateHoursForRoleEntryMutationVariables>;
export const InvoiceBuilderChangeInvoiceVersionDocument = gql`
    mutation InvoiceBuilderChangeInvoiceVersion($input: InvoiceChangeVersionInput!) {
  changeInvoiceVersion(input: $input) {
    invoice {
      invoiceVersion
    }
  }
}
    `;
export type InvoiceBuilderChangeInvoiceVersionMutationFn = Apollo.MutationFunction<InvoiceBuilderChangeInvoiceVersionMutation, InvoiceBuilderChangeInvoiceVersionMutationVariables>;

/**
 * __useInvoiceBuilderChangeInvoiceVersionMutation__
 *
 * To run a mutation, you first call `useInvoiceBuilderChangeInvoiceVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceBuilderChangeInvoiceVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceBuilderChangeInvoiceVersionMutation, { data, loading, error }] = useInvoiceBuilderChangeInvoiceVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceBuilderChangeInvoiceVersionMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceBuilderChangeInvoiceVersionMutation, InvoiceBuilderChangeInvoiceVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceBuilderChangeInvoiceVersionMutation, InvoiceBuilderChangeInvoiceVersionMutationVariables>(InvoiceBuilderChangeInvoiceVersionDocument, options);
      }
export type InvoiceBuilderChangeInvoiceVersionMutationHookResult = ReturnType<typeof useInvoiceBuilderChangeInvoiceVersionMutation>;
export type InvoiceBuilderChangeInvoiceVersionMutationResult = Apollo.MutationResult<InvoiceBuilderChangeInvoiceVersionMutation>;
export type InvoiceBuilderChangeInvoiceVersionMutationOptions = Apollo.BaseMutationOptions<InvoiceBuilderChangeInvoiceVersionMutation, InvoiceBuilderChangeInvoiceVersionMutationVariables>;
export const AddTaxItemDocument = gql`
    mutation AddTaxItem($input: InvoiceBuilderTaxItemsCreateInput!) {
  invoiceBuilder {
    createTaxItem(input: $input) {
      payload {
        taxes {
          ...TaxEntryPayload
        }
      }
    }
  }
}
    ${TaxEntryPayloadFragmentDoc}`;
export type AddTaxItemMutationFn = Apollo.MutationFunction<AddTaxItemMutation, AddTaxItemMutationVariables>;

/**
 * __useAddTaxItemMutation__
 *
 * To run a mutation, you first call `useAddTaxItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaxItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaxItemMutation, { data, loading, error }] = useAddTaxItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaxItemMutation(baseOptions?: Apollo.MutationHookOptions<AddTaxItemMutation, AddTaxItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTaxItemMutation, AddTaxItemMutationVariables>(AddTaxItemDocument, options);
      }
export type AddTaxItemMutationHookResult = ReturnType<typeof useAddTaxItemMutation>;
export type AddTaxItemMutationResult = Apollo.MutationResult<AddTaxItemMutation>;
export type AddTaxItemMutationOptions = Apollo.BaseMutationOptions<AddTaxItemMutation, AddTaxItemMutationVariables>;
export const DeleteTaxItemDocument = gql`
    mutation DeleteTaxItem($input: InvoiceBuilderTaxItemsDeleteInput!) {
  invoiceBuilder {
    deleteTaxItem(input: $input) {
      payload {
        taxes {
          ...TaxEntryPayload
        }
      }
    }
  }
}
    ${TaxEntryPayloadFragmentDoc}`;
export type DeleteTaxItemMutationFn = Apollo.MutationFunction<DeleteTaxItemMutation, DeleteTaxItemMutationVariables>;

/**
 * __useDeleteTaxItemMutation__
 *
 * To run a mutation, you first call `useDeleteTaxItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxItemMutation, { data, loading, error }] = useDeleteTaxItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaxItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxItemMutation, DeleteTaxItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxItemMutation, DeleteTaxItemMutationVariables>(DeleteTaxItemDocument, options);
      }
export type DeleteTaxItemMutationHookResult = ReturnType<typeof useDeleteTaxItemMutation>;
export type DeleteTaxItemMutationResult = Apollo.MutationResult<DeleteTaxItemMutation>;
export type DeleteTaxItemMutationOptions = Apollo.BaseMutationOptions<DeleteTaxItemMutation, DeleteTaxItemMutationVariables>;
export const UpdateTaxItemDocument = gql`
    mutation UpdateTaxItem($input: InvoiceBuilderTaxItemsUpdateInput!) {
  invoiceBuilder {
    updateTaxItem(input: $input) {
      payload {
        taxes {
          ...TaxEntryPayload
        }
      }
    }
  }
}
    ${TaxEntryPayloadFragmentDoc}`;
export type UpdateTaxItemMutationFn = Apollo.MutationFunction<UpdateTaxItemMutation, UpdateTaxItemMutationVariables>;

/**
 * __useUpdateTaxItemMutation__
 *
 * To run a mutation, you first call `useUpdateTaxItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxItemMutation, { data, loading, error }] = useUpdateTaxItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxItemMutation, UpdateTaxItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxItemMutation, UpdateTaxItemMutationVariables>(UpdateTaxItemDocument, options);
      }
export type UpdateTaxItemMutationHookResult = ReturnType<typeof useUpdateTaxItemMutation>;
export type UpdateTaxItemMutationResult = Apollo.MutationResult<UpdateTaxItemMutation>;
export type UpdateTaxItemMutationOptions = Apollo.BaseMutationOptions<UpdateTaxItemMutation, UpdateTaxItemMutationVariables>;
export const InvoiceDesignerDocument = gql`
    query InvoiceDesigner($slug: String!, $previewMode: Boolean) {
  invoiceBuilder(slug: $slug, previewMode: $previewMode) {
    loadingTotals @client
    invoice {
      ...InvoiceFragment
    }
    fixedFeeServices {
      ...FixedFeeServicesEntriesFragment
    }
    hourlyServices {
      ...HourlyServicesEntriesFragment
    }
    expenses {
      included {
        ...ExpenseFragment
      }
      reported {
        ...ExpenseFragment
      }
    }
    phaseConsultants {
      entries {
        ...PhaseConsultantsFragment
      }
      ...PhaseConsultantsTotalFragment
    }
    projectConsultants {
      ...ProjectConsultantsFragment
    }
    otherItems {
      ...OtherItemEntriesFragment
    }
    retainerItems {
      ...RetainerItemEntriesFragment
    }
    appliedRetainer {
      ...AppliedRetainerItemFragment
    }
    retainerSummary {
      ...RetainerSummaryFragment
    }
    taxes {
      ...TaxEntryPayload
    }
    timelogDetails {
      ...InvoiceTimelogDetails
    }
    outstandingInvoices {
      ...OutstandingInvoicesFragment
    }
    ...TabsTotalsFragment
    totalDue
    totalDueWithoutAppliedRetainer
    convenienceFee
    totalDueWithConvenienceFee
    subtotal
  }
}
    ${InvoiceFragmentFragmentDoc}
${FixedFeeServicesEntriesFragmentFragmentDoc}
${HourlyServicesEntriesFragmentFragmentDoc}
${ExpenseFragmentFragmentDoc}
${PhaseConsultantsFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}
${ProjectConsultantsFragmentFragmentDoc}
${OtherItemEntriesFragmentFragmentDoc}
${RetainerItemEntriesFragmentFragmentDoc}
${AppliedRetainerItemFragmentFragmentDoc}
${RetainerSummaryFragmentFragmentDoc}
${TaxEntryPayloadFragmentDoc}
${InvoiceTimelogDetailsFragmentDoc}
${OutstandingInvoicesFragmentFragmentDoc}
${TabsTotalsFragmentFragmentDoc}`;

/**
 * __useInvoiceDesignerQuery__
 *
 * To run a query within a React component, call `useInvoiceDesignerQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDesignerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceDesignerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      previewMode: // value for 'previewMode'
 *   },
 * });
 */
export function useInvoiceDesignerQuery(baseOptions: Apollo.QueryHookOptions<InvoiceDesignerQuery, InvoiceDesignerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceDesignerQuery, InvoiceDesignerQueryVariables>(InvoiceDesignerDocument, options);
      }
export function useInvoiceDesignerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceDesignerQuery, InvoiceDesignerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceDesignerQuery, InvoiceDesignerQueryVariables>(InvoiceDesignerDocument, options);
        }
export type InvoiceDesignerQueryHookResult = ReturnType<typeof useInvoiceDesignerQuery>;
export type InvoiceDesignerLazyQueryHookResult = ReturnType<typeof useInvoiceDesignerLazyQuery>;
export type InvoiceDesignerQueryResult = Apollo.QueryResult<InvoiceDesignerQuery, InvoiceDesignerQueryVariables>;
export const BillingDestinationCustomerListDocument = gql`
    query BillingDestinationCustomerList($id: ID!) {
  quickbooksCustomer(id: $id) {
    ...BillingDestinationCustomer
    descendants {
      ...BillingDestinationCustomer
    }
  }
}
    ${BillingDestinationCustomerFragmentDoc}`;

/**
 * __useBillingDestinationCustomerListQuery__
 *
 * To run a query within a React component, call `useBillingDestinationCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDestinationCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDestinationCustomerListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingDestinationCustomerListQuery(baseOptions: Apollo.QueryHookOptions<BillingDestinationCustomerListQuery, BillingDestinationCustomerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingDestinationCustomerListQuery, BillingDestinationCustomerListQueryVariables>(BillingDestinationCustomerListDocument, options);
      }
export function useBillingDestinationCustomerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingDestinationCustomerListQuery, BillingDestinationCustomerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingDestinationCustomerListQuery, BillingDestinationCustomerListQueryVariables>(BillingDestinationCustomerListDocument, options);
        }
export type BillingDestinationCustomerListQueryHookResult = ReturnType<typeof useBillingDestinationCustomerListQuery>;
export type BillingDestinationCustomerListLazyQueryHookResult = ReturnType<typeof useBillingDestinationCustomerListLazyQuery>;
export type BillingDestinationCustomerListQueryResult = Apollo.QueryResult<BillingDestinationCustomerListQuery, BillingDestinationCustomerListQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($slug: String) {
  invoice(slug: $slug) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoicePaymentMethodsDocument = gql`
    query InvoicePaymentMethods($slug: String) {
  invoice(slug: $slug) {
    id
    ...InvoicePaymentMethodsFragment
  }
}
    ${InvoicePaymentMethodsFragmentFragmentDoc}`;

/**
 * __useInvoicePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useInvoicePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePaymentMethodsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoicePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<InvoicePaymentMethodsQuery, InvoicePaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicePaymentMethodsQuery, InvoicePaymentMethodsQueryVariables>(InvoicePaymentMethodsDocument, options);
      }
export function useInvoicePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicePaymentMethodsQuery, InvoicePaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicePaymentMethodsQuery, InvoicePaymentMethodsQueryVariables>(InvoicePaymentMethodsDocument, options);
        }
export type InvoicePaymentMethodsQueryHookResult = ReturnType<typeof useInvoicePaymentMethodsQuery>;
export type InvoicePaymentMethodsLazyQueryHookResult = ReturnType<typeof useInvoicePaymentMethodsLazyQuery>;
export type InvoicePaymentMethodsQueryResult = Apollo.QueryResult<InvoicePaymentMethodsQuery, InvoicePaymentMethodsQueryVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($data: InvoiceUpdateInput!) {
  updateInvoice(input: $data) {
    invoice {
      ...InvoiceBasicInfoFragment
      client {
        ...InvoiceClientFragment
      }
    }
  }
}
    ${InvoiceBasicInfoFragmentFragmentDoc}
${InvoiceClientFragmentFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const UpdateInvoicePaymentMethodsDocument = gql`
    mutation UpdateInvoicePaymentMethods($data: InvoiceUpdateInput!) {
  updateInvoice(input: $data) {
    invoice {
      id
      ...InvoicePaymentMethodsFragment
    }
  }
}
    ${InvoicePaymentMethodsFragmentFragmentDoc}`;
export type UpdateInvoicePaymentMethodsMutationFn = Apollo.MutationFunction<UpdateInvoicePaymentMethodsMutation, UpdateInvoicePaymentMethodsMutationVariables>;

/**
 * __useUpdateInvoicePaymentMethodsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoicePaymentMethodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoicePaymentMethodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoicePaymentMethodsMutation, { data, loading, error }] = useUpdateInvoicePaymentMethodsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoicePaymentMethodsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoicePaymentMethodsMutation, UpdateInvoicePaymentMethodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoicePaymentMethodsMutation, UpdateInvoicePaymentMethodsMutationVariables>(UpdateInvoicePaymentMethodsDocument, options);
      }
export type UpdateInvoicePaymentMethodsMutationHookResult = ReturnType<typeof useUpdateInvoicePaymentMethodsMutation>;
export type UpdateInvoicePaymentMethodsMutationResult = Apollo.MutationResult<UpdateInvoicePaymentMethodsMutation>;
export type UpdateInvoicePaymentMethodsMutationOptions = Apollo.BaseMutationOptions<UpdateInvoicePaymentMethodsMutation, UpdateInvoicePaymentMethodsMutationVariables>;
export const SendToQboDocument = gql`
    mutation SendToQBO($data: InvoiceSendToQuickbooksInput!) {
  sendInvoiceToQuickbooks(input: $data) {
    invoice {
      id
      lastSyncedToQuickbooksAt
      organization {
        id
        connectedToQbo
      }
    }
  }
}
    `;
export type SendToQboMutationFn = Apollo.MutationFunction<SendToQboMutation, SendToQboMutationVariables>;

/**
 * __useSendToQboMutation__
 *
 * To run a mutation, you first call `useSendToQboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToQboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToQboMutation, { data, loading, error }] = useSendToQboMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendToQboMutation(baseOptions?: Apollo.MutationHookOptions<SendToQboMutation, SendToQboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendToQboMutation, SendToQboMutationVariables>(SendToQboDocument, options);
      }
export type SendToQboMutationHookResult = ReturnType<typeof useSendToQboMutation>;
export type SendToQboMutationResult = Apollo.MutationResult<SendToQboMutation>;
export type SendToQboMutationOptions = Apollo.BaseMutationOptions<SendToQboMutation, SendToQboMutationVariables>;
export const BatchSendToQboDocument = gql`
    mutation BatchSendToQBO($data: InvoiceBatchSendToQuickbooksInput!) {
  batchSendInvoiceToQuickbooks(input: $data) {
    enqueued
  }
}
    `;
export type BatchSendToQboMutationFn = Apollo.MutationFunction<BatchSendToQboMutation, BatchSendToQboMutationVariables>;

/**
 * __useBatchSendToQboMutation__
 *
 * To run a mutation, you first call `useBatchSendToQboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSendToQboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSendToQboMutation, { data, loading, error }] = useBatchSendToQboMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBatchSendToQboMutation(baseOptions?: Apollo.MutationHookOptions<BatchSendToQboMutation, BatchSendToQboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchSendToQboMutation, BatchSendToQboMutationVariables>(BatchSendToQboDocument, options);
      }
export type BatchSendToQboMutationHookResult = ReturnType<typeof useBatchSendToQboMutation>;
export type BatchSendToQboMutationResult = Apollo.MutationResult<BatchSendToQboMutation>;
export type BatchSendToQboMutationOptions = Apollo.BaseMutationOptions<BatchSendToQboMutation, BatchSendToQboMutationVariables>;
export const XeroExportDocument = gql`
    mutation XeroExport($input: XeroInput!) {
  startExport {
    invoiceXero(input: $input) {
      id
    }
  }
}
    `;
export type XeroExportMutationFn = Apollo.MutationFunction<XeroExportMutation, XeroExportMutationVariables>;

/**
 * __useXeroExportMutation__
 *
 * To run a mutation, you first call `useXeroExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useXeroExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [xeroExportMutation, { data, loading, error }] = useXeroExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useXeroExportMutation(baseOptions?: Apollo.MutationHookOptions<XeroExportMutation, XeroExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<XeroExportMutation, XeroExportMutationVariables>(XeroExportDocument, options);
      }
export type XeroExportMutationHookResult = ReturnType<typeof useXeroExportMutation>;
export type XeroExportMutationResult = Apollo.MutationResult<XeroExportMutation>;
export type XeroExportMutationOptions = Apollo.BaseMutationOptions<XeroExportMutation, XeroExportMutationVariables>;
export const QboExportDocument = gql`
    mutation QboExport($input: QBOInput!) {
  startExport {
    invoiceQbo(input: $input) {
      id
    }
  }
}
    `;
export type QboExportMutationFn = Apollo.MutationFunction<QboExportMutation, QboExportMutationVariables>;

/**
 * __useQboExportMutation__
 *
 * To run a mutation, you first call `useQboExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQboExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qboExportMutation, { data, loading, error }] = useQboExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQboExportMutation(baseOptions?: Apollo.MutationHookOptions<QboExportMutation, QboExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QboExportMutation, QboExportMutationVariables>(QboExportDocument, options);
      }
export type QboExportMutationHookResult = ReturnType<typeof useQboExportMutation>;
export type QboExportMutationResult = Apollo.MutationResult<QboExportMutation>;
export type QboExportMutationOptions = Apollo.BaseMutationOptions<QboExportMutation, QboExportMutationVariables>;
export const UpdateDisplayOptionsDocument = gql`
    mutation UpdateDisplayOptions($input: InvoiceUpdateDisplayOptionsInput!) {
  updateInvoiceDisplayOptions(input: $input) {
    payload {
      invoice {
        ...InvoiceWithDisplayOptionsFragment
      }
    }
  }
}
    ${InvoiceWithDisplayOptionsFragmentFragmentDoc}`;
export type UpdateDisplayOptionsMutationFn = Apollo.MutationFunction<UpdateDisplayOptionsMutation, UpdateDisplayOptionsMutationVariables>;

/**
 * __useUpdateDisplayOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayOptionsMutation, { data, loading, error }] = useUpdateDisplayOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDisplayOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisplayOptionsMutation, UpdateDisplayOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisplayOptionsMutation, UpdateDisplayOptionsMutationVariables>(UpdateDisplayOptionsDocument, options);
      }
export type UpdateDisplayOptionsMutationHookResult = ReturnType<typeof useUpdateDisplayOptionsMutation>;
export type UpdateDisplayOptionsMutationResult = Apollo.MutationResult<UpdateDisplayOptionsMutation>;
export type UpdateDisplayOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateDisplayOptionsMutation, UpdateDisplayOptionsMutationVariables>;
export const UpdateInvoiceInternalNoteDocument = gql`
    mutation UpdateInvoiceInternalNote($input: InvoiceUpdateInternalNoteInput!) {
  updateInvoiceInternalNote(input: $input) {
    invoice {
      id
      internalNote
    }
  }
}
    `;
export type UpdateInvoiceInternalNoteMutationFn = Apollo.MutationFunction<UpdateInvoiceInternalNoteMutation, UpdateInvoiceInternalNoteMutationVariables>;

/**
 * __useUpdateInvoiceInternalNoteMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceInternalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceInternalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceInternalNoteMutation, { data, loading, error }] = useUpdateInvoiceInternalNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceInternalNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceInternalNoteMutation, UpdateInvoiceInternalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceInternalNoteMutation, UpdateInvoiceInternalNoteMutationVariables>(UpdateInvoiceInternalNoteDocument, options);
      }
export type UpdateInvoiceInternalNoteMutationHookResult = ReturnType<typeof useUpdateInvoiceInternalNoteMutation>;
export type UpdateInvoiceInternalNoteMutationResult = Apollo.MutationResult<UpdateInvoiceInternalNoteMutation>;
export type UpdateInvoiceInternalNoteMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceInternalNoteMutation, UpdateInvoiceInternalNoteMutationVariables>;
export const UpdateInvoiceBillingDestinationDocument = gql`
    mutation UpdateInvoiceBillingDestination($input: InvoiceUpdateBillingDestinationInput!) {
  updateInvoiceBillingDestination(input: $input) {
    invoice {
      id
      billingDestination
      invoiceQboDetails {
        id
        accountingDestinationCustomer {
          ...BillingDestinationCustomer
        }
      }
    }
  }
}
    ${BillingDestinationCustomerFragmentDoc}`;
export type UpdateInvoiceBillingDestinationMutationFn = Apollo.MutationFunction<UpdateInvoiceBillingDestinationMutation, UpdateInvoiceBillingDestinationMutationVariables>;

/**
 * __useUpdateInvoiceBillingDestinationMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceBillingDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceBillingDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceBillingDestinationMutation, { data, loading, error }] = useUpdateInvoiceBillingDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceBillingDestinationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceBillingDestinationMutation, UpdateInvoiceBillingDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceBillingDestinationMutation, UpdateInvoiceBillingDestinationMutationVariables>(UpdateInvoiceBillingDestinationDocument, options);
      }
export type UpdateInvoiceBillingDestinationMutationHookResult = ReturnType<typeof useUpdateInvoiceBillingDestinationMutation>;
export type UpdateInvoiceBillingDestinationMutationResult = Apollo.MutationResult<UpdateInvoiceBillingDestinationMutation>;
export type UpdateInvoiceBillingDestinationMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceBillingDestinationMutation, UpdateInvoiceBillingDestinationMutationVariables>;
export const BatchInvoicesToQboJobDocument = gql`
    subscription BatchInvoicesToQboJob {
  batchInvoicesToQboJob {
    result {
      status
      error
      itemFailures {
        ...ItemFailures
      }
    }
  }
}
    ${ItemFailuresFragmentDoc}`;

/**
 * __useBatchInvoicesToQboJobSubscription__
 *
 * To run a query within a React component, call `useBatchInvoicesToQboJobSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBatchInvoicesToQboJobSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchInvoicesToQboJobSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBatchInvoicesToQboJobSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BatchInvoicesToQboJobSubscription, BatchInvoicesToQboJobSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BatchInvoicesToQboJobSubscription, BatchInvoicesToQboJobSubscriptionVariables>(BatchInvoicesToQboJobDocument, options);
      }
export type BatchInvoicesToQboJobSubscriptionHookResult = ReturnType<typeof useBatchInvoicesToQboJobSubscription>;
export type BatchInvoicesToQboJobSubscriptionResult = Apollo.SubscriptionResult<BatchInvoicesToQboJobSubscription>;
export const PublicInvoicePaymentDetailsDocument = gql`
    query PublicInvoicePaymentDetails($paymentUuid: String!) {
  publicInvoice(paymentUuid: $paymentUuid) {
    invoice {
      ...PublicInvoiceOrganizationDetails
    }
  }
}
    ${PublicInvoiceOrganizationDetailsFragmentDoc}`;

/**
 * __usePublicInvoicePaymentDetailsQuery__
 *
 * To run a query within a React component, call `usePublicInvoicePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicInvoicePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicInvoicePaymentDetailsQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function usePublicInvoicePaymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<PublicInvoicePaymentDetailsQuery, PublicInvoicePaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicInvoicePaymentDetailsQuery, PublicInvoicePaymentDetailsQueryVariables>(PublicInvoicePaymentDetailsDocument, options);
      }
export function usePublicInvoicePaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicInvoicePaymentDetailsQuery, PublicInvoicePaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicInvoicePaymentDetailsQuery, PublicInvoicePaymentDetailsQueryVariables>(PublicInvoicePaymentDetailsDocument, options);
        }
export type PublicInvoicePaymentDetailsQueryHookResult = ReturnType<typeof usePublicInvoicePaymentDetailsQuery>;
export type PublicInvoicePaymentDetailsLazyQueryHookResult = ReturnType<typeof usePublicInvoicePaymentDetailsLazyQuery>;
export type PublicInvoicePaymentDetailsQueryResult = Apollo.QueryResult<PublicInvoicePaymentDetailsQuery, PublicInvoicePaymentDetailsQueryVariables>;
export const PublicInvoiceDocument = gql`
    query PublicInvoice($paymentUuid: String!) {
  publicInvoice(paymentUuid: $paymentUuid) {
    invoice {
      ...PublicInvoiceFragment
    }
    fixedFeeServices {
      entries {
        ...PublicFixedFeeEntryFragment
      }
      groupedPhaseEntries {
        fixedFeeServiceEntry {
          ...FixedFeeEntryFragment
        }
        consultantEntries {
          consultant {
            consultantTemplate {
              id
              name
            }
          }
          currentDue
          currentCompletePercent
          planned
          id
          previouslyBilled {
            amount
            percent
          }
        }
        phase {
          id
          name
        }
        totals {
          currentCompletePercent
          planned
          previouslyBilled {
            amount
            percent
          }
          currentDue
        }
      }
      ...InvoiceBuilderPhaseTotalsFragment
      totals {
        currentDue
        unbilled
      }
    }
    hourlyServices {
      entries {
        ...HourlyServicesEntryFragment
        roleEntries {
          id
          hours
          previouslyBilledHours
          previouslyBilledAmount
          roleRate
          amount
          logged {
            total
            duringInvoiceRange
            beforeInvoiceBeginDate
          }
          role {
            id
            hours
            name
          }
        }
        consultantEntries {
          ...HourlyServicesConsultantEntriesFragment
        }
      }
      totals {
        ...InvoiceTotalFragment
      }
    }
    expenses {
      included {
        ...ExpenseFragment
      }
      reported {
        ...ExpenseFragment
      }
    }
    projectConsultants {
      ...ProjectConsultantsFragment
    }
    phaseConsultants {
      entries {
        ...PhaseConsultantsFragment
      }
      ...PhaseConsultantsTotalFragment
    }
    otherItems {
      ...OtherItemEntriesFragment
    }
    retainerItems {
      ...RetainerItemEntriesFragment
    }
    appliedRetainer {
      ...AppliedRetainerItemFragment
    }
    retainerSummary {
      ...RetainerSummaryFragment
    }
    taxes {
      ...TaxEntryPayload
    }
    timelogDetails {
      ...InvoiceTimelogDetails
    }
    outstandingInvoices {
      ...OutstandingInvoicesFragment
    }
    ...TabsTotalsFragment
    totalDue
    totalDueWithoutAppliedRetainer
    convenienceFee
    totalDueWithConvenienceFee
    subtotal
  }
}
    ${PublicInvoiceFragmentFragmentDoc}
${PublicFixedFeeEntryFragmentFragmentDoc}
${FixedFeeEntryFragmentFragmentDoc}
${InvoiceBuilderPhaseTotalsFragmentFragmentDoc}
${HourlyServicesEntryFragmentFragmentDoc}
${HourlyServicesConsultantEntriesFragmentFragmentDoc}
${InvoiceTotalFragmentFragmentDoc}
${ExpenseFragmentFragmentDoc}
${ProjectConsultantsFragmentFragmentDoc}
${PhaseConsultantsFragmentFragmentDoc}
${PhaseConsultantsTotalFragmentFragmentDoc}
${OtherItemEntriesFragmentFragmentDoc}
${RetainerItemEntriesFragmentFragmentDoc}
${AppliedRetainerItemFragmentFragmentDoc}
${RetainerSummaryFragmentFragmentDoc}
${TaxEntryPayloadFragmentDoc}
${InvoiceTimelogDetailsFragmentDoc}
${OutstandingInvoicesFragmentFragmentDoc}
${TabsTotalsFragmentFragmentDoc}`;

/**
 * __usePublicInvoiceQuery__
 *
 * To run a query within a React component, call `usePublicInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicInvoiceQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function usePublicInvoiceQuery(baseOptions: Apollo.QueryHookOptions<PublicInvoiceQuery, PublicInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicInvoiceQuery, PublicInvoiceQueryVariables>(PublicInvoiceDocument, options);
      }
export function usePublicInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicInvoiceQuery, PublicInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicInvoiceQuery, PublicInvoiceQueryVariables>(PublicInvoiceDocument, options);
        }
export type PublicInvoiceQueryHookResult = ReturnType<typeof usePublicInvoiceQuery>;
export type PublicInvoiceLazyQueryHookResult = ReturnType<typeof usePublicInvoiceLazyQuery>;
export type PublicInvoiceQueryResult = Apollo.QueryResult<PublicInvoiceQuery, PublicInvoiceQueryVariables>;
export const SendInvoicePaymentLinkDocument = gql`
    mutation SendInvoicePaymentLink($input: InvoiceSendPaymentLinkInput!) {
  sendInvoicePaymentLink(input: $input) {
    invoice {
      id
      ...InvoiceActivitiesFragment
      project {
        paymentEmailSettings {
          customBody
          defaultBodyWithPayment
          defaultBodyWithoutPayment
        }
      }
    }
  }
}
    ${InvoiceActivitiesFragmentFragmentDoc}`;
export type SendInvoicePaymentLinkMutationFn = Apollo.MutationFunction<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>;

/**
 * __useSendInvoicePaymentLinkMutation__
 *
 * To run a mutation, you first call `useSendInvoicePaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoicePaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoicePaymentLinkMutation, { data, loading, error }] = useSendInvoicePaymentLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvoicePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>(SendInvoicePaymentLinkDocument, options);
      }
export type SendInvoicePaymentLinkMutationHookResult = ReturnType<typeof useSendInvoicePaymentLinkMutation>;
export type SendInvoicePaymentLinkMutationResult = Apollo.MutationResult<SendInvoicePaymentLinkMutation>;
export type SendInvoicePaymentLinkMutationOptions = Apollo.BaseMutationOptions<SendInvoicePaymentLinkMutation, SendInvoicePaymentLinkMutationVariables>;
export const SendInvoiceReminderEmailDocument = gql`
    mutation SendInvoiceReminderEmail($input: InvoiceSendReminderEmailInput!) {
  sendInvoiceReminderEmail(input: $input) {
    invoice {
      id
      ...InvoiceActivitiesFragment
    }
  }
}
    ${InvoiceActivitiesFragmentFragmentDoc}`;
export type SendInvoiceReminderEmailMutationFn = Apollo.MutationFunction<SendInvoiceReminderEmailMutation, SendInvoiceReminderEmailMutationVariables>;

/**
 * __useSendInvoiceReminderEmailMutation__
 *
 * To run a mutation, you first call `useSendInvoiceReminderEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceReminderEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceReminderEmailMutation, { data, loading, error }] = useSendInvoiceReminderEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvoiceReminderEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceReminderEmailMutation, SendInvoiceReminderEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceReminderEmailMutation, SendInvoiceReminderEmailMutationVariables>(SendInvoiceReminderEmailDocument, options);
      }
export type SendInvoiceReminderEmailMutationHookResult = ReturnType<typeof useSendInvoiceReminderEmailMutation>;
export type SendInvoiceReminderEmailMutationResult = Apollo.MutationResult<SendInvoiceReminderEmailMutation>;
export type SendInvoiceReminderEmailMutationOptions = Apollo.BaseMutationOptions<SendInvoiceReminderEmailMutation, SendInvoiceReminderEmailMutationVariables>;
export const ViewInvoiceDocument = gql`
    mutation ViewInvoice($input: InvoiceViewInput!) {
  viewInvoice(input: $input) {
    invoice {
      id
    }
  }
}
    `;
export type ViewInvoiceMutationFn = Apollo.MutationFunction<ViewInvoiceMutation, ViewInvoiceMutationVariables>;

/**
 * __useViewInvoiceMutation__
 *
 * To run a mutation, you first call `useViewInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewInvoiceMutation, { data, loading, error }] = useViewInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ViewInvoiceMutation, ViewInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewInvoiceMutation, ViewInvoiceMutationVariables>(ViewInvoiceDocument, options);
      }
export type ViewInvoiceMutationHookResult = ReturnType<typeof useViewInvoiceMutation>;
export type ViewInvoiceMutationResult = Apollo.MutationResult<ViewInvoiceMutation>;
export type ViewInvoiceMutationOptions = Apollo.BaseMutationOptions<ViewInvoiceMutation, ViewInvoiceMutationVariables>;
export const ConfigureInvoiceReminderDocument = gql`
    mutation ConfigureInvoiceReminder($slug: String!, $sendOnDueDate: Boolean, $daysBeforeDueDate: Int, $daysAfterDueDate: Int, $emailAddresses: [String!], $emailAddressesCc: [String!]) {
  configureInvoiceReminder(
    input: {slug: $slug, sendOnDueDate: $sendOnDueDate, daysBeforeDueDate: $daysBeforeDueDate, daysAfterDueDate: $daysAfterDueDate, emailAddresses: $emailAddresses, emailAddressesCc: $emailAddressesCc}
  ) {
    invoice {
      id
    }
  }
}
    `;
export type ConfigureInvoiceReminderMutationFn = Apollo.MutationFunction<ConfigureInvoiceReminderMutation, ConfigureInvoiceReminderMutationVariables>;

/**
 * __useConfigureInvoiceReminderMutation__
 *
 * To run a mutation, you first call `useConfigureInvoiceReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureInvoiceReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureInvoiceReminderMutation, { data, loading, error }] = useConfigureInvoiceReminderMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      sendOnDueDate: // value for 'sendOnDueDate'
 *      daysBeforeDueDate: // value for 'daysBeforeDueDate'
 *      daysAfterDueDate: // value for 'daysAfterDueDate'
 *      emailAddresses: // value for 'emailAddresses'
 *      emailAddressesCc: // value for 'emailAddressesCc'
 *   },
 * });
 */
export function useConfigureInvoiceReminderMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureInvoiceReminderMutation, ConfigureInvoiceReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureInvoiceReminderMutation, ConfigureInvoiceReminderMutationVariables>(ConfigureInvoiceReminderDocument, options);
      }
export type ConfigureInvoiceReminderMutationHookResult = ReturnType<typeof useConfigureInvoiceReminderMutation>;
export type ConfigureInvoiceReminderMutationResult = Apollo.MutationResult<ConfigureInvoiceReminderMutation>;
export type ConfigureInvoiceReminderMutationOptions = Apollo.BaseMutationOptions<ConfigureInvoiceReminderMutation, ConfigureInvoiceReminderMutationVariables>;
export const DeleteInvoiceReminderDocument = gql`
    mutation DeleteInvoiceReminder($slug: String!) {
  deleteInvoiceReminder(input: {slug: $slug}) {
    invoice {
      id
    }
  }
}
    `;
export type DeleteInvoiceReminderMutationFn = Apollo.MutationFunction<DeleteInvoiceReminderMutation, DeleteInvoiceReminderMutationVariables>;

/**
 * __useDeleteInvoiceReminderMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceReminderMutation, { data, loading, error }] = useDeleteInvoiceReminderMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDeleteInvoiceReminderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceReminderMutation, DeleteInvoiceReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceReminderMutation, DeleteInvoiceReminderMutationVariables>(DeleteInvoiceReminderDocument, options);
      }
export type DeleteInvoiceReminderMutationHookResult = ReturnType<typeof useDeleteInvoiceReminderMutation>;
export type DeleteInvoiceReminderMutationResult = Apollo.MutationResult<DeleteInvoiceReminderMutation>;
export type DeleteInvoiceReminderMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceReminderMutation, DeleteInvoiceReminderMutationVariables>;
export const InvoiceRemindersSchedulerDocument = gql`
    query invoiceRemindersScheduler($slug: String!) {
  invoiceRemindersScheduler(slug: $slug) {
    ...InvoiceRemindersScheduler
  }
}
    ${InvoiceRemindersSchedulerFragmentDoc}`;

/**
 * __useInvoiceRemindersSchedulerQuery__
 *
 * To run a query within a React component, call `useInvoiceRemindersSchedulerQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRemindersSchedulerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceRemindersSchedulerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoiceRemindersSchedulerQuery(baseOptions: Apollo.QueryHookOptions<InvoiceRemindersSchedulerQuery, InvoiceRemindersSchedulerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceRemindersSchedulerQuery, InvoiceRemindersSchedulerQueryVariables>(InvoiceRemindersSchedulerDocument, options);
      }
export function useInvoiceRemindersSchedulerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceRemindersSchedulerQuery, InvoiceRemindersSchedulerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceRemindersSchedulerQuery, InvoiceRemindersSchedulerQueryVariables>(InvoiceRemindersSchedulerDocument, options);
        }
export type InvoiceRemindersSchedulerQueryHookResult = ReturnType<typeof useInvoiceRemindersSchedulerQuery>;
export type InvoiceRemindersSchedulerLazyQueryHookResult = ReturnType<typeof useInvoiceRemindersSchedulerLazyQuery>;
export type InvoiceRemindersSchedulerQueryResult = Apollo.QueryResult<InvoiceRemindersSchedulerQuery, InvoiceRemindersSchedulerQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($sortBy: InvoicesSortInput, $filters: InvoiceFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  invoices(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    totalBilled
    totalRemaining
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      total
      status
      issueDate
      dueDate
      slug
      number
      total
      totalPaid
      internalNote
      remainingBalance
      paidDate
      passConvenienceFeeCard
      passConvenienceFeeAch
      sentToQuickbooks
      internalNote
      paymentIntentStatus
      deliverTo
      deliverToCc
      ...CurrentActivityFragment
      ...InvoiceActivitiesFragment
      invoiceVersion
      invoicePayments {
        amount
        id
        paidDate
        isQboPayment
        applyConvenienceFee
      }
      client {
        displayName
        email
        name
      }
      lastSyncedToQuickbooksAt
      unassignedLineItemsCount
      organization {
        connectedToQbo
        isSubscribed
      }
      project {
        id
        color
        name
        number
        link
        v1InvoicesWithUnassignedLineItemsCount
        paymentEmailSettings {
          customBody
          defaultBodyWithPayment
          defaultBodyWithoutPayment
        }
      }
      invoicePayment {
        amount
        net
        applicationFee
        paymentType
        paymentCurrency
        applyConvenienceFee
      }
    }
  }
}
    ${CurrentActivityFragmentFragmentDoc}
${InvoiceActivitiesFragmentFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const QuickbooksBatchSendPermissionsDocument = gql`
    query QuickbooksBatchSendPermissions {
  currentProfile {
    organization {
      connectedToQbo
    }
    policy {
      permissions {
        canSendToQuickbooks
      }
    }
  }
}
    `;

/**
 * __useQuickbooksBatchSendPermissionsQuery__
 *
 * To run a query within a React component, call `useQuickbooksBatchSendPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksBatchSendPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksBatchSendPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksBatchSendPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksBatchSendPermissionsQuery, QuickbooksBatchSendPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksBatchSendPermissionsQuery, QuickbooksBatchSendPermissionsQueryVariables>(QuickbooksBatchSendPermissionsDocument, options);
      }
export function useQuickbooksBatchSendPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksBatchSendPermissionsQuery, QuickbooksBatchSendPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksBatchSendPermissionsQuery, QuickbooksBatchSendPermissionsQueryVariables>(QuickbooksBatchSendPermissionsDocument, options);
        }
export type QuickbooksBatchSendPermissionsQueryHookResult = ReturnType<typeof useQuickbooksBatchSendPermissionsQuery>;
export type QuickbooksBatchSendPermissionsLazyQueryHookResult = ReturnType<typeof useQuickbooksBatchSendPermissionsLazyQuery>;
export type QuickbooksBatchSendPermissionsQueryResult = Apollo.QueryResult<QuickbooksBatchSendPermissionsQuery, QuickbooksBatchSendPermissionsQueryVariables>;
export const InvoiceMarkAsOpenDocument = gql`
    mutation InvoiceMarkAsOpen($slug: String!) {
  markInvoiceAsOpen(input: {slug: $slug}) {
    invoice {
      id
      paidDate
      status
      paymentUrl
    }
  }
}
    `;
export type InvoiceMarkAsOpenMutationFn = Apollo.MutationFunction<InvoiceMarkAsOpenMutation, InvoiceMarkAsOpenMutationVariables>;

/**
 * __useInvoiceMarkAsOpenMutation__
 *
 * To run a mutation, you first call `useInvoiceMarkAsOpenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceMarkAsOpenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceMarkAsOpenMutation, { data, loading, error }] = useInvoiceMarkAsOpenMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoiceMarkAsOpenMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceMarkAsOpenMutation, InvoiceMarkAsOpenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceMarkAsOpenMutation, InvoiceMarkAsOpenMutationVariables>(InvoiceMarkAsOpenDocument, options);
      }
export type InvoiceMarkAsOpenMutationHookResult = ReturnType<typeof useInvoiceMarkAsOpenMutation>;
export type InvoiceMarkAsOpenMutationResult = Apollo.MutationResult<InvoiceMarkAsOpenMutation>;
export type InvoiceMarkAsOpenMutationOptions = Apollo.BaseMutationOptions<InvoiceMarkAsOpenMutation, InvoiceMarkAsOpenMutationVariables>;
export const InvoicesFiltersDocument = gql`
    query InvoicesFilters {
  projectsOptions {
    id
    name
  }
  allClients {
    displayName
    id
  }
  categories {
    id
    name
  }
  consultantCompanies {
    id
    name
  }
}
    `;

/**
 * __useInvoicesFiltersQuery__
 *
 * To run a query within a React component, call `useInvoicesFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesFiltersQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesFiltersQuery, InvoicesFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesFiltersQuery, InvoicesFiltersQueryVariables>(InvoicesFiltersDocument, options);
      }
export function useInvoicesFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesFiltersQuery, InvoicesFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesFiltersQuery, InvoicesFiltersQueryVariables>(InvoicesFiltersDocument, options);
        }
export type InvoicesFiltersQueryHookResult = ReturnType<typeof useInvoicesFiltersQuery>;
export type InvoicesFiltersLazyQueryHookResult = ReturnType<typeof useInvoicesFiltersLazyQuery>;
export type InvoicesFiltersQueryResult = Apollo.QueryResult<InvoicesFiltersQuery, InvoicesFiltersQueryVariables>;
export const ConsultantBillsFiltersDocument = gql`
    query ConsultantBillsFilters {
  projectsOptions {
    id
    name
  }
  consultantCompanies {
    id
    name
  }
}
    `;

/**
 * __useConsultantBillsFiltersQuery__
 *
 * To run a query within a React component, call `useConsultantBillsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantBillsFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsultantBillsFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantBillsFiltersQuery, ConsultantBillsFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantBillsFiltersQuery, ConsultantBillsFiltersQueryVariables>(ConsultantBillsFiltersDocument, options);
      }
export function useConsultantBillsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantBillsFiltersQuery, ConsultantBillsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantBillsFiltersQuery, ConsultantBillsFiltersQueryVariables>(ConsultantBillsFiltersDocument, options);
        }
export type ConsultantBillsFiltersQueryHookResult = ReturnType<typeof useConsultantBillsFiltersQuery>;
export type ConsultantBillsFiltersLazyQueryHookResult = ReturnType<typeof useConsultantBillsFiltersLazyQuery>;
export type ConsultantBillsFiltersQueryResult = Apollo.QueryResult<ConsultantBillsFiltersQuery, ConsultantBillsFiltersQueryVariables>;
export const LastInvoiceAutocompleteDataByProjectDocument = gql`
    query LastInvoiceAutocompleteDataByProject($projectId: ID!) {
  invoices(
    first: 1
    filters: {projectId: $projectId}
    sortBy: {field: ID, direction: DESC}
  ) {
    nodes {
      number
      beginDate
      endDate
    }
  }
}
    `;

/**
 * __useLastInvoiceAutocompleteDataByProjectQuery__
 *
 * To run a query within a React component, call `useLastInvoiceAutocompleteDataByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastInvoiceAutocompleteDataByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastInvoiceAutocompleteDataByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLastInvoiceAutocompleteDataByProjectQuery(baseOptions: Apollo.QueryHookOptions<LastInvoiceAutocompleteDataByProjectQuery, LastInvoiceAutocompleteDataByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastInvoiceAutocompleteDataByProjectQuery, LastInvoiceAutocompleteDataByProjectQueryVariables>(LastInvoiceAutocompleteDataByProjectDocument, options);
      }
export function useLastInvoiceAutocompleteDataByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastInvoiceAutocompleteDataByProjectQuery, LastInvoiceAutocompleteDataByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastInvoiceAutocompleteDataByProjectQuery, LastInvoiceAutocompleteDataByProjectQueryVariables>(LastInvoiceAutocompleteDataByProjectDocument, options);
        }
export type LastInvoiceAutocompleteDataByProjectQueryHookResult = ReturnType<typeof useLastInvoiceAutocompleteDataByProjectQuery>;
export type LastInvoiceAutocompleteDataByProjectLazyQueryHookResult = ReturnType<typeof useLastInvoiceAutocompleteDataByProjectLazyQuery>;
export type LastInvoiceAutocompleteDataByProjectQueryResult = Apollo.QueryResult<LastInvoiceAutocompleteDataByProjectQuery, LastInvoiceAutocompleteDataByProjectQueryVariables>;
export const InvoiceDuplicateDocument = gql`
    mutation InvoiceDuplicate($slug: String!) {
  duplicateInvoice(input: {slug: $slug}) {
    invoice {
      id
      slug
    }
  }
}
    `;
export type InvoiceDuplicateMutationFn = Apollo.MutationFunction<InvoiceDuplicateMutation, InvoiceDuplicateMutationVariables>;

/**
 * __useInvoiceDuplicateMutation__
 *
 * To run a mutation, you first call `useInvoiceDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDuplicateMutation, { data, loading, error }] = useInvoiceDuplicateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoiceDuplicateMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceDuplicateMutation, InvoiceDuplicateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceDuplicateMutation, InvoiceDuplicateMutationVariables>(InvoiceDuplicateDocument, options);
      }
export type InvoiceDuplicateMutationHookResult = ReturnType<typeof useInvoiceDuplicateMutation>;
export type InvoiceDuplicateMutationResult = Apollo.MutationResult<InvoiceDuplicateMutation>;
export type InvoiceDuplicateMutationOptions = Apollo.BaseMutationOptions<InvoiceDuplicateMutation, InvoiceDuplicateMutationVariables>;
export const InvoiceCreateDocument = gql`
    mutation InvoiceCreate($invoice: InvoiceCreateInput!) {
  createInvoice(input: $invoice) {
    invoice {
      slug
      invoiceVersion
    }
  }
}
    `;
export type InvoiceCreateMutationFn = Apollo.MutationFunction<InvoiceCreateMutation, InvoiceCreateMutationVariables>;

/**
 * __useInvoiceCreateMutation__
 *
 * To run a mutation, you first call `useInvoiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceCreateMutation, { data, loading, error }] = useInvoiceCreateMutation({
 *   variables: {
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useInvoiceCreateMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceCreateMutation, InvoiceCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceCreateMutation, InvoiceCreateMutationVariables>(InvoiceCreateDocument, options);
      }
export type InvoiceCreateMutationHookResult = ReturnType<typeof useInvoiceCreateMutation>;
export type InvoiceCreateMutationResult = Apollo.MutationResult<InvoiceCreateMutation>;
export type InvoiceCreateMutationOptions = Apollo.BaseMutationOptions<InvoiceCreateMutation, InvoiceCreateMutationVariables>;
export const InvoiceDeleteDocument = gql`
    mutation InvoiceDelete($slug: String!) {
  deleteInvoice(input: {slug: $slug}) {
    invoice {
      id
    }
  }
}
    `;
export type InvoiceDeleteMutationFn = Apollo.MutationFunction<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>;

/**
 * __useInvoiceDeleteMutation__
 *
 * To run a mutation, you first call `useInvoiceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDeleteMutation, { data, loading, error }] = useInvoiceDeleteMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvoiceDeleteMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>(InvoiceDeleteDocument, options);
      }
export type InvoiceDeleteMutationHookResult = ReturnType<typeof useInvoiceDeleteMutation>;
export type InvoiceDeleteMutationResult = Apollo.MutationResult<InvoiceDeleteMutation>;
export type InvoiceDeleteMutationOptions = Apollo.BaseMutationOptions<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>;
export const AddInvoiceExpenseAttachmentDocument = gql`
    mutation AddInvoiceExpenseAttachment($slug: ID!, $expenseId: ID!) {
  attachInvoiceExpense(input: {slug: $slug, expenseId: $expenseId}) {
    invoice {
      id
      attachedExpenses {
        ...ExpenseFragment
      }
    }
  }
}
    ${ExpenseFragmentFragmentDoc}`;
export type AddInvoiceExpenseAttachmentMutationFn = Apollo.MutationFunction<AddInvoiceExpenseAttachmentMutation, AddInvoiceExpenseAttachmentMutationVariables>;

/**
 * __useAddInvoiceExpenseAttachmentMutation__
 *
 * To run a mutation, you first call `useAddInvoiceExpenseAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceExpenseAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceExpenseAttachmentMutation, { data, loading, error }] = useAddInvoiceExpenseAttachmentMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useAddInvoiceExpenseAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoiceExpenseAttachmentMutation, AddInvoiceExpenseAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvoiceExpenseAttachmentMutation, AddInvoiceExpenseAttachmentMutationVariables>(AddInvoiceExpenseAttachmentDocument, options);
      }
export type AddInvoiceExpenseAttachmentMutationHookResult = ReturnType<typeof useAddInvoiceExpenseAttachmentMutation>;
export type AddInvoiceExpenseAttachmentMutationResult = Apollo.MutationResult<AddInvoiceExpenseAttachmentMutation>;
export type AddInvoiceExpenseAttachmentMutationOptions = Apollo.BaseMutationOptions<AddInvoiceExpenseAttachmentMutation, AddInvoiceExpenseAttachmentMutationVariables>;
export const RemoveInvoiceExpenseAttachmentDocument = gql`
    mutation RemoveInvoiceExpenseAttachment($slug: ID!, $expenseId: ID!) {
  removeAttachedInvoiceExpense(input: {slug: $slug, expenseId: $expenseId}) {
    invoice {
      id
      attachedExpenses {
        ...ExpenseFragment
      }
    }
  }
}
    ${ExpenseFragmentFragmentDoc}`;
export type RemoveInvoiceExpenseAttachmentMutationFn = Apollo.MutationFunction<RemoveInvoiceExpenseAttachmentMutation, RemoveInvoiceExpenseAttachmentMutationVariables>;

/**
 * __useRemoveInvoiceExpenseAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveInvoiceExpenseAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvoiceExpenseAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvoiceExpenseAttachmentMutation, { data, loading, error }] = useRemoveInvoiceExpenseAttachmentMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useRemoveInvoiceExpenseAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInvoiceExpenseAttachmentMutation, RemoveInvoiceExpenseAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInvoiceExpenseAttachmentMutation, RemoveInvoiceExpenseAttachmentMutationVariables>(RemoveInvoiceExpenseAttachmentDocument, options);
      }
export type RemoveInvoiceExpenseAttachmentMutationHookResult = ReturnType<typeof useRemoveInvoiceExpenseAttachmentMutation>;
export type RemoveInvoiceExpenseAttachmentMutationResult = Apollo.MutationResult<RemoveInvoiceExpenseAttachmentMutation>;
export type RemoveInvoiceExpenseAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveInvoiceExpenseAttachmentMutation, RemoveInvoiceExpenseAttachmentMutationVariables>;
export const AddInvoiceAttachmentsDocument = gql`
    mutation AddInvoiceAttachments($slug: ID!, $files: [InvoiceAttachmentFile!]!) {
  addInvoiceAttachments(input: {slug: $slug, files: $files}) {
    invoice {
      id
      invoiceAttachments {
        ...InvoiceAttachment
      }
    }
  }
}
    ${InvoiceAttachmentFragmentDoc}`;
export type AddInvoiceAttachmentsMutationFn = Apollo.MutationFunction<AddInvoiceAttachmentsMutation, AddInvoiceAttachmentsMutationVariables>;

/**
 * __useAddInvoiceAttachmentsMutation__
 *
 * To run a mutation, you first call `useAddInvoiceAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceAttachmentsMutation, { data, loading, error }] = useAddInvoiceAttachmentsMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddInvoiceAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoiceAttachmentsMutation, AddInvoiceAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvoiceAttachmentsMutation, AddInvoiceAttachmentsMutationVariables>(AddInvoiceAttachmentsDocument, options);
      }
export type AddInvoiceAttachmentsMutationHookResult = ReturnType<typeof useAddInvoiceAttachmentsMutation>;
export type AddInvoiceAttachmentsMutationResult = Apollo.MutationResult<AddInvoiceAttachmentsMutation>;
export type AddInvoiceAttachmentsMutationOptions = Apollo.BaseMutationOptions<AddInvoiceAttachmentsMutation, AddInvoiceAttachmentsMutationVariables>;
export const RemoveInvoiceAttachmentDocument = gql`
    mutation RemoveInvoiceAttachment($slug: ID!, $attachmentId: ID!) {
  removeInvoiceAttachment(input: {slug: $slug, attachmentId: $attachmentId}) {
    invoice {
      id
      invoiceAttachments {
        ...InvoiceAttachment
      }
    }
  }
}
    ${InvoiceAttachmentFragmentDoc}`;
export type RemoveInvoiceAttachmentMutationFn = Apollo.MutationFunction<RemoveInvoiceAttachmentMutation, RemoveInvoiceAttachmentMutationVariables>;

/**
 * __useRemoveInvoiceAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveInvoiceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvoiceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvoiceAttachmentMutation, { data, loading, error }] = useRemoveInvoiceAttachmentMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useRemoveInvoiceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInvoiceAttachmentMutation, RemoveInvoiceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInvoiceAttachmentMutation, RemoveInvoiceAttachmentMutationVariables>(RemoveInvoiceAttachmentDocument, options);
      }
export type RemoveInvoiceAttachmentMutationHookResult = ReturnType<typeof useRemoveInvoiceAttachmentMutation>;
export type RemoveInvoiceAttachmentMutationResult = Apollo.MutationResult<RemoveInvoiceAttachmentMutation>;
export type RemoveInvoiceAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveInvoiceAttachmentMutation, RemoveInvoiceAttachmentMutationVariables>;
export const AddInvoicePaymentDocument = gql`
    mutation AddInvoicePayment($input: InvoiceAddPaymentInput!) {
  addInvoicePayment(input: $input) {
    invoice {
      id
      paidDate
      invoiceVersion
      total
      remainingBalance
      status
      ...CurrentActivityFragment
      ...InvoiceActivitiesFragment
      remainingBalance
      totalPaid
      invoicePayments {
        id
        amount
        paidDate
      }
    }
  }
}
    ${CurrentActivityFragmentFragmentDoc}
${InvoiceActivitiesFragmentFragmentDoc}`;
export type AddInvoicePaymentMutationFn = Apollo.MutationFunction<AddInvoicePaymentMutation, AddInvoicePaymentMutationVariables>;

/**
 * __useAddInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useAddInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoicePaymentMutation, { data, loading, error }] = useAddInvoicePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<AddInvoicePaymentMutation, AddInvoicePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvoicePaymentMutation, AddInvoicePaymentMutationVariables>(AddInvoicePaymentDocument, options);
      }
export type AddInvoicePaymentMutationHookResult = ReturnType<typeof useAddInvoicePaymentMutation>;
export type AddInvoicePaymentMutationResult = Apollo.MutationResult<AddInvoicePaymentMutation>;
export type AddInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<AddInvoicePaymentMutation, AddInvoicePaymentMutationVariables>;
export const EditInvoicePaymentDocument = gql`
    mutation EditInvoicePayment($input: InvoiceEditPaymentInput!) {
  editInvoicePayment(input: $input) {
    invoice {
      invoicePayments {
        amount
        paidDate
        id
      }
      paidDate
      status
      totalPaid
      remainingBalance
      id
      ...CurrentActivityFragment
      ...InvoiceActivitiesFragment
    }
  }
}
    ${CurrentActivityFragmentFragmentDoc}
${InvoiceActivitiesFragmentFragmentDoc}`;
export type EditInvoicePaymentMutationFn = Apollo.MutationFunction<EditInvoicePaymentMutation, EditInvoicePaymentMutationVariables>;

/**
 * __useEditInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useEditInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvoicePaymentMutation, { data, loading, error }] = useEditInvoicePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<EditInvoicePaymentMutation, EditInvoicePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInvoicePaymentMutation, EditInvoicePaymentMutationVariables>(EditInvoicePaymentDocument, options);
      }
export type EditInvoicePaymentMutationHookResult = ReturnType<typeof useEditInvoicePaymentMutation>;
export type EditInvoicePaymentMutationResult = Apollo.MutationResult<EditInvoicePaymentMutation>;
export type EditInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<EditInvoicePaymentMutation, EditInvoicePaymentMutationVariables>;
export const DeleteInvoicePaymentsDocument = gql`
    mutation DeleteInvoicePayments($input: InvoicePaymentDeleteInput!) {
  deleteInvoicePayments(input: $input) {
    invoice {
      invoicePayments {
        amount
        paidDate
        id
      }
      paidDate
      status
      totalPaid
      remainingBalance
      id
      ...CurrentActivityFragment
      ...InvoiceActivitiesFragment
    }
  }
}
    ${CurrentActivityFragmentFragmentDoc}
${InvoiceActivitiesFragmentFragmentDoc}`;
export type DeleteInvoicePaymentsMutationFn = Apollo.MutationFunction<DeleteInvoicePaymentsMutation, DeleteInvoicePaymentsMutationVariables>;

/**
 * __useDeleteInvoicePaymentsMutation__
 *
 * To run a mutation, you first call `useDeleteInvoicePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoicePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoicePaymentsMutation, { data, loading, error }] = useDeleteInvoicePaymentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteInvoicePaymentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoicePaymentsMutation, DeleteInvoicePaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoicePaymentsMutation, DeleteInvoicePaymentsMutationVariables>(DeleteInvoicePaymentsDocument, options);
      }
export type DeleteInvoicePaymentsMutationHookResult = ReturnType<typeof useDeleteInvoicePaymentsMutation>;
export type DeleteInvoicePaymentsMutationResult = Apollo.MutationResult<DeleteInvoicePaymentsMutation>;
export type DeleteInvoicePaymentsMutationOptions = Apollo.BaseMutationOptions<DeleteInvoicePaymentsMutation, DeleteInvoicePaymentsMutationVariables>;
export const InvoiceClientsDocument = gql`
    query InvoiceClients {
  currentProfile {
    organization {
      connectedToQbo
      settings {
        multiQboInstanceEnabled
      }
    }
  }
  allClients {
    id
    syncedToQbo
    displayName
    qboCustomer {
      id
      qboInstance {
        id
      }
    }
  }
}
    `;

/**
 * __useInvoiceClientsQuery__
 *
 * To run a query within a React component, call `useInvoiceClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceClientsQuery(baseOptions?: Apollo.QueryHookOptions<InvoiceClientsQuery, InvoiceClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceClientsQuery, InvoiceClientsQueryVariables>(InvoiceClientsDocument, options);
      }
export function useInvoiceClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceClientsQuery, InvoiceClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceClientsQuery, InvoiceClientsQueryVariables>(InvoiceClientsDocument, options);
        }
export type InvoiceClientsQueryHookResult = ReturnType<typeof useInvoiceClientsQuery>;
export type InvoiceClientsLazyQueryHookResult = ReturnType<typeof useInvoiceClientsLazyQuery>;
export type InvoiceClientsQueryResult = Apollo.QueryResult<InvoiceClientsQuery, InvoiceClientsQueryVariables>;
export const ConsultantBillModalDetailsDocument = gql`
    query ConsultantBillModalDetails {
  currentProfile {
    organization {
      connectedToQbo
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
}
    `;

/**
 * __useConsultantBillModalDetailsQuery__
 *
 * To run a query within a React component, call `useConsultantBillModalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantBillModalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantBillModalDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsultantBillModalDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ConsultantBillModalDetailsQuery, ConsultantBillModalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsultantBillModalDetailsQuery, ConsultantBillModalDetailsQueryVariables>(ConsultantBillModalDetailsDocument, options);
      }
export function useConsultantBillModalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsultantBillModalDetailsQuery, ConsultantBillModalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsultantBillModalDetailsQuery, ConsultantBillModalDetailsQueryVariables>(ConsultantBillModalDetailsDocument, options);
        }
export type ConsultantBillModalDetailsQueryHookResult = ReturnType<typeof useConsultantBillModalDetailsQuery>;
export type ConsultantBillModalDetailsLazyQueryHookResult = ReturnType<typeof useConsultantBillModalDetailsLazyQuery>;
export type ConsultantBillModalDetailsQueryResult = Apollo.QueryResult<ConsultantBillModalDetailsQuery, ConsultantBillModalDetailsQueryVariables>;
export const ReviewInvoiceForQboDocument = gql`
    query ReviewInvoiceForQbo($slug: String!) {
  invoice(slug: $slug) {
    id
    status
    slug
    lineItemsHaveQboMappings
  }
}
    `;

/**
 * __useReviewInvoiceForQboQuery__
 *
 * To run a query within a React component, call `useReviewInvoiceForQboQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewInvoiceForQboQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewInvoiceForQboQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReviewInvoiceForQboQuery(baseOptions: Apollo.QueryHookOptions<ReviewInvoiceForQboQuery, ReviewInvoiceForQboQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewInvoiceForQboQuery, ReviewInvoiceForQboQueryVariables>(ReviewInvoiceForQboDocument, options);
      }
export function useReviewInvoiceForQboLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewInvoiceForQboQuery, ReviewInvoiceForQboQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewInvoiceForQboQuery, ReviewInvoiceForQboQueryVariables>(ReviewInvoiceForQboDocument, options);
        }
export type ReviewInvoiceForQboQueryHookResult = ReturnType<typeof useReviewInvoiceForQboQuery>;
export type ReviewInvoiceForQboLazyQueryHookResult = ReturnType<typeof useReviewInvoiceForQboLazyQuery>;
export type ReviewInvoiceForQboQueryResult = Apollo.QueryResult<ReviewInvoiceForQboQuery, ReviewInvoiceForQboQueryVariables>;
export const ProfileMilestonesDocument = gql`
    query ProfileMilestones($sortBy: ProfileMilestonesSortInput, $filters: MilestoneFiltersInput, $last: Int, $first: Int, $before: String, $after: String) {
  currentProfile {
    milestones(
      sortBy: $sortBy
      filters: $filters
      last: $last
      first: $first
      before: $before
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...ListedMilestone
      }
    }
  }
}
    ${ListedMilestoneFragmentDoc}`;

/**
 * __useProfileMilestonesQuery__
 *
 * To run a query within a React component, call `useProfileMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMilestonesQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProfileMilestonesQuery(baseOptions?: Apollo.QueryHookOptions<ProfileMilestonesQuery, ProfileMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileMilestonesQuery, ProfileMilestonesQueryVariables>(ProfileMilestonesDocument, options);
      }
export function useProfileMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileMilestonesQuery, ProfileMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileMilestonesQuery, ProfileMilestonesQueryVariables>(ProfileMilestonesDocument, options);
        }
export type ProfileMilestonesQueryHookResult = ReturnType<typeof useProfileMilestonesQuery>;
export type ProfileMilestonesLazyQueryHookResult = ReturnType<typeof useProfileMilestonesLazyQuery>;
export type ProfileMilestonesQueryResult = Apollo.QueryResult<ProfileMilestonesQuery, ProfileMilestonesQueryVariables>;
export const CreateMilestoneDocument = gql`
    mutation CreateMilestone($name: String!, $description: String!, $dueDate: ISO8601Date!, $phaseId: ID!) {
  createMilestone(
    input: {name: $name, description: $description, dueDate: $dueDate, phaseId: $phaseId}
  ) {
    milestone {
      id
      name
      description
      dueDate
      phase {
        id
      }
    }
  }
}
    `;
export type CreateMilestoneMutationFn = Apollo.MutationFunction<CreateMilestoneMutation, CreateMilestoneMutationVariables>;

/**
 * __useCreateMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneMutation, { data, loading, error }] = useCreateMilestoneMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function useCreateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilestoneMutation, CreateMilestoneMutationVariables>(CreateMilestoneDocument, options);
      }
export type CreateMilestoneMutationHookResult = ReturnType<typeof useCreateMilestoneMutation>;
export type CreateMilestoneMutationResult = Apollo.MutationResult<CreateMilestoneMutation>;
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>;
export const NavigationDocument = gql`
    query Navigation {
  currentProfile {
    id
    organization {
      id
      settings {
        id
        ptoEnabled
        checkId
      }
    }
  }
}
    `;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(baseOptions?: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
      }
export function useNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
        }
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const OnboardingDocument = gql`
    query Onboarding {
  onboarding {
    hasProjects
    hasMembers
    hasSubscription
    reportsVisited
    editedTimesheets
    editedDefaults
    usedTimer
    timesheetTour
    timesheetSubmit
    guideWatched
  }
}
    `;

/**
 * __useOnboardingQuery__
 *
 * To run a query within a React component, call `useOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingQuery, OnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingQuery, OnboardingQueryVariables>(OnboardingDocument, options);
      }
export function useOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingQuery, OnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingQuery, OnboardingQueryVariables>(OnboardingDocument, options);
        }
export type OnboardingQueryHookResult = ReturnType<typeof useOnboardingQuery>;
export type OnboardingLazyQueryHookResult = ReturnType<typeof useOnboardingLazyQuery>;
export type OnboardingQueryResult = Apollo.QueryResult<OnboardingQuery, OnboardingQueryVariables>;
export const CompletedOnboardingDocument = gql`
    query CompletedOnboarding {
  currentProfile {
    id
    completedOnboarding
    policy {
      id
      isAdmin
      permissions {
        canManageStaffing
      }
    }
  }
}
    `;

/**
 * __useCompletedOnboardingQuery__
 *
 * To run a query within a React component, call `useCompletedOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<CompletedOnboardingQuery, CompletedOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletedOnboardingQuery, CompletedOnboardingQueryVariables>(CompletedOnboardingDocument, options);
      }
export function useCompletedOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedOnboardingQuery, CompletedOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletedOnboardingQuery, CompletedOnboardingQueryVariables>(CompletedOnboardingDocument, options);
        }
export type CompletedOnboardingQueryHookResult = ReturnType<typeof useCompletedOnboardingQuery>;
export type CompletedOnboardingLazyQueryHookResult = ReturnType<typeof useCompletedOnboardingLazyQuery>;
export type CompletedOnboardingQueryResult = Apollo.QueryResult<CompletedOnboardingQuery, CompletedOnboardingQueryVariables>;
export const LogOnboardingEventDocument = gql`
    mutation LogOnboardingEvent($event: OnboardingEvent!) {
  logOnboardingEvent(input: {event: $event}) {
    id
  }
}
    `;
export type LogOnboardingEventMutationFn = Apollo.MutationFunction<LogOnboardingEventMutation, LogOnboardingEventMutationVariables>;

/**
 * __useLogOnboardingEventMutation__
 *
 * To run a mutation, you first call `useLogOnboardingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOnboardingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOnboardingEventMutation, { data, loading, error }] = useLogOnboardingEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useLogOnboardingEventMutation(baseOptions?: Apollo.MutationHookOptions<LogOnboardingEventMutation, LogOnboardingEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOnboardingEventMutation, LogOnboardingEventMutationVariables>(LogOnboardingEventDocument, options);
      }
export type LogOnboardingEventMutationHookResult = ReturnType<typeof useLogOnboardingEventMutation>;
export type LogOnboardingEventMutationResult = Apollo.MutationResult<LogOnboardingEventMutation>;
export type LogOnboardingEventMutationOptions = Apollo.BaseMutationOptions<LogOnboardingEventMutation, LogOnboardingEventMutationVariables>;
export const OrganizationsDocument = gql`
    query organizations($isQualified: Boolean, $before: String, $last: Int) {
  admin {
    organizations(isQualified: $isQualified, before: $before, last: $last) {
      nodes {
        ...AdminOrganizationFields
      }
      pageInfo {
        startCursor
        hasPreviousPage
      }
      totalCount
    }
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      isQualified: // value for 'isQualified'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationSearchDocument = gql`
    query organizationSearch($query: String!, $isQualified: Boolean) {
  admin {
    organizationSearch(filter: {query: $query, isQualified: $isQualified}) {
      ...AdminOrganizationFields
    }
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useOrganizationSearchQuery__
 *
 * To run a query within a React component, call `useOrganizationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      isQualified: // value for 'isQualified'
 *   },
 * });
 */
export function useOrganizationSearchQuery(baseOptions: Apollo.QueryHookOptions<OrganizationSearchQuery, OrganizationSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(OrganizationSearchDocument, options);
      }
export function useOrganizationSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSearchQuery, OrganizationSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(OrganizationSearchDocument, options);
        }
export type OrganizationSearchQueryHookResult = ReturnType<typeof useOrganizationSearchQuery>;
export type OrganizationSearchLazyQueryHookResult = ReturnType<typeof useOrganizationSearchLazyQuery>;
export type OrganizationSearchQueryResult = Apollo.QueryResult<OrganizationSearchQuery, OrganizationSearchQueryVariables>;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($id: ID!) {
  admin {
    deleteOrganization(input: {id: $id}) {
      organization {
        id
      }
    }
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const AccountManagerDocument = gql`
    query AccountManager {
  me {
    organizations {
      id
      name
      size
      logo
      isDemo
    }
    isAdmin
  }
  currentProfile {
    organization {
      id
      isDemo
      size
      name
      logo
      demo {
        id
        name
        size
      }
      demoParent {
        id
        name
        size
        logo
      }
    }
  }
}
    `;

/**
 * __useAccountManagerQuery__
 *
 * To run a query within a React component, call `useAccountManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountManagerQuery(baseOptions?: Apollo.QueryHookOptions<AccountManagerQuery, AccountManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountManagerQuery, AccountManagerQueryVariables>(AccountManagerDocument, options);
      }
export function useAccountManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountManagerQuery, AccountManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountManagerQuery, AccountManagerQueryVariables>(AccountManagerDocument, options);
        }
export type AccountManagerQueryHookResult = ReturnType<typeof useAccountManagerQuery>;
export type AccountManagerLazyQueryHookResult = ReturnType<typeof useAccountManagerLazyQuery>;
export type AccountManagerQueryResult = Apollo.QueryResult<AccountManagerQuery, AccountManagerQueryVariables>;
export const UpdateOrganizationAddressDocument = gql`
    mutation UpdateOrganizationAddress($data: UpdateOrganizationAddressInput!) {
  updateOrganizationAddress(input: $data) {
    address {
      ...OrganizationAddress
    }
  }
}
    ${OrganizationAddressFragmentDoc}`;
export type UpdateOrganizationAddressMutationFn = Apollo.MutationFunction<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>;

/**
 * __useUpdateOrganizationAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAddressMutation, { data, loading, error }] = useUpdateOrganizationAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>(UpdateOrganizationAddressDocument, options);
      }
export type UpdateOrganizationAddressMutationHookResult = ReturnType<typeof useUpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationResult = Apollo.MutationResult<UpdateOrganizationAddressMutation>;
export type UpdateOrganizationAddressMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationAddressMutation, UpdateOrganizationAddressMutationVariables>;
export const ToggleOrganizationDocument = gql`
    mutation toggleOrganization($id: ID!) {
  toggleOrganization(input: {organizationId: $id}) {
    organization {
      id
    }
  }
}
    `;
export type ToggleOrganizationMutationFn = Apollo.MutationFunction<ToggleOrganizationMutation, ToggleOrganizationMutationVariables>;

/**
 * __useToggleOrganizationMutation__
 *
 * To run a mutation, you first call `useToggleOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOrganizationMutation, { data, loading, error }] = useToggleOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOrganizationMutation, ToggleOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleOrganizationMutation, ToggleOrganizationMutationVariables>(ToggleOrganizationDocument, options);
      }
export type ToggleOrganizationMutationHookResult = ReturnType<typeof useToggleOrganizationMutation>;
export type ToggleOrganizationMutationResult = Apollo.MutationResult<ToggleOrganizationMutation>;
export type ToggleOrganizationMutationOptions = Apollo.BaseMutationOptions<ToggleOrganizationMutation, ToggleOrganizationMutationVariables>;
export const OrganizationPoliciesDocument = gql`
    query OrganizationPolicies {
  currentProfile {
    organization {
      policies {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useOrganizationPoliciesQuery__
 *
 * To run a query within a React component, call `useOrganizationPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationPoliciesQuery, OrganizationPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationPoliciesQuery, OrganizationPoliciesQueryVariables>(OrganizationPoliciesDocument, options);
      }
export function useOrganizationPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationPoliciesQuery, OrganizationPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationPoliciesQuery, OrganizationPoliciesQueryVariables>(OrganizationPoliciesDocument, options);
        }
export type OrganizationPoliciesQueryHookResult = ReturnType<typeof useOrganizationPoliciesQuery>;
export type OrganizationPoliciesLazyQueryHookResult = ReturnType<typeof useOrganizationPoliciesLazyQuery>;
export type OrganizationPoliciesQueryResult = Apollo.QueryResult<OrganizationPoliciesQuery, OrganizationPoliciesQueryVariables>;
export const SyncStripeAttributesDocument = gql`
    mutation SyncStripeAttributes($id: String!) {
  syncStripeAttributes(input: {id: $id}) {
    organization {
      id
    }
  }
}
    `;
export type SyncStripeAttributesMutationFn = Apollo.MutationFunction<SyncStripeAttributesMutation, SyncStripeAttributesMutationVariables>;

/**
 * __useSyncStripeAttributesMutation__
 *
 * To run a mutation, you first call `useSyncStripeAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncStripeAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncStripeAttributesMutation, { data, loading, error }] = useSyncStripeAttributesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncStripeAttributesMutation(baseOptions?: Apollo.MutationHookOptions<SyncStripeAttributesMutation, SyncStripeAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncStripeAttributesMutation, SyncStripeAttributesMutationVariables>(SyncStripeAttributesDocument, options);
      }
export type SyncStripeAttributesMutationHookResult = ReturnType<typeof useSyncStripeAttributesMutation>;
export type SyncStripeAttributesMutationResult = Apollo.MutationResult<SyncStripeAttributesMutation>;
export type SyncStripeAttributesMutationOptions = Apollo.BaseMutationOptions<SyncStripeAttributesMutation, SyncStripeAttributesMutationVariables>;
export const InvoicePaymentsDocument = gql`
    query InvoicePayments($sortBy: InvoicePaymentsSortInput, $filters: PaymentFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  invoicePayments(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    totalPaid
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      paidDate
      amount
      net
      applicationFee
      paymentType
      paymentCurrency
      applyConvenienceFee
      invoice {
        status
        issueDate
        dueDate
        slug
        number
        total
        paidDate
        invoiceVersion
        client {
          name
          displayName
        }
        project {
          id
          color
          name
          number
          link
          v1InvoicesWithUnassignedLineItemsCount
        }
      }
    }
  }
}
    `;

/**
 * __useInvoicePaymentsQuery__
 *
 * To run a query within a React component, call `useInvoicePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePaymentsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoicePaymentsQuery(baseOptions: Apollo.QueryHookOptions<InvoicePaymentsQuery, InvoicePaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicePaymentsQuery, InvoicePaymentsQueryVariables>(InvoicePaymentsDocument, options);
      }
export function useInvoicePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicePaymentsQuery, InvoicePaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicePaymentsQuery, InvoicePaymentsQueryVariables>(InvoicePaymentsDocument, options);
        }
export type InvoicePaymentsQueryHookResult = ReturnType<typeof useInvoicePaymentsQuery>;
export type InvoicePaymentsLazyQueryHookResult = ReturnType<typeof useInvoicePaymentsLazyQuery>;
export type InvoicePaymentsQueryResult = Apollo.QueryResult<InvoicePaymentsQuery, InvoicePaymentsQueryVariables>;
export const PayoutsDocument = gql`
    query Payouts($sortBy: PayoutsSortInput, $filters: PayoutFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  payouts(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    totalCount
    totalPayoutAmount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      stripeCreationDate
      amount
      invoices {
        status
        issueDate
        dueDate
        slug
        number
        total
        paidDate
        invoiceVersion
        client {
          name
          displayName
        }
        project {
          id
          color
          name
          number
          link
          v1InvoicesWithUnassignedLineItemsCount
        }
        invoicePayment {
          amount
          net
          applicationFee
          paymentType
          paymentCurrency
        }
      }
    }
  }
}
    `;

/**
 * __usePayoutsQuery__
 *
 * To run a query within a React component, call `usePayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePayoutsQuery(baseOptions: Apollo.QueryHookOptions<PayoutsQuery, PayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayoutsQuery, PayoutsQueryVariables>(PayoutsDocument, options);
      }
export function usePayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutsQuery, PayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayoutsQuery, PayoutsQueryVariables>(PayoutsDocument, options);
        }
export type PayoutsQueryHookResult = ReturnType<typeof usePayoutsQuery>;
export type PayoutsLazyQueryHookResult = ReturnType<typeof usePayoutsLazyQuery>;
export type PayoutsQueryResult = Apollo.QueryResult<PayoutsQuery, PayoutsQueryVariables>;
export const PayrollDocument = gql`
    query Payroll {
  payroll {
    paySchedule {
      ...PayrollPaySchedule
    }
    info {
      ...PayrollInfo
    }
    workplaces {
      ...PayrollWorkplace
    }
    nextPayday {
      ...PayrollPaySchedulePayday
    }
    documents {
      authorization {
        ...CompanyPayrollDocument
      }
      tax {
        ...CompanyPayrollDocument
      }
    }
    payrolls {
      list {
        id
        periodStart
        periodEnd
        payday
        status
        type
        totals {
          reimbursables
          cashRequirement
        }
      }
    }
  }
}
    ${PayrollPayScheduleFragmentDoc}
${PayrollInfoFragmentDoc}
${PayrollWorkplaceFragmentDoc}
${PayrollPaySchedulePaydayFragmentDoc}
${CompanyPayrollDocumentFragmentDoc}`;

/**
 * __usePayrollQuery__
 *
 * To run a query within a React component, call `usePayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollQuery(baseOptions?: Apollo.QueryHookOptions<PayrollQuery, PayrollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollQuery, PayrollQueryVariables>(PayrollDocument, options);
      }
export function usePayrollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollQuery, PayrollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollQuery, PayrollQueryVariables>(PayrollDocument, options);
        }
export type PayrollQueryHookResult = ReturnType<typeof usePayrollQuery>;
export type PayrollLazyQueryHookResult = ReturnType<typeof usePayrollLazyQuery>;
export type PayrollQueryResult = Apollo.QueryResult<PayrollQuery, PayrollQueryVariables>;
export const PayrollCompanyAuthorizationDocumentDocument = gql`
    query PayrollCompanyAuthorizationDocument($documentId: ID!) {
  payroll {
    document {
      authorization(documentId: $documentId) {
        pdf
      }
    }
  }
}
    `;

/**
 * __usePayrollCompanyAuthorizationDocumentQuery__
 *
 * To run a query within a React component, call `usePayrollCompanyAuthorizationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyAuthorizationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCompanyAuthorizationDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePayrollCompanyAuthorizationDocumentQuery(baseOptions: Apollo.QueryHookOptions<PayrollCompanyAuthorizationDocumentQuery, PayrollCompanyAuthorizationDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollCompanyAuthorizationDocumentQuery, PayrollCompanyAuthorizationDocumentQueryVariables>(PayrollCompanyAuthorizationDocumentDocument, options);
      }
export function usePayrollCompanyAuthorizationDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollCompanyAuthorizationDocumentQuery, PayrollCompanyAuthorizationDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollCompanyAuthorizationDocumentQuery, PayrollCompanyAuthorizationDocumentQueryVariables>(PayrollCompanyAuthorizationDocumentDocument, options);
        }
export type PayrollCompanyAuthorizationDocumentQueryHookResult = ReturnType<typeof usePayrollCompanyAuthorizationDocumentQuery>;
export type PayrollCompanyAuthorizationDocumentLazyQueryHookResult = ReturnType<typeof usePayrollCompanyAuthorizationDocumentLazyQuery>;
export type PayrollCompanyAuthorizationDocumentQueryResult = Apollo.QueryResult<PayrollCompanyAuthorizationDocumentQuery, PayrollCompanyAuthorizationDocumentQueryVariables>;
export const PayrollCompanyTaxDocumentDocument = gql`
    query PayrollCompanyTaxDocument($documentId: ID!) {
  payroll {
    document {
      tax(documentId: $documentId) {
        pdf
      }
    }
  }
}
    `;

/**
 * __usePayrollCompanyTaxDocumentQuery__
 *
 * To run a query within a React component, call `usePayrollCompanyTaxDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanyTaxDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCompanyTaxDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePayrollCompanyTaxDocumentQuery(baseOptions: Apollo.QueryHookOptions<PayrollCompanyTaxDocumentQuery, PayrollCompanyTaxDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollCompanyTaxDocumentQuery, PayrollCompanyTaxDocumentQueryVariables>(PayrollCompanyTaxDocumentDocument, options);
      }
export function usePayrollCompanyTaxDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollCompanyTaxDocumentQuery, PayrollCompanyTaxDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollCompanyTaxDocumentQuery, PayrollCompanyTaxDocumentQueryVariables>(PayrollCompanyTaxDocumentDocument, options);
        }
export type PayrollCompanyTaxDocumentQueryHookResult = ReturnType<typeof usePayrollCompanyTaxDocumentQuery>;
export type PayrollCompanyTaxDocumentLazyQueryHookResult = ReturnType<typeof usePayrollCompanyTaxDocumentLazyQuery>;
export type PayrollCompanyTaxDocumentQueryResult = Apollo.QueryResult<PayrollCompanyTaxDocumentQuery, PayrollCompanyTaxDocumentQueryVariables>;
export const PayrollWorkerTaxDocumentDocument = gql`
    query PayrollWorkerTaxDocument($profileId: ID!, $documentId: ID!) {
  payroll {
    worker(profileId: $profileId) {
      document {
        tax(profileId: $profileId, documentId: $documentId) {
          pdf
        }
      }
    }
  }
}
    `;

/**
 * __usePayrollWorkerTaxDocumentQuery__
 *
 * To run a query within a React component, call `usePayrollWorkerTaxDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollWorkerTaxDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollWorkerTaxDocumentQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePayrollWorkerTaxDocumentQuery(baseOptions: Apollo.QueryHookOptions<PayrollWorkerTaxDocumentQuery, PayrollWorkerTaxDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollWorkerTaxDocumentQuery, PayrollWorkerTaxDocumentQueryVariables>(PayrollWorkerTaxDocumentDocument, options);
      }
export function usePayrollWorkerTaxDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollWorkerTaxDocumentQuery, PayrollWorkerTaxDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollWorkerTaxDocumentQuery, PayrollWorkerTaxDocumentQueryVariables>(PayrollWorkerTaxDocumentDocument, options);
        }
export type PayrollWorkerTaxDocumentQueryHookResult = ReturnType<typeof usePayrollWorkerTaxDocumentQuery>;
export type PayrollWorkerTaxDocumentLazyQueryHookResult = ReturnType<typeof usePayrollWorkerTaxDocumentLazyQuery>;
export type PayrollWorkerTaxDocumentQueryResult = Apollo.QueryResult<PayrollWorkerTaxDocumentQuery, PayrollWorkerTaxDocumentQueryVariables>;
export const PayrollEmployeePaystubDocument = gql`
    query PayrollEmployeePaystub($profileId: ID, $profileSlug: String, $payrollId: ID!) {
  payroll {
    employee(profileId: $profileId, profileSlug: $profileSlug) {
      document {
        paystub(profileId: $profileId, profileSlug: $profileSlug, payrollId: $payrollId) {
          pdf
        }
      }
    }
  }
}
    `;

/**
 * __usePayrollEmployeePaystubQuery__
 *
 * To run a query within a React component, call `usePayrollEmployeePaystubQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollEmployeePaystubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollEmployeePaystubQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      profileSlug: // value for 'profileSlug'
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function usePayrollEmployeePaystubQuery(baseOptions: Apollo.QueryHookOptions<PayrollEmployeePaystubQuery, PayrollEmployeePaystubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollEmployeePaystubQuery, PayrollEmployeePaystubQueryVariables>(PayrollEmployeePaystubDocument, options);
      }
export function usePayrollEmployeePaystubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollEmployeePaystubQuery, PayrollEmployeePaystubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollEmployeePaystubQuery, PayrollEmployeePaystubQueryVariables>(PayrollEmployeePaystubDocument, options);
        }
export type PayrollEmployeePaystubQueryHookResult = ReturnType<typeof usePayrollEmployeePaystubQuery>;
export type PayrollEmployeePaystubLazyQueryHookResult = ReturnType<typeof usePayrollEmployeePaystubLazyQuery>;
export type PayrollEmployeePaystubQueryResult = Apollo.QueryResult<PayrollEmployeePaystubQuery, PayrollEmployeePaystubQueryVariables>;
export const PayrollWaitlisterDocument = gql`
    query PayrollWaitlister {
  payrollWaitlister {
    organization {
      name
    }
    profile {
      name
    }
  }
}
    `;

/**
 * __usePayrollWaitlisterQuery__
 *
 * To run a query within a React component, call `usePayrollWaitlisterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollWaitlisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollWaitlisterQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollWaitlisterQuery(baseOptions?: Apollo.QueryHookOptions<PayrollWaitlisterQuery, PayrollWaitlisterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollWaitlisterQuery, PayrollWaitlisterQueryVariables>(PayrollWaitlisterDocument, options);
      }
export function usePayrollWaitlisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollWaitlisterQuery, PayrollWaitlisterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollWaitlisterQuery, PayrollWaitlisterQueryVariables>(PayrollWaitlisterDocument, options);
        }
export type PayrollWaitlisterQueryHookResult = ReturnType<typeof usePayrollWaitlisterQuery>;
export type PayrollWaitlisterLazyQueryHookResult = ReturnType<typeof usePayrollWaitlisterLazyQuery>;
export type PayrollWaitlisterQueryResult = Apollo.QueryResult<PayrollWaitlisterQuery, PayrollWaitlisterQueryVariables>;
export const PayrollOrgStatusDocument = gql`
    query PayrollOrgStatus {
  payroll {
    workers {
      id
      info {
        id
      }
    }
    info {
      id
      onboarding {
        status
        blockingSteps
        remainingForms
        remainingSteps
        remainingTaxJurisdictions
        bankAccount
      }
      payFrequency
    }
    nextPayday {
      approvalDeadline
      payday
    }
    payrolls {
      listUnprocessed {
        id
      }
    }
  }
}
    `;

/**
 * __usePayrollOrgStatusQuery__
 *
 * To run a query within a React component, call `usePayrollOrgStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollOrgStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollOrgStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollOrgStatusQuery(baseOptions?: Apollo.QueryHookOptions<PayrollOrgStatusQuery, PayrollOrgStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollOrgStatusQuery, PayrollOrgStatusQueryVariables>(PayrollOrgStatusDocument, options);
      }
export function usePayrollOrgStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollOrgStatusQuery, PayrollOrgStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollOrgStatusQuery, PayrollOrgStatusQueryVariables>(PayrollOrgStatusDocument, options);
        }
export type PayrollOrgStatusQueryHookResult = ReturnType<typeof usePayrollOrgStatusQuery>;
export type PayrollOrgStatusLazyQueryHookResult = ReturnType<typeof usePayrollOrgStatusLazyQuery>;
export type PayrollOrgStatusQueryResult = Apollo.QueryResult<PayrollOrgStatusQuery, PayrollOrgStatusQueryVariables>;
export const PayrollReportDocument = gql`
    query PayrollReport($category: ReportCategory!, $startDate: ISO8601Date, $endDate: ISO8601Date) {
  payroll {
    report(category: $category, startDate: $startDate, endDate: $endDate) {
      csv
    }
  }
}
    `;

/**
 * __usePayrollReportQuery__
 *
 * To run a query within a React component, call `usePayrollReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollReportQuery({
 *   variables: {
 *      category: // value for 'category'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePayrollReportQuery(baseOptions: Apollo.QueryHookOptions<PayrollReportQuery, PayrollReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollReportQuery, PayrollReportQueryVariables>(PayrollReportDocument, options);
      }
export function usePayrollReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollReportQuery, PayrollReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollReportQuery, PayrollReportQueryVariables>(PayrollReportDocument, options);
        }
export type PayrollReportQueryHookResult = ReturnType<typeof usePayrollReportQuery>;
export type PayrollReportLazyQueryHookResult = ReturnType<typeof usePayrollReportLazyQuery>;
export type PayrollReportQueryResult = Apollo.QueryResult<PayrollReportQuery, PayrollReportQueryVariables>;
export const PayrollOrgWorkplacesDocument = gql`
    query PayrollOrgWorkplaces {
  payroll {
    workplaces {
      active
      address {
        ...PayrollAddress
      }
      id
      name
    }
  }
}
    ${PayrollAddressFragmentDoc}`;

/**
 * __usePayrollOrgWorkplacesQuery__
 *
 * To run a query within a React component, call `usePayrollOrgWorkplacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollOrgWorkplacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollOrgWorkplacesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollOrgWorkplacesQuery(baseOptions?: Apollo.QueryHookOptions<PayrollOrgWorkplacesQuery, PayrollOrgWorkplacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollOrgWorkplacesQuery, PayrollOrgWorkplacesQueryVariables>(PayrollOrgWorkplacesDocument, options);
      }
export function usePayrollOrgWorkplacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollOrgWorkplacesQuery, PayrollOrgWorkplacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollOrgWorkplacesQuery, PayrollOrgWorkplacesQueryVariables>(PayrollOrgWorkplacesDocument, options);
        }
export type PayrollOrgWorkplacesQueryHookResult = ReturnType<typeof usePayrollOrgWorkplacesQuery>;
export type PayrollOrgWorkplacesLazyQueryHookResult = ReturnType<typeof usePayrollOrgWorkplacesLazyQuery>;
export type PayrollOrgWorkplacesQueryResult = Apollo.QueryResult<PayrollOrgWorkplacesQuery, PayrollOrgWorkplacesQueryVariables>;
export const GetPayrollEmployeesDocument = gql`
    query GetPayrollEmployees {
  payroll {
    employees {
      info {
        ...PayrollEmployee
      }
      bankAccounts {
        ...PayrollBankAccount
      }
    }
  }
}
    ${PayrollEmployeeFragmentDoc}
${PayrollBankAccountFragmentDoc}`;

/**
 * __useGetPayrollEmployeesQuery__
 *
 * To run a query within a React component, call `useGetPayrollEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayrollEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollEmployeesQuery, GetPayrollEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollEmployeesQuery, GetPayrollEmployeesQueryVariables>(GetPayrollEmployeesDocument, options);
      }
export function useGetPayrollEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollEmployeesQuery, GetPayrollEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollEmployeesQuery, GetPayrollEmployeesQueryVariables>(GetPayrollEmployeesDocument, options);
        }
export type GetPayrollEmployeesQueryHookResult = ReturnType<typeof useGetPayrollEmployeesQuery>;
export type GetPayrollEmployeesLazyQueryHookResult = ReturnType<typeof useGetPayrollEmployeesLazyQuery>;
export type GetPayrollEmployeesQueryResult = Apollo.QueryResult<GetPayrollEmployeesQuery, GetPayrollEmployeesQueryVariables>;
export const GetPayrollWorkersDocument = gql`
    query GetPayrollWorkers {
  payroll {
    workers {
      id
      type
      info {
        ...PayrollWorker
      }
      bankAccounts {
        ...PayrollBankAccount
      }
    }
  }
}
    ${PayrollWorkerFragmentDoc}
${PayrollBankAccountFragmentDoc}`;

/**
 * __useGetPayrollWorkersQuery__
 *
 * To run a query within a React component, call `useGetPayrollWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollWorkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayrollWorkersQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollWorkersQuery, GetPayrollWorkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollWorkersQuery, GetPayrollWorkersQueryVariables>(GetPayrollWorkersDocument, options);
      }
export function useGetPayrollWorkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollWorkersQuery, GetPayrollWorkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollWorkersQuery, GetPayrollWorkersQueryVariables>(GetPayrollWorkersDocument, options);
        }
export type GetPayrollWorkersQueryHookResult = ReturnType<typeof useGetPayrollWorkersQuery>;
export type GetPayrollWorkersLazyQueryHookResult = ReturnType<typeof useGetPayrollWorkersLazyQuery>;
export type GetPayrollWorkersQueryResult = Apollo.QueryResult<GetPayrollWorkersQuery, GetPayrollWorkersQueryVariables>;
export const UnsafeProfilesForPayrollDocument = gql`
    query UNSAFEProfilesForPayroll {
  profiles(filters: {statuses: [ACTIVE, INACTIVE]}) {
    nodes {
      ...PayrollProfile
    }
  }
}
    ${PayrollProfileFragmentDoc}`;

/**
 * __useUnsafeProfilesForPayrollQuery__
 *
 * To run a query within a React component, call `useUnsafeProfilesForPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsafeProfilesForPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsafeProfilesForPayrollQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnsafeProfilesForPayrollQuery(baseOptions?: Apollo.QueryHookOptions<UnsafeProfilesForPayrollQuery, UnsafeProfilesForPayrollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnsafeProfilesForPayrollQuery, UnsafeProfilesForPayrollQueryVariables>(UnsafeProfilesForPayrollDocument, options);
      }
export function useUnsafeProfilesForPayrollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnsafeProfilesForPayrollQuery, UnsafeProfilesForPayrollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnsafeProfilesForPayrollQuery, UnsafeProfilesForPayrollQueryVariables>(UnsafeProfilesForPayrollDocument, options);
        }
export type UnsafeProfilesForPayrollQueryHookResult = ReturnType<typeof useUnsafeProfilesForPayrollQuery>;
export type UnsafeProfilesForPayrollLazyQueryHookResult = ReturnType<typeof useUnsafeProfilesForPayrollLazyQuery>;
export type UnsafeProfilesForPayrollQueryResult = Apollo.QueryResult<UnsafeProfilesForPayrollQuery, UnsafeProfilesForPayrollQueryVariables>;
export const ViewPayrollNavItemDocument = gql`
    query ViewPayrollNavItem {
  currentProfile {
    organization {
      settings {
        checkId
      }
    }
    id
    checkId
    policy {
      permissions {
        canManageCompensations
      }
    }
  }
}
    `;

/**
 * __useViewPayrollNavItemQuery__
 *
 * To run a query within a React component, call `useViewPayrollNavItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollNavItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollNavItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewPayrollNavItemQuery(baseOptions?: Apollo.QueryHookOptions<ViewPayrollNavItemQuery, ViewPayrollNavItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewPayrollNavItemQuery, ViewPayrollNavItemQueryVariables>(ViewPayrollNavItemDocument, options);
      }
export function useViewPayrollNavItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewPayrollNavItemQuery, ViewPayrollNavItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewPayrollNavItemQuery, ViewPayrollNavItemQueryVariables>(ViewPayrollNavItemDocument, options);
        }
export type ViewPayrollNavItemQueryHookResult = ReturnType<typeof useViewPayrollNavItemQuery>;
export type ViewPayrollNavItemLazyQueryHookResult = ReturnType<typeof useViewPayrollNavItemLazyQuery>;
export type ViewPayrollNavItemQueryResult = Apollo.QueryResult<ViewPayrollNavItemQuery, ViewPayrollNavItemQueryVariables>;
export const GetPayrollEmployeeDocument = gql`
    query GetPayrollEmployee($profileId: ID, $profileSlug: String) {
  profile(id: $profileId, slug: $profileSlug) {
    ...PayrollProfile
  }
  payroll {
    employee(profileId: $profileId, profileSlug: $profileSlug) {
      earningRates {
        ...PayrollEarningRate
      }
      documents {
        tax {
          ...CompanyPayrollDocument
        }
      }
      info {
        ...PayrollEmployee
      }
      bankAccounts {
        ...PayrollBankAccount
      }
    }
  }
}
    ${PayrollProfileFragmentDoc}
${PayrollEarningRateFragmentDoc}
${CompanyPayrollDocumentFragmentDoc}
${PayrollEmployeeFragmentDoc}
${PayrollBankAccountFragmentDoc}`;

/**
 * __useGetPayrollEmployeeQuery__
 *
 * To run a query within a React component, call `useGetPayrollEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollEmployeeQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      profileSlug: // value for 'profileSlug'
 *   },
 * });
 */
export function useGetPayrollEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollEmployeeQuery, GetPayrollEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollEmployeeQuery, GetPayrollEmployeeQueryVariables>(GetPayrollEmployeeDocument, options);
      }
export function useGetPayrollEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollEmployeeQuery, GetPayrollEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollEmployeeQuery, GetPayrollEmployeeQueryVariables>(GetPayrollEmployeeDocument, options);
        }
export type GetPayrollEmployeeQueryHookResult = ReturnType<typeof useGetPayrollEmployeeQuery>;
export type GetPayrollEmployeeLazyQueryHookResult = ReturnType<typeof useGetPayrollEmployeeLazyQuery>;
export type GetPayrollEmployeeQueryResult = Apollo.QueryResult<GetPayrollEmployeeQuery, GetPayrollEmployeeQueryVariables>;
export const GetPayrollWorkerDocument = gql`
    query GetPayrollWorker($profileId: ID, $profileSlug: String) {
  profile(id: $profileId, slug: $profileSlug) {
    ...PayrollProfile
  }
  payroll {
    worker(profileId: $profileId, profileSlug: $profileSlug) {
      type
      id
      profile {
        id
      }
      earningRates {
        ...PayrollEarningRate
      }
      documents {
        paystubs {
          ...PayrollPaystub
        }
        tax {
          ...CompanyPayrollDocument
        }
      }
      info {
        ...PayrollWorker
      }
      bankAccounts {
        ...PayrollBankAccount
      }
    }
  }
}
    ${PayrollProfileFragmentDoc}
${PayrollEarningRateFragmentDoc}
${PayrollPaystubFragmentDoc}
${CompanyPayrollDocumentFragmentDoc}
${PayrollWorkerFragmentDoc}
${PayrollBankAccountFragmentDoc}`;

/**
 * __useGetPayrollWorkerQuery__
 *
 * To run a query within a React component, call `useGetPayrollWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollWorkerQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      profileSlug: // value for 'profileSlug'
 *   },
 * });
 */
export function useGetPayrollWorkerQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollWorkerQuery, GetPayrollWorkerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollWorkerQuery, GetPayrollWorkerQueryVariables>(GetPayrollWorkerDocument, options);
      }
export function useGetPayrollWorkerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollWorkerQuery, GetPayrollWorkerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollWorkerQuery, GetPayrollWorkerQueryVariables>(GetPayrollWorkerDocument, options);
        }
export type GetPayrollWorkerQueryHookResult = ReturnType<typeof useGetPayrollWorkerQuery>;
export type GetPayrollWorkerLazyQueryHookResult = ReturnType<typeof useGetPayrollWorkerLazyQuery>;
export type GetPayrollWorkerQueryResult = Apollo.QueryResult<GetPayrollWorkerQuery, GetPayrollWorkerQueryVariables>;
export const GetPayrollNextPaydayPeriodDocument = gql`
    query GetPayrollNextPaydayPeriod {
  payroll {
    nextPayday {
      periodStart
      periodEnd
    }
  }
}
    `;

/**
 * __useGetPayrollNextPaydayPeriodQuery__
 *
 * To run a query within a React component, call `useGetPayrollNextPaydayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollNextPaydayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollNextPaydayPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayrollNextPaydayPeriodQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollNextPaydayPeriodQuery, GetPayrollNextPaydayPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollNextPaydayPeriodQuery, GetPayrollNextPaydayPeriodQueryVariables>(GetPayrollNextPaydayPeriodDocument, options);
      }
export function useGetPayrollNextPaydayPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollNextPaydayPeriodQuery, GetPayrollNextPaydayPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollNextPaydayPeriodQuery, GetPayrollNextPaydayPeriodQueryVariables>(GetPayrollNextPaydayPeriodDocument, options);
        }
export type GetPayrollNextPaydayPeriodQueryHookResult = ReturnType<typeof useGetPayrollNextPaydayPeriodQuery>;
export type GetPayrollNextPaydayPeriodLazyQueryHookResult = ReturnType<typeof useGetPayrollNextPaydayPeriodLazyQuery>;
export type GetPayrollNextPaydayPeriodQueryResult = Apollo.QueryResult<GetPayrollNextPaydayPeriodQuery, GetPayrollNextPaydayPeriodQueryVariables>;
export const GetPayrollHourlyReportDocument = gql`
    query GetPayrollHourlyReport {
  payroll {
    workers(status: ACTIVE) {
      profile {
        id
        name
        image
        slug
      }
      type
      compensations {
        effectiveOn
        hoursPerWeek
        id
        isCurrent
        payrollType
        rate
      }
      earningRates {
        active
        amount
        employeeId
        id
        period
      }
    }
  }
}
    `;

/**
 * __useGetPayrollHourlyReportQuery__
 *
 * To run a query within a React component, call `useGetPayrollHourlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollHourlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollHourlyReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayrollHourlyReportQuery(baseOptions?: Apollo.QueryHookOptions<GetPayrollHourlyReportQuery, GetPayrollHourlyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayrollHourlyReportQuery, GetPayrollHourlyReportQueryVariables>(GetPayrollHourlyReportDocument, options);
      }
export function useGetPayrollHourlyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayrollHourlyReportQuery, GetPayrollHourlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayrollHourlyReportQuery, GetPayrollHourlyReportQueryVariables>(GetPayrollHourlyReportDocument, options);
        }
export type GetPayrollHourlyReportQueryHookResult = ReturnType<typeof useGetPayrollHourlyReportQuery>;
export type GetPayrollHourlyReportLazyQueryHookResult = ReturnType<typeof useGetPayrollHourlyReportLazyQuery>;
export type GetPayrollHourlyReportQueryResult = Apollo.QueryResult<GetPayrollHourlyReportQuery, GetPayrollHourlyReportQueryVariables>;
export const GetHourlyReportHoursDocument = gql`
    query GetHourlyReportHours($profileId: ID!, $beginDate: ISO8601Date!, $endDate: ISO8601Date!) {
  profile(id: $profileId) {
    timesheets(beginDate: $beginDate, endDate: $endDate) {
      entries(beginDate: $beginDate, endDate: $endDate) {
        date
        hours
      }
    }
  }
}
    `;

/**
 * __useGetHourlyReportHoursQuery__
 *
 * To run a query within a React component, call `useGetHourlyReportHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHourlyReportHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHourlyReportHoursQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetHourlyReportHoursQuery(baseOptions: Apollo.QueryHookOptions<GetHourlyReportHoursQuery, GetHourlyReportHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHourlyReportHoursQuery, GetHourlyReportHoursQueryVariables>(GetHourlyReportHoursDocument, options);
      }
export function useGetHourlyReportHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHourlyReportHoursQuery, GetHourlyReportHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHourlyReportHoursQuery, GetHourlyReportHoursQueryVariables>(GetHourlyReportHoursDocument, options);
        }
export type GetHourlyReportHoursQueryHookResult = ReturnType<typeof useGetHourlyReportHoursQuery>;
export type GetHourlyReportHoursLazyQueryHookResult = ReturnType<typeof useGetHourlyReportHoursLazyQuery>;
export type GetHourlyReportHoursQueryResult = Apollo.QueryResult<GetHourlyReportHoursQuery, GetHourlyReportHoursQueryVariables>;
export const CreatePayrollEmployeeDocument = gql`
    mutation createPayrollEmployee($input: CreatePayrollEmployeeInput!) {
  createPayrollEmployee(input: $input) {
    employee {
      info {
        ...PayrollEmployee
      }
    }
  }
}
    ${PayrollEmployeeFragmentDoc}`;
export type CreatePayrollEmployeeMutationFn = Apollo.MutationFunction<CreatePayrollEmployeeMutation, CreatePayrollEmployeeMutationVariables>;

/**
 * __useCreatePayrollEmployeeMutation__
 *
 * To run a mutation, you first call `useCreatePayrollEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollEmployeeMutation, { data, loading, error }] = useCreatePayrollEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayrollEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollEmployeeMutation, CreatePayrollEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollEmployeeMutation, CreatePayrollEmployeeMutationVariables>(CreatePayrollEmployeeDocument, options);
      }
export type CreatePayrollEmployeeMutationHookResult = ReturnType<typeof useCreatePayrollEmployeeMutation>;
export type CreatePayrollEmployeeMutationResult = Apollo.MutationResult<CreatePayrollEmployeeMutation>;
export type CreatePayrollEmployeeMutationOptions = Apollo.BaseMutationOptions<CreatePayrollEmployeeMutation, CreatePayrollEmployeeMutationVariables>;
export const CreatePayrollContractorDocument = gql`
    mutation createPayrollContractor($input: CreatePayrollContractorInput!) {
  createPayrollContractor(input: $input) {
    contractor {
      info {
        ...PayrollContractor
      }
    }
  }
}
    ${PayrollContractorFragmentDoc}`;
export type CreatePayrollContractorMutationFn = Apollo.MutationFunction<CreatePayrollContractorMutation, CreatePayrollContractorMutationVariables>;

/**
 * __useCreatePayrollContractorMutation__
 *
 * To run a mutation, you first call `useCreatePayrollContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollContractorMutation, { data, loading, error }] = useCreatePayrollContractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayrollContractorMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollContractorMutation, CreatePayrollContractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollContractorMutation, CreatePayrollContractorMutationVariables>(CreatePayrollContractorDocument, options);
      }
export type CreatePayrollContractorMutationHookResult = ReturnType<typeof useCreatePayrollContractorMutation>;
export type CreatePayrollContractorMutationResult = Apollo.MutationResult<CreatePayrollContractorMutation>;
export type CreatePayrollContractorMutationOptions = Apollo.BaseMutationOptions<CreatePayrollContractorMutation, CreatePayrollContractorMutationVariables>;
export const UpdatePayrollEmployeeDocument = gql`
    mutation updatePayrollEmployee($input: UpdatePayrollEmployeeInput!) {
  updatePayrollEmployee(input: $input) {
    employeeInfo {
      ...PayrollEmployee
    }
  }
}
    ${PayrollEmployeeFragmentDoc}`;
export type UpdatePayrollEmployeeMutationFn = Apollo.MutationFunction<UpdatePayrollEmployeeMutation, UpdatePayrollEmployeeMutationVariables>;

/**
 * __useUpdatePayrollEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmployeeMutation, { data, loading, error }] = useUpdatePayrollEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayrollEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmployeeMutation, UpdatePayrollEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmployeeMutation, UpdatePayrollEmployeeMutationVariables>(UpdatePayrollEmployeeDocument, options);
      }
export type UpdatePayrollEmployeeMutationHookResult = ReturnType<typeof useUpdatePayrollEmployeeMutation>;
export type UpdatePayrollEmployeeMutationResult = Apollo.MutationResult<UpdatePayrollEmployeeMutation>;
export type UpdatePayrollEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmployeeMutation, UpdatePayrollEmployeeMutationVariables>;
export const UpdatePayrollContractorDocument = gql`
    mutation updatePayrollContractor($input: UpdatePayrollContractorInput!) {
  updatePayrollContractor(input: $input) {
    contractorInfo {
      ...PayrollWorker
    }
  }
}
    ${PayrollWorkerFragmentDoc}`;
export type UpdatePayrollContractorMutationFn = Apollo.MutationFunction<UpdatePayrollContractorMutation, UpdatePayrollContractorMutationVariables>;

/**
 * __useUpdatePayrollContractorMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollContractorMutation, { data, loading, error }] = useUpdatePayrollContractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayrollContractorMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollContractorMutation, UpdatePayrollContractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollContractorMutation, UpdatePayrollContractorMutationVariables>(UpdatePayrollContractorDocument, options);
      }
export type UpdatePayrollContractorMutationHookResult = ReturnType<typeof useUpdatePayrollContractorMutation>;
export type UpdatePayrollContractorMutationResult = Apollo.MutationResult<UpdatePayrollContractorMutation>;
export type UpdatePayrollContractorMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollContractorMutation, UpdatePayrollContractorMutationVariables>;
export const UpdatePayrollEmploymentTypeDocument = gql`
    mutation updatePayrollEmploymentType($input: UpdatePayrollEmploymentTypeInput!) {
  updatePayrollEmploymentType(input: $input) {
    profile {
      ...PayrollProfile
    }
  }
}
    ${PayrollProfileFragmentDoc}`;
export type UpdatePayrollEmploymentTypeMutationFn = Apollo.MutationFunction<UpdatePayrollEmploymentTypeMutation, UpdatePayrollEmploymentTypeMutationVariables>;

/**
 * __useUpdatePayrollEmploymentTypeMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollEmploymentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollEmploymentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollEmploymentTypeMutation, { data, loading, error }] = useUpdatePayrollEmploymentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayrollEmploymentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollEmploymentTypeMutation, UpdatePayrollEmploymentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollEmploymentTypeMutation, UpdatePayrollEmploymentTypeMutationVariables>(UpdatePayrollEmploymentTypeDocument, options);
      }
export type UpdatePayrollEmploymentTypeMutationHookResult = ReturnType<typeof useUpdatePayrollEmploymentTypeMutation>;
export type UpdatePayrollEmploymentTypeMutationResult = Apollo.MutationResult<UpdatePayrollEmploymentTypeMutation>;
export type UpdatePayrollEmploymentTypeMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollEmploymentTypeMutation, UpdatePayrollEmploymentTypeMutationVariables>;
export const CreatePayrollEmployeeEarningRateDocument = gql`
    mutation createPayrollEmployeeEarningRate($input: CreatePayrollEmployeeEarningRateInput!) {
  createPayrollEmployeeEarningRate(input: $input) {
    worker {
      id
      earningRates {
        ...PayrollEarningRate
      }
    }
  }
}
    ${PayrollEarningRateFragmentDoc}`;
export type CreatePayrollEmployeeEarningRateMutationFn = Apollo.MutationFunction<CreatePayrollEmployeeEarningRateMutation, CreatePayrollEmployeeEarningRateMutationVariables>;

/**
 * __useCreatePayrollEmployeeEarningRateMutation__
 *
 * To run a mutation, you first call `useCreatePayrollEmployeeEarningRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollEmployeeEarningRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollEmployeeEarningRateMutation, { data, loading, error }] = useCreatePayrollEmployeeEarningRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayrollEmployeeEarningRateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollEmployeeEarningRateMutation, CreatePayrollEmployeeEarningRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollEmployeeEarningRateMutation, CreatePayrollEmployeeEarningRateMutationVariables>(CreatePayrollEmployeeEarningRateDocument, options);
      }
export type CreatePayrollEmployeeEarningRateMutationHookResult = ReturnType<typeof useCreatePayrollEmployeeEarningRateMutation>;
export type CreatePayrollEmployeeEarningRateMutationResult = Apollo.MutationResult<CreatePayrollEmployeeEarningRateMutation>;
export type CreatePayrollEmployeeEarningRateMutationOptions = Apollo.BaseMutationOptions<CreatePayrollEmployeeEarningRateMutation, CreatePayrollEmployeeEarningRateMutationVariables>;
export const GenerateCompanyOnboardingLinkDocument = gql`
    mutation GenerateCompanyOnboardingLink {
  generateCompanyPayrollOnboardingLink(input: {}) {
    onboardingLink
    signatoryRequired
  }
}
    `;
export type GenerateCompanyOnboardingLinkMutationFn = Apollo.MutationFunction<GenerateCompanyOnboardingLinkMutation, GenerateCompanyOnboardingLinkMutationVariables>;

/**
 * __useGenerateCompanyOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useGenerateCompanyOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompanyOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCompanyOnboardingLinkMutation, { data, loading, error }] = useGenerateCompanyOnboardingLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateCompanyOnboardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCompanyOnboardingLinkMutation, GenerateCompanyOnboardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCompanyOnboardingLinkMutation, GenerateCompanyOnboardingLinkMutationVariables>(GenerateCompanyOnboardingLinkDocument, options);
      }
export type GenerateCompanyOnboardingLinkMutationHookResult = ReturnType<typeof useGenerateCompanyOnboardingLinkMutation>;
export type GenerateCompanyOnboardingLinkMutationResult = Apollo.MutationResult<GenerateCompanyOnboardingLinkMutation>;
export type GenerateCompanyOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<GenerateCompanyOnboardingLinkMutation, GenerateCompanyOnboardingLinkMutationVariables>;
export const GenerateEmployeeOnboardingLinkDocument = gql`
    mutation GenerateEmployeeOnboardingLink($input: GenerateEmployeeOnboardingLinkInput!) {
  generateEmployeePayrollOnboardingLink(input: $input) {
    onboardingLink
  }
}
    `;
export type GenerateEmployeeOnboardingLinkMutationFn = Apollo.MutationFunction<GenerateEmployeeOnboardingLinkMutation, GenerateEmployeeOnboardingLinkMutationVariables>;

/**
 * __useGenerateEmployeeOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useGenerateEmployeeOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmployeeOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmployeeOnboardingLinkMutation, { data, loading, error }] = useGenerateEmployeeOnboardingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateEmployeeOnboardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEmployeeOnboardingLinkMutation, GenerateEmployeeOnboardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEmployeeOnboardingLinkMutation, GenerateEmployeeOnboardingLinkMutationVariables>(GenerateEmployeeOnboardingLinkDocument, options);
      }
export type GenerateEmployeeOnboardingLinkMutationHookResult = ReturnType<typeof useGenerateEmployeeOnboardingLinkMutation>;
export type GenerateEmployeeOnboardingLinkMutationResult = Apollo.MutationResult<GenerateEmployeeOnboardingLinkMutation>;
export type GenerateEmployeeOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<GenerateEmployeeOnboardingLinkMutation, GenerateEmployeeOnboardingLinkMutationVariables>;
export const GenerateRunPayrollLinkDocument = gql`
    mutation GenerateRunPayrollLink($input: GenerateRunPayrollLinkInput!) {
  generatePayrollRunPayrollLink(input: $input) {
    runPayrollLink
  }
}
    `;
export type GenerateRunPayrollLinkMutationFn = Apollo.MutationFunction<GenerateRunPayrollLinkMutation, GenerateRunPayrollLinkMutationVariables>;

/**
 * __useGenerateRunPayrollLinkMutation__
 *
 * To run a mutation, you first call `useGenerateRunPayrollLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRunPayrollLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRunPayrollLinkMutation, { data, loading, error }] = useGenerateRunPayrollLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateRunPayrollLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateRunPayrollLinkMutation, GenerateRunPayrollLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateRunPayrollLinkMutation, GenerateRunPayrollLinkMutationVariables>(GenerateRunPayrollLinkDocument, options);
      }
export type GenerateRunPayrollLinkMutationHookResult = ReturnType<typeof useGenerateRunPayrollLinkMutation>;
export type GenerateRunPayrollLinkMutationResult = Apollo.MutationResult<GenerateRunPayrollLinkMutation>;
export type GenerateRunPayrollLinkMutationOptions = Apollo.BaseMutationOptions<GenerateRunPayrollLinkMutation, GenerateRunPayrollLinkMutationVariables>;
export const GenerateCompanyInsuranceLinkDocument = gql`
    mutation GenerateCompanyInsuranceLink($input: GenerateCompanyInsuranceLinkInput!) {
  generateCompanyPayrollInsuranceLink(input: $input) {
    link
    emailRequired
  }
}
    `;
export type GenerateCompanyInsuranceLinkMutationFn = Apollo.MutationFunction<GenerateCompanyInsuranceLinkMutation, GenerateCompanyInsuranceLinkMutationVariables>;

/**
 * __useGenerateCompanyInsuranceLinkMutation__
 *
 * To run a mutation, you first call `useGenerateCompanyInsuranceLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompanyInsuranceLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCompanyInsuranceLinkMutation, { data, loading, error }] = useGenerateCompanyInsuranceLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCompanyInsuranceLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCompanyInsuranceLinkMutation, GenerateCompanyInsuranceLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCompanyInsuranceLinkMutation, GenerateCompanyInsuranceLinkMutationVariables>(GenerateCompanyInsuranceLinkDocument, options);
      }
export type GenerateCompanyInsuranceLinkMutationHookResult = ReturnType<typeof useGenerateCompanyInsuranceLinkMutation>;
export type GenerateCompanyInsuranceLinkMutationResult = Apollo.MutationResult<GenerateCompanyInsuranceLinkMutation>;
export type GenerateCompanyInsuranceLinkMutationOptions = Apollo.BaseMutationOptions<GenerateCompanyInsuranceLinkMutation, GenerateCompanyInsuranceLinkMutationVariables>;
export const GenerateCompanyRetirementLinkDocument = gql`
    mutation GenerateCompanyRetirementLink($input: GenerateCompanyRetirementLinkInput!) {
  generateCompanyPayrollRetirementLink(input: $input) {
    link
    emailRequired
  }
}
    `;
export type GenerateCompanyRetirementLinkMutationFn = Apollo.MutationFunction<GenerateCompanyRetirementLinkMutation, GenerateCompanyRetirementLinkMutationVariables>;

/**
 * __useGenerateCompanyRetirementLinkMutation__
 *
 * To run a mutation, you first call `useGenerateCompanyRetirementLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompanyRetirementLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCompanyRetirementLinkMutation, { data, loading, error }] = useGenerateCompanyRetirementLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCompanyRetirementLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCompanyRetirementLinkMutation, GenerateCompanyRetirementLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCompanyRetirementLinkMutation, GenerateCompanyRetirementLinkMutationVariables>(GenerateCompanyRetirementLinkDocument, options);
      }
export type GenerateCompanyRetirementLinkMutationHookResult = ReturnType<typeof useGenerateCompanyRetirementLinkMutation>;
export type GenerateCompanyRetirementLinkMutationResult = Apollo.MutationResult<GenerateCompanyRetirementLinkMutation>;
export type GenerateCompanyRetirementLinkMutationOptions = Apollo.BaseMutationOptions<GenerateCompanyRetirementLinkMutation, GenerateCompanyRetirementLinkMutationVariables>;
export const CreateSignatoryItemAndOnboardingLinkDocument = gql`
    mutation CreateSignatoryItemAndOnboardingLink($input: CreateSignatoryItemAndOnboardingLinkInput!) {
  createSignatoryItemAndOnboardingLink(input: $input) {
    onboardingLink
  }
}
    `;
export type CreateSignatoryItemAndOnboardingLinkMutationFn = Apollo.MutationFunction<CreateSignatoryItemAndOnboardingLinkMutation, CreateSignatoryItemAndOnboardingLinkMutationVariables>;

/**
 * __useCreateSignatoryItemAndOnboardingLinkMutation__
 *
 * To run a mutation, you first call `useCreateSignatoryItemAndOnboardingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSignatoryItemAndOnboardingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSignatoryItemAndOnboardingLinkMutation, { data, loading, error }] = useCreateSignatoryItemAndOnboardingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSignatoryItemAndOnboardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSignatoryItemAndOnboardingLinkMutation, CreateSignatoryItemAndOnboardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSignatoryItemAndOnboardingLinkMutation, CreateSignatoryItemAndOnboardingLinkMutationVariables>(CreateSignatoryItemAndOnboardingLinkDocument, options);
      }
export type CreateSignatoryItemAndOnboardingLinkMutationHookResult = ReturnType<typeof useCreateSignatoryItemAndOnboardingLinkMutation>;
export type CreateSignatoryItemAndOnboardingLinkMutationResult = Apollo.MutationResult<CreateSignatoryItemAndOnboardingLinkMutation>;
export type CreateSignatoryItemAndOnboardingLinkMutationOptions = Apollo.BaseMutationOptions<CreateSignatoryItemAndOnboardingLinkMutation, CreateSignatoryItemAndOnboardingLinkMutationVariables>;
export const CreatePayrollWaitlisterDocument = gql`
    mutation CreatePayrollWaitlister($organizationId: ID!) {
  createPayrollWaitlister(input: {organizationId: $organizationId}) {
    payrollWaitlister {
      id
    }
  }
}
    `;
export type CreatePayrollWaitlisterMutationFn = Apollo.MutationFunction<CreatePayrollWaitlisterMutation, CreatePayrollWaitlisterMutationVariables>;

/**
 * __useCreatePayrollWaitlisterMutation__
 *
 * To run a mutation, you first call `useCreatePayrollWaitlisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollWaitlisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollWaitlisterMutation, { data, loading, error }] = useCreatePayrollWaitlisterMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreatePayrollWaitlisterMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollWaitlisterMutation, CreatePayrollWaitlisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollWaitlisterMutation, CreatePayrollWaitlisterMutationVariables>(CreatePayrollWaitlisterDocument, options);
      }
export type CreatePayrollWaitlisterMutationHookResult = ReturnType<typeof useCreatePayrollWaitlisterMutation>;
export type CreatePayrollWaitlisterMutationResult = Apollo.MutationResult<CreatePayrollWaitlisterMutation>;
export type CreatePayrollWaitlisterMutationOptions = Apollo.BaseMutationOptions<CreatePayrollWaitlisterMutation, CreatePayrollWaitlisterMutationVariables>;
export const CreatePayrollWorkplaceDocument = gql`
    mutation CreatePayrollWorkplace($name: String!, $address: PayrollAddressInput!) {
  createPayrollWorkplace(input: {name: $name, address: $address}) {
    workplace {
      ...PayrollWorkplace
    }
  }
}
    ${PayrollWorkplaceFragmentDoc}`;
export type CreatePayrollWorkplaceMutationFn = Apollo.MutationFunction<CreatePayrollWorkplaceMutation, CreatePayrollWorkplaceMutationVariables>;

/**
 * __useCreatePayrollWorkplaceMutation__
 *
 * To run a mutation, you first call `useCreatePayrollWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollWorkplaceMutation, { data, loading, error }] = useCreatePayrollWorkplaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreatePayrollWorkplaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollWorkplaceMutation, CreatePayrollWorkplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollWorkplaceMutation, CreatePayrollWorkplaceMutationVariables>(CreatePayrollWorkplaceDocument, options);
      }
export type CreatePayrollWorkplaceMutationHookResult = ReturnType<typeof useCreatePayrollWorkplaceMutation>;
export type CreatePayrollWorkplaceMutationResult = Apollo.MutationResult<CreatePayrollWorkplaceMutation>;
export type CreatePayrollWorkplaceMutationOptions = Apollo.BaseMutationOptions<CreatePayrollWorkplaceMutation, CreatePayrollWorkplaceMutationVariables>;
export const UpdatePayrollWorkplaceDocument = gql`
    mutation UpdatePayrollWorkplace($id: String!, $name: String, $active: Boolean, $address: PayrollAddressInputPartial) {
  updatePayrollWorkplace(
    input: {workplaceId: $id, name: $name, active: $active, address: $address}
  ) {
    workplace {
      ...PayrollWorkplace
    }
  }
}
    ${PayrollWorkplaceFragmentDoc}`;
export type UpdatePayrollWorkplaceMutationFn = Apollo.MutationFunction<UpdatePayrollWorkplaceMutation, UpdatePayrollWorkplaceMutationVariables>;

/**
 * __useUpdatePayrollWorkplaceMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollWorkplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollWorkplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollWorkplaceMutation, { data, loading, error }] = useUpdatePayrollWorkplaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdatePayrollWorkplaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollWorkplaceMutation, UpdatePayrollWorkplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollWorkplaceMutation, UpdatePayrollWorkplaceMutationVariables>(UpdatePayrollWorkplaceDocument, options);
      }
export type UpdatePayrollWorkplaceMutationHookResult = ReturnType<typeof useUpdatePayrollWorkplaceMutation>;
export type UpdatePayrollWorkplaceMutationResult = Apollo.MutationResult<UpdatePayrollWorkplaceMutation>;
export type UpdatePayrollWorkplaceMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollWorkplaceMutation, UpdatePayrollWorkplaceMutationVariables>;
export const UpdatePayrollPayFrequencyDocument = gql`
    mutation UpdatePayrollPayFrequency($payFrequency: PayFrequency!) {
  updatePayrollCompany(input: {payFrequency: $payFrequency}) {
    companyInfo {
      ...PayrollInfo
    }
  }
}
    ${PayrollInfoFragmentDoc}`;
export type UpdatePayrollPayFrequencyMutationFn = Apollo.MutationFunction<UpdatePayrollPayFrequencyMutation, UpdatePayrollPayFrequencyMutationVariables>;

/**
 * __useUpdatePayrollPayFrequencyMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollPayFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollPayFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollPayFrequencyMutation, { data, loading, error }] = useUpdatePayrollPayFrequencyMutation({
 *   variables: {
 *      payFrequency: // value for 'payFrequency'
 *   },
 * });
 */
export function useUpdatePayrollPayFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollPayFrequencyMutation, UpdatePayrollPayFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollPayFrequencyMutation, UpdatePayrollPayFrequencyMutationVariables>(UpdatePayrollPayFrequencyDocument, options);
      }
export type UpdatePayrollPayFrequencyMutationHookResult = ReturnType<typeof useUpdatePayrollPayFrequencyMutation>;
export type UpdatePayrollPayFrequencyMutationResult = Apollo.MutationResult<UpdatePayrollPayFrequencyMutation>;
export type UpdatePayrollPayFrequencyMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollPayFrequencyMutation, UpdatePayrollPayFrequencyMutationVariables>;
export const UpdatePayrollBasicInfoDocument = gql`
    mutation UpdatePayrollBasicInfo($email: String, $legalName: String, $address: PayrollAddressInput) {
  updatePayrollCompany(
    input: {legalName: $legalName, email: $email, address: $address}
  ) {
    companyInfo {
      ...PayrollInfo
    }
  }
}
    ${PayrollInfoFragmentDoc}`;
export type UpdatePayrollBasicInfoMutationFn = Apollo.MutationFunction<UpdatePayrollBasicInfoMutation, UpdatePayrollBasicInfoMutationVariables>;

/**
 * __useUpdatePayrollBasicInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollBasicInfoMutation, { data, loading, error }] = useUpdatePayrollBasicInfoMutation({
 *   variables: {
 *      email: // value for 'email'
 *      legalName: // value for 'legalName'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdatePayrollBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayrollBasicInfoMutation, UpdatePayrollBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayrollBasicInfoMutation, UpdatePayrollBasicInfoMutationVariables>(UpdatePayrollBasicInfoDocument, options);
      }
export type UpdatePayrollBasicInfoMutationHookResult = ReturnType<typeof useUpdatePayrollBasicInfoMutation>;
export type UpdatePayrollBasicInfoMutationResult = Apollo.MutationResult<UpdatePayrollBasicInfoMutation>;
export type UpdatePayrollBasicInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePayrollBasicInfoMutation, UpdatePayrollBasicInfoMutationVariables>;
export const CreatePayrollDocument = gql`
    mutation CreatePayroll {
  createPayroll(input: {}) {
    runPayrollLink
    payrollId
  }
}
    `;
export type CreatePayrollMutationFn = Apollo.MutationFunction<CreatePayrollMutation, CreatePayrollMutationVariables>;

/**
 * __useCreatePayrollMutation__
 *
 * To run a mutation, you first call `useCreatePayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayrollMutation, { data, loading, error }] = useCreatePayrollMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePayrollMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayrollMutation, CreatePayrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayrollMutation, CreatePayrollMutationVariables>(CreatePayrollDocument, options);
      }
export type CreatePayrollMutationHookResult = ReturnType<typeof useCreatePayrollMutation>;
export type CreatePayrollMutationResult = Apollo.MutationResult<CreatePayrollMutation>;
export type CreatePayrollMutationOptions = Apollo.BaseMutationOptions<CreatePayrollMutation, CreatePayrollMutationVariables>;
export const CreateOffCyclePayrollDocument = gql`
    mutation CreateOffCyclePayroll($periodStart: ISO8601Date!, $periodEnd: ISO8601Date!, $payday: ISO8601Date!, $forceSupplementalWithholding: Boolean!, $applyBenefits: Boolean!, $applyPostTaxDeductions: Boolean!) {
  createOffCyclePayroll(
    input: {periodStart: $periodStart, periodEnd: $periodEnd, payday: $payday, forceSupplementalWithholding: $forceSupplementalWithholding, applyBenefits: $applyBenefits, applyPostTaxDeductions: $applyPostTaxDeductions}
  ) {
    runPayrollLink
    payrollId
  }
}
    `;
export type CreateOffCyclePayrollMutationFn = Apollo.MutationFunction<CreateOffCyclePayrollMutation, CreateOffCyclePayrollMutationVariables>;

/**
 * __useCreateOffCyclePayrollMutation__
 *
 * To run a mutation, you first call `useCreateOffCyclePayrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOffCyclePayrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOffCyclePayrollMutation, { data, loading, error }] = useCreateOffCyclePayrollMutation({
 *   variables: {
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      payday: // value for 'payday'
 *      forceSupplementalWithholding: // value for 'forceSupplementalWithholding'
 *      applyBenefits: // value for 'applyBenefits'
 *      applyPostTaxDeductions: // value for 'applyPostTaxDeductions'
 *   },
 * });
 */
export function useCreateOffCyclePayrollMutation(baseOptions?: Apollo.MutationHookOptions<CreateOffCyclePayrollMutation, CreateOffCyclePayrollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOffCyclePayrollMutation, CreateOffCyclePayrollMutationVariables>(CreateOffCyclePayrollDocument, options);
      }
export type CreateOffCyclePayrollMutationHookResult = ReturnType<typeof useCreateOffCyclePayrollMutation>;
export type CreateOffCyclePayrollMutationResult = Apollo.MutationResult<CreateOffCyclePayrollMutation>;
export type CreateOffCyclePayrollMutationOptions = Apollo.BaseMutationOptions<CreateOffCyclePayrollMutation, CreateOffCyclePayrollMutationVariables>;
export const PayrollHourlyWorkerDocument = gql`
    subscription PayrollHourlyWorker($periodStart: ISO8601Date, $periodEnd: ISO8601Date) {
  payrollHourlyWorker(periodStart: $periodStart, periodEnd: $periodEnd) {
    periodStart
    periodEnd
    workers {
      type
      profile {
        slug
        fullName
      }
      hourlyRate
      timesheets {
        totalHours
        beginDate
        endDate
      }
    }
  }
}
    `;

/**
 * __usePayrollHourlyWorkerSubscription__
 *
 * To run a query within a React component, call `usePayrollHourlyWorkerSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePayrollHourlyWorkerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollHourlyWorkerSubscription({
 *   variables: {
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *   },
 * });
 */
export function usePayrollHourlyWorkerSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PayrollHourlyWorkerSubscription, PayrollHourlyWorkerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PayrollHourlyWorkerSubscription, PayrollHourlyWorkerSubscriptionVariables>(PayrollHourlyWorkerDocument, options);
      }
export type PayrollHourlyWorkerSubscriptionHookResult = ReturnType<typeof usePayrollHourlyWorkerSubscription>;
export type PayrollHourlyWorkerSubscriptionResult = Apollo.SubscriptionResult<PayrollHourlyWorkerSubscription>;
export const PayrollReimbursableExpensesDocument = gql`
    subscription PayrollReimbursableExpenses($periodStart: ISO8601Date, $periodEnd: ISO8601Date) {
  payrollReimbursableExpenses(periodStart: $periodStart, periodEnd: $periodEnd) {
    workers {
      profile {
        slug
        fullName
      }
      expenses {
        note
        attachment
        date
        amount
        category {
          name
        }
        isReimbursable
        project {
          name
        }
        phase {
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePayrollReimbursableExpensesSubscription__
 *
 * To run a query within a React component, call `usePayrollReimbursableExpensesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePayrollReimbursableExpensesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollReimbursableExpensesSubscription({
 *   variables: {
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *   },
 * });
 */
export function usePayrollReimbursableExpensesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<PayrollReimbursableExpensesSubscription, PayrollReimbursableExpensesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PayrollReimbursableExpensesSubscription, PayrollReimbursableExpensesSubscriptionVariables>(PayrollReimbursableExpensesDocument, options);
      }
export type PayrollReimbursableExpensesSubscriptionHookResult = ReturnType<typeof usePayrollReimbursableExpensesSubscription>;
export type PayrollReimbursableExpensesSubscriptionResult = Apollo.SubscriptionResult<PayrollReimbursableExpensesSubscription>;
export const PayrollOnboardStatsDocument = gql`
    query payrollOnboardStats {
  payrollOnboardStats {
    organization {
      remainingSteps
    }
    worker {
      remainingSteps
    }
  }
}
    `;

/**
 * __usePayrollOnboardStatsQuery__
 *
 * To run a query within a React component, call `usePayrollOnboardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollOnboardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollOnboardStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollOnboardStatsQuery(baseOptions?: Apollo.QueryHookOptions<PayrollOnboardStatsQuery, PayrollOnboardStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollOnboardStatsQuery, PayrollOnboardStatsQueryVariables>(PayrollOnboardStatsDocument, options);
      }
export function usePayrollOnboardStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollOnboardStatsQuery, PayrollOnboardStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollOnboardStatsQuery, PayrollOnboardStatsQueryVariables>(PayrollOnboardStatsDocument, options);
        }
export type PayrollOnboardStatsQueryHookResult = ReturnType<typeof usePayrollOnboardStatsQuery>;
export type PayrollOnboardStatsLazyQueryHookResult = ReturnType<typeof usePayrollOnboardStatsLazyQuery>;
export type PayrollOnboardStatsQueryResult = Apollo.QueryResult<PayrollOnboardStatsQuery, PayrollOnboardStatsQueryVariables>;
export const ActivePayScheduleDocument = gql`
    query ActivePaySchedule {
  currentProfile {
    id
    organization {
      id
      paySchedule {
        ...PayrollPaySchedule
      }
    }
  }
}
    ${PayrollPayScheduleFragmentDoc}`;

/**
 * __useActivePayScheduleQuery__
 *
 * To run a query within a React component, call `useActivePayScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePayScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePayScheduleQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivePayScheduleQuery(baseOptions?: Apollo.QueryHookOptions<ActivePayScheduleQuery, ActivePayScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivePayScheduleQuery, ActivePayScheduleQueryVariables>(ActivePayScheduleDocument, options);
      }
export function useActivePayScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivePayScheduleQuery, ActivePayScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivePayScheduleQuery, ActivePayScheduleQueryVariables>(ActivePayScheduleDocument, options);
        }
export type ActivePayScheduleQueryHookResult = ReturnType<typeof useActivePayScheduleQuery>;
export type ActivePayScheduleLazyQueryHookResult = ReturnType<typeof useActivePayScheduleLazyQuery>;
export type ActivePayScheduleQueryResult = Apollo.QueryResult<ActivePayScheduleQuery, ActivePayScheduleQueryVariables>;
export const RecentProfilesDocument = gql`
    query recentProfiles {
  admin {
    recentProfiles {
      ...AdminProfileFields
    }
  }
}
    ${AdminProfileFieldsFragmentDoc}`;

/**
 * __useRecentProfilesQuery__
 *
 * To run a query within a React component, call `useRecentProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentProfilesQuery(baseOptions?: Apollo.QueryHookOptions<RecentProfilesQuery, RecentProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentProfilesQuery, RecentProfilesQueryVariables>(RecentProfilesDocument, options);
      }
export function useRecentProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentProfilesQuery, RecentProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentProfilesQuery, RecentProfilesQueryVariables>(RecentProfilesDocument, options);
        }
export type RecentProfilesQueryHookResult = ReturnType<typeof useRecentProfilesQuery>;
export type RecentProfilesLazyQueryHookResult = ReturnType<typeof useRecentProfilesLazyQuery>;
export type RecentProfilesQueryResult = Apollo.QueryResult<RecentProfilesQuery, RecentProfilesQueryVariables>;
export const ProfileDocument = gql`
    query profile($id: ID, $slug: String) {
  profile(id: $id, slug: $slug) {
    ...ProfileRoleTemplates
    ...ProfileRate
    ...ProfileBasicInfo
    ...ProfileSettings
    ...ProfilePayrollInfo
    ajeraIdentifier {
      ...AjeraFields
    }
  }
}
    ${ProfileRoleTemplatesFragmentDoc}
${ProfileRateFragmentDoc}
${ProfileBasicInfoFragmentDoc}
${ProfileSettingsFragmentDoc}
${ProfilePayrollInfoFragmentDoc}
${AjeraFieldsFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const ProfileProjectsDocument = gql`
    query profileProjects($id: ID, $slug: String, $projectSortBy: ProfileProjectsSortInput, $projectFilters: ProfileProjectsFiltersInput, $last: Int, $first: Int, $before: String, $after: String) {
  profile(id: $id, slug: $slug) {
    ...ProfileBasicInfo
    projects(
      sortBy: $projectSortBy
      filters: $projectFilters
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...ProjectFragment
      }
    }
  }
}
    ${ProfileBasicInfoFragmentDoc}
${ProjectFragmentFragmentDoc}`;

/**
 * __useProfileProjectsQuery__
 *
 * To run a query within a React component, call `useProfileProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileProjectsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      projectSortBy: // value for 'projectSortBy'
 *      projectFilters: // value for 'projectFilters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProfileProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProfileProjectsQuery, ProfileProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileProjectsQuery, ProfileProjectsQueryVariables>(ProfileProjectsDocument, options);
      }
export function useProfileProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileProjectsQuery, ProfileProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileProjectsQuery, ProfileProjectsQueryVariables>(ProfileProjectsDocument, options);
        }
export type ProfileProjectsQueryHookResult = ReturnType<typeof useProfileProjectsQuery>;
export type ProfileProjectsLazyQueryHookResult = ReturnType<typeof useProfileProjectsLazyQuery>;
export type ProfileProjectsQueryResult = Apollo.QueryResult<ProfileProjectsQuery, ProfileProjectsQueryVariables>;
export const ProfileSearchDocument = gql`
    query profileSearch($query: String!) {
  admin {
    profileSearch(filter: {query: $query}) {
      ...AdminProfileFields
    }
  }
}
    ${AdminProfileFieldsFragmentDoc}`;

/**
 * __useProfileSearchQuery__
 *
 * To run a query within a React component, call `useProfileSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useProfileSearchQuery(baseOptions: Apollo.QueryHookOptions<ProfileSearchQuery, ProfileSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileSearchQuery, ProfileSearchQueryVariables>(ProfileSearchDocument, options);
      }
export function useProfileSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileSearchQuery, ProfileSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileSearchQuery, ProfileSearchQueryVariables>(ProfileSearchDocument, options);
        }
export type ProfileSearchQueryHookResult = ReturnType<typeof useProfileSearchQuery>;
export type ProfileSearchLazyQueryHookResult = ReturnType<typeof useProfileSearchLazyQuery>;
export type ProfileSearchQueryResult = Apollo.QueryResult<ProfileSearchQuery, ProfileSearchQueryVariables>;
export const TeamOverviewDocument = gql`
    query teamOverview($filters: ProfileFiltersInput, $sortBy: ProfilesSortInput, $last: Int, $first: Int, $before: String, $after: String) {
  currentProfile {
    id
    policy {
      id
      isAdmin
    }
    organization {
      policies {
        id
        name
      }
      profilesWithIncompleteOnboardingCount
    }
  }
  profiles(
    filters: $filters
    sortBy: $sortBy
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      fname
      lname
      fullName
      initials
      image
      title
      email
      telephone
      slug
      confirmedAt
      status
      onboardingComplete
      policy {
        id
        name
      }
      ...TeamRoleColumn
    }
  }
}
    ${TeamRoleColumnFragmentDoc}`;

/**
 * __useTeamOverviewQuery__
 *
 * To run a query within a React component, call `useTeamOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOverviewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTeamOverviewQuery(baseOptions?: Apollo.QueryHookOptions<TeamOverviewQuery, TeamOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamOverviewQuery, TeamOverviewQueryVariables>(TeamOverviewDocument, options);
      }
export function useTeamOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamOverviewQuery, TeamOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamOverviewQuery, TeamOverviewQueryVariables>(TeamOverviewDocument, options);
        }
export type TeamOverviewQueryHookResult = ReturnType<typeof useTeamOverviewQuery>;
export type TeamOverviewLazyQueryHookResult = ReturnType<typeof useTeamOverviewLazyQuery>;
export type TeamOverviewQueryResult = Apollo.QueryResult<TeamOverviewQuery, TeamOverviewQueryVariables>;
export const TeamCompensationsDocument = gql`
    query teamCompensations($filters: ProfileFiltersInput, $sortBy: ProfilesSortInput, $last: Int, $first: Int, $before: String, $after: String) {
  profiles(
    filters: $filters
    sortBy: $sortBy
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      compensations {
        id
      }
    }
  }
}
    `;

/**
 * __useTeamCompensationsQuery__
 *
 * To run a query within a React component, call `useTeamCompensationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamCompensationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamCompensationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTeamCompensationsQuery(baseOptions?: Apollo.QueryHookOptions<TeamCompensationsQuery, TeamCompensationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamCompensationsQuery, TeamCompensationsQueryVariables>(TeamCompensationsDocument, options);
      }
export function useTeamCompensationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamCompensationsQuery, TeamCompensationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamCompensationsQuery, TeamCompensationsQueryVariables>(TeamCompensationsDocument, options);
        }
export type TeamCompensationsQueryHookResult = ReturnType<typeof useTeamCompensationsQuery>;
export type TeamCompensationsLazyQueryHookResult = ReturnType<typeof useTeamCompensationsLazyQuery>;
export type TeamCompensationsQueryResult = Apollo.QueryResult<TeamCompensationsQuery, TeamCompensationsQueryVariables>;
export const PrepaidSeatsDocument = gql`
    query prepaidSeats {
  currentProfile {
    organization {
      subscription {
        prepaidSeatCount
        prepaidSeatsFilled
      }
      size
    }
  }
}
    `;

/**
 * __usePrepaidSeatsQuery__
 *
 * To run a query within a React component, call `usePrepaidSeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrepaidSeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrepaidSeatsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrepaidSeatsQuery(baseOptions?: Apollo.QueryHookOptions<PrepaidSeatsQuery, PrepaidSeatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrepaidSeatsQuery, PrepaidSeatsQueryVariables>(PrepaidSeatsDocument, options);
      }
export function usePrepaidSeatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrepaidSeatsQuery, PrepaidSeatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrepaidSeatsQuery, PrepaidSeatsQueryVariables>(PrepaidSeatsDocument, options);
        }
export type PrepaidSeatsQueryHookResult = ReturnType<typeof usePrepaidSeatsQuery>;
export type PrepaidSeatsLazyQueryHookResult = ReturnType<typeof usePrepaidSeatsLazyQuery>;
export type PrepaidSeatsQueryResult = Apollo.QueryResult<PrepaidSeatsQuery, PrepaidSeatsQueryVariables>;
export const ProfileAlertCountDocument = gql`
    query profileAlertCount {
  currentProfile {
    id
    organization {
      id
      profilesWithoutCompensationsCount
      profilesWithIncompleteOnboardingCount
      unprocessedQboCustomers
    }
  }
}
    `;

/**
 * __useProfileAlertCountQuery__
 *
 * To run a query within a React component, call `useProfileAlertCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileAlertCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileAlertCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileAlertCountQuery(baseOptions?: Apollo.QueryHookOptions<ProfileAlertCountQuery, ProfileAlertCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileAlertCountQuery, ProfileAlertCountQueryVariables>(ProfileAlertCountDocument, options);
      }
export function useProfileAlertCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileAlertCountQuery, ProfileAlertCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileAlertCountQuery, ProfileAlertCountQueryVariables>(ProfileAlertCountDocument, options);
        }
export type ProfileAlertCountQueryHookResult = ReturnType<typeof useProfileAlertCountQuery>;
export type ProfileAlertCountLazyQueryHookResult = ReturnType<typeof useProfileAlertCountLazyQuery>;
export type ProfileAlertCountQueryResult = Apollo.QueryResult<ProfileAlertCountQuery, ProfileAlertCountQueryVariables>;
export const TeamDocument = gql`
    query team {
  currentProfile {
    id
    policy {
      id
      isAdmin
    }
    organization {
      id
      roleTemplates {
        id
        name
        archivedAt
      }
      profiles {
        id
        image
        fname
        lname
        fullName
        initials
        title
        email
        telephone
        slug
        confirmedAt
        status
        policy {
          id
          name
        }
        compensations {
          id
        }
        defaultRoleTemplate {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: Apollo.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
      }
export function useTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, options);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<TeamQuery, TeamQueryVariables>;
export const AdminProfileToggleDocument = gql`
    query AdminProfileToggle {
  currentProfile {
    id
    organization {
      id
      profiles {
        id
        image
        fname
        lname
        initials
        title
        slug
        policy {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProfileToggleQuery__
 *
 * To run a query within a React component, call `useAdminProfileToggleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProfileToggleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProfileToggleQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminProfileToggleQuery(baseOptions?: Apollo.QueryHookOptions<AdminProfileToggleQuery, AdminProfileToggleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProfileToggleQuery, AdminProfileToggleQueryVariables>(AdminProfileToggleDocument, options);
      }
export function useAdminProfileToggleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProfileToggleQuery, AdminProfileToggleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProfileToggleQuery, AdminProfileToggleQueryVariables>(AdminProfileToggleDocument, options);
        }
export type AdminProfileToggleQueryHookResult = ReturnType<typeof useAdminProfileToggleQuery>;
export type AdminProfileToggleLazyQueryHookResult = ReturnType<typeof useAdminProfileToggleLazyQuery>;
export type AdminProfileToggleQueryResult = Apollo.QueryResult<AdminProfileToggleQuery, AdminProfileToggleQueryVariables>;
export const UpdateAdminProfileToggleDocument = gql`
    mutation UpdateAdminProfileToggle($profileId: ID!) {
  admin {
    toggleProfile(input: {profileId: $profileId}) {
      profile {
        id
      }
    }
  }
}
    `;
export type UpdateAdminProfileToggleMutationFn = Apollo.MutationFunction<UpdateAdminProfileToggleMutation, UpdateAdminProfileToggleMutationVariables>;

/**
 * __useUpdateAdminProfileToggleMutation__
 *
 * To run a mutation, you first call `useUpdateAdminProfileToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminProfileToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminProfileToggleMutation, { data, loading, error }] = useUpdateAdminProfileToggleMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useUpdateAdminProfileToggleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminProfileToggleMutation, UpdateAdminProfileToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminProfileToggleMutation, UpdateAdminProfileToggleMutationVariables>(UpdateAdminProfileToggleDocument, options);
      }
export type UpdateAdminProfileToggleMutationHookResult = ReturnType<typeof useUpdateAdminProfileToggleMutation>;
export type UpdateAdminProfileToggleMutationResult = Apollo.MutationResult<UpdateAdminProfileToggleMutation>;
export type UpdateAdminProfileToggleMutationOptions = Apollo.BaseMutationOptions<UpdateAdminProfileToggleMutation, UpdateAdminProfileToggleMutationVariables>;
export const ProfileRoleTemplatesDocument = gql`
    query ProfileRoleTemplates {
  currentProfile {
    id
    organization {
      profiles {
        id
        fullName
        roleTemplate {
          id
          name
        }
      }
      roleTemplates {
        id
        name
        archivedAt
      }
    }
  }
}
    `;

/**
 * __useProfileRoleTemplatesQuery__
 *
 * To run a query within a React component, call `useProfileRoleTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileRoleTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileRoleTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileRoleTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ProfileRoleTemplatesQuery, ProfileRoleTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileRoleTemplatesQuery, ProfileRoleTemplatesQueryVariables>(ProfileRoleTemplatesDocument, options);
      }
export function useProfileRoleTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileRoleTemplatesQuery, ProfileRoleTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileRoleTemplatesQuery, ProfileRoleTemplatesQueryVariables>(ProfileRoleTemplatesDocument, options);
        }
export type ProfileRoleTemplatesQueryHookResult = ReturnType<typeof useProfileRoleTemplatesQuery>;
export type ProfileRoleTemplatesLazyQueryHookResult = ReturnType<typeof useProfileRoleTemplatesLazyQuery>;
export type ProfileRoleTemplatesQueryResult = Apollo.QueryResult<ProfileRoleTemplatesQuery, ProfileRoleTemplatesQueryVariables>;
export const UpdateProfileRoleTemplateDocument = gql`
    mutation UpdateProfileRoleTemplate($profileId: ID!, $roleTemplateId: ID!) {
  updateProfileRoleTemplate(
    input: {profileId: $profileId, roleTemplateId: $roleTemplateId}
  ) {
    profile {
      id
      roleTemplate {
        id
        name
      }
    }
  }
}
    `;
export type UpdateProfileRoleTemplateMutationFn = Apollo.MutationFunction<UpdateProfileRoleTemplateMutation, UpdateProfileRoleTemplateMutationVariables>;

/**
 * __useUpdateProfileRoleTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateProfileRoleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileRoleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileRoleTemplateMutation, { data, loading, error }] = useUpdateProfileRoleTemplateMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      roleTemplateId: // value for 'roleTemplateId'
 *   },
 * });
 */
export function useUpdateProfileRoleTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileRoleTemplateMutation, UpdateProfileRoleTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileRoleTemplateMutation, UpdateProfileRoleTemplateMutationVariables>(UpdateProfileRoleTemplateDocument, options);
      }
export type UpdateProfileRoleTemplateMutationHookResult = ReturnType<typeof useUpdateProfileRoleTemplateMutation>;
export type UpdateProfileRoleTemplateMutationResult = Apollo.MutationResult<UpdateProfileRoleTemplateMutation>;
export type UpdateProfileRoleTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateProfileRoleTemplateMutation, UpdateProfileRoleTemplateMutationVariables>;
export const UpdateProfileDefaultRoleTemplateDocument = gql`
    mutation UpdateProfileDefaultRoleTemplate($profileId: ID!, $roleTemplateId: ID, $rateTableId: ID) {
  updateProfileDefaultRoleTemplate(
    input: {profileId: $profileId, roleTemplateId: $roleTemplateId, rateTableId: $rateTableId}
  ) {
    profile {
      id
      defaultRoleTemplate {
        id
      }
      defaultRateTable {
        id
      }
    }
  }
}
    `;
export type UpdateProfileDefaultRoleTemplateMutationFn = Apollo.MutationFunction<UpdateProfileDefaultRoleTemplateMutation, UpdateProfileDefaultRoleTemplateMutationVariables>;

/**
 * __useUpdateProfileDefaultRoleTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateProfileDefaultRoleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileDefaultRoleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileDefaultRoleTemplateMutation, { data, loading, error }] = useUpdateProfileDefaultRoleTemplateMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      roleTemplateId: // value for 'roleTemplateId'
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useUpdateProfileDefaultRoleTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileDefaultRoleTemplateMutation, UpdateProfileDefaultRoleTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileDefaultRoleTemplateMutation, UpdateProfileDefaultRoleTemplateMutationVariables>(UpdateProfileDefaultRoleTemplateDocument, options);
      }
export type UpdateProfileDefaultRoleTemplateMutationHookResult = ReturnType<typeof useUpdateProfileDefaultRoleTemplateMutation>;
export type UpdateProfileDefaultRoleTemplateMutationResult = Apollo.MutationResult<UpdateProfileDefaultRoleTemplateMutation>;
export type UpdateProfileDefaultRoleTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateProfileDefaultRoleTemplateMutation, UpdateProfileDefaultRoleTemplateMutationVariables>;
export const DeactivateProfileDocument = gql`
    mutation DeactivateProfile($profileId: ID!) {
  deactivateProfile(input: {id: $profileId}) {
    profile {
      id
    }
  }
}
    `;
export type DeactivateProfileMutationFn = Apollo.MutationFunction<DeactivateProfileMutation, DeactivateProfileMutationVariables>;

/**
 * __useDeactivateProfileMutation__
 *
 * To run a mutation, you first call `useDeactivateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateProfileMutation, { data, loading, error }] = useDeactivateProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDeactivateProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateProfileMutation, DeactivateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateProfileMutation, DeactivateProfileMutationVariables>(DeactivateProfileDocument, options);
      }
export type DeactivateProfileMutationHookResult = ReturnType<typeof useDeactivateProfileMutation>;
export type DeactivateProfileMutationResult = Apollo.MutationResult<DeactivateProfileMutation>;
export type DeactivateProfileMutationOptions = Apollo.BaseMutationOptions<DeactivateProfileMutation, DeactivateProfileMutationVariables>;
export const ReactivateProfileDocument = gql`
    mutation ReactivateProfile($profileId: ID!) {
  reactivateProfile(input: {id: $profileId}) {
    profile {
      id
    }
  }
}
    `;
export type ReactivateProfileMutationFn = Apollo.MutationFunction<ReactivateProfileMutation, ReactivateProfileMutationVariables>;

/**
 * __useReactivateProfileMutation__
 *
 * To run a mutation, you first call `useReactivateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateProfileMutation, { data, loading, error }] = useReactivateProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useReactivateProfileMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateProfileMutation, ReactivateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateProfileMutation, ReactivateProfileMutationVariables>(ReactivateProfileDocument, options);
      }
export type ReactivateProfileMutationHookResult = ReturnType<typeof useReactivateProfileMutation>;
export type ReactivateProfileMutationResult = Apollo.MutationResult<ReactivateProfileMutation>;
export type ReactivateProfileMutationOptions = Apollo.BaseMutationOptions<ReactivateProfileMutation, ReactivateProfileMutationVariables>;
export const DeleteProfileDocument = gql`
    mutation DeleteProfile($profileId: ID!) {
  deleteProfile(input: {id: $profileId}) {
    profile {
      id
    }
  }
}
    `;
export type DeleteProfileMutationFn = Apollo.MutationFunction<DeleteProfileMutation, DeleteProfileMutationVariables>;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileMutation, { data, loading, error }] = useDeleteProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDeleteProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileMutation, DeleteProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileDocument, options);
      }
export type DeleteProfileMutationHookResult = ReturnType<typeof useDeleteProfileMutation>;
export type DeleteProfileMutationResult = Apollo.MutationResult<DeleteProfileMutation>;
export type DeleteProfileMutationOptions = Apollo.BaseMutationOptions<DeleteProfileMutation, DeleteProfileMutationVariables>;
export const CreateProfileDocument = gql`
    mutation CreateProfile($fname: String, $lname: String, $policyId: ID, $email: String, $defaultRoleTemplateId: ID, $defaultRateTableId: ID, $sendInvitation: Boolean, $title: String) {
  createProfile(
    input: {fname: $fname, lname: $lname, policyId: $policyId, email: $email, title: $title, defaultRateTableId: $defaultRateTableId, sendInvitation: $sendInvitation, defaultRoleTemplateId: $defaultRoleTemplateId}
  ) {
    profile {
      id
      email
    }
  }
}
    `;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      fname: // value for 'fname'
 *      lname: // value for 'lname'
 *      policyId: // value for 'policyId'
 *      email: // value for 'email'
 *      defaultRoleTemplateId: // value for 'defaultRoleTemplateId'
 *      defaultRateTableId: // value for 'defaultRateTableId'
 *      sendInvitation: // value for 'sendInvitation'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const SendProfileInvitationDocument = gql`
    mutation SendProfileInvitation($profileId: ID!) {
  sendProfileInvitation(input: {id: $profileId}) {
    profile {
      id
    }
  }
}
    `;
export type SendProfileInvitationMutationFn = Apollo.MutationFunction<SendProfileInvitationMutation, SendProfileInvitationMutationVariables>;

/**
 * __useSendProfileInvitationMutation__
 *
 * To run a mutation, you first call `useSendProfileInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProfileInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProfileInvitationMutation, { data, loading, error }] = useSendProfileInvitationMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useSendProfileInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendProfileInvitationMutation, SendProfileInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendProfileInvitationMutation, SendProfileInvitationMutationVariables>(SendProfileInvitationDocument, options);
      }
export type SendProfileInvitationMutationHookResult = ReturnType<typeof useSendProfileInvitationMutation>;
export type SendProfileInvitationMutationResult = Apollo.MutationResult<SendProfileInvitationMutation>;
export type SendProfileInvitationMutationOptions = Apollo.BaseMutationOptions<SendProfileInvitationMutation, SendProfileInvitationMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($id: ID!, $fname: String, $lname: String, $policyId: ID, $title: String, $pronouns: String, $email: String, $image: String, $telephone: String) {
  updateProfile(
    input: {id: $id, fname: $fname, lname: $lname, policyId: $policyId, title: $title, pronouns: $pronouns, email: $email, image: $image, telephone: $telephone}
  ) {
    profile {
      ...ProfileBasicInfo
    }
  }
}
    ${ProfileBasicInfoFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fname: // value for 'fname'
 *      lname: // value for 'lname'
 *      policyId: // value for 'policyId'
 *      title: // value for 'title'
 *      pronouns: // value for 'pronouns'
 *      email: // value for 'email'
 *      image: // value for 'image'
 *      telephone: // value for 'telephone'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateProfileSettingsDocument = gql`
    mutation UpdateProfileSettings($profileId: ID!, $sendDailyEmail: Boolean, $sendTimesheetsSummaryEmail: Boolean) {
  updateProfileSettings(
    input: {profileId: $profileId, sendDailyEmail: $sendDailyEmail, sendTimesheetsSummaryEmail: $sendTimesheetsSummaryEmail}
  ) {
    profile {
      ...ProfileBasicInfo
      ...ProfileSettings
    }
  }
}
    ${ProfileBasicInfoFragmentDoc}
${ProfileSettingsFragmentDoc}`;
export type UpdateProfileSettingsMutationFn = Apollo.MutationFunction<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;

/**
 * __useUpdateProfileSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileSettingsMutation, { data, loading, error }] = useUpdateProfileSettingsMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      sendDailyEmail: // value for 'sendDailyEmail'
 *      sendTimesheetsSummaryEmail: // value for 'sendTimesheetsSummaryEmail'
 *   },
 * });
 */
export function useUpdateProfileSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>(UpdateProfileSettingsDocument, options);
      }
export type UpdateProfileSettingsMutationHookResult = ReturnType<typeof useUpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationResult = Apollo.MutationResult<UpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;
export const UpdateProfileUtilizationRateDocument = gql`
    mutation UpdateProfileUtilizationRate($input: UpdateUtilizationRateInput!) {
  updateProfileUtilizationRate(input: $input) {
    profile {
      id
      utilizationRate
    }
  }
}
    `;
export type UpdateProfileUtilizationRateMutationFn = Apollo.MutationFunction<UpdateProfileUtilizationRateMutation, UpdateProfileUtilizationRateMutationVariables>;

/**
 * __useUpdateProfileUtilizationRateMutation__
 *
 * To run a mutation, you first call `useUpdateProfileUtilizationRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileUtilizationRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileUtilizationRateMutation, { data, loading, error }] = useUpdateProfileUtilizationRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileUtilizationRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileUtilizationRateMutation, UpdateProfileUtilizationRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileUtilizationRateMutation, UpdateProfileUtilizationRateMutationVariables>(UpdateProfileUtilizationRateDocument, options);
      }
export type UpdateProfileUtilizationRateMutationHookResult = ReturnType<typeof useUpdateProfileUtilizationRateMutation>;
export type UpdateProfileUtilizationRateMutationResult = Apollo.MutationResult<UpdateProfileUtilizationRateMutation>;
export type UpdateProfileUtilizationRateMutationOptions = Apollo.BaseMutationOptions<UpdateProfileUtilizationRateMutation, UpdateProfileUtilizationRateMutationVariables>;
export const UpdateInsightsEmailPreferenceDocument = gql`
    mutation UpdateInsightsEmailPreference($input: UpdateInsightsEmailPreferenceInput!) {
  updateInsightsEmailPreference(input: $input) {
    profile {
      ...ProfileBasicInfo
      ...ProfileSettings
    }
  }
}
    ${ProfileBasicInfoFragmentDoc}
${ProfileSettingsFragmentDoc}`;
export type UpdateInsightsEmailPreferenceMutationFn = Apollo.MutationFunction<UpdateInsightsEmailPreferenceMutation, UpdateInsightsEmailPreferenceMutationVariables>;

/**
 * __useUpdateInsightsEmailPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateInsightsEmailPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsightsEmailPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsightsEmailPreferenceMutation, { data, loading, error }] = useUpdateInsightsEmailPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsightsEmailPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInsightsEmailPreferenceMutation, UpdateInsightsEmailPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInsightsEmailPreferenceMutation, UpdateInsightsEmailPreferenceMutationVariables>(UpdateInsightsEmailPreferenceDocument, options);
      }
export type UpdateInsightsEmailPreferenceMutationHookResult = ReturnType<typeof useUpdateInsightsEmailPreferenceMutation>;
export type UpdateInsightsEmailPreferenceMutationResult = Apollo.MutationResult<UpdateInsightsEmailPreferenceMutation>;
export type UpdateInsightsEmailPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateInsightsEmailPreferenceMutation, UpdateInsightsEmailPreferenceMutationVariables>;
export const ProjectPlannerCreatePhaseTemplateDocument = gql`
    mutation ProjectPlannerCreatePhaseTemplate($name: String!, $abbreviation: String!, $feeType: PhaseFee!) {
  createPhaseTemplate(
    input: {name: $name, abbreviation: $abbreviation, feeType: $feeType}
  ) {
    phaseTemplate {
      ...PlannerPhaseTemplate
    }
  }
}
    ${PlannerPhaseTemplateFragmentDoc}`;
export type ProjectPlannerCreatePhaseTemplateMutationFn = Apollo.MutationFunction<ProjectPlannerCreatePhaseTemplateMutation, ProjectPlannerCreatePhaseTemplateMutationVariables>;

/**
 * __useProjectPlannerCreatePhaseTemplateMutation__
 *
 * To run a mutation, you first call `useProjectPlannerCreatePhaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerCreatePhaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectPlannerCreatePhaseTemplateMutation, { data, loading, error }] = useProjectPlannerCreatePhaseTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      feeType: // value for 'feeType'
 *   },
 * });
 */
export function useProjectPlannerCreatePhaseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ProjectPlannerCreatePhaseTemplateMutation, ProjectPlannerCreatePhaseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectPlannerCreatePhaseTemplateMutation, ProjectPlannerCreatePhaseTemplateMutationVariables>(ProjectPlannerCreatePhaseTemplateDocument, options);
      }
export type ProjectPlannerCreatePhaseTemplateMutationHookResult = ReturnType<typeof useProjectPlannerCreatePhaseTemplateMutation>;
export type ProjectPlannerCreatePhaseTemplateMutationResult = Apollo.MutationResult<ProjectPlannerCreatePhaseTemplateMutation>;
export type ProjectPlannerCreatePhaseTemplateMutationOptions = Apollo.BaseMutationOptions<ProjectPlannerCreatePhaseTemplateMutation, ProjectPlannerCreatePhaseTemplateMutationVariables>;
export const ProjectPlannerPhaseTemplatesDocument = gql`
    query ProjectPlannerPhaseTemplates {
  phaseTemplates {
    ...PlannerPhaseTemplate
  }
}
    ${PlannerPhaseTemplateFragmentDoc}`;

/**
 * __useProjectPlannerPhaseTemplatesQuery__
 *
 * To run a query within a React component, call `useProjectPlannerPhaseTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerPhaseTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlannerPhaseTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectPlannerPhaseTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectPlannerPhaseTemplatesQuery, ProjectPlannerPhaseTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlannerPhaseTemplatesQuery, ProjectPlannerPhaseTemplatesQueryVariables>(ProjectPlannerPhaseTemplatesDocument, options);
      }
export function useProjectPlannerPhaseTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlannerPhaseTemplatesQuery, ProjectPlannerPhaseTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlannerPhaseTemplatesQuery, ProjectPlannerPhaseTemplatesQueryVariables>(ProjectPlannerPhaseTemplatesDocument, options);
        }
export type ProjectPlannerPhaseTemplatesQueryHookResult = ReturnType<typeof useProjectPlannerPhaseTemplatesQuery>;
export type ProjectPlannerPhaseTemplatesLazyQueryHookResult = ReturnType<typeof useProjectPlannerPhaseTemplatesLazyQuery>;
export type ProjectPlannerPhaseTemplatesQueryResult = Apollo.QueryResult<ProjectPlannerPhaseTemplatesQuery, ProjectPlannerPhaseTemplatesQueryVariables>;
export const ProjectPlannerProjectTemplatesDocument = gql`
    query ProjectPlannerProjectTemplates {
  currentProfile {
    organization {
      projectTemplates {
        budget
        color
        consultantsFee
        id
        isBillable
        name
        number
        slug
        totalFee
      }
    }
  }
}
    `;

/**
 * __useProjectPlannerProjectTemplatesQuery__
 *
 * To run a query within a React component, call `useProjectPlannerProjectTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerProjectTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlannerProjectTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectPlannerProjectTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectPlannerProjectTemplatesQuery, ProjectPlannerProjectTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlannerProjectTemplatesQuery, ProjectPlannerProjectTemplatesQueryVariables>(ProjectPlannerProjectTemplatesDocument, options);
      }
export function useProjectPlannerProjectTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlannerProjectTemplatesQuery, ProjectPlannerProjectTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlannerProjectTemplatesQuery, ProjectPlannerProjectTemplatesQueryVariables>(ProjectPlannerProjectTemplatesDocument, options);
        }
export type ProjectPlannerProjectTemplatesQueryHookResult = ReturnType<typeof useProjectPlannerProjectTemplatesQuery>;
export type ProjectPlannerProjectTemplatesLazyQueryHookResult = ReturnType<typeof useProjectPlannerProjectTemplatesLazyQuery>;
export type ProjectPlannerProjectTemplatesQueryResult = Apollo.QueryResult<ProjectPlannerProjectTemplatesQuery, ProjectPlannerProjectTemplatesQueryVariables>;
export const ProjectPlannerPlanableDocument = gql`
    query ProjectPlannerPlanable($slug: String!, $isProjectTemplate: Boolean!) {
  planable(slug: $slug, isProjectTemplate: $isProjectTemplate) {
    id
    color
    slug
    budget
    isBillable
    name
    consultantsFee
    notes
    number
    totalFee
    phases {
      ...PlannerPhase
    }
    categories {
      id
      name
    }
  }
}
    ${PlannerPhaseFragmentDoc}`;

/**
 * __useProjectPlannerPlanableQuery__
 *
 * To run a query within a React component, call `useProjectPlannerPlanableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerPlanableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlannerPlanableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isProjectTemplate: // value for 'isProjectTemplate'
 *   },
 * });
 */
export function useProjectPlannerPlanableQuery(baseOptions: Apollo.QueryHookOptions<ProjectPlannerPlanableQuery, ProjectPlannerPlanableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlannerPlanableQuery, ProjectPlannerPlanableQueryVariables>(ProjectPlannerPlanableDocument, options);
      }
export function useProjectPlannerPlanableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlannerPlanableQuery, ProjectPlannerPlanableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlannerPlanableQuery, ProjectPlannerPlanableQueryVariables>(ProjectPlannerPlanableDocument, options);
        }
export type ProjectPlannerPlanableQueryHookResult = ReturnType<typeof useProjectPlannerPlanableQuery>;
export type ProjectPlannerPlanableLazyQueryHookResult = ReturnType<typeof useProjectPlannerPlanableLazyQuery>;
export type ProjectPlannerPlanableQueryResult = Apollo.QueryResult<ProjectPlannerPlanableQuery, ProjectPlannerPlanableQueryVariables>;
export const PlannerProfilesDocument = gql`
    query PlannerProfiles {
  currentProfile {
    organization {
      roleTemplates {
        id
        name
      }
      profiles {
        ...PlannerPhaseProfile
      }
    }
  }
}
    ${PlannerPhaseProfileFragmentDoc}`;

/**
 * __usePlannerProfilesQuery__
 *
 * To run a query within a React component, call `usePlannerProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlannerProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlannerProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlannerProfilesQuery(baseOptions?: Apollo.QueryHookOptions<PlannerProfilesQuery, PlannerProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlannerProfilesQuery, PlannerProfilesQueryVariables>(PlannerProfilesDocument, options);
      }
export function usePlannerProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlannerProfilesQuery, PlannerProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlannerProfilesQuery, PlannerProfilesQueryVariables>(PlannerProfilesDocument, options);
        }
export type PlannerProfilesQueryHookResult = ReturnType<typeof usePlannerProfilesQuery>;
export type PlannerProfilesLazyQueryHookResult = ReturnType<typeof usePlannerProfilesLazyQuery>;
export type PlannerProfilesQueryResult = Apollo.QueryResult<PlannerProfilesQuery, PlannerProfilesQueryVariables>;
export const ProjectPlannerPhaseDocument = gql`
    query ProjectPlannerPhase($id: ID!) {
  phase(id: $id) {
    ...PlannerPhase
  }
}
    ${PlannerPhaseFragmentDoc}`;

/**
 * __useProjectPlannerPhaseQuery__
 *
 * To run a query within a React component, call `useProjectPlannerPhaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerPhaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlannerPhaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectPlannerPhaseQuery(baseOptions: Apollo.QueryHookOptions<ProjectPlannerPhaseQuery, ProjectPlannerPhaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlannerPhaseQuery, ProjectPlannerPhaseQueryVariables>(ProjectPlannerPhaseDocument, options);
      }
export function useProjectPlannerPhaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlannerPhaseQuery, ProjectPlannerPhaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlannerPhaseQuery, ProjectPlannerPhaseQueryVariables>(ProjectPlannerPhaseDocument, options);
        }
export type ProjectPlannerPhaseQueryHookResult = ReturnType<typeof useProjectPlannerPhaseQuery>;
export type ProjectPlannerPhaseLazyQueryHookResult = ReturnType<typeof useProjectPlannerPhaseLazyQuery>;
export type ProjectPlannerPhaseQueryResult = Apollo.QueryResult<ProjectPlannerPhaseQuery, ProjectPlannerPhaseQueryVariables>;
export const ProjectPlannerProfilesDocument = gql`
    query ProjectPlannerProfiles {
  currentProfile {
    organization {
      profiles {
        ...PlannerPhaseProfile
      }
    }
  }
}
    ${PlannerPhaseProfileFragmentDoc}`;

/**
 * __useProjectPlannerProfilesQuery__
 *
 * To run a query within a React component, call `useProjectPlannerProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlannerProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlannerProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectPlannerProfilesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectPlannerProfilesQuery, ProjectPlannerProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlannerProfilesQuery, ProjectPlannerProfilesQueryVariables>(ProjectPlannerProfilesDocument, options);
      }
export function useProjectPlannerProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlannerProfilesQuery, ProjectPlannerProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlannerProfilesQuery, ProjectPlannerProfilesQueryVariables>(ProjectPlannerProfilesDocument, options);
        }
export type ProjectPlannerProfilesQueryHookResult = ReturnType<typeof useProjectPlannerProfilesQuery>;
export type ProjectPlannerProfilesLazyQueryHookResult = ReturnType<typeof useProjectPlannerProfilesLazyQuery>;
export type ProjectPlannerProfilesQueryResult = Apollo.QueryResult<ProjectPlannerProfilesQuery, ProjectPlannerProfilesQueryVariables>;
export const ProjectExpenseCategoriesDocument = gql`
    query projectExpenseCategories($projectId: ID!) {
  projectExpenseCategories(projectId: $projectId) {
    ...ProjectPlannerExpenseCategory
  }
}
    ${ProjectPlannerExpenseCategoryFragmentDoc}`;

/**
 * __useProjectExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useProjectExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectExpenseCategoriesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectExpenseCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProjectExpenseCategoriesQuery, ProjectExpenseCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectExpenseCategoriesQuery, ProjectExpenseCategoriesQueryVariables>(ProjectExpenseCategoriesDocument, options);
      }
export function useProjectExpenseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectExpenseCategoriesQuery, ProjectExpenseCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectExpenseCategoriesQuery, ProjectExpenseCategoriesQueryVariables>(ProjectExpenseCategoriesDocument, options);
        }
export type ProjectExpenseCategoriesQueryHookResult = ReturnType<typeof useProjectExpenseCategoriesQuery>;
export type ProjectExpenseCategoriesLazyQueryHookResult = ReturnType<typeof useProjectExpenseCategoriesLazyQuery>;
export type ProjectExpenseCategoriesQueryResult = Apollo.QueryResult<ProjectExpenseCategoriesQuery, ProjectExpenseCategoriesQueryVariables>;
export const UpdateExpenseCategoryForProjectDocument = gql`
    mutation UpdateExpenseCategoryForProject($id: ID!, $projectId: ID!, $markupPercentage: Float!, $applyToAllForProject: Boolean!) {
  updateExpenseCategoryForProject(
    input: {id: $id, projectId: $projectId, markupPercentage: $markupPercentage, applyToAllForProject: $applyToAllForProject}
  ) {
    expenseCategory {
      id
      name
      markupPercentage
    }
  }
}
    `;
export type UpdateExpenseCategoryForProjectMutationFn = Apollo.MutationFunction<UpdateExpenseCategoryForProjectMutation, UpdateExpenseCategoryForProjectMutationVariables>;

/**
 * __useUpdateExpenseCategoryForProjectMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseCategoryForProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseCategoryForProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseCategoryForProjectMutation, { data, loading, error }] = useUpdateExpenseCategoryForProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      markupPercentage: // value for 'markupPercentage'
 *      applyToAllForProject: // value for 'applyToAllForProject'
 *   },
 * });
 */
export function useUpdateExpenseCategoryForProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseCategoryForProjectMutation, UpdateExpenseCategoryForProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseCategoryForProjectMutation, UpdateExpenseCategoryForProjectMutationVariables>(UpdateExpenseCategoryForProjectDocument, options);
      }
export type UpdateExpenseCategoryForProjectMutationHookResult = ReturnType<typeof useUpdateExpenseCategoryForProjectMutation>;
export type UpdateExpenseCategoryForProjectMutationResult = Apollo.MutationResult<UpdateExpenseCategoryForProjectMutation>;
export type UpdateExpenseCategoryForProjectMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseCategoryForProjectMutation, UpdateExpenseCategoryForProjectMutationVariables>;
export const CreateCustomPhaseDocument = gql`
    mutation CreateCustomPhase($name: String!, $feeType: PhaseFee!, $percent: Float, $budget: Float, $abbreviation: String!, $beginDate: ISO8601Date!, $endDate: ISO8601Date!, $projectId: ID, $projectTemplateId: ID, $linkedPhaseId: ID) {
  createPhase(
    input: {name: $name, feeType: $feeType, abbreviation: $abbreviation, percent: $percent, budget: $budget, beginDate: $beginDate, endDate: $endDate, projectId: $projectId, projectTemplateId: $projectTemplateId, linkedPhaseId: $linkedPhaseId}
  ) {
    phase {
      ...PlannerPhase
    }
  }
}
    ${PlannerPhaseFragmentDoc}`;
export type CreateCustomPhaseMutationFn = Apollo.MutationFunction<CreateCustomPhaseMutation, CreateCustomPhaseMutationVariables>;

/**
 * __useCreateCustomPhaseMutation__
 *
 * To run a mutation, you first call `useCreateCustomPhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPhaseMutation, { data, loading, error }] = useCreateCustomPhaseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      feeType: // value for 'feeType'
 *      percent: // value for 'percent'
 *      budget: // value for 'budget'
 *      abbreviation: // value for 'abbreviation'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      projectId: // value for 'projectId'
 *      projectTemplateId: // value for 'projectTemplateId'
 *      linkedPhaseId: // value for 'linkedPhaseId'
 *   },
 * });
 */
export function useCreateCustomPhaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomPhaseMutation, CreateCustomPhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomPhaseMutation, CreateCustomPhaseMutationVariables>(CreateCustomPhaseDocument, options);
      }
export type CreateCustomPhaseMutationHookResult = ReturnType<typeof useCreateCustomPhaseMutation>;
export type CreateCustomPhaseMutationResult = Apollo.MutationResult<CreateCustomPhaseMutation>;
export type CreateCustomPhaseMutationOptions = Apollo.BaseMutationOptions<CreateCustomPhaseMutation, CreateCustomPhaseMutationVariables>;
export const CreatePhaseFromTemplateDocument = gql`
    mutation CreatePhaseFromTemplate($phaseTemplateId: ID!, $percent: Float, $budget: Float, $beginDate: ISO8601Date!, $endDate: ISO8601Date!, $projectId: ID, $projectTemplateId: ID, $feeType: PhaseFee, $linkedPhaseId: ID) {
  createPhaseFromTemplate(
    input: {beginDate: $beginDate, budget: $budget, endDate: $endDate, feeType: $feeType, linkedPhaseId: $linkedPhaseId, percent: $percent, phaseTemplateId: $phaseTemplateId, projectId: $projectId, projectTemplateId: $projectTemplateId}
  ) {
    phase {
      ...PlannerPhase
    }
  }
}
    ${PlannerPhaseFragmentDoc}`;
export type CreatePhaseFromTemplateMutationFn = Apollo.MutationFunction<CreatePhaseFromTemplateMutation, CreatePhaseFromTemplateMutationVariables>;

/**
 * __useCreatePhaseFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePhaseFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhaseFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhaseFromTemplateMutation, { data, loading, error }] = useCreatePhaseFromTemplateMutation({
 *   variables: {
 *      phaseTemplateId: // value for 'phaseTemplateId'
 *      percent: // value for 'percent'
 *      budget: // value for 'budget'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      projectId: // value for 'projectId'
 *      projectTemplateId: // value for 'projectTemplateId'
 *      feeType: // value for 'feeType'
 *      linkedPhaseId: // value for 'linkedPhaseId'
 *   },
 * });
 */
export function useCreatePhaseFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhaseFromTemplateMutation, CreatePhaseFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhaseFromTemplateMutation, CreatePhaseFromTemplateMutationVariables>(CreatePhaseFromTemplateDocument, options);
      }
export type CreatePhaseFromTemplateMutationHookResult = ReturnType<typeof useCreatePhaseFromTemplateMutation>;
export type CreatePhaseFromTemplateMutationResult = Apollo.MutationResult<CreatePhaseFromTemplateMutation>;
export type CreatePhaseFromTemplateMutationOptions = Apollo.BaseMutationOptions<CreatePhaseFromTemplateMutation, CreatePhaseFromTemplateMutationVariables>;
export const DeletePhaseDocument = gql`
    mutation DeletePhase($id: ID!) {
  deletePhase(input: {id: $id}) {
    phase {
      id
      name
    }
  }
}
    `;
export type DeletePhaseMutationFn = Apollo.MutationFunction<DeletePhaseMutation, DeletePhaseMutationVariables>;

/**
 * __useDeletePhaseMutation__
 *
 * To run a mutation, you first call `useDeletePhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhaseMutation, { data, loading, error }] = useDeletePhaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhaseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhaseMutation, DeletePhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhaseMutation, DeletePhaseMutationVariables>(DeletePhaseDocument, options);
      }
export type DeletePhaseMutationHookResult = ReturnType<typeof useDeletePhaseMutation>;
export type DeletePhaseMutationResult = Apollo.MutationResult<DeletePhaseMutation>;
export type DeletePhaseMutationOptions = Apollo.BaseMutationOptions<DeletePhaseMutation, DeletePhaseMutationVariables>;
export const UpdatePhaseDocument = gql`
    mutation UpdatePhase($id: ID!, $name: String, $abbreviation: String, $percent: Float, $budget: Float, $linkedPhaseId: ID, $feeType: PhaseFee, $phaseTemplateId: ID, $beginDate: ISO8601Date, $endDate: ISO8601Date, $updateRoleHours: Boolean) {
  updatePhase(
    input: {id: $id, name: $name, abbreviation: $abbreviation, percent: $percent, budget: $budget, linkedPhaseId: $linkedPhaseId, feeType: $feeType, phaseTemplateId: $phaseTemplateId, beginDate: $beginDate, endDate: $endDate, updateRoleHours: $updateRoleHours}
  ) {
    phase {
      ...PlannerPhase
    }
  }
}
    ${PlannerPhaseFragmentDoc}`;
export type UpdatePhaseMutationFn = Apollo.MutationFunction<UpdatePhaseMutation, UpdatePhaseMutationVariables>;

/**
 * __useUpdatePhaseMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseMutation, { data, loading, error }] = useUpdatePhaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      percent: // value for 'percent'
 *      budget: // value for 'budget'
 *      linkedPhaseId: // value for 'linkedPhaseId'
 *      feeType: // value for 'feeType'
 *      phaseTemplateId: // value for 'phaseTemplateId'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      updateRoleHours: // value for 'updateRoleHours'
 *   },
 * });
 */
export function useUpdatePhaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhaseMutation, UpdatePhaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhaseMutation, UpdatePhaseMutationVariables>(UpdatePhaseDocument, options);
      }
export type UpdatePhaseMutationHookResult = ReturnType<typeof useUpdatePhaseMutation>;
export type UpdatePhaseMutationResult = Apollo.MutationResult<UpdatePhaseMutation>;
export type UpdatePhaseMutationOptions = Apollo.BaseMutationOptions<UpdatePhaseMutation, UpdatePhaseMutationVariables>;
export const UpdatePhasePositionDocument = gql`
    mutation UpdatePhasePosition($id: ID!, $position: Int!) {
  updatePhasePosition(input: {id: $id, position: $position}) {
    phase {
      id
      position
    }
  }
}
    `;
export type UpdatePhasePositionMutationFn = Apollo.MutationFunction<UpdatePhasePositionMutation, UpdatePhasePositionMutationVariables>;

/**
 * __useUpdatePhasePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePhasePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhasePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhasePositionMutation, { data, loading, error }] = useUpdatePhasePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdatePhasePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhasePositionMutation, UpdatePhasePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhasePositionMutation, UpdatePhasePositionMutationVariables>(UpdatePhasePositionDocument, options);
      }
export type UpdatePhasePositionMutationHookResult = ReturnType<typeof useUpdatePhasePositionMutation>;
export type UpdatePhasePositionMutationResult = Apollo.MutationResult<UpdatePhasePositionMutation>;
export type UpdatePhasePositionMutationOptions = Apollo.BaseMutationOptions<UpdatePhasePositionMutation, UpdatePhasePositionMutationVariables>;
export const UpdateRoleProfileDocument = gql`
    mutation UpdateRoleProfile($input: UpdateRoleProfileInput!) {
  updateRoleProfile(input: $input) {
    role {
      ...PhaseRole
    }
  }
}
    ${PhaseRoleFragmentDoc}`;
export type UpdateRoleProfileMutationFn = Apollo.MutationFunction<UpdateRoleProfileMutation, UpdateRoleProfileMutationVariables>;

/**
 * __useUpdateRoleProfileMutation__
 *
 * To run a mutation, you first call `useUpdateRoleProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleProfileMutation, { data, loading, error }] = useUpdateRoleProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleProfileMutation, UpdateRoleProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleProfileMutation, UpdateRoleProfileMutationVariables>(UpdateRoleProfileDocument, options);
      }
export type UpdateRoleProfileMutationHookResult = ReturnType<typeof useUpdateRoleProfileMutation>;
export type UpdateRoleProfileMutationResult = Apollo.MutationResult<UpdateRoleProfileMutation>;
export type UpdateRoleProfileMutationOptions = Apollo.BaseMutationOptions<UpdateRoleProfileMutation, UpdateRoleProfileMutationVariables>;
export const UpdateRoleHoursDocument = gql`
    mutation UpdateRoleHours($input: UpdateRoleHoursInput!) {
  updateRoleHours(input: $input) {
    role {
      ...PhaseRole
    }
  }
}
    ${PhaseRoleFragmentDoc}`;
export type UpdateRoleHoursMutationFn = Apollo.MutationFunction<UpdateRoleHoursMutation, UpdateRoleHoursMutationVariables>;

/**
 * __useUpdateRoleHoursMutation__
 *
 * To run a mutation, you first call `useUpdateRoleHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleHoursMutation, { data, loading, error }] = useUpdateRoleHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleHoursMutation, UpdateRoleHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleHoursMutation, UpdateRoleHoursMutationVariables>(UpdateRoleHoursDocument, options);
      }
export type UpdateRoleHoursMutationHookResult = ReturnType<typeof useUpdateRoleHoursMutation>;
export type UpdateRoleHoursMutationResult = Apollo.MutationResult<UpdateRoleHoursMutation>;
export type UpdateRoleHoursMutationOptions = Apollo.BaseMutationOptions<UpdateRoleHoursMutation, UpdateRoleHoursMutationVariables>;
export const UpdateRoleRoleTemplateAndRateDocument = gql`
    mutation UpdateRoleRoleTemplateAndRate($input: UpdateRoleRoleTemplateAndRateInput!) {
  updateRoleRoleTemplateAndRate(input: $input) {
    role {
      ...PhaseRole
    }
  }
}
    ${PhaseRoleFragmentDoc}`;
export type UpdateRoleRoleTemplateAndRateMutationFn = Apollo.MutationFunction<UpdateRoleRoleTemplateAndRateMutation, UpdateRoleRoleTemplateAndRateMutationVariables>;

/**
 * __useUpdateRoleRoleTemplateAndRateMutation__
 *
 * To run a mutation, you first call `useUpdateRoleRoleTemplateAndRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleRoleTemplateAndRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleRoleTemplateAndRateMutation, { data, loading, error }] = useUpdateRoleRoleTemplateAndRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleRoleTemplateAndRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleRoleTemplateAndRateMutation, UpdateRoleRoleTemplateAndRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleRoleTemplateAndRateMutation, UpdateRoleRoleTemplateAndRateMutationVariables>(UpdateRoleRoleTemplateAndRateDocument, options);
      }
export type UpdateRoleRoleTemplateAndRateMutationHookResult = ReturnType<typeof useUpdateRoleRoleTemplateAndRateMutation>;
export type UpdateRoleRoleTemplateAndRateMutationResult = Apollo.MutationResult<UpdateRoleRoleTemplateAndRateMutation>;
export type UpdateRoleRoleTemplateAndRateMutationOptions = Apollo.BaseMutationOptions<UpdateRoleRoleTemplateAndRateMutation, UpdateRoleRoleTemplateAndRateMutationVariables>;
export const UpdateRolePositionDocument = gql`
    mutation UpdateRolePosition($id: ID!, $position: Int!) {
  updateRolePosition(input: {id: $id, position: $position}) {
    role {
      id
      position
    }
  }
}
    `;
export type UpdateRolePositionMutationFn = Apollo.MutationFunction<UpdateRolePositionMutation, UpdateRolePositionMutationVariables>;

/**
 * __useUpdateRolePositionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePositionMutation, { data, loading, error }] = useUpdateRolePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateRolePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRolePositionMutation, UpdateRolePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRolePositionMutation, UpdateRolePositionMutationVariables>(UpdateRolePositionDocument, options);
      }
export type UpdateRolePositionMutationHookResult = ReturnType<typeof useUpdateRolePositionMutation>;
export type UpdateRolePositionMutationResult = Apollo.MutationResult<UpdateRolePositionMutation>;
export type UpdateRolePositionMutationOptions = Apollo.BaseMutationOptions<UpdateRolePositionMutation, UpdateRolePositionMutationVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    role {
      ...PhaseRole
    }
    phase {
      id
      roles {
        id
        profile {
          id
          timesheets {
            ...StaffingTimesheet
          }
        }
      }
    }
  }
}
    ${PhaseRoleFragmentDoc}
${StaffingTimesheetFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input) {
    roles {
      ...PhaseRole
    }
  }
}
    ${PhaseRoleFragmentDoc}`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const AssignRoleToAllPhasesDocument = gql`
    mutation AssignRoleToAllPhases($id: ID!) {
  assignRoleToAllPhases(input: {id: $id}) {
    role {
      ...PhaseRole
    }
  }
}
    ${PhaseRoleFragmentDoc}`;
export type AssignRoleToAllPhasesMutationFn = Apollo.MutationFunction<AssignRoleToAllPhasesMutation, AssignRoleToAllPhasesMutationVariables>;

/**
 * __useAssignRoleToAllPhasesMutation__
 *
 * To run a mutation, you first call `useAssignRoleToAllPhasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleToAllPhasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoleToAllPhasesMutation, { data, loading, error }] = useAssignRoleToAllPhasesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignRoleToAllPhasesMutation(baseOptions?: Apollo.MutationHookOptions<AssignRoleToAllPhasesMutation, AssignRoleToAllPhasesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRoleToAllPhasesMutation, AssignRoleToAllPhasesMutationVariables>(AssignRoleToAllPhasesDocument, options);
      }
export type AssignRoleToAllPhasesMutationHookResult = ReturnType<typeof useAssignRoleToAllPhasesMutation>;
export type AssignRoleToAllPhasesMutationResult = Apollo.MutationResult<AssignRoleToAllPhasesMutation>;
export type AssignRoleToAllPhasesMutationOptions = Apollo.BaseMutationOptions<AssignRoleToAllPhasesMutation, AssignRoleToAllPhasesMutationVariables>;
export const UpdatePlanableBudgetDocument = gql`
    mutation UpdatePlanableBudget($input: PlanableBudgetUpdateInput!) {
  updatePlanableBudget(input: $input) {
    planable {
      ...PlannerPlanable
    }
  }
}
    ${PlannerPlanableFragmentDoc}`;
export type UpdatePlanableBudgetMutationFn = Apollo.MutationFunction<UpdatePlanableBudgetMutation, UpdatePlanableBudgetMutationVariables>;

/**
 * __useUpdatePlanableBudgetMutation__
 *
 * To run a mutation, you first call `useUpdatePlanableBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanableBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanableBudgetMutation, { data, loading, error }] = useUpdatePlanableBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanableBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanableBudgetMutation, UpdatePlanableBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanableBudgetMutation, UpdatePlanableBudgetMutationVariables>(UpdatePlanableBudgetDocument, options);
      }
export type UpdatePlanableBudgetMutationHookResult = ReturnType<typeof useUpdatePlanableBudgetMutation>;
export type UpdatePlanableBudgetMutationResult = Apollo.MutationResult<UpdatePlanableBudgetMutation>;
export type UpdatePlanableBudgetMutationOptions = Apollo.BaseMutationOptions<UpdatePlanableBudgetMutation, UpdatePlanableBudgetMutationVariables>;
export const BulkGenerateRoleAllocationsDocument = gql`
    mutation BulkGenerateRoleAllocations($roleIds: [ID!]!) {
  bulkGenerateStaffingAllocations(input: {roleIds: $roleIds}) {
    entryGroups {
      id
    }
  }
}
    `;
export type BulkGenerateRoleAllocationsMutationFn = Apollo.MutationFunction<BulkGenerateRoleAllocationsMutation, BulkGenerateRoleAllocationsMutationVariables>;

/**
 * __useBulkGenerateRoleAllocationsMutation__
 *
 * To run a mutation, you first call `useBulkGenerateRoleAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkGenerateRoleAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkGenerateRoleAllocationsMutation, { data, loading, error }] = useBulkGenerateRoleAllocationsMutation({
 *   variables: {
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useBulkGenerateRoleAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<BulkGenerateRoleAllocationsMutation, BulkGenerateRoleAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkGenerateRoleAllocationsMutation, BulkGenerateRoleAllocationsMutationVariables>(BulkGenerateRoleAllocationsDocument, options);
      }
export type BulkGenerateRoleAllocationsMutationHookResult = ReturnType<typeof useBulkGenerateRoleAllocationsMutation>;
export type BulkGenerateRoleAllocationsMutationResult = Apollo.MutationResult<BulkGenerateRoleAllocationsMutation>;
export type BulkGenerateRoleAllocationsMutationOptions = Apollo.BaseMutationOptions<BulkGenerateRoleAllocationsMutation, BulkGenerateRoleAllocationsMutationVariables>;
export const DeleteRoleInfoDocument = gql`
    query DeleteRoleInfo($id: ID!) {
  role(id: $id) {
    allRolesEntryHoursCount
  }
}
    `;

/**
 * __useDeleteRoleInfoQuery__
 *
 * To run a query within a React component, call `useDeleteRoleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteRoleInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleInfoQuery(baseOptions: Apollo.QueryHookOptions<DeleteRoleInfoQuery, DeleteRoleInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeleteRoleInfoQuery, DeleteRoleInfoQueryVariables>(DeleteRoleInfoDocument, options);
      }
export function useDeleteRoleInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeleteRoleInfoQuery, DeleteRoleInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeleteRoleInfoQuery, DeleteRoleInfoQueryVariables>(DeleteRoleInfoDocument, options);
        }
export type DeleteRoleInfoQueryHookResult = ReturnType<typeof useDeleteRoleInfoQuery>;
export type DeleteRoleInfoLazyQueryHookResult = ReturnType<typeof useDeleteRoleInfoLazyQuery>;
export type DeleteRoleInfoQueryResult = Apollo.QueryResult<DeleteRoleInfoQuery, DeleteRoleInfoQueryVariables>;
export const ProjectTemplatesDocument = gql`
    query ProjectTemplates($after: String, $before: String, $first: Int, $last: Int, $sortBy: ProjectTemplatesSortInput) {
  projectTemplates(
    after: $after
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      ...ProjectTemplateGeneralInfo
    }
  }
}
    ${ProjectTemplateGeneralInfoFragmentDoc}`;

/**
 * __useProjectTemplatesQuery__
 *
 * To run a query within a React component, call `useProjectTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTemplatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProjectTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(ProjectTemplatesDocument, options);
      }
export function useProjectTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>(ProjectTemplatesDocument, options);
        }
export type ProjectTemplatesQueryHookResult = ReturnType<typeof useProjectTemplatesQuery>;
export type ProjectTemplatesLazyQueryHookResult = ReturnType<typeof useProjectTemplatesLazyQuery>;
export type ProjectTemplatesQueryResult = Apollo.QueryResult<ProjectTemplatesQuery, ProjectTemplatesQueryVariables>;
export const CreateProjectTemplateDocument = gql`
    mutation CreateProjectTemplate($input: CreateProjectTemplateInput!) {
  createProjectTemplate(input: $input) {
    projectTemplate {
      ...ProjectTemplateGeneralInfo
    }
  }
}
    ${ProjectTemplateGeneralInfoFragmentDoc}`;
export type CreateProjectTemplateMutationFn = Apollo.MutationFunction<CreateProjectTemplateMutation, CreateProjectTemplateMutationVariables>;

/**
 * __useCreateProjectTemplateMutation__
 *
 * To run a mutation, you first call `useCreateProjectTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTemplateMutation, { data, loading, error }] = useCreateProjectTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectTemplateMutation, CreateProjectTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectTemplateMutation, CreateProjectTemplateMutationVariables>(CreateProjectTemplateDocument, options);
      }
export type CreateProjectTemplateMutationHookResult = ReturnType<typeof useCreateProjectTemplateMutation>;
export type CreateProjectTemplateMutationResult = Apollo.MutationResult<CreateProjectTemplateMutation>;
export type CreateProjectTemplateMutationOptions = Apollo.BaseMutationOptions<CreateProjectTemplateMutation, CreateProjectTemplateMutationVariables>;
export const DeleteProjectTemplateDocument = gql`
    mutation DeleteProjectTemplate($id: ID!) {
  deleteProjectTemplate(input: {id: $id}) {
    projectTemplate {
      id
    }
  }
}
    `;
export type DeleteProjectTemplateMutationFn = Apollo.MutationFunction<DeleteProjectTemplateMutation, DeleteProjectTemplateMutationVariables>;

/**
 * __useDeleteProjectTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTemplateMutation, { data, loading, error }] = useDeleteProjectTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectTemplateMutation, DeleteProjectTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectTemplateMutation, DeleteProjectTemplateMutationVariables>(DeleteProjectTemplateDocument, options);
      }
export type DeleteProjectTemplateMutationHookResult = ReturnType<typeof useDeleteProjectTemplateMutation>;
export type DeleteProjectTemplateMutationResult = Apollo.MutationResult<DeleteProjectTemplateMutation>;
export type DeleteProjectTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteProjectTemplateMutation, DeleteProjectTemplateMutationVariables>;
export const UpdateProjectTemplateBasicInfoDocument = gql`
    mutation UpdateProjectTemplateBasicInfo($input: ProjectTemplateBasicInfoUpdateInput!) {
  updateProjectTemplateBasicInfo(input: $input) {
    projectTemplate {
      ...ProjectTemplateGeneralInfo
    }
  }
}
    ${ProjectTemplateGeneralInfoFragmentDoc}`;
export type UpdateProjectTemplateBasicInfoMutationFn = Apollo.MutationFunction<UpdateProjectTemplateBasicInfoMutation, UpdateProjectTemplateBasicInfoMutationVariables>;

/**
 * __useUpdateProjectTemplateBasicInfoMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTemplateBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTemplateBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTemplateBasicInfoMutation, { data, loading, error }] = useUpdateProjectTemplateBasicInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectTemplateBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTemplateBasicInfoMutation, UpdateProjectTemplateBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTemplateBasicInfoMutation, UpdateProjectTemplateBasicInfoMutationVariables>(UpdateProjectTemplateBasicInfoDocument, options);
      }
export type UpdateProjectTemplateBasicInfoMutationHookResult = ReturnType<typeof useUpdateProjectTemplateBasicInfoMutation>;
export type UpdateProjectTemplateBasicInfoMutationResult = Apollo.MutationResult<UpdateProjectTemplateBasicInfoMutation>;
export type UpdateProjectTemplateBasicInfoMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTemplateBasicInfoMutation, UpdateProjectTemplateBasicInfoMutationVariables>;
export const ProjectColorsDocument = gql`
    query ProjectColors {
  projectColors {
    number
    hex
  }
}
    `;

/**
 * __useProjectColorsQuery__
 *
 * To run a query within a React component, call `useProjectColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectColorsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectColorsQuery, ProjectColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectColorsQuery, ProjectColorsQueryVariables>(ProjectColorsDocument, options);
      }
export function useProjectColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectColorsQuery, ProjectColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectColorsQuery, ProjectColorsQueryVariables>(ProjectColorsDocument, options);
        }
export type ProjectColorsQueryHookResult = ReturnType<typeof useProjectColorsQuery>;
export type ProjectColorsLazyQueryHookResult = ReturnType<typeof useProjectColorsLazyQuery>;
export type ProjectColorsQueryResult = Apollo.QueryResult<ProjectColorsQuery, ProjectColorsQueryVariables>;
export const ProjectGeneralInfoSettingsDocument = gql`
    query ProjectGeneralInfoSettings {
  allClients {
    id
    displayName
    syncedToQbo
    qboCustomer {
      id
    }
  }
  currentProfile {
    organization {
      categories {
        id
        name
      }
      expenseCategories {
        id
        name
        markupPercentage
      }
    }
  }
}
    `;

/**
 * __useProjectGeneralInfoSettingsQuery__
 *
 * To run a query within a React component, call `useProjectGeneralInfoSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGeneralInfoSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGeneralInfoSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectGeneralInfoSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectGeneralInfoSettingsQuery, ProjectGeneralInfoSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectGeneralInfoSettingsQuery, ProjectGeneralInfoSettingsQueryVariables>(ProjectGeneralInfoSettingsDocument, options);
      }
export function useProjectGeneralInfoSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectGeneralInfoSettingsQuery, ProjectGeneralInfoSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectGeneralInfoSettingsQuery, ProjectGeneralInfoSettingsQueryVariables>(ProjectGeneralInfoSettingsDocument, options);
        }
export type ProjectGeneralInfoSettingsQueryHookResult = ReturnType<typeof useProjectGeneralInfoSettingsQuery>;
export type ProjectGeneralInfoSettingsLazyQueryHookResult = ReturnType<typeof useProjectGeneralInfoSettingsLazyQuery>;
export type ProjectGeneralInfoSettingsQueryResult = Apollo.QueryResult<ProjectGeneralInfoSettingsQuery, ProjectGeneralInfoSettingsQueryVariables>;
export const ProjectGeneralInfoDocument = gql`
    query ProjectGeneralInfo($slug: String!) {
  project(slug: $slug) {
    ...ProjectGeneralInfo
  }
}
    ${ProjectGeneralInfoFragmentDoc}`;

/**
 * __useProjectGeneralInfoQuery__
 *
 * To run a query within a React component, call `useProjectGeneralInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGeneralInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGeneralInfoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectGeneralInfoQuery(baseOptions: Apollo.QueryHookOptions<ProjectGeneralInfoQuery, ProjectGeneralInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectGeneralInfoQuery, ProjectGeneralInfoQueryVariables>(ProjectGeneralInfoDocument, options);
      }
export function useProjectGeneralInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectGeneralInfoQuery, ProjectGeneralInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectGeneralInfoQuery, ProjectGeneralInfoQueryVariables>(ProjectGeneralInfoDocument, options);
        }
export type ProjectGeneralInfoQueryHookResult = ReturnType<typeof useProjectGeneralInfoQuery>;
export type ProjectGeneralInfoLazyQueryHookResult = ReturnType<typeof useProjectGeneralInfoLazyQuery>;
export type ProjectGeneralInfoQueryResult = Apollo.QueryResult<ProjectGeneralInfoQuery, ProjectGeneralInfoQueryVariables>;
export const ProjectPageCommonDataDocument = gql`
    query ProjectPageCommonData($slug: String!) {
  project(slug: $slug) {
    ...ProjectCommonDisplayData
  }
}
    ${ProjectCommonDisplayDataFragmentDoc}`;

/**
 * __useProjectPageCommonDataQuery__
 *
 * To run a query within a React component, call `useProjectPageCommonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPageCommonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPageCommonDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectPageCommonDataQuery(baseOptions: Apollo.QueryHookOptions<ProjectPageCommonDataQuery, ProjectPageCommonDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPageCommonDataQuery, ProjectPageCommonDataQueryVariables>(ProjectPageCommonDataDocument, options);
      }
export function useProjectPageCommonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPageCommonDataQuery, ProjectPageCommonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPageCommonDataQuery, ProjectPageCommonDataQueryVariables>(ProjectPageCommonDataDocument, options);
        }
export type ProjectPageCommonDataQueryHookResult = ReturnType<typeof useProjectPageCommonDataQuery>;
export type ProjectPageCommonDataLazyQueryHookResult = ReturnType<typeof useProjectPageCommonDataLazyQuery>;
export type ProjectPageCommonDataQueryResult = Apollo.QueryResult<ProjectPageCommonDataQuery, ProjectPageCommonDataQueryVariables>;
export const ProjectOptionsDocument = gql`
    query ProjectOptions {
  projects {
    id
    ...ProjectCommonDisplayData
  }
}
    ${ProjectCommonDisplayDataFragmentDoc}`;

/**
 * __useProjectOptionsQuery__
 *
 * To run a query within a React component, call `useProjectOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectOptionsQuery, ProjectOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectOptionsQuery, ProjectOptionsQueryVariables>(ProjectOptionsDocument, options);
      }
export function useProjectOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectOptionsQuery, ProjectOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectOptionsQuery, ProjectOptionsQueryVariables>(ProjectOptionsDocument, options);
        }
export type ProjectOptionsQueryHookResult = ReturnType<typeof useProjectOptionsQuery>;
export type ProjectOptionsLazyQueryHookResult = ReturnType<typeof useProjectOptionsLazyQuery>;
export type ProjectOptionsQueryResult = Apollo.QueryResult<ProjectOptionsQuery, ProjectOptionsQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($includeArchived: Boolean) {
  projects(includeArchived: $includeArchived) {
    id
    name
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectPhasesDocument = gql`
    query ProjectPhases($slug: String!) {
  project(slug: $slug) {
    ...ProjectPhases
  }
}
    ${ProjectPhasesFragmentDoc}`;

/**
 * __useProjectPhasesQuery__
 *
 * To run a query within a React component, call `useProjectPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPhasesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectPhasesQuery(baseOptions: Apollo.QueryHookOptions<ProjectPhasesQuery, ProjectPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPhasesQuery, ProjectPhasesQueryVariables>(ProjectPhasesDocument, options);
      }
export function useProjectPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPhasesQuery, ProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPhasesQuery, ProjectPhasesQueryVariables>(ProjectPhasesDocument, options);
        }
export type ProjectPhasesQueryHookResult = ReturnType<typeof useProjectPhasesQuery>;
export type ProjectPhasesLazyQueryHookResult = ReturnType<typeof useProjectPhasesLazyQuery>;
export type ProjectPhasesQueryResult = Apollo.QueryResult<ProjectPhasesQuery, ProjectPhasesQueryVariables>;
export const UpdateProjectShareableDocument = gql`
    mutation UpdateProjectShareable($id: ID!, $isShareable: Boolean!) {
  updateProjectShareable(input: {id: $id, isShareable: $isShareable}) {
    project {
      id
      isShareable
    }
  }
}
    `;
export type UpdateProjectShareableMutationFn = Apollo.MutationFunction<UpdateProjectShareableMutation, UpdateProjectShareableMutationVariables>;

/**
 * __useUpdateProjectShareableMutation__
 *
 * To run a mutation, you first call `useUpdateProjectShareableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectShareableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectShareableMutation, { data, loading, error }] = useUpdateProjectShareableMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isShareable: // value for 'isShareable'
 *   },
 * });
 */
export function useUpdateProjectShareableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectShareableMutation, UpdateProjectShareableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectShareableMutation, UpdateProjectShareableMutationVariables>(UpdateProjectShareableDocument, options);
      }
export type UpdateProjectShareableMutationHookResult = ReturnType<typeof useUpdateProjectShareableMutation>;
export type UpdateProjectShareableMutationResult = Apollo.MutationResult<UpdateProjectShareableMutation>;
export type UpdateProjectShareableMutationOptions = Apollo.BaseMutationOptions<UpdateProjectShareableMutation, UpdateProjectShareableMutationVariables>;
export const UpdateProjectCostDocument = gql`
    mutation UpdateProjectCost($id: ID!, $constructionCost: Float, $sqUnits: Float, $sqUnitCostType: SqUnitCost) {
  updateProjectCost(
    input: {id: $id, constructionCost: $constructionCost, sqUnits: $sqUnits, sqUnitCostType: $sqUnitCostType}
  ) {
    project {
      projectCost {
        ...ProjectCostDetails
      }
    }
  }
}
    ${ProjectCostDetailsFragmentDoc}`;
export type UpdateProjectCostMutationFn = Apollo.MutationFunction<UpdateProjectCostMutation, UpdateProjectCostMutationVariables>;

/**
 * __useUpdateProjectCostMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCostMutation, { data, loading, error }] = useUpdateProjectCostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      constructionCost: // value for 'constructionCost'
 *      sqUnits: // value for 'sqUnits'
 *      sqUnitCostType: // value for 'sqUnitCostType'
 *   },
 * });
 */
export function useUpdateProjectCostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectCostMutation, UpdateProjectCostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectCostMutation, UpdateProjectCostMutationVariables>(UpdateProjectCostDocument, options);
      }
export type UpdateProjectCostMutationHookResult = ReturnType<typeof useUpdateProjectCostMutation>;
export type UpdateProjectCostMutationResult = Apollo.MutationResult<UpdateProjectCostMutation>;
export type UpdateProjectCostMutationOptions = Apollo.BaseMutationOptions<UpdateProjectCostMutation, UpdateProjectCostMutationVariables>;
export const UpdateProjectBasicInfoDocument = gql`
    mutation UpdateProjectBasicInfo($input: ProjectBasicInfoUpdateInput!) {
  updateProjectBasicInfo(input: $input) {
    project {
      ...ProjectGeneralInfo
    }
  }
}
    ${ProjectGeneralInfoFragmentDoc}`;
export type UpdateProjectBasicInfoMutationFn = Apollo.MutationFunction<UpdateProjectBasicInfoMutation, UpdateProjectBasicInfoMutationVariables>;

/**
 * __useUpdateProjectBasicInfoMutation__
 *
 * To run a mutation, you first call `useUpdateProjectBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBasicInfoMutation, { data, loading, error }] = useUpdateProjectBasicInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectBasicInfoMutation, UpdateProjectBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectBasicInfoMutation, UpdateProjectBasicInfoMutationVariables>(UpdateProjectBasicInfoDocument, options);
      }
export type UpdateProjectBasicInfoMutationHookResult = ReturnType<typeof useUpdateProjectBasicInfoMutation>;
export type UpdateProjectBasicInfoMutationResult = Apollo.MutationResult<UpdateProjectBasicInfoMutation>;
export type UpdateProjectBasicInfoMutationOptions = Apollo.BaseMutationOptions<UpdateProjectBasicInfoMutation, UpdateProjectBasicInfoMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    project {
      ...ProjectGeneralInfo
    }
  }
}
    ${ProjectGeneralInfoFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateProjectFromTemplateDocument = gql`
    mutation CreateProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
  createProjectFromTemplate(input: $input) {
    project {
      ...ProjectGeneralInfo
    }
  }
}
    ${ProjectGeneralInfoFragmentDoc}`;
export type CreateProjectFromTemplateMutationFn = Apollo.MutationFunction<CreateProjectFromTemplateMutation, CreateProjectFromTemplateMutationVariables>;

/**
 * __useCreateProjectFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateProjectFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFromTemplateMutation, { data, loading, error }] = useCreateProjectFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectFromTemplateMutation, CreateProjectFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectFromTemplateMutation, CreateProjectFromTemplateMutationVariables>(CreateProjectFromTemplateDocument, options);
      }
export type CreateProjectFromTemplateMutationHookResult = ReturnType<typeof useCreateProjectFromTemplateMutation>;
export type CreateProjectFromTemplateMutationResult = Apollo.MutationResult<CreateProjectFromTemplateMutation>;
export type CreateProjectFromTemplateMutationOptions = Apollo.BaseMutationOptions<CreateProjectFromTemplateMutation, CreateProjectFromTemplateMutationVariables>;
export const ProjectAccountingInfoDocument = gql`
    query ProjectAccountingInfo($slug: String!) {
  project(slug: $slug) {
    id
    name
    externalAccountingId
    externalAccountingRoleId
  }
}
    `;

/**
 * __useProjectAccountingInfoQuery__
 *
 * To run a query within a React component, call `useProjectAccountingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAccountingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAccountingInfoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectAccountingInfoQuery(baseOptions: Apollo.QueryHookOptions<ProjectAccountingInfoQuery, ProjectAccountingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectAccountingInfoQuery, ProjectAccountingInfoQueryVariables>(ProjectAccountingInfoDocument, options);
      }
export function useProjectAccountingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectAccountingInfoQuery, ProjectAccountingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectAccountingInfoQuery, ProjectAccountingInfoQueryVariables>(ProjectAccountingInfoDocument, options);
        }
export type ProjectAccountingInfoQueryHookResult = ReturnType<typeof useProjectAccountingInfoQuery>;
export type ProjectAccountingInfoLazyQueryHookResult = ReturnType<typeof useProjectAccountingInfoLazyQuery>;
export type ProjectAccountingInfoQueryResult = Apollo.QueryResult<ProjectAccountingInfoQuery, ProjectAccountingInfoQueryVariables>;
export const UpdateProjectAccountingDocument = gql`
    mutation UpdateProjectAccounting($id: ID!, $externalAccountingId: ID, $externalAccountingRoleId: ID) {
  updateProjectAccounting(
    input: {id: $id, externalAccountingId: $externalAccountingId, externalAccountingRoleId: $externalAccountingRoleId}
  ) {
    project {
      id
      externalAccountingId
      externalAccountingRoleId
    }
  }
}
    `;
export type UpdateProjectAccountingMutationFn = Apollo.MutationFunction<UpdateProjectAccountingMutation, UpdateProjectAccountingMutationVariables>;

/**
 * __useUpdateProjectAccountingMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAccountingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAccountingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAccountingMutation, { data, loading, error }] = useUpdateProjectAccountingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      externalAccountingId: // value for 'externalAccountingId'
 *      externalAccountingRoleId: // value for 'externalAccountingRoleId'
 *   },
 * });
 */
export function useUpdateProjectAccountingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectAccountingMutation, UpdateProjectAccountingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectAccountingMutation, UpdateProjectAccountingMutationVariables>(UpdateProjectAccountingDocument, options);
      }
export type UpdateProjectAccountingMutationHookResult = ReturnType<typeof useUpdateProjectAccountingMutation>;
export type UpdateProjectAccountingMutationResult = Apollo.MutationResult<UpdateProjectAccountingMutation>;
export type UpdateProjectAccountingMutationOptions = Apollo.BaseMutationOptions<UpdateProjectAccountingMutation, UpdateProjectAccountingMutationVariables>;
export const ProjectProfitDocument = gql`
    query ProjectProfit($slug: String!) {
  currentProfile {
    id
    organization {
      id
      connectedToQbo
    }
  }
  projectProfit(slug: $slug) {
    entries {
      costs
      grossProfit
      missingLaborRelatedCosts
      name
      revenue
    }
    costsTotal
    profilesMissingCompensation {
      fullName
      id
      slug
    }
    profitTotal
    revenueTotal
    missingCostsImport
  }
}
    `;

/**
 * __useProjectProfitQuery__
 *
 * To run a query within a React component, call `useProjectProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectProfitQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectProfitQuery(baseOptions: Apollo.QueryHookOptions<ProjectProfitQuery, ProjectProfitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectProfitQuery, ProjectProfitQueryVariables>(ProjectProfitDocument, options);
      }
export function useProjectProfitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectProfitQuery, ProjectProfitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectProfitQuery, ProjectProfitQueryVariables>(ProjectProfitDocument, options);
        }
export type ProjectProfitQueryHookResult = ReturnType<typeof useProjectProfitQuery>;
export type ProjectProfitLazyQueryHookResult = ReturnType<typeof useProjectProfitLazyQuery>;
export type ProjectProfitQueryResult = Apollo.QueryResult<ProjectProfitQuery, ProjectProfitQueryVariables>;
export const ClientListDetailsDocument = gql`
    query ClientListDetails {
  currentProfile {
    organization {
      connectedToQbo
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
}
    `;

/**
 * __useClientListDetailsQuery__
 *
 * To run a query within a React component, call `useClientListDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientListDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientListDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientListDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ClientListDetailsQuery, ClientListDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientListDetailsQuery, ClientListDetailsQueryVariables>(ClientListDetailsDocument, options);
      }
export function useClientListDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientListDetailsQuery, ClientListDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientListDetailsQuery, ClientListDetailsQueryVariables>(ClientListDetailsDocument, options);
        }
export type ClientListDetailsQueryHookResult = ReturnType<typeof useClientListDetailsQuery>;
export type ClientListDetailsLazyQueryHookResult = ReturnType<typeof useClientListDetailsLazyQuery>;
export type ClientListDetailsQueryResult = Apollo.QueryResult<ClientListDetailsQuery, ClientListDetailsQueryVariables>;
export const CategoriesAndProjectsDocument = gql`
    query CategoriesAndProjects {
  categories {
    id
    name
  }
  projects {
    id
    name
  }
}
    `;

/**
 * __useCategoriesAndProjectsQuery__
 *
 * To run a query within a React component, call `useCategoriesAndProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesAndProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesAndProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesAndProjectsQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesAndProjectsQuery, CategoriesAndProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesAndProjectsQuery, CategoriesAndProjectsQueryVariables>(CategoriesAndProjectsDocument, options);
      }
export function useCategoriesAndProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesAndProjectsQuery, CategoriesAndProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesAndProjectsQuery, CategoriesAndProjectsQueryVariables>(CategoriesAndProjectsDocument, options);
        }
export type CategoriesAndProjectsQueryHookResult = ReturnType<typeof useCategoriesAndProjectsQuery>;
export type CategoriesAndProjectsLazyQueryHookResult = ReturnType<typeof useCategoriesAndProjectsLazyQuery>;
export type CategoriesAndProjectsQueryResult = Apollo.QueryResult<CategoriesAndProjectsQuery, CategoriesAndProjectsQueryVariables>;
export const ProjectsReportDocument = gql`
    query ProjectsReport($filters: ProjectTimingsFiltersInput) {
  reports {
    projectsTimings(filters: $filters) {
      chart {
        ...ProjectChartFragment
      }
      filters {
        ...ProjectFiltersFragment
      }
      dateIncrement
      partialStart
      partialEnd
      projects {
        ...ProjectDataFragment
      }
      totals {
        ...ProjectTotalsFragment
      }
    }
  }
}
    ${ProjectChartFragmentFragmentDoc}
${ProjectFiltersFragmentFragmentDoc}
${ProjectDataFragmentFragmentDoc}
${ProjectTotalsFragmentFragmentDoc}`;

/**
 * __useProjectsReportQuery__
 *
 * To run a query within a React component, call `useProjectsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectsReportQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsReportQuery, ProjectsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsReportQuery, ProjectsReportQueryVariables>(ProjectsReportDocument, options);
      }
export function useProjectsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsReportQuery, ProjectsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsReportQuery, ProjectsReportQueryVariables>(ProjectsReportDocument, options);
        }
export type ProjectsReportQueryHookResult = ReturnType<typeof useProjectsReportQuery>;
export type ProjectsReportLazyQueryHookResult = ReturnType<typeof useProjectsReportLazyQuery>;
export type ProjectsReportQueryResult = Apollo.QueryResult<ProjectsReportQuery, ProjectsReportQueryVariables>;
export const ProjectsReportDefaultsDocument = gql`
    query ProjectsReportDefaults($filters: ProjectTimingsFiltersInput) {
  reports {
    projectsTimings(filters: $filters) {
      filters {
        ...ProjectFiltersFragment
      }
    }
  }
}
    ${ProjectFiltersFragmentFragmentDoc}`;

/**
 * __useProjectsReportDefaultsQuery__
 *
 * To run a query within a React component, call `useProjectsReportDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsReportDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsReportDefaultsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectsReportDefaultsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsReportDefaultsQuery, ProjectsReportDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsReportDefaultsQuery, ProjectsReportDefaultsQueryVariables>(ProjectsReportDefaultsDocument, options);
      }
export function useProjectsReportDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsReportDefaultsQuery, ProjectsReportDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsReportDefaultsQuery, ProjectsReportDefaultsQueryVariables>(ProjectsReportDefaultsDocument, options);
        }
export type ProjectsReportDefaultsQueryHookResult = ReturnType<typeof useProjectsReportDefaultsQuery>;
export type ProjectsReportDefaultsLazyQueryHookResult = ReturnType<typeof useProjectsReportDefaultsLazyQuery>;
export type ProjectsReportDefaultsQueryResult = Apollo.QueryResult<ProjectsReportDefaultsQuery, ProjectsReportDefaultsQueryVariables>;
export const ImportAllVendorsFromQuickbooksDocument = gql`
    mutation ImportAllVendorsFromQuickbooks {
  importAllVendorsFromQuickbooks(input: {}) {
    consultantCompanies {
      id
      name
      quickbooksVendorId
      active
      primaryConsultantContact {
        fname
        lname
        email
      }
    }
  }
}
    `;
export type ImportAllVendorsFromQuickbooksMutationFn = Apollo.MutationFunction<ImportAllVendorsFromQuickbooksMutation, ImportAllVendorsFromQuickbooksMutationVariables>;

/**
 * __useImportAllVendorsFromQuickbooksMutation__
 *
 * To run a mutation, you first call `useImportAllVendorsFromQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAllVendorsFromQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAllVendorsFromQuickbooksMutation, { data, loading, error }] = useImportAllVendorsFromQuickbooksMutation({
 *   variables: {
 *   },
 * });
 */
export function useImportAllVendorsFromQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<ImportAllVendorsFromQuickbooksMutation, ImportAllVendorsFromQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportAllVendorsFromQuickbooksMutation, ImportAllVendorsFromQuickbooksMutationVariables>(ImportAllVendorsFromQuickbooksDocument, options);
      }
export type ImportAllVendorsFromQuickbooksMutationHookResult = ReturnType<typeof useImportAllVendorsFromQuickbooksMutation>;
export type ImportAllVendorsFromQuickbooksMutationResult = Apollo.MutationResult<ImportAllVendorsFromQuickbooksMutation>;
export type ImportAllVendorsFromQuickbooksMutationOptions = Apollo.BaseMutationOptions<ImportAllVendorsFromQuickbooksMutation, ImportAllVendorsFromQuickbooksMutationVariables>;
export const ImportSpecificVendorsFromQuickbooksDocument = gql`
    mutation ImportSpecificVendorsFromQuickbooks($vendorsIds: [ID!]!) {
  importSpecificVendorsFromQuickbooks(input: {vendorsIds: $vendorsIds}) {
    consultantCompanies {
      id
      name
      quickbooksVendorId
      active
      primaryConsultantContact {
        fname
        lname
        email
      }
    }
  }
}
    `;
export type ImportSpecificVendorsFromQuickbooksMutationFn = Apollo.MutationFunction<ImportSpecificVendorsFromQuickbooksMutation, ImportSpecificVendorsFromQuickbooksMutationVariables>;

/**
 * __useImportSpecificVendorsFromQuickbooksMutation__
 *
 * To run a mutation, you first call `useImportSpecificVendorsFromQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSpecificVendorsFromQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSpecificVendorsFromQuickbooksMutation, { data, loading, error }] = useImportSpecificVendorsFromQuickbooksMutation({
 *   variables: {
 *      vendorsIds: // value for 'vendorsIds'
 *   },
 * });
 */
export function useImportSpecificVendorsFromQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<ImportSpecificVendorsFromQuickbooksMutation, ImportSpecificVendorsFromQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSpecificVendorsFromQuickbooksMutation, ImportSpecificVendorsFromQuickbooksMutationVariables>(ImportSpecificVendorsFromQuickbooksDocument, options);
      }
export type ImportSpecificVendorsFromQuickbooksMutationHookResult = ReturnType<typeof useImportSpecificVendorsFromQuickbooksMutation>;
export type ImportSpecificVendorsFromQuickbooksMutationResult = Apollo.MutationResult<ImportSpecificVendorsFromQuickbooksMutation>;
export type ImportSpecificVendorsFromQuickbooksMutationOptions = Apollo.BaseMutationOptions<ImportSpecificVendorsFromQuickbooksMutation, ImportSpecificVendorsFromQuickbooksMutationVariables>;
export const QuickbooksCustomersDocument = gql`
    query QuickbooksCustomers($filters: QBOCustomerFilters) {
  quickbooksCustomers(filters: $filters) {
    ...QboCustomerDetails
  }
}
    ${QboCustomerDetailsFragmentDoc}`;

/**
 * __useQuickbooksCustomersQuery__
 *
 * To run a query within a React component, call `useQuickbooksCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksCustomersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQuickbooksCustomersQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksCustomersQuery, QuickbooksCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksCustomersQuery, QuickbooksCustomersQueryVariables>(QuickbooksCustomersDocument, options);
      }
export function useQuickbooksCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksCustomersQuery, QuickbooksCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksCustomersQuery, QuickbooksCustomersQueryVariables>(QuickbooksCustomersDocument, options);
        }
export type QuickbooksCustomersQueryHookResult = ReturnType<typeof useQuickbooksCustomersQuery>;
export type QuickbooksCustomersLazyQueryHookResult = ReturnType<typeof useQuickbooksCustomersLazyQuery>;
export type QuickbooksCustomersQueryResult = Apollo.QueryResult<QuickbooksCustomersQuery, QuickbooksCustomersQueryVariables>;
export const AllNonQboClientsDocument = gql`
    query AllNonQboClients {
  allClients(filters: {nonQboOnly: true}) {
    id
    displayName
    displayName
    email
  }
}
    `;

/**
 * __useAllNonQboClientsQuery__
 *
 * To run a query within a React component, call `useAllNonQboClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNonQboClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNonQboClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNonQboClientsQuery(baseOptions?: Apollo.QueryHookOptions<AllNonQboClientsQuery, AllNonQboClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNonQboClientsQuery, AllNonQboClientsQueryVariables>(AllNonQboClientsDocument, options);
      }
export function useAllNonQboClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNonQboClientsQuery, AllNonQboClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNonQboClientsQuery, AllNonQboClientsQueryVariables>(AllNonQboClientsDocument, options);
        }
export type AllNonQboClientsQueryHookResult = ReturnType<typeof useAllNonQboClientsQuery>;
export type AllNonQboClientsLazyQueryHookResult = ReturnType<typeof useAllNonQboClientsLazyQuery>;
export type AllNonQboClientsQueryResult = Apollo.QueryResult<AllNonQboClientsQuery, AllNonQboClientsQueryVariables>;
export const HasUnImportedQboClientsDocument = gql`
    query HasUnImportedQboClients {
  currentProfile {
    id
    organization {
      connectedToQbo
      hasUnmatchedQboCustomers
      id
      settings {
        id
        multiQboInstanceEnabled
      }
    }
  }
}
    `;

/**
 * __useHasUnImportedQboClientsQuery__
 *
 * To run a query within a React component, call `useHasUnImportedQboClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasUnImportedQboClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasUnImportedQboClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasUnImportedQboClientsQuery(baseOptions?: Apollo.QueryHookOptions<HasUnImportedQboClientsQuery, HasUnImportedQboClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasUnImportedQboClientsQuery, HasUnImportedQboClientsQueryVariables>(HasUnImportedQboClientsDocument, options);
      }
export function useHasUnImportedQboClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasUnImportedQboClientsQuery, HasUnImportedQboClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasUnImportedQboClientsQuery, HasUnImportedQboClientsQueryVariables>(HasUnImportedQboClientsDocument, options);
        }
export type HasUnImportedQboClientsQueryHookResult = ReturnType<typeof useHasUnImportedQboClientsQuery>;
export type HasUnImportedQboClientsLazyQueryHookResult = ReturnType<typeof useHasUnImportedQboClientsLazyQuery>;
export type HasUnImportedQboClientsQueryResult = Apollo.QueryResult<HasUnImportedQboClientsQuery, HasUnImportedQboClientsQueryVariables>;
export const ImportCustomersFromQboDocument = gql`
    mutation ImportCustomersFromQbo($input: ImportCustomersFromQBOInput!) {
  importCustomersFromQbo(input: $input) {
    enqueued
  }
}
    `;
export type ImportCustomersFromQboMutationFn = Apollo.MutationFunction<ImportCustomersFromQboMutation, ImportCustomersFromQboMutationVariables>;

/**
 * __useImportCustomersFromQboMutation__
 *
 * To run a mutation, you first call `useImportCustomersFromQboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomersFromQboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomersFromQboMutation, { data, loading, error }] = useImportCustomersFromQboMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCustomersFromQboMutation(baseOptions?: Apollo.MutationHookOptions<ImportCustomersFromQboMutation, ImportCustomersFromQboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCustomersFromQboMutation, ImportCustomersFromQboMutationVariables>(ImportCustomersFromQboDocument, options);
      }
export type ImportCustomersFromQboMutationHookResult = ReturnType<typeof useImportCustomersFromQboMutation>;
export type ImportCustomersFromQboMutationResult = Apollo.MutationResult<ImportCustomersFromQboMutation>;
export type ImportCustomersFromQboMutationOptions = Apollo.BaseMutationOptions<ImportCustomersFromQboMutation, ImportCustomersFromQboMutationVariables>;
export const SyncImportCustomersFromQboDocument = gql`
    mutation SyncImportCustomersFromQbo($input: SyncImportCustomersFromQBOInput!) {
  syncImportCustomersFromQbo(input: $input) {
    clients {
      ...ClientResult
    }
  }
}
    ${ClientResultFragmentDoc}`;
export type SyncImportCustomersFromQboMutationFn = Apollo.MutationFunction<SyncImportCustomersFromQboMutation, SyncImportCustomersFromQboMutationVariables>;

/**
 * __useSyncImportCustomersFromQboMutation__
 *
 * To run a mutation, you first call `useSyncImportCustomersFromQboMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncImportCustomersFromQboMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncImportCustomersFromQboMutation, { data, loading, error }] = useSyncImportCustomersFromQboMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncImportCustomersFromQboMutation(baseOptions?: Apollo.MutationHookOptions<SyncImportCustomersFromQboMutation, SyncImportCustomersFromQboMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncImportCustomersFromQboMutation, SyncImportCustomersFromQboMutationVariables>(SyncImportCustomersFromQboDocument, options);
      }
export type SyncImportCustomersFromQboMutationHookResult = ReturnType<typeof useSyncImportCustomersFromQboMutation>;
export type SyncImportCustomersFromQboMutationResult = Apollo.MutationResult<SyncImportCustomersFromQboMutation>;
export type SyncImportCustomersFromQboMutationOptions = Apollo.BaseMutationOptions<SyncImportCustomersFromQboMutation, SyncImportCustomersFromQboMutationVariables>;
export const QuickbooksAccountPayableAccountsDocument = gql`
    query QuickbooksAccountPayableAccounts {
  quickbooksAccountPayableAccounts {
    id
    name
    active
    fullyQualifiedName
    quickbooksId
    parentQuickbooksId
  }
  quickbooksPaymentAccounts {
    id
    name
    active
    fullyQualifiedName
    quickbooksId
    parentQuickbooksId
  }
}
    `;

/**
 * __useQuickbooksAccountPayableAccountsQuery__
 *
 * To run a query within a React component, call `useQuickbooksAccountPayableAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksAccountPayableAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksAccountPayableAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksAccountPayableAccountsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksAccountPayableAccountsQuery, QuickbooksAccountPayableAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksAccountPayableAccountsQuery, QuickbooksAccountPayableAccountsQueryVariables>(QuickbooksAccountPayableAccountsDocument, options);
      }
export function useQuickbooksAccountPayableAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksAccountPayableAccountsQuery, QuickbooksAccountPayableAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksAccountPayableAccountsQuery, QuickbooksAccountPayableAccountsQueryVariables>(QuickbooksAccountPayableAccountsDocument, options);
        }
export type QuickbooksAccountPayableAccountsQueryHookResult = ReturnType<typeof useQuickbooksAccountPayableAccountsQuery>;
export type QuickbooksAccountPayableAccountsLazyQueryHookResult = ReturnType<typeof useQuickbooksAccountPayableAccountsLazyQuery>;
export type QuickbooksAccountPayableAccountsQueryResult = Apollo.QueryResult<QuickbooksAccountPayableAccountsQuery, QuickbooksAccountPayableAccountsQueryVariables>;
export const QuickSearchDocument = gql`
    query QuickSearch($searchString: String, $limit: Int = 4) {
  searchableProjects(parameters: {searchString: $searchString, limit: $limit}) {
    ...QuickSearchProject
  }
  profiles(filters: {searchString: $searchString}, first: $limit) {
    nodes {
      ...QuickSearchProfile
    }
  }
  clients(filters: {searchString: $searchString, statuses: ACTIVE}, first: $limit) {
    nodes {
      ...QuickSearchClient
    }
  }
}
    ${QuickSearchProjectFragmentDoc}
${QuickSearchProfileFragmentDoc}
${QuickSearchClientFragmentDoc}`;

/**
 * __useQuickSearchQuery__
 *
 * To run a query within a React component, call `useQuickSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchQuery(baseOptions?: Apollo.QueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
      }
export function useQuickSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchQuery, QuickSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchQuery, QuickSearchQueryVariables>(QuickSearchDocument, options);
        }
export type QuickSearchQueryHookResult = ReturnType<typeof useQuickSearchQuery>;
export type QuickSearchLazyQueryHookResult = ReturnType<typeof useQuickSearchLazyQuery>;
export type QuickSearchQueryResult = Apollo.QueryResult<QuickSearchQuery, QuickSearchQueryVariables>;
export const RoleTemplatesWithRateTablesDocument = gql`
    query RoleTemplatesWithRateTables($includeDeactivated: Boolean = true) {
  roleTemplates(includeDeactivated: $includeDeactivated) {
    ...RoleTemplateWithRates
  }
}
    ${RoleTemplateWithRatesFragmentDoc}`;

/**
 * __useRoleTemplatesWithRateTablesQuery__
 *
 * To run a query within a React component, call `useRoleTemplatesWithRateTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleTemplatesWithRateTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleTemplatesWithRateTablesQuery({
 *   variables: {
 *      includeDeactivated: // value for 'includeDeactivated'
 *   },
 * });
 */
export function useRoleTemplatesWithRateTablesQuery(baseOptions?: Apollo.QueryHookOptions<RoleTemplatesWithRateTablesQuery, RoleTemplatesWithRateTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleTemplatesWithRateTablesQuery, RoleTemplatesWithRateTablesQueryVariables>(RoleTemplatesWithRateTablesDocument, options);
      }
export function useRoleTemplatesWithRateTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleTemplatesWithRateTablesQuery, RoleTemplatesWithRateTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleTemplatesWithRateTablesQuery, RoleTemplatesWithRateTablesQueryVariables>(RoleTemplatesWithRateTablesDocument, options);
        }
export type RoleTemplatesWithRateTablesQueryHookResult = ReturnType<typeof useRoleTemplatesWithRateTablesQuery>;
export type RoleTemplatesWithRateTablesLazyQueryHookResult = ReturnType<typeof useRoleTemplatesWithRateTablesLazyQuery>;
export type RoleTemplatesWithRateTablesQueryResult = Apollo.QueryResult<RoleTemplatesWithRateTablesQuery, RoleTemplatesWithRateTablesQueryVariables>;
export const RateTableDocument = gql`
    query RateTable($slug: String!) {
  rateTable(slug: $slug) {
    ...RateTableWithRates
    ...RateTableDependencies
  }
}
    ${RateTableWithRatesFragmentDoc}
${RateTableDependenciesFragmentDoc}`;

/**
 * __useRateTableQuery__
 *
 * To run a query within a React component, call `useRateTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateTableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRateTableQuery(baseOptions: Apollo.QueryHookOptions<RateTableQuery, RateTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateTableQuery, RateTableQueryVariables>(RateTableDocument, options);
      }
export function useRateTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateTableQuery, RateTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateTableQuery, RateTableQueryVariables>(RateTableDocument, options);
        }
export type RateTableQueryHookResult = ReturnType<typeof useRateTableQuery>;
export type RateTableLazyQueryHookResult = ReturnType<typeof useRateTableLazyQuery>;
export type RateTableQueryResult = Apollo.QueryResult<RateTableQuery, RateTableQueryVariables>;
export const RateDocument = gql`
    query Rate($id: ID!) {
  rate(id: $id) {
    ...RateDetail
    dependencyCounts {
      projects
      roles
      profiles {
        id
        fullName
      }
    }
  }
}
    ${RateDetailFragmentDoc}`;

/**
 * __useRateQuery__
 *
 * To run a query within a React component, call `useRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRateQuery(baseOptions: Apollo.QueryHookOptions<RateQuery, RateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateQuery, RateQueryVariables>(RateDocument, options);
      }
export function useRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateQuery, RateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateQuery, RateQueryVariables>(RateDocument, options);
        }
export type RateQueryHookResult = ReturnType<typeof useRateQuery>;
export type RateLazyQueryHookResult = ReturnType<typeof useRateLazyQuery>;
export type RateQueryResult = Apollo.QueryResult<RateQuery, RateQueryVariables>;
export const RoleTemplateActiveRateTablesDocument = gql`
    query RoleTemplateActiveRateTables($id: ID!) {
  roleTemplate(id: $id) {
    id
    name
    archivedAt
    activeRateTables {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;

/**
 * __useRoleTemplateActiveRateTablesQuery__
 *
 * To run a query within a React component, call `useRoleTemplateActiveRateTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleTemplateActiveRateTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleTemplateActiveRateTablesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleTemplateActiveRateTablesQuery(baseOptions: Apollo.QueryHookOptions<RoleTemplateActiveRateTablesQuery, RoleTemplateActiveRateTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleTemplateActiveRateTablesQuery, RoleTemplateActiveRateTablesQueryVariables>(RoleTemplateActiveRateTablesDocument, options);
      }
export function useRoleTemplateActiveRateTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleTemplateActiveRateTablesQuery, RoleTemplateActiveRateTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleTemplateActiveRateTablesQuery, RoleTemplateActiveRateTablesQueryVariables>(RoleTemplateActiveRateTablesDocument, options);
        }
export type RoleTemplateActiveRateTablesQueryHookResult = ReturnType<typeof useRoleTemplateActiveRateTablesQuery>;
export type RoleTemplateActiveRateTablesLazyQueryHookResult = ReturnType<typeof useRoleTemplateActiveRateTablesLazyQuery>;
export type RoleTemplateActiveRateTablesQueryResult = Apollo.QueryResult<RoleTemplateActiveRateTablesQuery, RoleTemplateActiveRateTablesQueryVariables>;
export const ArchiveRoleTemplateDocument = gql`
    mutation ArchiveRoleTemplate($id: ID!) {
  archiveRoleTemplate(input: {id: $id}) {
    roleTemplate {
      ...RoleTemplateWithRates
    }
  }
}
    ${RoleTemplateWithRatesFragmentDoc}`;
export type ArchiveRoleTemplateMutationFn = Apollo.MutationFunction<ArchiveRoleTemplateMutation, ArchiveRoleTemplateMutationVariables>;

/**
 * __useArchiveRoleTemplateMutation__
 *
 * To run a mutation, you first call `useArchiveRoleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRoleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRoleTemplateMutation, { data, loading, error }] = useArchiveRoleTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveRoleTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveRoleTemplateMutation, ArchiveRoleTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveRoleTemplateMutation, ArchiveRoleTemplateMutationVariables>(ArchiveRoleTemplateDocument, options);
      }
export type ArchiveRoleTemplateMutationHookResult = ReturnType<typeof useArchiveRoleTemplateMutation>;
export type ArchiveRoleTemplateMutationResult = Apollo.MutationResult<ArchiveRoleTemplateMutation>;
export type ArchiveRoleTemplateMutationOptions = Apollo.BaseMutationOptions<ArchiveRoleTemplateMutation, ArchiveRoleTemplateMutationVariables>;
export const UnArchiveRoleTemplateDocument = gql`
    mutation UnArchiveRoleTemplate($id: ID!) {
  unarchiveRoleTemplate(input: {id: $id}) {
    roleTemplate {
      ...RoleTemplateWithRates
    }
  }
}
    ${RoleTemplateWithRatesFragmentDoc}`;
export type UnArchiveRoleTemplateMutationFn = Apollo.MutationFunction<UnArchiveRoleTemplateMutation, UnArchiveRoleTemplateMutationVariables>;

/**
 * __useUnArchiveRoleTemplateMutation__
 *
 * To run a mutation, you first call `useUnArchiveRoleTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnArchiveRoleTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unArchiveRoleTemplateMutation, { data, loading, error }] = useUnArchiveRoleTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnArchiveRoleTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UnArchiveRoleTemplateMutation, UnArchiveRoleTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnArchiveRoleTemplateMutation, UnArchiveRoleTemplateMutationVariables>(UnArchiveRoleTemplateDocument, options);
      }
export type UnArchiveRoleTemplateMutationHookResult = ReturnType<typeof useUnArchiveRoleTemplateMutation>;
export type UnArchiveRoleTemplateMutationResult = Apollo.MutationResult<UnArchiveRoleTemplateMutation>;
export type UnArchiveRoleTemplateMutationOptions = Apollo.BaseMutationOptions<UnArchiveRoleTemplateMutation, UnArchiveRoleTemplateMutationVariables>;
export const UpdateRoleTemplateNameDocument = gql`
    mutation UpdateRoleTemplateName($id: ID!, $name: String!) {
  updateRoleTemplate(input: {id: $id, name: $name}) {
    roleTemplate {
      ...RoleTemplateWithRates
    }
  }
}
    ${RoleTemplateWithRatesFragmentDoc}`;
export type UpdateRoleTemplateNameMutationFn = Apollo.MutationFunction<UpdateRoleTemplateNameMutation, UpdateRoleTemplateNameMutationVariables>;

/**
 * __useUpdateRoleTemplateNameMutation__
 *
 * To run a mutation, you first call `useUpdateRoleTemplateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleTemplateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleTemplateNameMutation, { data, loading, error }] = useUpdateRoleTemplateNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateRoleTemplateNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleTemplateNameMutation, UpdateRoleTemplateNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleTemplateNameMutation, UpdateRoleTemplateNameMutationVariables>(UpdateRoleTemplateNameDocument, options);
      }
export type UpdateRoleTemplateNameMutationHookResult = ReturnType<typeof useUpdateRoleTemplateNameMutation>;
export type UpdateRoleTemplateNameMutationResult = Apollo.MutationResult<UpdateRoleTemplateNameMutation>;
export type UpdateRoleTemplateNameMutationOptions = Apollo.BaseMutationOptions<UpdateRoleTemplateNameMutation, UpdateRoleTemplateNameMutationVariables>;
export const CreateRoleTemplateAndRateDocument = gql`
    mutation CreateRoleTemplateAndRate($templateName: String!, $rateName: String!, $rate: Float!) {
  createRoleTemplateAndRate(
    input: {templateName: $templateName, rateName: $rateName, rate: $rate}
  ) {
    roleTemplate {
      ...RoleTemplateWithRates
    }
  }
}
    ${RoleTemplateWithRatesFragmentDoc}`;
export type CreateRoleTemplateAndRateMutationFn = Apollo.MutationFunction<CreateRoleTemplateAndRateMutation, CreateRoleTemplateAndRateMutationVariables>;

/**
 * __useCreateRoleTemplateAndRateMutation__
 *
 * To run a mutation, you first call `useCreateRoleTemplateAndRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleTemplateAndRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleTemplateAndRateMutation, { data, loading, error }] = useCreateRoleTemplateAndRateMutation({
 *   variables: {
 *      templateName: // value for 'templateName'
 *      rateName: // value for 'rateName'
 *      rate: // value for 'rate'
 *   },
 * });
 */
export function useCreateRoleTemplateAndRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleTemplateAndRateMutation, CreateRoleTemplateAndRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleTemplateAndRateMutation, CreateRoleTemplateAndRateMutationVariables>(CreateRoleTemplateAndRateDocument, options);
      }
export type CreateRoleTemplateAndRateMutationHookResult = ReturnType<typeof useCreateRoleTemplateAndRateMutation>;
export type CreateRoleTemplateAndRateMutationResult = Apollo.MutationResult<CreateRoleTemplateAndRateMutation>;
export type CreateRoleTemplateAndRateMutationOptions = Apollo.BaseMutationOptions<CreateRoleTemplateAndRateMutation, CreateRoleTemplateAndRateMutationVariables>;
export const DeleteRateDocument = gql`
    mutation DeleteRate($id: ID!) {
  deleteRate(input: {id: $id}) {
    rate {
      id
    }
  }
}
    `;
export type DeleteRateMutationFn = Apollo.MutationFunction<DeleteRateMutation, DeleteRateMutationVariables>;

/**
 * __useDeleteRateMutation__
 *
 * To run a mutation, you first call `useDeleteRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRateMutation, { data, loading, error }] = useDeleteRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRateMutation, DeleteRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRateMutation, DeleteRateMutationVariables>(DeleteRateDocument, options);
      }
export type DeleteRateMutationHookResult = ReturnType<typeof useDeleteRateMutation>;
export type DeleteRateMutationResult = Apollo.MutationResult<DeleteRateMutation>;
export type DeleteRateMutationOptions = Apollo.BaseMutationOptions<DeleteRateMutation, DeleteRateMutationVariables>;
export const UpdateRateDocument = gql`
    mutation UpdateRate($id: ID!, $effectiveOn: ISO8601Date, $amount: Float!) {
  updateRate(input: {id: $id, effectiveOn: $effectiveOn, amount: $amount}) {
    rate {
      ...RateDetail
    }
  }
}
    ${RateDetailFragmentDoc}`;
export type UpdateRateMutationFn = Apollo.MutationFunction<UpdateRateMutation, UpdateRateMutationVariables>;

/**
 * __useUpdateRateMutation__
 *
 * To run a mutation, you first call `useUpdateRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateMutation, { data, loading, error }] = useUpdateRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      effectiveOn: // value for 'effectiveOn'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRateMutation, UpdateRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRateMutation, UpdateRateMutationVariables>(UpdateRateDocument, options);
      }
export type UpdateRateMutationHookResult = ReturnType<typeof useUpdateRateMutation>;
export type UpdateRateMutationResult = Apollo.MutationResult<UpdateRateMutation>;
export type UpdateRateMutationOptions = Apollo.BaseMutationOptions<UpdateRateMutation, UpdateRateMutationVariables>;
export const CreateRateDocument = gql`
    mutation CreateRate($rateTableId: ID!, $effectiveOn: ISO8601Date!, $amount: Float!) {
  createRate(
    input: {rateTableId: $rateTableId, effectiveOn: $effectiveOn, amount: $amount}
  ) {
    rate {
      id
    }
  }
}
    `;
export type CreateRateMutationFn = Apollo.MutationFunction<CreateRateMutation, CreateRateMutationVariables>;

/**
 * __useCreateRateMutation__
 *
 * To run a mutation, you first call `useCreateRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateMutation, { data, loading, error }] = useCreateRateMutation({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *      effectiveOn: // value for 'effectiveOn'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreateRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateMutation, CreateRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateMutation, CreateRateMutationVariables>(CreateRateDocument, options);
      }
export type CreateRateMutationHookResult = ReturnType<typeof useCreateRateMutation>;
export type CreateRateMutationResult = Apollo.MutationResult<CreateRateMutation>;
export type CreateRateMutationOptions = Apollo.BaseMutationOptions<CreateRateMutation, CreateRateMutationVariables>;
export const CreateRateTableDocument = gql`
    mutation CreateRateTable($initialRateAmount: Float!, $roleTemplateId: ID!, $name: String!) {
  createRateTable(
    input: {initialRateAmount: $initialRateAmount, roleTemplateId: $roleTemplateId, name: $name}
  ) {
    rateTable {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export type CreateRateTableMutationFn = Apollo.MutationFunction<CreateRateTableMutation, CreateRateTableMutationVariables>;

/**
 * __useCreateRateTableMutation__
 *
 * To run a mutation, you first call `useCreateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateTableMutation, { data, loading, error }] = useCreateRateTableMutation({
 *   variables: {
 *      initialRateAmount: // value for 'initialRateAmount'
 *      roleTemplateId: // value for 'roleTemplateId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateRateTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateTableMutation, CreateRateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateTableMutation, CreateRateTableMutationVariables>(CreateRateTableDocument, options);
      }
export type CreateRateTableMutationHookResult = ReturnType<typeof useCreateRateTableMutation>;
export type CreateRateTableMutationResult = Apollo.MutationResult<CreateRateTableMutation>;
export type CreateRateTableMutationOptions = Apollo.BaseMutationOptions<CreateRateTableMutation, CreateRateTableMutationVariables>;
export const UpdateRateTableDocument = gql`
    mutation UpdateRateTable($input: EditRateTableInput!) {
  editRateTable(input: $input) {
    rateTable {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export type UpdateRateTableMutationFn = Apollo.MutationFunction<UpdateRateTableMutation, UpdateRateTableMutationVariables>;

/**
 * __useUpdateRateTableMutation__
 *
 * To run a mutation, you first call `useUpdateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateTableMutation, { data, loading, error }] = useUpdateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRateTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRateTableMutation, UpdateRateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRateTableMutation, UpdateRateTableMutationVariables>(UpdateRateTableDocument, options);
      }
export type UpdateRateTableMutationHookResult = ReturnType<typeof useUpdateRateTableMutation>;
export type UpdateRateTableMutationResult = Apollo.MutationResult<UpdateRateTableMutation>;
export type UpdateRateTableMutationOptions = Apollo.BaseMutationOptions<UpdateRateTableMutation, UpdateRateTableMutationVariables>;
export const DuplicateRateTableDocument = gql`
    mutation DuplicateRateTable($input: DuplicateRateTableInput!) {
  duplicateRateTable(input: $input) {
    rateTable {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export type DuplicateRateTableMutationFn = Apollo.MutationFunction<DuplicateRateTableMutation, DuplicateRateTableMutationVariables>;

/**
 * __useDuplicateRateTableMutation__
 *
 * To run a mutation, you first call `useDuplicateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRateTableMutation, { data, loading, error }] = useDuplicateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateRateTableMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateRateTableMutation, DuplicateRateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateRateTableMutation, DuplicateRateTableMutationVariables>(DuplicateRateTableDocument, options);
      }
export type DuplicateRateTableMutationHookResult = ReturnType<typeof useDuplicateRateTableMutation>;
export type DuplicateRateTableMutationResult = Apollo.MutationResult<DuplicateRateTableMutation>;
export type DuplicateRateTableMutationOptions = Apollo.BaseMutationOptions<DuplicateRateTableMutation, DuplicateRateTableMutationVariables>;
export const DeactivateRateTableDocument = gql`
    mutation DeactivateRateTable($input: DeactivateRateTableInput!) {
  deactivateRateTable(input: $input) {
    rateTable {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export type DeactivateRateTableMutationFn = Apollo.MutationFunction<DeactivateRateTableMutation, DeactivateRateTableMutationVariables>;

/**
 * __useDeactivateRateTableMutation__
 *
 * To run a mutation, you first call `useDeactivateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateRateTableMutation, { data, loading, error }] = useDeactivateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateRateTableMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateRateTableMutation, DeactivateRateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateRateTableMutation, DeactivateRateTableMutationVariables>(DeactivateRateTableDocument, options);
      }
export type DeactivateRateTableMutationHookResult = ReturnType<typeof useDeactivateRateTableMutation>;
export type DeactivateRateTableMutationResult = Apollo.MutationResult<DeactivateRateTableMutation>;
export type DeactivateRateTableMutationOptions = Apollo.BaseMutationOptions<DeactivateRateTableMutation, DeactivateRateTableMutationVariables>;
export const ReactivateRateTableDocument = gql`
    mutation ReactivateRateTable($input: ReactivateRateTableInput!) {
  reactivateRateTable(input: $input) {
    rateTable {
      ...RateTableWithRates
    }
  }
}
    ${RateTableWithRatesFragmentDoc}`;
export type ReactivateRateTableMutationFn = Apollo.MutationFunction<ReactivateRateTableMutation, ReactivateRateTableMutationVariables>;

/**
 * __useReactivateRateTableMutation__
 *
 * To run a mutation, you first call `useReactivateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateRateTableMutation, { data, loading, error }] = useReactivateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivateRateTableMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateRateTableMutation, ReactivateRateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateRateTableMutation, ReactivateRateTableMutationVariables>(ReactivateRateTableDocument, options);
      }
export type ReactivateRateTableMutationHookResult = ReturnType<typeof useReactivateRateTableMutation>;
export type ReactivateRateTableMutationResult = Apollo.MutationResult<ReactivateRateTableMutation>;
export type ReactivateRateTableMutationOptions = Apollo.BaseMutationOptions<ReactivateRateTableMutation, ReactivateRateTableMutationVariables>;
export const ProfitReportDocument = gql`
    query ProfitReport($dateRange: DateRangeInput!, $statuses: [ProjectStatus!], $phaseFeeType: PhaseFee, $revenueSource: ProfitRevenueSource!, $includeConsultants: Boolean) {
  reports {
    profit(
      dateRange: $dateRange
      statuses: $statuses
      phaseFeeType: $phaseFeeType
      revenueSource: $revenueSource
      includeConsultants: $includeConsultants
    ) {
      chart {
        data {
          costs
          revenue
          revenueSource
          profit
          margin
        }
        xAxis
      }
      pastRevenueSource
      futureRevenueSource
      dateIncrement
      partialEnd
      partialStart
      totals {
        costs
        revenue
        profit
      }
    }
  }
}
    `;

/**
 * __useProfitReportQuery__
 *
 * To run a query within a React component, call `useProfitReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitReportQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      statuses: // value for 'statuses'
 *      phaseFeeType: // value for 'phaseFeeType'
 *      revenueSource: // value for 'revenueSource'
 *      includeConsultants: // value for 'includeConsultants'
 *   },
 * });
 */
export function useProfitReportQuery(baseOptions: Apollo.QueryHookOptions<ProfitReportQuery, ProfitReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitReportQuery, ProfitReportQueryVariables>(ProfitReportDocument, options);
      }
export function useProfitReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitReportQuery, ProfitReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitReportQuery, ProfitReportQueryVariables>(ProfitReportDocument, options);
        }
export type ProfitReportQueryHookResult = ReturnType<typeof useProfitReportQuery>;
export type ProfitReportLazyQueryHookResult = ReturnType<typeof useProfitReportLazyQuery>;
export type ProfitReportQueryResult = Apollo.QueryResult<ProfitReportQuery, ProfitReportQueryVariables>;
export const ProfitDriverFiltersDocument = gql`
    query ProfitDriverFilters {
  categories {
    id
    name
  }
  phaseTemplates {
    id
    name
  }
  allClients {
    displayName
    id
  }
}
    `;

/**
 * __useProfitDriverFiltersQuery__
 *
 * To run a query within a React component, call `useProfitDriverFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitDriverFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitDriverFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfitDriverFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ProfitDriverFiltersQuery, ProfitDriverFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitDriverFiltersQuery, ProfitDriverFiltersQueryVariables>(ProfitDriverFiltersDocument, options);
      }
export function useProfitDriverFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitDriverFiltersQuery, ProfitDriverFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitDriverFiltersQuery, ProfitDriverFiltersQueryVariables>(ProfitDriverFiltersDocument, options);
        }
export type ProfitDriverFiltersQueryHookResult = ReturnType<typeof useProfitDriverFiltersQuery>;
export type ProfitDriverFiltersLazyQueryHookResult = ReturnType<typeof useProfitDriverFiltersLazyQuery>;
export type ProfitDriverFiltersQueryResult = Apollo.QueryResult<ProfitDriverFiltersQuery, ProfitDriverFiltersQueryVariables>;
export const ProfitDriversV2Document = gql`
    query ProfitDriversV2($filters: ProfitDriversV2FiltersInput!) {
  reports {
    profitDriversV2(filters: $filters) {
      identifier
      name
      costs
      revenue
      profit
      margin
    }
  }
}
    `;

/**
 * __useProfitDriversV2Query__
 *
 * To run a query within a React component, call `useProfitDriversV2Query` and pass it any options that fit your needs.
 * When your component renders, `useProfitDriversV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitDriversV2Query({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProfitDriversV2Query(baseOptions: Apollo.QueryHookOptions<ProfitDriversV2Query, ProfitDriversV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitDriversV2Query, ProfitDriversV2QueryVariables>(ProfitDriversV2Document, options);
      }
export function useProfitDriversV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitDriversV2Query, ProfitDriversV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitDriversV2Query, ProfitDriversV2QueryVariables>(ProfitDriversV2Document, options);
        }
export type ProfitDriversV2QueryHookResult = ReturnType<typeof useProfitDriversV2Query>;
export type ProfitDriversV2LazyQueryHookResult = ReturnType<typeof useProfitDriversV2LazyQuery>;
export type ProfitDriversV2QueryResult = Apollo.QueryResult<ProfitDriversV2Query, ProfitDriversV2QueryVariables>;
export const TimeLogFiltersDocument = gql`
    query TimeLogFilters {
  activities {
    id
    name
  }
  projects {
    id
    name
  }
  phaseTemplates {
    id
    name
  }
  roleTemplates {
    id
    name
  }
  categories {
    id
    name
  }
}
    `;

/**
 * __useTimeLogFiltersQuery__
 *
 * To run a query within a React component, call `useTimeLogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeLogFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeLogFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeLogFiltersQuery(baseOptions?: Apollo.QueryHookOptions<TimeLogFiltersQuery, TimeLogFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeLogFiltersQuery, TimeLogFiltersQueryVariables>(TimeLogFiltersDocument, options);
      }
export function useTimeLogFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeLogFiltersQuery, TimeLogFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeLogFiltersQuery, TimeLogFiltersQueryVariables>(TimeLogFiltersDocument, options);
        }
export type TimeLogFiltersQueryHookResult = ReturnType<typeof useTimeLogFiltersQuery>;
export type TimeLogFiltersLazyQueryHookResult = ReturnType<typeof useTimeLogFiltersLazyQuery>;
export type TimeLogFiltersQueryResult = Apollo.QueryResult<TimeLogFiltersQuery, TimeLogFiltersQueryVariables>;
export const TimelogChartAvailableFiltersDocument = gql`
    query TimelogChartAvailableFilters($intent: TimeLogIntent!, $filters: TimeLogFiltersInput!) {
  reports {
    timeLog(intent: $intent, filters: $filters) {
      availableFilters {
        phases {
          label
          value
        }
        profiles {
          label
          value
        }
      }
    }
  }
}
    `;

/**
 * __useTimelogChartAvailableFiltersQuery__
 *
 * To run a query within a React component, call `useTimelogChartAvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogChartAvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogChartAvailableFiltersQuery({
 *   variables: {
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTimelogChartAvailableFiltersQuery(baseOptions: Apollo.QueryHookOptions<TimelogChartAvailableFiltersQuery, TimelogChartAvailableFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogChartAvailableFiltersQuery, TimelogChartAvailableFiltersQueryVariables>(TimelogChartAvailableFiltersDocument, options);
      }
export function useTimelogChartAvailableFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogChartAvailableFiltersQuery, TimelogChartAvailableFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogChartAvailableFiltersQuery, TimelogChartAvailableFiltersQueryVariables>(TimelogChartAvailableFiltersDocument, options);
        }
export type TimelogChartAvailableFiltersQueryHookResult = ReturnType<typeof useTimelogChartAvailableFiltersQuery>;
export type TimelogChartAvailableFiltersLazyQueryHookResult = ReturnType<typeof useTimelogChartAvailableFiltersLazyQuery>;
export type TimelogChartAvailableFiltersQueryResult = Apollo.QueryResult<TimelogChartAvailableFiltersQuery, TimelogChartAvailableFiltersQueryVariables>;
export const ProjectIdDocument = gql`
    query ProjectId($slug: String!) {
  project(slug: $slug) {
    id
  }
}
    `;

/**
 * __useProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectIdQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectIdQuery(baseOptions: Apollo.QueryHookOptions<ProjectIdQuery, ProjectIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectIdQuery, ProjectIdQueryVariables>(ProjectIdDocument, options);
      }
export function useProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectIdQuery, ProjectIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectIdQuery, ProjectIdQueryVariables>(ProjectIdDocument, options);
        }
export type ProjectIdQueryHookResult = ReturnType<typeof useProjectIdQuery>;
export type ProjectIdLazyQueryHookResult = ReturnType<typeof useProjectIdLazyQuery>;
export type ProjectIdQueryResult = Apollo.QueryResult<ProjectIdQuery, ProjectIdQueryVariables>;
export const ProjectPerformanceProjectDocument = gql`
    query ProjectPerformanceProject($slug: String!) {
  project(slug: $slug) {
    id
    orderedPhases {
      id
      name
      roles {
        profile {
          id
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useProjectPerformanceProjectQuery__
 *
 * To run a query within a React component, call `useProjectPerformanceProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPerformanceProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPerformanceProjectQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectPerformanceProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectPerformanceProjectQuery, ProjectPerformanceProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPerformanceProjectQuery, ProjectPerformanceProjectQueryVariables>(ProjectPerformanceProjectDocument, options);
      }
export function useProjectPerformanceProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPerformanceProjectQuery, ProjectPerformanceProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPerformanceProjectQuery, ProjectPerformanceProjectQueryVariables>(ProjectPerformanceProjectDocument, options);
        }
export type ProjectPerformanceProjectQueryHookResult = ReturnType<typeof useProjectPerformanceProjectQuery>;
export type ProjectPerformanceProjectLazyQueryHookResult = ReturnType<typeof useProjectPerformanceProjectLazyQuery>;
export type ProjectPerformanceProjectQueryResult = Apollo.QueryResult<ProjectPerformanceProjectQuery, ProjectPerformanceProjectQueryVariables>;
export const ProjectTimelogChartDocument = gql`
    query ProjectTimelogChart($intent: TimeLogIntent!, $filters: TimeLogFiltersInput!) {
  reports {
    timeLog(intent: $intent, filters: $filters) {
      filters {
        dateRange {
          beginDate
          endDate
        }
        activityIds
        profileIds
        phaseTemplateIds
      }
      chart {
        xAxis
        data {
          billable
          fee
          hours
        }
      }
      dateIncrement
      partialStart
      partialEnd
      totals {
        billableFee
        billableHours
        billablePercent
        nonBillableHours
        totalHours
      }
      project {
        color
      }
      availableFilters {
        activities {
          label
          value
        }
        projects {
          label
          value
        }
        phases {
          label
          value
        }
        phaseTemplates {
          label
          value
        }
        profiles {
          label
          value
        }
        roleTemplates {
          label
          value
        }
      }
    }
  }
}
    `;

/**
 * __useProjectTimelogChartQuery__
 *
 * To run a query within a React component, call `useProjectTimelogChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimelogChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimelogChartQuery({
 *   variables: {
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectTimelogChartQuery(baseOptions: Apollo.QueryHookOptions<ProjectTimelogChartQuery, ProjectTimelogChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTimelogChartQuery, ProjectTimelogChartQueryVariables>(ProjectTimelogChartDocument, options);
      }
export function useProjectTimelogChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimelogChartQuery, ProjectTimelogChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTimelogChartQuery, ProjectTimelogChartQueryVariables>(ProjectTimelogChartDocument, options);
        }
export type ProjectTimelogChartQueryHookResult = ReturnType<typeof useProjectTimelogChartQuery>;
export type ProjectTimelogChartLazyQueryHookResult = ReturnType<typeof useProjectTimelogChartLazyQuery>;
export type ProjectTimelogChartQueryResult = Apollo.QueryResult<ProjectTimelogChartQuery, ProjectTimelogChartQueryVariables>;
export const TimelogChartDocument = gql`
    query TimelogChart($intent: TimeLogIntent!, $filters: TimeLogFiltersInput!) {
  reports {
    timeLog(intent: $intent, filters: $filters) {
      filters {
        dateRange {
          beginDate
          endDate
        }
        activityIds
        profileIds
        phaseTemplateIds
      }
      chart {
        xAxis
        data {
          billable
          fee
          hours
        }
      }
      dateIncrement
      partialStart
      partialEnd
      totals {
        billableFee
        billableHours
        billablePercent
        nonBillableHours
        totalHours
      }
      project {
        color
      }
    }
  }
}
    `;

/**
 * __useTimelogChartQuery__
 *
 * To run a query within a React component, call `useTimelogChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogChartQuery({
 *   variables: {
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTimelogChartQuery(baseOptions: Apollo.QueryHookOptions<TimelogChartQuery, TimelogChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogChartQuery, TimelogChartQueryVariables>(TimelogChartDocument, options);
      }
export function useTimelogChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogChartQuery, TimelogChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogChartQuery, TimelogChartQueryVariables>(TimelogChartDocument, options);
        }
export type TimelogChartQueryHookResult = ReturnType<typeof useTimelogChartQuery>;
export type TimelogChartLazyQueryHookResult = ReturnType<typeof useTimelogChartLazyQuery>;
export type TimelogChartQueryResult = Apollo.QueryResult<TimelogChartQuery, TimelogChartQueryVariables>;
export const TimelogGroupedTableDocument = gql`
    query TimelogGroupedTable($intent: TimeLogIntent!, $groupBy: GroupByInput!, $filters: EntryFiltersInput!) {
  groupedEntries(intent: $intent, groupBy: $groupBy, filters: $filters) {
    grouping {
      ... on Activity {
        ...TimelogGoupedByActivity
      }
      ... on Phase {
        ...TimelogGroupedByPhase
      }
      ... on Project {
        ...TimelogGroupedByProject
      }
      ... on Profile {
        ...TimelogGroupedByProfile
      }
    }
    totalAmount
    totalEntries
    totalHours
  }
}
    ${TimelogGoupedByActivityFragmentDoc}
${TimelogGroupedByPhaseFragmentDoc}
${TimelogGroupedByProjectFragmentDoc}
${TimelogGroupedByProfileFragmentDoc}`;

/**
 * __useTimelogGroupedTableQuery__
 *
 * To run a query within a React component, call `useTimelogGroupedTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogGroupedTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogGroupedTableQuery({
 *   variables: {
 *      intent: // value for 'intent'
 *      groupBy: // value for 'groupBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useTimelogGroupedTableQuery(baseOptions: Apollo.QueryHookOptions<TimelogGroupedTableQuery, TimelogGroupedTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogGroupedTableQuery, TimelogGroupedTableQueryVariables>(TimelogGroupedTableDocument, options);
      }
export function useTimelogGroupedTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogGroupedTableQuery, TimelogGroupedTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogGroupedTableQuery, TimelogGroupedTableQueryVariables>(TimelogGroupedTableDocument, options);
        }
export type TimelogGroupedTableQueryHookResult = ReturnType<typeof useTimelogGroupedTableQuery>;
export type TimelogGroupedTableLazyQueryHookResult = ReturnType<typeof useTimelogGroupedTableLazyQuery>;
export type TimelogGroupedTableQueryResult = Apollo.QueryResult<TimelogGroupedTableQuery, TimelogGroupedTableQueryVariables>;
export const TimelogTableDocument = gql`
    query TimelogTable($sortBy: EntriesSortInput, $intent: TimeLogIntent!, $filters: EntryFiltersInput!, $after: String, $before: String, $first: Int, $last: Int) {
  entries(
    sortBy: $sortBy
    intent: $intent
    filters: $filters
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    totalHours
    totalAmount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    nodes {
      activity {
        id
        name
      }
      profile {
        name
        slug
      }
      project {
        color
        number
        name
        slug
        id
      }
      date
      notes
      rate
      role {
        name
      }
      hours
      amount
      phase {
        id
        abbreviation
      }
    }
  }
}
    `;

/**
 * __useTimelogTableQuery__
 *
 * To run a query within a React component, call `useTimelogTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogTableQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      intent: // value for 'intent'
 *      filters: // value for 'filters'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useTimelogTableQuery(baseOptions: Apollo.QueryHookOptions<TimelogTableQuery, TimelogTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelogTableQuery, TimelogTableQueryVariables>(TimelogTableDocument, options);
      }
export function useTimelogTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogTableQuery, TimelogTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelogTableQuery, TimelogTableQueryVariables>(TimelogTableDocument, options);
        }
export type TimelogTableQueryHookResult = ReturnType<typeof useTimelogTableQuery>;
export type TimelogTableLazyQueryHookResult = ReturnType<typeof useTimelogTableLazyQuery>;
export type TimelogTableQueryResult = Apollo.QueryResult<TimelogTableQuery, TimelogTableQueryVariables>;
export const ProjectPerformanceChartDocument = gql`
    query ProjectPerformanceChart($filters: ProjectPerformanceFiltersInput!) {
  reports {
    projectPerformance(filters: $filters) {
      dateRange {
        beginDate
        endDate
      }
      projectEndDate
      dateIncrement
      projectEndDate
      projectBudget {
        total {
          hours
          fee
        }
        fixedFee {
          hours
          fee
        }
        hourlyFee {
          hours
          fee
        }
      }
      entries {
        startDate
        endDate
        planned {
          hours
          fee
        }
        consumed {
          hours
          fee
        }
        allocated {
          hours
          fee
        }
        invoicedFee
      }
    }
  }
}
    `;

/**
 * __useProjectPerformanceChartQuery__
 *
 * To run a query within a React component, call `useProjectPerformanceChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPerformanceChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPerformanceChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectPerformanceChartQuery(baseOptions: Apollo.QueryHookOptions<ProjectPerformanceChartQuery, ProjectPerformanceChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPerformanceChartQuery, ProjectPerformanceChartQueryVariables>(ProjectPerformanceChartDocument, options);
      }
export function useProjectPerformanceChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPerformanceChartQuery, ProjectPerformanceChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPerformanceChartQuery, ProjectPerformanceChartQueryVariables>(ProjectPerformanceChartDocument, options);
        }
export type ProjectPerformanceChartQueryHookResult = ReturnType<typeof useProjectPerformanceChartQuery>;
export type ProjectPerformanceChartLazyQueryHookResult = ReturnType<typeof useProjectPerformanceChartLazyQuery>;
export type ProjectPerformanceChartQueryResult = Apollo.QueryResult<ProjectPerformanceChartQuery, ProjectPerformanceChartQueryVariables>;
export const ProjectPerformancePhasesDocument = gql`
    query ProjectPerformancePhases($filters: ProjectPerformanceFiltersInput, $endDate: ISO8601Date!) {
  reports {
    projectPerformance(filters: $filters) {
      phases(endDate: $endDate) {
        ...ProjectPerformancePhase
      }
    }
  }
}
    ${ProjectPerformancePhaseFragmentDoc}`;

/**
 * __useProjectPerformancePhasesQuery__
 *
 * To run a query within a React component, call `useProjectPerformancePhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPerformancePhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPerformancePhasesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProjectPerformancePhasesQuery(baseOptions: Apollo.QueryHookOptions<ProjectPerformancePhasesQuery, ProjectPerformancePhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPerformancePhasesQuery, ProjectPerformancePhasesQueryVariables>(ProjectPerformancePhasesDocument, options);
      }
export function useProjectPerformancePhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPerformancePhasesQuery, ProjectPerformancePhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPerformancePhasesQuery, ProjectPerformancePhasesQueryVariables>(ProjectPerformancePhasesDocument, options);
        }
export type ProjectPerformancePhasesQueryHookResult = ReturnType<typeof useProjectPerformancePhasesQuery>;
export type ProjectPerformancePhasesLazyQueryHookResult = ReturnType<typeof useProjectPerformancePhasesLazyQuery>;
export type ProjectPerformancePhasesQueryResult = Apollo.QueryResult<ProjectPerformancePhasesQuery, ProjectPerformancePhasesQueryVariables>;
export const TaxesCollectedDocument = gql`
    query TaxesCollected($filters: TaxRatesFiltersInput!, $sortBy: TaxesSortInput) {
  reports {
    taxesCollected(filters: $filters, sortBy: $sortBy) {
      name
      percent
      total
    }
  }
}
    `;

/**
 * __useTaxesCollectedQuery__
 *
 * To run a query within a React component, call `useTaxesCollectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxesCollectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxesCollectedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useTaxesCollectedQuery(baseOptions: Apollo.QueryHookOptions<TaxesCollectedQuery, TaxesCollectedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxesCollectedQuery, TaxesCollectedQueryVariables>(TaxesCollectedDocument, options);
      }
export function useTaxesCollectedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxesCollectedQuery, TaxesCollectedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxesCollectedQuery, TaxesCollectedQueryVariables>(TaxesCollectedDocument, options);
        }
export type TaxesCollectedQueryHookResult = ReturnType<typeof useTaxesCollectedQuery>;
export type TaxesCollectedLazyQueryHookResult = ReturnType<typeof useTaxesCollectedLazyQuery>;
export type TaxesCollectedQueryResult = Apollo.QueryResult<TaxesCollectedQuery, TaxesCollectedQueryVariables>;
export const ExpenseReportDocument = gql`
    query ExpenseReport {
  currentProfile {
    policy {
      permissions {
        canSendToQuickbooks
      }
    }
    organization {
      connectedToQbo
      settings {
        id
        multiQboInstanceEnabled
        reimbursableExpensesAccount {
          id
          name
        }
        nonReimbursableExpensesAccount {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useExpenseReportQuery__
 *
 * To run a query within a React component, call `useExpenseReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpenseReportQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseReportQuery, ExpenseReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseReportQuery, ExpenseReportQueryVariables>(ExpenseReportDocument, options);
      }
export function useExpenseReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseReportQuery, ExpenseReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseReportQuery, ExpenseReportQueryVariables>(ExpenseReportDocument, options);
        }
export type ExpenseReportQueryHookResult = ReturnType<typeof useExpenseReportQuery>;
export type ExpenseReportLazyQueryHookResult = ReturnType<typeof useExpenseReportLazyQuery>;
export type ExpenseReportQueryResult = Apollo.QueryResult<ExpenseReportQuery, ExpenseReportQueryVariables>;
export const RealizationReportFiltersDocument = gql`
    query RealizationReportFilters {
  projectsOptions {
    id
    name
  }
  categories {
    id
    name
  }
}
    `;

/**
 * __useRealizationReportFiltersQuery__
 *
 * To run a query within a React component, call `useRealizationReportFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealizationReportFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealizationReportFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRealizationReportFiltersQuery(baseOptions?: Apollo.QueryHookOptions<RealizationReportFiltersQuery, RealizationReportFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealizationReportFiltersQuery, RealizationReportFiltersQueryVariables>(RealizationReportFiltersDocument, options);
      }
export function useRealizationReportFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealizationReportFiltersQuery, RealizationReportFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealizationReportFiltersQuery, RealizationReportFiltersQueryVariables>(RealizationReportFiltersDocument, options);
        }
export type RealizationReportFiltersQueryHookResult = ReturnType<typeof useRealizationReportFiltersQuery>;
export type RealizationReportFiltersLazyQueryHookResult = ReturnType<typeof useRealizationReportFiltersLazyQuery>;
export type RealizationReportFiltersQueryResult = Apollo.QueryResult<RealizationReportFiltersQuery, RealizationReportFiltersQueryVariables>;
export const RealizationReportDocument = gql`
    query RealizationReport($filters: RealizationFiltersInput) {
  reports {
    realization(filters: $filters) {
      chart {
        data {
          billableFees
          invoicedFees
          realizationRate
        }
        xAxis
      }
      dateIncrement
      defaultRealizationRate
      earliestEntryDate
    }
  }
}
    `;

/**
 * __useRealizationReportQuery__
 *
 * To run a query within a React component, call `useRealizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealizationReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRealizationReportQuery(baseOptions?: Apollo.QueryHookOptions<RealizationReportQuery, RealizationReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealizationReportQuery, RealizationReportQueryVariables>(RealizationReportDocument, options);
      }
export function useRealizationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealizationReportQuery, RealizationReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealizationReportQuery, RealizationReportQueryVariables>(RealizationReportDocument, options);
        }
export type RealizationReportQueryHookResult = ReturnType<typeof useRealizationReportQuery>;
export type RealizationReportLazyQueryHookResult = ReturnType<typeof useRealizationReportLazyQuery>;
export type RealizationReportQueryResult = Apollo.QueryResult<RealizationReportQuery, RealizationReportQueryVariables>;
export const DetailedRealizationReportDocument = gql`
    query DetailedRealizationReport($filters: RealizationFiltersInput) {
  reports {
    realization(filters: $filters) {
      chart {
        data {
          billableFees
          invoicedFees
          realizationRate
        }
        xAxis
      }
      dateIncrement
      defaultRealizationRate
      earliestEntryDate
      totals {
        ...RealizationTotalFields
      }
      table {
        ...RealizationTableData
      }
    }
  }
}
    ${RealizationTotalFieldsFragmentDoc}
${RealizationTableDataFragmentDoc}`;

/**
 * __useDetailedRealizationReportQuery__
 *
 * To run a query within a React component, call `useDetailedRealizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedRealizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedRealizationReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDetailedRealizationReportQuery(baseOptions?: Apollo.QueryHookOptions<DetailedRealizationReportQuery, DetailedRealizationReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedRealizationReportQuery, DetailedRealizationReportQueryVariables>(DetailedRealizationReportDocument, options);
      }
export function useDetailedRealizationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedRealizationReportQuery, DetailedRealizationReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedRealizationReportQuery, DetailedRealizationReportQueryVariables>(DetailedRealizationReportDocument, options);
        }
export type DetailedRealizationReportQueryHookResult = ReturnType<typeof useDetailedRealizationReportQuery>;
export type DetailedRealizationReportLazyQueryHookResult = ReturnType<typeof useDetailedRealizationReportLazyQuery>;
export type DetailedRealizationReportQueryResult = Apollo.QueryResult<DetailedRealizationReportQuery, DetailedRealizationReportQueryVariables>;
export const RealizationProjectsDocument = gql`
    query RealizationProjects($filters: RealizationFiltersInput!) {
  reports {
    realizationProjects(filters: $filters) {
      ...RealizationProjectTableData
    }
  }
}
    ${RealizationProjectTableDataFragmentDoc}`;

/**
 * __useRealizationProjectsQuery__
 *
 * To run a query within a React component, call `useRealizationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRealizationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealizationProjectsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRealizationProjectsQuery(baseOptions: Apollo.QueryHookOptions<RealizationProjectsQuery, RealizationProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RealizationProjectsQuery, RealizationProjectsQueryVariables>(RealizationProjectsDocument, options);
      }
export function useRealizationProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RealizationProjectsQuery, RealizationProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RealizationProjectsQuery, RealizationProjectsQueryVariables>(RealizationProjectsDocument, options);
        }
export type RealizationProjectsQueryHookResult = ReturnType<typeof useRealizationProjectsQuery>;
export type RealizationProjectsLazyQueryHookResult = ReturnType<typeof useRealizationProjectsLazyQuery>;
export type RealizationProjectsQueryResult = Apollo.QueryResult<RealizationProjectsQuery, RealizationProjectsQueryVariables>;
export const ExportRealizationDocument = gql`
    mutation ExportRealization($filters: RealizationFiltersInput!) {
  startExport {
    realization(input: {filters: $filters}) {
      id
    }
  }
}
    `;
export type ExportRealizationMutationFn = Apollo.MutationFunction<ExportRealizationMutation, ExportRealizationMutationVariables>;

/**
 * __useExportRealizationMutation__
 *
 * To run a mutation, you first call `useExportRealizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportRealizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportRealizationMutation, { data, loading, error }] = useExportRealizationMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExportRealizationMutation(baseOptions?: Apollo.MutationHookOptions<ExportRealizationMutation, ExportRealizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportRealizationMutation, ExportRealizationMutationVariables>(ExportRealizationDocument, options);
      }
export type ExportRealizationMutationHookResult = ReturnType<typeof useExportRealizationMutation>;
export type ExportRealizationMutationResult = Apollo.MutationResult<ExportRealizationMutation>;
export type ExportRealizationMutationOptions = Apollo.BaseMutationOptions<ExportRealizationMutation, ExportRealizationMutationVariables>;
export const UtilizationReportFiltersDocument = gql`
    query UtilizationReportFilters {
  currentProfile {
    organization {
      profiles {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUtilizationReportFiltersQuery__
 *
 * To run a query within a React component, call `useUtilizationReportFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizationReportFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizationReportFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUtilizationReportFiltersQuery(baseOptions?: Apollo.QueryHookOptions<UtilizationReportFiltersQuery, UtilizationReportFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UtilizationReportFiltersQuery, UtilizationReportFiltersQueryVariables>(UtilizationReportFiltersDocument, options);
      }
export function useUtilizationReportFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UtilizationReportFiltersQuery, UtilizationReportFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UtilizationReportFiltersQuery, UtilizationReportFiltersQueryVariables>(UtilizationReportFiltersDocument, options);
        }
export type UtilizationReportFiltersQueryHookResult = ReturnType<typeof useUtilizationReportFiltersQuery>;
export type UtilizationReportFiltersLazyQueryHookResult = ReturnType<typeof useUtilizationReportFiltersLazyQuery>;
export type UtilizationReportFiltersQueryResult = Apollo.QueryResult<UtilizationReportFiltersQuery, UtilizationReportFiltersQueryVariables>;
export const UtilizationReportDocument = gql`
    query UtilizationReport($filters: UtilizationFiltersInput) {
  reports {
    utilization(filters: $filters) {
      chart {
        data {
          billableHours
          nonBillableHours
          totalHours
          utilizationRate
        }
        totalHours
        xAxis
      }
      dateIncrement
      defaultUtilizationRate
      earliestEntryDate
    }
  }
}
    `;

/**
 * __useUtilizationReportQuery__
 *
 * To run a query within a React component, call `useUtilizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizationReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtilizationReportQuery(baseOptions?: Apollo.QueryHookOptions<UtilizationReportQuery, UtilizationReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UtilizationReportQuery, UtilizationReportQueryVariables>(UtilizationReportDocument, options);
      }
export function useUtilizationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UtilizationReportQuery, UtilizationReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UtilizationReportQuery, UtilizationReportQueryVariables>(UtilizationReportDocument, options);
        }
export type UtilizationReportQueryHookResult = ReturnType<typeof useUtilizationReportQuery>;
export type UtilizationReportLazyQueryHookResult = ReturnType<typeof useUtilizationReportLazyQuery>;
export type UtilizationReportQueryResult = Apollo.QueryResult<UtilizationReportQuery, UtilizationReportQueryVariables>;
export const DetailedUtilizationReportDocument = gql`
    query DetailedUtilizationReport($filters: UtilizationFiltersInput) {
  reports {
    utilization(filters: $filters) {
      chart {
        data {
          billableHours
          nonBillableHours
          totalHours
          utilizationRate
        }
        totalHours
        xAxis
      }
      dateIncrement
      defaultUtilizationRate
      earliestEntryDate
      totals {
        ...UtilizationTotals
      }
      table {
        ...UtilizationTableData
      }
    }
  }
}
    ${UtilizationTotalsFragmentDoc}
${UtilizationTableDataFragmentDoc}`;

/**
 * __useDetailedUtilizationReportQuery__
 *
 * To run a query within a React component, call `useDetailedUtilizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedUtilizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedUtilizationReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDetailedUtilizationReportQuery(baseOptions?: Apollo.QueryHookOptions<DetailedUtilizationReportQuery, DetailedUtilizationReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedUtilizationReportQuery, DetailedUtilizationReportQueryVariables>(DetailedUtilizationReportDocument, options);
      }
export function useDetailedUtilizationReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedUtilizationReportQuery, DetailedUtilizationReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedUtilizationReportQuery, DetailedUtilizationReportQueryVariables>(DetailedUtilizationReportDocument, options);
        }
export type DetailedUtilizationReportQueryHookResult = ReturnType<typeof useDetailedUtilizationReportQuery>;
export type DetailedUtilizationReportLazyQueryHookResult = ReturnType<typeof useDetailedUtilizationReportLazyQuery>;
export type DetailedUtilizationReportQueryResult = Apollo.QueryResult<DetailedUtilizationReportQuery, DetailedUtilizationReportQueryVariables>;
export const UtilizationProfileDocument = gql`
    query UtilizationProfile($id: ID) {
  profile(id: $id) {
    id
    name
    utilizationRate
  }
}
    `;

/**
 * __useUtilizationProfileQuery__
 *
 * To run a query within a React component, call `useUtilizationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizationProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUtilizationProfileQuery(baseOptions?: Apollo.QueryHookOptions<UtilizationProfileQuery, UtilizationProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UtilizationProfileQuery, UtilizationProfileQueryVariables>(UtilizationProfileDocument, options);
      }
export function useUtilizationProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UtilizationProfileQuery, UtilizationProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UtilizationProfileQuery, UtilizationProfileQueryVariables>(UtilizationProfileDocument, options);
        }
export type UtilizationProfileQueryHookResult = ReturnType<typeof useUtilizationProfileQuery>;
export type UtilizationProfileLazyQueryHookResult = ReturnType<typeof useUtilizationProfileLazyQuery>;
export type UtilizationProfileQueryResult = Apollo.QueryResult<UtilizationProfileQuery, UtilizationProfileQueryVariables>;
export const UtilizationPeopleDocument = gql`
    query UtilizationPeople($filters: UtilizationFiltersInput!) {
  reports {
    utilizationPeople(filters: $filters) {
      ...UtilizationPeople
    }
  }
}
    ${UtilizationPeopleFragmentDoc}`;

/**
 * __useUtilizationPeopleQuery__
 *
 * To run a query within a React component, call `useUtilizationPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizationPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizationPeopleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUtilizationPeopleQuery(baseOptions: Apollo.QueryHookOptions<UtilizationPeopleQuery, UtilizationPeopleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UtilizationPeopleQuery, UtilizationPeopleQueryVariables>(UtilizationPeopleDocument, options);
      }
export function useUtilizationPeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UtilizationPeopleQuery, UtilizationPeopleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UtilizationPeopleQuery, UtilizationPeopleQueryVariables>(UtilizationPeopleDocument, options);
        }
export type UtilizationPeopleQueryHookResult = ReturnType<typeof useUtilizationPeopleQuery>;
export type UtilizationPeopleLazyQueryHookResult = ReturnType<typeof useUtilizationPeopleLazyQuery>;
export type UtilizationPeopleQueryResult = Apollo.QueryResult<UtilizationPeopleQuery, UtilizationPeopleQueryVariables>;
export const ExportUtilizationDocument = gql`
    mutation ExportUtilization($filters: UtilizationFiltersInput!) {
  startExport {
    utilization(input: {filters: $filters}) {
      id
    }
  }
}
    `;
export type ExportUtilizationMutationFn = Apollo.MutationFunction<ExportUtilizationMutation, ExportUtilizationMutationVariables>;

/**
 * __useExportUtilizationMutation__
 *
 * To run a mutation, you first call `useExportUtilizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUtilizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUtilizationMutation, { data, loading, error }] = useExportUtilizationMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExportUtilizationMutation(baseOptions?: Apollo.MutationHookOptions<ExportUtilizationMutation, ExportUtilizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUtilizationMutation, ExportUtilizationMutationVariables>(ExportUtilizationDocument, options);
      }
export type ExportUtilizationMutationHookResult = ReturnType<typeof useExportUtilizationMutation>;
export type ExportUtilizationMutationResult = Apollo.MutationResult<ExportUtilizationMutation>;
export type ExportUtilizationMutationOptions = Apollo.BaseMutationOptions<ExportUtilizationMutation, ExportUtilizationMutationVariables>;
export const ProfitDriversExportDocument = gql`
    mutation ProfitDriversExport($input: ProfitDriversExportInput!) {
  startExport {
    profitDrivers(input: $input) {
      id
    }
  }
}
    `;
export type ProfitDriversExportMutationFn = Apollo.MutationFunction<ProfitDriversExportMutation, ProfitDriversExportMutationVariables>;

/**
 * __useProfitDriversExportMutation__
 *
 * To run a mutation, you first call `useProfitDriversExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfitDriversExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profitDriversExportMutation, { data, loading, error }] = useProfitDriversExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfitDriversExportMutation(baseOptions?: Apollo.MutationHookOptions<ProfitDriversExportMutation, ProfitDriversExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfitDriversExportMutation, ProfitDriversExportMutationVariables>(ProfitDriversExportDocument, options);
      }
export type ProfitDriversExportMutationHookResult = ReturnType<typeof useProfitDriversExportMutation>;
export type ProfitDriversExportMutationResult = Apollo.MutationResult<ProfitDriversExportMutation>;
export type ProfitDriversExportMutationOptions = Apollo.BaseMutationOptions<ProfitDriversExportMutation, ProfitDriversExportMutationVariables>;
export const ProfitForecastEmptyStateDocument = gql`
    query ProfitForecastEmptyState {
  currentProfile {
    organization {
      projectsCount
      overheadMultiplier
      profilesWithoutCompensation {
        id
        fullName
        slug
      }
    }
  }
}
    `;

/**
 * __useProfitForecastEmptyStateQuery__
 *
 * To run a query within a React component, call `useProfitForecastEmptyStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitForecastEmptyStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitForecastEmptyStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfitForecastEmptyStateQuery(baseOptions?: Apollo.QueryHookOptions<ProfitForecastEmptyStateQuery, ProfitForecastEmptyStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitForecastEmptyStateQuery, ProfitForecastEmptyStateQueryVariables>(ProfitForecastEmptyStateDocument, options);
      }
export function useProfitForecastEmptyStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitForecastEmptyStateQuery, ProfitForecastEmptyStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitForecastEmptyStateQuery, ProfitForecastEmptyStateQueryVariables>(ProfitForecastEmptyStateDocument, options);
        }
export type ProfitForecastEmptyStateQueryHookResult = ReturnType<typeof useProfitForecastEmptyStateQuery>;
export type ProfitForecastEmptyStateLazyQueryHookResult = ReturnType<typeof useProfitForecastEmptyStateLazyQuery>;
export type ProfitForecastEmptyStateQueryResult = Apollo.QueryResult<ProfitForecastEmptyStateQuery, ProfitForecastEmptyStateQueryVariables>;
export const ProfitForecastDocument = gql`
    query ProfitForecast($filters: ProfitForecastFiltersInput!) {
  currentProfile {
    organization {
      projectsCount
      overheadMultiplier
      profilesWithoutCompensation {
        id
        fullName
        slug
      }
    }
  }
  reports {
    profitForecast(filters: $filters) {
      totals {
        cost
        profit
        revenue
      }
      entries {
        ...ProfitForecastEntries
      }
    }
  }
}
    ${ProfitForecastEntriesFragmentDoc}`;

/**
 * __useProfitForecastQuery__
 *
 * To run a query within a React component, call `useProfitForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitForecastQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProfitForecastQuery(baseOptions: Apollo.QueryHookOptions<ProfitForecastQuery, ProfitForecastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitForecastQuery, ProfitForecastQueryVariables>(ProfitForecastDocument, options);
      }
export function useProfitForecastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitForecastQuery, ProfitForecastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitForecastQuery, ProfitForecastQueryVariables>(ProfitForecastDocument, options);
        }
export type ProfitForecastQueryHookResult = ReturnType<typeof useProfitForecastQuery>;
export type ProfitForecastLazyQueryHookResult = ReturnType<typeof useProfitForecastLazyQuery>;
export type ProfitForecastQueryResult = Apollo.QueryResult<ProfitForecastQuery, ProfitForecastQueryVariables>;
export const ProfitForecastExportDocument = gql`
    mutation ProfitForecastExport($input: ProfitForecastExportInput!) {
  startExport {
    profitForecast(input: $input) {
      id
    }
  }
}
    `;
export type ProfitForecastExportMutationFn = Apollo.MutationFunction<ProfitForecastExportMutation, ProfitForecastExportMutationVariables>;

/**
 * __useProfitForecastExportMutation__
 *
 * To run a mutation, you first call `useProfitForecastExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfitForecastExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profitForecastExportMutation, { data, loading, error }] = useProfitForecastExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfitForecastExportMutation(baseOptions?: Apollo.MutationHookOptions<ProfitForecastExportMutation, ProfitForecastExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfitForecastExportMutation, ProfitForecastExportMutationVariables>(ProfitForecastExportDocument, options);
      }
export type ProfitForecastExportMutationHookResult = ReturnType<typeof useProfitForecastExportMutation>;
export type ProfitForecastExportMutationResult = Apollo.MutationResult<ProfitForecastExportMutation>;
export type ProfitForecastExportMutationOptions = Apollo.BaseMutationOptions<ProfitForecastExportMutation, ProfitForecastExportMutationVariables>;
export const ProfitReportEmptyStateDocument = gql`
    query ProfitReportEmptyState {
  currentProfile {
    organization {
      connectedToQbo
      profilesWithoutCompensation {
        id
        fullName
        slug
      }
      overheadMultiplier
      settings {
        costsImportStartDate
      }
    }
  }
  invoices {
    totalCount
  }
}
    `;

/**
 * __useProfitReportEmptyStateQuery__
 *
 * To run a query within a React component, call `useProfitReportEmptyStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitReportEmptyStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfitReportEmptyStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfitReportEmptyStateQuery(baseOptions?: Apollo.QueryHookOptions<ProfitReportEmptyStateQuery, ProfitReportEmptyStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitReportEmptyStateQuery, ProfitReportEmptyStateQueryVariables>(ProfitReportEmptyStateDocument, options);
      }
export function useProfitReportEmptyStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitReportEmptyStateQuery, ProfitReportEmptyStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitReportEmptyStateQuery, ProfitReportEmptyStateQueryVariables>(ProfitReportEmptyStateDocument, options);
        }
export type ProfitReportEmptyStateQueryHookResult = ReturnType<typeof useProfitReportEmptyStateQuery>;
export type ProfitReportEmptyStateLazyQueryHookResult = ReturnType<typeof useProfitReportEmptyStateLazyQuery>;
export type ProfitReportEmptyStateQueryResult = Apollo.QueryResult<ProfitReportEmptyStateQuery, ProfitReportEmptyStateQueryVariables>;
export const QboProfitReportDocument = gql`
    query QboProfitReport($filters: ProfitFiltersInput!) {
  reports {
    qboProfit(filters: $filters) {
      entries {
        ...QboProfitReportEntry
      }
      minimumReportDate
      showConsultantWarning
      totals {
        revenue
        profit
        cost
      }
    }
  }
}
    ${QboProfitReportEntryFragmentDoc}`;

/**
 * __useQboProfitReportQuery__
 *
 * To run a query within a React component, call `useQboProfitReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQboProfitReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQboProfitReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useQboProfitReportQuery(baseOptions: Apollo.QueryHookOptions<QboProfitReportQuery, QboProfitReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QboProfitReportQuery, QboProfitReportQueryVariables>(QboProfitReportDocument, options);
      }
export function useQboProfitReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QboProfitReportQuery, QboProfitReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QboProfitReportQuery, QboProfitReportQueryVariables>(QboProfitReportDocument, options);
        }
export type QboProfitReportQueryHookResult = ReturnType<typeof useQboProfitReportQuery>;
export type QboProfitReportLazyQueryHookResult = ReturnType<typeof useQboProfitReportLazyQuery>;
export type QboProfitReportQueryResult = Apollo.QueryResult<QboProfitReportQuery, QboProfitReportQueryVariables>;
export const NonQboProfitReportDocument = gql`
    query NonQboProfitReport($filters: ProfitFiltersInput!) {
  reports {
    nonQboProfit(filters: $filters) {
      entries {
        ...nonQboProfitReportEntry
      }
      minimumReportDate
      showConsultantWarning
      totals {
        revenue
        profit
        cost
      }
    }
  }
}
    ${NonQboProfitReportEntryFragmentDoc}`;

/**
 * __useNonQboProfitReportQuery__
 *
 * To run a query within a React component, call `useNonQboProfitReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonQboProfitReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonQboProfitReportQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNonQboProfitReportQuery(baseOptions: Apollo.QueryHookOptions<NonQboProfitReportQuery, NonQboProfitReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NonQboProfitReportQuery, NonQboProfitReportQueryVariables>(NonQboProfitReportDocument, options);
      }
export function useNonQboProfitReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NonQboProfitReportQuery, NonQboProfitReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NonQboProfitReportQuery, NonQboProfitReportQueryVariables>(NonQboProfitReportDocument, options);
        }
export type NonQboProfitReportQueryHookResult = ReturnType<typeof useNonQboProfitReportQuery>;
export type NonQboProfitReportLazyQueryHookResult = ReturnType<typeof useNonQboProfitReportLazyQuery>;
export type NonQboProfitReportQueryResult = Apollo.QueryResult<NonQboProfitReportQuery, NonQboProfitReportQueryVariables>;
export const ProfitQboExportDocument = gql`
    mutation ProfitQboExport($input: ProfitQBOVersionExportInput!) {
  startExport {
    profitQboVersion(input: $input) {
      id
    }
  }
}
    `;
export type ProfitQboExportMutationFn = Apollo.MutationFunction<ProfitQboExportMutation, ProfitQboExportMutationVariables>;

/**
 * __useProfitQboExportMutation__
 *
 * To run a mutation, you first call `useProfitQboExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfitQboExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profitQboExportMutation, { data, loading, error }] = useProfitQboExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfitQboExportMutation(baseOptions?: Apollo.MutationHookOptions<ProfitQboExportMutation, ProfitQboExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfitQboExportMutation, ProfitQboExportMutationVariables>(ProfitQboExportDocument, options);
      }
export type ProfitQboExportMutationHookResult = ReturnType<typeof useProfitQboExportMutation>;
export type ProfitQboExportMutationResult = Apollo.MutationResult<ProfitQboExportMutation>;
export type ProfitQboExportMutationOptions = Apollo.BaseMutationOptions<ProfitQboExportMutation, ProfitQboExportMutationVariables>;
export const ProfitNonQboExportDocument = gql`
    mutation ProfitNonQboExport($input: ProfitNonQBOVersionExportInput!) {
  startExport {
    profitNonQboVersion(input: $input) {
      id
    }
  }
}
    `;
export type ProfitNonQboExportMutationFn = Apollo.MutationFunction<ProfitNonQboExportMutation, ProfitNonQboExportMutationVariables>;

/**
 * __useProfitNonQboExportMutation__
 *
 * To run a mutation, you first call `useProfitNonQboExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfitNonQboExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profitNonQboExportMutation, { data, loading, error }] = useProfitNonQboExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfitNonQboExportMutation(baseOptions?: Apollo.MutationHookOptions<ProfitNonQboExportMutation, ProfitNonQboExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfitNonQboExportMutation, ProfitNonQboExportMutationVariables>(ProfitNonQboExportDocument, options);
      }
export type ProfitNonQboExportMutationHookResult = ReturnType<typeof useProfitNonQboExportMutation>;
export type ProfitNonQboExportMutationResult = Apollo.MutationResult<ProfitNonQboExportMutation>;
export type ProfitNonQboExportMutationOptions = Apollo.BaseMutationOptions<ProfitNonQboExportMutation, ProfitNonQboExportMutationVariables>;
export const ProjectFinancialsProjectPhasesDocument = gql`
    query ProjectFinancialsProjectPhases($slug: String!) {
  project(slug: $slug) {
    phases {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectFinancialsProjectPhasesQuery__
 *
 * To run a query within a React component, call `useProjectFinancialsProjectPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFinancialsProjectPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFinancialsProjectPhasesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProjectFinancialsProjectPhasesQuery(baseOptions: Apollo.QueryHookOptions<ProjectFinancialsProjectPhasesQuery, ProjectFinancialsProjectPhasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFinancialsProjectPhasesQuery, ProjectFinancialsProjectPhasesQueryVariables>(ProjectFinancialsProjectPhasesDocument, options);
      }
export function useProjectFinancialsProjectPhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFinancialsProjectPhasesQuery, ProjectFinancialsProjectPhasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFinancialsProjectPhasesQuery, ProjectFinancialsProjectPhasesQueryVariables>(ProjectFinancialsProjectPhasesDocument, options);
        }
export type ProjectFinancialsProjectPhasesQueryHookResult = ReturnType<typeof useProjectFinancialsProjectPhasesQuery>;
export type ProjectFinancialsProjectPhasesLazyQueryHookResult = ReturnType<typeof useProjectFinancialsProjectPhasesLazyQuery>;
export type ProjectFinancialsProjectPhasesQueryResult = Apollo.QueryResult<ProjectFinancialsProjectPhasesQuery, ProjectFinancialsProjectPhasesQueryVariables>;
export const ProjectFinancialsDocument = gql`
    query ProjectFinancials($slug: String!, $includeConsultantsInPhases: Boolean!, $filters: ProjectFinancialsFiltersInput!) {
  reports {
    financials(
      includeConsultantsInPhases: $includeConsultantsInPhases
      filters: $filters
      slug: $slug
    ) {
      hasPhaseConsultants
      phases {
        ...ProjectFinancialsEntryItem
      }
      phasesTotals {
        ...ProjectFinancialsEntryItem
      }
      consultants {
        ...ProjectFinancialsEntryItem
      }
      consultantsTotals {
        ...ProjectFinancialsEntryItem
      }
      expenses {
        ...ProjectFinancialsEntryItem
      }
      expensesTotals {
        ...ProjectFinancialsEntryItem
      }
      others {
        ...ProjectFinancialsOtherEntryItem
      }
      othersTotals {
        ...ProjectFinancialsOtherEntryItem
      }
      retainers {
        ...ProjectFinancialsRetainerEntryItem
      }
      retainersTotals {
        ...ProjectFinancialsRetainerEntryItem
      }
    }
  }
}
    ${ProjectFinancialsEntryItemFragmentDoc}
${ProjectFinancialsOtherEntryItemFragmentDoc}
${ProjectFinancialsRetainerEntryItemFragmentDoc}`;

/**
 * __useProjectFinancialsQuery__
 *
 * To run a query within a React component, call `useProjectFinancialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFinancialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFinancialsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includeConsultantsInPhases: // value for 'includeConsultantsInPhases'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectFinancialsQuery(baseOptions: Apollo.QueryHookOptions<ProjectFinancialsQuery, ProjectFinancialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFinancialsQuery, ProjectFinancialsQueryVariables>(ProjectFinancialsDocument, options);
      }
export function useProjectFinancialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFinancialsQuery, ProjectFinancialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFinancialsQuery, ProjectFinancialsQueryVariables>(ProjectFinancialsDocument, options);
        }
export type ProjectFinancialsQueryHookResult = ReturnType<typeof useProjectFinancialsQuery>;
export type ProjectFinancialsLazyQueryHookResult = ReturnType<typeof useProjectFinancialsLazyQuery>;
export type ProjectFinancialsQueryResult = Apollo.QueryResult<ProjectFinancialsQuery, ProjectFinancialsQueryVariables>;
export const ExportProjectFinancialsDocument = gql`
    mutation ExportProjectFinancials($slug: String!, $filters: ProjectFinancialsFiltersInput) {
  startExport {
    projectFinancials(input: {slug: $slug, filters: $filters}) {
      id
    }
  }
}
    `;
export type ExportProjectFinancialsMutationFn = Apollo.MutationFunction<ExportProjectFinancialsMutation, ExportProjectFinancialsMutationVariables>;

/**
 * __useExportProjectFinancialsMutation__
 *
 * To run a mutation, you first call `useExportProjectFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportProjectFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportProjectFinancialsMutation, { data, loading, error }] = useExportProjectFinancialsMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExportProjectFinancialsMutation(baseOptions?: Apollo.MutationHookOptions<ExportProjectFinancialsMutation, ExportProjectFinancialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportProjectFinancialsMutation, ExportProjectFinancialsMutationVariables>(ExportProjectFinancialsDocument, options);
      }
export type ExportProjectFinancialsMutationHookResult = ReturnType<typeof useExportProjectFinancialsMutation>;
export type ExportProjectFinancialsMutationResult = Apollo.MutationResult<ExportProjectFinancialsMutation>;
export type ExportProjectFinancialsMutationOptions = Apollo.BaseMutationOptions<ExportProjectFinancialsMutation, ExportProjectFinancialsMutationVariables>;
export const RoleTemplatesDocument = gql`
    query RoleTemplates {
  roleTemplates {
    id
    name
  }
}
    `;

/**
 * __useRoleTemplatesQuery__
 *
 * To run a query within a React component, call `useRoleTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<RoleTemplatesQuery, RoleTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleTemplatesQuery, RoleTemplatesQueryVariables>(RoleTemplatesDocument, options);
      }
export function useRoleTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleTemplatesQuery, RoleTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleTemplatesQuery, RoleTemplatesQueryVariables>(RoleTemplatesDocument, options);
        }
export type RoleTemplatesQueryHookResult = ReturnType<typeof useRoleTemplatesQuery>;
export type RoleTemplatesLazyQueryHookResult = ReturnType<typeof useRoleTemplatesLazyQuery>;
export type RoleTemplatesQueryResult = Apollo.QueryResult<RoleTemplatesQuery, RoleTemplatesQueryVariables>;
export const CreateSalesforceLeadDocument = gql`
    mutation CreateSalesforceLead($input: CreateSalesforceLeadInput!) {
  createSalesforceLead(input: $input) {
    email
  }
}
    `;
export type CreateSalesforceLeadMutationFn = Apollo.MutationFunction<CreateSalesforceLeadMutation, CreateSalesforceLeadMutationVariables>;

/**
 * __useCreateSalesforceLeadMutation__
 *
 * To run a mutation, you first call `useCreateSalesforceLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesforceLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesforceLeadMutation, { data, loading, error }] = useCreateSalesforceLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesforceLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesforceLeadMutation, CreateSalesforceLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalesforceLeadMutation, CreateSalesforceLeadMutationVariables>(CreateSalesforceLeadDocument, options);
      }
export type CreateSalesforceLeadMutationHookResult = ReturnType<typeof useCreateSalesforceLeadMutation>;
export type CreateSalesforceLeadMutationResult = Apollo.MutationResult<CreateSalesforceLeadMutation>;
export type CreateSalesforceLeadMutationOptions = Apollo.BaseMutationOptions<CreateSalesforceLeadMutation, CreateSalesforceLeadMutationVariables>;
export const AppDefaultsDocument = gql`
    query AppDefaults {
  currentProfile {
    id
    organization {
      id
      connectedToQbo
      timer
      overheads {
        ...AppDefaultsOverheadItem
      }
      settings {
        id
        ptoEnabled
        ajeraEnabled
        activityRequired
        defaultConsultantMarkup
        multiQboInstanceEnabled
      }
      timesheetLock {
        ...AppDefaultsTimesheetLocking
      }
      timesheetReminder {
        ...AppDefaultsTimesheetReminder
      }
      activities {
        ...AppDefaultsActivityItem
      }
      timesheetNotesReminder
      timesheetNotesExample
      invoiceFooter
      terms {
        ...AppDefaultsInvoiceTerm
      }
      consultantTemplates {
        ...AppDefaultsConsultantTemplate
      }
      expenseCategories {
        ...AppDefaultsExpenseCategory
      }
      qboInstances {
        ...AppDefaultsQboInstance
      }
      phaseTemplates {
        ...AppDefaultsPhaseTemplate
      }
      quickbooksItems {
        ...AppDefaultsQuickbooksItem
      }
    }
  }
  categories {
    ...AppDefaultsProjectCategory
  }
  taxRates {
    ...AppDefaultsTaxRate
  }
}
    ${AppDefaultsOverheadItemFragmentDoc}
${AppDefaultsTimesheetLockingFragmentDoc}
${AppDefaultsTimesheetReminderFragmentDoc}
${AppDefaultsActivityItemFragmentDoc}
${AppDefaultsInvoiceTermFragmentDoc}
${AppDefaultsConsultantTemplateFragmentDoc}
${AppDefaultsExpenseCategoryFragmentDoc}
${AppDefaultsQboInstanceFragmentDoc}
${AppDefaultsPhaseTemplateFragmentDoc}
${AppDefaultsQuickbooksItemFragmentDoc}
${AppDefaultsProjectCategoryFragmentDoc}
${AppDefaultsTaxRateFragmentDoc}`;

/**
 * __useAppDefaultsQuery__
 *
 * To run a query within a React component, call `useAppDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDefaultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppDefaultsQuery(baseOptions?: Apollo.QueryHookOptions<AppDefaultsQuery, AppDefaultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppDefaultsQuery, AppDefaultsQueryVariables>(AppDefaultsDocument, options);
      }
export function useAppDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppDefaultsQuery, AppDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppDefaultsQuery, AppDefaultsQueryVariables>(AppDefaultsDocument, options);
        }
export type AppDefaultsQueryHookResult = ReturnType<typeof useAppDefaultsQuery>;
export type AppDefaultsLazyQueryHookResult = ReturnType<typeof useAppDefaultsLazyQuery>;
export type AppDefaultsQueryResult = Apollo.QueryResult<AppDefaultsQuery, AppDefaultsQueryVariables>;
export const UpdateTimesheetLockDocument = gql`
    mutation UpdateTimesheetLock($timesheetLock: Boolean!) {
  updateTimesheetLock(input: {timesheetLock: $timesheetLock}) {
    timesheetLock {
      ...AppDefaultsTimesheetLocking
    }
  }
}
    ${AppDefaultsTimesheetLockingFragmentDoc}`;
export type UpdateTimesheetLockMutationFn = Apollo.MutationFunction<UpdateTimesheetLockMutation, UpdateTimesheetLockMutationVariables>;

/**
 * __useUpdateTimesheetLockMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetLockMutation, { data, loading, error }] = useUpdateTimesheetLockMutation({
 *   variables: {
 *      timesheetLock: // value for 'timesheetLock'
 *   },
 * });
 */
export function useUpdateTimesheetLockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetLockMutation, UpdateTimesheetLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetLockMutation, UpdateTimesheetLockMutationVariables>(UpdateTimesheetLockDocument, options);
      }
export type UpdateTimesheetLockMutationHookResult = ReturnType<typeof useUpdateTimesheetLockMutation>;
export type UpdateTimesheetLockMutationResult = Apollo.MutationResult<UpdateTimesheetLockMutation>;
export type UpdateTimesheetLockMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetLockMutation, UpdateTimesheetLockMutationVariables>;
export const UpdateTimesheetLockDaysTimeDocument = gql`
    mutation UpdateTimesheetLockDaysTime($timesheetLockDays: Int!, $timesheetLockTime: Float!) {
  updateTimesheetLockTime(input: {timesheetLockTime: $timesheetLockTime}) {
    timesheetLock {
      ...AppDefaultsTimesheetLocking
    }
  }
  updateTimesheetLockDays(input: {timesheetLockDays: $timesheetLockDays}) {
    timesheetLock {
      ...AppDefaultsTimesheetLocking
    }
  }
}
    ${AppDefaultsTimesheetLockingFragmentDoc}`;
export type UpdateTimesheetLockDaysTimeMutationFn = Apollo.MutationFunction<UpdateTimesheetLockDaysTimeMutation, UpdateTimesheetLockDaysTimeMutationVariables>;

/**
 * __useUpdateTimesheetLockDaysTimeMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetLockDaysTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetLockDaysTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetLockDaysTimeMutation, { data, loading, error }] = useUpdateTimesheetLockDaysTimeMutation({
 *   variables: {
 *      timesheetLockDays: // value for 'timesheetLockDays'
 *      timesheetLockTime: // value for 'timesheetLockTime'
 *   },
 * });
 */
export function useUpdateTimesheetLockDaysTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetLockDaysTimeMutation, UpdateTimesheetLockDaysTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetLockDaysTimeMutation, UpdateTimesheetLockDaysTimeMutationVariables>(UpdateTimesheetLockDaysTimeDocument, options);
      }
export type UpdateTimesheetLockDaysTimeMutationHookResult = ReturnType<typeof useUpdateTimesheetLockDaysTimeMutation>;
export type UpdateTimesheetLockDaysTimeMutationResult = Apollo.MutationResult<UpdateTimesheetLockDaysTimeMutation>;
export type UpdateTimesheetLockDaysTimeMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetLockDaysTimeMutation, UpdateTimesheetLockDaysTimeMutationVariables>;
export const UpdateTimesheetReminderDocument = gql`
    mutation UpdateTimesheetReminder($timesheetReminder: Boolean!) {
  updateTimesheetReminder(input: {timesheetReminder: $timesheetReminder}) {
    timesheetReminder {
      ...AppDefaultsTimesheetReminder
    }
  }
}
    ${AppDefaultsTimesheetReminderFragmentDoc}`;
export type UpdateTimesheetReminderMutationFn = Apollo.MutationFunction<UpdateTimesheetReminderMutation, UpdateTimesheetReminderMutationVariables>;

/**
 * __useUpdateTimesheetReminderMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetReminderMutation, { data, loading, error }] = useUpdateTimesheetReminderMutation({
 *   variables: {
 *      timesheetReminder: // value for 'timesheetReminder'
 *   },
 * });
 */
export function useUpdateTimesheetReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetReminderMutation, UpdateTimesheetReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetReminderMutation, UpdateTimesheetReminderMutationVariables>(UpdateTimesheetReminderDocument, options);
      }
export type UpdateTimesheetReminderMutationHookResult = ReturnType<typeof useUpdateTimesheetReminderMutation>;
export type UpdateTimesheetReminderMutationResult = Apollo.MutationResult<UpdateTimesheetReminderMutation>;
export type UpdateTimesheetReminderMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetReminderMutation, UpdateTimesheetReminderMutationVariables>;
export const UpdateTimesheetReminderDayTimeNoteDocument = gql`
    mutation UpdateTimesheetReminderDayTimeNote($timesheetReminderDay: Int!, $timesheetReminderTime: Float!, $timesheetReminderNote: String!) {
  updateTimesheetReminderDay(input: {timesheetReminderDay: $timesheetReminderDay}) {
    timesheetReminder {
      ...AppDefaultsTimesheetReminder
    }
  }
  updateTimesheetReminderTime(
    input: {timesheetReminderTime: $timesheetReminderTime}
  ) {
    timesheetReminder {
      ...AppDefaultsTimesheetReminder
    }
  }
  updateTimesheetReminderNote(
    input: {timesheetReminderNote: $timesheetReminderNote}
  ) {
    timesheetReminder {
      ...AppDefaultsTimesheetReminder
    }
  }
}
    ${AppDefaultsTimesheetReminderFragmentDoc}`;
export type UpdateTimesheetReminderDayTimeNoteMutationFn = Apollo.MutationFunction<UpdateTimesheetReminderDayTimeNoteMutation, UpdateTimesheetReminderDayTimeNoteMutationVariables>;

/**
 * __useUpdateTimesheetReminderDayTimeNoteMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetReminderDayTimeNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetReminderDayTimeNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetReminderDayTimeNoteMutation, { data, loading, error }] = useUpdateTimesheetReminderDayTimeNoteMutation({
 *   variables: {
 *      timesheetReminderDay: // value for 'timesheetReminderDay'
 *      timesheetReminderTime: // value for 'timesheetReminderTime'
 *      timesheetReminderNote: // value for 'timesheetReminderNote'
 *   },
 * });
 */
export function useUpdateTimesheetReminderDayTimeNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetReminderDayTimeNoteMutation, UpdateTimesheetReminderDayTimeNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetReminderDayTimeNoteMutation, UpdateTimesheetReminderDayTimeNoteMutationVariables>(UpdateTimesheetReminderDayTimeNoteDocument, options);
      }
export type UpdateTimesheetReminderDayTimeNoteMutationHookResult = ReturnType<typeof useUpdateTimesheetReminderDayTimeNoteMutation>;
export type UpdateTimesheetReminderDayTimeNoteMutationResult = Apollo.MutationResult<UpdateTimesheetReminderDayTimeNoteMutation>;
export type UpdateTimesheetReminderDayTimeNoteMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetReminderDayTimeNoteMutation, UpdateTimesheetReminderDayTimeNoteMutationVariables>;
export const UpdateTimerPreferenceDocument = gql`
    mutation UpdateTimerPreference($hasTimer: Boolean!) {
  updateTimer(input: {hasTimer: $hasTimer}) {
    organization {
      timer
    }
  }
}
    `;
export type UpdateTimerPreferenceMutationFn = Apollo.MutationFunction<UpdateTimerPreferenceMutation, UpdateTimerPreferenceMutationVariables>;

/**
 * __useUpdateTimerPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateTimerPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimerPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimerPreferenceMutation, { data, loading, error }] = useUpdateTimerPreferenceMutation({
 *   variables: {
 *      hasTimer: // value for 'hasTimer'
 *   },
 * });
 */
export function useUpdateTimerPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimerPreferenceMutation, UpdateTimerPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimerPreferenceMutation, UpdateTimerPreferenceMutationVariables>(UpdateTimerPreferenceDocument, options);
      }
export type UpdateTimerPreferenceMutationHookResult = ReturnType<typeof useUpdateTimerPreferenceMutation>;
export type UpdateTimerPreferenceMutationResult = Apollo.MutationResult<UpdateTimerPreferenceMutation>;
export type UpdateTimerPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateTimerPreferenceMutation, UpdateTimerPreferenceMutationVariables>;
export const CreateOverheadItemDocument = gql`
    mutation CreateOverheadItem($name: String!, $availableHours: Boolean!) {
  createOverhead(input: {name: $name, availableHours: $availableHours}) {
    overhead {
      ...AppDefaultsOverheadItem
    }
  }
}
    ${AppDefaultsOverheadItemFragmentDoc}`;
export type CreateOverheadItemMutationFn = Apollo.MutationFunction<CreateOverheadItemMutation, CreateOverheadItemMutationVariables>;

/**
 * __useCreateOverheadItemMutation__
 *
 * To run a mutation, you first call `useCreateOverheadItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOverheadItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOverheadItemMutation, { data, loading, error }] = useCreateOverheadItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      availableHours: // value for 'availableHours'
 *   },
 * });
 */
export function useCreateOverheadItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateOverheadItemMutation, CreateOverheadItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOverheadItemMutation, CreateOverheadItemMutationVariables>(CreateOverheadItemDocument, options);
      }
export type CreateOverheadItemMutationHookResult = ReturnType<typeof useCreateOverheadItemMutation>;
export type CreateOverheadItemMutationResult = Apollo.MutationResult<CreateOverheadItemMutation>;
export type CreateOverheadItemMutationOptions = Apollo.BaseMutationOptions<CreateOverheadItemMutation, CreateOverheadItemMutationVariables>;
export const DeleteOverheadItemDocument = gql`
    mutation DeleteOverheadItem($id: ID!) {
  deleteOverhead(input: {id: $id}) {
    overhead {
      ...AppDefaultsOverheadItem
    }
  }
}
    ${AppDefaultsOverheadItemFragmentDoc}`;
export type DeleteOverheadItemMutationFn = Apollo.MutationFunction<DeleteOverheadItemMutation, DeleteOverheadItemMutationVariables>;

/**
 * __useDeleteOverheadItemMutation__
 *
 * To run a mutation, you first call `useDeleteOverheadItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOverheadItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOverheadItemMutation, { data, loading, error }] = useDeleteOverheadItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOverheadItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOverheadItemMutation, DeleteOverheadItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOverheadItemMutation, DeleteOverheadItemMutationVariables>(DeleteOverheadItemDocument, options);
      }
export type DeleteOverheadItemMutationHookResult = ReturnType<typeof useDeleteOverheadItemMutation>;
export type DeleteOverheadItemMutationResult = Apollo.MutationResult<DeleteOverheadItemMutation>;
export type DeleteOverheadItemMutationOptions = Apollo.BaseMutationOptions<DeleteOverheadItemMutation, DeleteOverheadItemMutationVariables>;
export const UpdateOverheadItemDocument = gql`
    mutation UpdateOverheadItem($id: ID!, $name: String!, $availableHours: Boolean!) {
  updateOverhead(input: {id: $id, name: $name, availableHours: $availableHours}) {
    overhead {
      ...AppDefaultsOverheadItem
    }
  }
}
    ${AppDefaultsOverheadItemFragmentDoc}`;
export type UpdateOverheadItemMutationFn = Apollo.MutationFunction<UpdateOverheadItemMutation, UpdateOverheadItemMutationVariables>;

/**
 * __useUpdateOverheadItemMutation__
 *
 * To run a mutation, you first call `useUpdateOverheadItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOverheadItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOverheadItemMutation, { data, loading, error }] = useUpdateOverheadItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      availableHours: // value for 'availableHours'
 *   },
 * });
 */
export function useUpdateOverheadItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOverheadItemMutation, UpdateOverheadItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOverheadItemMutation, UpdateOverheadItemMutationVariables>(UpdateOverheadItemDocument, options);
      }
export type UpdateOverheadItemMutationHookResult = ReturnType<typeof useUpdateOverheadItemMutation>;
export type UpdateOverheadItemMutationResult = Apollo.MutationResult<UpdateOverheadItemMutation>;
export type UpdateOverheadItemMutationOptions = Apollo.BaseMutationOptions<UpdateOverheadItemMutation, UpdateOverheadItemMutationVariables>;
export const CreateActivityItemDocument = gql`
    mutation CreateActivityItem($name: String!, $isBillable: Boolean!) {
  createActivity(input: {name: $name, isBillable: $isBillable}) {
    activity {
      ...AppDefaultsActivityItem
    }
  }
}
    ${AppDefaultsActivityItemFragmentDoc}`;
export type CreateActivityItemMutationFn = Apollo.MutationFunction<CreateActivityItemMutation, CreateActivityItemMutationVariables>;

/**
 * __useCreateActivityItemMutation__
 *
 * To run a mutation, you first call `useCreateActivityItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityItemMutation, { data, loading, error }] = useCreateActivityItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isBillable: // value for 'isBillable'
 *   },
 * });
 */
export function useCreateActivityItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityItemMutation, CreateActivityItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivityItemMutation, CreateActivityItemMutationVariables>(CreateActivityItemDocument, options);
      }
export type CreateActivityItemMutationHookResult = ReturnType<typeof useCreateActivityItemMutation>;
export type CreateActivityItemMutationResult = Apollo.MutationResult<CreateActivityItemMutation>;
export type CreateActivityItemMutationOptions = Apollo.BaseMutationOptions<CreateActivityItemMutation, CreateActivityItemMutationVariables>;
export const DeleteActivityItemDocument = gql`
    mutation DeleteActivityItem($id: ID!) {
  deleteActivity(input: {id: $id}) {
    activity {
      ...AppDefaultsActivityItem
    }
  }
}
    ${AppDefaultsActivityItemFragmentDoc}`;
export type DeleteActivityItemMutationFn = Apollo.MutationFunction<DeleteActivityItemMutation, DeleteActivityItemMutationVariables>;

/**
 * __useDeleteActivityItemMutation__
 *
 * To run a mutation, you first call `useDeleteActivityItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityItemMutation, { data, loading, error }] = useDeleteActivityItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityItemMutation, DeleteActivityItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityItemMutation, DeleteActivityItemMutationVariables>(DeleteActivityItemDocument, options);
      }
export type DeleteActivityItemMutationHookResult = ReturnType<typeof useDeleteActivityItemMutation>;
export type DeleteActivityItemMutationResult = Apollo.MutationResult<DeleteActivityItemMutation>;
export type DeleteActivityItemMutationOptions = Apollo.BaseMutationOptions<DeleteActivityItemMutation, DeleteActivityItemMutationVariables>;
export const UpdateActivityItemDocument = gql`
    mutation UpdateActivityItem($id: ID!, $name: String!, $isBillable: Boolean!) {
  updateActivity(input: {id: $id, name: $name, isBillable: $isBillable}) {
    activity {
      ...AppDefaultsActivityItem
    }
  }
}
    ${AppDefaultsActivityItemFragmentDoc}`;
export type UpdateActivityItemMutationFn = Apollo.MutationFunction<UpdateActivityItemMutation, UpdateActivityItemMutationVariables>;

/**
 * __useUpdateActivityItemMutation__
 *
 * To run a mutation, you first call `useUpdateActivityItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityItemMutation, { data, loading, error }] = useUpdateActivityItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isBillable: // value for 'isBillable'
 *   },
 * });
 */
export function useUpdateActivityItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityItemMutation, UpdateActivityItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityItemMutation, UpdateActivityItemMutationVariables>(UpdateActivityItemDocument, options);
      }
export type UpdateActivityItemMutationHookResult = ReturnType<typeof useUpdateActivityItemMutation>;
export type UpdateActivityItemMutationResult = Apollo.MutationResult<UpdateActivityItemMutation>;
export type UpdateActivityItemMutationOptions = Apollo.BaseMutationOptions<UpdateActivityItemMutation, UpdateActivityItemMutationVariables>;
export const UpdateOrganizationSettingActivityRequiredDocument = gql`
    mutation UpdateOrganizationSettingActivityRequired($isActivityRequired: Boolean!) {
  updateOrganizationActivityRequired(
    input: {isActivityRequired: $isActivityRequired}
  ) {
    organization {
      id
      settings {
        id
        activityRequired
      }
    }
  }
}
    `;
export type UpdateOrganizationSettingActivityRequiredMutationFn = Apollo.MutationFunction<UpdateOrganizationSettingActivityRequiredMutation, UpdateOrganizationSettingActivityRequiredMutationVariables>;

/**
 * __useUpdateOrganizationSettingActivityRequiredMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingActivityRequiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingActivityRequiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingActivityRequiredMutation, { data, loading, error }] = useUpdateOrganizationSettingActivityRequiredMutation({
 *   variables: {
 *      isActivityRequired: // value for 'isActivityRequired'
 *   },
 * });
 */
export function useUpdateOrganizationSettingActivityRequiredMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSettingActivityRequiredMutation, UpdateOrganizationSettingActivityRequiredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationSettingActivityRequiredMutation, UpdateOrganizationSettingActivityRequiredMutationVariables>(UpdateOrganizationSettingActivityRequiredDocument, options);
      }
export type UpdateOrganizationSettingActivityRequiredMutationHookResult = ReturnType<typeof useUpdateOrganizationSettingActivityRequiredMutation>;
export type UpdateOrganizationSettingActivityRequiredMutationResult = Apollo.MutationResult<UpdateOrganizationSettingActivityRequiredMutation>;
export type UpdateOrganizationSettingActivityRequiredMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSettingActivityRequiredMutation, UpdateOrganizationSettingActivityRequiredMutationVariables>;
export const UpdateAjeraIdentifierDocument = gql`
    mutation UpdateAjeraIdentifier($input: UpdateAjeraIdentifierInput!) {
  updateAjeraIdentifier(input: $input) {
    ajeraIdentifier {
      id
      key
      typeKey
      recordId
      recordType
    }
  }
}
    `;
export type UpdateAjeraIdentifierMutationFn = Apollo.MutationFunction<UpdateAjeraIdentifierMutation, UpdateAjeraIdentifierMutationVariables>;

/**
 * __useUpdateAjeraIdentifierMutation__
 *
 * To run a mutation, you first call `useUpdateAjeraIdentifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAjeraIdentifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAjeraIdentifierMutation, { data, loading, error }] = useUpdateAjeraIdentifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAjeraIdentifierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAjeraIdentifierMutation, UpdateAjeraIdentifierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAjeraIdentifierMutation, UpdateAjeraIdentifierMutationVariables>(UpdateAjeraIdentifierDocument, options);
      }
export type UpdateAjeraIdentifierMutationHookResult = ReturnType<typeof useUpdateAjeraIdentifierMutation>;
export type UpdateAjeraIdentifierMutationResult = Apollo.MutationResult<UpdateAjeraIdentifierMutation>;
export type UpdateAjeraIdentifierMutationOptions = Apollo.BaseMutationOptions<UpdateAjeraIdentifierMutation, UpdateAjeraIdentifierMutationVariables>;
export const UpdateTimesheetNotesReminderDocument = gql`
    mutation UpdateTimesheetNotesReminder($timesheetNotesReminder: Boolean!) {
  updateTimesheetNotesReminder(
    input: {timesheetNotesReminder: $timesheetNotesReminder}
  ) {
    organization {
      id
      timesheetNotesReminder
    }
  }
}
    `;
export type UpdateTimesheetNotesReminderMutationFn = Apollo.MutationFunction<UpdateTimesheetNotesReminderMutation, UpdateTimesheetNotesReminderMutationVariables>;

/**
 * __useUpdateTimesheetNotesReminderMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetNotesReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetNotesReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetNotesReminderMutation, { data, loading, error }] = useUpdateTimesheetNotesReminderMutation({
 *   variables: {
 *      timesheetNotesReminder: // value for 'timesheetNotesReminder'
 *   },
 * });
 */
export function useUpdateTimesheetNotesReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetNotesReminderMutation, UpdateTimesheetNotesReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetNotesReminderMutation, UpdateTimesheetNotesReminderMutationVariables>(UpdateTimesheetNotesReminderDocument, options);
      }
export type UpdateTimesheetNotesReminderMutationHookResult = ReturnType<typeof useUpdateTimesheetNotesReminderMutation>;
export type UpdateTimesheetNotesReminderMutationResult = Apollo.MutationResult<UpdateTimesheetNotesReminderMutation>;
export type UpdateTimesheetNotesReminderMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetNotesReminderMutation, UpdateTimesheetNotesReminderMutationVariables>;
export const UpdateTimesheetNotesExampleDocument = gql`
    mutation UpdateTimesheetNotesExample($timesheetNotesExample: String!) {
  updateTimesheetNotesExample(
    input: {timesheetNotesExample: $timesheetNotesExample}
  ) {
    organization {
      id
      timesheetNotesExample
    }
  }
}
    `;
export type UpdateTimesheetNotesExampleMutationFn = Apollo.MutationFunction<UpdateTimesheetNotesExampleMutation, UpdateTimesheetNotesExampleMutationVariables>;

/**
 * __useUpdateTimesheetNotesExampleMutation__
 *
 * To run a mutation, you first call `useUpdateTimesheetNotesExampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimesheetNotesExampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimesheetNotesExampleMutation, { data, loading, error }] = useUpdateTimesheetNotesExampleMutation({
 *   variables: {
 *      timesheetNotesExample: // value for 'timesheetNotesExample'
 *   },
 * });
 */
export function useUpdateTimesheetNotesExampleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimesheetNotesExampleMutation, UpdateTimesheetNotesExampleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimesheetNotesExampleMutation, UpdateTimesheetNotesExampleMutationVariables>(UpdateTimesheetNotesExampleDocument, options);
      }
export type UpdateTimesheetNotesExampleMutationHookResult = ReturnType<typeof useUpdateTimesheetNotesExampleMutation>;
export type UpdateTimesheetNotesExampleMutationResult = Apollo.MutationResult<UpdateTimesheetNotesExampleMutation>;
export type UpdateTimesheetNotesExampleMutationOptions = Apollo.BaseMutationOptions<UpdateTimesheetNotesExampleMutation, UpdateTimesheetNotesExampleMutationVariables>;
export const CreateProjectCategoryDocument = gql`
    mutation CreateProjectCategory($name: String!) {
  createCategory(input: {name: $name}) {
    category {
      ...AppDefaultsProjectCategory
    }
  }
}
    ${AppDefaultsProjectCategoryFragmentDoc}`;
export type CreateProjectCategoryMutationFn = Apollo.MutationFunction<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>;

/**
 * __useCreateProjectCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProjectCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCategoryMutation, { data, loading, error }] = useCreateProjectCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProjectCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>(CreateProjectCategoryDocument, options);
      }
export type CreateProjectCategoryMutationHookResult = ReturnType<typeof useCreateProjectCategoryMutation>;
export type CreateProjectCategoryMutationResult = Apollo.MutationResult<CreateProjectCategoryMutation>;
export type CreateProjectCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>;
export const UpdateProjectCategoryDocument = gql`
    mutation UpdateProjectCategory($id: ID!, $name: String!) {
  updateCategory(input: {id: $id, name: $name}) {
    category {
      ...AppDefaultsProjectCategory
    }
  }
}
    ${AppDefaultsProjectCategoryFragmentDoc}`;
export type UpdateProjectCategoryMutationFn = Apollo.MutationFunction<UpdateProjectCategoryMutation, UpdateProjectCategoryMutationVariables>;

/**
 * __useUpdateProjectCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCategoryMutation, { data, loading, error }] = useUpdateProjectCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateProjectCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectCategoryMutation, UpdateProjectCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectCategoryMutation, UpdateProjectCategoryMutationVariables>(UpdateProjectCategoryDocument, options);
      }
export type UpdateProjectCategoryMutationHookResult = ReturnType<typeof useUpdateProjectCategoryMutation>;
export type UpdateProjectCategoryMutationResult = Apollo.MutationResult<UpdateProjectCategoryMutation>;
export type UpdateProjectCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProjectCategoryMutation, UpdateProjectCategoryMutationVariables>;
export const DeleteProjectCategoryDocument = gql`
    mutation DeleteProjectCategory($id: ID!) {
  deleteCategory(input: {id: $id}) {
    category {
      ...AppDefaultsProjectCategory
    }
  }
}
    ${AppDefaultsProjectCategoryFragmentDoc}`;
export type DeleteProjectCategoryMutationFn = Apollo.MutationFunction<DeleteProjectCategoryMutation, DeleteProjectCategoryMutationVariables>;

/**
 * __useDeleteProjectCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCategoryMutation, { data, loading, error }] = useDeleteProjectCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectCategoryMutation, DeleteProjectCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectCategoryMutation, DeleteProjectCategoryMutationVariables>(DeleteProjectCategoryDocument, options);
      }
export type DeleteProjectCategoryMutationHookResult = ReturnType<typeof useDeleteProjectCategoryMutation>;
export type DeleteProjectCategoryMutationResult = Apollo.MutationResult<DeleteProjectCategoryMutation>;
export type DeleteProjectCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteProjectCategoryMutation, DeleteProjectCategoryMutationVariables>;
export const UpdateInvoiceFooterDocument = gql`
    mutation UpdateInvoiceFooter($invoiceFooter: String!) {
  updateInvoiceFooter(input: {invoiceFooter: $invoiceFooter}) {
    organization {
      id
      invoiceFooter
    }
  }
}
    `;
export type UpdateInvoiceFooterMutationFn = Apollo.MutationFunction<UpdateInvoiceFooterMutation, UpdateInvoiceFooterMutationVariables>;

/**
 * __useUpdateInvoiceFooterMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceFooterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceFooterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceFooterMutation, { data, loading, error }] = useUpdateInvoiceFooterMutation({
 *   variables: {
 *      invoiceFooter: // value for 'invoiceFooter'
 *   },
 * });
 */
export function useUpdateInvoiceFooterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceFooterMutation, UpdateInvoiceFooterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceFooterMutation, UpdateInvoiceFooterMutationVariables>(UpdateInvoiceFooterDocument, options);
      }
export type UpdateInvoiceFooterMutationHookResult = ReturnType<typeof useUpdateInvoiceFooterMutation>;
export type UpdateInvoiceFooterMutationResult = Apollo.MutationResult<UpdateInvoiceFooterMutation>;
export type UpdateInvoiceFooterMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceFooterMutation, UpdateInvoiceFooterMutationVariables>;
export const UpdateOrganizationConsultantMarkupDocument = gql`
    mutation UpdateOrganizationConsultantMarkup($defaultConsultantMarkup: Float!) {
  updateOrganizationConsultantMarkup(
    input: {defaultConsultantMarkup: $defaultConsultantMarkup}
  ) {
    organization {
      id
      settings {
        id
        defaultConsultantMarkup
      }
    }
  }
}
    `;
export type UpdateOrganizationConsultantMarkupMutationFn = Apollo.MutationFunction<UpdateOrganizationConsultantMarkupMutation, UpdateOrganizationConsultantMarkupMutationVariables>;

/**
 * __useUpdateOrganizationConsultantMarkupMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationConsultantMarkupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationConsultantMarkupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationConsultantMarkupMutation, { data, loading, error }] = useUpdateOrganizationConsultantMarkupMutation({
 *   variables: {
 *      defaultConsultantMarkup: // value for 'defaultConsultantMarkup'
 *   },
 * });
 */
export function useUpdateOrganizationConsultantMarkupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationConsultantMarkupMutation, UpdateOrganizationConsultantMarkupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationConsultantMarkupMutation, UpdateOrganizationConsultantMarkupMutationVariables>(UpdateOrganizationConsultantMarkupDocument, options);
      }
export type UpdateOrganizationConsultantMarkupMutationHookResult = ReturnType<typeof useUpdateOrganizationConsultantMarkupMutation>;
export type UpdateOrganizationConsultantMarkupMutationResult = Apollo.MutationResult<UpdateOrganizationConsultantMarkupMutation>;
export type UpdateOrganizationConsultantMarkupMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationConsultantMarkupMutation, UpdateOrganizationConsultantMarkupMutationVariables>;
export const CreateTaxRateDocument = gql`
    mutation CreateTaxRate($name: String!, $percent: Float!) {
  createTaxRate(input: {name: $name, percent: $percent}) {
    taxRate {
      ...AppDefaultsTaxRate
    }
  }
}
    ${AppDefaultsTaxRateFragmentDoc}`;
export type CreateTaxRateMutationFn = Apollo.MutationFunction<CreateTaxRateMutation, CreateTaxRateMutationVariables>;

/**
 * __useCreateTaxRateMutation__
 *
 * To run a mutation, you first call `useCreateTaxRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxRateMutation, { data, loading, error }] = useCreateTaxRateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useCreateTaxRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxRateMutation, CreateTaxRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxRateMutation, CreateTaxRateMutationVariables>(CreateTaxRateDocument, options);
      }
export type CreateTaxRateMutationHookResult = ReturnType<typeof useCreateTaxRateMutation>;
export type CreateTaxRateMutationResult = Apollo.MutationResult<CreateTaxRateMutation>;
export type CreateTaxRateMutationOptions = Apollo.BaseMutationOptions<CreateTaxRateMutation, CreateTaxRateMutationVariables>;
export const UpdateTaxRateDocument = gql`
    mutation UpdateTaxRate($id: ID!, $name: String!, $percent: Float!) {
  updateTaxRate(input: {id: $id, name: $name, percent: $percent}) {
    taxRate {
      ...AppDefaultsTaxRate
    }
  }
}
    ${AppDefaultsTaxRateFragmentDoc}`;
export type UpdateTaxRateMutationFn = Apollo.MutationFunction<UpdateTaxRateMutation, UpdateTaxRateMutationVariables>;

/**
 * __useUpdateTaxRateMutation__
 *
 * To run a mutation, you first call `useUpdateTaxRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxRateMutation, { data, loading, error }] = useUpdateTaxRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useUpdateTaxRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxRateMutation, UpdateTaxRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxRateMutation, UpdateTaxRateMutationVariables>(UpdateTaxRateDocument, options);
      }
export type UpdateTaxRateMutationHookResult = ReturnType<typeof useUpdateTaxRateMutation>;
export type UpdateTaxRateMutationResult = Apollo.MutationResult<UpdateTaxRateMutation>;
export type UpdateTaxRateMutationOptions = Apollo.BaseMutationOptions<UpdateTaxRateMutation, UpdateTaxRateMutationVariables>;
export const DeleteTaxRateDocument = gql`
    mutation DeleteTaxRate($id: ID!) {
  deleteTaxRate(input: {id: $id}) {
    taxRate {
      id
    }
  }
}
    `;
export type DeleteTaxRateMutationFn = Apollo.MutationFunction<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>;

/**
 * __useDeleteTaxRateMutation__
 *
 * To run a mutation, you first call `useDeleteTaxRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxRateMutation, { data, loading, error }] = useDeleteTaxRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaxRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>(DeleteTaxRateDocument, options);
      }
export type DeleteTaxRateMutationHookResult = ReturnType<typeof useDeleteTaxRateMutation>;
export type DeleteTaxRateMutationResult = Apollo.MutationResult<DeleteTaxRateMutation>;
export type DeleteTaxRateMutationOptions = Apollo.BaseMutationOptions<DeleteTaxRateMutation, DeleteTaxRateMutationVariables>;
export const CreateInvoiceTermDocument = gql`
    mutation CreateInvoiceTerm($name: String!, $days: Int!, $active: Boolean!) {
  createTerm(input: {name: $name, days: $days, active: $active}) {
    term {
      ...AppDefaultsInvoiceTerm
    }
  }
}
    ${AppDefaultsInvoiceTermFragmentDoc}`;
export type CreateInvoiceTermMutationFn = Apollo.MutationFunction<CreateInvoiceTermMutation, CreateInvoiceTermMutationVariables>;

/**
 * __useCreateInvoiceTermMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceTermMutation, { data, loading, error }] = useCreateInvoiceTermMutation({
 *   variables: {
 *      name: // value for 'name'
 *      days: // value for 'days'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateInvoiceTermMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceTermMutation, CreateInvoiceTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceTermMutation, CreateInvoiceTermMutationVariables>(CreateInvoiceTermDocument, options);
      }
export type CreateInvoiceTermMutationHookResult = ReturnType<typeof useCreateInvoiceTermMutation>;
export type CreateInvoiceTermMutationResult = Apollo.MutationResult<CreateInvoiceTermMutation>;
export type CreateInvoiceTermMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceTermMutation, CreateInvoiceTermMutationVariables>;
export const UpdateInvoiceTermDocument = gql`
    mutation UpdateInvoiceTerm($id: ID!, $name: String!, $days: Int!, $active: Boolean!) {
  updateTerm(input: {id: $id, name: $name, days: $days, active: $active}) {
    term {
      ...AppDefaultsInvoiceTerm
    }
  }
}
    ${AppDefaultsInvoiceTermFragmentDoc}`;
export type UpdateInvoiceTermMutationFn = Apollo.MutationFunction<UpdateInvoiceTermMutation, UpdateInvoiceTermMutationVariables>;

/**
 * __useUpdateInvoiceTermMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceTermMutation, { data, loading, error }] = useUpdateInvoiceTermMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      days: // value for 'days'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateInvoiceTermMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceTermMutation, UpdateInvoiceTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceTermMutation, UpdateInvoiceTermMutationVariables>(UpdateInvoiceTermDocument, options);
      }
export type UpdateInvoiceTermMutationHookResult = ReturnType<typeof useUpdateInvoiceTermMutation>;
export type UpdateInvoiceTermMutationResult = Apollo.MutationResult<UpdateInvoiceTermMutation>;
export type UpdateInvoiceTermMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceTermMutation, UpdateInvoiceTermMutationVariables>;
export const CreateConsultantTemplateDocument = gql`
    mutation CreateConsultantTemplate($name: String!, $quickbooksItemId: ID) {
  createConsultantTemplate(
    input: {name: $name, quickbooksItemId: $quickbooksItemId}
  ) {
    consultantTemplate {
      ...AppDefaultsConsultantTemplate
    }
  }
}
    ${AppDefaultsConsultantTemplateFragmentDoc}`;
export type CreateConsultantTemplateMutationFn = Apollo.MutationFunction<CreateConsultantTemplateMutation, CreateConsultantTemplateMutationVariables>;

/**
 * __useCreateConsultantTemplateMutation__
 *
 * To run a mutation, you first call `useCreateConsultantTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultantTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultantTemplateMutation, { data, loading, error }] = useCreateConsultantTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      quickbooksItemId: // value for 'quickbooksItemId'
 *   },
 * });
 */
export function useCreateConsultantTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateConsultantTemplateMutation, CreateConsultantTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConsultantTemplateMutation, CreateConsultantTemplateMutationVariables>(CreateConsultantTemplateDocument, options);
      }
export type CreateConsultantTemplateMutationHookResult = ReturnType<typeof useCreateConsultantTemplateMutation>;
export type CreateConsultantTemplateMutationResult = Apollo.MutationResult<CreateConsultantTemplateMutation>;
export type CreateConsultantTemplateMutationOptions = Apollo.BaseMutationOptions<CreateConsultantTemplateMutation, CreateConsultantTemplateMutationVariables>;
export const DeleteConsultantTemplateDocument = gql`
    mutation DeleteConsultantTemplate($id: ID!) {
  deleteConsultantTemplate(input: {id: $id}) {
    consultantTemplate {
      ...AppDefaultsConsultantTemplate
    }
  }
}
    ${AppDefaultsConsultantTemplateFragmentDoc}`;
export type DeleteConsultantTemplateMutationFn = Apollo.MutationFunction<DeleteConsultantTemplateMutation, DeleteConsultantTemplateMutationVariables>;

/**
 * __useDeleteConsultantTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteConsultantTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsultantTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsultantTemplateMutation, { data, loading, error }] = useDeleteConsultantTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConsultantTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConsultantTemplateMutation, DeleteConsultantTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConsultantTemplateMutation, DeleteConsultantTemplateMutationVariables>(DeleteConsultantTemplateDocument, options);
      }
export type DeleteConsultantTemplateMutationHookResult = ReturnType<typeof useDeleteConsultantTemplateMutation>;
export type DeleteConsultantTemplateMutationResult = Apollo.MutationResult<DeleteConsultantTemplateMutation>;
export type DeleteConsultantTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteConsultantTemplateMutation, DeleteConsultantTemplateMutationVariables>;
export const UpdateConsultantTemplateDocument = gql`
    mutation UpdateConsultantTemplate($id: ID!, $name: String!) {
  updateConsultantTemplate(input: {id: $id, name: $name}) {
    consultantTemplate {
      ...AppDefaultsConsultantTemplate
    }
  }
}
    ${AppDefaultsConsultantTemplateFragmentDoc}`;
export type UpdateConsultantTemplateMutationFn = Apollo.MutationFunction<UpdateConsultantTemplateMutation, UpdateConsultantTemplateMutationVariables>;

/**
 * __useUpdateConsultantTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateConsultantTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultantTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultantTemplateMutation, { data, loading, error }] = useUpdateConsultantTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateConsultantTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultantTemplateMutation, UpdateConsultantTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultantTemplateMutation, UpdateConsultantTemplateMutationVariables>(UpdateConsultantTemplateDocument, options);
      }
export type UpdateConsultantTemplateMutationHookResult = ReturnType<typeof useUpdateConsultantTemplateMutation>;
export type UpdateConsultantTemplateMutationResult = Apollo.MutationResult<UpdateConsultantTemplateMutation>;
export type UpdateConsultantTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateConsultantTemplateMutation, UpdateConsultantTemplateMutationVariables>;
export const CreateExpenseCategoryDocument = gql`
    mutation CreateExpenseCategory($name: String!, $markupPercentage: Float!, $qboExpenseAccountId: ID, $qboItemMapping: [ExpenseCategoryQBOItemMapping!]) {
  createExpenseCategory(
    input: {name: $name, markupPercentage: $markupPercentage, qboExpenseAccountId: $qboExpenseAccountId, qboItemMapping: $qboItemMapping}
  ) {
    expenseCategory {
      ...AppDefaultsExpenseCategory
    }
  }
}
    ${AppDefaultsExpenseCategoryFragmentDoc}`;
export type CreateExpenseCategoryMutationFn = Apollo.MutationFunction<CreateExpenseCategoryMutation, CreateExpenseCategoryMutationVariables>;

/**
 * __useCreateExpenseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateExpenseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseCategoryMutation, { data, loading, error }] = useCreateExpenseCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      markupPercentage: // value for 'markupPercentage'
 *      qboExpenseAccountId: // value for 'qboExpenseAccountId'
 *      qboItemMapping: // value for 'qboItemMapping'
 *   },
 * });
 */
export function useCreateExpenseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseCategoryMutation, CreateExpenseCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseCategoryMutation, CreateExpenseCategoryMutationVariables>(CreateExpenseCategoryDocument, options);
      }
export type CreateExpenseCategoryMutationHookResult = ReturnType<typeof useCreateExpenseCategoryMutation>;
export type CreateExpenseCategoryMutationResult = Apollo.MutationResult<CreateExpenseCategoryMutation>;
export type CreateExpenseCategoryMutationOptions = Apollo.BaseMutationOptions<CreateExpenseCategoryMutation, CreateExpenseCategoryMutationVariables>;
export const UpdateExpenseCategoryDocument = gql`
    mutation UpdateExpenseCategory($id: ID!, $name: String!, $markupPercentage: Float!) {
  updateExpenseCategory(
    input: {id: $id, name: $name, markupPercentage: $markupPercentage}
  ) {
    expenseCategory {
      ...AppDefaultsExpenseCategory
    }
  }
}
    ${AppDefaultsExpenseCategoryFragmentDoc}`;
export type UpdateExpenseCategoryMutationFn = Apollo.MutationFunction<UpdateExpenseCategoryMutation, UpdateExpenseCategoryMutationVariables>;

/**
 * __useUpdateExpenseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseCategoryMutation, { data, loading, error }] = useUpdateExpenseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      markupPercentage: // value for 'markupPercentage'
 *   },
 * });
 */
export function useUpdateExpenseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseCategoryMutation, UpdateExpenseCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseCategoryMutation, UpdateExpenseCategoryMutationVariables>(UpdateExpenseCategoryDocument, options);
      }
export type UpdateExpenseCategoryMutationHookResult = ReturnType<typeof useUpdateExpenseCategoryMutation>;
export type UpdateExpenseCategoryMutationResult = Apollo.MutationResult<UpdateExpenseCategoryMutation>;
export type UpdateExpenseCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseCategoryMutation, UpdateExpenseCategoryMutationVariables>;
export const DeleteExpenseCategoryDocument = gql`
    mutation DeleteExpenseCategory($id: ID!) {
  deleteExpenseCategory(input: {id: $id}) {
    expenseCategory {
      ...AppDefaultsExpenseCategory
    }
  }
}
    ${AppDefaultsExpenseCategoryFragmentDoc}`;
export type DeleteExpenseCategoryMutationFn = Apollo.MutationFunction<DeleteExpenseCategoryMutation, DeleteExpenseCategoryMutationVariables>;

/**
 * __useDeleteExpenseCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseCategoryMutation, { data, loading, error }] = useDeleteExpenseCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpenseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseCategoryMutation, DeleteExpenseCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseCategoryMutation, DeleteExpenseCategoryMutationVariables>(DeleteExpenseCategoryDocument, options);
      }
export type DeleteExpenseCategoryMutationHookResult = ReturnType<typeof useDeleteExpenseCategoryMutation>;
export type DeleteExpenseCategoryMutationResult = Apollo.MutationResult<DeleteExpenseCategoryMutation>;
export type DeleteExpenseCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseCategoryMutation, DeleteExpenseCategoryMutationVariables>;
export const CreatePhaseTemplateDocument = gql`
    mutation CreatePhaseTemplate($name: String!, $abbreviation: String!, $feeType: PhaseFee!, $qboItemIds: [ID!]) {
  createPhaseTemplate(
    input: {name: $name, abbreviation: $abbreviation, feeType: $feeType, qboItemIds: $qboItemIds}
  ) {
    organization {
      id
      phaseTemplates {
        ...AppDefaultsPhaseTemplate
      }
    }
  }
}
    ${AppDefaultsPhaseTemplateFragmentDoc}`;
export type CreatePhaseTemplateMutationFn = Apollo.MutationFunction<CreatePhaseTemplateMutation, CreatePhaseTemplateMutationVariables>;

/**
 * __useCreatePhaseTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePhaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhaseTemplateMutation, { data, loading, error }] = useCreatePhaseTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      feeType: // value for 'feeType'
 *      qboItemIds: // value for 'qboItemIds'
 *   },
 * });
 */
export function useCreatePhaseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhaseTemplateMutation, CreatePhaseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhaseTemplateMutation, CreatePhaseTemplateMutationVariables>(CreatePhaseTemplateDocument, options);
      }
export type CreatePhaseTemplateMutationHookResult = ReturnType<typeof useCreatePhaseTemplateMutation>;
export type CreatePhaseTemplateMutationResult = Apollo.MutationResult<CreatePhaseTemplateMutation>;
export type CreatePhaseTemplateMutationOptions = Apollo.BaseMutationOptions<CreatePhaseTemplateMutation, CreatePhaseTemplateMutationVariables>;
export const DeletePhaseTemplateDocument = gql`
    mutation DeletePhaseTemplate($id: ID!) {
  deletePhaseTemplate(input: {id: $id}) {
    organization {
      id
      phaseTemplates {
        ...AppDefaultsPhaseTemplate
      }
    }
  }
}
    ${AppDefaultsPhaseTemplateFragmentDoc}`;
export type DeletePhaseTemplateMutationFn = Apollo.MutationFunction<DeletePhaseTemplateMutation, DeletePhaseTemplateMutationVariables>;

/**
 * __useDeletePhaseTemplateMutation__
 *
 * To run a mutation, you first call `useDeletePhaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhaseTemplateMutation, { data, loading, error }] = useDeletePhaseTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhaseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhaseTemplateMutation, DeletePhaseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhaseTemplateMutation, DeletePhaseTemplateMutationVariables>(DeletePhaseTemplateDocument, options);
      }
export type DeletePhaseTemplateMutationHookResult = ReturnType<typeof useDeletePhaseTemplateMutation>;
export type DeletePhaseTemplateMutationResult = Apollo.MutationResult<DeletePhaseTemplateMutation>;
export type DeletePhaseTemplateMutationOptions = Apollo.BaseMutationOptions<DeletePhaseTemplateMutation, DeletePhaseTemplateMutationVariables>;
export const UpdatePhaseTemplateDocument = gql`
    mutation UpdatePhaseTemplate($id: ID!, $name: String!, $abbreviation: String!, $feeType: PhaseFee!) {
  updatePhaseTemplate(
    input: {id: $id, name: $name, abbreviation: $abbreviation, feeType: $feeType}
  ) {
    phaseTemplate {
      ...AppDefaultsPhaseTemplate
    }
  }
}
    ${AppDefaultsPhaseTemplateFragmentDoc}`;
export type UpdatePhaseTemplateMutationFn = Apollo.MutationFunction<UpdatePhaseTemplateMutation, UpdatePhaseTemplateMutationVariables>;

/**
 * __useUpdatePhaseTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseTemplateMutation, { data, loading, error }] = useUpdatePhaseTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      abbreviation: // value for 'abbreviation'
 *      feeType: // value for 'feeType'
 *   },
 * });
 */
export function useUpdatePhaseTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhaseTemplateMutation, UpdatePhaseTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhaseTemplateMutation, UpdatePhaseTemplateMutationVariables>(UpdatePhaseTemplateDocument, options);
      }
export type UpdatePhaseTemplateMutationHookResult = ReturnType<typeof useUpdatePhaseTemplateMutation>;
export type UpdatePhaseTemplateMutationResult = Apollo.MutationResult<UpdatePhaseTemplateMutation>;
export type UpdatePhaseTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePhaseTemplateMutation, UpdatePhaseTemplateMutationVariables>;
export const OrganizationSettingsDocument = gql`
    query OrganizationSettings {
  currentProfile {
    id
    organization {
      id
      name
      logo
      timeZone
      overheadMultiplier
      emailSenderName
      emailReplyToAddress
      isSubscribed
      telephone
      website
      paySchedule {
        id
      }
      settings {
        ajeraEnabled
        defaultRealizationRate
        defaultUtilizationRate
        unitOfMeasurement
        ptoEnabled
        timeOffToggledAt
        applyUtilizationRateToExistingProfiles
        defaultHoursPerWeek
        yearlyWorkingHours
      }
      address {
        ...OrganizationSettingsAddress
      }
      currency {
        ...OrganizationSettingsCurrency
      }
      locale {
        ...OrganizationSettingsLocale
      }
      stripeAccount {
        active
      }
    }
  }
  timeZones {
    value
    name
  }
  locales {
    id
    name
    tag
  }
  currencies {
    code
    decimalDigits
    id
    name
    symbol
  }
}
    ${OrganizationSettingsAddressFragmentDoc}
${OrganizationSettingsCurrencyFragmentDoc}
${OrganizationSettingsLocaleFragmentDoc}`;

/**
 * __useOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>(OrganizationSettingsDocument, options);
      }
export function useOrganizationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>(OrganizationSettingsDocument, options);
        }
export type OrganizationSettingsQueryHookResult = ReturnType<typeof useOrganizationSettingsQuery>;
export type OrganizationSettingsLazyQueryHookResult = ReturnType<typeof useOrganizationSettingsLazyQuery>;
export type OrganizationSettingsQueryResult = Apollo.QueryResult<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>;
export const OrgUpdateCompanyLogoDocument = gql`
    mutation OrgUpdateCompanyLogo($logo: String) {
  updateOrganizationLogo(input: {logo: $logo}) {
    organization {
      logo
    }
  }
}
    `;
export type OrgUpdateCompanyLogoMutationFn = Apollo.MutationFunction<OrgUpdateCompanyLogoMutation, OrgUpdateCompanyLogoMutationVariables>;

/**
 * __useOrgUpdateCompanyLogoMutation__
 *
 * To run a mutation, you first call `useOrgUpdateCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateCompanyLogoMutation, { data, loading, error }] = useOrgUpdateCompanyLogoMutation({
 *   variables: {
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useOrgUpdateCompanyLogoMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateCompanyLogoMutation, OrgUpdateCompanyLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateCompanyLogoMutation, OrgUpdateCompanyLogoMutationVariables>(OrgUpdateCompanyLogoDocument, options);
      }
export type OrgUpdateCompanyLogoMutationHookResult = ReturnType<typeof useOrgUpdateCompanyLogoMutation>;
export type OrgUpdateCompanyLogoMutationResult = Apollo.MutationResult<OrgUpdateCompanyLogoMutation>;
export type OrgUpdateCompanyLogoMutationOptions = Apollo.BaseMutationOptions<OrgUpdateCompanyLogoMutation, OrgUpdateCompanyLogoMutationVariables>;
export const OrgUpdateOrgNameDocument = gql`
    mutation OrgUpdateOrgName($name: String!) {
  updateOrganizationName(input: {name: $name}) {
    organization {
      id
      name
    }
  }
}
    `;
export type OrgUpdateOrgNameMutationFn = Apollo.MutationFunction<OrgUpdateOrgNameMutation, OrgUpdateOrgNameMutationVariables>;

/**
 * __useOrgUpdateOrgNameMutation__
 *
 * To run a mutation, you first call `useOrgUpdateOrgNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateOrgNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateOrgNameMutation, { data, loading, error }] = useOrgUpdateOrgNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrgUpdateOrgNameMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateOrgNameMutation, OrgUpdateOrgNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateOrgNameMutation, OrgUpdateOrgNameMutationVariables>(OrgUpdateOrgNameDocument, options);
      }
export type OrgUpdateOrgNameMutationHookResult = ReturnType<typeof useOrgUpdateOrgNameMutation>;
export type OrgUpdateOrgNameMutationResult = Apollo.MutationResult<OrgUpdateOrgNameMutation>;
export type OrgUpdateOrgNameMutationOptions = Apollo.BaseMutationOptions<OrgUpdateOrgNameMutation, OrgUpdateOrgNameMutationVariables>;
export const OrgUpdateCompanyAddressDocument = gql`
    mutation OrgUpdateCompanyAddress($line1: String, $line2: String, $city: String, $state: String, $zipcode: String, $country: String) {
  updateOrganizationAddress(
    input: {line1: $line1, line2: $line2, city: $city, state: $state, zipcode: $zipcode, country: $country}
  ) {
    address {
      ...OrganizationSettingsAddress
    }
  }
}
    ${OrganizationSettingsAddressFragmentDoc}`;
export type OrgUpdateCompanyAddressMutationFn = Apollo.MutationFunction<OrgUpdateCompanyAddressMutation, OrgUpdateCompanyAddressMutationVariables>;

/**
 * __useOrgUpdateCompanyAddressMutation__
 *
 * To run a mutation, you first call `useOrgUpdateCompanyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateCompanyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateCompanyAddressMutation, { data, loading, error }] = useOrgUpdateCompanyAddressMutation({
 *   variables: {
 *      line1: // value for 'line1'
 *      line2: // value for 'line2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zipcode: // value for 'zipcode'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useOrgUpdateCompanyAddressMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateCompanyAddressMutation, OrgUpdateCompanyAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateCompanyAddressMutation, OrgUpdateCompanyAddressMutationVariables>(OrgUpdateCompanyAddressDocument, options);
      }
export type OrgUpdateCompanyAddressMutationHookResult = ReturnType<typeof useOrgUpdateCompanyAddressMutation>;
export type OrgUpdateCompanyAddressMutationResult = Apollo.MutationResult<OrgUpdateCompanyAddressMutation>;
export type OrgUpdateCompanyAddressMutationOptions = Apollo.BaseMutationOptions<OrgUpdateCompanyAddressMutation, OrgUpdateCompanyAddressMutationVariables>;
export const OrgUpdateClientEmailSettingsDocument = gql`
    mutation OrgUpdateClientEmailSettings($emailSenderName: String, $emailReplyToAddress: String) {
  updateOrganizationEmailSenderName(input: {emailSenderName: $emailSenderName}) {
    organization {
      id
      emailSenderName
    }
  }
  updateOrganizationEmailReplyToAddress(
    input: {emailReplyToAddress: $emailReplyToAddress}
  ) {
    organization {
      id
      emailReplyToAddress
    }
  }
}
    `;
export type OrgUpdateClientEmailSettingsMutationFn = Apollo.MutationFunction<OrgUpdateClientEmailSettingsMutation, OrgUpdateClientEmailSettingsMutationVariables>;

/**
 * __useOrgUpdateClientEmailSettingsMutation__
 *
 * To run a mutation, you first call `useOrgUpdateClientEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateClientEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateClientEmailSettingsMutation, { data, loading, error }] = useOrgUpdateClientEmailSettingsMutation({
 *   variables: {
 *      emailSenderName: // value for 'emailSenderName'
 *      emailReplyToAddress: // value for 'emailReplyToAddress'
 *   },
 * });
 */
export function useOrgUpdateClientEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateClientEmailSettingsMutation, OrgUpdateClientEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateClientEmailSettingsMutation, OrgUpdateClientEmailSettingsMutationVariables>(OrgUpdateClientEmailSettingsDocument, options);
      }
export type OrgUpdateClientEmailSettingsMutationHookResult = ReturnType<typeof useOrgUpdateClientEmailSettingsMutation>;
export type OrgUpdateClientEmailSettingsMutationResult = Apollo.MutationResult<OrgUpdateClientEmailSettingsMutation>;
export type OrgUpdateClientEmailSettingsMutationOptions = Apollo.BaseMutationOptions<OrgUpdateClientEmailSettingsMutation, OrgUpdateClientEmailSettingsMutationVariables>;
export const OrgUpdateRegionalPreferencesDocument = gql`
    mutation OrgUpdateRegionalPreferences($timeZone: String!, $localeId: ID!, $currencyId: ID!, $unitOfMeasurement: UnitOfMeasurement!) {
  updateOrganizationTimeZone(input: {timeZone: $timeZone}) {
    organization {
      id
      timeZone
    }
  }
  updateOrganizationLocale(input: {localeId: $localeId}) {
    organization {
      id
      locale {
        name
        tag
      }
    }
  }
  updateOrganizationCurrency(input: {currencyId: $currencyId}) {
    organization {
      id
      currency {
        code
        decimalDigits
        id
        name
        symbol
      }
    }
  }
  updateOrganizationUnitOfMeasurement(
    input: {unitOfMeasurement: $unitOfMeasurement}
  ) {
    organization {
      id
    }
  }
}
    `;
export type OrgUpdateRegionalPreferencesMutationFn = Apollo.MutationFunction<OrgUpdateRegionalPreferencesMutation, OrgUpdateRegionalPreferencesMutationVariables>;

/**
 * __useOrgUpdateRegionalPreferencesMutation__
 *
 * To run a mutation, you first call `useOrgUpdateRegionalPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateRegionalPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateRegionalPreferencesMutation, { data, loading, error }] = useOrgUpdateRegionalPreferencesMutation({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *      localeId: // value for 'localeId'
 *      currencyId: // value for 'currencyId'
 *      unitOfMeasurement: // value for 'unitOfMeasurement'
 *   },
 * });
 */
export function useOrgUpdateRegionalPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateRegionalPreferencesMutation, OrgUpdateRegionalPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateRegionalPreferencesMutation, OrgUpdateRegionalPreferencesMutationVariables>(OrgUpdateRegionalPreferencesDocument, options);
      }
export type OrgUpdateRegionalPreferencesMutationHookResult = ReturnType<typeof useOrgUpdateRegionalPreferencesMutation>;
export type OrgUpdateRegionalPreferencesMutationResult = Apollo.MutationResult<OrgUpdateRegionalPreferencesMutation>;
export type OrgUpdateRegionalPreferencesMutationOptions = Apollo.BaseMutationOptions<OrgUpdateRegionalPreferencesMutation, OrgUpdateRegionalPreferencesMutationVariables>;
export const OrgUpdateOverheadMutiplierDocument = gql`
    mutation OrgUpdateOverheadMutiplier($overheadMultiplier: Float) {
  updateOverheadMultiplier(input: {overheadMultiplier: $overheadMultiplier}) {
    organization {
      id
      overheadMultiplier
    }
  }
}
    `;
export type OrgUpdateOverheadMutiplierMutationFn = Apollo.MutationFunction<OrgUpdateOverheadMutiplierMutation, OrgUpdateOverheadMutiplierMutationVariables>;

/**
 * __useOrgUpdateOverheadMutiplierMutation__
 *
 * To run a mutation, you first call `useOrgUpdateOverheadMutiplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgUpdateOverheadMutiplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgUpdateOverheadMutiplierMutation, { data, loading, error }] = useOrgUpdateOverheadMutiplierMutation({
 *   variables: {
 *      overheadMultiplier: // value for 'overheadMultiplier'
 *   },
 * });
 */
export function useOrgUpdateOverheadMutiplierMutation(baseOptions?: Apollo.MutationHookOptions<OrgUpdateOverheadMutiplierMutation, OrgUpdateOverheadMutiplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgUpdateOverheadMutiplierMutation, OrgUpdateOverheadMutiplierMutationVariables>(OrgUpdateOverheadMutiplierDocument, options);
      }
export type OrgUpdateOverheadMutiplierMutationHookResult = ReturnType<typeof useOrgUpdateOverheadMutiplierMutation>;
export type OrgUpdateOverheadMutiplierMutationResult = Apollo.MutationResult<OrgUpdateOverheadMutiplierMutation>;
export type OrgUpdateOverheadMutiplierMutationOptions = Apollo.BaseMutationOptions<OrgUpdateOverheadMutiplierMutation, OrgUpdateOverheadMutiplierMutationVariables>;
export const UpdateOrganizationDefaultUtilizationInfoDocument = gql`
    mutation UpdateOrganizationDefaultUtilizationInfo($input: UpdateDefaultUtilizationInfoInput!) {
  updateOrganizationDefaultUtilizationInfo(input: $input) {
    organization {
      id
      settings {
        id
        defaultUtilizationRate
        defaultHoursPerWeek
        applyUtilizationRateToExistingProfiles
      }
    }
  }
}
    `;
export type UpdateOrganizationDefaultUtilizationInfoMutationFn = Apollo.MutationFunction<UpdateOrganizationDefaultUtilizationInfoMutation, UpdateOrganizationDefaultUtilizationInfoMutationVariables>;

/**
 * __useUpdateOrganizationDefaultUtilizationInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDefaultUtilizationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDefaultUtilizationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDefaultUtilizationInfoMutation, { data, loading, error }] = useUpdateOrganizationDefaultUtilizationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationDefaultUtilizationInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationDefaultUtilizationInfoMutation, UpdateOrganizationDefaultUtilizationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationDefaultUtilizationInfoMutation, UpdateOrganizationDefaultUtilizationInfoMutationVariables>(UpdateOrganizationDefaultUtilizationInfoDocument, options);
      }
export type UpdateOrganizationDefaultUtilizationInfoMutationHookResult = ReturnType<typeof useUpdateOrganizationDefaultUtilizationInfoMutation>;
export type UpdateOrganizationDefaultUtilizationInfoMutationResult = Apollo.MutationResult<UpdateOrganizationDefaultUtilizationInfoMutation>;
export type UpdateOrganizationDefaultUtilizationInfoMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationDefaultUtilizationInfoMutation, UpdateOrganizationDefaultUtilizationInfoMutationVariables>;
export const UpdateYearlyWorkingHoursDocument = gql`
    mutation UpdateYearlyWorkingHours($yearlyWorkingHours: Float!) {
  updateYearlyWorkingHours(input: {yearlyWorkingHours: $yearlyWorkingHours}) {
    organization {
      id
      settings {
        id
        yearlyWorkingHours
      }
    }
  }
}
    `;
export type UpdateYearlyWorkingHoursMutationFn = Apollo.MutationFunction<UpdateYearlyWorkingHoursMutation, UpdateYearlyWorkingHoursMutationVariables>;

/**
 * __useUpdateYearlyWorkingHoursMutation__
 *
 * To run a mutation, you first call `useUpdateYearlyWorkingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYearlyWorkingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYearlyWorkingHoursMutation, { data, loading, error }] = useUpdateYearlyWorkingHoursMutation({
 *   variables: {
 *      yearlyWorkingHours: // value for 'yearlyWorkingHours'
 *   },
 * });
 */
export function useUpdateYearlyWorkingHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateYearlyWorkingHoursMutation, UpdateYearlyWorkingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateYearlyWorkingHoursMutation, UpdateYearlyWorkingHoursMutationVariables>(UpdateYearlyWorkingHoursDocument, options);
      }
export type UpdateYearlyWorkingHoursMutationHookResult = ReturnType<typeof useUpdateYearlyWorkingHoursMutation>;
export type UpdateYearlyWorkingHoursMutationResult = Apollo.MutationResult<UpdateYearlyWorkingHoursMutation>;
export type UpdateYearlyWorkingHoursMutationOptions = Apollo.BaseMutationOptions<UpdateYearlyWorkingHoursMutation, UpdateYearlyWorkingHoursMutationVariables>;
export const UpdateOrganizationRealizationTargetDocument = gql`
    mutation UpdateOrganizationRealizationTarget($input: UpdateOrganizationRealizationInput!) {
  updateOrganizationRealization(input: $input) {
    organization {
      id
      settings {
        id
        defaultRealizationRate
      }
    }
  }
}
    `;
export type UpdateOrganizationRealizationTargetMutationFn = Apollo.MutationFunction<UpdateOrganizationRealizationTargetMutation, UpdateOrganizationRealizationTargetMutationVariables>;

/**
 * __useUpdateOrganizationRealizationTargetMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationRealizationTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationRealizationTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationRealizationTargetMutation, { data, loading, error }] = useUpdateOrganizationRealizationTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationRealizationTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationRealizationTargetMutation, UpdateOrganizationRealizationTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationRealizationTargetMutation, UpdateOrganizationRealizationTargetMutationVariables>(UpdateOrganizationRealizationTargetDocument, options);
      }
export type UpdateOrganizationRealizationTargetMutationHookResult = ReturnType<typeof useUpdateOrganizationRealizationTargetMutation>;
export type UpdateOrganizationRealizationTargetMutationResult = Apollo.MutationResult<UpdateOrganizationRealizationTargetMutation>;
export type UpdateOrganizationRealizationTargetMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationRealizationTargetMutation, UpdateOrganizationRealizationTargetMutationVariables>;
export const UpdateTimeOffDocument = gql`
    mutation UpdateTimeOff($timeOff: Boolean!) {
  updateTimeOff(input: {timeOff: $timeOff}) {
    organization {
      id
      settings {
        id
        timeOffToggledAt
        ptoEnabled
      }
    }
  }
}
    `;
export type UpdateTimeOffMutationFn = Apollo.MutationFunction<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;

/**
 * __useUpdateTimeOffMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffMutation, { data, loading, error }] = useUpdateTimeOffMutation({
 *   variables: {
 *      timeOff: // value for 'timeOff'
 *   },
 * });
 */
export function useUpdateTimeOffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>(UpdateTimeOffDocument, options);
      }
export type UpdateTimeOffMutationHookResult = ReturnType<typeof useUpdateTimeOffMutation>;
export type UpdateTimeOffMutationResult = Apollo.MutationResult<UpdateTimeOffMutation>;
export type UpdateTimeOffMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;
export const QuickbooksSettingsDocument = gql`
    query QuickbooksSettings {
  currentProfile {
    id
    organization {
      id
      connectedToQbo
      connectedQboAccountsCount
      qboInstances {
        ...QuickbooksSettingsQboInstance
        ...QuickbooksSettingsClientsQboInstance
        ...QuickbooksSettingsConsultantContactsQboInstance
        ...QuickbooksSettingsTaxesQboInstance
        ...QuickbooksSettingsExpenseTypesQboInstance
        ...QuickbooksSettingsExpenseCategoriesAccountsQboInstance
        qboTerms {
          ...QuickbooksSettingsQBOTerm
        }
      }
      expenseCategories {
        ...QuickbooksSettingsExpenseCategoriesAccountsExpenseCategory
        ...QuickbooksSettingsExpenseCategoriesServicesExpenseCategory
      }
      phaseTemplates {
        ...QuickbooksSettingsPhaseTypesPhaseTemplate
      }
      consultantTemplates {
        ...QuickbooksSettingsConsultantTypesConsultantTemplate
      }
      settings {
        id
        multiQboInstanceEnabled
        stripePayoutAccount {
          ...QuickbooksSettingsQboAccount
        }
        onlinePaymentFeeAccount {
          ...QuickbooksSettingsQboAccount
        }
        paymentDepositAccount {
          ...QuickbooksSettingsQboAccount
        }
        ...QuickbooksSettingsOrganizationConsultantMarkupSetting
        ...QuickbooksSettingsExpenseTypesSetting
        ...QuickbooksSettingsOrganizationCostsSetting
      }
      retainers {
        ...QuickbooksSettingsRetainer
      }
      quickbooksItems {
        ...QuickbooksSettingsQuickbooksItem
      }
    }
  }
}
    ${QuickbooksSettingsQboInstanceFragmentDoc}
${QuickbooksSettingsClientsQboInstanceFragmentDoc}
${QuickbooksSettingsConsultantContactsQboInstanceFragmentDoc}
${QuickbooksSettingsTaxesQboInstanceFragmentDoc}
${QuickbooksSettingsExpenseTypesQboInstanceFragmentDoc}
${QuickbooksSettingsExpenseCategoriesAccountsQboInstanceFragmentDoc}
${QuickbooksSettingsQboTermFragmentDoc}
${QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragmentDoc}
${QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragmentDoc}
${QuickbooksSettingsPhaseTypesPhaseTemplateFragmentDoc}
${QuickbooksSettingsConsultantTypesConsultantTemplateFragmentDoc}
${QuickbooksSettingsQboAccountFragmentDoc}
${QuickbooksSettingsOrganizationConsultantMarkupSettingFragmentDoc}
${QuickbooksSettingsExpenseTypesSettingFragmentDoc}
${QuickbooksSettingsOrganizationCostsSettingFragmentDoc}
${QuickbooksSettingsRetainerFragmentDoc}
${QuickbooksSettingsQuickbooksItemFragmentDoc}`;

/**
 * __useQuickbooksSettingsQuery__
 *
 * To run a query within a React component, call `useQuickbooksSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksSettingsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksSettingsQuery, QuickbooksSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksSettingsQuery, QuickbooksSettingsQueryVariables>(QuickbooksSettingsDocument, options);
      }
export function useQuickbooksSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksSettingsQuery, QuickbooksSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksSettingsQuery, QuickbooksSettingsQueryVariables>(QuickbooksSettingsDocument, options);
        }
export type QuickbooksSettingsQueryHookResult = ReturnType<typeof useQuickbooksSettingsQuery>;
export type QuickbooksSettingsLazyQueryHookResult = ReturnType<typeof useQuickbooksSettingsLazyQuery>;
export type QuickbooksSettingsQueryResult = Apollo.QueryResult<QuickbooksSettingsQuery, QuickbooksSettingsQueryVariables>;
export const QuickbooksSettingsDisconnectQboInstanceDocument = gql`
    mutation QuickbooksSettingsDisconnectQboInstance($qboInstanceId: ID) {
  disconnectOrganizationQuickbooks(input: {qboInstanceId: $qboInstanceId}) {
    organization {
      id
      connectedToQbo
      qboInstances {
        id
        connected
      }
    }
  }
}
    `;
export type QuickbooksSettingsDisconnectQboInstanceMutationFn = Apollo.MutationFunction<QuickbooksSettingsDisconnectQboInstanceMutation, QuickbooksSettingsDisconnectQboInstanceMutationVariables>;

/**
 * __useQuickbooksSettingsDisconnectQboInstanceMutation__
 *
 * To run a mutation, you first call `useQuickbooksSettingsDisconnectQboInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksSettingsDisconnectQboInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickbooksSettingsDisconnectQboInstanceMutation, { data, loading, error }] = useQuickbooksSettingsDisconnectQboInstanceMutation({
 *   variables: {
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useQuickbooksSettingsDisconnectQboInstanceMutation(baseOptions?: Apollo.MutationHookOptions<QuickbooksSettingsDisconnectQboInstanceMutation, QuickbooksSettingsDisconnectQboInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuickbooksSettingsDisconnectQboInstanceMutation, QuickbooksSettingsDisconnectQboInstanceMutationVariables>(QuickbooksSettingsDisconnectQboInstanceDocument, options);
      }
export type QuickbooksSettingsDisconnectQboInstanceMutationHookResult = ReturnType<typeof useQuickbooksSettingsDisconnectQboInstanceMutation>;
export type QuickbooksSettingsDisconnectQboInstanceMutationResult = Apollo.MutationResult<QuickbooksSettingsDisconnectQboInstanceMutation>;
export type QuickbooksSettingsDisconnectQboInstanceMutationOptions = Apollo.BaseMutationOptions<QuickbooksSettingsDisconnectQboInstanceMutation, QuickbooksSettingsDisconnectQboInstanceMutationVariables>;
export const UpdateOrganizationSettingConsultantMarkupQuickbooksMappingDocument = gql`
    mutation UpdateOrganizationSettingConsultantMarkupQuickbooksMapping($applyConsultantMapping: Boolean!, $quickbooksItemId: ID) {
  updateOrganizationConsultantMarkupQuickbooksMapping(
    input: {applyConsultantMapping: $applyConsultantMapping, quickbooksItemId: $quickbooksItemId}
  ) {
    organization {
      id
      settings {
        ...QuickbooksSettingsOrganizationConsultantMarkupSetting
      }
    }
  }
}
    ${QuickbooksSettingsOrganizationConsultantMarkupSettingFragmentDoc}`;
export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationFn = Apollo.MutationFunction<UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation, UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationVariables>;

/**
 * __useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingConsultantMarkupQuickbooksMappingMutation, { data, loading, error }] = useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation({
 *   variables: {
 *      applyConsultantMapping: // value for 'applyConsultantMapping'
 *      quickbooksItemId: // value for 'quickbooksItemId'
 *   },
 * });
 */
export function useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation, UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation, UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationVariables>(UpdateOrganizationSettingConsultantMarkupQuickbooksMappingDocument, options);
      }
export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationHookResult = ReturnType<typeof useUpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation>;
export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationResult = Apollo.MutationResult<UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation>;
export type UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutation, UpdateOrganizationSettingConsultantMarkupQuickbooksMappingMutationVariables>;
export const UpdateConsultantTemplateQuickbooksMappingDocument = gql`
    mutation UpdateConsultantTemplateQuickbooksMapping($id: ID!, $quickbooksItemId: ID) {
  updateConsultantTemplateQuickbooksMapping(
    input: {id: $id, quickbooksItemId: $quickbooksItemId}
  ) {
    consultantTemplate {
      ...QuickbooksSettingsConsultantTypesConsultantTemplate
    }
  }
}
    ${QuickbooksSettingsConsultantTypesConsultantTemplateFragmentDoc}`;
export type UpdateConsultantTemplateQuickbooksMappingMutationFn = Apollo.MutationFunction<UpdateConsultantTemplateQuickbooksMappingMutation, UpdateConsultantTemplateQuickbooksMappingMutationVariables>;

/**
 * __useUpdateConsultantTemplateQuickbooksMappingMutation__
 *
 * To run a mutation, you first call `useUpdateConsultantTemplateQuickbooksMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultantTemplateQuickbooksMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultantTemplateQuickbooksMappingMutation, { data, loading, error }] = useUpdateConsultantTemplateQuickbooksMappingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quickbooksItemId: // value for 'quickbooksItemId'
 *   },
 * });
 */
export function useUpdateConsultantTemplateQuickbooksMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsultantTemplateQuickbooksMappingMutation, UpdateConsultantTemplateQuickbooksMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConsultantTemplateQuickbooksMappingMutation, UpdateConsultantTemplateQuickbooksMappingMutationVariables>(UpdateConsultantTemplateQuickbooksMappingDocument, options);
      }
export type UpdateConsultantTemplateQuickbooksMappingMutationHookResult = ReturnType<typeof useUpdateConsultantTemplateQuickbooksMappingMutation>;
export type UpdateConsultantTemplateQuickbooksMappingMutationResult = Apollo.MutationResult<UpdateConsultantTemplateQuickbooksMappingMutation>;
export type UpdateConsultantTemplateQuickbooksMappingMutationOptions = Apollo.BaseMutationOptions<UpdateConsultantTemplateQuickbooksMappingMutation, UpdateConsultantTemplateQuickbooksMappingMutationVariables>;
export const ImportQboCostsDocument = gql`
    mutation ImportQboCosts($input: ImportQBOCostsInput!) {
  importQboCosts(input: $input) {
    organization {
      expenseCategories {
        id
        qboExpenseAccount {
          id
        }
      }
    }
  }
}
    `;
export type ImportQboCostsMutationFn = Apollo.MutationFunction<ImportQboCostsMutation, ImportQboCostsMutationVariables>;

/**
 * __useImportQboCostsMutation__
 *
 * To run a mutation, you first call `useImportQboCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportQboCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importQboCostsMutation, { data, loading, error }] = useImportQboCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportQboCostsMutation(baseOptions?: Apollo.MutationHookOptions<ImportQboCostsMutation, ImportQboCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportQboCostsMutation, ImportQboCostsMutationVariables>(ImportQboCostsDocument, options);
      }
export type ImportQboCostsMutationHookResult = ReturnType<typeof useImportQboCostsMutation>;
export type ImportQboCostsMutationResult = Apollo.MutationResult<ImportQboCostsMutation>;
export type ImportQboCostsMutationOptions = Apollo.BaseMutationOptions<ImportQboCostsMutation, ImportQboCostsMutationVariables>;
export const QuickbooksCostsDocument = gql`
    query QuickbooksCosts($filters: CostsFiltersInput, $sortBy: CostsSortInput, $after: String, $before: String, $first: Int, $last: Int) {
  quickbooksCosts(
    filters: $filters
    sortBy: $sortBy
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    nodes {
      ...QBOCost
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    ${QboCostFragmentDoc}`;

/**
 * __useQuickbooksCostsQuery__
 *
 * To run a query within a React component, call `useQuickbooksCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksCostsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useQuickbooksCostsQuery(baseOptions?: Apollo.QueryHookOptions<QuickbooksCostsQuery, QuickbooksCostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickbooksCostsQuery, QuickbooksCostsQueryVariables>(QuickbooksCostsDocument, options);
      }
export function useQuickbooksCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickbooksCostsQuery, QuickbooksCostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickbooksCostsQuery, QuickbooksCostsQueryVariables>(QuickbooksCostsDocument, options);
        }
export type QuickbooksCostsQueryHookResult = ReturnType<typeof useQuickbooksCostsQuery>;
export type QuickbooksCostsLazyQueryHookResult = ReturnType<typeof useQuickbooksCostsLazyQuery>;
export type QuickbooksCostsQueryResult = Apollo.QueryResult<QuickbooksCostsQuery, QuickbooksCostsQueryVariables>;
export const UpdateCostsDocument = gql`
    mutation UpdateCosts($input: UpdateCostsInput!) {
  updateCosts(input: $input) {
    qboJournalEntryCosts {
      id
      project {
        id
      }
      costCategory
    }
  }
}
    `;
export type UpdateCostsMutationFn = Apollo.MutationFunction<UpdateCostsMutation, UpdateCostsMutationVariables>;

/**
 * __useUpdateCostsMutation__
 *
 * To run a mutation, you first call `useUpdateCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostsMutation, { data, loading, error }] = useUpdateCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCostsMutation, UpdateCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCostsMutation, UpdateCostsMutationVariables>(UpdateCostsDocument, options);
      }
export type UpdateCostsMutationHookResult = ReturnType<typeof useUpdateCostsMutation>;
export type UpdateCostsMutationResult = Apollo.MutationResult<UpdateCostsMutation>;
export type UpdateCostsMutationOptions = Apollo.BaseMutationOptions<UpdateCostsMutation, UpdateCostsMutationVariables>;
export const DeleteCostsDocument = gql`
    mutation DeleteCosts($input: DeleteCostsInput!) {
  deleteCosts(input: $input) {
    organization {
      id
    }
  }
}
    `;
export type DeleteCostsMutationFn = Apollo.MutationFunction<DeleteCostsMutation, DeleteCostsMutationVariables>;

/**
 * __useDeleteCostsMutation__
 *
 * To run a mutation, you first call `useDeleteCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostsMutation, { data, loading, error }] = useDeleteCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCostsMutation, DeleteCostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCostsMutation, DeleteCostsMutationVariables>(DeleteCostsDocument, options);
      }
export type DeleteCostsMutationHookResult = ReturnType<typeof useDeleteCostsMutation>;
export type DeleteCostsMutationResult = Apollo.MutationResult<DeleteCostsMutation>;
export type DeleteCostsMutationOptions = Apollo.BaseMutationOptions<DeleteCostsMutation, DeleteCostsMutationVariables>;
export const UpdateExpenseCategoryQuickbooksAccountDocument = gql`
    mutation UpdateExpenseCategoryQuickbooksAccount($id: ID!, $qboInstanceId: ID, $qboExpenseAccountId: ID) {
  updateExpenseCategoryQuickbooksAccount(
    input: {id: $id, qboInstanceId: $qboInstanceId, qboExpenseAccountId: $qboExpenseAccountId}
  ) {
    expenseCategory {
      ...QuickbooksSettingsExpenseCategoriesAccountsExpenseCategory
    }
  }
}
    ${QuickbooksSettingsExpenseCategoriesAccountsExpenseCategoryFragmentDoc}`;
export type UpdateExpenseCategoryQuickbooksAccountMutationFn = Apollo.MutationFunction<UpdateExpenseCategoryQuickbooksAccountMutation, UpdateExpenseCategoryQuickbooksAccountMutationVariables>;

/**
 * __useUpdateExpenseCategoryQuickbooksAccountMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseCategoryQuickbooksAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseCategoryQuickbooksAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseCategoryQuickbooksAccountMutation, { data, loading, error }] = useUpdateExpenseCategoryQuickbooksAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qboInstanceId: // value for 'qboInstanceId'
 *      qboExpenseAccountId: // value for 'qboExpenseAccountId'
 *   },
 * });
 */
export function useUpdateExpenseCategoryQuickbooksAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseCategoryQuickbooksAccountMutation, UpdateExpenseCategoryQuickbooksAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseCategoryQuickbooksAccountMutation, UpdateExpenseCategoryQuickbooksAccountMutationVariables>(UpdateExpenseCategoryQuickbooksAccountDocument, options);
      }
export type UpdateExpenseCategoryQuickbooksAccountMutationHookResult = ReturnType<typeof useUpdateExpenseCategoryQuickbooksAccountMutation>;
export type UpdateExpenseCategoryQuickbooksAccountMutationResult = Apollo.MutationResult<UpdateExpenseCategoryQuickbooksAccountMutation>;
export type UpdateExpenseCategoryQuickbooksAccountMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseCategoryQuickbooksAccountMutation, UpdateExpenseCategoryQuickbooksAccountMutationVariables>;
export const UpdateExpenseCategoryQuickbooksServiceDocument = gql`
    mutation UpdateExpenseCategoryQuickbooksService($id: ID!, $mapToPhaseQbItem: Boolean!, $qboInstanceId: ID!, $qboItemId: ID) {
  updateExpenseCategoryQuickbooksService(
    input: {id: $id, mapToPhaseQbItem: $mapToPhaseQbItem, qboInstanceId: $qboInstanceId, qboItemId: $qboItemId}
  ) {
    expenseCategory {
      ...QuickbooksSettingsExpenseCategoriesServicesExpenseCategory
    }
  }
}
    ${QuickbooksSettingsExpenseCategoriesServicesExpenseCategoryFragmentDoc}`;
export type UpdateExpenseCategoryQuickbooksServiceMutationFn = Apollo.MutationFunction<UpdateExpenseCategoryQuickbooksServiceMutation, UpdateExpenseCategoryQuickbooksServiceMutationVariables>;

/**
 * __useUpdateExpenseCategoryQuickbooksServiceMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseCategoryQuickbooksServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseCategoryQuickbooksServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseCategoryQuickbooksServiceMutation, { data, loading, error }] = useUpdateExpenseCategoryQuickbooksServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mapToPhaseQbItem: // value for 'mapToPhaseQbItem'
 *      qboInstanceId: // value for 'qboInstanceId'
 *      qboItemId: // value for 'qboItemId'
 *   },
 * });
 */
export function useUpdateExpenseCategoryQuickbooksServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseCategoryQuickbooksServiceMutation, UpdateExpenseCategoryQuickbooksServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseCategoryQuickbooksServiceMutation, UpdateExpenseCategoryQuickbooksServiceMutationVariables>(UpdateExpenseCategoryQuickbooksServiceDocument, options);
      }
export type UpdateExpenseCategoryQuickbooksServiceMutationHookResult = ReturnType<typeof useUpdateExpenseCategoryQuickbooksServiceMutation>;
export type UpdateExpenseCategoryQuickbooksServiceMutationResult = Apollo.MutationResult<UpdateExpenseCategoryQuickbooksServiceMutation>;
export type UpdateExpenseCategoryQuickbooksServiceMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseCategoryQuickbooksServiceMutation, UpdateExpenseCategoryQuickbooksServiceMutationVariables>;
export const UpdateReimbursableExpensesAccountDocument = gql`
    mutation UpdateReimbursableExpensesAccount($input: UpdateReimbursableExpensesAccountInput!) {
  updateOrganizationReimbursableExpensesAccount(input: $input) {
    organization {
      settings {
        id
        reimbursableExpensesAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export type UpdateReimbursableExpensesAccountMutationFn = Apollo.MutationFunction<UpdateReimbursableExpensesAccountMutation, UpdateReimbursableExpensesAccountMutationVariables>;

/**
 * __useUpdateReimbursableExpensesAccountMutation__
 *
 * To run a mutation, you first call `useUpdateReimbursableExpensesAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReimbursableExpensesAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReimbursableExpensesAccountMutation, { data, loading, error }] = useUpdateReimbursableExpensesAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReimbursableExpensesAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReimbursableExpensesAccountMutation, UpdateReimbursableExpensesAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReimbursableExpensesAccountMutation, UpdateReimbursableExpensesAccountMutationVariables>(UpdateReimbursableExpensesAccountDocument, options);
      }
export type UpdateReimbursableExpensesAccountMutationHookResult = ReturnType<typeof useUpdateReimbursableExpensesAccountMutation>;
export type UpdateReimbursableExpensesAccountMutationResult = Apollo.MutationResult<UpdateReimbursableExpensesAccountMutation>;
export type UpdateReimbursableExpensesAccountMutationOptions = Apollo.BaseMutationOptions<UpdateReimbursableExpensesAccountMutation, UpdateReimbursableExpensesAccountMutationVariables>;
export const UpdateNonReimbursableExpensesAccountDocument = gql`
    mutation UpdateNonReimbursableExpensesAccount($input: UpdateNonReimbursableExpensesAccountInput!) {
  updateOrganizationNonReimbursableExpensesAccount(input: $input) {
    organization {
      settings {
        id
        nonReimbursableExpensesAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export type UpdateNonReimbursableExpensesAccountMutationFn = Apollo.MutationFunction<UpdateNonReimbursableExpensesAccountMutation, UpdateNonReimbursableExpensesAccountMutationVariables>;

/**
 * __useUpdateNonReimbursableExpensesAccountMutation__
 *
 * To run a mutation, you first call `useUpdateNonReimbursableExpensesAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNonReimbursableExpensesAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNonReimbursableExpensesAccountMutation, { data, loading, error }] = useUpdateNonReimbursableExpensesAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNonReimbursableExpensesAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNonReimbursableExpensesAccountMutation, UpdateNonReimbursableExpensesAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNonReimbursableExpensesAccountMutation, UpdateNonReimbursableExpensesAccountMutationVariables>(UpdateNonReimbursableExpensesAccountDocument, options);
      }
export type UpdateNonReimbursableExpensesAccountMutationHookResult = ReturnType<typeof useUpdateNonReimbursableExpensesAccountMutation>;
export type UpdateNonReimbursableExpensesAccountMutationResult = Apollo.MutationResult<UpdateNonReimbursableExpensesAccountMutation>;
export type UpdateNonReimbursableExpensesAccountMutationOptions = Apollo.BaseMutationOptions<UpdateNonReimbursableExpensesAccountMutation, UpdateNonReimbursableExpensesAccountMutationVariables>;
export const SyncInvoiceTermsWithQuickbooksDocument = gql`
    mutation SyncInvoiceTermsWithQuickbooks($qboInstanceId: ID) {
  syncTermsWithQuickbooks(input: {qboInstanceId: $qboInstanceId}) {
    organization {
      id
    }
  }
}
    `;
export type SyncInvoiceTermsWithQuickbooksMutationFn = Apollo.MutationFunction<SyncInvoiceTermsWithQuickbooksMutation, SyncInvoiceTermsWithQuickbooksMutationVariables>;

/**
 * __useSyncInvoiceTermsWithQuickbooksMutation__
 *
 * To run a mutation, you first call `useSyncInvoiceTermsWithQuickbooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncInvoiceTermsWithQuickbooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncInvoiceTermsWithQuickbooksMutation, { data, loading, error }] = useSyncInvoiceTermsWithQuickbooksMutation({
 *   variables: {
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useSyncInvoiceTermsWithQuickbooksMutation(baseOptions?: Apollo.MutationHookOptions<SyncInvoiceTermsWithQuickbooksMutation, SyncInvoiceTermsWithQuickbooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncInvoiceTermsWithQuickbooksMutation, SyncInvoiceTermsWithQuickbooksMutationVariables>(SyncInvoiceTermsWithQuickbooksDocument, options);
      }
export type SyncInvoiceTermsWithQuickbooksMutationHookResult = ReturnType<typeof useSyncInvoiceTermsWithQuickbooksMutation>;
export type SyncInvoiceTermsWithQuickbooksMutationResult = Apollo.MutationResult<SyncInvoiceTermsWithQuickbooksMutation>;
export type SyncInvoiceTermsWithQuickbooksMutationOptions = Apollo.BaseMutationOptions<SyncInvoiceTermsWithQuickbooksMutation, SyncInvoiceTermsWithQuickbooksMutationVariables>;
export const MonographPaymentAccountsDocument = gql`
    query MonographPaymentAccounts {
  currentProfile {
    id
    organization {
      id
      settings {
        stripePayoutAccount {
          ...QuickbooksSettingsQboAccount
        }
        onlinePaymentFeeAccount {
          ...QuickbooksSettingsQboAccount
        }
        paymentDepositAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;

/**
 * __useMonographPaymentAccountsQuery__
 *
 * To run a query within a React component, call `useMonographPaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonographPaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonographPaymentAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonographPaymentAccountsQuery(baseOptions?: Apollo.QueryHookOptions<MonographPaymentAccountsQuery, MonographPaymentAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonographPaymentAccountsQuery, MonographPaymentAccountsQueryVariables>(MonographPaymentAccountsDocument, options);
      }
export function useMonographPaymentAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonographPaymentAccountsQuery, MonographPaymentAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonographPaymentAccountsQuery, MonographPaymentAccountsQueryVariables>(MonographPaymentAccountsDocument, options);
        }
export type MonographPaymentAccountsQueryHookResult = ReturnType<typeof useMonographPaymentAccountsQuery>;
export type MonographPaymentAccountsLazyQueryHookResult = ReturnType<typeof useMonographPaymentAccountsLazyQuery>;
export type MonographPaymentAccountsQueryResult = Apollo.QueryResult<MonographPaymentAccountsQuery, MonographPaymentAccountsQueryVariables>;
export const UpdateStripePayoutAccountDocument = gql`
    mutation UpdateStripePayoutAccount($input: UpdateStripePayoutAccountInput!) {
  updateOrganizationStripePayoutAccount(input: $input) {
    organization {
      settings {
        id
        stripePayoutAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export type UpdateStripePayoutAccountMutationFn = Apollo.MutationFunction<UpdateStripePayoutAccountMutation, UpdateStripePayoutAccountMutationVariables>;

/**
 * __useUpdateStripePayoutAccountMutation__
 *
 * To run a mutation, you first call `useUpdateStripePayoutAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripePayoutAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripePayoutAccountMutation, { data, loading, error }] = useUpdateStripePayoutAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripePayoutAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStripePayoutAccountMutation, UpdateStripePayoutAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStripePayoutAccountMutation, UpdateStripePayoutAccountMutationVariables>(UpdateStripePayoutAccountDocument, options);
      }
export type UpdateStripePayoutAccountMutationHookResult = ReturnType<typeof useUpdateStripePayoutAccountMutation>;
export type UpdateStripePayoutAccountMutationResult = Apollo.MutationResult<UpdateStripePayoutAccountMutation>;
export type UpdateStripePayoutAccountMutationOptions = Apollo.BaseMutationOptions<UpdateStripePayoutAccountMutation, UpdateStripePayoutAccountMutationVariables>;
export const UpdateOnlinePaymentFeeAccountDocument = gql`
    mutation UpdateOnlinePaymentFeeAccount($input: UpdateOnlinePaymentFeeAccountInput!) {
  updateOrganizationOnlinePaymentFeeAccount(input: $input) {
    organization {
      settings {
        id
        onlinePaymentFeeAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export type UpdateOnlinePaymentFeeAccountMutationFn = Apollo.MutationFunction<UpdateOnlinePaymentFeeAccountMutation, UpdateOnlinePaymentFeeAccountMutationVariables>;

/**
 * __useUpdateOnlinePaymentFeeAccountMutation__
 *
 * To run a mutation, you first call `useUpdateOnlinePaymentFeeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnlinePaymentFeeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnlinePaymentFeeAccountMutation, { data, loading, error }] = useUpdateOnlinePaymentFeeAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnlinePaymentFeeAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnlinePaymentFeeAccountMutation, UpdateOnlinePaymentFeeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnlinePaymentFeeAccountMutation, UpdateOnlinePaymentFeeAccountMutationVariables>(UpdateOnlinePaymentFeeAccountDocument, options);
      }
export type UpdateOnlinePaymentFeeAccountMutationHookResult = ReturnType<typeof useUpdateOnlinePaymentFeeAccountMutation>;
export type UpdateOnlinePaymentFeeAccountMutationResult = Apollo.MutationResult<UpdateOnlinePaymentFeeAccountMutation>;
export type UpdateOnlinePaymentFeeAccountMutationOptions = Apollo.BaseMutationOptions<UpdateOnlinePaymentFeeAccountMutation, UpdateOnlinePaymentFeeAccountMutationVariables>;
export const UpdatePaymentDepositAccountDocument = gql`
    mutation UpdatePaymentDepositAccount($input: UpdatePaymentDepositAccountInput!) {
  updateOrganizationPaymentDepositAccount(input: $input) {
    organization {
      settings {
        id
        paymentDepositAccount {
          ...QuickbooksSettingsQboAccount
        }
      }
    }
  }
}
    ${QuickbooksSettingsQboAccountFragmentDoc}`;
export type UpdatePaymentDepositAccountMutationFn = Apollo.MutationFunction<UpdatePaymentDepositAccountMutation, UpdatePaymentDepositAccountMutationVariables>;

/**
 * __useUpdatePaymentDepositAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentDepositAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentDepositAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentDepositAccountMutation, { data, loading, error }] = useUpdatePaymentDepositAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentDepositAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentDepositAccountMutation, UpdatePaymentDepositAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentDepositAccountMutation, UpdatePaymentDepositAccountMutationVariables>(UpdatePaymentDepositAccountDocument, options);
      }
export type UpdatePaymentDepositAccountMutationHookResult = ReturnType<typeof useUpdatePaymentDepositAccountMutation>;
export type UpdatePaymentDepositAccountMutationResult = Apollo.MutationResult<UpdatePaymentDepositAccountMutation>;
export type UpdatePaymentDepositAccountMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentDepositAccountMutation, UpdatePaymentDepositAccountMutationVariables>;
export const UpdatePhaseTemplateQuickbooksMappingDocument = gql`
    mutation UpdatePhaseTemplateQuickbooksMapping($id: ID!, $qboItemId: ID, $qboInstanceId: ID) {
  updatePhaseTemplateQuickbooksMapping(
    input: {id: $id, qboItemId: $qboItemId, qboInstanceId: $qboInstanceId}
  ) {
    phaseTemplate {
      id
      qboItems {
        ...QuickbooksSettingsQboItem
      }
    }
  }
}
    ${QuickbooksSettingsQboItemFragmentDoc}`;
export type UpdatePhaseTemplateQuickbooksMappingMutationFn = Apollo.MutationFunction<UpdatePhaseTemplateQuickbooksMappingMutation, UpdatePhaseTemplateQuickbooksMappingMutationVariables>;

/**
 * __useUpdatePhaseTemplateQuickbooksMappingMutation__
 *
 * To run a mutation, you first call `useUpdatePhaseTemplateQuickbooksMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhaseTemplateQuickbooksMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhaseTemplateQuickbooksMappingMutation, { data, loading, error }] = useUpdatePhaseTemplateQuickbooksMappingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qboItemId: // value for 'qboItemId'
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useUpdatePhaseTemplateQuickbooksMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhaseTemplateQuickbooksMappingMutation, UpdatePhaseTemplateQuickbooksMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhaseTemplateQuickbooksMappingMutation, UpdatePhaseTemplateQuickbooksMappingMutationVariables>(UpdatePhaseTemplateQuickbooksMappingDocument, options);
      }
export type UpdatePhaseTemplateQuickbooksMappingMutationHookResult = ReturnType<typeof useUpdatePhaseTemplateQuickbooksMappingMutation>;
export type UpdatePhaseTemplateQuickbooksMappingMutationResult = Apollo.MutationResult<UpdatePhaseTemplateQuickbooksMappingMutation>;
export type UpdatePhaseTemplateQuickbooksMappingMutationOptions = Apollo.BaseMutationOptions<UpdatePhaseTemplateQuickbooksMappingMutation, UpdatePhaseTemplateQuickbooksMappingMutationVariables>;
export const UpdateRetainerQuickbooksMappingDocument = gql`
    mutation UpdateRetainerQuickbooksMapping($qboItemId: ID, $qboInstanceId: ID) {
  updateOrganizationRetainerQuickbooksMapping(
    input: {qboItemId: $qboItemId, qboInstanceId: $qboInstanceId}
  ) {
    retainer {
      ...QuickbooksSettingsRetainer
    }
  }
}
    ${QuickbooksSettingsRetainerFragmentDoc}`;
export type UpdateRetainerQuickbooksMappingMutationFn = Apollo.MutationFunction<UpdateRetainerQuickbooksMappingMutation, UpdateRetainerQuickbooksMappingMutationVariables>;

/**
 * __useUpdateRetainerQuickbooksMappingMutation__
 *
 * To run a mutation, you first call `useUpdateRetainerQuickbooksMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetainerQuickbooksMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetainerQuickbooksMappingMutation, { data, loading, error }] = useUpdateRetainerQuickbooksMappingMutation({
 *   variables: {
 *      qboItemId: // value for 'qboItemId'
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useUpdateRetainerQuickbooksMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRetainerQuickbooksMappingMutation, UpdateRetainerQuickbooksMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRetainerQuickbooksMappingMutation, UpdateRetainerQuickbooksMappingMutationVariables>(UpdateRetainerQuickbooksMappingDocument, options);
      }
export type UpdateRetainerQuickbooksMappingMutationHookResult = ReturnType<typeof useUpdateRetainerQuickbooksMappingMutation>;
export type UpdateRetainerQuickbooksMappingMutationResult = Apollo.MutationResult<UpdateRetainerQuickbooksMappingMutation>;
export type UpdateRetainerQuickbooksMappingMutationOptions = Apollo.BaseMutationOptions<UpdateRetainerQuickbooksMappingMutation, UpdateRetainerQuickbooksMappingMutationVariables>;
export const UnassignedProjectCostsCountDocument = gql`
    query UnassignedProjectCostsCount {
  currentProfile {
    id
    organization {
      id
      qboInstances {
        id
        connected
        unassignedProjectCostsCount
      }
    }
  }
}
    `;

/**
 * __useUnassignedProjectCostsCountQuery__
 *
 * To run a query within a React component, call `useUnassignedProjectCostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedProjectCostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedProjectCostsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnassignedProjectCostsCountQuery(baseOptions?: Apollo.QueryHookOptions<UnassignedProjectCostsCountQuery, UnassignedProjectCostsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassignedProjectCostsCountQuery, UnassignedProjectCostsCountQueryVariables>(UnassignedProjectCostsCountDocument, options);
      }
export function useUnassignedProjectCostsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassignedProjectCostsCountQuery, UnassignedProjectCostsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassignedProjectCostsCountQuery, UnassignedProjectCostsCountQueryVariables>(UnassignedProjectCostsCountDocument, options);
        }
export type UnassignedProjectCostsCountQueryHookResult = ReturnType<typeof useUnassignedProjectCostsCountQuery>;
export type UnassignedProjectCostsCountLazyQueryHookResult = ReturnType<typeof useUnassignedProjectCostsCountLazyQuery>;
export type UnassignedProjectCostsCountQueryResult = Apollo.QueryResult<UnassignedProjectCostsCountQuery, UnassignedProjectCostsCountQueryVariables>;
export const SyncQboTaxRatesDocument = gql`
    mutation SyncQboTaxRates {
  syncQboTaxRates(input: {}) {
    taxRate {
      id
    }
  }
}
    `;
export type SyncQboTaxRatesMutationFn = Apollo.MutationFunction<SyncQboTaxRatesMutation, SyncQboTaxRatesMutationVariables>;

/**
 * __useSyncQboTaxRatesMutation__
 *
 * To run a mutation, you first call `useSyncQboTaxRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncQboTaxRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncQboTaxRatesMutation, { data, loading, error }] = useSyncQboTaxRatesMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncQboTaxRatesMutation(baseOptions?: Apollo.MutationHookOptions<SyncQboTaxRatesMutation, SyncQboTaxRatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncQboTaxRatesMutation, SyncQboTaxRatesMutationVariables>(SyncQboTaxRatesDocument, options);
      }
export type SyncQboTaxRatesMutationHookResult = ReturnType<typeof useSyncQboTaxRatesMutation>;
export type SyncQboTaxRatesMutationResult = Apollo.MutationResult<SyncQboTaxRatesMutation>;
export type SyncQboTaxRatesMutationOptions = Apollo.BaseMutationOptions<SyncQboTaxRatesMutation, SyncQboTaxRatesMutationVariables>;
export const RolesAndRatesDocument = gql`
    query RolesAndRates {
  currentProfile {
    id
    organization {
      id
      roleTemplates {
        id
        name
        archivedAt
        profiles {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useRolesAndRatesQuery__
 *
 * To run a query within a React component, call `useRolesAndRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesAndRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesAndRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesAndRatesQuery(baseOptions?: Apollo.QueryHookOptions<RolesAndRatesQuery, RolesAndRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesAndRatesQuery, RolesAndRatesQueryVariables>(RolesAndRatesDocument, options);
      }
export function useRolesAndRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesAndRatesQuery, RolesAndRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesAndRatesQuery, RolesAndRatesQueryVariables>(RolesAndRatesDocument, options);
        }
export type RolesAndRatesQueryHookResult = ReturnType<typeof useRolesAndRatesQuery>;
export type RolesAndRatesLazyQueryHookResult = ReturnType<typeof useRolesAndRatesLazyQuery>;
export type RolesAndRatesQueryResult = Apollo.QueryResult<RolesAndRatesQuery, RolesAndRatesQueryVariables>;
export const SettingsPoliciesDocument = gql`
    query SettingsPolicies {
  currentProfile {
    id
    organization {
      id
      policies {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSettingsPoliciesQuery__
 *
 * To run a query within a React component, call `useSettingsPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<SettingsPoliciesQuery, SettingsPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsPoliciesQuery, SettingsPoliciesQueryVariables>(SettingsPoliciesDocument, options);
      }
export function useSettingsPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsPoliciesQuery, SettingsPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsPoliciesQuery, SettingsPoliciesQueryVariables>(SettingsPoliciesDocument, options);
        }
export type SettingsPoliciesQueryHookResult = ReturnType<typeof useSettingsPoliciesQuery>;
export type SettingsPoliciesLazyQueryHookResult = ReturnType<typeof useSettingsPoliciesLazyQuery>;
export type SettingsPoliciesQueryResult = Apollo.QueryResult<SettingsPoliciesQuery, SettingsPoliciesQueryVariables>;
export const SettingsPermissionsDocument = gql`
    query SettingsPermissions {
  currentProfile {
    organization {
      isDemo
      connectedToQbo
      policies {
        ...SettingsPolicy
      }
    }
  }
}
    ${SettingsPolicyFragmentDoc}`;

/**
 * __useSettingsPermissionsQuery__
 *
 * To run a query within a React component, call `useSettingsPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsPermissionsQuery, SettingsPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsPermissionsQuery, SettingsPermissionsQueryVariables>(SettingsPermissionsDocument, options);
      }
export function useSettingsPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsPermissionsQuery, SettingsPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsPermissionsQuery, SettingsPermissionsQueryVariables>(SettingsPermissionsDocument, options);
        }
export type SettingsPermissionsQueryHookResult = ReturnType<typeof useSettingsPermissionsQuery>;
export type SettingsPermissionsLazyQueryHookResult = ReturnType<typeof useSettingsPermissionsLazyQuery>;
export type SettingsPermissionsQueryResult = Apollo.QueryResult<SettingsPermissionsQuery, SettingsPermissionsQueryVariables>;
export const OrganizationUpdatePolicyDocument = gql`
    mutation OrganizationUpdatePolicy($policyId: ID!, $value: Boolean!, $permissionsKey: PermissionsKey!) {
  updatePermission(input: {id: $policyId, value: $value, key: $permissionsKey}) {
    policy {
      ...SettingsPolicy
    }
  }
}
    ${SettingsPolicyFragmentDoc}`;
export type OrganizationUpdatePolicyMutationFn = Apollo.MutationFunction<OrganizationUpdatePolicyMutation, OrganizationUpdatePolicyMutationVariables>;

/**
 * __useOrganizationUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdatePolicyMutation, { data, loading, error }] = useOrganizationUpdatePolicyMutation({
 *   variables: {
 *      policyId: // value for 'policyId'
 *      value: // value for 'value'
 *      permissionsKey: // value for 'permissionsKey'
 *   },
 * });
 */
export function useOrganizationUpdatePolicyMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationUpdatePolicyMutation, OrganizationUpdatePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationUpdatePolicyMutation, OrganizationUpdatePolicyMutationVariables>(OrganizationUpdatePolicyDocument, options);
      }
export type OrganizationUpdatePolicyMutationHookResult = ReturnType<typeof useOrganizationUpdatePolicyMutation>;
export type OrganizationUpdatePolicyMutationResult = Apollo.MutationResult<OrganizationUpdatePolicyMutation>;
export type OrganizationUpdatePolicyMutationOptions = Apollo.BaseMutationOptions<OrganizationUpdatePolicyMutation, OrganizationUpdatePolicyMutationVariables>;
export const OrganizationDuplicatePolicyDocument = gql`
    mutation OrganizationDuplicatePolicy($id: ID!, $name: String!) {
  duplicatePolicy(input: {id: $id, name: $name}) {
    policy {
      ...SettingsPolicy
    }
  }
}
    ${SettingsPolicyFragmentDoc}`;
export type OrganizationDuplicatePolicyMutationFn = Apollo.MutationFunction<OrganizationDuplicatePolicyMutation, OrganizationDuplicatePolicyMutationVariables>;

/**
 * __useOrganizationDuplicatePolicyMutation__
 *
 * To run a mutation, you first call `useOrganizationDuplicatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDuplicatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationDuplicatePolicyMutation, { data, loading, error }] = useOrganizationDuplicatePolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationDuplicatePolicyMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationDuplicatePolicyMutation, OrganizationDuplicatePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationDuplicatePolicyMutation, OrganizationDuplicatePolicyMutationVariables>(OrganizationDuplicatePolicyDocument, options);
      }
export type OrganizationDuplicatePolicyMutationHookResult = ReturnType<typeof useOrganizationDuplicatePolicyMutation>;
export type OrganizationDuplicatePolicyMutationResult = Apollo.MutationResult<OrganizationDuplicatePolicyMutation>;
export type OrganizationDuplicatePolicyMutationOptions = Apollo.BaseMutationOptions<OrganizationDuplicatePolicyMutation, OrganizationDuplicatePolicyMutationVariables>;
export const OrganizationCreatePolicyDocument = gql`
    mutation OrganizationCreatePolicy($name: String!) {
  createPolicy(input: {name: $name}) {
    policy {
      ...SettingsPolicy
    }
  }
}
    ${SettingsPolicyFragmentDoc}`;
export type OrganizationCreatePolicyMutationFn = Apollo.MutationFunction<OrganizationCreatePolicyMutation, OrganizationCreatePolicyMutationVariables>;

/**
 * __useOrganizationCreatePolicyMutation__
 *
 * To run a mutation, you first call `useOrganizationCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreatePolicyMutation, { data, loading, error }] = useOrganizationCreatePolicyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationCreatePolicyMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationCreatePolicyMutation, OrganizationCreatePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationCreatePolicyMutation, OrganizationCreatePolicyMutationVariables>(OrganizationCreatePolicyDocument, options);
      }
export type OrganizationCreatePolicyMutationHookResult = ReturnType<typeof useOrganizationCreatePolicyMutation>;
export type OrganizationCreatePolicyMutationResult = Apollo.MutationResult<OrganizationCreatePolicyMutation>;
export type OrganizationCreatePolicyMutationOptions = Apollo.BaseMutationOptions<OrganizationCreatePolicyMutation, OrganizationCreatePolicyMutationVariables>;
export const OrganizationDeletePolicyDocument = gql`
    mutation OrganizationDeletePolicy($id: ID!) {
  deletePolicy(input: {id: $id}) {
    policy {
      ...SettingsPolicy
    }
  }
}
    ${SettingsPolicyFragmentDoc}`;
export type OrganizationDeletePolicyMutationFn = Apollo.MutationFunction<OrganizationDeletePolicyMutation, OrganizationDeletePolicyMutationVariables>;

/**
 * __useOrganizationDeletePolicyMutation__
 *
 * To run a mutation, you first call `useOrganizationDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationDeletePolicyMutation, { data, loading, error }] = useOrganizationDeletePolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDeletePolicyMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationDeletePolicyMutation, OrganizationDeletePolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationDeletePolicyMutation, OrganizationDeletePolicyMutationVariables>(OrganizationDeletePolicyDocument, options);
      }
export type OrganizationDeletePolicyMutationHookResult = ReturnType<typeof useOrganizationDeletePolicyMutation>;
export type OrganizationDeletePolicyMutationResult = Apollo.MutationResult<OrganizationDeletePolicyMutation>;
export type OrganizationDeletePolicyMutationOptions = Apollo.BaseMutationOptions<OrganizationDeletePolicyMutation, OrganizationDeletePolicyMutationVariables>;
export const UpdatePolicyNameDocument = gql`
    mutation UpdatePolicyName($id: ID!, $name: String!) {
  updatePolicy(input: {id: $id, name: $name}) {
    policy {
      id
      isAdmin
      name
      slug
    }
  }
}
    `;
export type UpdatePolicyNameMutationFn = Apollo.MutationFunction<UpdatePolicyNameMutation, UpdatePolicyNameMutationVariables>;

/**
 * __useUpdatePolicyNameMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyNameMutation, { data, loading, error }] = useUpdatePolicyNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePolicyNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePolicyNameMutation, UpdatePolicyNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePolicyNameMutation, UpdatePolicyNameMutationVariables>(UpdatePolicyNameDocument, options);
      }
export type UpdatePolicyNameMutationHookResult = ReturnType<typeof useUpdatePolicyNameMutation>;
export type UpdatePolicyNameMutationResult = Apollo.MutationResult<UpdatePolicyNameMutation>;
export type UpdatePolicyNameMutationOptions = Apollo.BaseMutationOptions<UpdatePolicyNameMutation, UpdatePolicyNameMutationVariables>;
export const LogSignUpInformationDocument = gql`
    mutation LogSignUpInformation($email: String!, $organizationName: String, $firstName: String, $lastName: String, $phoneNumber: String, $organizationSize: String, $organizationLocation: String, $industry: String, $howDidYouHearAboutUs: String) {
  logSignUpInformation(
    input: {email: $email, organizationName: $organizationName, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, organizationSize: $organizationSize, organizationLocation: $organizationLocation, industry: $industry, howDidYouHearAboutUs: $howDidYouHearAboutUs}
  ) {
    icp
  }
}
    `;
export type LogSignUpInformationMutationFn = Apollo.MutationFunction<LogSignUpInformationMutation, LogSignUpInformationMutationVariables>;

/**
 * __useLogSignUpInformationMutation__
 *
 * To run a mutation, you first call `useLogSignUpInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogSignUpInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logSignUpInformationMutation, { data, loading, error }] = useLogSignUpInformationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organizationName: // value for 'organizationName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      organizationSize: // value for 'organizationSize'
 *      organizationLocation: // value for 'organizationLocation'
 *      industry: // value for 'industry'
 *      howDidYouHearAboutUs: // value for 'howDidYouHearAboutUs'
 *   },
 * });
 */
export function useLogSignUpInformationMutation(baseOptions?: Apollo.MutationHookOptions<LogSignUpInformationMutation, LogSignUpInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogSignUpInformationMutation, LogSignUpInformationMutationVariables>(LogSignUpInformationDocument, options);
      }
export type LogSignUpInformationMutationHookResult = ReturnType<typeof useLogSignUpInformationMutation>;
export type LogSignUpInformationMutationResult = Apollo.MutationResult<LogSignUpInformationMutation>;
export type LogSignUpInformationMutationOptions = Apollo.BaseMutationOptions<LogSignUpInformationMutation, LogSignUpInformationMutationVariables>;
export const CheckForExistingUserDocument = gql`
    mutation CheckForExistingUser($input: CheckForExistingUserInput!) {
  checkForExistingUser(input: $input) {
    userExists
  }
}
    `;
export type CheckForExistingUserMutationFn = Apollo.MutationFunction<CheckForExistingUserMutation, CheckForExistingUserMutationVariables>;

/**
 * __useCheckForExistingUserMutation__
 *
 * To run a mutation, you first call `useCheckForExistingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckForExistingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkForExistingUserMutation, { data, loading, error }] = useCheckForExistingUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckForExistingUserMutation(baseOptions?: Apollo.MutationHookOptions<CheckForExistingUserMutation, CheckForExistingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckForExistingUserMutation, CheckForExistingUserMutationVariables>(CheckForExistingUserDocument, options);
      }
export type CheckForExistingUserMutationHookResult = ReturnType<typeof useCheckForExistingUserMutation>;
export type CheckForExistingUserMutationResult = Apollo.MutationResult<CheckForExistingUserMutation>;
export type CheckForExistingUserMutationOptions = Apollo.BaseMutationOptions<CheckForExistingUserMutation, CheckForExistingUserMutationVariables>;
export const OnboardingIndustriesDocument = gql`
    query OnboardingIndustries {
  __type(name: "OnboardingIndustry") {
    enumValues {
      name
      description
    }
  }
}
    `;

/**
 * __useOnboardingIndustriesQuery__
 *
 * To run a query within a React component, call `useOnboardingIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingIndustriesQuery, OnboardingIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingIndustriesQuery, OnboardingIndustriesQueryVariables>(OnboardingIndustriesDocument, options);
      }
export function useOnboardingIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingIndustriesQuery, OnboardingIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingIndustriesQuery, OnboardingIndustriesQueryVariables>(OnboardingIndustriesDocument, options);
        }
export type OnboardingIndustriesQueryHookResult = ReturnType<typeof useOnboardingIndustriesQuery>;
export type OnboardingIndustriesLazyQueryHookResult = ReturnType<typeof useOnboardingIndustriesLazyQuery>;
export type OnboardingIndustriesQueryResult = Apollo.QueryResult<OnboardingIndustriesQuery, OnboardingIndustriesQueryVariables>;
export const OnboardingLocationsDocument = gql`
    query OnboardingLocations {
  __type(name: "OnboardingLocation") {
    enumValues {
      name
      description
    }
  }
}
    `;

/**
 * __useOnboardingLocationsQuery__
 *
 * To run a query within a React component, call `useOnboardingLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingLocationsQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingLocationsQuery, OnboardingLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingLocationsQuery, OnboardingLocationsQueryVariables>(OnboardingLocationsDocument, options);
      }
export function useOnboardingLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingLocationsQuery, OnboardingLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingLocationsQuery, OnboardingLocationsQueryVariables>(OnboardingLocationsDocument, options);
        }
export type OnboardingLocationsQueryHookResult = ReturnType<typeof useOnboardingLocationsQuery>;
export type OnboardingLocationsLazyQueryHookResult = ReturnType<typeof useOnboardingLocationsLazyQuery>;
export type OnboardingLocationsQueryResult = Apollo.QueryResult<OnboardingLocationsQuery, OnboardingLocationsQueryVariables>;
export const StaffingProfilesDocument = gql`
    query StaffingProfiles($beginDate: ISO8601Date!, $endDate: ISO8601Date!, $profileIds: [ID!]) {
  staffing {
    id
    profiles(beginDate: $beginDate, endDate: $endDate, profileIds: $profileIds) {
      ...StaffingProfile
    }
  }
}
    ${StaffingProfileFragmentDoc}`;

/**
 * __useStaffingProfilesQuery__
 *
 * To run a query within a React component, call `useStaffingProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingProfilesQuery({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      profileIds: // value for 'profileIds'
 *   },
 * });
 */
export function useStaffingProfilesQuery(baseOptions: Apollo.QueryHookOptions<StaffingProfilesQuery, StaffingProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingProfilesQuery, StaffingProfilesQueryVariables>(StaffingProfilesDocument, options);
      }
export function useStaffingProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingProfilesQuery, StaffingProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingProfilesQuery, StaffingProfilesQueryVariables>(StaffingProfilesDocument, options);
        }
export type StaffingProfilesQueryHookResult = ReturnType<typeof useStaffingProfilesQuery>;
export type StaffingProfilesLazyQueryHookResult = ReturnType<typeof useStaffingProfilesLazyQuery>;
export type StaffingProfilesQueryResult = Apollo.QueryResult<StaffingProfilesQuery, StaffingProfilesQueryVariables>;
export const StaffingProfileDocument = gql`
    query StaffingProfile($profileId: ID!, $beginDate: ISO8601Date!, $endDate: ISO8601Date) {
  staffing {
    id
    profile(profileId: $profileId, beginDate: $beginDate, endDate: $endDate) {
      roles {
        ...StaffingRole
      }
      overheads {
        ...StaffingOverhead
      }
      timeOff {
        timesheetBeginDate
        value
      }
    }
  }
  profile(id: $profileId) {
    timesheets(beginDate: $beginDate, endDate: $endDate) {
      ...StaffingTimesheet
    }
  }
}
    ${StaffingRoleFragmentDoc}
${StaffingOverheadFragmentDoc}
${StaffingTimesheetFragmentDoc}`;

/**
 * __useStaffingProfileQuery__
 *
 * To run a query within a React component, call `useStaffingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingProfileQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStaffingProfileQuery(baseOptions: Apollo.QueryHookOptions<StaffingProfileQuery, StaffingProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingProfileQuery, StaffingProfileQueryVariables>(StaffingProfileDocument, options);
      }
export function useStaffingProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingProfileQuery, StaffingProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingProfileQuery, StaffingProfileQueryVariables>(StaffingProfileDocument, options);
        }
export type StaffingProfileQueryHookResult = ReturnType<typeof useStaffingProfileQuery>;
export type StaffingProfileLazyQueryHookResult = ReturnType<typeof useStaffingProfileLazyQuery>;
export type StaffingProfileQueryResult = Apollo.QueryResult<StaffingProfileQuery, StaffingProfileQueryVariables>;
export const StaffingTotalsDocument = gql`
    query StaffingTotals($beginDate: ISO8601Date!, $endDate: ISO8601Date!, $profileIds: [ID!]) {
  staffing {
    id
    totals(beginDate: $beginDate, endDate: $endDate, profileIds: $profileIds) {
      beginDate
      endDate
      totalAssignedHours
      totalAvailableHours
    }
  }
}
    `;

/**
 * __useStaffingTotalsQuery__
 *
 * To run a query within a React component, call `useStaffingTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffingTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffingTotalsQuery({
 *   variables: {
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      profileIds: // value for 'profileIds'
 *   },
 * });
 */
export function useStaffingTotalsQuery(baseOptions: Apollo.QueryHookOptions<StaffingTotalsQuery, StaffingTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffingTotalsQuery, StaffingTotalsQueryVariables>(StaffingTotalsDocument, options);
      }
export function useStaffingTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffingTotalsQuery, StaffingTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffingTotalsQuery, StaffingTotalsQueryVariables>(StaffingTotalsDocument, options);
        }
export type StaffingTotalsQueryHookResult = ReturnType<typeof useStaffingTotalsQuery>;
export type StaffingTotalsLazyQueryHookResult = ReturnType<typeof useStaffingTotalsLazyQuery>;
export type StaffingTotalsQueryResult = Apollo.QueryResult<StaffingTotalsQuery, StaffingTotalsQueryVariables>;
export const CreateStaffingRoleAllocationDocument = gql`
    mutation CreateStaffingRoleAllocation($input: CreateStaffingRoleAllocationInput!) {
  createStaffingRoleAllocation(input: $input) {
    role {
      id
      hoursAllocated
      staffingEntryGroups {
        ...StaffingEntryGroup
      }
      profile {
        timesheets {
          ...StaffingTimesheet
          entryGroups {
            id
            allocatedByUser
            allocation
          }
        }
      }
    }
  }
}
    ${StaffingEntryGroupFragmentDoc}
${StaffingTimesheetFragmentDoc}`;
export type CreateStaffingRoleAllocationMutationFn = Apollo.MutationFunction<CreateStaffingRoleAllocationMutation, CreateStaffingRoleAllocationMutationVariables>;

/**
 * __useCreateStaffingRoleAllocationMutation__
 *
 * To run a mutation, you first call `useCreateStaffingRoleAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffingRoleAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffingRoleAllocationMutation, { data, loading, error }] = useCreateStaffingRoleAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStaffingRoleAllocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffingRoleAllocationMutation, CreateStaffingRoleAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffingRoleAllocationMutation, CreateStaffingRoleAllocationMutationVariables>(CreateStaffingRoleAllocationDocument, options);
      }
export type CreateStaffingRoleAllocationMutationHookResult = ReturnType<typeof useCreateStaffingRoleAllocationMutation>;
export type CreateStaffingRoleAllocationMutationResult = Apollo.MutationResult<CreateStaffingRoleAllocationMutation>;
export type CreateStaffingRoleAllocationMutationOptions = Apollo.BaseMutationOptions<CreateStaffingRoleAllocationMutation, CreateStaffingRoleAllocationMutationVariables>;
export const UpdateOverheadAllocationDocument = gql`
    mutation UpdateOverheadAllocation($input: UpdateEntryGroupAllocationInput!) {
  updateEntryGroupAllocation(input: $input) {
    entryGroup {
      id
      allocation
      role {
        id
        staffingEntryGroups {
          ...StaffingEntryGroup
        }
        profile {
          timesheets {
            ...StaffingTimesheet
          }
        }
      }
    }
  }
}
    ${StaffingEntryGroupFragmentDoc}
${StaffingTimesheetFragmentDoc}`;
export type UpdateOverheadAllocationMutationFn = Apollo.MutationFunction<UpdateOverheadAllocationMutation, UpdateOverheadAllocationMutationVariables>;

/**
 * __useUpdateOverheadAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateOverheadAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOverheadAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOverheadAllocationMutation, { data, loading, error }] = useUpdateOverheadAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOverheadAllocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOverheadAllocationMutation, UpdateOverheadAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOverheadAllocationMutation, UpdateOverheadAllocationMutationVariables>(UpdateOverheadAllocationDocument, options);
      }
export type UpdateOverheadAllocationMutationHookResult = ReturnType<typeof useUpdateOverheadAllocationMutation>;
export type UpdateOverheadAllocationMutationResult = Apollo.MutationResult<UpdateOverheadAllocationMutation>;
export type UpdateOverheadAllocationMutationOptions = Apollo.BaseMutationOptions<UpdateOverheadAllocationMutation, UpdateOverheadAllocationMutationVariables>;
export const BulkCreateStaffingRoleAllocationsDocument = gql`
    mutation BulkCreateStaffingRoleAllocations($input: BulkCreateStaffingRoleAllocationsInput!) {
  bulkCreateStaffingRoleAllocations(input: $input) {
    role {
      id
      staffingEntryGroups {
        ...StaffingEntryGroup
      }
    }
  }
}
    ${StaffingEntryGroupFragmentDoc}`;
export type BulkCreateStaffingRoleAllocationsMutationFn = Apollo.MutationFunction<BulkCreateStaffingRoleAllocationsMutation, BulkCreateStaffingRoleAllocationsMutationVariables>;

/**
 * __useBulkCreateStaffingRoleAllocationsMutation__
 *
 * To run a mutation, you first call `useBulkCreateStaffingRoleAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateStaffingRoleAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateStaffingRoleAllocationsMutation, { data, loading, error }] = useBulkCreateStaffingRoleAllocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateStaffingRoleAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateStaffingRoleAllocationsMutation, BulkCreateStaffingRoleAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateStaffingRoleAllocationsMutation, BulkCreateStaffingRoleAllocationsMutationVariables>(BulkCreateStaffingRoleAllocationsDocument, options);
      }
export type BulkCreateStaffingRoleAllocationsMutationHookResult = ReturnType<typeof useBulkCreateStaffingRoleAllocationsMutation>;
export type BulkCreateStaffingRoleAllocationsMutationResult = Apollo.MutationResult<BulkCreateStaffingRoleAllocationsMutation>;
export type BulkCreateStaffingRoleAllocationsMutationOptions = Apollo.BaseMutationOptions<BulkCreateStaffingRoleAllocationsMutation, BulkCreateStaffingRoleAllocationsMutationVariables>;
export const BulkUpdateStaffingOverheadAllocationsDocument = gql`
    mutation BulkUpdateStaffingOverheadAllocations($input: BulkUpdateStaffingOverheadAllocationsInput!) {
  bulkUpdateStaffingOverheadAllocations(input: $input) {
    entryGroups {
      ...StaffingEntryGroup
    }
  }
}
    ${StaffingEntryGroupFragmentDoc}`;
export type BulkUpdateStaffingOverheadAllocationsMutationFn = Apollo.MutationFunction<BulkUpdateStaffingOverheadAllocationsMutation, BulkUpdateStaffingOverheadAllocationsMutationVariables>;

/**
 * __useBulkUpdateStaffingOverheadAllocationsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateStaffingOverheadAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateStaffingOverheadAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateStaffingOverheadAllocationsMutation, { data, loading, error }] = useBulkUpdateStaffingOverheadAllocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateStaffingOverheadAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateStaffingOverheadAllocationsMutation, BulkUpdateStaffingOverheadAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateStaffingOverheadAllocationsMutation, BulkUpdateStaffingOverheadAllocationsMutationVariables>(BulkUpdateStaffingOverheadAllocationsDocument, options);
      }
export type BulkUpdateStaffingOverheadAllocationsMutationHookResult = ReturnType<typeof useBulkUpdateStaffingOverheadAllocationsMutation>;
export type BulkUpdateStaffingOverheadAllocationsMutationResult = Apollo.MutationResult<BulkUpdateStaffingOverheadAllocationsMutation>;
export type BulkUpdateStaffingOverheadAllocationsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateStaffingOverheadAllocationsMutation, BulkUpdateStaffingOverheadAllocationsMutationVariables>;
export const GenerateStaffingAllocationsDocument = gql`
    mutation GenerateStaffingAllocations($input: GenerateStaffingAllocationsInput!) {
  generateStaffingAllocations(input: $input) {
    entryGroups {
      id
      allocation
      role {
        id
      }
    }
  }
}
    `;
export type GenerateStaffingAllocationsMutationFn = Apollo.MutationFunction<GenerateStaffingAllocationsMutation, GenerateStaffingAllocationsMutationVariables>;

/**
 * __useGenerateStaffingAllocationsMutation__
 *
 * To run a mutation, you first call `useGenerateStaffingAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStaffingAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStaffingAllocationsMutation, { data, loading, error }] = useGenerateStaffingAllocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateStaffingAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateStaffingAllocationsMutation, GenerateStaffingAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateStaffingAllocationsMutation, GenerateStaffingAllocationsMutationVariables>(GenerateStaffingAllocationsDocument, options);
      }
export type GenerateStaffingAllocationsMutationHookResult = ReturnType<typeof useGenerateStaffingAllocationsMutation>;
export type GenerateStaffingAllocationsMutationResult = Apollo.MutationResult<GenerateStaffingAllocationsMutation>;
export type GenerateStaffingAllocationsMutationOptions = Apollo.BaseMutationOptions<GenerateStaffingAllocationsMutation, GenerateStaffingAllocationsMutationVariables>;
export const GlobalRebalanceAllocationsDocument = gql`
    mutation GlobalRebalanceAllocations($input: GlobalRebalanceAllocationsInput!) {
  globalRebalanceAllocations(input: $input) {
    jobBatchBid
  }
}
    `;
export type GlobalRebalanceAllocationsMutationFn = Apollo.MutationFunction<GlobalRebalanceAllocationsMutation, GlobalRebalanceAllocationsMutationVariables>;

/**
 * __useGlobalRebalanceAllocationsMutation__
 *
 * To run a mutation, you first call `useGlobalRebalanceAllocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGlobalRebalanceAllocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [globalRebalanceAllocationsMutation, { data, loading, error }] = useGlobalRebalanceAllocationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGlobalRebalanceAllocationsMutation(baseOptions?: Apollo.MutationHookOptions<GlobalRebalanceAllocationsMutation, GlobalRebalanceAllocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GlobalRebalanceAllocationsMutation, GlobalRebalanceAllocationsMutationVariables>(GlobalRebalanceAllocationsDocument, options);
      }
export type GlobalRebalanceAllocationsMutationHookResult = ReturnType<typeof useGlobalRebalanceAllocationsMutation>;
export type GlobalRebalanceAllocationsMutationResult = Apollo.MutationResult<GlobalRebalanceAllocationsMutation>;
export type GlobalRebalanceAllocationsMutationOptions = Apollo.BaseMutationOptions<GlobalRebalanceAllocationsMutation, GlobalRebalanceAllocationsMutationVariables>;
export const CopyAllocationsFromPreviousWeekDocument = gql`
    mutation CopyAllocationsFromPreviousWeek($input: CopyAllocationsFromPreviousWeekInput!) {
  copyAllocationsFromPreviousWeek(input: $input) {
    entryGroups {
      id
      allocation
      role {
        id
      }
    }
  }
}
    `;
export type CopyAllocationsFromPreviousWeekMutationFn = Apollo.MutationFunction<CopyAllocationsFromPreviousWeekMutation, CopyAllocationsFromPreviousWeekMutationVariables>;

/**
 * __useCopyAllocationsFromPreviousWeekMutation__
 *
 * To run a mutation, you first call `useCopyAllocationsFromPreviousWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyAllocationsFromPreviousWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyAllocationsFromPreviousWeekMutation, { data, loading, error }] = useCopyAllocationsFromPreviousWeekMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyAllocationsFromPreviousWeekMutation(baseOptions?: Apollo.MutationHookOptions<CopyAllocationsFromPreviousWeekMutation, CopyAllocationsFromPreviousWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyAllocationsFromPreviousWeekMutation, CopyAllocationsFromPreviousWeekMutationVariables>(CopyAllocationsFromPreviousWeekDocument, options);
      }
export type CopyAllocationsFromPreviousWeekMutationHookResult = ReturnType<typeof useCopyAllocationsFromPreviousWeekMutation>;
export type CopyAllocationsFromPreviousWeekMutationResult = Apollo.MutationResult<CopyAllocationsFromPreviousWeekMutation>;
export type CopyAllocationsFromPreviousWeekMutationOptions = Apollo.BaseMutationOptions<CopyAllocationsFromPreviousWeekMutation, CopyAllocationsFromPreviousWeekMutationVariables>;
export const BatchJobStatusDocument = gql`
    subscription BatchJobStatus($input: String!) {
  batchJobStatus(jobBatchBid: $input) {
    status {
      bid
      failInfo
      failures
      isComplete
      pending
      total
    }
  }
}
    `;

/**
 * __useBatchJobStatusSubscription__
 *
 * To run a query within a React component, call `useBatchJobStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBatchJobStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchJobStatusSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchJobStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<BatchJobStatusSubscription, BatchJobStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BatchJobStatusSubscription, BatchJobStatusSubscriptionVariables>(BatchJobStatusDocument, options);
      }
export type BatchJobStatusSubscriptionHookResult = ReturnType<typeof useBatchJobStatusSubscription>;
export type BatchJobStatusSubscriptionResult = Apollo.SubscriptionResult<BatchJobStatusSubscription>;
export const StripeAccountResolverDocument = gql`
    query StripeAccountResolver {
  stripeAccount {
    id
    chargesEnabled
    detailsSubmitted
    payoutsEnabled
    stripeId
    setupIncomplete
    status
  }
}
    `;

/**
 * __useStripeAccountResolverQuery__
 *
 * To run a query within a React component, call `useStripeAccountResolverQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountResolverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountResolverQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountResolverQuery(baseOptions?: Apollo.QueryHookOptions<StripeAccountResolverQuery, StripeAccountResolverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeAccountResolverQuery, StripeAccountResolverQueryVariables>(StripeAccountResolverDocument, options);
      }
export function useStripeAccountResolverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeAccountResolverQuery, StripeAccountResolverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeAccountResolverQuery, StripeAccountResolverQueryVariables>(StripeAccountResolverDocument, options);
        }
export type StripeAccountResolverQueryHookResult = ReturnType<typeof useStripeAccountResolverQuery>;
export type StripeAccountResolverLazyQueryHookResult = ReturnType<typeof useStripeAccountResolverLazyQuery>;
export type StripeAccountResolverQueryResult = Apollo.QueryResult<StripeAccountResolverQuery, StripeAccountResolverQueryVariables>;
export const InitiateStripeAccountOnboardingDocument = gql`
    mutation InitiateStripeAccountOnboarding($url: String) {
  initiateStripeAccountOnboarding(input: {url: $url}) {
    stripeAccount {
      id
    }
    clientMutationId
  }
}
    `;
export type InitiateStripeAccountOnboardingMutationFn = Apollo.MutationFunction<InitiateStripeAccountOnboardingMutation, InitiateStripeAccountOnboardingMutationVariables>;

/**
 * __useInitiateStripeAccountOnboardingMutation__
 *
 * To run a mutation, you first call `useInitiateStripeAccountOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateStripeAccountOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateStripeAccountOnboardingMutation, { data, loading, error }] = useInitiateStripeAccountOnboardingMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInitiateStripeAccountOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<InitiateStripeAccountOnboardingMutation, InitiateStripeAccountOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateStripeAccountOnboardingMutation, InitiateStripeAccountOnboardingMutationVariables>(InitiateStripeAccountOnboardingDocument, options);
      }
export type InitiateStripeAccountOnboardingMutationHookResult = ReturnType<typeof useInitiateStripeAccountOnboardingMutation>;
export type InitiateStripeAccountOnboardingMutationResult = Apollo.MutationResult<InitiateStripeAccountOnboardingMutation>;
export type InitiateStripeAccountOnboardingMutationOptions = Apollo.BaseMutationOptions<InitiateStripeAccountOnboardingMutation, InitiateStripeAccountOnboardingMutationVariables>;
export const ResyncStripeAccountDocument = gql`
    mutation ResyncStripeAccount($input: StripeAccountResyncInput!) {
  resyncStripeAccount(input: $input) {
    clientMutationId
    stripeAccount {
      id
    }
  }
}
    `;
export type ResyncStripeAccountMutationFn = Apollo.MutationFunction<ResyncStripeAccountMutation, ResyncStripeAccountMutationVariables>;

/**
 * __useResyncStripeAccountMutation__
 *
 * To run a mutation, you first call `useResyncStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncStripeAccountMutation, { data, loading, error }] = useResyncStripeAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResyncStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<ResyncStripeAccountMutation, ResyncStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncStripeAccountMutation, ResyncStripeAccountMutationVariables>(ResyncStripeAccountDocument, options);
      }
export type ResyncStripeAccountMutationHookResult = ReturnType<typeof useResyncStripeAccountMutation>;
export type ResyncStripeAccountMutationResult = Apollo.MutationResult<ResyncStripeAccountMutation>;
export type ResyncStripeAccountMutationOptions = Apollo.BaseMutationOptions<ResyncStripeAccountMutation, ResyncStripeAccountMutationVariables>;
export const StripeAccountDocument = gql`
    subscription StripeAccount {
  stripeAccount {
    stripeAccount {
      id
      detailsSubmitted
      chargesEnabled
      payoutsEnabled
    }
  }
}
    `;

/**
 * __useStripeAccountSubscription__
 *
 * To run a query within a React component, call `useStripeAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountSubscription({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountSubscription(baseOptions?: Apollo.SubscriptionHookOptions<StripeAccountSubscription, StripeAccountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StripeAccountSubscription, StripeAccountSubscriptionVariables>(StripeAccountDocument, options);
      }
export type StripeAccountSubscriptionHookResult = ReturnType<typeof useStripeAccountSubscription>;
export type StripeAccountSubscriptionResult = Apollo.SubscriptionResult<StripeAccountSubscription>;
export const StripeAccountOnboardingUrlDocument = gql`
    subscription StripeAccountOnboardingUrl {
  stripeAccountOnboardingUrl {
    onboardingUrl
  }
}
    `;

/**
 * __useStripeAccountOnboardingUrlSubscription__
 *
 * To run a query within a React component, call `useStripeAccountOnboardingUrlSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountOnboardingUrlSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountOnboardingUrlSubscription({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountOnboardingUrlSubscription(baseOptions?: Apollo.SubscriptionHookOptions<StripeAccountOnboardingUrlSubscription, StripeAccountOnboardingUrlSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<StripeAccountOnboardingUrlSubscription, StripeAccountOnboardingUrlSubscriptionVariables>(StripeAccountOnboardingUrlDocument, options);
      }
export type StripeAccountOnboardingUrlSubscriptionHookResult = ReturnType<typeof useStripeAccountOnboardingUrlSubscription>;
export type StripeAccountOnboardingUrlSubscriptionResult = Apollo.SubscriptionResult<StripeAccountOnboardingUrlSubscription>;
export const StripeAccountDashboardLinkDocument = gql`
    query StripeAccountDashboardLink {
  stripeAccountDashboardLink {
    url
  }
}
    `;

/**
 * __useStripeAccountDashboardLinkQuery__
 *
 * To run a query within a React component, call `useStripeAccountDashboardLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeAccountDashboardLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeAccountDashboardLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeAccountDashboardLinkQuery(baseOptions?: Apollo.QueryHookOptions<StripeAccountDashboardLinkQuery, StripeAccountDashboardLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeAccountDashboardLinkQuery, StripeAccountDashboardLinkQueryVariables>(StripeAccountDashboardLinkDocument, options);
      }
export function useStripeAccountDashboardLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeAccountDashboardLinkQuery, StripeAccountDashboardLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeAccountDashboardLinkQuery, StripeAccountDashboardLinkQueryVariables>(StripeAccountDashboardLinkDocument, options);
        }
export type StripeAccountDashboardLinkQueryHookResult = ReturnType<typeof useStripeAccountDashboardLinkQuery>;
export type StripeAccountDashboardLinkLazyQueryHookResult = ReturnType<typeof useStripeAccountDashboardLinkLazyQuery>;
export type StripeAccountDashboardLinkQueryResult = Apollo.QueryResult<StripeAccountDashboardLinkQuery, StripeAccountDashboardLinkQueryVariables>;
export const SubscriptionDocument = gql`
    query Subscription {
  currentProfile {
    id
    organization {
      id
      subscription {
        isAnnual
        isActive
        cancelDate
        updatedAt
        stripeId
        upcomingInvoiceDate
        upcomingInvoiceAmount
        previousInvoiceDate
        previousInvoiceAmount
        hasFailedPayment
        prepaidSeatCount
        prepaidSeatsFilled
        planName
        billingEmail
      }
    }
  }
}
    `;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionQueryResult = Apollo.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const TasksDocument = gql`
    query Tasks($sortBy: ProfileTasksSortInput, $filters: TaskFiltersInput, $last: Int, $first: Int, $before: String, $after: String) {
  currentProfile {
    tasks(
      sortBy: $sortBy
      filters: $filters
      last: $last
      first: $first
      before: $before
      after: $after
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...ListedTask
      }
    }
  }
}
    ${ListedTaskFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksFiltersDocument = gql`
    query TasksFilters {
  projects {
    ...TaskFilterProject
  }
  phaseTemplates {
    id
    name
  }
  currentProfile {
    policy {
      permissions {
        canViewAllProjects
      }
    }
    ...AssigneeProfile
    organization {
      profiles {
        ...AssigneeProfile
      }
    }
  }
}
    ${TaskFilterProjectFragmentDoc}
${AssigneeProfileFragmentDoc}`;

/**
 * __useTasksFiltersQuery__
 *
 * To run a query within a React component, call `useTasksFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksFiltersQuery(baseOptions?: Apollo.QueryHookOptions<TasksFiltersQuery, TasksFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksFiltersQuery, TasksFiltersQueryVariables>(TasksFiltersDocument, options);
      }
export function useTasksFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksFiltersQuery, TasksFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksFiltersQuery, TasksFiltersQueryVariables>(TasksFiltersDocument, options);
        }
export type TasksFiltersQueryHookResult = ReturnType<typeof useTasksFiltersQuery>;
export type TasksFiltersLazyQueryHookResult = ReturnType<typeof useTasksFiltersLazyQuery>;
export type TasksFiltersQueryResult = Apollo.QueryResult<TasksFiltersQuery, TasksFiltersQueryVariables>;
export const TaskMarkCompleteDocument = gql`
    mutation TaskMarkComplete($id: ID!) {
  markTaskComplete(input: {id: $id}) {
    task {
      ...ListedTask
    }
  }
}
    ${ListedTaskFragmentDoc}`;
export type TaskMarkCompleteMutationFn = Apollo.MutationFunction<TaskMarkCompleteMutation, TaskMarkCompleteMutationVariables>;

/**
 * __useTaskMarkCompleteMutation__
 *
 * To run a mutation, you first call `useTaskMarkCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskMarkCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskMarkCompleteMutation, { data, loading, error }] = useTaskMarkCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskMarkCompleteMutation(baseOptions?: Apollo.MutationHookOptions<TaskMarkCompleteMutation, TaskMarkCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskMarkCompleteMutation, TaskMarkCompleteMutationVariables>(TaskMarkCompleteDocument, options);
      }
export type TaskMarkCompleteMutationHookResult = ReturnType<typeof useTaskMarkCompleteMutation>;
export type TaskMarkCompleteMutationResult = Apollo.MutationResult<TaskMarkCompleteMutation>;
export type TaskMarkCompleteMutationOptions = Apollo.BaseMutationOptions<TaskMarkCompleteMutation, TaskMarkCompleteMutationVariables>;
export const TaskToggleCompleteDocument = gql`
    mutation TaskToggleComplete($id: ID!) {
  toggleTaskComplete(input: {id: $id}) {
    task {
      ...ListedTask
    }
  }
}
    ${ListedTaskFragmentDoc}`;
export type TaskToggleCompleteMutationFn = Apollo.MutationFunction<TaskToggleCompleteMutation, TaskToggleCompleteMutationVariables>;

/**
 * __useTaskToggleCompleteMutation__
 *
 * To run a mutation, you first call `useTaskToggleCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskToggleCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskToggleCompleteMutation, { data, loading, error }] = useTaskToggleCompleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskToggleCompleteMutation(baseOptions?: Apollo.MutationHookOptions<TaskToggleCompleteMutation, TaskToggleCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskToggleCompleteMutation, TaskToggleCompleteMutationVariables>(TaskToggleCompleteDocument, options);
      }
export type TaskToggleCompleteMutationHookResult = ReturnType<typeof useTaskToggleCompleteMutation>;
export type TaskToggleCompleteMutationResult = Apollo.MutationResult<TaskToggleCompleteMutation>;
export type TaskToggleCompleteMutationOptions = Apollo.BaseMutationOptions<TaskToggleCompleteMutation, TaskToggleCompleteMutationVariables>;
export const TaxRatesDocument = gql`
    query TaxRates($qboInstanceId: ID) {
  taxRates(qboInstanceId: $qboInstanceId) {
    id
    name
    percent
    draftInvoices {
      ...DraftInvoice
    }
  }
}
    ${DraftInvoiceFragmentDoc}`;

/**
 * __useTaxRatesQuery__
 *
 * To run a query within a React component, call `useTaxRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxRatesQuery({
 *   variables: {
 *      qboInstanceId: // value for 'qboInstanceId'
 *   },
 * });
 */
export function useTaxRatesQuery(baseOptions?: Apollo.QueryHookOptions<TaxRatesQuery, TaxRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxRatesQuery, TaxRatesQueryVariables>(TaxRatesDocument, options);
      }
export function useTaxRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxRatesQuery, TaxRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxRatesQuery, TaxRatesQueryVariables>(TaxRatesDocument, options);
        }
export type TaxRatesQueryHookResult = ReturnType<typeof useTaxRatesQuery>;
export type TaxRatesLazyQueryHookResult = ReturnType<typeof useTaxRatesLazyQuery>;
export type TaxRatesQueryResult = Apollo.QueryResult<TaxRatesQuery, TaxRatesQueryVariables>;
export const TimeOffRequestsDocument = gql`
    query TimeOffRequests($sortBy: TimeOffRequestsSortInput, $filters: TimeOffRequestsFiltersInput!, $last: Int, $first: Int, $before: String, $after: String) {
  timeOffRequests(
    sortBy: $sortBy
    filters: $filters
    last: $last
    first: $first
    before: $before
    after: $after
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;

/**
 * __useTimeOffRequestsQuery__
 *
 * To run a query within a React component, call `useTimeOffRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffRequestsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      filters: // value for 'filters'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTimeOffRequestsQuery(baseOptions: Apollo.QueryHookOptions<TimeOffRequestsQuery, TimeOffRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffRequestsQuery, TimeOffRequestsQueryVariables>(TimeOffRequestsDocument, options);
      }
export function useTimeOffRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffRequestsQuery, TimeOffRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffRequestsQuery, TimeOffRequestsQueryVariables>(TimeOffRequestsDocument, options);
        }
export type TimeOffRequestsQueryHookResult = ReturnType<typeof useTimeOffRequestsQuery>;
export type TimeOffRequestsLazyQueryHookResult = ReturnType<typeof useTimeOffRequestsLazyQuery>;
export type TimeOffRequestsQueryResult = Apollo.QueryResult<TimeOffRequestsQuery, TimeOffRequestsQueryVariables>;
export const StandardHolidaysDocument = gql`
    query standardHolidays {
  standardHolidays {
    id
    displayName
    lookupName
  }
}
    `;

/**
 * __useStandardHolidaysQuery__
 *
 * To run a query within a React component, call `useStandardHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardHolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<StandardHolidaysQuery, StandardHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardHolidaysQuery, StandardHolidaysQueryVariables>(StandardHolidaysDocument, options);
      }
export function useStandardHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardHolidaysQuery, StandardHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardHolidaysQuery, StandardHolidaysQueryVariables>(StandardHolidaysDocument, options);
        }
export type StandardHolidaysQueryHookResult = ReturnType<typeof useStandardHolidaysQuery>;
export type StandardHolidaysLazyQueryHookResult = ReturnType<typeof useStandardHolidaysLazyQuery>;
export type StandardHolidaysQueryResult = Apollo.QueryResult<StandardHolidaysQuery, StandardHolidaysQueryVariables>;
export const TimeOffPolicyDocument = gql`
    query timeOffPolicy($slug: String, $id: ID) {
  timeOffPolicy(slug: $slug, id: $id) {
    ...TimeOffPolicyResult
  }
}
    ${TimeOffPolicyResultFragmentDoc}`;

/**
 * __useTimeOffPolicyQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffPolicyQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffPolicyQuery, TimeOffPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPolicyQuery, TimeOffPolicyQueryVariables>(TimeOffPolicyDocument, options);
      }
export function useTimeOffPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPolicyQuery, TimeOffPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPolicyQuery, TimeOffPolicyQueryVariables>(TimeOffPolicyDocument, options);
        }
export type TimeOffPolicyQueryHookResult = ReturnType<typeof useTimeOffPolicyQuery>;
export type TimeOffPolicyLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyLazyQuery>;
export type TimeOffPolicyQueryResult = Apollo.QueryResult<TimeOffPolicyQuery, TimeOffPolicyQueryVariables>;
export const TimeOffHolidayPolicyDocument = gql`
    query timeOffHolidayPolicy($slug: String, $id: ID) {
  timeOffHolidayPolicy(slug: $slug, id: $id) {
    ...TimeOffHolidayPolicyResult
  }
}
    ${TimeOffHolidayPolicyResultFragmentDoc}`;

/**
 * __useTimeOffHolidayPolicyQuery__
 *
 * To run a query within a React component, call `useTimeOffHolidayPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffHolidayPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffHolidayPolicyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffHolidayPolicyQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffHolidayPolicyQuery, TimeOffHolidayPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffHolidayPolicyQuery, TimeOffHolidayPolicyQueryVariables>(TimeOffHolidayPolicyDocument, options);
      }
export function useTimeOffHolidayPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffHolidayPolicyQuery, TimeOffHolidayPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffHolidayPolicyQuery, TimeOffHolidayPolicyQueryVariables>(TimeOffHolidayPolicyDocument, options);
        }
export type TimeOffHolidayPolicyQueryHookResult = ReturnType<typeof useTimeOffHolidayPolicyQuery>;
export type TimeOffHolidayPolicyLazyQueryHookResult = ReturnType<typeof useTimeOffHolidayPolicyLazyQuery>;
export type TimeOffHolidayPolicyQueryResult = Apollo.QueryResult<TimeOffHolidayPolicyQuery, TimeOffHolidayPolicyQueryVariables>;
export const TimeOffHolidayDeletionInfoDocument = gql`
    query timeOffHolidayDeletionInfo($id: ID) {
  timeOffHoliday(id: $id) {
    id
    canBeDeleted
    appliesToNextPayday
  }
}
    `;

/**
 * __useTimeOffHolidayDeletionInfoQuery__
 *
 * To run a query within a React component, call `useTimeOffHolidayDeletionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffHolidayDeletionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffHolidayDeletionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffHolidayDeletionInfoQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffHolidayDeletionInfoQuery, TimeOffHolidayDeletionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffHolidayDeletionInfoQuery, TimeOffHolidayDeletionInfoQueryVariables>(TimeOffHolidayDeletionInfoDocument, options);
      }
export function useTimeOffHolidayDeletionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffHolidayDeletionInfoQuery, TimeOffHolidayDeletionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffHolidayDeletionInfoQuery, TimeOffHolidayDeletionInfoQueryVariables>(TimeOffHolidayDeletionInfoDocument, options);
        }
export type TimeOffHolidayDeletionInfoQueryHookResult = ReturnType<typeof useTimeOffHolidayDeletionInfoQuery>;
export type TimeOffHolidayDeletionInfoLazyQueryHookResult = ReturnType<typeof useTimeOffHolidayDeletionInfoLazyQuery>;
export type TimeOffHolidayDeletionInfoQueryResult = Apollo.QueryResult<TimeOffHolidayDeletionInfoQuery, TimeOffHolidayDeletionInfoQueryVariables>;
export const TimeOffRequestDeletionInfoDocument = gql`
    query timeOffRequestDeletionInfo($id: ID) {
  timeOffRequest(id: $id) {
    id
    needsDeletionWarning
  }
}
    `;

/**
 * __useTimeOffRequestDeletionInfoQuery__
 *
 * To run a query within a React component, call `useTimeOffRequestDeletionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffRequestDeletionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffRequestDeletionInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffRequestDeletionInfoQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffRequestDeletionInfoQuery, TimeOffRequestDeletionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffRequestDeletionInfoQuery, TimeOffRequestDeletionInfoQueryVariables>(TimeOffRequestDeletionInfoDocument, options);
      }
export function useTimeOffRequestDeletionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffRequestDeletionInfoQuery, TimeOffRequestDeletionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffRequestDeletionInfoQuery, TimeOffRequestDeletionInfoQueryVariables>(TimeOffRequestDeletionInfoDocument, options);
        }
export type TimeOffRequestDeletionInfoQueryHookResult = ReturnType<typeof useTimeOffRequestDeletionInfoQuery>;
export type TimeOffRequestDeletionInfoLazyQueryHookResult = ReturnType<typeof useTimeOffRequestDeletionInfoLazyQuery>;
export type TimeOffRequestDeletionInfoQueryResult = Apollo.QueryResult<TimeOffRequestDeletionInfoQuery, TimeOffRequestDeletionInfoQueryVariables>;
export const TimeOffPolicyProfileGroupsDocument = gql`
    query timeOffPolicyProfileGroups($slug: String, $id: ID) {
  timeOffPolicy(slug: $slug, id: $id) {
    id
    profileOptions {
      ...SelectableProfileGroup
    }
  }
}
    ${SelectableProfileGroupFragmentDoc}`;

/**
 * __useTimeOffPolicyProfileGroupsQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyProfileGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyProfileGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyProfileGroupsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimeOffPolicyProfileGroupsQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffPolicyProfileGroupsQuery, TimeOffPolicyProfileGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPolicyProfileGroupsQuery, TimeOffPolicyProfileGroupsQueryVariables>(TimeOffPolicyProfileGroupsDocument, options);
      }
export function useTimeOffPolicyProfileGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPolicyProfileGroupsQuery, TimeOffPolicyProfileGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPolicyProfileGroupsQuery, TimeOffPolicyProfileGroupsQueryVariables>(TimeOffPolicyProfileGroupsDocument, options);
        }
export type TimeOffPolicyProfileGroupsQueryHookResult = ReturnType<typeof useTimeOffPolicyProfileGroupsQuery>;
export type TimeOffPolicyProfileGroupsLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyProfileGroupsLazyQuery>;
export type TimeOffPolicyProfileGroupsQueryResult = Apollo.QueryResult<TimeOffPolicyProfileGroupsQuery, TimeOffPolicyProfileGroupsQueryVariables>;
export const ProfilePayrollTypeDocument = gql`
    query profilePayrollType($id: ID) {
  profile(id: $id) {
    id
    payrollType
  }
}
    `;

/**
 * __useProfilePayrollTypeQuery__
 *
 * To run a query within a React component, call `useProfilePayrollTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePayrollTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePayrollTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProfilePayrollTypeQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePayrollTypeQuery, ProfilePayrollTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePayrollTypeQuery, ProfilePayrollTypeQueryVariables>(ProfilePayrollTypeDocument, options);
      }
export function useProfilePayrollTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePayrollTypeQuery, ProfilePayrollTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePayrollTypeQuery, ProfilePayrollTypeQueryVariables>(ProfilePayrollTypeDocument, options);
        }
export type ProfilePayrollTypeQueryHookResult = ReturnType<typeof useProfilePayrollTypeQuery>;
export type ProfilePayrollTypeLazyQueryHookResult = ReturnType<typeof useProfilePayrollTypeLazyQuery>;
export type ProfilePayrollTypeQueryResult = Apollo.QueryResult<ProfilePayrollTypeQuery, ProfilePayrollTypeQueryVariables>;
export const TimeOffPolicyAssignmentTotalsDocument = gql`
    query timeOffPolicyAssignmentTotals($profileSlug: String!, $timeOffPolicySlug: String!) {
  timeOffPolicyAssignment(
    profileSlug: $profileSlug
    timeOffPolicySlug: $timeOffPolicySlug
  ) {
    id
    timeOffPolicy {
      ...TimeOffPolicyResult
    }
    totals {
      hoursAccruedYtd
      hoursTaken
      hoursPlanned
      hoursAvailable
    }
  }
}
    ${TimeOffPolicyResultFragmentDoc}`;

/**
 * __useTimeOffPolicyAssignmentTotalsQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyAssignmentTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyAssignmentTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyAssignmentTotalsQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *      timeOffPolicySlug: // value for 'timeOffPolicySlug'
 *   },
 * });
 */
export function useTimeOffPolicyAssignmentTotalsQuery(baseOptions: Apollo.QueryHookOptions<TimeOffPolicyAssignmentTotalsQuery, TimeOffPolicyAssignmentTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPolicyAssignmentTotalsQuery, TimeOffPolicyAssignmentTotalsQueryVariables>(TimeOffPolicyAssignmentTotalsDocument, options);
      }
export function useTimeOffPolicyAssignmentTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPolicyAssignmentTotalsQuery, TimeOffPolicyAssignmentTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPolicyAssignmentTotalsQuery, TimeOffPolicyAssignmentTotalsQueryVariables>(TimeOffPolicyAssignmentTotalsDocument, options);
        }
export type TimeOffPolicyAssignmentTotalsQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentTotalsQuery>;
export type TimeOffPolicyAssignmentTotalsLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentTotalsLazyQuery>;
export type TimeOffPolicyAssignmentTotalsQueryResult = Apollo.QueryResult<TimeOffPolicyAssignmentTotalsQuery, TimeOffPolicyAssignmentTotalsQueryVariables>;
export const TimeOffPolicyAssignmentEventsDocument = gql`
    query timeOffPolicyAssignmentEvents($profileSlug: String!, $timeOffPolicySlug: String!, $sortDirection: SortDirectionInput!, $after: String, $before: String, $first: Int, $last: Int) {
  timeOffPolicyAssignment(
    profileSlug: $profileSlug
    timeOffPolicySlug: $timeOffPolicySlug
  ) {
    events(
      after: $after
      before: $before
      sortDirection: $sortDirection
      first: $first
      last: $last
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        activity
        displayHours
        balance
        beginning
        ending
        note
        createdAt
      }
    }
  }
}
    `;

/**
 * __useTimeOffPolicyAssignmentEventsQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyAssignmentEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyAssignmentEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyAssignmentEventsQuery({
 *   variables: {
 *      profileSlug: // value for 'profileSlug'
 *      timeOffPolicySlug: // value for 'timeOffPolicySlug'
 *      sortDirection: // value for 'sortDirection'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useTimeOffPolicyAssignmentEventsQuery(baseOptions: Apollo.QueryHookOptions<TimeOffPolicyAssignmentEventsQuery, TimeOffPolicyAssignmentEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPolicyAssignmentEventsQuery, TimeOffPolicyAssignmentEventsQueryVariables>(TimeOffPolicyAssignmentEventsDocument, options);
      }
export function useTimeOffPolicyAssignmentEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPolicyAssignmentEventsQuery, TimeOffPolicyAssignmentEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPolicyAssignmentEventsQuery, TimeOffPolicyAssignmentEventsQueryVariables>(TimeOffPolicyAssignmentEventsDocument, options);
        }
export type TimeOffPolicyAssignmentEventsQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentEventsQuery>;
export type TimeOffPolicyAssignmentEventsLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentEventsLazyQuery>;
export type TimeOffPolicyAssignmentEventsQueryResult = Apollo.QueryResult<TimeOffPolicyAssignmentEventsQuery, TimeOffPolicyAssignmentEventsQueryVariables>;
export const TimeOffPolicyAssignmentsDocument = gql`
    query timeOffPolicyAssignments($profileId: ID!) {
  profile(id: $profileId) {
    timeOffPolicyAssignments {
      ...TimeOffPolicyAssignment
    }
  }
}
    ${TimeOffPolicyAssignmentFragmentDoc}`;

/**
 * __useTimeOffPolicyAssignmentsQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyAssignmentsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useTimeOffPolicyAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<TimeOffPolicyAssignmentsQuery, TimeOffPolicyAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPolicyAssignmentsQuery, TimeOffPolicyAssignmentsQueryVariables>(TimeOffPolicyAssignmentsDocument, options);
      }
export function useTimeOffPolicyAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPolicyAssignmentsQuery, TimeOffPolicyAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPolicyAssignmentsQuery, TimeOffPolicyAssignmentsQueryVariables>(TimeOffPolicyAssignmentsDocument, options);
        }
export type TimeOffPolicyAssignmentsQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentsQuery>;
export type TimeOffPolicyAssignmentsLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyAssignmentsLazyQuery>;
export type TimeOffPolicyAssignmentsQueryResult = Apollo.QueryResult<TimeOffPolicyAssignmentsQuery, TimeOffPolicyAssignmentsQueryVariables>;
export const TimeOffHolidayPoliciesDocument = gql`
    query timeOffHolidayPolicies {
  currentProfile {
    organization {
      timeOffHolidayPolicies {
        ...TimeOffHolidayPolicyResult
      }
    }
  }
}
    ${TimeOffHolidayPolicyResultFragmentDoc}`;

/**
 * __useTimeOffHolidayPoliciesQuery__
 *
 * To run a query within a React component, call `useTimeOffHolidayPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffHolidayPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffHolidayPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffHolidayPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffHolidayPoliciesQuery, TimeOffHolidayPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffHolidayPoliciesQuery, TimeOffHolidayPoliciesQueryVariables>(TimeOffHolidayPoliciesDocument, options);
      }
export function useTimeOffHolidayPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffHolidayPoliciesQuery, TimeOffHolidayPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffHolidayPoliciesQuery, TimeOffHolidayPoliciesQueryVariables>(TimeOffHolidayPoliciesDocument, options);
        }
export type TimeOffHolidayPoliciesQueryHookResult = ReturnType<typeof useTimeOffHolidayPoliciesQuery>;
export type TimeOffHolidayPoliciesLazyQueryHookResult = ReturnType<typeof useTimeOffHolidayPoliciesLazyQuery>;
export type TimeOffHolidayPoliciesQueryResult = Apollo.QueryResult<TimeOffHolidayPoliciesQuery, TimeOffHolidayPoliciesQueryVariables>;
export const TimeOffPoliciesDocument = gql`
    query timeOffPolicies {
  currentProfile {
    organization {
      timeOffPolicies {
        ...TimeOffPolicyResult
      }
    }
  }
}
    ${TimeOffPolicyResultFragmentDoc}`;

/**
 * __useTimeOffPoliciesQuery__
 *
 * To run a query within a React component, call `useTimeOffPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffPoliciesQuery, TimeOffPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffPoliciesQuery, TimeOffPoliciesQueryVariables>(TimeOffPoliciesDocument, options);
      }
export function useTimeOffPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffPoliciesQuery, TimeOffPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffPoliciesQuery, TimeOffPoliciesQueryVariables>(TimeOffPoliciesDocument, options);
        }
export type TimeOffPoliciesQueryHookResult = ReturnType<typeof useTimeOffPoliciesQuery>;
export type TimeOffPoliciesLazyQueryHookResult = ReturnType<typeof useTimeOffPoliciesLazyQuery>;
export type TimeOffPoliciesQueryResult = Apollo.QueryResult<TimeOffPoliciesQuery, TimeOffPoliciesQueryVariables>;
export const TimeOffRequestsFiltersDocument = gql`
    query TimeOffRequestsFilters {
  currentProfile {
    organization {
      timeOffPolicies {
        id
        name
      }
      profiles {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useTimeOffRequestsFiltersQuery__
 *
 * To run a query within a React component, call `useTimeOffRequestsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffRequestsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffRequestsFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffRequestsFiltersQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffRequestsFiltersQuery, TimeOffRequestsFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffRequestsFiltersQuery, TimeOffRequestsFiltersQueryVariables>(TimeOffRequestsFiltersDocument, options);
      }
export function useTimeOffRequestsFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffRequestsFiltersQuery, TimeOffRequestsFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffRequestsFiltersQuery, TimeOffRequestsFiltersQueryVariables>(TimeOffRequestsFiltersDocument, options);
        }
export type TimeOffRequestsFiltersQueryHookResult = ReturnType<typeof useTimeOffRequestsFiltersQuery>;
export type TimeOffRequestsFiltersLazyQueryHookResult = ReturnType<typeof useTimeOffRequestsFiltersLazyQuery>;
export type TimeOffRequestsFiltersQueryResult = Apollo.QueryResult<TimeOffRequestsFiltersQuery, TimeOffRequestsFiltersQueryVariables>;
export const UpdateTimeOffPolicyDocument = gql`
    mutation UpdateTimeOffPolicy($input: UpdateTimeOffPolicyInput!) {
  updateTimeOffPolicy(input: $input) {
    timeOffPolicy {
      id
      name
      description
      slug
      autoApprove
      reviewRecipients {
        id
        fullName
      }
    }
  }
}
    `;
export type UpdateTimeOffPolicyMutationFn = Apollo.MutationFunction<UpdateTimeOffPolicyMutation, UpdateTimeOffPolicyMutationVariables>;

/**
 * __useUpdateTimeOffPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffPolicyMutation, { data, loading, error }] = useUpdateTimeOffPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeOffPolicyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffPolicyMutation, UpdateTimeOffPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeOffPolicyMutation, UpdateTimeOffPolicyMutationVariables>(UpdateTimeOffPolicyDocument, options);
      }
export type UpdateTimeOffPolicyMutationHookResult = ReturnType<typeof useUpdateTimeOffPolicyMutation>;
export type UpdateTimeOffPolicyMutationResult = Apollo.MutationResult<UpdateTimeOffPolicyMutation>;
export type UpdateTimeOffPolicyMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffPolicyMutation, UpdateTimeOffPolicyMutationVariables>;
export const CreateAdminTimeOffAccrualDocument = gql`
    mutation CreateAdminTimeOffAccrual($input: CreateAdminTimeOffAccrualInput!) {
  createAdminTimeOffAccrual(input: $input) {
    timeOffPolicyAssignment {
      ...TimeOffPolicyAssignment
    }
  }
}
    ${TimeOffPolicyAssignmentFragmentDoc}`;
export type CreateAdminTimeOffAccrualMutationFn = Apollo.MutationFunction<CreateAdminTimeOffAccrualMutation, CreateAdminTimeOffAccrualMutationVariables>;

/**
 * __useCreateAdminTimeOffAccrualMutation__
 *
 * To run a mutation, you first call `useCreateAdminTimeOffAccrualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminTimeOffAccrualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminTimeOffAccrualMutation, { data, loading, error }] = useCreateAdminTimeOffAccrualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminTimeOffAccrualMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminTimeOffAccrualMutation, CreateAdminTimeOffAccrualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminTimeOffAccrualMutation, CreateAdminTimeOffAccrualMutationVariables>(CreateAdminTimeOffAccrualDocument, options);
      }
export type CreateAdminTimeOffAccrualMutationHookResult = ReturnType<typeof useCreateAdminTimeOffAccrualMutation>;
export type CreateAdminTimeOffAccrualMutationResult = Apollo.MutationResult<CreateAdminTimeOffAccrualMutation>;
export type CreateAdminTimeOffAccrualMutationOptions = Apollo.BaseMutationOptions<CreateAdminTimeOffAccrualMutation, CreateAdminTimeOffAccrualMutationVariables>;
export const TimeOffEnumsDocument = gql`
    query TimeOffEnums {
  timeOffTypes: __type(name: "TimeOffType") {
    enumValues {
      name
      description
    }
  }
  timeOffAccrualMethodTypes: __type(name: "TimeOffAccrualMethod") {
    enumValues {
      name
      description
    }
  }
  timeOffEarnedRateTypes: __type(name: "TimeOffEarnedRate") {
    enumValues {
      name
      description
    }
  }
  payFrequencyTypes: __type(name: "PayFrequency") {
    enumValues {
      name
      description
    }
  }
  timeOffRequestStatusTypes: __type(name: "TimeOffRequestStatus") {
    enumValues {
      name
      description
    }
  }
}
    `;

/**
 * __useTimeOffEnumsQuery__
 *
 * To run a query within a React component, call `useTimeOffEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffEnumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffEnumsQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffEnumsQuery, TimeOffEnumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffEnumsQuery, TimeOffEnumsQueryVariables>(TimeOffEnumsDocument, options);
      }
export function useTimeOffEnumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffEnumsQuery, TimeOffEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffEnumsQuery, TimeOffEnumsQueryVariables>(TimeOffEnumsDocument, options);
        }
export type TimeOffEnumsQueryHookResult = ReturnType<typeof useTimeOffEnumsQuery>;
export type TimeOffEnumsLazyQueryHookResult = ReturnType<typeof useTimeOffEnumsLazyQuery>;
export type TimeOffEnumsQueryResult = Apollo.QueryResult<TimeOffEnumsQuery, TimeOffEnumsQueryVariables>;
export const TimeOffOrganizationDocument = gql`
    query timeOffOrganization {
  currentProfile {
    id
    organization {
      id
      paySchedule {
        id
      }
      overheads {
        id
        name
        entryCount
      }
    }
  }
}
    `;

/**
 * __useTimeOffOrganizationQuery__
 *
 * To run a query within a React component, call `useTimeOffOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffOrganizationQuery, TimeOffOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffOrganizationQuery, TimeOffOrganizationQueryVariables>(TimeOffOrganizationDocument, options);
      }
export function useTimeOffOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffOrganizationQuery, TimeOffOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffOrganizationQuery, TimeOffOrganizationQueryVariables>(TimeOffOrganizationDocument, options);
        }
export type TimeOffOrganizationQueryHookResult = ReturnType<typeof useTimeOffOrganizationQuery>;
export type TimeOffOrganizationLazyQueryHookResult = ReturnType<typeof useTimeOffOrganizationLazyQuery>;
export type TimeOffOrganizationQueryResult = Apollo.QueryResult<TimeOffOrganizationQuery, TimeOffOrganizationQueryVariables>;
export const DeleteTimeOffPolicyAssignmentDocument = gql`
    mutation DeleteTimeOffPolicyAssignment($id: ID!, $profileId: ID!) {
  deleteTimeOffPolicyAssignment(input: {id: $id, profileId: $profileId}) {
    timeOffPolicy {
      id
      timeOffPolicyAssignments {
        id
      }
      eligibleProfiles {
        id
      }
      ineligibleProfiles {
        id
      }
    }
  }
}
    `;
export type DeleteTimeOffPolicyAssignmentMutationFn = Apollo.MutationFunction<DeleteTimeOffPolicyAssignmentMutation, DeleteTimeOffPolicyAssignmentMutationVariables>;

/**
 * __useDeleteTimeOffPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffPolicyAssignmentMutation, { data, loading, error }] = useDeleteTimeOffPolicyAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDeleteTimeOffPolicyAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffPolicyAssignmentMutation, DeleteTimeOffPolicyAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffPolicyAssignmentMutation, DeleteTimeOffPolicyAssignmentMutationVariables>(DeleteTimeOffPolicyAssignmentDocument, options);
      }
export type DeleteTimeOffPolicyAssignmentMutationHookResult = ReturnType<typeof useDeleteTimeOffPolicyAssignmentMutation>;
export type DeleteTimeOffPolicyAssignmentMutationResult = Apollo.MutationResult<DeleteTimeOffPolicyAssignmentMutation>;
export type DeleteTimeOffPolicyAssignmentMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffPolicyAssignmentMutation, DeleteTimeOffPolicyAssignmentMutationVariables>;
export const CreateTimeOffPolicyDocument = gql`
    mutation CreateTimeOffPolicy($input: CreateTimeOffPolicyInput!) {
  createTimeOffPolicy(input: $input) {
    timeOffPolicy {
      ...TimeOffPolicyResult
    }
  }
}
    ${TimeOffPolicyResultFragmentDoc}`;
export type CreateTimeOffPolicyMutationFn = Apollo.MutationFunction<CreateTimeOffPolicyMutation, CreateTimeOffPolicyMutationVariables>;

/**
 * __useCreateTimeOffPolicyMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffPolicyMutation, { data, loading, error }] = useCreateTimeOffPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeOffPolicyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffPolicyMutation, CreateTimeOffPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeOffPolicyMutation, CreateTimeOffPolicyMutationVariables>(CreateTimeOffPolicyDocument, options);
      }
export type CreateTimeOffPolicyMutationHookResult = ReturnType<typeof useCreateTimeOffPolicyMutation>;
export type CreateTimeOffPolicyMutationResult = Apollo.MutationResult<CreateTimeOffPolicyMutation>;
export type CreateTimeOffPolicyMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffPolicyMutation, CreateTimeOffPolicyMutationVariables>;
export const DeactivateTimeOffPolicyDocument = gql`
    mutation DeactivateTimeOffPolicy($input: DeactivateTimeOffPolicyInput!) {
  deactivateTimeOffPolicy(input: $input) {
    timeOffPolicy {
      id
      deletedAt
    }
  }
}
    `;
export type DeactivateTimeOffPolicyMutationFn = Apollo.MutationFunction<DeactivateTimeOffPolicyMutation, DeactivateTimeOffPolicyMutationVariables>;

/**
 * __useDeactivateTimeOffPolicyMutation__
 *
 * To run a mutation, you first call `useDeactivateTimeOffPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTimeOffPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTimeOffPolicyMutation, { data, loading, error }] = useDeactivateTimeOffPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateTimeOffPolicyMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateTimeOffPolicyMutation, DeactivateTimeOffPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateTimeOffPolicyMutation, DeactivateTimeOffPolicyMutationVariables>(DeactivateTimeOffPolicyDocument, options);
      }
export type DeactivateTimeOffPolicyMutationHookResult = ReturnType<typeof useDeactivateTimeOffPolicyMutation>;
export type DeactivateTimeOffPolicyMutationResult = Apollo.MutationResult<DeactivateTimeOffPolicyMutation>;
export type DeactivateTimeOffPolicyMutationOptions = Apollo.BaseMutationOptions<DeactivateTimeOffPolicyMutation, DeactivateTimeOffPolicyMutationVariables>;
export const CreatePayScheduleDocument = gql`
    mutation CreatePaySchedule($input: CreatePayScheduleInput!) {
  createPaySchedule(input: $input) {
    paySchedule {
      ...PayrollPaySchedule
    }
  }
}
    ${PayrollPayScheduleFragmentDoc}`;
export type CreatePayScheduleMutationFn = Apollo.MutationFunction<CreatePayScheduleMutation, CreatePayScheduleMutationVariables>;

/**
 * __useCreatePayScheduleMutation__
 *
 * To run a mutation, you first call `useCreatePayScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayScheduleMutation, { data, loading, error }] = useCreatePayScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayScheduleMutation, CreatePayScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayScheduleMutation, CreatePayScheduleMutationVariables>(CreatePayScheduleDocument, options);
      }
export type CreatePayScheduleMutationHookResult = ReturnType<typeof useCreatePayScheduleMutation>;
export type CreatePayScheduleMutationResult = Apollo.MutationResult<CreatePayScheduleMutation>;
export type CreatePayScheduleMutationOptions = Apollo.BaseMutationOptions<CreatePayScheduleMutation, CreatePayScheduleMutationVariables>;
export const CancelTimeOffRequestDocument = gql`
    mutation CancelTimeOffRequest($input: CancelTimeOffRequestInput!) {
  cancelTimeOffRequest(input: $input) {
    timeOffRequest {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;
export type CancelTimeOffRequestMutationFn = Apollo.MutationFunction<CancelTimeOffRequestMutation, CancelTimeOffRequestMutationVariables>;

/**
 * __useCancelTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useCancelTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTimeOffRequestMutation, { data, loading, error }] = useCancelTimeOffRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTimeOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelTimeOffRequestMutation, CancelTimeOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTimeOffRequestMutation, CancelTimeOffRequestMutationVariables>(CancelTimeOffRequestDocument, options);
      }
export type CancelTimeOffRequestMutationHookResult = ReturnType<typeof useCancelTimeOffRequestMutation>;
export type CancelTimeOffRequestMutationResult = Apollo.MutationResult<CancelTimeOffRequestMutation>;
export type CancelTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<CancelTimeOffRequestMutation, CancelTimeOffRequestMutationVariables>;
export const CreateTimeOffRequestDocument = gql`
    mutation CreateTimeOffRequest($input: CreateTimeOffRequestInput!) {
  createTimeOffRequest(input: $input) {
    timeOffRequest {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;
export type CreateTimeOffRequestMutationFn = Apollo.MutationFunction<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>;

/**
 * __useCreateTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffRequestMutation, { data, loading, error }] = useCreateTimeOffRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>(CreateTimeOffRequestDocument, options);
      }
export type CreateTimeOffRequestMutationHookResult = ReturnType<typeof useCreateTimeOffRequestMutation>;
export type CreateTimeOffRequestMutationResult = Apollo.MutationResult<CreateTimeOffRequestMutation>;
export type CreateTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>;
export const DeleteTimeOffRequestDocument = gql`
    mutation DeleteTimeOffRequest($input: DeleteTimeOffRequestInput!) {
  deleteTimeOffRequest(input: $input) {
    timeOffRequest {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;
export type DeleteTimeOffRequestMutationFn = Apollo.MutationFunction<DeleteTimeOffRequestMutation, DeleteTimeOffRequestMutationVariables>;

/**
 * __useDeleteTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffRequestMutation, { data, loading, error }] = useDeleteTimeOffRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffRequestMutation, DeleteTimeOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffRequestMutation, DeleteTimeOffRequestMutationVariables>(DeleteTimeOffRequestDocument, options);
      }
export type DeleteTimeOffRequestMutationHookResult = ReturnType<typeof useDeleteTimeOffRequestMutation>;
export type DeleteTimeOffRequestMutationResult = Apollo.MutationResult<DeleteTimeOffRequestMutation>;
export type DeleteTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffRequestMutation, DeleteTimeOffRequestMutationVariables>;
export const UpdateTimeOffRequestDocument = gql`
    mutation UpdateTimeOffRequest($input: UpdateTimeOffRequestInput!) {
  updateTimeOffRequest(input: $input) {
    timeOffRequest {
      ...TimeOffRequest
    }
  }
}
    ${TimeOffRequestFragmentDoc}`;
export type UpdateTimeOffRequestMutationFn = Apollo.MutationFunction<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>;

/**
 * __useUpdateTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffRequestMutation, { data, loading, error }] = useUpdateTimeOffRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeOffRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>(UpdateTimeOffRequestDocument, options);
      }
export type UpdateTimeOffRequestMutationHookResult = ReturnType<typeof useUpdateTimeOffRequestMutation>;
export type UpdateTimeOffRequestMutationResult = Apollo.MutationResult<UpdateTimeOffRequestMutation>;
export type UpdateTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>;
export const BulkCreateTimeOffPolicyAssignmentsDocument = gql`
    mutation BulkCreateTimeOffPolicyAssignments($input: BulkCreateTimeOffPolicyAssignmentsInput!) {
  bulkCreateTimeOffPolicyAssignments(input: $input) {
    timeOffPolicy {
      ...TimeOffPolicyResult
    }
  }
}
    ${TimeOffPolicyResultFragmentDoc}`;
export type BulkCreateTimeOffPolicyAssignmentsMutationFn = Apollo.MutationFunction<BulkCreateTimeOffPolicyAssignmentsMutation, BulkCreateTimeOffPolicyAssignmentsMutationVariables>;

/**
 * __useBulkCreateTimeOffPolicyAssignmentsMutation__
 *
 * To run a mutation, you first call `useBulkCreateTimeOffPolicyAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateTimeOffPolicyAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateTimeOffPolicyAssignmentsMutation, { data, loading, error }] = useBulkCreateTimeOffPolicyAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateTimeOffPolicyAssignmentsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateTimeOffPolicyAssignmentsMutation, BulkCreateTimeOffPolicyAssignmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateTimeOffPolicyAssignmentsMutation, BulkCreateTimeOffPolicyAssignmentsMutationVariables>(BulkCreateTimeOffPolicyAssignmentsDocument, options);
      }
export type BulkCreateTimeOffPolicyAssignmentsMutationHookResult = ReturnType<typeof useBulkCreateTimeOffPolicyAssignmentsMutation>;
export type BulkCreateTimeOffPolicyAssignmentsMutationResult = Apollo.MutationResult<BulkCreateTimeOffPolicyAssignmentsMutation>;
export type BulkCreateTimeOffPolicyAssignmentsMutationOptions = Apollo.BaseMutationOptions<BulkCreateTimeOffPolicyAssignmentsMutation, BulkCreateTimeOffPolicyAssignmentsMutationVariables>;
export const CreateTimeOffHolidayPolicyDocument = gql`
    mutation CreateTimeOffHolidayPolicy($input: CreateTimeOffHolidayPolicyInput!) {
  createTimeOffHolidayPolicy(input: $input) {
    timeOffHolidayPolicy {
      ...TimeOffHolidayPolicyResult
    }
  }
}
    ${TimeOffHolidayPolicyResultFragmentDoc}`;
export type CreateTimeOffHolidayPolicyMutationFn = Apollo.MutationFunction<CreateTimeOffHolidayPolicyMutation, CreateTimeOffHolidayPolicyMutationVariables>;

/**
 * __useCreateTimeOffHolidayPolicyMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffHolidayPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffHolidayPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffHolidayPolicyMutation, { data, loading, error }] = useCreateTimeOffHolidayPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeOffHolidayPolicyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffHolidayPolicyMutation, CreateTimeOffHolidayPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeOffHolidayPolicyMutation, CreateTimeOffHolidayPolicyMutationVariables>(CreateTimeOffHolidayPolicyDocument, options);
      }
export type CreateTimeOffHolidayPolicyMutationHookResult = ReturnType<typeof useCreateTimeOffHolidayPolicyMutation>;
export type CreateTimeOffHolidayPolicyMutationResult = Apollo.MutationResult<CreateTimeOffHolidayPolicyMutation>;
export type CreateTimeOffHolidayPolicyMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffHolidayPolicyMutation, CreateTimeOffHolidayPolicyMutationVariables>;
export const DeleteTimeOffHolidayDocument = gql`
    mutation DeleteTimeOffHoliday($input: DeleteTimeOffHolidayInput!) {
  deleteTimeOffHoliday(input: $input) {
    timeOffHolidayPolicy {
      id
      timeOffHolidaysByYear {
        year
        holidays {
          ...TimeOffHolidayResult
        }
      }
    }
  }
}
    ${TimeOffHolidayResultFragmentDoc}`;
export type DeleteTimeOffHolidayMutationFn = Apollo.MutationFunction<DeleteTimeOffHolidayMutation, DeleteTimeOffHolidayMutationVariables>;

/**
 * __useDeleteTimeOffHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteTimeOffHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeOffHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeOffHolidayMutation, { data, loading, error }] = useDeleteTimeOffHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTimeOffHolidayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeOffHolidayMutation, DeleteTimeOffHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeOffHolidayMutation, DeleteTimeOffHolidayMutationVariables>(DeleteTimeOffHolidayDocument, options);
      }
export type DeleteTimeOffHolidayMutationHookResult = ReturnType<typeof useDeleteTimeOffHolidayMutation>;
export type DeleteTimeOffHolidayMutationResult = Apollo.MutationResult<DeleteTimeOffHolidayMutation>;
export type DeleteTimeOffHolidayMutationOptions = Apollo.BaseMutationOptions<DeleteTimeOffHolidayMutation, DeleteTimeOffHolidayMutationVariables>;
export const UpdateTimeOffHolidayDocument = gql`
    mutation UpdateTimeOffHoliday($input: UpdateTimeOffHolidayInput!) {
  updateTimeOffHoliday(input: $input) {
    timeOffHolidayPolicy {
      id
      timeOffHolidaysByYear {
        year
        holidays {
          ...TimeOffHolidayResult
        }
      }
    }
  }
}
    ${TimeOffHolidayResultFragmentDoc}`;
export type UpdateTimeOffHolidayMutationFn = Apollo.MutationFunction<UpdateTimeOffHolidayMutation, UpdateTimeOffHolidayMutationVariables>;

/**
 * __useUpdateTimeOffHolidayMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffHolidayMutation, { data, loading, error }] = useUpdateTimeOffHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimeOffHolidayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffHolidayMutation, UpdateTimeOffHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeOffHolidayMutation, UpdateTimeOffHolidayMutationVariables>(UpdateTimeOffHolidayDocument, options);
      }
export type UpdateTimeOffHolidayMutationHookResult = ReturnType<typeof useUpdateTimeOffHolidayMutation>;
export type UpdateTimeOffHolidayMutationResult = Apollo.MutationResult<UpdateTimeOffHolidayMutation>;
export type UpdateTimeOffHolidayMutationOptions = Apollo.BaseMutationOptions<UpdateTimeOffHolidayMutation, UpdateTimeOffHolidayMutationVariables>;
export const CreateTimeOffHolidayDocument = gql`
    mutation CreateTimeOffHoliday($input: CreateTimeOffHolidayInput!) {
  createTimeOffHoliday(input: $input) {
    timeOffHolidayPolicy {
      id
      timeOffHolidaysByYear {
        year
        holidays {
          ...TimeOffHolidayResult
        }
      }
    }
  }
}
    ${TimeOffHolidayResultFragmentDoc}`;
export type CreateTimeOffHolidayMutationFn = Apollo.MutationFunction<CreateTimeOffHolidayMutation, CreateTimeOffHolidayMutationVariables>;

/**
 * __useCreateTimeOffHolidayMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffHolidayMutation, { data, loading, error }] = useCreateTimeOffHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeOffHolidayMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeOffHolidayMutation, CreateTimeOffHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeOffHolidayMutation, CreateTimeOffHolidayMutationVariables>(CreateTimeOffHolidayDocument, options);
      }
export type CreateTimeOffHolidayMutationHookResult = ReturnType<typeof useCreateTimeOffHolidayMutation>;
export type CreateTimeOffHolidayMutationResult = Apollo.MutationResult<CreateTimeOffHolidayMutation>;
export type CreateTimeOffHolidayMutationOptions = Apollo.BaseMutationOptions<CreateTimeOffHolidayMutation, CreateTimeOffHolidayMutationVariables>;
export const CopyTimeOffHolidaysYearDocument = gql`
    mutation CopyTimeOffHolidaysYear($input: CopyTimeOffHolidaysYearInput!) {
  copyTimeOffHolidaysYear(input: $input) {
    timeOffHolidayPolicy {
      ...TimeOffHolidayPolicyResult
    }
  }
}
    ${TimeOffHolidayPolicyResultFragmentDoc}`;
export type CopyTimeOffHolidaysYearMutationFn = Apollo.MutationFunction<CopyTimeOffHolidaysYearMutation, CopyTimeOffHolidaysYearMutationVariables>;

/**
 * __useCopyTimeOffHolidaysYearMutation__
 *
 * To run a mutation, you first call `useCopyTimeOffHolidaysYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTimeOffHolidaysYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTimeOffHolidaysYearMutation, { data, loading, error }] = useCopyTimeOffHolidaysYearMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTimeOffHolidaysYearMutation(baseOptions?: Apollo.MutationHookOptions<CopyTimeOffHolidaysYearMutation, CopyTimeOffHolidaysYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyTimeOffHolidaysYearMutation, CopyTimeOffHolidaysYearMutationVariables>(CopyTimeOffHolidaysYearDocument, options);
      }
export type CopyTimeOffHolidaysYearMutationHookResult = ReturnType<typeof useCopyTimeOffHolidaysYearMutation>;
export type CopyTimeOffHolidaysYearMutationResult = Apollo.MutationResult<CopyTimeOffHolidaysYearMutation>;
export type CopyTimeOffHolidaysYearMutationOptions = Apollo.BaseMutationOptions<CopyTimeOffHolidaysYearMutation, CopyTimeOffHolidaysYearMutationVariables>;
export const TimeOffCreateOverheadItemDocument = gql`
    mutation TimeOffCreateOverheadItem($name: String!, $availableHours: Boolean!) {
  createOverhead(input: {name: $name, availableHours: $availableHours}) {
    overhead {
      ...TimeOffOverheadItem
    }
  }
}
    ${TimeOffOverheadItemFragmentDoc}`;
export type TimeOffCreateOverheadItemMutationFn = Apollo.MutationFunction<TimeOffCreateOverheadItemMutation, TimeOffCreateOverheadItemMutationVariables>;

/**
 * __useTimeOffCreateOverheadItemMutation__
 *
 * To run a mutation, you first call `useTimeOffCreateOverheadItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffCreateOverheadItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffCreateOverheadItemMutation, { data, loading, error }] = useTimeOffCreateOverheadItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *      availableHours: // value for 'availableHours'
 *   },
 * });
 */
export function useTimeOffCreateOverheadItemMutation(baseOptions?: Apollo.MutationHookOptions<TimeOffCreateOverheadItemMutation, TimeOffCreateOverheadItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TimeOffCreateOverheadItemMutation, TimeOffCreateOverheadItemMutationVariables>(TimeOffCreateOverheadItemDocument, options);
      }
export type TimeOffCreateOverheadItemMutationHookResult = ReturnType<typeof useTimeOffCreateOverheadItemMutation>;
export type TimeOffCreateOverheadItemMutationResult = Apollo.MutationResult<TimeOffCreateOverheadItemMutation>;
export type TimeOffCreateOverheadItemMutationOptions = Apollo.BaseMutationOptions<TimeOffCreateOverheadItemMutation, TimeOffCreateOverheadItemMutationVariables>;
export const CreateTimerEntryDocument = gql`
    mutation createTimerEntry($input: CreateEntryInput!) {
  createEntry(input: $input) {
    entry {
      ...TimerEntry
    }
  }
}
    ${TimerEntryFragmentDoc}`;
export type CreateTimerEntryMutationFn = Apollo.MutationFunction<CreateTimerEntryMutation, CreateTimerEntryMutationVariables>;

/**
 * __useCreateTimerEntryMutation__
 *
 * To run a mutation, you first call `useCreateTimerEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimerEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimerEntryMutation, { data, loading, error }] = useCreateTimerEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimerEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimerEntryMutation, CreateTimerEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimerEntryMutation, CreateTimerEntryMutationVariables>(CreateTimerEntryDocument, options);
      }
export type CreateTimerEntryMutationHookResult = ReturnType<typeof useCreateTimerEntryMutation>;
export type CreateTimerEntryMutationResult = Apollo.MutationResult<CreateTimerEntryMutation>;
export type CreateTimerEntryMutationOptions = Apollo.BaseMutationOptions<CreateTimerEntryMutation, CreateTimerEntryMutationVariables>;
export const UpdateTimerEntryDocument = gql`
    mutation updateTimerEntry($input: UpdateEntryInput!) {
  updateEntry(input: $input) {
    entry {
      ...TimerEntry
    }
  }
}
    ${TimerEntryFragmentDoc}`;
export type UpdateTimerEntryMutationFn = Apollo.MutationFunction<UpdateTimerEntryMutation, UpdateTimerEntryMutationVariables>;

/**
 * __useUpdateTimerEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimerEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimerEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimerEntryMutation, { data, loading, error }] = useUpdateTimerEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimerEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimerEntryMutation, UpdateTimerEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimerEntryMutation, UpdateTimerEntryMutationVariables>(UpdateTimerEntryDocument, options);
      }
export type UpdateTimerEntryMutationHookResult = ReturnType<typeof useUpdateTimerEntryMutation>;
export type UpdateTimerEntryMutationResult = Apollo.MutationResult<UpdateTimerEntryMutation>;
export type UpdateTimerEntryMutationOptions = Apollo.BaseMutationOptions<UpdateTimerEntryMutation, UpdateTimerEntryMutationVariables>;
export const TimerEntriesDocument = gql`
    query timerEntries {
  timer {
    entries {
      ...TimerEntry
    }
  }
}
    ${TimerEntryFragmentDoc}`;

/**
 * __useTimerEntriesQuery__
 *
 * To run a query within a React component, call `useTimerEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimerEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimerEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimerEntriesQuery(baseOptions?: Apollo.QueryHookOptions<TimerEntriesQuery, TimerEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimerEntriesQuery, TimerEntriesQueryVariables>(TimerEntriesDocument, options);
      }
export function useTimerEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimerEntriesQuery, TimerEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimerEntriesQuery, TimerEntriesQueryVariables>(TimerEntriesDocument, options);
        }
export type TimerEntriesQueryHookResult = ReturnType<typeof useTimerEntriesQuery>;
export type TimerEntriesLazyQueryHookResult = ReturnType<typeof useTimerEntriesLazyQuery>;
export type TimerEntriesQueryResult = Apollo.QueryResult<TimerEntriesQuery, TimerEntriesQueryVariables>;
export const TimerOrgDataDocument = gql`
    query timerOrgData {
  activities {
    id
    name
  }
  timer {
    projects {
      id
      name
    }
  }
}
    `;

/**
 * __useTimerOrgDataQuery__
 *
 * To run a query within a React component, call `useTimerOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimerOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimerOrgDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimerOrgDataQuery(baseOptions?: Apollo.QueryHookOptions<TimerOrgDataQuery, TimerOrgDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimerOrgDataQuery, TimerOrgDataQueryVariables>(TimerOrgDataDocument, options);
      }
export function useTimerOrgDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimerOrgDataQuery, TimerOrgDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimerOrgDataQuery, TimerOrgDataQueryVariables>(TimerOrgDataDocument, options);
        }
export type TimerOrgDataQueryHookResult = ReturnType<typeof useTimerOrgDataQuery>;
export type TimerOrgDataLazyQueryHookResult = ReturnType<typeof useTimerOrgDataLazyQuery>;
export type TimerOrgDataQueryResult = Apollo.QueryResult<TimerOrgDataQuery, TimerOrgDataQueryVariables>;
export const TimerProjectDataDocument = gql`
    query timerProjectData($projectId: ID!) {
  timer {
    projectPhases(projectId: $projectId) {
      id
      name
    }
  }
}
    `;

/**
 * __useTimerProjectDataQuery__
 *
 * To run a query within a React component, call `useTimerProjectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimerProjectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimerProjectDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useTimerProjectDataQuery(baseOptions: Apollo.QueryHookOptions<TimerProjectDataQuery, TimerProjectDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimerProjectDataQuery, TimerProjectDataQueryVariables>(TimerProjectDataDocument, options);
      }
export function useTimerProjectDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimerProjectDataQuery, TimerProjectDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimerProjectDataQuery, TimerProjectDataQueryVariables>(TimerProjectDataDocument, options);
        }
export type TimerProjectDataQueryHookResult = ReturnType<typeof useTimerProjectDataQuery>;
export type TimerProjectDataLazyQueryHookResult = ReturnType<typeof useTimerProjectDataLazyQuery>;
export type TimerProjectDataQueryResult = Apollo.QueryResult<TimerProjectDataQuery, TimerProjectDataQueryVariables>;
export const TimerPhaseDataDocument = gql`
    query timerPhaseData($phaseId: ID!) {
  currentProfile {
    roles(phaseId: $phaseId) {
      id
      name
    }
  }
}
    `;

/**
 * __useTimerPhaseDataQuery__
 *
 * To run a query within a React component, call `useTimerPhaseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimerPhaseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimerPhaseDataQuery({
 *   variables: {
 *      phaseId: // value for 'phaseId'
 *   },
 * });
 */
export function useTimerPhaseDataQuery(baseOptions: Apollo.QueryHookOptions<TimerPhaseDataQuery, TimerPhaseDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimerPhaseDataQuery, TimerPhaseDataQueryVariables>(TimerPhaseDataDocument, options);
      }
export function useTimerPhaseDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimerPhaseDataQuery, TimerPhaseDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimerPhaseDataQuery, TimerPhaseDataQueryVariables>(TimerPhaseDataDocument, options);
        }
export type TimerPhaseDataQueryHookResult = ReturnType<typeof useTimerPhaseDataQuery>;
export type TimerPhaseDataLazyQueryHookResult = ReturnType<typeof useTimerPhaseDataLazyQuery>;
export type TimerPhaseDataQueryResult = Apollo.QueryResult<TimerPhaseDataQuery, TimerPhaseDataQueryVariables>;
export const ProjectsUnbilledDocument = gql`
    query ProjectsUnbilled($filters: ProjectsUnbilledFiltersInput!, $sortBy: ProjectsUnbilledSortInput, $last: Int, $first: Int, $before: String, $after: String) {
  reports {
    projectsUnbilled(
      sortBy: $sortBy
      filters: $filters
      last: $last
      first: $first
      before: $before
      after: $after
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        projectColor
        projectId
        projectName
        projectNumber
        clientName
        totalInvoiced
        lastInvoiceTotal
        totalPlannedServices
        percentComplete
        currentUnbilled
        totalUnbilled
        projectLink
        lastInvoiceEndDate
        firstPhaseBeginDate
        minUnbilledEntryDate
        firstTimelogEntryDate
      }
      totals {
        currentUnbilled
        invoicedServices
        plannedServices
        totalUnbilled
      }
    }
  }
}
    `;

/**
 * __useProjectsUnbilledQuery__
 *
 * To run a query within a React component, call `useProjectsUnbilledQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsUnbilledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsUnbilledQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortBy: // value for 'sortBy'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectsUnbilledQuery(baseOptions: Apollo.QueryHookOptions<ProjectsUnbilledQuery, ProjectsUnbilledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsUnbilledQuery, ProjectsUnbilledQueryVariables>(ProjectsUnbilledDocument, options);
      }
export function useProjectsUnbilledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsUnbilledQuery, ProjectsUnbilledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsUnbilledQuery, ProjectsUnbilledQueryVariables>(ProjectsUnbilledDocument, options);
        }
export type ProjectsUnbilledQueryHookResult = ReturnType<typeof useProjectsUnbilledQuery>;
export type ProjectsUnbilledLazyQueryHookResult = ReturnType<typeof useProjectsUnbilledLazyQuery>;
export type ProjectsUnbilledQueryResult = Apollo.QueryResult<ProjectsUnbilledQuery, ProjectsUnbilledQueryVariables>;
export const ProjectsUnbilledFiltersDocument = gql`
    query ProjectsUnbilledFilters {
  projectsOptions {
    id
    name
  }
  allClients {
    displayName
    id
  }
  categories {
    id
    name
  }
}
    `;

/**
 * __useProjectsUnbilledFiltersQuery__
 *
 * To run a query within a React component, call `useProjectsUnbilledFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsUnbilledFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsUnbilledFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsUnbilledFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsUnbilledFiltersQuery, ProjectsUnbilledFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsUnbilledFiltersQuery, ProjectsUnbilledFiltersQueryVariables>(ProjectsUnbilledFiltersDocument, options);
      }
export function useProjectsUnbilledFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsUnbilledFiltersQuery, ProjectsUnbilledFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsUnbilledFiltersQuery, ProjectsUnbilledFiltersQueryVariables>(ProjectsUnbilledFiltersDocument, options);
        }
export type ProjectsUnbilledFiltersQueryHookResult = ReturnType<typeof useProjectsUnbilledFiltersQuery>;
export type ProjectsUnbilledFiltersLazyQueryHookResult = ReturnType<typeof useProjectsUnbilledFiltersLazyQuery>;
export type ProjectsUnbilledFiltersQueryResult = Apollo.QueryResult<ProjectsUnbilledFiltersQuery, ProjectsUnbilledFiltersQueryVariables>;
export const ExportUnbilledReportDocument = gql`
    mutation ExportUnbilledReport($input: ExportUnbilledProjectsReportInput!) {
  exportUnbilledProjectsReport(input: $input) {
    organization {
      id
    }
  }
}
    `;
export type ExportUnbilledReportMutationFn = Apollo.MutationFunction<ExportUnbilledReportMutation, ExportUnbilledReportMutationVariables>;

/**
 * __useExportUnbilledReportMutation__
 *
 * To run a mutation, you first call `useExportUnbilledReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUnbilledReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUnbilledReportMutation, { data, loading, error }] = useExportUnbilledReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportUnbilledReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportUnbilledReportMutation, ExportUnbilledReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUnbilledReportMutation, ExportUnbilledReportMutationVariables>(ExportUnbilledReportDocument, options);
      }
export type ExportUnbilledReportMutationHookResult = ReturnType<typeof useExportUnbilledReportMutation>;
export type ExportUnbilledReportMutationResult = Apollo.MutationResult<ExportUnbilledReportMutation>;
export type ExportUnbilledReportMutationOptions = Apollo.BaseMutationOptions<ExportUnbilledReportMutation, ExportUnbilledReportMutationVariables>;
export const CurrentAccountDocument = gql`
    query currentAccount {
  me {
    ...CurrentUser
    organizations {
      ...CurrentUserOrganizations
    }
  }
}
    ${CurrentUserFragmentDoc}
${CurrentUserOrganizationsFragmentDoc}`;

/**
 * __useCurrentAccountQuery__
 *
 * To run a query within a React component, call `useCurrentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAccountQuery(baseOptions?: Apollo.QueryHookOptions<CurrentAccountQuery, CurrentAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentAccountQuery, CurrentAccountQueryVariables>(CurrentAccountDocument, options);
      }
export function useCurrentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentAccountQuery, CurrentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentAccountQuery, CurrentAccountQueryVariables>(CurrentAccountDocument, options);
        }
export type CurrentAccountQueryHookResult = ReturnType<typeof useCurrentAccountQuery>;
export type CurrentAccountLazyQueryHookResult = ReturnType<typeof useCurrentAccountLazyQuery>;
export type CurrentAccountQueryResult = Apollo.QueryResult<CurrentAccountQuery, CurrentAccountQueryVariables>;
export const CurrentLocaleDocument = gql`
    query currentLocale {
  currentProfile {
    organization {
      currency {
        ...CurrentOrganizationCurrency
      }
      locale {
        ...CurrentOrganizationLocale
      }
    }
  }
}
    ${CurrentOrganizationCurrencyFragmentDoc}
${CurrentOrganizationLocaleFragmentDoc}`;

/**
 * __useCurrentLocaleQuery__
 *
 * To run a query within a React component, call `useCurrentLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentLocaleQuery(baseOptions?: Apollo.QueryHookOptions<CurrentLocaleQuery, CurrentLocaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentLocaleQuery, CurrentLocaleQueryVariables>(CurrentLocaleDocument, options);
      }
export function useCurrentLocaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentLocaleQuery, CurrentLocaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentLocaleQuery, CurrentLocaleQueryVariables>(CurrentLocaleDocument, options);
        }
export type CurrentLocaleQueryHookResult = ReturnType<typeof useCurrentLocaleQuery>;
export type CurrentLocaleLazyQueryHookResult = ReturnType<typeof useCurrentLocaleLazyQuery>;
export type CurrentLocaleQueryResult = Apollo.QueryResult<CurrentLocaleQuery, CurrentLocaleQueryVariables>;
export const CurrentProfileDocument = gql`
    query currentProfile {
  currentProfile {
    ...CurrentProfile
  }
}
    ${CurrentProfileFragmentDoc}`;

/**
 * __useCurrentProfileQuery__
 *
 * To run a query within a React component, call `useCurrentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfileQuery(baseOptions?: Apollo.QueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
      }
export function useCurrentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, options);
        }
export type CurrentProfileQueryHookResult = ReturnType<typeof useCurrentProfileQuery>;
export type CurrentProfileLazyQueryHookResult = ReturnType<typeof useCurrentProfileLazyQuery>;
export type CurrentProfileQueryResult = Apollo.QueryResult<CurrentProfileQuery, CurrentProfileQueryVariables>;
export const CurrentProfilePolicyDocument = gql`
    query currentProfilePolicy {
  currentProfile {
    id
    policy {
      ...CurrentProfilePolicy
    }
  }
}
    ${CurrentProfilePolicyFragmentDoc}`;

/**
 * __useCurrentProfilePolicyQuery__
 *
 * To run a query within a React component, call `useCurrentProfilePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfilePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfilePolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfilePolicyQuery(baseOptions?: Apollo.QueryHookOptions<CurrentProfilePolicyQuery, CurrentProfilePolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentProfilePolicyQuery, CurrentProfilePolicyQueryVariables>(CurrentProfilePolicyDocument, options);
      }
export function useCurrentProfilePolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentProfilePolicyQuery, CurrentProfilePolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentProfilePolicyQuery, CurrentProfilePolicyQueryVariables>(CurrentProfilePolicyDocument, options);
        }
export type CurrentProfilePolicyQueryHookResult = ReturnType<typeof useCurrentProfilePolicyQuery>;
export type CurrentProfilePolicyLazyQueryHookResult = ReturnType<typeof useCurrentProfilePolicyLazyQuery>;
export type CurrentProfilePolicyQueryResult = Apollo.QueryResult<CurrentProfilePolicyQuery, CurrentProfilePolicyQueryVariables>;
export const TimeOffCurrentProfileDocument = gql`
    query TimeOffCurrentProfile {
  currentProfile {
    id
    timeOffPolicyAssignments {
      id
      timeOffPolicy {
        id
        deletedAt
      }
    }
    organization {
      id
      settings {
        id
        ptoEnabled
      }
    }
  }
}
    `;

/**
 * __useTimeOffCurrentProfileQuery__
 *
 * To run a query within a React component, call `useTimeOffCurrentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffCurrentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffCurrentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffCurrentProfileQuery(baseOptions?: Apollo.QueryHookOptions<TimeOffCurrentProfileQuery, TimeOffCurrentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeOffCurrentProfileQuery, TimeOffCurrentProfileQueryVariables>(TimeOffCurrentProfileDocument, options);
      }
export function useTimeOffCurrentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeOffCurrentProfileQuery, TimeOffCurrentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeOffCurrentProfileQuery, TimeOffCurrentProfileQueryVariables>(TimeOffCurrentProfileDocument, options);
        }
export type TimeOffCurrentProfileQueryHookResult = ReturnType<typeof useTimeOffCurrentProfileQuery>;
export type TimeOffCurrentProfileLazyQueryHookResult = ReturnType<typeof useTimeOffCurrentProfileLazyQuery>;
export type TimeOffCurrentProfileQueryResult = Apollo.QueryResult<TimeOffCurrentProfileQuery, TimeOffCurrentProfileQueryVariables>;