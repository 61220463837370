
const digest = '30f2bb1c175767be8c184ac29b93c3dda6cd6b510fb979cb12e08a4409d9b5c6';
const css = `._negative_1u5vo_1 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_1u5vo_1"};
export { css, digest };
  